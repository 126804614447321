/**
 * @file src/components/cloud-drive/actions/CloudDriveActions.tsx
 * @description Cloud drive action buttons with consistent rounded styling
 * @version 2.0.0
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Upload, FolderPlus } from 'lucide-react';
import { cn } from '@/utils/utils';

interface CloudDriveActionsProps {
  onUpload: () => void;
  onNewFolder: () => void;
  variant?: 'default' | 'compact';
  className?: string;
}

export const CloudDriveActions: React.FC<CloudDriveActionsProps> = ({
  onUpload,
  onNewFolder,
  className
}) => {
  const { t } = useTranslation();

  const buttonStyles = cn(
    "flex items-center justify-center gap-2",
    "w-full px-4 py-2.5",
    "rounded-xl",  // Keep the original rounded corners
    "text-sm font-medium",
    "shadow-sm hover:shadow-md",
    "transition-all duration-300",
    "backdrop-blur-sm"
  );

  return (
    <div className={cn(
      "flex flex-col gap-3 w-full",
      "max-w-[280px]",
      className
    )}>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onUpload}
        className={cn(
          buttonStyles,
          "bg-blue-500 hover:bg-blue-600",
          "text-white",
          "border border-blue-400 hover:border-blue-300",
        )}
      >
        <Upload className="h-4 w-4 flex-shrink-0" />
        <span className="truncate">{t('cloud_drive.actions.upload')}</span>
      </motion.button>

      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onNewFolder}
        className={cn(
          buttonStyles,
          "bg-white dark:bg-gray-800",
          "text-gray-700 dark:text-gray-200",
          "border border-gray-200 dark:border-gray-700",
          "hover:bg-gray-50 dark:hover:bg-gray-700"
        )}
      >
        <FolderPlus className="h-4 w-4 flex-shrink-0" />
        <span className="truncate">{t('cloud_drive.actions.new_folder')}</span>
      </motion.button>
    </div>
  );
};

export default CloudDriveActions;