/**
 * @file src/app/ghost/components/dialogs/GhostUnlockDriveDialog.tsx
 * @description Enhanced unlock dialog with Nygma styling for phantom drives
 * @version 1.1.0
 */

import React, { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Eye, 
  EyeOff, 
  Shield, 
  Lock,
  Key,
  AlertTriangle,
  CheckCircle2 
} from 'lucide-react';

import { GhostDialog } from '../common/GhostDialog';
import { GhostGradientText } from '../common/GhostGradients';
import { GhostGlass } from '../common/GhostGlass';
import { CircularProgress } from '@/components/ui/circular-progress';
import { useEncryptedDrives } from '@/hooks/encrypted-drive/useEncryptedDrive';
import { encryptionService } from '@/services/encrypted-drive/encryption-singleton';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { debugLogger } from '@/utils/debug-logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import type { EncryptedDrive } from '@/types/encrypted-drive.types';

interface GhostUnlockDriveDialogProps {
  drive: EncryptedDrive;
  onOpenChange: (open: boolean) => void;
  onUnlockSuccess?: (drive: EncryptedDrive) => void;
  onRecovery?: () => void;
}

export const GhostUnlockDriveDialog: React.FC<GhostUnlockDriveDialogProps> = ({
  drive,
  onOpenChange,
  onUnlockSuccess,
  onRecovery
}) => {
  const { t } = useGhostTranslation();
  const queryClient = useQueryClient();
  
  // Local state
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [unlockSuccess, setUnlockSuccess] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  
  const { unlockDrive } = useEncryptedDrives();

  // Clear state on unmount
  useEffect(() => {
    return () => {
      setPassword('');
      setError('');
      setUnlockSuccess(false);
      setRedirecting(false);
    };
  }, []);

  // Handle form submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsVerifying(true);
    setError('');
    
    try {
      debugLogger.info('Starting unlock process', { 
        driveId: drive.id,
        driveName: drive.name,
        passwordLength: password.length 
      });

      // First try local verification - this is the normal unlock flow
      const verified = await encryptionService.unlockDrive(password.trim(), drive);

      // For security - don't indicate if this is a duress password or failed password
      if (!verified) {
        // Local verification failed, but this could still be a duress password
        // Let's try to unlock with it directly through the API

        try {
          logger.debug('Local verification failed, trying as potential duress password');
          
          // Call the API to unlock - the server will check if it's a duress password
          await unlockDrive({ 
            driveId: drive.id,
            password: password.trim()
          });
          
          // If we get here, either:
          // 1. It was a duress password and the server accepted it
          // 2. The server has accepted the password for some other reason
          
          // Since we don't know which, we'll mark the drive as "unlocked" in all cases
          // and let the middleware handle showing the correct data based on duress mode
          
          // Update local state immediately
          queryClient.setQueryData<EncryptedDrive[]>(
            ['encrypted-drives', 'list'],
            old => (old || []).map((d: any) => 
              d.id === drive.id ? { ...d, status: 'unlocked' } : d
            )
          );
          
          // Show success state
          setUnlockSuccess(true);
          
          // If success callback provided, call it
          if (onUnlockSuccess) {
            setTimeout(() => {
              onUnlockSuccess(drive);
              // Also explicitly close the dialog
              onOpenChange(false);
            }, 1500);
          } else {
            // Prepare for auto-closing
            setRedirecting(true);
            
            // Wait for success animation then close
            setTimeout(() => {
              onOpenChange(false);
            }, 1500);
          }
          
          logger.debug('Drive unlock with provided password accepted', { driveId: drive.id });
          
        } catch (duressError) {
          // If duress check fails, the password is truly invalid
          logger.debug('Password invalid for both regular unlock and duress');
          setError(t('create.password.errors.mismatch') as string);
          setIsVerifying(false);
          return;
        }
        
        return;
      }

      debugLogger.info('Local verification successful', {
        driveId: drive.id,
        driveName: drive.name
      });

      // Check if the keys are actually stored
      const hasKeys = encryptionService.getDecryptedKeys(drive.id);
      
      if (!hasKeys) {
        logger.error('Keys not stored despite successful verification', {
          driveId: drive.id
        });
        setError(t('create.errors.drive_creation_failed') as string);
        setIsVerifying(false);
        return;
      }

      // Then update server status for regular unlock
      await unlockDrive({ driveId: drive.id });

      // Update local state immediately
      queryClient.setQueryData<EncryptedDrive[]>(
        ['encrypted-drives', 'list'],
        old => (old || []).map((d: any) => 
          d.id === drive.id ? { ...d, status: 'unlocked' } : d
        )
      );

      // Show success state
      setUnlockSuccess(true);

      // If success callback provided, call it
      if (onUnlockSuccess) {
        setTimeout(() => {
          onUnlockSuccess(drive);
          // Also explicitly close the dialog
          onOpenChange(false);
        }, 1500);
      } else {
        // Prepare for auto-closing
        setRedirecting(true);
        
        // Wait for success animation then close
        setTimeout(() => {
          onOpenChange(false);
        }, 1500);
      }

      logger.debug('Drive unlock completed successfully', { driveId: drive.id });

    } catch (error) {
      logger.error('Error unlocking drive:', error);
      debugLogger.error('Unlock process failed', {
        error: error instanceof Error ? error.message : 'Unknown error',
        driveId: drive.id
      });

      setError(t('create.errors.drive_creation_failed') as string);
      encryptionService.clearDecryptedKeys(drive.id);
      setIsVerifying(false);
    }
  };

  const handleRecovery = () => {
    logger.debug('Opening recovery flow', {
      component: 'GhostUnlockDriveDialog',
      driveId: drive.id
    });
    
    if (onRecovery) {
      onRecovery();
    }
  };

  return (
    <GhostDialog
      open={true}
      onOpenChange={onOpenChange}
    >
      <div className="space-y-6">
        {/* Animated Header */}
        <div className="relative h-20 -m-6 mb-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]" />
          <motion.div
            className="absolute inset-0 bg-grid-white/[0.2] bg-[size:16px_16px]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.2 }}
          />
          <motion.div 
            className="absolute inset-0 opacity-30"
            initial={{ backgroundPosition: "0% 0%" }}
            animate={{ 
              backgroundPosition: ["0% 0%", "100% 100%"],
              transition: { duration: 10, repeat: Infinity, repeatType: "mirror" }
            }}
            style={{
              backgroundImage: "radial-gradient(circle at 50% 50%, rgba(255,255,255,0.8) 0%, transparent 40%)",
              backgroundSize: "150% 150%"
            }}
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <motion.div
              initial={{ scale: 0.8, opacity: 0.5 }}
              animate={{ 
                scale: [0.8, 1.1, 0.8],
                opacity: [0.5, 1, 0.5]
              }}
              transition={{ 
                duration: 3,
                repeat: Infinity,
                repeatType: "mirror" 
              }}
            >
              <Shield className="h-10 w-10 text-white" />
            </motion.div>
          </div>
        </div>

        {/* Drive Info Card */}
        <GhostGlass
          rounded="xl"
          opacity="low"
          className="p-4"
        >
          <div className="flex items-center space-x-3">
            <div className="p-2 rounded-xl bg-[hsl(var(--ghost-accent))/0.2]">
              <Lock className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
            </div>
            <div>
              <h3 className="font-medium text-[hsl(var(--ghost-foreground))]">
                {drive.name}
              </h3>
              <p className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
                {t('drives.encryption_type', { type: drive.encryption_tier }) as React.ReactNode}
              </p>
            </div>
          </div>
        </GhostGlass>

        <AnimatePresence mode="wait">
          {unlockSuccess ? (
            // Success State
            <motion.div
              key="success"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="py-8 text-center space-y-4"
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 15
                }}
                className="mx-auto w-16 h-16 rounded-full bg-[hsl(var(--ghost-accent-secondary))/0.2] 
                         flex items-center justify-center"
              >
                <CheckCircle2 className="h-8 w-8 text-[hsl(var(--ghost-accent-secondary))]" />
              </motion.div>
              
              <div className="space-y-2">
                <GhostGradientText
                  variant="secondary"
                  className="text-lg font-medium"
                >
                  {t('drives.status.unlocked') as React.ReactNode}
                </GhostGradientText>
                <p className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
                  {`${drive.name} ${t('encrypted_drive.unlock.success_message') || 'is now unlocked'}`}
                </p>
              </div>

              {redirecting && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="flex items-center justify-center space-x-2 text-[hsl(var(--ghost-accent))]"
                >
                  <CircularProgress 
                    percentage={100} 
                    size={16} 
                    strokeWidth={3}
                    color="currentColor"
                  />
                  <p className="text-sm">
                    {t('create.success.start_button') as React.ReactNode}
                  </p>
                </motion.div>
              )}
            </motion.div>
          ) : (
            // Password Form
            <motion.form
              key="form"
              onSubmit={handleSubmit}
              className="space-y-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="space-y-2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-[hsl(var(--ghost-foreground))]"
                >
                  {t('create.password.title') as React.ReactNode}
                </label>
                
                <div className="relative">
                  <input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError('');
                    }}
                    className={cn(
                      "w-full px-4 py-2.5 rounded-lg",
                      "bg-[hsl(var(--ghost-surface))]",
                      "border border-[hsl(var(--ghost-accent))/0.2]",
                      "text-[hsl(var(--ghost-foreground))]",
                      "placeholder:text-[hsl(var(--ghost-foreground-muted))]",
                      "focus:outline-none focus:border-[hsl(var(--ghost-accent))]",
                      "pr-10",
                      error && "border-[hsl(var(--ghost-accent-tertiary))]",
                      "transition-colors duration-200"
                    )}
                    placeholder={t('create.password.password_placeholder') as string}
                    disabled={isVerifying}
                    autoFocus
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-[hsl(var(--ghost-foreground-muted))] 
                              hover:text-[hsl(var(--ghost-foreground))]
                              transition-colors duration-200"
                  >
                    <AnimatePresence mode="wait">
                      {showPassword ? (
                        <motion.div
                          key="eye-off"
                          initial={{ opacity: 0, rotate: -90 }}
                          animate={{ opacity: 1, rotate: 0 }}
                          exit={{ opacity: 0, rotate: 90 }}
                        >
                          <EyeOff className="h-4 w-4" />
                        </motion.div>
                      ) : (
                        <motion.div
                          key="eye"
                          initial={{ opacity: 0, rotate: -90 }}
                          animate={{ opacity: 1, rotate: 0 }}
                          exit={{ opacity: 0, rotate: 90 }}
                        >
                          <Eye className="h-4 w-4" />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </button>
                </div>

                {/* Error Message */}
                <AnimatePresence mode="wait">
                  {error && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="flex items-center space-x-2 text-[hsl(var(--ghost-accent-tertiary))]"
                    >
                      <AlertTriangle className="h-4 w-4" />
                      <p className="text-sm">{error}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Action Buttons */}
              <div className="flex justify-end space-x-3 pt-4">
                <button
                  type="button"
                  onClick={() => onOpenChange(false)}
                  disabled={isVerifying}
                  className={cn(
                    "px-4 py-2 rounded-lg",
                    "bg-[hsl(var(--ghost-surface))]",
                    "border border-[hsl(var(--ghost-accent))/0.2]",
                    "text-[hsl(var(--ghost-foreground))]",
                    "hover:bg-[hsl(var(--ghost-accent))/0.05]",
                    "transition-all duration-200",
                    isVerifying && "opacity-50 cursor-not-allowed"
                  )}
                >
                  {t('common.cancel') as React.ReactNode}
                </button>
                
                <button
                  type="submit"
                  disabled={isVerifying || !password.trim()}
                  className={cn(
                    "px-4 py-2 rounded-lg",
                    "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
                    "text-white",
                    "hover:shadow-lg hover:shadow-[hsl(var(--ghost-accent))/0.3]",
                    "transition-all duration-200",
                    "flex items-center space-x-2",
                    (isVerifying || !password.trim()) && "opacity-50 cursor-not-allowed"
                  )}
                >
                  <AnimatePresence mode="wait">
                    {isVerifying ? (
                      <motion.div
                        key="unlocking"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="flex items-center space-x-2"
                      >
                        <CircularProgress 
                          percentage={100} 
                          size={16} 
                          strokeWidth={3}
                          color="currentColor"
                        />
                        <span>{t('common.creating') as React.ReactNode}</span>
                      </motion.div>
                    ) : (
                      <motion.div
                        key="unlock"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="flex items-center space-x-2"
                      >
                        <Key className="h-4 w-4" />
                        <span>{t('security.two_factor.actions.verify') as React.ReactNode}</span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </button>
              </div>
            </motion.form>
          )}
        </AnimatePresence>

        {/* Recovery Link - Added this section for "Forgot Password" */}
        {/* Recovery Link - Should always be visible */}
        {!unlockSuccess && (
        <div className="text-center border-t border-[hsl(var(--ghost-accent))/0.1] pt-4 mt-4">
            <button
            type="button"
            onClick={handleRecovery}
            className="text-sm text-[hsl(var(--ghost-accent))] hover:text-[hsl(var(--ghost-accent-secondary))] 
                    transition-colors duration-200"
            >
            {t('create.recovery.title') as React.ReactNode}
            </button>
        </div>
        )}
      </div>
    </GhostDialog>
  );
};