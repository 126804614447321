/**
 * @file src/layouts/ghost/components/GhostMobileMenu.tsx
 * @description Refined mobile menu with elegant styling, improved spacing, and visual hierarchy
 * @version 2.2.0
 * @updated 2025-03-29
 */

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  Settings, Shield, User, CreditCard, HelpCircle, LogOut, 
  ChevronRight, X, Plus, Globe, Sun, Moon
} from 'lucide-react';
import { GhostGlass } from '@/app/ghost/components/common/GhostGlass';
import { GhostGradientText } from '@/app/ghost/components/common/GhostGradients';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { useAuth } from '@/context/AuthContext';
import { logoutAndClearCaches } from '@/utils/logout-utils';
import { useGhostTheme } from '@/app/ghost/providers/GhostThemeProvider';
import { useAccount } from '@/context/AccountContext';

interface MenuItemProps {
  icon: React.ElementType;
  label: string;
  onClick?: () => void;
  badge?: string | number;
  isDestructive?: boolean;
  isAction?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ 
  icon: Icon, 
  label, 
  onClick,
  badge,
  isDestructive,
  isAction
}) => (
  <motion.button
    whileTap={{ scale: 0.98 }}
    whileHover={{ x: isAction ? 0 : 2 }}
    onClick={onClick}
    className={cn(
      "w-full flex items-center justify-between",
      "px-5 py-3 rounded-xl my-0.5",
      "transition-all duration-200",
      isAction 
        ? "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))] text-white shadow-md"
        : isDestructive
          ? "hover:bg-[hsl(var(--ghost-accent-tertiary))/0.08] text-[hsl(var(--ghost-accent-tertiary))]"
          : "hover:bg-[hsl(var(--ghost-accent))/0.08] hover:shadow-sm"
    )}
  >
    <div className="flex items-center space-x-3.5">
      <div className={cn(
        "flex items-center justify-center",
        "w-8 h-8 rounded-lg",
        isAction
          ? "bg-white/20"
          : isDestructive
            ? "bg-[hsl(var(--ghost-accent-tertiary))/0.12]"
            : "bg-[hsl(var(--ghost-accent))/0.12]"
      )}>
        <Icon className={cn(
          "h-5 w-5",
          isAction
            ? "text-white"
            : isDestructive
              ? "text-[hsl(var(--ghost-accent-tertiary))]"
              : "text-[hsl(var(--ghost-accent))]"
        )} />
      </div>
      <span className="text-sm font-medium">{label}</span>
    </div>
    <div className="flex items-center space-x-3">
      {badge && (
        <span className={cn(
          "px-2.5 py-1 text-xs rounded-full",
          "font-medium min-w-[24px] text-center inline-block",
          isAction 
            ? "bg-white/20 text-white"
            : "bg-[hsl(var(--ghost-accent))/0.15] text-[hsl(var(--ghost-accent))]",
          "border",
          isAction
            ? "border-white/10"
            : "border-[hsl(var(--ghost-accent))/0.2]"
        )}>
          {badge}
        </span>
      )}
      {!isAction && (
        <ChevronRight className={cn(
          "h-4 w-4",
          isDestructive
            ? "text-[hsl(var(--ghost-accent-tertiary))/0.6]"
            : "text-[hsl(var(--ghost-accent))/0.6]"
        )} />
      )}
    </div>
  </motion.button>
);

interface MenuSectionProps {
  children: React.ReactNode;
  hasDivider?: boolean;
}

const MenuSection: React.FC<MenuSectionProps> = ({ children, hasDivider = true }) => (
  <div className={cn(
    "py-1",
    hasDivider && [
      "relative after:absolute after:bottom-0 after:left-6 after:right-6",
      "after:h-px after:bg-gradient-to-r",
      "after:from-transparent after:via-[hsl(var(--ghost-accent))/0.15]",
      "after:to-transparent after:rounded-full"
    ]
  )}>
    {children}
  </div>
);

interface GhostMobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GhostMobileMenu: React.FC<GhostMobileMenuProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { t } = useGhostTranslation();
  const { logout } = useAuth();
  const { theme, setTheme } = useGhostTheme();
  const { accountInfo } = useAccount();

  const userEmail = accountInfo?.email || 'demo@ghost.app';
  // Use a straightforward approach to avoid type issues
  let planType = String(t('account.free_plan', 'Free Plan'));
  
  if (accountInfo?.subscription?.plan && 
      typeof accountInfo.subscription.plan === 'object' && 
      'name' in accountInfo.subscription.plan) {
    const planName = accountInfo.subscription.plan.name;
    if (planName && typeof planName === 'string') {
      planType = planName;
    }
  }

  logger.debug('Rendering GhostMobileMenu', {
    component: 'GhostMobileMenu',
    theme,
    isOpen
  });

  const handleNavigation = (path: string) => {
    navigate(path);
    onClose();
  };
  
  const handleLogout = async () => {
    try {
      logger.debug('Logout clicked from Ghost mobile menu');
      
      // First close the menu
      onClose();
      
      // Then handle the full logout process - force Ghost UI
      await logoutAndClearCaches(logout, navigate, true);
    } catch (error) {
      logger.error('Error during logout from Ghost mobile menu:', {
        component: 'GhostMobileMenu',
        error
      });
      // Fallback - try direct navigation to Ghost signin
      navigate('/ghost/auth/signin');
    }
  };

  const toggleTheme = () => {
    const newTheme = theme === 'night' ? 'day' : 'night';
    setTheme(newTheme);
    logger.debug('Theme toggled', {
      component: 'GhostMobileMenu',
      oldTheme: theme,
      newTheme
    });
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ type: "spring", damping: 30, stiffness: 300 }}
          className={cn(
            "fixed inset-y-0 right-0 z-50",
            "w-[320px] bg-[hsl(var(--ghost-bg))]",
            "border-l border-[hsl(var(--ghost-accent))/0.1]",
            "overflow-hidden shadow-xl",
            "backdrop-blur-md"
          )}
        >
          <div className="flex flex-col h-full">
            {/* Header with Close Button */}
            <GhostGlass
              className={cn(
                "p-6 relative",
                "border-b-0",
                "after:absolute after:bottom-0 after:left-6 after:right-6",
                "after:h-px after:bg-gradient-to-r",
                "after:from-transparent after:via-[hsl(var(--ghost-accent))/0.2]",
                "after:to-transparent after:rounded-full"
              )}
              opacity="low"
            >
              {/* Add explicit close button */}
              <motion.button
                whileTap={{ scale: 0.9 }}
                onClick={onClose}
                className="absolute top-5 right-5 w-7 h-7 rounded-full flex items-center justify-center bg-[hsl(var(--ghost-accent))/0.12] backdrop-blur-md"
              >
                <X className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
              </motion.button>
              
              <div className="flex items-center space-x-4">
                <div className={cn(
                  "h-14 w-14 rounded-2xl",
                  "flex items-center justify-center",
                  "bg-gradient-to-br from-[hsl(var(--ghost-accent))/0.2] to-[hsl(var(--ghost-accent-secondary))/0.15]",
                  "border border-[hsl(var(--ghost-accent))/0.2]",
                  "shadow-inner backdrop-blur-sm"
                )}>
                  <Shield className="h-7 w-7 text-[hsl(var(--ghost-accent))]" />
                </div>
                <div className="flex-1">
                  <GhostGradientText className="text-lg font-bold">
                    {userEmail}
                  </GhostGradientText>
                  <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                    {planType}
                  </p>
                </div>
              </div>
            </GhostGlass>

            {/* Menu Sections */}
            <div className="flex-1 overflow-y-auto px-2">
              {/* Primary Actions Section */}
              <MenuSection>
                <div className="px-3 py-1 space-y-3">
                  <MenuItem 
                    icon={Plus} 
                    label={String(t('core.create_drive', 'Create Nygma Drive'))}
                    onClick={() => handleNavigation('/ghost/create')}
                    isAction
                  />
                  
                  <motion.button
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleNavigation('/ghost/upgrade')}
                    className={cn(
                      "w-full flex items-center justify-between",
                      "px-5 py-3 rounded-xl",
                      "transition-all duration-200",
                      "border border-[hsl(var(--ghost-accent))/0.3]",
                      "bg-[hsl(var(--ghost-accent))/0.08]"
                    )}
                  >
                    <div className="flex items-center space-x-3.5">
                      <div className={cn(
                        "flex items-center justify-center",
                        "w-8 h-8 rounded-lg",
                        "bg-[hsl(var(--ghost-accent))/0.15]"
                      )}>
                        <Shield className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
                      </div>
                      <span className="text-sm font-medium">{String(t('account.upgrade', 'Upgrade'))}</span>
                    </div>
                    <ChevronRight className="h-4 w-4 text-[hsl(var(--ghost-accent))/0.6]" />
                  </motion.button>
                </div>
              </MenuSection>

              {/* Account Section */}
              <MenuSection>
                <MenuItem 
                  icon={User} 
                  label={String(t('account.profile', 'Profile'))}
                  onClick={() => handleNavigation('/ghost/profile')}
                />
                <MenuItem 
                  icon={Settings} 
                  label={String(t('account.settings', 'Settings'))}
                  onClick={() => handleNavigation('/ghost/settings')}
                />
                <MenuItem 
                  icon={CreditCard} 
                  label={String(t('account.billing', 'Billing'))}
                  onClick={() => handleNavigation('/ghost/billing')}
                />
              </MenuSection>

              {/* Preferences Section */}
              <MenuSection>
                <MenuItem 
                  icon={theme === 'night' ? Sun : Moon} 
                  label={String(t('settings.theme', 'Theme'))}
                  onClick={toggleTheme}
                />
                <MenuItem 
                  icon={Globe} 
                  label={String(t('settings.language', 'Language'))}
                  onClick={() => {
                    // Close the menu first
                    onClose();
                    // Open language dialog is implemented in the parent component
                    navigate('/ghost/settings?openLanguage=true');
                  }}
                />
              </MenuSection>

              {/* Help & Logout Section */}
              <MenuSection hasDivider={false}>
                <MenuItem 
                  icon={HelpCircle} 
                  label={String(t('account.help', 'Help & Support'))}
                  onClick={() => handleNavigation('/ghost/help')}
                />
                <MenuItem
                  icon={LogOut}
                  label={String(t('account.logout', 'Sign Out'))}
                  isDestructive
                  onClick={handleLogout}
                />
              </MenuSection>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GhostMobileMenu;