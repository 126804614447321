/**
 * @file src/context/cloud-drive/hooks/useDriveState.ts
 * @description State management hook for cloud drive with RootDrive integration
 * @version 2.1.0
 * @updated 2025-03-19
 */

import { useState, useCallback, useEffect, useRef } from 'react';
import type { 
  ViewMode, 
  ViewDisplayType,
  SortConfig, 
  FilterConfig, 
  DriveItem,
  BreadcrumbItem 
} from '../types';
import { defaultContextValue } from '../types';
import { logger } from '@/utils/logger';

export const useDriveState = () => {
  // Basic state
  const [currentFolder, setCurrentFolder] = useState<string | null>(defaultContextValue.currentFolder || null);
  const [currentFolderContentsState, setCurrentFolderContentsState] = useState<DriveItem[]>(defaultContextValue.currentFolderContents || []);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>(defaultContextValue.breadcrumbs || []);
  
  // View state
  const [viewMode, setViewMode] = useState<ViewMode>(defaultContextValue.viewMode || 'grid');
  const [viewDisplay, setViewDisplay] = useState<ViewDisplayType>(defaultContextValue.viewDisplay || 'grid');
  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultContextValue.sortConfig || { field: 'name', order: 'asc' });
  const [filterConfig, setFilterConfig] = useState<FilterConfig>(defaultContextValue.filterConfig || {});
  
  // Selection state
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultContextValue.selectedItems || []);
  const [selectedFile, setSelectedFile] = useState<string | null>(defaultContextValue.selectedFile || null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(defaultContextValue.selectedItemId || null);
  
  // UI state
  const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(defaultContextValue.isFolderDialogOpen || false);
  const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(defaultContextValue.isInfoPanelOpen || false);

  // Ref to track previous viewDisplay value
  const prevViewDisplayRef = useRef<ViewDisplayType | null>(null);

  // Track view display changes less aggressively
  // and use a ref to prevent repeated effect runs
  const viewDisplayLoggerRun = useRef(false);
  
  useEffect(() => {
    // Only log on first mount or actual changes, not on every re-render
    if (!viewDisplayLoggerRun.current || prevViewDisplayRef.current !== viewDisplay) {
      // Mark logger as having run
      viewDisplayLoggerRun.current = true;
      
      // Only log if this is an actual change and not the initial setting
      if (prevViewDisplayRef.current !== null) {
        logger.debug('View display changed', {
          component: 'useDriveState',
          data: { 
            viewDisplay,
            previousViewDisplay: prevViewDisplayRef.current
          }
        });
      }
      
      // Update the ref
      prevViewDisplayRef.current = viewDisplay;
    }
  }, [viewDisplay]);

  // Enhanced setter for viewDisplay to prevent unnecessary updates
  const enhancedSetViewDisplay = useCallback((newValue: ViewDisplayType) => {
    if (newValue !== viewDisplay) {
      setViewDisplay(newValue);
    }
  }, [viewDisplay]);

  // Optimized setCurrentFolderContents
  const setCurrentFolderContents = useCallback((
    newContents: DriveItem[] | ((prev: DriveItem[]) => DriveItem[])
  ) => {
    logger.debug('Updating folder contents', {
      component: 'useDriveState',
      data: {
        contentCount: typeof newContents === 'function' 
          ? 'function update'
          : newContents.length
      }
    });

    const updateContents = (contents: DriveItem[]) => {
      // Remove duplicates while preserving order
      return Array.from(
        new Map(contents.map((item: any) => [item.id, item])).values()
      );
    };

    setCurrentFolderContentsState(prev => {
      const nextContents = typeof newContents === 'function'
        ? newContents(prev)
        : newContents;
      
      return updateContents(nextContents);
    });
  }, []);

  // Selected item computation
  const selectedItem = currentFolderContentsState.find((item: any) => item.id === selectedItemId) || null;

  // Clear selection
  const clearSelection = useCallback(() => {
    setSelectedItems([]);
    setSelectedItemId(null);
  }, []);

  // Toggle item selection
  const toggleItemSelection = useCallback((itemId: string) => {
    setSelectedItems(prev => 
      prev.includes(itemId) 
        ? prev.filter((id: any) => id !== itemId)
        : [...prev, itemId]
    );
  }, []);

  // Dialog controls
  const openFolderDialog = useCallback(() => {
    setIsFolderDialogOpen(true);
  }, []);

  const closeFolderDialog = useCallback(() => {
    setIsFolderDialogOpen(false);
  }, []);

  return {
    // State
    currentFolder,
    setCurrentFolder,
    currentFolderContents: currentFolderContentsState,
    setCurrentFolderContents,
    breadcrumbs,
    setBreadcrumbs,
    viewMode,
    viewDisplay,
    setViewDisplay: enhancedSetViewDisplay, // Use enhanced setter
    setViewMode,
    sortConfig,
    setSortConfig,
    filterConfig,
    setFilterConfig,
    selectedItems,
    setSelectedItems,
    selectedFile,
    setSelectedFile,
    selectedItemId,
    setSelectedItemId,
    selectedItem,
    isFolderDialogOpen,
    setIsFolderDialogOpen,
    isInfoPanelOpen,
    setIsInfoPanelOpen,

    // Actions
    clearSelection,
    toggleItemSelection,
    openFolderDialog,
    closeFolderDialog,
  };
};