/**
 * @file src/services/sharing.service.ts
 * @description Service for share management with encryption support
 * @version 1.0.1
 */

import { authService } from './auth.service';
import { logger } from '@/utils/logger';

import type { DriveItem } from '@/types/cloud-drive.types';
import { getAuthHeaders as apiAuthHeaders } from '@/utils/api-helpers';

interface ShareSettings {
  shareType: 'private' | 'public' | 'password' | 'domain';
  permission: 'view' | 'download' | 'edit' | 'manage';
  expirationDate?: string; // ISO date string
  password?: string;
  accessLimit?: number;
  allowedDomains?: string[];
}

interface ShareResponse {
  id: string;
  itemId: string;
  shareKey: string;
  shareType: string;
  permission: string;
  expiresAt?: string;
  createdAt: string;
  lastAccessedAt?: string;
  currentAccessCount: number;
  maxAccessCount?: number;
  isActive: boolean;
  requiresPassword: boolean;
  shareUrl: string;
  is_encrypted?: boolean;
  encryption_tier?: string;
}

interface SharesListResponse {
  items: Array<{
    id: string;
    item_id: string;
    item_name: string;
    item_type: string;
    created_by: string;
    share_type: string;
    permission: string;
    share_key: string;
    requires_password: boolean;
    max_access_count?: number;
    current_access_count: number;
    expires_at?: string;
    created_at: string;
    last_accessed_at?: string;
    is_active: boolean;
    is_encrypted: boolean;
    encryption_tier?: string;
    is_owner: boolean;
  }>;
}

const BASE_URL = '/api/v1/shares';

// Helper to get auth headers
const getAuthHeaders = () => {
  try {
    const token = authService.getStoredToken();
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token || ''
    };
  } catch (error) {
    logger.error('Error getting auth headers:', error);
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }
};

export const sharingService = {
  /**
   * Create a share for a file or folder
   */
  createShare: async (
    item: DriveItem, 
    settings: ShareSettings
  ): Promise<ShareResponse> => {
    try {
      logger.debug('Creating share', {
        component: 'sharingService',
        data: { 
          itemId: item.id, 
          itemType: item.type,
          shareType: settings.shareType,
          permission: settings.permission,
          isEncrypted: (item as any).isEncrypted
        }
      });

      const response = await fetch(`${BASE_URL}`, {
        method: 'POST',
        headers: apiAuthHeaders() as HeadersInit as HeadersInit as HeadersInit as HeadersInit,
        body: JSON.stringify({
          item_id: item.id,
          share_type: settings.shareType,
          permission: settings.permission,
          expires_at: settings.expirationDate,
          password: settings.password,
          max_access_count: settings.accessLimit,
          allowed_domains: settings.allowedDomains
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('Share creation error:', errorText);
        throw new Error(errorText);
      }

      const data = await response.json();
      
      // Format share URL
      const shareUrl = `${window.location.origin}/s/${data.share_key}`;
      
      return {
        ...data,
        shareUrl
      };
    } catch (error) {
      logger.error('Error creating share:', error);
      throw error;
    }
  },

  /**
   * Get details for a share
   */
  getShareDetails: async (shareId: string): Promise<ShareResponse> => {
    try {
      const response = await fetch(`${BASE_URL}/${shareId}`, {
        headers: apiAuthHeaders() as HeadersInit as HeadersInit as HeadersInit as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('Share details error:', errorText);
        throw new Error(errorText);
      }

      const data = await response.json();
      
      // Format share URL
      const shareUrl = `${window.location.origin}/s/${data.share_key}`;
      
      return {
        ...data,
        shareUrl
      };
    } catch (error) {
      logger.error('Error getting share details:', error);
      throw error;
    }
  },

  /**
   * Delete a share
   */
  deleteShare: async (shareId: string): Promise<void> => {
    try {
      const response = await fetch(`${BASE_URL}/${shareId}`, {
        method: 'DELETE',
        headers: apiAuthHeaders() as HeadersInit as HeadersInit as HeadersInit as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('Share deletion error:', errorText);
        throw new Error(errorText);
      }
    } catch (error) {
      logger.error('Error deleting share:', error);
      throw error;
    }
  },

  /**
   * List all shares for the current user
   */// Update listShares method in sharing.service.ts
listShares: async () => {
    try {
      // FIXED: Changed from /my-shares to /by-user/me
      const response = await fetch(`${BASE_URL}/by-user/me`, {
        headers: apiAuthHeaders() as HeadersInit as HeadersInit as HeadersInit as HeadersInit,
        credentials: 'include'
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        logger.error('List shares error:', errorText);
        throw new Error(errorText);
      }
  
      const data = await response.json();
      
      // Log the entire response for debugging
      logger.debug('Shares list response', {
        component: 'sharingService',
        data: {
          response: data
        }
      });
      
      // Make sure data.items exists and is an array before proceeding
      if (!data || !data.items || !Array.isArray(data.items)) {
        logger.error('Unexpected response format from server', {
          component: 'sharingService',
          data: typeof data === 'object' ? Object.keys(data) : typeof data
        });
        return { items: [] }; // Return empty items array as fallback
      }
      
      // Format share URLs and map response to expected format
      const formattedItems = data.items.map((share: any) => {
        // Generate share URL based on whether it's encrypted
        let shareUrl = `${window.location.origin}/s/${share.share_key}`;
        
        // For encrypted shares, include encryption key in the URL hash
        if (share.is_encrypted && share.encryption_key) {
          shareUrl += `#${encodeURIComponent(share.encryption_key)}`;
        }
        
        return {
          id: share.id,
          itemId: share.item_id,
          item_id: share.item_id,
          item_name: share.item_name,
          item_type: share.item_type,
          created_by: share.created_by,
          created_by_name: share.created_by, // For display
          shareKey: share.share_key,
          share_key: share.share_key,
          shareType: share.share_type,
          share_type: share.share_type,
          permission: share.permission,
          requiresPassword: share.requires_password,
          requires_password: share.requires_password,
          maxAccessCount: share.max_access_count,
          max_access_count: share.max_access_count,
          currentAccessCount: share.current_access_count,
          current_access_count: share.current_access_count,
          expiresAt: share.expires_at,
          expires_at: share.expires_at,
          createdAt: share.created_at,
          created_at: share.created_at,
          lastAccessedAt: share.last_accessed_at,
          last_accessed_at: share.last_accessed_at,
          isActive: share.is_active,
          is_active: share.is_active,
          is_encrypted: share.is_encrypted,
          isEncrypted: share.is_encrypted,
          encryption_tier: share.encryption_tier,
          encryption_key: share.encryption_key, // Store the encryption key for later use
          is_owner: share.is_owner,
          shareUrl: shareUrl
        };
      });
      
      logger.debug('Shares list processed successfully', {
        component: 'sharingService',
        data: { 
          count: formattedItems.length
        }
      });
      
      return { items: formattedItems };
    } catch (error) {
      logger.error('Error listing shares:', error);
      // Return an empty array instead of throwing to make the component more resilient
      return { items: [] };
    }
  },

  /**
   * List shares for a specific item
   */
  getItemShares: async (itemId: string): Promise<ShareResponse[]> => {
    try {
      // FIXED: Changed from /item/{itemId} to /by-item/{itemId}
      const response = await fetch(`${BASE_URL}/by-item/${itemId}`, {
        headers: apiAuthHeaders() as HeadersInit as HeadersInit as HeadersInit as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('Item shares error:', errorText);
        throw new Error(errorText);
      }

      const data = await response.json() as SharesListResponse;
      
      // Format share URLs
      return data.items.map((share: any) => ({
        id: share.id,
        itemId: share.item_id,
        shareKey: share.share_key,
        shareType: share.share_type,
        permission: share.permission,
        requiresPassword: share.requires_password,
        maxAccessCount: share.max_access_count,
        currentAccessCount: share.current_access_count,
        expiresAt: share.expires_at,
        createdAt: share.created_at,
        lastAccessedAt: share.last_accessed_at,
        isActive: share.is_active,
        is_encrypted: share.is_encrypted,
        encryption_tier: share.encryption_tier,
        shareUrl: `${window.location.origin}/s/${share.share_key}`
      }));
    } catch (error) {
      logger.error('Error getting item shares:', error);
      throw error;
    }
  }
};