/**
 * @file src/app/ghost/components/language/CircleFlag.tsx
 * @description Circular flag component with better visual representation
 * @version 1.0.0
 */

import React from 'react';
import { cn } from '@/utils/utils';

// Mapping of language codes to country codes for flags
const languageToCountry = (language: string): string => {
  // Basic mapping
  const defaultMap: Record<string, string> = {
    en: 'gb', // Default English to GB
    fr: 'fr',
    de: 'de',
    es: 'es',
    it: 'it',
    ja: 'jp',
    zh: 'cn',
    ko: 'kr',
    pt: 'pt',
    nl: 'nl',
  };
  
  // Handle special cases for English variants
  if (language.startsWith('en')) {
    // Check browser locale to determine country for English
    const browserLocale = navigator.language || 'en-US';
    
    if (browserLocale === 'en-US' || browserLocale.startsWith('en-US')) {
      return 'us'; // Show US flag for US English
    }
    if (browserLocale === 'en-CA' || browserLocale.startsWith('en-CA')) {
      return 'ca'; // Show Canadian flag for Canadian English
    }
    if (browserLocale === 'en-AU' || browserLocale.startsWith('en-AU')) {
      return 'au'; // Show Australian flag for Australian English
    }
    // Could extend with more variants (en-NZ, en-IE, etc.)
  }
  
  return defaultMap[language] || 'unknown';
};

interface CircleFlagProps {
  language: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const CircleFlag: React.FC<CircleFlagProps> = ({
  language,
  size = 'medium',
  className,
}) => {
  const sizeMap = {
    small: 24,
    medium: 32,
    large: 48,
  };
  
  const pixelSize = sizeMap[size];
  
  const countryCode = languageToCountry(language);
  
  // Flag design with both country code and background color
  const getFlag = (country: string) => {
    switch (country) {
      case 'gb': 
        return { color1: '#012169', color2: '#C8102E', abbr: 'GB' }; // UK
      case 'us': 
        return { color1: '#3C3B6E', color2: '#B22234', abbr: 'US' }; // USA
      case 'ca': 
        return { color1: '#FF0000', color2: '#FFFFFF', abbr: 'CA' }; // Canada
      case 'au': 
        return { color1: '#012169', color2: '#FF0000', abbr: 'AU' }; // Australia
      case 'fr': 
        return { color1: '#0055A4', color2: '#EF4135', abbr: 'FR' }; // France
      case 'de': 
        return { color1: '#000000', color2: '#DD0000', abbr: 'DE' }; // Germany
      case 'es': 
        return { color1: '#C60B1E', color2: '#FFC400', abbr: 'ES' }; // Spain
      case 'it': 
        return { color1: '#008C45', color2: '#CD212A', abbr: 'IT' }; // Italy
      case 'jp': 
        return { color1: '#FFFFFF', color2: '#BC002D', abbr: 'JP' }; // Japan
      case 'cn': 
        return { color1: '#DE2910', color2: '#FFDE00', abbr: 'CN' }; // China
      case 'kr': 
        return { color1: '#FFFFFF', color2: '#0047A0', abbr: 'KR' }; // South Korea
      case 'pt': 
        return { color1: '#006600', color2: '#FF0000', abbr: 'PT' }; // Portugal
      case 'nl': 
        return { color1: '#AE1C28', color2: '#21468B', abbr: 'NL' }; // Netherlands
      default:
        return { color1: '#4B4B4B', color2: '#8D8D8D', abbr: '?' };
    }
  };
  
  const { color1, color2, abbr } = getFlag(countryCode);
  
  return (
    <div 
      className={cn(
        "relative rounded-full overflow-hidden flex-shrink-0",
        className
      )}
      style={{
        width: pixelSize,
        height: pixelSize,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(135deg, ${color1} 0%, ${color1} 50%, ${color2} 50%, ${color2} 100%)`
      }}
    >
      <span 
        style={{
          color: '#FFFFFF',
          fontSize: pixelSize * 0.4,
          fontWeight: 'bold',
          textShadow: '0px 1px 2px rgba(0,0,0,0.5)',
          letterSpacing: '-0.5px',
          textAlign: 'center',
          lineHeight: 1
        }}
      >
        {abbr}
      </span>
    </div>
  );
};

export default CircleFlag;