/**
 * @file src/lib/utils.ts
 * @description Utility functions for the application
 * @version 1.0.0
 * @created 2024-11-23
 */

import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { format, formatDistanceToNow } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import i18next from 'i18next';


const locales = {
  en: enUS,
  ja: ja
};

export function formatDate(date: Date | string | number, formatStr: string = 'PPP'): string {
  const currentLocale = i18next.language;
  return format(new Date(date), formatStr, {
    locale: locales[currentLocale as keyof typeof locales] || enUS
  });
}

export function formatRelativeTime(date: Date | string | number): string {
  const currentLocale = i18next.language;
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: locales[currentLocale as keyof typeof locales] || enUS
  });
}


/**
 * Combines class names using clsx and tailwind-merge
 * This is useful for combining Tailwind classes with dynamic classes
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Formats bytes into human readable string
 */
export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
}

/**
 * Delays execution for specified time
 */
export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Debounces a function
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout;

  return function executedFunction(...args: Parameters<T>) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

/**
 * Checks if a value is a valid number
 */
export function isNumeric(value: any): boolean {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

/**
 * Generates a random string
 */
export function generateId(length: number = 8): string {
  return Math.random().toString(36).substring(2, length + 2);
}



/**
 * Truncates a string to a specified length
 */
export function truncateString(str: string, length: number = 50): string {
  if (str?.length <= length) return str;
  return str.slice(0, length) + '...';
}

/**
 * Validates an email address
 */
export function isValidEmail(email: string): boolean {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

/**
 * Converts a string to title case
 */
export function toTitleCase(str: string): string {
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );
}

/**
 * Deep clones an object
 */
export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Formats seconds into a human-readable time remaining string
 * @param seconds Time in seconds
 */
export function formatTimeRemaining(seconds: number): string {
  if (!isFinite(seconds) || seconds < 0) return 'Unknown';

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m remaining`;
  } else if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s remaining`;
  } else {
    return `${remainingSeconds}s remaining`;
  }
}

export function formatPercentage(value: number, decimals: number = 1): string {
  if (!isFinite(value) || isNaN(value)) return '0%';
  return (value * 100).toFixed(decimals) + '%';
}

/**
 * Simple navigation helper for use in callbacks and utility functions
 * Will use window.history API with fallback to direct location change
 */
export function navigate(path: string): void {
  try {
    // Try to use history API first
    window.history.pushState({}, '', path);
    // Dispatch a popstate event to trigger route updates
    window.dispatchEvent(new PopStateEvent('popstate', { state: {} }));
    
    // If that doesn't work (no route update), fall back to direct navigation
    setTimeout(() => {
      const currentPath = window.location.pathname;
      if (currentPath !== path) {
        window.location.href = path;
      }
    }, 100);
  } catch (error) {
    console.error('Navigation error:', error);
    window.location.href = path;
  }
}