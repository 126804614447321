/**
 * @file src/providers/QueryProvider.tsx
 * @description React Query provider setup with default options
 * @version 1.1.0
 * @created 2024-11-30
 * @updated 2025-03-21
 * @dependencies @tanstack/react-query
 */

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { registerQueryClient } from '@/utils/logout-utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

export function QueryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Register query client for logout utility and handle auth refreshes
  useEffect(() => {
    registerQueryClient(queryClient);
    
    // Make query client available globally for pre-fetching from auth context
    window.__REACT_QUERY_GLOBAL_CLIENT__ = queryClient;
    
    // Check for auth_refresh parameter to clear all query cache
    const authRefresh = new URLSearchParams(window.location.search).get("auth_refresh") === "true";
    if (authRefresh) {
      // Clear all query cache when auth_refresh is detected
      queryClient.clear();
      console.log("Query cache cleared due to auth_refresh parameter");
    }
  }, []);
  
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}