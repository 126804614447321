/**
 * @file src/components/encrypted-drive/dialogs/steps/StepConfirmation.tsx
 * @description Final confirmation step after drive creation with enhanced animations
 * @version 1.1.0
 */

import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Shield, Check, Lock, CloudLightning } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { cn } from '@/utils/utils';

// Define the missing type
interface CreateDriveFormData {
  name: string;
  encryptionTier: 'standard' | 'advanced' | 'enterprise';
  password: string;
  confirmPassword: string;
  recoveryKey?: string;
}


interface StepConfirmationProps {
  formData: CreateDriveFormData;
  onClose: () => void;
}

export const StepConfirmation: React.FC<StepConfirmationProps> = ({
  formData,
  onClose,
}) => {
  const { t } = useTranslation();
  const controls = useAnimation();

  // Sequence the animations
  useEffect(() => {
    const sequence = async () => {
      await controls.start('visible');
    };
    sequence();
  }, [controls]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  // Success check animation
  const checkVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20
      }
    }
  };

  // Feature list with icons and descriptions
  const features = [
    {
      icon: Lock,
      key: 'zero_knowledge',
      color: 'text-blue-500 dark:text-blue-400'
    },
    {
      icon: Shield,
      key: 'secure_storage',
      color: 'text-green-500 dark:text-green-400'
    },
    {
      icon: CloudLightning,
      key: 'recovery_option',
      color: 'text-purple-500 dark:text-purple-400'
    }
  ];

  return (
    <motion.div
      className="space-y-6"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <DialogHeader>
        <DialogTitle className="sr-only">
          {t('encrypted_drive.confirmation.title')}
        </DialogTitle>
      </DialogHeader>

      {/* Success Animation */}
      <motion.div 
        className="text-center"
        variants={itemVariants}
      >
        <motion.div
          variants={checkVariants}
          className={cn(
            "mx-auto w-16 h-16 rounded-full",
            "bg-green-100 dark:bg-green-900/20",
            "flex items-center justify-center",
            "mb-6"
          )}
        >
          <Check className="h-8 w-8 text-green-500 dark:text-green-400" />
        </motion.div>

        <motion.div
          variants={itemVariants}
          className="space-y-2"
        >
          <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
            {t('encrypted_drive.confirmation.title')}
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {t('encrypted_drive.confirmation.description', { name: formData.name })}
          </p>
        </motion.div>
      </motion.div>

      {/* Features List */}
      <motion.div 
        className="mt-8 space-y-4"
        variants={itemVariants}
      >
        {features.map(({ icon: Icon, key, color }) => (
          <motion.div
            key={key}
            variants={itemVariants}
            className={cn(
              "flex items-center space-x-3 p-3",
              "bg-gray-50 dark:bg-gray-800/50",
              "rounded-lg border border-gray-100 dark:border-gray-800",
              "transition-colors duration-200"
            )}
          >
            <Icon className={cn("h-5 w-5", color)} />
            <span className="text-sm text-gray-600 dark:text-gray-300">
              {t(`encrypted_drive.confirmation.features.${key}`)}
            </span>
          </motion.div>
        ))}
      </motion.div>

      {/* Get Started Button */}
      <motion.div
        variants={itemVariants}
        className="mt-8 flex justify-center"
      >
        <Button
          onClick={onClose}
          className="min-w-[200px]"
          size="lg"
        >
          {t('encrypted_drive.confirmation.get_started')}
        </Button>
      </motion.div>

      {/* Security Reminder */}
      <motion.div
        variants={itemVariants}
        className={cn(
          "mt-6 p-4 rounded-lg text-center text-sm",
          "bg-blue-50 dark:bg-blue-900/20",
          "text-blue-700 dark:text-blue-300"
        )}
      >
        {t('encrypted_drive.confirmation.security_reminder')}
      </motion.div>
    </motion.div>
  );
};