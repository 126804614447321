/**
 * @file src/hooks/audit/useAuditReport.ts
 * @description Hook for fetching audit report data with pagination handling and deduplication
 * @version 6.5.0
 * @created 2024-02-24
 * @updated 2025-03-04
 */

import React from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { auditService } from '@/services/audit.service';
import { AuditCursorFilters, AuditBaseFilters, AuditLog } from '@/types/audit.types';
import { logger } from '@/utils/logger';

interface AuditReportProps extends AuditBaseFilters {
    resetCounter?: number; // Add this to force a refetch when sorting changes
}

/**
 * Maximum number of pages we'll try to load
 * This is a safety limit to prevent infinite loading if the server doesn't return all data
 */
const MAX_PAGES = 100;

/**
 * Maximum percentage of data to consider complete
 * Some backends might not return exactly 100% due to concurrent changes
 */
const COMPLETE_THRESHOLD = 99.99; // Increased from 99 to 99.99

/**
 * Hook for fetching audit logs with infinite scrolling
 */
export const useAuditReport = (filters: AuditReportProps) => {
    logger.warn("🔍 useAuditReport called with filters:", { data: filters });

    // State to store all unique action values for sorting
    const [actionData, setActionData] = React.useState<{
        values: string[];
        dataByAction: Record<string, AuditLog[]>;
        completed: boolean;
    }>({
        values: [],
        dataByAction: {},
        completed: false
    });

    // Store page count for forced pagination
    const pageCounter = React.useRef(1);

    // State to track if we should force page-based pagination
    const [forcePageBasedPagination, setForcePageBasedPagination] = React.useState(false);

    // Track manual load clicks to force loading of next page
    const [manualLoadCounter, setManualLoadCounter] = React.useState(0);

    // Track if we've received a null cursor but still have more data to load
    const [hasNullCursorButIncomplete, setHasNullCursorButIncomplete] = React.useState(false);

    // Track last seen total count to handle cases where it might change between requests
    const lastTotalCountRef = React.useRef<number | undefined>(undefined);

    // Track consecutive null cursor responses
    const nullCursorCountRef = React.useRef(0);

    // Track loaded items count for better percentage calculation
    const loadedItemsCountRef = React.useRef(0);

    // Track the current data items to handle sorting changes properly
    const currentDataRef = React.useRef<AuditLog[]>([]);

    // Store the previous sort configuration for comparison
    const prevSortRef = React.useRef({
        column: filters.sortColumn || 'timestamp',
        direction: filters.sortDirection || 'desc'
    });

    // For deduplication - use a Map to track items by composite key since IDs may not be reliable
    // Map key is a composite key of timestamp + action + actor
    const seenItemsRef = React.useRef(new Map<string, boolean>());

    // Helper function to generate a stable composite key for an audit log entry
    const getItemKey = (item: AuditLog): string => {
        return `${item.timestamp}_${item.action}_${item.actor}_${item.target || ''}`;
    };

    // Reset state when sorting changes
    React.useEffect(() => {
        // Check if sorting has changed
        const sortingHasChanged =
            prevSortRef.current.column !== (filters.sortColumn || 'timestamp') ||
            prevSortRef.current.direction !== (filters.sortDirection || 'desc');

        if (sortingHasChanged) {
            logger.warn("🔍 Sorting changed from:", {
                data: {
                    from: prevSortRef.current,
                    to: {
                        column: filters.sortColumn || 'timestamp',
                        direction: filters.sortDirection || 'desc'
                    }
                }
            });

            // Update previous sort reference
            prevSortRef.current = {
                column: filters.sortColumn || 'timestamp',
                direction: filters.sortDirection || 'desc'
            };

            // Reset pagination state when filters change
            pageCounter.current = 1;
            setForcePageBasedPagination(false);
            setHasNullCursorButIncomplete(false);
            lastTotalCountRef.current = undefined;
            nullCursorCountRef.current = 0;
            loadedItemsCountRef.current = 0;

            // Reset data cache
            currentDataRef.current = [];

            // Clear seen items 
            seenItemsRef.current.clear();
        }
    }, [
        filters.sortColumn,
        filters.sortDirection
    ]);

    // Reset state when other filters change
    React.useEffect(() => {
        // Only reset if it's not a sort change
        const currentSortConfig = {
            column: filters.sortColumn || 'timestamp',
            direction: filters.sortDirection || 'desc'
        };

        if (JSON.stringify(prevSortRef.current) === JSON.stringify(currentSortConfig)) {
            // Reset pagination state when other filters change
            pageCounter.current = 1;
            setForcePageBasedPagination(false);
            setHasNullCursorButIncomplete(false);
            lastTotalCountRef.current = undefined;
            nullCursorCountRef.current = 0;
            loadedItemsCountRef.current = 0;

            // Reset data cache
            currentDataRef.current = [];

            // Clear seen items
            seenItemsRef.current.clear();

            // Clear action data when filters change
            setActionData({
                values: [],
                dataByAction: {},
                completed: false
            });
        }
    }, [
        filters.userId,
        filters.deviceId,
        filters.fromDate,
        filters.toDate,
        filters.action,
        filters.search,
        filters.resetCounter
    ]);

    // Generate query key that includes all filters and reset counter
    const queryKey = React.useMemo(() => {
        return [
            'auditLogs',
            {
                sortColumn: filters.sortColumn || 'timestamp',
                sortDirection: filters.sortDirection || 'desc',
                userId: filters.userId,
                deviceId: filters.deviceId,
                fromDate: filters.fromDate,
                toDate: filters.toDate,
                action: filters.action,
                search: filters.search,
                resetCounter: filters.resetCounter || 0,
                forcePageBasedPagination,
                manualLoadCounter,
                hasNullCursorButIncomplete,
                nullCursorCount: nullCursorCountRef.current,
            }
        ];
    }, [
        filters.sortColumn,
        filters.sortDirection,
        filters.userId,
        filters.deviceId,
        filters.fromDate,
        filters.toDate,
        filters.action,
        filters.search,
        filters.resetCounter,
        forcePageBasedPagination,
        manualLoadCounter,
        hasNullCursorButIncomplete,
    ]);

    // Fetch audit logs with intelligent pagination handling
    const auditQuery = useInfiniteQuery({
        queryKey,
        queryFn: async ({ pageParam }) => {
            try {
                // Use a larger page size for better performance, especially when sorting by action
                const pageSize = 100;
                let page: number | undefined = undefined;
                let cursor: string | null = null;

                // Determine pagination approach based on pageParam
                if (pageParam === 'initial') {
                    // First page - always use page=1
                    page = 1;
                    pageCounter.current = 1;
                    logger.warn("🔍 Initial page request (page 1)", { data: null });

                    // Clear seen items when starting a new query
                    seenItemsRef.current.clear();
                } else if (typeof pageParam === 'number') {
                    // Explicit page number
                    page = pageParam;
                    logger.warn(`🔍 Explicit page request (page ${page})`, { data: { page } });
                } else if (typeof pageParam === 'string' && pageParam !== 'initial') {
                    if (pageParam.startsWith('page_')) {
                        // Page-based pagination
                        page = parseInt(pageParam.replace('page_', ''), 10);
                        logger.warn(`🔍 Page-based pagination (page ${page})`, { data: { page } });
                    } else if (forcePageBasedPagination || hasNullCursorButIncomplete) {
                        // Force page-based pagination
                        pageCounter.current += 1;
                        page = pageCounter.current;
                        logger.warn(`🔍 Forced page-based pagination (page ${page})`, { data: { page } });
                    } else if (pageParam === 'force_next_page') {
                        // Force the next page due to special conditions
                        pageCounter.current += 1;
                        page = pageCounter.current;
                        logger.warn(`🔍 Force next page special condition (page ${page})`, { data: { page } });
                    } else {
                        // Cursor-based pagination from server
                        cursor = pageParam;
                        logger.warn(`🔍 Cursor-based pagination (${pageParam.substring(0, 20)}...)`, {
                            data: { cursorPrefix: pageParam.substring(0, 20) }
                        });
                    }
                }

                // Parameters to send to the API
                const requestParams: Partial<AuditCursorFilters> & { _uniqueParam?: number } = {
                    ...filters,
                    limit: pageSize,
                    _uniqueParam: Date.now() // Prevent caching
                };

                // Add either page or cursor, not both
                if (forcePageBasedPagination || hasNullCursorButIncomplete || page) {
                    requestParams.page = page;
                } else if (cursor) {
                    requestParams.cursor = cursor;
                }

                logger.warn("🔍 Request params:", {
                    data: {
                        page: requestParams.page,
                        cursor: requestParams.cursor,
                        limit: requestParams.limit,
                        sortColumn: requestParams.sortColumn,
                        sortDirection: requestParams.sortDirection,
                        forcePageBased: forcePageBasedPagination,
                        hasNullCursorButIncomplete,
                        nullCursorCount: nullCursorCountRef.current
                    }
                });

                // Make the API request
                const result = await auditService.getAuditLogs(requestParams as AuditCursorFilters);

                // Log item details to help warn duplication issues
                const firstItem = result.items[0];
                const lastItem = result.items[result.items.length - 1];

                logger.warn("🔍 API Response:", {
                    data: {
                        itemsCount: result.items.length,
                        nextCursor: result.nextCursor,
                        totalItems: result.totalItems,
                        firstItemId: firstItem?.id,
                        lastItemId: lastItem?.id
                    }
                });

                if (firstItem) {
                    logger.warn("🔍 First item details:", {
                        data: {
                            id: firstItem.id,
                            timestamp: firstItem.timestamp,
                            action: firstItem.action,
                            compositeKey: getItemKey(firstItem)
                        }
                    });
                }

                // Store the total count for comparison
                if (result.totalItems !== undefined) {
                    // If this is not the first page, check if total count has changed
                    if (lastTotalCountRef.current !== undefined &&
                        lastTotalCountRef.current !== result.totalItems) {
                        logger.warn(`🔍 Server reported different total count: was ${lastTotalCountRef.current}, now ${result.totalItems}`, {
                            data: {
                                previous: lastTotalCountRef.current,
                                current: result.totalItems
                            }
                        });
                    }

                    lastTotalCountRef.current = result.totalItems;
                }

                // Deduplicate items based on composite key
                const newItems: AuditLog[] = [];
                let duplicateCount = 0;

                for (const item of result.items) {
                    const key = getItemKey(item);

                    // If we haven't seen this item before, add it
                    if (!seenItemsRef.current.has(key)) {
                        seenItemsRef.current.set(key, true);
                        newItems.push(item);
                    } else {
                        duplicateCount++;
                    }
                }

                // Only log duplicates if any were found
                if (duplicateCount > 0) {
                    logger.warn(`🔍 Deduplicated: removed ${duplicateCount} duplicate items`, {
                        data: { duplicateCount }
                    });
                }

                // Safety check - if our deduplication removed ALL items, just use the original items
                // This prevents breaking the UI if our deduplication is overly aggressive
                if (newItems.length === 0 && result.items.length > 0) {
                    logger.warn("⚠️ Deduplication removed all items! Using original items as fallback", {
                        data: {
                            originalItemCount: result.items.length
                        }
                    });
                    newItems.push(...result.items);
                }

                // Update loaded items count
                const currentPageItemCount = newItems.length;

                // Update our data reference
                if (pageParam === 'initial') {
                    // Reset when it's a new initial request
                    currentDataRef.current = [...newItems];
                    loadedItemsCountRef.current = newItems.length;
                } else {
                    // Append for subsequent pages
                    currentDataRef.current = [...currentDataRef.current, ...newItems];
                    loadedItemsCountRef.current += currentPageItemCount;
                }

                // Calculate loaded percentage for this page
                const loadedItemsCount = loadedItemsCountRef.current;
                const totalItemsCount = result.totalItems || 0;
                const loadedPercentage = totalItemsCount > 0
                    ? (loadedItemsCount / totalItemsCount) * 100
                    : 100;

                logger.warn(`🔍 Loaded ${loadedPercentage.toFixed(1)}% of data (${loadedItemsCount}/${totalItemsCount})`, {
                    data: {
                        loadedPercentage: parseFloat(loadedPercentage.toFixed(1)),
                        loadedItemsCount,
                        totalItemsCount
                    }
                });

                // If the server returned null cursor but we have less than threshold% of data
                if (!forcePageBasedPagination &&
                    result.nextCursor === null &&
                    result.items.length > 0 &&
                    result.totalItems &&
                    loadedPercentage < COMPLETE_THRESHOLD) {

                    // Increment the null cursor counter
                    nullCursorCountRef.current += 1;
                    logger.warn(`⚠️ Server returned null cursor but we've loaded only ${loadedPercentage.toFixed(1)}% of data (null count: ${nullCursorCountRef.current})`, {
                        data: {
                            loadedPercentage: parseFloat(loadedPercentage.toFixed(1)),
                            nullCursorCount: nullCursorCountRef.current
                        }
                    });

                    // If we've seen multiple null cursors, force pagination mode
                    if (nullCursorCountRef.current >= 2) {
                        logger.warn("🔍 Multiple null cursors detected, switching to forced page-based pagination", {
                            data: {
                                nullCursorCount: nullCursorCountRef.current
                            }
                        });
                        setForcePageBasedPagination(true);
                        setHasNullCursorButIncomplete(true);
                    }
                } else if (result.nextCursor !== null) {
                    // Reset null cursor counter if we get a valid cursor
                    nullCursorCountRef.current = 0;
                }

                // Return modified result with deduplicated items
                return {
                    ...result,
                    items: newItems, // Use deduplicated items
                    originalItemCount: result.items.length,
                    deduplicatedItemCount: newItems.length,
                    page: page || pageCounter.current,
                    forcePageBased: forcePageBasedPagination,
                    hasNullCursorButIncomplete,
                    loadedPercentage,
                    loadedItemsCount,
                    totalItemsCount
                };
            } catch (error) {
                logger.error("❌ API Error:", error);
                throw error;
            }
        },
        getNextPageParam: (lastPage, allPages) => {
            // Safety check - if we've reached our maximum page limit
            if (allPages.length >= MAX_PAGES) {
                logger.warn(`🔍 Reached maximum page limit (${MAX_PAGES}), stopping pagination`, {
                    data: { pageCount: allPages.length, maxPages: MAX_PAGES }
                });
                return undefined;
            }

            // If no items in the response, stop pagination
            if (!lastPage.items || lastPage.items.length === 0) {
                logger.warn("🔍 No items in response, stopping pagination", { data: null });
                return undefined;
            }

            // Calculate total items loaded across all pages
            const loadedItems = loadedItemsCountRef.current;
            const totalItems = lastPage.totalItems || 0;

            // If we've loaded EXACTLY all items, stop pagination
            if (totalItems > 0 && loadedItems >= totalItems) {
                logger.warn(`🔍 Loaded ${loadedItems}/${totalItems} items (100%), stopping pagination`, {
                    data: { loadedItems, totalItems }
                });
                return undefined;
            }

            // Calculate loaded percentage based on the server's reported total
            const loadedPercentage = totalItems > 0 ? (loadedItems / totalItems) * 100 : 100;

            // Continue loading until we reach 100% - only stop if we've loaded EVERYTHING
            if (loadedItems === totalItems) {
                logger.warn(`🔍 Loaded exactly ${loadedItems}/${totalItems} items, pagination complete`, {
                    data: { loadedItems, totalItems }
                });
                return undefined;
            }

            // Use the server's cursor if available and we're not forcing page-based pagination
            if (lastPage.nextCursor && !forcePageBasedPagination && !hasNullCursorButIncomplete) {
                logger.warn(`🔍 Using server cursor for next page: ${lastPage.nextCursor.substring(0, 20)}... (${loadedPercentage.toFixed(1)}% loaded)`, {
                    data: {
                        cursorPrefix: lastPage.nextCursor.substring(0, 20),
                        loadedPercentage: parseFloat(loadedPercentage.toFixed(1))
                    }
                });
                return lastPage.nextCursor;
            }

            // If we're forcing page-based pagination or the server didn't return a cursor
            // but we know there's more data to load
            if (forcePageBasedPagination ||
                hasNullCursorButIncomplete ||
                (!lastPage.nextCursor && lastPage.items.length > 0 && loadedItems < totalItems)) {

                // If we have total items and we still have more to load, continue pagination
                if (totalItems && loadedItems < totalItems) {
                    const nextPage = lastPage.page + 1;
                    logger.warn(`🔍 Using page-based pagination for next page: ${nextPage} (${loadedPercentage.toFixed(1)}% loaded)`, {
                        data: {
                            nextPage,
                            loadedPercentage: parseFloat(loadedPercentage.toFixed(1))
                        }
                    });

                    // Return signal to trigger next page load
                    return "force_next_page";
                }
            }

            // No more pages to load
            logger.warn(`🔍 No more pages to load, stopping pagination (${loadedPercentage.toFixed(1)}% loaded)`, {
                data: { loadedPercentage: parseFloat(loadedPercentage.toFixed(1)) }
            });
            return undefined;
        },
        initialPageParam: 'initial' as string,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 1,
        gcTime: 5 * 60 * 1000, // 5 minutes
    });

    // Fetch users
    const usersQuery = useQuery({
        queryKey: ['auditUsers'],
        queryFn: async () => {
            try {
                return await auditService.getUsers();
            } catch (error) {
                logger.error("❌ Failed to fetch users:", error);
                throw error;
            }
        },
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    // Fetch devices for selected user
    const devicesQuery = useQuery({
        queryKey: ['auditDevices', filters.userId],
        queryFn: async () => {
            if (!filters.userId) {
                return [];
            }
            try {
                return await auditService.getUserDevices(filters.userId);
            } catch (error) {
                logger.error("❌ Failed to fetch devices:", error);
                throw error;
            }
        },
        enabled: Boolean(filters.userId),
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    // Get the accumulated data from our ref instead of rebuilding from API response
    const allItems = React.useMemo(() => {
        if (!currentDataRef.current.length && auditQuery.data?.pages) {
            // Initialize or update currentDataRef if it's empty but we have data
            let items: AuditLog[] = [];

            auditQuery.data.pages.forEach(page => {
                if (page.items && Array.isArray(page.items)) {
                    items.push(...page.items);
                }
            });

            // Set the current data ref
            currentDataRef.current = items;
        }

        let items = [...currentDataRef.current];

        // Sort items by action if needed
        if (filters.sortColumn === 'action' && items.length > 0) {
            // Process action data for better sorting
            const uniqueActions = new Set<string>();
            const dataByAction: Record<string, AuditLog[]> = {};

            // Group items by action
            items.forEach(item => {
                const action = item.action || '';
                uniqueActions.add(action);

                if (!dataByAction[action]) {
                    dataByAction[action] = [];
                }

                dataByAction[action].push(item);
            });

            // Sort action values
            const sortedActionValues = Array.from(uniqueActions).sort();
            if (filters.sortDirection === 'desc') {
                sortedActionValues.reverse();
            }

            // Update action data state if it changed
            const actionsChanged =
                sortedActionValues.length !== actionData.values.length ||
                JSON.stringify(sortedActionValues) !== JSON.stringify(actionData.values);

            if (actionsChanged) {
                setActionData({
                    values: sortedActionValues,
                    dataByAction,
                    completed: !auditQuery.hasNextPage
                });
            }

            // Create new sorted array by action
            let sortedItems: AuditLog[] = [];

            // Add items in order of sorted action values
            sortedActionValues.forEach(action => {
                if (dataByAction[action]) {
                    sortedItems.push(...dataByAction[action]);
                }
            });

            items = sortedItems;
        }

        return items;
    }, [auditQuery.data?.pages, filters.sortColumn, filters.sortDirection, actionData, auditQuery.hasNextPage]);

    // Get total count from first page
    const totalCount = React.useMemo(() => {
        if (!auditQuery.data || !auditQuery.data.pages.length) {
            return undefined;
        }
        return auditQuery.data.pages[0].totalItems;
    }, [auditQuery.data]);

    // warn logging
    React.useEffect(() => {
        if (auditQuery.data) {
            const loadedItems = allItems.length;
            const totalItems = totalCount || 0;

            // Calculate percentage, ensuring it never exceeds 100%
            const percentage = totalItems > 0
                ? Math.min(Math.round((loadedItems / totalItems) * 100), 100)
                : 0;

            logger.warn("🔍 Current audit query state:", {
                data: {
                    pagesCount: auditQuery.data.pages.length,
                    loadedItems,
                    totalFromServer: totalItems,
                    loadedPercentage: `${percentage}%`,
                    hasNextPage: auditQuery.hasNextPage,
                    isFetchingNextPage: auditQuery.isFetchingNextPage,
                    forcePageBasedPagination,
                    hasNullCursorButIncomplete,
                    currentPage: pageCounter.current,
                    nullCursorCount: nullCursorCountRef.current,
                    seenItemsCount: seenItemsRef.current.size,
                    filters: {
                        sortColumn: filters.sortColumn,
                        sortDirection: filters.sortDirection,
                        resetCounter: filters.resetCounter
                    }
                }
            });
        }
    }, [
        auditQuery.data,
        allItems.length,
        totalCount,
        auditQuery.hasNextPage,
        auditQuery.isFetchingNextPage,
        forcePageBasedPagination,
        hasNullCursorButIncomplete,
    ]);

    // Calculate loading progress for UI
    const loadingProgress = React.useMemo(() => {
        if (!totalCount || totalCount === 0) return 0;

        // More precise calculation - show the exact percentage with 1 decimal place
        const exact = (allItems.length / totalCount) * 100;

        // For UI display - return the precise percentage (not rounded to integer)
        // This ensures we show 99.8% instead of 100% when missing a few records
        return Math.min(exact, 100);
    }, [allItems.length, totalCount]);

    // Enhanced manual trigger for loading more data
    const handleManualLoad = React.useCallback(() => {
        // More aggressive approach: Always try to force pagination if we're below threshold
        // and especially if we don't have a next page but data is incomplete
        if (loadingProgress < COMPLETE_THRESHOLD) {
            logger.warn(`🔍 Manual load: Enabling forced pagination due to incomplete data (${loadingProgress}%)`, {
                data: { loadingProgress }
            });
            setForcePageBasedPagination(true);
            setHasNullCursorButIncomplete(true);
            pageCounter.current = Math.ceil(loadedItemsCountRef.current / 100); // Estimate current page

            // Increment counter to force a new load attempt
            setManualLoadCounter(prev => prev + 1);

            logger.warn("🔍 Manual load with forced pagination triggered", { data: null });
            return;
        }

        // Standard next page fetch for normal cases
        auditQuery.fetchNextPage();
        logger.warn("🔍 Standard manual load triggered", { data: null });
    }, [auditQuery, loadingProgress]);

    // Calculate effective data for display - ensuring we don't display more records than total
    const effectiveData = React.useMemo(() => {
        if (!totalCount || allItems.length <= totalCount) {
            return allItems;
        }

        // If we have more items than total count, truncate the array
        logger.warn(`🔍 Truncating displayed items from ${allItems.length} to ${totalCount} to match server's reported total`, {
            data: {
                originalCount: allItems.length,
                truncatedTo: totalCount
            }
        });
        return allItems.slice(0, totalCount);
    }, [allItems, totalCount]);

    return {
        auditLogs: {
            // Use the truncated array to prevent showing more items than the total
            data: effectiveData,
            // Only show "has next page" if we're under the total count
            hasNextPage: loadingProgress < COMPLETE_THRESHOLD && !!auditQuery.hasNextPage && allItems.length < (totalCount || 0),
            fetchNextPage: auditQuery.fetchNextPage,
            manualLoad: handleManualLoad,
            isFetchingNextPage: auditQuery.isFetchingNextPage,
            totalCount,
            refetch: auditQuery.refetch,
            loadingProgress
        },
        users: {
            data: usersQuery.data || [],
            isLoading: usersQuery.isLoading,
        },
        devices: {
            data: devicesQuery.data || [],
            isLoading: devicesQuery.isLoading,
        },
        isLoading: auditQuery.isLoading || usersQuery.isLoading || (Boolean(filters.userId) && devicesQuery.isLoading),
        isError: auditQuery.isError || usersQuery.isError || (Boolean(filters.userId) && devicesQuery.isError),
        paginationStatus: {
            // Ensure we don't show more loaded items than total
            loaded: Math.min(allItems.length, totalCount || allItems.length),
            total: totalCount || 0,
            percentage: loadingProgress,
            forcePageBased: forcePageBasedPagination
        }
    };
};