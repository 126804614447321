/**
 * @file src/routes/debug.routes.tsx
 * @description Debug routes configuration (development only)
 * @version 1.0.0
 * @created 2024-01-16
 */

import { RouteObject, Navigate } from 'react-router-dom';
import EncryptionDebug from '@/pages/debug/encryption';
import UploadDebug from '@/pages/debug/upload';

export const debugRoutes: RouteObject = {
  path: 'debug',
  children: [
    { 
      index: true, 
      element: process.env.NODE_ENV === 'development' ? <EncryptionDebug /> : <Navigate to="/" replace /> 
    },
    {
      path: 'encryption',
      element: process.env.NODE_ENV === 'development' ? <EncryptionDebug /> : <Navigate to="/" replace />
    },
    {
      path: 'upload',
      element: process.env.NODE_ENV === 'development' ? <UploadDebug /> : <Navigate to="/" replace />
    }
  ]
};