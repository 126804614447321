/**
 * @file src/hooks/useIntersectionObserver.ts
 * @description Custom hook for handling intersection observer functionality
 * @version 1.0.0
 * @created 2024-12-23
 * @dependencies react
 */

import {useEffect} from 'react';

interface UseIntersectionObserverProps {
  target: React.RefObject<HTMLElement>;
  onIntersect?: () => void;
  enabled?: boolean;
  root?: HTMLElement | null;
  rootMargin?: string;
  threshold?: number | number[];
}

export const useIntersectionObserver = ({
  target,
  onIntersect,
  enabled = true,
  root = null,
  rootMargin = '0px',
  threshold = 0.1,
}: UseIntersectionObserverProps) => {
  useEffect(() => {
    if (!enabled || !target.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect?.();
          }
        });
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    observer.observe(target.current);

    return () => {
      if (target.current) {
        observer.unobserve(target.current);
      }
    };
  }, [enabled, root, rootMargin, threshold, target, onIntersect]);
};

export default useIntersectionObserver;