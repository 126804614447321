/**
 * @file src/hooks/useAnimation.ts
 * @description Custom hook for reusable animations using Framer Motion
 * @version 1.0.0
 */

import { useMemo } from 'react';
import { ANIMATION_CONFIG } from '@/config/animation.config';
import type { AnimationVariant, TransitionType, SpringType } from '@/config/animation.config';

export const useAnimation = (
  variant: AnimationVariant = 'fadeIn',
  transition: TransitionType = 'default',
  useSpring?: SpringType
) => {
  const animationConfig = useMemo(() => {
    const baseVariant = ANIMATION_CONFIG.variants[variant];
    const transitionConfig = useSpring 
      ? ANIMATION_CONFIG.spring[useSpring]
      : ANIMATION_CONFIG.transition[transition];

    return {
      ...baseVariant,
      transition: transitionConfig
    };
  }, [variant, transition, useSpring]);

  // Common animation presets
  const presets = useMemo(() => ({
    fadeInOut: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: ANIMATION_CONFIG.transition[transition]
    },
    slideUpDown: {
      initial: { y: 20, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      exit: { y: 20, opacity: 0 },
      transition: ANIMATION_CONFIG.transition[transition]
    },
    scaleInOut: {
      initial: { scale: 0.95, opacity: 0 },
      animate: { scale: 1, opacity: 1 },
      exit: { scale: 0.95, opacity: 0 },
      transition: useSpring 
        ? ANIMATION_CONFIG.spring[useSpring]
        : ANIMATION_CONFIG.transition[transition]
    },
    height: (from: number, to: number) => ({
      initial: { height: from },
      animate: { height: to },
      exit: { height: from },
      transition: ANIMATION_CONFIG.transition[transition]
    }),
    width: (from: number, to: number) => ({
      initial: { width: from },
      animate: { width: to },
      exit: { width: from },
      transition: ANIMATION_CONFIG.transition[transition]
    })
  }), [transition, useSpring]);

  return {
    config: animationConfig,
    presets
  };
};