/**
 * @file src/services/account.service.ts
 * @description Account service with avatar support
 * @version 1.2.0
 */

import { authService } from './auth.service';
import type { AccountInfo, AvatarUploadResponse } from '@/types/account.types';
import { logger } from '@/utils/logger';
import { getAuthHeaders } from '@/utils/api-utils';

const BASE_URL = '/api/v1/account';

export const accountApi = {
  getInfo: async (): Promise<AccountInfo> => {
    try {
      // Check if we're on auth page first to avoid unnecessary work
      const path = window.location.pathname;
      const isAuthPage = path.includes('/auth/') || path.includes('/ghost/auth/');
      
      if (isAuthPage) {
        logger.debug('Skipping account info request on auth page', {
          component: 'accountApi',
          path
        });
        throw new Error('Not authenticated - on auth page');
      }
      
      // Get access token directly
      const accessToken = localStorage.getItem('access_token');
      const hasUser = !!localStorage.getItem('user');
      
      if (!accessToken) {
        logger.warn('No access token available for account request', {
          component: 'accountApi',
          hasUser
        });
        throw new Error('No access token available');
      }
      
      // Check if user info exists
      if (!hasUser) {
        logger.warn('Account request: Missing user data in localStorage', {
          component: 'accountApi',
          hasAccessToken: true,
          willProceedAnyway: true
        });
        // Continue anyway - maybe the backend can identify the user from the token
      }
      
      // Create headers with token, ensuring proper format
      const formattedToken = accessToken.startsWith('Bearer ') 
        ? accessToken 
        : `Bearer ${accessToken}`;
        
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': formattedToken,
        // Add diagnostic header
        'X-Auth-Debug': 'account-info'
      };
      
      // Log token format for debugging
      logger.debug('Account API headers prepared', {
        component: 'accountApi',
        tokenFormat: accessToken.startsWith('Bearer ') ? 'with Bearer prefix' : 'without Bearer prefix',
        tokenFormatFixed: formattedToken.startsWith('Bearer '),
        tokenLength: accessToken.length,
        hasUser
      });
      
      logger.info('Making account info request', {
        component: 'accountApi',
        endpoint: `${BASE_URL}/info`,
        hasAuth: !!headers.Authorization,
        authPrefix: headers.Authorization?.substring(0, 15),
        hasUserData: hasUser
      });

      const response = await fetch(`${BASE_URL}/info`, {
        method: 'GET',
        headers: headers as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        // Handle 401 for expired signed URLs
        if (response?.status === 401) {
          // Retry the request once
          logger.debug('Retrying account info request after 401');
          const retryResponse = await fetch(`${BASE_URL}/info`, {
            method: 'GET',
            headers: headers as HeadersInit,
            credentials: 'include'
          });
          if (retryResponse.ok) {
            return retryResponse.json();
          }
        }
        logger.error('Account info API error', {
          component: 'accountApi',
          status: response.status,
          error: errorText
        });
        throw new Error(errorText);
      }

      const data = await response.json();

      logger.debug('Account info API response', {
        component: 'accountApi',
        data: {
          hasData: !!data,
          structure: {
            hasUser: !!data?.user,
            hasSubscription: !!data?.subscription,
            hasAvatar: !!data?.profile?.avatar_url
          }
        }
      });

      return data;

    } catch (error) {
      logger.error('Account API error:', {
        component: 'accountApi',
        error
      });
      throw error;
    }
  },

  uploadAvatar: async (formData: FormData): Promise<AvatarUploadResponse> => {
    try {
      const { accessToken } = authService.getStoredToken();
      if (!accessToken) {
        throw new Error("No access token available");
      }
      const headers = {
        'Authorization': accessToken
      };

      const response = await fetch(`${BASE_URL}/profile/avatar`, {
        method: 'POST',
        headers: headers as HeadersInit,
        credentials: 'include',
        body: formData
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      return response.json();
    } catch (error) {
      logger.error('Avatar upload error:', {
        component: 'accountApi',
        error
      });
      throw error;
    }
  },

  deleteAvatar: async (): Promise<void> => {
    try {
      const { accessToken } = authService.getStoredToken();
      if (!accessToken) {
        throw new Error("No access token available");
      }
      const headers = {
        'Authorization': accessToken
      };

      const response = await fetch(`${BASE_URL}/profile/avatar`, {
        method: 'DELETE',
        headers: headers as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
    } catch (error) {
      logger.error('Avatar delete error:', {
        component: 'accountApi',
        error
      });
      throw error;
    }
  },

  updateProfile: async (data: { first_name?: string; last_name?: string; company_name?: string }) => {
    try {
      const { accessToken } = authService.getStoredToken();
      if (!accessToken) {
        throw new Error("No access token available");
      }
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': accessToken
      };

      const response = await fetch(`${BASE_URL}/profile`, {
        method: 'PATCH',
        headers: headers as HeadersInit,
        credentials: 'include',
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      return response.json();
    } catch (error) {
      logger.error('Profile update error:', error);
      throw error;
    }
  }


};