/**
 * @file src/providers/GhostTranslationProvider.tsx
 * @description Ghost translation provider using namespace fallback
 * @version 2.0.0
 */

import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n/config';
import { logger } from '@/utils/logger';

interface GhostTranslationProviderProps {
  children: React.ReactNode;
}

export const GhostTranslationProvider: React.FC<GhostTranslationProviderProps> = ({ 
  children 
}) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!i18n.isInitialized) {
      logger.debug('Initializing translation system with Ghost namespace');
      i18n.init()
        .then(() => {
          setIsInitialized(true);
          logger.debug('Translation system initialized successfully with Ghost namespace');
        })
        .catch(error => {
          logger.error('Failed to initialize translation system:', error);
        });
    } else {
      setIsInitialized(true);
    }
  }, []);

  if (!isInitialized) {
    return null; // Or a loading spinner if needed
  }

  return (
    <I18nextProvider i18n={i18n}>
      {children}
    </I18nextProvider>
  );
};