/**
 * @file src/layouts/ghost/components/create-core/steps/shield-selection/components/ShieldDetails.tsx
 * @description Enhanced shield details dialog with elegant feature display
 * @version 2.0.0
 */

import React from 'react';
import {motion} from 'framer-motion';
import {ArrowUpRight, Check} from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { GhostDialog } from '@/app/ghost/components/common/GhostDialog';
import { GhostGlass } from '@/app/ghost/components/common/GhostGlass';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import type { Shield } from '../types/shield-types';

interface ShieldDetailsProps {
  shield: Shield;
  onClose: () => void;
}

export const ShieldDetails: React.FC<ShieldDetailsProps> = ({
  shield,
  onClose
}) => {
  const { t } = useGhostTranslation();
  const Icon = shield.icon;

  logger.debug('Showing shield details', { shieldId: shield.id } as any);

  return (
    <GhostDialog 
      open 
      onOpenChange={onClose}
      className="max-w-xl"
    >
      <div className="space-y-6">
        {/* Header - Removed close button */}
        <div className="flex items-center space-x-4">
          <div className={cn(
            "p-3 rounded-xl",
            "bg-[hsl(var(--ghost-accent))]/10"
          )}>
            <Icon className="w-6 h-6 text-[hsl(var(--ghost-accent))]" />
          </div>
          <div>
            <h3 className="text-lg font-semibold">
              {t(shield.nameKey) as React.ReactNode}
            </h3>
            <p className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
              {t(shield.descriptionKey) as React.ReactNode}
            </p>
          </div>
        </div>

        {/* Features */}
        <div className="space-y-3">
          {shield.features.detailed.map((feature, index) => (
            <motion.div
              key={feature.id}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.05 }}
            >
              <GhostGlass
                className={cn(
                  "p-4 group",
                  "hover:bg-[hsl(var(--ghost-accent))]/5",
                  "transition-colors duration-200"
                )}
                opacity="low"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-start space-x-3">
                    {/* Feature Icon */}
                    <div className={cn(
                      "p-2 rounded-lg",
                      "bg-[hsl(var(--ghost-accent))]/10",
                      "group-hover:bg-[hsl(var(--ghost-accent))]/15",
                      "transition-colors duration-200"
                    )}>
                      <feature.icon className="w-4 h-4 text-[hsl(var(--ghost-accent))]" />
                    </div>

                    {/* Feature Content */}
                    <div>
                      <h4 className="text-sm font-medium">
                        {t(feature.titleKey) as React.ReactNode}
                      </h4>
                      {feature.descriptionKey && (
                        <p className="mt-1 text-sm text-[hsl(var(--ghost-foreground-muted))]">
                          {t(feature.descriptionKey) as React.ReactNode}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Check Icon */}
                  <Check className="w-5 h-5 text-[hsl(var(--ghost-accent))]" />
                </div>
              </GhostGlass>
            </motion.div>
          ))}
        </div>

        {/* Upgrade Section */}
        {shield.requiresUpgrade && (
          <div className="space-y-4">
            <div className="h-px bg-[hsl(var(--ghost-accent))]/10" />
            
            <div className="space-y-4">
              <h4 className="text-sm font-medium">
                {t('security.upgrade.title') as React.ReactNode}
              </h4>

              {/* Upgrade Benefits */}
              <div className="space-y-2">
                {shield.upgradeBenefits?.map((benefit, index) => (
                  <motion.div
                    key={benefit}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.3 + (index * 0.1) }}
                    className="flex items-center space-x-2"
                  >
                    <Check className="w-4 h-4 text-[hsl(var(--ghost-accent))]" />
                    <span className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
                      {t(benefit) as React.ReactNode}
                    </span>
                  </motion.div>
                ))}
              </div>

              {/* Upgrade Button */}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className={cn(
                  "w-full p-3 rounded-xl",
                  "bg-[hsl(var(--ghost-accent))]",
                  "text-white text-sm font-medium",
                  "flex items-center justify-center space-x-2"
                )}
              >
                <span>{t('security.upgrade.button') as React.ReactNode}</span>
                <ArrowUpRight className="w-4 h-4" />
              </motion.button>
            </div>
          </div>
        )}

        {/* Close Button */}
        <button
          onClick={onClose}
          className={cn(
            "w-full p-3 rounded-xl",
            "bg-[hsl(var(--ghost-surface))]",
            "hover:bg-[hsl(var(--ghost-accent))]/5",
            "text-[hsl(var(--ghost-foreground))] text-sm font-medium",
            "transition-colors duration-200"
          )}
        >
          {t('common.close') as React.ReactNode}
        </button>
      </div>
    </GhostDialog>
  );
};

export default ShieldDetails;