/**
 * @file src/components/upload/shared/FileIcon.tsx
 * @description Dynamic file type icon component
 * @version 1.0.1
 */

import React from 'react';
import { 
  FileText,
  FileImage,
  FileVideo,
  FileAudio,
  FileCode,
  FileArchive,
  FileType, // Changed from FilePdf
  Table, // Changed from FileSpreadsheet
  Presentation , // Changed from FileSlides
  File
} from 'lucide-react';
import { cn } from '@/utils/utils';

interface FileIconProps {
  fileName: string;
  size?: number;
  className?: string;
}

export const FileIcon: React.FC<FileIconProps> = ({
  fileName,
  size = 24,
  className
}) => {
  const extension = fileName.split('.').pop()?.toLowerCase() || '';

  const getIconByType = () => {
    // Image files
    if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(extension)) {
      return FileImage;
    }
    // Video files
    if (['mp4', 'webm', 'mov', 'avi'].includes(extension)) {
      return FileVideo;
    }
    // Audio files
    if (['mp3', 'wav', 'ogg'].includes(extension)) {
      return FileAudio;
    }
    // Document files
    if (['doc', 'docx', 'txt', 'rtf'].includes(extension)) {
      return FileText;
    }
    // PDF files
    if (extension === 'pdf') {
      return FileType; // Changed from FilePdf
    }
    // Spreadsheet files
    if (['xls', 'xlsx', 'csv'].includes(extension)) {
      return Table; // Changed from FileSpreadsheet
    }
    // Presentation files
    if (['ppt', 'pptx'].includes(extension)) {
      return Presentation ; // Changed from FileSlides
    }
    // Archive files
    if (['zip', 'rar', '7z', 'tar', 'gz'].includes(extension)) {
      return FileArchive;
    }
    // Code files
    if (['js', 'ts', 'jsx', 'tsx', 'html', 'css', 'py', 'java'].includes(extension)) {
      return FileCode;
    }
    // Default file icon
    return File;
  };

  const IconComponent = getIconByType();

  const getIconColor = () => {
    switch (getIconByType()) {
      case FileImage:
        return 'text-purple-500';
      case FileVideo:
        return 'text-pink-500';
      case FileAudio:
        return 'text-blue-500';
      case FileType: // Changed from FilePdf
        return 'text-red-500';
      case Table: // Changed from FileSpreadsheet
        return 'text-green-500';
      case Presentation : // Changed from FileSlides
        return 'text-orange-500';
      case FileArchive:
        return 'text-yellow-500';
      case FileCode:
        return 'text-cyan-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div className={cn(
      "relative group",
      className
    )}>
      <IconComponent 
        size={size} 
        className={cn(
          getIconColor(),
          "transition-all duration-200",
          "group-hover:scale-110"
        )}
      />
      {extension && (
        <span className={cn(
          "absolute bottom-0 left-1/2 -translate-x-1/2",
          "text-[8px] font-bold uppercase",
          "opacity-0 group-hover:opacity-100",
          "transition-opacity duration-200",
          "pointer-events-none"
        )}>
          {extension}
        </span>
      )}
    </div>
  );
};