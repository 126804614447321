/**
 * @file src/components/common/PlaceholderView.tsx
 * @description Reusable placeholder view for sections under development
 * @version 1.0.0
 */

import React from 'react';
import { motion } from 'framer-motion';
import { Construction } from 'lucide-react';
import { cn } from '@/utils/utils';

interface PlaceholderViewProps {
  title: string;
  description?: string;
  icon?: React.ReactNode;
  className?: string;
}

export const PlaceholderView: React.FC<PlaceholderViewProps> = ({
  title,
  description = 'This section is coming soon',
  icon = <Construction className="h-8 w-8" />,
  className
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={cn(
        "flex flex-col items-center justify-center",
        "min-h-[400px] p-8",
        "text-center",
        className
      )}
    >
      <motion.div
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.2 }}
        className="w-16 h-16 rounded-full bg-blue-50 dark:bg-blue-900/20 
                  flex items-center justify-center mb-6 
                  text-blue-500 dark:text-blue-400"
      >
        {icon}
      </motion.div>
      <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100 mb-3">
        {title}
      </h2>
      <p className="text-gray-500 dark:text-gray-400 max-w-md">
        {description}
      </p>
    </motion.div>
  );
};

export default PlaceholderView;