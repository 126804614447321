// src/layouts/ghost/components/create-core/steps/shield-selection/constants/shield-data.ts

import { 
  Shield as ShieldIcon, 
  Zap, 
  Lock,
  Key,
  Sparkles,
  Cpu,
  FileText,
  Rocket,
  Shield as ShieldIconLucide,
  Layers,
  Timer
} from 'lucide-react';
import type { Shield as ShielD, ShieldFeature } from '../types/shield-types';

// Common features that can be reused
const commonFeatures: Record<string, ShieldFeature> = {
  zeroKnowledge: {
    id: 'zero_knowledge',
    titleKey: 'security.features.zero_knowledge.title',
    descriptionKey: 'security.features.zero_knowledge.description',
    icon: Key,
    category: 'security'
  },
  militaryGrade: {
    id: 'military_grade',
    titleKey: 'security.features.military_grade.title',
    descriptionKey: 'security.features.military_grade.description',
    icon: ShieldIconLucide,
    category: 'security'
  }
};

export const SHIELDS: ShielD[] = [
  {
    id: 'standard',
    nameKey: 'security.shields.ghost.name',
    descriptionKey: 'security.shields.ghost.description',
    icon: ShieldIcon,
    iconFill: true,
    gradient: 'from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]',
    available: true,
    requiresUpgrade: false,
    features: {
      simple: [
        commonFeatures.zeroKnowledge,
        commonFeatures.militaryGrade
      ],
      detailed: [
        commonFeatures.zeroKnowledge,
        commonFeatures.militaryGrade,
        {
          id: 'emergency',
          titleKey: 'security.features.emergency.title',
          descriptionKey: 'security.features.emergency.description',
          icon: Sparkles,
          category: 'advanced'
        }
      ]
    },
    upgradeBenefits: [] as string[]
  },
  {
    id: 'quantum',
    nameKey: 'security.shields.quantum.name',
    descriptionKey: 'security.shields.quantum.description',
    icon: Zap,
    iconFill: true,
    gradient: 'from-[hsl(var(--ghost-accent-secondary))] to-[hsl(var(--ghost-accent-tertiary))]',
    available: true,
    requiresUpgrade: true,
    features: {
      simple: [
        commonFeatures.zeroKnowledge,
        {
          id: 'optimized',
          titleKey: 'security.features.optimized.title',
          icon: Rocket,
          category: 'performance'
        }
      ],
      detailed: [
        commonFeatures.zeroKnowledge,
        commonFeatures.militaryGrade,
        {
          id: 'chacha20',
          titleKey: 'security.features.chacha20.title',
          descriptionKey: 'security.features.chacha20.description',
          icon: Cpu,
          category: 'performance'
        },
        {
          id: 'large_files',
          titleKey: 'security.features.large_files.title',
          descriptionKey: 'security.features.large_files.description',
          icon: FileText,
          category: 'performance'
        }
      ]
    },
    upgradeBenefits: [
      'security.benefits.speed',
      'security.benefits.quantum_resistant'
    ]
  },
  {
    id: 'paranoid',
    nameKey: 'security.shields.paranoid.name',
    descriptionKey: 'security.shields.paranoid.description',
    icon: Lock,
    iconFill: true,
    gradient: 'from-[hsl(var(--ghost-accent-tertiary))] to-[hsl(var(--ghost-accent))]',
    available: true,
    requiresUpgrade: true,
    features: {
      simple: [
        {
          id: 'multi_layer',
          titleKey: 'security.features.multi_layer.title',
          icon: Layers,
          category: 'advanced'
        },
        {
          id: 'time_control',
          titleKey: 'security.features.time_control.title',
          icon: Timer,
          category: 'advanced'
        }
      ],
      detailed: [
        commonFeatures.zeroKnowledge,
        commonFeatures.militaryGrade,
        {
          id: 'quantum_resistant',
          titleKey: 'security.features.quantum_resistant.title',
          descriptionKey: 'security.features.quantum_resistant.description',
          icon: ShieldIconLucide,
          category: 'security'
        },
        {
          id: 'multi_layer',
          titleKey: 'security.features.multi_layer.title',
          descriptionKey: 'security.features.multi_layer.description',
          icon: Layers,
          category: 'advanced'
        },
        {
          id: 'time_control',
          titleKey: 'security.features.time_control.title',
          descriptionKey: 'security.features.time_control.description',
          icon: Timer,
          category: 'advanced'
        }
      ]
    },
    upgradeBenefits: [
      'security.benefits.multi_layer',
      'security.benefits.advanced_features'
    ]
  }
];
