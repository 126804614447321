/**
 * @file src/app/ghost/components/theme/GhostThemeToggle.tsx
 * @description Enhanced theme toggle with better mobile support using Radix UI Dropdown
 * @version 1.6.0
 */

import React from 'react';
import { Sun, Moon, Zap } from 'lucide-react';
import { cn } from '@/utils/utils';
import {useGhostTheme} from '../../providers/GhostThemeProvider';
import { logger } from '@/utils/logger';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

type GhostTheme = 'day' | 'night' | 'quantum';

interface ThemeOption {
  id: GhostTheme;
  label: string;
  icon: React.ElementType;
}

interface GhostThemeToggleProps {
  className?: string;
}

const themeOptions: ThemeOption[] = [
  { id: 'day', label: 'Day', icon: Sun },
  { id: 'night', label: 'Night', icon: Moon },
  { id: 'quantum', label: 'Quantum', icon: Zap }
];

export const GhostThemeToggle: React.FC<GhostThemeToggleProps> = ({ 
  className 
}) => {
  const { theme, setTheme } = useGhostTheme();
  const isMobile = useMediaQuery('(max-width: 640px)');

  const handleThemeChange = (newTheme: GhostTheme) => {
    logger.debug('Theme change requested', {
      component: 'GhostThemeToggle',
      from: theme,
      to: newTheme
    } as any);
    setTheme(newTheme);
  };

  const currentTheme = themeOptions.find((t) => t.id === theme) || themeOptions[2];
  const CurrentIcon = currentTheme.icon;

  // For mobile, we can cycle through themes directly on the button
  const handleCycleTheme = () => {
    if (isMobile) {
      const currentIndex = themeOptions.findIndex(t => t.id === theme);
      const nextIndex = (currentIndex + 1) % themeOptions.length;
      handleThemeChange(themeOptions[nextIndex].id);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          className={cn(
            "w-full h-10 rounded-lg",
            "flex items-center justify-center",
            "cursor-pointer",
            "transition-colors duration-150",
            "hover:bg-[hsl(var(--ghost-accent))/0.1]",
            "active:bg-[hsl(var(--ghost-accent))/0.2]",
            "touch-manipulation", // Improve touch handling
            className
          )}
          onClick={isMobile ? handleCycleTheme : undefined}
          aria-label={`Current theme: ${currentTheme.label}. ${isMobile ? 'Tap to change theme' : 'Click to open theme menu'}`}
        >
          <CurrentIcon className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
          {!isMobile && (
            <span className="ml-2 text-sm hidden sm:inline-block text-[hsl(var(--ghost-foreground))]">
              {currentTheme.label}
            </span>
          )}
        </button>
      </DropdownMenuTrigger>
      {/* Only render the dropdown content on non-mobile devices */}
      {!isMobile && (
        <DropdownMenuContent
          className="w-[200px] bg-[hsl(var(--ghost-surface))] border-[hsl(var(--ghost-accent))/0.2] text-[hsl(var(--ghost-foreground))]"
          align="end"
          sideOffset={8}
        >
          {themeOptions.map((option) => {
            const Icon = option.icon;
            const isActive = theme === option.id;
            
            return (
              <DropdownMenuItem
                key={option.id}
                onClick={() => handleThemeChange(option.id)}
                className={cn(
                  "flex items-center justify-between gap-2",
                  "cursor-pointer",
                  "py-3", // Increase padding for better touch target
                  "hover:bg-[hsl(var(--ghost-accent))/0.1]",
                  "focus:bg-[hsl(var(--ghost-accent))/0.1]",
                  isActive && "text-[hsl(var(--ghost-accent))]"
                )}
              >
                <div className="flex items-center gap-2">
                  <Icon className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
                  <span className="font-medium text-[hsl(var(--ghost-foreground))]">{option.label}</span>
                </div>
                {isActive && (
                  <div className="w-1 h-1 rounded-full bg-[hsl(var(--ghost-accent))]" />
                )}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};

export default GhostThemeToggle;