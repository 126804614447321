/**
 * @file src/components/cloud-drive/items/components/ItemInfo.tsx
 * @description Item information display component
 * @version 1.0.0
 */

import React from 'react';
import { format } from 'date-fns';
import { MoreHorizontal } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';
import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';

interface ItemInfoProps {
  item: DriveFile | DriveFolder;
  view: 'grid' | 'list';
  showOptions?: boolean;
  className?: string;
}

export const ItemInfo: React.FC<ItemInfoProps> = ({
  item,
  view,
  showOptions = true,
  className
}) => {
  if (view === 'grid') {
    return (
      <div className={cn("p-3 space-y-1", className)}>
        <p className="font-medium truncate text-gray-900 dark:text-gray-100">
          {item.name}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-400">
          {item.type === 'folder'
            ? `${(item as DriveFolder).folder_info?.total_items?.toLocaleString() || 
                 (item as DriveFolder).itemCount?.toLocaleString() || '0'} items`
            : format(new Date(item.modified), 'MMM d, yyyy')}
        </p>
      </div>
    );
  }

  return (
    <>
      <div className={cn("flex-1 min-w-0", className)}>
        <p className="font-medium truncate text-gray-900 dark:text-gray-100">
          {item.name}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Modified {format(new Date(item.modified), 'MMM d, yyyy')}
        </p>
      </div>

      <div className="flex items-center gap-4 flex-shrink-0 text-sm text-gray-500 dark:text-gray-400">
        {item.type === 'folder' ? (
          <span>{(item as DriveFolder).folder_info?.total_items?.toLocaleString() || 
                 (item as DriveFolder).itemCount?.toLocaleString() || '0'} items</span>
        ) : (
          <span>{Math.round((item as DriveFile).size / 1024).toLocaleString()} KB</span>
        )}
        
        {showOptions && (
          <Button
            variant="ghost"
            size="icon"
            className="opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        )}
      </div>
    </>
  );
};

export default ItemInfo;