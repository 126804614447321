/**
 * @file src/components/theme/theme-toggle.tsx
 * @description Theme toggle button with improved animation and text display
 * @version 1.2.0
 * @created 2024-12-05
 */

import { Moon, Sun } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "@/components/theme/theme-provider";
import { useUIStore } from "@/stores/uiStore";
import { cn } from "@/utils/utils";

export function ThemeToggle() {
  const { theme, setTheme } = useTheme();
  const { isSidebarCollapsed } = useUIStore();
  const isDark = theme === "dark";

  return (
    <button
      onClick={() => setTheme(isDark ? "light" : "dark")}
      className={cn(
        "flex items-center w-full",
        "px-3 py-2",
        "rounded-xl transition-all duration-300",
        "text-gray-700 dark:text-gray-300",
        "hover:bg-gray-50 dark:hover:bg-gray-800/50",
        "text-sm relative"
      )}
    >
      <div className="w-7 h-7 flex items-center justify-center text-gray-500 dark:text-gray-400">
        <motion.div
          initial={false}
          animate={{
            scale: isDark ? 0 : 1,
            opacity: isDark ? 0 : 1,
            rotate: isDark ? 90 : 0,
          }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          className="absolute"
        >
          <Sun className="w-5 h-5 text-amber-500" />
        </motion.div>
        
        <motion.div
          initial={false}
          animate={{
            scale: isDark ? 1 : 0,
            opacity: isDark ? 1 : 0,
            rotate: isDark ? 0 : -90,
          }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          className="absolute"
        >
          <Moon className="w-5 h-5 text-blue-400" />
        </motion.div>
      </div>
      <AnimatePresence>
        {!isSidebarCollapsed && (
          <motion.span
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            className="ml-2 font-medium"
          >
            {isDark ? 'Dark Mode' : 'Light Mode'}
          </motion.span>
        )}
      </AnimatePresence>
    </button>
  );
}