/**
 * @file src/components/services/ServiceSearchBar.tsx
 * @description Search bar component for filtering services
 * @version 1.0.0
 * @created 2025-02-28
 * @dependencies lucide-react, react-i18next
 */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Search, X } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface ServiceSearchBarProps {
  onSearch: (query: string) => void;
  initialQuery?: string;
  className?: string;
}

export const ServiceSearchBar: React.FC<ServiceSearchBarProps> = ({
  onSearch,
  initialQuery = '',
  className
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState(initialQuery);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    // Update search when query changes
    const timeoutId = setTimeout(() => {
      logger.debug('Service search query changed', {
        component: 'ServiceSearchBar',
        query,
        action: 'search'
      });
      onSearch(query);
    }, 300); // Debounce search for better performance

    return () => clearTimeout(timeoutId);
  }, [query, onSearch]);

  const handleClear = () => {
    logger.debug('Clearing search query', {
      component: 'ServiceSearchBar',
      action: 'clear'
    });
    setQuery('');
    onSearch('');
  };

  return (
    <div className={cn("relative w-full max-w-md", className)}>
      <Search 
        className={cn(
          "absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4",
          "text-gray-400 transition-colors duration-200",
          isFocused && "text-blue-500"
        )} 
      />
      
      <Input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={t('services.search.placeholder')}
        className={cn(
          "pl-9 pr-8 h-10",
          "bg-gray-50 dark:bg-gray-800",
          "border-transparent",
          "focus:border-blue-500 focus:ring-blue-500/20",
          "transition-all duration-200",
          "w-full"
        )}
      />
      
      {query && (
        <Button
          variant="ghost"
          size="sm"
          onClick={handleClear}
          className="absolute right-2 top-1/2 -translate-y-1/2 h-6 w-6 p-0"
        >
          <X className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default ServiceSearchBar;