/**
 * @file src/components/ui/checkbox.tsx
 * @description Checkbox component using Radix UI with proper indeterminate handling
 * @version 1.1.0
 */

import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import { cn } from "@/utils/utils";

interface CheckboxProps extends Omit<CheckboxPrimitive.CheckboxProps, 'indeterminate'> {
  className?: string;
  indeterminate?: boolean;
}

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ className, indeterminate, ...props }, ref) => {
    // Create internal ref if external one isn't provided
    const internalRef = React.useRef<HTMLButtonElement>(null);
    const checkboxRef = (ref as React.RefObject<HTMLButtonElement>) || internalRef;
    
    // Handle indeterminate state using ref
    React.useEffect(() => {
      if (checkboxRef.current) {
        // Explicitly cast to HTMLInputElement to access indeterminate property
        const inputElement = checkboxRef.current as unknown as HTMLInputElement;
        if (inputElement.indeterminate !== undefined) {
          inputElement.indeterminate = !!indeterminate;
        }
      }
    }, [indeterminate, checkboxRef]);

    return (
      <CheckboxPrimitive.Root
        ref={checkboxRef}
        className={cn(
          "peer h-4 w-4 shrink-0 rounded-sm border border-gray-200",
          "ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2",
          "disabled:cursor-not-allowed disabled:opacity-50",
          "data-[state=checked]:bg-gray-900 data-[state=checked]:text-gray-50",
          "dark:border-gray-800 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300",
          "dark:data-[state=checked]:bg-gray-50 dark:data-[state=checked]:text-gray-900",
          indeterminate && "data-[state=unchecked]:bg-gray-300 dark:data-[state=unchecked]:bg-gray-700",
          className
        )}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cn("flex items-center justify-center text-current")}
        >
          <Check className="h-4 w-4" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    )
  }
)

Checkbox.displayName = "Checkbox";

export { Checkbox };
