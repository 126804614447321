/**
 * @file src/context/UploadContext.tsx
 * @description Upload context provider for global upload state
 * @version 1.2.0
 */

import { createContext, useContext } from 'react';
import type { UploadFile, EncryptionProgress } from '@/types/upload.types';

interface EncryptedUploadOptions {
  encryptionKey: string;
  metadataKey?: string;
  chunked?: boolean;
}

// Define the context value interface
interface UploadContextValue {
  // State
  queue: UploadFile[];
  isVisible: boolean;
  isMinimized: boolean;
  isAcceptingFiles: boolean;
  activeUpload: string | null;

  // Methods
  addFiles: (
    files: File[], 
    parentId: string | null | undefined, 
    driveId: string,
    encryptedOptions?: EncryptedUploadOptions
  ) => Promise<void>;

  // Add encryption-specific methods
  updateEncryptionProgress: (
    fileId: string, 
    progress: EncryptionProgress
  ) => void;

  removeFile: (fileId: string) => void;
  clearCompleted: () => void;
  cancelUpload: (fileId: string) => void;
  setIsVisible: (visible: boolean) => void;
  setIsMinimized: (minimized: boolean) => void;
  setAcceptingFiles: (accepting: boolean) => void;
  pauseUpload?: (fileId: string) => void;
  resumeUpload?: (fileId: string) => void;
}

// Create the context
export const UploadContext = createContext<UploadContextValue | undefined>(undefined);

// Context hook
export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error('useUploadContext must be used within an UploadProvider');
  }
  return context;
};