/**
 * @file src/components/cloud-drive/dialogs/DeleteDialog.tsx
 * @description Dialog for confirming item deletion
 * @version 1.0.0
 * @created 2024-12-24
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Trash2, AlertTriangle } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';

interface DeleteDialogProps {
  selectedCount: number;
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  isPermanent?: boolean;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  selectedCount,
  isOpen,
  onClose,
  onDelete,
  isPermanent = false
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDelete = async () => {
    setError(null);
    setIsLoading(true);
  
    try {
      await onDelete();
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : t('common.error.delete'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center">
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: [0, -10, 10, -10, 10, 0] }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="mr-2"
            >
              <div className={cn(
                "p-2 rounded-full",
                "bg-red-100 dark:bg-red-900/20"
              )}>
                <Trash2 className="h-5 w-5 text-red-500" />
              </div>
            </motion.div>
            {isPermanent 
              ? t('cloud_drive.dialogs.delete.permanent_title', { count: selectedCount })
              : t('cloud_drive.dialogs.delete.title', { count: selectedCount })}
          </DialogTitle>
          <DialogDescription className="pt-4">
          {isPermanent ? (
            <div className="flex items-start space-x-2 text-red-500">
              <AlertTriangle className="h-5 w-5 flex-shrink-0 mt-0.5" />
              <div>
                <p className="font-medium">
                  {t('cloud_drive.dialogs.delete.permanent_warning')}
                </p>
                <p className="text-sm text-red-400 mt-1">
                  {t('cloud_drive.dialogs.delete.permanent_description', { count: selectedCount })}
                </p>
              </div>
            </div>
          ) : (
            <p>{t('cloud_drive.dialogs.delete.description', { count: selectedCount })}</p>
          )}
          </DialogDescription>
        </DialogHeader>

        {error && (
          <div className="text-sm text-red-500 bg-red-50 dark:bg-red-900/20 p-3 rounded-md">
            {error}
          </div>
        )}

        <DialogFooter>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 w-full sm:w-auto">
            <Button
              variant="outline"
              onClick={onClose}
              disabled={isLoading}
              className="sm:order-1"
            >
              {t('common.cancel')}
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              disabled={isLoading}
              className={cn(
                "w-full sm:w-auto",
                isPermanent && "bg-red-600 hover:bg-red-700"
              )}
            >
              {isLoading ? t('common.deleting') : (
                isPermanent 
                  ? t('cloud_drive.dialogs.delete.permanent_action')
                  : t('cloud_drive.dialogs.delete.move_to_trash')
              )}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialog;