/**
 * @file src/context/cloud-drive/types.ts
 * @description Type definitions for cloud drive context
 * @version 2.0.0
 */

import type { UseInfiniteQueryResult, UseQueryResult, InfiniteData } from '@tanstack/react-query';
import type { DecryptionStatus } from '@/services/encrypted-drive/encrypted-files.service';
import { EncryptionTier } from '@/types/encrypted-drive.types';
import type { DriveFolder, DriveItemUser } from '@/types/cloud-drive.types';
import type { UserLabel } from '@/types/user.types';

export type DriveItemType = 'file' | 'folder';
export type ViewMode = 'grid' | 'list';
export type ViewDisplayType = 'list' | 'grid' | 'media' | 'shared' | 'favorites';
export type SortField = 'name' | 'modified' | 'size' | 'type' | 'favorited_at';
export type SortOrder = 'asc' | 'desc';

export interface SortConfig {
  field: SortField;
  order: SortOrder;
}

export interface FilterConfig {
  search?: string;
  types?: DriveItemType[];
  dateRange?: {
    start: string;
    end: string;
  };
  shared?: boolean;
  favorite?: boolean;
  labels?: string[];
}

export interface BreadcrumbItem {
  id: string;
  name: string;
  path: string[];
}

export interface DriveItem {
  id: string;
  name: string;
  type: DriveItemType;
  parentId: string | null;
  path: string[];
  created: string;
  modified: string;
  createdBy: DriveItemUser;
  modifiedBy: DriveItemUser;
  shared: boolean;
  favorite: boolean;
  labels: UserLabel[];
}

export interface DriveItemsResponse {
  items: DriveItem[];
  total: number;
  hasMore: boolean;
  nextCursor?: string;
  driveId?: string;
  totalItems?: number;
}

export interface EncryptedDriveState {
  isEncryptedMode: boolean;
  encryptedDriveId?: string;
  encryptionTier?: EncryptionTier;
  driveName?: string;  // Add this
  setSelectedItem?: (item: DriveItem) => void; // Add this for selection tracking
}

export interface CloudDriveContextValue {
  // State
  currentFolderContents: DriveItem[];
  currentFolder: string | null;
  breadcrumbs: BreadcrumbItem[];
  selectedItems: string[];
  viewMode: ViewMode;
  viewDisplay: ViewDisplayType; // Add this
  sortConfig: SortConfig;
  filterConfig: FilterConfig;
  isFolderDialogOpen: boolean;
  selectedFile: string | null;
  isInfoPanelOpen: boolean;
  selectedItemId: string | null;
  selectedItem: DriveItem | null;
  activeDriveId: string | null;

  // Add encryption state
  encryptedDrive: EncryptedDriveState;
  setEncryptedDrive: (state: Partial<EncryptedDriveState>) => void;
  clearEncryptedDriveState: () => void;
  
  // View Actions
  setViewMode: (mode: ViewMode) => void;
  setViewDisplay: (display: ViewDisplayType) => void; // Add this
  setSortConfig: (config: SortConfig) => void;
  setFilterConfig: (config: FilterConfig) => void;
  
  // Selection
  clearSelection: () => void;
  toggleItemSelection: (itemId: string) => void;
  
  // Dialog Controls
  openFolderDialog: () => void;
  closeFolderDialog: () => void;
  setCurrentFolder: (id: string | null) => void;
  
  // File Operations
  uploadFiles: (files: File[], targetFolderId?: string | null) => Promise<void>;
  createFolder: (name: string) => Promise<DriveFolder>; // updated
  deleteItems: (itemIds: string[]) => Promise<void>;
  renameItem: (itemId: string, newName: string) => Promise<void>;
  moveItems: (itemIds: string[], targetFolderId: string) => Promise<void>;
  copyItems: (itemIds: string[], targetFolderId: string) => Promise<void>;
  toggleFavorite: (itemId: string) => Promise<void>;
  
  // Info Panel State
  setIsInfoPanelOpen: (isOpen: boolean) => void;
  setSelectedItemId: (id: string | null) => void;
  setCurrentFolderContents: (items: DriveItem[]) => void;

  // Queries
  getFolderContents: (folderId: string | null) => UseInfiniteQueryResult<InfiniteData<DriveItemsResponse>, Error>;
  getFolderPath: (folderId: string | null) => UseQueryResult<BreadcrumbItem[], Error>;
  
  decryptionStatus: DecryptionStatus;
  updateDecryptionStatus: (updates: Partial<DecryptionStatus>) => void;
  forceRefreshFolderContents: () => void;

}

// Default values for context
export const defaultContextValue: Partial<CloudDriveContextValue> = {
  currentFolderContents: [],
  currentFolder: null,
  breadcrumbs: [{ id: 'root', name: 'Cloud Drive', path: ['root'] }],
  selectedItems: [],
  viewMode: 'grid',
  viewDisplay: 'grid', // Add this
  sortConfig: { field: 'name', order: 'asc' },
  filterConfig: {},
  isFolderDialogOpen: false,
  selectedFile: null,
  isInfoPanelOpen: false,
  selectedItemId: null,
  selectedItem: null,
};