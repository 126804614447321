/**
 * @file src/components/layout/sidebar/animations.ts
 * @description Animation configurations for sidebar components
 * @version 1.0.0
 * @created 2024-12-05
 */

export const sidebarVariants = {
    expanded: {
      width: 240,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 25
      }
    },
    collapsed: {
      width: 68,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 25
      }
    }
  };
  
  export const buttonHoverAnimation = {
    scale: 1.02,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 10
    }
  };