/**
 * @file src/components/layout/sidebar/Sidebar.tsx
 * @description Main sidebar container component
 * @version 1.0.0
 * @created 2024-12-05
 * @dependencies framer-motion, lucide-react
 */

import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Database, ChevronLeft } from 'lucide-react';
import { cn } from '@/utils/utils';
import { useUIStore } from '@/stores/uiStore';
// import { useMediaQuery } from '@/hooks/useMediaQuery';
import SidebarNav from './SidebarNav';
import SidebarUtils from './SidebarUtils';
import { sidebarVariants } from './animations';
import type { TooltipPosition } from './types';

const Sidebar = () => {
  const { isSidebarCollapsed, setSidebarCollapsed } = useUIStore();
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition | null>(null);
  // const isMobile = useMediaQuery('(max-width: 768px)');

  const updateTooltipPosition = (id: string, element: HTMLDivElement | null) => {
    if (element) {
      const rect = element.getBoundingClientRect();
      setTooltipPosition({
        id,
        top: rect.top + rect.height / 2
      });
    }
  };

  return (
    <motion.div 
      variants={sidebarVariants}
      initial={isSidebarCollapsed ? "collapsed" : "expanded"}
      animate={isSidebarCollapsed ? "collapsed" : "expanded"}
      className={cn(
        "relative min-h-screen",
        "border-r border-gray-100 dark:border-gray-800",
        "bg-white dark:bg-gray-900",
        "flex flex-col",
        "shadow-sm",
        "z-20"
      )}
    >
      {/* Tooltip */}
      <AnimatePresence>
        {isSidebarCollapsed && hoveredItem && tooltipPosition && (
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="fixed left-16 px-3 py-1.5 bg-gray-900 dark:bg-gray-800 text-white text-xs rounded-lg shadow-lg backdrop-blur-sm whitespace-nowrap z-[100]"
            style={{
              top: tooltipPosition.top,
              transform: 'translateY(-50%)'
            }}
          >
            {hoveredItem}
          </motion.div>
        )}
      </AnimatePresence>      

      {/* Collapse Toggle Button */}
      <motion.button
        onClick={() => setSidebarCollapsed(!isSidebarCollapsed)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        className={cn(
          "absolute -right-3 top-6",
          "bg-white dark:bg-gray-900",
          "rounded-full p-1.5",
          "shadow-md hover:shadow-lg",
          "border border-gray-100 dark:border-gray-800",
          "hover:border-blue-200 dark:hover:border-blue-800",
          "transition-all duration-300",
          "z-50",
          "text-gray-600 dark:text-gray-300",
          "hover:text-blue-500 dark:hover:text-blue-400"
        )}
      >
        <motion.div
          initial={{ rotate: isSidebarCollapsed ? 0 : 180 }}
          animate={{ rotate: isSidebarCollapsed ? 0 : 180 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronLeft className="h-4 w-4 stroke-[2]" />
        </motion.div>
      </motion.button>

      {/* Logo Section */}
      <motion.div 
        className="p-4 flex items-center"
        whileHover={{ scale: 1.02 }}
      >
        <div className="relative">
          <Database className="h-6 w-6 text-blue-500" />
          <motion.div 
            className="absolute -bottom-0.5 -right-0.5 w-2 h-2 bg-emerald-400 rounded-full"
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 2, repeat: Infinity }}
          />
        </div>
        <AnimatePresence>
          {!isSidebarCollapsed && (
            <motion.span
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -10 }}
              className="ml-3 font-semibold bg-gradient-to-r from-blue-600 to-blue-400 
                        bg-clip-text text-transparent"
            >
              BigMIND
            </motion.span>
          )}
        </AnimatePresence>
      </motion.div>

      {/* Navigation Section */}
      <SidebarNav 
        isSidebarCollapsed={isSidebarCollapsed}
        setHoveredItem={setHoveredItem}
        updateTooltipPosition={updateTooltipPosition}
      />

      {/* Utils Section */}
      <div className="px-2 py-4">
        <SidebarUtils 
          isSidebarCollapsed={isSidebarCollapsed}
          setHoveredItem={setHoveredItem}
          updateTooltipPosition={updateTooltipPosition}
        />
      </div>
    </motion.div>
  );
};

export default Sidebar;