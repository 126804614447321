/**
 * @file src/components/upload/GlobalUploadQueue/index.tsx
 * @description Enhanced upload queue with RootDrive integration
 * @version 2.1.0
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useUploadContext } from '@/context/UploadContext';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/components/ui/toast';
import { useAccount } from '@/context/AccountContext';
import { useNavigation } from '@/context/NavigationContext';
import { useAnimation } from '@/hooks/useAnimation';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { UPLOAD_CONFIG } from '@/config/upload.config';
import { UploadQueueHeader } from './UploadQueueHeader';
import { UploadQueueList } from './UploadQueueList';
import { UploadQueueFooter } from './UploadQueueFooter';
import { Upload } from 'lucide-react';

// Safe version of useAccount that doesn't throw when no provider exists
const useSafeAccount = () => {
  try {
    return useAccount();
  } catch (error) {
    logger.debug('Account context not available for GlobalUploadQueue', {
      component: 'GlobalUploadQueue'
    });
    return { accountInfo: null };
  }
};

// Safe version of useUploadContext that doesn't throw when provider is missing
const useSafeUploadContext = () => {
  try {
    return useUploadContext();
  } catch (error) {
    logger.debug('Upload context not available for GlobalUploadQueue', {
      component: 'GlobalUploadQueue',
      error: error instanceof Error ? error.message : String(error)
    });
    // Return a stub implementation with default values
    return {
      isVisible: false,
      isMinimized: false,
      isAcceptingFiles: false,
      queue: [],
      activeUpload: null,
      addFiles: () => Promise.resolve(),
      removeFile: () => {},
      clearCompleted: () => {},
      cancelUpload: () => {},
      updateEncryptionProgress: () => {},
      setIsVisible: () => {},
      setIsMinimized: () => {},
      setAcceptingFiles: () => {}
    };
  }
};


export const GlobalUploadQueue: React.FC = () => {
  const { t } = useTranslation();
  const { presets } = useAnimation('slideUp', 'default');
  const { showToast } = useToast();
 
  // Use the safe version of useAccount
  const { accountInfo } = useSafeAccount();
  
  // Get complete drive state
  const { activeDriveId, primaryDriveId, systemDevice } = useRootDrive();
  
  // Use safe version of the context with fallback values
  const { 
    isVisible, 
    isMinimized,
    queue,
    addFiles,
    isAcceptingFiles,
    setIsVisible
  } = useSafeUploadContext();
  
  // Track if there are any unlocked drives
  const hasAvailableDrive = !!activeDriveId || !!primaryDriveId;
  
  // Get current folder ID from navigation context
  const { currentFolderId } = useNavigation();

  // Effect to hide upload panel if no drives are available
  useEffect(() => {
    if (!hasAvailableDrive && isVisible) {
      logger.debug('No drives available, hiding upload panel', {
        component: 'GlobalUploadQueue',
        action: 'driveCheck'
      });
      setIsVisible(false);
    }
  }, [hasAvailableDrive, isVisible, setIsVisible]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  // Calculate how many concurrent uploads are allowed
  const getConcurrentLimit = useCallback(() => {
    // Check if user has a paid plan
    const isPaidUser = accountInfo?.subscription?.status === 'ACTIVE' && 
                      accountInfo?.subscription?.plan !== 'Nygma Free' &&
                      accountInfo?.subscription?.plan !== 'Free';
    
    return isPaidUser ? 
      UPLOAD_CONFIG.concurrentUploads?.paid || 3 : 
      UPLOAD_CONFIG.concurrentUploads?.free || 1;
  }, [accountInfo]);

  // Add useEffect to log drive state
  useEffect(() => {
    logger.debug('Drive state updated', {
      component: 'GlobalUploadQueue',
      data: {
        activeDriveId,
        primaryDriveId,
        hasSystemDevice: !!systemDevice,
        queueLength: queue.length,
        concurrentUploads: getConcurrentLimit()
      }
    });
  }, [activeDriveId, primaryDriveId, systemDevice, queue.length, getConcurrentLimit]);

  // Enhanced file selection handling with proper folder ID tracking
  const handleFileSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const effectiveDriveId = activeDriveId || primaryDriveId;
    
    // Get the most current folder ID to ensure it's up-to-date
    const targetFolderId = currentFolderId;
    
    if (!effectiveDriveId) {
      logger.error('File selection blocked: No drive available', {
        component: 'GlobalUploadQueue',
        action: 'handleFileSelect',
        data: {
          activeDriveId,
          primaryDriveId,
          hasSystemDevice: !!systemDevice
        }
      });
      showToast(t('upload.no_drive_selected'), 'error');
      return;
    }
  
    const files = Array.from(event.target.files || []);
    if (files.length) {
      logger.debug('Files selected', {
        component: 'GlobalUploadQueue',
        action: 'handleFileSelect',
        data: {
          fileCount: files.length,
          driveId: effectiveDriveId,
          concurrentLimit: getConcurrentLimit(),
          parentFolderId: targetFolderId || ''
        }
      });

      // Use the current folder ID from navigation context, ensure it's definitive
      addFiles(files, targetFolderId || '', effectiveDriveId);
    }
    
    // Reset input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [activeDriveId, primaryDriveId, systemDevice, addFiles, showToast, t, getConcurrentLimit, currentFolderId]);

  const handleDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, []);

  // Enhanced drop handling with drive validation and better folder tracking
  const handleDrop = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const effectiveDriveId = activeDriveId || primaryDriveId;
    
    // Get the most current folder ID to ensure it's up-to-date
    const targetFolderId = currentFolderId;
  
    if (!effectiveDriveId) {
      logger.error('File drop blocked: No drive available', {
        component: 'GlobalUploadQueue',
        action: 'handleDrop',
        data: {
          activeDriveId,
          primaryDriveId
        }
      });
      showToast(t('upload.no_drive_selected'), 'error');
      return;
    }

    if (!isAcceptingFiles) {
      logger.debug('Upload blocked: Not accepting files', {
        component: 'GlobalUploadQueue',
        action: 'handleDrop'
      });
      return;
    }
  
    const files = Array.from(event.dataTransfer.files);
    if (files.length) {
      logger.debug('Files dropped', {
        component: 'GlobalUploadQueue',
        action: 'handleDrop',
        data: {
          fileCount: files.length,
          driveId: effectiveDriveId,
          concurrentLimit: getConcurrentLimit(),
          parentFolderId: targetFolderId || ''
        }
      });

      // Use the current folder ID from navigation context, ensure it's definitive
      addFiles(files, targetFolderId || '', effectiveDriveId);
    }
  }, [activeDriveId, primaryDriveId, addFiles, showToast, t, isAcceptingFiles, getConcurrentLimit, currentFolderId]);

  if (!isVisible) return null;

  const isPaidUser = accountInfo?.subscription?.status === 'ACTIVE' && 
                    accountInfo?.subscription?.plan !== 'Nygma Free' &&
                    accountInfo?.subscription?.plan !== 'Free';

  return (
    <motion.div
      {...presets.slideUpDown}
      className={cn(
        "fixed z-50",
        "right-3 sm:right-5 bottom-3 sm:bottom-5",
        "w-[calc(100%-24px)] sm:w-[400px]", // Responsive width
        "max-w-[400px]", // Don't grow too wide
        "bg-white dark:bg-gray-900",
        "rounded-xl shadow-xl",
        "border border-gray-200 dark:border-gray-800",
        isDragging && "ring-2 ring-blue-500",
        "flex flex-col",
        "overflow-hidden"
      )}
      style={{
        height: isMinimized ? '64px' : '400px',
      }}
      data-testid="global-upload-queue"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {/* Hidden file input */}
      <input
        ref={fileInputRef}
        type="file"
        multiple
        className="hidden"
        onChange={handleFileSelect}
        accept={UPLOAD_CONFIG.acceptedFiles}
      />
  
      {/* Header */}
      <UploadQueueHeader 
        fileInputRef={fileInputRef} 
        concurrentLimit={getConcurrentLimit()}
        isPaidUser={isPaidUser}
      />
  
      {/* Main content area with flexible height */}
      {!isMinimized && (
        <div className="flex-1 relative overflow-hidden">
          {/* Drive selection overlay */}
          <AnimatePresence>
            {!activeDriveId && !primaryDriveId && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={cn(
                  "absolute inset-0",
                  "flex items-center justify-center",
                  "bg-white/90 dark:bg-gray-900/90",
                  "backdrop-blur-sm",
                  "z-[1]"
                )}
              >
                <div className="text-center px-4">
                  <Upload className="h-8 w-8 text-gray-400 mx-auto mb-2" />
                  <p className="text-sm text-gray-600 dark:text-gray-400 font-medium">
                    {t('upload.select_drive_prompt')}
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
  
          {/* Rest of the component remains the same */}
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="h-full"
            >
              {/* Drag overlay */}
              <AnimatePresence>
                {isDragging && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={cn(
                      "absolute inset-0 z-10",
                      "bg-blue-500/10 dark:bg-blue-500/20",
                      "border-2 border-dashed border-blue-500",
                      "rounded-lg m-2",
                      "flex items-center justify-center"
                    )}
                  >
                    <p className="text-blue-500 font-medium">
                      {t('upload.drop_files')}
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
  
              {/* Queue list */}
              <ScrollArea className="h-full">
                <div className="py-2">
                  <UploadQueueList />
                </div>
              </ScrollArea>
            </motion.div>
          </AnimatePresence>
        </div>
      )}
  
      {/* Footer - Only show when not minimized */}
      {!isMinimized && <UploadQueueFooter fileInputRef={fileInputRef} />}
    </motion.div>
  );
};