/**
 * @file src/components/cloud-drive/loading/NavigationLoader.tsx
 * @description Loading overlay for folder navigation transitions
 * @version 1.0.0
 */

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { cn } from '@/utils/utils';

interface NavigationLoaderProps {
  isLoading: boolean;
  message?: string;
}

export const NavigationLoader: React.FC<NavigationLoaderProps> = ({
  isLoading,
  message = 'Loading folder contents...'
}) => {
  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn(
            "absolute inset-0 z-50",
            "bg-white/50 dark:bg-gray-900/50",
            "backdrop-blur-sm",
            "flex items-center justify-center"
          )}
        >
          <div className="flex flex-col items-center gap-3">
            <Loader2 className="h-8 w-8 text-blue-500 animate-spin" />
            <p className="text-sm text-gray-600 dark:text-gray-300">
              {message}
            </p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};