/**
 * @file src/components/audit/table/AuditTable.tsx
 * @description Audit table component with infinite scroll
 * @version 2.3.0
 * @created 2024-02-24
 * @updated 2025-02-26
 */

import React from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { ArrowUpDown, Loader2 } from "lucide-react";
import { format } from "date-fns";
import { cn } from "@/lib/utils";
import type { AuditLog } from "@/types/audit.types";

interface AuditTableProps {
  data: AuditLog[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  onSortChange?: (column: string, direction: "asc" | "desc") => void;
  totalCount?: number;
  className?: string;
  currentSortColumn?: string;
  currentSortDirection?: "asc" | "desc";
  paginationStatus?: {
    loaded: number;
    total: number;
    percentage: number;
  };
}

// Define column names that can be sorted
type SortableColumn = "timestamp" | "actor" | "action" | "target";

// Mapping component column names to API column names
const COLUMN_MAPPING: Record<string, string> = {
  timestamp: "timestamp",
  actor: "actor",
  action: "action",
  target: "target",
};

export const AuditTable: React.FC<AuditTableProps> = ({
  data,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  onSortChange,
  totalCount,
  className,
  currentSortColumn = "timestamp",
  currentSortDirection = "desc",
}) => {
  const { t } = useTranslation();

  // Configure InView for infinite scrolling with increased sensitivity
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px", // Increased margin to detect earlier
    triggerOnce: false, // Allow multiple triggers
  });

  // Store last sort configuration for comparison to prevent unnecessary rerenders
  const lastSortRef = React.useRef({
    column: currentSortColumn as SortableColumn,
    direction: currentSortDirection,
  });

  // Track if sorting has changed - for auto-loading more data after sort
  const [sortChanged, setSortChanged] = React.useState(false);

  // Maintain internal sort state synchronized with props
  const [sortConfig, setSortConfig] = React.useState<{
    column: SortableColumn;
    direction: "asc" | "desc";
  }>({
    column: (currentSortColumn || "timestamp") as SortableColumn,
    direction: currentSortDirection || "desc",
  });

  // Update internal state when props change
  React.useEffect(() => {
    if (currentSortColumn && currentSortDirection) {
      // Check if sorting has changed
      if (
        sortConfig.column !== (currentSortColumn as SortableColumn) ||
        sortConfig.direction !== currentSortDirection
      ) {
        console.log("🔍 Updating sort config from props:", {
          column: currentSortColumn,
          direction: currentSortDirection,
        });

        setSortConfig({
          column: currentSortColumn as SortableColumn,
          direction: currentSortDirection,
        });

        // Set the sortChanged flag to trigger auto-loading
        setSortChanged(true);
      }
    }
  }, [currentSortColumn, currentSortDirection, sortConfig]);

  // Auto-load more data after sort change
  React.useEffect(() => {
    if (sortChanged && hasNextPage && !isFetchingNextPage && data.length > 0) {
      // If we have very few records after sorting, automatically load more
      const percentLoaded = totalCount ? (data.length / totalCount) * 100 : 0;

      if (percentLoaded < 20) {
        console.log(
          "🔍 Auto-loading more data after sort (low percentage loaded):",
          percentLoaded.toFixed(1) + "%"
        );

        // Clear the sortChanged flag after a short delay
        const timer = setTimeout(() => {
          fetchNextPage();
          setSortChanged(false);
        }, 500);

        return () => clearTimeout(timer);
      } else {
        // Reset the flag if we don't need to auto-load
        setSortChanged(false);
      }
    }
  }, [
    sortChanged,
    data.length,
    totalCount,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  ]);

  // Track when we need to fetch more data
  React.useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && data.length > 0) {
      console.log("🔍 Auto-loading next page due to scroll position");
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage, data.length]);

  // Handle sort change
  const handleSort = (column: SortableColumn) => {
    // Determine new direction
    let newDirection: "asc" | "desc";

    if (sortConfig.column === column) {
      // Toggle direction if same column
      newDirection = sortConfig.direction === "asc" ? "desc" : "asc";
    } else {
      // Default to ascending for new column
      newDirection = "asc";
    }

    console.log("🔍 Sort clicked:", { column, newDirection });

    // Update last sort config
    lastSortRef.current = {
      column,
      direction: newDirection,
    };

    // Update local state first for immediate UI feedback
    setSortConfig({
      column,
      direction: newDirection,
    });

    // Call parent handler with mapped column name
    if (onSortChange) {
      const apiColumnName = COLUMN_MAPPING[column] || column;
      console.log("🔍 Propagating sort change to parent:", {
        column,
        apiColumnName,
        direction: newDirection,
      });
      onSortChange(apiColumnName, newDirection);
    }
  };

  // Format date
  const formatDate = (date: string) => {
    try {
      return format(new Date(date), "MMM d, yyyy HH:mm:ss");
    } catch (error) {
      console.error("Error formatting date:", error);
      return date;
    }
  };

  // Get safe data array
  const safeData = Array.isArray(data) ? data : [];

  // Calculate percentage of loaded data
  const percentLoaded = React.useMemo(() => {
    if (!totalCount || totalCount === 0) return 0;
    // Use precision to show decimal points for percentages
    return (safeData.length / totalCount) * 100;
  }, [safeData.length, totalCount]);

  // Track manual load attempts
  const [manualLoadCount, setManualLoadCount] = React.useState(0);

  // Log component render
  React.useEffect(() => {
    console.log("🔍 AuditTable render:", {
      dataLength: safeData.length,
      isLoading,
      isFetchingNextPage,
      hasNextPage,
      totalCount,
      sortConfig,
      percentLoaded,
    });
  }, [
    safeData.length,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    totalCount,
    sortConfig,
    percentLoaded,
  ]);

  // Manual fetch handler - useful for debugging or when auto-fetch fails
  const handleManualFetch = () => {
    console.log("🔍 Manual fetch triggered", {
      manualLoadCount: manualLoadCount + 1,
    });
    setManualLoadCount((prev) => prev + 1);
    fetchNextPage();
  };

  return (
    <div className={cn("h-full flex flex-col", className)}>
      <div className="overflow-auto flex-1">
        <Table>
          <TableHeader className="sticky top-0 bg-white dark:bg-gray-900 z-10">
            <TableRow className="bg-gray-50/50 dark:bg-gray-800/50 hover:bg-gray-50/50 dark:hover:bg-gray-800/50">
              <TableHead
                className="cursor-pointer w-[180px]"
                onClick={() => handleSort("timestamp")}
              >
                <div className="flex items-center space-x-2">
                  <span>
                    {t("audit.table.dateTime", "Date & Time \u000A(GMT +2:00)")}
                  </span>
                  <ArrowUpDown
                    className={cn(
                      "h-4 w-4",
                      sortConfig.column === "timestamp"
                        ? "text-gray-900 dark:text-gray-100"
                        : "text-gray-400 dark:text-gray-600"
                    )}
                  />
                </div>
              </TableHead>
              <TableHead
                className="cursor-pointer min-w-[180px]"
                onClick={() => handleSort("actor")}
              >
                <div className="flex items-center space-x-2">
                  <span>{t("audit.table.actor", "Actor")}</span>
                  <ArrowUpDown
                    className={cn(
                      "h-4 w-4",
                      sortConfig.column === "actor"
                        ? "text-gray-900 dark:text-gray-100"
                        : "text-gray-400 dark:text-gray-600"
                    )}
                  />
                </div>
              </TableHead>
              <TableHead
                className="cursor-pointer min-w-[180px]"
                onClick={() => handleSort("action")}
              >
                <div className="flex items-center space-x-2">
                  <span>{t("audit.table.action", "Action")}</span>
                  <ArrowUpDown
                    className={cn(
                      "h-4 w-4",
                      sortConfig.column === "action"
                        ? "text-gray-900 dark:text-gray-100"
                        : "text-gray-400 dark:text-gray-600"
                    )}
                  />
                </div>
              </TableHead>
              <TableHead
                className="cursor-pointer min-w-[300px]"
                onClick={() => handleSort("target")}
              >
                <div className="flex items-center space-x-2">
                  <span>{t("audit.table.target", "Target")}</span>
                  <ArrowUpDown
                    className={cn(
                      "h-4 w-4",
                      sortConfig.column === "target"
                        ? "text-gray-900 dark:text-gray-100"
                        : "text-gray-400 dark:text-gray-600"
                    )}
                  />
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading && safeData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} className="h-24 text-center">
                  <div className="flex items-center justify-center">
                    <Loader2 className="h-6 w-6 animate-spin text-gray-500 dark:text-gray-400 mr-2" />
                    <span>{t("audit.table.loading", "Loading...")}</span>
                  </div>
                </TableCell>
              </TableRow>
            ) : safeData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} className="h-24 text-center">
                  <div className="text-gray-500 dark:text-gray-400">
                    {t("audit.table.noData", "No data found")}
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {safeData.map((log, index) => (
                  <TableRow
                    key={`${log.id}_${index}`}
                    className={cn(
                      "hover:bg-gray-50 dark:hover:bg-gray-800/50",
                      "transition-colors duration-150"
                    )}
                  >
                    <TableCell className="font-mono text-sm whitespace-nowrap">
                      {formatDate(log.timestamp)}
                    </TableCell>
                    <TableCell className="max-w-[200px] truncate">
                      {log.actor}
                    </TableCell>
                    <TableCell>
                      <span
                        className={cn(
                          "px-2 py-1 rounded-full text-xs font-medium",
                          log.action.toLowerCase().includes("error") ||
                            log.action.toLowerCase().includes("failed") ||
                            log.action.toLowerCase().includes("deleted")
                            ? "bg-red-100 text-red-700 dark:bg-red-900/20 dark:text-red-400"
                            : "bg-blue-100 text-blue-700 dark:bg-blue-900/20 dark:text-blue-400"
                        )}
                      >
                        {log.action}
                      </span>
                    </TableCell>
                    <TableCell className="max-w-[300px] truncate">
                      {log.target}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Pagination info */}
      <div className="px-4 py-2 text-xs text-gray-500 border-t border-gray-200 dark:border-gray-800">
        {totalCount !== undefined && (
          <div className="flex justify-between">
            <div>
              {t(
                "audit.table.showingRecords",
                "Showing {{current}} of {{total}} records",
                {
                  current: safeData.length,
                  total: totalCount,
                }
              )}
            </div>
            <div
              className={cn(
                percentLoaded < 50
                  ? "text-amber-600 font-medium"
                  : percentLoaded < 90
                  ? "text-blue-600"
                  : percentLoaded < 100
                  ? "text-blue-500"
                  : "text-green-600",
                "dark:text-opacity-80"
              )}
            >
              {/* Show with 1 decimal place for accuracy */}
              {percentLoaded === 100 ? "100" : percentLoaded.toFixed(1)}%
            </div>
          </div>
        )}
      </div>

      {/* Load more/loading indicator - improved for more visibility */}
      <div
        ref={scrollRef}
        className="p-4 text-center border-t border-gray-200 dark:border-gray-800"
      >
        {isFetchingNextPage ? (
          <div className="flex items-center justify-center">
            <Loader2 className="h-5 w-5 animate-spin text-gray-500 dark:text-gray-400 mr-2" />
            <span className="text-gray-500 dark:text-gray-400">
              {t("audit.table.loading", "Loading more...")}
            </span>
          </div>
        ) : hasNextPage ? (
          <Button
            variant="outline"
            size="sm"
            onClick={handleManualFetch}
            className="w-40"
          >
            {t("audit.table.loadMore", "Load More")}
          </Button>
        ) : safeData.length > 0 ? (
          <div className="text-gray-500 dark:text-gray-400">
            {percentLoaded < 99.9 &&
            totalCount &&
            safeData.length < totalCount ? (
              <div className="flex flex-col items-center space-y-2">
                <div className="text-amber-600 dark:text-amber-400">
                  {safeData.length} of {totalCount} records loaded (
                  {percentLoaded.toFixed(1)}%)
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleManualFetch}
                  className="w-40"
                >
                  Try Load More
                </Button>
              </div>
            ) : (
              t("audit.table.endOfData", "End of audit log data")
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AuditTable;
