/**
 * @file src/routes/ghost.routes.tsx
 * @description Ghost drive routing with integrated auth routes
 * @version 2.3.0
 */

import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';
import { GhostTranslationProvider } from '@/providers/GhostTranslationProvider';
import { GhostThemeProvider } from '@/app/ghost/providers/GhostThemeProvider';
import { AccountProvider } from '@/context/AccountContext';
import { NavigationProvider } from '@/context/NavigationContext';
import GhostLayout from '@/app/ghost/components/layout/GhostLayout';

// Ghost Main Pages
const GhostRoot = React.lazy(() => import('@/app/ghost/pages'));
const GhostProfilePage = React.lazy(() => import('@/app/ghost/pages/profile'));
const GhostSettingsPage = React.lazy(() => import('@/app/ghost/pages/settings'));
const GhostProtocolPage = React.lazy(() => import('@/app/ghost/pages/protocol'));
const GhostNotificationsPage = React.lazy(() => import('@/app/ghost/pages/notifications'));
const GhostBillingPage = React.lazy(() => import('@/app/ghost/pages/billing'));
const GhostUpgradePage = React.lazy(() => import('@/app/ghost/pages/upgrade'));
const GhostDrivesPage = React.lazy(() => import('@/app/ghost/pages/drives'));
const GhostDriveView = React.lazy(() => import('@/app/ghost/pages/drive'));
const GhostFolderView = React.lazy(() => import('@/app/ghost/pages/drive/folder'));
const GhostMediaView = React.lazy(() => import('@/app/ghost/pages/drive/media'));

// Ghost Auth Pages
const GhostSignIn = React.lazy(() => import('@/app/ghost/pages/auth/SignIn'));
const GhostSignUp = React.lazy(() => import('@/app/ghost/pages/auth/SignUp'));
const GhostForgotPassword = React.lazy(() => import('@/app/ghost/pages/auth/ForgotPassword'));
const GhostResetPassword = React.lazy(() => import('@/app/ghost/pages/auth/ResetPassword'));
const VerifyEmail = React.lazy(() => import('@/app/ghost/pages/auth/VerifyEmail'));
const ResendVerification = React.lazy(() => import('@/app/ghost/pages/auth/ResendVerification'));
const VerificationSuccess = React.lazy(() => import('@/app/ghost/pages/auth/VerificationSuccess'));

// Billing pages
const BillingSuccessPage = React.lazy(() => import('@/app/ghost/pages/billing/success'));
const BillingCancelPage = React.lazy(() => import('@/app/ghost/pages/billing/cancel'));

const GhostLoader = () => (
  <div className="min-h-screen flex items-center justify-center bg-ghost text-ghost-foreground">
    <div className="flex flex-col items-center space-y-4">
      <div className="animate-spin rounded-full h-8 w-8 border-2 border-ghost-accent border-t-transparent" />
      <p className="text-sm">Loading Ghost Drive...</p>
    </div>
  </div>
);

export const ghostRoutes = {
  path: 'ghost',
  element: (
    <ErrorBoundary>
      <Suspense fallback={<GhostLoader />}>
        <GhostThemeProvider>
          <Outlet />
        </GhostThemeProvider>
      </Suspense>
    </ErrorBoundary>
  ),
  children: [
    // Auth routes - publicly accessible without authentication checks
    // Use a minimal provider setup to avoid unnecessary API calls
    {
      path: 'auth',
      element: (
        // Only include GhostThemeProvider, exclude all data providers
        // to prevent unnecessary API calls on auth pages
        <Suspense fallback={<GhostLoader />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          path: 'signin',
          element: <GhostSignIn />,
        },
        {
          path: 'signup',
          element: <GhostSignUp />,
        },
        {
          path: 'forgot-password',
          element: <GhostForgotPassword />,
        },
        {
          path: 'reset-password',
          element: <GhostResetPassword />,
        },
        {
          path: 'resend-verification',
          element: <ResendVerification />,
        },
        {
          path: 'verification-success',
          element: <VerificationSuccess />,
        },
      ],
    },
    // Email verification page - separate route to handle verification tokens
    {
      path: 'verify-email',
      element: <VerifyEmail />,
    },
    
    // Protected Ghost routes - these will be checked by the main AuthGuard
    {
      element: (
        <AccountProvider>
          <GhostTranslationProvider>
            <NavigationProvider>
              <GhostLayout>
                <Outlet />
              </GhostLayout>
            </NavigationProvider>
          </GhostTranslationProvider>
        </AccountProvider>
      ),
      children: [
        {
          index: true,
          element: <GhostRoot />
        },
        {
          path: 'profile',
          element: <GhostProfilePage />
        },
        {
          path: 'settings',
          element: <GhostSettingsPage />
        },
        {
          path: 'quantum',
          element: <GhostProtocolPage />
        },
        {
          path: 'notifications',
          element: <GhostNotificationsPage />
        },
        {
          path: 'billing',
          element: <GhostBillingPage />
        },
        {
          path: 'upgrade',
          element: <GhostUpgradePage />
        },
        {
          path: 'drives',
          element: <GhostDrivesPage />
        },
        {
          path: 'drive/:driveId',
          element: <GhostDriveView />
        },
        {
          path: 'drive/:driveId/folder/:folderId',
          element: <GhostFolderView />
        },
        {
          path: 'drive/:driveId/media',
          element: <GhostMediaView />
        },
        // Billing success/cancel routes
        {
          path: 'billing/success',
          element: <BillingSuccessPage />
        },
        {
          path: 'billing/cancel',
          element: <BillingCancelPage />
        },
      ]
    },
    // Default route when auth state is uncertain - let the main router handle this
    {
      path: '*',
      element: <Navigate to="/ghost" replace />
    }
  ]
};