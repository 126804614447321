/**
 * @file src/types/upload.types.ts
 * @description Type definitions for upload functionality
 * @version 1.0.0
 */

export type UploadStrategy = 'server_relay' | 'multipart_relay' | 'direct_s3' | 'chunked_s3';

// Define UploadStrategy enum-like object for usage as a value
export const UploadStrategy = {
  SERVER_RELAY: 'server_relay' as UploadStrategy,
  MULTIPART_RELAY: 'multipart_relay' as UploadStrategy,
  DIRECT_S3: 'direct_s3' as UploadStrategy,
  CHUNKED_S3: 'chunked_s3' as UploadStrategy
};

export interface UploadFile {
  id: string;
  file: File;
  parentId: string | null;
  driveId?: string;
  progress: number;
  status: UploadStatus;
  error?: string;
  resumable?: boolean;
  startTime?: number;
  retryCount?: number;
  uploadId?: string;
  totalChunks?: number;
  uploadedChunks?: number[];
  strategy?: UploadStrategy;
  createdAt?: number;

  // Additional properties needed by implementations
  bytesUploaded?: number;
  endTime?: number;
  chunks?: any[];
  message?: string; // Add message for status display in UI

  // Encryption-related properties
  isEncrypted?: boolean;
  encryptionProgress?: number;
  encryptionStatus?: string;
  originalSize?: number;
  encryptedSize?: number;
  encryptionTier?: string;
}

export type UploadStatus =
  | 'pending'  // Added 'pending' to match usage in UploadProvider
  | 'queued'
  | 'preparing'
  | 'uploading'
  | 'paused'
  | 'completed'
  | 'failed'
  | 'error'     // Added 'error' to match usage in UploadProvider
  | 'cancelled';

export interface ChunkState {
  index: number;
  start: number;
  end: number;
  status: 'pending' | 'uploading' | 'uploaded' | 'failed';
  retryCount: number;
  uploadId: string;

  // Additional properties needed
  uploaded?: boolean;
  progress?: number;
  etag?: string;
  partNumber?: number;
  attempt?: number;
}

export interface UploadProgress {
  loaded: number;
  total: number;
  currentChunk?: number;
  speed?: number;
  encryptionProgress?: number;
  encryptionStatus?: string;
  originalSize?: number;
  encryptedSize?: number;
  message?: string; // Add message for status updates
}

export interface UploadResponse {
  id: string;
  name: string;
  path: string;
  size: number;
  type: string;
  storage_path: string;
}

export interface UploadStrategyResponse {
  valid: boolean;
  message?: string;
  fallback?: UploadStrategy;
}

export interface UploadStrategyValidation {
  valid: boolean;
  message?: string;
  fallback?: UploadStrategy;
}

// Add minDirectUploadSize to the upload config
export interface UploadConfig {
  readonly maxQueueSize: number;
  readonly maxFileSize: number;
  readonly chunkSize: number;
  readonly minChunkSize: number;
  readonly maxChunks: number;
  readonly chunkRetryAttempts: number;
  readonly chunkRetryDelay: number;
  readonly minDirectUploadSize: number;
  readonly speedCalculationWindow: number;
  readonly concurrentUploads?: {
    free: number;
    paid: number;
  };
}

// Fixed duplicate declarations - keeping only one
export interface EncryptionProgress {
  progress: number;
  stage: string;
  originalSize: number;
  encryptedSize?: number;
}

export interface UploadContextValue {
  // State
  queue: UploadFile[];
  isVisible: boolean;
  isMinimized: boolean;
  isAcceptingFiles: boolean;
  activeUpload: string | null;

  // Methods
  addFiles: (files: File[], parentId?: string | null, driveId?: string) => Promise<void>;
  removeFile: (fileId: string) => void;
  clearCompleted: () => void;
  cancelUpload: (fileId: string) => Promise<void>;
  updateEncryptionProgress: (fileId: string, progress: EncryptionProgress) => void;
  setIsVisible: (visible: boolean) => void;
  setIsMinimized: (minimized: boolean) => void;
  setAcceptingFiles: (accepting: boolean) => void;
  pauseUpload: (fileId: string) => void;
  resumeUpload: (fileId: string) => void;
}