/**
 * @file src/components/cloud-drive/actions/FileContextMenu.tsx
 * @description Enhanced context menu with improved dialog handling and copy functionality
 * @version 3.5.0
 * @updated 2025-03-21
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Download,
  Share2,
  Pencil,
  Trash2,
  FolderUp,
  Copy,
  Star,
  Info,
  Shield,
  Lock
} from 'lucide-react';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
  ContextMenuShortcut,
} from '@/components/ui/context-menu';
import { useCloudDrive } from '@/context/cloud-drive';
import { useDriveOperations } from '@/context/cloud-drive/hooks/useDriveOperations';
import { useSelection } from '@/context/SelectionContext';
import { cn } from '@/utils/utils';
import ShareDialog from '../sharing/ShareDialog';
import RenameDialog from '../dialogs/RenameDialog';
import DeleteDialog from '../dialogs/DeleteDialog';
import MoveDialog from '../dialogs/MoveDialog';
import CopyDialog from '../dialogs/CopyDialog'; // Import the new CopyDialog
import { logger } from '@/utils/logger';
import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';
import ZeroKnowledgeProofDialog from '@/app/ghost/components/zero-knowledge/ZeroKnowledgeProofDialog';
import SimpleFileDetailsDialog, { FileDetails } from '@/app/ghost/components/dialogs/SimpleFileDetailsDialog';

interface FileContextMenuProps {
  item: DriveFile | DriveFolder;
  children: React.ReactNode;
  // Keep these callback props for backward compatibility
  onRename?: (id: string, newName: string) => void;
  onDelete?: (id: string) => void;
  onMove?: (itemIds: string[], targetId: string) => void;
  onCopy?: (itemIds: string[], targetId: string) => void;
  onShare?: (id: string) => void;
  onDownload?: (id: string) => void;
  onToggleFavorite?: (id: string) => void;
  onShowInfo?: (id: string) => void;
}

export const FileContextMenu = React.forwardRef<HTMLDivElement, FileContextMenuProps>(({
  item,
  children,
  onRename,
  onDelete,
  onMove,
  onCopy,
  onShare,
  onDownload,
  onToggleFavorite,
  onShowInfo
}, ref) => {
  const { t } = useTranslation();
  const { encryptedDrive, setSelectedItemId, setIsInfoPanelOpen, selectedItemId } = useCloudDrive();
  const { 
    renameItem, 
    deleteItems, 
    moveItems, 
    copyItems, 
    toggleFavorite: toggleFavoriteOperation,
    downloadItem
  } = useDriveOperations();
  
  // Get selection context to be aware of multi-selection
  const selectionContext = useSelection();
  
  // Dialogs state
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [isRenameDialogOpen, setRenameDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isMoveDialogOpen, setMoveDialogOpen] = useState(false);
  const [isCopyDialogOpen, setCopyDialogOpen] = useState(false); // New state for CopyDialog
  const [isEncryptionProofDialogOpen, setEncryptionProofDialogOpen] = useState(false); // New state for Encryption Proof
  const [isFileDetailsDialogOpen, setFileDetailsDialogOpen] = useState(false); // New state for File Details Dialog
  const [fileDetails, setFileDetails] = useState<FileDetails | null>(null); // State to store file details
  const [isFavoriteTogglePending, setIsFavoriteTogglePending] = useState(false);

  // Selected items (for multi-item operations)
  const [operationItems, setOperationItems] = useState<string[]>([]);

  // Close context menu when any dialog opens
  useEffect(() => {
    if (
      isShareDialogOpen || 
      isRenameDialogOpen || 
      isDeleteDialogOpen || 
      isMoveDialogOpen ||
      isCopyDialogOpen ||
      isEncryptionProofDialogOpen ||
      isFileDetailsDialogOpen
    ) {
      setContextMenuOpen(false);
    }
  }, [isShareDialogOpen, isRenameDialogOpen, isDeleteDialogOpen, isMoveDialogOpen, isCopyDialogOpen, isEncryptionProofDialogOpen, isFileDetailsDialogOpen]);

  // Debug log when selected item changes
  useEffect(() => {
    if (selectedItemId === item.id) {
      logger.debug('Item is now selected', {
        component: 'FileContextMenu',
        data: { 
          itemId: item.id, 
          itemName: item.name 
        }
      });
    }
  }, [selectedItemId, item.id, item.name]);

  // Handlers for each operation
  const handleShare = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    
    logger.debug('Share menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id, 
        itemName: item.name,
        itemType: item.type,
        isEncryptedMode: encryptedDrive.isEncryptedMode
      }
    });
    
    // Close context menu before opening dialog
    setContextMenuOpen(false);
    // Use timeout to ensure context menu is fully closed before dialog opens
    setTimeout(() => {
      setShareDialogOpen(true);
      
      // Still call the passed onShare callback if provided
      if (onShare) {
        onShare(item.id);
      }
    }, 50);
  }, [item, onShare, encryptedDrive.isEncryptedMode]);

  const handleRename = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    logger.debug('Rename menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id, 
        itemName: item.name,
        itemType: item.type
      }
    });

    setContextMenuOpen(false);
    setTimeout(() => {
      setRenameDialogOpen(true);
      
      // Still call the passed onRename callback if provided
      if (onRename) {
        onRename(item.id, item.name);
      }
    }, 50);
  }, [item, onRename]);

  const handleDelete = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    // Check if the item is part of a multi-selection
    const isPartOfMultiSelection = selectionContext.selectedItems.includes(item.id) && 
                                 selectionContext.selectedItems.length > 1;

    logger.debug('Delete menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id, 
        itemName: item.name,
        itemType: item.type,
        isPartOfMultiSelection,
        selectedCount: selectionContext.selectedItems.length
      }
    });

    setContextMenuOpen(false);
    
    // Use all selected items if this item is part of a selection,
    // otherwise just use this single item
    if (isPartOfMultiSelection) {
      setOperationItems(selectionContext.selectedItems);
    } else {
      setOperationItems([item.id]);
    }
    
    setTimeout(() => {
      setDeleteDialogOpen(true);
      
      // Still call the passed onDelete callback if provided
      if (onDelete) {
        onDelete(item.id);
      }
    }, 50);
  }, [item, onDelete, selectionContext.selectedItems]);

  const handleMove = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    // Check if the item is part of a multi-selection
    const isPartOfMultiSelection = selectionContext.selectedItems.includes(item.id) && 
                                 selectionContext.selectedItems.length > 1;

    logger.debug('Move menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id, 
        itemName: item.name,
        itemType: item.type,
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        isPartOfMultiSelection,
        selectedCount: selectionContext.selectedItems.length
      }
    });

    setContextMenuOpen(false);
    
    // Use all selected items if this item is part of a selection,
    // otherwise just use this single item
    if (isPartOfMultiSelection) {
      setOperationItems(selectionContext.selectedItems);
    } else {
      setOperationItems([item.id]);
    }
    
    setTimeout(() => {
      setMoveDialogOpen(true);
      
      // Still call the passed onMove callback if provided
      if (onMove) {
        onMove(isPartOfMultiSelection ? selectionContext.selectedItems : [item.id], '');
      }
    }, 50);
  }, [item, onMove, encryptedDrive.isEncryptedMode, selectionContext.selectedItems]);

  // Enhanced copy handler
  const handleCopy = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    
    // Check if the item is part of a multi-selection
    const isPartOfMultiSelection = selectionContext.selectedItems.includes(item.id) && 
                                 selectionContext.selectedItems.length > 1;
    
    logger.debug('Copy menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id, 
        itemName: item.name,
        itemType: item.type,
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        isPartOfMultiSelection,
        selectedCount: selectionContext.selectedItems.length
      }
    });

    setContextMenuOpen(false);
    
    // Use all selected items if this item is part of a selection,
    // otherwise just use this single item
    if (isPartOfMultiSelection) {
      setOperationItems(selectionContext.selectedItems);
    } else {
      setOperationItems([item.id]);
    }
    
    setTimeout(() => {
      setCopyDialogOpen(true);
      
      // Still call the passed onCopy callback if provided
      if (onCopy) {
        onCopy(isPartOfMultiSelection ? selectionContext.selectedItems : [item.id], '');
      }
    }, 50);
  }, [item, onCopy, encryptedDrive.isEncryptedMode, selectionContext.selectedItems]);

  // Updated download handler that uses the downloadItem function
  const handleDownload = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    
    logger.debug('Download menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id, 
        itemName: item.name,
        itemType: item.type,
        isEncryptedMode: encryptedDrive.isEncryptedMode
      }
    });
    
    setContextMenuOpen(false);
    
    // Use the downloadItem function from useDriveOperations
    downloadItem(item.id, item.name);
    
    // Still call the passed onDownload callback if provided
    if (onDownload) {
      onDownload(item.id);
    }
  }, [item, downloadItem, onDownload, encryptedDrive.isEncryptedMode]);

  // Enhanced favorite toggle handler that passes the full item
  const handleToggleFavorite = useCallback(async (e: React.MouseEvent) => {
    e.stopPropagation();
    
    // Prevent double-clicks
    if (isFavoriteTogglePending) return;
    
    logger.debug('Favorite toggle menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id,
        itemName: item.name,
        currentFavoriteState: item.favorite,
        isEncryptedMode: encryptedDrive.isEncryptedMode
      }
    });
    
    try {
      setIsFavoriteTogglePending(true);
      
      // Close the context menu
      setContextMenuOpen(false);
      
      // Use the toggle operation from useDriveOperations,
      // passing both the ID and the full item object to avoid lookup issues
      await toggleFavoriteOperation(item.id, item);
      
      // Still call the callback if provided (for backward compatibility)
      if (onToggleFavorite) {
        onToggleFavorite(item.id);
      }
    } catch (error) {
      logger.error('Error in favorite toggle handler', {
        component: 'FileContextMenu',
        error,
        data: { itemId: item.id }
      });
    } finally {
      setIsFavoriteTogglePending(false);
    }
  }, [item, toggleFavoriteOperation, onToggleFavorite, isFavoriteTogglePending, encryptedDrive.isEncryptedMode]);

  // Completely revised showInfo handler to fix the "Not Found" error when right-clicking items
  // New handler for encryption proof
  const handleShowEncryptionProof = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    
    logger.debug('See encryption proof menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id, 
        itemName: item.name,
        itemType: item.type,
        isEncryptedMode: encryptedDrive.isEncryptedMode
      }
    });
    
    setContextMenuOpen(false);
    
    // Store selected file information in localStorage for the proof dialog to use
    try {
      localStorage.setItem('selected_file_for_proof', JSON.stringify({
        id: item.id,
        name: item.name,
        size: (item as any).size || 0,
        type: item.type,
        driveId: encryptedDrive.encryptedDriveId
      }));
      
      logger.debug('Stored file info for encryption proof', {
        component: 'FileContextMenu',
        data: { itemId: item.id }
      });
    } catch (error) {
      logger.warn('Could not store file info in localStorage', {
        component: 'FileContextMenu',
        error
      });
    }
    
    // Open the dialog with a slight delay to ensure context menu is closed
    setTimeout(() => {
      setEncryptionProofDialogOpen(true);
    }, 50);
  }, [item, encryptedDrive.isEncryptedMode, encryptedDrive.encryptedDriveId]);

  const handleShowInfo = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    
    logger.debug('View details menu item clicked', {
      component: 'FileContextMenu',
      data: { 
        itemId: item.id, 
        itemName: item.name,
        itemType: item.type,
        encryptedMode: encryptedDrive.isEncryptedMode
      }
    });
    
    // Close the context menu first
    setContextMenuOpen(false);
    
    // Create simple file details object
    const details: FileDetails = {
      id: item.id,
      name: item.name,
      type: item.type,
      size: (item as any).size || 0,
      created: item.created || new Date().toISOString(),
      modified: item.modified || new Date().toISOString(),
      mimeType: (item as any).mimeType || undefined
    };
    
    // Set file details state
    setFileDetails(details);
    
    // Open the dialog with a slight delay to ensure context menu is closed
    setTimeout(() => {
      setFileDetailsDialogOpen(true);
      
      logger.debug('File details dialog opened', {
        component: 'FileContextMenu',
        data: { 
          itemId: item.id,
          itemName: item.name
        }
      });
      
      // Still call the passed onShowInfo callback if provided
      if (onShowInfo) {
        onShowInfo(item.id);
      }
    }, 50);
  }, [item, onShowInfo]);

  // Dialog close handlers
  const handleDialogOpenChange = (open: boolean, setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
    logger.debug('Dialog open state changing', {
      component: 'FileContextMenu',
      data: { 
        isOpen: open,
        itemId: item.id
      }
    });
    
    setDialogOpen(open);
  };

  // Operations implementation
  const handleRenameConfirm = async (newName: string) => {
    try {
      await renameItem(item.id, newName);
      setRenameDialogOpen(false);
      
      // Add this: Try to force refresh folder contents after rename
      try {
        const { useCloudDrive } = await import('@/context/cloud-drive');
        const cloudDrive = useCloudDrive();
        if (cloudDrive.forceRefreshFolderContents) {
          setTimeout(() => {
            cloudDrive.forceRefreshFolderContents();
          }, 200);
        }
      } catch (error) {
        logger.warn('Could not force refresh after rename', {
          component: 'FileContextMenu',
          error
        });
      }
    } catch (error) {
      logger.error('Error renaming item', {
        component: 'FileContextMenu',
        error,
        data: {
          itemId: item.id,
          oldName: item.name,
          newName
        }
      });
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteItems(operationItems);
      setDeleteDialogOpen(false);
    } catch (error) {
      logger.error('Error deleting items', {
        component: 'FileContextMenu',
        error,
        data: {
          operationItems
        }
      });
    }
  };

  const handleMoveConfirm = async (targetFolderId: string) => {
    try {
      logger.debug('Move confirmation', {
        component: 'FileContextMenu',
        data: {
          operationItems,
          targetFolderId,
          isEncryptedMode: encryptedDrive.isEncryptedMode
        }
      });
      
      await moveItems(operationItems, targetFolderId);
      setMoveDialogOpen(false);
    } catch (error) {
      logger.error('Error moving items', {
        component: 'FileContextMenu',
        error,
        data: {
          operationItems,
          targetFolderId
        }
      });
    }
  };

  // New handler for copy confirmation
  const handleCopyConfirm = async (targetFolderId: string) => {
    try {
      logger.debug('Copy confirmation', {
        component: 'FileContextMenu',
        data: {
          operationItems,
          targetFolderId,
          isEncryptedMode: encryptedDrive.isEncryptedMode
        }
      });
      
      await copyItems(operationItems, targetFolderId);
      setCopyDialogOpen(false);
    } catch (error) {
      logger.error('Error copying items', {
        component: 'FileContextMenu',
        error,
        data: {
          operationItems,
          targetFolderId
        }
      });
    }
  };

  // Check if the item is part of a multi-selection
  const isPartOfMultiSelection = selectionContext.selectedItems.includes(item.id) && 
                               selectionContext.selectedItems.length > 1;
  const selectedCount = selectionContext.selectedItems.length;

  // Menu items definition
  const menuItems = [
    {
      icon: Download,
      label: isPartOfMultiSelection 
        ? t('cloud_drive.context_menu.download_multiple', { count: selectedCount }) || `Download (${selectedCount})` 
        : t('cloud_drive.context_menu.download'),
      shortcut: t('cloud_drive.context_menu.shortcuts.download'),
      action: handleDownload,
      show: item.type === 'file' // Only show download for files, not folders
    },
    {
      icon: Share2,
      label: isPartOfMultiSelection 
        ? t('cloud_drive.context_menu.share_multiple', { count: selectedCount }) || `Share (${selectedCount})` 
        : t('cloud_drive.context_menu.share'),
      shortcut: t('cloud_drive.context_menu.shortcuts.share'),
      action: handleShare,
      show: true
    },
    {
      icon: Pencil,
      label: t('cloud_drive.context_menu.rename'),
      shortcut: t('cloud_drive.context_menu.shortcuts.rename'),
      action: handleRename,
      show: !isPartOfMultiSelection // Only show rename for single items
    },
    {
      icon: Star,
      label: item.favorite ? t('cloud_drive.context_menu.unstar') : t('cloud_drive.context_menu.star'),
      shortcut: t('cloud_drive.context_menu.shortcuts.star'),
      action: handleToggleFavorite,
      show: true,
      active: item.favorite
    },
    {
      icon: FolderUp,
      label: isPartOfMultiSelection 
        ? t('cloud_drive.context_menu.move_multiple', { count: selectedCount }) || `Move (${selectedCount})` 
        : t('cloud_drive.context_menu.move'),
      shortcut: t('cloud_drive.context_menu.shortcuts.move'),
      action: handleMove,
      show: true
    },
    {
      icon: Copy,
      label: isPartOfMultiSelection 
        ? t('cloud_drive.context_menu.copy_multiple', { count: selectedCount }) || `Copy (${selectedCount})` 
        : t('cloud_drive.context_menu.copy'),
      shortcut: t('cloud_drive.context_menu.shortcuts.copy'),
      action: handleCopy,
      show: true
    },
    {
      icon: Shield,
      label: t('cloud_drive.context_menu.encryption_proof', 'See Encryption Proof'),
      shortcut: t('cloud_drive.context_menu.shortcuts.encryption_proof', 'E'),
      action: handleShowEncryptionProof,
      show: !isPartOfMultiSelection && 
            encryptedDrive.isEncryptedMode === true && 
            item.type === 'file' && 
            encryptedDrive.encryptedDriveId // Only show for single files in encrypted drives
    },
    {
      icon: Info,
      label: t('cloud_drive.context_menu.info'),
      shortcut: t('cloud_drive.context_menu.shortcuts.info'),
      action: handleShowInfo,
      show: !isPartOfMultiSelection // Only show info for single items
    },
    {
      icon: Trash2,
      label: isPartOfMultiSelection 
        ? t('cloud_drive.context_menu.delete_multiple', { count: selectedCount }) || `Delete (${selectedCount})` 
        : t('cloud_drive.context_menu.delete'),
      shortcut: t('cloud_drive.context_menu.shortcuts.delete'),
      action: handleDelete,
      show: true,
      danger: true
    }
  ];

  return (
    <>
      <ContextMenu onOpenChange={setContextMenuOpen}>
        <ContextMenuTrigger asChild>
          {children}
        </ContextMenuTrigger>
        <ContextMenuContent 
          ref={ref}
          className="w-64 overflow-hidden rounded-lg shadow-lg animate-in fade-in-0 zoom-in-95"
        >
          {menuItems.map((menuItem, index) => (
            menuItem.show && (
              <React.Fragment key={menuItem.label}>
                <ContextMenuItem
                  onClick={menuItem.action}
                  className={cn(
                    "flex items-center cursor-pointer",
                    "hover:bg-gray-50 dark:hover:bg-gray-800",
                    "focus:bg-gray-50 dark:focus:bg-gray-800",
                    "transition-colors duration-200",
                    menuItem.danger && "text-red-500 dark:text-red-400 hover:text-red-600 dark:hover:text-red-300",
                    menuItem.active && "text-blue-500 dark:text-blue-400 font-medium"
                  )}
                >
                  <menuItem.icon className={cn(
                    "h-4 w-4 mr-2 flex-shrink-0",
                    menuItem.active && "text-blue-500 dark:text-blue-400"
                  )} />
                  <span className="flex-1">{menuItem.label}</span>
                  {menuItem.shortcut && (
                    <ContextMenuShortcut>{menuItem.shortcut}</ContextMenuShortcut>
                  )}
                </ContextMenuItem>
                {index < menuItems.length - 1 && menuItem.show && (
                  <ContextMenuSeparator />
                )}
              </React.Fragment>
            )
          ))}
        </ContextMenuContent>
      </ContextMenu>
      
      {/* Share Dialog */}
      <ShareDialog 
        item={item}
        isOpen={isShareDialogOpen}
        onOpenChange={(open) => handleDialogOpenChange(open, setShareDialogOpen)}
      />

      {/* Rename Dialog */}
      <RenameDialog
        item={item}
        isOpen={isRenameDialogOpen}
        onClose={(open) => handleDialogOpenChange(open, setRenameDialogOpen)}
        onRename={handleRenameConfirm}
      />

      {/* Delete Dialog */}
      <DeleteDialog
        selectedCount={operationItems.length}
        isOpen={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={handleDeleteConfirm}
      />

      {/* Move Dialog */}
      <MoveDialog
        selectedItems={operationItems}
        isOpen={isMoveDialogOpen}
        onClose={() => setMoveDialogOpen(false)}
        onMove={handleMoveConfirm}
      />

      {/* Copy Dialog - Add new copy dialog */}
      <CopyDialog
        selectedItems={operationItems}
        isOpen={isCopyDialogOpen}
        onClose={() => setCopyDialogOpen(false)}
        onCopy={handleCopyConfirm}
      />
      
      {/* Encryption Proof Dialog - Open directly to the "See The Proof" tab */}
      {isEncryptionProofDialogOpen && (
        <ZeroKnowledgeProofDialog
          open={isEncryptionProofDialogOpen}
          onOpenChange={setEncryptionProofDialogOpen}
          initialTab="seeTheProof"
        />
      )}
      
      {/* Simple File Details Dialog */}
      {isFileDetailsDialogOpen && fileDetails && (
        <SimpleFileDetailsDialog
          open={isFileDetailsDialogOpen}
          onOpenChange={setFileDetailsDialogOpen}
          fileDetails={fileDetails}
        />
      )}
    </>
  );
});

FileContextMenu.displayName = "FileContextMenu";

export default FileContextMenu;