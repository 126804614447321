// src/services/favorites.service.ts

import { authService } from './auth.service';
import type {
  FavoriteStats,
  FavoriteToggleResponse,
  BatchFavoriteRequest,
  BatchFavoriteResponse,
  FavoritesListParams,
  FavoritesListResponse
} from '@/types/favorites.types';
import { logger } from '@/utils/logger';
import { getAuthHeaders } from '@/utils/api-utils';

const BASE_URL = '/api/v1/cloud-drive/favorites';

// No need for a custom helper as we're using the centralized getAuthHeaders from api-utils

export const favoritesApi = {
  // List favorites
  listFavorites: async (params: FavoritesListParams = {}): Promise<FavoritesListResponse> => {
    try {
      const queryParams = new URLSearchParams();
      if (params.cursor) queryParams.append('cursor', params.cursor);
      if (params.sort_by) queryParams.append('sort_by', params.sort_by);
      if (params.sort_order) queryParams.append('sort_order', params.sort_order);
      if (params.page_size) queryParams.append('page_size', params.page_size.toString());

      const url = `${BASE_URL}?${queryParams.toString()}`;

      logger.debug('Fetching favorites list', {
        component: 'favoritesApi',
        url,
        params
      });

      const response = await fetch(url, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        logger.error('Failed to fetch favorites', {
          status: response.status,
          statusText: response.statusText
        });
        throw new Error('Failed to fetch favorites');
      }

      const data = await response.json();
      logger.debug('Favorites data received', {
        component: 'favoritesApi'
      });

      return data;
    } catch (error) {
      logger.error('Error fetching favorites:', error);
      throw error;
    }
  },

  // Toggle favorite status
  toggleFavorite: async (itemId: string): Promise<FavoriteToggleResponse> => {
    try {
      logger.debug('Toggling favorite status', {
        component: 'favoritesApi'
      });

      const response = await fetch(`${BASE_URL}/${itemId}`, {
        method: 'POST',
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to toggle favorite');
      }

      return response.json();
    } catch (error) {
      logger.error('Error toggling favorite:', error);
      throw error;
    }
  },

  // Batch toggle favorites
  batchToggleFavorites: async (data: BatchFavoriteRequest): Promise<BatchFavoriteResponse> => {
    try {
      logger.debug('Batch toggling favorites', {
        component: 'favoritesApi'
      });

      const response = await fetch(`${BASE_URL}/batch`, {
        method: 'POST',
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include',
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Failed to batch toggle favorites');
      }

      return response.json();
    } catch (error) {
      logger.error('Error in batch favorite operation:', error);
      throw error;
    }
  },

  // Get favorite statistics
  getFavoriteStats: async (): Promise<FavoriteStats> => {
    try {
      logger.debug('Fetching favorite stats', {
        component: 'favoritesApi'
      });

      const response = await fetch(`${BASE_URL}/stats`, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch favorite stats');
      }

      return response.json();
    } catch (error) {
      logger.error('Error fetching favorite stats:', error);
      throw error;
    }
  }
};