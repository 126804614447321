/**
 * @file src/components/cloud-drive/layout/toolbar/components/SearchBar.tsx
 * @description Enhanced search bar with filter integration
 * @version 1.0.0
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search, X } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';
import { FilterPopover } from './FilterPopover';
import { logger } from '@/utils/logger';
import type { SearchBarProps } from '../types';

export const SearchBar: React.FC<SearchBarProps> = ({
  filterConfig,
  onSearch,
  onFilterChange,
  onClearFilters,
  className
}) => {
  const { t } = useTranslation();
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  // Calculate active filters count
  const activeFiltersCount = React.useMemo(() => {
    let count = 0;
    if (filterConfig.types?.length) count++;
    if (filterConfig.dateRange) count++;
    if (filterConfig?.shared !== undefined) count++;
    if (filterConfig?.favorite !== undefined) count++;
    if (filterConfig.labels?.length) count++;
    return count;
  }, [filterConfig]);

  const handleSearchChange = (value: string) => {
    logger.debug('Search value changed', {
      component: 'SearchBar',
      value
    } as any);
    onSearch(value);
  };

  return (
    <div className={cn(
      "flex items-center gap-2",
      className
    )}>
      <div className="relative flex-1">
        <Search className={cn(
          "absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4",
          "text-gray-400 transition-colors duration-200",
          isSearchFocused && "text-blue-500"
        )} />
        <Input
          value={filterConfig.search || ''}
          onChange={(e) => handleSearchChange(e.target.value)}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
          placeholder={t('cloud_drive.search.placeholder')}
          className={cn(
            "pl-9 h-9 w-full",
            "bg-gray-50 dark:bg-gray-800",
            "border-transparent",
            "focus:border-blue-500 focus:ring-blue-500/20",
            "transition-all duration-200"
          )}
        />
        {filterConfig.search && (
          <Button
            variant="ghost"
            size="sm"
            className="absolute right-2 top-1/2 -translate-y-1/2 h-5 w-5 p-0"
            onClick={() => handleSearchChange('')}
          >
            <X className="h-3 w-3" />
          </Button>
        )}
      </div>

      <FilterPopover
        filterConfig={filterConfig}
        onFilterChange={onFilterChange}
        onClearFilters={onClearFilters}
        activeFiltersCount={activeFiltersCount}
      />
    </div>
  );
};

export default SearchBar;