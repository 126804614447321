// src/components/favorites/FavoritesToolbar.tsx

/**
 * @file src/components/favorites/FavoritesToolbar.tsx
 * @description Specialized toolbar for favorites view
 * @version 1.0.0
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Star, Clock, SortAsc, SortDesc, Grid, List } from 'lucide-react';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { 
  DropdownMenu, 
  DropdownMenuTrigger, 
  DropdownMenuContent, 
  DropdownMenuItem 
} from '@/components/ui/dropdown-menu';
import { SearchBar } from '@/components/cloud-drive/layout/toolbar/components/SearchBar';
import { useCloudDrive } from '@/context/cloud-drive';
import { useFavoriteStats } from '@/hooks/favorites/useFavorites';
import { SortField } from '@/types/cloud-drive.types';

interface FavoritesToolbarProps {
  className?: string;
}

export const FavoritesToolbar: React.FC<FavoritesToolbarProps> = ({ className }) => {
  const { t } = useTranslation();
  const { 
    viewMode, 
    setViewMode,
    filterConfig,
    setFilterConfig,
    sortConfig,
    setSortConfig
  } = useCloudDrive();
  const { data: stats } = useFavoriteStats();

  // Handle sort change
  const handleSortChange = (field: SortField) => {
    setSortConfig({
      field: field as SortField,
      order:
        sortConfig.field === field && sortConfig.order === "asc"
          ? "desc"
          : "asc",
    });
  };


  return (
    <div className={cn(
      "flex items-center h-[64px] px-4 gap-4",
      "bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm",
      "border-b border-gray-200 dark:border-gray-800",
      className
    )}>
      {/* Stats */}
      <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
        <Star className="h-4 w-4 text-amber-400" />
        <span>{stats?.total_count || 0} {t('favorites.items')}</span>
      </div>

      {/* Search */}
      <SearchBar
        filterConfig={filterConfig}
        onSearch={(value) => setFilterConfig({ ...filterConfig, search: value })}
        onFilterChange={(filters) => setFilterConfig({ ...filterConfig, ...filters })}
        onClearFilters={() => setFilterConfig({})}
        className="flex-1 max-w-2xl"
      />

      {/* Sort Options */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="gap-2">
            {sortConfig.order === 'asc' ? <SortAsc className="h-4 w-4" /> : <SortDesc className="h-4 w-4" />}
            {t(`favorites.sort.${sortConfig.field}`)}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => handleSortChange("favorited_at" as SortField)}>
            <Clock className="h-4 w-4 mr-2" />
            {t('favorites.sort.favorited_at')}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleSortChange("name" as SortField)}>
            <Star className="h-4 w-4 mr-2" />
            {t('favorites.sort.name')}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleSortChange("size" as SortField)}>
            <Star className="h-4 w-4 mr-2" />
            {t('favorites.sort.size')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Separator orientation="vertical" className="h-6" />

      {/* View Mode Toggle */}
      <div className="flex items-center gap-1">
        <Button
          variant={viewMode === 'grid' ? 'secondary' : 'ghost'}
          size="icon"
          onClick={() => setViewMode('grid')}
        >
          <Grid className="h-4 w-4" />
        </Button>
        <Button
          variant={viewMode === 'list' ? 'secondary' : 'ghost'}
          size="icon"
          onClick={() => setViewMode('list')}
        >
          <List className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};