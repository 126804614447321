/**
 * @file src/routes/ai.routes.tsx
 * @description AI feature routes with lazy loading
 * @version 1.1.0
 * @created 2024-12-07
 * @updated 2025-02-25
 */

import { Suspense, lazy } from 'react';

// Lazy load AI page components
const AIPage = lazy(() => import('@/pages/ai'));
const FacesPage = lazy(() => import('@/pages/ai/faces'));
const ThingsPage = lazy(() => import('@/pages/ai/things'));
const CCTVPage = lazy(() => import('@/pages/ai/cctv'));
const CameraPage = lazy(() => import('@/pages/ai/cctv/camera/index'));
const HealthcarePage = lazy(() => import('@/pages/ai/healthcare'));

// Loader component for AI pages
const AIPageLoader = () => (
  <div className="flex items-center justify-center min-h-[60vh]">
    <div className="flex flex-col items-center space-y-2">
      <div className="h-8 w-8 rounded-full border-2 border-blue-300 border-t-blue-500 animate-spin"></div>
      <p className="text-sm text-gray-500 dark:text-gray-400">Loading AI features...</p>
    </div>
  </div>
);

export const aiRoutes = {
  path: 'ai',
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<AIPageLoader />}>
          <AIPage />
        </Suspense>
      )
    },
    {
      path: 'cctv',
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<AIPageLoader />}>
              <CCTVPage />
            </Suspense>
          )
        },
        {
          path: 'camera/:cameraId',
          element: (
            <Suspense fallback={<AIPageLoader />}>
              <CameraPage />
            </Suspense>
          )
        }
      ]
    },
    {
      path: 'faces',
      element: (
        <Suspense fallback={<AIPageLoader />}>
          <FacesPage />
        </Suspense>
      )
    },
    {
      path: 'things',
      element: (
        <Suspense fallback={<AIPageLoader />}>
          <ThingsPage />
        </Suspense>
      )
    },
    {
      path: 'healthcare',
      element: (
        <Suspense fallback={<AIPageLoader />}>
          <HealthcarePage />
        </Suspense>
      )
    }
  ]
};