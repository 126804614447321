/**
 * @file src/components/devices/DeviceFilterTabs.tsx
 * @description Filter tabs for device types
 * @version 1.0.0
 * @created 2025-02-27
 * @dependencies lucide-react, react-i18next
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Laptop, Smartphone, Server, Database, X } from 'lucide-react';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

export type DeviceFilterType = 'all' | 'computers' | 'mobiles' | 'servers' | 'nas';

interface DeviceFilterTabsProps {
  activeFilter: DeviceFilterType;
  onFilterChange: (filter: DeviceFilterType) => void;
  className?: string;
}

export const DeviceFilterTabs: React.FC<DeviceFilterTabsProps> = ({
  activeFilter,
  onFilterChange,
  className
}) => {
  const { t } = useTranslation();

  const filters: {id: DeviceFilterType; label: string; icon: React.ElementType}[] = [
    { id: 'all', label: t('devices.filters.all'), icon: X },
    { id: 'computers', label: t('devices.filters.computers'), icon: Laptop },
    { id: 'mobiles', label: t('devices.filters.mobiles'), icon: Smartphone },
    { id: 'servers', label: t('devices.filters.servers'), icon: Server },
    { id: 'nas', label: t('devices.filters.nas'), icon: Database }
  ];

  const handleTabClick = (filter: DeviceFilterType) => {
    logger.debug('Device filter changed', {
      component: 'DeviceFilterTabs',
      previousFilter: activeFilter,
      newFilter: filter,
      action: 'changeFilter'
    });
    onFilterChange(filter);
  };

  return (
    <div className={cn("flex overflow-x-auto pb-2", className)}>
      <div className="flex bg-gray-100 dark:bg-gray-800 rounded-lg p-1 mx-auto">
        {filters.map((filter: any) => {
          const isActive = activeFilter === filter.id;
          const Icon = filter.icon;
          
          return (
            <button
              key={filter.id}
              onClick={() => handleTabClick(filter.id)}
              className={cn(
                "flex items-center px-3 py-2 rounded-md transition-all",
                "text-sm font-medium whitespace-nowrap",
                "focus:outline-none focus:ring-2 focus:ring-blue-500/20",
                isActive 
                  ? "bg-white dark:bg-gray-700 text-blue-600 dark:text-blue-400 shadow-sm"
                  : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
              )}
            >
              <Icon className={cn(
                "h-4 w-4 mr-2",
                isActive && "text-blue-600 dark:text-blue-400",
                filter.id === 'all' && isActive && "text-gray-600 dark:text-gray-400"
              )} />
              {filter.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DeviceFilterTabs;