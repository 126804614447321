/**
 * @file src/components/cloud-drive/selection/SelectionControls.tsx
 * @description Fixed selection controls for selection handling
 * @version 1.1.0
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check, X, ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useSelection } from '@/context/SelectionContext';
import { useCloudDrive } from '@/context/cloud-drive';
import { logger } from '@/utils/logger';

interface SelectionControlsProps {
  className?: string;
  currentPageIds?: string[];
}

export const SelectionControls: React.FC<SelectionControlsProps> = ({
  className: _className,
}) => {
  const { t } = useTranslation();
  const { currentFolderContents } = useCloudDrive();
  const { selectedItems, clearSelection, selectAll, isSelectMode } =
    useSelection();

  const handleSelectAll = () => {
    logger.debug("Select all clicked", {
      component: "SelectionControls",
      currentItems: currentFolderContents?.length,
      selectedItems: selectedItems.length,
    } as any);

    // Get all current item IDs
    const currentIds = currentFolderContents?.map((item: any) => item.id) || [];
    selectAll(currentIds);
  };

  if (!isSelectMode) return null;

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm font-medium">
        {t("cloud_drive.selection.items_selected", {
          count: selectedItems.length,
        })}
      </span>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="h-8">
            <ChevronDown className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={handleSelectAll}>
            <Check className="h-4 w-4 mr-2" />
            {t("cloud_drive.selection.select_all")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Button
        variant="ghost"
        size="sm"
        onClick={clearSelection}
        className="h-8"
      >
        <X className="h-4 w-4 mr-1" />
        {t("cloud_drive.selection.clear_selection")}
      </Button>
    </div>
  );
};

export default SelectionControls;