/**
 * @file src/app/ghost/components/mobile-apps/MobileAppsPromoDialog.tsx
 * @description Mobile apps promotional dialog with non-interactive founders banner
 * @version 2.4.0
 * @created 2025-04-06
 * @dependencies framer-motion, lucide-react
 */

import React, { useCallback, useContext } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';
import { Smartphone, Sparkles, ShieldCheck, Lock, Fingerprint, ArrowRight, Award } from 'lucide-react';
import { logger } from '@/utils/logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { cn } from '@/utils/utils';

interface MobileAppsPromoDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const MobileAppsPromoDialog: React.FC<MobileAppsPromoDialogProps> = ({
  open,
  onOpenChange
}) => {
  const { t } = useGhostTranslation();
  
  // Check if navigation context is available
  const navContext = useContext(UNSAFE_NavigationContext);
  const navigate = navContext ? useNavigate() : null;
  
  React.useEffect(() => {
    if (open) {
      logger.debug('Mobile apps promo dialog opened');
    }
  }, [open]);

  const handleUpgradeClick = useCallback(() => {
    logger.debug('Upgrade to founders clicked');
    onOpenChange(false);
    
    if (navigate) {
      navigate('/ghost/upgrade');
    } else {
      logger.warn('Navigation function not available, using window.location');
      window.location.href = '/ghost/upgrade';
    }
  }, [navigate, onOpenChange]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        className="max-w-sm p-0 bg-[hsl(var(--ghost-bg))] border-[hsl(var(--ghost-border))]"
      >
        <div className="p-6">
          {/* Dialog Header with Phone Icon */}
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-3">
              <div className="relative">
                <motion.div
                  className="absolute inset-0 bg-[hsl(var(--ghost-accent))]"
                  animate={{ 
                    opacity: [0.3, 0.7, 0.3],
                    scale: [0.9, 1.2, 0.9]
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                  style={{ filter: 'blur(8px)' }}
                />
                <Smartphone className="relative h-6 w-6 text-[hsl(var(--ghost-accent))]" />
              </div>
              
              <h2 className="text-lg font-bold text-[hsl(var(--ghost-foreground))]">
                {String(t('mobileApps.dialog.title', 'Nygma Mobile'))}
              </h2>
            </div>
            
            <motion.div
              animate={{ y: [0, -2, 0] }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="px-2 py-0.5 rounded-full text-xs bg-[hsl(var(--ghost-accent))]/20 text-[hsl(var(--ghost-accent))]"
            >
              {String(t('mobileApps.dialog.comingSoon', 'Coming Q2/2025'))}
            </motion.div>
          </div>
          
          {/* Phone Illustration with Futuristic Animations */}
          <div className="relative h-28 mb-4 overflow-hidden rounded-lg bg-[hsl(var(--ghost-surface))]/30">
            {/* Fast moving particles */}
            {[...Array(12)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute w-2 h-2 rounded-full bg-[hsl(var(--ghost-accent))]"
                style={{ 
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  opacity: 0.1 + Math.random() * 0.5
                }}
                animate={{
                  x: [0, Math.random() > 0.5 ? 20 : -20],
                  y: [0, Math.random() > 0.5 ? 20 : -20],
                  opacity: [0, 0.7, 0],
                  scale: [0, 1, 0]
                }}
                transition={{
                  duration: 0.8 + Math.random() * 0.6,
                  repeat: Infinity,
                  delay: Math.random() * 0.5,
                  ease: "linear"
                }}
              />
            ))}
            
            {/* Phone outline with lock */}
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <motion.div
                className="relative w-14 h-24 rounded-xl bg-transparent border-2 border-[hsl(var(--ghost-accent))]/50 flex items-center justify-center"
                animate={{
                  boxShadow: [
                    '0 0 5px 0px hsla(var(--ghost-accent), 0.4)',
                    '0 0 12px 2px hsla(var(--ghost-accent), 0.6)',
                    '0 0 5px 0px hsla(var(--ghost-accent), 0.4)'
                  ]
                }}
                transition={{
                  duration: 1.2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <motion.div
                  animate={{ 
                    scale: [1, 1.15, 1],
                    opacity: [0.8, 1, 0.8]
                  }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  <Lock className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
                </motion.div>
              </motion.div>
            </div>
          </div>
          
          {/* Tagline with enhanced animation */}
          <div className="mb-4">
            <motion.div 
              className="flex items-center gap-1.5 mb-2"
              animate={{ 
                x: [-2, 0, -2]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <motion.div
                animate={{ rotate: [0, 360] }}
                transition={{
                  duration: 6,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                <Sparkles className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
              </motion.div>
              <h3 className="text-base font-bold text-[hsl(var(--ghost-accent))]">
                {String(t('mobileApps.dialog.tagline', 'SECURE ANYWHERE, ANYTIME'))}
              </h3>
            </motion.div>
            
            <p className="text-sm text-[hsl(var(--ghost-foreground))]">
              {String(t('mobileApps.dialog.description', 'Take Nygma\'s zero-knowledge encryption wherever you go with our upcoming mobile apps.'))}
            </p>
          </div>
          
          {/* Features List - Compact Version */}
          <div className="mb-4 grid grid-cols-2 gap-3">
            <FeatureItem 
              icon={ShieldCheck}
              text={String(t('mobileApps.dialog.features.encrypted', 'Encrypted file access'))}
              delay={0}
            />
            
            <FeatureItem 
              icon={Smartphone}
              text={String(t('mobileApps.dialog.features.capture', 'Secure capture'))}
              delay={0.1}
            />
            
            <FeatureItem 
              icon={Fingerprint}
              text={String(t('mobileApps.dialog.features.biometric', 'Biometric security'))}
              delay={0.2}
            />
            
            <FeatureItem 
              icon={Lock}
              text={String(t('mobileApps.dialog.features.zeroKnowledge', 'Zero-knowledge'))}
              delay={0.3}
            />
          </div>
          
          {/* Founders Info Banner - Redesigned to not look like a button */}
          <div className="border border-dashed border-[hsl(var(--ghost-accent))]/40 bg-[hsl(var(--ghost-accent))]/5 rounded-lg p-3 mb-4">
            <div className="flex items-center justify-center gap-3">
              <motion.div
                animate={{ rotate: [0, 360] }}
                transition={{ 
                  duration: 10,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                <Award className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
              </motion.div>
              
              <div className="text-center">
                <motion.span
                  animate={{ 
                    color: [
                      'hsl(var(--ghost-accent))',
                      'hsl(var(--ghost-accent-secondary))',
                      'hsl(var(--ghost-accent))'
                    ]
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                  className="text-sm font-bold text-[hsl(var(--ghost-accent))]"
                >
                  {String(t('mobileApps.dialog.foundersAccess', 'FOUNDERS GET EARLY ACCESS'))}
                </motion.span>
              </div>
              
              <motion.div
                animate={{ rotate: [0, -360] }}
                transition={{ 
                  duration: 10,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                <Award className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
              </motion.div>
            </div>
          </div>
          
          {/* CTA Button with enhanced animation */}
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleUpgradeClick}
            className={cn(
              "w-full py-3 rounded-lg",
              "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
              "flex items-center justify-center gap-2",
              "group",
              "relative overflow-hidden"
            )}
          >
            {/* Fast flowing light effect */}
            <motion.div
              className="absolute inset-0 bg-white opacity-20"
              animate={{
                x: ['-100%', '100%']
              }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                ease: "linear",
                repeatDelay: 0.2
              }}
              style={{
                clipPath: 'polygon(10% 0%, 30% 0%, 20% 100%, 0% 100%)'
              }}
            />
            
            {/* Secondary faster light effect */}
            <motion.div
              className="absolute inset-0 bg-white opacity-10"
              animate={{
                x: ['-100%', '100%']
              }}
              transition={{
                duration: 0.6,
                repeat: Infinity,
                ease: "linear",
                repeatDelay: 0.4,
                delay: 0.3
              }}
              style={{
                clipPath: 'polygon(40% 0%, 50% 0%, 40% 100%, 30% 100%)'
              }}
            />
            
            <Sparkles className="h-4 w-4 text-white" />
            <span className="text-sm font-medium text-white">
              {String(t('mobileApps.dialog.becomeFounder', 'BECOME A FOUNDER'))}
            </span>
            <motion.div
              animate={{ x: [0, 4, 0] }}
              transition={{
                duration: 0.6,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <ArrowRight className="h-4 w-4 text-white" />
            </motion.div>
          </motion.button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

// Helper component for feature items - enhanced animation
interface FeatureItemProps {
  icon: React.FC<{ className?: string }>;
  text: string;
  delay: number;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon: Icon, text, delay }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        type: "spring", 
        stiffness: 500, 
        damping: 20,
        delay 
      }}
      className="flex items-center gap-2"
    >
      <motion.div 
        className="flex-shrink-0 w-7 h-7 rounded-lg bg-[hsl(var(--ghost-accent))]/10 flex items-center justify-center"
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        <Icon className="h-3.5 w-3.5 text-[hsl(var(--ghost-accent))]" />
      </motion.div>
      <span className="text-xs text-[hsl(var(--ghost-foreground))]">{text}</span>
    </motion.div>
  );
};

export default MobileAppsPromoDialog;