/**
 * @file src/utils/file-utils.ts
 * @description Utility functions for working with files
 * @version 1.0.0
 * @created 2025-04-04
 */

import React from 'react';
import {
  File,
  FileText,
  FileImage,
  FileVideo,
  FileAudio,
  FileCode,
  FileBox, // Using FileBox instead of FileArchive
  FileSpreadsheet,
  Folder
} from 'lucide-react';

// Map common file extensions to their types
export const getFileTypeFromExtension = (extension: string): string => {
  const ext = extension.toLowerCase();

  // Images
  if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'].includes(ext)) {
    return 'image';
  }
  
  // Videos
  if (['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm', 'mkv', 'm4v'].includes(ext)) {
    return 'video';
  }
  
  // Audio
  if (['mp3', 'wav', 'ogg', 'flac', 'aac', 'm4a', 'wma'].includes(ext)) {
    return 'audio';
  }
  
  // Documents
  if (['pdf', 'doc', 'docx', 'txt', 'rtf', 'md', 'odt'].includes(ext)) {
    return 'document';
  }
  
  // Spreadsheets
  if (['xls', 'xlsx', 'csv', 'ods'].includes(ext)) {
    return 'spreadsheet';
  }
  
  // Presentations
  if (['ppt', 'pptx', 'odp', 'key'].includes(ext)) {
    return 'presentation';
  }
  
  // Code files
  if (['js', 'ts', 'jsx', 'tsx', 'html', 'css', 'java', 'py', 'c', 'cpp', 'cs', 'php', 'rb', 'go', 'swift', 'json', 'xml'].includes(ext)) {
    return 'code';
  }
  
  // Archives
  if (['zip', 'rar', '7z', 'tar', 'gz', 'bz2'].includes(ext)) {
    return 'archive';
  }
  
  // Default
  return 'file';
};

// Get appropriate icon component for file type
export const getFileIcon = (type: string, extension: string = ''): React.FC<React.SVGProps<SVGSVGElement>> => {
  // If it's a folder, return folder icon
  if (type === 'folder') {
    return Folder;
  }
  
  // If it's a file, determine the icon based on file type or extension
  const fileType = extension ? getFileTypeFromExtension(extension) : 'file';
  
  switch (fileType) {
    case 'image':
      return FileImage;
    case 'video':
      return FileVideo;
    case 'audio':
      return FileAudio;
    case 'document':
      return FileText;
    case 'spreadsheet':
      return FileSpreadsheet;
    case 'presentation':
      return FileText; // Using FileText for presentations as FileType has issues
    case 'code':
      return FileCode;
    case 'archive':
      return FileBox;
    default:
      return File;
  }
};

export default {
  getFileTypeFromExtension,
  getFileIcon
};