/**
 * @file src/app/ghost/components/zero-knowledge/common/EmptyStateView.tsx
 * @description Empty state view for when drives are locked or empty
 * @version 1.0.1
 * @created 2025-04-01
 * @updated 2025-04-03
 * @dependencies framer-motion, lucide-react
 */

import React from 'react';
import { Lock, Play } from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { cn } from '@/utils/utils';

interface EmptyStateViewProps {
  type: 'locked' | 'empty';
  onUnlock?: () => void;
  onUpload?: () => void;
  onEnableDemoMode: () => void;
}

export const EmptyStateView: React.FC<EmptyStateViewProps> = ({
  type,
  onUnlock,
  onUpload,
  onEnableDemoMode
}) => {
  const { t } = useGhostTranslation();
  
  const title = type === 'locked' 
    ? t('zeroKnowledge.dialog.emptyState.locked.title', 'Drive is Locked')
    : t('zeroKnowledge.dialog.emptyState.empty.title', 'No Files Found');
    
  const message = type === 'locked'
    ? t('zeroKnowledge.dialog.emptyState.locked.message', 'Unlock your drive to see how your files are encrypted.')
    : t('zeroKnowledge.dialog.emptyState.empty.message', 'Upload a file to see the zero knowledge encryption in action.');
    
  const primaryButtonText = type === 'locked'
    ? t('zeroKnowledge.dialog.emptyState.locked.primaryButton', 'Unlock My Drive')
    : t('zeroKnowledge.dialog.emptyState.empty.primaryButton', 'Upload a File');
    
  const primaryAction = type === 'locked' ? onUnlock : onUpload;
  
  return (
    <div className="h-[280px] flex flex-col items-center justify-center px-4"> {/* Reduced height */}
      {/* Lock Icon */}
      <div className="w-14 h-14 rounded-full bg-[hsl(var(--ghost-accent))]/10 flex items-center justify-center mb-4"> {/* Reduced size and spacing */}
        <Lock className="w-6 h-6 text-[hsl(var(--ghost-accent))]" /> {/* Reduced size */}
      </div>
      
      {/* Title and Message */}
      <h3 className="text-lg font-bold text-[hsl(var(--ghost-foreground))] mb-1 text-center"> {/* Reduced size and spacing */}
        {title}
      </h3>
      <p className="text-sm text-[hsl(var(--ghost-foreground-muted))] mb-4 text-center max-w-md"> {/* Reduced size and spacing */}
        {message}
      </p>
      
      {/* Action Buttons */}
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2"> {/* Reduced spacing */}
        <button
          className={cn(
            "px-4 py-2 text-sm rounded-lg", // Reduced size
            "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
            "text-white font-medium",
            "flex items-center justify-center",
            "transition-all duration-200"
          )}
          onClick={primaryAction}
        >
          {type === 'locked' ? <Lock className="w-3 h-3 mr-1" /> : null} {/* Reduced size */}
          {primaryButtonText}
        </button>
        
        <button
          className={cn(
            "px-4 py-2 text-sm rounded-lg", // Reduced size
            "bg-[hsl(var(--ghost-surface))]/40",
            "text-[hsl(var(--ghost-foreground))]",
            "border border-[hsl(var(--ghost-accent))]/10",
            "flex items-center justify-center",
            "transition-all duration-200"
          )}
          onClick={onEnableDemoMode}
        >
          <Play className="w-3 h-3 mr-1" /> {/* Reduced size */}
          {t('zeroKnowledge.dialog.emptyState.demoButton', 'Try Demo Mode')}
        </button>
      </div>
    </div>
  );
};

export default EmptyStateView;