/**
 * @file src/components/data/DataSplitView/MainContent/index.tsx
 * @description Main content wrapper with animations
 * @version 1.0.0
 */

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/utils/utils';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';
import { Loader2 } from 'lucide-react';  // Import a loading icon
import ContentRenderer from './ContentRenderer';
import type { MainContentProps } from '../types';
import DeviceListView from '@/components/devices/DeviceListView';
import DeviceDetailView from '@/components/devices/DeviceDetailView';
// Comment out the import until the module is available
// import ServicesView from '@/components/services/ServicesView';

const initialView = "default";

// Animation variants for view transitions
const viewTransitionVariants = {
  enter: {
    opacity: 0,
    y: 20,
    scale: 0.98
  },
  active: {
    opacity: 1,
    y: 0,
    scale: 1
  },
  exit: {
    opacity: 0,
    y: -20,
    scale: 0.98
  }
};

export const MainContent: React.FC<MainContentProps> = ({
  selectedCategory,
  expandedDevice,
  folderId,
  viewType,
  className
}) => {
  return (
    <div className={cn("flex-1 relative", className)}>
      <ErrorBoundary>
        <React.Suspense 
          fallback={
            <div className="flex items-center justify-center h-full">
              <div className="flex flex-col items-center space-y-2">
                <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
                <span className="text-sm text-gray-500 dark:text-gray-400">Loading...</span>
              </div>
            </div>
          }
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={`${selectedCategory}-${expandedDevice}`}
              variants={viewTransitionVariants}
              initial="enter"
              animate="active"
              exit="exit"
              transition={{
                duration: 0.3,
                type: "spring",
                stiffness: 300,
                damping: 30
              }}
              className="h-full"
            >
              <ContentRenderer
                category={selectedCategory}
                folderId={folderId}
                expandedDevice={expandedDevice}
                viewType={(viewType || initialView) as "default" | "folder" | "shared" | "favorites" | "trash" | undefined}
                className="h-full"
              />
            </motion.div>
          </AnimatePresence>
        </React.Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default MainContent;