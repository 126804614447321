/**
 * @file src/hooks/cloud-drive/useItemDetails.ts
 * @description Custom hook for fetching and caching item details
 * @version 1.0.0
 * @created 2024-12-30
 */

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { cloudDriveService } from '@/services/cloud-drive.service';
import type { ItemDetails } from '@/types/cloud-drive.types';

interface UseItemDetailsOptions {
  enabled?: boolean;
  staleTime?: number;
  gcTime?: number;  // Changed from cacheTime to gcTime
  onSuccess?: (data: ItemDetails) => void;
  onError?: (error: Error) => void;
  // Add explicit context option
  context?: {
    encryptedMode?: boolean;
    encryptedDriveId?: string;
  };
}

import { useCloudDrive } from '@/context/cloud-drive';

export const useItemDetails = (
  itemId: string | null,
  options?: UseItemDetailsOptions
): UseQueryResult<ItemDetails | null, Error> => {
  // Get encryption context properly with the hook
  const { encryptedDrive } = useCloudDrive();
  
  // Use explicit context from options if provided, otherwise use the hook's context
  const isEncryptedMode = options?.context?.encryptedMode !== undefined 
    ? options.context.encryptedMode 
    : (encryptedDrive?.isEncryptedMode || false);
    
  const encryptedDriveId = options?.context?.encryptedDriveId || encryptedDrive?.encryptedDriveId;
  
  console.log('useItemDetails called with itemId:', itemId, 
    { isEncryptedMode, encryptedDriveId });

  return useQuery<ItemDetails | null, Error>({
    // Include encryption context in the query key
    queryKey: [...itemDetailsKeys.detail(itemId), isEncryptedMode, encryptedDriveId],
    queryFn: async () => {
      console.log('Fetching item details for:', itemId, 
        { isEncryptedMode, encryptedDriveId });
      
      if (!itemId) {
        console.log('No item ID provided, skipping fetch');
        return null;
      }
      
      // Additional check: try to get any stored encryption context from localStorage
      let effectiveEncryptedDriveId = encryptedDriveId;
      let effectiveEncryptedMode = isEncryptedMode;
      
      try {
        const storedContext = localStorage.getItem('encrypted_item_view_context');
        if (storedContext) {
          const parsedContext = JSON.parse(storedContext);
          // Only use it if it's for the current item
          if (parsedContext.itemId === itemId) {
            effectiveEncryptedMode = parsedContext.isEncryptedMode;
            effectiveEncryptedDriveId = parsedContext.encryptedDriveId;
            
            console.log('Using stored encryption context for item details:', {
              itemId,
              isEncryptedMode: effectiveEncryptedMode,
              encryptedDriveId: effectiveEncryptedDriveId
            });
          }
        }
      } catch (e) {
        console.warn('Error reading stored encryption context:', e);
        // Continue with the original context values
      }
      
      try {
        // Pass effective encryption context to the item details service
        const response = await cloudDriveService.getItemDetails(
          itemId, 
          effectiveEncryptedMode ? effectiveEncryptedDriveId : undefined
        );
        
        console.log('Item details response:', response);
        if (!response.data) {
          throw new Error('No data returned from server');
        }
        return response.data;
      } catch (error) {
        console.error('Error fetching item details:', error);
        
        // Enhanced error handling with more detailed logging
        if (error instanceof Error) {
          console.error('Item details fetch error details:', {
            message: error.message,
            itemId,
            isEncryptedMode: effectiveEncryptedMode,
            encryptedDriveId: effectiveEncryptedDriveId,
            stack: error.stack
          });
        }
        
        throw error;
      }
    },
    enabled: !!itemId && (options?.enabled ?? true),
    staleTime: options?.staleTime ?? 5 * 60 * 1000,
    gcTime: options?.gcTime ?? 30 * 60 * 1000,  // Changed from cacheTime to gcTime
    retry: 3, // Increased retries
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 10000),
  });
}

// Query key factory for cache management
export const itemDetailsKeys = {
  all: ['item-details'] as const,
  detail: (id: string | null) => [...itemDetailsKeys.all, id] as const,
  lists: () => [...itemDetailsKeys.all, 'list'] as const,
};
