/**
 * @file src/context/cloud-drive/CloudDriveContext.tsx
 * @description Main cloud drive context provider with RootDrive integration
 * @version 2.3.0
 */

import React, { createContext, useContext, useEffect } from 'react';
import { useDriveState } from './hooks/useDriveState';
import { useDriveQueries } from './hooks/useDriveQueries';
import { useDriveOperations } from './hooks/useDriveOperations';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { useAuth } from '@/context/AuthContext';
import type { CloudDriveContextValue, EncryptedDriveState, DriveItemsResponse, BreadcrumbItem } from './types';
import { logger } from '@/utils/logger';
import { useToast } from '@/components/ui/toast';
import { useQueryClient } from '@tanstack/react-query';
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { InfiniteData } from '@tanstack/react-query';

const CloudDriveContext = createContext<CloudDriveContextValue | undefined>(undefined);

// Add this type definition
export interface DecryptionStatus {
  isDecrypting: boolean;
  progress: number;
  completedItems: number;
  totalItems: number;
}

export const CloudDriveProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { activeDriveId } = useRootDrive();

  // IMPORTANT: Move encrypted drive state before other hooks
  const [encryptedDrive, setEncryptedDriveState] = React.useState<EncryptedDriveState>({
    isEncryptedMode: false,
    encryptedDriveId: undefined,
    encryptionTier: undefined,
    driveName: undefined
  });

  // Add decryption status to state
  const [decryptionStatus, setDecryptionStatus] = React.useState<DecryptionStatus>({
    isDecrypting: false,
    progress: 0,
    completedItems: 0,
    totalItems: 0
  });

  // Load all state and operations from hooks
  const state = useDriveState();
  
  // FIXED: Pass necessary state functions to useDriveQueries
  const queries = useDriveQueries({ 
    encryptedDrive,
    setCurrentFolderContents: state.setCurrentFolderContents,
    setBreadcrumbs: state.setBreadcrumbs
  });
  
  const operations = useDriveOperations();

  // Enhanced decryption status handling with loop prevention
  const lastDecryptionUpdateTime = React.useRef<number>(0);
  const updateDecryptionStatus = React.useCallback((updates: Partial<DecryptionStatus>) => {
    // Get current time
    const now = Date.now();
    
    // Skip frequent updates to prevent render loops - enforce minimum 300ms between updates
    if (now - lastDecryptionUpdateTime.current < 300) {
      return;
    }
    
    // Record this update time
    lastDecryptionUpdateTime.current = now;
    
    logger.debug('Updating decryption status', {
      component: 'CloudDriveProvider',
      data: updates
    });
    
    setDecryptionStatus(prev => {
      // Only update if there's an actual change to avoid unnecessary renders
      if (
        (updates.isDecrypting !== undefined && updates.isDecrypting === prev.isDecrypting) &&
        (updates.progress !== undefined && Math.abs(updates.progress - prev.progress) < 20) &&
        (updates.completedItems !== undefined && updates.completedItems === prev.completedItems) &&
        (updates.totalItems !== undefined && updates.totalItems === prev.totalItems)
      ) {
        // No significant changes, return existing state to prevent re-render
        return prev;
      }
      
      const newStatus = {
        ...prev,
        ...updates
      };
      
      // Only refresh content on full decryption completion 
      // and limit refreshing frequency to prevent loops
      if (prev.isDecrypting && !newStatus.isDecrypting &&
          newStatus.completedItems > 0 && 
          newStatus.completedItems === newStatus.totalItems) {
        logger.debug('Decryption completed, refreshing content', {
          component: 'CloudDriveProvider',
          data: {
            itemCount: newStatus.totalItems
          }
        });
        
        // This will trigger a re-render of components to show decrypted content
        // But do it through a timeout to avoid immediate render loops
        if (state.currentFolderContents) {
          setTimeout(() => {
            // Only refresh if we're still mounted and have content
            if (state.currentFolderContents && state.currentFolderContents.length > 0) {
              state.setCurrentFolderContents([...state.currentFolderContents]);
            }
          }, 100);
        }
      }
      
      return newStatus;
    });
  }, [state]);  

  // Enhanced logging for state changes including encryption
  useEffect(() => {
    logger.debug('Drive state updated', {
      component: 'CloudDriveProvider',
      data: {
        currentFolder: state.currentFolder,
        itemCount: state.currentFolderContents?.length,
        selectedItems: state.selectedItems.length,
        isInfoPanelOpen: state.isInfoPanelOpen,
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        encryptedDriveId: encryptedDrive.encryptedDriveId
      }
    });
  }, [
    state.currentFolder,
    state.currentFolderContents,
    state.selectedItems,
    state.isInfoPanelOpen,
    encryptedDrive
  ]);  

  // Handle auth state changes
  useEffect(() => {
    logger.debug('Auth state changed', {
      component: 'CloudDriveProvider',
      data: { 
        isAuthenticated,
        activeDriveId
      }
    });

    if (!isAuthenticated) {
      setEncryptedDriveState({
        isEncryptedMode: false,
        encryptedDriveId: undefined,
        encryptionTier: undefined,
        driveName: undefined
      });
      state.setCurrentFolder(null);
      state.clearSelection();
      queryClient.invalidateQueries();
    }

  }, [isAuthenticated, queryClient]);

  // Enhanced logging for state changes
  useEffect(() => {
    logger.debug('Drive state updated', {
      component: 'CloudDriveProvider',
      data: {
        currentFolder: state.currentFolder,
        itemCount: state.currentFolderContents?.length,
        selectedItems: state.selectedItems.length,
        isInfoPanelOpen: state.isInfoPanelOpen,
        isFolderDialogOpen: state.isFolderDialogOpen
      }
    });
  }, [
    state.currentFolder,
    state.currentFolderContents,
    state.selectedItems,
    state.isInfoPanelOpen,
    state.isFolderDialogOpen
  ]);

  // Enhanced encrypted drive state handlers
  const setEncryptedDrive = React.useCallback((updates: Partial<EncryptedDriveState>) => {
    logger.debug('Updating encrypted drive state', {
      component: 'CloudDriveProvider',
      updates
    });
    
    // Import services needed for drive switching
    Promise.all([
      import('@/services/encrypted-drive/media/media-cache.service'),
      import('@/services/encrypted-drive/encryption-singleton')
    ])
      .then(([{ mediaCacheService }, { encryptionService }]) => {
        // Store encrypted drive ID in localStorage when it's set or updated
        if (updates.encryptedDriveId) {
          localStorage.setItem('encrypted_drive_id', updates.encryptedDriveId);
          localStorage.setItem('current_drive_type', 'encrypted_drive');
          
          // Set active drive in the encryption service to ensure correct tier is used
          encryptionService.setActiveDrive(updates.encryptedDriveId);
          
          // Get the current tier for logging
          const encryptionTier = encryptionService.getDriveEncryptionTier(updates.encryptedDriveId);
          
          logger.debug('Set active encrypted drive', {
            component: 'CloudDriveProvider',
            data: { 
              driveId: updates.encryptedDriveId,
              encryptionTier: encryptionTier || 'unknown',
              driveName: updates.driveName
            }
          });
          
          // Clear animation tracking when changing to an encrypted drive or when drive ID changes
          // This ensures smooth animations when switching between drives
          if (updates.isEncryptedMode && updates.encryptedDriveId) {
            mediaCacheService.clearAnimatedFilesForDrive(updates.encryptedDriveId);
            logger.debug('Cleared animation state for drive transition', {
              component: 'CloudDriveProvider',
              data: { driveId: updates.encryptedDriveId }
            });
          }
        }
        
        // If turning off encrypted mode, clear the localStorage values
        if (updates?.isEncryptedMode === false) {
          localStorage.removeItem('encrypted_drive_id');
          localStorage.removeItem('current_drive_type');
          
          // Clear animation tracking for all drives when exiting encrypted mode
          if (encryptedDrive.encryptedDriveId) {
            mediaCacheService.clearAnimatedFilesForDrive(encryptedDrive.encryptedDriveId);
            logger.debug('Cleared animation state when exiting encrypted mode', {
              component: 'CloudDriveProvider',
              data: { driveId: encryptedDrive.encryptedDriveId }
            });
          }
        }
      })
      .catch(error => {
        logger.error('Error importing services for drive switching', {
          component: 'CloudDriveProvider',
          error
        });
      });
    
    setEncryptedDriveState(prev => ({
      ...prev,
      ...updates
    }));
  }, [encryptedDrive.encryptedDriveId]); 

  const clearEncryptedDriveState = React.useCallback(() => {
    logger.debug('Clearing encrypted drive state', {
      component: 'CloudDriveProvider',
      data: { currentDriveId: encryptedDrive.encryptedDriveId }
    });
    
    // Clear animation tracking and reset encryption state when leaving a drive
    if (encryptedDrive.encryptedDriveId) {
      Promise.all([
        import('@/services/encrypted-drive/media/media-cache.service'),
        import('@/services/encrypted-drive/encryption-singleton')
      ])
        .then(([{ mediaCacheService }, { encryptionService }]) => {
          // Reset the media cache
          mediaCacheService.clearAnimatedFilesForDrive(encryptedDrive.encryptedDriveId || '');
          
          // Reset the encryption service singleton state for this drive
          // This ensures that the next time we upload to any drive, we'll get the correct tier
          encryptionService.setActiveDrive(''); // Clear active drive
          
          logger.debug('Drive state reset complete', {
            component: 'CloudDriveProvider',
            data: { 
              clearedDriveId: encryptedDrive.encryptedDriveId,
              clearedCaches: true
            }
          });
        })
        .catch(error => {
          logger.error('Error during drive state reset', {
            component: 'CloudDriveProvider',
            error
          });
        });
    }
    
    // Clear localStorage values
    localStorage.removeItem('encrypted_drive_id');
    localStorage.removeItem('current_drive_type');
    localStorage.removeItem('last_folder_refresh_time'); // Also clear refresh throttling
    
    // Reset state
    setEncryptedDriveState({
      isEncryptedMode: false,
      encryptedDriveId: undefined,
      encryptionTier: undefined,
      driveName: undefined
    });
  }, [encryptedDrive.encryptedDriveId]);

  // Enhanced utility function to force refresh the current folder contents with better encryption support
  // and improved multi-file upload handling
  const forceRefreshFolderContents = React.useCallback((isBatchOperation = false) => {
    // Set a flag in localStorage to prevent excessive refreshes
    const lastRefreshTime = localStorage.getItem('last_folder_refresh_time');
    const now = Date.now();
    
    // If we've refreshed in the last 2 seconds, skip this refresh to prevent loops
    if (lastRefreshTime && (now - parseInt(lastRefreshTime, 10)) < 2000) {
      logger.debug('Skipping folder refresh due to throttling', {
        component: 'CloudDriveProvider',
        data: { 
          lastRefresh: new Date(parseInt(lastRefreshTime, 10)).toISOString(),
          currentTime: new Date(now).toISOString(),
          timeSinceLastRefresh: now - parseInt(lastRefreshTime, 10)
        }
      });
      return;
    }
    
    // Update the last refresh time
    localStorage.setItem('last_folder_refresh_time', now.toString());
    // Get the current active drive ID directly from the hook
    const driveId = encryptedDrive.isEncryptedMode ? 
      encryptedDrive.encryptedDriveId : 
      activeDriveId;
    
    logger.debug('Forcing refresh of folder contents', {
      component: 'CloudDriveProvider',
      data: { 
        currentFolder: state.currentFolder,
        itemCount: state.currentFolderContents?.length,
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        encryptedDriveId: encryptedDrive.encryptedDriveId,
        activeDriveId: activeDriveId,
        effectiveDriveId: driveId,
        isBatchOperation
      }
    });
    
    // Step 1: Invalidate queries with proper encryption context
    if (encryptedDrive.isEncryptedMode) {
      // For encrypted drives, use the encrypted query key format
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder', state.currentFolder, driveId, encryptedDrive.isEncryptedMode]
      });
      
      // Also invalidate queries with the encrypted-drive key format
      if (encryptedDrive.encryptedDriveId) {
        queryClient.invalidateQueries({
          queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, state.currentFolder]
        });
        
        // Additional query invalidation with all permutations of the folder ID
        const folderIdVal = state.currentFolder || null;
        queryClient.invalidateQueries({
          queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, folderIdVal]
        });
      }
      
      // Also try invalidating any queries related to encrypted folders
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey.join('/');
          return (queryKey.includes('encrypted-drive') || 
                  queryKey.includes('folder')) && 
                  queryKey.includes(state.currentFolder || 'root');
        }
      });
    } else {
      // For standard drives
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder', state.currentFolder, activeDriveId, false]
      });
    }
    
    // Step 2: Immediately force a refetch for faster updates - use both formats
    queryClient.refetchQueries({
      queryKey: ['cloud-drive', 'folder', state.currentFolder]
    });
    
    if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
      queryClient.refetchQueries({
        queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, state.currentFolder]
      });
    }
    
    // Step 3: Force a state update to trigger re-renders
    setTimeout(() => {
      if (state.currentFolderContents) {
        logger.debug('Forcing UI update through state refresh', {
          component: 'CloudDriveProvider',
          data: { 
            currentItemCount: state.currentFolderContents.length
          }
        });
        state.setCurrentFolderContents([...state.currentFolderContents]);
      }
    }, 100);
    
    // Step 4: Additional delayed refresh for reliable updates even on slow connections
    setTimeout(() => {
      if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
        queryClient.refetchQueries({
          queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, state.currentFolder]
        });
      } else {
        queryClient.refetchQueries({
          queryKey: ['cloud-drive', 'folder', state.currentFolder]
        });
      }
    }, 1000);
    
    // Step 5: For multi-file or batch operations, add additional staggered refreshes
    // This helps ensure all items appear without requiring the user to manually refresh
    if (isBatchOperation) {
      // Use single refresh with longer delay for batch operations
      // This avoids creating too many simultaneous requests
      setTimeout(() => {
        // Store a new timestamp to allow this refresh
        localStorage.setItem('last_folder_refresh_time', Date.now().toString());
        
        // One complete refresh with invalidation and refetch
        if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
          // Do a complete invalidation
          queryClient.invalidateQueries({
            queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, state.currentFolder]
          });
          // Wait a bit before refetching to ensure invalidation is processed
          setTimeout(() => {
            queryClient.refetchQueries({
              queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, state.currentFolder]
            });
            
            // Force a UI update
            if (state.currentFolderContents) {
              state.setCurrentFolderContents([...state.currentFolderContents]);
            }
          }, 100);
        } else {
          // Same for standard drives
          queryClient.invalidateQueries({
            queryKey: ['cloud-drive', 'folder', state.currentFolder]
          });
          setTimeout(() => {
            queryClient.refetchQueries({
              queryKey: ['cloud-drive', 'folder', state.currentFolder]
            });
            
            // Force a UI update
            if (state.currentFolderContents) {
              state.setCurrentFolderContents([...state.currentFolderContents]);
            }
          }, 100);
        }
      }, 3000);
    }
  }, [
    state.currentFolder, 
    state.currentFolderContents, 
    state.setCurrentFolderContents, 
    queryClient, 
    activeDriveId, 
    encryptedDrive.isEncryptedMode, 
    encryptedDrive.encryptedDriveId
  ]);
    

  // Handle errors with encryption context
  const handleError = (error: any, action: string) => {
    logger.error(`Error during ${action}:`, {
      component: 'CloudDriveProvider',
      error,
      isEncryptedMode: encryptedDrive.isEncryptedMode
    });

    if (error?.response?.status === 401) {
      showToast('Please sign in to access your files', 'error');
      return;
    }

    if (encryptedDrive.isEncryptedMode && error?.message?.includes('decrypt')) {
      showToast('Unable to decrypt file. Please try unlocking the drive again.', 'error');
      clearEncryptedDriveState();
      return;
    }

    showToast(`Failed to ${action}. Please try again.`, 'error');
  };

  // Wrap operations with error handling
  const wrappedOperations = {
    ...operations,
    createFolder: async (...args: Parameters<typeof operations.createFolder>) => {
      try {
        await operations.createFolder(...args);
      } catch (error) {
        handleError(error, 'create folder');
        throw error;
      }
    },
    deleteItems: async (...args: Parameters<typeof operations.deleteItems>) => {
      try {
        await operations.deleteItems(...args);
      } catch (error) {
        handleError(error, 'delete items');
        throw error;
      }
    },
    moveItems: async (...args: Parameters<typeof operations.moveItems>) => {
      try {
        await operations.moveItems(...args);
      } catch (error) {
        handleError(error, 'move items');
        throw error;
      }
    },
    copyItems: async (...args: Parameters<typeof operations.copyItems>) => {
      try {
        await operations.copyItems(...args);
      } catch (error) {
        handleError(error, 'copy items');
        throw error;
      }
    },
    renameItem: async (...args: Parameters<typeof operations.renameItem>) => {
      try {
        await operations.renameItem(...args);
      } catch (error) {
        handleError(error, 'rename item');
        throw error;
      }
    },
    toggleFavorite: async (...args: Parameters<typeof operations.toggleFavorite>) => {
      try {
        await operations.toggleFavorite(...args);
      } catch (error) {
        handleError(error, 'toggle favorite');
        throw error;
      }
    },
    uploadFiles: async (...args: Parameters<typeof operations.uploadFiles>) => {
      try {
        await operations.uploadFiles(...args);
      } catch (error) {
        handleError(error, 'upload files');
        throw error;
      }
    },
  };

  const value = {
    // State from useDriveState
    currentFolderContents: state.currentFolderContents,
    currentFolder: state.currentFolder,
    breadcrumbs: state.breadcrumbs,
    selectedItems: state.selectedItems,
    viewMode: state.viewMode,
    viewDisplay: state.viewDisplay,
    setViewDisplay: state.setViewDisplay,    
    sortConfig: state.sortConfig,
    filterConfig: state.filterConfig,
    isFolderDialogOpen: state.isFolderDialogOpen,
    selectedFile: state.selectedFile,
    isInfoPanelOpen: state.isInfoPanelOpen,
    selectedItemId: state.selectedItemId,
    selectedItem: state.selectedItem,


    // State setters
    setViewMode: state.setViewMode,
    setSortConfig: state.setSortConfig,
    setFilterConfig: state.setFilterConfig,
    setIsInfoPanelOpen: state.setIsInfoPanelOpen,
    setSelectedItemId: state.setSelectedItemId,
    setCurrentFolder: state.setCurrentFolder,
    setCurrentFolderContents: state.setCurrentFolderContents,

    // Selection actions
    clearSelection: state.clearSelection,
    toggleItemSelection: state.toggleItemSelection,

    // Dialog controls
    openFolderDialog: state.openFolderDialog,
    closeFolderDialog: state.closeFolderDialog,

    // Operations with error handling
    ...operations,

    // Queries
    getFolderContents: queries.getFolderContents as unknown as (folderId: string | null) => UseInfiniteQueryResult<DriveItemsResponse, Error>,
    getFolderPath: queries.getFolderPath as (folderId: string | null) => UseQueryResult<BreadcrumbItem[], Error>,

    // Add encrypted drive state and operations
    encryptedDrive,
    setEncryptedDrive,
    clearEncryptedDriveState,
    decryptionStatus,
    updateDecryptionStatus,
    forceRefreshFolderContents
  } as unknown as CloudDriveContextValue;

  // Expose the forceRefreshFolderContents for direct access from other components
  // This allows the UploadProvider to directly trigger a refresh
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore - Adding a global reference for cross-component communication
      window._cloudDriveContext = {
        forceRefreshFolderContents
      };
    }
    
    return () => {
      if (typeof window !== 'undefined') {
        // @ts-ignore - Cleanup on unmount
        delete window._cloudDriveContext;
      }
    };
  }, [forceRefreshFolderContents]);

  return (
    <CloudDriveContext.Provider value={value}>
      {children}
    </CloudDriveContext.Provider>
  );
};

export const useCloudDrive = () => {
  const context = useContext(CloudDriveContext);
  if (!context) {
    throw new Error('useCloudDrive must be used within a CloudDriveProvider');
  }
  return context;
};

export default CloudDriveProvider;