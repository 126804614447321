/**
 * @file src/components/audit/AuditContainer.tsx
 * @description Container component managing audit report state and components
 * @version 1.2.0
 * @created 2024-02-24
 * @updated 2025-02-26
 */

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Download,
  Search,
  User as UserIcon,
  Monitor,
  Calendar,
  Plus,
  Minus,
} from "lucide-react";

// Components
import { UserFilter } from "./filters/UserFilter";
import { DeviceFilter } from "./filters/DeviceFilter";
import { ActionFilter } from "./filters/ActionFilter";
import { DateRangeFilter } from "./filters/DateRangeFilter";
import { AuditTable } from "./table/AuditTable";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

// Utils & Types
import { cn } from "@/lib/utils";
import { logger } from "@/utils/logger";
// Import the ActionType enum directly, not as a type
import { ActionType } from "@/types/audit.types";
// Import other types with the 'type' keyword
import type { AuditLog, User, Device } from "@/types/audit.types";

// Hooks
import { useToast } from "@/components/ui/toast";
import { useDebounce } from "@/hooks/useDebounce";

interface AuditContainerProps {
  data: AuditLog[];
  users: User[];
  devices: Device[];
  selectedUser?: string;
  selectedDevice?: string;
  isLoading: boolean;
  isLoadingMore: boolean;
  hasNextPage: boolean;
  isLoadingDevices: boolean;
  className?: string;
  onUserChange: (userId: string | undefined) => void;
  onDeviceChange: (deviceId: string | undefined) => void;
  onLoadMore: () => void;
  onExport: () => void;
  onReset: () => void;
  onSortChange?: (column: string, direction: "asc" | "desc") => void;
  onSearch: (filters: {
    search?: string;
    action?: ActionType;
    fromDate?: Date | null;
    toDate?: Date | null;
  }) => void;
  totalCount?: number;
  currentSortColumn?: string;
  currentSortDirection?: "asc" | "desc";
}

export const AuditContainer: React.FC<AuditContainerProps> = ({
  data,
  users,
  devices,
  selectedUser,
  selectedDevice,
  isLoading,
  isLoadingMore,
  hasNextPage,
  isLoadingDevices,
  className,
  onUserChange,
  onDeviceChange,
  onLoadMore,
  onExport,
  onReset,
  onSearch,
  onSortChange,
  totalCount,
  currentSortColumn,
  currentSortDirection,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast(); // Get showToast function from the context

  // Fixed useState hooks
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedAction, setSelectedAction] = useState<ActionType | undefined>(
    undefined
  );
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(true);

  // Debounce search query
  const debouncedSearch = useDebounce(searchQuery, 500);

  // Effect for search
  React.useEffect(() => {
    onSearch({
      search: debouncedSearch,
      action: selectedAction,
      fromDate,
      toDate,
    });
  }, [debouncedSearch, selectedAction, fromDate, toDate, onSearch]);

  // Handle export
  const handleExport = async () => {
    try {
      logger.debug("Starting audit log export");
      onExport();
      // Use showToast instead of toast
      showToast(t("audit.export.downloadStarted"), "success");
    } catch (error) {
      logger.error("Export failed:", error);
      showToast(t("audit.export.failed"), "error");
    }
  };

  // Handle filters reset
  const handleReset = () => {
    setSearchQuery("");
    setSelectedAction(undefined);
    setFromDate(null);
    setToDate(null);
    onReset();
  };

  // Map the devices to the format expected by DeviceFilter
  const deviceFilterProps = React.useMemo(() => {
    return devices.map((device) => ({
      ...device,
      // Add any missing properties required by DeviceFilter
      type: device.device_type || "unknown", // Use device_type or provide a default
    }));
  }, [devices]);

  return (
    <div
      className={cn(
        "border rounded-md border-gray-200 dark:border-gray-800",
        "flex flex-col h-full", // Add flex column layout for proper overflow handling
        className
      )}
    >
      {/* Header */}
      <div className="bg-[#E5F4F4] dark:bg-cyan-900/10 px-4 py-3 flex-shrink-0">
        <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
          {t("audit.title")}
        </h2>
      </div>

      {/* Filters */}
      <Collapsible
        open={isFiltersOpen}
        onOpenChange={setIsFiltersOpen}
        className="space-y-2 flex-shrink-0"
      >
        <div className="px-4 flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {t("Search Filters")}
          </h3>
          <CollapsibleTrigger asChild>
            <Button variant="ghost" size="sm">
              {isFiltersOpen ? (
                <Minus className="h-4 w-4" />
              ) : (
                <Plus className="h-4 w-4" />
              )}
            </Button>
          </CollapsibleTrigger>
        </div>

        <CollapsibleContent>
          <div className="p-4 bg-white dark:bg-gray-900 space-y-4">
            {/* Search and Action Filters */}
            <div className="flex items-start gap-4">
              <div className="flex-1">
                <div className="relative">
                  <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400" />
                  <Input
                    placeholder={t("Enter your search query")}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-9"
                  />
                </div>
              </div>
              <ActionFilter
                value={selectedAction}
                onChange={setSelectedAction}
                disabled={isLoading}
              />
            </div>

            {/* User and Device Filters */}
            <div className="flex items-start gap-4">
              <div className="flex-1">
                <div className="bg-gray-100 dark:bg-gray-800 rounded-md">
                  <div className="flex items-center">
                    <div className="px-3 py-2 bg-gray-200 dark:bg-gray-700 rounded-l-md">
                      <UserIcon className="h-4 w-4 text-gray-600 dark:text-gray-400" />
                    </div>
                    <UserFilter
                      value={selectedUser}
                      onChange={onUserChange}
                      users={users}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="bg-gray-100 dark:bg-gray-800 rounded-md">
                  <div className="flex items-center">
                    <div className="px-3 py-2 bg-gray-200 dark:bg-gray-700 rounded-l-md">
                      <Monitor className="h-4 w-4 text-gray-600 dark:text-gray-400" />
                    </div>
                    <DeviceFilter
                      value={selectedDevice}
                      onChange={onDeviceChange}
                      devices={deviceFilterProps}
                      disabled={isLoading || !selectedUser}
                      isLoading={isLoadingDevices}
                      selectedUser={selectedUser}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Date Range */}
            <div className="flex items-center gap-4">
              <div className="flex-1">
                <div className="bg-gray-100 dark:bg-gray-800 rounded-md">
                  <div className="flex items-center">
                    <div className="px-3 py-2 bg-gray-200 dark:bg-gray-700 rounded-l-md">
                      <Calendar className="h-4 w-4 text-gray-600 dark:text-gray-400" />
                    </div>
                    <DateRangeFilter
                      fromDate={fromDate}
                      toDate={toDate}
                      onFromDateChange={(date) => setFromDate(date)}
                      onToDateChange={(date) => setToDate(date)}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={handleReset}
                disabled={isLoading}
                className="bg-gray-100 hover:bg-gray-200 text-gray-700"
              >
                {t("common.reset")}
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={handleExport}
                disabled={isLoading || data.length === 0}
              >
                <Download className="h-4 w-4 mr-2" />
                {t("common.exportToCsv")}
              </Button>
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>

      {/* Results Count */}
      <div className="px-4 py-2 flex-shrink-0">
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {data.length > 0
            ? t("audit.results.found", { count: data.length })
            : t("audit.results.noResults")}
        </p>
      </div>

      {/* Table - Give it flex-1 to fill available space */}
      <div className="flex-1 min-h-0">
        <AuditTable
          data={data}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isLoadingMore}
          fetchNextPage={onLoadMore}
          onSortChange={onSortChange}
          totalCount={totalCount}
          className="h-full"
          currentSortColumn={currentSortColumn}
          currentSortDirection={currentSortDirection}
        />
      </div>
    </div>
  );
};

export default AuditContainer;
