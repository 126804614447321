/**
 * @file src/components/layout/AccountMenu.tsx
 * @description Account dropdown menu component with improved UI context detection
 * @version 1.2.0
 * @created 2024-12-07
 * @updated 2025-03-21
 * @dependencies lucide-react
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Settings,
  Users,
  FileText,
  Mail,
  Flag,
  Bell,
  LogOut,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/utils/utils";
import { motion } from "framer-motion";
import { useAuth } from "@/context/AuthContext";
import { logoutAndClearCaches } from "@/utils/logout-utils";
import { logger } from "@/utils/logger";

interface AccountMenuProps {
  isSidebarCollapsed: boolean;
}

export const AccountMenu: React.FC<AccountMenuProps> = ({
  isSidebarCollapsed,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [isGhostUI, setIsGhostUI] = useState(false);

  // Detect if we're in Ghost UI context
  useEffect(() => {
    // Check current path to determine if we're in Ghost UI
    const inGhostUI = location.pathname.startsWith('/ghost');
    setIsGhostUI(inGhostUI);
    
    logger.debug('AccountMenu context detection', {
      component: 'AccountMenu',
      data: {
        pathname: location.pathname,
        isGhostUI: inGhostUI
      }
    });
  }, [location.pathname]);

  const menuItems = [
    {
      icon: <Settings className="w-4 h-4 mr-2" />,
      label: t("account_menu.account_settings"),
      path: isGhostUI ? "/ghost/settings" : "/account/settings",
    },
    {
      icon: <Users className="w-4 h-4 mr-2" />,
      label: t("account_menu.manage_admins"),
      path: isGhostUI ? "/ghost/admins" : "/account/admins",
    },
    {
      icon: <FileText className="w-4 h-4 mr-2" />,
      label: t("account_menu.billing_reports"),
      path: isGhostUI ? "/ghost/billing" : "/account/billing",
    },
    {
      icon: <Mail className="w-4 h-4 mr-2" />,
      label: t("account_menu.contact_us"),
      path: isGhostUI ? "/ghost/contact" : "/account/contact",
    },
    {
      icon: <Flag className="w-4 h-4 mr-2" />,
      label: t("account_menu.report_issue"),
      path: isGhostUI ? "/ghost/report" : "/account/report",
    },
    {
      icon: <Bell className="w-4 h-4 mr-2" />,
      label: t("account_menu.whats_new"),
      path: isGhostUI ? "/ghost/whatsnew" : "/account/whatsnew",
    },
  ];

  const handleSignOut = async () => {
    try {
      const uiContext = isGhostUI ? 'Nygma' : 'BigMIND';
      logger.debug(`Sign out initiated from ${uiContext} account menu`, {
        component: 'AccountMenu',
        data: { 
          isGhostUI,
          pathname: location.pathname
        }
      });
      
      // Pass the UI context to ensure proper redirect
      await logoutAndClearCaches(logout, navigate, isGhostUI);
    } catch (error) {
      logger.error("Error signing out:", error);
      // Fallback - try direct navigation to the appropriate signin page
      navigate(isGhostUI ? '/ghost/auth/signin' : '/auth/signin');
    }
  };

  const handleMenuClick = (path: string) => {
    logger.debug("Navigating to:", {
      component: 'AccountMenu',
      data: { path }
    });
    navigate(path);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <motion.button
          whileHover={{ scale: 1.02 }}
          className={cn(
            "flex items-center px-3 py-2 w-full",
            "rounded-xl transition-all duration-300",
            "hover:bg-gray-50 dark:hover:bg-gray-800/50",
            "text-sm",
            "border border-transparent",
            "hover:border-gray-100 dark:hover:border-gray-800"
          )}
        >
          <div className="w-7 h-7 flex items-center justify-center">
            <div
              className="relative w-6 h-6 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 
                          flex items-center justify-center text-white text-xs font-medium"
            >
              RS
              <motion.div
                className="absolute -bottom-0.5 -right-0.5 w-2 h-2 bg-emerald-400 rounded-full 
                          border-2 border-white dark:border-gray-900"
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
            </div>
          </div>
          {!isSidebarCollapsed && (
            <div className="ml-2 text-left">
              <div className="font-medium text-gray-900 dark:text-gray-100">
                Robin Scott
              </div>
              <div className="text-xs text-gray-500 dark:text-gray-400">
                Account
              </div>
            </div>
          )}
        </motion.button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align="end"
        className={cn(
          "w-56 mt-1",
          "bg-white dark:bg-gray-900",
          "border border-gray-100 dark:border-gray-800",
          "shadow-lg"
        )}
        sideOffset={5}
        side="right"
      >
        {menuItems.map((item) => (
          <DropdownMenuItem
            key={item.path}
            className="cursor-pointer"
            onClick={() => handleMenuClick(item.path)}
          >
            {item.icon}
            {item.label}
          </DropdownMenuItem>
        ))}

        <DropdownMenuSeparator />

        <DropdownMenuItem
          className="cursor-pointer text-rose-500 dark:text-rose-400"
          onClick={handleSignOut}
        >
          <LogOut className="w-4 h-4 mr-2" />
          {t("account_menu.sign_out")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AccountMenu;