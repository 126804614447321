/**
 * @file src/App.tsx
 * @description Root application component
 * @version 1.3.0
 */

import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config';
import { ThemeProvider } from '@/components/theme/theme-provider';
import { QueryProvider } from '@/providers/QueryProvider';
import { AuthProvider } from '@/context/AuthContext';
import { CloudDriveProvider } from '@/context/cloud-drive';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';
// import { GlobalUploadQueue } from '@/components/upload/GlobalUploadQueue';
import { ToastProvider } from '@/components/ui/toast';
import { UploadProvider } from '@/providers/UploadProvider';
import { AccountProvider } from '@/providers/AccountProvider';
import { appRouter, isAuthPage } from '@/routes';
import { logger } from '@/utils/logger';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RootDriveProvider } from '@/context/root-drive/RootDriveContext';
// import { NavigationProvider } from '@/context/NavigationContext';
import { TooltipProvider } from '@/components/ui/tooltip';
import { FilePreviewProvider } from '@/providers/FilePreviewProvider';
import { useEffect, useMemo } from 'react';

const App = () => {
  console.log('App component mounting...');
  
  // Check if we're on an auth page using the imported isAuthPage function
  const onAuthPage = useMemo(() => isAuthPage(), []);
  
  useEffect(() => {
    console.log('App component mounted');
    logger.debug('App initialized with provider configuration', {
      component: 'App',
      isAuthPage: onAuthPage,
      path: window.location.pathname
    });
    
    // Clear any error counts when app initializes on an auth page
    if (onAuthPage) {
      logger.debug('Auth page detected, clearing error states', {
        component: 'App'
      });
      localStorage.removeItem('system_query_failures');
      localStorage.removeItem('auth_error_count');
      
      // Add a class to body for easy styling/targeting
      document.body.classList.add('auth-page');
    } else {
      document.body.classList.remove('auth-page');
    }
    
    return () => {
      console.log('App component unmounting');
    };
  }, [onAuthPage]);
  
  // Always include all required providers even on auth pages
  // This prevents errors like "useRootDrive must be used within RootDriveProvider",
  // "useCloudDrive must be used within CloudDriveProvider", and
  // "useUploadContext must be used within an UploadProvider"
  if (onAuthPage) {
    return (
      <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <QueryProvider>
            <ThemeProvider defaultTheme="system" storageKey="bigmind-theme">
              <ToastProvider>
                <TooltipProvider>
                  <AuthProvider onLogout={() => {
                    // Determine correct auth page based on current path
                    const isGhostUI = window.location.pathname.includes('/ghost/');
                    window.location.href = isGhostUI ? '/ghost/auth/signin' : '/auth/signin';
                  }}>
                    {/* Include all providers in auth pages to prevent initialization errors during navigation */}
                    <AccountProvider>
                      <RootDriveProvider>
                        <CloudDriveProvider>
                          <UploadProvider>
                            <FilePreviewProvider>
                              <RouterProvider router={appRouter} />
                            </FilePreviewProvider>
                          </UploadProvider>
                        </CloudDriveProvider>
                      </RootDriveProvider>
                    </AccountProvider>
                  </AuthProvider>
                </TooltipProvider>
              </ToastProvider>
            </ThemeProvider>
          </QueryProvider>
        </I18nextProvider>
      </ErrorBoundary>
    );
  }
  
  // Full provider structure for authenticated pages
  return (
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <QueryProvider>
          <ThemeProvider defaultTheme="system" storageKey="bigmind-theme">
            <ToastProvider>
              <TooltipProvider>
                <AuthProvider onLogout={() => {
                  const isGhostUI = window.location.pathname.includes('/ghost/');
                  window.location.href = isGhostUI ? '/ghost/auth/signin' : '/auth/signin';
                }}>
                  <AccountProvider>
                    <RootDriveProvider>
                      <DndProvider backend={HTML5Backend}>
                        <CloudDriveProvider>
                          <UploadProvider>
                            <FilePreviewProvider>
                              <RouterProvider router={appRouter} />
                            </FilePreviewProvider>
                          </UploadProvider>
                        </CloudDriveProvider>
                      </DndProvider>
                    </RootDriveProvider>
                  </AccountProvider>
                </AuthProvider>
              </TooltipProvider>
            </ToastProvider>
          </ThemeProvider>
        </QueryProvider>
      </I18nextProvider>
    </ErrorBoundary>
  );
};

export default App;
