/**
 * @file src/routes/encrypted-drive.routes.tsx
 * @description Routing configuration for encrypted drives
 * @version 1.0.0
 * @created 2025-02-25
 */

import React, { Suspense } from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import { NavigationLoader } from '@/components/cloud-drive/loading/NavigationLoader';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';

// Create a lazy-loaded EncryptedDriveView component
const EncryptedDriveView = React.lazy(() => import('@/pages/encrypted-drive/EncryptedDriveView'));

export const encryptedDriveRoutes = {
  path: 'encrypted-drives',
  element: (
    <ErrorBoundary>
      <Suspense fallback={<NavigationLoader isLoading={true} />}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="/data/encrypted-drives" replace />
    },
    {
      path: ':driveId',
      element: <EncryptedDriveView />
    },
    {
      path: '*',
      element: <Navigate to="/data/encrypted-drives" replace />
    }
  ]
};