/**
 * @file: pages/media/index.tsx
 * @description: Media gallery page component that renders the MediaView
 * @version: 1.0.0
 */

import { useParams } from 'react-router-dom';
import { MediaView } from '../../components/media';
import { useCloudDrive } from '../../context/cloud-drive/CloudDriveContext';
import { useEffect } from 'react';

const MediaGalleryPage = () => {
  const { driveId } = useParams<{ driveId: string }>();
  const { currentDrive, setCurrentDriveById } = useCloudDrive();
  
  // Set the current drive based on the URL parameter if provided
  useEffect(() => {
    if (driveId && driveId !== currentDrive?.id) {
      setCurrentDriveById(driveId);
    }
  }, [driveId, currentDrive, setCurrentDriveById]);
  
  // If no drive is selected, show a message
  if (!driveId && !currentDrive) {
    return (
      <div className="p-8 text-center">
        <h2 className="text-2xl font-bold mb-4">Media Gallery</h2>
        <p className="text-gray-500">Please select a drive to view media.</p>
      </div>
    );
  }
  
  return (
    <div className="h-full">
      <MediaView driveId={driveId} />
    </div>
  );
};

export default MediaGalleryPage;