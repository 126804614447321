import { RouteObject } from "react-router-dom";
import MediaGalleryPage from "../pages/media";
import MainLayout from "../components/layout/MainLayout";

export const mediaRoutes: RouteObject[] = [
  {
    path: "/media",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <MediaGalleryPage />,
      },
      {
        path: ":driveId",
        element: <MediaGalleryPage />,
      },
    ],
  },
];