/**
 * @file src/utils/axios.ts
 * @description Axios instance with comprehensive token management
 */

import axios from 'axios';
import { authService } from '@/services/auth.service';
import { logger } from '@/utils/logger';
import { jwtDecode } from 'jwt-decode';
import { refreshTokenWrapper } from '@/context/AuthContext';
import { authRefreshManager } from '@/components/ui/AuthRefreshModal';

/** Minimal shape of a decoded JWT. */
interface JWTPayload {
    exp: number;
    iat: number;
    sub: string;
    type: string;
}

/**
 * Local request config interface with custom flags.
 * Making properties like url and headers optional.
 */
interface RequestConfig {
    url?: string;
    method?: string;
    headers?: Record<string, any>;
    _retry?: boolean;
    _skipAuthRetry?: boolean;
    [key: string]: any;
}

/** Create our Axios instance. */
const api = axios.create({
    baseURL: '/api/v1',
    timeout: 10000,
});

/** Flag indicating whether a token refresh is in progress. */
let isRefreshing = false;

/** Queue of callbacks waiting for the new token. */
let refreshSubscribers: Array<(token: string) => void> = [];

/** Subscribe a callback to be notified once the token is refreshed. */
function subscribeTokenRefresh(cb: (token: string) => void) {
    refreshSubscribers.push(cb);
}

/** Once the token is refreshed, notify all subscribers. */
function onTokenRefreshed(token: string) {
    refreshSubscribers.forEach(cb => cb(token));
    refreshSubscribers = [];
}

/** Returns true if token is near expiry (<= 5s left). */
function isTokenExpired(token: string): boolean {
    try {
        const cleanToken = token.replace(/^bearer\s+/i, '').trim();
        const decoded = jwtDecode<JWTPayload>(cleanToken);
        const now = Date.now() / 1000;
        return decoded.exp - now <= 5;
    } catch {
        return true; // Assume expired if decoding fails
    }
}

/**
 * REQUEST INTERCEPTOR
 * - Attaches the stored access token.
 * - If the token is expired, attempts a refresh (using shared logic).
 */
api.interceptors.request.use(
    async (incoming: any) => {
        const config = incoming as RequestConfig;
        // Skip refresh handling if this is a refresh call.
        if (config._skipAuthRetry || config.url?.includes('auth/refresh')) {
            return config as any;
        }
        try {
            const { accessToken } = authService.getStoredToken();
            if (accessToken) {
                if (isTokenExpired(accessToken)) {
                    if (!isRefreshing) {
                        isRefreshing = true;
                        try {
                            const response = await refreshTokenWrapper();
                            const newToken = response.access_token;
                            onTokenRefreshed(newToken);
                            config.headers = config.headers || {};
                            config.headers.Authorization = `Bearer ${newToken}`;
                        } catch (error) {
                            logger.error('Token refresh failed:', { error });
                            
                            // Clear all auth state
                            authService.clearTokens();
                            
                            // Track failure for circuit breaker
                            const currentFailures = parseInt(localStorage.getItem('auth_error_count') || '0', 10);
                            localStorage.setItem('auth_error_count', String(currentFailures + 1));
                            
                            // Check if there are active uploads or downloads before redirecting
                            const hasActiveUploads = localStorage.getItem('active_uploads') === 'true';
                            const hasActiveDownloads = localStorage.getItem('active_downloads') === 'true';
                            
                            if (hasActiveUploads || hasActiveDownloads) {
                                // If there are active file operations, don't redirect but notify the console
                                logger.warn('Token refresh failed but file operations in progress - showing auth dialog', {
                                    component: 'axios',
                                    action: 'refreshToken',
                                    data: {
                                        uploadsActive: hasActiveUploads,
                                        downloadsActive: hasActiveDownloads
                                    }
                                });
                                
                                // Show the auth refresh dialog instead of redirecting
                                authRefreshManager.showDialog();
                                
                                // Return a special type of error that can be handled by API calls
                                return Promise.reject(new Error('AUTH_SESSION_EXPIRED_WITH_FILE_OPERATIONS'));
                            } else {
                                // Show the auth refresh dialog
                                authRefreshManager.showDialog();
                                
                                // Also return a rejection
                                return Promise.reject(error);
                            }
                        } finally {
                            isRefreshing = false;
                        }
                    } else {
                        // Wait for the ongoing refresh to complete.
                        const newToken = await new Promise<string>(resolve => {
                            subscribeTokenRefresh(token => resolve(token));
                        });
                        config.headers = config.headers || {};
                        config.headers.Authorization = `Bearer ${newToken}`;
                    }
                }
                else {
                    // Token is valid; attach it.
                    config.headers = config.headers || {};
                    
                    // Make sure the token has the Bearer prefix
                    if (accessToken && !accessToken.toLowerCase().startsWith('bearer ')) {
                        config.headers.Authorization = `Bearer ${accessToken}`;
                    } else {
                        config.headers.Authorization = accessToken;
                    }
                    
                    logger.debug('Added token to request', {
                        url: config.url,
                        hasToken: !!accessToken
                    });
                }
            }
            return config as any;
        } catch (err) {
            logger.error('Request interceptor error:', { err });
            return config as any;
        }
    },
    (error: any) => {
        logger.error('Request interceptor error:', { error });
        return Promise.reject(error);
    }
);

/**
 * RESPONSE INTERCEPTOR
 * - On a 401 response, attempt to refresh the token (once).
 * - If multiple requests get a 401 while refresh is in progress, queue them.
 */
api.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
        const originalRequest = error.config as RequestConfig;
        if (!originalRequest || !error.response) {
            logger.error('Network error in API call:', { 
                url: originalRequest?.url,
                error: error.message
            });
            return Promise.reject(error);
        }
        
        // Log all API errors for debugging
        logger.debug('API error response:', {
            url: originalRequest.url,
            status: error.response?.status,
            statusText: error.response?.statusText,
            data: error.response?.data
        });
        
        // Prevent infinite loops.
        if (originalRequest._retry || originalRequest._skipAuthRetry) {
            return Promise.reject(error);
        }
        
        if (error.response.status === 401) {
            originalRequest._retry = true;
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const response = await authService.refreshToken();
                    const newToken = response.access_token;
                    onTokenRefreshed(newToken);
                    originalRequest.headers = originalRequest.headers || {};
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return api.request(originalRequest as any);
                } catch (refreshError) {
                    logger.error('Token refresh failed:', { refreshError });
                    
                    // Clear all auth state
                    authService.clearTokens();
                    
                    // Track failure for circuit breaker
                    const currentFailures = parseInt(localStorage.getItem('auth_error_count') || '0', 10);
                    localStorage.setItem('auth_error_count', String(currentFailures + 1));
                    
                    // Check if there are active uploads or downloads before redirecting
                    const hasActiveUploads = localStorage.getItem('active_uploads') === 'true';
                    const hasActiveDownloads = localStorage.getItem('active_downloads') === 'true';
                    
                    if (hasActiveUploads || hasActiveDownloads) {
                        // If there are active file operations, don't redirect but notify the console
                        logger.warn('Token refresh failed but file operations in progress - showing auth dialog', {
                            component: 'axios',
                            action: 'responseInterceptor',
                            data: {
                                uploadsActive: hasActiveUploads,
                                downloadsActive: hasActiveDownloads
                            }
                        });
                        
                        // Show the auth refresh dialog instead of redirecting
                        authRefreshManager.showDialog();
                        
                        // Return a special type of error that can be handled by API calls
                        return Promise.reject(new Error('AUTH_SESSION_EXPIRED_WITH_FILE_OPERATIONS'));
                    } else {
                        // Show the auth refresh dialog
                        authRefreshManager.showDialog();
                        return Promise.reject(refreshError);
                    }
                } finally {
                    isRefreshing = false;
                }
            }
            // If refresh is already in progress, wait for it.
            try {
                const newToken = await new Promise<string>(resolve => {
                    subscribeTokenRefresh(token => resolve(token));
                });
                originalRequest.headers = originalRequest.headers || {};
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return api.request(originalRequest as any);
            } catch (waitError) {
                return Promise.reject(waitError);
            }
        }
        return Promise.reject(error);
    }
);

export default api;
