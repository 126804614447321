/**
 * @file src/components/cloud-drive/items/hooks/useItemSelection.ts
 * @description Hook for managing item selection state and behavior
 * @version 1.0.0
 */

import { useCallback } from 'react';
import { useSelection } from '@/context/SelectionContext';
import { logger } from '@/utils/logger';
import type { DriveItem } from '@/types/cloud-drive.types';

interface UseItemSelectionProps {
  item: DriveItem;
  items: DriveItem[];
}

interface SelectionHandlers {
  isSelected: boolean;
  handleSelection: (e: React.MouseEvent) => void;
  handleCheckboxChange: (checked: boolean) => void;
  handleRangeSelect: (endId: string) => void;
}

export const useItemSelection = ({
  item,
  items
}: UseItemSelectionProps): SelectionHandlers => {
  const {
    selectedItems,
    toggleSelection,
    selectRange,
    lastSelectedItem,
    isSelectMode,
    setIsSelectMode
  } = useSelection();

  const isSelected = selectedItems.includes(item.id);

  const handleSelection = useCallback((e: React.MouseEvent) => {
if (!e?.preventDefault) {
    logger.warn('Invalid event object received', {
        component: 'useItemInteraction',
        data: { itemId: item.id }
    });
    return;
    }
    e.stopPropagation();

    logger.debug('Selection event', {
      component: 'useItemSelection',
      data: {
        itemId: item.id,
        modifiers: {
          ctrl: e.ctrlKey,
          shift: e.shiftKey
        }
      }
    });

    if (e.shiftKey && lastSelectedItem) {
      selectRange(lastSelectedItem, item.id, items);
    } else {
      if (!isSelectMode && !e.ctrlKey && !e.shiftKey) {
        setIsSelectMode(true);
      }
      toggleSelection(item.id, e);
    }
  }, [item.id, lastSelectedItem, items, isSelectMode, toggleSelection, selectRange, setIsSelectMode]);

  const handleCheckboxChange = useCallback((checked: boolean) => {
    logger.debug('Checkbox change', {
      component: 'useItemSelection',
      data: { itemId: item.id, checked, newState: checked }
    });
    
    // If not already in select mode, make sure to enter it
    if (!isSelectMode) {
      setIsSelectMode(true);
    }
    
    // Toggle the selection state for this item
    toggleSelection(item.id);
    
    // If we're deselecting the last item, exit select mode
    if (!checked && selectedItems.length === 1 && selectedItems[0] === item.id) {
      // Small delay to ensure selection is updated first
      setTimeout(() => {
        setIsSelectMode(false);
      }, 50);
    }
  }, [item.id, toggleSelection, isSelectMode, setIsSelectMode, selectedItems]);

  const handleRangeSelect = useCallback((endId: string) => {
    if (lastSelectedItem) {
      selectRange(lastSelectedItem, endId, items);
    }
  }, [lastSelectedItem, items, selectRange]);

  return {
    isSelected,
    handleSelection,
    handleCheckboxChange,
    handleRangeSelect
  };
};

export default useItemSelection;