/**
 * @file src/components/encrypted-drive/dialogs/steps/StepDriveDetails.tsx
 * @description Drive name and details step
 * @version 1.0.0
 */

import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Folder } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { cn } from '@/utils/utils'; // Add this import
import { logger } from '@/utils/logger';

// Define the missing type
interface CreateDriveFormData {
  name: string;
  encryptionTier: 'standard' | 'advanced' | 'enterprise';
  password: string;
  confirmPassword: string;
  recoveryKey?: string;
}

interface StepDriveDetailsProps {
  formData: CreateDriveFormData;
  setFormData: (data: CreateDriveFormData) => void;
  onNext: () => void;
  onBack: () => void;
}

export const StepDriveDetails: React.FC<StepDriveDetailsProps> = ({
  formData,
  setFormData,
  onNext,
  onBack,
}) => {
  const { t } = useTranslation();
  const [error, setError] = React.useState('');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setFormData({ ...formData, name });
    setError('');
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    
    if (!formData.name.trim()) {
      setError(t('encrypted_drive.errors.name_required'));
      return;
    }

    if (formData.name?.length > 64) {
      setError(t('encrypted_drive.errors.name_too_long'));
      return;
    }

    logger.debug(`Drive name: ${formData.name}`);
    onNext();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <DialogHeader>
        <DialogTitle>
          {t('encrypted_drive.details.title')}
        </DialogTitle>
      </DialogHeader>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-6"
      >
        {/* Drive Name Input */}
        <div className="space-y-4">
          <label
            htmlFor="driveName"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300"
          >
            {t('encrypted_drive.details.name_label')}
          </label>
          
          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
              <Folder className="h-5 w-5 text-gray-400 dark:text-gray-500" />
            </div>
            
            <Input
              id="driveName"
              type="text"
              value={formData.name}
              onChange={handleNameChange}
              className={cn(
                "pl-10",
                error && "border-red-500 dark:border-red-500"
              )}
              placeholder={t('encrypted_drive.details.name_placeholder')}
              autoFocus
            />
          </div>

          {error && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-sm text-red-500 dark:text-red-400"
            >
              {error}
            </motion.p>
          )}

          <p className="text-sm text-gray-500 dark:text-gray-400">
            {t('encrypted_drive.details.name_help')}
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between pt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            className="min-w-[100px]"
          >
            {t('common.back')}
          </Button>
          
          <Button
            type="submit"
            className="min-w-[100px]"
          >
            {t('common.continue')}
          </Button>
        </div>
      </motion.div>
    </form>
  );
};