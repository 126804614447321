/**
 * @file src/components/cloud-drive/browser/FileBrowserView.tsx
 * @description Enhanced file browser with final scroll fixes
 * @version 2.7.0
 * @updated 2025-03-18
 */

import React, { useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import { FileGrid } from './FileGrid';
import { FileList } from './FileList';
import { CloudDriveEmptyState } from '../states/CloudDriveEmptyState';

import { useCloudDrive } from '@/context/cloud-drive';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { useNavigation } from '@/context/NavigationContext';
import { SelectionBox } from '../selection/SelectionBox';

import { logger } from '@/utils/logger';
import type { 
  DriveItem, 
  DriveFile, 
  DriveFolder, 
  DriveItemsResponse 
} from '@/types/cloud-drive.types';
import { useFavorites } from '@/hooks/favorites/useFavorites';
import { DecryptionStatus } from '@/components/encrypted-drive/status/DecryptionStatus';
import { MediaGallery } from './MediaGallery';
import { SharedItems } from './SharedItems';
import { FavoritesView } from '../views/FavoritesView';

interface FileBrowserViewProps {
  folderId: string | null;
  viewType?: 'default' | 'folder' | 'shared' | 'favorites' | 'trash';
  className?: string;
  sortBy?: 'name' | 'size' | 'favorited_at';
  sortOrder?: 'asc' | 'desc';
  showAddFolder?: boolean;
}

export const FileBrowserView = React.forwardRef<HTMLDivElement, FileBrowserViewProps>(({
  folderId,
  viewType = 'default',
  className = '',
  sortBy = 'favorited_at',
  sortOrder = 'desc'
}, _ref) => { // Using _ref to indicate intentionally unused parameter
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  
  // IMPORTANT: Get all context hooks at the top before any conditional logic
  const cloudDriveContext = useCloudDrive(); // Get context first
  const { viewDisplay, viewMode, encryptedDrive, filterConfig, sortConfig, setCurrentFolderContents } = cloudDriveContext;
  const { activeDriveId, setActiveDriveId } = useRootDrive();
  
  // Log the state to debug - only when component mounts and when values actually change
  // and only in development mode to reduce noise in production
  useEffect(() => {
    // Log state changes only in development mode
    if (process.env.NODE_ENV === 'development') {
      logger.debug('FileBrowserView state changed', {
        component: 'FileBrowserView',
        data: {
          viewDisplay,
          viewMode,
          isEncryptedMode: encryptedDrive?.isEncryptedMode
        }
      });
    }
  }, [viewDisplay, viewMode, encryptedDrive?.isEncryptedMode]);

  // Add safer navigation context usage
  let navigationContext;
  try {
    navigationContext = useNavigation();
  } catch (error) {
    logger.warn('Navigation context not available', {
      component: 'FileBrowserView'
    });
    navigationContext = { isNavigating: false };
  }
  const { isNavigating } = navigationContext;
  
  // Add favorites query
  const {
    data: favoritesData,
    isLoading: isFavoritesLoading,
    isError: isFavoritesError,
    refetch: refetchFavorites
  } = useFavorites({
    cursor: undefined,
    sort_by: sortBy,
    sort_order: sortOrder,
    page_size: 50
  });

  // Context hooks with proper state updates
  const { 
    getFolderContents, 
    setSelectedItemId,
    setIsInfoPanelOpen
  } = cloudDriveContext;

  // Data fetching with proper error handling
  const {
    data,
    isLoading,
    isError,
    refetch,
    hasNextPage = false,
    fetchNextPage = () => {},
    isFetchingNextPage = false
  } = viewType === 'favorites' 
    ? { 
        data: favoritesData, 
        isLoading: isFavoritesLoading,
        isError: isFavoritesError,
        refetch: refetchFavorites,
        hasNextPage: false,
        fetchNextPage: () => {},
        isFetchingNextPage: false
      }
    : getFolderContents(folderId);

  // Update drive ID effect to use RootDrive
  useEffect(() => {
    if (data && typeof data === 'object' && 'pages' in data && data.pages && data.pages[0]) {
      const firstPage = data.pages[0] as DriveItemsResponse;
      logger.debug('Checking drive ID from response', {
        component: 'FileBrowserView',
        data: {
          driveId: firstPage.driveId,
          itemCount: firstPage.items?.length,
          currentDriveId: activeDriveId
        }
      });

      // Only update if necessary and in cloud drive view
      if (viewType === 'default' && firstPage.driveId && firstPage.driveId !== activeDriveId) {
        logger.debug('Setting drive ID from folder contents', {
          component: 'FileBrowserView',
          data: { driveId: firstPage.driveId }
        });
        setActiveDriveId(firstPage.driveId);
      }
    }
  }, [data, viewType, activeDriveId, setActiveDriveId]);

  // Update folder contents in context when data changes
  useEffect(() => {
    if (viewType === 'favorites' && favoritesData?.items && Array.isArray(favoritesData.items)) {
      logger.debug('Updating favorites contents', {
        component: 'FileBrowserView',
        data: {
          itemCount: favoritesData.items.length
        }
      });
      setCurrentFolderContents(favoritesData.items);
    } else if (data && typeof data === 'object' && 'pages' in data && data.pages && data.pages[0]?.items) {
      const firstPage = data.pages[0] as DriveItemsResponse;
      logger.debug('Updating folder contents', {
        component: 'FileBrowserView',
        data: {
          folderId,
          itemCount: firstPage.items.length,
          driveId: firstPage.driveId
        }
      });
      setCurrentFolderContents(firstPage.items);

      // If we have a drive ID, make sure it's set
      if (firstPage.driveId) {
        setActiveDriveId(firstPage.driveId);
      }
    }
  }, [viewType, data, favoritesData, folderId, setCurrentFolderContents, setActiveDriveId]);
  
  const showLoader = useMemo(() => {
    // Check for data.pages existing and having length
    const hasPagesWithLength = typeof data === 'object' && 
                              'pages' in data && 
                              data.pages !== undefined && 
                              Array.isArray(data.pages) && 
                              data.pages.length === 0;
                              
    return isNavigating || (isLoading && (!data || hasPagesWithLength));
  }, [isNavigating, isLoading, data]);

  // Process items with filtering and sorting and track total count
  const { processedItems, totalItemCount } = useMemo(() => {
    // For favorites view, handle data structure differently
    if (viewType === 'favorites') {
      if (!favoritesData?.items) return { processedItems: [], totalItemCount: 0 };
      let items = favoritesData.items;
      const totalCount = favoritesData.total || items.length;
      
      // Apply filters
      if (filterConfig?.search) {
        const search = filterConfig.search.toLowerCase();
        items = items.filter((item: DriveItem) => 
          item.name.toLowerCase().includes(search)
        );
      }

      if (filterConfig?.types?.length) {
        items = items.filter((item: DriveItem) => 
          filterConfig.types!.includes(item.type)
        );
      }

      // Show folders first, then apply sorting within each group
      const folders = items.filter(item => item.type === 'folder');
      const files = items.filter(item => item.type === 'file');
      
      // Sort folders and files separately
      return { 
        processedItems: [...folders, ...files], 
        totalItemCount: totalCount 
      };
    }

    // Regular cloud drive view
    if (!data || typeof data !== 'object' || !('pages' in data) || !data.pages) {
      return { processedItems: [], totalItemCount: 0 };
    }
    
    // Extract total count from first page
    const firstPage = data.pages[0] as DriveItemsResponse;
    const totalCount = firstPage.total || firstPage.totalItems || 0;
    
    let allItems: DriveItem[] = [];
    data.pages.forEach((page: any) => {
      if (page.items) {
        allItems = [...allItems, ...page.items];
      }
    });

    // Check if data is paginated (hasNextPage means server-side pagination is in effect)
    const isPaginated = hasNextPage || data.pages.length > 1;

    // Split into folders and files
    const folders = allItems.filter(item => item.type === 'folder');
    const files = allItems.filter(item => item.type === 'file');
    
    // For paginated data (server-side), don't sort by name, only by date or size
    if (isPaginated) {
      // For paginated data, only apply sorting within type groups
      if (sortConfig.field === 'modified' || sortConfig.field === 'created') {
        folders.sort((a: DriveItem, b: DriveItem) => {
          const aDate = new Date(a[sortConfig.field] as string).getTime();
          const bDate = new Date(b[sortConfig.field] as string).getTime();
          return sortConfig.order === 'asc' ? aDate - bDate : bDate - aDate;
        });
        
        files.sort((a: DriveItem, b: DriveItem) => {
          const aDate = new Date(a[sortConfig.field] as string).getTime();
          const bDate = new Date(b[sortConfig.field] as string).getTime();
          return sortConfig.order === 'asc' ? aDate - bDate : bDate - aDate;
        });
      } else if (sortConfig.field === 'size') {
        // Only sort files by size - folders don't have meaningful size
        files.sort((a: any, b: any) => {
          const aSize = a.size || 0;
          const bSize = b.size || 0;
          return sortConfig.order === 'asc' ? aSize - bSize : bSize - aSize;
        });
      }
    } else {
      // For complete data (client-side), we can sort by any field
      // Sort folders
      if (sortConfig.field === 'name') {
        folders.sort((a: DriveItem, b: DriveItem) => {
          return sortConfig.order === 'asc' 
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        });
        
        files.sort((a: DriveItem, b: DriveItem) => {
          return sortConfig.order === 'asc' 
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        });
      } else if (sortConfig.field === 'modified' || sortConfig.field === 'created') {
        folders.sort((a: DriveItem, b: DriveItem) => {
          const aDate = new Date(a[sortConfig.field] as string).getTime();
          const bDate = new Date(b[sortConfig.field] as string).getTime();
          return sortConfig.order === 'asc' ? aDate - bDate : bDate - aDate;
        });
        
        files.sort((a: DriveItem, b: DriveItem) => {
          const aDate = new Date(a[sortConfig.field] as string).getTime();
          const bDate = new Date(b[sortConfig.field] as string).getTime();
          return sortConfig.order === 'asc' ? aDate - bDate : bDate - aDate;
        });
      } else if (sortConfig.field === 'size') {
        // Only sort files by size - folders don't have meaningful size
        files.sort((a: any, b: any) => {
          const aSize = a.size || 0;
          const bSize = b.size || 0;
          return sortConfig.order === 'asc' ? aSize - bSize : bSize - aSize;
        });
      }
    }

    // Return folders first, then files
    return { 
      processedItems: [...folders, ...files], 
      totalItemCount: totalCount 
    };
  }, [viewType, data, favoritesData, filterConfig, sortConfig, hasNextPage]);

  // Loading state
  if (showLoader) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mb-2" />
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {isNavigating ? t('common.navigating') : t('common.loading')}
          </div>
        </div>
      </div>
    );
  }

  // Error state
  if (isError) {
    return (
      <CloudDriveEmptyState 
        type="error"
        customAction={
          <Button variant="outline" onClick={() => refetch()}>
            {t('common.try_again')}
          </Button>
        }
      />
    );
  }

  // IMPORTANT: Check view type BEFORE accessing specific variables
  logger.debug('Checking view display', {
    component: 'FileBrowserView',
    data: {
      viewDisplay,
      isEncryptedMode: encryptedDrive.isEncryptedMode,
      itemCount: processedItems.length
    }
  });

  // If using media, shared, or favorites view, render those components
  if (viewDisplay === 'media') {
    logger.debug('Checking view display', {
      viewDisplay: 'media',
      isEncryptedMode: encryptedDrive.isEncryptedMode,
      itemCount: processedItems.length
    });
    return <MediaGallery folderId={folderId} className={className} />;
  }

  if (viewDisplay === 'shared') {
    logger.debug('Checking view display', {
      viewDisplay: 'shared',
      isEncryptedMode: encryptedDrive.isEncryptedMode,
      itemCount: processedItems.length
    });
    return <SharedItems folderId={folderId} className={className} />;
  }

  if (viewDisplay === 'favorites') {
    logger.debug('Checking view display', {
      viewDisplay: 'favorites',
      isEncryptedMode: encryptedDrive.isEncryptedMode,
      itemCount: processedItems.length
    });
    return <FavoritesView className={className} />;
  }

  // Empty state
  if (!processedItems.length) {
    return (
      <CloudDriveEmptyState 
        type={filterConfig.search ? 'search' : viewType}
        searchQuery={filterConfig.search}
      />
    );
  }

  // Function to handle item selection for the SelectionBox
  const handleItemSelection = (selectedIds: string[]) => {
    // Implement selection logic, likely updating some state
    if (selectedIds?.length === 1) {
      setSelectedItemId(selectedIds[0]);
    } else if (selectedIds?.length > 1) {
      // Handle multi-select case if needed
      console.log(`Selected ${selectedIds.length} items`);
    }
  };

  // FIXED: Remove all height constraints and overflow properties to prevent double scroll
  return (
    <div 
      ref={containerRef}
      className={cn(
        "relative w-full ghost-browser-view no-scroll", // Added specific class with no-scroll marker
        className
      )}
      style={{ overflow: 'visible' }} // Explicit style to override any inherited overflow
    >
      {/* Decryption Status Indicator */}
      {encryptedDrive.isEncryptedMode && (
        <div className="absolute top-2 right-2 z-50">
          <DecryptionStatus />
        </div>
      )}
      {/* Content View */}
      <AnimatePresence mode="wait">
        <motion.div
          key={folderId || 'root'}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
          className="ghost-browser-content no-scroll full-width-content" // Added additional class markers
          style={{ 
            overflow: 'visible', 
            height: 'auto',
            width: '100%',
            maxWidth: '100%',
            padding: '0'  // Add padding to prevent content from touching edges
          }}
        >
          {viewMode === 'grid' ? (
            <FileGrid
              items={processedItems as (DriveFile | DriveFolder)[]}
              hasNextPage={hasNextPage}
              onLoadMore={fetchNextPage}
              isLoadingMore={isFetchingNextPage}
              totalItemCount={totalItemCount}
            />
          ) : (
            <FileList
              items={processedItems as (DriveFile | DriveFolder)[]}
              hasNextPage={hasNextPage}
              onLoadMore={fetchNextPage}
              isLoadingMore={isFetchingNextPage}
              totalItemCount={totalItemCount}
            />
          )}
        </motion.div>
      </AnimatePresence>
  
      {/* Selection Box */}
      <SelectionBox
        containerRef={containerRef}
        items={processedItems as (DriveFile | DriveFolder)[]}
        onSelect={handleItemSelection}
      />
    </div>
  );
});

FileBrowserView.displayName = "FileBrowserView";

export default FileBrowserView;