/**
 * @file src/components/cloud-drive/layout/toolbar/components/SortDropdown.tsx
 * @description Sort dropdown with enhanced options
 * @version 1.0.0
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortAsc } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import type { SortDropdownProps } from '../types';

// src/components/cloud-drive/layout/toolbar/components/SortDropdown.tsx

export const SortDropdown: React.FC<SortDropdownProps> = ({
  sortConfig,
  onSortChange,
  viewType = 'default',  // Add viewType prop
  className
}) => {
  const { t } = useTranslation();

  const handleSortChange = (value: string) => {
    const [field, order] = value.split(':');
    logger.debug('Sort changed', {
      component: 'SortDropdown',
      field,
      order,
      viewType
    } as any);
    onSortChange(field as any, order as 'asc' | 'desc');
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline" 
          size="sm"
          className={cn("hidden md:flex", className)}
        >
          <SortAsc className="h-4 w-4 mr-2" />
          {t('cloud_drive.actions.sort_by')}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuRadioGroup
          value={`${sortConfig.field}:${sortConfig.order}`}
          onValueChange={handleSortChange}
        >
          {/* Show favorites date first if in favorites view */}
          {viewType === 'favorites' && (
            <>
              <DropdownMenuRadioItem value="favorited_at:desc">
                {t('cloud_drive.sort.recent_favorites')}
              </DropdownMenuRadioItem>
              <DropdownMenuSeparator />
            </>
          )}

          {/* Name sorting options removed for this release */}
          {/* Will be added in future release */}
          
          {/* No separator needed here anymore since we removed the name options */}
          
          <DropdownMenuRadioItem value="modified:desc">
            {t('cloud_drive.sort.recent')}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="modified:asc">
            {t('cloud_drive.sort.oldest')}
          </DropdownMenuRadioItem>
          
          <DropdownMenuSeparator />
          
          <DropdownMenuRadioItem value="size:desc">
            {t('cloud_drive.sort.largest')}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="size:asc">
            {t('cloud_drive.sort.smallest')}
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};



export default SortDropdown;


