/**
 * @file src/components/cloud-drive/browser/MediaGallery.tsx
 * @description Enhanced media gallery view with virtualized timeline support
 * @version 3.0.0
 * @updated 2025-03-26
 */

import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { Image, Film, Calendar, Info, Shield, Play, Loader2 } from 'lucide-react';
import { cn } from '@/utils/utils';
import { useCloudDrive } from '@/context/cloud-drive';
import { useFilePreviewContext } from '@/providers/FilePreviewProvider';
import { ThumbnailViewer } from '@/components/encrypted-drive/media/ThumbnailViewer';
import { DecryptionAnimation } from '@/components/encrypted-drive/animations/DecryptionAnimation';
import { logger } from '@/utils/logger';
import { FileContextMenu } from '../actions/FileContextMenu';
import { useSelection } from '@/context/SelectionContext';
import { format, isThisWeek, isToday, parseISO } from 'date-fns';
import { DriveFile } from '@/types/cloud-drive.types';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { useQueryClient } from '@tanstack/react-query';
import { Skeleton } from '@/components/ui/skeleton';

interface MediaGalleryProps {
  folderId: string | null;
  className?: string;
}

interface DriveItem {
  id: string;
  name: string;
  type: string;
  modified: string;
  [key: string]: any;
}

// Extended DriveFile type to include properties needed in this component
interface ExtendedDriveFile extends DriveFile {
  media_info?: {
    thumbnails?: any;
    type?: string;
  };
  mediaInfo?: {
    thumbnails?: any;
    type?: string;
  };
  thumbnailUrl?: string;
}

// Define interface for date group
interface DateGroup {
  label: string;
  files: ExtendedDriveFile[];
  isLoaded: boolean;
}

/**
 * MediaGallery component with optimizations for large media collections
 */
export const MediaGallery: React.FC<MediaGalleryProps> = ({
  folderId,
  className
}) => {
  // === STATE AND HOOKS DECLARATIONS ===
  const { t } = useTranslation();
  const { currentFolderContents, encryptedDrive, forceRefreshFolderContents, filterConfig } = useCloudDrive();
  const { activeDriveId } = useRootDrive();
  const { previewFile } = useFilePreviewContext();
  const { toggleSelection, selectedItems } = useSelection();
  const queryClient = useQueryClient();
  
  // State declarations
  const [hasError, setHasError] = useState(false);
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [groupsProcessed, setGroupsProcessed] = useState(0);
  const [visibleGroups, setVisibleGroups] = useState<string[]>([]);
  const [isDecrypting, setIsDecrypting] = useState(false);
  const [decryptionProgress, setDecryptionProgress] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  // MediaResults state for encrypted mode
  const [mediaResults, setMediaResults] = useState<{
    mediaFiles: any[];
    isLoading: boolean;
    isError: boolean;
    totalCount: number;
    isFetchingNextPage: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => void;
    refetch: () => void;
  }>({
    mediaFiles: [],
    isLoading: true,
    isError: false,
    totalCount: 0,
    isFetchingNextPage: false,
    hasNextPage: false,
    fetchNextPage: () => {},
    refetch: () => {}
  });
  
  // Refs declarations
  const observerRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const infiniteScrollObserver = useRef<IntersectionObserver | null>(null);
  const loadMoreTriggerRef = useRef<HTMLDivElement>(null);
  const hasTriggeredInitialRefresh = useRef(false);
  
  // === EFFECTS ===
  
  // Error boundary effect - with improved error handling
  useEffect(() => {
    const errorHandler = (error: ErrorEvent) => {
      // Log the detailed error information for easier debugging
      logger.error('Unhandled error in MediaGallery', {
        component: 'MediaGallery',
        errorMessage: error.error?.message || 'Unknown error',
        errorStack: error.error?.stack || 'No stack available',
        errorType: error.error?.name || typeof error.error,
        timestamp: new Date().toISOString()
      });
      
      // Set hasError state to trigger fallback UI
      setHasError(true);
      
      // Prevent the error from propagating
      error.preventDefault();
    };
    
    // Add window-level error handler
    window.addEventListener('error', errorHandler);
    
    // Add unhandled promise rejection handler for async errors
    const rejectionHandler = (event: PromiseRejectionEvent) => {
      logger.error('Unhandled promise rejection in MediaGallery', {
        component: 'MediaGallery',
        reason: event.reason?.message || event.reason || 'Unknown reason',
        stack: event.reason?.stack || 'No stack available',
        timestamp: new Date().toISOString()
      });
      
      setHasError(true);
      event.preventDefault();
    };
    
    window.addEventListener('unhandledrejection', rejectionHandler);
    
    return () => {
      window.removeEventListener('error', errorHandler);
      window.removeEventListener('unhandledrejection', rejectionHandler);
    };
  }, []);
  
  // Load encrypted media files
  useEffect(() => {
    if (!encryptedDrive.isEncryptedMode || !encryptedDrive.encryptedDriveId || isDecrypting) {
      return;
    }
    
    const loadMediaFiles = async () => {
      try {
        const { encryptedFilesService } = await import('@/services/encrypted-drive/encrypted-files.service');
        
        // Initial load with a smaller page size to ensure faster initial rendering
        const response = await encryptedFilesService.listMediaFiles({
          driveId: encryptedDrive.encryptedDriveId,
          mediaTypes: ['image', 'video'],
          pageSize: 30 // Reduced from 50 for faster initial load
        });
        
        // Create the fetchNextPage function with improved cursor handling and debugging
        const createFetchNextPage = (nextCursor: string | undefined) => {
          return async () => {
            try {
              // Log the operation with cursor information
              logger.debug('Fetching next page of media files', {
                component: 'MediaGallery.fetchNextPage',
                data: {
                  driveId: encryptedDrive.encryptedDriveId,
                  hasCursor: !!nextCursor,
                  cursor: nextCursor
                }
              });
              
              // Set loading state
              setMediaResults(prev => ({
                ...prev,
                isFetchingNextPage: true
              }));
              
              // Make the API request with consistent page size
              const nextResponse = await encryptedFilesService.listMediaFiles({
                driveId: encryptedDrive.encryptedDriveId!,
                mediaTypes: ['image', 'video'],
                pageSize: 20, // Smaller page size for faster loading
                cursor: nextCursor
              });
              
              // Log the response details
              logger.debug('Next page response received', {
                component: 'MediaGallery.fetchNextPage',
                data: {
                  itemCount: nextResponse.items?.length || 0,
                  hasMore: nextResponse.hasMore,
                  receivedCursor: nextResponse.nextCursor,
                  totalItems: nextResponse.total || 0
                }
              });
              
              // Create new fetch function with the updated cursor
              const newFetchNextPage = createFetchNextPage(nextResponse.nextCursor);
              
              // Update the state with the new items and metadata
              setMediaResults(prev => {
                // Create a new combined items array with deduplication
                const existingIds = new Set(prev.mediaFiles.map(file => file.id));
                const newItems = (nextResponse.items || []).filter(item => !existingIds.has(item.id));
                
                logger.debug('Adding new items to media results', {
                  component: 'MediaGallery.fetchNextPage',
                  data: {
                    existingCount: prev.mediaFiles.length,
                    newItemsCount: newItems.length,
                    totalAfterMerge: prev.mediaFiles.length + newItems.length
                  }
                });
                
                return {
                  mediaFiles: [...prev.mediaFiles, ...newItems],
                  isLoading: false,
                  isError: false,
                  totalCount: nextResponse.total || prev.totalCount,
                  isFetchingNextPage: false,
                  hasNextPage: nextResponse.hasMore || false,
                  fetchNextPage: newFetchNextPage,
                  refetch: prev.refetch
                };
              });
            } catch (e) {
              // Log the error
              logger.error('Error fetching next page', {
                component: 'MediaGallery.fetchNextPage',
                error: e
              });
              
              // Reset fetching state on error
              setMediaResults(prev => ({
                ...prev,
                isFetchingNextPage: false,
                isError: true
              }));
            }
          };
        };
        
        // Create a refetch function
        const refetchFunction = async () => {
          try {
            setMediaResults(prev => ({
              ...prev,
              isLoading: true
            }));
            
            const refetchResponse = await encryptedFilesService.listMediaFiles({
              driveId: encryptedDrive.encryptedDriveId!,
              mediaTypes: ['image', 'video'],
              pageSize: 50,
              forceRefresh: true
            });
            
            const newFetchNextPage = createFetchNextPage(refetchResponse.nextCursor);
            
            setMediaResults({
              mediaFiles: refetchResponse.items || [],
              isLoading: false,
              isError: false,
              totalCount: refetchResponse.total || 0,
              isFetchingNextPage: false,
              hasNextPage: refetchResponse.hasMore || false,
              fetchNextPage: newFetchNextPage,
              refetch: refetchFunction
            });
          } catch (e) {
            setMediaResults(prev => ({
              ...prev,
              isLoading: false,
              isError: true
            }));
          }
        };
        
        setMediaResults({
          mediaFiles: response.items || [],
          isLoading: false,
          isError: false,
          totalCount: response.total || 0,
          isFetchingNextPage: false,
          hasNextPage: response.hasMore || false,
          fetchNextPage: createFetchNextPage(response.nextCursor),
          refetch: refetchFunction
        });
      } catch (error) {
        logger.error('Error loading media files', {
          component: 'MediaGallery',
          error
        });
        
        setMediaResults({
          mediaFiles: [],
          isLoading: false,
          isError: true,
          totalCount: 0,
          isFetchingNextPage: false,
          hasNextPage: false,
          fetchNextPage: () => {},
          refetch: () => {}
        });
      }
    };
    
    loadMediaFiles();
  }, [encryptedDrive.isEncryptedMode, encryptedDrive.encryptedDriveId, isDecrypting]);
  
  // Monitor decryption status
  useEffect(() => {
    if (!encryptedDrive.isEncryptedMode || !encryptedDrive.encryptedDriveId) {
      return;
    }

    const checkDecryptionStatus = async () => {
      try {
        const { encryptedFilesService } = await import('@/services/encrypted-drive/encrypted-files.service');
        
        setIsDecrypting(encryptedFilesService.decryptionStatus.isDecrypting);
        setDecryptionProgress(encryptedFilesService.decryptionStatus.progress);
        
        const intervalId = setInterval(() => {
          if (encryptedFilesService.decryptionStatus.isDecrypting) {
            setIsDecrypting(prev => {
              const newValue = encryptedFilesService.decryptionStatus.isDecrypting;
              return prev !== newValue ? newValue : prev;
            });
            
            setDecryptionProgress(prev => {
              const newValue = encryptedFilesService.decryptionStatus.progress;
              return Math.abs(prev - newValue) >= 5 ? newValue : prev;
            });
          } else {
            clearInterval(intervalId);
            
            setIsDecrypting(false);
            setDecryptionProgress(100);
          }
        }, 500);
        
        return () => {
          clearInterval(intervalId);
        };
      } catch (error) {
        logger.error('Error monitoring decryption status', {
          component: 'MediaGallery',
          error
        });
      }
    };
    
    checkDecryptionStatus();
  }, [encryptedDrive.isEncryptedMode, encryptedDrive.encryptedDriveId]);

  // Component initialization
  useEffect(() => {
    // Always start with Today visible for better UX
    setVisibleGroups([t('cloud_drive.date_groups.today') || 'Today']);
      
    // Set a unique timestamp in localStorage to track view state
    localStorage.setItem('media_view_refresh_timestamp', Date.now().toString());
    
    // Make sure folder stats are refreshed
    const refreshFolderStats = async () => {
      if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId && folderId) {
        try {
          const { encryptedDriveApi } = await import('@/services/encrypted-drive/encrypted-drive.service');
          await encryptedDriveApi.updateFolderStats(encryptedDrive.encryptedDriveId, folderId);
          logger.debug('Encrypted drive folder stats updated', {
            component: 'MediaGallery',
            data: {
              driveId: encryptedDrive.encryptedDriveId,
              folderId
            }
          });
        } catch (error) {
          logger.warn('Failed to update folder stats', {
            component: 'MediaGallery',
            error,
            data: {
              driveId: encryptedDrive.encryptedDriveId,
              folderId
            }
          });
        }
      } else {
        try {
          const { cloudDriveService } = await import('@/services/cloud-drive.service');
          if (folderId) {
            await cloudDriveService.getFolderDetails(folderId);
            logger.debug('Cloud drive folder stats checked', {
              component: 'MediaGallery',
              data: { folderId }
            });
          }
        } catch (error) {
          logger.warn('Failed to check regular folder stats', {
            component: 'MediaGallery',
            error,
            data: { folderId }
          });
        }
      }
    };
    
    refreshFolderStats();
    
    // Force a refresh on initial mount with added debugging
    logger.debug('MediaGallery initial mount', {
      component: 'MediaGallery',
      data: {
        hasRefreshFunc: !!forceRefreshFolderContents,
        hasTriggeredRefresh: hasTriggeredInitialRefresh.current,
        folderId,
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        encryptedDriveId: encryptedDrive.encryptedDriveId,
        currentFolderContentsType: typeof currentFolderContents,
        currentFolderItemCount: Array.isArray(currentFolderContents) ? currentFolderContents.length : 0
      }
    });
    
    if (forceRefreshFolderContents && !hasTriggeredInitialRefresh.current) {
      hasTriggeredInitialRefresh.current = true;
      
      // First try immediate refresh
      forceRefreshFolderContents();
      
      // Then schedule a delayed refresh to ensure content loads
      setTimeout(() => {
        logger.debug('Performing delayed refresh', {
          component: 'MediaGallery'
        });
        forceRefreshFolderContents();
        
        // Check if content loaded - avoid referencing mediaFiles directly
        // to prevent initialization errors
        setTimeout(() => {
          const hasContent = !!currentFolderContents && Array.isArray(currentFolderContents) && currentFolderContents.length > 0;
          
          if (!hasContent) {
            logger.debug('No content loaded after initial refreshes, trying batch mode', {
              component: 'MediaGallery',
              data: {
                currentFolderContentsLength: currentFolderContents?.length || 0
              }
            });
            forceRefreshFolderContents(true); // Use batch mode for final attempt
          }
        }, 1000);
      }, 500);
    }
  }, [t, folderId, forceRefreshFolderContents, encryptedDrive.isEncryptedMode, encryptedDrive.encryptedDriveId, currentFolderContents]);
  
  // === MEMOIZED VALUES ===
  
  // Extract thumbnail information helper
  const getThumbnailInfo = useCallback((item: ExtendedDriveFile) => {
    try {
      if (item.media_info?.thumbnails) {
        return item.media_info.thumbnails;
      }
      
      if (item.mediaInfo?.thumbnails) {
        return item.mediaInfo.thumbnails;
      }
      
      return null;
    } catch (error) {
      return null;
    }
  }, []);
  
  // Get media type helper
  const getMediaType = useCallback((item: ExtendedDriveFile) => {
    // First check media_info.type
    const mediaInfo = item.media_info || item.mediaInfo;
    if (mediaInfo?.type) {
      return mediaInfo.type;
    }
    
    // Then check mimeType
    if (item.mimeType) {
      return item.mimeType.startsWith('image/') ? 'image' : 'video';
    }
    
    // Fallback to file extension
    const fileExt = item.name?.toLowerCase().split('.').pop() || '';
    const imageExts = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp', 'svg'];
    const videoExts = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv', '3gp'];
    
    if (imageExts.includes(fileExt)) return 'image';
    if (videoExts.includes(fileExt)) return 'video';
    
    return null;
  }, []);
  
  // Filter media files with enhanced error handling
  const mediaFiles = useMemo(() => {
    // First set loading state
    setIsLoading(true);
    
    try {
      // If in encrypted mode, use our pre-loaded media files
      if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
        // Check that mediaResults is properly initialized
        if (!mediaResults || !Array.isArray(mediaResults.mediaFiles)) {
          logger.warn('Media results not properly initialized in encrypted mode', {
            component: 'MediaGallery',
            data: { 
              isEncryptedMode: true,
              hasMediaResults: !!mediaResults,
              mediaFilesType: mediaResults ? typeof mediaResults.mediaFiles : 'undefined'
            }
          });
          setIsLoading(false);
          return [];
        }
        
        // Apply media type filtering if specified in the filter config
        let filteredFiles = [...mediaResults.mediaFiles];
        
        if (filterConfig?.mediaType) {
          logger.debug('Applying media type filter', {
            component: 'MediaGallery',
            data: { mediaType: filterConfig.mediaType }
          });
          
          filteredFiles = filteredFiles.filter(file => {
            const mediaType = getMediaType(file);
            
            if (filterConfig.mediaType === 'photos') {
              return mediaType === 'image';
            } else if (filterConfig.mediaType === 'videos') {
              return mediaType === 'video';
            }
            
            return true; // If filter is 'all' or unknown, show everything
          });
        }
        
        setIsLoading(false);
        return filteredFiles;
      }
      
      // Safety check for currentFolderContents
      if (!currentFolderContents) {
        logger.debug('No current folder contents available', {
          component: 'MediaGallery',
          data: { folderId }
        });
        setIsLoading(false);
        return [];
      }
      
      // Safety check - if array is empty but defined
      if (!Array.isArray(currentFolderContents) || currentFolderContents.length === 0) {
        logger.debug('Current folder contents is empty', {
          component: 'MediaGallery',
          data: { 
            folderId,
            isArray: Array.isArray(currentFolderContents),
            length: Array.isArray(currentFolderContents) ? currentFolderContents.length : 0
          }
        });
        setIsLoading(false);
        return [];
      }
      
      // Add extra logging to debug what's in the folder
      logger.debug('Current folder contents before filtering', {
        component: 'MediaGallery',
        data: {
          folderItemCount: currentFolderContents.length,
          sampleItems: currentFolderContents.slice(0, 3).map(item => ({
            id: item.id,
            name: item.name,
            type: item.type,
            mimeType: (item as any).mimeType || 'unknown'
          }))
        }
      });
      
      // Apply media file filtering with more robust checks
      const filteredFiles = currentFolderContents
        .filter((item: DriveItem) => {
          // Skip invalid or non-file items
          if (!item || typeof item !== 'object') {
            return false;
          }
          
          // Accept all items of type 'file' for debugging
          if (item.type === 'file') {
            try {
              // For non-encrypted, try multiple ways to determine media type
              const fileItem = item as DriveFile;
              const mimeType = fileItem.mimeType || '';
              
              // First check mimeType
              if (mimeType.startsWith('image/') || mimeType.startsWith('video/')) {
                return true;
              }
              
              // If no valid mimeType, try checking file extension
              const fileExtension = fileItem.name?.toLowerCase().split('.').pop() || '';
              const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'bmp', 'heic'];
              const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'wmv', 'm4v'];
              
              if (imageExtensions.includes(fileExtension) || videoExtensions.includes(fileExtension)) {
                return true;
              }
              
              // Also check media_info or mediaInfo if available
              if (fileItem.media_info?.type === 'image' || fileItem.media_info?.type === 'video' ||
                  fileItem.mediaInfo?.type === 'image' || fileItem.mediaInfo?.type === 'video') {
                return true;
              }
              
              return false;
            } catch (itemError) {
              // If any individual item causes an error, log and skip it rather than failing entire operation
              logger.warn('Error processing media item', {
                component: 'MediaGallery',
                error: itemError,
                data: { itemId: item.id }
              });
              return false;
            }
          }
          
          return false;
        }) as unknown as ExtendedDriveFile[];
      
      logger.debug('Filtered media files', {
        component: 'MediaGallery',
        data: { 
          totalItems: currentFolderContents.length,
          filteredCount: filteredFiles.length,
          folderId,
          sampleFilteredItems: filteredFiles.slice(0, 3).map(item => ({
            id: item.id,
            name: item.name,
            type: item.type,
            mimeType: item.mimeType || 'unknown'
          }))
        }
      });
      
      setIsLoading(false);
      return filteredFiles;
    } catch (error) {
      // Log the error for debugging
      logger.error('Error filtering media files', {
        component: 'MediaGallery',
        error,
        data: { 
          folderId,
          currentFolderContentsType: typeof currentFolderContents,
          isEncryptedMode: encryptedDrive.isEncryptedMode
        }
      });
      
      setIsLoading(false);
      return [];
    }
  }, [
    currentFolderContents, 
    encryptedDrive.isEncryptedMode, 
    encryptedDrive.encryptedDriveId, 
    mediaResults?.mediaFiles,
    folderId,
    filterConfig,
    getMediaType // Include getMediaType as it's used in the filter
  ]);
  
  // Group by date with enhanced error handling
  const groupedMedia = useMemo(() => {
    // First make sure we have valid media files to process
    if (!mediaFiles || !Array.isArray(mediaFiles) || mediaFiles.length === 0) {
      // Set groups processed to 0 when we have no files
      setGroupsProcessed(0);
      return [];
    }
    
    try {
      // Create a map for faster lookups
      const groups: { [key: string]: ExtendedDriveFile[] } = {};
      const allDates: string[] = [];
    
      // Make sure translation function is available
      const todayLabel = typeof t === 'function' ? t('date_groups.today') || 'Today' : 'Today';
      const thisWeekLabel = typeof t === 'function' ? t('date_groups.this_week') || 'This Week' : 'This Week';
      
      // First pass: process each file individually with error handling
      mediaFiles.forEach((file: ExtendedDriveFile, index: number) => {
        // Skip invalid files
        if (!file || typeof file !== 'object') return;
        
        try {
          // Safely parse the date with fallback
          let date: Date;
          
          if (file.modified && typeof file.modified === 'string') {
            date = new Date(file.modified);
            // If date is invalid, use current date as fallback
            if (isNaN(date.getTime())) {
              date = new Date();
              file.modified = date.toISOString();
            }
          } else {
            // If no modified date present, use current date
            date = new Date();
            file.modified = date.toISOString();
          }
          
          // Determine the date key for grouping
          let dateKey: string;
          
          // Today
          if (isToday(date)) {
            dateKey = todayLabel;
          } 
          // This week
          else if (isThisWeek(date)) {
            dateKey = thisWeekLabel;
          }
          // This month/year
          else {
            try {
              dateKey = format(date, 'MMMM yyyy');
            } catch (formatErr) {
              // If date formatting fails, use a generic bucket
              dateKey = 'Other';
              logger.warn('Error formatting date', {
                component: 'MediaGallery',
                error: formatErr,
                data: { date: file.modified }
              });
            }
          }
          
          if (!groups[dateKey]) {
            groups[dateKey] = [];
            allDates.push(dateKey);
          }
          
          groups[dateKey].push(file);
        } catch (fileError) {
          // Log and skip problematic files rather than failing entire group
          logger.warn('Error processing file for date grouping', {
            component: 'MediaGallery',
            error: fileError,
            data: { 
              fileId: file.id, 
              fileName: file.name,
              modified: file.modified,
              index
            }
          });
        }
      });
      
      // If we have no valid groups after processing, return early
      if (allDates.length === 0) {
        setGroupsProcessed(0);
        return [];
      }
      
      // Custom sorting logic: Today → This Week → Months (most recent first)
      const sortedDates = allDates.sort((a, b) => {
        // Special keys always come first
        if (a === todayLabel) return -1;
        if (b === todayLabel) return 1;
        if (a === thisWeekLabel) return -1;
        if (b === thisWeekLabel) return 1;
        
        try {
          const parseMonth = (monthStr: string) => {
            // Handle all strings safely
            try {
              const parts = monthStr.split(' ');
              // Need at least month and year
              if (parts.length < 2) return new Date(0); // Return epoch if invalid
              
              const month = parts[0].toLowerCase();
              const year = parseInt(parts[1]);
              
              // If year isn't a valid number, use current year
              if (isNaN(year)) return new Date(0);
              
              const monthNames = ['january', 'february', 'march', 'april', 'may', 'june', 
                                'july', 'august', 'september', 'october', 'november', 'december'];
              
              const monthIndex = monthNames.findIndex(m => m.toLowerCase() === month);
              
              // If month isn't valid, use January
              return new Date(year, monthIndex >= 0 ? monthIndex : 0);
            } catch (parseError) {
              logger.warn('Error parsing month string', {
                component: 'MediaGallery',
                error: parseError,
                data: { monthStr }
              });
              return new Date(0); // Return epoch if parsing fails
            }
          };
          
          // Most recent first
          return parseMonth(b).getTime() - parseMonth(a).getTime();
        } catch (sortError) {
          // Fall back to string comparison if date parsing fails
          logger.warn('Error sorting dates, falling back to string compare', {
            component: 'MediaGallery',
            error: sortError,
            data: { a, b }
          });
          return b.localeCompare(a);
        }
      });
      
      // Sort files within each group by date (newest first)
      const sortFilesInGroup = (groupLabel: string) => {
        const files = groups[groupLabel] || [];
        
        if (files.length === 0) return files;
        
        try {
          // Sort by modified date, newest first
          return files.sort((a, b) => {
            try {
              // Convert dates to timestamps for comparison
              const dateA = new Date(a.modified || 0).getTime();
              const dateB = new Date(b.modified || 0).getTime();
              
              // If both dates are valid, compare them
              if (!isNaN(dateA) && !isNaN(dateB)) {
                return dateB - dateA;
              }
              
              // Handle invalid dates - ensure they're at the end
              if (isNaN(dateA)) return 1; 
              if (isNaN(dateB)) return -1;
              
              return 0; // Should never reach here
            } catch (e) {
              return 0; // Keep original order if comparison fails
            }
          });
        } catch (sortError) {
          // If sorting fails, just return unsorted
          logger.warn('Error sorting files in group', {
            component: 'MediaGallery',
            error: sortError,
            data: { groupLabel, fileCount: files.length }
          });
          return files;
        }
      };
      
      // Now create the final array of date groups
      const result = sortedDates.map(label => ({
        label,
        files: visibleGroups.includes(label) ? sortFilesInGroup(label) : [],
        isLoaded: visibleGroups.includes(label)
      }));
      
      setGroupsProcessed(result.length);
      
      return result;
    } catch (error) {
      // Log any unexpected errors during the entire process
      logger.error('Error grouping media files by date', {
        component: 'MediaGallery',
        error,
        data: { 
          mediaFilesCount: mediaFiles.length,
          visibleGroupsCount: visibleGroups.length
        }
      });
      
      // Reset groups processed count
      setGroupsProcessed(0);
      return [];
    }
  }, [mediaFiles, t, visibleGroups]);
  
  // Reference to track the latest fetchNextPage function
  const fetchNextPageRef = useRef(mediaResults.fetchNextPage);
  
  // Update the ref whenever fetchNextPage changes - separate effect to avoid nesting
  useEffect(() => {
    fetchNextPageRef.current = mediaResults.fetchNextPage;
  }, [mediaResults.fetchNextPage]);
  
  // Initialize infinite scroll observation - completely rewritten for reliability
  useEffect(() => {
    
    // Only set up observation if we have more pages to load and we're not currently decrypting
    const shouldObserve = !isDecrypting && 
                        mediaResults.mediaFiles?.length > 0 && 
                        mediaResults.hasNextPage && 
                        !mediaResults.isFetchingNextPage;
    
    // Clean up any existing observer
    if (infiniteScrollObserver.current) {
      infiniteScrollObserver.current.disconnect();
      infiniteScrollObserver.current = null;
    }
    
    // Debug log whenever page loading conditions change
    logger.debug('Infinite scroll conditions updated', {
      component: 'MediaGallery.infiniteScroll',
      data: {
        isDecrypting,
        mediaFilesCount: mediaResults.mediaFiles?.length || 0,
        hasNextPage: mediaResults.hasNextPage,
        isFetchingNextPage: mediaResults.isFetchingNextPage,
        shouldObserve
      }
    });
    
    if (shouldObserve) {
      // Create a reliable intersection handler that captures the latest state
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        
        if (entry && entry.isIntersecting) {
          logger.debug('Load more trigger is visible', {
            component: 'MediaGallery.infiniteScroll',
            data: {
              intersectionRatio: entry.intersectionRatio,
              isIntersecting: entry.isIntersecting,
              hasNextPage: mediaResults.hasNextPage,
              isFetchingNextPage: mediaResults.isFetchingNextPage
            }
          });
          
          // Only fetch if we're not already fetching and there's more to fetch
          // Use the ref to get the latest fetchNextPage function
          if (!mediaResults.isFetchingNextPage && mediaResults.hasNextPage) {
            // Short delay to avoid rapid API calls
            setTimeout(() => {
              if (typeof fetchNextPageRef.current === 'function') {
                logger.debug('Executing fetchNextPage', {
                  component: 'MediaGallery.infiniteScroll'
                });
                
                fetchNextPageRef.current();
              } else {
                logger.warn('fetchNextPage function not available', {
                  component: 'MediaGallery.infiniteScroll',
                  data: {
                    fetchNextPageType: typeof fetchNextPageRef.current
                  }
                });
              }
            }, 100);
          }
        }
      };
      
      // Create a more aggressive observer with larger margins to trigger earlier
      infiniteScrollObserver.current = new IntersectionObserver(
        handleIntersection,
        { 
          // Larger margin to start loading before the element is visible
          rootMargin: '800px 0px 800px 0px',
          // Lower threshold so it triggers more easily
          threshold: 0.01
        }
      );
      
      // Only observe if we have a valid reference element
      if (loadMoreTriggerRef.current) {
        infiniteScrollObserver.current.observe(loadMoreTriggerRef.current);
        
        logger.debug('Infinite scroll observer initialized', {
          component: 'MediaGallery.infiniteScroll',
          data: { 
            loadMoreElementExists: !!loadMoreTriggerRef.current,
            observerActive: !!infiniteScrollObserver.current
          }
        });
      } else {
        logger.warn('Load more trigger element not found', {
          component: 'MediaGallery.infiniteScroll'
        });
      }
    }
    
    // Clean up observer on unmount
    return () => {
      if (infiniteScrollObserver.current) {
        infiniteScrollObserver.current.disconnect();
        infiniteScrollObserver.current = null;
      }
    };
  }, [
    isDecrypting,
    mediaResults.hasNextPage,
    mediaResults.isFetchingNextPage,
    mediaResults.mediaFiles?.length
  ]);
  
  // Set up intersection observer for each group - optimized to reduce render cycles
  useEffect(() => {
    // Only run if we have valid groupedMedia data
    if (!groupedMedia || groupedMedia.length === 0) {
      return;
    }
    
    // Track which groups need new observers to minimize DOM operations
    const newGroups: string[] = [];
    const existingGroups: string[] = [];
    
    // Create/update refs for each group
    groupedMedia.forEach(group => {
      if (!observerRefs.current[group.label]) {
        observerRefs.current[group.label] = React.createRef<HTMLDivElement>();
        newGroups.push(group.label);
      } else {
        existingGroups.push(group.label);
      }
    });
    
    // Clean up stale refs - build list first to avoid modifying during iteration
    const staleKeys = Object.keys(observerRefs.current)
      .filter(key => !groupedMedia.find(g => g.label === key));
    
    staleKeys.forEach(key => {
      delete observerRefs.current[key];
    });
    
    // Log group changes to help debug
    if (newGroups.length > 0 || staleKeys.length > 0) {
      logger.debug('Group visibility observer updating refs', {
        component: 'MediaGallery',
        data: {
          newGroupsCount: newGroups.length,
          existingGroupsCount: existingGroups.length,
          removedGroupsCount: staleKeys.length
        }
      });
    }
    
    // Create a stable intersection handler to prevent unnecessary state updates
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      // First collect all new visible groups
      const newVisibleGroups: string[] = [];
      
      entries.forEach(entry => {
        const groupId = entry.target.getAttribute('data-group-id');
        // Only add truly new groups to minimize state updates
        if (groupId && entry.isIntersecting && !visibleGroups.includes(groupId)) {
          newVisibleGroups.push(groupId);
        }
      });
      
      // Only update state if we actually have new groups
      if (newVisibleGroups.length > 0) {
        // Use a callback to ensure we're working with the latest state
        setVisibleGroups(prev => {
          // Double-check to avoid adding duplicates
          const filtered = newVisibleGroups.filter(id => !prev.includes(id));
          if (filtered.length === 0) return prev; // No changes needed
          
          logger.debug('Adding new visible groups', {
            component: 'MediaGallery',
            data: {
              addedGroups: filtered,
              totalVisibleGroups: prev.length + filtered.length
            }
          });
          
          return [...prev, ...filtered];
        });
      }
    };
    
    // Set up observer for lazy loading groups - create only once
    const observer = new IntersectionObserver(
      handleIntersection,
      { 
        rootMargin: '300px 0px 300px 0px',
        threshold: 0.05  // Slightly higher threshold to reduce sensitivity
      }
    );
    
    // Only observe groups that have valid refs
    const refsToObserve = Object.entries(observerRefs.current);
    
    for (const [label, ref] of refsToObserve) {
      if (ref.current) {
        // Set data attribute for identification
        ref.current.setAttribute('data-group-id', label);
        // Only observe if it's not already visible
        if (!visibleGroups.includes(label)) {
          observer.observe(ref.current);
        }
      }
    }
    
    return () => {
      observer.disconnect();
    };
  }, [groupedMedia]); // Remove visibleGroups dependency to prevent repeated recreation
  
  // === HANDLERS ===
  
  // Handle media item click
  const handleMediaClick = useCallback((file: ExtendedDriveFile, allFiles?: ExtendedDriveFile[]) => {
    // Use the provided files or fallback to current mediaFiles
    const filesToPreview = allFiles || mediaFiles;
    previewFile(file, filesToPreview);
  }, [previewFile, mediaFiles]);
  
  // Handle selection
  const handleSelect = useCallback((fileId: string, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    toggleSelection(fileId);
  }, [toggleSelection]);
  
  // Enhanced manual refresh handler with explicit UI feedback
  const handleRefresh = useCallback(() => {
    // Set refreshing state to show UI feedback
    setIsRefreshing(true);
    
    // Clear cached media groups to force reload
    setVisibleGroups([t('cloud_drive.date_groups.today') || 'Today']);
    
    // Signal that a refresh was requested through localStorage
    localStorage.setItem('media_view_refresh_timestamp', Date.now().toString());
    
    // Log refresh attempt for debugging
    logger.debug('Manual refresh triggered', {
      component: 'MediaGallery',
      data: {
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        encryptedDriveId: encryptedDrive.encryptedDriveId,
        folderId,
        folderContentsCount: currentFolderContents?.length || 0
      }
    });
    
    // Clear any cached media files if in encrypted mode
    const clearCache = async () => {
      if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
        try {
          const { mediaCacheService } = await import('@/services/encrypted-drive/media/media-cache.service');
          mediaCacheService.clearDriveCache(encryptedDrive.encryptedDriveId || '');
          mediaCacheService.clearAnimatedFilesForDrive(encryptedDrive.encryptedDriveId || '');
          logger.debug('Media cache cleared', {
            component: 'MediaGallery',
            data: { driveId: encryptedDrive.encryptedDriveId }
          });
        } catch (error) {
          logger.error('Error clearing media cache', {
            component: 'MediaGallery',
            error
          });
        }
      }
    };
    
    clearCache()
      .then(() => {
        // Execute primary refresh operations
        const performRefresh = async () => {
          // First try CloudDriveContext's force refresh (batch mode for thorough refresh)
          if (forceRefreshFolderContents) {
            forceRefreshFolderContents(true);
          }
          
          // Also directly invalidate relevant queries for maximum thoroughness
          if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
            // Invalidate all encrypted drive queries related to this folder
            queryClient.invalidateQueries({
              queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, folderId]
            });
            
            queryClient.invalidateQueries({
              queryKey: ['encrypted-drive', 'media', encryptedDrive.encryptedDriveId]
            });
            
            // Additional invalidation for special cases
            queryClient.invalidateQueries({
              queryKey: ['cloud-drive', 'folder', folderId, encryptedDrive.encryptedDriveId, true]
            });
          } else if (activeDriveId) {
            // Invalidate standard drive queries
            queryClient.invalidateQueries({
              queryKey: ['cloud-drive', 'folder', folderId, activeDriveId, false]
            });
            
            // General folder invalidation
            queryClient.invalidateQueries({
              queryKey: ['cloud-drive', 'folder', folderId]
            });
          }
          
          // Force immediate refetch - more direct way
          try {
            if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
              // For encrypted drives, use the encrypted files service directly
              const { encryptedFilesService } = await import('@/services/encrypted-drive/encrypted-files.service');
              const response = await encryptedFilesService.listContents({
                driveId: encryptedDrive.encryptedDriveId,
                folderId,
                forceRefresh: true // Request fresh data
              });
              
              logger.debug('Direct encrypted folder refresh result', {
                component: 'MediaGallery',
                data: {
                  itemCount: response.items?.length || 0,
                  hasMore: response.hasMore
                }
              });
              
              // Also refresh media files list
              if (mediaResults.refetch) {
                await mediaResults.refetch();
              }
            } else if (activeDriveId) {
              // For standard drives, use cloud drive service
              const { cloudDriveService } = await import('@/services/cloud-drive.service');
              const response = await cloudDriveService.listFolderContents(folderId, undefined, true);
              
              logger.debug('Direct standard folder refresh result', {
                component: 'MediaGallery',
                data: {
                  itemCount: response.items?.length || 0,
                  hasMore: response.hasMore
                }
              });
            }
          } catch (error) {
            logger.error('Error during direct refresh attempt', {
              component: 'MediaGallery',
              error
            });
          }
          
          // Finally, try one more time with React Query's refetch
          await queryClient.refetchQueries({
            queryKey: ['cloud-drive', 'folder']
          });
          
          // Set a timeout to turn off the refreshing indicator
          setTimeout(() => {
            setIsRefreshing(false);
            
            // Log refresh completion
            logger.debug('Manual refresh completed', {
              component: 'MediaGallery',
              data: {
                folderContentsCount: currentFolderContents?.length || 0,
                encryptedModeItemCount: encryptedDrive.isEncryptedMode ? mediaResults.mediaFiles?.length || 0 : 0
              }
            });
          }, 1000);
        };
        
        performRefresh().catch(e => {
          logger.error('Error during refresh operations', {
            component: 'MediaGallery',
            error: e
          });
          setIsRefreshing(false);
        });
      })
      .catch(() => {
        // Ensure we turn off refreshing state even if there's an error
        setIsRefreshing(false);
      });
  }, [
    t,
    forceRefreshFolderContents, 
    queryClient, 
    encryptedDrive.isEncryptedMode, 
    encryptedDrive.encryptedDriveId, 
    folderId, 
    activeDriveId, 
    mediaResults,
    currentFolderContents
  ]);
  
  // === CONDITIONAL RENDERING ===
  
  // Error fallback UI is now handled at the top of the render function
  if (hasError) {
    return (
      <div className="p-8 flex flex-col items-center justify-center min-h-[400px] text-center">
        <div className="p-4 rounded-full bg-red-100 dark:bg-red-900/20 mb-4">
          <Info className="h-8 w-8 text-red-500" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
          {t('drive.media.error') || 'Something went wrong'}
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md">
          {t('drive.media.error_description') || 'There was an error loading the media gallery. Please try refreshing the page.'}
        </p>
        <div className="flex space-x-3 mt-4">
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white text-sm"
          >
            {t('drive.media.refresh') || 'Refresh'}
          </button>
          <button 
            onClick={() => {
              // Reset error state to attempt recovery without full page reload
              setHasError(false);
              
              // Attempt to force refresh folder contents if the function is available
              if (forceRefreshFolderContents) {
                setTimeout(() => forceRefreshFolderContents(), 100);
              }
            }}
            className="px-4 py-2 rounded-md bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-gray-200 text-sm"
          >
            {t('drive.media.try_again') || 'Try Again'}
          </button>
        </div>
      </div>
    );
  }
  
  // Determine if we're still in a loading state
  const isLoadingState = isLoading || 
                       mediaResults.isLoading || 
                       isDecrypting;

  // Empty state - handle both null and empty arrays
  const hasNoMedia = (!mediaFiles || mediaFiles.length === 0);
  
  // Show loading state if we're loading or decrypting
  if (hasNoMedia) {
    if (isLoadingState) {
      // Loading or decrypting state
      return (
        <div className="p-4">
          <div className="animate-pulse flex items-center mb-6">
            <Skeleton className="h-7 w-7 rounded-full mr-2" />
            <Skeleton className="h-6 w-28" />
          </div>
          
          {/* Show custom decryption UI if decrypting */}
          {isDecrypting ? (
            <div className="flex flex-col items-center justify-center h-60 p-4">
              <Loader2 className="h-10 w-10 animate-spin text-blue-500 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
                {t('drive.media.decrypting_title') || 'Decrypting Files'}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md text-center mb-4">
                {t('drive.media.decrypting_description') || 'Please wait while your files are being decrypted...'}
              </p>
              
              {/* Progress bar */}
              <div className="w-full max-w-md">
                <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                  <div 
                    className="h-full bg-blue-500 rounded-full transition-all duration-300 ease-out"
                    style={{ width: `${Math.max(5, decryptionProgress)}%` }}
                  />
                </div>
                <div className="text-xs text-right mt-1 text-gray-500">
                  {Math.round(decryptionProgress)}%
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
              {[...Array(12)].map((_, i) => (
                <Skeleton key={i} className="aspect-square rounded-lg" />
              ))}
            </div>
          )}
        </div>
      );
    }
    
    return (
      <div className="flex flex-col items-center justify-center h-96 p-8">
        <div className="p-4 rounded-full bg-gray-100 dark:bg-gray-800 mb-4">
          <Image className="h-8 w-8 text-gray-400 dark:text-gray-500" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
          {t('drive.media.empty') || 'No media files'}
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md text-center">
          {t('drive.media.empty_description') || 'Upload images or videos to see them here'}
        </p>
        <button 
          onClick={handleRefresh}
          className="mt-4 px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white text-sm"
        >
          {t('drive.media.refresh') || 'Refresh'}
        </button>
      </div>
    );
  }
  
  // Main content view
  return (
    <div 
      className={cn("p-4 space-y-8 min-h-screen", className)} 
      ref={containerRef}
    >
      {/* Enhanced pull to refresh indicator with visual feedback */}
      <div className="fixed right-4 bottom-4 z-50">
        <motion.button
          onClick={handleRefresh}
          whileTap={{ scale: 0.95 }}
          className={cn(
            "shadow-lg rounded-full p-3 transition-colors",
            "bg-white dark:bg-gray-800",
            "hover:bg-gray-100 dark:hover:bg-gray-700",
            isRefreshing ? "ring-2 ring-blue-500" : ""
          )}
          disabled={isRefreshing} // Prevent multiple rapid refreshes
        >
          <Loader2 
            className={cn(
              "h-5 w-5 text-gray-600 dark:text-gray-400",
              (isLoadingState || isRefreshing) ? "animate-spin" : ""
            )}
          />
          {isRefreshing && (
            <span className="absolute -top-1 -right-1 flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
            </span>
          )}
        </motion.button>
      </div>
      
      {/* Stats and progress indicator */}
      <div className="text-xs text-gray-500 dark:text-gray-400 mb-2 flex items-center justify-between">
        <span>
          {encryptedDrive.isEncryptedMode ? 
            mediaResults.totalCount : 
            (Array.isArray(mediaFiles) ? mediaFiles.length : 0)} {t('drive.media.items') || 'items'}
          {mediaResults.isFetchingNextPage && (
            <Loader2 className="h-3 w-3 animate-spin inline-block ml-1" />
          )}
        </span>
        <span>
          {groupsProcessed === 0 ? (
            <Loader2 className="h-3 w-3 animate-spin inline-block mr-1" />
          ) : null}
          {groupsProcessed} {t('drive.media.groups') || 'groups'}
        </span>
      </div>
      
      {groupedMedia.map((group: DateGroup) => (
        <div 
          key={group.label}
          ref={observerRefs.current[group.label]}
          className="space-y-3"
        >
          <div className="flex items-center mb-2 sticky top-0 z-10 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm py-2">
            <Calendar className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
              {group.label}
            </h3>
            
            {/* Items count badge */}
            <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400">
              {group.files.length || (group.isLoaded ? '0' : '...')}
            </span>
          </div>
          
          {!group.isLoaded ? (
            // Placeholder skeleton grid for unloaded groups
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
              {[...Array(Math.min(6, 20))].map((_, i) => (
                <Skeleton key={i} className="aspect-square rounded-lg bg-gray-200 dark:bg-gray-800" />
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
              <AnimatePresence mode="popLayout">
                {group.files.map((file: ExtendedDriveFile, index: number) => {
                  const isSelected = selectedItems.includes(file.id);
                  const thumbnailInfo = getThumbnailInfo(file);
                  const mediaType = getMediaType(file);
                  const isVideo = mediaType === 'video';
                  
                  return (
                    <FileContextMenu 
                      key={file.id} 
                      item={file}
                      onShare={(id) => logger.debug('Share clicked', {id})}
                      onShowInfo={(id) => logger.debug('Info clicked', {id})}
                    >
                      <motion.div
                        layoutId={`${group.label}-${file.id}`}
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ 
                          duration: 0.15, 
                          delay: index < 20 ? index * 0.03 : 0 
                        }}
                        className={cn(
                          "relative group cursor-pointer",
                          "aspect-square overflow-hidden rounded-lg",
                          "transition-all duration-150",
                          isSelected ? "ring-4 ring-blue-500" : "hover:ring-2 hover:ring-blue-400/50",
                          "bg-gray-100 dark:bg-gray-800"
                        )}
                        onClick={() => handleMediaClick(file, group.files)}
                        onMouseEnter={() => setHoveredId(file.id)}
                        onMouseLeave={() => setHoveredId(null)}
                      >
                        {/* Encrypted mode with ThumbnailViewer */}
                        {encryptedDrive.isEncryptedMode ? (
                          <>
                            {thumbnailInfo ? (
                              <ThumbnailViewer
                                fileId={file.id}
                                driveId={encryptedDrive.encryptedDriveId || ''}
                                mediaType={mediaType ?? undefined}
                                thumbnails={thumbnailInfo}
                                size="medium"
                                // Show animations only for first few items in each group
                                // for better user experience while scrolling
                                showAnimation={index < 12}
                                // Use a carefully controlled staggered delay for animations
                                // Keep delays short for faster items and increase for later items
                                animationDelay={index < 12 ? Math.min(index * 80, 400) : 0}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <div className="w-full h-full flex items-center justify-center">
                                {isVideo ? (
                                  <Film className="h-12 w-12 text-gray-400" />
                                ) : (
                                  <Image className="h-12 w-12 text-gray-400" />
                                )}
                              </div>
                            )}
                            
                            {/* Encryption indicator */}
                            <div className="absolute top-2 right-2">
                              <div className="p-1 bg-blue-500/20 backdrop-blur-sm rounded-full">
                                <Shield className="h-3 w-3 text-blue-500" />
                              </div>
                            </div>
                          </>
                        ) : (
                          // Non-encrypted mode - Show direct thumbnail or placeholder
                          <>
                            {file.thumbnailUrl ? (
                              <img 
                                src={file.thumbnailUrl} 
                                alt={file.name}
                                className="w-full h-full object-cover" 
                                loading="lazy"
                              />
                            ) : (
                              <div className="w-full h-full flex items-center justify-center">
                                {isVideo ? (
                                  <Film className="h-12 w-12 text-gray-400" />
                                ) : (
                                  <Image className="h-12 w-12 text-gray-400" />
                                )}
                              </div>
                            )}
                          </>
                        )}
                        
                        {/* Selection checkbox */}
                        <div className={cn(
                          "absolute top-2 left-2 z-10 transition-opacity",
                          !isSelected && "opacity-0 group-hover:opacity-100"
                        )}>
                          <motion.button
                            onClick={(e) => handleSelect(file.id, e)}
                            whileTap={{ scale: 0.9 }}
                            className={cn(
                              "h-6 w-6 rounded-full",
                              "flex items-center justify-center",
                              "transition-colors duration-200",
                              isSelected
                                ? "bg-blue-500 text-white"
                                : "bg-black/30 backdrop-blur-sm text-white hover:bg-black/40"
                            )}
                          >
                            {isSelected ? (
                              <motion.svg
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                className="h-3 w-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={3}
                                  d="M5 13l4 4L19 7"
                                />
                              </motion.svg>
                            ) : (
                              <span className="h-2.5 w-2.5 rounded-full border-2 border-white" />
                            )}
                          </motion.button>
                        </div>
                        
                        {/* Video indicator */}
                        {isVideo && (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className={cn(
                              "p-2 rounded-full",
                              "bg-black/40 backdrop-blur-sm",
                              "transition-transform duration-200",
                              hoveredId === file.id ? "scale-110" : "scale-100"
                            )}>
                              <Play fill="white" className="h-6 w-6 text-white" />
                            </div>
                          </div>
                        )}
                        
                        {/* File info overlay */}
                        <div className={cn(
                          "absolute inset-x-0 bottom-0",
                          "p-3 pt-6",
                          "bg-gradient-to-t from-black/60 to-transparent",
                          "transition-opacity duration-200",
                          hoveredId === file.id ? "opacity-100" : "opacity-0"
                        )}>
                          {encryptedDrive.isEncryptedMode ? (
                            <DecryptionAnimation
                              decryptedText={file.name}
                              className="text-sm font-medium text-white truncate"
                              duration={800}
                              showEncryptedState={false}
                            />
                          ) : (
                            <p className="text-sm font-medium text-white truncate">
                              {file.name}
                            </p>
                          )}
                        </div>
                      </motion.div>
                    </FileContextMenu>
                  );
                })}
              </AnimatePresence>
            </div>
          )}
        </div>
      ))}
      
      {/* Loading more indicator */}
      {isLoadingState && (
        <div className="flex flex-col items-center justify-center py-4">
          <div className="flex items-center mb-2">
            <Loader2 className="h-6 w-6 animate-spin text-gray-400 dark:text-gray-600 mr-2" />
            <span className="text-sm text-gray-600 dark:text-gray-400">
              {isDecrypting 
                ? (t('drive.media.decrypting') || 'Decrypting files...') 
                : (t('drive.media.loading') || 'Loading media...')}
            </span>
          </div>
          
          {/* Show progress bar for decryption */}
          {isDecrypting && (
            <div className="w-full max-w-xs">
              <div className="w-full h-1.5 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-blue-500 rounded-full transition-all duration-300 ease-out"
                  style={{ width: `${Math.max(5, decryptionProgress)}%` }}
                />
              </div>
              <div className="text-xs text-center mt-1 text-gray-500">
                {Math.round(decryptionProgress)}%
              </div>
            </div>
          )}
        </div>
      )}
      
      {/* Infinite scroll trigger */}
      {encryptedDrive.isEncryptedMode && mediaResults.hasNextPage && (
        <div 
          ref={loadMoreTriggerRef}
          className="h-20 flex items-center justify-center"
          data-testid="load-more-trigger"
        >
          {mediaResults.isFetchingNextPage ? (
            <div className="flex items-center justify-center text-sm text-gray-400">
              <span className="loader-dot mr-2 w-2 h-2 bg-blue-500 rounded-full animate-pulse"></span>
              <span className="loader-dot mr-2 w-2 h-2 bg-blue-500 rounded-full animate-pulse" style={{ animationDelay: '0.2s' }}></span>
              <span className="loader-dot mr-2 w-2 h-2 bg-blue-500 rounded-full animate-pulse" style={{ animationDelay: '0.4s' }}></span>
            </div>
          ) : null}
        </div>
      )}
      
      {/* Empty bottom area for scrolling to ensure everything can be seen */}
      <div className="h-20"></div>
    </div>
  );
};

export default MediaGallery;