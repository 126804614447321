/**
 * @file src/components/cloud-drive/dialogs/CopyDialog.tsx
 * @description Dialog for copying files and folders with enhanced navigation and keyboard support
 * @version 1.0.0
 * @created 2025-03-21
 * @dependencies framer-motion, lucide-react, @/components/ui
 */

import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { FolderOpen, ChevronRight, Search, Loader2, ArrowLeft, Info, Copy } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/utils/utils";
import { useCloudDrive } from "@/context/cloud-drive";
import { useNavigation } from "@/context/NavigationContext";
import { DriveFolder } from "@/types/cloud-drive.types";
import { logger } from "@/utils/logger";

interface CopyDialogProps {
  selectedItems: string[];
  isOpen: boolean;
  onClose: () => void;
  onCopy: (targetFolderId: string) => Promise<void>;
}

export const CopyDialog: React.FC<CopyDialogProps> = ({
  selectedItems,
  isOpen,
  onClose,
  onCopy,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  // Use the navigation context
  const { 
    currentFolderId,
    breadcrumbs,
    navigateToFolder
  } = useNavigation();

  const { getFolderContents, encryptedDrive } = useCloudDrive();
  const folderContentsQuery = getFolderContents(currentFolderId);

  // Get all folders from the current directory
  // For copy, we can also include folders that are in the selected items
  const folders = (folderContentsQuery?.data?.pages?.[0]?.items.filter((item: any) => 
    item.type === 'folder'
  ) ?? []) as unknown as DriveFolder[];

  // Keyboard event handler - add Escape and Enter support
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen) return;
      
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'Enter' && selectedFolderId) {
        handleCopy();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, selectedFolderId]);

  // Reset selected folder when navigation changes
  useEffect(() => {
    setSelectedFolderId(null);
  }, [currentFolderId]);

  // Auto-select a folder when it's the only search result
  useEffect(() => {
    const filteredFolders = folders.filter((folder: DriveFolder) => 
      folder.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
    if (filteredFolders.length === 1 && searchQuery.trim().length > 2) {
      setSelectedFolderId(filteredFolders[0].id);
    }
  }, [searchQuery, folders]);

  const handleCopy = async () => {
    if (!currentFolderId) return;
    setError(null);
    setIsLoading(true);

    try {
      logger.debug('Starting copy operation', {
        component: 'CopyDialog',
        data: {
          targetFolderId: currentFolderId,
          itemCount: selectedItems.length,
          isEncrypted: encryptedDrive.isEncryptedMode
        }
      });
      
      await onCopy(currentFolderId);
      onClose();
    } catch (err) {
      logger.error('Copy operation failed', {
        component: 'CopyDialog',
        error: err,
        data: {
          targetFolderId: currentFolderId,
          itemCount: selectedItems.length
        }
      });
      
      setError(
        err instanceof Error ? err.message : t("cloud_drive.dialogs.copy.error", "Failed to copy items")
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleFolderClick = useCallback(
    (folderId: string | null) => {
      navigateToFolder(folderId);
      setSearchQuery(""); // Clear search when navigating
    },
    [navigateToFolder]
  );

  const handleSelectFolder = useCallback((folderId: string) => {
    setSelectedFolderId(folderId === selectedFolderId ? null : folderId);
  }, [selectedFolderId]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-lg md:max-w-xl w-[95%] max-h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle className="text-xl flex items-center">
            <Copy className="h-5 w-5 mr-2 text-blue-500" />
            {t("cloud_drive.dialogs.copy.title", {
              count: selectedItems.length,
              defaultValue: `Copy ${selectedItems.length} item${selectedItems.length > 1 ? 's' : ''}`
            })}
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-4 flex-1 overflow-hidden">
          {/* Search */}
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
            <Input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t("cloud_drive.dialogs.copy.search", "Search folders")}
              className="pl-9 pr-3 h-10"
            />
          </div>

          {/* Breadcrumb Navigation */}
          <div className="flex items-center flex-wrap gap-1 text-sm rounded-md bg-gray-50 dark:bg-gray-900 p-2">
            {breadcrumbs.map((crumb: any, index: number) => (
              <React.Fragment key={crumb.id || index}>
                {index > 0 && (
                  <ChevronRight className="h-4 w-4 text-gray-400 flex-shrink-0" />
                )}
                <button
                  onClick={() => handleFolderClick(crumb.id)}
                  className={cn(
                    "hover:text-blue-500 truncate max-w-[150px] transition-colors px-1 rounded",
                    currentFolderId === crumb.id && "text-blue-500 font-medium bg-blue-50 dark:bg-blue-900/20"
                  )}
                >
                  {crumb.name}
                </button>
              </React.Fragment>
            ))}
          </div>

          {/* Folder List with Improved Layout */}
          <div className="rounded-md border bg-card dark:bg-gray-900/50">
            <ScrollArea className="h-[300px] md:h-[350px] p-1">
              {folderContentsQuery.isLoading ? (
                <div className="flex items-center justify-center h-full">
                  <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
                </div>
              ) : (
                <AnimatePresence mode="popLayout">
                  {folders.length === 0 && !folderContentsQuery.isLoading && (
                    <div className="p-8 text-center text-gray-500 flex flex-col items-center justify-center h-[200px]">
                      <FolderOpen className="h-12 w-12 text-gray-300 mb-4" />
                      {searchQuery
                        ? t("cloud_drive.dialogs.copy.no_results", "No matching folders found")
                        : t("cloud_drive.dialogs.copy.no_folders", "No folders found in this location")}
                    </div>
                  )}
                  
                  {folders
                    .filter((folder: DriveFolder) => 
                      folder.name.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((folder: DriveFolder) => (
                      <motion.div
                        key={folder.id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="px-1"
                      >
                        <div 
                          className={cn(
                            "w-full p-3 rounded-md",
                            "flex items-center",
                            "transition-colors duration-200",
                            "cursor-pointer",
                            selectedFolderId === folder.id 
                              ? "bg-blue-50 dark:bg-blue-900/20 border-blue-200 dark:border-blue-800"
                              : "hover:bg-gray-50 dark:hover:bg-gray-800/50",
                            "border border-transparent",
                            "group"
                          )}
                          onClick={() => handleSelectFolder(folder.id)}
                          onDoubleClick={() => handleFolderClick(folder.id)}
                        >
                          <FolderOpen className={cn(
                            "h-5 w-5 mr-3 flex-shrink-0",
                            selectedFolderId === folder.id
                              ? "text-blue-500"
                              : "text-blue-400 group-hover:text-blue-500"
                          )} />
                          <span className="truncate flex-1">{folder.name}</span>
                          <ChevronRight className={cn(
                            "h-4 w-4 text-gray-400 flex-shrink-0",
                            "transition-transform duration-200",
                            "group-hover:translate-x-0.5"
                          )} />
                        </div>
                      </motion.div>
                    ))}
                </AnimatePresence>
              )}
            </ScrollArea>
          </div>

          {error && (
            <div className="flex items-center text-sm text-red-500 bg-red-50 dark:bg-red-900/20 p-2 rounded">
              <Info className="h-4 w-4 mr-2 flex-shrink-0" />
              {error}
            </div>
          )}
        </div>

        <DialogFooter className="flex flex-row justify-between sm:justify-between gap-2 mt-4">
          <DialogClose asChild>
            <Button variant="outline" disabled={isLoading} className="flex-1 sm:flex-initial">
              {t("common.cancel", "Cancel")}
            </Button>
          </DialogClose>
          <Button
            onClick={handleCopy}
            disabled={
              !currentFolderId ||
              isLoading
            }
            className={cn(
              "flex-1 sm:flex-initial",
              "bg-blue-500 text-white hover:bg-blue-600",
              "relative"
            )}
          >
            {isLoading && (
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            )}
            {isLoading
              ? t("common.loading", "Loading...")
              : t("cloud_drive.dialogs.copy.button", {
                  count: selectedItems.length,
                  defaultValue: `Copy ${selectedItems.length} item${selectedItems.length > 1 ? 's' : ''}`
                })}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CopyDialog;