/**
 * @file src/components/encrypted-drive/media/ThumbnailViewer.tsx
 * @description Enhanced thumbnail viewer with optimized decryption animations and improved caching
 * @version 2.2.0
 * @created 2025-03-01
 * @updated 2025-03-18
 */

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, Image, FileVideo, AlertCircle, Loader2, RefreshCw } from 'lucide-react';
import { useCloudDrive } from '@/context/cloud-drive';

import { encryptionService } from '@/services/encrypted-drive/encryption-singleton';
import { mediaCacheService } from '@/services/encrypted-drive/media/media-cache.service';
import { logger } from '@/utils/logger';
import { cn } from '@/utils/utils';
import { AnimatedThumbnail } from './AnimatedThumbnail';
import { DecryptionAnimation } from '../animations/DecryptionAnimation';
import { authService } from '@/services/auth.service';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

interface ThumbnailViewerProps {
  fileId: string;
  driveId: string;
  mediaType?: string;
  thumbnails?: Record<string, {
    iv: string;
    path_suffix: string;
    presigned_url?: string;
    storage_path?: string;
    dimensions?: {
      width?: number;
      height?: number;
    }
  }> | string[];
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onLoad?: () => void;
  onError?: (error: Error) => void;
  animationDelay?: number;
  showAnimation?: boolean; // New prop to explicitly control animation display
}

export const ThumbnailViewer: React.FC<ThumbnailViewerProps> = ({
  fileId,
  driveId,
  mediaType,
  thumbnails,
  size = 'small',
  className,
  onLoad,
  onError,
  animationDelay = 0,
  showAnimation = true // Default to true for backward compatibility
}) => {
  const { t } = useTranslation();
  const { encryptedDrive, updateDecryptionStatus } = useCloudDrive();
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState<Error | null>(null);
  
  // Track if the thumbnail is from cache for animation optimization
  const [isFromCache, setIsFromCache] = useState(false);
  
  // Check if this file has already been animated previously
  const initialHasBeenAnimated = useMemo(() => 
    mediaCacheService.hasFileBeenAnimated(driveId, fileId),
    [driveId, fileId]
  );
  
  // Only show decryption animation for:
  // - non-cached items 
  // - when animation delay is set
  // - files that haven't been animated before
  // - when showAnimation prop is true (allows parent to disable all animations)
  const [showDecryption, setShowDecryption] = useState(
    showAnimation && animationDelay > 0 && !isFromCache && !initialHasBeenAnimated
  );
  
  // Track animation limit: 
  // Generate a random number for this instance to determine if it should show animation
  // This helps further limit animations across different instances
  const animationLimitIndex = useRef(Math.floor(Math.random() * 50));
  
  const [fetchAttempts, setFetchAttempts] = useState(0);
  const MAX_FETCH_ATTEMPTS = 3;
  const attemptedLoad = useRef(false);
  
  // Use ref to track if the component is mounted
  const isMounted = useRef(true);
  // Add unique component instance ID for debug
  const instanceId = useRef(`tv-${Math.random().toString(36).substring(2, 9)}`);

  // Get auth headers for API calls
  const getAuthHeaders = () => {
    try {
      const tokens = authService.getStoredToken();
      if (!tokens || !tokens.accessToken || !authService.isValidToken(tokens.accessToken)) {
        return {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        };
      }
  
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': tokens.accessToken
      };
    } catch (error) {
      logger.error('Error getting auth headers:', error);
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
    }
  };
  
  // Component lifecycle handling with animation optimization
  useEffect(() => {
    // Improve performance by skipping debug logs for most instances
    if (process.env.NODE_ENV === 'development' && animationLimitIndex.current < 3) {
      logger.debug('ThumbnailViewer mounted', {
        component: 'ThumbnailViewer',
        instanceId: instanceId.current,
        data: {
          fileId,
          driveId,
          mediaType,
          size,
          hasThumbnails: !!thumbnails,
          hasBeenAnimated: mediaCacheService.hasFileBeenAnimated(driveId, fileId),
          animationDelay,
          showAnimation,
          animationLimitIndex: animationLimitIndex.current
        }
      });
    }
    
    isMounted.current = true;
    
    // Immediately mark the file as animated to prevent infinite loops,
    // regardless of whether we're showing animations or not
    mediaCacheService.markFileAnimated(driveId, fileId);
    
    // Additional animation limiting - only let certain thumbnails show animations
    // This helps prevent too many simultaneous animations
    const shouldAllowAnimation = showAnimation && 
                                 animationDelay > 0 && 
                                 animationLimitIndex.current < 20;
    
    // Immediately check if we already have this file in cache to skip animation
    const hasUrlInCache = !!mediaCacheService.getUrl(driveId, fileId, size);
    if (hasUrlInCache || !shouldAllowAnimation) {
      setIsFromCache(true);
      setShowDecryption(false);
      // No need to run animations for cached content or when animations are limited
      mediaCacheService.markFileAnimated(driveId, fileId);
    }
    
    return () => {
      // Dramatically reduce unmount logging to improve performance
      if (process.env.NODE_ENV === 'development' && animationLimitIndex.current < 2) {
        logger.debug('ThumbnailViewer unmounting', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: { fileId, driveId }
        });
      }
      isMounted.current = false;
    };
  }, [fileId, driveId, mediaType, thumbnails, size, animationDelay, showAnimation]);
  
  // Check if this file has already been animated
  const hasBeenAnimated = useMemo(() => {
    return mediaCacheService.hasFileBeenAnimated(driveId, fileId);
  }, [driveId, fileId]);
  
  // Animation handling with cache awareness and optimization to prevent double animations
  useEffect(() => {
    // Fix animation configuration - be more permissive with animations for first visible items
    const shouldAllowAnimation = showAnimation && 
                               animationDelay >= 0 && // Allow zero delay animations too
                               animationLimitIndex.current < 25; // Allow slightly more animations
    
    // If already animated or cached, skip animation
    if (hasBeenAnimated || isFromCache || !shouldAllowAnimation) {
      // Immediately hide any active animations
      setShowDecryption(false);
      
      // Mark as animated to prevent future animations
      if (!hasBeenAnimated) {
        mediaCacheService.markFileAnimated(driveId, fileId);
      }
      return;
    }
    
    // For thumbnails that should animate, show the animation
    if (shouldAllowAnimation && !isFromCache) {
      // Don't initially hide the animation for items that qualify
      // This reverses the previous approach - now we explicitly SHOW the animation
      setShowDecryption(true);
      
      // Only log a small percentage of animations to avoid spam
      if (process.env.NODE_ENV === 'development' && animationLimitIndex.current < 3) {
        logger.debug('Starting thumbnail decryption animation', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: { 
            fileId,
            index: animationLimitIndex.current,
            delay: animationDelay
          }
        });
      }
      
      // Set a completion timer that's proportional to the delay but with a minimum time
      // The later the item appears, the shorter its animation to avoid holding up the UI
      const completionTime = Math.min(700, Math.max(500, animationDelay + 500));
      
      const timer = setTimeout(() => {
        if (isMounted.current) {
          // End the animation
          setShowDecryption(false);
          
          // Mark as animated to prevent future animations
          mediaCacheService.markFileAnimated(driveId, fileId);
          
          // Very limited logging for animation completion
          if (process.env.NODE_ENV === 'development' && animationLimitIndex.current < 2) {
            logger.debug('Animation completed', {
              component: 'ThumbnailViewer',
              data: { fileId }
            });
          }
        }
      }, completionTime);
      
      return () => clearTimeout(timer);
    } else {
      // For non-animated items, immediately hide any decryption animation
      setShowDecryption(false);
      
      // Mark as animated to prevent future attempts
      if (!hasBeenAnimated) {
        mediaCacheService.markFileAnimated(driveId, fileId);
      }
    }
  }, [animationDelay, isFromCache, hasBeenAnimated, driveId, fileId, showAnimation]);
  
  // Safety mechanism to ensure animations complete - separate effect to prevent stuck animations
  useEffect(() => {
    // If there's an active decryption animation, ensure it completes within a maximum time
    if (showDecryption) {
      const safetyTimer = setTimeout(() => {
        setShowDecryption(false);
        mediaCacheService.markFileAnimated(driveId, fileId);
      }, 2000); // Maximum animation time as a fallback
      
      return () => clearTimeout(safetyTimer);
    }
  }, [showDecryption, driveId, fileId]);
  
  // Optimize mount logging to dramatically reduce debug output
  // This helps prevent excessive work during rendering
  const hasLoggedMount = useRef(false);
  const hasCacheChecked = useRef(false);
  
  useEffect(() => {
    // Only log mounting once per instance and for approximately 5% of thumbnails
    // This massive reduction in logging helps prevent render loops
    const shouldLog = !hasLoggedMount.current && (Math.random() < 0.05);
    
    if (shouldLog) {
      hasLoggedMount.current = true;
      logger.debug('ThumbnailViewer mounted', {
        component: 'ThumbnailViewer',
        instanceId: instanceId.current,
        data: {
          fileId,
          driveId,
          size,
          hasExistingUrl: !!thumbnailUrl,
          cacheStatus: {
            initialUrl: mediaCacheService.getUrl(driveId, fileId, size) ? 'available' : 'missing',
            initialDecryptedData: mediaCacheService.getDecryptedData(driveId, fileId, size) ? 'available' : 'missing'
          }
        }
      });
    }
    
    // Only check cache on first render to prevent re-render loops
    if (!hasCacheChecked.current && !thumbnailUrl && !isLoading && !error) {
      hasCacheChecked.current = true;
      const cachedUrl = mediaCacheService.getUrl(driveId, fileId, size);
      
      if (cachedUrl) {
        // Only log for a small percentage of items
        if (shouldLog) {
          logger.debug('Using cached URL', {
            component: 'ThumbnailViewer',
            instanceId: instanceId.current,
            data: { fileId, size }
          });
        }
        
        // Set the thumbnail URL and indicate it's from cache
        setThumbnailUrl(cachedUrl);
        setIsFromCache(true);
        setShowDecryption(false); // No animation for cached content
        
        // Always mark as animated to prevent future animations
        mediaCacheService.markFileAnimated(driveId, fileId);
      }
    }
    
    // No dependencies to prevent re-execution of this effect
    // This is intentional to avoid re-render loops
  }, []); // Empty dependency array to run only once on mount

  // Update global decryption status with much more aggressive throttling
  // to prevent render loops
  const lastUpdateTime = useRef<number>(0);
  const updateGlobalDecryptionStatus = useCallback((isDecrypting: boolean, progress: number) => {
    if (!updateDecryptionStatus || !isMounted.current) return;
    
    // Get current time
    const now = Date.now();
    
    // Only allow updates every 500ms at most to prevent re-render loops
    // Also add stronger throttling when using cached content
    const minUpdateInterval = isFromCache ? 1000 : 500;
    
    // Exit early if we've updated too recently - this is critical to prevent loops
    if (now - lastUpdateTime.current < minUpdateInterval) {
      return;
    }
    
    // Add much more aggressive throttling - only update on major changes
    if (
      Math.abs(loadingProgress - progress) >= 30 || // Only on large progress changes
      (isDecrypting === false && progress === 100) || // Completion event
      (isDecrypting === true && progress === 0) // Start event
    ) {
      lastUpdateTime.current = now;
      
      // For cached content, never set isDecrypting to true to avoid loops
      if (isFromCache && isDecrypting) {
        return;
      }
      
      updateDecryptionStatus({
        isDecrypting,
        progress,
        completedItems: progress === 100 ? 1 : 0,
        totalItems: 1
      });
    }
  }, [updateDecryptionStatus, loadingProgress, isFromCache]);
  
  // Get best size match
  const getBestSizeMatch = useCallback((preferredSize: string, availableThumbnails: Record<string, any> | string[]) => {
    // Handle array of thumbnail URLs (from shared drive items)
    if (Array.isArray(availableThumbnails)) {
      logger.debug('Handling array of thumbnail URLs', {
        component: 'ThumbnailViewer',
        instanceId: instanceId.current,
        data: {
          thumbnailCount: availableThumbnails.length
        }
      });
      
      // If we have any thumbnails, just return 'default' as the key
      return availableThumbnails.length > 0 ? 'default' : null;
    }
    
    // Handle record of thumbnails by size
    if (availableThumbnails[preferredSize]) {
      return preferredSize;
    }
    
    const sizePreferences = {
      'small': ['small', 'medium', 'large'],
      'medium': ['medium', 'small', 'large'],
      'large': ['large', 'medium', 'small']
    } as const;
    
    const preferences = sizePreferences[preferredSize as keyof typeof sizePreferences] || ['small', 'medium', 'large'];
    
    for (const size of preferences) {
      if (availableThumbnails[size]) {
        return size;
      }
    }
    
    const availableSizes = Object.keys(availableThumbnails);
    return availableSizes.length > 0 ? availableSizes[0] : null;
  }, []);
  
  // Fetch through proxy (backend-based approach to avoid CORS issues)
  const fetchThroughProxy = useCallback(async (
    url: string, 
    fileId: string, 
    driveId: string
  ): Promise<ArrayBuffer> => {
    try {
      const isDirectPath = !url.startsWith('http');
      
      logger.debug('Fetching through proxy', {
        component: 'ThumbnailViewer',
        instanceId: instanceId.current,
        data: { 
          fileId, 
          driveId,
          urlType: isDirectPath ? 'storage_path' : 'presigned_url',
          urlValue: isDirectPath ? url : url.substring(0, 30) + '...'
        }
      });
      
      // Using the media proxy endpoint from our backend
      const proxyResponse = await fetch('/api/v1/media/content', {
        method: 'POST',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json'
        } as HeadersInit,
        body: JSON.stringify({
          url,
          file_id: fileId,
          drive_id: driveId,
          is_thumbnail: true
        }),
        credentials: 'include'
      });
      
      if (!proxyResponse.ok) {
        let errorDetail = '';
        try {
          const errorResponse = await proxyResponse.json();
          errorDetail = errorResponse.detail || '';
        } catch (e) {
          // Ignore JSON parsing errors
        }
        
        logger.error('Proxy request failed', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            status: proxyResponse.status,
            statusText: proxyResponse.statusText,
            detail: errorDetail
          }
        });
        throw new Error(`Proxy request failed: ${proxyResponse.status} ${proxyResponse.statusText}${errorDetail ? ` - ${errorDetail}` : ''}`);
      }
      
      const arrayBuffer = await proxyResponse.arrayBuffer();
      
      logger.debug('Proxy fetch successful', {
        component: 'ThumbnailViewer',
        instanceId: instanceId.current,
        data: {
          fileId,
          responseSize: arrayBuffer.byteLength
        }
      });
      
      return arrayBuffer;
    } catch (error) {
      logger.error('Proxy fetch error', {
        component: 'ThumbnailViewer',
        instanceId: instanceId.current,
        error,
        data: { fileId, driveId }
      });
      throw error;
    }
  }, [getAuthHeaders]);
  
  // Handle manually initiated retry
  const handleRetry = useCallback(() => {
    setError(null);
    setFetchAttempts(0);
    attemptedLoad.current = false;
  }, []);
  
  // Main thumbnail loading effect
  useEffect(() => {
    // Reset attempted load flag on dependency changes
    attemptedLoad.current = false;
    
    // Skip if we don't have required data
    if (!thumbnails || !driveId || !fileId) {
      return;
    }
    
    // Skip if we already have a URL or are loading
    if (thumbnailUrl || isLoading) {
      return;
    }
    
    // Skip if we've exceeded max attempts
    if (fetchAttempts >= MAX_FETCH_ATTEMPTS) {
      return;
    }
    
    // Skip if we've already tried to load this thumbnail in this render cycle
    if (attemptedLoad.current) {
      return;
    }
    
    attemptedLoad.current = true;
    
    const loadThumbnail = async () => {
      if (!isMounted.current) return;
      
      try {
        setIsLoading(true);
        setLoadingProgress(10);
        updateGlobalDecryptionStatus(true, 10);
        
        // Handle different thumbnail formats
        let bestSize: string | null = null;
        let thumbInfo: any = null;
        let directThumbnailUrl: string | null = null;
        
        // Check if thumbnails is an array (from shared items) or a record (from encrypted drive)
        if (Array.isArray(thumbnails)) {
          logger.debug('Processing array of thumbnail URLs', {
            component: 'ThumbnailViewer',
            instanceId: instanceId.current,
            data: {
              fileId,
              driveId,
              thumbnailCount: thumbnails.length
            }
          });
          
          // For arrays, use the first thumbnail URL directly
          if (thumbnails.length > 0) {
            bestSize = 'default';
            directThumbnailUrl = thumbnails[0];
            
            // If we have a direct URL from shared thumbnails, use it immediately
            if (directThumbnailUrl) {
              logger.debug('Using direct thumbnail URL from shared item', {
                component: 'ThumbnailViewer',
                instanceId: instanceId.current,
                data: {
                  fileId,
                  urlStart: directThumbnailUrl.substring(0, 30) + '...'
                }
              });
              
              // Create the image URL directly
              setThumbnailUrl(directThumbnailUrl);
              setIsFromCache(true); // Treat as cached to skip animation
              setShowDecryption(false);
              setLoadingProgress(100);
              updateGlobalDecryptionStatus(false, 100);
              onLoad?.();
              return; // Exit early with direct URL
            }
          } else {
            logger.debug('Empty thumbnails array', {
              component: 'ThumbnailViewer',
              instanceId: instanceId.current,
              data: { fileId, driveId }
            });
            throw new Error('No thumbnails available in the array');
          }
        } else {
          // Get the best size match for record-style thumbnails
          bestSize = getBestSizeMatch(size, thumbnails);
          if (!bestSize) {
            throw new Error('No suitable thumbnail size found');
          }
          
          // Log cache state at beginning with component ID
          logger.debug('Cache check before processing', {
            component: 'ThumbnailViewer',
            instanceId: instanceId.current,
            data: {
              fileId,
              driveId,
              size: bestSize,
              hasCachedUrl: !!mediaCacheService.getUrl(driveId, fileId, bestSize),
              hasCachedData: !!mediaCacheService.getDecryptedData(driveId, fileId, bestSize)
            }
          });
          
          // Always get a fresh URL from cache - this ensures we handle revoked URLs
          const cachedUrl = mediaCacheService.getUrl(driveId, fileId, bestSize);
          if (cachedUrl) {
            logger.debug('Using cached thumbnail URL', {
              component: 'ThumbnailViewer',
              instanceId: instanceId.current,
              data: { fileId, size: bestSize, cachedUrl }
            });
            
            // Set the URL and mark it as from cache to skip animation
            setThumbnailUrl(cachedUrl);
            setIsFromCache(true);
            setShowDecryption(false); // Explicitly disable animation for cached content
            setLoadingProgress(100);
            updateGlobalDecryptionStatus(false, 100);
            onLoad?.();
            return; // Exit early with cached URL
          }
          
          // Get thumbnail info for regular encrypted drive thumbnails
          thumbInfo = thumbnails[bestSize];
        }
        
        // More lenient check for thumbnails + fallback for favorites view
        if (!thumbInfo?.storage_path && !thumbInfo?.presigned_url) {
          logger.debug('No storage path or presigned URL - creating placeholder', {
            component: 'ThumbnailViewer',
            instanceId: instanceId.current,
            data: { 
              fileId, 
              driveId,
              size: bestSize,
              mediaType
            }
          });
            
          // Create a placeholder for the item instead of throwing an error
          const iconSize = 200;
          const iconColor = mediaType === 'video' ? '#3b82f6' : '#10b981';
          
          // Create a simple SVG as placeholder
          const svgContent = `
            <svg width="${iconSize}" height="${iconSize}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${iconSize} ${iconSize}">
              <rect width="100%" height="100%" fill="#f3f4f6" />
              <circle cx="${iconSize/2}" cy="${iconSize/2}" r="${iconSize/4}" fill="${iconColor}" />
              <text x="50%" y="50%" font-family="Arial" font-size="14" fill="white" text-anchor="middle" dominant-baseline="middle">
                ${mediaType === 'video' ? 'VIDEO' : 'IMAGE'}
              </text>
            </svg>
          `;
          
          const svgBlob = new Blob([svgContent], { type: 'image/svg+xml' });
          const placeholderUrl = URL.createObjectURL(svgBlob);
          
          // Set the URL and skip full loading procedure
          setThumbnailUrl(placeholderUrl);
          setIsFromCache(true);
          setShowDecryption(false);
          setLoadingProgress(100);
          updateGlobalDecryptionStatus(false, 100);
          onLoad?.();
          return;
        }

        logger.debug('Processing thumbnail', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            fileId,
            size: bestSize,
            hasStoragePath: !!thumbInfo.storage_path,
            hasPresignedUrl: !!thumbInfo.presigned_url,
          }
        });

        const urlToFetch = thumbInfo.storage_path || thumbInfo.presigned_url || '';
        
        if (!urlToFetch) {
          throw new Error('No URL available for thumbnail');
        }
        
        // STEP 1: Fetch through proxy
        const encryptedData = await fetchThroughProxy(
          urlToFetch,
          fileId,
          driveId
        );
        
        setLoadingProgress(60);
        updateGlobalDecryptionStatus(true, 60);
        
        // Cache the encrypted data with its IV
        mediaCacheService.cacheEncryptedData(driveId, fileId, bestSize, encryptedData, thumbInfo.iv);
        
        logger.debug('Encrypted data cached', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            fileId,
            size: bestSize,
            encryptedSize: encryptedData.byteLength
          }
        });
        
        // Get encryption keys and drive info
        const keys = encryptionService.getDecryptedKeys(driveId);
        if (!keys) {
          throw new Error('Drive not unlocked');
        }
        
        // Determine encryption tier from multiple sources in order of precedence
        let encryptionTier = 'standard'; // Default fallback
        
        // 1. Try to get from drive object in context
        if (encryptedDrive?.encryptionTier) {
          encryptionTier = encryptedDrive.encryptionTier;
        } 
        // 2. Try to get from encryption service if it supports tier tracking
        else if (typeof (encryptionService as any).getDriveEncryptionTier === 'function') {
          const storedTier = (encryptionService as any).getDriveEncryptionTier(driveId);
          if (storedTier) {
            encryptionTier = storedTier;
          }
        }
        
        logger.debug('Drive keys retrieved for decryption', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            fileId,
            driveId,
            hasContentKey: !!keys.contentKey,
            hasMetadataKey: !!keys.metadataKey,
            encryptionTier,
            ivLength: thumbInfo.iv?.length,
            actualTierSource: encryptedDrive?.encryptionTier ? 'context' : 
              ((encryptionService as any).getDriveEncryptionTier?.(driveId) ? 'service' : 'default'),
            size: bestSize
          }
        });
        
        setLoadingProgress(70);
        updateGlobalDecryptionStatus(true, 70);
        
        // Log the data we're about to decrypt
        logger.debug('Decrypting thumbnail', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            fileId,
            size: bestSize,
            encryptedSize: encryptedData.byteLength,
            iv: thumbInfo.iv ? thumbInfo.iv.substring(0, 10) + '...' : 'missing',
            hasContentKey: !!keys.contentKey
          }
        });
        
        logger.debug('Starting thumbnail decryption', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            fileId,
            encryptedSize: encryptedData.byteLength,
            iv: thumbInfo.iv ? thumbInfo.iv.substring(0, 10) + '...' : 'missing',
            ivLength: thumbInfo.iv?.length,
            encryptionTier, // Using the previously determined encryption tier
            size: bestSize
          }
        });
        
        // STEP 2: Decrypt the thumbnail
        const decryptedData = await encryptionService.decryptContent(
          encryptedData,
          thumbInfo.iv,
          keys.contentKey,
          true,  // Explicitly indicate it's a thumbnail
          encryptionTier, // Pass the encryption tier if known
          driveId  // Pass the driveId so the service can look up encryption tier
        );
        
        logger.debug('Thumbnail decryption successful', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            fileId,
            decryptedSize: decryptedData.byteLength,
            encryptionTier
          }
        });
        
        setLoadingProgress(90);
        updateGlobalDecryptionStatus(true, 90);
        
        // STEP 3: Cache the decrypted data
        mediaCacheService.cacheDecryptedData(driveId, fileId, bestSize, decryptedData);
        
        logger.debug('Decrypted data cached', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            fileId,
            size: bestSize,
            decryptedSize: decryptedData.byteLength
          }
        });
        
        // STEP 4: Create blob and cache it
        const blob = new Blob([decryptedData], { type: 'image/jpeg' });
        mediaCacheService.cacheBlob(driveId, fileId, bestSize, blob, thumbInfo.iv);
        
        logger.debug('Blob created and cached', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          data: {
            fileId,
            size: bestSize,
            blobSize: blob.size
          }
        });
        
        // STEP 5: Get fresh URL from the cache
        const freshUrl = mediaCacheService.getUrl(driveId, fileId, bestSize);
        
        if (!freshUrl) {
          logger.error('Failed to get URL after caching blob', {
            component: 'ThumbnailViewer',
            instanceId: instanceId.current,
            data: { fileId, size: bestSize }
          });
          throw new Error('Failed to create thumbnail URL');
        }
        
        if (isMounted.current) {
          setThumbnailUrl(freshUrl);
          // This URL is freshly loaded from server, not from cache
          setIsFromCache(false);
          setLoadingProgress(100);
          updateGlobalDecryptionStatus(false, 100);
          
          // Mark this file as having been animated to prevent future animations
          mediaCacheService.markFileAnimated(driveId, fileId);
          
          onLoad?.();
        }
      } catch (error) {
        logger.error('Thumbnail processing error', {
          component: 'ThumbnailViewer',
          instanceId: instanceId.current,
          error,
          data: { 
            fileId, 
            size,
            attempt: fetchAttempts + 1
          }
        });
        
        if (isMounted.current) {
          const err = error instanceof Error ? error : new Error(String(error));
          setError(err);
          setLoadingProgress(0);
          updateGlobalDecryptionStatus(false, 0);
          onError?.(err);
          setFetchAttempts(prev => prev + 1);
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };
    
    loadThumbnail();
    
  }, [
    fileId, 
    driveId, 
    thumbnails, 
    size, 
    getBestSizeMatch,
    thumbnailUrl,
    isLoading,
    fetchAttempts,
    MAX_FETCH_ATTEMPTS,
    fetchThroughProxy,
    onLoad,
    onError,
    updateGlobalDecryptionStatus
  ]);
  
  // Placeholder based on media type and state
  const renderPlaceholder = useCallback(() => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
            className="mb-2"
          >
            <Loader2 className="h-6 w-6 text-gray-400" />
          </motion.div>
          
          {loadingProgress > 0 && (
            <div className="text-xs text-gray-400">
              {loadingProgress < 100 ? `${Math.round(loadingProgress)}%` : 'Ready!'}
            </div>
          )}
        </div>
      );
    }
    
    if (error) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <AlertCircle className="h-6 w-6 text-red-500 mb-1" />
          <div className="text-xs text-gray-500 dark:text-gray-400 mb-2">
            {fetchAttempts >= MAX_FETCH_ATTEMPTS ? t('encrypted_drive.errors.failed_to_load') : t('common.retrying')}
          </div>
          
          {fetchAttempts >= MAX_FETCH_ATTEMPTS && (
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={handleRetry}
              className="h-7 text-xs px-2 py-1 flex items-center gap-1"
            >
              <RefreshCw className="h-3 w-3" />
              {t('common.retry')}
            </Button>
          )}
        </div>
      );
    }
    
    if (mediaType === 'image') {
      return (
        <div className="flex items-center justify-center w-full h-full">
          <Image className="h-6 w-6 text-gray-400" />
        </div>
      );
    }
    
    if (mediaType === 'video') {
      return (
        <div className="flex items-center justify-center w-full h-full">
          <FileVideo className="h-6 w-6 text-gray-400" />
        </div>
      );
    }
    
    return null;
  }, [isLoading, loadingProgress, error, mediaType, fetchAttempts, MAX_FETCH_ATTEMPTS, handleRetry, t]);
  
  // Memoize the thumbnail component to prevent unnecessary re-renders
  const thumbnailComponent = useMemo(() => {
    if (thumbnailUrl) {
      // Log when thumbnail is displayed
      logger.debug('Rendering thumbnail with URL', {
        component: 'ThumbnailViewer',
        instanceId: instanceId.current,
        data: {
          fileId,
          size,
          urlStart: thumbnailUrl.substring(0, 30) + '...',
          isFromCache
        }
      });
      
      return (
        <div className="relative w-full h-full">
          <AnimatePresence>
            {showDecryption && !isFromCache && (
              <motion.div 
                className="absolute inset-0 flex items-center justify-center bg-gray-800/80 z-10"
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <DecryptionAnimation 
                  decryptedText="Decrypting..." 
                  duration={500}
                  steps={6}
                  className="text-blue-400 text-sm"
                />
              </motion.div>
            )}
          </AnimatePresence>
          
          <AnimatedThumbnail 
            imageUrl={thumbnailUrl}
            isLoading={isLoading}
            key={`${fileId}-${instanceId.current}`} // Add a stable key to ensure proper lifecycle
          />
          
          {/* Add video play button overlay */}
          {mediaType === 'video' && (
            <motion.div 
              className="absolute inset-0 flex items-center justify-center bg-black/30 z-5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: isFromCache ? 0 : (animationDelay + 800) / 1000, duration: 0.3 }}
            >
              <motion.div 
                className="bg-white/80 dark:bg-gray-800/80 rounded-full p-1.5 backdrop-blur-sm"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: isFromCache ? 0.1 : (animationDelay + 900) / 1000, duration: 0.2 }}
                whileHover={{ scale: 1.1 }}
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24" 
                  fill="currentColor" 
                  className="w-6 h-6 text-blue-500"
                >
                  <path fillRule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clipRule="evenodd" />
                </svg>
              </motion.div>
            </motion.div>
          )}
          
          <motion.div 
            className="absolute bottom-1 right-1 bg-gray-200/70 dark:bg-gray-800/70 rounded-full p-0.5"
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: isFromCache ? 0.2 : (animationDelay + 800) / 1000, duration: 0.2 }}
          >
            <Shield className="h-3 w-3 text-blue-500" />
          </motion.div>
        </div>
      );
    }
    
    return renderPlaceholder();
  }, [
    fileId, 
    size, 
    thumbnailUrl, 
    isLoading, 
    showDecryption, 
    animationDelay, 
    renderPlaceholder,
    mediaType,
    isFromCache
  ]);

  
  return (
    <div 
      className={cn(
        "relative w-full h-full overflow-hidden rounded-md",
        "bg-gray-100 dark:bg-gray-800",
        className
      )}
    >
      {thumbnailComponent}
    </div>
  );
};

export default ThumbnailViewer;