/**
 * @file src/i18n/config.ts
 * @description Unified i18n configuration with namespace fallback support
 * @version 2.0.0
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { logger } from '@/utils/logger';

// Import main translations
import en from '../locales/en.json';
import ja from '../locales/ja.json';
import ko from '../locales/ko.json';
import it from '../locales/it.json';

// Import Ghost translations (overrides)
import ghostEn from '../locales/ghost/en.json';
import ghostJa from '../locales/ghost/ja.json';
import ghostKo from '../locales/ghost/ko.json';
import ghostIt from '../locales/ghost/it.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        default: en,      // BigMIND translations
        ghost: ghostEn    // Ghost-specific overrides
      },
      ja: {
        default: ja,
        ghost: ghostJa
      },
      ko: {
        default: ko,
        ghost: ghostKo
      },
      it: {
        default: it,
        ghost: ghostIt
      }
    },
    fallbackLng: 'en',
    defaultNS: 'default',  // Default namespace for BigMIND
    fallbackNS: 'default', // Fallback to BigMIND translations

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'bigmind_language',
      caches: ['localStorage']
    },
    
    debug: process.env.NODE_ENV === 'development',
    
    // Log missing translations in development
    saveMissing: process.env.NODE_ENV === 'development',
    missingKeyHandler: (lng, ns, key) => {
      logger.warn('Missing translation:', { data: {lng, ns, key} });
    },
  });

export default i18n;