/**
 * @file src/components/encrypted-drive/dialogs/steps/StepEncryptionType.tsx
 * @description Encryption type selection step
 * @version 1.0.0
 */

import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Shield, Zap, Server } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { cn } from '@/utils/utils';

// Define the missing types
type EncryptionTier = 'standard' | 'advanced' | 'enterprise';

interface CreateDriveFormData {
  name: string;
  encryptionTier: EncryptionTier;
  password: string;
  confirmPassword: string;
  recoveryKey?: string;
}

interface StepEncryptionTypeProps {
  formData: CreateDriveFormData;
  setFormData: (data: CreateDriveFormData) => void;
  onNext: () => void;
  onBack: () => void;
}

const encryptionOptions = [
  {
    id: 'standard' as EncryptionTier,
    icon: Shield,
    title: 'AES-256',
    description: 'Military-grade protection',
    features: ['256-bit encryption', 'Zero-knowledge privacy'],
    available: true
  },
  {
    id: 'advanced' as EncryptionTier,
    icon: Zap,
    title: 'ChaCha20',
    description: 'High-performance encryption',
    features: ['Optimized for speed', 'Perfect for large files'],
    available: false, // Requires subscription
  },
  {
    id: 'enterprise' as EncryptionTier,
    icon: Server,
    title: 'Multi-Layer',
    description: 'Enterprise-grade security',
    features: ['Multiple encryption layers', 'Advanced protection'],
    available: false, // Requires subscription
  }
];

export const StepEncryptionType: React.FC<StepEncryptionTypeProps> = ({
  formData,
  setFormData,
  onNext,
  onBack,
}) => {
  const { t } = useTranslation();

  const handleSelectOption = (tier: EncryptionTier) => {
    if (!encryptionOptions.find((opt) => opt.id === tier)?.available) {
      return;
    }
    setFormData({ ...formData, encryptionTier: tier });
  };

  return (
    <div className="space-y-6">
      <DialogHeader>
        <DialogTitle>
          {t('encrypted_drive.encryption.title')}
        </DialogTitle>
      </DialogHeader>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="space-y-4"
      >
        {/* Encryption Options */}
        <div className="grid gap-4">
          {encryptionOptions.map((option, index) => {
            const Icon = option.icon;
            const isSelected = formData.encryptionTier === option.id;
            
            return (
              <motion.div
                key={option.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ 
                  opacity: 1, 
                  x: 0,
                  transition: { delay: index * 0.1 }
                }}
              >
                <button
                  type="button"
                  onClick={() => handleSelectOption(option.id)}
                  className={cn(
                    "w-full p-4 rounded-lg border transition-all duration-200",
                    "text-left flex items-start space-x-4",
                    isSelected ? (
                      "border-blue-500 bg-blue-50/50 dark:bg-blue-900/20"
                    ) : (
                      "border-gray-200 dark:border-gray-800 hover:border-gray-300 dark:hover:border-gray-700"
                    ),
                    !option.available && "opacity-50 cursor-not-allowed"
                  )}
                  disabled={!option.available}
                >
                  <div className={cn(
                    "rounded-full p-2",
                    isSelected ? (
                      "bg-blue-500 text-white"
                    ) : (
                      "bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400"
                    )
                  )}>
                    <Icon className="h-5 w-5" />
                  </div>
                  
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {option.title}
                      </h4>
                      {!option.available && (
                        <span className="text-xs font-medium text-blue-500 dark:text-blue-400">
                          {t('encrypted_drive.encryption.upgrade_required')}
                        </span>
                      )}
                    </div>
                    
                    <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                      {option.description}
                    </p>
                    
                    <ul className="mt-2 space-y-1">
                      {option.features.map((feature, i) => (
                        <li 
                          key={i}
                          className="text-xs text-gray-500 dark:text-gray-400 flex items-center"
                        >
                          <span className="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700 mr-2" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </button>
              </motion.div>
            );
          })}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between pt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            className="min-w-[100px]"
          >
            {t('common.back')}
          </Button>
          
          <Button
            onClick={onNext}
            className="min-w-[100px]"
          >
            {t('common.continue')}
          </Button>
        </div>
      </motion.div>
    </div>
  );
};