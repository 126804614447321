/**
 * @file src/components/reports/ReportCard.tsx
 * @description Report card component with icons and animations
 * @version 1.2.0
 * @updated 2024-01-29
 */

import React from 'react';
import { motion } from 'framer-motion';
import { 
  BarChart2, 
  CreditCard, 
  AlertTriangle,
  Globe,
  Activity,
  Download,
  History,
  Monitor,
  FileX,
  Cpu
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import type { Report } from '@/types/reports.types';

interface ReportCardProps {
  report: Report;
  onView: (report: Report) => void;
  className?: string;
}

// Map report types to icons
const reportIcons: Record<string, React.ReactNode> = {
  smart: <BarChart2 className="h-8 w-8" />,
  billing: <CreditCard className="h-8 w-8" />,
  alerts: <AlertTriangle className="h-8 w-8" />,
  client: <Globe className="h-8 w-8" />,
  bandwidth: <Activity className="h-8 w-8" />,
  deployment: <Download className="h-8 w-8" />,
  activity: <History className="h-8 w-8" />,
  devices: <Monitor className="h-8 w-8" />,
  deviceBuilds: <Cpu className="h-8 w-8" />,
  failed: <FileX className="h-8 w-8" />,
};

// Map report types to colors
const reportColors: Record<string, { bg: string; text: string }> = {
  smart: { bg: "bg-emerald-100", text: "text-emerald-600" },
  billing: { bg: "bg-purple-100", text: "text-purple-600" },
  alerts: { bg: "bg-red-100", text: "text-red-600" },
  client: { bg: "bg-cyan-100", text: "text-cyan-600" },
  bandwidth: { bg: "bg-blue-100", text: "text-blue-600" },
  deployment: { bg: "bg-yellow-100", text: "text-yellow-600" },
  activity: { bg: "bg-green-100", text: "text-green-600" },
  devices: { bg: "bg-orange-100", text: "text-orange-600" },
  deviceBuilds: { bg: "bg-indigo-100", text: "text-indigo-600" },
  failed: { bg: "bg-red-100", text: "text-red-600" },
};

export const ReportCard: React.FC<ReportCardProps> = ({
  report,
  onView,
  className
}) => {
  const { t } = useTranslation();
  const colors = reportColors[report.type] || { bg: "bg-gray-100", text: "text-gray-600" };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Card className={cn(
        "overflow-hidden transition-all duration-200",
        "hover:shadow-lg",
        "border border-gray-100",
        "bg-white dark:bg-gray-900",
        "min-h-[200px]",
        "flex flex-col",
        report.disabled && "opacity-60 cursor-not-allowed",
        className
      )}>
        <CardHeader className="p-6 space-y-4">
          <div className="flex flex-col">
            <div className={cn(
              "p-4 rounded-lg w-16 h-16",
              "flex items-center justify-center mb-4",
              colors.bg,
              colors.text
            )}>
              {reportIcons[report.type]}
            </div>
            <CardTitle className="text-xl font-semibold text-gray-900 dark:text-gray-100">
              {t(`reports.types.${report.type}.title`)}
            </CardTitle>
            <CardDescription className="mt-2 text-sm leading-relaxed text-gray-500 dark:text-gray-400">
              {t(`reports.types.${report.type}.description`)}
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent className="p-6 pt-0 mt-auto">
          <div className="flex justify-end">
            <Button
              className={cn(
                "bg-blue-500 hover:bg-blue-600 text-white",
                "font-medium px-6 py-2 rounded-full",
                "w-full sm:w-auto"
              )}
              onClick={() => onView(report)}
              disabled={report.disabled}
            >
              {t('reports.actions.view')}
            </Button>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default ReportCard;