/**
 * @file src/components/cloud-drive/items/FileItem.tsx
 * @description Main file item component with enhanced encrypted media thumbnail support
 * @version 4.4.0
 */

import React, { useMemo, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/utils/utils';
import { useNavigation } from '@/context/NavigationContext';
import { useSelection } from '@/context/SelectionContext';
import { useDraggableItem, useDroppableFolder } from '../dnd/DragContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useCloudDrive } from '@/context/cloud-drive';

import { FileContextMenu } from '../actions/FileContextMenu';
import { StatusBadges } from './components/StatusBadges';
import { ItemCheckbox } from './components/ItemCheckbox';
import { ItemIcon } from './components/ItemIcon';
import { ItemInfo } from './components/ItemInfo';
import { useItemInteraction } from './hooks/useItemInteraction';
import { useItemSelection } from './hooks/useItemSelection';
import { createMotionProps } from './utils/motionProps';
import { DecryptionAnimation } from '@/components/encrypted-drive/animations/DecryptionAnimation';
import { ThumbnailViewer } from '@/components/encrypted-drive/media/ThumbnailViewer';

import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';
import { Shield } from 'lucide-react';
import { logger } from '@/utils/logger';

interface FileItemProps {
  item: DriveFile | DriveFolder;
  items: (DriveFile | DriveFolder)[];
  view: 'grid' | 'list';
  className?: string;
  isSelected?: boolean;
  isSelectMode?: boolean;
  onSelect?: (id: string, event?: React.MouseEvent) => void;
  onOpen?: (item: DriveFile | DriveFolder) => void;
  showCheckbox?: boolean;
  animationDelay?: number;
}

export const FileItem = React.forwardRef<HTMLDivElement, FileItemProps>(({
  item,
  items,
  view,
  className,
  isSelected: propIsSelected,
  isSelectMode: propIsSelectMode,
  onSelect: propOnSelect,
  onOpen: propOnOpen,
  showCheckbox,
  animationDelay = 0
}, ref) => {
  const { navigateToFolder, navigateToFile } = useNavigation();
  const selectionContext = useSelection();
  const { encryptedDrive } = useCloudDrive();
  
  // Debug full item structure on mount - limited to development mode
  useEffect(() => {
    // Only log in development mode to reduce logger noise
    if (process.env.NODE_ENV === 'development') {
      logger.debug('FileItem debug', {
        component: 'FileItem',
        data: {
          itemId: item.id,
          itemType: item.type,
          hasMediaInfo: !!((item as any).media_info || (item as any).mediaInfo),
          animationDelay
        }
      });
    }
  }, [item, animationDelay]);
  
  // Use context or props for selection state
  const isSelectMode = propIsSelectMode !== undefined ? propIsSelectMode : selectionContext.isSelectMode;
  const setIsSelectMode = selectionContext.setIsSelectMode;
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Item selection handling - THIS MUST COME BEFORE DRAGGABLE SETUP
  const {
    isSelected: hookIsSelected,
    handleSelection,
    handleCheckboxChange: originalHandleCheckboxChange,
  } = useItemSelection({ item, items });
  
  // Enhanced checkbox handler with extra logging to debug selection issues
  const handleCheckboxChange = useCallback((newValue: boolean, event?: React.MouseEvent) => {
    logger.debug('Checkbox clicked in FileItem', {
      component: 'FileItem',
      data: {
        itemId: item.id,
        newValue,
        hasEvent: !!event
      }
    });
    
    // Make sure event propagation is stopped if event exists
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    
    // Call the original handler but ensure it's done safely
    originalHandleCheckboxChange(newValue);
  }, [item.id, originalHandleCheckboxChange]);
  
  // Use prop value if provided, otherwise use hook value
  const isSelected = propIsSelected !== undefined ? propIsSelected : hookIsSelected;

  // Comprehensive detection of media info using multiple approaches
  const hasMediaInfo = useMemo(() => {
    try {
      // First check if hasMediaInfo property is explicitly set
      if ((item as any).hasMediaInfo === true) {
        return true;
      }
      
      // Try accessing the media_info in multiple ways
      const mediaInfo = 
        // Original API response structure 
        (item as any).media_info || 
        // Camel case version
        (item as any).mediaInfo || 
        // Any other potential property
        null;
      
      if (mediaInfo) {
        logger.debug('Media info found', {
          component: 'FileItem',
          data: {
            itemId: item.id,
            mediaInfoKeys: Object.keys(mediaInfo),
            hasThumbnails: !!(mediaInfo.thumbnails || mediaInfo.has_thumbnails),
            mediaType: mediaInfo.type
          }
        });
        
        // Check if it has thumbnails in any form
        if (mediaInfo.thumbnails || mediaInfo.has_thumbnails) {
          return true;
        }
      }
      
      // Fallback: Check file extension
      const fileExt = item.name?.toLowerCase().split('.').pop() || '';
      const imageExts = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp'];
      const videoExts = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv'];
      
      const isImageByExt = imageExts.includes(fileExt);
      const isVideoByExt = videoExts.includes(fileExt);
      
      if (isImageByExt || isVideoByExt) {
        logger.debug('Media file detected by extension', {
          component: 'FileItem',
          data: {
            itemId: item.id, 
            fileName: item.name,
            extension: fileExt,
            mediaType: isImageByExt ? 'image' : 'video'
          }
        });
        
        // In favorites view, we should still attempt to show thumbnails even if no media_info
        if (item.favorite) {
          return true;
        }
        
        // Double check if we have some form of media info
        return !!(mediaInfo);
      }
      
      return false;
    } catch (error) {
      logger.error('Error detecting media info', {
        component: 'FileItem',
        error,
        data: { itemId: item.id }
      });
      return false;
    }
  }, [item, encryptedDrive.isEncryptedMode]);

  // Extract thumbnail information from any available source
  const thumbnailInfo = useMemo(() => {
    try {
      // Try multiple paths to find thumbnails
      
      // 1. Try direct access to original API response structure
      if ((item as any).media_info?.thumbnails) {
        logger.debug('Found thumbnails in item.media_info', {
          component: 'FileItem',
          data: {
            itemId: item.id,
            thumbKeys: Object.keys((item as any).media_info.thumbnails)
          }
        });
        return (item as any).media_info.thumbnails;
      }
      
      // 2. Try camelCase version
      if ((item as any).mediaInfo?.thumbnails) {
        logger.debug('Found thumbnails in item.mediaInfo', {
          component: 'FileItem',
          data: {
            itemId: item.id,
            thumbKeys: Object.keys((item as any).mediaInfo.thumbnails)
          }
        });
        return (item as any).mediaInfo.thumbnails;
      }
      
      // Check if we have media info that has a has_thumbnails flag but no actual thumbnails object
      if ((item as any).media_info?.has_thumbnails || (item as any).mediaInfo?.has_thumbnails) {
        // Create a placeholder thumbnails object
        logger.debug('Has thumbnails flag found but no thumbnails object', {
          component: 'FileItem',
          data: { itemId: item.id }
        });
        
        return {
          small: { width: 200, height: 200 },
          medium: { width: 400, height: 400 }
        };
      }
      
      // For favorite image/video items with no thumbnails, create a placeholder
      if (item.favorite && item.type === 'file') {
        const fileExt = (item.name || '').toLowerCase().split('.').pop() || '';
        const isImageByExt = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp'].includes(fileExt);
        const isVideoByExt = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv'].includes(fileExt);
        
        if (isImageByExt || isVideoByExt) {
          logger.debug('Creating placeholder thumbnails for favorite media item', {
            component: 'FileItem',
            data: { itemId: item.id, fileType: isImageByExt ? 'image' : 'video' }
          });
          
          return {
            small: { width: 200, height: 200 },
            medium: { width: 400, height: 400 }
          };
        }
      }
      
      // Log if we have any media info but no thumbnails
      if ((item as any).media_info || (item as any).mediaInfo) {
        const mediaInfo = (item as any).media_info || (item as any).mediaInfo;
        logger.debug('Have media info but no thumbnails', {
          component: 'FileItem',
          data: {
            itemId: item.id,
            mediaInfoKeys: Object.keys(mediaInfo),
            hasThumbnailsFlag: mediaInfo.has_thumbnails || mediaInfo.hasThumbnails
          }
        });
      } else {
        logger.debug('No media_info available', {
          component: 'FileItem',
          data: { itemId: item.id }
        });
      }
      
      return null;
    } catch (error) {
      logger.error('Error extracting thumbnail info', {
        component: 'FileItem',
        error,
        data: { itemId: item.id }
      });
      return null;
    }
  }, [item]);

  // Get media type safely
  const mediaType = useMemo(() => {
    if (!hasMediaInfo) return null;
    try {
      // Try both snake_case and camelCase
      const mediaInfo = (item as any).media_info || (item as any).mediaInfo;
      
      // First check if we have explicit type in media info
      if (mediaInfo?.type) {
        return mediaInfo.type;
      }
      
      // Fallback: Check file extension
      const fileExt = item.name?.toLowerCase().split('.').pop() || '';
      const imageExts = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp', 'svg'];
      const videoExts = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv', '3gp'];
      
      if (imageExts.includes(fileExt)) return 'image';
      if (videoExts.includes(fileExt)) return 'video';
      
      return null;
    } catch (error) {
      return null;
    }
  }, [item, hasMediaInfo]);

  // Drag and drop setup - now that isSelected is defined
  const { dragRef, isDragging } = useDraggableItem(item, isSelected);
  const { dropRef, isOver, canDrop } = item.type === 'folder' 
    ? useDroppableFolder(item as DriveFolder, (itemIds, targetId) => {
        // Provide a noop function to satisfy the type
        console.log('Would move', itemIds, 'to', targetId);
      })
    : { dropRef: null, isOver: false, canDrop: false };

  // Combine refs
  const combinedRef = (node: HTMLDivElement) => {
    if (ref) {
      typeof ref === 'function' ? ref(node) : (ref.current = node);
    }
    if (node) {
      dragRef(node);
      if (dropRef) dropRef(node);
    }
  };

  // Item interaction handling with adapter for type compatibility 
  // Enhanced with safeguards for opening media files in favorites view
  const handleOpenWrapper = (item: DriveFile | DriveFolder) => {
    try {
      if (item?.type === 'folder') {
        if (typeof navigateToFolder === 'function') {
          navigateToFolder(item.id);
        } else {
          logger.warn('navigateToFolder is not a function', {
            component: 'FileItem',
            data: { itemId: item.id }
          });
        }
      } else {
        // For media files in favorites view, always use the file preview
        const fileExt = (item.name || '').toLowerCase().split('.').pop() || '';
        const isImageByExt = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp'].includes(fileExt);
        const isVideoByExt = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv'].includes(fileExt);
        
        // Special handling for favorite media files to use preview directly
        // But avoid the dynamic import which causes errors and use a simple approach
        if (item.favorite && (isImageByExt || isVideoByExt)) {
          logger.debug('Favorite media file detected - using direct navigation', {
            component: 'FileItem',
            data: {
              itemId: item.id,
              itemName: item.name,
              extension: fileExt,
              isImage: isImageByExt,
              isVideo: isVideoByExt
            }
          });
          
          // In favorites view, use the main drive navigation instead of preview
          // This avoids the hook/context issues with dynamic imports
          if (typeof navigateToFile === 'function') {
            navigateToFile(item.id);
          } else {
            logger.warn('navigateToFile not available', {
              component: 'FileItem',
              data: { itemId: item.id }
            });
          }
        } else {
          // Regular file navigation
          if (typeof navigateToFile === 'function') {
            navigateToFile(item.id);
          } else {
            logger.warn('navigateToFile is not a function', {
              component: 'FileItem',
              data: { itemId: item.id }
            });
          }
        }
      }
    } catch (error) {
      logger.error('Error in handleOpenWrapper', {
        component: 'FileItem',
        error,
        data: { itemId: item.id, itemType: item.type }
      });
    }
  };

  // Create type-safe handlers before passing to useItemInteraction
  // This must match the exact expected signature
  const safeHandleSelection = (id: string, event?: React.MouseEvent) => {
    if (propOnSelect) {
      propOnSelect(id, event);
    } else if (event) { 
      // For proper type safety, we don't call handleSelection if no event is provided
      // because handleSelection expects a MouseEvent as required parameter
      handleSelection(event);
    }
  };
  
  const safeHandleOpen = (item: DriveFile | DriveFolder) => {
    if (propOnOpen) {
      propOnOpen(item);
    } else {
      handleOpenWrapper(item);
    }
  };

  const {
    handleClick,
    handleTouchStart,
    handleTouchEnd,
    handleOpen: interactionHandleOpen
  } = useItemInteraction({
    item,
    isSelectMode,
    onSelect: safeHandleSelection,
    onOpen: safeHandleOpen,
    setIsSelectMode
  });
  
  // Use provided onOpen or default
  const handleOpen = propOnOpen ? ((item: DriveFile | DriveFolder) => propOnOpen(item)) : interactionHandleOpen;

  // Motion animation props
  const motionProps = createMotionProps(
    {
      ref: combinedRef,
      onClick: handleClick,
      onDoubleClick: () => handleOpen(item),
      onTouchStart: handleTouchStart,
      onTouchEnd: handleTouchEnd,
    },
    isMobile,
    view
  );

  // For Grid view
  if (view === 'grid') {
    return (
      <FileContextMenu item={item}>
        <motion.div
          {...motionProps}
          initial={false}
          animate={{
            scale: isSelected ? 0.98 : 1,
            opacity: isDragging ? 0.6 : 1
          }}
          className={cn(
            "group relative h-full",
            "bg-white dark:bg-gray-800",
            "border-2 transition-colors duration-200",
            isSelected
              ? "border-blue-500 dark:border-blue-400"
              : "border-gray-200 dark:border-gray-700",
            "rounded-lg overflow-hidden",
            "hover:border-blue-400/50 dark:hover:border-blue-500/50",
            "hover:shadow-md",
            className
          )}
        >
          {/* Selection Overlay */}
          <motion.div
            initial={false}
            animate={{
              opacity: isSelected ? 0.1 : 0
            }}
            className="absolute inset-0 bg-blue-500 pointer-events-none"
          />

          {/* Checkbox */}
          <ItemCheckbox
            checked={isSelected}
            isSelectMode={isSelectMode}
            onChange={handleCheckboxChange}
            className="absolute left-2 top-2 z-20"
          />

          {/* Status Badges with toggle action */}
          <div className="absolute right-2 top-2 z-20">
            <StatusBadges
              isFavorite={item.favorite}
              isShared={item.shared}
              onToggleFavorite={useCallback((e) => {
                e.stopPropagation();
                e.preventDefault();
                try {
                  import('@/context/cloud-drive').then(({ useCloudDrive }) => {
                    const { toggleFavorite } = useCloudDrive();
                    if (toggleFavorite) {
                      toggleFavorite(item.id);
                      logger.debug('Toggled favorite from StatusBadges', {
                        component: 'FileItem',
                        data: { itemId: item.id, newState: !item.favorite }
                      });
                    }
                  });
                } catch (error) {
                  logger.error('Error toggling favorite from StatusBadges', {
                    component: 'FileItem',
                    error,
                    data: { itemId: item.id }
                  });
                }
              }, [item.id, item.favorite])}
            />
          </div>

          {/* Enhanced icon/thumbnail section */}
          {hasMediaInfo && thumbnailInfo ? (
            <div className="aspect-square w-full relative overflow-hidden">
              <ThumbnailViewer
                fileId={item.id}
                driveId={encryptedDrive.encryptedDriveId || ''}
                mediaType={mediaType ?? undefined}
                thumbnails={thumbnailInfo}
                size={view === 'grid' ? 'medium' : 'small'}
                animationDelay={animationDelay}
                className="w-full h-full"
              />
            </div>
          ) : (
            <ItemIcon
              item={item}
              size="lg"
              className="aspect-square w-full"
            />
          )}

          {/* Info section with Decryption Animation */}
          {encryptedDrive.isEncryptedMode ? (
            <div className={cn("p-3 space-y-1")}>
              <div className="flex items-center gap-1.5">
                <DecryptionAnimation
                  decryptedText={item.name}
                  className="font-medium text-gray-900 dark:text-gray-100 block truncate"
                  duration={800 + (animationDelay || 0)}
                  showEncryptedState={animationDelay === undefined}
                />
                {/* Remove shield icon here */}
              </div>
              <DecryptionAnimation
                decryptedText={
                  item.type === 'folder'
                    ? `${(item as DriveFolder).itemCount?.toLocaleString() || 
                      ((item as any).folder_info?.total_items?.toLocaleString()) || '0'} items`
                    : new Date(item.modified).toLocaleDateString()
                }
                className="text-xs text-gray-500 dark:text-gray-400"
                duration={1000 + (animationDelay || 0)}
                showEncryptedState={animationDelay === undefined}
              />
            </div>
          ) : (
            <ItemInfo
              item={item}
              view="grid"
            />
          )}
        </motion.div>
      </FileContextMenu>
    );
  }

  // List view
  return (
    <FileContextMenu item={item}>
      <motion.div
        {...motionProps}
        className={cn(
          "group relative",
          "flex items-center gap-4 px-4 py-2",
          "bg-white dark:bg-gray-800",
          "rounded-lg",
          "transition-all duration-200",
          isSelected && "bg-[hsl(var(--selection-bg))] border-[hsl(var(--selection-border))]",
          !isSelected && !isMobile && "hover:bg-gray-50/80 dark:hover:bg-gray-700/50",
          className
        )}
      >
        {/* Checkbox */}
        {(showCheckbox || isSelectMode) && (
          <ItemCheckbox
            checked={isSelected}
            isSelectMode={isSelectMode}
            onChange={handleCheckboxChange}
            className="flex-shrink-0"
          />
        )}

        {/* Enhanced icon/thumbnail section for list view */}
        {hasMediaInfo && thumbnailInfo ? (
          <div className="flex-shrink-0 w-10 h-10 rounded-lg overflow-hidden">
            <ThumbnailViewer
              fileId={item.id}
              driveId={encryptedDrive.encryptedDriveId || ''}
              mediaType={mediaType ?? undefined}
              thumbnails={thumbnailInfo}
              size="small" // Always use small for list view thumbnails
              animationDelay={animationDelay}
              className="w-full h-full"
            />
          </div>
        ) : (
          <ItemIcon
            item={item}
            size="sm"
            className="flex-shrink-0 w-10 h-10 rounded-lg"
          />
        )}

        {/* Info section with Decryption Animation */}
        {encryptedDrive.isEncryptedMode ? (
          <div className="flex-1 min-w-0">
            <div className="flex items-center gap-1.5">
              <DecryptionAnimation
                decryptedText={item.name}
                className="font-medium text-gray-900 dark:text-gray-100 block truncate"
                duration={800 + (animationDelay || 0)}
                showEncryptedState={animationDelay === undefined}
              />
              {/* Keep only one shield icon */}
              <Shield className="h-3 w-3 text-blue-500 dark:text-blue-400 flex-shrink-0" />
            </div>
            <div className="flex items-center gap-2 text-xs text-gray-500 dark:text-gray-400">
              <DecryptionAnimation
                decryptedText={
                  item.type === 'folder'
                    ? `${(item as DriveFolder).itemCount?.toLocaleString() || 
                      ((item as any).folder_info?.total_items?.toLocaleString()) || '0'} items`
                    : new Date(item.modified).toLocaleDateString()
                }
                duration={1000 + (animationDelay || 0)}
                showEncryptedState={animationDelay === undefined}
              />
            </div>
          </div>
        ) : (
          <ItemInfo
            item={item}
            view="list"
            showOptions={!isMobile}
          />
        )}
      </motion.div>
    </FileContextMenu>
  );
});

FileItem.displayName = "FileItem";

export default FileItem;