/**
 * @file src/components/ui/AuthRefreshModal.tsx
 * @description Session expiry dialog that provides a friendly way to re-authenticate
 * @version 1.0.0
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, LogOut, AlertTriangle, RotateCw } from 'lucide-react';
import { useAuth } from '@/context/AuthContext';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { logger } from '@/utils/logger';

// Global singleton to track when the dialog is shown
let isDialogShowing = false;

interface AuthRefreshModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onReauthenticate: () => void;
}

export const AuthRefreshModal: React.FC<AuthRefreshModalProps> = ({
  open,
  onOpenChange,
  onReauthenticate
}) => {
  const { refreshSession, logout } = useAuth();
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  // Handle refresh attempt
  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      
      logger.debug('Attempting manual session refresh', {
        component: 'AuthRefreshModal'
      });
      
      const result = await refreshSession();
      
      if (result) {
        logger.info('Manual session refresh successful', {
          component: 'AuthRefreshModal'
        });
        
        onOpenChange(false);
        isDialogShowing = false;
      } else {
        logger.warn('Manual session refresh failed', {
          component: 'AuthRefreshModal'
        });
      }
    } catch (error) {
      logger.error('Error refreshing session', {
        component: 'AuthRefreshModal', 
        error
      });
    } finally {
      setIsRefreshing(false);
    }
  };
  
  // Handle logout
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/auth/signin');
    } catch (error) {
      logger.error('Error logging out', {
        component: 'AuthRefreshModal',
        error
      });
      // Force navigation to login page even if logout fails
      navigate('/auth/signin');
    }
  };
  
  // Handle re-authenticate
  const handleReauthenticate = () => {
    onReauthenticate();
    onOpenChange(false);
    isDialogShowing = false;
  };
  
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-lg">
            <Shield className="h-5 w-5 text-yellow-500" />
            Your session needs attention
          </DialogTitle>
        </DialogHeader>
        
        <div className="p-4 my-2 border rounded-md bg-yellow-50 dark:bg-yellow-900/20">
          <div className="flex gap-3">
            <AlertTriangle className="h-5 w-5 text-yellow-500 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm mb-2">
                Your session may have expired or encountered an issue.
                You have several options to continue:
              </p>
              <ol className="text-sm list-decimal pl-4 space-y-1">
                <li>Try refreshing your session</li>
                <li>Sign in again</li>
                <li>Sign out completely</li>
              </ol>
            </div>
          </div>
        </div>
        
        <DialogFooter className="flex justify-between sm:justify-between gap-2">
          <Button variant="outline" className="flex-1" onClick={handleLogout}>
            <LogOut className="h-4 w-4 mr-2" />
            Sign Out
          </Button>
          
          <Button 
            variant="default" 
            onClick={handleReauthenticate}
            className="flex-1"
          >
            Sign In Again
          </Button>
          
          <Button 
            variant="secondary" 
            onClick={handleRefresh}
            disabled={isRefreshing}
            className="flex-1"
          >
            {isRefreshing ? (
              <>
                <RotateCw className="h-4 w-4 mr-2 animate-spin" />
                Refreshing...
              </>
            ) : (
              <>
                <RotateCw className="h-4 w-4 mr-2" />
                Try Refresh
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Singleton manager for the auth refresh dialog
export class AuthRefreshManager {
  private static instance: AuthRefreshManager;
  
  // Prevent multiple instances
  private constructor() {}
  
  // Get singleton instance
  public static getInstance(): AuthRefreshManager {
    if (!AuthRefreshManager.instance) {
      AuthRefreshManager.instance = new AuthRefreshManager();
    }
    return AuthRefreshManager.instance;
  }
  
  // Show the dialog
  public showDialog() {
    // Only show one instance of the dialog
    if (isDialogShowing) return;
    
    // Set global flag that dialog is showing
    isDialogShowing = true;
    
    // Dispatch custom event to trigger dialog rendering
    const event = new CustomEvent('auth:session-expired', {
      detail: { timestamp: Date.now() }
    });
    window.dispatchEvent(event);
    
    logger.debug('Auth refresh dialog triggered', {
      component: 'AuthRefreshManager'
    });
  }
  
  // Hide the dialog
  public hideDialog() {
    if (!isDialogShowing) return;
    
    isDialogShowing = false;
    
    // Dispatch custom event to hide dialog
    const event = new CustomEvent('auth:session-restored', {
      detail: { timestamp: Date.now() }
    });
    window.dispatchEvent(event);
  }
}

// Export the singleton instance
export const authRefreshManager = AuthRefreshManager.getInstance();