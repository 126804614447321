/**
 * @file src/layouts/ghost/components/create-core/CreateCoreDialog.tsx
 * @description Ghost-themed create core dialog with accessibility improvements
 * @version 1.2.0
 */

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { useEncryptedDrives } from '@/hooks/encrypted-drive/useEncryptedDrive';
import { encryptionService } from '@/services/encrypted-drive/encryption-singleton';
import { GhostDialog } from '@/app/ghost/components/common/GhostDialog';
import { logger } from '@/utils/logger';
import type { KeyGenerationResult } from '@/types/encryption.types';
import { useToast } from '@/components/ui/toast';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { useCloudDrive } from '@/context/cloud-drive';
import { useNavigate } from 'react-router-dom';
import { CreateDriveRequest, EncryptionTier } from '@/types/encrypted-drive.types';

// Import step components and error boundary
import { StepWelcome } from "./steps/StepWelcome";
import { StepDriveDetails } from "./steps/StepDriveDetails";
import { StepShieldType } from "./steps/StepShieldType";
import { StepPassword } from "./steps/StepPassword";
import { StepRecovery } from "./steps/StepRecovery";
import { StepSuccess } from "./steps/StepSuccess";
import { ShieldErrorBoundary } from "./steps/shield-selection/components/ShieldErrorBoundary";

interface CreateCoreDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export interface CreateCoreFormData {
  name: string;
  encryptionTier: EncryptionTier;
  password: string;
  confirmPassword: string;
  recoveryKey?: string;
  driveId?: string;
}

type StepProps = 
  | { onNext: () => void }
  | { formData: CreateCoreFormData; setFormData: React.Dispatch<React.SetStateAction<CreateCoreFormData>>; onNext: () => void; onBack: () => void }
  | { formData: CreateCoreFormData; setFormData: React.Dispatch<React.SetStateAction<CreateCoreFormData>>; onNext: () => void; onBack: () => void }
  | { formData: CreateCoreFormData; setFormData: React.Dispatch<React.SetStateAction<CreateCoreFormData>>; onNext: () => void; onBack: () => void }
  | { formData: CreateCoreFormData; keyData: KeyGenerationResult | null; onNext: () => void; onBack: () => void; onComplete: () => void; isCreating: boolean }
  | { formData: CreateCoreFormData; onClose: () => void };

export const CreateCoreDialog: React.FC<CreateCoreDialogProps> = ({
  open,
  onOpenChange,
}) => {
  const { t } = useGhostTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { setEncryptedDrive } = useCloudDrive();

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<CreateCoreFormData>({
    name: '',
    encryptionTier: EncryptionTier.STANDARD,
    password: '',
    confirmPassword: '',
  });
  const [keyData, setKeyData] = useState<KeyGenerationResult | null>(null);
  const { createDrive } = useEncryptedDrives();
  const { systemDevice } = useRootDrive();
  const [isCreating, setIsCreating] = useState(false);
  const [createdDriveId, setCreatedDriveId] = useState<string | null>(null);

  const getTitles = () => {
    switch (step) {
      case 0:
        return {
          title: t('create.welcome.title'),
          description: t('create.welcome.description')
        };
      case 1:
        return {
          title: t('create.details.title'),
          description: t('create.details.description')
        };
      case 2:
        return {
          title: t('create.shield.title'),
          description: t('create.shield.description')
        };
      case 3:
        return {
          title: t('create.password.title'),
          description: t('create.password.description')
        };
      case 4:
        return {
          title: t('create.recovery.title'),
          description: t('create.recovery.description')
        };
      default:
        return {
          title: t('create.success.title'),
          description: t('create.success.description')
        };
    }
  };

  const { title, description } = getTitles();  

  const handlePasswordConfirmed = async () => {
    try {
      logger.debug('Generating encryption keys after password confirmation');
      
      // Generate encryption keys
      const generatedKeys = await encryptionService.generateMasterKey(formData.password);
      
      logger.debug('Encryption keys generated', {
        hasSalt: !!generatedKeys.salt,
        hasRecoveryData: !!generatedKeys.recoveryData,
        hasVerificationHash: !!generatedKeys.verificationHash
      });
  
      // Store key data and recovery key
      setKeyData(generatedKeys);
      setFormData(prev => ({
        ...prev,
        recoveryKey: generatedKeys.recoveryData?.key
      }));
  
      // Move to next step
      handleNext();
    } catch (error) {
      logger.error('Error generating encryption keys:', error);
      
      showToast(
        String(t('create.errors.key_generation_failed')),
        "error"
      );
      
      // Stay on current step
      return;
    }
  };

  // Enhanced version with proper key handling and navigation
  const handleCreateDrive = async () => {
    try {
      if (!keyData) {
        throw new Error('Missing key data');
      }
      
      if (!systemDevice?.id) {
        logger.error('System device not found', {
          component: 'CreateCoreDialog',
          action: 'handleCreateDrive'
        });
        showToast(
          String(t('create.errors.no_system_device')),
          "error"
        );
        return;
      }
  
      setIsCreating(true);
      
      // Create parameters with snake_case for API
      const driveParams: CreateDriveRequest = {
        name: formData.name,
        device_id: systemDevice.id,
        encryption_tier: formData.encryptionTier,
        encrypted_content_key: keyData.encryptedKeys?.contentKey,
        encrypted_metadata_key: keyData.encryptedKeys?.metadataKey,
        encrypted_keys_iv: keyData.encryptedKeys?.iv,
        key_salt: keyData.salt,
        key_verification_hash: keyData.verificationHash,
        recovery_verification_hash: keyData.recoveryData?.verification_hash
      };
      
      logger.debug('Creating drive with params', {
        driveName: driveParams.name,
        deviceId: driveParams.device_id,
        encryptionTier: driveParams.encryption_tier
      });
      
      // Make API call directly without additional error handling here
      const result = await createDrive(driveParams);
      
      // Log the success
      logger.debug('Drive created successfully', {
        component: 'CreateCoreDialog',
        action: 'handleCreateDrive',
        data: {
          result,
          driveId: result?.drive_id,
          driveName: formData.name,
          encryptionTier: formData.encryptionTier
        }
      });
      
      // Store the created drive ID
      if (result?.drive_id) {
        setCreatedDriveId(result.drive_id);
        
        // Set the active drive in encryptionService to ensure correct tier is used
        // Use await to ensure tier is properly set
        await encryptionService.setActiveDrive(result.drive_id);
        
        // Add explicit console logging
        console.debug(`[CreateCoreDialog] Active drive set to ${result.drive_id} with tier ${formData.encryptionTier}`);
        
        // Store decrypted keys for this drive using keys generated earlier
        // This ensures the drive is "unlocked" without requiring a password again
        if (keyData.keys?.contentKey && keyData.keys?.metadataKey) {
          logger.debug('Storing decrypted keys for new drive', {
            component: 'CreateCoreDialog',
            data: {
              driveId: result.drive_id,
              tier: formData.encryptionTier
            }
          });
          
          // First set the tier in the driveTierMap
          logger.debug('Setting encryption tier for newly created drive', {
            component: 'CreateCoreDialog',
            data: {
              driveId: result.drive_id,
              tier: formData.encryptionTier
            }
          });

          try {
            // Store encryption tier in storage
            const tierData = {
              [result.drive_id]: formData.encryptionTier
            };
            // Store in localStorage to survive page refresh
            localStorage.setItem('drive_encryption_tiers', JSON.stringify({
              ...JSON.parse(localStorage.getItem('drive_encryption_tiers') || '{}'),
              ...tierData
            }));
          } catch (e) {
            logger.error('Error storing encryption tier in localStorage', {
              component: 'CreateCoreDialog',
              error: e
            });
          }
          
          // IMPORTANT: Must set drive tier before setting keys
          // Update the driveTierMap directly using set() instead of creating a new Map
          // This ensures we don't replace the entire map and lose other drive tiers
          encryptionService.driveTierMap.set(result.drive_id, formData.encryptionTier);
          
          // Manually change the current encryption tier
          encryptionService.currentEncryptionTier = formData.encryptionTier;
          
          // Wait for a short period to ensure the tier is set before continuing
          await new Promise(resolve => setTimeout(resolve, 50));
          
          logger.debug('Manually updated encryptionService tiers', {
            component: 'CreateCoreDialog',
            data: {
              newDriveId: result.drive_id,
              tier: formData.encryptionTier,
              currentTier: encryptionService.currentEncryptionTier,
              tiersCount: encryptionService.driveTierMap.size
            }
          });
          
          // Now store the keys in encryptionService with explicit encryption tier
          encryptionService.storeDecryptedKeys(
            result.drive_id, 
            {
              contentKey: keyData.keys.contentKey,
              metadataKey: keyData.keys.metadataKey,
              keyVersion: "1.0"
            },
            formData.encryptionTier // Pass the tier explicitly
          );
          
          // Verify keys were stored properly
          const storedKeys = encryptionService.getDecryptedKeys(result.drive_id);
          logger.debug('Verified key storage after creation', {
            component: 'CreateCoreDialog',
            data: {
              driveId: result.drive_id,
              hasKeys: !!storedKeys,
              hasMetadataKey: !!storedKeys?.metadataKey,
              hasContentKey: !!storedKeys?.contentKey,
              isUnlocked: encryptionService.isDriveUnlocked(result.drive_id)
            }
          });
        }
        
        // Also update the form data for display in success screen
        setFormData(prev => ({
          ...prev,
          driveId: result.drive_id
        }));
      }
      
      // Proceed to next step regardless of API response details
      setIsCreating(false);
      handleNext();
      
    } catch (error) {
      setIsCreating(false);
      logger.error('Error creating drive:', error);
      
      showToast(
        String(t('create.errors.drive_creation_failed')),
        "error"
      );
    }
  };  

  const handleNext = () => {
    logger.debug('Moving to next step', { step });
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    logger.debug('Moving to previous step', { step });
    setStep((prev) => prev - 1);
  };

  // Handle navigation to the drives list page after creating a new drive
  const navigateToNewDrive = React.useCallback(() => {
    logger.debug('Navigating to drives page after creating new drive', {
      component: 'CreateCoreDialog',
      action: 'navigateToNewDrive',
      data: {
        driveId: createdDriveId,
        driveName: formData.name,
        encryptionTier: formData.encryptionTier
      }
    });

    // Instead of navigating directly to the drive, go to the drives list page
    setTimeout(() => {
      navigate('/ghost/drives', { replace: true });
      
      // Show a toast message instructing the user to unlock the drive
      showToast(t('create.success.unlock_message') || 'Drive created successfully. Please unlock it to start uploading files.', 'success');
    }, 100);
  }, [createdDriveId, formData, navigate, showToast, t]);

  const handleClose = () => {
    // If we have a created drive ID, navigate to it when closing the dialog
    if (createdDriveId) {
      navigateToNewDrive();
    }
    
    // Reset state
    setStep(0);
    setFormData({
      name: '',
      encryptionTier: EncryptionTier.STANDARD,
      password: '',
      confirmPassword: '',
    });
    setKeyData(null);
    setCreatedDriveId(null);
    onOpenChange(false);
  };

  const steps = [
    {
      component: StepWelcome,
      props: { onNext: handleNext }
    },
    {
      component: StepDriveDetails,
      props: {
        formData,
        setFormData,
        onNext: handleNext,
        onBack: handleBack
      }
    },
    {
        component: StepShieldType,
        props: {
          formData,
          setFormData,
          onNext: handleNext,
          onBack: handleBack
        },
        wrapper: ShieldErrorBoundary
    },
    {
      component: StepPassword,
      props: {
        formData,
        setFormData,
        onNext: handlePasswordConfirmed,
        onBack: handleBack
      }
    },
    {
      component: StepRecovery,
      props: {
        formData,
        keyData,
        onNext: handleNext,
        onBack: handleBack,
        onComplete: handleCreateDrive,
        isCreating
      }
    },
    {
      component: StepSuccess,
      props: {
        formData,
        onClose: handleClose
      }
    }
  ]

  const CurrentStep = steps[step].component;
  const Wrapper = steps[step].wrapper || React.Fragment;
  const currentProps = steps[step].props as any;

  return (
    <GhostDialog 
      open={open} 
      onOpenChange={handleClose}
      className="max-w-2xl"
    >
      <Wrapper>
        <AnimatePresence mode="wait">
          <motion.div
            key={step}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30
            }}
            className="relative z-10"
          >
            <CurrentStep {...currentProps} />
          </motion.div>
        </AnimatePresence>
      </Wrapper>
    </GhostDialog>
  );
};
