/**
 * @file src/routes/data.routes.tsx
 * @description Data section routing configuration with consistent DataSplitView layout
 * @version 1.2.0
 */

import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { NavigationLoader } from '@/components/cloud-drive/loading/NavigationLoader';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';
import { DataSplitView } from '@/components/data/DataSplitView';
import { logger } from '@/utils/logger';
import { CategoryId, DataSplitViewProps } from '@/components/data/DataSplitView/types';
import DeviceDetailPage from '@/pages/data/devices/DeviceDetailPage';


const getInitialView = (
  category: CategoryId
): DataSplitViewProps["initialView"] => {
  switch (category) {
    case "services":
    case "favorites":
    case "trash":
      return category;
    case "sharedWithMe":
      return "shared";
    // Map other categories to "default" or another valid view type as needed
    default:
      return "default";
  }
};

// Component wrapper to maintain consistent layout
const withDataSplitView = (category: CategoryId) => {
  return () => {
    logger.debug('Rendering data view', {
      component: 'DataRoutes',
      data:{
        category
      }
    });
    
    return (
      <ErrorBoundary>
        <DataSplitView
          initialCategory={category}
          initialView={getInitialView(category)}
        />
      </ErrorBoundary>
    );
  };
};

export const dataRoutes = {
  path: 'data',
  element: (
    <ErrorBoundary>
      <Suspense fallback={<NavigationLoader isLoading={true} />}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="/cloud-drive" replace />
    },
    {
      path: 'services',
      element: withDataSplitView('services')()
    },
    {
      path: 'encrypted-drives',
      element: withDataSplitView('encryptedDrives')()
    },
    {
      path: 'devices',
      element: withDataSplitView('devices')()
    },
    { path: 'devices/:deviceId', element: <DeviceDetailPage /> }, // Add this
    {
      path: 'shares',
      element: withDataSplitView('shares')()
    },
    {
      path: 'shared-with-me',
      element: withDataSplitView('sharedWithMe')()
    },
    {
      path: 'trash',
      element: withDataSplitView('trash')()
    },
    {
      path: 'cold-storage',
      element: withDataSplitView('coldStorage')()
    }
  ]
};