/**
 * @file src/components/ui/sheet.tsx
 * @description Simplified sliding panel component focused on side panel use case
 * @version 2.0.0
 */

import * as React from "react";
import { motion, AnimatePresence, HTMLMotionProps } from "framer-motion";
import { cn } from "@/utils/utils";

interface SheetProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

interface SheetContentProps extends Omit<HTMLMotionProps<"div">, "ref"> {
  onClose?: () => void;
  showCloseButton?: boolean;
  side?: "left" | "right";
}

interface SheetHeaderProps extends React.HTMLAttributes<HTMLDivElement> {}
interface SheetFooterProps extends React.HTMLAttributes<HTMLDivElement> {}
interface SheetTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {}
interface SheetDescriptionProps
  extends React.HTMLAttributes<HTMLParagraphElement> {}
interface SheetTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

// Memoize the Sheet component to prevent unnecessary rerenders
const Sheet = React.memo(
  React.forwardRef<HTMLDivElement, SheetProps>(
    ({ children, isOpen }, _ref) => {
      // Remove console.log to reduce noise in console
      return <AnimatePresence>{isOpen && children}</AnimatePresence>;
    }
  )
);

const SheetContent = React.forwardRef<HTMLDivElement, SheetContentProps>(
  (
    {
      children,
      onClose,
      showCloseButton = true,
      side = "right",
      className,
      ...props
    },
    ref
  ) => {
    return (
      <motion.div
        ref={ref}
        initial={{ x: side === "right" ? 320 : -320 }}
        animate={{ x: 0 }}
        exit={{ x: side === "right" ? 320 : -320 }}
        transition={{ type: "spring", damping: 25, stiffness: 200 }}
        className={cn(
          "fixed",
          side === "right" ? "right-0" : "left-0",
          "w-80",
          "bg-white dark:bg-gray-900",
          "border-l border-gray-200 dark:border-gray-800",
          "shadow-lg",
          className
        )}
        style={{
          top: "144px", // Exact toolbar height
          bottom: 0,
          willChange: "transform",
        }}
        {...props as HTMLMotionProps<"div">}
      >
        {children}
      </motion.div>
    );
  }
);

const SheetHeader = React.forwardRef<HTMLDivElement, SheetHeaderProps>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn("px-6 pt-6 space-y-2", className)}
      {...props}
    />
  )
);

const SheetFooter = React.forwardRef<HTMLDivElement, SheetFooterProps>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "flex justify-end px-6 py-4 space-x-2",
        "border-t border-gray-200 dark:border-gray-800",
        className
      )}
      {...props}
    />
  )
);

const SheetTitle = React.forwardRef<HTMLHeadingElement, SheetTitleProps>(
  ({ className, ...props }, ref) => (
    <h3
      ref={ref}
      className={cn(
        "text-lg font-semibold",
        "text-gray-900 dark:text-gray-100",
        className
      )}
      {...props}
    />
  )
);

const SheetDescription = React.forwardRef<
  HTMLParagraphElement,
  SheetDescriptionProps
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-gray-500 dark:text-gray-400", className)}
    {...props}
  />
));

const SheetTrigger = React.forwardRef<HTMLDivElement, SheetTriggerProps>(
  ({ children, asChild = false, className, ...props }, ref) => (
    <div ref={ref} className={className} {...props}>
      {asChild ? children : <button>{children}</button>}
    </div>
  )
);

// Add display names
Sheet.displayName = "Sheet";
SheetContent.displayName = "SheetContent";
SheetHeader.displayName = "SheetHeader";
SheetFooter.displayName = "SheetFooter";
SheetTitle.displayName = "SheetTitle";
SheetDescription.displayName = "SheetDescription";
SheetTrigger.displayName = "SheetTrigger";

// Export all components
export {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
};
