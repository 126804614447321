// src/layouts/ghost/components/create-core/steps/shield-selection/components/ShieldErrorBoundary.tsx

/**
 * @file src/layouts/ghost/components/create-core/steps/shield-selection/components/ShieldErrorBoundary.tsx
 * @description Error boundary for shield selection
 * @version 1.0.0
 */

import React, { Component, ErrorInfo } from 'react';
import { Shield, RefreshCw } from 'lucide-react';
import { GhostGlass } from '@/app/ghost/components/common/GhostGlass';
import { logger } from '@/utils/logger';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ShieldErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('Shield selection error:', {
      error,
      errorInfo
    });
  }

  private handleRetry = () => {
    this.setState({ hasError: false });
  };

  public render() {
    if (this.state.hasError) {
      return (
        <GhostGlass className="p-6 text-center">
          <Shield className="h-12 w-12 mx-auto mb-4 text-[hsl(var(--ghost-accent))]" />
          <h3 className="text-lg font-medium mb-2">Shield Initialization Failed</h3>
          <p className="text-sm text-[hsl(var(--ghost-foreground-muted))] mb-4">
            Something went wrong while loading the security options.
          </p>
          <button
            onClick={this.handleRetry}
            className="inline-flex items-center space-x-2 px-4 py-2 rounded-lg bg-[hsl(var(--ghost-accent))] text-white"
          >
            <RefreshCw className="h-4 w-4" />
            <span>Try Again</span>
          </button>
        </GhostGlass>
      );
    }

    return this.props.children;
  }
}