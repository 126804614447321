/**
 * @file src/main.tsx
 * @description Application entry point
 * @version 1.0.0
 * @created 2024-12-07
 */

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import '@/styles/globals.css'
import '@/app/ghost/styles' // Import ghost theme variables

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)