/**
 * @file src/components/cloud-drive/items/utils/fileIcons.ts
 * @description File type icon mappings and utilities
 * @version 1.0.0
 */

import { 
    File, 
    Folder, 
    Image, 
    FileText, 
    Film,
    Music,
    Code,
    Archive
  } from 'lucide-react';
  
  export const fileIcons = {
    'image': { Icon: Image, color: 'text-pink-500 dark:text-pink-400' },
    'video': { Icon: Film, color: 'text-purple-500 dark:text-purple-400' },
    'audio': { Icon: Music, color: 'text-green-500 dark:text-green-400' },
    'text': { Icon: FileText, color: 'text-blue-500 dark:text-blue-400' },
    'code': { Icon: Code, color: 'text-orange-500 dark:text-orange-400' },
    'archive': { Icon: Archive, color: 'text-yellow-500 dark:text-yellow-400' },
    'default': { Icon: File, color: 'text-gray-500 dark:text-gray-400' }
  } as const;
  
  export const getFolderIcon = () => ({
    Icon: Folder,
    color: 'text-blue-500 dark:text-blue-400'
  });
  
  export const getFileIcon = (mimeType: string = '') => {
    const type = mimeType.split('/')[0] as keyof typeof fileIcons;
    return fileIcons[type] || fileIcons.default;
  };
  
  export type IconInfo = {
    Icon: typeof File;
    color: string;
  };