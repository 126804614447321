/**
 * @file src/components/layout/sidebar/SidebarNav.tsx
 * @description Navigation section of the sidebar
 * @version 1.0.0
 * @created 2024-12-05
 * @dependencies framer-motion, lucide-react
 */

import React, { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  LayoutDashboard, 
  Database,
  Brain,
  Users,
  Shield,
  ClipboardList,
  BarChart2,
  Atom,
  ArrowUpCircle,
  Image
} from 'lucide-react';
import { cn } from '@/utils/utils';
import { buttonHoverAnimation } from './animations';
import type { NavItem } from './types';
import { useTranslation } from 'react-i18next';

interface SidebarNavProps {
  isSidebarCollapsed: boolean;
  setHoveredItem: (id: string | null) => void;
  updateTooltipPosition: (id: string, element: HTMLDivElement | null) => void;
}

export const SidebarNav: React.FC<SidebarNavProps> = ({
  isSidebarCollapsed,
  setHoveredItem,
  updateTooltipPosition
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Main navigation items
  const mainNavItems: NavItem[] = [
    { id: 'dashboard', label: t('sidebar.dashboard'), icon: <LayoutDashboard className="stroke-[1.5]" />, path: '/' },
    { id: 'data', label: t('sidebar.data'), icon: <Database className="stroke-[1.5]" />, path: '/data', hasSplitView: true },
    { id: 'media', label: t('sidebar.media') || 'Media', icon: <Image className="stroke-[1.5]" />, path: '/cloud-drive/media' },
    { id: 'ai', label: t('sidebar.ai'), icon: <Brain className="stroke-[1.5]" />, path: '/ai', hasSplitView: true },
    { id: 'automation', label: t('sidebar.automation'), icon: <Atom className="stroke-[1.5]" />, path: '/automation' },    
    { id: 'users', label: t('sidebar.users'), icon: <Users className="stroke-[1.5]" />, path: '/users' },
    { id: 'policies', label: t('sidebar.policies'), icon: <Shield className="stroke-[1.5]" />, path: '/policies' },
    { id: 'audit', label: t('sidebar.audit'), icon: <ClipboardList className="stroke-[1.5]" />, path: '/audit' },
    { id: 'reports', label: t('sidebar.reports'), icon: <BarChart2 className="stroke-[1.5]" />, path: '/reports' },
  ];

  const renderNavItem = (item: NavItem) => (
    <motion.div
      key={item.id}
      ref={el => itemRefs.current[item.id] = el}
      onHoverStart={() => {
        setHoveredItem(item.id);
        updateTooltipPosition(item.id, itemRefs.current[item.id]);
      }}
      onHoverEnd={() => {
        setHoveredItem(null);
      }}
      onClick={() => navigate(item.path)}
      whileHover={buttonHoverAnimation}
      className={cn(
        "flex items-center px-3 py-2 cursor-pointer",
        "relative group rounded-xl",
        "transition-all duration-300",
        location.pathname === item.path 
          ? "bg-blue-100/80 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 shadow-sm" 
          : "text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800/50",
        "text-sm h-10"
      )}
    >
      {location.pathname === item.path && (
        <motion.div 
          layoutId="activeIndicator"
          className="absolute left-0 top-0 h-full w-1 bg-blue-500 rounded-r-full shadow-md"
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        />
      )}

      <motion.div 
        className={cn(
          "w-7 h-7 flex items-center justify-center",
          location.pathname === item.path 
            ? "text-blue-600 dark:text-blue-400" 
            : "text-gray-500 dark:text-gray-400 group-hover:text-blue-500 dark:group-hover:text-blue-400"
        )}
        whileHover={{ rotate: 5 }}
      >
        {item.icon}
      </motion.div>

      <AnimatePresence>
        {!isSidebarCollapsed && (
          <motion.span
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            className="ml-2 font-medium truncate"
          >
            {item.label}
          </motion.span>
        )}
      </AnimatePresence>
    </motion.div>
  );

  return (
    <>
      {/* Main Navigation */}
      <nav className="flex-1 px-2 space-y-1 mt-4">
        {mainNavItems.map(renderNavItem)}
      </nav>

      {/* Enhanced Upgrade Button */}
      <div className="px-3 mt-auto">
        <motion.button 
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => navigate('/upgrade')}
          className={cn(
            "w-full py-2.5 rounded-xl",
            "bg-blue-500 text-white",
            "text-sm font-medium",
            "flex items-center justify-center gap-2",
            "shadow-md hover:shadow-lg",
            "transition-all duration-300",
            "border border-blue-400 hover:border-blue-300",
            "backdrop-blur-sm"
          )}
        >
          <motion.div
            animate={{ 
              y: [0, -2, 0],
              transition: { duration: 2, repeat: Infinity }
            }}
          >
            <ArrowUpCircle className="h-4 w-4" />
          </motion.div>
          {!isSidebarCollapsed && <span>Upgrade Plan</span>}
        </motion.button>
      </div>
    </>
  );
};

export default SidebarNav;