/**
 * @file src/layouts/ghost/components/create-core/steps/shield-selection/components/ShieldProgress.tsx
 */

import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/utils/utils';
import type { Shield } from '../types/shield-types';

interface ShieldProgressProps {
  shields: Shield[];
  selectedShield: string | null;
  className?: string;
}

export const ShieldProgress: React.FC<ShieldProgressProps> = ({
  shields,
  selectedShield,
  className
}) => {
  const selectedIndex = selectedShield ? 
  shields.findIndex(s => s.id === selectedShield) : -1;
  
  return (
    <div className={cn("relative h-6", className)}> {/* Reduced height */}
      {/* Progress Line */}
      <div className="absolute top-1/2 left-[15%] right-[15%] h-[1px]"> {/* Thinner line */}
        {/* Background Line */}
        <div className="absolute inset-0 bg-[hsl(var(--ghost-accent))]/10" />
        
        {/* Active Line */}
        {selectedShield && (
          <motion.div
            initial={{ width: 0 }}
            animate={{ 
              width: `${((selectedIndex + 1) / shields.length) * 100}%`
            }}
            className="absolute h-full bg-[hsl(var(--ghost-accent))]"
          />
        )}
      </div>

      {/* Shield Points */}
      <div className="relative flex justify-between px-[15%]">
        {shields.map((shield, index) => {
          const isSelected = selectedShield === shield.id;
          const isPast = selectedShield ? 
            shields.findIndex(s => s.id === selectedShield) >= index : 
            false;
          const Icon = shield.icon;

          return (
            <motion.div
              key={shield.id}
              className="relative"
              initial={false}
              animate={{
                scale: isSelected ? 1.1 : 1
              }}
            >
              {/* Point */}
              <div className={cn(
                "w-3 h-3 rounded-full", // Smaller points
                "border",
                (isSelected || isPast)
                  ? "border-[hsl(var(--ghost-accent))] bg-[hsl(var(--ghost-accent))]"
                  : "border-[hsl(var(--ghost-accent))]/20 bg-[hsl(var(--ghost-surface))]",
                "transition-colors duration-300"
              )}>
                {/* Glow Effect */}
                {isSelected && (
                  <motion.div
                    className="absolute inset-0"
                    animate={{
                      scale: [1, 1.5, 1],
                      opacity: [0.2, 0.1, 0.2]
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity
                    }}
                  >
                    <div className="w-full h-full rounded-full bg-[hsl(var(--ghost-accent))]" />
                  </motion.div>
                )}
              </div>

              {/* Shield Icon */}
              <div className={cn(
                "absolute -bottom-5 left-1/2 -translate-x-1/2", // Adjusted positioning
                (isSelected || isPast)
                  ? "text-[hsl(var(--ghost-accent))]"
                  : "text-[hsl(var(--ghost-foreground-muted)]"
              )}>
                <Icon className="w-3 h-3" /> {/* Smaller icons */}
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default ShieldProgress;