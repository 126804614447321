/**
 * @file src/components/cloud-drive/batch/BatchOperationsBar.tsx
 * @description Enhanced batch operations bar with improved selection handling
 * @version 1.2.0
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { 
  Copy,
  FolderInput,
  Download,
  Share2,
  Star,
  Trash2,
} from 'lucide-react';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useSelection } from '@/context/SelectionContext';
import { logger } from '@/utils/logger';

interface BatchOperationsBarProps {
  className?: string;
}

export const BatchOperationsBar: React.FC<BatchOperationsBarProps> = ({
  className
}) => {
  const { t } = useTranslation();
  const { 
    selectedItems,
    getSelectionInfo,
    isPaginatedSelection,
  } = useSelection();

  const selectionInfo = getSelectionInfo();

  // Selection info text
  const selectionText = React.useMemo(() => {
    if (isPaginatedSelection) {
      return t('cloud_drive.selection.paginated_items_selected', { count: selectionInfo.count });
    }
    return t('cloud_drive.selection.items_selected', { count: selectionInfo.count });
  }, [selectionInfo.count, isPaginatedSelection, t]);

  logger.debug('BatchOperationsBar render', {
    component: 'BatchOperationsBar',
    selectedCount: selectedItems.length
  } as any); 

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.2 }}
      className={cn(
        "flex items-center justify-between px-4 py-2",
        "bg-[hsl(var(--selection-bg))]",
        "border-b border-[hsl(var(--selection-border))]",
        "backdrop-blur-sm",
        className
      )}
    >
      {/* Left Section - Selection Info */}
      <div className="flex items-center gap-2">
        <span className="font-medium text-[hsl(var(--selection-text))]">
          {selectionText}
        </span>
      </div>

      {/* Right Section - Actions */}
      <div className="flex items-center gap-2">
        {/* Download - Only show if files are selected */}
        {selectionInfo.hasFiles && (
          <Button 
            variant="ghost" 
            size="sm"
            className="text-[hsl(var(--selection-text))] hover:text-[hsl(var(--selection-text))/80]"
          >
            <Download className="h-4 w-4 mr-2" />
            {t('cloud_drive.selection.selected_actions.download')}
          </Button>
        )}

        {/* Common Actions */}
        <Button 
          variant="ghost" 
          size="sm"
          className="text-[hsl(var(--selection-text))] hover:text-[hsl(var(--selection-text))/80]"
        >
          <Copy className="h-4 w-4 mr-2" />
          {t('cloud_drive.selection.selected_actions.copy')}
        </Button>

        <Button 
          variant="ghost" 
          size="sm"
          className="text-[hsl(var(--selection-text))] hover:text-[hsl(var(--selection-text))/80]"
        >
          <FolderInput className="h-4 w-4 mr-2" />
          {t('cloud_drive.selection.selected_actions.move')}
        </Button>

        <Button 
          variant="ghost" 
          size="sm"
          className="text-[hsl(var(--selection-text))] hover:text-[hsl(var(--selection-text))/80]"
        >
          <Share2 className="h-4 w-4 mr-2" />
          {t('cloud_drive.selection.selected_actions.share')}
        </Button>

        <Button 
          variant="ghost" 
          size="sm"
          className="text-[hsl(var(--selection-text))] hover:text-[hsl(var(--selection-text))/80]"
        >
          <Star className="h-4 w-4 mr-2" />
          {t('cloud_drive.selection.selected_actions.favorite')}
        </Button>

        {/* Destructive Actions */}
        <Separator orientation="vertical" className="h-4" />
        
        <Button 
          variant="ghost" 
          size="sm"
          className="text-destructive hover:text-destructive/80"
        >
          <Trash2 className="h-4 w-4 mr-2" />
          {t('cloud_drive.selection.selected_actions.delete')}
        </Button>
      </div>
    </motion.div>
  );
};

export default BatchOperationsBar;