/**
 * @file src/utils/validation.ts
 * @description Common validation utilities
 * @version 1.0.0
 */

import { logger } from './logger';

/**
 * Validates if a string is a valid UUID v4
 * @param uuid - String to validate
 * @returns boolean indicating if string is valid UUID
 */
export const isValidUUID = (uuid: string | null | undefined): boolean => {
  try {
    if (!uuid) return false;
    
    // Less strict UUID pattern that accepts both v4 and other formats
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const isValid = uuidRegex.test(uuid);
    
    logger.debug('UUID validation', {
      component: 'validation',
      data: { 
        uuid,
        isValid,
      }
    });

    return isValid;
  } catch (error) {
    logger.error('UUID validation error:', {
      component: 'validation',
      error,
      data: { uuid }
    });
    return false;
  }
};

/**
 * Validates folder/file name for invalid characters
 * @param name - Name to validate
 * @returns boolean indicating if name is valid
 */
export const isValidName = (name: string): boolean => {
  try {
    if (!name || typeof name !== 'string') return false;
    
    // Check for invalid characters
    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/;
    const isValid = !invalidChars.test(name) && name.trim().length > 0 && name.length <= 255;
    
    logger.debug('Name validation', {
      component: 'validation',
      data: { 
        name,
        isValid,
        length: name.length 
      }
    });

    return isValid;
  } catch (error) {
    logger.error('Name validation error:', {
      component: 'validation',
      error,
      data: { name }
    });
    return false;
  }
};

/**
 * Validates path segments
 * @param path - Array of path segments to validate
 * @returns boolean indicating if path is valid
 */
export const isValidPath = (path: string[]): boolean => {
  try {
    if (!Array.isArray(path)) return false;
    
    const isValid = path.every((pathSegment: string) => 
      pathSegment === 'root' || isValidUUID(pathSegment)
    );
    
    logger.debug('Path validation', {
      component: 'validation',
      data: { 
        path,
        isValid,
        segmentCount: path.length 
      }
    });

    return isValid;
  } catch (error) {
    logger.error('Path validation error:', {
      component: 'validation',
      error,
      data: { path }
    });
    return false;
  }
};

/**
 * Validates drive ID
 * @param driveId - Drive ID to validate
 * @returns boolean indicating if drive ID is valid
 */
export const isValidDriveId = (driveId: string | null | undefined): boolean => {
  try {
    if (!driveId) return false;
    return isValidUUID(driveId);
  } catch (error) {
    logger.error('Drive ID validation error:', {
      component: 'validation',
      error,
      data: { driveId }
    });
    return false;
  }
};

export const validation = {
  isValidUUID,
  isValidName,
  isValidPath,
  isValidDriveId
};

export default validation;