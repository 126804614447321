/**
 * @file src/components/data/DataSplitView/CategorySidebar/DeviceList.tsx
 * @description Device list component with loading states
 * @version 1.0.0
 */

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/utils/utils';
import { DeviceWithDrives } from '@/types/root-drive.types';

interface DeviceListProps {
  devices: DeviceWithDrives[];
  expandedDevice: string | null;
  isLoading?: boolean;
  onDeviceSelect: (deviceId: string) => void;
  className?: string;
}

export const DeviceList: React.FC<DeviceListProps> = ({
  devices,
  expandedDevice,
  isLoading,
  onDeviceSelect,
  className
}) => {
  if (isLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={cn("ml-8 mt-1 p-2", className)}
      >
        <div className="animate-pulse space-y-2">
          {[1, 2, 3].map((i) => (
            <div key={i} className="h-8 bg-gray-200 dark:bg-gray-800 rounded-lg" />
          ))}
        </div>
      </motion.div>
    );
  }

  return (
    <AnimatePresence>
      {devices.map((device) => (
        <motion.div
          key={device.id}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={cn("ml-8 mt-1", className)}
        >
          <button
            onClick={() => onDeviceSelect(device.id)}
            className={cn(
              "flex items-center w-full px-3 py-2",
              "rounded-lg text-sm",
              expandedDevice === device.id
                ? "bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400"
                : "text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800",
              "transition-colors duration-200"
            )}
          >
            <div className="flex items-center space-x-2">
              <div className={cn(
                "w-2 h-2 rounded-full",
                device.status === 'online' 
                  ? "bg-green-400" 
                  : "bg-gray-400"
              )} />
              <span>{device.name}</span>
            </div>
          </button>
        </motion.div>
      ))}
    </AnimatePresence>
  );
};

export default DeviceList;