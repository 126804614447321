/**
 * @file: services/media.service.ts
 * @description: Service for handling media operations
 * @version: 1.0.0
 */

import api from "@/utils/axios";
import { API_BASE_URL } from "../config/constants";

export interface MediaListParams {
  drive_id: string;
  media_types?: string[];
  cursor?: string | null;
  page_size?: number;
  date_range?: {
    start: string;
    end: string;
  } | null;
  favorites_only?: boolean;
}

export interface MediaItem {
  id: string;
  file_id: string;
  drive_id: string;
  name: string;
  path: string;
  folder_id: string | null;
  size: number;
  mime_type: string | null;
  media_type: string;
  created_at: string;
  modified_at: string;
  capture_date: string | null;
  uploaded_at: string;
  has_thumbnail: boolean;
  is_encrypted: boolean;
  user_id: string;
  is_favorite: boolean;
  is_shared: boolean;
  type: string;
  dimensions?: {
    width: number;
    height: number;
  };
  duration?: number;
  media_info?: any;
  encrypted_metadata?: string;
  metadata_iv?: string;
}

export interface MediaListResponse {
  items: MediaItem[];
  has_more: boolean;
  next_cursor: string | null;
  total: number;
  count: number;
}

export interface MediaStats {
  total_items: number;
  image_count: number;
  video_count: number;
  favorites_count: number;
  date_range: {
    oldest: string | null;
    newest: string | null;
  };
}

export interface MediaContentRequest {
  url: string;
  file_id: string;
  drive_id: string;
  is_thumbnail?: boolean;
}

class MediaService {
  /**
   * List media files with filtering and pagination
   */
  async listMediaFiles(params: MediaListParams): Promise<MediaListResponse> {
    const response = await api.post<MediaListResponse>(`/media/list`, params);
    return response.data;
  }

  /**
   * Get statistics about media in a drive
   */
  async getMediaStats(driveId: string): Promise<MediaStats> {
    const response = await api.get<MediaStats>(`/media/stats/${driveId}`);
    return response.data;
  }

  /**
   * Rebuild the media index for a drive (admin only)
   */
  async rebuildMediaIndex(driveId: string): Promise<{ message: string }> {
    const response = await api.post<{ message: string }>(`/media/rebuild/${driveId}`);
    return response.data;
  }

  /**
   * Proxy encrypted media content through the backend
   */
  async getMediaContent(params: MediaContentRequest): Promise<Blob> {
    const response = await api.post(`/media/content`, params, {
      responseType: 'blob'
    });
    return response.data;
  }

  /**
   * Get a safely proxied URL for an image
   */
  getMediaSrc(mediaItem: MediaItem, thumbnail: boolean = false): string {
    const storageKey = thumbnail ? 
      `${mediaItem.drive_id}/${mediaItem.file_id}/thumbnail` : 
      `${mediaItem.drive_id}/${mediaItem.file_id}`;
    
    // Use the full API_BASE_URL since this returns a URL string, not making an API call
    return `${API_BASE_URL}/media/content?path=${encodeURIComponent(storageKey)}&file_id=${mediaItem.file_id}&drive_id=${mediaItem.drive_id}&is_thumbnail=${thumbnail}`;
  }
}

export const mediaService = new MediaService();
export default mediaService;