/**
 * @file src/layouts/ghost/components/create-core/steps/StepPassword.tsx
 * @description Password creation with power-ups and shield energy
 * @version 1.0.0
 */

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Eye,
  EyeOff,
  Shield,
  Zap,
  Check,
  AlertCircle,
  Lock,
  Power
} from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface StepPasswordProps {
  formData: {
    password: string;
    confirmPassword: string;
    [key: string]: any;
  };
  setFormData: (data: any) => void;
  onNext: () => void;
  onBack: () => void;
}

// Power-ups (password requirements)
const POWER_UPS = [
  {
    id: "length",
    icon: Shield,
    name: "Shield Core",
    requirement: /.{6,}/,
    description: "At least 6 characters",
    points: 20,
  },
  {
    id: "uppercase",
    icon: Power,
    name: "Upper Energy",
    requirement: /[A-Z]/,
    description: "One uppercase letter",
    points: 20,
  },
  {
    id: "lowercase",
    icon: Power,
    name: "Lower Energy",
    requirement: /[a-z]/,
    description: "One lowercase letter",
    points: 20,
  },
  {
    id: "number",
    icon: Zap,
    name: "Numeric Boost",
    requirement: /[0-9]/,
    description: "One number",
    points: 20,
  },
  {
    id: "special",
    icon: Lock,
    name: "Special Force",
    requirement: /[!@#$%^&*(),.?":{}|<>]/,
    description: "One special character",
    points: 20,
  },
];

export const StepPassword: React.FC<StepPasswordProps> = ({
  formData,
  setFormData,
  onNext,
  onBack,
}) => {
  const { t } = useGhostTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [powerUps, setPowerUps] = useState(
    POWER_UPS.map((up: any) => ({ ...up, active: false }))
  );
  const [shieldEnergy, setShieldEnergy] = useState(0);

  // Update power-ups and shield energy when password changes
  useEffect(() => {
    const updatedPowerUps = POWER_UPS.map((up: any) => ({
      ...up,
      active: up.requirement.test(formData.password)
    }));

    setPowerUps(updatedPowerUps);

    const energy = updatedPowerUps
      .filter((up: any) => up.active)
      .reduce((sum: any, up: any) => sum + up.points, 0);

    setShieldEnergy(energy);

    logger.debug('Password power-ups updated', {
      activePowerUps: updatedPowerUps.filter((up: any) => up.active).length,
      shieldEnergy: energy
    });
  }, [formData.password]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (shieldEnergy < 100) {
      setError(t('create.password.errors.insufficient_power') as string);
      return;
    }

    if (formData?.password !== formData.confirmPassword) {
      setError(t('create.password.errors.mismatch') as string);
      return;
    }

    logger.debug('Password validated successfully', {
      shieldEnergy,
      powerUpsActive: powerUps.filter((up: any) => up.active).length
    });
    
    onNext();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center space-y-2"
      >
        <h2 className="text-xl font-bold text-[hsl(var(--ghost-foreground))]">
          {t('create.password.title') as React.ReactNode}
        </h2>
        <p className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
          {t('create.password.description') as React.ReactNode}
        </p>
      </motion.div>

      {/* Password Input */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1 }}
        className="space-y-4"
      >
        {/* Main Password */}
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            value={formData.password}
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
              setError("");
            }}
            className={cn(
              "w-full px-4 py-3 pr-10",
              "bg-[hsl(var(--ghost-surface))]",
              "border border-[hsl(var(--ghost-accent))/0.2]",
              "rounded-xl",
              "text-[hsl(var(--ghost-foreground))]",
              "placeholder:text-[hsl(var(--ghost-foreground-muted))]",
              "focus:outline-none focus:ring-2",
              "focus:ring-[hsl(var(--ghost-accent))]",
              "transition-all duration-200",
              error && "border-red-500 focus:ring-red-500"
            )}
            placeholder={t('create.password.password_placeholder') as string}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 
                     text-[hsl(var(--ghost-foreground-muted))] 
                     hover:text-[hsl(var(--ghost-foreground))]
                     transition-colors duration-200"
          >
            <AnimatePresence mode="wait">
              {showPassword ? (
                <motion.div
                  key="hide"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <EyeOff className="w-5 h-5" />
                </motion.div>
              ) : (
                <motion.div
                  key="show"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <Eye className="w-5 h-5" />
                </motion.div>
              )}
            </AnimatePresence>
          </button>
        </div>

        {/* Shield Energy Bar */}
        <div className="space-y-2">
          <div className="flex items-center justify-between text-sm">
            <span className="text-[hsl(var(--ghost-foreground-muted))]">
              {t('create.password.shield_energy') as React.ReactNode}
            </span>
            <motion.span
              className="font-medium text-[hsl(var(--ghost-accent))]"
              key={shieldEnergy}
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
            >
              {shieldEnergy}%
            </motion.span>
          </div>
          <div className="relative h-2 bg-[hsl(var(--ghost-surface))] rounded-full overflow-hidden">
            <motion.div
              className="absolute inset-y-0 left-0 rounded-full"
              style={{
                background: `linear-gradient(to right, 
                  hsl(var(--ghost-accent)), 
                  hsl(var(--ghost-accent-secondary))
                )`,
              }}
              initial={{ width: 0 }}
              animate={{ width: `${shieldEnergy}%` }}
              transition={{ type: "spring", damping: 20 }}
            />
            {/* Energy particles */}
            {shieldEnergy > 0 && (
              <motion.div
                className="absolute inset-0"
                initial={false}
                animate={{
                  background: [
                    "radial-gradient(circle at 30% 50%, hsl(var(--ghost-accent)/0.5) 0%, transparent 50%)",
                    "radial-gradient(circle at 70% 50%, hsl(var(--ghost-accent)/0.5) 0%, transparent 50%)",
                    "radial-gradient(circle at 30% 50%, hsl(var(--ghost-accent)/0.5) 0%, transparent 50%)",
                  ],
                }}
                transition={{ duration: 2, repeat: Infinity }}
              />
            )}
          </div>
        </div>

        {/* Power-ups Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          {powerUps.map((powerUp, index) => {
            const Icon = powerUp.icon;

            return (
              <motion.div
                key={powerUp.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: { delay: index * 0.1 },
                }}
                className={cn(
                  "p-3 rounded-lg",
                  "border",
                  "transition-all duration-300",
                  powerUp.active
                    ? "bg-[hsl(var(--ghost-accent))/0.1] border-[hsl(var(--ghost-accent))]"
                    : "bg-[hsl(var(--ghost-surface))] border-[hsl(var(--ghost-accent))/0.2]"
                )}
              >
                <div className="flex items-center space-x-3">
                  <div
                    className={cn(
                      "p-1.5 rounded-lg",
                      "transition-colors duration-300",
                      powerUp.active
                        ? "bg-[hsl(var(--ghost-accent))]"
                        : "bg-[hsl(var(--ghost-surface))]"
                    )}
                  >
                    <Icon
                      className={cn(
                        "w-4 h-4",
                        "transition-colors duration-300",
                        powerUp.active
                          ? "text-white"
                          : "text-[hsl(var(--ghost-foreground-muted))]"
                      )}
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between">
                      <p
                        className={cn(
                          "text-sm font-medium",
                          "transition-colors duration-300",
                          powerUp.active
                            ? "text-[hsl(var(--ghost-accent))]"
                            : "text-[hsl(var(--ghost-foreground))]"
                        )}
                      >
                        {powerUp.name}
                      </p>
                      {powerUp.active && (
                        <Check className="w-4 h-4 text-[hsl(var(--ghost-accent))]" />
                      )}
                    </div>
                    <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                      {powerUp.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>

        {/* Confirm Password */}
        <div className="relative mt-4">
          <input
            type={showConfirmPassword ? "text" : "password"}
            value={formData.confirmPassword}
            onChange={(e) => {
              setFormData({ ...formData, confirmPassword: e.target.value });
              setError("");
            }}
            className={cn(
              "w-full px-4 py-3 pr-10",
              "bg-[hsl(var(--ghost-surface))]",
              "border border-[hsl(var(--ghost-accent))/0.2]",
              "rounded-xl",
              "text-[hsl(var(--ghost-foreground))]",
              "placeholder:text-[hsl(var(--ghost-foreground-muted))]",
              "focus:outline-none focus:ring-2",
              "focus:ring-[hsl(var(--ghost-accent))]",
              "transition-all duration-200",
              error && "border-red-500 focus:ring-red-500"
            )}
            placeholder={t('create.password.confirm_placeholder') as string}
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 
                     text-[hsl(var(--ghost-foreground-muted))] 
                     hover:text-[hsl(var(--ghost-foreground))]
                     transition-colors duration-200"
          >
            <AnimatePresence mode="wait">
              {showConfirmPassword ? (
                <motion.div
                  key="hide"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <EyeOff className="w-5 h-5" />
                </motion.div>
              ) : (
                <motion.div
                  key="show"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <Eye className="w-5 h-5" />
                </motion.div>
              )}
            </AnimatePresence>
          </button>
        </div>

        {/* Error Message */}
        <AnimatePresence mode="wait">
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="flex items-center space-x-2 text-red-500"
            >
              <AlertCircle className="w-4 h-4" />
              <span className="text-sm">{error}</span>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Action Buttons */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="flex justify-between pt-4"
        >
          <motion.button
            type="button"
            onClick={onBack}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={cn(
              "px-6 py-2 rounded-lg",
              "bg-[hsl(var(--ghost-surface))]",
              "border border-[hsl(var(--ghost-accent))/0.2]",
              "text-[hsl(var(--ghost-foreground))]",
              "text-sm font-medium",
              "transition-colors duration-200",
              "hover:bg-[hsl(var(--ghost-accent))/0.1]"
            )}
          >
            {t('common.back') as React.ReactNode}
          </motion.button>

          <motion.button
            type="submit"
            disabled={shieldEnergy < 100}
            whileHover={shieldEnergy >= 100 ? { scale: 1.02 } : {}}
            whileTap={shieldEnergy >= 100 ? { scale: 0.98 } : {}}
            className={cn(
              "px-6 py-2 rounded-lg",
              "text-sm font-medium",
              "transition-all duration-200",
              "relative overflow-hidden",
              shieldEnergy >= 100
                ? "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))] text-white"
                : "bg-[hsl(var(--ghost-surface))] text-[hsl(var(--ghost-foreground-muted))]"
            )}
          >
            {shieldEnergy >= 100 ? (
              <motion.span
                initial={false}
                animate={{
                  background: [
                    "radial-gradient(circle at left, hsl(var(--ghost-accent-loud)) 0%, transparent 50%)",
                    "radial-gradient(circle at right, hsl(var(--ghost-accent-loud)) 0%, transparent 50%)",
                    "radial-gradient(circle at left, hsl(var(--ghost-accent-loud)) 0%, transparent 50%)",
                  ],
                }}
                transition={{ duration: 2, repeat: Infinity }}
                className="absolute inset-0 opacity-50"
              />
            ) : null}
            <span className="relative">
              {shieldEnergy >= 100 
                ? t('common.continue') as React.ReactNode
                : t('create.password.power_needed') as React.ReactNode
              }
            </span>
          </motion.button>
        </motion.div>
      </motion.div>
    </form>
  );
};