/**
 * @file src/services/cloud-service.ts
 * @description Cloud service API integration
 * @version 1.1.0
 * @created 2024-12-03
 */

import type { CloudService } from '@/types/cloud-services';
import { getAuthHeaders as apiAuthHeaders } from '@/utils/api-helpers';
import { authService } from './auth.service';

const BASE_URL = '/api/v1/cloud-services';

const getAuthHeaders = () => {
  const headers: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  try {
    const { accessToken } = authService.getStoredToken();
    if (accessToken && authService.isValidToken(accessToken)) {
      headers['Authorization'] = accessToken;
    }
  } catch (error) {
    console.error('Error getting auth headers:', error);
  }
  return headers;
};

export const cloudServiceApi = {
  getServices: async (): Promise<CloudService[]> => {
    try {
      const headers = getAuthHeaders();
      console.log('Fetching services with headers:', headers);

      const response = await fetch(`${BASE_URL}/services`, {
        method: 'GET',
        headers: headers as HeadersInit as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Services API Error:', errorText);
        throw new Error(errorText);
      }

      const data = await response.json();
      console.log('Services response:', data);
      return data;
    } catch (error) {
      console.error('Error fetching services:', error);
      throw error;
    }
  },

  initiateOAuth: async (serviceType: string): Promise<{ auth_url: string }> => {
    const response = await fetch(`${BASE_URL}/services/oauth/initiate?service_type=${serviceType}`, {
      method: 'GET',
      headers: apiAuthHeaders() as HeadersInit
    });
    if (!response.ok) {
      throw new Error('Failed to connect service');
    }
    return response.json();
  },

  disconnectService: async (serviceId: string): Promise<void> => {
    const headers = getAuthHeaders();
    const response = await fetch(`${BASE_URL}/services/${serviceId}`, {
      method: 'DELETE',
      headers: apiAuthHeaders() as HeadersInit
    });
    if (!response.ok) {
      throw new Error('Failed to disconnect service');
    }
  },

  triggerSync: async (serviceId: string): Promise<{ success: boolean }> => {
    const headers = getAuthHeaders();
    const response = await fetch(`${BASE_URL}/services/${serviceId}/sync`, {
      method: 'POST',
      headers,
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to trigger sync');
    }
    return response.json();
  },

  /**
   * Updated to return both `status` and `lastSync`.
   * Adjust the actual response fields as needed.
   */
  getSyncStatus: async (serviceId: string): Promise<{ status: string; lastSync?: string }> => {
    const headers = getAuthHeaders();
    const response = await fetch(`${BASE_URL}/services/${serviceId}/sync/status`, {
      method: 'GET',
      headers,
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to get sync status');
    }
    return response.json();
  },

  verifyOAuth: async (code: string, state: string) => {
    try {
      const response = await fetch('/api/cloud-service/services/oauth/callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, state }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (err) {
      console.error('verifyOAuth error:', err);
      return { success: false, error: { message: (err as Error).message } };
    }
  },
};
