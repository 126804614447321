/**
 * @file src/components/devices/DeviceDetailView.tsx
 * @description File browser view for a specific device
 * @version 1.0.0
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, Computer } from 'lucide-react';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { CloudDriveLayout } from '@/components/cloud-drive/layout/CloudDriveLayout';
import { FileBrowserView } from '@/components/cloud-drive/browser/FileBrowserView';
import { Button } from '@/components/ui/button';
import { logger } from '@/utils/logger';

interface DeviceDetailViewProps {
  deviceId: string;
  onBack: () => void;
  className?: string;
}

export const DeviceDetailView: React.FC<DeviceDetailViewProps> = ({
  deviceId,
  onBack,
  className
}) => {
  const { t } = useTranslation();
  const { devices, setActiveDriveId } = useRootDrive();
  
  // Find the device
  const device = devices.find((d: any) => d.id === deviceId);
  
  // Get the default drive for this device
  const deviceDrives = device?.drives || [];
  const defaultDrive = deviceDrives.length > 0 ? deviceDrives[0] : null;
  
  useEffect(() => {
    // Set the active drive ID to the device's main drive
    if (defaultDrive) {
      logger.debug('Setting active drive for device', {
        component: 'DeviceDetailView',
        deviceId,
        driveId: defaultDrive.id
      });
      setActiveDriveId(defaultDrive.id);
    }
  }, [deviceId, defaultDrive, setActiveDriveId]);
  
  if (!device) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-6">
        <div className="rounded-full bg-red-100 dark:bg-red-900/20 p-3 mb-4">
          <Computer className="h-6 w-6 text-red-600 dark:text-red-400" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
          {t('devices.device_not_found')}
        </h3>
        <Button onClick={onBack} variant="outline" className="mt-4">
          <ArrowLeft className="h-4 w-4 mr-2" />
          {t('common.back')}
        </Button>
      </div>
    );
  }
  
  return (
    <div className="h-full flex flex-col">
      {/* Device header */}
      <div className="flex items-center justify-between bg-white dark:bg-gray-900 p-4 border-b border-gray-100 dark:border-gray-800">
        <div className="flex items-center">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={onBack}
            className="mr-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            {t('common.back')}
          </Button>
          
          <div>
            <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
              {device.name}
            </h2>
            <div className="flex items-center">
              <span className={`inline-block w-2 h-2 rounded-full mr-2 ${
                device.status === 'online' ? "bg-green-500" : "bg-gray-400"
              }`}></span>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {t(`devices.status.${device.status}`)}
              </span>
            </div>
          </div>
        </div>
      </div>
      
      {/* File browser for the device */}
      {defaultDrive ? (
        <div className="flex-1">
          <CloudDriveLayout>
            <FileBrowserView
              folderId={null} // Start at root of the device drive
              viewType="default"
            />
          </CloudDriveLayout>
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center p-6">
            <div className="rounded-full bg-yellow-100 dark:bg-yellow-900/20 p-3 mx-auto mb-4 w-fit">
              <Computer className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
              {t('devices.no_drives')}
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              {t('devices.no_drives_description')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceDetailView;