/**
 * @file src/components/cloud-drive/preview/FilePreview.tsx
 * @description File preview component with support for various file types
 * @version 1.0.0
 * @created 2024-12-21
 */

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  X, 
  Download, 
  Share2,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

import { ImagePreview } from './types/ImagePreview';
import { VideoPreview } from './types/VideoPreview';
import { DocumentPreview } from './types/DocumentPreview';

import type { DriveFile } from '@/types/cloud-drive.types';

interface FilePreviewProps {
  file: DriveFile;
  isOpen: boolean;
  onClose: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
  hasPrevious?: boolean;
  hasNext?: boolean;
}

interface CustomDialogContentProps extends React.ComponentProps<typeof DialogContent> {
  closeButton?: boolean;
}

export const FilePreview: React.FC<FilePreviewProps> = ({
  file,
  isOpen,
  onClose,
  onPrevious,
  onNext,
  hasPrevious,
  hasNext
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Get preview component based on file type
  const getPreviewComponent = () => {
    const type = file.mimeType.split('/')[0];
    
    switch (type) {
      case 'image':
        return (
          <ImagePreview
            file={file}
            onLoad={() => setIsLoading(false)}
            onError={(err) => {
              setError(err.message);
              setIsLoading(false);
            }}
          />
        );
      case 'video':
        return (
          <VideoPreview
            file={file}
            onLoad={() => setIsLoading(false)}
            onError={(err) => {
              setError(err.message);
              setIsLoading(false);
            }}
          />
        );
      case 'text':
      case 'application/pdf':
        return (
          <DocumentPreview
            file={file}
            onLoad={() => setIsLoading(false)}
            onError={(err) => {
              setError(err.message);
              setIsLoading(false);
            }}
          />
        );
      default:
        return (
          <div className="flex flex-col items-center justify-center p-8">
            <p className="text-gray-500 dark:text-gray-400">
              {t('preview.no_preview')}
            </p>
            <Button
              variant="outline"
              className="mt-4"
              onClick={() => {
                // Implement download
              }}
            >
              <Download className="h-4 w-4 mr-2" />
              {t('preview.download')}
            </Button>
          </div>
        );
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className="max-w-7xl w-full h-[90vh] p-0 overflow-hidden"
      >
        {/* Header */}
        <div className="absolute top-0 left-0 right-0 z-10 bg-gradient-to-b from-black/50 to-transparent">
          <div className="flex items-center justify-between p-4">
            <h3 className="text-lg font-medium text-white">
              {file.name}
            </h3>
            <div className="flex items-center space-x-2">
              <Button
                variant="ghost"
                size="sm"
                className="text-white hover:bg-white/20"
                onClick={() => {
                  // Implement share
                }}
              >
                <Share2 className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                className="text-white hover:bg-white/20"
                onClick={() => {
                  // Implement download
                }}
              >
                <Download className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                className="text-white hover:bg-white/20"
                onClick={onClose}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>

        {/* Preview Content */}
        <div className="relative w-full h-full flex items-center justify-center">
          {/* Navigation Buttons */}
          {hasPrevious && (
            <Button
              variant="ghost"
              size="lg"
              className="absolute left-4 text-white hover:bg-white/20"
              onClick={onPrevious}
            >
              <ChevronLeft className="h-6 w-6" />
            </Button>
          )}
          {hasNext && (
            <Button
              variant="ghost"
              size="lg"
              className="absolute right-4 text-white hover:bg-white/20"
              onClick={onNext}
            >
              <ChevronRight className="h-6 w-6" />
            </Button>
          )}

          {/* Preview */}
          <AnimatePresence mode="wait">
            {isLoading ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex flex-col items-center justify-center"
              >
                <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent" />
              </motion.div>
            ) : error ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="text-red-500 dark:text-red-400"
              >
                {error}
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="w-full h-full"
              >
                {getPreviewComponent()}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FilePreview;