/**
 * @file src/components/cloud-drive/items/components/StatusBadges.tsx
 * @description Item status badges component (favorite, shared, etc.)
 * @version 1.0.0
 */

import React from 'react';
import { Star, Share2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { 
  Tooltip,
  TooltipContent,
  TooltipTrigger 
} from '@/components/ui/tooltip';
import { cn } from '@/utils/utils';

interface StatusBadgesProps {
  isFavorite: boolean;
  isShared: boolean;
  onToggleFavorite?: (e: React.MouseEvent) => void;
  className?: string;
}

export const StatusBadges: React.FC<StatusBadgesProps> = ({
  isFavorite,
  isShared,
  onToggleFavorite,
  className
}) => {
  return (
    <div className={cn("flex items-center gap-1", className)}>
      {/* Always show star icon, but with filled/outlined styles based on favorite status */}
      <Tooltip>
        <TooltipTrigger asChild>
          <Button 
            variant="ghost" 
            size="sm"
            className={cn(
              "h-6 w-6 p-0", 
              isFavorite 
                ? "hover:bg-amber-100/50 dark:hover:bg-amber-900/50" 
                : "hover:bg-gray-100/50 dark:hover:bg-gray-800/50"
            )}
            onClick={(e) => {
              e.stopPropagation();
              onToggleFavorite?.(e);
            }}
          >
            <Star 
              className={cn(
                "h-3.5 w-3.5", 
                isFavorite 
                  ? "fill-amber-500 text-amber-500" 
                  : "fill-transparent text-gray-400 dark:text-gray-500"
              )} 
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{isFavorite ? 'Starred' : 'Add to Starred'}</TooltipContent>
      </Tooltip>
      
      {isShared && (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="rounded-full p-1.5 bg-blue-100/50 dark:bg-blue-900/50">
              <Share2 className="h-3.5 w-3.5 text-blue-500" />
            </div>
          </TooltipTrigger>
          <TooltipContent>Shared</TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};

export default StatusBadges;