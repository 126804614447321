/**
 * @file src/components/data/DataSplitView/CategorySidebar/index.tsx
 * @description Main category sidebar with improved navigation
 * @version 1.2.0
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCloudDrive } from '@/context/cloud-drive';
import { cn } from '@/utils/utils';
import CategoryButton from './CategoryButton';
import DeviceList from './DeviceList';
import { logger } from '@/utils/logger';
import type { CategorySidebarProps, CategoryId } from '../types';

export const CategorySidebar: React.FC<CategorySidebarProps> = ({
  activeCategory,
  onCategoryChange,
  selectedItemId,
  onDeviceSelect,
  categories,
  className
}) => {
  const { t } = useTranslation();
  const { encryptedDrive } = useCloudDrive();
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);

  const handleCategoryClick = (categoryId: CategoryId) => {
    logger.debug('Category click', {
      component: 'CategorySidebar',
      data: {
        categoryId,
        currentExpanded: expandedCategory,
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        currentCategory: activeCategory
      }
    });

    // Handle devices expansion
    if (categories.find((c: any) => c.id === categoryId)?.devices) {
      setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
    }

    onCategoryChange(categoryId as CategoryId);
  };

  // Determine which category should be shown as active
  const effectiveCategory = encryptedDrive.isEncryptedMode ? 'encryptedDrives' : activeCategory;

  return (
    <div className={cn(
      "flex flex-col h-full",
      "bg-white dark:bg-gray-900",
      "border-r border-gray-100 dark:border-gray-800",
      className
    )}>
      {/* Header */}
      <div className="p-4 border-b border-gray-100 dark:border-gray-800">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
          {t('data_view.title')}
        </h2>
      </div>

      {/* Categories */}
      <nav className="flex-1 overflow-y-auto p-2 space-y-1">
        {categories.map((category) => (
          <div key={category.id}>
            <CategoryButton
              category={category}
              isActive={effectiveCategory === category.id}
              onClick={() => handleCategoryClick(category.id)}
              hasDevices={!!category.devices?.length}
              isExpanded={expandedCategory === category.id}
            />
            
            {/* Device list */}
            {expandedCategory === category.id && category.devices && (
              <DeviceList
                devices={category.devices}
                expandedDevice={selectedItemId || null}
                isLoading={category.isLoading}
                onDeviceSelect={(deviceId) => onDeviceSelect?.(deviceId)}
              />
            )}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default CategorySidebar;