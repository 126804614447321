/**
 * @file src/app/ghost/components/sidebar/components/MobileAppsSection.tsx
 * @description Ultra-compact mobile apps promo section with enhanced animations
 * @version 2.1.0
 * @created 2025-04-05
 * @dependencies framer-motion, lucide-react
 */

import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Smartphone, Sparkles } from 'lucide-react';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { MobileAppsPromoDialog } from '../../mobile-apps/MobileAppsPromoDialog';

interface MobileAppsSectionProps {
  isCollapsed: boolean;
}

export const MobileAppsSection: React.FC<MobileAppsSectionProps> = ({
  isCollapsed
}) => {
  const { t } = useGhostTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  
  // Log component render
  React.useEffect(() => {
    logger.debug('Rendering MobileAppsSection', {
      isCollapsed
    });
  }, [isCollapsed]);

  const handleOpenDialog = useCallback(() => {
    logger.debug('Mobile apps promo section clicked');
    setDialogOpen(true);
  }, []);

  // Collapsed view - Ultra-compact with enhanced animations
  if (isCollapsed) {
    return (
      <div className="px-1 mb-2">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleOpenDialog}
          className="w-full h-12 rounded-lg bg-gradient-to-br from-[hsl(var(--ghost-accent))]/10 to-transparent relative overflow-hidden"
        >
          {/* Fast animated pulse effect */}
          <motion.div 
            className="absolute inset-0 bg-[hsl(var(--ghost-accent))]"
            animate={{ 
              opacity: [0, 0.1, 0],
              scale: [0.8, 1.1, 0.8]
            }}
            transition={{
              duration: 1.2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            style={{ filter: 'blur(10px)' }}
          />
          
          {/* High-contrast attention dot */}
          <motion.div 
            className="absolute top-1 right-1 w-1.5 h-1.5 rounded-full bg-[hsl(var(--ghost-accent))]"
            animate={{ 
              opacity: [0.6, 1, 0.6],
              scale: [0.8, 1.3, 0.8],
            }}
            transition={{
              duration: 0.8,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
          
          {/* Content with fast animations */}
          <div className="relative z-10 h-full flex flex-col items-center justify-center">
            <Smartphone className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
            
            <motion.div
              animate={{ 
                y: [1, -1, 1],
                scale: [0.95, 1.05, 0.95]
              }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="mt-0.5"
            >
              <Sparkles className="h-3 w-3 text-[hsl(var(--ghost-accent))]" />
            </motion.div>
          </div>
        </motion.button>

        <MobileAppsPromoDialog 
          open={dialogOpen}
          onOpenChange={setDialogOpen}
        />
      </div>
    );
  }

  // Expanded view - Compact with enhanced animations
  return (
    <div className="px-1 mb-2">
      <motion.button
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
        onClick={handleOpenDialog}
        className="w-full rounded-lg relative overflow-hidden"
      >
        <div className="p-2.5 rounded-lg bg-gradient-to-br from-[hsl(var(--ghost-accent))]/10 to-transparent relative overflow-hidden">
          {/* Fast moving background particle effect */}
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-3 h-8 bg-[hsl(var(--ghost-accent))]"
              style={{ 
                opacity: 0.03,
                top: '50%',
                left: `${30 + i * 20}%`,
                filter: 'blur(8px)',
                transformOrigin: 'center'
              }}
              animate={{
                y: [20, -20],
                rotate: [0, 10, -10, 0],
                opacity: [0.02, 0.06, 0.02]
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "linear",
                delay: i * 0.3
              }}
            />
          ))}
          
          {/* Cyberpunk scanning line effect */}
          <motion.div
            className="absolute top-0 right-0 w-[150%] h-[1px] bg-[hsl(var(--ghost-accent))]"
            style={{ 
              opacity: 0.2,
              transform: 'rotate(-30deg)',
              transformOrigin: 'center'
            }}
            animate={{
              y: [-20, 40]
            }}
            transition={{
              duration: 1.2,
              repeat: Infinity,
              ease: "linear"
            }}
          />
          
          {/* Content */}
          <div className="relative z-10">
            {/* Header Row with enhanced animation */}
            <div className="flex items-center justify-between mb-1.5">
              <motion.div 
                className="flex items-center gap-1.5"
                animate={{ 
                  x: [-1, 1, -1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <Smartphone className="h-3.5 w-3.5 text-[hsl(var(--ghost-accent))]" />
                <span className="text-xs font-medium text-[hsl(var(--ghost-foreground))]">
                  {t('mobileApps.title', 'Mobile Apps')}
                </span>
              </motion.div>
              
              <motion.div
                animate={{ 
                  opacity: [0.7, 1, 0.7],
                  scale: [0.95, 1.05, 0.95]
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className="px-1.5 py-0.5 rounded-full text-[10px] bg-[hsl(var(--ghost-accent))]/10 text-[hsl(var(--ghost-accent))]"
              >
                {t('mobileApps.comingSoon', 'Q2/2025')}
              </motion.div>
            </div>
            
            {/* Compact app buttons */}
            <div className="flex gap-1.5 mb-1.5">
              <motion.div 
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="flex-1 h-4 rounded-md bg-[hsl(var(--ghost-surface))]/70 flex items-center justify-center"
              >
                <span className="text-[9px] text-[hsl(var(--ghost-foreground-muted))]">Play Store</span>
              </motion.div>
              
              <motion.div 
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="flex-1 h-4 rounded-md bg-[hsl(var(--ghost-surface))]/70 flex items-center justify-center"
              >
                <span className="text-[9px] text-[hsl(var(--ghost-foreground-muted))]">App Store</span>
              </motion.div>
            </div>
            
            {/* Founders access banner with enhanced animation */}
            <motion.div
              animate={{ 
                boxShadow: [
                  '0 0 0px 0px hsla(var(--ghost-accent), 0)',
                  '0 0 5px 0px hsla(var(--ghost-accent), 0.5)',
                  '0 0 0px 0px hsla(var(--ghost-accent), 0)'
                ]
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="w-full py-0.5 rounded flex items-center justify-center gap-1 bg-gradient-to-r from-[hsl(var(--ghost-accent))]/10 to-[hsl(var(--ghost-accent-secondary))]/10"
            >
              <motion.div
                animate={{ rotate: [0, 180] }}
                transition={{
                  duration: 5,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                <Sparkles className="h-2.5 w-2.5 text-[hsl(var(--ghost-accent))]" />
              </motion.div>
              <span className="text-[9px] font-medium text-[hsl(var(--ghost-accent))]">
                {t('mobileApps.exclusiveAccess', 'FOUNDERS GET EARLY ACCESS')}
              </span>
            </motion.div>
          </div>
        </div>
      </motion.button>

      <MobileAppsPromoDialog 
        open={dialogOpen}
        onOpenChange={setDialogOpen}
      />
    </div>
  );
};

export default MobileAppsSection;