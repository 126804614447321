/**
 * @file src/components/ghost/common/background/effects/useScanLinesEffect.ts
 * @description Subtle cyberpunk scan lines effect
 * @version 1.0.0
 */

import { getAccentColor } from '../utils/colors';
import { CanvasDimensions } from '../utils/canvasHelpers';
import { logger } from '@/utils/logger';

export const useScanLinesEffect = (
  ctx: CanvasRenderingContext2D,
  dimensions: CanvasDimensions
) => {
  logger.debug('Scan lines effect initialized', { 
    component: 'useScanLinesEffect'
  });

  const render = () => {
    const time = Date.now() * 0.001;
    const scanHeight = 2;
    const scanGap = 4;
    const scanSpeed = 0.5;

    for (let y = 0; y < dimensions.height; y += scanGap) {
      const opacity = 0.03 + Math.sin(y * 0.1 + time * scanSpeed) * 0.02;
      ctx.fillStyle = getAccentColor(opacity);
      ctx.fillRect(0, y, dimensions.width, scanHeight);
    }
  };

  return {
    render,
    cleanup: () => {
      logger.debug('Scan lines effect cleaned up');
    }
  };
};