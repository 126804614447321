/**
 * @file src/components/data/DataSplitView/CategorySidebar/CategoryButton.tsx
 * @description Simplified category button component
 * @version 1.1.0
 */

import React from 'react';
import { ChevronRight } from 'lucide-react';
import { cn } from '@/utils/utils';
import type { Category } from '../types';

interface CategoryButtonProps {
  category: Category;
  isActive: boolean;
  onClick: () => void;
  hasDevices?: boolean;
  isExpanded?: boolean;
  className?: string;
}

export const CategoryButton: React.FC<CategoryButtonProps> = ({
  category,
  isActive,
  onClick,
  hasDevices,
  isExpanded,
  className
}) => {
  const Icon = category.icon;
  
  return (
    <button
      onClick={onClick}
      className={cn(
        "w-full px-3 py-2 rounded-lg",
        "flex items-center justify-between",
        "text-sm font-medium",
        "transition-colors duration-200",
        // Base styles
        "text-gray-700 dark:text-gray-300",
        "hover:bg-gray-50 dark:hover:bg-gray-800",
        // Active state
        isActive && "bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400",
        className
      )}
    >
      <div className="flex items-center space-x-3">
        <Icon className={cn(
          "h-4 w-4",
          isActive && "text-blue-600 dark:text-blue-400"
        )} />
        <span>{category.label}</span>
      </div>
      
      {hasDevices && (
        <ChevronRight 
          className={cn(
            "h-4 w-4 transition-transform duration-200",
            isExpanded && "rotate-90"
          )} 
        />
      )}
    </button>
  );
};

export default CategoryButton;