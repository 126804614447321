/**
 * @file src/components/encrypted-drive/media/EncryptedImageViewer.tsx
 * @description Main container for encrypted media viewing with loader and media type detection
 * @version 4.0.0
 * @created 2025-03-05
 * @updated 2025-03-30
 * @dependencies framer-motion, lucide-react
 */

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { motion } from 'framer-motion';
import { 
  X, Download, Shield, ChevronLeft, ChevronRight, 
  Share2
} from 'lucide-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { useCloudDrive } from '@/context/cloud-drive';
import { mediaCacheService } from '@/services/encrypted-drive/media/media-cache.service';
import { encryptionService } from '@/services/encrypted-drive/encryption-singleton';
import { authService } from '@/services/auth.service';
import { logger } from '@/utils/logger';
import { cn } from '@/utils/utils';
import { DecryptionAnimation } from '../animations/DecryptionAnimation';
import { useToast } from '@/components/ui/toast';
import { useTranslation } from 'react-i18next';
import { EncryptedVideoPlayer } from './EncryptedVideoPlayer';
import { EncryptedImageDisplay } from './EncryptedImageDisplay';

interface EncryptedImageViewerProps {
  file: any; // DriveFile
  onClose?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  hasNext?: boolean;
  hasPrevious?: boolean;
  className?: string;
  open?: boolean; 
  onOpenChange?: (open: boolean) => void;
}

export const EncryptedImageViewer: React.FC<EncryptedImageViewerProps> = ({
  file,
  onClose,
  onNext,
  onPrevious,
  hasNext = false,
  hasPrevious = false,
  className,
  open = false,
  onOpenChange
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { encryptedDrive } = useCloudDrive();
  
  // Core state
  const [isSheetOpen, setIsSheetOpen] = useState(!!open);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  
  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef<number | null>(null);
  const isMounted = useRef(true);
  const previousLoadAttempt = useRef<number>(0);
  const maxLoadAttempts = useRef<number>(2); // Limit to 2 attempts
  
  // Determine if file is a video based on media_info or file extension
  const isVideo = useMemo(() => {
    // Check media_info first
    if (file?.media_info?.type === 'video') {
      return true;
    }
    
    // Check file extension as fallback
    const fileExt = (file?.name || '').toLowerCase().split('.').pop() || '';
    return ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv', '3gp'].includes(fileExt);
  }, [file]);
  
  // Get the best available thumbnail
  const thumbnails = file?.media_info?.thumbnails || {};
  
  // Setup component lifecycle
  useEffect(() => {
    isMounted.current = true;
    
    // Auto-focus the container for keyboard events
    if (isSheetOpen && containerRef.current) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.focus();
          logger.debug('Container focused', { component: 'EncryptedImageViewer' });
        }
      }, 100);
    }
    
    return () => {
      isMounted.current = false;
    };
  }, [isSheetOpen]);
  
  // Handle sheet close with cleanup and rate limiting
  const handleSheetClose = useCallback(() => {
    logger.debug('Closing image viewer', { component: 'EncryptedImageViewer' });
    setIsSheetOpen(false);
    
    // Reset state to clean values
    setImageUrl(null);
    setLoading(true);
    setError(null);
    
    // Remove any cached data for this specific file to prevent issues on reopen
    if (file?.id && encryptedDrive.encryptedDriveId) {
      try {
        window.queryClient?.removeQueries({ 
          queryKey: ['item-details', file.id]
        });
      } catch (error) {
        logger.warn('Error cleaning up queries', {
          component: 'EncryptedImageViewer',
          error
        });
      }
    }
    
    if (onOpenChange) onOpenChange(false);
    if (onClose) onClose();
  }, [onClose, onOpenChange, file?.id, encryptedDrive.encryptedDriveId]);
  
  // Touch handlers for mobile
  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  }, []);
  
  const handleTouchEnd = useCallback((e: React.TouchEvent) => {
    if (touchStartX?.current === null) return;
    
    const touchEndX = e.changedTouches[0].clientX;
    const diff = touchStartX.current - touchEndX;
    
    // Detect swipe (threshold of 50px)
    if (Math.abs(diff) > 50) {
      if (diff > 0 && hasNext && onNext) {
        onNext();
      } else if (diff < 0 && hasPrevious && onPrevious) {
        onPrevious();
      }
    }
    
    touchStartX.current = null;
  }, [hasNext, hasPrevious, onNext, onPrevious]);
  
  // Keyboard event handler
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    logger.debug('Key pressed', { 
      component: 'EncryptedImageViewer', 
      key: e.key,
      isSheetOpen
    });
    
    if (!isSheetOpen) return;
    
    switch (e.key) {
      case 'Escape':
        handleSheetClose();
        break;
      case 'ArrowRight':
        if (hasNext && onNext) {
          onNext();
          e.preventDefault();
        }
        break;
      case 'ArrowLeft':
        if (hasPrevious && onPrevious) {
          onPrevious();
          e.preventDefault();
        }
        break;
    }
  }, [isSheetOpen, handleSheetClose, hasNext, hasPrevious, onNext, onPrevious]);
  
  // Setup keyboard events
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  
  // Handle open state changes from props
  useEffect(() => {
    logger.debug('Open state changed', { 
      component: 'EncryptedImageViewer',
      openProp: open, 
      currentOpenState: isSheetOpen 
    });
    
    if (open !== isSheetOpen) {
      setIsSheetOpen(!!open);
    }
  }, [open, isSheetOpen]);
  
  // Auth headers for API calls
  const getAuthHeaders = () => {
    try {
      const tokens = authService.getStoredToken();
      if (!tokens || !tokens.accessToken || !authService.isValidToken(tokens.accessToken)) {
        return {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        };
      }
  
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': tokens.accessToken
      };
    } catch (error) {
      logger.error('Error getting auth headers:', error);
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
    }
  };
  
  // Simplified fetch through proxy
  const fetchThroughProxy = async (
    url: string | null, 
    fileId: string, 
    driveId: string,
    contentType: string = 'thumbnail'
  ): Promise<ArrayBuffer> => {
    try {
      // Make sure url is not null - construct a fallback path if needed
      let effectiveUrl = url;
      
      if (!effectiveUrl) {
        // Create a direct path using driveId and fileId in the format expected by the backend
        // This attempts to construct a path in the format the backend would expect
        effectiveUrl = `encrypted/${driveId}/${fileId}.${contentType === 'preview' ? 'preview.enc' : 'thumb.la.enc'}`;
        
        logger.debug('Created fallback URL path', {
          component: 'EncryptedImageViewer',
          data: {
            fileId, 
            driveId, 
            generatedPath: effectiveUrl
          }
        });
      }
      
      logger.debug('Fetching through proxy', {
        component: 'EncryptedImageViewer',
        data: { 
          fileId, 
          driveId, 
          contentType,
          url: effectiveUrl ? (effectiveUrl.substring(0, 100) + (effectiveUrl.length > 100 ? '...' : '')) : 'EMPTY'
        }
      });
      
      const proxyResponse = await fetch('/api/v1/media/content', {
        method: 'POST',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json'
        } as HeadersInit,
        body: JSON.stringify({
          url: effectiveUrl,
          file_id: fileId,
          drive_id: driveId,
          is_thumbnail: contentType === 'thumbnail',
          is_preview: contentType === 'preview',
          content_type: contentType
        }),
        credentials: 'include'
      });
      
      if (!proxyResponse.ok) {
        throw new Error(`Proxy request failed: ${proxyResponse.status}`);
      }
      
      return await proxyResponse.arrayBuffer();
    } catch (error) {
      logger.error('Proxy fetch error', { error, fileId, driveId });
      throw error;
    }
  };

  /**
   * Helper function to load and process video content with improved error handling and feedback
   */
  const loadVideoWithInfo = async (videoPreviewInfo: any) => {
    try {
      // Clear any previous state first
      setVideoUrl(null);
      setLoadingProgress(5);
      setLoading(true);
      setError(new Error("Preparing to download encrypted video..."));
      
      // Get URL to fetch from preview info, with priority order
      let urlToFetch = videoPreviewInfo.presigned_url || 
                       videoPreviewInfo.storage_path ||
                       videoPreviewInfo.url;
                      
      // Extra validation and logging for debugging path issues  
      if (!urlToFetch) {
        logger.error('Video preview info has no URL', {
          component: 'EncryptedImageViewer',
          data: {
            fileId: file.id,
            previewInfo: JSON.stringify(videoPreviewInfo)
          }
        });
        throw new Error('No URL available for transcoded video');
      }
      
      // Get encryption details from preview info
      const iv = videoPreviewInfo.iv;
      if (!iv) {
        logger.error('Video preview info has no IV', {
          component: 'EncryptedImageViewer',
          data: {
            fileId: file.id,
            previewInfoKeys: Object.keys(videoPreviewInfo)
          }
        });
        throw new Error('Missing IV for video decryption');
      }
      
      // Now that we're validated, start the download
      setLoadingProgress(10);
      setError(new Error("Downloading encrypted video..."));
      
      logger.debug('Using video preview info', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: file.id,
          url: urlToFetch,
          ivLength: iv.length,
          previewPath: videoPreviewInfo.path_suffix || 'unknown',
          videoPreviewInfo: JSON.stringify(videoPreviewInfo)
        }
      });
      
      // Fetch encrypted video data using the direct storage path
      // Set up smaller progress increments for smoother UI feedback
      setTimeout(() => {
        setLoadingProgress(15);
        setError(new Error("Downloading encrypted video... (15%)"));
      }, 200);
      
      setTimeout(() => {
        setLoadingProgress(20);
        setError(new Error("Downloading encrypted video... (20%)"));
      }, 500);
      
      // Very important: Log exact path for debugging path issues
      logger.debug('Exact video fetch path', {
        component: 'EncryptedImageViewer',
        data: {
          exactPath: urlToFetch,
          fileId: file.id
        }
      });
      
      const encryptedData = await fetchThroughProxy(
        urlToFetch,
        file.id,
        encryptedDrive.encryptedDriveId,
        'preview' // Let the server know this is a preview request
      );
      
      // Update download progress with smoother increments
      setLoadingProgress(35);
      setError(new Error("Downloading encrypted video... (35%)"));
      
      // Simulate download progress steps for better UX
      setTimeout(() => {
        setLoadingProgress(45);
        setError(new Error("Downloading encrypted video... (45%)"));
      }, 300);
      
      setTimeout(() => {
        setLoadingProgress(50);
        setError(new Error("Download complete. Preparing to decrypt..."));
      }, 600);
      
      // Get keys and determine encryption tier
      const keys = encryptionService.getDecryptedKeys(encryptedDrive.encryptedDriveId);
      if (!keys) {
        throw new Error('Drive not unlocked');
      }
      
      // Determine encryption tier from multiple sources
      let encryptionTier = 'standard'; // Default fallback
      
      if (encryptedDrive?.encryptionTier) {
        encryptionTier = encryptedDrive.encryptionTier;
      } 
      else if (typeof (encryptionService as any).getDriveEncryptionTier === 'function') {
        const storedTier = (encryptionService as any).getDriveEncryptionTier(encryptedDrive.encryptedDriveId);
        if (storedTier) {
          encryptionTier = storedTier;
        }
      }
      
      setLoadingProgress(60);
      setError(new Error("Decrypting video on your device... (0%)"));
      
      logger.debug('Starting video decryption', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: file.id,
          encryptedSize: encryptedData.byteLength,
          ivLength: iv.length,
          encryptionTier
        }
      });
      
      // Estimate size for progress visualization - larger files take longer
      const estimatedDecryptionProgress = Math.min(
        Math.floor(1000000 / (encryptedData.byteLength || 1000000) * 100), 
        95
      );
      
      // Update to show substantial progress for large files
      setLoadingProgress(65);
      setError(new Error(`Decrypting video on your device... (${estimatedDecryptionProgress/2}%)`));
      
      // Decrypt the video data
      const decryptedData = await encryptionService.decryptContent(
        encryptedData,
        iv,
        keys.contentKey,
        true, // Indicate it's media for decryption
        encryptionTier,
        encryptedDrive.encryptedDriveId
      );
      
      setLoadingProgress(80);
      setError(new Error("Decryption complete. Preparing video..."));
      
      logger.debug('Video decryption successful', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: file.id,
          decryptedSize: decryptedData.byteLength
        }
      });
      
      // Detect mime type based on file extension or magic bytes
      let mimeType = 'video/mp4'; // Default
      
      // Check file extension first
      if (file.name) {
        const extension = file.name.toLowerCase().split('.').pop() || '';
        if (extension === 'webm') mimeType = 'video/webm';
        else if (extension === 'mov') mimeType = 'video/quicktime';
        else if (extension === 'avi') mimeType = 'video/x-msvideo';
        else if (extension === 'mkv') mimeType = 'video/x-matroska';
      }
      
      // Create blob with correct MIME type
      const blob = new Blob([decryptedData], { type: mimeType });
      
      // Update progress to nearly complete
      setLoadingProgress(90);
      setError(new Error("Creating video player..."));
      
      // Cache the decrypted video
      mediaCacheService.cacheBlob(
        encryptedDrive.encryptedDriveId,
        file.id,
        'video_preview',
        blob,
        iv
      );
      
      // Get the URL
      const freshUrl = mediaCacheService.getUrl(
        encryptedDrive.encryptedDriveId,
        file.id,
        'video_preview'
      );
      
      if (!freshUrl) {
        throw new Error('Failed to create video URL');
      }
      
      // Final progress update
      setLoadingProgress(100);
      
      // Set video URL first 
      setVideoUrl(freshUrl);
      
      // Immediately clear the error to prevent showing error message over video
      setError(null);

      // Set progress to 100% to show complete
      setLoadingProgress(100);
      
      // The loading state will be cleared by the EncryptedVideoPlayer component
      // when it triggers onPlayStateChange after successful video loading
      
      return true;
    } catch (error) {
      // Pass the error up to be handled by the caller
      throw error;
    }
  };
  
  /**
   * Constructs a standardized path for transcoded video
   * Following backend path_utils.py format: encrypted/{drive_id}/{file_id}.preview.enc
   * @param driveId The encrypted drive ID
   * @param fileId The file ID to use
   * @param attempt The attempt number to try different strategies
   * @returns A properly formatted video path
   */
  const constructVideoPath = useCallback((driveId: string, fileId: string, attempt: number = 0): string => {
    // First priority: use paths directly from the API when available
    const previewInfo = file?.media_info?.thumbnails?.preview;
    
    // If attempt 0 and we have explicit storage path in preview info, use it
    if (attempt === 0 && previewInfo?.storage_path) {
      logger.debug('Using storage_path directly from preview info', {
        component: 'EncryptedImageViewer',
        data: {
          storagePath: previewInfo.storage_path
        }
      });
      return previewInfo.storage_path;
    }
    
    // Check for storage_uuid which is the correct file_uuid used when creating the file
    // This is the most reliable way to match what was used during file creation
    const storageUuid = file?.storage_uuid || null;
    
    if (attempt === 0 && storageUuid) {
      logger.debug('Using storage_uuid from file metadata', {
        component: 'EncryptedImageViewer',
        data: {
          fileId,
          storageUuid
        }
      });
      return `encrypted/${driveId}/${storageUuid}.preview.enc`;
    }
    
    // If we have a media_info with a video preview, extract the actual ID
    // from the thumbnail path which should be consistent with the preview path
    if (attempt === 0) {
      // Standard format according to backend path_utils.py: encrypted/{drive_id}/{file_id}.preview.enc
      return `encrypted/${driveId}/${fileId}.preview.enc`;
    } 
    else if (attempt === 1) {
      // Some older paths may use a slashed path format: encrypted/{drive_id}/{file_id}/preview.enc
      return `encrypted/${driveId}/${fileId}/preview.enc`;
    }
    else if (attempt === 2) {
      // Try to extract UUID from thumbnail paths - most reliable alternate approach
      // Important: thumbnails often contain accurate IDs even when file metadata has a different ID
      const thumbPath = file?.media_info?.thumbnails?.large?.storage_path ||
                      file?.media_info?.thumbnails?.medium?.storage_path ||
                      file?.media_info?.thumbnails?.small?.storage_path;
                      
      if (thumbPath) {
        try {
          // Extract the filename from the path and get the ID
          const pathParts = thumbPath.split('/');
          const filename = pathParts[pathParts.length - 1];
          const extractedId = filename.split('.')[0]; // Get UUID part
          
          // Use the extracted ID with the standard path - only if different from original
          if (extractedId && extractedId !== fileId) {
            logger.debug('Using different ID extracted from thumbnail path', {
              component: 'EncryptedImageViewer',
              data: {
                origId: fileId,
                extractedId,
                thumbPath
              }
            });
            return `encrypted/${driveId}/${extractedId}.preview.enc`;
          }
        } catch (e) {
          // If extraction fails, log but continue to next attempt
          logger.warn('Failed to extract ID from thumbnail path', {
            component: 'EncryptedImageViewer',
            error: e,
            data: { thumbPath }
          });
        }
      }
      
      // No extracted ID or extraction failed, use a different variation
      // For videos with non-standard naming, try the direct file ID with preview
      return `encrypted/${driveId}/${fileId}.preview.enc`;
    }
    else if (attempt === 3) {
      // For attempt 3, try a variation based on thumb path structure
      // Extract drive path from thumbnail if available
      const thumbPath = file?.media_info?.thumbnails?.large?.storage_path ||
                      file?.media_info?.thumbnails?.medium?.storage_path ||
                      file?.media_info?.thumbnails?.small?.storage_path;
      
      if (thumbPath && thumbPath.includes('/')) {
        try {
          // Get the base path up to the last segment
          const pathParts = thumbPath.split('/');
          const basePath = pathParts.slice(0, pathParts.length - 1).join('/');
          
          // Replace thumb filename with preview filename
          // This handles cases where the path format is different but the directory is correct
          return `${basePath}/preview.enc`;
        } catch (e) {
          logger.warn('Failed to extract base path', {
            component: 'EncryptedImageViewer',
            error: e,
            data: { thumbPath }
          });
        }
      }
      
      // Fallback to standard format with a small variation
      return `encrypted/${driveId}/${fileId}.video.preview.enc`;
    }
    else {
      // For attempt 4+, try various legacy paths and formats
      if (attempt === 4) {
        // Try the path directly to video folder
        return `encrypted/${driveId}/${fileId}/preview`;
      } else {
        // Try other variations for very old files or special cases
        return `encrypted/${driveId}/${fileId}/${attempt === 5 ? 'video' : 'media'}.enc`;
      }
    }
  }, [file?.media_info?.thumbnails]);
  
  /**
   * Main function to load transcoded video with improved error handling and path resolution
   */
  const loadTranscodedVideo = useCallback(async () => {
    if (!encryptedDrive.encryptedDriveId || !file?.id) {
      return;
    }
    
    // Reset error state on each load attempt
    setError(null);
    
    try {
      setLoading(true);
      
      // Check video cache first for quick loading
      const cachedVideoUrl = mediaCacheService.getUrl(
        encryptedDrive.encryptedDriveId,
        file.id,
        'video_preview'
      );
      
      if (cachedVideoUrl) {
        logger.debug('Using cached video', { 
          component: 'EncryptedImageViewer',
          fileId: file.id
        });
        
        setVideoUrl(cachedVideoUrl);
        setLoading(false);
        return;
      }
      
      logger.debug('Analyzing video file', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: file.id,
          mediaInfoKeys: file?.media_info ? Object.keys(file.media_info) : [],
          thumbnailsKeys: file?.media_info?.thumbnails ? Object.keys(file.media_info.thumbnails) : [],
          hasThumbnails: !!file?.media_info?.thumbnails,
        }
      });

      // Path resolution strategy (in order of preference):
      // 1. Use direct preview info from thumbnails if available
      // 2. Extract file ID from thumbnail path and construct standardized path
      // 3. Use the file's ID to construct a standardized path
      
      // Option 1: Check if the file has a preview in the thumbnails object
      const previewFromThumbnails = file?.media_info?.thumbnails?.preview;
      
      if (previewFromThumbnails) {
        logger.debug('Found preview in thumbnails', {
          component: 'EncryptedImageViewer',
          data: {
            fileId: file.id,
            previewInfo: JSON.stringify(previewFromThumbnails)
          }
        });
        
        return loadVideoWithInfo(previewFromThumbnails);
      }
      
      // Get IV for decryption - required for all fallback methods
      // We need an IV for decryption. Try to get it from one of the thumbnails
      const thumbnailIV = file?.media_info?.thumbnails?.large?.iv || 
                         file?.media_info?.thumbnails?.medium?.iv ||
                         file?.media_info?.thumbnails?.small?.iv;
      
      if (!thumbnailIV) {
        throw new Error('Cannot load video: No encryption IV available');
      }
      
      // Option 2: Extract file ID from thumbnail path if available
      let fileIdToUse = file.id; // Default to file.id
      
      // Try to extract ID from thumbnail path if available
      const thumbPath = file?.media_info?.thumbnails?.large?.storage_path || 
                       file?.media_info?.thumbnails?.medium?.storage_path ||
                       file?.media_info?.thumbnails?.small?.storage_path;
                     
      if (thumbPath) {
        try {
          // Extract filename and remove extension to get ID
          const pathMatch = thumbPath.match(/\/([^\/]+)\.(thumb|small|medium|large)/);
          if (pathMatch && pathMatch[1]) {
            fileIdToUse = pathMatch[1];
            logger.debug('Extracted file ID from thumbnail path', {
              component: 'EncryptedImageViewer',
              data: {
                originalPath: thumbPath,
                extractedId: fileIdToUse
              }
            });
          }
        } catch (error) {
          // Log but continue - this is just a path extraction helper
          logger.warn('Failed to extract ID from thumbnail path', {
            component: 'EncryptedImageViewer',
            error,
            data: { thumbPath }
          });
        }
      }
      
      // Create a standardized path and preview info object
      const standardPath = constructVideoPath(encryptedDrive.encryptedDriveId, fileIdToUse);
      
      logger.debug('Using standardized path for video', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: fileIdToUse,
          path: standardPath
        }
      });
      
      // Create a preview info object
      const previewInfo = {
        iv: thumbnailIV,
        storage_path: standardPath,
        path_suffix: 'preview.enc'
      };
      
      // Try to load with our constructed info, with multiple retries for different path formats
      try {
        return await loadVideoWithInfo(previewInfo);
      } catch (initialError) {
        // First load attempt failed - check if this is a 404 error and use our retry strategy
        const is404Error = initialError.message && initialError.message.includes('404');
        
        if (is404Error && previousLoadAttempt.current < maxLoadAttempts.current) {
          // Special error handling for 404s - file not found
          logger.debug('Video 404 error, trying alternate path formats', {
            component: 'EncryptedImageViewer',
            data: {
              attempt: previousLoadAttempt.current + 1,
              maxAttempts: maxLoadAttempts.current,
              fileId: file.id
            }
          });
          
          // Try with different path construction strategies:
          // 1. First try uses direct {fileId}.preview.enc path (already done)
          // 2. Second try uses {fileId}/preview.enc format
          // 3. Third try uses ID extracted from thumbnail paths
          // 4. Fourth try uses older 'preview' or 'video' formats
          
          // Calculate which attempt we should be trying, starting from 1
          // (since attempt 0 was the initial attempt that just failed)
          // Calculate next path attempt - incremental strategy
          const pathAttempt = Math.min(previousLoadAttempt.current + 1, 6);
          
          const alternatePath = constructVideoPath(
            encryptedDrive.encryptedDriveId, 
            fileIdToUse,
            pathAttempt
          );
          
          // Log our retry strategy clearly
          logger.debug('Video 404 retry strategy', {
            component: 'EncryptedImageViewer',
            data: {
              originalId: file.id,
              fileIdToUse,
              pathAttempt,
              alternatePath,
              attemptNum: previousLoadAttempt.current + 1
            }
          });
          
          if (alternatePath === previewInfo.storage_path) {
            // If the path is the same, increment attempt and throw - no need to retry same path
            previousLoadAttempt.current += 1;
            throw new Error(`Path not found: ${alternatePath} (attempt ${previousLoadAttempt.current})`);
          }
          
          logger.debug('Retrying with alternate path format', {
            component: 'EncryptedImageViewer',
            data: {
              attempt: previousLoadAttempt.current + 1,
              pathAttempt,
              originalPath: previewInfo.storage_path,
              alternatePath: alternatePath
            }
          });
          
          // Create new preview info with the alternate path but same IV
          const alternateInfo = {
            iv: thumbnailIV,
            storage_path: alternatePath,
            path_suffix: pathAttempt <= 1 ? 'preview.enc' : 
                         pathAttempt === 2 ? alternatePath.endsWith('preview.enc') ? 'preview.enc' : 'unknown' :
                         pathAttempt === 3 ? 'preview' : 'video'
          };
          
          try {
            // Update error message to show we're trying alternative path
            setError(new Error(`Trying alternative video path (attempt ${previousLoadAttempt.current + 1})...`));
            
            // Try with the alternate path format
            return await loadVideoWithInfo(alternateInfo);
          } catch (alternateError) {
            // Increment attempt counter
            previousLoadAttempt.current += 1;
            
            // Check if we've exceeded max attempts
            if (previousLoadAttempt.current >= maxLoadAttempts.current) {
              throw new Error(`Video file not found: tried multiple path formats (${previousLoadAttempt.current} attempts)`);
            }
            
            // If this was also a 404, throw to trigger the next attempt
            throw new Error(`Video path not found (attempt ${previousLoadAttempt.current})`);
          }
        } else if (previousLoadAttempt.current < maxLoadAttempts.current) {
          // For non-404 errors or other cases
          previousLoadAttempt.current += 1;
          throw new Error(`Error loading video: ${initialError.message} (attempt ${previousLoadAttempt.current})`);
        } else {
          // We've exceeded max attempts
          throw new Error(`Failed to load video after ${previousLoadAttempt.current} attempts: ${initialError.message}`);
        }
      }
    } catch (error) {
      const err = error instanceof Error ? error : new Error(String(error));
      logger.error('Video load error', { 
        error: err, 
        fileId: file.id,
        attemptCount: previousLoadAttempt.current
      });
      
      // Show helpful error message based on the nature of the error
      let errorMessage = t('encrypted_drive.video_load_error', 'Failed to load encrypted video');
      if (err.message.includes('No encryption IV')) {
        errorMessage = t('encrypted_drive.missing_iv_error', 'Cannot decrypt video: missing encryption key');
      } else if (err.message.includes('404')) {
        errorMessage = t('encrypted_drive.video_not_found', 'Video file not found on server');
      }
      
      // Retry logic
      if (previousLoadAttempt.current < maxLoadAttempts.current) {
        setError(new Error(`Retrying video load (attempt ${previousLoadAttempt.current + 1}/${maxLoadAttempts.current})...`));
        
        logger.debug('Video load attempt failed, retrying', {
          component: 'EncryptedImageViewer',
          data: { 
            fileId: file.id, 
            attempt: previousLoadAttempt.current + 1,
            maxAttempts: maxLoadAttempts.current,
            error: err.message
          }
        });
        
        // Exponential backoff for retries (500ms, then 1000ms, etc.)
        const backoffDelay = Math.min(500 * Math.pow(2, previousLoadAttempt.current), 5000);
        
        previousLoadAttempt.current += 1;
        
        // Retry with delay
        setTimeout(() => {
          if (isMounted.current) {
            loadTranscodedVideo();
          }
        }, backoffDelay);
        return;
      }
      
      // If we've exhausted all retries
      setError(err);
      showToast(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  }, [file, encryptedDrive.encryptedDriveId, showToast, t, constructVideoPath]);

  // Simplified image loading function
  const loadFullSizeImage = useCallback(async () => {
    if (!encryptedDrive.encryptedDriveId || !file?.id) {
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      // Check cache first
      const cachedUrl = mediaCacheService.getUrl(
        encryptedDrive.encryptedDriveId,
        file.id,
        'large'
      );
      
      if (cachedUrl) {
        logger.debug('Using cached image', { 
          component: 'EncryptedImageViewer',
          fileId: file.id
        });
        
        setImageUrl(cachedUrl);
        setLoading(false);
        return;
      }
      
      // Find best thumbnail or create fallback for favorites
      if (!thumbnails || Object.keys(thumbnails).length === 0) {
        // Check if this is a favorite item and create a fallback
        if (file.favorite || (file as any).source === 'favorites') {
          logger.debug('Creating fallback image for favorite item', {
            component: 'EncryptedImageViewer',
            data: {
              fileId: file.id,
              fileName: file.name,
              hasFavoriteFlag: !!file.favorite
            }
          });
          
          const fileExt = (file.name || '').toLowerCase().split('.').pop() || '';
          const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp'].includes(fileExt);
          const isVideo = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv'].includes(fileExt);
          
          // Create placeholder SVG
          const bgColor = isVideo ? '#3b82f6' : '#10b981'; // Blue for video, green for image
          const iconType = isVideo ? 'VIDEO' : 'IMAGE';
          const svgWidth = 800;
          const svgHeight = 600;
          
          const svgContent = `
            <svg width="${svgWidth}" height="${svgHeight}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${svgWidth} ${svgHeight}">
              <rect width="100%" height="100%" fill="#1f2937" />
              <rect x="200" y="150" width="400" height="300" fill="${bgColor}" />
              <text x="${svgWidth/2}" y="${svgHeight/2}" font-family="Arial" font-size="36" fill="white" text-anchor="middle" dominant-baseline="middle">
                ${file.name || iconType}
              </text>
              <text x="${svgWidth/2}" y="${svgHeight/2 + 50}" font-family="Arial" font-size="24" fill="white" text-anchor="middle" dominant-baseline="middle">
                (Placeholder for encrypted ${iconType.toLowerCase()})
              </text>
            </svg>
          `;
          
          const svgBlob = new Blob([svgContent], { type: 'image/svg+xml' });
          const placeholderUrl = URL.createObjectURL(svgBlob);
          
          // Use the placeholder and exit loading
          setImageUrl(placeholderUrl);
          setLoading(false);
          return;
        }
        
        throw new Error('No thumbnails available');
      }
      
      const bestSize = thumbnails.large ? 'large' : (thumbnails.medium ? 'medium' : 'small');
      const thumbInfo = thumbnails[bestSize];
      
      if (!thumbInfo) {
        throw new Error('No thumbnail info available');
      }
      
      const urlToFetch = thumbInfo.presigned_url || thumbInfo.storage_path;
      if (!urlToFetch) {
        throw new Error('No URL available to fetch the thumbnail');
      }
      
      // Fetch encrypted data
      const encryptedData = await fetchThroughProxy(
        urlToFetch,
        file.id,
        encryptedDrive.encryptedDriveId
      );
      
      // Get keys and decrypt
      const keys = encryptionService.getDecryptedKeys(encryptedDrive.encryptedDriveId);
      if (!keys) {
        throw new Error('Drive not unlocked');
      }
      
      // Determine encryption tier if available from encryption service
      // or from context
      let encryptionTier = 'standard'; // Default fallback
      
      // Check for encryption tier in drive object or service
      if (encryptedDrive?.encryptionTier) {
        encryptionTier = encryptedDrive.encryptionTier;
      } 
      else if (typeof (encryptionService as any).getDriveEncryptionTier === 'function') {
        const storedTier = (encryptionService as any).getDriveEncryptionTier(encryptedDrive.encryptedDriveId);
        if (storedTier) {
          encryptionTier = storedTier;
        }
      }
      
      logger.debug('Starting full image decryption', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: file.id,
          encryptedSize: encryptedData.byteLength,
          ivLength: thumbInfo.iv.length,
          encryptionTier
        }
      });

      // Passing both forThumbnail flag and encryption tier to help decryption
      const decryptedData = await encryptionService.decryptContent(
        encryptedData,
        thumbInfo.iv,
        keys.contentKey,
        true, // Indicate it's for image/thumbnail decryption
        encryptionTier, // Pass the encryption tier to help algorithm selection
        encryptedDrive.encryptedDriveId // Pass the driveId so the service can look up encryption tier
      );
      
      logger.debug('Decryption successful, preparing image', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: file.id,
          decryptedSize: decryptedData.byteLength
        }
      });
      
      // Create blob and cache
      const blob = new Blob([decryptedData], { type: 'image/jpeg' });
      mediaCacheService.cacheBlob(
        encryptedDrive.encryptedDriveId, 
        file.id, 
        bestSize, 
        blob, 
        thumbInfo.iv
      );
      
      // Get URL
      const freshUrl = mediaCacheService.getUrl(
        encryptedDrive.encryptedDriveId, 
        file.id, 
        bestSize
      );
      
      if (!freshUrl) {
        throw new Error('Failed to create image URL');
      }
      
      setImageUrl(freshUrl);
    } catch (error) {
      const err = error instanceof Error ? error : new Error(String(error));
      logger.error('Image load error', { error: err, fileId: file.id });
      
      // If this looks like a decryption error and is the first attempt, try one more time
      // Sometimes the first decryption fails but subsequent attempts work
      if (err.message?.includes('decryption failed') && previousLoadAttempt.current <= 1) {
        // Set error but don't show toast yet
        setError(new Error('Retrying decryption automatically...'));
        logger.debug('First decryption attempt failed, retrying automatically', {
          component: 'EncryptedImageViewer',
          data: { fileId: file.id, attempt: previousLoadAttempt.current }
        });
        
        // Small delay before retry
        setTimeout(() => {
          if (isMounted.current) {
            loadFullSizeImage();
          }
        }, 500);
      } else {
        // Show error after retries or for non-decryption errors
        setError(err);
        showToast(t('encrypted_drive.image_load_error', 'Failed to load encrypted image'), 'error');
      }
    } finally {
      setLoading(false);
    }
  }, [file, encryptedDrive.encryptedDriveId, thumbnails, showToast, t]);
  
  // Load media when sheet opens - with circuit breaker to prevent infinite loops
  useEffect(() => {
    if (!isSheetOpen || !file?.id) {
      // Reset attempt counter when sheet is closed
      previousLoadAttempt.current = 0;
      return;
    }
    
    // Prevent infinite loop by limiting load attempts - allow more retries for videos
    const effectiveMaxAttempts = isVideo ? 2 : 2;
    
    if (previousLoadAttempt.current >= effectiveMaxAttempts) {
      logger.warn('Maximum load attempts reached - preventing further attempts', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: file.id,
          attempts: previousLoadAttempt.current,
          isVideo,
          maxAttempts: effectiveMaxAttempts
        }
      });
      return;
    }
    
    previousLoadAttempt.current += 1;
    
    // Give it a slight delay to ensure proper state initialization
    const loadTimeout = setTimeout(() => {
      if (isVideo) {
        logger.debug('Loading video file', { 
          component: 'EncryptedImageViewer', 
          fileId: file.id 
        });
        loadTranscodedVideo();
      } else {
        logger.debug('Loading image file', { 
          component: 'EncryptedImageViewer', 
          fileId: file.id 
        });
        loadFullSizeImage();
      }
    }, 50); 
    
    return () => clearTimeout(loadTimeout);
  }, [isSheetOpen, file?.id, loadFullSizeImage, loadTranscodedVideo, isVideo]);
  
  // Download handler
  const handleDownload = useCallback(() => {
    const url = isVideo ? videoUrl : imageUrl;
    if (!url) {
      showToast(
        isVideo 
          ? t('encrypted_drive.video_not_ready', 'Video is not ready for download yet')
          : t('encrypted_drive.image_not_ready', 'Image is not ready for download yet'),
        'error'
      );
      return;
    }
    
    try {
      const a = document.createElement('a');
      a.href = url;
      
      // Set appropriate download filename based on media type
      const defaultName = isVideo ? 'encrypted-video.mp4' : 'encrypted-image.jpg';
      a.download = file?.name || defaultName;
      
      // Log the download attempt
      logger.debug('Attempting media download', {
        component: 'EncryptedImageViewer',
        data: {
          fileId: file?.id,
          fileName: file?.name,
          isVideo,
          urlAvailable: !!url
        }
      });
      
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      
      showToast(
        t('encrypted_drive.download_started', 'Download started'),
        'success'
      );
    } catch (error) {
      logger.error('Error downloading media', { error });
      showToast(
        t('encrypted_drive.download_error', 'Failed to download media'),
        'error'
      );
    }
  }, [imageUrl, videoUrl, isVideo, file?.id, file?.name, showToast, t]);
  
  // Share handler
  const handleShare = useCallback(() => {
    if (!file) return;
    
    // Determine media type for text
    const mediaType = isVideo ? 'video' : 'image';
    
    if (navigator.share) {
      navigator.share({
        title: file.name || `Encrypted ${mediaType}`,
        text: `Check out this encrypted ${mediaType}: ${file.name}`,
      }).catch(error => {
        logger.error('Error sharing', { error });
      });
    } else {
      navigator.clipboard.writeText(`Encrypted ${mediaType}: ${file.name || mediaType}`)
        .then(() => {
          showToast(t('common.copied_to_clipboard', 'Copied to clipboard'), 'success');
        })
        .catch(err => {
          logger.error('Failed to copy', { error: err });
        });
    }
  }, [file, isVideo, showToast, t]);

  return (
    <Sheet 
      isOpen={isSheetOpen} 
      onClose={handleSheetClose}
    >
      <SheetContent 
        side="right" 
        onClose={handleSheetClose}
        className={cn(
          "w-full max-w-none border-none",
          "bg-gray-900/95 backdrop-blur-sm",
          "fixed z-[100]", 
          className
        )}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 0,
          padding: 0,
          height: '100vh',
          width: '100vw',
          maxHeight: '100vh',
          maxWidth: '100vw'
        }}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <SheetHeader className="px-4 py-3 flex-shrink-0 bg-gray-950 text-white border-b border-gray-800">
            <div className="flex items-center justify-between w-full">
              <SheetTitle className="text-white">
                <div className="flex items-center">
                  <Shield className="h-4 w-4 text-blue-400 mr-2" />
                  <span className="truncate max-w-[200px] sm:max-w-md font-medium text-sm sm:text-base">
                    {file?.name || 'Encrypted Media'}
                  </span>
                </div>
              </SheetTitle>
              <Button 
                variant="ghost" 
                size="icon"
                onClick={handleSheetClose}
                className="h-8 w-8 rounded-full hover:bg-gray-800 text-white ml-2 flex-shrink-0"
                aria-label="Close viewer"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </SheetHeader>
          
          {/* Main content */}
          <div 
            className="flex-1 overflow-auto bg-gray-950 relative"
            ref={containerRef}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            tabIndex={0}
            style={{ outline: 'none' }} // Remove focus outline
          >
            <div className="h-full w-full flex items-center justify-center p-4"
              style={{ minHeight: 'calc(100vh - 150px)' }}>
              
              {/* Loading and Error state (combined into one UI component) */}
              {(loading || error) && !(isVideo && videoUrl) && (
                <div className="absolute inset-0 flex items-center justify-center z-10 bg-gray-900/80">
                  <div className="text-center text-white max-w-md">
                    {/* Visual status indicator */}
                    <div className="mb-4">
                      {/* Only show animation for normal loading without progress message */}
                      {loading && !error && (
                        // Only show decryption animation if the file hasn't been animated before
                        !mediaCacheService.hasFileBeenAnimated(encryptedDrive.encryptedDriveId, file?.id) ? (
                          <DecryptionAnimation 
                            decryptedText={isVideo ? "Decrypting video..." : "Decrypting image..."} 
                            duration={800}
                            steps={8}
                            className="text-blue-400 text-sm sm:text-base"
                            onComplete={() => {
                              // Mark this file as having shown animation
                              if (file?.id && encryptedDrive.encryptedDriveId) {
                                mediaCacheService.markFileAnimated(encryptedDrive.encryptedDriveId, file.id);
                              }
                            }}
                          />
                        ) : (
                          // For files that already showed animation, just show static text
                          <div className="text-blue-400 text-sm sm:text-base">
                            {isVideo ? "Loading video..." : "Loading image..."}
                          </div>
                        )
                      )}
                      
                      {/* Show video-specific emoji for video processing */}
                      {isVideo && error && error.message && !error.message.includes('Failed') && (
                        <div className="text-3xl mb-2">
                          {error.message.includes('Downloading') && '📥'}
                          {error.message.includes('Decrypting') && '🔐'}
                          {error.message.includes('Preparing') && '🎬'}
                          {error.message.includes('Creating') && '▶️'}
                        </div>
                      )}
                      
                      {/* Progress message */}
                      {error && error.message && !error.message.includes('Failed') && (
                        <div className="text-sm sm:text-base font-medium text-blue-300 mb-2">
                          {error.message}
                        </div>
                      )}
                      
                      {/* Error message for actual errors */}
                      {error && error.message && error.message.includes('Failed') && (
                        <div className="text-sm sm:text-base font-medium text-red-400 mb-2">
                          {isVideo 
                            ? t('encrypted_drive.video_load_error', 'Failed to load encrypted video') 
                            : t('encrypted_drive.image_load_error', 'Failed to load encrypted image')
                          }
                          <p className="text-xs text-gray-400 mb-4 max-w-md mx-auto mt-2">{error.message}</p>
                        </div>
                      )}
                    </div>
                    
                    {/* Progress bar - don't show if video is already loaded */}
                    {(loading || (error && !error.message.includes('Failed'))) && (
                      <div className="relative mb-4">
                        <div className="h-2 w-64 bg-gray-700 rounded-full overflow-hidden mx-auto">
                          {/* Different animation approach based on if we have exact progress */}
                          {loadingProgress > 0 ? (
                            <motion.div
                              initial={{ width: 0 }}
                              animate={{ width: `${loadingProgress}%` }}
                              className="h-full bg-blue-500"
                              transition={{ 
                                duration: 0.8, // Smoother transitions
                                ease: "easeInOut" // More natural easing
                              }}
                            />
                          ) : (
                            <motion.div
                              initial={{ width: 0, x: '-100%' }}
                              animate={{ width: '50%', x: ['0%', '150%', '250%'] }}
                              transition={{ 
                                duration: 2.5,
                                repeat: Infinity,
                                ease: "linear"
                              }}
                              className="h-full bg-blue-500 rounded-full"
                            />
                          )}
                        </div>
                        
                        {/* Progress percentage with smoother animation */}
                        {loadingProgress > 0 && (
                          <motion.div 
                            className="text-xs text-gray-300 mt-1"
                            initial={{ opacity: 0.8 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                          >
                            {loadingProgress}%
                          </motion.div>
                        )}
                      </div>
                    )}
                    
                    {/* Security note for decryption */}
                    {isVideo && error && error.message && error.message.includes('Decrypting') && (
                      <div className="text-xs text-gray-400 mb-4 max-w-sm mx-auto">
                        <div className="flex items-center justify-center mb-1">
                          <Shield className="h-3 w-3 text-blue-500 mr-1" />
                          <span>Zero-knowledge encryption</span>
                        </div>
                        Your video is being decrypted securely on your device, not on any server
                      </div>
                    )}
                    
                    {/* Action buttons for errors */}
                    {error && error.message && error.message.includes('Failed') && (
                      <div className="flex flex-col items-center mt-4">
                        <div className="flex flex-col sm:flex-row justify-center gap-2 mb-4">
                          <Button 
                            variant="outline" 
                            size="sm"
                            onClick={isVideo ? loadTranscodedVideo : loadFullSizeImage}
                            className="text-sm"
                          >
                            <RefreshCw className="h-3 w-3 mr-2" />
                            {t('common.retry', 'Retry')}
                          </Button>
                          
                          <Button 
                            variant="ghost" 
                            size="sm"
                            onClick={handleSheetClose}
                            className="text-sm text-gray-400"
                          >
                            <X className="h-3 w-3 mr-2" />
                            {t('common.close', 'Close')}
                          </Button>
                        </div>
                        
                        {/* Helpful information for videos that can't be played */}
                        {isVideo && error.message.includes('not found') && (
                          <div className="text-xs text-gray-400 mt-2 max-w-xs mx-auto">
                            <p className="mb-2">
                              This video may need processing. Try these options:
                            </p>
                            <ul className="list-disc list-inside text-left">
                              <li className="mb-1">Download the encrypted original file and view it locally</li>
                              <li className="mb-1">Wait a few minutes as preview generation may be in progress</li>
                              <li>If the issue persists, the video format may not be supported for transcoding</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Help text for long-running operations */}
                    {isVideo && loading && loadingProgress > 0 && loadingProgress < 100 && (
                      <div className="text-xs text-gray-400 mt-2 max-w-xs mx-auto">
                        {loadingProgress < 50 && "Downloading encrypted video, please wait..."}
                        {loadingProgress >= 50 && loadingProgress < 80 && "Large videos may take a moment to decrypt"}
                        {loadingProgress >= 80 && "Almost ready to play your video..."}
                      </div>
                    )}
                  </div>
                </div>
              )}
              
              {/* Video Player - using the EncryptedVideoPlayer component */}
              {isVideo && videoUrl && (
                <EncryptedVideoPlayer 
                  videoUrl={videoUrl}
                  isLoading={loading}
                  onPlayStateChange={(isPlaying) => {
                    // When video starts playing, clear any loading indicators
                    if (isPlaying) {
                      setLoading(false);
                      setError(null);
                      setLoadingProgress(100);
                    }
                  }}
                />
              )}
              
              {/* Image Display - using the EncryptedImageDisplay component */}
              {!isVideo && (
                <EncryptedImageDisplay
                  imageUrl={imageUrl}
                  fileId={file?.id || ''}
                  driveId={encryptedDrive.encryptedDriveId || ''}
                  thumbnails={thumbnails}
                  isLoading={loading}
                  fileName={file?.name}
                />
              )}
              
              {/* Navigation buttons */}
              {hasPrevious && (
                <div className="absolute left-4 top-1/2 transform -translate-y-1/2 z-30">
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={onPrevious}
                    aria-label="Previous image"
                    className="h-10 w-10 sm:h-12 sm:w-12 rounded-full bg-gray-800/60 border-0 text-white hover:bg-gray-700"
                  >
                    <ChevronLeft className="h-6 w-6" />
                  </Button>
                </div>
              )}
              
              {hasNext && (
                <div className="absolute right-4 top-1/2 transform -translate-y-1/2 z-30">
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={onNext}
                    aria-label="Next image"
                    className="h-10 w-10 sm:h-12 sm:w-12 rounded-full bg-gray-800/60 border-0 text-white hover:bg-gray-700"
                  >
                    <ChevronRight className="h-6 w-6" />
                  </Button>
                </div>
              )}
              
              {/* Mobile swipe hint */}
              {(hasNext || hasPrevious) && (
                <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2 bg-gray-800/70 rounded-lg px-3 py-1.5 text-xs text-white sm:hidden">
                  {t('encrypted_drive.swipe_to_navigate', 'Swipe to navigate')}
                </div>
              )}
            </div>
          </div>
          
          {/* Footer controls */}
          <div className="flex-shrink-0 p-3 bg-gray-950/90 text-white border-t border-gray-800">
            <div className="flex items-center justify-center gap-4">
              {/* Common controls for all media types */}
              {/* Share button */}
              <Button 
                variant="ghost" 
                size="sm"
                onClick={handleShare}
                className="text-gray-300 hover:text-white hover:bg-gray-800 h-10 w-10 p-0 rounded-full"
                aria-label="Share media"
              >
                <Share2 className="h-4 w-4" />
              </Button>
              
              {/* Download button */}
              <Button 
                variant="ghost" 
                size="sm"
                onClick={handleDownload}
                disabled={!(imageUrl || videoUrl)}
                className="text-gray-300 hover:text-white hover:bg-gray-800 h-10 w-10 p-0 rounded-full"
                aria-label="Download media"
              >
                <Download className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default EncryptedImageViewer;