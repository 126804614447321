/**
 * @file src/components/encrypted-drive/dialogs/CreateDriveDialog.tsx
 * @description Multi-step dialog for creating encrypted drives
 * @version 1.0.1
 */

import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';  // Add this
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useToast } from '@/components/ui/toast';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';  // Add this
import { StepWelcome } from './steps/StepWelcome';
import { StepDriveDetails } from './steps/StepDriveDetails';
import { StepEncryptionType } from './steps/StepEncryptionType';
import { StepPassword } from './steps/StepPassword';
import { StepRecoveryKey } from './steps/StepRecoveryKey';
import { StepConfirmation } from './steps/StepConfirmation';

import { EncryptionService } from '@/services/encrypted-drive/encryption.service';

import { logger } from '@/utils/logger';
import { KeyGenerationResult } from '@/types/encryption.types';
import { completeDriveCreation } from '@/services/encrypted-drive/createDrive.service';
import { EncryptionTier } from '@/types/encrypted-drive.types';

// Define the missing type
interface CreateDriveFormData {
  name: string;
  encryptionTier: EncryptionTier;
  password: string;
  confirmPassword: string;
  recoveryKey?: string;
  keyData?: KeyGenerationResult;
}

interface CreateDriveDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const CreateDriveDialog: React.FC<CreateDriveDialogProps> = ({
  open,
  onOpenChange,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { systemDevice } = useRootDrive();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<CreateDriveFormData>({
    name: '',
    encryptionTier: 'standard' as EncryptionTier,
    password: '',
    confirmPassword: '',
  });

  // Correctly destructure keyData and its setter.
  const [keyData, setKeyData] = useState<KeyGenerationResult | null>(null);
  const encryptionService = new EncryptionService();

  // Reset state when dialog closes
  useEffect(() => {
    if (!open) {
      setStep(0);
      setFormData({
        name: '',
        encryptionTier: 'standard' as EncryptionTier,
        password: '',
        confirmPassword: '',
      });
      setKeyData(null);
    }
  }, [open]);

  // const handleComplete = () => {
  //   showToast(
  //     t('encrypted_drive.create_success'),
  //     'success'
  //   );
  //   onOpenChange(false);
  // };

  const handleNext = () => {
    logger.debug('Moving to next step', { currentStep: step });
    setStep((prev: number) => prev + 1);
  };

  const handleBack = () => {
    logger.debug('Moving to previous step', { currentStep: step });
    setStep((prev: number) => prev - 1);
  };

  const handlePasswordConfirmed = async () => {
    try {
      // Generate encryption keys after password is confirmed.
      const generatedKeys = await encryptionService.generateMasterKey(
        formData.password
      );
      logger.debug(
        `Generated keys: ${JSON.stringify(
          generatedKeys
        )} and has salt: ${!!generatedKeys.salt}`
      );

      // Store key data and recovery key.
      setKeyData(generatedKeys);
      setFormData((prev) => ({
        ...prev,
        recoveryKey: generatedKeys.recoveryData?.key,
        keyData: generatedKeys
      }));

      handleNext();
    } catch (error) {
      logger.error("Error generating encryption keys:", error);
      showToast(
        "Failed to generate encryption keys. Please try again.",
        "error"
      );
    }
  };

  const handleCreateDrive = async () => {
    try {
      // If systemDevice is not available from context, try to fetch it directly
      let deviceId = systemDevice?.id;
      
      if (!deviceId) {
        // Try to fetch system device directly
        logger.debug("System device not available from context, trying direct fetch", {
          component: "CreateDriveDialog"
        });
        
        try {
          // Import directly to avoid circular dependencies
          const { cloudDriveService } = await import('@/services/cloud-drive.service');
          const systemOverview = await cloudDriveService.getSystemOverview();
          
          const fetchedSystemDevice = systemOverview.devices.find((d: any) => 
            d.device_type === 'system' && d.config?.isRoot
          );
          
          if (fetchedSystemDevice?.id) {
            deviceId = fetchedSystemDevice.id;
            logger.debug("Retrieved system device directly", {
              deviceId,
              component: "CreateDriveDialog"
            });
          }
        } catch (fetchError) {
          logger.error("Failed to fetch system device directly", {
            error: fetchError,
            component: "CreateDriveDialog"
          });
        }
      }
      
      if (!deviceId) {
        logger.error("System device not found", {
          component: "CreateDriveDialog",
          action: "handleCreateDrive",
        });
        showToast(
          t("encrypted_drive.errors.no_system_device"),
          "error"
        );
        return;
      }

      if (!keyData) {
        throw new Error("Missing key data");
      }

      logger.info("Starting drive creation", {
        component: "CreateDriveDialog",
        action: "handleCreateDrive",
        data: {
          name: formData.name,
          deviceId, // Use the deviceId we've obtained
          hasKeyData: !!keyData,
        },
      });

      const result = await completeDriveCreation(
        {
          name: formData.name,
          password: formData.password,
          deviceId, // Use the deviceId we've obtained
          encryptionTier: formData.encryptionTier as any
        },
        keyData
      );

      if (!result.success) {
        throw new Error(result.error);
      }
  
      logger.info('Drive created successfully', {
        component: 'CreateDriveDialog',
        data:{
          driveId: result.driveId
        }
      });
  
      handleNext();
    } catch (error) {
      logger.error("Error creating drive:", error);
      showToast(
        t("encrypted_drive.errors.drive_creation_failed"),
        "error"
      );
    }
  };

  const handleClose = () => {
    setStep(0);
    setFormData({
      name: '',
      encryptionTier: 'standard' as EncryptionTier,
      password: '',
      confirmPassword: '',
    });
    setKeyData(null);
    onOpenChange(false);
  };

  // Define step props interfaces
  interface StepWelcomeProps {
    onNext: () => void;
  }

  interface StepDriveDetailsProps {
    formData: CreateDriveFormData;
    setFormData: Dispatch<SetStateAction<CreateDriveFormData>>;
    onNext: () => void;
    onBack: () => void;
  }

  interface StepEncryptionTypeProps {
    formData: CreateDriveFormData;
    setFormData: Dispatch<SetStateAction<CreateDriveFormData>>;
    onNext: () => void;
    onBack: () => void;
  }

  interface StepPasswordProps {
    formData: CreateDriveFormData;
    setFormData: Dispatch<SetStateAction<CreateDriveFormData>>;
    onNext: () => void;
    onBack: () => void;
  }

  interface StepRecoveryKeyProps {
    formData: CreateDriveFormData;
    onComplete: () => void;
    onBack: () => void;
  }

  interface StepConfirmationProps {
    formData: CreateDriveFormData;
    onClose: () => void;
  }

  type StepProps = 
    | StepWelcomeProps 
    | StepDriveDetailsProps 
    | StepEncryptionTypeProps 
    | StepPasswordProps 
    | StepRecoveryKeyProps 
    | StepConfirmationProps;

  interface Step {
    component: React.ComponentType<any>;
    props: StepProps;
  }

  const steps: Step[] = [
    {
      component: StepWelcome,
      props: { onNext: handleNext }
    },
    {
      component: StepDriveDetails,
      props: {
        formData,
        setFormData,
        onNext: handleNext,
        onBack: handleBack
      }
    },
    {
      component: StepEncryptionType,
      props: {
        formData,
        setFormData,
        onNext: handleNext,
        onBack: handleBack
      }
    },
    {
      component: StepPassword,
      props: {
        formData,
        setFormData,
        onNext: handlePasswordConfirmed,
        onBack: handleBack
      }
    },
    {
      component: StepRecoveryKey,
      props: {
        formData,
        onComplete: handleCreateDrive,
        onBack: handleBack
      }
    },
    {
      component: StepConfirmation,
      props: {
        formData,
        onClose: handleClose
      }
    }
  ];

  const CurrentStep = steps[step].component;
  const currentProps = steps[step].props;

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px]">
        <CurrentStep {...currentProps} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateDriveDialog;