/**
 * @file src/services/encrypted-drive/encrypted-file-download.service.ts
 * @description Services for downloading encrypted files
 * @version 1.0.0
 * @created 2025-03-19
 * @dependencies encrypted-files.service, encryption-singleton
 */

import { logger } from '@/utils/logger';
import { debugLogger } from '@/utils/debug-logger';
import { encryptionService } from './encryption-singleton';
import { encryptedFilesService } from './encrypted-files.service';
import { authService } from '../auth.service';

// Helper function to get auth headers that returns a proper HeadersInit
const getAuthHeaders = (): HeadersInit => {
  try {
    const tokens = authService.getStoredToken();
    const baseHeaders: Record<string, string> = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    
    if (!tokens || !tokens.accessToken || !authService.isValidToken(tokens.accessToken)) {
      return baseHeaders;
    }

    return {
      ...baseHeaders,
      'Authorization': tokens.accessToken
    };
  } catch (error) {
    logger.error('Error getting auth headers:', error);
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }
};

// Create the service object
export const encryptedFileDownloadService = {
  /**
   * Fetches encrypted file content through proxy to avoid CORS issues
   */
/**
 * Fetches encrypted file content through proxy to avoid CORS issues
 */
async fetchEncryptedFile(
  fileId: string, 
  driveId: string
): Promise<ArrayBuffer> {
  try {
    debugLogger.info('Fetching encrypted file', {
      component: 'encryptedFileDownloadService',
      data: { fileId, driveId }
    });
    
    // Use the more efficient download endpoint
    const downloadResponse = await fetch(`/api/v1/encrypted-drive/download/${fileId}?drive_id=${driveId}`, {
      method: 'GET',
      headers: getAuthHeaders() as Record<string, string>,
      credentials: 'include'
    });
    
    if (!downloadResponse.ok) {
      const errorStatus = downloadResponse.status;
      let errorText = '';
      try {
        const errorData = await downloadResponse.json();
        errorText = errorData.detail || '';
      } catch (e) {
        errorText = await downloadResponse.text() || '';
      }
      
      logger.error('Failed to get download URL', {
        component: 'encryptedFileDownloadService',
        data: { 
          fileId,
          status: errorStatus,
          error: errorText
        }
      });
      
      throw new Error(`Failed to get download URL: ${errorStatus} ${errorText}`);
    }
    
    const downloadData = await downloadResponse.json();
    
    // Check if we have the required storage_path
    if (!downloadData.storage_path) {
      logger.error('No storage path found in response', {
        component: 'encryptedFileDownloadService',
        data: { 
          fileId,
          responseKeys: Object.keys(downloadData)
        }
      });
      throw new Error('No download URL available for this file');
    }
    
    // Log success and content_iv status
    logger.debug('Retrieved download URL', {
      component: 'encryptedFileDownloadService',
      data: { 
        fileId,
        hasStoragePath: true,
        hasContentIv: !!downloadData.content_iv
      }
    });
    
    // Send the storage path to the proxy
    const proxyResponse = await fetch('/api/v1/media/content', {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({
        url: downloadData.storage_path,
        file_id: fileId,
        drive_id: driveId,
        is_thumbnail: false
      }),
      credentials: 'include'
    });
    
    if (!proxyResponse.ok) {
      let errorDetail = '';
      try {
        const errorResponse = await proxyResponse.json();
        errorDetail = errorResponse.detail || '';
      } catch (e) {
        errorDetail = await proxyResponse.text() || '';
      }
      
      logger.error('Proxy request failed', {
        component: 'encryptedFileDownloadService',
        data: { 
          fileId, 
          status: proxyResponse.status,
          statusText: proxyResponse.statusText,
          errorDetail 
        }
      });
      
      throw new Error(`Proxy request failed: ${proxyResponse.status} ${proxyResponse.statusText}${errorDetail ? ` - ${errorDetail}` : ''}`);
    }
    
    const arrayBuffer = await proxyResponse.arrayBuffer();
    
    logger.debug('File content fetched successfully', {
      component: 'encryptedFileDownloadService',
      data: { 
        fileId,
        contentSize: arrayBuffer.byteLength 
      }
    });
    
    return arrayBuffer;
  } catch (error) {
    logger.error('Error fetching encrypted file', {
      component: 'encryptedFileDownloadService',
      error,
      data: { fileId, driveId }
    });
    throw error;
  }
},



  /**
   * Downloads and decrypts an encrypted file
   */
  async downloadFile(
    fileId: string, 
    driveId: string, 
    contentKey: CryptoKey,
    fileName?: string
  ): Promise<void> {
    try {
      debugLogger.info('Starting encrypted file download', {
        component: 'encryptedFileDownloadService',
        data: { fileId, driveId }
      });
      
      // 1. Get file details
      const fileDetails = await encryptedFilesService.getFileDetails(fileId, driveId);
      
      if (!fileDetails) {
        throw new Error('File details not found');
      }
      
      // Get IV from content_iv or iv in file details
      const iv = (fileDetails as any).content_iv || (fileDetails as any).iv || '';
      if (!iv) {
        throw new Error('Missing initialization vector for file');
      }
      
      debugLogger.info('File details retrieved for download', {
        component: 'encryptedFileDownloadService',
        data: {
          fileId,
          fileName: fileDetails.name,
          fileSize: fileDetails.size,
          hasIV: !!iv
        }
      });
      
      // 2. Fetch the encrypted file data
      const encryptedData = await this.fetchEncryptedFile(fileId, driveId);
      
      debugLogger.info('Decrypting file content', {
        component: 'encryptedFileDownloadService',
        data: {
          fileId,
          fileSize: encryptedData.byteLength,
          fileName: fileDetails.name
        }
      });
      
      // 3. Decrypt the file content
      const decryptedData = await encryptionService.decryptContent(
        encryptedData,
        iv,
        contentKey,
        false // Not a thumbnail
      );
      
      // 4. Create a Blob from the decrypted data
      const blob = new Blob([decryptedData]);
      
      // 5. Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName || fileDetails.name || `file-${fileId}`;
      document.body.appendChild(a);
      a.click();
      
      // 6. Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      debugLogger.info('Encrypted file download complete', {
        component: 'encryptedFileDownloadService',
        data: {
          fileId,
          fileName: a.download,
          fileSize: blob.size
        }
      });
    } catch (error) {
      logger.error('Error downloading encrypted file', {
        component: 'encryptedFileDownloadService',
        error,
        data: { fileId, driveId }
      });
      throw error;
    }
  }
};

/**
 * Downloads and decrypts an encrypted file - Exported function for use in other modules
 */
export const downloadEncryptedFile = async (
  fileId: string, 
  driveId: string, 
  contentKey: CryptoKey,
  fileName?: string
): Promise<void> => {
  return encryptedFileDownloadService.downloadFile(fileId, driveId, contentKey, fileName);
};