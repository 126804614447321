/**
 * @file src/components/data/DataSplitView/MainContent/ContentRenderer.tsx
 * @description Content renderer for different category views
 * @version 1.2.0
 * @updated 2025-02-27
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {  
  Share2, 
  Users, 
  Trash2, 
  Download,
  Plus
} from 'lucide-react';
import { cn } from '@/utils/utils';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';
import { CloudDriveLayout } from '@/components/cloud-drive/layout/CloudDriveLayout';
import { FileBrowserView } from '@/components/cloud-drive/browser/FileBrowserView';
import { EncryptedDriveSection } from '@/components/encrypted-drive/EncryptedDriveSection';
import { PlaceholderView } from '@/components/common/PlaceholderView';
import { useCloudDrive } from '@/context/cloud-drive';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { DeviceDetailView } from '@/components/devices/DeviceDetailView';
import { DeviceListView } from '@/components/devices/DeviceListView'; // Add DeviceListView
import { ServicesListView } from '@/components/services/ServicesListView'; // Add ServicesListView
import { logger } from '@/utils/logger';
import type { CategoryId } from '../types';

interface ContentRendererProps {
  category: CategoryId | null;
  folderId: string | null;
  expandedDevice?: string | null;
  viewType?: "default" | "folder" | "shared" | "favorites" | "trash" | undefined;
  className?: string;
}

export const ContentRenderer: React.FC<ContentRendererProps> = ({
  category,
  folderId,
  expandedDevice = null,
  viewType = 'default',
  className
}) => {
  const { t } = useTranslation();
  const { encryptedDrive } = useCloudDrive();
  const { devices } = useRootDrive();
  
  // State variables
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
  const [authDialogService, setAuthDialogService] = useState<any | null>(null);

  // Handle device selection
  const handleSelectDevice = (deviceId: string) => {
    logger.debug('Device selected from list', {
      component: 'ContentRenderer',
      deviceId,
      action: 'selectDevice'
    });
    setSelectedDeviceId(deviceId);
  };

  // Render Cloud Drive content
  const renderCloudDriveContent = () => (
    <ErrorBoundary>
      <CloudDriveLayout>
        <div className="flex-1 overflow-auto">
          <FileBrowserView
            folderId={folderId}
            viewType={viewType}
          />
        </div>
      </CloudDriveLayout>
    </ErrorBoundary>
  );

  // Render category content
  const renderContent = () => {
    switch (category) {
      case 'cloudDrive':
        return renderCloudDriveContent();

      case 'favorites':
        return (
          <ErrorBoundary>
            <CloudDriveLayout viewType="favorites">
              <div className="flex-1 overflow-auto">
                <FileBrowserView 
                  folderId={null}
                  viewType="favorites"
                  sortBy="favorited_at"
                  sortOrder="desc"
                  className="h-full"
                />
              </div>
            </CloudDriveLayout>
          </ErrorBoundary>
        );

      case 'encryptedDrives':
        return (
          <div className="h-full">
            <EncryptedDriveSection />
          </div>
        );

        case 'devices':
          // Return device list or detail based on selection
          return selectedDeviceId || expandedDevice ? (
            <DeviceDetailView 
              deviceId={selectedDeviceId || expandedDevice || ''}
              onBack={() => {
                setSelectedDeviceId(null);
                // Let parent component know if needed
              }}
            />
          ) : (
            <DeviceListView 
              onSelectDevice={(deviceId) => {
                logger.debug('Device selected from DeviceListView', {
                  component: 'ContentRenderer',
                  deviceId,
                  action: 'selectDevice'
                });
                setSelectedDeviceId(deviceId);
              }}
              className="h-full"
            />
          );

      case 'services':
        return (
          <ServicesListView className="h-full" />
        );

      case 'shares':
        return (
          <PlaceholderView
            title={t('data_view.shares.title')}
            description={t('data_view.shares.description')}
            icon={<Share2 className="h-8 w-8" />}
          />
        );

      case 'sharedWithMe':
        return (
          <PlaceholderView
            title={t('data_view.shared_with_me.title')}
            description={t('data_view.shared_with_me.description')}
            icon={<Users className="h-8 w-8" />}
          />
        );

      case 'trash':
        return (
          <PlaceholderView
            title={t('data_view.trash.title')}
            description={t('data_view.trash.description')}
            icon={<Trash2 className="h-8 w-8" />}
          />
        );

      case 'coldStorage':
        return (
          <PlaceholderView
            title={t('data_view.cold_storage.title')}
            description={t('data_view.cold_storage.description')}
            icon={<Download className="h-8 w-8" />}
          />
        );

      default:
        return (
          <div className="flex flex-col items-center justify-center h-full p-8 space-y-4">
            <div className="h-12 w-12 rounded-full bg-blue-50 dark:bg-blue-900/20 
                        flex items-center justify-center">
              <Plus className="h-6 w-6 text-blue-500 dark:text-blue-400" />
            </div>
            <div className="text-center space-y-2">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {t('data_view.empty_state.title')}
              </h3>
              <p className="text-gray-500 dark:text-gray-400 text-sm max-w-sm">
                {t('data_view.empty_state.description')}
              </p>
            </div>
          </div>
        );
    }
  };

  return (
    <div className={cn(
      'h-full',
      encryptedDrive.isEncryptedMode && 'bg-blue-50/30 dark:bg-blue-950/20',
      className
    )}>
      {renderContent()}
    </div>
  );
};

export default ContentRenderer;