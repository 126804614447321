/**
 * @file src/services/navigation.service.ts
 * @description Navigation service for handling device, drive, and folder paths with encryption support
 * @version 2.1.0
 * @updated 2025-03-20
 */

import { logger } from '@/utils/logger';
import { cloudDriveService } from './cloud-drive.service';
import { encryptedFilesService } from './encrypted-drive/encrypted-files.service';
import { isValidUUID } from '@/utils/validation';
import type { 
  DeviceInfo, 
  DriveInfo, 
  BreadcrumbSegment,
  NavigationPath 
} from '@/types/navigation.types';

// Define a navigation context interface to make requirements explicit
export interface NavigationContext {
  isEncryptedMode: boolean;
  encryptedDriveId?: string;
}

// Add a folder name to ID mapping cache to avoid repeatedly fetching the same folders
const folderNameToIdMap = new Map<string, string>();

export const navigationService = {
  /**
   * Find drive and device info by drive ID
   */
  findDriveInfo: (
    driveId: string | null,
    devices: DeviceInfo[]
  ): { drive: DriveInfo | null; device: DeviceInfo | null } => {
    try {
      if (!driveId || !devices.length) {
        return { drive: null, device: null };
      }

      for (const device of devices) {
        const drive = device.drives.find((d: any) => d.id === driveId);
        if (drive) {
          return { drive, device };
        }
      }

      return { drive: null, device: null };

    } catch (error) {
      logger.error('Error finding drive info:', {
        component: 'navigationService',
        error,
        data: { driveId }
      });
      return { drive: null, device: null };
    }
  },

  /**
   * Build navigation path with device and drive context
   * Enhanced with encryption support, UUID validation, and better error handling
   */
  buildNavigationPath: async (
    folderId: string | null,
    devices: DeviceInfo[],
    activeDriveId: string | null,
    primaryDriveId: string | null,
    options?: {
      isEncryptedMode?: boolean;
      encryptedDriveId?: string;
    }
  ): Promise<NavigationPath> => {
    try {
      // Check if we're in encrypted mode based on options or localStorage
      const isEncryptedMode = options?.isEncryptedMode || 
                             localStorage.getItem('current_drive_type') === 'encrypted_drive';
      
      const encryptedDriveId = options?.encryptedDriveId || 
                             localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Building navigation path', {
        component: 'navigationService',
        data: {
          folderId,
          activeDriveId,
          primaryDriveId,
          deviceCount: devices.length,
          isEncryptedMode,
          encryptedDriveId
        }
      });

      // Find current drive and device
      const effectiveDriveId = activeDriveId || primaryDriveId;
      const { drive, device } = navigationService.findDriveInfo(effectiveDriveId, devices);

      // Start with device and drive segments if available
      let segments: BreadcrumbSegment[] = [];

      if (device) {
        segments.push({
          id: device.id,
          name: device.name,
          type: 'device',
          path: [device.id],
          deviceId: device.id
        });
      }

      if (drive) {
        segments.push({
          id: drive.id,
          name: drive.name,
          type: 'drive',
          path: device ? [device.id, drive.id] : [drive.id],
          driveId: drive.id,
          deviceId: device?.id
        });
      }

      // If we have a folder ID, get its path using appropriate service
      if (folderId) {
        try {
          // Validate UUID format before proceeding
          if (!isValidUUID(folderId)) {
            logger.warn('Invalid UUID format for folder ID, returning minimal path', {
              component: 'navigationService',
              data: { folderId }
            });
            
            // Add a basic segment for invalid folder ID and return early
            segments.push({
              id: folderId,
              name: "Current Folder",
              type: 'folder',
              path: [...segments.map((s: any) => s.id), folderId],
              driveId: drive?.id,
              deviceId: device?.id
            });
            
            return {
              device: device,
              drive: drive,
              segments,
              currentId: folderId
            };
          }
          
          // Get folder details with enhanced error handling
          let folderDetails;
          
          if (isEncryptedMode && encryptedDriveId) {
            logger.debug('Using encrypted service for folder details', {
              component: 'navigationService',
              data: { folderId, encryptedDriveId }
            });
            
            try {
              folderDetails = await encryptedFilesService.getFileDetails(folderId, encryptedDriveId);
              
              // Store folder name to ID mapping for future reference
              if (folderDetails && folderDetails.name) {
                folderNameToIdMap.set(folderDetails.name, folderDetails.id);
                logger.debug('Stored folder name to ID mapping', {
                  component: 'navigationService',
                  data: {
                    folderName: folderDetails.name,
                    folderId: folderDetails.id
                  }
                });
              }
            } catch (error) {
              const encryptedError = error as Error;
              logger.warn('Error getting encrypted folder details, using fallback', {
                component: 'navigationService',
                error: encryptedError,
                data: { 
                  folderId, 
                  encryptedDriveId,
                  errorMessage: encryptedError.message 
                }
              });
              
              // Create fallback folder details with meaningful name
              folderDetails = {
                id: folderId,
                name: "Current Folder",
                type: 'folder',
                path: [],
                parentId: null
              };
              
              // CRITICAL FIX: Always ensure we add a segment for the current folder
              // even when we can't get proper path information
              segments.push({
                id: folderId,
                name: "Current Folder",
                type: 'folder',
                path: [...segments.map((s) => s.id), folderId],
                driveId: drive?.id,
                deviceId: device?.id
              });
              
              // Return early with at least the device, drive, and current folder segment
              return {
                device: device,
                drive: drive,
                segments,
                currentId: folderId
              };
            }
          }
          else {
            logger.debug('Using standard cloud drive service for folder details', {
              component: 'navigationService',
              data: { folderId }
            });
            
            folderDetails = await cloudDriveService.getFolderDetails(folderId);
          }
          
          // Process folder path with enhanced validation
          if (folderDetails && folderDetails.path) {
            // Store processed path IDs to avoid duplicates and validate UUIDs
            const processedIds = new Set<string>();
            
            // Add each valid path segment
            for (const pathId of folderDetails.path) {
              if (pathId === 'root' || processedIds.has(pathId)) continue;
              
              // Check if pathId is a UUID or a folder name
              let effectivePathId = pathId;
              
              // If not a valid UUID, check if we have a mapping for it
              if (!isValidUUID(pathId)) {
                // Try to find the ID from our name mapping
                if (folderNameToIdMap.has(pathId)) {
                  effectivePathId = folderNameToIdMap.get(pathId)!;
                  logger.debug('Resolved folder name to ID', {
                    component: 'navigationService',
                    data: {
                      folderName: pathId,
                      resolvedId: effectivePathId
                    }
                  });
                } else {
                  logger.warn('Invalid UUID format for path segment, skipping', {
                    component: 'navigationService',
                    data: { pathId }
                  });
                  continue;
                }
              }
              
              // Mark this ID as processed
              processedIds.add(effectivePathId);
              
              // Get details for the path segment
              let details;
              if (isEncryptedMode && encryptedDriveId) {
                try {
                  details = await encryptedFilesService.getFileDetails(
                    effectivePathId,
                    encryptedDriveId
                  );
                  
                  // Store name mapping if we get a valid response
                  if (details && details.name) {
                    folderNameToIdMap.set(details.name, details.id);
                  }
                } catch (encryptedError) {
                  logger.warn('Error getting encrypted path segment details, skipping', {
                    component: 'navigationService',
                    error: encryptedError,
                    data: { 
                      pathId: effectivePathId,
                      encryptedDriveId
                    }
                  });
                  continue;
                }
              } else {
                details = await cloudDriveService.getFolderDetails(effectivePathId);
              }
              
              // Add valid segment
              if (details) {
                segments.push({
                  id: details.id,
                  name: details.name || "Unnamed Folder",
                  type: 'folder',
                  path: [...segments.map((s: any) => s.id), details.id],
                  parentId: details.parentId,
                  driveId: drive?.id,
                  deviceId: device?.id
                });
              }
            }

            // Add current folder if not already in segments
            if (!segments.find((s: any) => s.id === folderId)) {
              segments.push({
                id: folderId,
                name: folderDetails.name || "Current Folder",
                type: 'folder',
                path: [...segments.map((s: any) => s.id), folderId],
                parentId: folderDetails.parentId,
                driveId: drive?.id,
                deviceId: device?.id
              });
            }
          }
        } catch (error) {
          logger.error('Error getting folder details:', {
            component: 'navigationService',
            error,
            data: { 
              folderId, 
              isEncryptedMode,
              encryptedDriveId 
            }
          });
          
          // Add a meaningful segment for the current folder even when details can't be fetched
          segments.push({
            id: folderId,
            name: "Current Folder",
            type: 'folder',
            path: [...segments.map((s: any) => s.id), folderId],
            driveId: drive?.id,
            deviceId: device?.id
          });
        }
      }

      // Log the created path
      logger.debug('Created navigation path', {
        component: 'navigationService',
        data: {
          segmentCount: segments.length,
          path: segments.map(s => s.name).join(' > ')
        }
      });

      return {
        device: device,
        drive: drive,
        segments,
        currentId: folderId
      };

    } catch (error) {
      logger.error('Error building navigation path:', {
        component: 'navigationService',
        error,
        data: {
          folderId,
          isEncryptedMode: options?.isEncryptedMode
        }
      });
      return {
        device: null,
        drive: null,
        segments: [],
        currentId: null
      };
    }
  },

  /**
   * Format segments for display
   */
  formatPathSegments: (
    device: DeviceInfo | null,
    drive: DriveInfo | null,
    segments: BreadcrumbSegment[]
  ): BreadcrumbSegment[] => {
    try {
      return segments.map((segment: any) => ({
        ...segment,
        name: navigationService.formatName(segment, device, drive)
      }));
    } catch (error) {
      logger.error('Error formatting path segments:', {
        component: 'navigationService',
        error
      });
      return segments;
    }
  },

  /**
   * Format individual segment name
   */
  formatName: (
    segment: BreadcrumbSegment,
    device: DeviceInfo | null,
    drive: DriveInfo | null
  ): string => {
    switch (segment.type) {
      case 'device':
        return device?.name || 'Device';
      case 'drive':
        return drive?.name || 'Drive';
      default:
        return segment.name;
    }
  },

  /**
   * Clear folder name to ID mapping cache (useful when locking drives)
   */
  clearFolderMappingCache(driveId?: string): void {
    if (driveId) {
      // Clear specific drive mappings (would need to add driveId to the mapping keys)
      // For now, just clear all since we don't track by driveId
      folderNameToIdMap.clear();
    } else {
      folderNameToIdMap.clear();
    }
    logger.debug('Cleared folder name to ID mapping cache', {
      component: 'navigationService',
      data: { driveId: driveId || 'all' }
    });
  }
};