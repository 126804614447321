/**
 * @file src/components/ui/label.tsx
 * @description Label component with accessibility support
 * @version 1.0.0
 */

import * as React from "react"
import { cn } from "@/utils/utils"

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  htmlFor?: string
}

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(({
  className,
  children,
  ...props
}, ref) => {
  return (
    <label
      ref={ref}
      className={cn(
        "text-sm font-medium leading-none",
        "text-gray-900 dark:text-gray-100",
        "peer-disabled:cursor-not-allowed",
        "peer-disabled:opacity-70",
        className
      )}
      {...props}
    >
      {children}
    </label>
  )
})

Label.displayName = "Label"

export { Label }