/**
 * @file src/components/cloud-drive/dialogs/CreateFolderDialog.tsx
 * @description Enhanced create folder dialog with animations and lazy loading
 * @version 2.1.0
 * @updated 2025-03-19
 */

import React, { lazy, Suspense } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Loader2 } from 'lucide-react';

// Lazy load the dialog content
const CreateFolderDialogContent = lazy(() => import('./CreateFolderDialogContent'));

interface CreateFolderDialogProps {
  isOpen: boolean;
  onClose: () => void;
  parentFolderId: string | null;
}

export const CreateFolderDialog: React.FC<CreateFolderDialogProps> = ({
  isOpen,
  onClose,
  parentFolderId
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md p-0 overflow-hidden">
        <Suspense fallback={
          <div className="flex items-center justify-center p-8">
            <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
          </div>
        }>
          <CreateFolderDialogContent 
            onClose={onClose}
            parentFolderId={parentFolderId}
          />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFolderDialog;