// src/types/audit.types.ts

export enum ActionType {
    // User Management
    USER_ADDED = "User added",
    USER_SUSPENDED = "User suspended",
    USER_DELETED = "User deleted",
    USER_ACTIVATED = "User activated",
    USER_EMAIL_EDITED = "User email edited",
    USER_TYPE_CHANGED = "User type changed",
    USER_NAME_EDITED = "User name edited",
    USER_BLOCKED = "User blocked",
    USER_ACCOUNT_USER_NAME_AND_EMAIL = "User Account User Name And Email",

    // Authentication
    PASSWORD_RESETTED = "Password resetted",
    SIGN_IN = "Sign in",
    SIGN_OUT = "Sign out",

    // Storage Management
    HOT_STORAGE_LIMIT_SET = "Hot storage limit set",
    COLD_STORAGE_LIMIT_SET = "Cold storage limit set",

    // User Invitations
    ADDED_USER_VIA_EMAIL_INVITE = "Added user via email invite",

    // Computer/Device Management
    COMPUTER_ACTIVATED = "Computer activated",
    COMPUTER_SUSPENDED = "Computer suspended",
    COMPUTER_ARCHIVED = "Computer archived",
    COMPUTER_DELETED = "Computer deleted",
    DEVICE_RENAMED = "Device renamed",
    COMPUTER_HOT_STORAGE_LIMIT_SET = "Computer Hot storage limit set",
    COMPUTER_COLD_STORAGE_LIMIT_SET = "Computer Cold storage limit set",

    // Backup Operations
    BACKUP_STARTED = "Backup started",
    BACKUP_ENDED = "Backup ended",
    RESTORE_STARTED = "Restore started",
    RESTORE_ENDED = "Restore ended",

    // Server Management
    SERVER_ADDED = "Server added",
    SERVER_DELETED = "Server deleted",
    SERVER_SUSPENDED = "Server suspended",
    SERVER_ARCHIVED = "Server archived",
    SERVER_ACTIVATED = "Server activated",
    SERVER_HOT_STORAGE_LIMIT_SET = "Server Hot Storage limit set",
    SERVER_COLD_STORAGE_LIMIT_SET = "Server Cold Storage limit set",

    // Deployment
    DEPLOYMENT_TOKEN_GENERATED = "Deployment token generated",
    DEPLOYMENT_PASSWORD_TOKEN_GENERATED = "Deployment password token generated",

    // Policy Management
    POLICY_CREATED = "Policy created",
    POLICY_EDITED = "Policy edited",
    POLICY_DELETED = "Policy deleted",
    POLICY_ASSIGNED = "Policy assigned",
    POLICY_UNASSIGNED = "Policy unassigned",

    // Label Management
    LABEL_CREATED = "Label created",
    LABEL_APPLIED = "Label applied",
    LABEL_UNAPPLIED = "Label unapplied",
    LABEL_DELETED = "Label deleted",

    // Web Management
    WEB_SETTINGS_CHANGED = "Web settings changed",

    // File Operations
    FILES_DELETED_FROM_DEVICE = "Files deleted from device",
    FILES_DELETED_FROM_WEB = "files deleted from the web",
    FILE_OPENED = "File opened",
    FILE_SHARED = "File shared",
    FILE_UNSHARED = "File unshared",
    FILE_BACKED_UP = "File backed up",
    FILE_UPLOADED = "File uploaded",
    FILE_RENAMED_ON_DEVICE = "File renamed on device",
    FILE_RENAMED_FROM_WEB = "File renamed from web",
    FILE_DOWNLOADED = "File downloaded",
    FILE_MOVED = "File moved",
    FILE_COPIED = "File copied",

    // Folder Operations
    NEW_FOLDER_CREATED = "New folder created",

    // Report Management
    REPORT_SETTINGS_UPDATED = "Report settings updated",

    // OCR Settings
    SET_USER_OCR_LIMIT = "Set User OCR Limit",
    SET_MACHINE_OCR_LIMIT = "Set Machine OCR Limit",
    SET_SERVER_OCR_LIMIT = "Set Server OCR Limit",

    // Account Management
    RESTORE_DELETED_FILE_WEB = "Restore Deleted File Web",
    UPDATE_ACCOUNT_USER_NAME_AND_EMAIL = "Update Account User Name And Email",
    CHANGE_ACCOUNT_PASSWORD = "Change Account Password",
    ACCOUNT_UPGRADED = "Account upgraded",

    // External Service Integration
    // Dropbox
    ADD_DROPBOX_MACHINE = "Add Dropbox Machine",
    FORCE_SYNC_DROPBOX_MACHINE = "Force Sync Dropbox Machine",
    UNLINK_DROPBOX_MACHINE = "Unlink Dropbox Machine",
    DELETE_DROPBOX_MACHINE = "Delete Dropbox Machine",

    // Box
    ADD_BOX_MACHINE = "Add Box Machine",
    FORCE_SYNC_BOX_MACHINE = "Force Sync Box Machine",
    UNLINK_BOX_MACHINE = "Unlink Box Machine",
    DELETE_BOX_MACHINE = "Delete Box Machine",

    // Facebook
    ADD_FACEBOOK_MACHINE = "Add Facebook Machine",
    FORCE_SYNC_FACEBOOK_MACHINE = "Force Sync Facebook Machine",
    UNLINK_FACEBOOK_MACHINE = "Unlink Facebook Machine",
    DELETE_FACEBOOK_MACHINE = "Delete Facebook Machine",

    // Google Drive
    ADD_GOOGLE_DRIVE_MACHINE = "Add Google Drive Machine",
    FORCE_SYNC_GOOGLE_DRIVE_MACHINE = "Force Sync Google Drive Machine",
    UNLINK_GOOGLE_DRIVE_MACHINE = "Unlink Google Drive Machine",
    DELETE_GOOGLE_DRIVE_MACHINE = "Delete Google Drive Machine",

    // OneDrive
    ADD_ONE_DRIVE_MACHINE = "Add One Drive Machine",
    FORCE_SYNC_ONE_DRIVE_MACHINE = "Force Sync One Drive Machine",
    UNLINK_ONE_DRIVE_MACHINE = "Unlink One Drive Machine",
    DELETE_ONE_DRIVE_MACHINE = "Delete One Drive Machine",

    // Instagram
    ADD_INSTAGRAM_MACHINE = "Add Instagram Machine",
    FORCE_SYNC_INSTAGRAM_MACHINE = "Force Sync Instagram Machine",
    UNLINK_INSTAGRAM_MACHINE = "Unlink Instagram Machine",
    DELETE_INSTAGRAM_MACHINE = "Delete Instagram Machine",

    // MS SQL
    ADD_MS_SQL_MACHINE = "Add MS SQL Machine",
    DELETE_MS_SQL_MACHINE = "Delete MS SQL Machine",
    ARCHIVE_MS_SQL_MACHINE = "Archive MS SQL Machine",
    RENAME_MS_SQL_MACHINE = "Rename MS SQL Machine",
    DELETE_MS_SQL_FILE = "Delete MS SQL File",
    MS_SQL_BACKUP_STARTED = "MS SQL backup started",
    MS_SQL_BACKUP_ENDED = "MS SQL backup ended",
    MS_SQL_UPLOADED = "MS SQL uploaded",

    // Google Cloud
    ADD_G_CLOUD_MACHINE = "Add G Cloud Machine",
    DELETE_G_CLOUD_MACHINE = "Delete G Cloud Machine",
    CANCEL_G_CLOUD_MACHINE = "Cancel G Cloud Machine",
    RESYNC_G_CLOUD = "ReSync G Cloud",

    // S3
    ADD_S3_MACHINE = "Add S3 Machine",
    DELETE_S3_MACHINE = "Delete S3 Machine",
    CANCEL_S3_MACHINE = "Cancel S3 Machine",
    RESYNC_S3 = "ReSync S3",

    // Search Operations
    SEARCH = "Search",
    SEARCH_DELETED_FILES = "Search Deleted Files",
    SEARCH_FAVORITES_FILES = "Search Favorites Files",
    SEARCH_FILE_TAGS = "Search file tags",

    // Remote Operations
    ADD_REMOTE_RESTORE_FILES = "Add Remote Restore Files",
    DOWNLOAD_REMOTE_RESTORE_FILES = "Download Remote Restore Files",
}

export interface AuditFilters {
    userId?: string;
    deviceId?: string;
    fromDate?: Date | null;  // Changed from string to Date
    toDate?: Date | null;    // Changed from string to Date
    action?: ActionType;
    search?: string;
    page: number;
    pageSize: number;
}

export interface User {
    id: string;
    name: string;
    email: string;
}

export interface Device {
    id: string;
    name: string;
    device_type: string;
    device_name: string;  // Added
    device_state: string; // Added
    status: string;
    platform: string;
}

export interface AuditLog {
    id: string;
    timestamp: string;
    actor: string;
    action: ActionType;
    target: string;
    device_id?: string;
    user_id?: string;
    metadata?: Record<string, any>;
}

export interface User {
    id: string;
    name: string;
    email: string;
}

export interface Device {
    id: string;
    name: string;
    device_type: string;
    status: string;
    platform: string;
}

export interface AuditFilters {
    userId?: string;
    deviceId?: string;
    fromDate?: Date | null;
    toDate?: Date | null;
    action?: ActionType;
    search?: string;
    sortColumn?: string;
    sortDirection?: "asc" | "desc";
}

export interface AuditCursorFilters extends AuditFilters {
    cursor?: string | null;
    limit: number;
}

export interface AuditBaseFilters {
    userId?: string;
    deviceId?: string;
    fromDate?: Date | null;
    toDate?: Date | null;
    action?: ActionType;
    search?: string;
    sortColumn?: string;
    sortDirection?: "asc" | "desc";
}

export interface AuditPageFilters extends AuditBaseFilters {
    page: number;
    pageSize: number;
}

export interface AuditCursorResponse {
    items: AuditLog[];
    nextCursor: string | null;
    previousCursor?: string | null;
    totalItems: number;
}