/**
 * @file src/components/cloud-drive/items/components/ItemCheckbox.tsx
 * @description Enhanced selection checkbox with improved animations and consistent styling
 * @version 2.0.0
 * @updated 2025-03-26
 */

import React, { useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/utils/utils';
import { CheckCircle } from 'lucide-react';

interface ItemCheckboxProps {
  checked: boolean;
  isSelectMode: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  variant?: 'standard' | 'modern';
  absolute?: boolean;
}

export const ItemCheckbox: React.FC<ItemCheckboxProps> = ({
  checked,
  isSelectMode,
  onChange,
  className,
  variant = 'modern', // Default to modern style like in MediaGallery
  absolute = true
}) => {
  // Memory-optimized handler - only create one callback function
  const handleCheckboxChange = useCallback((newValue: boolean, e?: React.MouseEvent) => {
    // Stop propagation to prevent parent item click events
    // This is important for ensuring the checkbox click doesn't trigger item selection
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    
    // Immediate callback without setTimeout to fix propagation issues
    onChange(newValue);
  }, [onChange]);

  // Event handler to stop propagation
  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  // Modern variant (circular, like in MediaGallery)
  if (variant === 'modern') {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{
            opacity: isSelectMode || checked ? 1 : 0.85,
            scale: isSelectMode || checked ? 1 : 0.95,
          }}
          exit={{ opacity: 0, scale: 0.8 }}
          whileHover={{ opacity: 1, scale: 1.05 }}
          className={cn(
            absolute ? "absolute left-2 top-2 z-50" : "relative",
            "checkbox-container", // For easier targeting
            !isSelectMode && !checked && "opacity-0 group-hover:opacity-100",
            "transition-all duration-200 ease-in-out",
            className
          )}
          onClick={stopPropagation}
        >
          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={(e) => {
              stopPropagation(e);
              handleCheckboxChange(!checked, e);
            }}
            className={cn(
              "h-6 w-6 rounded-full",
              "flex items-center justify-center",
              "transition-colors duration-200",
              checked
                ? "bg-blue-500 text-white"
                : "bg-black/30 backdrop-blur-sm text-white hover:bg-black/40",
              "shadow-sm"
            )}
            aria-checked={checked}
            role="checkbox"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleCheckboxChange(!checked);
              }
            }}
            tabIndex={0}
          >
            {checked ? (
              <motion.svg
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                className="h-3 w-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={3}
                  d="M5 13l4 4L19 7"
                />
              </motion.svg>
            ) : (
              <span className="h-2.5 w-2.5 rounded-full border-2 border-white" />
            )}
          </motion.button>
        </motion.div>
      </AnimatePresence>
    );
  }

  // Standard checkbox variant
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{
          opacity: isSelectMode || checked ? 1 : 0.85,
          scale: isSelectMode || checked ? 1 : 0.95,
        }}
        exit={{ opacity: 0, scale: 0.8 }}
        whileHover={{ opacity: 1, scale: 1 }}
        className={cn(
          absolute ? "absolute left-2 top-2 z-50" : "relative",
          "checkbox-container", // For easier targeting
          !isSelectMode && !checked && "opacity-0 group-hover:opacity-85",
          "transition-all duration-200 ease-in-out",
          className
        )}
        onClick={stopPropagation}
      >
        <motion.div 
          whileTap={{ scale: 0.9 }}
          onClick={stopPropagation}
        >
          <Checkbox
            {...({
              checked,
              onCheckedChange: (value) => {
                // Pass the React.MouseEvent from stopPropagation to handleCheckboxChange
                const event = window.event as React.MouseEvent;
                handleCheckboxChange(Boolean(value), event as any);
              },
              className: cn(
                "h-5 w-5",
                "border-2",
                "bg-white dark:bg-gray-800",
                "rounded-md",
                "transition-colors duration-200",
                "hover:border-blue-500 dark:hover:border-blue-400",
                checked && [
                  "border-blue-500 dark:border-blue-400",
                  "bg-blue-500 dark:bg-blue-400",
                ],
                "shadow-sm hover:shadow-md"
              ),
            } as any)}
          />
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ItemCheckbox;