/**
 * @file src/components/layout/sidebar/SidebarUtils.tsx
 * @description Utility section of the sidebar including theme toggle, notifications, and account
 * @version 1.1.0
 * @created 2024-12-05
 */

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Bell, HelpCircle, Globe, Check } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { cn } from '@/utils/utils';
import { ThemeToggle } from '@/components/theme/theme-toggle';
import { buttonHoverAnimation } from './animations';
import {languageService} from '@/services/language.service';
import type { NavItem, NotificationType } from './types';
import AccountMenu from '../AccountMenu';

interface SidebarUtilsProps {
    isSidebarCollapsed: boolean;
    setHoveredItem: (id: string | null) => void;
    updateTooltipPosition: (id: string, element: HTMLDivElement | null) => void;
  }

interface Language {
  code: string;
  nativeName: string;
}

export const SidebarUtils: React.FC<SidebarUtilsProps> = ({
    isSidebarCollapsed,
    setHoveredItem,
    updateTooltipPosition
  }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showNotifications, setShowNotifications] = useState(false);
    const [showLanguages, setShowLanguages] = useState(false);
    const notificationRef = useRef<HTMLDivElement>(null);
    const languageRef = useRef<HTMLDivElement>(null);
    const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  
    // Get available languages
    const languages = languageService.getLanguages();
    const currentLanguage = languageService.getCurrentLanguage();
  
    // Click outside handlers
    React.useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
          setShowNotifications(false);
        }
        if (languageRef.current && !languageRef.current.contains(event.target as Node)) {
          setShowLanguages(false);
        }
      };
  
      if (showNotifications || showLanguages) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showNotifications, showLanguages]);
  
    // Handle language change
    const handleLanguageChange = async (language: Language) => {
      await languageService.setLanguage(language.code);
      setShowLanguages(false);
    };

  // Sample notifications
  const [notifications] = useState<NotificationType[]>([
    { id: 1, message: t('notifications.backup_success'), time: '2m ago', type: 'success' },
    { id: 2, message: t('notifications.storage_warning'), time: '1h ago', type: 'warning' },
    { id: 3, message: t('notifications.new_device', { deviceName: 'MacBook Pro' }), time: '3h ago', type: 'success' },
    { id: 4, message: t('notifications.backup_failed', { location: 'Documents' }), time: '5h ago', type: 'error' },
    { id: 5, message: t('notifications.sync_completed', { service: 'iCloud' }), time: '1d ago', type: 'success' },
  ]);

  const utilityNavItems: NavItem[] = [
    { id: 'help', label: t('sidebar.help'), icon: <HelpCircle className="stroke-[1.5]" />, path: '/help' },
  ];


  const renderUtilityItem = (item: NavItem) => (
    <motion.div
      key={item.id}
      ref={el => itemRefs.current[item.id] = el}
      onHoverStart={() => {
        setHoveredItem(item.id);
        updateTooltipPosition(item.id, itemRefs.current[item.id]);
      }}
      onHoverEnd={() => {
        setHoveredItem(null);
      }}
      onClick={() => navigate(item.path)}
      whileHover={buttonHoverAnimation}
      className={cn(
        "flex items-center px-3 py-2 cursor-pointer",
        "relative group rounded-xl",
        "transition-all duration-300",
        "text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800/50",
        "text-sm h-10"
      )}
    >
      <motion.div 
        className="w-7 h-7 flex items-center justify-center text-gray-500 dark:text-gray-400 
                  group-hover:text-blue-500 dark:group-hover:text-blue-400"
        whileHover={{ rotate: 5 }}
      >
        {item.icon}
      </motion.div>

      <AnimatePresence>
        {!isSidebarCollapsed && (
          <motion.span
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            className="ml-2 font-medium truncate"
          >
            {item.label}
          </motion.span>
        )}
      </AnimatePresence>
    </motion.div>
  );

  return (
    <div className="space-y-1 border-t border-gray-100 dark:border-gray-800 mt-4">
      {/* Theme Toggle */}
      <ThemeToggle />

      {/* Utility Items */}
      {utilityNavItems.map(renderUtilityItem)}

   {/* Language Selector */}
   <div className="relative" ref={languageRef}>
        <motion.button
          whileHover={buttonHoverAnimation}
          onClick={() => setShowLanguages(!showLanguages)}
          className={cn(
            "flex items-center w-full",
            "px-3 py-2",
            "rounded-xl transition-all duration-300",
            "text-gray-700 dark:text-gray-300",
            "hover:bg-gray-50 dark:hover:bg-gray-800/50",
            "text-sm relative"
          )}
        >
          <div className="w-7 h-7 flex items-center justify-center text-gray-500 dark:text-gray-400">
            <Globe className="h-5 w-5" />
          </div>
          {!isSidebarCollapsed && (
            <div className="ml-2 flex items-center justify-between flex-1">
              <span className="font-medium">{currentLanguage.nativeName}</span>
            </div>
          )}
        </motion.button>

        {/* Language Selection Panel */}
        <AnimatePresence>
          {showLanguages && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className={cn(
                "fixed w-48",
                "bg-white dark:bg-gray-900",
                "rounded-lg shadow-xl",
                "border border-gray-200 dark:border-gray-800",
                "overflow-hidden",
                "z-[9999]"
              )}
              style={{
                left: isSidebarCollapsed ? '68px' : '240px',
                bottom: window.innerHeight - (languageRef.current?.getBoundingClientRect().top || 0),
                transform: 'translateY(-100%)'
              }}
            >
              {languages.map((lang) => (
                <button
                  key={lang.code}
                  onClick={() => handleLanguageChange(lang)}
                  className={cn(
                    "w-full px-4 py-2 text-sm flex items-center justify-between",
                    "hover:bg-gray-50 dark:hover:bg-gray-800/50",
                    "transition-colors"
                  )}
                >
                  <span className="font-medium">{lang.nativeName}</span>
                  {currentLanguage.code === lang.code && (
                    <Check className="h-4 w-4 text-green-500" />
                  )}
                </button>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Notifications */}
      <div className="relative" ref={notificationRef}>
        <motion.button
          whileHover={buttonHoverAnimation}
          onClick={() => setShowNotifications(!showNotifications)}
          className={cn(
            "flex items-center w-full",
            "px-3 py-2",
            "rounded-xl transition-all duration-300",
            "text-gray-700 dark:text-gray-300",
            "hover:bg-gray-50 dark:hover:bg-gray-800/50",
            "text-sm relative"
          )}
        >
          <div className="w-7 h-7 flex items-center justify-center text-gray-500 dark:text-gray-400">
            <Bell className="h-5 w-5" />
            {notifications.length > 0 && (
              <span className="absolute top-2 right-2 w-2 h-2 bg-rose-500 rounded-full" />
            )}
          </div>
          {!isSidebarCollapsed && (
            <span className="ml-2 font-medium">Notifications</span>
          )}
        </motion.button>

        {/* Notifications Panel */}

        <AnimatePresence>
        {showNotifications && (
            <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className={cn(
                "fixed w-80",
                "bg-white dark:bg-gray-900",
                "rounded-lg shadow-xl",
                "border border-gray-200 dark:border-gray-800",
                "overflow-hidden",
                "z-[9999]"
            )}
            style={{
                left: isSidebarCollapsed ? '68px' : '240px',
                bottom: window.innerHeight - (notificationRef.current?.getBoundingClientRect().top || 0),
                transform: 'translateY(-100%)', // This will position it above the button
                maxHeight: '400px'
            }}
            >
            <div className="p-4 border-b border-gray-100 dark:border-gray-800">
                <h3 className="font-medium text-gray-900 dark:text-gray-100">
                Notifications
                </h3>
            </div>
            <div className="max-h-[400px] overflow-y-auto">
                {notifications.map((notification) => (
                <motion.div
                    key={notification.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={cn(
                    "p-4 border-b border-gray-100 dark:border-gray-800",
                    "hover:bg-gray-50 dark:hover:bg-gray-800/50",
                    "cursor-pointer transition-colors"
                    )}
                >
                    <div className="flex items-start space-x-3">
                    <div className={cn(
                        "w-2 h-2 mt-1.5 rounded-full flex-shrink-0",
                        notification.type === 'success' && "bg-emerald-500",
                        notification.type === 'warning' && "bg-amber-500",
                        notification.type === 'error' && "bg-rose-500"
                    )} />
                    <div className="flex-1">
                        <p className="text-sm text-gray-600 dark:text-gray-300">
                        {notification.message}
                        </p>
                        <span className="text-xs text-gray-400 dark:text-gray-500">
                        {notification.time}
                        </span>
                    </div>
                    </div>
                </motion.div>
                ))}
            </div>
            </motion.div>
        )}
        </AnimatePresence>
      </div>

      {/* Account Button */}
      <AccountMenu isSidebarCollapsed={isSidebarCollapsed} />
    </div>
  );
};

export default SidebarUtils;