/**
 * @file src/config/constants.ts
 * @description Application constants
 * @version 1.0.0
 * @created 2025-03-04
 */

// Base URL for API requests
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || '/api/v1';

// Other application constants
export const APP_NAME = 'BigMIND 2.0';
export const DEFAULT_PAGINATION_LIMIT = 20;
export const DEFAULT_PAGINATION_PAGE = 1;
export const AUTH_TOKEN_KEY = 'auth_token';
export const USER_DATA_KEY = 'user_data';
export const THEME_KEY = 'app_theme';

// Feature flags
export const FEATURES = {
    ENCRYPTED_DRIVE: true,
    GHOST_MODE: true,
    OCR_PROCESSING: true
};

// Timeouts and intervals
export const REQUEST_TIMEOUT = 30000; // 30 seconds
export const SESSION_REFRESH_INTERVAL = 15 * 60 * 1000; // 15 minutes
export const AUTO_SAVE_INTERVAL = 60 * 1000; // 1 minute
export const SESSION_TIMEOUT_MINUTES = 30; // 30 minutes (matches backend ACCESS_TOKEN_EXPIRE_MINUTES)
export const UPLOAD_HEARTBEAT_INTERVAL = 10 * 60 * 1000; // 10 minutes (for keeping session alive during uploads)

// File size limits
export const MAX_UPLOAD_SIZE = 1024 * 1024 * 1024; // 1GB
export const CHUNKED_UPLOAD_THRESHOLD = 5 * 1024 * 1024; // 5MB