/**
 * @file src/services/upload.service.ts
 * @description Upload service with enhanced drive handling
 * @version 1.2.0
 */

import { UPLOAD_CONFIG } from '@/config/upload.config';
import type {
  UploadProgress,
  UploadResponse,
  UploadStrategyResponse
} from '@/types/upload.types';
import { logger } from '@/utils/logger';
import { getAuthHeaders } from '@/utils/api-utils';
import { compressionService } from './compression.service';
import { multipartUploadService } from './multipart-upload.service';
import { encryptedUploadService } from './encrypted-drive/encrypted-upload.service';
import type { EncryptionTier } from '@/types/encrypted-drive.types';

class UploadError extends Error {
  constructor(message: string, public code: string) {
    super(message);
    this.name = 'UploadError';
  }
}

const calculateSpeed = (bytesUploaded: number, elapsedMs: number): number => {
  if (elapsedMs === 0) return 0;
  return (bytesUploaded * 1000) / elapsedMs; // bytes per second
};

export const uploadService = {
  /**
   * Upload a single file with progress tracking and drive ID
   */
  // In src/services/upload.service.ts
  // Replace the entire uploadFile function with this:
  // In src/services/upload.service.ts
  // Replace the uploadFile function with:
  /**
   * Upload a single file with progress tracking and drive ID
   */
  async uploadFile(
    file: File,
    parentId: string | null,
    driveId: string,
    onProgress?: (progress: UploadProgress) => void,
    encryptedOptions?: {
      isEncrypted: boolean;
      encryptedDriveId: string;
      encryptionTier: string;
    }
  ): Promise<UploadResponse> {
    try {
      // Check if this is an encrypted upload
      if (encryptedOptions?.isEncrypted) {
        logger.debug('Delegating to encrypted upload service', {
          component: 'uploadService',
          data: {
            fileName: file.name,
            fileSize: file.size,
            encryptedDriveId: encryptedOptions.encryptedDriveId,
            encryptionTier: encryptedOptions.encryptionTier
          }
        });

        // Validate required encryption parameters
        if (!encryptedOptions.encryptedDriveId) {
          throw new Error('Missing encrypted drive ID for encrypted upload');
        }

        if (!encryptedOptions.encryptionTier) {
          throw new Error('Missing encryption tier for encrypted upload');
        }

        // Use the encrypted upload service with enhanced progress tracking
        try {
          return await encryptedUploadService.uploadFile(file, {
            driveId: encryptedOptions.encryptedDriveId,
            parentId,
            encryptionTier: encryptedOptions.encryptionTier as EncryptionTier,
            onProgress: (encProgress) => {
              if (onProgress) {
                // Transform the progress data to include encryption information
                onProgress({
                  loaded: encProgress.loaded || 0,
                  total: encProgress.total || file.size,
                  speed: encProgress.speed || 0,
                  // Add encryption-specific details
                  encryptionStatus: encProgress.encryptionStatus,
                  originalSize: encProgress.originalSize || file.size,
                  encryptedSize: encProgress.encryptedSize
                } as unknown as UploadProgress & { encryptionProgress: unknown });
              }
            }
          });
        } catch (error) {
          logger.error('Encrypted upload failed in service:', {
            component: 'uploadService',
            error,
            data: {
              fileName: file.name,
              encryptedDriveId: encryptedOptions.encryptedDriveId
            }
          });
          // Rethrow error - no fallback for encrypted uploads
          throw error;
        }
      }

      logger.debug('Starting file upload:', {
        component: 'uploadService',
        data: {
          fileName: file.name,
          fileSize: file.size,
          mimeType: file.type,
          parentId,
          driveId
        }
      });

      // Validate drive ID
      if (!driveId) {
        logger.error('Upload failed: No drive ID provided', {
          component: 'uploadService',
          data: { fileName: file.name }
        });
        throw new UploadError('No drive ID provided', 'NO_DRIVE');
      }

      // Try to compress file if suitable
      const compressedFile = await compressionService.compressFile(file);

      // Log compression attempt result
      logger.debug('Compression attempt result:', {
        component: 'uploadService',
        data: {
          fileName: file.name,
          originalSize: compressedFile.originalSize,
          compressed: compressedFile.compressed,
          newSize: compressedFile.data.size,
          compressionRatio: compressedFile.compressionRatio
        }
      });

      const fileToUpload = compressedFile.compressed ? compressedFile.data : file;

      // For large files or video files that exceed threshold, delegate to multipart service
      const isVideo = file.type.startsWith('video/');
      const videoSizeThreshold = 5 * 1024 * 1024; // 5MB threshold for videos

      if (fileToUpload.size > UPLOAD_CONFIG.chunkSize || (isVideo && fileToUpload.size > videoSizeThreshold)) {
        logger.debug('Delegating to multipart upload service', {
          component: 'uploadService',
          data: {
            fileName: file.name,
            fileSize: fileToUpload.size,
            isVideo,
            reason: fileToUpload.size > UPLOAD_CONFIG.chunkSize ?
              'file exceeds chunk size' : 'video exceeds size threshold'
          }
        });

        return this.uploadMultipart(
          fileToUpload,
          parentId,
          driveId,
          onProgress,
          compressedFile.compressed ? {
            compressed: true,
            originalSize: compressedFile.originalSize,
            compressionRatio: compressedFile.compressionRatio
          } : undefined
        );
      }

      // For small files, use simple upload
      const formData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('filename', file.name);
      formData.append('mime_type', file.type);
      formData.append('drive_id', driveId);
      if (parentId) formData.append('parent_id', parentId);

      // Add compression metadata if compressed
      if (compressedFile.compressed) {
        formData.append('compressed', 'true');
        formData.append('original_size', compressedFile.originalSize.toString());
        formData.append('compression_ratio', compressedFile.compressionRatio?.toString() || '');
      }

      const response = await fetch(UPLOAD_CONFIG.endpoints.simple, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorResponse = await response.json().catch(() => null);
        logger.error('Upload failed', {
          component: 'uploadService',
          data: {
            fileName: file.name,
            status: response.status,
            error: errorResponse?.detail || response.statusText
          }
        });
        throw new UploadError(
          errorResponse?.detail || 'Upload failed',
          `HTTP_${response.status}`
        );
      }

      const responseData = await response.json();
      logger.debug('Upload completed successfully', {
        component: 'uploadService',
        data: {
          fileName: file.name,
          driveId,
          response: responseData
        }
      });

      return responseData;

    } catch (error) {
      logger.error('Upload error:', {
        component: 'uploadService',
        error,
        data: {
          fileName: file.name,
          isEncrypted: !!encryptedOptions?.isEncrypted,
          driveId: encryptedOptions?.isEncrypted ? encryptedOptions.encryptedDriveId : driveId
        }
      });
      throw error;
    }
  },

  /**
   * Upload a file using multipart upload with drive ID
   */
  uploadMultipart: async (
    file: File | Blob,
    parentId: string | null,
    driveId: string,
    onProgress?: (progress: UploadProgress) => void,
    compressionInfo?: { compressed: boolean; originalSize: number; compressionRatio?: number }
  ): Promise<UploadResponse> => {
    try {
      logger.debug('Initializing multipart upload', {
        component: 'uploadService',
        action: 'uploadMultipart',
        data: {
          fileName: file instanceof File ? file.name : 'blob',
          fileSize: file.size,
          driveId,
          parentId: parentId || 'root',
          compressed: compressionInfo?.compressed
        }
      });

      // Initialize upload with compression info
      const formData = new FormData();
      formData.append('file_size', file.size.toString());
      formData.append('mime_type', file instanceof File ? file.type : 'application/octet-stream');
      formData.append('drive_id', driveId);
      if (parentId) formData.append('parent_id', parentId);

      // Add compression metadata if compressed
      if (compressionInfo?.compressed) {
        formData.append('compressed', 'true');
        formData.append('original_size', compressionInfo.originalSize.toString());
        formData.append('compression_ratio', compressionInfo.compressionRatio?.toString() || '');
      }

      const initResponse = await fetch(
        `${UPLOAD_CONFIG.endpoints.multipart.initialize}?drive_id=${driveId}`,
        {
          method: 'POST',
          body: formData
        }
      );

      if (!initResponse.ok) {
        throw new UploadError('Failed to initialize upload', `HTTP_${initResponse.status}`);
      }

      const { upload_id, chunk_size, total_chunks } = await initResponse.json();

      logger.debug('Multipart upload initialized', {
        component: 'uploadService',
        action: 'uploadMultipart',
        data: {
          uploadId: upload_id,
          totalChunks: total_chunks,
          driveId
        }
      });

      // Upload chunks with progress tracking
      let uploadedChunks = 0;
      const startTime = Date.now();

      for (let i = 0; i < total_chunks; i++) {
        const start = i * chunk_size;
        const end = Math.min(start + chunk_size, file.size);
        const chunk = file.slice(start, end);

        const chunkFormData = new FormData();
        chunkFormData.append('chunk', chunk);
        chunkFormData.append('chunk_index', i.toString());
        chunkFormData.append('upload_id', upload_id);
        chunkFormData.append('drive_id', driveId);

        const chunkResponse = await fetch(
          UPLOAD_CONFIG.endpoints.multipart.chunk,
          {
            method: 'POST',
            body: chunkFormData
          }
        );

        if (!chunkResponse.ok) {
          throw new UploadError('Chunk upload failed', `HTTP_${chunkResponse.status}`);
        }

        uploadedChunks++;
        if (onProgress) {
          const elapsedMs = Date.now() - startTime;
          onProgress({
            loaded: uploadedChunks * chunk_size,
            total: file.size,
            speed: calculateSpeed(uploadedChunks * chunk_size, elapsedMs)
          });
        }
      }

      logger.debug('Completing multipart upload', {
        component: 'uploadService',
        action: 'uploadMultipart',
        data: {
          uploadId: upload_id,
          driveId
        }
      });

      const completeResponse = await fetch(
        UPLOAD_CONFIG.endpoints.multipart.complete,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          } as HeadersInit,
          body: JSON.stringify({
            upload_id,
            drive_id: driveId
          })
        }
      );

      if (!completeResponse.ok) {
        throw new UploadError('Failed to complete upload', `HTTP_${completeResponse.status}`);
      }

      return completeResponse.json();

    } catch (error) {
      logger.error('Multipart upload error:', {
        component: 'uploadService',
        error,
        data: {
          fileName: file instanceof File ? file.name : 'blob',
          driveId
        }
      });
      throw error;
    }
  },

  /**
   * Cancel an ongoing upload
   */
  cancelUpload: async (uploadId: string, driveId: string): Promise<void> => {
    logger.debug('Cancelling upload', {
      component: 'uploadService',
      data: { uploadId }
    });

    try {
      await multipartUploadService.abortUpload(uploadId, driveId);
    } catch (error) {
      logger.error('Error cancelling upload:', {
        component: 'uploadService',
        error,
        data: { uploadId }
      });
      throw error;
    }
  },


  async getUploadStrategy(): Promise<UploadStrategyResponse> {
    try {
      logger.debug('Fetching upload strategy from API', {
        component: 'uploadService',
        action: 'getUploadStrategy'
      });

      // Get auth headers from central utility
      const headers = {
        ...getAuthHeaders(),
        'X-Auth-Source': 'upload-service'
      };
      
      const response = await fetch('/api/v1/cloud-drive/upload/strategy', {
        method: 'GET',
        credentials: 'include', // Important for auth
        headers: headers as HeadersInit
      });

      if (!response.ok) {
        logger.error('Upload strategy fetch failed', {
          component: 'uploadService',
          action: 'getUploadStrategy',
          data: {
            status: response.status,
            statusText: response.statusText
          }
        });
        throw new Error(`Failed to get upload strategy: ${response.statusText}`);
      }

      const data = await response.json();

      logger.debug('Upload strategy fetch succeeded', {
        component: 'uploadService',
        action: 'getUploadStrategy',
        data: {
          mode: data.strategy.default_mode,
          version: data.strategy.version,
          compressionEnabled: data.strategy.compression.enabled
        }
      });

      return data;
    } catch (error) {
      logger.error('Upload strategy fetch error', {
        component: 'uploadService',
        action: 'getUploadStrategy',
        error
      });
      throw error;
    }
  }


};