/**
 * @file src/context/root-drive/RootDriveContext.tsx
 * @description Enhanced root drive context with React Query integration
 * @version 1.2.1
 */

import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useSystemQueries } from '@/hooks/root-drive/useSystemQueries';
import { useQueryClient } from '@tanstack/react-query';
import { logger } from '@/utils/logger';
import type { 
  Device, 
  Drive, 
  DeviceWithDrives,
  SystemOverview,
  UploadStrategy
} from '@/types/root-drive.types';
import type { EncryptedDriveState } from '@/context/cloud-drive/types';

interface RootDriveContextType {
  // State
  devices: DeviceWithDrives[];
  activeDriveId: string | null;
  primaryDriveId: string | null;
  systemDevice: DeviceWithDrives | null; 
  isLoading: boolean;
  error: Error | null;
  uploadStrategy: UploadStrategy | null;
  encryptedDrive?: EncryptedDriveState;
  
  // Getters
  getDeviceById: (deviceId: string) => Device | undefined;
  getDrivesByDevice: (deviceId: string) => Drive[];
  getDefaultDriveId: () => string | null;
  
  // Actions
  setActiveDriveId: (id: string | null) => void;
  refreshDevices: () => Promise<void>;
  refreshDeviceDrives: (deviceId: string) => Promise<void>;
  refreshUploadStrategy: () => Promise<void>;
}

const RootDriveContext = createContext<RootDriveContextType | undefined>(undefined);

const ACTIVE_DRIVE_KEY = 'bigmind_active_drive_id';

interface UploadStrategyResponse {
  success: boolean;
  strategy: {
    default_mode: 'relay' | 'direct';
    version: string;
    thresholds: {
      relay: {
        min_size: number;
        max_size: number;
        chunk_size: number;
      };
    };
    compression: {
      enabled: boolean;
      max_size: number;
      mime_types: string[];
    };
  };
}

export const RootDriveProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const [activeDriveId, setActiveDriveId] = useState<string | null>(null);
  
  // Add default values for the case when useSystemQueries returns unexpected results
  const systemQueries = useSystemQueries() || {};
  const { 
    system: systemQuery = { data: undefined, isLoading: false, error: null }, 
    getDeviceDrives = () => Promise.resolve([]), 
    strategyQuery = { data: undefined, isLoading: false, error: null } 
  } = systemQueries;

  // Use the strategy from query with added safety checks
  const uploadStrategy = useMemo(() => {
    // Fix: Add proper checks to handle undefined data
    if (
      strategyQuery?.data && 
      typeof strategyQuery.data === 'object' && 
      'success' in strategyQuery.data && 
      strategyQuery.data.success === true
    ) {
      try {
        // Use a safer assertion
        const typedData = strategyQuery.data as unknown as UploadStrategyResponse;
        logger.debug('Upload strategy available', {
          component: 'RootDriveProvider',
          action: 'getUploadStrategy',
          data: {
            mode: typedData.strategy?.default_mode,
            version: typedData.strategy?.version
          }
        });
        if ('strategy' in strategyQuery.data) {
          // Map the strategy from response to the expected UploadStrategy type
          const strategy = typedData.strategy;
          return {
            default_mode: strategy.default_mode,
            version: strategy.version,
            thresholds: strategy.thresholds,
            compression: strategy.compression
          };
        }
        return null;
      } catch (error) {
        logger.error('Error parsing strategy data', {
          component: 'RootDriveProvider',
          error
        });
        return null;
      }
    }
    return null;
  }, [strategyQuery]);

  // Memoized system data with safety checks
  const {
    devices = [],
    primaryDriveId = null,
    error = null,
    systemDevice = null
  } = useMemo(() => {
    if (systemQuery?.data) {
      try {
        const data = systemQuery.data as SystemOverview;
        const systemDevice = data.devices?.find((d: any) => 
          d.device_type === 'system' && d.config?.isRoot
        );
        
        return {
          devices: data.devices || [],
          primaryDriveId: data.primaryDriveId,
          systemDevice: systemDevice || null,
          error: null
        };
      } catch (error) {
        logger.error('Error parsing system data', {
          component: 'RootDriveProvider',
          error
        });
        return {
          devices: [],
          primaryDriveId: null,
          systemDevice: null,
          error: error as Error
        };
      }
    }
    return {
      devices: [],
      primaryDriveId: null,
      systemDevice: null,
      error: systemQuery?.error as Error | null
    };
  }, [systemQuery?.data, systemQuery?.error]);

  // Initialize active drive ID from storage or primary drive
  useEffect(() => {
    // Check if user is authenticated
    const isAuthenticated = localStorage.getItem('access_token') !== null;
    
    if (!isAuthenticated) {
      // If not authenticated, clear active drive
      setActiveDriveId(null);
      // Optionally remove from localStorage
      localStorage.removeItem(ACTIVE_DRIVE_KEY);
      return;
    }
    
    const storedDriveId = localStorage.getItem(ACTIVE_DRIVE_KEY);
    const effectiveDriveId = storedDriveId || primaryDriveId;
    
    if (effectiveDriveId) {
      logger.debug('Setting initial drive ID', {
        component: 'RootDriveProvider',
        action: 'initializeDriveId',
        data: {
          storedDriveId,
          primaryDriveId,
          effectiveDriveId
        }
      });
      setActiveDriveId(effectiveDriveId);
      localStorage.setItem(ACTIVE_DRIVE_KEY, effectiveDriveId);
    }
  }, [primaryDriveId]);

  // Store active drive ID changes
  useEffect(() => {
    if (activeDriveId) {
      localStorage.setItem(ACTIVE_DRIVE_KEY, activeDriveId);
    }
  }, [activeDriveId]);

  // Log when strategy changes
  useEffect(() => {
    logger.info('Upload strategy state updated', {
      component: 'RootDriveProvider',
      action: 'strategyEffect',
      data: {
        hasStrategy: !!uploadStrategy,
        mode: uploadStrategy?.default_mode,
        isLoading: strategyQuery?.isLoading
      }
    });
  }, [uploadStrategy, strategyQuery?.isLoading]);

  // Add refresh strategy function
  const refreshUploadStrategy = useCallback(async () => {
    logger.debug('Refreshing upload strategy', {
      component: 'RootDriveProvider',
      action: 'refreshUploadStrategy'
    });
    await queryClient.invalidateQueries({ 
      queryKey: ['system', 'upload-strategy'] 
    });
  }, [queryClient]);

  // Utility functions with safety checks
  const getDeviceById = useCallback((deviceId: string) => {
    return devices.find((d: any) => d.id === deviceId);
  }, [devices]);

  const getDrivesByDevice = useCallback((deviceId: string) => {
    return devices.find((d: any) => d.id === deviceId)?.drives || [];
  }, [devices]);

  const getDefaultDriveId = useCallback(() => {
    return primaryDriveId || activeDriveId || null;
  }, [primaryDriveId, activeDriveId]);

  // Refresh functions
  const refreshDevices = useCallback(async () => {
    logger.debug('Refreshing devices', {
      component: 'RootDriveProvider',
      action: 'refreshDevices'
    });
    await queryClient.invalidateQueries({ queryKey: ['system', 'overview'] });
  }, [queryClient]);

  const refreshDeviceDrives = useCallback(async (deviceId: string) => {
    logger.debug('Refreshing device drives', {
      component: 'RootDriveProvider',
      action: 'refreshDeviceDrives',
      data: { deviceId }
    });
    await queryClient.invalidateQueries({ 
      queryKey: ['system', 'drives', deviceId] 
    });
  }, [queryClient]);

  const contextValue: RootDriveContextType = useMemo(() => ({
    // State
    devices,
    activeDriveId,
    primaryDriveId,
    systemDevice,
    isLoading: systemQuery?.isLoading || strategyQuery?.isLoading || false,
    error,
    uploadStrategy,
  
    // Getters
    getDeviceById,
    getDrivesByDevice,
    getDefaultDriveId,
  
    // Actions
    setActiveDriveId,
    refreshDevices,
    refreshDeviceDrives,
    refreshUploadStrategy
  }), [
    devices,
    activeDriveId,
    primaryDriveId,
    systemDevice,
    systemQuery?.isLoading,
    strategyQuery?.isLoading,
    error,
    uploadStrategy,
    getDeviceById,
    getDrivesByDevice,
    getDefaultDriveId,
    refreshDevices,
    refreshDeviceDrives,
    refreshUploadStrategy
  ]);

  return (
    <RootDriveContext.Provider value={contextValue}>
      {children}
    </RootDriveContext.Provider>
  );
};

export const useRootDrive = () => {
  const context = useContext(RootDriveContext);
  if (!context) {
    throw new Error('useRootDrive must be used within RootDriveProvider');
  }
  return context;
};