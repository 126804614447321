/**
 * @file src/layouts/ghost/components/sidebar/components/CreateCoreButton.tsx
 * @description Create core button with improved collapsed state
 * @version 1.2.0
 */

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Sparkles } from 'lucide-react';
import { cn } from '@/utils/utils';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { CreateCoreDialog } from '../../create-core/CreateCoreDialog';
import { logger } from '@/utils/logger';

interface CreateCoreButtonProps {
  isCollapsed: boolean;
}

export const CreateCoreButton: React.FC<CreateCoreButtonProps> = ({
  isCollapsed
}) => {
  const { t } = useGhostTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = () => {
    logger.debug('Create core button clicked');
    setDialogOpen(true);
  };

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={handleClick}
        className={cn(
          "w-full relative group",
          isCollapsed ? "h-10" : "h-12",
          "rounded-xl overflow-hidden",
          "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
          "flex items-center justify-center"
        )}
      >
        {/* Sparkle effects */}
        <div className={cn(
          "absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity",
          "bg-[radial-gradient(circle_at_center,_var(--ghost-accent-loud)_0%,_transparent_50%)]",
          "blur-xl"
        )} />

        <div className={cn(
          "relative flex items-center",
          isCollapsed ? "justify-center" : "justify-center space-x-2"
        )}>
          <Plus className={cn(
            "text-white",
            isCollapsed ? "h-4 w-4" : "h-5 w-5"
          )} />
          
          <AnimatePresence>
            {!isCollapsed && (
              <motion.div
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
                className="flex items-center"
              >
                <span className="text-white font-medium">
                  {String(t('core.create'))}
                </span>
                <Sparkles className="h-4 w-4 ml-2 text-white/50" />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Hover glow effect */}
        <div className="absolute inset-0 opacity-0 group-hover:opacity-20 transition-opacity">
          <div className="absolute inset-0 bg-white transform rotate-45 translate-y-full group-hover:translate-y-0 transition-transform duration-300" />
        </div>
      </motion.button>

      <CreateCoreDialog 
        open={dialogOpen}
        onOpenChange={setDialogOpen}
      />
    </>
  );
};

export default CreateCoreButton;