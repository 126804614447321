/**
 * @file src/hooks/cloud-drive/useCloudDrive.ts
 * @description Collection of React Query hooks for cloud drive operations
 * @version 1.2.0
 * @created 2024-12-21
 * @updated 2025-04-03
 */

import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useToast } from '@/components/ui/toast';
import { cloudDriveService } from '@/services/cloud-drive.service';
import type {
  DriveItemsResponse,
  DriveFile,
  DriveFolder,
  ShareSettings,
  StorageQuota,
  BatchOperationResponse,
  DriveItem
} from '@/types/cloud-drive.types';

// Define UploadResponse type since it's missing
interface UploadResponse {
  success: boolean;
  message: string;
  files?: { id: string; name: string }[];
}

// Query keys for better cache management
export const driveKeys = {
  all: ['cloud-drive'] as const,
  folders: (id: string | null) => [...driveKeys.all, 'folder', id] as const,
  items: (id: string | null) => [...driveKeys.all, 'items', id] as const,
  quota: () => [...driveKeys.all, 'quota'] as const,
  shares: (id: string) => [...driveKeys.all, 'shares', id] as const,
  search: (query: string) => [...driveKeys.all, 'search', query] as const,
};

interface FolderContentsResponse {
  items: DriveItem[];
  total: number;
  hasMore: boolean;
  nextCursor: string | null;
  driveId?: string;
}

export const useFolderContents = (folderId: string | null) => {
  return useInfiniteQuery<FolderContentsResponse>({
    queryKey: driveKeys.items(folderId),
    queryFn: async ({ pageParam }) => {
      const page = pageParam as string | undefined;
      const result = await cloudDriveService.listFolderContents(folderId, page);
      return {
        ...result,
        items: result.items || [],
        hasMore: !!result.hasMore,
        nextCursor: result.nextCursor || null,
      };
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.nextCursor ?? undefined : undefined,
    initialPageParam: undefined,
    enabled: true,
    refetchOnMount: true,
    initialData: {
      pages: [{ items: [], total: 0, hasMore: false, nextCursor: null }],
      pageParams: [undefined],
    },
  });
};

export const useStorageQuota = () => {
  const { showToast } = useToast();

  return useQuery<StorageQuota, Error>({
    queryKey: driveKeys.quota(),
    queryFn: async () => {
      try {
        return await cloudDriveService.getStorageQuota();
      } catch (error) {
        console.error('Error fetching storage quota:', error);
        showToast('Failed to load storage quota', 'error');
        throw error;
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};

export const useFileOperations = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const uploadMutation = useMutation({
    mutationFn: ({
      files,
      folderId,
      onProgress
    }: {
      files: File[];
      folderId: string | null;
      onProgress?: (progress: number) => void;
    }) => cloudDriveService.uploadFiles(files, folderId, onProgress),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: driveKeys.items(variables.folderId) });
      queryClient.invalidateQueries({ queryKey: driveKeys.quota() });
      showToast('Files uploaded successfully', 'success');
    },
    onError: (error: Error) => {
      console.error('Upload error:', error);
      showToast('Failed to upload files', 'error');
    }
  });

  const deleteMutation = useMutation<BatchOperationResponse, Error, string[]>({
    mutationFn: async (itemIds: string[]) => {
      const result = await cloudDriveService.deleteItems(itemIds);
      return {
        successful: result.success ? itemIds : [],
        failed: result.success ? [] : itemIds.map(id => ({ id, error: 'Failed to delete item' }))
      };
    },
    onSuccess: (result) => {
      queryClient.invalidateQueries({ queryKey: driveKeys.all });
      showToast(`items deleted successfully`, 'success');
      if (result.failed) {
        showToast(`items failed to delete`, 'error');
      }
    },
    onError: (error: Error) => {
      console.error('Delete error:', error);
      showToast('Failed to delete items', 'error');
    }
  });

  return {
    uploadFiles: uploadMutation.mutate,
    deleteItems: deleteMutation.mutate,
    isUploading: uploadMutation.isPending,
    isDeleting: deleteMutation.isPending
  };
};

export const useShareOperations = (itemId: string) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const shareQuery = useQuery<ShareSettings[], Error>({
    queryKey: driveKeys.shares(itemId),
    queryFn: async () => {
      try {
        const shares = await cloudDriveService.getShareSettings(itemId);
        return shares;
      } catch (error) {
        console.error('Error fetching share settings:', error);
        showToast('Failed to load share settings', 'error');
        throw error;
      }
    },
    enabled: !!itemId
  });

  const updateShareMutation = useMutation<void, Error, ShareSettings>({
    mutationFn: async (settings: ShareSettings) => {
      await cloudDriveService.updateShareSettings(itemId, settings);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: driveKeys.shares(itemId) });
      showToast('Share settings updated successfully', 'success');
    },
    onError: (error: Error) => {
      console.error('Share update error:', error);
      showToast('Failed to update share settings', 'error');
    }
  });

  return {
    shareSettings: shareQuery.data,
    isLoading: shareQuery.isPending,
    updateShare: updateShareMutation.mutate,
    isUpdating: updateShareMutation.isPending
  };
};

export const useCloudDrive = () => {
  try {
    // Try to use the context first (preferred method)
    const { useCloudDrive } = require('@/context/cloud-drive');
    return useCloudDrive();
  } catch (error) {
    // Fallback to direct implementation if context not available
    // This handles the case where provider isn't available at init time
    const folderContents = useFolderContents;
    const storageQuota = useStorageQuota;
    const fileOps = useFileOperations();
    const shareOps = useShareOperations;

    const getFileDetails = (fileId: string | null) => {
      const { showToast } = useToast();

      return useQuery<DriveFolder, Error>({
        queryKey: ['cloud-drive', 'file', fileId],
        queryFn: async () => {
          if (!fileId) throw new Error('No file ID provided');
          try {
            return await cloudDriveService.getFolderDetails(fileId);
          } catch (error) {
            console.error('Error fetching file details:', error);
            showToast('Failed to load file details', 'error');
            throw error;
          }
        },
        enabled: !!fileId
      });
    };

    const getItemDetails = (itemId: string | null) => {
      const { showToast } = useToast();

      return useQuery<any, Error>({
        queryKey: [...driveKeys.all, 'item-details', itemId],
        queryFn: async () => {
          if (!itemId) throw new Error('No item ID provided');
          try {
            const response = await cloudDriveService.getItemDetails(itemId);
            return response.data;
          } catch (error) {
            console.error('Error fetching item details:', error);
            showToast('Failed to load item details', 'error');
            throw error;
          }
        },
        enabled: !!itemId
      });
    };

    // Return a dummy encryptedDrive value for components that expect it
    const encryptedDrive = {
      isEncryptedMode: false,
      encryptedDriveId: undefined,
      encryptionTier: undefined,
      driveName: undefined
    };

    return {
      ...fileOps,
      getShareOperations: shareOps,
      getFolderContents: folderContents,
      getFileDetails,
      getStorageQuota: storageQuota,
      getItemDetails,
      encryptedDrive, // Added for components that need it
    };
  }
};