/**
 * @file src/utils/api-utils.ts
 * @description Helper utilities for API calls
 * @version 1.0.0
 */

import { logger } from './logger';
import { authService } from '@/services/auth.service';

/**
 * Get authentication headers for API requests with centralized token handling
 * This is the official, centralized auth header utility that should be used by all services
 */
// Import AuthRefreshManager
import { authRefreshManager } from '@/components/ui/AuthRefreshModal';

// Add global function for detecting token expiration and triggering refresh
let lastRefreshAttempt = 0;
const MIN_REFRESH_INTERVAL = 10000; // 10 seconds minimum between refresh attempts
let refreshFailureCount = 0;
const MAX_SILENT_FAILURES = 2; // After this many failures, show the refresh dialog

// Function to handle token expiration
export const checkAndRefreshToken = async () => {
  const currentTime = Date.now();
  
  // Prevent refresh storm by enforcing minimum interval between attempts
  if (currentTime - lastRefreshAttempt < MIN_REFRESH_INTERVAL) {
    return false;
  }
  
  try {
    // Check if we have a token to examine
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) return false;
    
    // Remove Bearer prefix if present
    const token = accessToken.startsWith('Bearer ') 
      ? accessToken.substring(7) 
      : accessToken;
      
    // Decode token to check expiration
    const parts = token.split('.');
    if (parts.length !== 3) return false;
    
    // Decode the payload
    const payload = JSON.parse(atob(parts[1]));
    if (!payload.exp) return false;
    
    // Check expiration time
    const expiryTime = payload.exp * 1000; // convert to milliseconds
    const timeUntilExpiry = expiryTime - currentTime;
    
    // If token is expiring soon (within 5 minutes), refresh it
    if (timeUntilExpiry < 300000 && timeUntilExpiry > 0) {
      logger.debug('Token expiring soon, attempting refresh', {
        component: 'apiUtils',
        timeUntilExpiry: Math.round(timeUntilExpiry / 1000),
        tokenExp: new Date(expiryTime).toISOString()
      });
      
      lastRefreshAttempt = currentTime;
      
      // Try to get AuthContext to trigger refresh
      const authContext = window.__REACT_AUTH_CONTEXT__;
      if (authContext && typeof authContext.refreshSession === 'function') {
        try {
          const result = await authContext.refreshSession();
          
          if (result) {
            // Reset failure count on success
            refreshFailureCount = 0;
            return true;
          } else {
            // Count this as a failure
            refreshFailureCount++;
            
            // After multiple failures, show the auth refresh dialog
            if (refreshFailureCount >= MAX_SILENT_FAILURES) {
              authRefreshManager.showDialog();
            }
            return false;
          }
        } catch (error) {
          // Count refresh errors
          refreshFailureCount++;
          
          // After multiple failures, show the auth refresh dialog
          if (refreshFailureCount >= MAX_SILENT_FAILURES) {
            authRefreshManager.showDialog();
          }
          return false;
        }
      }
      
      // No auth context available
      refreshFailureCount++;
      if (refreshFailureCount >= MAX_SILENT_FAILURES) {
        authRefreshManager.showDialog();
      }
      return false;
    }
    
    // If token is already expired, increment failure count and possibly show dialog
    if (timeUntilExpiry <= 0) {
      refreshFailureCount++;
      if (refreshFailureCount >= MAX_SILENT_FAILURES) {
        authRefreshManager.showDialog();
      }
    }
    
    return false;
  } catch (error) {
    logger.error('Error checking token expiration', {
      component: 'apiUtils',
      error
    });
    
    // Count decoding errors as failures too
    refreshFailureCount++;
    if (refreshFailureCount >= MAX_SILENT_FAILURES) {
      authRefreshManager.showDialog();
    }
    
    return false;
  }
};

export const getAuthHeaders = (requireContentType = true): Record<string, string> => {
  try {
    // Check for auth page - never send auth headers from auth pages
    const path = window.location.pathname;
    const isAuthPage = path.includes('/auth/') || path.includes('/ghost/auth/');
    
    if (isAuthPage) {
      logger.debug('Auth page detected, skipping auth token in headers', {
        component: 'apiUtils',
        path
      });
      // Just return basic headers without auth for auth pages
      return {
        'Accept': 'application/json',
        'Content-Type': requireContentType ? 'application/json' : undefined as any
      };
    }
    
    // Create basic headers that will be returned no matter what
    const headers: Record<string, string> = {
      'Accept': 'application/json'
    };
    
    // Add content type if required
    if (requireContentType) {
      headers['Content-Type'] = 'application/json';
    }
    
    try {
      // Check for token expiration and possibly trigger refresh
      // This is non-blocking - we don't await it to avoid delaying the current request
      checkAndRefreshToken().catch(err => {
        logger.warn('Background token refresh check failed', {
          component: 'apiUtils',
          error: err
        });
      });
      
      // First try direct localStorage access (most reliable after login/signup)
      const accessToken = localStorage.getItem('access_token');
      
      if (accessToken && accessToken !== 'null' && accessToken !== 'undefined') {
        // Add token with Bearer prefix if it doesn't already have it
        headers['Authorization'] = accessToken.startsWith('Bearer ') 
          ? accessToken 
          : `Bearer ${accessToken}`;
          
        logger.debug('Using direct localStorage token', {
          component: 'apiUtils',
          tokenFormat: accessToken.startsWith('Bearer ') ? 'with Bearer prefix' : 'without Bearer prefix',
          tokenLength: accessToken.length
        });
          
        return headers;
      }
      
      // If no direct token, try authService
      const storedTokens = authService.getStoredToken();
      
      if (storedTokens?.accessToken && 
          storedTokens.accessToken !== 'null' && 
          storedTokens.accessToken !== 'undefined') {
        
        // Ensure format is correct with Bearer prefix
        const formattedToken = storedTokens.accessToken.startsWith('Bearer ') 
          ? storedTokens.accessToken 
          : `Bearer ${storedTokens.accessToken}`;
          
        headers['Authorization'] = formattedToken;
        
        logger.debug('Using authService token', {
          component: 'apiUtils',
          tokenFormat: storedTokens.accessToken.startsWith('Bearer ') ? 'with Bearer prefix' : 'without Bearer prefix',
          tokenFormatFixed: formattedToken.startsWith('Bearer ')
        });
        
        return headers;
      }
      
      // If we got here, no valid token found - log warning but return headers without auth
      logger.warn('No valid auth token found for request', {
        component: 'apiUtils'
      });
      
      return headers;
    } catch (tokenError) {
      // If any error occurs while getting token, log it but return headers without auth
      logger.error('Error getting auth token', {
        component: 'apiUtils',
        error: tokenError
      });
      
      return headers;
    }
  } catch (error) {
    logger.error('Error getting auth headers:', {
      component: 'apiUtils',
      error,
    });
    return {
      'Accept': 'application/json',
      'Content-Type': requireContentType ? 'application/json' : undefined as any
    };
  }
};