/**
 * @file src/services/encrypted-drive/media/media-cache.service.ts
 * @description Enhanced cache service for encrypted media content with improved URL management
 * @version 1.1.0
 * @created 2025-03-01
 * @updated 2025-03-05
 */

import { logger } from '@/utils/logger';

interface CachedMedia {
  url: string;
  iv: string;
  encryptedData?: ArrayBuffer;
  decryptedData?: ArrayBuffer;
  blob?: Blob;
  timestamp: number;
  urlRevoked?: boolean; // Track if URL has been revoked
}

class MediaCacheService {
  private cache: Map<string, CachedMedia> = new Map();
  private maxCacheSize: number = 50; // Maximum number of items to cache
  private maxAge: number = 10 * 60 * 1000; // 10 minutes
  private activeUrls: Set<string> = new Set(); // Track active URLs to prevent double revocation
  
  // Track files that have already shown the decryption animation
  private animatedFiles: Set<string> = new Set();
  private readonly ANIMATED_FILES_KEY = 'animated_files';
  
  constructor() {
    // Clean up cache periodically
    setInterval(() => this.cleanupCache(), 60 * 1000); // Every minute
    
    // Log creation with version
    logger.debug('MediaCacheService instantiated', {
      component: 'mediaCacheService',
      version: '1.2.0',
      maxCacheSize: this.maxCacheSize,
      maxAge: this.maxAge
    });
    
    // Load animated files from localStorage
    try {
      const storedAnimatedFiles = localStorage.getItem(this.ANIMATED_FILES_KEY);
      if (storedAnimatedFiles) {
        const parsedFiles = JSON.parse(storedAnimatedFiles);
        if (Array.isArray(parsedFiles)) {
          this.animatedFiles = new Set(parsedFiles);
          logger.debug('Loaded animated files from localStorage', {
            component: 'mediaCacheService',
            data: { count: this.animatedFiles.size }
          });
        }
      }
    } catch (error) {
      logger.error('Error loading animated files from localStorage', {
        component: 'mediaCacheService',
        error
      });
    }
  }
  
  /**
   * Generate a cache key
   */
  private getCacheKey(driveId: string, fileId: string, versionType: string): string {
    return `${driveId}:${fileId}:${versionType}`;
  }
  
  /**
   * Cache a media URL
   */
  cacheUrl(
    driveId: string,
    fileId: string,
    versionType: string,
    url: string,
    iv: string
  ): void {
    try {
      if (!driveId || !fileId || !versionType || !url) {
        logger.warn('Invalid parameters for cacheUrl', {
          component: 'mediaCacheService',
          data: { driveId, fileId, versionType, hasUrl: !!url }
        });
        return;
      }
      
      const key = this.getCacheKey(driveId, fileId, versionType);
      
      // Get existing or create new entry
      const existing = this.cache.get(key) || {
        url: "",
        iv,
        timestamp: Date.now(),
        encryptedData: undefined,
        decryptedData: undefined,
        urlRevoked: false
      };
      
      // Safely revoke old URL if it exists
      if (existing.url && existing.url !== url && !existing.urlRevoked) {
        try {
          if (this.activeUrls.has(existing.url)) {
            URL.revokeObjectURL(existing.url);
            this.activeUrls.delete(existing.url);
            logger.debug('Revoked old URL during cacheUrl', {
              component: 'mediaCacheService',
              data: { driveId, fileId, versionType }
            });
          }
        } catch (e) {
          logger.warn('Error revoking URL during cacheUrl', {
            component: 'mediaCacheService',
            error: e,
            data: { driveId, fileId, versionType }
          });
        }
      }
      
      // Update with new URL
      existing.url = url;
      existing.timestamp = Date.now();
      existing.urlRevoked = false;
      
      // Track this URL
      this.activeUrls.add(url);
      
      this.cache.set(key, existing);
      
      logger.debug('Cached media URL', {
        component: 'mediaCacheService',
        data: {
          driveId,
          fileId,
          versionType,
          cacheSize: this.cache.size,
          activeUrls: this.activeUrls.size
        }
      });
      
      this.pruneCache();
    } catch (error) {
      logger.error('Error caching URL', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId, versionType }
      });
    }
  }

  /**
   * Get a cached URL
   */
  getUrl(
    driveId: string,
    fileId: string,
    versionType: string
  ): string | null {
    try {
      const key = this.getCacheKey(driveId, fileId, versionType);
      const cached = this.cache.get(key);
      
      if (!cached) {
        return null;
      }
      
      // Update timestamp
      cached.timestamp = Date.now();
      
      // First check existing URL
      if (cached.url && !cached.urlRevoked && this.activeUrls.has(cached.url)) {
        return cached.url;
      }
      
      // Fall back to creating a URL from the blob if available
      if (cached.blob) {
        // If we have an old URL, revoke it first
        if (cached.url && this.activeUrls.has(cached.url)) {
          try {
            URL.revokeObjectURL(cached.url);
            this.activeUrls.delete(cached.url);
          } catch (e) {
            // Ignore errors when revoking
          }
        }
        
        // Create and track fresh URL
        const freshUrl = URL.createObjectURL(cached.blob);
        cached.url = freshUrl;
        cached.urlRevoked = false;
        this.activeUrls.add(freshUrl);
        
        this.cache.set(key, cached);
        
        logger.debug('Created fresh URL from cached blob', {
          component: 'mediaCacheService',
          data: { driveId, fileId, versionType }
        });
        
        return freshUrl;
      }
      
      return null;
    } catch (error) {
      logger.error('Error getting URL from cache', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId, versionType }
      });
      return null;
    }
  }

  /**
   * Cache a blob and create a URL
   */
  cacheBlob(
    driveId: string,
    fileId: string,
    versionType: string,
    blob: Blob,
    iv: string
  ): void {
    try {
      if (!driveId || !fileId || !versionType || !blob) {
        logger.warn('Invalid parameters for cacheBlob', {
          component: 'mediaCacheService',
          data: { driveId, fileId, versionType, hasBlob: !!blob }
        });
        return;
      }
      
      const key = this.getCacheKey(driveId, fileId, versionType);
      
      // Get existing or create new entry
      const existing = this.cache.get(key) || {
        url: "",
        iv,
        timestamp: Date.now(),
        encryptedData: undefined,
        decryptedData: undefined,
        urlRevoked: true
      };
      
      // Store the blob
      existing.blob = blob;
      
      // Safely revoke old URL if it exists
      if (existing.url && !existing.urlRevoked && this.activeUrls.has(existing.url)) {
        try {
          URL.revokeObjectURL(existing.url);
          this.activeUrls.delete(existing.url);
        } catch (e) {
          // Ignore errors when revoking
          logger.warn('Error revoking old URL during cacheBlob', {
            component: 'mediaCacheService',
            error: e
          });
        }
      }
      
      // Create a fresh URL
      const freshUrl = URL.createObjectURL(blob);
      existing.url = freshUrl;
      existing.urlRevoked = false;
      existing.timestamp = Date.now();
      
      // Track this URL
      this.activeUrls.add(freshUrl);
      
      this.cache.set(key, existing as CachedMedia);
      
      logger.debug('Cached blob and created URL', {
        component: 'mediaCacheService',
        data: {
          driveId, fileId, versionType,
          blobSize: blob.size,
          cacheSize: this.cache.size,
          activeUrls: this.activeUrls.size
        }
      });
      
      this.pruneCache();
    } catch (error) {
      logger.error('Error caching blob', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId, versionType }
      });
    }
  } 

/**
 * Cache encrypted data with improved IV validation
 */
cacheEncryptedData(
  driveId: string,
  fileId: string,
  versionType: string,
  data: ArrayBuffer,
  iv: string
): void {
  try {
    // First, validate the IV format
    if (!iv || typeof iv !== 'string') {
      logger.warn('Invalid IV format when caching encrypted data', {
        component: 'mediaCacheService',
        data: {
          driveId,
          fileId,
          versionType,
          ivType: typeof iv
        }
      });
      
      // Use a placeholder IV to avoid breaking the cache
      iv = 'INVALID_IV';
    }
    
    // Log detailed info about the IV to help diagnose issues
    logger.debug('IV details for caching', {
      component: 'mediaCacheService',
      data: {
        ivLength: iv.length,
        ivSample: iv.substring(0, Math.min(10, iv.length)) + (iv.length > 10 ? '...' : ''),
        looksLikeBase64: /^[A-Za-z0-9+/]*={0,2}$/.test(iv),
        fileId,
        versionType
      }
    });

    const key = this.getCacheKey(driveId, fileId, versionType);
    
    const existing = this.cache.get(key) || {
      url: '',
      iv,
      timestamp: Date.now(),
      urlRevoked: true
    };
    
    existing.encryptedData = data;
    existing.iv = iv;
    existing.timestamp = Date.now();
    
    this.cache.set(key, existing as CachedMedia);
    
    logger.debug('Cached encrypted data', {
      component: 'mediaCacheService',
      data: {
        driveId,
        fileId,
        versionType,
        dataSize: data.byteLength
      }
    });
    
    this.pruneCache();
  } catch (error) {
    logger.error('Error caching encrypted data', {
      component: 'mediaCacheService',
      error,
      data: { driveId, fileId, versionType }
    });
  }
}
  
/**
 * Get cached encrypted data with improved handling
 */
getEncryptedData(
  driveId: string,
  fileId: string,
  versionType: string
): { data: ArrayBuffer; iv: string } | null {
  try {
    const key = this.getCacheKey(driveId, fileId, versionType);
    const cached = this.cache.get(key);
    
    if (cached && cached.encryptedData) {
      // Enhanced logging to help diagnose IV issues
      logger.debug('Retrieved encrypted data from cache', {
        component: 'mediaCacheService',
        data: {
          fileId,
          versionType,
          hasIv: !!cached.iv,
          ivLength: cached.iv?.length || 0,
          ivSample: cached.iv ? (cached.iv.substring(0, Math.min(10, cached.iv.length)) + (cached.iv.length > 10 ? '...' : '')) : 'none'
        }
      });
      
      // Update timestamp
      cached.timestamp = Date.now();
      this.cache.set(key, cached);
      
      // If IV is invalid, provide a warning in logs
      if (!cached.iv || cached.iv === 'INVALID_IV') {
        logger.warn('Retrieved cached data has invalid IV', {
          component: 'mediaCacheService',
          data: { fileId, versionType }
        });
      }
      
      return {
        data: cached.encryptedData,
        iv: cached.iv
      };
    }
    
    return null;
  } catch (error) {
    logger.error('Error getting encrypted data from cache', {
      component: 'mediaCacheService',
      error,
      data: { driveId, fileId, versionType }
    });
    return null;
  }
}
  
  /**
   * Cache decrypted data
   */
  cacheDecryptedData(
    driveId: string,
    fileId: string,
    versionType: string,
    data: ArrayBuffer
  ): void {
    try {
      if (!driveId || !fileId || !versionType || !data) {
        logger.warn('Invalid parameters for cacheDecryptedData', {
          component: 'mediaCacheService',
          data: { driveId, fileId, versionType, hasData: !!data }
        });
        return;
      }
      
      const key = this.getCacheKey(driveId, fileId, versionType);
      
      // Get existing or create new entry
      const existing = this.cache.get(key) || {
        url: '',
        iv: '',  // This will be populated later
        timestamp: Date.now(),
        encryptedData: undefined,
        blob: undefined,
        urlRevoked: true
      };
      
      existing.decryptedData = data;
      existing.timestamp = Date.now();
      
      this.cache.set(key, existing as CachedMedia);
      
      logger.debug('Cached decrypted data', {
        component: 'mediaCacheService',
        data: {
          driveId,
          fileId,
          versionType,
          dataSize: data.byteLength,
          cacheSize: this.cache.size
        }
      });
      
      this.pruneCache();
    } catch (error) {
      logger.error('Error caching decrypted data', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId, versionType }
      });
    }
  }

  /**
   * Debug helper for cache entry inspection
   */
  debugCache(driveId: string, fileId: string, versionType: string): void {
    try {
      const key = this.getCacheKey(driveId, fileId, versionType);
      const cached = this.cache.get(key);
      
      logger.debug('Cache entry debug:', {
        component: 'mediaCacheService',
        data: {
          driveId,
          fileId,
          versionType,
          hasEntry: !!cached,
          hasUrl: cached?.url ? 'yes' : 'no',
          urlRevoked: cached?.urlRevoked,
          urlTracked: cached?.url ? this.activeUrls.has(cached.url) : false,
          hasEncryptedData: cached?.encryptedData ? 'yes' : 'no',
          hasDecryptedData: cached?.decryptedData ? 'yes' : 'no',
          hasBlob: cached?.blob ? 'yes' : 'no',
          iv: cached?.iv ? (cached.iv.substring(0, 10) + '...') : 'none',
          timestamp: cached?.timestamp ? new Date(cached.timestamp).toISOString() : 'none',
          activeUrlsCount: this.activeUrls.size
        }
      });
    } catch (error) {
      logger.error('Error debugging cache', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId, versionType }
      });
    }
  }
  
  /**
   * Get cached decrypted data
   */
  getDecryptedData(
    driveId: string,
    fileId: string,
    versionType: string
  ): ArrayBuffer | null {
    try {
      const key = this.getCacheKey(driveId, fileId, versionType);
      const cached = this.cache.get(key);
      
      if (cached && cached.decryptedData) {
        // Update timestamp
        cached.timestamp = Date.now();
        this.cache.set(key, cached);
        
        return cached.decryptedData;
      }
      
      return null;
    } catch (error) {
      logger.error('Error getting decrypted data', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId, versionType }
      });
      return null;
    }
  }
  
  /**
   * Clear cache for a specific drive
   */
  clearDriveCache(driveId: string): void {
    try {
      const urlsToRevoke: string[] = [];
      
      // Delete all items with this driveId
      for (const [key, value] of this.cache.entries()) {
        if (key.startsWith(`${driveId}:`)) {
          // Track URLs to revoke
          if (value.url && !value.urlRevoked && this.activeUrls.has(value.url)) {
            urlsToRevoke.push(value.url);
          }
          this.cache.delete(key);
        }
      }
      
      // Revoke all collected URLs
      for (const url of urlsToRevoke) {
        try {
          URL.revokeObjectURL(url);
          this.activeUrls.delete(url);
        } catch (e) {
          // Ignore errors when revoking
        }
      }
      
      logger.debug('Cleared cache for drive', {
        component: 'mediaCacheService',
        data: {
          driveId,
          urlsRevoked: urlsToRevoke.length,
          remainingActiveUrls: this.activeUrls.size
        }
      });
    } catch (error) {
      logger.error('Error clearing drive cache', {
        component: 'mediaCacheService',
        error,
        data: { driveId }
      });
    }
  }
  
  /**
   * Clear cache for a specific file
   */
  clearFileCache(driveId: string, fileId: string): void {
    try {
      const urlsToRevoke: string[] = [];
      
      // Delete all items with this fileId
      for (const [key, value] of this.cache.entries()) {
        if (key.startsWith(`${driveId}:${fileId}:`)) {
          // Track URLs to revoke
          if (value.url && !value.urlRevoked && this.activeUrls.has(value.url)) {
            urlsToRevoke.push(value.url);
          }
          this.cache.delete(key);
        }
      }
      
      // Revoke all collected URLs
      for (const url of urlsToRevoke) {
        try {
          URL.revokeObjectURL(url);
          this.activeUrls.delete(url);
        } catch (e) {
          // Ignore errors when revoking
        }
      }
      
      logger.debug('Cleared cache for file', {
        component: 'mediaCacheService',
        data: {
          driveId,
          fileId,
          urlsRevoked: urlsToRevoke.length,
          remainingActiveUrls: this.activeUrls.size
        }
      });
    } catch (error) {
      logger.error('Error clearing file cache', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId }
      });
    }
  }
  
  /**
   * Clear all cache
   */
  clearAllCache(): void {
    try {
      // Revoke all URLs first
      for (const [_, value] of this.cache.entries()) {
        if (value.url && !value.urlRevoked && this.activeUrls.has(value.url)) {
          try {
            URL.revokeObjectURL(value.url);
          } catch (e) {
            // Ignore errors when revoking
          }
        }
      }
      
      // Clear tracking sets
      this.activeUrls.clear();
      
      // Clear cache
      this.cache.clear();
      
      logger.debug('Cleared all cache', {
        component: 'mediaCacheService'
      });
    } catch (error) {
      logger.error('Error clearing all cache', {
        component: 'mediaCacheService',
        error
      });
    }
  }
  
  /**
   * Prune cache if it exceeds max size
   */
  private pruneCache(): void {
    try {
      if (this.cache?.size <= this.maxCacheSize) {
        return;
      }
      
      // Convert to array and sort by timestamp
      const entries = Array.from(this.cache.entries())
        .sort(([_, a], [__, b]) => a.timestamp - b.timestamp);
      
      // Remove oldest entries until we're under the limit
      const toRemove = entries.slice(0, this.cache.size - this.maxCacheSize);
      
      // URLs to revoke
      const urlsToRevoke: string[] = [];
      
      for (const [key, value] of toRemove) {
        // Track URLs to revoke
        if (value.url && !value.urlRevoked && this.activeUrls.has(value.url)) {
          urlsToRevoke.push(value.url);
        }
        this.cache.delete(key);
      }
      
      // Revoke all collected URLs
      for (const url of urlsToRevoke) {
        try {
          URL.revokeObjectURL(url);
          this.activeUrls.delete(url);
        } catch (e) {
          // Ignore errors when revoking
        }
      }
      
      logger.debug('Pruned cache', {
        component: 'mediaCacheService',
        data: {
          removedCount: toRemove.length,
          urlsRevoked: urlsToRevoke.length,
          newSize: this.cache.size,
          remainingActiveUrls: this.activeUrls.size
        }
      });
    } catch (error) {
      logger.error('Error pruning cache', {
        component: 'mediaCacheService',
        error
      });
    }
  }
  
  /**
   * Clean up expired cache items
   */
  private cleanupCache(): void {
    try {
      const now = Date.now();
      const expired = Array.from(this.cache.entries())
        .filter(([_, item]) => now - item.timestamp > this.maxAge);
      
      if (expired?.length === 0) {
        return;
      }
      
      // URLs to revoke
      const urlsToRevoke: string[] = [];
      
      for (const [key, value] of expired) {
        // Track URLs to revoke
        if (value.url && !value.urlRevoked && this.activeUrls.has(value.url)) {
          urlsToRevoke.push(value.url);
        }
        this.cache.delete(key);
      }
      
      // Revoke all collected URLs
      for (const url of urlsToRevoke) {
        try {
          URL.revokeObjectURL(url);
          this.activeUrls.delete(url);
        } catch (e) {
          // Ignore errors when revoking
        }
      }
      
      logger.debug('Cleaned up expired cache items', {
        component: 'mediaCacheService',
        data: {
          removedCount: expired.length,
          urlsRevoked: urlsToRevoke.length,
          newSize: this.cache.size,
          remainingActiveUrls: this.activeUrls.size
        }
      });
    } catch (error) {
      logger.error('Error cleaning up cache', {
        component: 'mediaCacheService',
        error
      });
    }
  }
  
  /**
   * Record that a file has been shown with animation and persist to localStorage
   */
  markFileAnimated(driveId: string, fileId: string): void {
    try {
      if (!driveId || !fileId) return;
      
      const key = `${driveId}:${fileId}`;
      
      // If already marked, don't do anything
      if (this.animatedFiles.has(key)) return;
      
      // Add to in-memory set
      this.animatedFiles.add(key);
      
      // Persist to localStorage (with throttling to avoid excessive writes)
      this.persistAnimatedFilesThrottled();
      
      logger.debug('Marked file as animated', {
        component: 'mediaCacheService',
        data: {
          driveId,
          fileId,
          animatedFilesCount: this.animatedFiles.size
        }
      });
    } catch (error) {
      logger.error('Error marking file as animated', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId }
      });
    }
  }
  
  /**
   * Throttled persistence of animated files to localStorage
   */
  private persistenceTimeout: any = null;
  private persistAnimatedFilesThrottled(): void {
    if (this.persistenceTimeout) return;
    
    this.persistenceTimeout = setTimeout(() => {
      try {
        const filesArray = Array.from(this.animatedFiles);
        localStorage.setItem(this.ANIMATED_FILES_KEY, JSON.stringify(filesArray));
        
        logger.debug('Persisted animated files to localStorage', {
          component: 'mediaCacheService',
          data: { count: filesArray.length }
        });
      } catch (error) {
        logger.error('Error persisting animated files', {
          component: 'mediaCacheService',
          error
        });
      } finally {
        this.persistenceTimeout = null;
      }
    }, 1000); // Throttle to once per second
  }
  
  /**
   * NEW: Check if a file has already been shown with animation
   */
  hasFileBeenAnimated(driveId: string, fileId: string): boolean {
    try {
      if (!driveId || !fileId) return false;
      
      const key = `${driveId}:${fileId}`;
      return this.animatedFiles.has(key);
    } catch (error) {
      logger.error('Error checking if file has been animated', {
        component: 'mediaCacheService',
        error,
        data: { driveId, fileId }
      });
      return false;
    }
  }
  
  /**
   * Clear animated files tracking for a drive (e.g. when locked) and update localStorage
   */
  clearAnimatedFilesForDrive(driveId: string): void {
    try {
      if (!driveId) return;
      
      const toRemove: string[] = [];
      
      for (const key of this.animatedFiles) {
        if (key.startsWith(`${driveId}:`)) {
          toRemove.push(key);
        }
      }
      
      for (const key of toRemove) {
        this.animatedFiles.delete(key);
      }
      
      // If we removed any files, persist changes to localStorage
      if (toRemove.length > 0) {
        try {
          const filesArray = Array.from(this.animatedFiles);
          localStorage.setItem(this.ANIMATED_FILES_KEY, JSON.stringify(filesArray));
        } catch (persistError) {
          logger.error('Error persisting animated files after clear', {
            component: 'mediaCacheService',
            error: persistError
          });
        }
      }
      
      // Also clear any media files from localStorage cache
      const storageKeys = Object.keys(localStorage);
      const mediaCacheKeys = storageKeys.filter(key => 
        (key.startsWith('media_file_') || key.startsWith('media_files_cache_')) && 
        key.includes(driveId)
      );
      
      if (mediaCacheKeys.length > 0) {
        logger.debug('Clearing media file cache entries from localStorage', {
          component: 'mediaCacheService',
          data: {
            count: mediaCacheKeys.length,
            keys: mediaCacheKeys
          }
        });
        
        mediaCacheKeys.forEach(key => localStorage.removeItem(key));
      }
      
      logger.debug('Cleared animated files for drive', {
        component: 'mediaCacheService',
        data: {
          driveId,
          removedCount: toRemove.length,
          remainingCount: this.animatedFiles.size
        }
      });
    } catch (error) {
      logger.error('Error clearing animated files for drive', {
        component: 'mediaCacheService',
        error,
        data: { driveId }
      });
    }
  }
}

export const mediaCacheService = new MediaCacheService();
export default mediaCacheService;