/**
 * @file src/layouts/ghost/GhostLayout.tsx
 * @description Enhanced layout with better padding, improved mobile responsiveness, and fixed Nygma heading
 * @version 1.8.0
 * @updated 2025-03-29
 */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { cn } from '@/utils/utils';
import { GhostGridBackground } from '@/app/ghost/components/common/background/GhostGridBackground';
import { GhostMobileMenu } from './GhostMobileMenu';
import { GhostBottomNav } from './GhostBottomNav';
import { GhostSidebar } from '../sidebar';
import { GhostHeader } from './GhostHeader';
import { logger } from '@/utils/logger';
import { useGhostTheme } from '@/app/ghost/providers/GhostThemeProvider';
import { GlobalUploadQueue } from '@/components/upload/GlobalUploadQueue';

interface GhostLayoutProps {
  children: React.ReactNode;
  className?: string;
}

export const GhostLayout: React.FC<GhostLayoutProps> = ({
  children,
  className
}) => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { theme } = useGhostTheme();

  // Determine if we're in a drive view to control scrolling behavior
  const isDriveView = location.pathname.includes('/ghost/drive/');
  
  // Track the current drive ID for upload targeting
  useEffect(() => {
    if (isDriveView) {
      try {
        // Extract drive ID from URL pattern /ghost/drive/:driveId
        const driveId = location.pathname.split('/ghost/drive/')[1]?.split('/')[0];
        if (driveId) {
          logger.debug('Updating last active drive from route', {
            component: 'GhostLayout',
            data: { driveId, pathname: location.pathname }
          });
          // Store in localStorage for persistence across page changes
          localStorage.setItem('last_active_drive', driveId);
          localStorage.setItem('last_active_drive_timestamp', Date.now().toString());
        }
      } catch (error) {
        logger.error('Error extracting drive ID from path', {
          component: 'GhostLayout',
          error,
          data: { pathname: location.pathname }
        });
      }
    }
  }, [isDriveView, location.pathname]);

  // Close mobile menu on route change
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  // Close mobile menu when screen size changes from mobile to desktop
  useEffect(() => {
    if (!isMobile) {
      setMobileMenuOpen(false);
    }
  }, [isMobile]);

  const handleToggleMobileMenu = () => {
    logger.debug('Toggling mobile menu', {
      component: 'GhostLayout',
      currentState: isMobileMenuOpen,
      newState: !isMobileMenuOpen
    });
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCloseMobileMenu = () => {
    logger.debug('Closing mobile menu', {
      component: 'GhostLayout'
    });
    setMobileMenuOpen(false);
  };

  logger.debug('Rendering GhostLayout', {
    isMobile,
    isMobileMenuOpen,
    pathname: location.pathname,
    theme,
    isDriveView
  });

  return (
    <div className={cn(
      "fixed inset-0 overflow-hidden",
      `ghost-theme-${theme}`,
      "bg-[hsl(var(--ghost-bg))]"
    )}>
      {/* Quantum Background Layer - Only shown for quantum theme */}
      {theme === 'quantum' && (
        <GhostGridBackground 
          className="fixed inset-0 z-0"
        />
      )}

      {/* Content Layer */}
      <div className={cn(
        "relative z-10 h-full",
        "text-[hsl(var(--ghost-foreground))]"
      )}>
        {/* Mobile Header */}
        {isMobile && (
          <div className="relative z-20">
            <GhostHeader
              onMenuClick={handleToggleMobileMenu}
              isMobileMenuOpen={isMobileMenuOpen}
            />
          </div>
        )}

        {/* Mobile Menu with backdrop overlay */}
        {isMobile && (
          <>
            {/* Backdrop overlay that closes the menu when clicked */}
            {isMobileMenuOpen && (
              <div 
                className="fixed inset-0 z-20 bg-black/50 backdrop-blur-sm"
                onClick={handleCloseMobileMenu}
              />
            )}
            <div className="relative z-30">
              <GhostMobileMenu
                isOpen={isMobileMenuOpen}
                onClose={handleCloseMobileMenu}
              />
            </div>
          </>
        )}

        {/* Navigation (Desktop Sidebar or Mobile Bottom Nav) */}
        <div className="relative z-20">
          {isMobile ? <GhostBottomNav /> : <GhostSidebar />}
        </div>

        {/* Main Content with improved padding - reduced top padding */}
        <main className={cn(
          "relative z-10",
          "h-full",
          // Only allow scrolling if we're NOT in a drive view
          isDriveView ? "overflow-hidden" : "overflow-y-auto",
          isMobile 
            ? "pb-20 pt-2 px-4" // Reduced from pt-16 to pt-2
            : "ml-[280px] pt-3 pb-8 px-8", // Reduced from py-8 to pt-3 pb-8
          className
        )}>
          <div className={cn(
            "animate-in fade-in duration-500",
            // Ensure the height is properly set for drive views
            isDriveView ? "h-full" : ""
          )}>
            {children}
          </div>
        </main>
        
        {/* Global Upload Queue - Added to ensure persistence in Ghost UI */}
        <div className="relative z-50">
          {/* Wrap in try/catch to handle cases where UploadProvider might not be available yet */}
          {(() => {
            try {
              return <GlobalUploadQueue />;
            } catch (error) {
              // Silently handle the error without rendering anything
              return null;
            }
          })()}</div>
      </div>
    </div>
  );
};

export default GhostLayout;