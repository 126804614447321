/**
 * @file src/utils/debug-logger.ts
 * @description Enhanced debug logging utility handling both visual and console logs
 * @version 2.0.0
 */

import { logger } from '@/utils/logger';
import { formatBytes } from '@/utils/format';

type LogEntry = {
    timestamp: string;
    message: string;
    type: 'info' | 'error' | 'success';
    data?: any;
};

class DebugLogger {
    private static instance: DebugLogger;
    private logs: LogEntry[] = [];
    private subscribers: ((logs: LogEntry[]) => void)[] = [];

    private constructor() {}

    // Add this method to handle both logger and upload panel
  // Add this method to handle both logger and upload panel
  logEncryption(message: string, data?: any) {
    // Send to regular logger
    logger.debug(message, {
      component: 'encryption',
      data
    });
    
    // Send to debug panel
    this.log(message, 'info', data);
    
    // Try to send to upload debug panel if available
    try {
      if (window && window._uploadDebugPanel) {
        window._uploadDebugPanel.addLog({
          type: 'encryption',
          message,
          data
        });
      }
    } catch (e) {
      // Silently ignore if panel not available
    }
  }   

    static getInstance(): DebugLogger {
        if (!DebugLogger.instance) {
            DebugLogger.instance = new DebugLogger();
        }
        return DebugLogger.instance;
    }

    subscribe(callback: (logs: LogEntry[]) => void) {
        this.subscribers.push(callback);
        callback(this.logs);
        return () => {
            this.subscribers = this.subscribers.filter((cb: any) => cb !== callback);
        };
    }

    private notify() {
        this.subscribers.forEach((cb: any) => cb(this.logs));
    }

    private formatData(data: any): any {
        if (!data) return data;

        const formatted = { ...data };
        
        // Format sizes if present
        if (formatted.fileSize && typeof formatted.fileSize === 'number') {
            formatted.fileSize = formatBytes(formatted.fileSize);
        }
        if (formatted.size && typeof formatted.size === 'number') {
            formatted.size = formatBytes(formatted.size);
        }
        
        return formatted;
    }

    private log(message: string, type: 'info' | 'error' | 'success', data?: any) {
        // Add to visual logs
        const entry = {
            timestamp: new Date().toISOString(),
            message,
            type,
            data: this.formatData(data)
        };
        this.logs.push(entry);
        this.notify();

        // Log to console
        const consoleData = {
            component: 'debugLogger',
            data: data || {}
        };

        switch (type) {
            case 'error':
                logger.error(message, consoleData);
                break;
            case 'success':
                logger.info(message, consoleData);
                break;
            default:
                logger.debug(message, consoleData);
        }
    }

    info(message: string, data?: any) {
        this.log(message, 'info', data);
    }

    error(message: string, data?: any) {
        this.log(message, 'error', data);
    }

    success(message: string, data?: any) {
        this.log(message, 'success', data);
    }

    clear() {
        this.logs = [];
        this.notify();
        logger.debug('Debug logs cleared');
    }
}

export const debugLogger = DebugLogger.getInstance();