/**
 * @file src/hooks/upload/useUploadQueue.ts
 * @description Enhanced upload queue hook with chunk support
 * @version 1.1.0
 */

import { useContext, useCallback } from 'react';
import { UploadContext } from '@/context/UploadContext';
import { useToast } from '@/components/ui/toast';
import { UPLOAD_CONFIG } from '@/config/upload.config';
import { logger } from '@/utils/logger';
import { UploadFile, UploadStatus } from '@/types/upload.types';

interface UploadContextValue {
  queue: UploadFile[];
  isVisible: boolean;
  isMinimized: boolean;
  activeUpload: UploadFile | null;
  isAcceptingFiles: boolean;
  addFiles: (files: FileList | File[], driveId: string, parentId: string) => void;
  removeFile: (fileId: string) => void;
  clearCompleted: () => void;
  cancelUpload: (fileId: string) => Promise<void>;
  setIsVisible?: (visible: boolean) => void;
  setIsMinimized?: (minimized: boolean) => void;
  setAcceptingFiles?: (accepting: boolean) => void;
}

export const useUploadQueue = () => {
  const context = useContext(UploadContext);
  const { showToast } = useToast();

  if (!context) {
    throw new Error('useUploadQueue must be used within UploadProvider');
  }

  /**
   * Add files to the upload queue
   */
  const addFiles = useCallback((
    files: FileList | File[],
    driveId: string,
    parentId: string
  ) => {
    logger.debug('Adding files to queue', {
      component: 'useUploadQueue',
      action: 'addFiles',
      data: {
        fileCount: files.length,
        driveId,
        parentId
      }
    });

    if (!driveId) {
      logger.error('Upload blocked: No drive ID provided', {
        component: 'useUploadQueue',
        action: 'addFiles'
      });
      showToast('Please select a drive first', 'error');
      return;
    }

    const fileArray = Array.from(files);

    // Validate file size
    const invalidFiles = fileArray.filter((file: any) => 
      file.size > UPLOAD_CONFIG.maxFileSize
    );

    if (invalidFiles?.length > 0) {
      logger.warn('Files exceed size limit', {
        component: 'useUploadQueue',
        action: 'addFiles',
        data: { invalidCount: invalidFiles.length }
      });
      showToast(`${invalidFiles.length} files exceed the size limit`, 'error');
      return;
    }

    // Validate queue size
    if (context.queue?.length + fileArray.length > UPLOAD_CONFIG.maxQueueSize) {
      logger.warn('Queue size limit exceeded', {
        component: 'useUploadQueue',
        action: 'addFiles',
        data: {
          currentSize: context.queue.length,
          attemptedAdd: fileArray.length
        }
      });
      showToast(`Cannot add more than ${UPLOAD_CONFIG.maxQueueSize} files to queue`, 'error');
      return;
    }

    // Convert null parentId to empty string if needed
    const safeParentId = parentId || '';
    context.addFiles(fileArray, driveId, safeParentId);
    showQueue();
  }, [context, showToast]);

  // Add showQueue function
  const showQueue = useCallback(() => {
    logger.debug('Showing upload queue', {
      component: 'useUploadQueue',
      action: 'showQueue'
    });
  
    // Set accepting files to true
    if (context.setAcceptingFiles) {
      context.setAcceptingFiles(true);
    }
  
    // Show and unminimize the queue
    if (context.setIsVisible) {
      context.setIsVisible(true);
    }
    if (context.setIsMinimized) {
      context.setIsMinimized(false);
    }
  }, [context]);


  // Add hideQueue function
  const hideQueue = useCallback(() => {
    logger.debug('Hiding upload queue', {
      component: 'useUploadQueue',
      action: 'hideQueue'
    });
    if (context.setIsVisible) {
      context.setIsVisible(false);
    }
  }, [context]);

  // Add minimizeQueue function
  const minimizeQueue = useCallback(() => {
    logger.debug('Minimizing upload queue', {
      component: 'useUploadQueue',
      action: 'minimizeQueue'
    });
    if (context.setIsMinimized) {
      context.setIsMinimized(true);
    }
  }, [context]);

  /**
   * Pause a chunked upload
   */
  const pauseUpload = useCallback((fileId: string) => {
    const file = context.queue.find((f: any) => f.id === fileId);
    if (!file || !('isChunked' in file && file.isChunked)) {
      console.warn('Cannot pause non-chunked upload');
      return;
    }
  
    showToast(`Paused upload of ${file.file.name}`, 'info'); // Changed
  }, [context, showToast]); // Update dependency

  /**
   * Resume a paused upload
   */
  const resumeUpload = useCallback((fileId: string) => {
    const file = context.queue.find((f: any) => f.id === fileId);
    if (!file || !('isChunked' in file && file.isChunked)) {
      console.warn('Cannot resume non-chunked upload');
      return;
    }
  
    showToast(`Resumed upload of ${file.file.name}`, 'success'); // Changed
  }, [context, showToast]); // Update dependency

  /**
   * Remove file from queue
   */
  const removeFile = useCallback((fileId: string) => {
    const file = context.queue.find((f: UploadFile) => f.id === fileId);
    context.removeFile(fileId);
  
    if (file?.status === 'failed') {
      showToast(`Removed failed upload of ${file.file.name}`, 'info');
    }
  }, [context, showToast]);

  /**
   * Cancel an active upload
   */
  const cancelUpload = useCallback(async (fileId: string) => {
    const file = context.queue.find((f: UploadFile) => f.id === fileId);
    if (!file) return;
  
    try {
      await context.cancelUpload(fileId);
      showToast(`Cancelled upload of ${file.file.name}`, 'info');
    } catch (error) {
      console.error('Error cancelling upload:', error);
      showToast('Failed to cancel upload', 'error');
    }
  }, [context, showToast]);

  /**
   * Clear completed uploads
   */
  const clearCompleted = useCallback(() => {
    context.clearCompleted();
    showToast('Removed all completed uploads from queue', 'success');
  }, [context, showToast]);

  return {
    // State
    queue: context.queue,
    isVisible: context.isVisible,
    isMinimized: context.isMinimized,
    activeUpload: context.activeUpload,
    isAcceptingFiles: context.isAcceptingFiles,
  
    // Methods
    addFiles,
    removeFile: context.removeFile,
    clearCompleted: context.clearCompleted,
    cancelUpload: context.cancelUpload,
    showQueue,
    hideQueue,
    minimizeQueue,
    pauseUpload: pauseUpload,
    resumeUpload: resumeUpload
  };
};

// Helper function for formatting bytes
// const formatBytes = (bytes: number) => {
//   if (bytes === 0) return '0 Bytes';
//   const k = 1024;
//   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
//   const i = Math.floor(Math.log(bytes) / Math.log(k));
//   return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
// };

export default useUploadQueue;