/**
 * @file src/components/ghost/common/background/effects/useDataStreamEffect.ts
 * @description Matrix-style data streams effect
 * @version 1.0.0
 */

import { getAccentColor } from '../utils/colors';
import { CanvasDimensions, getResponsiveValue } from '../utils/canvasHelpers';
import { logger } from '@/utils/logger';

interface DataStream {
  x: number;
  y: number;
  speed: number;
  glyphs: string[];
  opacity: number;
}

export const useDataStreamEffect = (
  ctx: CanvasRenderingContext2D,
  dimensions: CanvasDimensions
) => {
  const streamCount = getResponsiveValue(3, 5);
  
  const generateGlyphs = (length: number) => 
    Array.from({ length }, () => 
      String.fromCharCode(0x30A0 + Math.random() * 96)
    );

  const streams: DataStream[] = Array.from({ length: streamCount }, () => ({
    x: Math.random() * dimensions.width,
    y: Math.random() * dimensions.height,
    speed: Math.random() * 1 + 0.5,
    glyphs: generateGlyphs(10),
    opacity: Math.random() * 0.15 + 0.05
  }));

  logger.debug('Data stream effect initialized', { 
    component: 'useDataStreamEffect'
  });

  const render = () => {
    ctx.font = '12px monospace';
    
    streams.forEach((stream: any) => {
      // Update position
      stream.y += stream.speed;
      if (stream?.y > dimensions.height) {
        stream.y = -100;
        stream.x = Math.random() * dimensions.width;
      }

      // Occasionally update glyphs
      if (Math.random() < 0.1) {
        const index = Math.floor(Math.random() * stream.glyphs.length);
        stream.glyphs[index] = String.fromCharCode(0x30A0 + Math.random() * 96);
      }

      // Draw glyphs with fading effect
      stream.glyphs.forEach((glyph: string, i: number) => {
        const fadeOpacity = stream.opacity * (1 - i / stream.glyphs.length);
        ctx.fillStyle = getAccentColor(fadeOpacity);
        ctx.fillText(glyph, stream.x, stream.y + i * 15);
      });
    });
  };

  return {
    render,
    cleanup: () => {
      logger.debug('Data stream effect cleaned up');
    }
  };
};