/**
 * @file src/components/cloud-drive/dialogs/RenameDialog.tsx
 * @description Dialog for renaming files and folders
 * @version 1.0.1
 * @created 2024-12-21
 * @updated 2025-03-15
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { File, Folder } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';

interface RenameDialogProps {
  item: DriveFile | DriveFolder;
  isOpen: boolean;
  onClose: (open: boolean) => void; // Change to accept a boolean parameter
  onRename: (newName: string) => Promise<void>;
}

export const RenameDialog: React.FC<RenameDialogProps> = ({
  item,
  isOpen,
  onClose,
  onRename,
}) => {
  const { t } = useTranslation();
  const [newName, setNewName] = useState(item.name);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const Icon = item.type === 'folder' ? Folder : File;

  // Initialize newName when dialog opens with the current item name
  React.useEffect(() => {
    if (isOpen && item && item.name) {
      setNewName(item.name);
      setError(null);
    }
  }, [isOpen, item, item?.name]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      await onRename(newName);
      onClose(false); // Close dialog on success
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error renaming item');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center">
            <Icon className="h-5 w-5 mr-2" />
            {t('cloud_drive.dialogs.rename.title')}
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit}>
          <div className="space-y-4 py-4">
            <Input
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              placeholder={t('cloud_drive.dialogs.rename.placeholder')}
              autoFocus
              disabled={isLoading}
            />
            {error && (
              <p className="text-sm text-red-500">{error}</p>
            )}
          </div>

          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              onClick={() => onClose(false)}
              disabled={isLoading}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="submit"
              disabled={!newName || !newName.trim() || (newName === item?.name) || isLoading}
            >
              {isLoading ? t('common.loading') : t('common.rename')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RenameDialog;