/**
 * @file src/hooks/useNavigationPath.ts
 * @description Hook for managing navigation paths with device and drive context
 * @version 2.1.0
 * @updated 2025-03-20
 */

import { useCallback } from 'react';
import { navigationService, NavigationContext } from '@/services/navigation.service';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import type { NavigationPath, DeviceInfo } from '@/types/navigation.types';
import type { DeviceWithDrives } from '@/types/root-drive.types';

import { logger } from '@/utils/logger';
import { isValidUUID } from '@/utils/validation';

function convertDevice(device: DeviceWithDrives): DeviceInfo {
  return {
    id: device.id,
    name: device.name,
    type: device.device_type as 'system' | 'cloud' | 'backup' | 'network',
    status: device.status,
    drives: device.drives.map(drive => ({
      id: drive.id,
      name: drive.name,
      type: (['cloud', 'system', 'backup', 'network'].includes(drive.type)
        ? drive.type
        : 'cloud') as 'cloud' | 'system' | 'backup' | 'network',
      deviceId: device.id,
      parentId: drive.parentId,
      config: drive.configuration ?? undefined, // Convert null to undefined
      drives: [] // Adjust further if needed
    }))
  };
}


export const useNavigationPath = () => {
  const { 
    devices, 
    activeDriveId, 
    primaryDriveId 
  } = useRootDrive();

  const getNavigationPath = useCallback(async (
    folderId: string | null
  ): Promise<NavigationPath> => {
    try {
      // If folderId is provided but invalid UUID format, log and handle gracefully
      if (folderId && !isValidUUID(folderId)) {
        logger.warn('Invalid UUID format for navigation path', {
          component: 'useNavigationPath',
          data: { folderId }
        });
      }
      
      // Get current encryption context from localStorage
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Getting navigation path', {
        component: 'useNavigationPath',
        data: { 
          folderId,
          activeDriveId,
          primaryDriveId,
          deviceCount: devices.length,
          isEncryptedMode,
          encryptedDriveId
        }
      });

      // Create navigation context with explicit encryption status
      const navContext: NavigationContext = {
        isEncryptedMode,
        encryptedDriveId: encryptedDriveId || undefined
      };
      
      // Pass explicit navigation context to buildNavigationPath
      const path = await navigationService.buildNavigationPath(
        folderId,
        devices.map(convertDevice),
        activeDriveId,
        primaryDriveId,
        navContext
      );

      // Format segments for display
      const formattedSegments = navigationService.formatPathSegments(
        path.device,
        path.drive,
        path.segments
      );

      return {
        ...path,
        segments: formattedSegments
      };

    } catch (error) {
      logger.error('Error in getNavigationPath:', {
        component: 'useNavigationPath',
        error,
        data: { folderId }
      });

      // Return empty path on error
      return {
        device: null,
        drive: null,
        segments: [],
        currentId: null
      };
    }
  }, [devices, activeDriveId, primaryDriveId]);

  return {
    getNavigationPath
  };
};

export default useNavigationPath;