/**
 * @file src/ghost/components/sidebar/DesktopSidebar.tsx
 * @description Desktop sidebar container with fixed collapsed layout
 * @version 1.4.0
 * @updated 2025-04-04
 */

import React from 'react';
// import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, ChevronLeft } from 'lucide-react';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

// Import sidebar components
import { CreateCoreButton } from './components/CreateCoreButton';
import { CoresList } from './components/CoresList';
import { QuickAccess } from './components/QuickAccess';
import { MobileAppsSection } from './components/MobileAppsSection';
import { AccountSection } from './components/AccountSection';

interface DesktopSidebarProps {
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  className?: string;
}

export const DesktopSidebar: React.FC<DesktopSidebarProps> = ({
  isCollapsed,
  onToggleCollapse,
  className
}) => {
  // const { t } = useTranslation('ghost');

  logger.debug('Rendering DesktopSidebar', {
    component: 'DesktopSidebar',
    data: { isCollapsed }
  });

  return (
    <div className={cn(
      "h-full flex flex-col",
      "touch-manipulation",
      className
    )}>
      {/* Header with Logo */}
      <div className={cn(
        "flex items-center justify-between",
        isCollapsed ? "px-3 py-4" : "p-4"
      )}>
        <div className="flex items-center">
          <motion.div
            animate={{ rotate: [0, 360] }}
            transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
            className="relative"
          >
            <div className="absolute inset-0 ghost-glow rounded-lg" />
            <Shield className="h-8 w-8 text-[hsl(var(--ghost-accent))]" />
          </motion.div>
          
          <AnimatePresence>
            {!isCollapsed && (
              <motion.span
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
                className="ml-3 ghost-text-gradient text-xl font-bold truncate"
              >
                Nygma.ai
              </motion.span>
            )}
          </AnimatePresence>
        </div>

        {/* Collapse Toggle Button */}
        <motion.button
          onClick={onToggleCollapse}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          animate={{ rotate: isCollapsed ? 180 : 0 }}
          className={cn(
            "rounded-lg",
            "w-8 h-8 flex items-center justify-center", // Fixed size
            "text-[hsl(var(--ghost-foreground-muted))]",
            "hover:text-[hsl(var(--ghost-accent))]",
            "hover:bg-[hsl(var(--ghost-surface))]",
            "active:bg-[hsl(var(--ghost-surface))]/80",
            "transition-colors duration-200"
          )}
        >
          <ChevronLeft className="h-5 w-5" />
        </motion.button>
      </div>

      {/* Create Core Button */}
      <div className={cn(
        "mb-6",
        isCollapsed ? "px-3" : "px-4"
      )}>
        <CreateCoreButton isCollapsed={isCollapsed} />
      </div>

      {/* Main Navigation */}
      <div className={cn(
        "flex-1 space-y-6 overflow-y-auto scrollbar-ghost",
        isCollapsed ? "px-1.5" : "px-2"
      )}>
        <CoresList isCollapsed={isCollapsed} />
        <QuickAccess isCollapsed={isCollapsed} />
      </div>

      {/* Footer Sections */}
      <div className={cn(
        "mt-auto space-y-4",
        isCollapsed ? "p-3" : "p-4"
      )}>
        <MobileAppsSection isCollapsed={isCollapsed} />
        <AccountSection isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default DesktopSidebar;