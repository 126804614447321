/**
 * @file src/layouts/ghost/components/create-core/steps/StepDriveDetails.tsx
 * @description Drive name and details step with enhanced validation and UX
 * @version 2.0.0
 */

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {Ghost, Sparkles, AlertCircle} from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
// import { useGhostTheme } from '@/app/ghost/providers/GhostThemeProvider';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

// Name suggestions with emojis
const NAME_SUGGESTIONS = [
  { name: 'Ghost Vault', emoji: '👻' },
  { name: 'Shadow Core', emoji: '🌑' },
  { name: 'Stealth Drive', emoji: '🔐' },
  { name: 'Quantum Realm', emoji: '✨' }
];

const MAX_NAME_LENGTH = 64;
// const NAME_REGEX = /^[a-zA-Z0-9\s\-_]+$/;

interface StepDriveDetailsProps {
  formData: {
    name: string;
    [key: string]: any;
  };
  setFormData: (data: any) => void;
  onNext: () => void;
  onBack: () => void;
}

export const StepDriveDetails: React.FC<StepDriveDetailsProps> = ({
  formData,
  setFormData,
  onNext,
  onBack,
}) => {
  const { t } = useGhostTranslation();
  // const { theme } = useGhostTheme();
  // const isQuantum = theme === 'quantum';
  const [error, setError] = useState('');
  const [nameFocused, setNameFocused] = useState(false);

  useEffect(() => {
    logger.debug(`Drive name: ${formData.name} and length: ${formData.name.length}`);
  }, [formData.name]);

  const validateName = (name: string): boolean => {
    if (!name.trim()) {
      setError(t('create.details.errors.required') as string);
      return false;
    }
    if (name?.length > MAX_NAME_LENGTH) {
      setError(t('create.details.errors.too_long') as string);
      return false;
    }
    // if (!NAME_REGEX.test(name)) {
    //   setError(t('create.details.errors.invalid_chars') as string);
    //   return false;
    // }
    return true;
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    // Prevent input if exceeding max length
    if (name?.length <= MAX_NAME_LENGTH) {
      setFormData({ ...formData, name });
      setError('');
    }
  };

  const handleSuggestionSelect = (suggestion: string) => {
    setFormData({ ...formData, name: suggestion });
    setError('');
    logger.debug('Suggestion selected', { data:{suggestion} });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validateName(formData.name)) {
      logger.debug(`Drive name: ${formData.name}`);
      onNext();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center space-y-2"
      >
        <h2 className={cn(
          "text-xl font-bold",
          "bg-clip-text text-transparent",
          "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]"
        )}>
          {t('create.details.title') as React.ReactNode}
        </h2>
        <p className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
          {t('create.details.subtitle') as React.ReactNode}
        </p>
      </motion.div>

      {/* Name Input */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="space-y-4"
      >
        <div className="relative">
          {/* Focus effect background */}
          <motion.div
            className={cn(
              "absolute inset-0",
              "rounded-xl",
              "bg-[hsl(var(--ghost-accent))/0.1]",
              "transition-all duration-500",
              "pointer-events-none"
            )}
            animate={{
              scale: nameFocused ? 1.02 : 1,
              opacity: nameFocused ? 0.2 : 0
            }}
          />

          <div className="relative">
            <input
              type="text"
              value={formData.name}
              onChange={handleNameChange}
              onFocus={() => setNameFocused(true)}
              onBlur={() => setNameFocused(false)}
              placeholder={t('create.details.placeholder') as string}
              maxLength={MAX_NAME_LENGTH}
              className={cn(
                "w-full px-4 py-3 pr-20",
                "bg-[hsl(var(--ghost-surface))]",
                "border border-[hsl(var(--ghost-accent))/0.2]",
                "rounded-xl",
                "text-[hsl(var(--ghost-foreground))]",
                "placeholder:text-[hsl(var(--ghost-foreground-muted))]",
                "focus:outline-none focus:ring-2",
                "focus:ring-[hsl(var(--ghost-accent))]",
                "transition-all duration-200",
                error && "border-red-500 focus:ring-red-500"
              )}
            />

            {/* Character count */}
            <span className={cn(
              "absolute right-4 top-1/2 -translate-y-1/2",
              "text-sm",
              error ? "text-red-500" : "text-[hsl(var(--ghost-foreground-muted))]"
            )}>
              {formData.name.length}/{MAX_NAME_LENGTH}
            </span>
          </div>

          {/* Error Message */}
          <AnimatePresence mode="wait">
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="flex items-center space-x-2 mt-2 text-red-500"
              >
                <AlertCircle className="w-4 h-4" />
                <span className="text-sm">{error}</span>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Quick Suggestions */}
        <div className="space-y-2">
          <label className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
            {t('create.details.suggestions') as React.ReactNode}
          </label>
          
          <div className="flex flex-wrap gap-2">
            {NAME_SUGGESTIONS.map((suggestion, index) => (
              <motion.button
                key={suggestion.name}
                type="button"
                onClick={() => handleSuggestionSelect(suggestion.name)}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ 
                  opacity: 1, 
                  scale: 1,
                  transition: { delay: index * 0.1 }
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={cn(
                  "px-3 py-1.5 rounded-lg",
                  "bg-[hsl(var(--ghost-surface))]",
                  "border border-[hsl(var(--ghost-accent))/0.2]",
                  "text-sm text-[hsl(var(--ghost-foreground))]",
                  "flex items-center space-x-2",
                  "transition-colors duration-200",
                  "hover:bg-[hsl(var(--ghost-accent))/0.1]"
                )}
              >
                <span role="img" aria-label={suggestion.name}>
                  {suggestion.emoji}
                </span>
                <span>{suggestion.name}</span>
              </motion.button>
            ))}
          </div>
        </div>
      </motion.div>

      {/* Action Buttons */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="flex justify-between pt-4"
      >
        <motion.button
          type="button"
          onClick={onBack}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className={cn(
            "px-6 py-2 rounded-lg",
            "bg-[hsl(var(--ghost-surface))]",
            "border border-[hsl(var(--ghost-accent))/0.2]",
            "text-[hsl(var(--ghost-foreground))]",
            "text-sm font-medium",
            "transition-colors duration-200",
            "hover:bg-[hsl(var(--ghost-accent))/0.1]"
          )}
        >
          {t('common.back') as React.ReactNode}
        </motion.button>

        <motion.button
          type="submit"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className={cn(
            "px-6 py-2 rounded-lg",
            "bg-gradient-to-r",
            "from-[hsl(var(--ghost-accent))]",
            "to-[hsl(var(--ghost-accent-secondary))]",
            "text-white text-sm font-medium",
            "flex items-center space-x-2"
          )}
        >
          <span>{t('common.continue') as React.ReactNode}</span>
          <Sparkles className="w-4 h-4" />
        </motion.button>
      </motion.div>
    </form>
  );
};

export default StepDriveDetails;