/**
 * @file src/context/NavigationContext.tsx
 * @description Enhanced navigation context with app-awareness for proper routing
 * @version 3.2.0
 */

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigationPath } from '@/hooks/useNavigationPath';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import type { NavigationState } from '@/types/navigation.types';
import { logger } from '@/utils/logger';
import { useToast } from '@/components/ui/toast';
import { isValidUUID } from '@/utils/validation';

interface NavigationContextType extends NavigationState {
  navigateToFolder: (folderId: string | null, options?: NavigationOptions) => Promise<void>;
  navigateToBreadcrumb: (breadcrumbId: string | null) => Promise<void>;
  navigateToFile: (fileId: string) => Promise<void>;
  getCurrentAppContext: () => AppContext;
}

type AppContext = 'ghost' | 'bigmind';

interface NavigationOptions {
  encryptedDriveId?: string;
  isEncryptedMode?: boolean;
  forceAppContext?: AppContext;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const queryClient = useQueryClient();
  const { showToast } = useToast();
  const { getNavigationPath } = useNavigationPath();
  
  // Wrap useRootDrive in a try/catch to handle the case where it's not available yet
  let rootDriveContext;
  try {
    rootDriveContext = useRootDrive();
  } catch (error) {
    // Handle the case when RootDriveProvider isn't available yet
    logger.error('RootDriveProvider not available yet in NavigationProvider', {
      component: 'NavigationProvider',
      error
    });
    
    // Show a delayed initialization message while app is routing after login
    return (
      <div className="delayed-initialization">
        {children}
      </div>
    );
  }
  
  // Safely destructure values from context once we know it exists
  const { activeDriveId } = rootDriveContext;
  
  // Enhanced state with proper typing
  const [navigationState, setNavigationState] = useState<NavigationState>({
    currentFolderId: null,
    breadcrumbs: [],
    isNavigating: false
  });

  // Destructure state for convenience
  const { currentFolderId, breadcrumbs, isNavigating } = navigationState;
  
  // Helper to determine current app context
  const getCurrentAppContext = useCallback((): AppContext => {
    return location.pathname.includes('/ghost/') ? 'ghost' : 'bigmind';
  }, [location.pathname]);

  // Update navigation state
  const updateNavigationState = useCallback((updates: Partial<NavigationState>) => {
    setNavigationState(prev => ({
      ...prev,
      ...updates
    }));
  }, []);

// File: src/context/NavigationContext.tsx
// Update the updateFolderPath function to explicitly handle encryption context

// Enhanced folder path update
const updateFolderPath = useCallback(async (folderId: string | null) => {
  if (isNavigating) return;

  updateNavigationState({ isNavigating: true });
  try {
    // Get encryption context from localStorage for consistency
    const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
    const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
    
    logger.debug('Updating folder path', {
      component: 'NavigationProvider',
      data: { 
        folderId, 
        currentFolderId,
        activeDriveId,
        isEncryptedMode,
        encryptedDriveId
      }
    });

    // Get complete navigation path including device/drive info
    // Now explicitly passing the encryption context
    const path = await getNavigationPath(folderId);
    
    if (path.segments?.length > 0) {
      updateNavigationState({
        currentFolderId: folderId,
        breadcrumbs: path.segments
      });

      logger.debug('Updated navigation state', {
        component: 'NavigationProvider',
        data: {
          breadcrumbCount: path.segments.length,
          currentFolderId: folderId,
          path: path.segments.map((b: any) => b.name).join(' > '),
          isEncryptedMode,
          encryptedDriveId
        }
      });
    }
  } catch (error) {
    logger.error('Error updating folder path:', {
      component: 'NavigationProvider',
      error,
      data: { 
        folderId,
        isEncryptedMode: localStorage.getItem('current_drive_type') === 'encrypted_drive'
      }
    });
    showToast('Failed to update folder path', 'error');
  } finally {
    updateNavigationState({ isNavigating: false });
  }
}, [getNavigationPath, showToast, currentFolderId, activeDriveId, isNavigating, updateNavigationState]);


  // Initialize from URL with drive check
  useEffect(() => {
    const initializeFromUrl = async () => {
      const match = location.pathname.match(/\/folder\/([^/]+)/);
      const urlFolderId = match ? match[1] : null;

      if (urlFolderId !== currentFolderId) {
        if (urlFolderId && !isValidUUID(urlFolderId)) {
          logger.warn('Invalid folder ID in URL, navigating to root', {
            component: 'NavigationProvider',
            data: { urlFolderId }
          });
          navigate('/cloud-drive', { replace: true });
          return;
        }

        if (urlFolderId && !activeDriveId) {
          logger.warn('No active drive ID, deferring navigation', {
            component: 'NavigationProvider'
          });
          return;
        }

        await updateFolderPath(urlFolderId);
      }
    };

    initializeFromUrl();
  }, [location.pathname, currentFolderId, updateFolderPath, navigate, activeDriveId]);

  // Enhanced folder navigation with app context awareness
  const navigateToFolder = useCallback(async (folderId: string | null, options?: NavigationOptions) => {

      // CRITICAL FIX: Early return if trying to navigate to current folder
      if (folderId === currentFolderId && !options?.forceAppContext) {
        logger.debug('Skipping navigation - already on folder', {
          component: 'NavigationProvider',
          data: { folderId, currentFolderId }
        });
        return;
      }


    if (isNavigating) return;

    try {
      // Get app context - from options, current URL, or localStorage
      const appContext = options?.forceAppContext || getCurrentAppContext();
      
      // Get encrypted drive info from options or localStorage
      const isEncryptedMode = options?.isEncryptedMode || 
                              localStorage.getItem('current_drive_type') === 'encrypted_drive';
      
      const encryptedDriveId = options?.encryptedDriveId || 
                               localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Navigating to folder', {
        component: 'NavigationProvider',
        data: { 
          folderId, 
          currentFolderId,
          appContext,
          isEncryptedMode,
          encryptedDriveId
        }
      });

      // Generate path based on app context and encryption status
      let path = '';
      if (appContext === 'ghost' && isEncryptedMode && encryptedDriveId) {
        path = folderId 
          ? `/ghost/drive/${encryptedDriveId}/folder/${folderId}` 
          : `/ghost/drive/${encryptedDriveId}`;
      } else {
        path = folderId ? `/cloud-drive/folder/${folderId}` : '/cloud-drive';
      }

      // Update URL and navigation state
      navigate(path, { replace: location.pathname.includes('/folder/') });

      // Update folder path
      await updateFolderPath(folderId);

    } catch (error) {
      logger.error('Navigation failed:', {
        component: 'NavigationProvider',
        error,
        data: { folderId }
      });
      showToast('Unable to navigate to folder', 'error');
    }
  }, [navigate, updateFolderPath, location.pathname, isNavigating, showToast, currentFolderId, getCurrentAppContext]);

  // File navigation with app context awareness
  const navigateToFile = useCallback(async (fileId: string) => {
    if (isNavigating) return;
    
    try {
      // Get app context and encryption status
      const appContext = getCurrentAppContext();
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Navigating to file', {
        component: 'NavigationProvider',
        data: { 
          fileId,
          appContext,
          isEncryptedMode,
          encryptedDriveId
        }
      });
      
      // Generate path based on app context and encryption status
      let path = '';
      if (appContext === 'ghost' && isEncryptedMode && encryptedDriveId) {
        path = `/ghost/drive/${encryptedDriveId}/file/${fileId}`;
      } else {
        path = `/cloud-drive/file/${fileId}`;
      }
      
      navigate(path);
    } catch (error) {
      logger.error('File navigation failed:', {
        component: 'NavigationProvider',
        error,
        data: { fileId }
      });
      showToast('Unable to navigate to file', 'error');
    }
  }, [navigate, getCurrentAppContext, isNavigating, showToast]);

  const navigateToBreadcrumb = useCallback(async (breadcrumbId: string | null) => {
    await navigateToFolder(breadcrumbId);
  }, [navigateToFolder]);

  const value = {
    ...navigationState,
    navigateToFolder,
    navigateToBreadcrumb,
    navigateToFile,
    getCurrentAppContext
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within NavigationProvider');
  }
  return context;
};

export default NavigationProvider;