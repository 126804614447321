/**
 * @file src/components/cloud-drive/sharing/ShareDialog.tsx
 * @description Gen Z inspired sharing dialog with enhanced animations and modern UI
 * @version 2.1.0
 * @updated 2025-03-19
 */

import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, useAnimationControls } from 'framer-motion';
import { 
  X, 
  Link, 
  Users, 
  Lock, 
  Copy, 
  Check,
  Trash,
  AlertTriangle,
  Loader2,
  Share2,
  ShieldCheck,
  Eye,
  Download,
  Calendar,
  KeyRound,
  Hash,
  Heart,
  ChevronDown,
  ExternalLink
} from 'lucide-react';
import { cn } from '@/utils/utils';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useCloudDrive } from '@/context/cloud-drive';
import { sharingService } from '@/services/sharing.service';
import { encryptedSharingService } from '@/services/encrypted-sharing.service';
import { useToast } from '@/components/ui/toast';
import { logger } from '@/utils/logger';
import type { DriveItem } from '@/types/cloud-drive.types';

// Define the types locally
interface ShareSettings {
  shareType: 'public' | 'password';
  permission: 'view' | 'download';
  expirationDate?: string;
  password?: string;
  accessLimit?: number;
}

interface EncryptedShareSettings extends ShareSettings {
  // Any additional encrypted share settings
}

interface ShareDialogProps {
  item: DriveItem;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

// Animation variants for elements
const fadeIn = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, y: -10, transition: { duration: 0.2 } }
};

const pulse = {
  initial: { scale: 1 },
  animate: { 
    scale: [1, 1.03, 1],
    transition: { duration: 1.5, repeat: Infinity, ease: "easeInOut" }
  }
};

const shimmer = {
  initial: { backgroundPosition: '200% 0' },
  animate: { 
    backgroundPosition: ['-200%', '200%'],
    transition: { 
      repeat: Infinity, 
      duration: 3,
      ease: "linear"
    }
  }
};

// Lazy-loaded dialog content component to prevent translation lookups when not needed
const ShareDialogContent = ({ 
  item, 
  isOpen, 
  onOpenChange 
}: ShareDialogProps) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { encryptedDrive } = useCloudDrive();
  
  // Animation controls
  const buttonControls = useAnimationControls();
  
  // State for share settings
  const [shareType, setShareType] = useState<'public' | 'password'>('public');
  const [permission, setPermission] = useState<'view' | 'download'>('view');
  const [expiration, setExpiration] = useState<string>('7days');
  const [password, setPassword] = useState<string>('');
  const [requirePassword, setRequirePassword] = useState(false);
  const [accessLimit, setAccessLimit] = useState<number>(5);
  const [limitAccess, setLimitAccess] = useState(false);
  
  // State for share operation
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [shareUrl, setShareUrl] = useState<string>('');
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  
  // Strength indicators for password
  const [passwordStrength, setPasswordStrength] = useState<'weak' | 'medium' | 'strong'>('weak');
  
  // Animate button on hover
  const handleButtonHover = () => {
    if (!isCreating) {
      buttonControls.start({
        scale: [1, 1.05, 1],
        transition: { duration: 0.5 }
      });
    }
  };
  
  // Check password strength
  useEffect(() => {
    if (!password) {
      setPasswordStrength('weak');
      return;
    }
    
    // Simple password strength check
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[^A-Za-z0-9]/.test(password);
    const isLongEnough = password.length >= 8;
    
    const strength = 
      (hasLowercase ? 1 : 0) +
      (hasUppercase ? 1 : 0) +
      (hasNumber ? 1 : 0) +
      (hasSpecialChar ? 1 : 0) +
      (isLongEnough ? 1 : 0);
    
    if (strength >= 4) {
      setPasswordStrength('strong');
    } else if (strength >= 2) {
      setPasswordStrength('medium');
    } else {
      setPasswordStrength('weak');
    }
  }, [password]);
  
  // When dialog opens, reset state
  useEffect(() => {
    if (isOpen) {
      setShareType('public');
      setPermission('view');
      setExpiration('7days');
      setPassword('');
      setRequirePassword(false);
      setAccessLimit(5);
      setLimitAccess(false);
      setShareUrl('');
      setIsCopied(false);
      setIsCreating(false);
      setIsDeleting(false);
      setErrorMessage(null);
    }
  }, [isOpen]);
  
  // Create share function with enhanced error handling
  const createShare = async () => {
    try {
      setIsCreating(true);
      setErrorMessage(null);
      
      // Client-side validation
      if (requirePassword && (!password || password.length < 4)) {
        setErrorMessage(t('cloud_drive.sharing.password_too_short'));
        return;
      }
      
      if (limitAccess && (!accessLimit || accessLimit < 1)) {
        setErrorMessage(t('cloud_drive.sharing.invalid_access_limit'));
        return;
      }
      
      // Convert expiration to date
      let expirationDate: string | undefined;
      switch (expiration) {
        case '1day':
          expirationDate = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString();
          break;
        case '7days':
          expirationDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString();
          break;
        case '30days':
          expirationDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString();
          break;
        case 'never':
          expirationDate = undefined;
          break;
      }
      
      // Log sharing attempt
      logger.debug('Creating share', {
        component: 'ShareDialog',
        data: {
          itemId: item.id,
          itemName: item.name,
          isEncrypted: encryptedDrive.isEncryptedMode,
          shareType: requirePassword ? 'password' : 'public',
          permission,
          expiration,
          hasPassword: !!requirePassword,
          hasAccessLimit: !!limitAccess
        }
      });
      
      // Prepare share settings
      const settings: ShareSettings & EncryptedShareSettings = {
        shareType: requirePassword ? 'password' : 'public',
        permission,
        expirationDate,
        password: requirePassword ? password : undefined,
        accessLimit: limitAccess ? accessLimit : undefined
      };
      
      let response;
      
      // Use different sharing service based on encryption status
      if (encryptedDrive.isEncryptedMode) {
        if (!encryptedDrive.encryptedDriveId) {
          throw new Error('Encrypted drive ID not found');
        }
        
        response = await encryptedSharingService.createEncryptedShare(
          item,
          encryptedDrive.encryptedDriveId,
          settings
        );
        
        logger.debug('Encrypted share created', {
          component: 'ShareDialog',
          data: { 
            shareId: response.id,
            shareUrl: response.shareUrl
          }
        });
      } else {
        response = await sharingService.createShare(item, settings);
        
        logger.debug('Standard share created', {
          component: 'ShareDialog',
          data: { 
            shareId: response.id,
            shareUrl: response.shareUrl
          }
        });
      }
      
      // Set share URL and show success toast
      setShareUrl(response.shareUrl);
      showToast(t('cloud_drive.sharing.created_success'), 'success');
    } catch (error) {
      logger.error('Error creating share:', {
        component: 'ShareDialog',
        error
      });
      
      // Set appropriate error message
      if (error instanceof Error) {
        if (error.message.includes('drive ID')) {
          setErrorMessage(t('cloud_drive.sharing.error_drive_not_found'));
        } else if (error.message.includes('network')) {
          setErrorMessage(t('cloud_drive.sharing.error_network'));
        } else if (error.message.toLowerCase().includes('permission')) {
          setErrorMessage(t('cloud_drive.sharing.error_permission'));
        } else {
          setErrorMessage(t('cloud_drive.sharing.error_create'));
        }
      } else {
        setErrorMessage(t('cloud_drive.sharing.error_create'));
      }
      
      showToast(t('cloud_drive.sharing.error_create'), 'error');
    } finally {
      setIsCreating(false);
    }
  };
  
  // Copy URL to clipboard with error handling
  const copyShareUrl = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
      
      logger.debug('Share URL copied to clipboard', {
        component: 'ShareDialog',
        data: { 
          urlLength: shareUrl.length,
          isEncrypted: encryptedDrive.isEncryptedMode,
        }
      });
      
      showToast(t('cloud_drive.sharing.copied'), 'success');
    } catch (error) {
      logger.error('Error copying to clipboard:', {
        component: 'ShareDialog',
        error
      });
      
      showToast(t('cloud_drive.sharing.error_copy'), 'error');
    }
  };
  
  // Delete share function with error handling
  const deleteShare = async () => {
    try {
      setIsDeleting(true);
      setErrorMessage(null);
      
      // Extract share ID from URL - a simplistic approach for now
      // In practice, would store the share ID in state when the share is created
      const shareKey = shareUrl.split('/').pop()?.split('#')[0];
      
      if (!shareKey) {
        throw new Error('Invalid share URL');
      }
      
      logger.debug('Deleting share', {
        component: 'ShareDialog',
        data: { shareKey }
      });
      
      await sharingService.deleteShare(shareKey);
      
      setShareUrl('');
      showToast(t('cloud_drive.sharing.deleted_success'), 'success');
      
      logger.debug('Share deleted successfully', {
        component: 'ShareDialog',
        data: { shareKey }
      });
    } catch (error) {
      logger.error('Error deleting share:', {
        component: 'ShareDialog',
        error
      });
      
      setErrorMessage(t('cloud_drive.sharing.error_delete'));
      showToast(t('cloud_drive.sharing.error_delete'), 'error');
    } finally {
      setIsDeleting(false);
    }
  };
  
  // Generate content for file information header
  const renderFileInfo = () => {
    const isEncrypted = encryptedDrive.isEncryptedMode;
    const fileSize = (item as any).size 
      ? `${((item as any).size / (1024 * 1024)).toFixed(1)}MB` 
      : '';
    
    return (
      <motion.div 
        className="flex items-center gap-4 py-3"
        initial="initial"
        animate="animate"
        variants={fadeIn}
      >
        <motion.div 
          className={cn(
            "w-12 h-12 rounded-xl flex items-center justify-center relative",
            isEncrypted 
              ? "bg-gradient-to-tr from-blue-100 to-indigo-200 dark:from-blue-900/40 dark:to-indigo-800/30" 
              : "bg-gradient-to-tr from-gray-100 to-gray-200 dark:from-gray-800/40 dark:to-gray-700/30"
          )}
          whileHover={{ scale: 1.05 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          {item.type === 'folder' ? (
            <motion.div
              className="w-7 h-7 bg-gradient-to-tr from-yellow-400 to-amber-500 dark:from-yellow-500 dark:to-amber-600 rounded-lg"
              whileHover={{ scale: 1.1, rotate: 5 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            />
          ) : (
            <motion.div
              className="w-7 h-7 bg-gradient-to-tr from-blue-400 to-indigo-500 dark:from-blue-500 dark:to-indigo-600 rounded-lg"
              whileHover={{ scale: 1.1, rotate: 5 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            />
          )}
          
          {isEncrypted && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10, delay: 0.2 }}
              className="absolute -bottom-1 -right-1 bg-gradient-to-tr from-blue-500 to-indigo-600 rounded-full p-1 shadow-md"
            >
              <Lock className="h-3 w-3 text-white" />
            </motion.div>
          )}
        </motion.div>
        <div className="flex-1 min-w-0">
          <motion.h3 
            className="text-base font-medium truncate"
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.1 }}
          >
            {item.name}
          </motion.h3>
          <motion.p 
            className="text-xs text-gray-500 dark:text-gray-400 flex items-center mt-1"
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            {fileSize}
            {isEncrypted && (
              <>
                {fileSize && <span className="mx-1.5">•</span>}
                <motion.div 
                  className="flex items-center"
                  whileHover={{ scale: 1.1 }}
                >
                  <Lock className="h-3 w-3 mr-1 text-blue-500" />
                  <span className="text-blue-500 dark:text-blue-400 font-medium">{t('cloud_drive.sharing.encrypted')}</span>
                </motion.div>
              </>
            )}
          </motion.p>
        </div>
      </motion.div>
    );
  };
  
  // Render password strength indicator
  const renderPasswordStrength = () => {
    if (!requirePassword || !password) return null;
    
    const strengthColors = {
      weak: "bg-gradient-to-r from-red-500 to-red-400",
      medium: "bg-gradient-to-r from-yellow-500 to-amber-400",
      strong: "bg-gradient-to-r from-green-500 to-emerald-400"
    };
    
    const strengthWidths = {
      weak: "w-1/3",
      medium: "w-2/3",
      strong: "w-full"
    };
    
    const strengthText = {
      weak: t('cloud_drive.sharing.password_weak'),
      medium: t('cloud_drive.sharing.password_medium'),
      strong: t('cloud_drive.sharing.password_strong')
    };
    
    return (
      <div className="mt-2 space-y-1.5">
        <div className="h-1.5 w-full bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className={cn("h-full", strengthWidths[passwordStrength], strengthColors[passwordStrength])}
          />
        </div>
        <motion.p 
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className={cn(
            "text-xs font-medium",
            passwordStrength === 'weak' && "text-red-500 dark:text-red-400",
            passwordStrength === 'medium' && "text-yellow-500 dark:text-yellow-400",
            passwordStrength === 'strong' && "text-green-500 dark:text-green-400"
          )}
        >
          {strengthText[passwordStrength]}
        </motion.p>
      </div>
    );
  };
  
  return (
    <DialogContent className="sm:max-w-md rounded-xl border-0 shadow-2xl bg-white/95 dark:bg-gray-900/95 backdrop-blur-sm">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "spring", duration: 0.5 }}
      >
        <DialogHeader>
          <DialogTitle className="flex items-center">
            <motion.div
              initial={{ rotate: -45, opacity: 0 }}
              animate={{ rotate: 0, opacity: 1 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
            >
              <Share2 className="mr-2 h-5 w-5 text-blue-500 dark:text-blue-400" />
            </motion.div>
            <motion.span
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.1 }}
            >
              {t('cloud_drive.dialogs.share.title')}
            </motion.span>
            {encryptedDrive.isEncryptedMode && (
              <motion.div 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: 0.2 }}
                className="ml-2 bg-blue-500/10 text-blue-500 px-2.5 py-0.5 rounded-full text-xs flex items-center"
              >
                <Lock className="h-3 w-3 mr-1" />
                {t('cloud_drive.sharing.encrypted')}
              </motion.div>
            )}
          </DialogTitle>
          {renderFileInfo()}
        </DialogHeader>
        
        <Tabs defaultValue="link" className="w-full">
          <TabsList className="grid w-full grid-cols-2 p-1 bg-gray-100 dark:bg-gray-800/50 rounded-lg backdrop-blur">
            <TabsTrigger 
              value="link"
              className="rounded-md data-[state=active]:bg-white dark:data-[state=active]:bg-gray-700 data-[state=active]:shadow-sm transition-all"
            >
              <motion.div 
                className="flex items-center"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                <Link className="h-4 w-4 mr-2" />
                {t('cloud_drive.sharing.link')}
              </motion.div>
            </TabsTrigger>
            <TabsTrigger 
              value="people" 
              disabled
              className="rounded-md data-[state=active]:bg-white dark:data-[state=active]:bg-gray-700 data-[state=active]:shadow-sm transition-all"
            >
              <motion.div 
                className="flex items-center"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                <Users className="h-4 w-4 mr-2" />
                {t('cloud_drive.sharing.people')}
              </motion.div>
            </TabsTrigger>
          </TabsList>
          
          <TabsContent value="link" className="space-y-5 mt-5 px-1">
            <AnimatePresence mode="wait">
              {shareUrl ? (
                <motion.div 
                  className="space-y-5"
                  key="share-success"
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeIn}
                >
                  <div>
                    <Label className="flex items-center mb-2 text-base">
                      {encryptedDrive.isEncryptedMode ? (
                        <ShieldCheck className="h-4 w-4 mr-2 text-blue-500" />
                      ) : (
                        <Link className="h-4 w-4 mr-2 text-gray-500" />
                      )}
                      {t('cloud_drive.sharing.secure_link')}
                    </Label>
                    <div className="flex">
                      <motion.div 
                        className="flex flex-1"
                        variants={pulse}
                      >
                        <Input 
                          value={shareUrl}
                          readOnly
                          className="flex-1 rounded-l-xl rounded-r-none focus-visible:ring-blue-500 bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700 font-mono text-sm truncate"
                        />
                        <Button
                          variant="outline"
                          size="icon"
                          className="rounded-l-none rounded-r-xl border-l-0 bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
                          onClick={copyShareUrl}
                        >
                          <AnimatePresence mode="wait">
                            {isCopied ? (
                              <motion.div
                                key="check"
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                              >
                                <Check className="h-4 w-4 text-green-500" />
                              </motion.div>
                            ) : (
                              <motion.div
                                key="copy"
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                              >
                                <Copy className="h-4 w-4" />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </Button>
                      </motion.div>
                    </div>
                  </div>
                  
                  <Alert 
                    className={cn(
                      encryptedDrive.isEncryptedMode 
                        ? "bg-blue-50/50 dark:bg-blue-950/30 border-blue-200 dark:border-blue-900/50 rounded-xl"
                        : "bg-amber-50/50 dark:bg-amber-950/30 border-amber-200 dark:border-amber-900/50 rounded-xl"
                    )}
                  >
                    <AlertTriangle className={cn(
                      "h-4 w-4",
                      encryptedDrive.isEncryptedMode 
                        ? "text-blue-500 dark:text-blue-400"
                        : "text-amber-500 dark:text-amber-400"
                    )} />
                    <AlertDescription className={cn(
                      "text-sm",
                      encryptedDrive.isEncryptedMode 
                        ? "text-blue-700 dark:text-blue-300" 
                        : "text-amber-700 dark:text-amber-300"
                    )}>
                      {encryptedDrive.isEncryptedMode 
                        ? t('cloud_drive.sharing.encrypted_warning')
                        : t('cloud_drive.sharing.standard_warning')}
                    </AlertDescription>
                  </Alert>
                  
                  <div className="grid grid-cols-2 gap-4">
                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                      <Button
                        variant="outline"
                        className="w-full rounded-xl border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 text-blue-600 dark:text-blue-400"
                        onClick={() => window.open(shareUrl, '_blank')}
                      >
                        <div className="flex items-center justify-center w-full">
                          <ExternalLink className="h-4 w-4 mr-2" />
                          <span>{t('cloud_drive.sharing.open_link')}</span>
                        </div>
                      </Button>
                    </motion.div>
                    
                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                      <Button
                        variant="outline"
                        className={cn(
                          "w-full rounded-xl text-red-500 border-red-100 hover:bg-red-50/50",
                          "dark:text-red-400 dark:border-red-900/30 dark:hover:bg-red-950/30"
                        )}
                        onClick={deleteShare}
                        disabled={isDeleting}
                      >
                        <div className="flex items-center justify-center w-full">
                          {isDeleting ? (
                            <>
                              <motion.div
                                animate={{ rotate: 360 }}
                                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                className="mr-2"
                              >
                                <Loader2 className="h-4 w-4" />
                              </motion.div>
                              <span>{t('cloud_drive.sharing.deleting')}</span>
                            </>
                          ) : (
                            <>
                              <Trash className="h-4 w-4 mr-2" />
                              <span>{t('cloud_drive.sharing.delete_share')}</span>
                            </>
                          )}
                        </div>
                      </Button>
                    </motion.div>
                  </div>
                  
                  {errorMessage && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Alert variant="destructive" className="rounded-xl bg-red-50 dark:bg-red-950/30 border-red-200 dark:border-red-800/50">
                        <AlertTriangle className="h-4 w-4" />
                        <AlertDescription>{errorMessage}</AlertDescription>
                      </Alert>
                    </motion.div>
                  )}
                </motion.div>
              ) : (
                <motion.div 
                  className="space-y-5"
                  key="share-form"
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeIn}
                >
                  <motion.div 
                    className="space-y-2"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <Label className="flex items-center text-base">
                      <Eye className="h-4 w-4 mr-2 text-gray-600 dark:text-gray-300" />
                      {t('cloud_drive.sharing.permission')}
                    </Label>
                    <Select
                      value={permission}
                      onValueChange={(value) => setPermission(value as 'view' | 'download')}
                    >
                      <SelectTrigger className="h-11 rounded-xl bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="rounded-xl overflow-hidden border-gray-200 dark:border-gray-700">
                        <motion.div 
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.2 }}
                        >
                          <SelectItem value="view" className="focus:bg-blue-50 dark:focus:bg-blue-900/20">
                            <div className="flex items-center">
                              <Eye className="h-4 w-4 mr-2 text-blue-500" />
                              <span>{t('cloud_drive.sharing.view_only')}</span>
                            </div>
                          </SelectItem>
                        </motion.div>
                        <motion.div 
                          initial={{ opacity: 0, y: -5 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.2, delay: 0.05 }}
                        >
                          <SelectItem value="download" className="focus:bg-blue-50 dark:focus:bg-blue-900/20">
                            <div className="flex items-center">
                              <Download className="h-4 w-4 mr-2 text-blue-500" />
                              <span>{t('cloud_drive.sharing.download')}</span>
                            </div>
                          </SelectItem>
                        </motion.div>
                      </SelectContent>
                    </Select>
                  </motion.div>
                  
                  <motion.div 
                    className="space-y-2"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <Label className="flex items-center text-base">
                      <Calendar className="h-4 w-4 mr-2 text-gray-600 dark:text-gray-300" />
                      {t('cloud_drive.sharing.expiration')}
                    </Label>
                    <Select
                      value={expiration}
                      onValueChange={setExpiration}
                    >
                      <SelectTrigger className="h-11 rounded-xl bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="rounded-xl overflow-hidden border-gray-200 dark:border-gray-700">
                        {['1day', '7days', '30days', 'never'].map((value, index) => (
                          <motion.div 
                            key={value}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2, delay: index * 0.05 }}
                          >
                            <SelectItem value={value} className="focus:bg-blue-50 dark:focus:bg-blue-900/20">
                              {t(`cloud_drive.sharing.expiration_${value}`)}
                            </SelectItem>
                          </motion.div>
                        ))}
                      </SelectContent>
                    </Select>
                  </motion.div>
                  
                  <motion.div 
                    className="p-4 bg-gray-50 dark:bg-gray-800/50 rounded-xl border border-gray-200 dark:border-gray-700 space-y-4"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.3 }}
                  >
                    <motion.div 
                      className="flex items-start space-x-3"
                      whileHover={{ x: 2 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Checkbox
                        id="require-password"
                        checked={requirePassword}
                        onCheckedChange={(checked) => setRequirePassword(!!checked)}
                        className="rounded-md border-gray-300 dark:border-gray-600 data-[state=checked]:bg-blue-500 data-[state=checked]:border-blue-500"
                      />
                      <div className="grid gap-1 leading-none">
                        <Label
                          htmlFor="require-password"
                          className="flex items-center cursor-pointer text-base font-medium leading-none"
                        >
                          <KeyRound className="h-4 w-4 mr-2 text-gray-600 dark:text-gray-300" />
                          {t('cloud_drive.sharing.require_password')}
                        </Label>
                        <p className="text-xs text-gray-500 mt-1">
                          {t('cloud_drive.sharing.password_info')}
                        </p>
                      </div>
                    </motion.div>
                    
                    <AnimatePresence>
                      {requirePassword && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                          className="overflow-hidden"
                        >
                          <div className="pt-2 pl-10">
                            <Input
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder={t('cloud_drive.sharing.password_placeholder')}
                              className="bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500 rounded-xl h-11 focus-visible:ring-blue-500"
                            />
                            {renderPasswordStrength()}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                    
                    <motion.div 
                      className="flex items-start space-x-3"
                      whileHover={{ x: 2 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Checkbox
                        id="limit-access"
                        checked={limitAccess}
                        onCheckedChange={(checked) => setLimitAccess(!!checked)}
                        className="rounded-md border-gray-300 dark:border-gray-600 data-[state=checked]:bg-blue-500 data-[state=checked]:border-blue-500"
                      />
                      <div className="grid gap-1 leading-none">
                        <Label
                          htmlFor="limit-access"
                          className="flex items-center cursor-pointer text-base font-medium leading-none"
                        >
                          <Hash className="h-4 w-4 mr-2 text-gray-600 dark:text-gray-300" />
                          {t('cloud_drive.sharing.limit_access')}
                        </Label>
                        <p className="text-xs text-gray-500 mt-1">
                          {t('cloud_drive.sharing.access_info')}
                        </p>
                      </div>
                    </motion.div>
                    
                    <AnimatePresence>
                      {limitAccess && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                          className="overflow-hidden"
                        >
                          <div className="flex items-center gap-3 pl-10 pt-2">
                            <Input
                              type="number"
                              min={1}
                              max={100}
                              value={accessLimit}
                              onChange={(e) => setAccessLimit(parseInt(e.target.value))}
                              className="bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500 rounded-xl h-11 w-28 focus-visible:ring-blue-500"
                            />
                            <span className="text-sm text-gray-600 dark:text-gray-300">
                              {t('cloud_drive.sharing.times')}
                            </span>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                  
                  {errorMessage && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Alert variant="destructive" className="rounded-xl bg-red-50 dark:bg-red-950/30 border-red-200 dark:border-red-800/50">
                        <AlertTriangle className="h-4 w-4" />
                        <AlertDescription>{errorMessage}</AlertDescription>
                      </Alert>
                    </motion.div>
                  )}
                  
                  <motion.div
                    onHoverStart={handleButtonHover}  // Use onHoverStart to trigger the function
                    whileTap={{ scale: 0.98 }}
                    animate={buttonControls}
                    initial={{ opacity: 0, y: 10 }}
                    transition={{ 
                      duration: 0.3, 
                      delay: 0.4 
                    }}
                    className="w-full"
                  >
                    <Button
                      className={cn(
                        "w-full h-12 rounded-xl font-medium text-base",
                        "bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700",
                        "text-white shadow-md hover:shadow-lg",
                        "dark:from-blue-600 dark:to-indigo-700 dark:hover:from-blue-700 dark:hover:to-indigo-800"
                      )}
                      onClick={createShare}
                      disabled={isCreating || (requirePassword && (!password || password.length < 4))}
                      style={{
                        backgroundSize: '200% 100%'
                      }}
                    >
                      {isCreating ? (
                        <div className="flex items-center justify-center w-full">
                          <motion.div
                            animate={{ rotate: 360 }}
                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                            className="mr-3"
                          >
                            <Loader2 className="h-5 w-5" />
                          </motion.div>
                          <span>{t('cloud_drive.sharing.creating')}</span>
                        </div>
                      ) : (
                        <motion.div 
                          className="flex items-center justify-center w-full"
                          variants={shimmer}
                          style={{
                            backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)',
                            backgroundSize: '200% 100%',
                            backgroundPosition: '0% 0%'
                          }}
                        >
                          <Share2 className="h-5 w-5 mr-2" />
                          <span>{t('cloud_drive.sharing.create_share')}</span>
                        </motion.div>
                      )}
                    </Button>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </TabsContent>
          
          <TabsContent value="people">
            <motion.div 
              className="p-8 text-center text-gray-500 dark:text-gray-400"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <motion.div
                whileHover={{ scale: 1.05, rotate: 5 }}
                transition={{ type: "spring", stiffness: 300, damping: 10 }}
              >
                <Users className="h-10 w-10 mx-auto mb-3 opacity-50" />
              </motion.div>
              <p className="mb-2 font-medium text-base">{t('cloud_drive.sharing.people_tab_title')}</p>
              <div className="flex justify-center">
                <div className="bg-gradient-to-r from-blue-500/10 to-indigo-500/10 dark:from-blue-500/20 dark:to-indigo-500/20 px-3 py-1 rounded-full text-sm flex items-center">
                  <Heart className="h-3 w-3 mr-1.5 text-blue-500" />
                  <span className="text-blue-600 dark:text-blue-400">{t('cloud_drive.sharing.coming_soon')}</span>
                </div>
              </div>
            </motion.div>
          </TabsContent>
        </Tabs>
      </motion.div>
    </DialogContent>
  );
};

// Main export component that uses lazy loading to prevent translation lookups when not open
export const ShareDialog: React.FC<ShareDialogProps> = ({ 
  item, 
  isOpen, 
  onOpenChange 
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      {isOpen && (
        <Suspense fallback={null}>
          <ShareDialogContent item={item} isOpen={isOpen} onOpenChange={onOpenChange} />
        </Suspense>
      )}
    </Dialog>
  );
};

export default ShareDialog;