/**
 * @file src/context/cloud-drive/hooks/useDriveQueries.ts
 * @description Query hooks for cloud drive data fetching with encryption support
 * @version 2.1.0
 */

import { useCallback } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useToast } from '@/components/ui/toast';
import { cloudDriveService } from '@/services/cloud-drive.service';
import { encryptedFilesService } from '@/services/encrypted-drive/encrypted-files.service';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import type { BreadcrumbItem, EncryptedDriveState } from '../types';
import { logger } from '@/utils/logger';
import { isValidUUID } from '@/utils/validation';

// Explicitly define the props including state functions
interface UseDriveQueriesProps {
  encryptedDrive: EncryptedDriveState;
  setCurrentFolderContents: (contents: any) => void;
  setBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void;
}

interface FolderContentsResponse {
  items: any[];
  hasMore: boolean;
  nextCursor?: string;
}

export const useDriveQueries = ({ 
  encryptedDrive, 
  setCurrentFolderContents, 
  setBreadcrumbs 
}: UseDriveQueriesProps) => {
  const { showToast } = useToast();
  const { activeDriveId } = useRootDrive();

  // Enhanced folder content fetching with encryption support
  const getFolderContents = useCallback((folderId: string | null) => {
    return useInfiniteQuery<FolderContentsResponse, Error, FolderContentsResponse>({
      queryKey: ['cloud-drive', 'folder', folderId, activeDriveId, encryptedDrive.isEncryptedMode],
      queryFn: async ({ pageParam = undefined }) => {
        try {
          logger.debug('Fetching folder contents', {
            component: 'useDriveQueries',
            data: { 
              folderId, 
              pageParam, 
              currentDriveId: activeDriveId,
              isEncrypted: encryptedDrive.isEncryptedMode 
            }
          });

          if (!activeDriveId && !encryptedDrive.encryptedDriveId) {
            logger.warn('No drive ID available', {
              component: 'useDriveQueries'
            });
            throw new Error('No drive selected');
          }

          // Use appropriate service based on encryption mode
          const response = encryptedDrive.isEncryptedMode
            ? await encryptedFilesService.listContents({
                driveId: encryptedDrive.encryptedDriveId!,
                folderId,
                cursor: pageParam as string | undefined
              })
            : await cloudDriveService.listFolderContents(
                folderId,
                pageParam as string | undefined
              );

          logger.debug('Processing folder contents response', {
            component: 'useDriveQueries',
            data: {
              itemCount: response.items?.length,
              isEncrypted: encryptedDrive.isEncryptedMode,
              hasMore: response.hasMore
            }
          });

          return response;
        } catch (error) {
          logger.error('Error fetching folder contents:', {
            component: 'useDriveQueries',
            error,
            data: {
              folderId,
              activeDriveId,
              isEncrypted: encryptedDrive.isEncryptedMode,
              pageParam
            }
          });
          throw error;
        }
      },
      getNextPageParam: (lastPage: FolderContentsResponse) => lastPage.hasMore ? lastPage.nextCursor : undefined,
      initialPageParam: undefined,
      staleTime: 1000 * 5, // Reduce stale time to just 5 seconds for even faster refreshes
      refetchOnWindowFocus: true, // Refresh when window regains focus
      refetchOnMount: true, // Also refetch when components mount
      refetchOnReconnect: true, // Refetch on network reconnects
      enabled: !!(activeDriveId || encryptedDrive.encryptedDriveId)
    });
  }, [activeDriveId, encryptedDrive.isEncryptedMode, encryptedDrive.encryptedDriveId]);

  // Enhanced folder path with encryption support
  const getFolderPath = useCallback((folderId: string | null) => {
    return useQuery<BreadcrumbItem[], Error>({
      queryKey: ['cloud-drive', 'path', folderId, encryptedDrive.isEncryptedMode],
      queryFn: async (): Promise<BreadcrumbItem[]> => {
        try {
          if (!folderId || !isValidUUID(folderId)) {
            logger.debug('Invalid or null folder ID, returning root path', {
              component: 'useDriveQueries',
              data: { folderId }
            });
            return [{ 
              id: 'root', 
              name: encryptedDrive.isEncryptedMode 
                ? encryptedDrive.driveName || 'Encrypted Drive'
                : 'Cloud Drive', 
              path: ['root'] 
            }];
          }

          // Use appropriate service based on encryption mode
          const folderDetails = encryptedDrive.isEncryptedMode
            ? await encryptedFilesService.getFileDetails(
                folderId,
                encryptedDrive.encryptedDriveId!
              )
            : await cloudDriveService.getFolderDetails(folderId);

          if (!folderDetails) {
            logger.warn('No folder details found', {
              component: 'useDriveQueries',
              data: { 
                folderId,
                isEncrypted: encryptedDrive.isEncryptedMode 
              }
            });
            return [{ 
              id: 'root', 
              name: encryptedDrive.isEncryptedMode 
                ? encryptedDrive.driveName || 'Encrypted Drive'
                : 'Cloud Drive',
              path: ['root'] 
            }];
          }

          const pathSegments = folderDetails.path || [];
          const breadcrumbPath = [{
            id: 'root',
            name: encryptedDrive.isEncryptedMode 
              ? encryptedDrive.driveName || 'Encrypted Drive'
              : 'Cloud Drive',
            path: ['root']
          }];

          // Build path hierarchy
          for (const pathId of pathSegments) {
            if (pathId === 'root') continue;
            
            if (!isValidUUID(pathId)) {
              logger.warn('Invalid UUID in path segments, skipping', {
                component: 'useDriveQueries',
                data: { pathId }
              });
              continue;
            }

            try {
              const details = encryptedDrive.isEncryptedMode
                ? await encryptedFilesService.getFileDetails(
                    pathId,
                    encryptedDrive.encryptedDriveId!
                  )
                : await cloudDriveService.getFolderDetails(pathId);

              if (details) {
                breadcrumbPath.push({
                  id: details.id,
                  name: details.name,
                  path: [...breadcrumbPath.map((b: any) => b.id), details.id]
                });
              }
            } catch (error) {
              logger.warn('Error fetching path segment details, skipping', {
                component: 'useDriveQueries',
                error,
                data: { pathId }
              });
            }
          }

          // Add current folder if not in path
          if (!breadcrumbPath.find((b: any) => b.id === folderId)) {
            breadcrumbPath.push({
              id: folderId,
              name: folderDetails.name,
              path: [...breadcrumbPath.map((b: any) => b.id), folderId]
            });
          }

          setBreadcrumbs(breadcrumbPath);
          return breadcrumbPath;
        } catch (error) {
          logger.error('Error getting folder path:', {
            component: 'useDriveQueries',
            error,
            data: { 
              folderId,
              isEncrypted: encryptedDrive.isEncryptedMode 
            }
          });
          return [{ 
            id: 'root', 
            name: encryptedDrive.isEncryptedMode 
              ? encryptedDrive.driveName || 'Encrypted Drive'
              : 'Cloud Drive',
            path: ['root'] 
          }];
        }
      },
      staleTime: 1000 * 60,
      retry: 2
    });
  }, [encryptedDrive.isEncryptedMode, encryptedDrive.encryptedDriveId, encryptedDrive.driveName, setBreadcrumbs]);

  return {
    getFolderContents,
    getFolderPath
  };
};
