/**
 * @file src/components/services/ServicesAvailableView.tsx
 * @description Grid view of available cloud services for connection
 * @version 1.0.0
 * @created 2025-02-28
 * @dependencies framer-motion, lucide-react, react-i18next
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ArrowLeft, Cloud, Loader2, AlertCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';
import { ServiceCard } from './ServiceCard';
import { ServiceAuthDialog } from './ServiceAuthDialog';
import { useCloudServices } from '@/hooks/useCloudServices';
import { ServiceSearchBar } from './ServiceSearchBar';
import { logger } from '@/utils/logger';

interface ServicesAvailableViewProps {
  onBack: () => void;
  className?: string;
}

export const ServicesAvailableView: React.FC<ServicesAvailableViewProps> = ({
  onBack,
  className
}) => {
  const { t } = useTranslation();
  const {
    services,
    isLoading,
    error,
    connectService,
    disconnectService,
    connectingService,
  } = useCloudServices();

  const [searchQuery, setSearchQuery] = useState('');
  const [authDialogService, setAuthDialogService] = useState<any | null>(null);

  // Filter services based on search query
  const filteredServices = services
    ? services.filter((service: any) => {
        if (!searchQuery.trim()) return true;
        const query = searchQuery.toLowerCase();
        return (
          service.name.toLowerCase().includes(query) ||
          service.type.toLowerCase().includes(query) ||
          service.description?.toLowerCase().includes(query)
        );
      })
    : [];

  // Handle search
  const handleSearch = (query: string) => {
    logger.debug('Service search', {
      component: 'ServicesAvailableView',
      query,
      action: 'search'
    });
    setSearchQuery(query);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col h-full">
        {/* Header with back button */}
        <div className="flex items-center gap-4 p-6 pb-2 border-b border-gray-100 dark:border-gray-800">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={onBack}
            className="mr-2"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            {t('common.back')}
          </Button>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
            {t('services.available.title')}
          </h2>
        </div>
        
        <div className="flex-1 flex items-center justify-center">
          <div className="flex items-center space-x-2 text-blue-500 dark:text-blue-400">
            <Loader2 className="w-5 h-5 animate-spin" />
            <span>{t('services.loading')}</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col h-full">
        {/* Header with back button */}
        <div className="flex items-center gap-4 p-6 pb-2 border-b border-gray-100 dark:border-gray-800">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={onBack}
            className="mr-2"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            {t('common.back')}
          </Button>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
            {t('services.available.title')}
          </h2>
        </div>
        
        <div className="flex-1 flex flex-col items-center justify-center">
          <div className="rounded-full bg-red-100 dark:bg-red-900/20 p-3 mb-4">
            <AlertCircle className="h-6 w-6 text-red-600 dark:text-red-400" />
          </div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
            {t('services.error_title')}
          </h3>
          <p className="text-gray-500 dark:text-gray-400 text-center mb-4">
            {t('services.error_description')}
          </p>
          <Button onClick={() => window.location.reload()}>
            {t('common.try_again')}
          </Button>
        </div>
      </div>
    );
  }

  if (!filteredServices.length) {
    return (
      <div className="flex flex-col h-full">
        {/* Header with back button and search */}
        <div className="p-6 pb-4 border-b border-gray-100 dark:border-gray-800">
          <div className="flex items-center gap-4 mb-4">
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={onBack}
              className="mr-2"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              {t('common.back')}
            </Button>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
              {t('services.available.title')}
            </h2>
          </div>
          
          <ServiceSearchBar onSearch={handleSearch} initialQuery={searchQuery} />
        </div>
        
        <div className="flex-1 flex flex-col items-center justify-center p-6">
          <div className="rounded-full bg-blue-100 dark:bg-blue-900/20 p-3 mb-4">
            <Cloud className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
            {searchQuery
              ? t('services.no_matching_services_title')
              : t('services.no_available_services_title')}
          </h3>
          
          <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
            {searchQuery
              ? t('services.no_matching_services_description', { query: searchQuery })
              : t('services.no_available_services_description')}
          </p>
          
          {searchQuery && (
            <Button 
              variant="outline" 
              onClick={() => setSearchQuery('')} 
              className="mt-4"
            >
              {t('services.search.clear')}
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={cn("flex flex-col h-full", className)}>
      {/* Header with back button and search */}
      <div className="p-6 pb-4 border-b border-gray-100 dark:border-gray-800">
        <div className="flex items-center gap-4 mb-4">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={onBack}
            className="mr-2"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            {t('common.back')}
          </Button>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
            {t('services.available.title')}
          </h2>
        </div>
        
        <ServiceSearchBar onSearch={handleSearch} initialQuery={searchQuery} />
      </div>

      {/* Service grid */}
      <div className="p-6 overflow-auto">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          {filteredServices.map((service) => (
            <ServiceCard
              key={service.id}
              service={service}
              onConnect={() => {
                logger.debug('Connecting to service', {
                  component: 'ServicesAvailableView',
                  serviceId: service.id,
                  serviceType: service.type
                });
                setAuthDialogService(service);
              }}
              onDisconnect={() => disconnectService(service.id)}
              isConnecting={connectingService === service.type}
            />
          ))}
        </motion.div>
      </div>

      {/* Auth dialog */}
      {authDialogService && (
        <ServiceAuthDialog
          service={authDialogService}
          isOpen={!!authDialogService}
          onClose={() => setAuthDialogService(null)}
          onSuccess={() => {
            setAuthDialogService(null);
            // Optionally navigate back to connected services view
            onBack();
          }}
        />
      )}
    </div>
  );
};

export default ServicesAvailableView;