/**
 * @file src/i18n/ghost/config.ts
 * @description Ghost-specific i18n configuration
 * @version 1.2.0
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { logger } from '@/utils/logger';

// Import ghost translations
import en from '@/locales/ghost/en.json';
import ja from '@/locales/ghost/ja.json';
import ko from '@/locales/ghost/ko.json';
import it from '@/locales/ghost/it.json';
// New language imports will be added here as they become available
// import fr from '@/locales/ghost/fr.json';
// import de from '@/locales/ghost/de.json';
// import es from '@/locales/ghost/es.json';
// import zh from '@/locales/ghost/zh.json';
// import pt from '@/locales/ghost/pt.json';
// import nl from '@/locales/ghost/nl.json';

// Initialize Ghost-specific i18n instance
const ghostI18n = i18n.createInstance();

// Define available languages (even if translation files aren't yet complete)
// This will allow the language selector to show all planned languages
export const availableLanguages = [
  { code: 'en', name: 'English', nativeName: 'English' },
  { code: 'fr', name: 'French', nativeName: 'Français' },
  { code: 'de', name: 'German', nativeName: 'Deutsch' },
  { code: 'es', name: 'Spanish', nativeName: 'Español' },
  { code: 'it', name: 'Italian', nativeName: 'Italiano' },
  { code: 'ja', name: 'Japanese', nativeName: '日本語' },
  { code: 'zh', name: 'Chinese', nativeName: '中文' },
  { code: 'ko', name: 'Korean', nativeName: '한국어' },
  { code: 'pt', name: 'Portuguese', nativeName: 'Português' },
  { code: 'nl', name: 'Dutch', nativeName: 'Nederlands' }
];

ghostI18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { ghost: en },  // Notice the 'ghost' namespace here
      ja: { ghost: ja },
      ko: { ghost: ko },
      it: { ghost: it },
      // New languages will be added here as they become available
      // fr: { ghost: fr },
      // de: { ghost: de },
      // es: { ghost: es },
      // zh: { ghost: zh },
      // pt: { ghost: pt },
      // nl: { ghost: nl }
    },
    fallbackLng: 'en',
    defaultNS: 'ghost',  // Set default namespace
    ns: ['ghost'],       // Declare namespaces
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'ghost_language',
      caches: ['localStorage']
    },

    // Log missing translations in development
    saveMissing: process.env.NODE_ENV === 'development',
    missingKeyHandler: (lng, ns, key) => {
      logger.warn('Missing ghost translation:', { data:{lng, ns, key} });
    },
  });

export default ghostI18n;