/**
 * @file src/components/ui/tooltip.tsx
 * @description Tooltip UI component with theme support and proper positioning
 * @version 1.1.0
 * @date 2024-12-23
 */

import * as React from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { cn } from "@/utils/utils"

interface TooltipProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root> {
  delayDuration?: number
}

interface TooltipTriggerProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger> {}

interface TooltipContentProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  sideOffset?: number
}

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = ({ delayDuration = 200, ...props }: TooltipProps) => (
  <TooltipPrimitive.Root delayDuration={delayDuration} {...props} />
)

const TooltipTrigger = React.forwardRef<HTMLButtonElement, TooltipTriggerProps>(
  ({ className, ...props }, ref) => (
    <TooltipPrimitive.Trigger
      ref={ref}
      className={cn("inline-flex", className)}
      {...props}
    />
  )
)

const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps>(
  ({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
          "z-50 overflow-hidden px-3 py-1.5 text-sm",
          "bg-gray-900 dark:bg-gray-100",
          "text-white dark:text-gray-900",
          "rounded-md shadow-md",
          "animate-in fade-in-0 zoom-in-95",
          "data-[side=bottom]:slide-in-from-top-2",
          "data-[side=top]:slide-in-from-bottom-2",
          "data-[side=left]:slide-in-from-right-2",
          "data-[side=right]:slide-in-from-left-2",
          className
        )}
        {...props}
      />
    </TooltipPrimitive.Portal>
  )
)

Tooltip.displayName = "Tooltip"
TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }