/**
 * @file src/services/encrypted-drive/encryption-singleton.ts
 * @description Enhanced encryption facade service with session storage persistence
 * @version 2.0.0
 */

import { EncryptionService } from './encryption.service';
import { logger } from '@/utils/logger';
import { debugLogger } from '@/utils/debug-logger';
import { EncryptionTier } from '@/types/encrypted-drive.types';
import type { EncryptedDrive } from '@/types/encrypted-drive.types';
import type { KeyGenerationResult } from '@/types/encryption.types';

// Session storage keys
const STORAGE_KEYS = {
  UNLOCKED_DRIVES: 'bigmind_unlocked_drives',
  LAST_UNLOCKED: 'bigmind_last_unlock_time'
} as const;

// Key expiration in milliseconds (24 hours)
const KEY_EXPIRATION = 24 * 60 * 60 * 1000;

class EncryptionFacadeService {
  private encryptionInstance: EncryptionService;
  // Make these accessible for direct manipulation in edge cases
  public unlockedDrives: Set<string>;
  private lastUnlockTime: Record<string, number>;
  public driveTierMap: Map<string, string> = new Map(); // Add map of drive IDs to encryption tiers
  public currentEncryptionTier: EncryptionTier | string = EncryptionTier.STANDARD; // Default encryption tier
  
  /**
   * Utility method to convert ArrayBuffer to base64 safely in chunks
   * to avoid maximum call stack size exceeded errors
   */
  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    try {
      const bytes = new Uint8Array(buffer);
      
      // For small buffers, use direct conversion
      if (bytes.length < 10000) {
        let binary = '';
        for (let i = 0; i < bytes.length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
      }
      
      // For larger buffers, process in chunks
      const chunkSize = 1024; // 1KB chunks
      let binary = '';
      
      // Process in chunks to avoid stack overflow
      for (let i = 0; i < bytes.length; i += chunkSize) {
        const chunk = bytes.slice(i, Math.min(i + chunkSize, bytes.length));
        for (let j = 0; j < chunk.length; j++) {
          binary += String.fromCharCode(chunk[j]);
        }
      }
      
      debugLogger.info('Processed large buffer in chunks', {
        component: 'EncryptionFacadeService',
        data: {
          originalSize: buffer.byteLength,
          chunkSize,
          chunksProcessed: Math.ceil(bytes.length / chunkSize)
        }
      });
      
      return btoa(binary);
    } catch (error) {
      debugLogger.error('Error converting ArrayBuffer to base64', {
        component: 'EncryptionFacadeService',
        error,
        data: {
          bufferSize: buffer.byteLength
        }
      });
      throw new Error('Failed to convert buffer to base64: ' + 
        (error instanceof Error ? error.message : 'Unknown error'));
    }
  }
  
  /**
   * Enhanced method for encrypting content with specific tiers
   * Delegates to the actual implementation in EncryptionService
   */
  async encryptContentWithTier(
    content: ArrayBuffer, 
    tier: EncryptionTier, 
    key: CryptoKey,
    driveId?: string
  ): Promise<{ encryptedData: string; iv: string; algorithm?: string }> {
    try {
      // Add validation to confirm the encryption tier is consistent
      if (driveId) {
        const storedTier = this.getDriveEncryptionTier(driveId);
        if (storedTier && storedTier !== tier) {
          logger.warn('Encryption tier mismatch detected in encryptContentWithTier', {
            component: 'EncryptionFacadeService',
            data: { 
              requestedTier: tier,
              storedTier: storedTier,
              driveId: driveId,
              usingStoredTier: true
            }
          });
          
          // Always use the stored tier to prevent inconsistency
          tier = storedTier as EncryptionTier;
        }
      }
      
      // Add debug logging
      console.debug(`[EncryptionService] Encrypting content with tier: ${tier}`);
      
      logger.debug('Delegating content encryption to tier-specific implementation', {
        component: 'EncryptionFacadeService',
        data: { 
          contentSize: content.byteLength,
          tier: tier,
          currentEncryptionTier: this.currentEncryptionTier,
          driveId: driveId || 'not provided'
        }
      });

      // Delegate to the encryption service to use the right algorithm based on tier
      const result = await this.encryptionInstance.encryptContent(content, tier, key);
      
      // Add debug logging for successful encryption
      console.debug(`[EncryptionService] Content encrypted successfully with algorithm: ${result.algorithm || tier}`);
      
      // Return the result with all properties
      return {
        encryptedData: result.encryptedData,
        iv: result.iv,
        algorithm: result.algorithm
      };
    } catch (error) {
      logger.error('Error in encryptContentWithTier:', {
        component: 'EncryptionFacadeService',
        error,
        data: {
          contentSize: content?.byteLength,
          tier: tier,
          errorType: error instanceof Error ? error.constructor.name : typeof error
        }
      });

      // Warn about the encryption failure but don't fall back to AES-GCM
      // because that would create an inconsistency in the encryption algorithm
      logger.error('Encryption failed with specified tier - NOT falling back to AES-GCM', {
        component: 'EncryptionFacadeService',
        data: { 
          tier: tier,
          contentSize: content?.byteLength
        }
      });
      
      // Rethrow the original error to ensure the client knows encryption failed
      // rather than silently using a different algorithm which would cause
      // decryption problems later
      throw error;
    }
  }

  constructor() {
    this.encryptionInstance = new EncryptionService();
    this.unlockedDrives = new Set();
    this.lastUnlockTime = {};
    this.loadStoredState();

    // Register cleanup on page unload
    window.addEventListener('beforeunload', () => {
      this.saveStoredState();
    });

    // Periodically check for expired keys
    setInterval(() => this.cleanupExpiredKeys(), 5 * 60 * 1000); // Check every 5 minutes
  }
  
  /**
   * Get the encryption service instance
   * @returns The encryption service instance
   */
  getEncryptionService(): EncryptionService {
    return this.encryptionInstance;
  }
  
  /**
   * Store decrypted keys for a drive
   * @param driveId Drive ID
   * @param keys Decrypted keys
   */
  storeDecryptedKeys(driveId: string, keys: any, encryptionTier?: EncryptionTier | string): void {
    if (!driveId || !keys) {
      logger.error('Cannot store undefined keys', {
        component: 'EncryptionFacadeService',
        data: { driveId }
      });
      return;
    }
    
    // Extra validation and debug logging
    console.debug(`[EncryptionService] Storing keys for drive ${driveId}`);
    
    if ((this.encryptionInstance as any).setCachedKeys) {
      (this.encryptionInstance as any).setCachedKeys(driveId, keys);
    }
    
    this.unlockedDrives.add(driveId);
    this.lastUnlockTime[driveId] = Date.now();
    
    // If an encryption tier was provided, store it in the map
    if (encryptionTier) {
      // Check if this drive already has a tier before overwriting
      if (!this.driveTierMap.has(driveId)) {
        this.driveTierMap.set(driveId, encryptionTier);
        
        // Also update localStorage to ensure persistence
        try {
          const storedTiers = localStorage.getItem('drive_encryption_tiers');
          const tierData = storedTiers ? JSON.parse(storedTiers) : {};
          tierData[driveId] = encryptionTier;
          localStorage.setItem('drive_encryption_tiers', JSON.stringify(tierData));
          
          logger.debug('Updated tier in localStorage during key storage', {
            component: 'EncryptionFacadeService',
            data: { driveId, tier: encryptionTier }
          });
        } catch (e) {
          logger.error('Error updating tier in localStorage', {
            component: 'EncryptionFacadeService',
            error: e
          });
        }
      }
    } else {
      // If no tier was provided, check if we should add one
      if (!this.driveTierMap.has(driveId)) {
        logger.warn('Storing keys without encryption tier', {
          component: 'EncryptionFacadeService',
          data: { driveId }
        });
      }
    }
    
    // Ensure all unlocked drives have tiers
    this.ensureUnlockedDrivesHaveTiers();
    
    // Save to session storage
    this.saveStoredState();
  }

  /**
   * Load unlocked drive state from session storage
   */
  private loadStoredState() {
    try {
      // Load unlocked drives
      const storedDrives = sessionStorage.getItem(STORAGE_KEYS.UNLOCKED_DRIVES);
      
      // Load encryption tiers from localStorage (these persist across sessions)
      try {
        const storedTiers = localStorage.getItem('drive_encryption_tiers');
        if (storedTiers) {
          const tierData = JSON.parse(storedTiers);
          
          // Clear existing tiers and load from storage
          this.driveTierMap.clear();
          
          for (const [driveId, tier] of Object.entries(tierData)) {
            this.driveTierMap.set(driveId, tier as string);
          }
          
          logger.debug('Loaded encryption tiers from localStorage', {
            component: 'EncryptionFacadeService',
            data: {
              tiersCount: this.driveTierMap.size,
              tiers: Object.fromEntries(this.driveTierMap.entries())
            }
          });
          
          // Additional debug logging for easier troubleshooting
          console.debug(`[EncryptionService] Loaded ${this.driveTierMap.size} encryption tiers from localStorage`);
        }
      } catch (tierError) {
        logger.error('Error loading encryption tiers from localStorage', {
          component: 'EncryptionFacadeService',
          error: tierError
        });
      }
      
      if (storedDrives) {
        const driveData = JSON.parse(storedDrives);
        this.unlockedDrives = new Set(driveData.driveIds);
        this.lastUnlockTime = driveData.timestamps || {};
        
        // Load encryption tiers from session if available (these are also backed up in session)
        if (driveData.tiers) {
          for (const [driveId, tier] of Object.entries(driveData.tiers)) {
            // Only set if not already loaded from localStorage
            if (!this.driveTierMap.has(driveId)) {
              this.driveTierMap.set(driveId, tier as string);
            }
          }
        }
        
        logger.debug('Loaded stored drive states', {
          component: 'EncryptionFacadeService',
          data: { 
            unlockedCount: this.unlockedDrives.size,
            tiersCount: this.driveTierMap.size,
            driveIds: Array.from(this.unlockedDrives),
            tiers: Object.fromEntries(this.driveTierMap.entries())
          }
        });

        // Important: Restore keys from previously unlocked drives
        // We'll try to restore the actual keys that might be missing
        Array.from(this.unlockedDrives).forEach(driveId => {
          const keys = this.encryptionInstance.getDecryptedKeys(driveId);
          if (!keys) {
            logger.warn('Keys missing for unlocked drive, removing from unlocked set', {
              component: 'EncryptionFacadeService',
              data: { driveId }
            });
            this.unlockedDrives.delete(driveId);
            delete this.lastUnlockTime[driveId];
            this.driveTierMap.delete(driveId);
          } else {
            logger.debug('Keys found for unlocked drive', {
              component: 'EncryptionFacadeService',
              data: { 
                driveId,
                hasMetadataKey: !!keys.metadataKey,
                hasContentKey: !!keys.contentKey
              }
            });
          }
        });

        // Ensure all unlocked drives have corresponding tiers
        this.ensureUnlockedDrivesHaveTiers();
        
        // Save the cleaned up state after restoration attempt
        if (this.unlockedDrives.size > 0) {
          this.saveStoredState();
        }

        // Clear expired drives on load
        this.cleanupExpiredKeys();
      }
    } catch (error) {
      logger.error('Error loading stored encryption state:', error);
      // Reset state on error
      this.unlockedDrives = new Set();
      this.lastUnlockTime = {};
      this.driveTierMap.clear();
    }
  }

  /**
   * Save unlocked drive state to session storage
   */
  private saveStoredState() {
    try {
      // Save unlocked drives and timestamps
      const driveIds = Array.from(this.unlockedDrives);
      
      // Also save encryption tiers
      const tiers: Record<string, string> = {};
      for (const [driveId, tier] of this.driveTierMap.entries()) {
        tiers[driveId] = tier;
      }
      
      const data = {
        driveIds,
        timestamps: this.lastUnlockTime,
        tiers // Include encryption tiers in saved state
      };

      sessionStorage.setItem(STORAGE_KEYS.UNLOCKED_DRIVES, JSON.stringify(data));
      
      logger.debug('Saved encryption state', {
        component: 'EncryptionFacadeService',
        data: { 
          unlockedCount: this.unlockedDrives.size,
          tiersCount: Object.keys(tiers).length,
          driveIds 
        }
      });
    } catch (error) {
      logger.error('Error saving encryption state:', error);
    }
  }

  /**
   * Remove expired keys (older than 24 hours)
   */
  private cleanupExpiredKeys() {
    const now = Date.now();
    let expiredCount = 0;

    for (const [driveId, timestamp] of Object.entries(this.lastUnlockTime)) {
      if (now - timestamp > KEY_EXPIRATION) {
        // Remove from Set
        this.unlockedDrives.delete(driveId);
        // Remove from timestamps
        delete this.lastUnlockTime[driveId];
        // Remove from tier map
        this.driveTierMap.delete(driveId);
        // Clear from encryption service
        this.encryptionInstance.clearDecryptedKeys(driveId);
        expiredCount++;
      }
    }

    if (expiredCount > 0) {
      logger.debug('Cleared expired encryption keys', {
        component: 'EncryptionFacadeService',
        data: { 
          expiredCount,
          remainingCount: this.unlockedDrives.size,
          remainingTiers: this.driveTierMap.size
        }
      });
      this.saveStoredState();
    }
  }

  /**
   * Generate master key for drive creation
   */
  public async generateMasterKey(password: string): Promise<KeyGenerationResult> {
    try {
      logger.debug('Delegating master key generation', {
        component: 'EncryptionFacadeService',
        data: { passwordLength: password.length }
      });
      return await this.encryptionInstance.generateMasterKey(password);
    } catch (error) {
      logger.error('Error in facade generateMasterKey:', error);
      throw error;
    }
  }

  /**
   * Unlock a drive and store the keys
   * Dispatches a custom event when successful to notify UI components
   */
  public async unlockDrive(password: string, drive: EncryptedDrive): Promise<boolean> {
    try {
      logger.debug('Attempt to unlock drive', {
        component: 'EncryptionFacadeService',
        data: { 
          driveId: drive.id,
          driveName: drive.name,
          encryptionTier: drive.encryption_tier,
          passwordLength: password?.length || 0
        }
      });

      const success = await this.encryptionInstance.unlockDrive(password, drive);
      
      if (success) {
        // Add to unlocked drives set
        this.unlockedDrives.add(drive.id);
        // Update last unlock time
        this.lastUnlockTime[drive.id] = Date.now();
        
        // Store the encryption tier for this drive
        if (drive.encryption_tier) {
          const tier = drive.encryption_tier.toLowerCase();
          this.driveTierMap.set(drive.id, tier);
          logger.debug('Set drive encryption tier', {
            component: 'EncryptionFacadeService',
            data: {
              driveId: drive.id,
              tier: tier
            }
          });
          
          logger.debug('Stored drive encryption tier', {
            component: 'EncryptionFacadeService',
            data: { 
              driveId: drive.id,
              tier: tier
            }
          });
        }
        
        // Save to session storage
        this.saveStoredState();

        // Verify keys were stored properly
        const keys = this.encryptionInstance.getDecryptedKeys(drive.id);
        
        logger.debug('Drive unlock successful', {
          component: 'EncryptionFacadeService',
          data: { 
            driveId: drive.id,
            driveName: drive.name,
            encryptionTier: drive.encryption_tier,
            storedTier: this.driveTierMap.get(drive.id),
            unlockedCount: this.unlockedDrives.size,
            timestamp: this.lastUnlockTime[drive.id],
            hasKeys: !!keys,
            hasMetadataKey: keys?.metadataKey ? true : false,
            hasContentKey: keys?.contentKey ? true : false
          }
        });

        debugLogger.info('Drive unlocked and keys stored', {
          driveId: drive.id,
          encryptionTier: drive.encryption_tier,
          allUnlockedDrives: Array.from(this.unlockedDrives),
          hasStoredKeys: !!keys,
          keysComplete: keys?.metadataKey && keys?.contentKey ? true : false
        });
        
        // IMPORTANT: Dispatch a custom event to notify components of status change
        // This will trigger UI updates in components listening for drive status changes
        dispatchDriveStatusChange(drive.id, 'unlocked');
        
        if (!keys || !keys.metadataKey || !keys.contentKey) {
          logger.error('Critical encryption error: Keys not properly stored after unlock', {
            component: 'EncryptionFacadeService', 
            data: {
              driveId: drive.id,
              driveName: drive.name,
              hasKeys: !!keys,
              hasMetadataKey: keys?.metadataKey ? true : false,
              hasContentKey: keys?.contentKey ? true : false
            }
          });
        }
      } else {
        logger.error('Drive unlock failed - invalid password', {
          component: 'EncryptionFacadeService',
          data: { 
            driveId: drive.id,
            driveName: drive.name
          }
        });
      }

      return success;
    } catch (error) {
      logger.error('Error unlocking drive:', {
        component: 'EncryptionFacadeService',
        error,
        data: {
          driveId: drive.id,
          driveName: drive.name
        }
      });
      return false;
    }
  }

  /**
   * Recover drive access using recovery key
   */
  public async recoverDrive(recoveryKey: string, drive: EncryptedDrive): Promise<boolean> {
    try {
      const success = await this.encryptionInstance.recoverDrive(recoveryKey, drive);
      
      if (success) {
        // Add to unlocked drives set
        this.unlockedDrives.add(drive.id);
        // Update last unlock time
        this.lastUnlockTime[drive.id] = Date.now();
        // Save to session storage
        this.saveStoredState();
        
        logger.debug('Drive recovery successful', {
          component: 'EncryptionFacadeService',
          data: { driveId: drive.id }
        });
      }
      
      return success;
    } catch (error) {
      logger.error('Error recovering drive:', error);
      return false;
    }
  }

  /**
   * Check if a drive is unlocked with enhanced validation
   * This performs both server and client-side validation to ensure drive is truly unlocked
   */
  public isDriveUnlocked(driveId: string): boolean {
    if (!driveId) {
      logger.error('Empty driveId provided to isDriveUnlocked', {
        component: 'EncryptionFacadeService'
      });
      return false;
    }
    
    // First check our tracked set
    const isUnlocked = this.unlockedDrives.has(driveId);
    
    if (isUnlocked) {
      // Verify keys are actually available and complete
      const keys = this.encryptionInstance.getDecryptedKeys(driveId);
      
      // Check that we have both content and metadata keys
      if (!keys || !keys.contentKey || !keys.metadataKey) {
        logger.warn('Drive marked as unlocked but valid keys not found', {
          component: 'EncryptionFacadeService',
          data: { 
            driveId,
            hasKeys: !!keys,
            hasMetadataKey: keys?.metadataKey ? true : false,
            hasContentKey: keys?.contentKey ? true : false
          }
        });
        
        // Remove from unlocked set if keys are missing or incomplete
        this.unlockedDrives.delete(driveId);
        delete this.lastUnlockTime[driveId];
        this.saveStoredState();
        return false;
      }
      
      // Try to validate the keys are actually functional by checking their type
      try {
        const contentKeyValid = keys.contentKey instanceof CryptoKey;
        const metadataKeyValid = keys.metadataKey instanceof CryptoKey;
        
        if (!contentKeyValid || !metadataKeyValid) {
          logger.error('Drive keys are not valid CryptoKey instances', {
            component: 'EncryptionFacadeService',
            data: { 
              driveId,
              contentKeyValid,
              metadataKeyValid
            }
          });
          
          // Remove from unlocked set
          this.unlockedDrives.delete(driveId);
          delete this.lastUnlockTime[driveId];
          this.saveStoredState();
          return false;
        }
      } catch (e) {
        logger.error('Error validating drive keys', {
          component: 'EncryptionFacadeService',
          error: e,
          data: { driveId }
        });
        return false;
      }
      
      // Keys are valid and complete
      return true;
    }
    
    // Not in our unlocked set
    return false;
  }

  /**
   * Get decrypted keys for a drive
   */
  public getDecryptedKeys(driveId: string) {
    // Check if drive is in our unlocked set
    logger.debug('Getting decrypted keys', {
      component: 'EncryptionFacadeService',
      data: { 
        driveId,
        isInUnlockedSet: this.unlockedDrives.has(driveId),
        unlockedDrivesCount: this.unlockedDrives.size,
        unlockedDrives: Array.from(this.unlockedDrives)
      }
    });
    
    // Handle missing driveId
    if (!driveId) {
      logger.error('Empty driveId provided to getDecryptedKeys', {
        component: 'EncryptionFacadeService',
        data: { driveId }
      });
      return undefined;
    }
    
    // Try to get keys from encryption service
    try {
      // First try to get keys directly - they might exist even if not in unlocked set
      const keys = this.encryptionInstance.getDecryptedKeys(driveId);
      
      // If keys exist, ensure drive is in unlocked set and return them
      if (keys && keys.metadataKey && keys.contentKey) {
        // Ensure drive is in unlocked set
        if (!this.unlockedDrives.has(driveId)) {
          logger.warn('Keys exist but drive not in unlocked set - correcting state', {
            component: 'EncryptionFacadeService',
            data: { driveId }
          });
          this.unlockedDrives.add(driveId);
          this.lastUnlockTime[driveId] = Date.now();
          this.saveStoredState();
        } else {
          // Refresh the unlock timestamp
          this.lastUnlockTime[driveId] = Date.now();
        }
        
        logger.debug('Successfully retrieved keys', {
          component: 'EncryptionFacadeService',
          data: { 
            driveId,
            hasMetadataKey: !!keys.metadataKey,
            hasContentKey: !!keys.contentKey
          }
        });
        
        return keys;
      }
      
      // If we get here, keys don't exist or are incomplete
      // Check if drive is in our unlocked set but keys aren't in memory
      if (this.unlockedDrives.has(driveId)) {
        logger.error('Critical encryption error: Drive marked as unlocked but complete keys not found', {
          component: 'EncryptionFacadeService',
          data: { 
            driveId,
            unlockedStatus: this.unlockedDrives.has(driveId),
            lastUnlockTime: this.lastUnlockTime[driveId] ? new Date(this.lastUnlockTime[driveId]).toISOString() : 'never',
            hasPartialKeys: !!keys,
            hasMetadataKey: keys?.metadataKey ? true : false,
            hasContentKey: keys?.contentKey ? true : false
          }
        });
      } else {
        logger.error('Drive is not unlocked - user must unlock the drive before upload', {
          component: 'EncryptionFacadeService',
          data: { 
            driveId,
            unlockedDrives: Array.from(this.unlockedDrives)
          }
        });
      }
      
      return undefined;
    } catch (error) {
      logger.error('Error getting decrypted keys', {
        component: 'EncryptionFacadeService',
        error,
        data: { driveId }
      });
      return undefined;
    }
  }

  /**
   * Clear decrypted keys for a drive
   * Dispatches a custom event when successful to notify UI components
   */
  public clearDecryptedKeys(driveId: string): void {
    const wasUnlocked = this.unlockedDrives.has(driveId);
    
    // Remove from unlocked drives set
    this.unlockedDrives.delete(driveId);
    delete this.lastUnlockTime[driveId];
    this.driveTierMap.delete(driveId);
    this.saveStoredState();
    this.encryptionInstance.clearDecryptedKeys(driveId);
    
    logger.debug('Cleared drive keys', {
      component: 'EncryptionFacadeService',
      data: { 
        driveId,
        wasUnlocked,
        remainingUnlocked: this.unlockedDrives.size,
        remainingTiers: this.driveTierMap.size
      }
    });
    
    // Only dispatch event if the drive was actually unlocked before
    if (wasUnlocked) {
      // IMPORTANT: Dispatch a custom event to notify components of status change
      // This will trigger UI updates in components listening for drive status changes
      dispatchDriveStatusChange(driveId, 'locked');
    }
  }

  /**
   * Clear all decrypted keys
   */
  public clearAllKeys(): void {
    // For each unlocked drive, clear its keys
    for (const driveId of this.unlockedDrives) {
      this.encryptionInstance.clearDecryptedKeys(driveId);
    }
    
    // Clear our tracking
    this.unlockedDrives.clear();
    this.lastUnlockTime = {};
    this.driveTierMap.clear();
    sessionStorage.removeItem(STORAGE_KEYS.UNLOCKED_DRIVES);
    
    logger.debug('Cleared all encryption keys and state');
  }

  /**
   * Get all cached drive IDs for debugging
   */
  public getAllCachedDriveIds(): string[] {
    return Array.from(this.unlockedDrives);
  }
  
  /**
   * Set decrypted keys for a specific drive
   * Used for temporary shared item decryption
   */
  public setDecryptedKeysForDrive(driveId: string, keys: { 
    contentKey: CryptoKey;
    metadataKey: CryptoKey;
    keyVersion: string;
    encryptionTier?: string;
  }): void {
    // Store the keys in the encryption service
    this.encryptionInstance.storeDecryptedKeys(driveId, keys);
    
    // Add to unlocked drives set
    this.unlockedDrives.add(driveId);
    // Update last unlock time
    this.lastUnlockTime[driveId] = Date.now();
    
    // Save the encryption tier for this drive
    if (keys.encryptionTier) {
      this.driveTierMap.set(driveId, keys.encryptionTier);
      logger.debug('Set drive encryption tier', {
        component: 'EncryptionFacadeService',
        data: {
          driveId,
          tier: keys.encryptionTier
        }
      });
    }
    
    // Save to session storage
    this.saveStoredState();
    
    logger.debug('Temporary keys set for shared drive', {
      component: 'EncryptionFacadeService',
      data: { 
        driveId,
        contentKeyType: keys.contentKey.type,
        metadataKeyType: keys.metadataKey.type,
        keyVersion: keys.keyVersion,
        encryptionTier: keys.encryptionTier || 'not provided',
        unlockedDrivesCount: this.unlockedDrives.size
      }
    });
  }
  
  /**
   * Get the encryption tier for a drive
   * @param driveId The drive ID
   * @returns The encryption tier, or undefined if not found
   */
  public getDriveEncryptionTier(driveId: string): EncryptionTier | string | undefined {
    if (!driveId) return undefined;
    
    // First try to get from the tier map
    const tier = this.driveTierMap.get(driveId);
    if (tier) return tier;
    
    // If no tier in the map, try to recover from localStorage
    try {
      const storedTiers = localStorage.getItem('drive_encryption_tiers');
      if (storedTiers) {
        const tierData = JSON.parse(storedTiers);
        if (tierData[driveId]) {
          // Found a tier in localStorage - update our map
          const recoveredTier = tierData[driveId];
          this.driveTierMap.set(driveId, recoveredTier);
          
          logger.debug('Recovered encryption tier from localStorage', {
            component: 'EncryptionFacadeService',
            data: { driveId, recoveredTier }
          });
          
          return recoveredTier;
        }
      }
    } catch (e) {
      logger.error('Error trying to recover tier from localStorage', {
        component: 'EncryptionFacadeService',
        error: e
      });
    }
    
    // Still not found, but drive is unlocked - use default tier
    if (this.unlockedDrives.has(driveId)) {
      logger.warn('Drive is unlocked but no tier found - using standard', {
        component: 'EncryptionFacadeService',
        data: { driveId }
      });
      
      // Set standard as a fallback
      this.driveTierMap.set(driveId, EncryptionTier.STANDARD);
      return EncryptionTier.STANDARD;
    }
    
    return undefined;
  }
  
  /**
   * Ensure all unlocked drives have tiers
   * This synchronizes the unlockedDrives set with the driveTierMap
   */
  private ensureUnlockedDrivesHaveTiers(): void {
    // First check for any unlocked drives without tiers
    for (const driveId of this.unlockedDrives) {
      if (!this.driveTierMap.has(driveId)) {
        // Try to find tier in localStorage
        try {
          const storedTiers = localStorage.getItem('drive_encryption_tiers');
          if (storedTiers) {
            const tierData = JSON.parse(storedTiers);
            if (tierData[driveId]) {
              this.driveTierMap.set(driveId, tierData[driveId]);
              logger.debug('Added missing tier for unlocked drive', {
                component: 'EncryptionFacadeService',
                data: { driveId, tier: tierData[driveId] }
              });
              continue;
            }
          }
        } catch (e) {
          // Ignore localStorage errors
        }
        
        // If still no tier, add default
        logger.warn('Unlocked drive has no tier - adding default', {
          component: 'EncryptionFacadeService',
          data: { driveId }
        });
        this.driveTierMap.set(driveId, EncryptionTier.STANDARD);
      }
    }
  }

  /**
   * Encrypt content
   */
  public async encryptContent(
    content: ArrayBuffer,
    tier: EncryptionTier,
    contentKey: CryptoKey
  ): Promise<{
    encryptedData: string;
    iv: string;
    algorithm: string;
  }> {
    return this.encryptionInstance.encryptContent(content, tier, contentKey);
  }

  /**
   * Encrypt metadata
   */
  public async encryptMetadata(
    metadata: Record<string, any>,
    metadataKey: CryptoKey
  ) {
    return this.encryptionInstance.encryptMetadata(metadata, metadataKey);
  }

  /**
   * Decrypt metadata
   */
  public async decryptMetadata(
    encryptedData: string,
    iv: string,
    metadataKey: CryptoKey
  ) {
    try {
      logger.debug('Delegating metadata decryption', {
        component: 'EncryptionFacadeService',
        data: { 
          encryptedDataLength: encryptedData.length,
          ivLength: iv.length 
        }
      });
      
      return await this.encryptionInstance.decryptMetadata(
        encryptedData,
        iv,
        metadataKey
      );
    } catch (error) {
      logger.error('Error in facade decryptMetadata:', {
        component: 'EncryptionFacadeService',
        error
      });
      throw error;
    }
  }


/**
 * Decrypt content
 */
public async decryptContent(
  encryptedData: ArrayBuffer,
  iv: string,
  contentKey: CryptoKey,
  forThumbnail: boolean = false,
  algorithm?: string,
  driveId?: string
): Promise<ArrayBuffer> {
  try {
    // Get the drive's encryption tier if not provided but driveId is available
    if (!algorithm && driveId && typeof this.encryptionInstance.getDriveEncryptionTier === 'function') {
      const driveTier = this.encryptionInstance.getDriveEncryptionTier(driveId);
      if (driveTier) {
        algorithm = driveTier;
        logger.debug('Using drive encryption tier for decryption', {
          component: 'EncryptionFacadeService',
          data: { 
            driveId,
            detectedTier: driveTier
          }
        });
      }
    }
    
    logger.debug('Delegating content decryption', {
      component: 'EncryptionFacadeService',
      data: { 
        encryptedDataSize: encryptedData.byteLength,
        ivLength: iv.length,
        forThumbnail,
        algorithm: algorithm || 'default',
        driveId: driveId || 'not-provided'
      }
    });
    
    // Check if the underlying instance has the method
    if (!this.encryptionInstance.decryptContent) {
      logger.error('Underlying encryption service missing decryptContent method', {
        component: 'EncryptionFacadeService'
      });
      throw new Error('decryptContent method not available in encryption service');
    }
    
    return await this.encryptionInstance.decryptContent(
      encryptedData,
      iv,
      contentKey,
      forThumbnail,
      algorithm
    );
  } catch (error) {
    logger.error('Error in facade decryptContent:', {
      component: 'EncryptionFacadeService',
      error,
      data: {
        dataSize: encryptedData?.byteLength,
        ivSample: iv ? iv.substring(0, 10) + '...' : 'undefined',
        forThumbnail,
        algorithm,
        driveId: driveId || 'not-provided'
      }
    });
    throw error;
  }
}


/**
 * Decrypt a thumbnail with special handling for different IV formats
 * This is a new dedicated method for thumbnail decryption to fix IV handling issues
 */
/**
 * Set current active drive ID - this helps ensure the right encryption tier is used
 * @param driveId The active encrypted drive ID
 */
public setActiveDrive(driveId: string): Promise<boolean> {
  return new Promise((resolve) => {
    if (!driveId) {
      logger.warn('Invalid driveId passed to setActiveDrive', {
        component: 'EncryptionFacadeService',
        data: { driveId }
      });
      return resolve(false);
    }
    
    // Get the encryption tier for this drive
    const tier = this.driveTierMap.get(driveId);
    if (tier) {
      this.currentEncryptionTier = tier;
      
      // Make sure the drive is in unlockedDrives set for consistency
      if (!this.unlockedDrives.has(driveId)) {
        const keys = this.encryptionInstance.getDecryptedKeys(driveId);
        if (keys && keys.contentKey && keys.metadataKey) {
          // Drive has valid keys but wasn't in unlocked set - add it now
          this.unlockedDrives.add(driveId);
          this.lastUnlockTime[driveId] = Date.now();
          this.saveStoredState();
          
          logger.debug('Added drive to unlocked set in setActiveDrive', {
            component: 'EncryptionFacadeService',
            data: { driveId, tier }
          });
        }
      }
      
      // Debug console message for easier troubleshooting
      console.debug(`[EncryptionService] Active drive set to ${driveId} with tier ${tier}`);
      
      logger.debug('Set active drive and current encryption tier', {
        component: 'EncryptionFacadeService',
        data: {
          driveId,
          tier,
          allDriveTiers: Object.fromEntries(this.driveTierMap.entries()),
          unlockedDrives: Array.from(this.unlockedDrives)
        }
      });
      
      // Wait briefly to ensure the tier is set before any operations use it
      setTimeout(() => resolve(true), 10);
    } else {
      logger.warn('No encryption tier found for drive', {
        component: 'EncryptionFacadeService',
        data: {
          driveId,
          knownDrives: Array.from(this.driveTierMap.keys()),
          currentTier: this.currentEncryptionTier
        }
      });
      
      // Try to recover the tier from localStorage as a last resort
      try {
        const storedTiers = localStorage.getItem('drive_encryption_tiers');
        if (storedTiers) {
          const tierData = JSON.parse(storedTiers);
          if (tierData[driveId]) {
            const recoveredTier = tierData[driveId];
            // Set the tier in the map
            this.driveTierMap.set(driveId, recoveredTier);
            this.currentEncryptionTier = recoveredTier;
            
            logger.info('Recovered tier from localStorage', {
              component: 'EncryptionFacadeService',
              data: { driveId, recoveredTier }
            });
            
            // Wait briefly to ensure the tier is set
            setTimeout(() => resolve(true), 10);
            return;
          }
        }
      } catch (e) {
        logger.error('Error trying to recover tier from localStorage', {
          component: 'EncryptionFacadeService',
          error: e
        });
      }
      
      resolve(false);
    }
  });
}

async decryptThumbnail(
  encryptedContent: any,
  contentKey: CryptoKey,
  driveId?: string
): Promise<ArrayBuffer> {
  try {
    // Get tier from drive mapping if available
    const driveTier = driveId ? this.driveTierMap.get(driveId) : undefined;
    
    // If driveId is provided, update the current encryption tier
    if (driveId && driveTier) {
      this.currentEncryptionTier = driveTier;
    }
    
    debugLogger.info('Decrypting thumbnail with specialized method', {
      component: 'EncryptionFacadeService',
      data: {
        encryptedDataSize: encryptedContent.encryptedData?.byteLength || 0,
        ivPresent: !!encryptedContent.iv,
        ivLength: encryptedContent.iv?.length || 0,
        ivSample: encryptedContent.iv ? encryptedContent.iv.substring(0, 10) + '...' : null,
        forThumbnail: true,
        driveId: driveId || 'not-provided',
        driveTier: driveTier || 'not-found',
        algorithm: driveTier || this.currentEncryptionTier || 'default'
      }
    });

    // Ensure encryptedContent is an ArrayBuffer
    let dataToDecrypt: ArrayBuffer;
    if (encryptedContent.encryptedData instanceof ArrayBuffer) {
      dataToDecrypt = encryptedContent.encryptedData;
    } else {
      debugLogger.error('Invalid encrypted data format, expected ArrayBuffer', {
        component: 'EncryptionFacadeService',
        data: {
          encryptedDataType: typeof encryptedContent.encryptedData,
          isArrayBuffer: encryptedContent.encryptedData instanceof ArrayBuffer
        }
      });
      throw new Error('Invalid encrypted data format');
    }

    // Validate and process the IV
    if (!encryptedContent.iv || typeof encryptedContent.iv !== 'string') {
      debugLogger.error('Missing or invalid IV', {
        component: 'EncryptionFacadeService',
        data: {
          ivType: typeof encryptedContent.iv
        }
      });
      throw new Error('Invalid IV format');
    }

    // Get the expected IV length based on encryption algorithm
    let expectedIvLength = 12; // Default for AES-GCM
    
    // Adjust length based on encryption tier
    if (this.currentEncryptionTier === 'performance') {
      // ChaCha20-Poly1305
      expectedIvLength = 24;
    }

    // Clean the padded IV to get the correct format
    const cleanedIv = this.cleanPaddedIv(encryptedContent.iv, expectedIvLength);

    debugLogger.info('Using cleaned IV for decryption', {
      component: 'EncryptionFacadeService',
      data: {
        originalIvLength: encryptedContent.iv.length,
        cleanedIvLength: cleanedIv.length,
        expectedIvLength
      }
    });

    // Format the object for the appropriate encryption service
    const formattedContent = {
      encryptedData: this.arrayBufferToBase64(dataToDecrypt),
      iv: cleanedIv,
      algorithm: encryptedContent.algorithm || 'AES-GCM',
      chunked: false
    };

    // Get the appropriate encryption service
    const encryptionService = this.getEncryptionService();
    
    // Call the decrypt method on the encryption service
    // Add required parameters for proper decryptContent call
    return await encryptionService.decryptContent(
      formattedContent, 
      formattedContent.iv,
      contentKey
    );
  } catch (error) {
    debugLogger.error('Error in thumbnail decryption', {
      component: 'EncryptionFacadeService',
      error,
      data: {
        encryptedDataSize: encryptedContent.encryptedData?.byteLength || 0,
        ivLength: encryptedContent.iv?.length || 0
      }
    });
    throw error;
  }
}


/**
 * Clean IV by removing null byte padding
 * @param paddedIv The padded IV string from the server
 * @param expectedLength The expected IV length (default is 12 for AES-GCM)
 * @returns Cleaned IV string without null byte padding
 */
private cleanPaddedIv(paddedIv: string, expectedLength: number = 12): string {
  try {
    if (!paddedIv) {
      throw new Error('IV is undefined or empty');
    }

    debugLogger.info('Cleaning padded IV', {
      component: 'EncryptionFacadeService',
      data: {
        originalIv: paddedIv.substring(0, 10) + '...',
        originalLength: paddedIv.length
      }
    });

    // If already correct length, return as is
    if (paddedIv.length === expectedLength) {
      return paddedIv;
    }

    // First, decode the base64 IV to get binary data
    let binaryData: Uint8Array;
    try {
      // First try with btoa/atob
      const binary = atob(paddedIv);
      binaryData = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        binaryData[i] = binary.charCodeAt(i);
      }
    } catch (error) {
      debugLogger.error('Failed to decode IV with atob', {
        component: 'EncryptionFacadeService',
        error
      });

      // Fallback approach - handle possible non-standard encoding
      // This is more lenient with malformed base64
      let rawData = paddedIv.replace(/\s/g, ''); // Remove whitespace
      // Add padding if needed
      while (rawData.length % 4 !== 0) {
        rawData += '=';
      }
      
      try {
        const binary = atob(rawData);
        binaryData = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
          binaryData[i] = binary.charCodeAt(i);
        }
      } catch (e) {
        debugLogger.error('Failed to decode IV even with fallback', {
          component: 'EncryptionFacadeService',
          error: e
        });
        throw new Error('Cannot decode IV: ' + e);
      }
    }

    // Find where the null bytes start
    let actualLength = binaryData.length;
    for (let i = 0; i < binaryData.length; i++) {
      if (binaryData[i] === 0) {
        // Found first null byte
        actualLength = i;
        break;
      }
    }

    // Use actual data or truncate to expected length
    const cleanLength = Math.min(actualLength || expectedLength, expectedLength);
    const cleanedData = binaryData.slice(0, cleanLength);

    // If we don't have enough data, pad with zeros
    if (cleanedData.length < expectedLength) {
      const paddedData = new Uint8Array(expectedLength);
      paddedData.set(cleanedData);
      // Rest will be zeros by default
      
      debugLogger.info('Padded short IV to expected length', {
        component: 'EncryptionFacadeService',
        data: {
          originalLength: cleanedData.length,
          paddedLength: expectedLength
        }
      });
      
      // Convert back to base64
      return btoa(String.fromCharCode.apply(null, Array.from(paddedData)));
    }

    // Convert back to base64
    const resultIv = btoa(String.fromCharCode.apply(null, Array.from(cleanedData)));

    debugLogger.info('Cleaned padded IV', {
      component: 'EncryptionFacadeService',
      data: {
        originalLength: paddedIv.length,
        cleanedLength: resultIv.length,
        binaryLength: binaryData.length,
        actualDataLength: actualLength,
        usedLength: cleanLength
      }
    });

    return resultIv;
  } catch (error) {
    debugLogger.error('Error cleaning padded IV', {
      component: 'EncryptionFacadeService',
      error,
      data: {
        ivSample: paddedIv?.substring(0, 10) + '...',
        ivLength: paddedIv?.length
      }
    });

    // In case of any error, return original to avoid breaking things further
    return paddedIv;
  }
}


  /**
   * Get decrypted metadata from cache if available
   */
  public getCachedMetadata(driveId: string, itemId: string) {
    if (typeof (this.encryptionInstance as any).getCachedMetadata !== 'function') {
      logger.warn('getCachedMetadata method not available in encryption service');
      return undefined;
    }
    return (this.encryptionInstance as any).getCachedMetadata(driveId, itemId);
  }


}

// Custom event for drive status changes
export const DRIVE_STATUS_CHANGED_EVENT = 'drive-status-changed';

// Custom event for drive unlock
export const DRIVE_UNLOCKED_EVENT = 'drive-unlocked';

// Custom event for drive lock
export const DRIVE_LOCKED_EVENT = 'drive-locked';

// Method to dispatch drive status change events
export function dispatchDriveStatusChange(driveId: string, status: 'locked' | 'unlocked') {
  if (typeof window !== 'undefined') {
    // Create and dispatch a custom event
    const event = new CustomEvent(DRIVE_STATUS_CHANGED_EVENT, { 
      detail: { driveId, status, timestamp: Date.now() } 
    });
    window.dispatchEvent(event);
    
    // Also dispatch specific event based on status
    const specificEvent = new CustomEvent(
      status === 'unlocked' ? DRIVE_UNLOCKED_EVENT : DRIVE_LOCKED_EVENT, 
      { detail: { driveId, timestamp: Date.now() } }
    );
    window.dispatchEvent(specificEvent);
    
    console.debug(`[EncryptionService] Dispatched drive status change event: ${status}`, { driveId });
  }
}

// Export a singleton instance
export const encryptionService = new EncryptionFacadeService();

// Make encryption service available globally for components that need direct access
// This is particularly needed for the CoreListItem status indicator
if (typeof window !== 'undefined') {
  (window as any).encryptionService = encryptionService;
}