/**
 * @file src/services/encrypted-drive/encrypted-drive.service.ts
 * @description API service for encrypted drive operations with fallback to cloud drive
 * @version 1.3.1
 * @updated 2025-03-21
 */

import { authService } from '../auth.service';
import { isValidUUID } from '@/utils/validation';
import { logger } from '@/utils/logger';
import { debugLogger } from '@/utils/debug-logger';
import { getAuthHeaders } from '@/utils/api-utils';
import type { 
  CreateDriveRequest,
  EncryptedDrive,
  UnlockDriveRequest,
  EncryptionTier,
  UnlockDriveRequest as LockDriveRequest
} from '@/types/encrypted-drive.types';
import type { DriveItemsResponse, DriveItem } from '@/types/cloud-drive.types';

const BASE_URL = '/api/v1/encrypted-drive';

// Using centralized getAuthHeaders utility from api-utils.ts

export const encryptedDriveApi  = {
  /**
   * List all encrypted drives for the current user
   */
  listDrives: async (): Promise<EncryptedDrive[]> => {
    try {
      // Check if we're on an auth page - return empty list instead of making API call
      const path = window.location.pathname;
      const isAuthPage = path.includes('/auth/') || path.includes('/ghost/auth/');
      
      if (isAuthPage) {
        logger.debug('Auth page detected, skipping encrypted drives API call', {
          component: 'encryptedDriveApi.listDrives',
          path
        });
        return [];
      }
      
      const headers = getAuthHeaders();
      logger.debug('Fetching encrypted drives list');

      const response = await fetch(`${BASE_URL}/encrypted-drives`, {
        method: 'GET',
        headers: headers as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('List drives API error:', errorText);
        throw new Error(errorText);
      }

      const data = await response.json();
      logger.debug('Encrypted drives fetched successfully', {
        driveCount: data.length
      });
      return data;
    } catch (error) {
      logger.error('Error fetching encrypted drives:', error);
      throw error;
    }
  },
  
  /**
   * Update folder statistics after upload
   * Triggers a folder count update to ensure UI reflects new items
   */
  updateFolderStats: async (driveId: string, folderId: string): Promise<void> => {
    try {
      const headers = getAuthHeaders();
      logger.debug('Updating folder statistics after upload', { 
        driveId, 
        folderId 
      });

      const response = await fetch(`${BASE_URL}/folders/${folderId}/stats`, {
        method: 'POST',
        headers: headers as HeadersInit,
        credentials: 'include',
        body: JSON.stringify({ 
          drive_id: driveId,
          folder_id: folderId,
          update_type: 'file_added'
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.warn('Update folder stats warning (non-critical):', errorText);
        // Don't throw - this is a non-critical operation
      } else {
        logger.debug('Folder statistics updated successfully');
      }
    } catch (error) {
      // Log but don't throw for this non-critical operation
      logger.warn('Non-critical error updating folder stats:', {
        component: 'encryptedDriveApi.updateFolderStats',
        error,
        data: { driveId, folderId }
      });
    }
  },

  /**
   * Create a new encrypted drive
   */
  createDrive: async (request: CreateDriveRequest): Promise<EncryptedDrive> => {
    try {
        const headers = getAuthHeaders();
        logger.debug('Creating drive with recovery data', {
          name: request.name,
          device_id: request.device_id,
          encryption_tier: request.encryption_tier,
          hasRecoveryData: !!request.recovery_data,
          recoveryDataStructure: request.recovery_data ? {
              verificationHashPresent: !!request.recovery_data.verification_hash,
              encryptedKeysPresent: !!request.recovery_data.encrypted_keys,
              recoveryKeyPresent: !!request.recovery_data.key
          } : null
      });

        const body = {
            name: request.name,
            device_id: request.device_id,
            encryption_tier: request.encryption_tier,
            encrypted_content_key: request.encrypted_content_key,
            encrypted_metadata_key: request.encrypted_metadata_key,
            encrypted_keys_iv: request.encrypted_keys_iv,
            key_salt: request.key_salt,
            key_verification_hash: request.key_verification_hash,
            recovery_verification_hash: request.recovery_verification_hash,
            recovery_data: request.recovery_data ? {
                key: request.recovery_data.key,
                verification_hash: request.recovery_data.verification_hash,
                encrypted_keys: {
                    content_key: request.recovery_data.encrypted_keys.content_key,
                    metadata_key: request.recovery_data.encrypted_keys.metadata_key,
                    iv: request.recovery_data.encrypted_keys.iv
                }
            } : undefined
        };

        logger.debug('Create drive request body:', { 
          hasRecoveryData: !!request.recovery_data,
          bodyKeys: Object.keys(body)
      });

        const response = await fetch(`${BASE_URL}/encrypted-drives`, {
            method: 'POST',
            headers: headers as HeadersInit,
            credentials: 'include',
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            const errorText = await response.text();
            logger.error('Create drive API error:', {
                status: response.status,
                statusText: response.statusText,
                error: errorText
            });
            throw new Error(errorText);
        }

        const data = await response.json();
        logger.debug('Drive created successfully:', data);
        return data;
    } catch (error) {
        logger.error('Error creating encrypted drive:', error);
        throw error;
    }
  },
  
  /**
   * Unlock an encrypted drive with optional password
   * Supports both normal password-based unlock and duress password
   */
  unlockDrive: async (driveId: string, password?: string): Promise<void> => {
    try {
        const headers = getAuthHeaders();
        logger.debug('Updating drive unlock status', { 
          driveId, 
          hasPassword: !!password 
        });

        // Prepare request body if password provided
        const requestBody = password ? 
          JSON.stringify({ password }) : 
          undefined;

        const response = await fetch(`${BASE_URL}/encrypted-drives/${driveId}/unlock`, {
            method: 'POST',
            headers: {
              ...headers as HeadersInit,
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: requestBody
        });

        if (!response.ok) {
            const errorText = await response.text();
            logger.error('Unlock status update error:', errorText);
            throw new Error(errorText);
        }
        
        // Drive unlocked successfully
        logger.debug('Drive unlock status updated successfully', { driveId });
    } catch (error) {
        logger.error('Error updating drive status:', error);
        throw error;
    }
  },

  /**
   * Lock an encrypted drive
   */
  lockDrive: async (driveId: string, force: boolean = false): Promise<void> => {
    try {
      const headers = getAuthHeaders();
      logger.debug('Locking encrypted drive', { driveId, force });

      const response = await fetch(`${BASE_URL}/encrypted-drives/${driveId}/lock`, {
        method: 'POST',
        headers: headers as HeadersInit,
        credentials: 'include',
        body: JSON.stringify({ force })
      });

      if (!response.ok) {
        // Try to parse error as JSON first
        let errorDetail = 'Failed to lock drive';
        let errorResponse = null;
        
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          try {
            errorResponse = await response.json();
            errorDetail = errorResponse.detail || errorResponse.message || errorDetail;
          } catch (parseError) {
            // Fallback to text if JSON parsing fails
            errorDetail = await response.text() || errorDetail;
          }
        } else {
          // Not JSON, get as text
          errorDetail = await response.text() || errorDetail;
        }
        
        logger.error('Lock drive API error:', {
          status: response.status,
          contentType,
          errorDetail,
          errorResponse
        });
        
        throw new Error(errorDetail);
      }
      
      logger.debug('Drive locked successfully', { driveId });
    } catch (error) {
      logger.error('Error locking drive:', { 
        driveId,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw error;
    }
  },

  /**
   * List folder contents from an encrypted drive
   */
  listFolderContents: async (options: {
    driveId: string;
    folderId: string | null;
    cursor?: string;
    pageSize?: number;
  }): Promise<DriveItemsResponse> => {
    try {
      const { driveId, folderId, cursor, pageSize } = options;
      const headers = getAuthHeaders();
      
      debugLogger.info('Fetching encrypted folder contents', {
        driveId,
        folderId,
        cursor,
        pageSize
      });

      // Try to call the encrypted files endpoint
      try {
        const endpoint = `${BASE_URL}/files/list`;
        
        // FIXED: Ensure drive_id is included in the request body
        // Validate cursor format if provided to prevent API errors
        let validatedCursor = cursor;
        if (validatedCursor && typeof validatedCursor === 'string' && (validatedCursor === 'undefined' || validatedCursor === 'dW5kZWZpbmVk')) {
          logger.warn('Invalid cursor value detected, omitting from request', {
            component: 'encryptedDriveApi.listFolderContents',
            data: { cursor: validatedCursor }
          });
          validatedCursor = undefined;
        }
        
        const body = JSON.stringify({
          drive_id: driveId,
          folder_id: folderId,
          cursor: validatedCursor && isValidUUID(validatedCursor) ? validatedCursor : undefined,
          page_size: pageSize || 50
        });
        
        logger.debug('Making folder list request', {
          component: 'encryptedDriveApi.listFolderContents',
          data: { 
            endpoint,
            driveId,
            folderId,
            bodyLength: body.length
          }
        });

        const response = await fetch(endpoint, {
          method: 'POST',
          headers: headers as HeadersInit,
          credentials: 'include',
          body
        });

        if (!response.ok) {
          // If endpoint doesn't exist (404), log it but don't throw yet
          if (response?.status === 404) {
            logger.warn('Encrypted files list endpoint not implemented yet');
            // Continue to fallback
          } else {
            const errorText = await response.text();
            logger.error('List encrypted folder contents error:', errorText);
            throw new Error(errorText);
          }
        } else {
          // Success case - API exists and returned successfully
          const data = await response.json();
          
          debugLogger.info('Encrypted folder contents received', {
            itemCount: data.items?.length,
            hasMore: data.hasMore,
            nextCursor: data.nextCursor
          });

          return data;
        }
      } catch (error) {
        // Log but don't rethrow, continue to fallback
        logger.error('Error calling encrypted files endpoint:', error);
      }

      // Fallback: Return empty response (temporary until endpoint is implemented)
      logger.info('Using empty response fallback for encrypted drive', {
        reason: 'API endpoint not fully implemented',
        driveId
      });
      
      return {
        driveId: options.driveId,
        items: [],
        total: 0,
        hasMore: false,
        nextCursor: undefined
      };
    } catch (error) {
      logger.error('Error listing encrypted folder contents:', error);
      
      // Always return empty response on error to avoid crashes
      return {
        driveId: options.driveId,
        items: [],
        total: 0,
        hasMore: false,
        nextCursor: undefined
      };
    }
  },

  /**
   * Get details for a single encrypted file or folder
   * Enhanced with UUID validation to prevent invalid API calls
   */
  getFileDetails: async (fileId: string, driveId?: string): Promise<any> => {
    try {
      // Validate UUID format before making API call
      if (!isValidUUID(fileId)) {
        logger.error('Invalid UUID format for file ID', {
          component: 'encryptedDriveApi.getFileDetails',
          data: { fileId }
        });
        throw new Error(`Invalid file ID format: ${fileId}`);
      }
      
      const headers = getAuthHeaders();
      
      logger.debug('Fetching encrypted file details', { fileId });

      try {
        // Try to call the actual endpoint
        const response = await fetch(`${BASE_URL}/files/${fileId}`, {
          method: 'GET',
          headers: headers as HeadersInit,
          credentials: 'include'
        });

        if (!response.ok) {
          // If endpoint doesn't exist yet, log but don't throw
          if (response?.status === 404) {
            logger.warn('Encrypted file details endpoint not implemented yet');
          } else {
            const errorText = await response.text();
            logger.error('Get encrypted file details error:', errorText);
            throw new Error(errorText);
          }
        } else {
          // Success case
          const data = await response.json();
          return data;
        }
      } catch (error) {
        // Log but continue to fallback
        logger.error('Error fetching encrypted file details:', error);
      }
      
      // Return a mock structure that mimics what we expect from the real API
      return {
        id: fileId,
        type: 'file',
        parent_id: null,
        encrypted_metadata: '',  // These would normally be encrypted strings
        metadata_iv: '',
        content_iv: ''
      };
    } catch (error) {
      logger.error('Error getting encrypted file details:', error);
      
      // Create a more informative response with error details
      return {
        id: fileId,
        type: 'file',
        name: 'Decryption Failed',
        parent_id: null,
        encrypted_metadata: '',
        metadata_iv: '',
        content_iv: '',
        _error: error instanceof Error ? error.message : 'Unknown error'
      };
    }
  }
};