/**
 * @file src/components/cloud-drive/layout/toolbar/MainToolbar.tsx
 * @description Enhanced main toolbar with view switcher
 * @version 4.0.0
 */

import React, { useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {Shield, ArrowLeft} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ActionButtons } from './components/ActionButtons';
import { SearchBar } from './components/SearchBar';
import { ViewControls } from './components/ViewControls';
import { ViewSwitcher } from './components/ViewSwitcher';
import { BatchOperationsBar } from '../../batch/BatchOperationsBar';
import { SelectionControls } from '../../selection/SelectionControls';
import { useCloudDrive } from '@/context/cloud-drive';
import { useSelection } from '@/context/SelectionContext';
import { useUploadQueue } from '@/hooks/upload/useUploadQueue';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useNavigate } from 'react-router-dom';
import { ViewMode } from '@/types/cloud-drive.types';
import { SortConfig, SortField, SortOrder, DriveItemType } from '@/context/cloud-drive/types';
import { useTranslation } from 'react-i18next';

// Define missing types
interface FilterConfig {
  search?: string;
  types?: DriveItemType[];
  dateRange?: { start: string; end: string } | any;
  shared?: boolean;
  favorite?: boolean;
  [key: string]: any;
}

interface ActionButtonsProps {
  onUpload: () => void;
  onNewFolder: () => void;
  className?: string;
  isEncryptedMode: boolean;
}

interface SearchBarProps {
  filterConfig: FilterConfig;
  onSearch: (value: string) => void;
  onFilterChange: (updates: Partial<FilterConfig>) => void;
  onClearFilters: () => void;
  className?: string;
  isEncryptedMode: boolean;
}

interface ViewControlsProps {
  sortConfig: SortConfig;
  onSortChange: (field: SortField, order: SortOrder) => void;
  viewMode: ViewMode;
  onViewModeChange: (mode: ViewMode) => void;
  isInfoPanelOpen: boolean;
  onToggleInfoPanel: () => void;
  className?: string;
  isEncryptedMode: boolean;
}

interface SelectionControlsProps {
  currentPageIds: string[];
  className?: string;
}

interface MainToolbarProps {
  className?: string;
}

export const MainToolbar = React.forwardRef<HTMLDivElement, MainToolbarProps>(({
  className,
}, ref) => {
  const uploadQueue = useUploadQueue();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Context hooks
  const { 
    viewMode,
    setViewMode,
    openFolderDialog,
    filterConfig,
    setFilterConfig,
    sortConfig,
    setSortConfig,
    isInfoPanelOpen,
    setIsInfoPanelOpen,
    currentFolderContents,
    encryptedDrive,
    clearEncryptedDriveState 
  } = useCloudDrive();

  const { 
    selectedItems,
    isSelectMode,
  } = useSelection();

  // Get current page IDs for selection
  const currentPageIds = React.useMemo(() => {
    return currentFolderContents?.map((item: any) => item.id) || [];
  }, [currentFolderContents]);

  // Handlers
  const handleSearch = useCallback((value: string) => {
    logger.debug('Search value changed', {
      component: 'MainToolbar',
      value
    });
    setFilterConfig({
      ...filterConfig,
      search: value || undefined
    });
  }, [setFilterConfig, filterConfig]);

  const handleUpload = useCallback(() => {
    logger.debug('Opening upload queue', {
      component: 'MainToolbar',
      data: {
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        encryptionTier: encryptedDrive.encryptionTier
      }
    });
    uploadQueue.showQueue();
  }, [uploadQueue, encryptedDrive]);

  // Handle back navigation from encrypted drive
  const handleBackToEncryptedDrives = useCallback(() => {
    logger.debug('Navigating back to encrypted drives list', {
      component: 'MainToolbar',
      action: 'handleBackToEncryptedDrives'
    });

    // Clear encrypted drive state
    clearEncryptedDriveState();
    
    // Navigate directly to encrypted drives list
    navigate('/data/encrypted-drives', { replace: true });
  }, [navigate, clearEncryptedDriveState]);

  const handleFilterChange = (updates: Partial<FilterConfig>) => {
    setFilterConfig({
      ...filterConfig,
      ...updates
    });
  };

  return (
    <div className="flex flex-col">
      {/* Encrypted Drive Banner - Shown when in encrypted mode */}
      {encryptedDrive.isEncryptedMode && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className={cn(
            "w-full px-4 py-2",
            "bg-blue-50 dark:bg-blue-900/20",
            "border-b border-blue-100 dark:border-blue-800"
          )}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="p-1.5 rounded-lg bg-blue-100 dark:bg-blue-800">
                <Shield className="h-4 w-4 text-blue-600 dark:text-blue-400" />
              </div>
              <div>
                <p className="text-sm font-medium text-blue-700 dark:text-blue-300">
                  {t("encrypted_drive.mode_indicator_with_name", {
                    name:
                      encryptedDrive.driveName ||
                      t("encrypted_drive.unnamed_drive"),
                  })}
                </p>
                <p className="text-xs text-blue-600/80 dark:text-blue-400/80">
                  {t("encrypted_drive.encryption_type", {
                    type: encryptedDrive.encryptionTier,
                  })}
                </p>
              </div>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleBackToEncryptedDrives}
              className="text-blue-600 dark:text-blue-400"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              {t("common.back")}
            </Button>
          </div>
        </motion.div>
      )}

      {/* Main Toolbar with View Switcher */}
      <div className={cn(
        "flex items-center h-[64px] px-4 gap-2",
        "bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm",
        "border-b border-gray-200 dark:border-gray-800",
        encryptedDrive.isEncryptedMode && "bg-blue-50/50 dark:bg-blue-950/30",
        className
      )} ref={ref}>
        {/* Left - Action Buttons or Selection Controls */}
        {isSelectMode ? (
          <SelectionControls 
            currentPageIds={currentPageIds as string[]}
          />
        ) : (
          <ActionButtons
            onUpload={handleUpload}
            onNewFolder={openFolderDialog}
            className="flex-none"
            isEncryptedMode={encryptedDrive.isEncryptedMode}
          />
        )}

        {/* Center - View Switcher */}
        {!isSelectMode && (
          <div className="flex-1 flex justify-center">
            <ViewSwitcher />
          </div>
        )}

        {/* Right - Search and View Controls */}
        <div className="flex-1 flex justify-end items-center gap-2">
          {!isSelectMode && (
            <SearchBar
              filterConfig={filterConfig}
              onSearch={handleSearch}
              onFilterChange={handleFilterChange}
              onClearFilters={() => 
                setFilterConfig({ search: filterConfig.search })
              }
              className="max-w-md"
              isEncryptedMode={encryptedDrive.isEncryptedMode}
            />
          )}

          {!isSelectMode && (
            <ViewControls
              sortConfig={sortConfig}
              onSortChange={(field, order) => setSortConfig({ field, order })}
              viewMode={viewMode}
              onViewModeChange={setViewMode}
              isInfoPanelOpen={isInfoPanelOpen}
              onToggleInfoPanel={() => setIsInfoPanelOpen(!isInfoPanelOpen)}
              className="flex-none"
            />
          )}
        </div>
      </div>

      {/* Batch Operations Bar */}
      <AnimatePresence>
        {selectedItems.length > 0 && (
          <BatchOperationsBar
            className={cn(
              "border-b border-gray-200 dark:border-gray-800",
              "z-20"
            )}
          />
        )}
      </AnimatePresence>
    </div>
  );
});

MainToolbar.displayName = "MainToolbar";

export default MainToolbar;