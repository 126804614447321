/**
 * @file src/app/ghost/components/dialogs/SimpleFileDetailsDialog.tsx
 * @description Simple dialog to display file details
 * @version 1.0.0
 * @created 2025-04-04
 */

import React from 'react';
import { File, Calendar, Tag, HardDrive } from 'lucide-react';
import { motion } from 'framer-motion';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { GhostDialog } from '@/app/ghost/components/common/GhostDialog';
import { formatFileSize, formatDate } from '@/utils/formatters';
import fileUtils from '@/utils/file-utils';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

export interface FileDetails {
  id: string;
  name: string;
  type: string;
  size?: number;
  created?: string;
  modified?: string;
  mimeType?: string;
}

interface SimpleFileDetailsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  fileDetails: FileDetails | null;
}

export const SimpleFileDetailsDialog: React.FC<SimpleFileDetailsDialogProps> = ({
  open,
  onOpenChange,
  fileDetails
}) => {
  const { t } = useGhostTranslation();

  // Log component rendering in development
  if (process.env.NODE_ENV === 'development') {
    logger.debug('Rendering SimpleFileDetailsDialog', {
      component: 'SimpleFileDetailsDialog',
      fileId: fileDetails?.id,
      fileName: fileDetails?.name
    });
  }

  // Handle case where no file details are provided
  if (!fileDetails) {
    return (
      <GhostDialog
        open={open}
        onOpenChange={onOpenChange}
        className="max-w-md w-full mx-auto"
      >
        <div className="text-center p-4">
          <p className="text-[hsl(var(--ghost-foreground-muted))]">
            {t('common.no_file_selected', 'No file selected')}
          </p>
        </div>
      </GhostDialog>
    );
  }

  // Determine file extension and icon
  const fileExtension = fileDetails.name.split('.').pop()?.toLowerCase() || '';
  const FileIcon = fileUtils.getFileIcon(fileDetails.type, fileExtension);

  return (
    <GhostDialog
      open={open}
      onOpenChange={onOpenChange}
      className="max-w-md w-full mx-auto"
    >
      <div className="flex flex-col space-y-4">
        {/* File Header */}
        <div className="flex items-center space-x-4">
          <div className="rounded-lg bg-[hsl(var(--ghost-surface))]/50 p-3">
            <FileIcon className="h-8 w-8 text-[hsl(var(--ghost-accent))]" />
          </div>
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-medium text-[hsl(var(--ghost-foreground))] truncate">
              {fileDetails.name}
            </h3>
            <p className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
              {fileExtension.toUpperCase()} {t('common.file')}
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="h-px bg-[hsl(var(--ghost-foreground-muted))]/10" />

        {/* File Properties Grid */}
        <div className="grid grid-cols-2 gap-4">
          {/* Type */}
          <div className="flex items-center space-x-2">
            <Tag className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
            <div>
              <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                {t('file_details.type')}
              </p>
              <p className="text-sm text-[hsl(var(--ghost-foreground))]">
                {fileDetails.mimeType || (fileExtension ? `${fileExtension.toUpperCase()} File` : 'Unknown')}
              </p>
            </div>
          </div>

          {/* Size */}
          <div className="flex items-center space-x-2">
            <HardDrive className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
            <div>
              <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                {t('file_details.size')}
              </p>
              <p className="text-sm text-[hsl(var(--ghost-foreground))]">
                {typeof fileDetails.size === 'number' 
                  ? formatFileSize(fileDetails.size) 
                  : t('common.unknown')}
              </p>
            </div>
          </div>

          {/* Created */}
          <div className="flex items-center space-x-2">
            <Calendar className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
            <div>
              <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                {t('file_details.created')}
              </p>
              <p className="text-sm text-[hsl(var(--ghost-foreground))]">
                {fileDetails.created 
                  ? formatDate(new Date(fileDetails.created))
                  : t('common.unknown')}
              </p>
            </div>
          </div>

          {/* Modified */}
          <div className="flex items-center space-x-2">
            <Calendar className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
            <div>
              <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                {t('file_details.modified')}
              </p>
              <p className="text-sm text-[hsl(var(--ghost-foreground))]">
                {fileDetails.modified 
                  ? formatDate(new Date(fileDetails.modified))
                  : t('common.unknown')}
              </p>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="h-px bg-[hsl(var(--ghost-foreground-muted))]/10" />

        {/* File ID (for reference) */}
        <div>
          <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
            {t('file_details.file_id')}
          </p>
          <p className="text-xs font-mono text-[hsl(var(--ghost-foreground-muted))] truncate">
            {fileDetails.id}
          </p>
        </div>

        {/* Close Button */}
        <div className="flex justify-center pt-2">
          <motion.button
            className="px-4 py-1.5 rounded-lg text-sm bg-[hsl(var(--ghost-surface))]/50 hover:bg-[hsl(var(--ghost-surface))]/80 text-[hsl(var(--ghost-foreground))] border border-[hsl(var(--ghost-accent))]/10"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => onOpenChange(false)}
          >
            {t('common.close')}
          </motion.button>
        </div>
      </div>
    </GhostDialog>
  );
};

export default SimpleFileDetailsDialog;