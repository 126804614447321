/**
 * @file src/ghost/components/sidebar/index.tsx
 * @description Main sidebar container with enhanced mobile support
 * @version 1.2.0
 */

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { logger } from '@/utils/logger';
import { cn } from '@/utils/utils';
import { DesktopSidebar } from './DesktopSidebar';

export interface GhostSidebarProps {
  className?: string;
}

export const GhostSidebar: React.FC<GhostSidebarProps> = ({ className }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Reset collapsed state when switching between mobile and desktop
  useEffect(() => {
    if (isMobile) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isMobile]);

  logger.debug('Rendering GhostSidebar', {
    component: 'GhostSidebar',
    data: { 
      isCollapsed,
      isMobile
    }
  });

  // Don't render sidebar at all on mobile
  if (isMobile) {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="sidebar"
        initial={false}
        animate={{
          width: isCollapsed ? 80 : 280,
          opacity: 1,
          x: 0
        }}
        exit={{
          width: 0,
          opacity: 0,
          x: -40
        }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 30
        }}
        className={cn(
          "fixed inset-y-0 left-0",
          "bg-[hsl(var(--ghost-bg))]",
          "border-r border-[hsl(var(--ghost-border))/10]",
          "flex flex-col",
          "z-30",
          "overflow-hidden", // Prevent content overflow during animation
          className
        )}
      >
        <DesktopSidebar 
          isCollapsed={isCollapsed}
          onToggleCollapse={() => setIsCollapsed(!isCollapsed)}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default GhostSidebar;