/**
 * @file src/components/debug/CryptoTester.tsx
 * @description Test component for crypto operations
 * @version 1.0.0
 */

import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { debugLogger } from '@/utils/debug-logger';
import { EncryptionService } from '@/services/encrypted-drive/encryption.service';
import type { TestEncryptionState, TestResults } from '@/types/encryption.types';
import { CheckCircle, XCircle } from 'lucide-react';
import { cn } from '@/utils/utils';

const encryptionService = new EncryptionService();

export const CryptoTester: React.FC = () => {
  const [testPassword, setTestPassword] = useState('');
  const [testState, setTestState] = useState<TestEncryptionState>({});
  const [testResults, setTestResults] = useState<TestResults[]>([]);
  const [isRunning, setIsRunning] = useState(false);

  const addResult = (result: TestResults) => {
    setTestResults(prev => [...prev, result]);
  };

   // Check if encryption is available based on protocol
   const isEncryptionAvailable = window.location.protocol === 'https:' || import.meta.env.VITE_ENV === 'development';

  if (!isEncryptionAvailable) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Crypto Test Suite</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="p-4 bg-yellow-50 text-yellow-900 dark:bg-yellow-900/10 dark:text-yellow-400 rounded-lg">
            <h4 className="font-semibold">Encryption Not Available</h4>
            <p className="text-sm mt-2">
              Encryption features require HTTPS. Current protocol: {window.location.protocol}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }
  
  const handleLocalTest = async () => {
    setIsRunning(true);
    setTestResults([]);

    try {
      // Step 1: Generate keys
      debugLogger.info('Step 1: Generating encryption keys');
      addResult({
        stage: 'Key Generation',
        success: true,
        data: { passwordLength: testPassword.length }
      });

      const keyData = await encryptionService.generateMasterKey(testPassword);
      const keyDataFixed = {
        ...keyData,
        recoveryData: keyData.recoveryData
          ? {
              ...keyData.recoveryData,
              encrypted_keys: {
                ...keyData.recoveryData.encrypted_keys,
                iv: keyData.recoveryData.encrypted_keys.iv || "",
              },
            }
          : undefined,
      };

    setTestState(keyDataFixed);
      debugLogger.info('Generated encryption data:', {
        saltLength: keyData.salt.length,
        verificationHashLength: keyData.verificationHash.length,
        hasRecoveryData: !!keyData.recoveryData
      });

      addResult({
        stage: 'Key Data Generated',
        success: true,
        data: {
          saltLength: keyData.salt.length,
          verificationHashLength: keyData.verificationHash.length
        }
      });

      // Step 2: Simulate server delay
      debugLogger.info('Step 2: Simulating server storage');
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      addResult({
        stage: 'Server Storage Simulation',
        success: true
      });

      // Step 3: Test unlock
      debugLogger.info('Step 3: Testing unlock process');
      
      const unlockResult = await encryptionService.unlockDrive(testPassword, {
        id: 'test-id',
        key_salt: keyData.salt,
        key_verification_hash: keyData.verificationHash,
        encrypted_keys: {
          content_key: keyData.encryptedKeys.contentKey,
          metadata_key: keyData.encryptedKeys.metadataKey,
          iv: keyData.encryptedKeys.iv
        }
      } as any);

      addResult({
        stage: 'Unlock Test',
        success: unlockResult,
        data: { unlockSuccessful: unlockResult }
      });

    } catch (error) {
      debugLogger.error('Test failed:', error);
      addResult({
        stage: 'Error',
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Crypto Test Suite</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex gap-4">
          <Input
            placeholder="Test Password"
            type="password"
            value={testPassword}
            onChange={(e) => setTestPassword(e.target.value)}
            disabled={isRunning}
          />
          <Button 
            onClick={handleLocalTest}
            disabled={!testPassword || isRunning}
          >
            Run Test
          </Button>
        </div>

        {/* Test Results */}
        {testResults.length > 0 && (
          <div className="space-y-2">
            <h4 className="font-semibold text-sm">Test Results:</h4>
            <div className="space-y-2">
              {testResults.map((result, index) => (
                <div 
                  key={index}
                  className={cn(
                    "p-2 rounded-lg text-sm",
                    result.success ? "bg-green-50 text-green-900" : "bg-red-50 text-red-900",
                    "dark:bg-opacity-10"
                  )}
                >
                  <div className="flex items-center gap-2">
                    {result.success ? (
                      <CheckCircle className="w-4 h-4 text-green-500" />
                    ) : (
                      <XCircle className="w-4 h-4 text-red-500" />
                    )}
                    <span className="font-medium">{result.stage}</span>
                  </div>
                  {result.data && (
                    <pre className="mt-1 text-xs overflow-auto">
                      {JSON.stringify(result.data, null, 2)}
                    </pre>
                  )}
                  {result.error && (
                    <div className="mt-1 text-xs text-red-600">
                      Error: {result.error}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Current State */}
        {Object.keys(testState).length > 0 && (
          <div className="mt-4 space-y-2">
            <h4 className="font-semibold text-sm">Generated Data:</h4>
            <pre className="bg-gray-50 dark:bg-gray-900 p-4 rounded-lg overflow-auto text-xs">
              {JSON.stringify(testState, null, 2)}
            </pre>
          </div>
        )}
      </CardContent>
    </Card>
  );
};