/**
 * @file src/components/cloud-drive/items/hooks/useItemInteraction.ts
 * @description Hook for handling item interactions with enhanced navigation
 * @version 1.1.0
 */

import { useState, useCallback, useRef, useEffect } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { logger } from '@/utils/logger';
import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';

interface UseItemInteractionProps {
  item: DriveFile | DriveFolder;
  isSelectMode: boolean;
  onSelect: (id: string, event?: React.MouseEvent) => void;
  onOpen: (item: DriveFile | DriveFolder) => void;
  setIsSelectMode: (mode: boolean) => void;
}

interface InteractionHandlers {
  handleClick: (e: React.MouseEvent) => void;
  handleTouchStart: (e: React.TouchEvent) => void;
  handleTouchEnd: (e: React.TouchEvent) => void;
  handleOpen: () => void;
}

const TAP_DURATION = 500;
const TAP_THRESHOLD = 300;

export const useItemInteraction = ({
  item,
  isSelectMode,
  onSelect,
  onOpen,
  setIsSelectMode
}: UseItemInteractionProps): InteractionHandlers => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  // State for touch/click handling
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [lastTapTime, setLastTapTime] = useState(0);
  const touchTimeout = useRef<NodeJS.Timeout | null>(null);
  const clickTimeout = useRef<NodeJS.Timeout | null>(null);

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (touchTimeout.current) clearTimeout(touchTimeout.current);
      if (clickTimeout.current) clearTimeout(clickTimeout.current);
    };
  }, []);

  // Clear timeouts utility
  const clearTimeouts = useCallback(() => {
    if (touchTimeout.current) {
      clearTimeout(touchTimeout.current);
      touchTimeout.current = null;
    }
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
  }, []);

  // Handle item opening
  const handleOpen = useCallback(() => {
    logger.debug('Opening item', {
      component: 'useItemInteraction',
      data: { 
        itemId: item.id,
        itemType: item.type,
        itemName: item.name 
      }
    });

    clearTimeouts();
    onOpen(item);
  }, [item, onOpen, clearTimeouts]);

  // Handle mouse click
// Update the handleClick function in useItemInteraction.ts to better handle opening vs selection:
const handleClick = useCallback((e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();

  // Check if the click was on the checkbox area
  const isCheckboxClick = (e.target as HTMLElement).closest('.checkbox-area') !== null;
  
  if (isMobile) return;

  clearTimeouts();

  // For checkbox area, always toggle selection
  if (isCheckboxClick) {
    onSelect(item.id, e);
    return;
  }

  if (e?.detail === 1) {
    clickTimeout.current = setTimeout(() => {
      logger.debug('Single click detected', {
        component: 'useItemInteraction',
        data: {
          itemId: item.id,
          itemType: item.type,
          isSelectMode,
          modifiers: {
            ctrl: e.ctrlKey,
            shift: e.shiftKey
          }
        }
      });

      // In select mode, select the item
      if (isSelectMode || e.ctrlKey || e.shiftKey) {
        onSelect(item.id, e);
      } else {
        // Otherwise open the item directly
        handleOpen();
      }
    }, 200);
  } else if (e?.detail === 2 && !isSelectMode) {
    handleOpen();
  }
}, [item, isSelectMode, onSelect, setIsSelectMode, isMobile, handleOpen, clearTimeouts]);




  // Handle touch start
  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    setTouchStartTime(Date.now());
    
    touchTimeout.current = setTimeout(() => {
      logger.debug('Long press detected', {
        component: 'useItemInteraction',
        itemId: item.id
      });
      
      if (!isSelectMode) {
        setIsSelectMode(true);
        onSelect(item.id, undefined);
      }
    }, TAP_DURATION);
  }, [item.id, isSelectMode, onSelect, setIsSelectMode]);

  // Handle touch end
  const handleTouchEnd = useCallback((e: React.TouchEvent) => {
    const touchDuration = Date.now() - touchStartTime;
    const now = Date.now();
    
    clearTimeouts();

    if (touchDuration < TAP_DURATION) {
      if (isSelectMode) {
        onSelect(item.id, undefined);
      } else if (now - lastTapTime < TAP_THRESHOLD) {
        handleOpen();
      } else {
        setLastTapTime(now);
      }
    }
  }, [
    item,
    touchStartTime,
    lastTapTime,
    isSelectMode,
    onSelect,
    handleOpen,
    clearTimeouts
  ]);

  return {
    handleClick,
    handleTouchStart,
    handleTouchEnd,
    handleOpen
  };
};

export default useItemInteraction;