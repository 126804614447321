/**
 * @file src/app/ghost/components/zero-knowledge/ZeroKnowledgeProofDialog.tsx
 * @description Dialog that demonstrates zero knowledge encryption proof with interactive elements
 * @version 1.0.1
 * @created 2025-04-01
 * @updated 2025-04-03
 * @dependencies framer-motion, lucide-react
 */

import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { logger } from '@/utils/logger';
import { GhostDialog } from '@/app/ghost/components/common/GhostDialog';
import { cn } from '@/utils/utils';

// Import tabs
import { HowItWorksTab } from './tabs/HowItWorksTab';
import SeeTheProofTab from './tabs/SeeTheProofTab';
import { CompareTab } from './tabs/CompareTab';

// Disabled console logging to reduce noise
// console.log('Rendering ZeroKnowledgeProofDialog component');

// Tab type definition
type TabKey = 'howItWorks' | 'seeTheProof' | 'compare';

interface ZeroKnowledgeProofDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialTab?: TabKey; // Optional prop to set the initial active tab
}

export const ZeroKnowledgeProofDialog: React.FC<ZeroKnowledgeProofDialogProps> = ({
  open,
  onOpenChange,
  initialTab = 'howItWorks'
}) => {
  const { t } = useGhostTranslation();
  const [activeTab, setActiveTab] = useState<TabKey>(initialTab);
  const [demoMode, setDemoMode] = useState(false);

  // Debug logging only in development
  if (process.env.NODE_ENV === 'development') {
    logger.debug('Rendering ZeroKnowledgeProofDialog', {
      component: 'ZeroKnowledgeProofDialog',
      activeTab,
      demoMode
    });
  }

  const handleTabChange = useCallback((tab: TabKey) => {
    if (process.env.NODE_ENV === 'development') {
      logger.debug('Tab changed', { from: activeTab, to: tab });
    }
    setActiveTab(tab);
  }, [activeTab]);

  const handleEnableDemoMode = useCallback(() => {
    if (process.env.NODE_ENV === 'development') {
      logger.debug('Enabling demo mode');
    }
    setDemoMode(true);
  }, []);

  const handleDisableDemoMode = useCallback(() => {
    logger.debug('Disabling demo mode');
    setDemoMode(false);
  }, []);
  
  // Update active tab when initialTab prop changes
  React.useEffect(() => {
    if (open && initialTab) {
      logger.debug('Setting initial tab from prop', { initialTab });
      setActiveTab(initialTab);
    }
  }, [open, initialTab, logger]);

  return (
    <GhostDialog 
      open={open} 
      onOpenChange={onOpenChange}
      className="max-w-2xl w-full mx-auto" // Reduced max width
    >
      {/* Tabs navigation - Compact style for better mobile experience */}
      <div className="flex mb-4 space-x-2">
        <TabButton 
          active={activeTab === 'howItWorks'} 
          onClick={() => handleTabChange('howItWorks')}
        >
          {t('zeroKnowledge.dialog.tabs.howItWorks', 'How It Works')}
        </TabButton>
        
        <TabButton 
          active={activeTab === 'seeTheProof'} 
          onClick={() => handleTabChange('seeTheProof')}
        >
          {t('zeroKnowledge.dialog.tabs.seeTheProof', 'See The Proof')}
        </TabButton>
        
        <TabButton 
          active={activeTab === 'compare'} 
          onClick={() => handleTabChange('compare')}
        >
          {t('zeroKnowledge.dialog.tabs.compare', 'Compare')}
        </TabButton>
      </div>

      {/* Tab Content with Animation */}
      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30
          }}
          className="relative z-10"
        >
          {activeTab === 'howItWorks' && (
            <HowItWorksTab />
          )}
          
          {activeTab === 'seeTheProof' && (
            <SeeTheProofTab 
              demoMode={demoMode}
              onEnableDemoMode={handleEnableDemoMode}
              onDisableDemoMode={handleDisableDemoMode}
            />
          )}
          
          {activeTab === 'compare' && (
            <CompareTab />
          )}
        </motion.div>
      </AnimatePresence>
    </GhostDialog>
  );
};

// Helper Tab Button Component
interface TabButtonProps {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
}

const TabButton: React.FC<TabButtonProps> = ({ children, active, onClick }) => {
  return (
    <button
      className={cn(
        "px-3 py-1 text-sm rounded-lg transition-all duration-200 relative", // Reduced size
        active 
          ? "bg-[hsl(var(--ghost-accent))] text-white" 
          : "bg-[hsl(var(--ghost-surface))]/40 hover:bg-[hsl(var(--ghost-surface))]/60 text-[hsl(var(--ghost-foreground))]"
      )}
      onClick={onClick}
    >
      {/* Simple highlight for active tab - removed glow */}
      <span className="relative z-10">{children}</span>
    </button>
  );
};

export default ZeroKnowledgeProofDialog;