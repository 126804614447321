/**
 * @file: components/media/MediaDateFilter.tsx
 * @description: Date range filter component for media gallery
 * @version: 1.0.0
 */

import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Calendar } from 'lucide-react';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { MediaDateRange } from '../../hooks/media/useMediaGallery';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Calendar as CalendarComponent } from '@/components/ui/calendar';

interface MediaDateFilterProps {
  dateRange: MediaDateRange | null;
  setDateRange: (range: MediaDateRange | null) => void;
  oldestDate: string | null;
  newestDate: string | null;
}

const MediaDateFilter: React.FC<MediaDateFilterProps> = ({
  dateRange,
  setDateRange,
  oldestDate,
  newestDate,
}) => {
  const [date, setDate] = useState<Date | undefined>(
    dateRange ? new Date(dateRange.start) : undefined
  );
  const [isSelecting, setIsSelecting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Helper to determine if there's valid date range data
  const hasDateRangeData = oldestDate && newestDate;

  // Calculate default oldest and newest dates for range
  const oldestDateTime = oldestDate ? new Date(oldestDate) : new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
  const newestDateTime = newestDate ? new Date(newestDate) : new Date();

  // Handle year selection
  const handleYearSelect = (year: number) => {
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31, 23, 59, 59, 999);
    
    setDateRange({
      start: startDate.toISOString(),
      end: endDate.toISOString()
    });
    
    setIsOpen(false);
  };

  // Handle month selection
  const handleMonthSelect = (year: number, month: number) => {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0, 23, 59, 59, 999);
    
    setDateRange({
      start: startDate.toISOString(),
      end: endDate.toISOString()
    });
    
    setIsOpen(false);
  };

  // Clear date filter
  const clearDateFilter = () => {
    setDateRange(null);
    setDate(undefined);
    setIsOpen(false);
  };

  // Generate list of years between oldest and newest dates
  const getYearsList = () => {
    if (!oldestDate || !newestDate) return [];
    
    const startYear = new Date(oldestDate).getFullYear();
    const endYear = new Date(newestDate).getFullYear();
    const years = [];
    
    for (let year = endYear; year >= startYear; year--) {
      years.push(year);
    }
    
    return years;
  };

  // Get month names
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const formatDateRange = () => {
    if (!dateRange) return 'All dates';
    
    const start = new Date(dateRange.start);
    const end = new Date(dateRange.end);
    
    // If same day
    if (
      start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth() &&
      start.getDate() === end.getDate()
    ) {
      return format(start, 'MMM d, yyyy');
    }
    
    // If same month
    if (
      start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth()
    ) {
      return format(start, 'MMMM yyyy');
    }
    
    // If same year, different month
    if (start.getFullYear() === end.getFullYear()) {
      // If entire year
      if (
        start.getMonth() === 0 && start.getDate() === 1 &&
        end.getMonth() === 11 && end.getDate() === 31
      ) {
        return format(start, 'yyyy');
      }
      
      return `${format(start, 'MMM')} - ${format(end, 'MMM')}, ${format(start, 'yyyy')}`;
    }
    
    // Different years
    return `${format(start, 'MMM yyyy')} - ${format(end, 'MMM yyyy')}`;
  };

  return (
    <div className={cn(
      "border-b dark:border-gray-700 bg-white dark:bg-gray-900 p-2 flex items-center",
      !hasDateRangeData && "hidden"
    )}>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button 
            variant={dateRange ? "default" : "outline"} 
            size="sm" 
            className="flex items-center"
          >
            <Calendar className="h-4 w-4 mr-2" />
            {formatDateRange()}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <div className="p-3 border-b">
            <div className="flex justify-between items-center mb-2">
              <h4 className="font-medium">Filter by date</h4>
              {dateRange && (
                <Button variant="ghost" size="sm" onClick={clearDateFilter}>
                  Clear
                </Button>
              )}
            </div>
            
            <div className="space-y-2">
              <div>
                <div className="font-medium text-sm mb-1">Years</div>
                <div className="flex flex-wrap gap-1 max-h-24 overflow-y-auto">
                  {getYearsList().map(year => (
                    <Button
                      key={year}
                      variant="outline"
                      size="sm"
                      className={cn(
                        "text-xs",
                        dateRange && 
                        new Date(dateRange.start).getFullYear() === year && 
                        new Date(dateRange.end).getFullYear() === year && 
                        new Date(dateRange.start).getMonth() === 0 &&
                        new Date(dateRange.end).getMonth() === 11 &&
                        "bg-primary text-primary-foreground"
                      )}
                      onClick={() => handleYearSelect(year)}
                    >
                      {year}
                    </Button>
                  ))}
                </div>
              </div>
              
              <div>
                <div className="font-medium text-sm mb-1">Months</div>
                <div className="grid grid-cols-3 gap-1">
                  {monthNames.map((month, index) => (
                    <Button
                      key={month}
                      variant="outline"
                      size="sm"
                      className={cn(
                        "text-xs",
                        dateRange && 
                        new Date(dateRange.start).getMonth() === index && 
                        new Date(dateRange.end).getMonth() === index &&
                        "bg-primary text-primary-foreground"
                      )}
                      onClick={() => {
                        // Use current year or latest year if no selection
                        const year = date 
                          ? date.getFullYear() 
                          : new Date().getFullYear();
                        handleMonthSelect(year, index);
                      }}
                    >
                      {month.substring(0, 3)}
                    </Button>
                  ))}
                </div>
              </div>
              
              <div>
                <div className="font-medium text-sm mb-1">Custom range</div>
                <CalendarComponent
                  mode="range"
                  defaultMonth={date}
                  selected={{
                    from: dateRange ? new Date(dateRange.start) : undefined,
                    to: dateRange ? new Date(dateRange.end) : undefined,
                  }}
                  onSelect={(range) => {
                    if (range?.from) {
                      const startDate = range.from;
                      const endDate = range.to || range.from;
                      // Set time to end of day for end date
                      endDate.setHours(23, 59, 59, 999);
                      
                      setDateRange({
                        start: startDate.toISOString(),
                        end: endDate.toISOString()
                      });
                      
                      if (range.to) {
                        setIsOpen(false);
                      }
                    } else {
                      clearDateFilter();
                    }
                  }}
                  disabled={{
                    before: oldestDateTime,
                    after: newestDateTime
                  }}
                  initialFocus
                />
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default MediaDateFilter;