/**
 * @file src/components/services/ServiceDetails.tsx
 * @description Detailed view panel for connected cloud services
 * @version 1.0.0
 * @created 2024-12-01
 * @dependencies framer-motion, lucide-react
 */

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  X,
  HardDrive,
  FolderSync,
  AlertCircle,
  RefreshCw,
  MoreVertical,
  Trash2,
  ExternalLink,
  ChevronRight,
  Loader2,
} from "lucide-react";
import { cn } from "@/utils/utils";
import { useCloudServices } from "@/hooks/useCloudServices";

interface ServiceDetailsProps {
  serviceId: string | null;
  onClose: () => void;
}

type Tab = "overview" | "activity" | "settings";

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  serviceId,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState<Tab>("overview");
  const { useServiceDetails, useSyncStatus, triggerSync } = useCloudServices();

  const { data: service, isLoading, error } = useServiceDetails(serviceId);
  const { data: syncStatus } = useSyncStatus(serviceId);

  // Loading state
  if (isLoading) {
    return (
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-4 border-b border-gray-100 dark:border-gray-800">
          <h3 className="text-lg font-semibold">Details</h3>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <div className="flex items-center space-x-2 text-blue-500 dark:text-blue-400">
            <Loader2 className="h-5 w-5 animate-spin" />
            <span>Loading details...</span>
          </div>
        </div>
      </div>
    );
  }

  // Error state
  if (error || !service) {
    return (
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-4 border-b border-gray-100 dark:border-gray-800">
          <h3 className="text-lg font-semibold">Details</h3>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <div className="text-red-500 dark:text-red-400 text-center">
            <AlertCircle className="h-8 w-8 mx-auto mb-2" />
            <p>Error loading service details</p>
            <p className="text-sm">{(error as Error)?.message}</p>
          </div>
        </div>
      </div>
    );
  }

  const renderOverviewTab = () => (
    <div className="space-y-6">
      {/* Storage Usage */}
      <div className="space-y-3">
        <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100 flex items-center">
          <HardDrive className="h-4 w-4 mr-2" />
          Storage Usage
        </h4>
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-500 dark:text-gray-400">Used Space</span>
            <span className="text-gray-700 dark:text-gray-300">
              {service.usedSpace?.formatted} / {service.totalSpace?.formatted}
            </span>
          </div>
          <div className="h-2 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{
                width: `${
                  ((service.usedSpace?.value ?? 0) /
                    (service.totalSpace?.value ?? 1)) *
                  100
                }%`,
              }}
              className="h-full bg-blue-500 dark:bg-blue-400 rounded-full"
            />
          </div>
        </div>
      </div>

      {/* Sync Status */}
      <div className="space-y-3">
        <div className="flex items-center justify-between">
          <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100 flex items-center">
            <FolderSync className="h-4 w-4 mr-2" />
            Sync Status
          </h4>
          <button
            onClick={() => serviceId && triggerSync(serviceId)}
            disabled={syncStatus?.status === 'syncing'}
            className={cn(
              "p-2 rounded-lg text-gray-500 dark:text-gray-400",
              "hover:bg-gray-100 dark:hover:bg-gray-800",
              "transition-colors duration-200",
              "disabled:opacity-50"
            )}
          >
            <RefreshCw
              className={cn(
                "h-4 w-4",
                syncStatus?.status === "syncing" && "animate-spin"
              )}
            />
          </button>
        </div>
        <div
          className={cn(
            "p-3 rounded-lg text-sm",
            "flex items-center justify-between",
            "bg-gray-50 dark:bg-gray-800"
          )}
        >
          <span className="text-gray-600 dark:text-gray-300">
            {syncStatus?.status === "syncing" ? "Syncing..." : "Last synced:"}
          </span>
          <span className="text-gray-900 dark:text-gray-100">
            {syncStatus?.lastSync
              ? new Date(syncStatus.lastSync).toLocaleString()
              : "Never"}
          </span>
        </div>
      </div>

      {/* Connected Folders */}
      <div className="space-y-3">
        <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100">
          Connected Folders
        </h4>
        <div className="space-y-2">
          {["Documents", "Pictures", "Downloads"].map((folder) => (
            <div
              key={folder}
              className={cn(
                "p-3 rounded-lg",
                "flex items-center justify-between",
                "bg-gray-50 dark:bg-gray-800",
                "text-sm"
              )}
            >
              <span className="text-gray-600 dark:text-gray-300">{folder}</span>
              <ChevronRight className="h-4 w-4 text-gray-400" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderActivityTab = () => (
    <div className="space-y-4">
      {/* Activity entries would go here */}
      <div className="text-sm text-gray-500 dark:text-gray-400 text-center py-8">
        Activity log coming soon
      </div>
    </div>
  );

  const renderSettingsTab = () => (
    <div className="space-y-6">
      {/* Settings options */}
      <div className="space-y-4">
        <button
          className={cn(
            "w-full p-3 rounded-lg",
            "flex items-center justify-between",
            "text-sm text-gray-600 dark:text-gray-300",
            "hover:bg-gray-50 dark:hover:bg-gray-800",
            "transition-colors duration-200"
          )}
        >
          <div className="flex items-center space-x-3">
            <ExternalLink className="h-4 w-4" />
            <span>Open in {service.name}</span>
          </div>
          <ChevronRight className="h-4 w-4 text-gray-400" />
        </button>

        <button
          className={cn(
            "w-full p-3 rounded-lg",
            "flex items-center justify-between",
            "text-sm text-red-600 dark:text-red-400",
            "hover:bg-red-50 dark:hover:bg-red-900/20",
            "transition-colors duration-200"
          )}
        >
          <div className="flex items-center space-x-3">
            <Trash2 className="h-4 w-4" />
            <span>Disconnect Service</span>
          </div>
          <ChevronRight className="h-4 w-4" />
        </button>
      </div>
    </div>
  );

  const handleTriggerSync = () => {
    if (serviceId) {
      triggerSync(serviceId);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b border-gray-100 dark:border-gray-800">
        <h3 className="text-lg font-semibold">{service.name}</h3>
        <div className="flex items-center space-x-2">
          <button
            className={cn(
              "p-2 rounded-lg",
              "text-gray-500 dark:text-gray-400",
              "hover:bg-gray-100 dark:hover:bg-gray-800",
              "transition-colors duration-200"
            )}
          >
            <MoreVertical className="h-4 w-4" />
          </button>
          <button
            onClick={onClose}
            className={cn(
              "p-2 rounded-lg",
              "text-gray-500 dark:text-gray-400",
              "hover:bg-gray-100 dark:hover:bg-gray-800",
              "transition-colors duration-200"
            )}
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="flex border-b border-gray-100 dark:border-gray-800">
        {(["overview", "activity", "settings"] as const).map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={cn(
              "flex-1 px-4 py-3",
              "text-sm font-medium",
              "transition-colors duration-200",
              activeTab === tab
                ? "text-blue-500 dark:text-blue-400 border-b-2 border-blue-500 dark:border-blue-400"
                : "text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
            )}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {/* Content */}
      <div className="flex-1 overflow-auto p-4">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            {activeTab === "overview" && renderOverviewTab()}
            {activeTab === "activity" && renderActivityTab()}
            {activeTab === "settings" && renderSettingsTab()}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};
