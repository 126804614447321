/**
 * @file src/services/encrypted-drive/encrypted-upload.service.ts
 * @description Handles encrypted file uploads with metadata encryption
 * @version 1.2.0
 */

import { encryptionService } from './encryption-singleton';

import { logger } from '@/utils/logger';
import { debugLogger } from '@/utils/debug-logger';
import { EncryptionTier } from '@/types/encrypted-drive.types';
import type { UploadProgress } from '@/types/upload.types';
import { UPLOAD_CONFIG } from '@/config/upload.config'; 

// Define the EncryptionStatus type locally since it's not exported from upload.types
type EncryptionStatus = 'idle' | 'encrypting' | 'uploading' | 'completed' | 'failed' | 'canceled';

// Redefine UploadProgress here to avoid incompatible inheritance issues
interface ProgressBase {
  loaded: number;
  total: number;
  currentChunk?: number;
  speed?: number;
}

// Base type for upload progress - now implements all properties directly
interface EncryptedUploadProgress extends ProgressBase {
  encryptionProgress?: number;
  encryptionStatus?: EncryptionStatus;
  originalSize?: number;
  encryptedSize?: number;
}

// Define options interface compatible with other services
interface EncryptedUploadOptions {
  driveId: string;
  parentId: string | null;
  encryptionTier: EncryptionTier;
  onProgress?: (progress: EncryptedUploadProgress) => void;
  // Add mediaInfo support for media files
  mediaInfo?: {
    type: string;
    dimensions?: { width: number; height: number };
    duration?: number;
    hasPreview?: boolean;
    [key: string]: any; // Allow additional properties
  };
}

export const encryptedUploadService = {
  // Creates an adapter for the progress callback to handle type compatibility
  createProgressAdapter(
    originalCallback?: (progress: EncryptedUploadProgress) => void
  ): ((progress: UploadProgress) => void) | undefined {
    if (!originalCallback) return undefined;
    
    return (progress: UploadProgress) => {
      // Map UploadProgress to EncryptedUploadProgress
      const adaptedProgress: EncryptedUploadProgress = {
        loaded: progress.loaded,
        total: progress.total,
        currentChunk: progress.currentChunk,
        speed: progress.speed,
        encryptionProgress: progress.encryptionProgress,
        encryptionStatus: progress.encryptionStatus as EncryptionStatus,
        originalSize: progress.originalSize,
        encryptedSize: progress.encryptedSize
      };
      
      // Call the original callback with the adapted progress
      originalCallback(adaptedProgress);
    };
  },

  async uploadFile(
    file: File,
    options: EncryptedUploadOptions
  ) {
    const { driveId, parentId } = options;
    // Extract encryptionTier into a mutable variable, not a constant
    let encryptionTier = options.encryptionTier;
    const onProgress = this.createProgressAdapter(options.onProgress);
  
    try {

          // More explicit media type checking with logging
          const fileType = file.type.toLowerCase();
          const fileName = file.name.toLowerCase();
          const fileExt = fileName.split('.').pop() || '';
          
          // Primary check: MIME type
          const isImage = fileType.includes('image/');
          const isVideo = fileType.includes('video/');
          
          // Fallback check: File extension for common media types
          const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp', 'svg'];
          const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv', '3gp'];
          
          const isImageByExt = imageExtensions.includes(fileExt);
          const isVideoByExt = videoExtensions.includes(fileExt);
          const isMedia = (isImage || isVideo || isImageByExt || isVideoByExt);
          
          logger.debug('Enhanced file type detection', {
            component: 'encryptedUploadService',
            data: {
              fileName: file.name,
              fileType: file.type,
              fileExt,
              isImage: isImage || isImageByExt,
              isVideo: isVideo || isVideoByExt,
              isMedia: isMedia,
              detectionMethod: (isImage || isVideo) ? 'mime-type' : (isImageByExt || isVideoByExt ? 'extension' : 'none')
            }
          });
          

          // Replace the media detection try/catch block in uploadFile with:
          if (isMedia) {
            try {
              // Import media services
              const mediaModule = await import('./media/media-processing.service');
              const mediaService = mediaModule.mediaProcessingService;
              
              logger.debug('Media file detected, using media upload service', {
                component: 'encryptedUploadService',
                data: {
                  fileName: file.name,
                  mediaType: (isImage || isImageByExt) ? 'image' : 'video'
                }
              });
              
              // Import media upload service
              const { default: mediaUploadService } = await import('./encrypted-media-upload.service');
              
              // Check file size
              if (file?.size > UPLOAD_CONFIG.chunkSize) {
                logger.debug('Large media file detected, using multipart upload', {
                  component: 'encryptedUploadService',
                  data: {
                    fileName: file.name,
                    fileSize: file.size,
                    chunkSize: UPLOAD_CONFIG.chunkSize
                  }
                });
                
                // Process media first
                const mediaResult = await mediaService.processMedia(file);
                if (!mediaResult) {
                  throw new Error('Failed to process media file');
                }
                
                // Use uploadMediaFile instead of handleLargeMediaFile which doesn't exist
                // Create a new options object that's type-safe
                const uploadOptions = { 
                  driveId: options.driveId,
                  parentId: options.parentId,
                  encryptionTier: options.encryptionTier,
                  // Use our adapter for the onProgress callback to ensure type safety
                  onProgress: this.createProgressAdapter(options.onProgress)
                };
                
                // Create a new mediaUploadOptions with proper typing
                const mediaUploadOptions = {
                  ...uploadOptions,
                  mediaInfo: mediaResult && mediaResult.type ? {
                    type: mediaResult.type,
                    ...(mediaResult.mediaInfo || {})
                  } : undefined
                }
                
                return await mediaUploadService.uploadMediaFile(file, mediaUploadOptions);
              }
              
              // For smaller files, use regular upload - create a safe options object
              const regularMediaOptions = {
                driveId: options.driveId,
                parentId: options.parentId,
                encryptionTier: options.encryptionTier,
                onProgress: this.createProgressAdapter(options.onProgress),
                mediaInfo: options.mediaInfo
              };
              return await mediaUploadService.uploadMediaFile(file, regularMediaOptions);
            } catch (error) {
              // Log error and abort - NO FALLBACK
              logger.error('Media upload failed, aborting upload', {
                component: 'encryptedUploadService',
                data: {
                  fileName: file.name,
                  error: error instanceof Error ? error.message : String(error),
                  stack: error instanceof Error ? error.stack : undefined
                }
              });
              
              // Show user-friendly error
              const errorMessage = error instanceof Error ? error.message : 'Upload failed';
              throw new Error(`Media file upload failed: ${errorMessage}. Media files require proper thumbnail processing.`);
            }
          }


      // 1. Get encryption keys and set active drive
      logger.debug('Retrieving encryption keys for upload', {
        component: 'encryptedUploadService',
        data: { 
          driveId,
          fileName: file.name,
          encryptionTier: encryptionTier
        }
      });
      
      // Add visual debug message
      console.debug(`[EncryptedUploadService] Starting upload for ${file.name} with tier ${encryptionTier}`);
      
      // Set active drive to ensure correct encryption tier is used
      // Use await to ensure tier is set before proceeding
      const activeDriveResult = await encryptionService.setActiveDrive(driveId);
      
      if (!activeDriveResult) {
        logger.warn('Failed to set active drive, but continuing with explicit tier', {
          component: 'encryptedUploadService',
          data: {
            driveId,
            encryptionTier
          }
        });
      }
      
      // Add a small delay to ensure tier is properly set
      await new Promise(resolve => setTimeout(resolve, 50));
      
      // Get the current stored encryption tier for this drive
      const actualEncryptionTier = encryptionService.getDriveEncryptionTier(driveId);
      
      // Log detailed information about the encryption tiers
      console.debug(`[EncryptedUploadService] Encryption tiers - Requested: ${encryptionTier}, Actual: ${actualEncryptionTier || 'none'}, Current: ${encryptionService.currentEncryptionTier}`);
      
      // Validate that the tier matches what we expect
      if (actualEncryptionTier && actualEncryptionTier !== encryptionTier) {
        logger.warn('Encryption tier mismatch - using stored tier from drive', {
          component: 'encryptedUploadService',
          data: {
            driveId,
            requestedTier: encryptionTier,
            actualTier: actualEncryptionTier,
            fileName: file.name
          }
        });
        // Use the correct tier from drive settings
        encryptionTier = actualEncryptionTier as EncryptionTier;
        
        console.debug(`[EncryptedUploadService] Corrected encryption tier to: ${encryptionTier}`);
      }
      
      const keys = encryptionService.getDecryptedKeys(driveId);
      if (!keys) {
        const error = new Error('Drive not unlocked for uploading. Please unlock the drive first.');
        logger.error('Encryption keys unavailable', {
          component: 'encryptedUploadService',
          error,
          data: { 
            driveId, 
            fileName: file.name,
            encryptionTier
          }
        });
        throw error;
      }
      
      logger.debug('Successfully retrieved encryption keys', {
        component: 'encryptedUploadService',
        data: { 
          driveId,
          hasMetadataKey: !!keys.metadataKey,
          hasContentKey: !!keys.contentKey,
          encryptionTier: encryptionTier,
          storedTier: actualEncryptionTier
        }
      });
  
      // Start progress reporting
      let encryptionProgress = 0;
      const startTime = Date.now();
      
      // Report initial state with "processing" status
      if (onProgress) {
        onProgress({
          loaded: 0,
          total: file.size,
          speed: 0,
          encryptionProgress: 0,
          encryptionStatus: 'processing', // Start with processing status
          originalSize: file.size,
          message: 'Preparing your file...'
        });
      }
  
      // Set up interval for simulated encryption progress
      const progressInterval = setInterval(() => {
        // First 30% is "processing" phase
        if (encryptionProgress < 30) {
          encryptionProgress += Math.random() * 3 + 1; // Slower increment for processing
          encryptionProgress = Math.min(encryptionProgress, 30);
          
          if (onProgress) {
            onProgress({
              loaded: 0,
              total: file.size,
              speed: 0,
              encryptionProgress,
              encryptionStatus: 'processing',
              originalSize: file.size,
              message: 'Processing file...'
            });
          }
        } 
        // 30-80% is "encrypting" phase
        else if (encryptionProgress < 80) {
          encryptionProgress += Math.random() * 4 + 2;
          encryptionProgress = Math.min(encryptionProgress, 80);
          
          if (onProgress) {
            onProgress({
              loaded: 0,
              total: file.size,
              speed: 0,
              encryptionProgress,
              encryptionStatus: 'encrypting',
              originalSize: file.size,
              message: 'Encrypting your data...'
            });
          }
        }
      }, 100); // Faster updates for better visual feedback
  
      try {
        debugLogger.info('Starting file metadata encryption', {
          component: 'encryptedUploadService',
          data: {
            fileName: file.name,
            fileSize: file.size,
            driveId,
            encryptionTier
          }
        });
    
        // Create comprehensive metadata object
        const metadata = {
          name: file.name,
          path: parentId ? `/${parentId}/${file.name}` : `/${file.name}`,
          size: file.size,
          type: file.type,
          created: file.lastModified,
          modified: file.lastModified
        };
    
        const encryptedMetadata = await encryptionService.encryptMetadata(
          metadata,
          keys.metadataKey
        );
        
        // Update progress to 85% after metadata encryption
        encryptionProgress = 85;
        if (onProgress) {
          onProgress({
            loaded: 0,
            total: file.size,
            speed: 0,
            encryptionProgress: 85,
            encryptionStatus: 'encrypting',
            originalSize: file.size,
            message: 'Applying encryption shield...'
          });
        }
    
        // 3. Encrypt file content
        debugLogger.info('Starting file content encryption', {
          component: 'encryptedUploadService',
          data: { 
            fileName: file.name, 
            fileSize: file.size,
            encryptionTier,
            driveId
          }
        });
        
        // Add explicit console log for debugging
        console.debug(`[EncryptedUploadService] Encrypting ${file.name} using tier: ${encryptionTier}`);
        
        const buffer = await file.arrayBuffer();
        const encryptedContent = await encryptionService.encryptContentWithTier(
          buffer,
          encryptionTier,
          keys.contentKey,
          driveId // Pass driveId to ensure tier consistency
        );
        
        // Log the algorithm used
        console.debug(`[EncryptedUploadService] File encrypted with algorithm: ${encryptedContent.algorithm || encryptionTier}`);
        
        // Add enhanced debug log to verify encryption was done with the right algorithm
        debugLogger.info('Content encryption completed', {
          component: 'encryptedUploadService',
          data: { 
            fileName: file.name, 
            encryptionTier,
            algorithm: encryptedContent.algorithm,
            usedTier: encryptionService.currentEncryptionTier,
            driveId
          }
        });
    
        // Update to 100% when content encryption is done
        clearInterval(progressInterval);
        encryptionProgress = 100;
        if (onProgress) {
          onProgress({
            loaded: 0,
            total: file.size,
            speed: 0,
            encryptionProgress: 100,
            encryptionStatus: 'uploading',
            originalSize: file.size,
            message: 'Encryption complete, preparing upload...'
          });
        }
    
        debugLogger.info('File encryption completed', {
          component: 'encryptedUploadService',
          data: {
            fileName: file.name,
            originalSize: file.size,
            encryptedSize: encryptedContent.encryptedData.length,
            timeMs: Date.now() - startTime
          }
        });
    
        // 4. Create encrypted blob - FIXED THIS PART
        // Convert base64 to binary using browser APIs
        const binaryString = atob(encryptedContent.encryptedData);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        
        const encryptedFile = new Blob(
          [bytes], 
          { type: 'application/octet-stream' }
        );
    
        // Add metadata to formdata
        const formData = new FormData(); 
        formData.append('file', encryptedFile);
        formData.append('encrypted_name', encryptedMetadata.encryptedData);
        formData.append('encrypted_path', encryptedMetadata.encryptedData);
        formData.append('encrypted_metadata', encryptedMetadata.encryptedData); // Full metadata
        formData.append('metadata_iv', encryptedMetadata.iv);
        formData.append('content_iv', encryptedContent.iv);
        formData.append('drive_id', driveId);
        formData.append('original_size', file.size.toString());
        formData.append('encrypted_size', encryptedFile.size.toString());
        
        if (parentId) {
          formData.append('parent_id', parentId);
        }
    
        // Create a progress tracker for the upload phase
        let lastLoaded = 0;
        const uploadStartTime = Date.now();
        
        // 5. Upload via encrypted endpoint with progress tracking
        const xhr = new XMLHttpRequest();
        const uploadPromise = new Promise<any>((resolve, reject) => {
          xhr.open('POST', '/api/v1/encrypted-drive/upload', true);
          
          // Track upload progress
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable && onProgress) {
              const loaded = event.loaded;
              const total = event.total;
              const elapsed = Date.now() - uploadStartTime;
              const speed = elapsed > 0 ? (loaded - lastLoaded) * (1000 / elapsed) : 0;
              
              lastLoaded = loaded;
              
              onProgress({
                loaded,
                total,
                speed,
                encryptionProgress: 100, // Encryption is complete
                encryptionStatus: 'uploading',
                originalSize: file.size,
                encryptedSize: encryptedFile.size,
                message: `Uploading to your secure vault (${Math.round(loaded/total*100)}%)...`
              });
            }
          };
          
          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              try {
                const response = JSON.parse(xhr.responseText);
                resolve(response);
              } catch (e) {
                reject(new Error('Invalid response format'));
              }
            } else {
              reject(new Error(`Upload failed with status ${xhr.status}: ${xhr.statusText}`));
            }
          };
          
          xhr.onerror = () => {
            reject(new Error('Network error during upload'));
          };
          
          xhr.send(formData);
        });
        
        const response = await uploadPromise;
        
        // Report completion
        if (onProgress) {
          onProgress({
            loaded: encryptedFile.size,
            total: encryptedFile.size,
            speed: 0,
            encryptionProgress: 100,
            encryptionStatus: 'completed',
            originalSize: file.size,
            encryptedSize: encryptedFile.size
          });
        }
        
        debugLogger.info('Encrypted file upload completed', {
          component: 'encryptedUploadService',
          data: {
            fileName: file.name,
            fileId: response.id || response.file_id,
            totalTimeMs: Date.now() - startTime
          }
        });
    
        return {
          ...response,
          id: response.id || response.file_id,  // Handle different response formats
          name: file.name,  // Use original file name for display
          size: file.size,  // Use original file size for display
          mimeType: file.type,
          isEncrypted: true,
          originalSize: file.size,
          encryptedSize: encryptedFile.size
        };
      } catch (error) {
        // Make sure to clear the interval if an error occurs
        clearInterval(progressInterval);
        throw error;
      }
  
    } catch (error) {
      logger.error('Encrypted upload failed:', {
        component: 'encryptedUploadService',
        error,
        data: { fileName: file.name }
      });
      throw error;
    }
  }
};