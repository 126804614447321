/**
 * @file src/services/upload/core/resumeManager.ts
 * @description Manages upload resume state and storage
 * @version 1.0.0
 */

import { logger } from '@/utils/logger';
import type { UploadFile, UploadStatus } from '@/types/upload.types';

const RESUME_STORAGE_KEY = 'bigmind_upload_state';
const RESUME_EXPIRY_HOURS = 24;

export class ResumeManager {
  private resumableUploads: Map<string, UploadFile> = new Map();

  constructor() {
    this.loadResumeState();
    this.cleanupExpiredStates();
  }

  /**
   * Save resume state to localStorage
   */
  saveResumeState(uploads: Map<string, UploadFile>): void {
    try {
      const states = Array.from(uploads.values())
        .filter((upload: UploadFile) => upload.resumable);

      localStorage.setItem(RESUME_STORAGE_KEY, JSON.stringify(states));

      logger.debug('Saved resume states', {
        component: 'ResumeManager',
        action: 'saveResumeState',
        data: {
          count: states.length,
          uploadIds: states.map((upload: UploadFile) => upload.id)
        }
      });
    } catch (error) {
      logger.error('Error saving resume state:', {
        component: 'ResumeManager',
        error
      });
    }
  }

  /**
   * Load resume state from localStorage
   */
  loadResumeState(): UploadFile[] {
    try {
      const data = localStorage.getItem(RESUME_STORAGE_KEY);
      const states = data ? JSON.parse(data) : [];

      states.forEach((state: UploadFile) => {
        this.resumableUploads.set(state.id, state);
      });

      logger.debug('Loaded resume states', {
        component: 'ResumeManager',
        action: 'loadResumeState',
        data: { count: states.length }
      });

      return states;
    } catch (error) {
      logger.error('Error loading resume state:', {
        component: 'ResumeManager',
        error
      });
      return [];
    }
  }

  /**
   * Find resumable upload for a file
   */
  findResumableUpload(file: File): UploadFile | null {
    try {
      const upload = Array.from(this.resumableUploads.values()).find((upload: UploadFile) =>
        upload.file.name === file.name &&
        upload.file.size === file.size &&
        upload.file.lastModified === file.lastModified &&
        upload.status !== 'completed' &&
        upload.status !== "error" as UploadStatus
      );

      if (upload) {
        logger.debug('Found resumable upload', {
          component: 'ResumeManager',
          action: 'findResumableUpload',
          data: {
            uploadId: upload.id,
            fileName: file.name,
            progress: upload.progress
          }
        });
      }

      return upload || null;
    } catch (error) {
      logger.error('Error finding resumable upload:', {
        component: 'ResumeManager',
        error,
        data: { fileName: file.name }
      });
      return null;
    }
  }

  /**
   * Clear resume state for an upload
   */
  clearResumeState(uploadId: string): void {
    try {
      this.resumableUploads.delete(uploadId);
      this.saveResumeState(this.resumableUploads);

      logger.debug('Cleared resume state', {
        component: 'ResumeManager',
        action: 'clearResumeState',
        data: { uploadId }
      });
    } catch (error) {
      logger.error('Error clearing resume state:', {
        component: 'ResumeManager',
        error,
        data: { uploadId }
      });
    }
  }

  /**
   * Clean up expired resume states
   */
  cleanupExpiredStates(): void {
    try {
      const now = Date.now();
      const expiryMs = RESUME_EXPIRY_HOURS * 60 * 60 * 1000;

      const expiredIds: string[] = [];
      this.resumableUploads.forEach((state, id) => {
        const uploadStartTime = state.startTime || Date.now();
        if (now - uploadStartTime > expiryMs) {
          expiredIds.push(id);
        }
      });

      expiredIds.forEach((uploadId: string) => {
        this.resumableUploads.delete(uploadId);
        logger.debug('Removed expired upload state', {
          component: 'ResumeManager',
          action: 'cleanupExpiredStates',
          data: { uploadId }
        });
      });

      if (expiredIds?.length > 0) {
        this.saveResumeState(this.resumableUploads);
      }
    } catch (error) {
      logger.error('Error cleaning up expired states:', {
        component: 'ResumeManager',
        error
      });
    }
  }

  /**
   * Update upload status and save state
   */
  updateUploadStatus(id: string, status: UploadStatus): void {
    const state = this.resumableUploads.get(id);
    if (state) {
      state.status = status;
      this.saveResumeState(this.resumableUploads);
    }
  }
}

// Export singleton instance
export const resumeManager = new ResumeManager();