/**
 * @file: components/media/MediaView.tsx
 * @description: Main component for displaying the media gallery
 * @version: 1.0.0
 */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useMediaGallery } from '../../hooks/media/useMediaGallery';
import { MediaItem } from '../../services/media.service';
import MediaGrid from './MediaGrid';
import MediaToolbar from './MediaToolbar';
import MediaDateFilter from './MediaDateFilter';
import MediaViewSkeleton from './MediaViewSkeleton';
import { useCloudDrive } from '../../context/cloud-drive/CloudDriveContext';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { RefreshCw } from 'lucide-react';

interface MediaViewProps {
  driveId?: string;
  className?: string;
}

export const MediaView: React.FC<MediaViewProps> = ({ 
  driveId,
  className = ''
}) => {
  const { currentDrive } = useCloudDrive();
  const [selectedItems, setSelectedItems] = useState<MediaItem[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    items,
    isLoading,
    hasMore,
    totalItems,
    error,
    stats,
    mediaTypes,
    dateRange,
    favoritesOnly,
    refresh,
    loadMore,
    setMediaTypes,
    setDateRange,
    setFavoritesOnly,
    resetFilters,
    hasActiveFilters
  } = useMediaGallery({
    driveId: driveId || currentDrive?.id,
    pageSize: 100,
    autoLoad: true
  });

  const handleScroll = useCallback(() => {
    if (containerRef.current && !isLoading && hasMore) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 300) {
        loadMore();
      }
    }
  }, [isLoading, hasMore, loadMore]);

  // Add scroll listener
  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
      return () => {
        currentContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  // Handle selection of items
  const handleSelect = useCallback((item: MediaItem, append: boolean = false) => {
    setSelectedItems(prev => {
      const selected = prev.find(i => i.id === item.id);
      if (selected) {
        return append ? prev.filter(i => i.id !== item.id) : [];
      } else {
        return append ? [...prev, item] : [item];
      }
    });
  }, []);

  // Clear selection
  const clearSelection = useCallback(() => {
    setSelectedItems([]);
  }, []);

  return (
    <div className={`flex flex-col h-full ${className}`}>
      {/* Toolbar with filters */}
      <MediaToolbar 
        totalItems={totalItems}
        selectedCount={selectedItems.length}
        mediaTypes={mediaTypes}
        setMediaTypes={setMediaTypes}
        favoritesOnly={favoritesOnly}
        setFavoritesOnly={setFavoritesOnly}
        onClearSelection={clearSelection}
        onRefresh={refresh}
        hasActiveFilters={hasActiveFilters}
        resetFilters={resetFilters}
      />
      
      {/* Date range filter */}
      {stats && (
        <MediaDateFilter 
          dateRange={dateRange}
          setDateRange={setDateRange}
          oldestDate={stats.date_range.oldest}
          newestDate={stats.date_range.newest}
        />
      )}
      
      {/* Error display */}
      {error && (
        <Alert variant="destructive" className="mb-4 mx-4">
          <AlertTitle>Error loading media</AlertTitle>
          <AlertDescription className="flex justify-between items-center">
            {error}
            <Button variant="outline" size="sm" onClick={refresh}>
              <RefreshCw className="mr-2 h-4 w-4" />
              Retry
            </Button>
          </AlertDescription>
        </Alert>
      )}
      
      {/* Main content */}
      <div 
        ref={containerRef}
        className="flex-1 overflow-y-auto p-4"
      >
        {isLoading && items.length === 0 ? (
          <MediaViewSkeleton />
        ) : (
          <MediaGrid 
            items={items}
            selectedItems={selectedItems}
            onSelect={handleSelect}
            isLoading={isLoading}
            hasMore={hasMore}
          />
        )}
        
        {/* Empty state */}
        {!isLoading && items.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full text-center p-8">
            <h3 className="text-2xl font-semibold text-gray-700 dark:text-gray-300 mb-4">
              No media found
            </h3>
            <p className="text-gray-500 dark:text-gray-400 mb-6 max-w-md">
              {hasActiveFilters 
                ? "Try adjusting your filters to see more results."
                : "Upload photos and videos to see them here."}
            </p>
            {hasActiveFilters && (
              <Button onClick={resetFilters} variant="outline">
                Reset Filters
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaView;