/**
 * @file src/providers/AuditProvider.tsx
 * @description Context provider for audit feature
 * @version 1.2.0
 * @created 2024-02-24
 * @updated 2025-02-25
 */

import React from "react";
import { AuditBaseFilters } from "@/types/audit.types";
import { logger } from "@/utils/logger";

// Add sort configuration to the context
interface SortConfig {
  sortColumn?: string;
  sortDirection?: "asc" | "desc";
}

interface AuditContextState {
  filters: AuditBaseFilters & SortConfig;
  setFilters: (filters: Partial<AuditBaseFilters & SortConfig>) => void;
  resetFilters: () => void;
  setSorting: (column: string, direction: "asc" | "desc") => void;
}

// Initial default state
const defaultFilters: AuditBaseFilters & SortConfig = {
  userId: undefined,
  deviceId: undefined,
  fromDate: null,
  toDate: null,
  action: undefined,
  search: undefined,
  sortColumn: "timestamp",
  sortDirection: "desc",
};

const AuditContext = React.createContext<AuditContextState | undefined>(
  undefined
);

export const AuditProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [filters, setFiltersState] = React.useState<
    AuditBaseFilters & SortConfig
  >(defaultFilters);

  const setFilters = React.useCallback(
    (newFilters: Partial<AuditBaseFilters & SortConfig>) => {
      logger.debug("Setting audit filters:", newFilters);
      setFiltersState((prev) => ({
        ...prev,
        ...newFilters,
      }));
    },
    []
  );

  const resetFilters = React.useCallback(() => {
    logger.debug("Resetting audit filters");
    setFiltersState(defaultFilters);
  }, []);

  // Add a new setter specifically for sorting
  const setSorting = React.useCallback(
    (column: string, direction: "asc" | "desc") => {
      logger.debug("Setting audit sorting:", { column, direction } as any);
      setFiltersState((prev) => ({
        ...prev,
        sortColumn: column,
        sortDirection: direction,
      }));
    },
    []
  );

  const value = React.useMemo(
    () => ({
      filters,
      setFilters,
      resetFilters,
      setSorting,
    }),
    [filters, setFilters, resetFilters, setSorting]
  );

  return (
    <AuditContext.Provider value={value}>{children}</AuditContext.Provider>
  );
};

export const useAudit = () => {
  const context = React.useContext(AuditContext);
  if (context === undefined) {
    throw new Error("useAudit must be used within an AuditProvider");
  }
  return context;
};
