/**
 * @file src/components/encrypted-drive/media/EncryptedImageDisplay.tsx
 * @description Dedicated component for displaying encrypted images with zoom and rotation
 * @version 1.0.0
 * @created 2025-03-30
 */

import React, { useState, useCallback } from 'react';
import { ZoomIn, ZoomOut, RotateCw, RefreshCw } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ThumbnailViewer } from './ThumbnailViewer';
import { logger } from '@/utils/logger';

interface EncryptedImageDisplayProps {
  imageUrl: string | null;
  fileId: string;
  driveId: string;
  thumbnails: Record<string, any>;
  isLoading: boolean;
  fileName?: string;
  className?: string;
}

export const EncryptedImageDisplay: React.FC<EncryptedImageDisplayProps> = ({
  imageUrl,
  fileId,
  driveId,
  thumbnails,
  isLoading,
  fileName,
  className
}) => {
  // Image display state
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  
  // Get the best available thumbnail
  const sizes: ('small' | 'medium' | 'large')[] = ['large', 'medium', 'small'];
  const bestAvailableSize = sizes.find((size: any) => thumbnails[size]) || 'medium';
  
  // Core image actions
  const handleZoomIn = useCallback(() => {
    logger.debug('Zoom in', { component: 'EncryptedImageDisplay', currentZoom: zoom });
    setZoom(prev => Math.min(prev + 0.25, 3));
  }, [zoom]);
  
  const handleZoomOut = useCallback(() => {
    logger.debug('Zoom out', { component: 'EncryptedImageDisplay', currentZoom: zoom });
    setZoom(prev => Math.max(prev - 0.25, 0.5));
  }, [zoom]);
  
  const handleRotate = useCallback(() => {
    logger.debug('Rotate image', { component: 'EncryptedImageDisplay', currentRotation: rotation });
    setRotation(prev => (prev + 90) % 360);
  }, [rotation]);
  
  const handleResetZoom = useCallback(() => {
    logger.debug('Reset view', { component: 'EncryptedImageDisplay' });
    setZoom(1);
    setRotation(0);
  }, []);

  return (
    <div className={`relative w-full h-full flex items-center justify-center ${className || ''}`}>
      {/* Reset Zoom/Rotation floating button - using permanent display when transformed */}
      {(zoom !== 1 || rotation !== 0) && (
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-50 transition-opacity duration-300">
          <Button 
            variant="outline" 
            size="sm"
            onClick={handleResetZoom}
            className="bg-gray-800/80 text-white border-0 hover:bg-gray-700 backdrop-blur-sm shadow-md"
          >
            <RefreshCw className="h-3 w-3 mr-2" />
            Reset view
          </Button>
        </div>
      )}
      
      {imageUrl ? (
        <div
          style={{
            transform: `scale(${zoom}) rotate(${rotation}deg)`,
            transformOrigin: 'center',
            transition: 'transform 0.2s ease-out'
          }}
          className="max-h-full max-w-full"
        >
          <img 
            src={imageUrl} 
            alt={fileName || 'Encrypted image'} 
            className="max-h-[80vh] sm:max-h-[85vh] max-w-full object-contain"
            style={{
              pointerEvents: 'auto',
              cursor: zoom > 1 ? 'grab' : 'auto'
            }}
            onDoubleClick={() => setZoom(prev => prev > 1 ? 1 : 2)}
            draggable={false}
          />
        </div>
      ) : (
        // Fallback to thumbnail while full-size loads
        <div className="max-h-[70vh] max-w-[90%] aspect-auto">
          <ThumbnailViewer
            fileId={fileId}
            driveId={driveId}
            mediaType="image"
            thumbnails={thumbnails}
            size={bestAvailableSize}
            className="w-full h-full"
          />
        </div>
      )}
      
      {/* Image controls toolbar */}
      <div className="absolute bottom-0 left-0 right-0 p-3 flex justify-center space-x-4 bg-gradient-to-t from-black/60 to-transparent">
        {/* Zoom out button */}
        <Button 
          variant="ghost" 
          size="sm"
          onClick={handleZoomOut}
          disabled={zoom <= 0.5}
          className="text-gray-300 hover:text-white hover:bg-gray-800 h-10 w-10 p-0 rounded-full"
          aria-label="Zoom out"
        >
          <ZoomOut className="h-4 w-4" />
        </Button>
        
        {/* Rotate button */}
        <Button 
          variant="ghost" 
          size="sm"
          onClick={handleRotate}
          className="text-gray-300 hover:text-white hover:bg-gray-800 h-10 w-10 p-0 rounded-full"
          aria-label="Rotate image"
        >
          <RotateCw className="h-4 w-4" />
        </Button>
        
        {/* Zoom in button */}
        <Button 
          variant="ghost" 
          size="sm"
          onClick={handleZoomIn}
          disabled={zoom >= 3}
          className="text-gray-300 hover:text-white hover:bg-gray-800 h-10 w-10 p-0 rounded-full"
          aria-label="Zoom in"
        >
          <ZoomIn className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default EncryptedImageDisplay;