/**
 * @file src/components/cloud-drive/items/utils/motionProps.ts
 * @description Motion animation properties for file items
 * @version 1.1.0
 */

import type { MotionProps } from 'framer-motion';

interface ItemMotionProps {
  ref: React.RefCallback<HTMLDivElement>;
  onClick: (e: React.MouseEvent) => void;
  onDoubleClick: () => void;
  onTouchStart: (e: React.TouchEvent) => void;
  onTouchEnd: (e: React.TouchEvent) => void;
}

export const createMotionProps = (
  baseProps: ItemMotionProps,
  isMobile: boolean,
  view: 'grid' | 'list'
): MotionProps => {
  const commonProps = {
    ...baseProps,
    transition: { duration: 0.2 },
  };

  if (view === 'grid') {
    return {
      ...commonProps,
      whileHover: !isMobile ? { scale: 1.02 } : undefined,
      whileTap: { scale: 0.98 },
    };
  }

  // For list view, don't animate backgroundColor directly
  // Let CSS classes handle the hover state instead
  return {
    ...commonProps,
    whileTap: { scale: 0.99 },
  };
};