/**
 * @file src/components/encrypted-drive/animations/DecryptionAnimation.tsx
 * @description Enhanced text decryption animation component with better completion handling
 * @version 1.2.1
 * @updated 2025-03-21
 */

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface DecryptionAnimationProps {
  decryptedText: string;
  showEncryptedState?: boolean;
  duration?: number;
  className?: string;
  steps?: number;
  textClassName?: string;
  onComplete?: () => void; // New callback for animation completion
}

export const DecryptionAnimation: React.FC<DecryptionAnimationProps> = ({
  decryptedText = '',
  showEncryptedState = false,
  duration = 1000,
  className = '',
  steps = 4, // Number of decryption steps
  textClassName = '',
  onComplete // Callback when animation completes
}) => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const [decryptionStage, setDecryptionStage] = useState(0);
  const [instanceId] = useState(`da-${Math.random().toString(36).substring(2, 9)}`);

  // Generate encrypted text with random characters
  const generateEncryptedText = (stage: number): string => {
    const chars = '!@#$%^&*()_-+|}{[]?><,.;:ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const progressRatio = stage / steps;
    
    // Calculate how much of the text to keep from the original
    const keepLength = Math.floor(decryptedText.length * progressRatio);
    
    // Build the text with correct characters up to keepLength
    let result = decryptedText.substring(0, keepLength);
    
    // Add random characters for the rest
    for (let i = keepLength; i < decryptedText.length; i++) {
      const randomChar = chars.charAt(Math.floor(Math.random() * chars.length));
      result += randomChar;
    }
    
    return result;
  };

  // Reset animation when decrypted text changes or showEncryptedState changes
  useEffect(() => {
    logger.debug('DecryptionAnimation update', {
      component: 'DecryptionAnimation',
      instanceId,
      data: {
        textLength: decryptedText?.length || 0,
        isEncrypted: showEncryptedState,
        animationState: {
          complete: animationComplete,
          stage: decryptionStage
        }
      }
    });

    // Reset animation state when props change
    setAnimationComplete(false);
    setDecryptionStage(0);
    
    // If we're not showing encrypted state, skip to completed view
    if (!showEncryptedState) {
      setAnimationComplete(true);
      return;
    }
    
    // Run the animation with stages
    const stageInterval = duration / steps;
    const timers: NodeJS.Timeout[] = [];
    
    logger.debug('Starting decryption animation', {
      component: 'DecryptionAnimation',
      instanceId,
      data: {
        showEncryptedState,
        duration,
        steps,
        stageInterval
      }
    });
    
    for (let i = 1; i <= steps; i++) {
      const timer = setTimeout(() => {
        if (i === steps) {
          logger.debug('Animation complete', {
            component: 'DecryptionAnimation',
            instanceId
          });
          setAnimationComplete(true);
          
          // Call the onComplete callback if provided
          if (onComplete) {
            onComplete();
          }
        } else {
          setDecryptionStage(i);
          logger.debug(`Animation stage ${i}`, {
            component: 'DecryptionAnimation',
            instanceId
          });
        }
      }, stageInterval * i);
      
      timers.push(timer);
    }
    
    // Clear timers on cleanup
    return () => timers.forEach(timer => clearTimeout(timer));
  }, [decryptedText, showEncryptedState, duration, steps, instanceId]);

  // If we're asked to always show the decrypted state (showEncryptedState is false)
  // or if animation is complete, just show the fully decrypted text
  if (!showEncryptedState || animationComplete) {
    return (
      <span className={cn(className, textClassName)}>
        {decryptedText}
      </span>
    );
  }
  
  // Otherwise, show the animation in progress
  return (
    <div className={cn("relative select-none", className)}>
      <AnimatePresence mode="popLayout">
        <motion.span
          key={`stage-${decryptionStage}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
          className={cn("block", textClassName)}
        >
          {generateEncryptedText(decryptionStage)}
        </motion.span>
      </AnimatePresence>
    </div>
  );
};

export default DecryptionAnimation;