/**
 * @file src/routes/cloud-drive.routes.tsx
 * @description Updated cloud drive routing with proper provider hierarchy
 * @version 1.3.0
 */

import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { NavigationLoader } from '@/components/cloud-drive/loading/NavigationLoader';
import SharedWithMePage from '@/pages/cloud-drive/shared';
import FileDetailPage from '@/pages/cloud-drive/file';


import { NavigationProvider } from '@/context/NavigationContext';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';
 // Add this if needed for route-specific tooltips

const CloudDriveLazy = React.lazy(() => import('@/pages/cloud-drive'));
const FolderViewLazy = React.lazy(() => import('@/pages/cloud-drive/folder'));
const FavoritesLazy = React.lazy(() => import('@/pages/favorites'));
const MediaViewLazy = React.lazy(() => import('@/pages/cloud-drive/media'));

export const cloudDriveRoutes = {
  path: 'cloud-drive',
  element: (
    <ErrorBoundary>
      <NavigationProvider> {/* Now this has access to Router context */}
        <Suspense fallback={<NavigationLoader isLoading={true} />}>
          <Outlet />
        </Suspense>
      </NavigationProvider>
    </ErrorBoundary>
  ),
  children: [
    {
      index: true,
      element: <CloudDriveLazy />
    },
    {
      path: 'favorites',
      element: <FavoritesLazy />
    },
    {
      path: 'shared',
      element: <SharedWithMePage />
    },
    {
      path: 'folder/:folderId',
      element: <FolderViewLazy />
    },
    {
      path: 'file/:fileId',
      element: <FileDetailPage />
    },
    {
      path: 'media',
      element: <MediaViewLazy />
    },
    {
      path: '*',
      element: <Navigate to="/cloud-drive" replace />
    }
  ]
};