/**
 * @file src/lib/utils.ts
 * @description Utility functions for the application
 * @version 1.0.0
 * @created 2024-01-27
 */

import {clsx} from 'clsx';
import { twMerge } from 'tailwind-merge';

type ClassValue = string | number | boolean | undefined | null | { [key: string]: boolean };

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}