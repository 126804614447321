/**
 * @file src/components/services/ServiceAuthDialog.tsx
 * @description OAuth dialog for cloud service authentication
 * @version 1.0.0
 * @created 2024-12-01
 * @dependencies framer-motion, lucide-react
 */

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  X, 
  Loader2, 
  CheckCircle2, 
  AlertCircle,
  ChevronRight,
  KeyRound
} from 'lucide-react';
import { cn } from '@/utils/utils';
import { cloudServiceApi } from '@/services/cloud-service';
import type { CloudService } from '@/types/cloud-services';

interface ServiceAuthDialogProps {
  service: CloudService;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

type AuthStep = 'initial' | 'authorizing' | 'success' | 'error';

interface OAuthResult {
  success: boolean;
  error?: {
    message: string;
  };
}

// Extend cloudServiceApi type to include the missing method
interface ExtendedCloudServiceApi {
  getServices: () => Promise<CloudService[]>;
  initiateOAuth: (serviceType: string) => Promise<{ auth_url: string }>;
  disconnectService: (serviceId: string) => Promise<{ success: boolean }>;
  verifyOAuth: (code: string, state: string) => Promise<OAuthResult>;
}

const extendedCloudServiceApi = cloudServiceApi as unknown as ExtendedCloudServiceApi;

export const ServiceAuthDialog: React.FC<ServiceAuthDialogProps> = ({
  service,
  isOpen,
  onClose,
  onSuccess
}) => {
  const [step, setStep] = useState<AuthStep>('initial');
  const [error, setError] = useState<string>('');
  const [authWindow, setAuthWindow] = useState<Window | null>(null);

  // Handle OAuth flow
  useEffect(() => {
    // Listen for messages from OAuth popup
    const handleMessage = async (event: MessageEvent) => {
      if (event?.origin !== window.location.origin) return;

      if (event.data?.type === 'oauth_callback') {
        try {
          if (event.data.error) {
            setStep('error');
            setError(event.data.error);
            return;
          }

          const { code, state } = event.data;
          const result = await extendedCloudServiceApi.verifyOAuth(code, state);

          if (result.success) {
            setStep('success');
            setTimeout(() => {
              onSuccess();
              onClose();
            }, 1500);
          } else {
            throw new Error(result.error?.message || 'Authentication failed');
          }
        } catch (err) {
          setStep('error');
          setError((err as Error).message);
        } finally {
          authWindow?.close();
          setAuthWindow(null);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [authWindow, onSuccess, onClose]);

  const handleAuth = async () => {
    try {
      setStep('authorizing');

      const { auth_url } = await cloudServiceApi.initiateOAuth(service.type);

      // Open OAuth popup
      const width = 600;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;

      const newWindow = window.open(
        auth_url,
        'OAuth',
        `width=${width},height=${height},left=${left},top=${top}`
      );

      setAuthWindow(newWindow);
    } catch (err) {
      setStep('error');
      setError((err as Error).message);
    }
  };
  
  
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const dialogVariants = {
    hidden: { 
      opacity: 0, 
      scale: 0.95,
      y: 20 
    },
    visible: { 
      opacity: 1, 
      scale: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 25,
        stiffness: 300
      }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={overlayVariants}
          className={cn(
            "fixed inset-0 z-50",
            "bg-black/50 backdrop-blur-sm",
            "flex items-center justify-center",
            "p-4"
          )}
          onClick={onClose}
        >
          {/* Dialog */}
          <motion.div
            variants={dialogVariants}
            className={cn(
              "w-full max-w-md",
              "bg-white dark:bg-gray-900",
              "rounded-xl shadow-xl",
              "border border-gray-200 dark:border-gray-800"
            )}
            onClick={e => e.stopPropagation()}
          >
            {/* Header */}
            <div className={cn(
              "flex items-center justify-between",
              "px-6 py-4 border-b",
              "border-gray-200 dark:border-gray-800"
            )}>
              <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                Connect {service.name}
              </h2>
              <button
                onClick={onClose}
                className={cn(
                  "p-2 rounded-lg text-gray-500 dark:text-gray-400",
                  "hover:bg-gray-100 dark:hover:bg-gray-800",
                  "transition-colors duration-200"
                )}
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {/* Content */}
            <div className="p-6">
              {step === 'initial' && (
                <div className="space-y-4">
                  <div className={cn(
                    "p-4 rounded-lg",
                    "bg-blue-50 dark:bg-blue-900/20",
                    "text-blue-600 dark:text-blue-400"
                  )}>
                    <p className="text-sm">
                      You'll be redirected to {service.name} to authorize access to your account.
                      This allows BigMIND to:
                    </p>
                  </div>
                  
                  {/* Permissions List */}
                  <div className="space-y-3">
                    {[
                      'View and download your files',
                      'Monitor changes to your files',
                      'Create backups of your data'
                    ].map((permission, index) => (
                      <div 
                        key={index}
                        className="flex items-center space-x-3 text-sm"
                      >
                        <ChevronRight className="w-4 h-4 text-gray-400" />
                        <span className="text-gray-600 dark:text-gray-300">
                          {permission}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {step === 'authorizing' && (
                <div className="py-8 text-center space-y-4">
                  <Loader2 className="w-12 h-12 mx-auto animate-spin text-blue-500" />
                  <p className="text-gray-600 dark:text-gray-300">
                    Connecting to {service.name}...
                  </p>
                </div>
              )}

              {step === 'success' && (
                <div className="py-8 text-center space-y-4">
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: 'spring', damping: 20 }}
                  >
                    <CheckCircle2 className="w-12 h-12 mx-auto text-emerald-500" />
                  </motion.div>
                  <p className="text-gray-600 dark:text-gray-300">
                    Successfully connected to {service.name}
                  </p>
                </div>
              )}

              {step === 'error' && (
                <div className="py-8 text-center space-y-4">
                  <AlertCircle className="w-12 h-12 mx-auto text-red-500" />
                  <div>
                    <p className="text-gray-900 dark:text-gray-100 font-medium">
                      Connection Failed
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                      {error}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Footer */}
            {step === 'initial' && (
              <div className={cn(
                "px-6 py-4 border-t",
                "border-gray-200 dark:border-gray-800",
                "flex justify-end space-x-3"
              )}>
                <button
                  onClick={onClose}
                  className={cn(
                    "px-4 py-2 rounded-lg",
                    "text-gray-600 dark:text-gray-300",
                    "hover:bg-gray-100 dark:hover:bg-gray-800",
                    "transition-colors duration-200"
                  )}
                >
                  Cancel
                </button>
                <button
                  onClick={handleAuth}
                  className={cn(
                    "px-4 py-2 rounded-lg",
                    "bg-blue-500 hover:bg-blue-600",
                    "text-white",
                    "flex items-center space-x-2",
                    "transition-colors duration-200"
                  )}
                >
                  <KeyRound className="w-4 h-4" />
                  <span>Authorize Access</span>
                </button>
              </div>
            )}

            {step === 'error' && (
              <div className={cn(
                "px-6 py-4 border-t",
                "border-gray-200 dark:border-gray-800",
                "flex justify-end"
              )}>
                <button
                  onClick={() => setStep('initial')}
                  className={cn(
                    "px-4 py-2 rounded-lg",
                    "bg-blue-500 hover:bg-blue-600",
                    "text-white",
                    "transition-colors duration-200"
                  )}
                >
                  Try Again
                </button>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};