/**
 * @file src/components/cloud-drive/navigation/BreadcrumbNavigation.tsx
 * @description Enhanced breadcrumb navigation with device/drive awareness
 * @version 2.3.0
 */

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import {ChevronRight, ChevronLeft, Home} from 'lucide-react';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import { useNavigation } from '@/context/NavigationContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { logger } from '@/utils/logger';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import type { BreadcrumbSegment } from '@/types/navigation.types';

interface BreadcrumbNavigationProps {
  className?: string;
}

export const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = ({
  className
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { devices, activeDriveId, primaryDriveId } = useRootDrive();
  
  const {
    currentFolderId,
    breadcrumbs,
    isNavigating,
    navigateToFolder,
    navigateToBreadcrumb
  } = useNavigation();

  // Get device and drive info
  const { device, drive } = useMemo(() => {
    if (!activeDriveId && !primaryDriveId) return { device: null, drive: null };

    const effectiveDriveId = activeDriveId || primaryDriveId;
    
    for (const dev of devices) {
      const foundDrive = dev.drives.find((d: any) => d.id === effectiveDriveId);
      if (foundDrive) {
        return { device: dev, drive: foundDrive };
      }
    }

    return { device: null, drive: null };
  }, [devices, activeDriveId, primaryDriveId]);

  // Enhanced breadcrumb processing
  const processedBreadcrumbs = useMemo(() => {
    logger.debug('Processing breadcrumbs', {
      component: 'BreadcrumbNavigation',
      data: {
        breadcrumbCount: breadcrumbs.length,
        deviceName: device?.name,
        driveName: drive?.name
      }
    });

    if (!breadcrumbs.length) return [];

    // Filter out any duplicates while maintaining order
    return breadcrumbs.filter((item, index, self) => 
      index === self.findIndex(t => t.id === item.id)
    );
  }, [breadcrumbs, device, drive]);

  // Get parent info for mobile view
  const parentInfo = useMemo(() => {
    if (!processedBreadcrumbs.length) return null;
    const parentIndex = processedBreadcrumbs.length - 2;
    return parentIndex >= 0 ? processedBreadcrumbs[parentIndex] : null;
  }, [processedBreadcrumbs]);

  // Get current location info
  const currentInfo = useMemo(() => {
    return processedBreadcrumbs.length > 0 
      ? processedBreadcrumbs[processedBreadcrumbs.length - 1]
      : null;
  }, [processedBreadcrumbs]);

  // Handle touch navigation
  const handleTouchBack = () => {
    if (!currentFolderId) return;
    
    const parentIndex = processedBreadcrumbs.length - 2;
    const parentId = parentIndex >= 0 ? processedBreadcrumbs[parentIndex].id : null;
    navigateToFolder(parentId);
  };

  // Format segment name based on type
  const formatSegmentName = (segment: BreadcrumbSegment): string => {
    switch (segment.type) {
      case 'device':
        return device?.name || t('common.device');
      case 'drive':
        return drive?.name || t('cloud_drive.breadcrumb.root');
      default:
        return segment.name;
    }
  };

  // Handle navigation
  const handleNavigation = async (item: BreadcrumbSegment) => {
    logger.debug('Breadcrumb navigation', {
      component: 'BreadcrumbNavigation',
      data: {
        segmentId: item.id,
        segmentType: item.type,
        currentId: currentFolderId
      }
    });

    if (item?.type === 'folder') {
      await navigateToBreadcrumb(item.id);
    } else {
      await navigateToFolder(null); // Navigate to root for device/drive segments
    }
  };

  // Mobile View
  if (isMobile) {
    return (
      <motion.nav
        aria-label="breadcrumb"
        className={cn(
          "flex flex-col px-4 py-2 min-h-[64px]",
          "bg-white/80 dark:bg-gray-900/80",
          "backdrop-blur-sm border-b",
          "border-gray-100 dark:border-gray-800",
          isNavigating && "opacity-70 pointer-events-none",
          className
        )}
        initial={false}
      >
        <div className="flex items-center gap-2">
          {currentFolderId && (
            <Button
              variant="ghost"
              size="icon"
              onClick={handleTouchBack}
              className="h-10 w-10"
            >
              <ChevronLeft className="h-5 w-5" />
            </Button>
          )}

          <div className="flex flex-col min-w-0 flex-1">
            {parentInfo && (
              <span className="text-xs text-gray-500 dark:text-gray-400 truncate">
                {formatSegmentName(parentInfo)}
              </span>
            )}
            
            <span className="text-base font-medium truncate">
              {currentInfo ? formatSegmentName(currentInfo) : drive?.name || t('cloud_drive.breadcrumb.root')}
            </span>
          </div>

          {isNavigating && (
            <div className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
          )}
        </div>
      </motion.nav>
    );
  }

  // Desktop View
  return (
    <nav
      aria-label="breadcrumb"
      className={cn(
        "flex items-center space-x-2 min-h-[48px] px-4",
        "bg-white/80 dark:bg-gray-900/80",
        "backdrop-blur-sm border-b",
        "border-gray-100 dark:border-gray-800",
        isNavigating && "opacity-70 pointer-events-none",
        className
      )}
    >
      {/* Home button with device/drive name */}
      <Button
        variant="ghost"
        size="sm"
        onClick={() => navigateToFolder(null)}
        className={cn(
          "flex-shrink-0",
          !currentFolderId && "bg-gray-100 dark:bg-gray-800"
        )}
      >
        <Home className="h-4 w-4 mr-2" />
        {drive?.name || device?.name || t('cloud_drive.breadcrumb.root')}
      </Button>

      {/* Breadcrumb segments */}
      <AnimatePresence mode="popLayout">
        {processedBreadcrumbs.map((item) => (
          <React.Fragment key={item.id}>
            <ChevronRight className="h-4 w-4 flex-shrink-0 text-gray-400" />
            
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              className="flex-shrink-0"
            >
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleNavigation(item)}
                className={cn(
                  "h-8",
                  item.id === currentFolderId && "bg-gray-100 dark:bg-gray-800",
                  "whitespace-nowrap"
                )}
              >
                {formatSegmentName(item)}
              </Button>
            </motion.div>
          </React.Fragment>
        ))}
      </AnimatePresence>

      {/* Loading indicator */}
      {isNavigating && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="ml-auto"
        >
          <div className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
        </motion.div>
      )}
    </nav>
  );
};

export default BreadcrumbNavigation;