/**
 * @file src/components/ui/toast.tsx
 * @description Toast notification component
 * @version 1.0.0
 * @created 2024-12-07
 * @dependencies framer-motion, react
 */

import React, { createContext, useContext, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle, XCircle, AlertCircle, X } from 'lucide-react';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

type ToastType = 'success' | 'error' | 'info' | 'warning';  // Added warning type

interface Toast {
  id: string;
  message: string;
  type: ToastType;
}

interface ToastContextType {
  showToast: (message: string, type: ToastType) => void;
  clearToasts: () => void;  // Add this new method
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const showToast = useCallback((message: string, type: ToastType) => {
    // Validate type to prevent undefined errors
    const validType = ['success', 'error', 'info', 'warning'].includes(type) 
      ? type 
      : 'info';

    const id = Math.random().toString(36).slice(2);
    setToasts(prev => [...prev, { id, message, type: validType }]);
    
    setTimeout(() => {
      setToasts(prev => prev.filter((toast: any) => toast.id !== id));
    }, 5000);
  }, []);


  const clearToasts = useCallback(() => {
    logger.debug('Clearing all toasts', {
      component: 'ToastProvider',
      data:{
        toastCount: toasts.length
      }
    });
    
    setToasts([]);
  }, [toasts.length]);

  

  return (
    <ToastContext.Provider value={{ showToast,clearToasts  }}>
      {children}
      <div className="fixed bottom-4 right-4 z-[100] flex flex-col gap-2">
        <AnimatePresence mode="sync">
          {toasts.map((toast: any) => (
            <Toast key={toast.id} {...toast} onClose={() => {
              setToasts(prev => prev.filter((t: any) => t.id !== toast.id));
            }} />
          ))}
        </AnimatePresence>
      </div>
    </ToastContext.Provider>
  );
};

const Toast: React.FC<Toast & { onClose: () => void }> = ({ 
  message, 
  type, 
  onClose 
}) => {
  const icons = {
    success: CheckCircle,
    error: XCircle,
    info: AlertCircle,
    warning: AlertCircle  // Added warning
  };

  const styles = {
    success: {
      bg: 'bg-emerald-50 dark:bg-emerald-900/20',
      border: 'border-emerald-100 dark:border-emerald-800',
      text: 'text-emerald-800 dark:text-emerald-200',
      icon: 'text-emerald-500 dark:text-emerald-400'
    },
    error: {
      bg: 'bg-red-50 dark:bg-red-900/20',
      border: 'border-red-100 dark:border-red-800',
      text: 'text-red-800 dark:text-red-200',
      icon: 'text-red-500 dark:text-red-400'
    },
    info: {
      bg: 'bg-blue-50 dark:bg-blue-900/20',
      border: 'border-blue-100 dark:border-blue-800',
      text: 'text-blue-800 dark:text-blue-200',
      icon: 'text-blue-500 dark:text-blue-400'
    },
    warning: {
      bg: 'bg-yellow-50 dark:bg-yellow-900/20',
      border: 'border-yellow-100 dark:border-yellow-800',
      text: 'text-yellow-800 dark:text-yellow-200',
      icon: 'text-yellow-500 dark:text-yellow-400'
    }   
  };

  // Ensure we have a valid type
  const validType = (type in styles) ? type : 'info';
  const Icon = icons[validType];
  const style = styles[validType];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20, scale: 0.95 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: 20, scale: 0.95 }}
      className={cn(
        "flex items-center gap-3 p-4 rounded-lg shadow-lg",
        "border backdrop-blur-sm",
        "min-w-[300px] max-w-[500px]",
        style.bg,
        style.border
      )}
    >
      <Icon className={cn("w-5 h-5", style.icon)} />
      <span className={cn("flex-1 text-sm font-medium", style.text)}>
        {message}
      </span>
      <button
        onClick={onClose}
        className={cn(
          "p-1 rounded-full",
          "hover:bg-gray-900/5 dark:hover:bg-gray-100/5",
          "transition-colors duration-200",
          style.text
        )}
      >
        <X className="w-4 h-4" />
      </button>
    </motion.div>
  );
};