/**
 * @file src/routes/account.routes.tsx
 * @description Account routes configuration with lazy loading
 * @version 1.1.0
 * @created 2024-12-07
 * @updated 2025-02-25
 */

import { Suspense, lazy } from 'react';

// Lazy load account page components
const AccountSettings = lazy(() => import('@/pages/account/AccountSettings'));
// const ManageAdmins = lazy(() => import('@/pages/account/ManageAdmins'));
// const BillingReports = lazy(() => import('@/pages/account/BillingReports'));
// const ContactUs = lazy(() => import('@/pages/account/ContactUs'));
// const ReportIssue = lazy(() => import('@/pages/account/ReportIssue'));
// const WhatsNew = lazy(() => import('@/pages/account/WhatsNew'));

// Loader component for account pages
const AccountPageLoader = () => (
  <div className="flex items-center justify-center min-h-[60vh]">
    <div className="flex flex-col items-center space-y-2">
      <div className="h-8 w-8 rounded-full border-2 border-primary/30 border-t-primary animate-spin"></div>
      <p className="text-sm text-muted-foreground">Loading account information...</p>
    </div>
  </div>
);

export const accountRoutes = {
  path: 'account',
  children: [
    {
      path: 'settings',
      element: (
        <Suspense fallback={<AccountPageLoader />}>
          <AccountSettings />
        </Suspense>
      )
    },
    // {
    //   path: 'admins',
    //   element: (
    //     <Suspense fallback={<AccountPageLoader />}>
    //       <ManageAdmins />
    //     </Suspense>
    //   )
    // },
    // {
    //   path: 'billing',
    //   element: (
    //     <Suspense fallback={<AccountPageLoader />}>
    //       <BillingReports />
    //     </Suspense>
    //   )
    // },
    // {
    //   path: 'contact',
    //   element: (
    //     <Suspense fallback={<AccountPageLoader />}>
    //       <ContactUs />
    //     </Suspense>
    //   )
    // },
    // {
    //   path: 'report',
    //   element: (
    //     <Suspense fallback={<AccountPageLoader />}>
    //       <ReportIssue />
    //     </Suspense>
    //   )
    // },
    // {
    //   path: 'whatsnew',
    //   element: (
    //     <Suspense fallback={<AccountPageLoader />}>
    //       <WhatsNew />
    //     </Suspense>
    //   )
    // }
  ]
};