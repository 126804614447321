/**
 * @file src/components/encrypted-drive/media/EncryptedVideoPlayer.tsx
 * @description Dedicated component for playing encrypted videos with custom controls
 * @version 1.0.0
 * @created 2025-03-30
 */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import { 
  Play, Pause, Volume2, VolumeX, Maximize, Minimize,
  SkipBack, SkipForward
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Slider } from '@/components/ui/slider';
import { logger } from '@/utils/logger';
import { cn } from '@/utils/utils';

interface EncryptedVideoPlayerProps {
  videoUrl: string | null;
  isLoading: boolean;
  onPlayStateChange?: (isPlaying: boolean) => void;
  className?: string;
}

export const EncryptedVideoPlayer: React.FC<EncryptedVideoPlayerProps> = ({
  videoUrl,
  isLoading,
  onPlayStateChange,
  className
}) => {
  // Video player state
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [isInteractingWithControls, setIsInteractingWithControls] = useState(false);
  
  // Refs
  const videoRef = useRef<HTMLVideoElement>(null);
  const controlsHideTimerRef = useRef<NodeJS.Timeout | null>(null);
  
  // Clean up any timers on unmount
  useEffect(() => {
    return () => {
      if (controlsHideTimerRef.current) {
        clearTimeout(controlsHideTimerRef.current);
      }
    };
  }, []);
  
  // Handle container click/tap to toggle controls - optimized for both mobile and desktop
  const handleContainerClick = useCallback(() => {
    // Toggle video controls visibility
    // If controls are already shown, don't toggle if user is interacting with them
    if (showControls && isInteractingWithControls) {
      return;
    }
    
    setShowControls(prev => !prev);
    
    // Clear any existing hide timer
    if (controlsHideTimerRef.current) {
      clearTimeout(controlsHideTimerRef.current);
      controlsHideTimerRef.current = null;
    }
    
    // Auto-hide controls after inactivity - but only if we're showing them
    if (!showControls) {
      controlsHideTimerRef.current = setTimeout(() => {
        // Only hide if user is not interacting with controls
        if (!isInteractingWithControls) {
          setShowControls(false);
        }
      }, 4000); // 4 seconds - good balance for mobile and desktop
    }
  }, [showControls, isInteractingWithControls]);
  
  // First define togglePlay before using it in other handlers
  // Toggle play/pause
  const togglePlay = useCallback(() => {
    if (!videoRef.current) return;
    
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      // Handle autoplay restrictions with user feedback
      videoRef.current.play()
        .catch(error => {
          logger.warn('Autoplay prevented by browser policy', {
            component: 'EncryptedVideoPlayer',
            error
          });
          // Keep play state accurate
          setIsPlaying(false);
        });
    }
    
    const newPlayState = !isPlaying;
    setIsPlaying(newPlayState);
    
    // Notify parent if needed
    if (onPlayStateChange) {
      onPlayStateChange(newPlayState);
    }
  }, [isPlaying, onPlayStateChange]);

  // Additional touch event handlers specifically for mobile
  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    // Save the initial touch position to determine if this is a tap or a swipe
    const touchX = e.touches[0].clientX;
    const touchY = e.touches[0].clientY;
    
    (videoRef.current as any).__touchStartX = touchX;
    (videoRef.current as any).__touchStartY = touchY;
    
    // Show controls immediately on touch
    setShowControls(true);
    
    // Clear any hide timer
    if (controlsHideTimerRef.current) {
      clearTimeout(controlsHideTimerRef.current);
      controlsHideTimerRef.current = null;
    }
  }, []);
  
  const handleTouchEnd = useCallback((e: React.TouchEvent) => {
    if (!videoRef.current) return;
    
    // Get the stored start position
    const startX = (videoRef.current as any).__touchStartX;
    const startY = (videoRef.current as any).__touchStartY;
    
    if (startX === undefined || startY === undefined) return;
    
    // Calculate movement
    const endX = e.changedTouches[0].clientX;
    const endY = e.changedTouches[0].clientY;
    const moveX = Math.abs(endX - startX);
    const moveY = Math.abs(endY - startY);
    
    // If it was just a tap (not a swipe), toggle play/pause
    // Use a small threshold to differentiate between taps and swipes
    if (moveX < 10 && moveY < 10) {
      togglePlay();
    }
    
    // Set timer to hide controls after touch interaction
    controlsHideTimerRef.current = setTimeout(() => {
      if (!isInteractingWithControls) {
        setShowControls(false);
      }
    }, 4000);
  }, [togglePlay, isInteractingWithControls]);

  // Store previous volume for proper mute/unmute
  const [previousVolume, setPreviousVolume] = useState<number>(1);
  
  // Toggle mute
  const toggleMute = useCallback(() => {
    if (!videoRef.current) return;
    
    const newMuteState = !isMuted;
    videoRef.current.muted = newMuteState;
    setIsMuted(newMuteState);
    
    // Store previous volume if muting
    if (newMuteState) {
      setPreviousVolume(volume > 0 ? volume : 0.5); // Default to 0.5 if volume was 0
      videoRef.current.volume = 0;
      setVolume(0);
    } else {
      // Restore previous volume when unmuting
      const volumeToRestore = previousVolume > 0 ? previousVolume : 0.5;
      videoRef.current.volume = volumeToRestore;
      setVolume(volumeToRestore);
    }
  }, [isMuted, volume, previousVolume]);

  // Handle volume change
  const handleVolumeChange = useCallback((values: number[]) => {
    if (!videoRef.current || !values.length) return;
    
    const newVolume = values[0];
    videoRef.current.volume = newVolume;
    setVolume(newVolume);
    
    // Update mute state based on volume
    if (newVolume === 0) {
      setIsMuted(true);
    } else if (isMuted) {
      setIsMuted(false);
      // Update the previous volume for future mute/unmute actions
      setPreviousVolume(newVolume);
    }
  }, [isMuted, setPreviousVolume]);

  // Handle seek with better reliability
  const handleSeek = useCallback((values: number[]) => {
    if (!videoRef.current || !values.length) return;
    
    // Flag that user is interacting with seek control
    setIsInteractingWithControls(true);
    
    // Clear any existing hide timer
    if (controlsHideTimerRef.current) {
      clearTimeout(controlsHideTimerRef.current);
      controlsHideTimerRef.current = null;
    }
    
    // Get the actual duration from the video element if possible
    // This is more reliable than using the state which might not be updated yet
    const actualDuration = videoRef.current.duration;
    const videoDuration = isFinite(actualDuration) && actualDuration > 0 
      ? actualDuration 
      : (isFinite(duration) && duration > 0 ? duration : 100);
    
    // Make sure to update duration state if needed
    if (isFinite(actualDuration) && actualDuration > 0 && actualDuration !== duration) {
      setDuration(actualDuration);
    }
    
    // Ensure the seek value is within the valid range of the video
    const seekTime = Math.max(0, Math.min(values[0], videoDuration));
    
    try {
      // Update local state immediately for responsive UI
      setCurrentTime(seekTime);
      
      // Use a direct update to the video element
      if (videoRef.current) {
        videoRef.current.currentTime = seekTime;
        
        // Only log meaningful seeks
        if (seekTime > 1 && videoDuration > 1) {
          logger.debug('Video seek', {
            component: 'EncryptedVideoPlayer',
            data: { 
              requestedTime: seekTime, 
              actualDuration,
              stateDuration: duration,
              videoDuration
            }
          });
        }
      }
    } catch (error) {
      logger.warn('Error seeking video:', {
        component: 'EncryptedVideoPlayer',
        error,
        data: { 
          requestedTime: seekTime, 
          videoDuration, 
          actualDuration, 
          actualCurrentTime: videoRef.current?.currentTime || 0
        }
      });
    }
  }, [duration]);

  // Skip forward 10 seconds - improved with duration detection
  const skipForward = useCallback(() => {
    if (!videoRef.current) return;
    
    // Use actual video duration if available
    const actualDuration = videoRef.current.duration;
    const videoDuration = isFinite(actualDuration) && actualDuration > 0 
      ? actualDuration 
      : (isFinite(duration) && duration > 0 ? duration : 100);
    
    // Set new time with bounds checking
    const newTime = Math.min(videoRef.current.currentTime + 10, videoDuration);
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    
    // Show controls for 3 seconds after skipping
    setShowControls(true);
    
    // Clear existing timer and set a new one
    if (controlsHideTimerRef.current) {
      clearTimeout(controlsHideTimerRef.current);
    }
    
    controlsHideTimerRef.current = setTimeout(() => {
      if (!isInteractingWithControls) {
        setShowControls(false);
      }
    }, 3000);
  }, [duration, isInteractingWithControls]);

  // Skip backward 10 seconds - improved with controls handling
  const skipBackward = useCallback(() => {
    if (!videoRef.current) return;
    
    // Set new time with bounds checking
    const newTime = Math.max(videoRef.current.currentTime - 10, 0);
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
    
    // Show controls for 3 seconds after skipping
    setShowControls(true);
    
    // Clear existing timer and set a new one
    if (controlsHideTimerRef.current) {
      clearTimeout(controlsHideTimerRef.current);
    }
    
    controlsHideTimerRef.current = setTimeout(() => {
      if (!isInteractingWithControls) {
        setShowControls(false);
      }
    }, 3000);
  }, [isInteractingWithControls]);

  // Toggle fullscreen
  const toggleFullscreen = useCallback(() => {
    if (!videoRef.current) return;
    
    if (!document.fullscreenElement) {
      videoRef.current.requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch(err => {
          logger.error('Error attempting to enable fullscreen mode:', err);
        });
    } else {
      document.exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch(err => {
          logger.error('Error attempting to exit fullscreen mode:', err);
        });
    }
  }, []);

  // Format time helper
  const formatTime = (timeInSeconds: number): string => {
    // Handle infinity, NaN, or negative values gracefully
    if (!isFinite(timeInSeconds) || timeInSeconds < 0) {
      return "0:00";
    }
    
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Return early if no video URL
  if (!videoUrl) {
    return null;
  }
  
  // Add effects for player behavior
  
  // Effect 1: Clear loading indicators when video starts playing
  useEffect(() => {
    // When the video is playing, make sure we tell the parent that loading is complete
    if (isPlaying && onPlayStateChange) {
      onPlayStateChange(true);
    }
  }, [isPlaying, onPlayStateChange]);
  
  // Effect 2: Auto-play when video is available and not loading
  useEffect(() => {
    // If video URL is available and we're not loading, try to auto-play
    if (videoUrl && !isLoading && videoRef.current) {
      // Try to auto-play but don't worry if it fails (browser policies)
      videoRef.current.play().catch(() => {
        // Browser may prevent autoplay - that's okay
      });
    }
  }, [videoUrl, isLoading]);
  
  // Effect 3: Make sure we're using the correct duration
  useEffect(() => {
    const checkDuration = () => {
      if (videoRef.current) {
        const videoDuration = videoRef.current.duration;
        
        if (isFinite(videoDuration) && videoDuration > 0 && videoDuration !== duration) {
          setDuration(videoDuration);
          logger.debug('Updated video duration', {
            component: 'EncryptedVideoPlayer',
            data: { videoDuration }
          });
        }
      }
    };
    
    // Check immediately
    checkDuration();
    
    // Then check again after the video has loaded more data
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadeddata', checkDuration);
      videoElement.addEventListener('durationchange', checkDuration);
      
      return () => {
        videoElement.removeEventListener('loadeddata', checkDuration);
        videoElement.removeEventListener('durationchange', checkDuration);
      };
    }
  }, [videoUrl, duration]);

  return (
    <div 
      className={cn("relative w-full h-full flex items-center justify-center", className)}
      onClick={handleContainerClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <video
        ref={videoRef}
        src={videoUrl}
        className="max-h-[80vh] max-w-full w-full h-full object-contain"
        playsInline // Better mobile support
        controls={false} // Using custom controls
        preload="auto" // Preload video data
        onLoadedMetadata={(e) => {
          if (videoRef.current) {
            // Set initial duration, even if it might be 0 to start
            const initialDuration = isFinite(videoRef.current.duration) ? videoRef.current.duration : 0;
            setDuration(initialDuration);
            
            // Check duration again after a brief delay - some browsers need time to determine duration
            setTimeout(() => {
              if (videoRef.current) {
                // Re-check duration after a brief delay
                const videoDuration = isFinite(videoRef.current.duration) ? videoRef.current.duration : 0;
                
                if (videoDuration > 0 && videoDuration !== initialDuration) {
                  setDuration(videoDuration);
                  
                  logger.debug('Video duration updated', {
                    component: 'EncryptedVideoPlayer',
                    data: {
                      initialDuration,
                      updatedDuration: videoDuration
                    }
                  });
                }
                
                logger.debug('Video metadata loaded', {
                  component: 'EncryptedVideoPlayer',
                  data: {
                    duration: videoDuration,
                    videoWidth: videoRef.current.videoWidth,
                    videoHeight: videoRef.current.videoHeight,
                    readyState: videoRef.current.readyState
                  }
                });
              }
            }, 500); // Increased delay to better ensure duration is properly loaded
          }
        }}
        onCanPlay={() => {
          // Using a ref to ensure we only log this once
          if (!videoRef.current || (videoRef.current as any).__canPlayFired) {
            return;
          }
          
          // Mark that we've already handled the canplay event
          (videoRef.current as any).__canPlayFired = true;
          
          // Check if duration is now available
          const currentDuration = videoRef.current.duration;
          if (isFinite(currentDuration) && currentDuration > 0 && currentDuration !== duration) {
            setDuration(currentDuration);
          }
          
          // Notify that video can play now (only once)
          logger.debug('Video can play now', {
            component: 'EncryptedVideoPlayer',
            data: {
              currentDuration,
              hasValidDuration: isFinite(currentDuration) && currentDuration > 0
            }
          });
          
          // Clear any loading indicators
          if (onPlayStateChange) {
            onPlayStateChange(true);
          }
          
          // If video is cached, try to auto-play
          videoRef.current.play().catch(err => {
            // Autoplay might be blocked - that's okay
            logger.debug('Autoplay prevented by browser policy', {
              component: 'EncryptedVideoPlayer'
            });
          });
        }}
        onTimeUpdate={() => {
          if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime);
          }
        }}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={() => setIsPlaying(false)}
        onError={(e) => {
          // Handle video playback errors
          const errorCode = (e.target as HTMLVideoElement).error?.code;
          const errorMessage = (e.target as HTMLVideoElement).error?.message;
          
          logger.error('Video playback error', {
            component: 'EncryptedVideoPlayer',
            data: {
              errorCode,
              errorMessage,
              src: videoUrl?.substring(0, 30) + '...'
            }
          });
        }}
      />
      
      {/* Video tap to show play/pause button overlay - optimized for mobile */}
      <div 
        className="absolute inset-0 z-20 flex items-center justify-center opacity-0 hover:opacity-100 active:opacity-100 transition-opacity duration-200"
        onClick={(e) => {
          e.stopPropagation();
          togglePlay();
        }}
      >
        <div className="bg-black/40 rounded-full p-3 touch-action-manipulation">
          {isPlaying ? 
            <Pause size={36} className="text-white drop-shadow-lg" /> : 
            <Play size={36} className="text-white drop-shadow-lg" />
          }
        </div>
      </div>
      
      {/* Video Controls Overlay - always rendered but visibility controlled by opacity */}
      <div
        className={`absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/80 to-transparent pt-6 pb-4 px-3 sm:p-4 z-30 transition-opacity duration-300 ${
          showControls ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onMouseEnter={() => {
          setIsInteractingWithControls(true);
          // Cancel any pending hide timer
          if (controlsHideTimerRef.current) {
            clearTimeout(controlsHideTimerRef.current);
            controlsHideTimerRef.current = null;
          }
        }}
        onMouseLeave={() => {
          setIsInteractingWithControls(false);
          // Set up a new hide timer when user leaves controls
          if (showControls) {
            controlsHideTimerRef.current = setTimeout(() => {
              setShowControls(false);
            }, 3000);
          }
        }}
        onClick={(e) => {
          // Prevent clicks from bubbling to the container and toggling controls
          e.stopPropagation();
        }}
      >
        {/* Progress bar - improved for touch */}
        <div className="flex items-center justify-between mb-3">
          <div className="text-white text-xs sm:text-sm mr-2">{formatTime(currentTime)}</div>
          <Slider 
            value={[isFinite(currentTime) ? currentTime : 0]} 
            max={isFinite(duration) && duration > 0 ? duration : 100} // Ensure max is always valid
            step={0.1} 
            onValueChange={handleSeek}
            onValueCommit={() => {
              // When seek complete, restart the hide timer
              if (controlsHideTimerRef.current) {
                clearTimeout(controlsHideTimerRef.current);
              }
              controlsHideTimerRef.current = setTimeout(() => {
                if (!isInteractingWithControls) {
                  setShowControls(false);
                }
              }, 3000);
            }}
            className="flex-1 mx-2 touch-action-manipulation" 
          />
          <div className="text-white text-xs sm:text-sm ml-2">{formatTime(duration)}</div>
        </div>
        
        {/* Enhanced mobile-friendly controls layout */}
        <div className="flex flex-row items-center justify-between mt-1 sm:mt-2">
          {/* Left side controls */}
          <div className="flex items-center space-x-2 sm:space-x-4">
            {/* Playback controls - order optimized for thumb reach */}
            <Button 
              onClick={togglePlay} 
              variant="ghost" 
              size="sm" 
              className="text-white h-11 w-11 sm:h-12 sm:w-12 p-0 rounded-full bg-white/10 touch-action-manipulation"
            >
              {isPlaying ? 
                <Pause size={22} className="sm:h-6 sm:w-6" /> : 
                <Play size={22} className="sm:h-6 sm:w-6" />
              }
            </Button>
            
            {/* Skip buttons - adjusted size for mobile */}
            <Button 
              onClick={skipBackward} 
              variant="ghost" 
              size="sm" 
              className="text-white h-9 w-9 sm:h-10 sm:w-10 p-0 rounded-full touch-action-manipulation"
            >
              <SkipBack size={16} className="sm:h-5 sm:w-5" />
            </Button>
            
            <Button 
              onClick={skipForward} 
              variant="ghost" 
              size="sm" 
              className="text-white h-9 w-9 sm:h-10 sm:w-10 p-0 rounded-full touch-action-manipulation"
            >
              <SkipForward size={16} className="sm:h-5 sm:w-5" />
            </Button>
          </div>
          
          {/* Right side controls */}
          <div className="flex items-center space-x-2">
            {/* Volume toggle - mobile optimized position */}
            <Button 
              onClick={toggleMute} 
              variant="ghost" 
              size="sm" 
              className="text-white h-9 w-9 sm:h-10 sm:w-10 p-0 rounded-full touch-action-manipulation"
            >
              {isMuted ? 
                <VolumeX size={16} className="sm:h-5 sm:w-5" /> : 
                <Volume2 size={16} className="sm:h-5 sm:w-5" />
              }
            </Button>
            
            {/* Only show volume slider on larger screens */}
            <div className="hidden sm:block">
              <Slider 
                value={[isMuted ? 0 : volume]} 
                max={1} 
                step={0.01} 
                onValueChange={handleVolumeChange} 
                className="w-20 touch-action-manipulation" 
              />
            </div>
            
            {/* Fullscreen button */}
            <Button 
              onClick={toggleFullscreen} 
              variant="ghost" 
              size="sm" 
              className="text-white h-9 w-9 sm:h-10 sm:w-10 p-0 rounded-full touch-action-manipulation"
            >
              {isFullscreen ? 
                <Minimize size={16} className="sm:h-5 sm:w-5" /> : 
                <Maximize size={16} className="sm:h-5 sm:w-5" />
              }
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EncryptedVideoPlayer;