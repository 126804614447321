/**
 * @file src/hooks/useCloudServices.ts
 * @description Hook for managing cloud service connections using React Query
 * @version 1.1.0
 * @created 2024-12-01
 * @dependencies @tanstack/react-query
 */

import { useState, useMemo } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
  Query, // Import Query from react-query
} from '@tanstack/react-query';
import { cloudServiceApi } from '@/services/cloud-service';
import type {
  CloudService,
  CloudServiceType,
  ServiceConnectionError,
} from '@/types/cloud-services';

interface CloudServiceApiType {
  getServices: () => Promise<CloudService[]>;
  getServiceDetails: (serviceId: string) => Promise<CloudService>;
  initiateOAuth: (serviceType: string) => Promise<{ auth_url: string }>;
  disconnectService: (serviceId: string) => Promise<any>;
  connectService: (serviceType: CloudServiceType) => Promise<any>;
  triggerSync: (serviceId: string) => Promise<any>;
  getSyncStatus: (serviceId: string) => Promise<any>;
}

// Extended cloud service API type to include missing methods
const extendedCloudServiceApi: CloudServiceApiType = {
  ...cloudServiceApi,
  getServiceDetails: (serviceId: string) => Promise.resolve({} as CloudService),
  connectService: (serviceType: CloudServiceType) => Promise.resolve(null),
  triggerSync: (serviceId: string) => Promise.resolve(null),
  getSyncStatus: (serviceId: string) => Promise.resolve(null)
};

export const useCloudServices = () => {
  const queryClient = useQueryClient();
  const [connectingService, setConnectingService] = useState<CloudServiceType | null>(null);

  // Main query for listing services
  const servicesQuery = useQuery<CloudService[], Error, CloudService[], string[]>({
    queryKey: ['cloudServices'],
    queryFn: () => extendedCloudServiceApi.getServices(),
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false,
  } as UseQueryOptions<CloudService[], Error, CloudService[], string[]>);

  const services = useMemo(() => servicesQuery.data ?? [], [servicesQuery.data]);

  // Query for fetching individual service details
  const useServiceDetails = (serviceId: string | null) => {
    return useQuery<CloudService, Error>({
      queryKey: ['cloudService', serviceId],
      queryFn: () => {
        if (!serviceId) {
          return Promise.reject(new Error('No service ID provided'));
        }
        return extendedCloudServiceApi.getServiceDetails(serviceId);
      },
      enabled: !!serviceId,
    });
  };

  // Mutation for connecting a service
  const connectServiceMutation = useMutation({
    mutationFn: (serviceType: CloudServiceType) => {
      setConnectingService(serviceType);
      return extendedCloudServiceApi.connectService(serviceType);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cloudServices'] });
    },
    onSettled: () => {
      setConnectingService(null);
    },
  });

  // Mutation for disconnecting a service
  const disconnectServiceMutation = useMutation({
    mutationFn: (serviceId: string) => extendedCloudServiceApi.disconnectService(serviceId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cloudServices'] });
    },
  });

  // Mutation for triggering manual sync
  const triggerSyncMutation = useMutation({
    mutationFn: (serviceId: string) => extendedCloudServiceApi.triggerSync(serviceId),
    onSuccess: (_, serviceId) => {
      queryClient.invalidateQueries({ queryKey: ['cloudService', serviceId] });
    },
  });

  /**
   * Query for monitoring sync status.
   * Instead of receiving data as the first parameter,
   * we receive the `query` object and extract `data` from `query.state.data`.
   */
  const useSyncStatus = (serviceId: string | null) => {
    return useQuery<{ status: string; lastSync?: string } | null, Error>({
      queryKey: ['cloudService', serviceId, 'sync'],
      queryFn: () => {
        if (!serviceId) {
          return Promise.reject(new Error('No service ID provided'));
        }
        return extendedCloudServiceApi.getSyncStatus(serviceId);
      },
      enabled: !!serviceId,
      refetchInterval: (query: Query<{ status: string; lastSync?: string } | null, Error>) => {
        const data = query.state.data;
        if (!data) return false;
        return data.status === 'syncing' ? 1000 : false;
      },
    });
  };

  // Overall loading and error states
  const isLoading = servicesQuery.isLoading;
  const error = servicesQuery.error;

  return {
    // Data
    services,
    isLoading,
    error,

    // Service details
    useServiceDetails,

    // Sync status
    useSyncStatus,

    // Mutations
    connectService: connectServiceMutation.mutate,
    disconnectService: disconnectServiceMutation.mutate,
    triggerSync: triggerSyncMutation.mutate,

    // States
    isConnecting: connectServiceMutation.isPending,
    isDisconnecting: disconnectServiceMutation.isPending,
    isSyncing: triggerSyncMutation.isPending,
    connectingService,

    // Errors
    connectionError: connectServiceMutation.error as ServiceConnectionError | null,

    // Refetch
    refetch: servicesQuery.refetch,
  };
};