/**
 * @file src/components/cloud-drive/browser/FileList.tsx
 * @description Virtualized list view with progressive loading for large collections
 * @version 3.0.0
 * @updated 2025-03-26
 */

import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2, Info, RefreshCw } from 'lucide-react';
import { cn } from '@/utils/utils';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import { FileItem } from '../items/FileItem';
import { Checkbox } from '@/components/ui/checkbox';
import { useNavigation } from '@/context/NavigationContext';
import { useSelection } from '@/context/SelectionContext';
import { useCloudDrive } from '@/context/cloud-drive';
import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';
import { logger } from '@/utils/logger';
import { useToast } from '@/components/ui/toast';
import { useTranslation } from 'react-i18next';
import { useFilePreviewContext } from '@/providers/FilePreviewProvider';
import { CheckedState } from '@radix-ui/react-checkbox';
import { ItemCheckbox } from '../items/components/ItemCheckbox';
import { Skeleton } from '@/components/ui/skeleton';
import { useQueryClient } from '@tanstack/react-query';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';

interface FileListProps {
  items: (DriveFile | DriveFolder)[];
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  isDragging?: boolean;
  showHeader?: boolean;
  className?: string;
  totalItemCount?: number;
}

const ITEM_HEIGHT = 56; // Default list item height
// Number of items to render initially
const INITIAL_RENDER_COUNT = 40;
// Number of items to add for each batch
const BATCH_SIZE = 20;

export const FileList: React.FC<FileListProps> = ({
  items,
  hasNextPage,
  onLoadMore,
  isLoadingMore,
  isDragging,
  showHeader = true,
  className,
  totalItemCount
}) => {
  const { t } = useTranslation();
  const parentRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const { activeDriveId } = useRootDrive();
  const { showToast } = useToast();
  
  // Virtualization and progressive loading state
  const [visibleCount, setVisibleCount] = useState(INITIAL_RENDER_COUNT);
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  // Performance metrics
  const renderStartTime = useRef<number>(performance.now());
  const [renderTime, setRenderTime] = useState<number | null>(null);
  
  // Get navigation helpers with safety checks
  const navigationContext = useNavigation();
  const navigateToFolder = navigationContext?.navigateToFolder;
  const navigateToFile = navigationContext?.navigateToFile;
  
  const { selectedItems, isSelectMode, toggleSelection, selectAll, clearSelection } = useSelection();

  const { currentFolder, encryptedDrive, currentFolderContents, forceRefreshFolderContents } = useCloudDrive();
  const { previewFile } = useFilePreviewContext();

  // More focused debug logging
  useEffect(() => {
    // Log only on initial mount and when the count changes significantly
    if (visibleCount === INITIAL_RENDER_COUNT || visibleCount % (BATCH_SIZE * 2) === 0) {
      logger.debug('FileList visible items update', {
        component: 'FileList',
        data: {
          visibleCount,
          totalItems: items.length,
          selectedCount: selectedItems.length,
          renderTimeMs: renderTime
        }
      });
    }
  }, [visibleCount, items.length, selectedItems.length, renderTime]);

  // Optimized staggered items - only create this for visible items
  const staggeredVisibleItems = useMemo(() => {
    if (items.length === 0) return [];
    
    const visibleItems = items.slice(0, visibleCount);
    
    if (!encryptedDrive?.isEncryptedMode) return visibleItems;
    
    return visibleItems.map((item, index) => ({
      ...item,
      _decryptDelay: index < 20 ? index * 50 : undefined // Only animate first 20 items for performance
    }));
  }, [items, visibleCount, encryptedDrive?.isEncryptedMode]);

  // Observer for progressive loading as user scrolls
  useEffect(() => {
    if (!observerRef.current || items.length <= visibleCount) return;
    
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isRefreshing) {
          // Start timing the render
          renderStartTime.current = performance.now();
          
          // Increase the number of visible items
          setVisibleCount(prev => Math.min(prev + BATCH_SIZE, items.length));
          
          // Measure render time after state update
          setTimeout(() => {
            const endTime = performance.now();
            setRenderTime(endTime - renderStartTime.current);
          }, 0);
        }
      },
      { rootMargin: '200px 0px' }
    );
    
    observer.observe(observerRef.current);
    
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [items.length, visibleCount, isRefreshing]);
  
  // Reset visible count when items change completely
  useEffect(() => {
    setVisibleCount(Math.min(INITIAL_RENDER_COUNT, items.length));
  }, [currentFolder]);

  // Handle infinite scroll for API pagination
  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: onLoadMore,
    enabled: !!hasNextPage && !isLoadingMore && visibleCount >= items.length
  });

  // Set up virtualization - only virtualize the visible subset for better performance
  const rowVirtualizer = useVirtualizer({
    count: Math.min(staggeredVisibleItems.length, visibleCount),
    getScrollElement: () => parentRef.current,
    estimateSize: () => ITEM_HEIGHT,
    overscan: 5
  });

  // Handle item selection
  const handleSelect = useCallback((id: string, event?: React.MouseEvent) => {
    if (event?.shiftKey && selectedItems.length > 0) {
      const lastSelectedId = selectedItems[selectedItems.length - 1];
      const lastSelectedIndex = items.findIndex(item => item.id === lastSelectedId);
      const currentIndex = items.findIndex(item => item.id === id);
      
      const start = Math.min(lastSelectedIndex, currentIndex);
      const end = Math.max(lastSelectedIndex, currentIndex);
      
      const itemsToSelect = items.slice(start, end + 1).map((item: any) => item.id);
      selectAll(itemsToSelect);
    } else {
      toggleSelection(id);
    }
  }, [items, selectedItems, toggleSelection, selectAll]);

  // Enhanced item open handling with fallbacks
  const handleItemOpen = useCallback((item: DriveFile | DriveFolder) => {
    logger.debug('Opening item', {
      component: 'FileItem', 
      data: { 
        itemId: item.id, 
        itemType: item.type,
        itemName: item.name,
        isEncryptedMode: encryptedDrive?.isEncryptedMode
      }
    });

    try {
      if (item?.type === 'folder') {
        // Use enhanced navigation with options
        if (typeof navigateToFolder === 'function') {
          navigateToFolder(item.id, {
            isEncryptedMode: encryptedDrive.isEncryptedMode,
            encryptedDriveId: encryptedDrive.encryptedDriveId
          });
        } else {
          throw new Error('Navigation function not available');
        }
      } else {
        // For files, check if it's a media file
        const isMediaFile = item.type === 'file' && 
        ((item as DriveFile).mimeType?.startsWith('image/') || 
         (item as DriveFile).mimeType?.startsWith('video/') ||
         !!(item as any).media_info);
      
      if (isMediaFile) {
        // In the current code:
        const mediaFiles: DriveFile[] = [];
        previewFile(item as DriveFile, mediaFiles);
        
        // This should be:
        previewFile(item as DriveFile, items.filter(i => 
          i.type === 'file' && 
          ((i as DriveFile).mimeType?.startsWith('image/') || 
           (i as DriveFile).mimeType?.startsWith('video/') ||
           !!(i as any).media_info)
        ) as DriveFile[]);
      }
        else if (typeof navigateToFile === 'function') {
          // Use enhanced file navigation
          navigateToFile(item.id);
        } else {
          throw new Error('Navigation function not available');
        }
      }
    } catch (error) {
      logger.error('Error opening item', {
        component: 'FileItem',
        error,
        data: { itemId: item.id, itemType: item.type }
      });
      
      showToast(t('errors.navigation_failed'), 'error');
    }
  }, [
    navigateToFolder,
    navigateToFile,
    previewFile,
    encryptedDrive,
    t,
    showToast
  ]);

  // Handle select all with memory optimization
  const handleSelectAll = useCallback((checked: CheckedState, event?: React.MouseEvent) => {
    // Make sure any event is stopped from propagating to prevent double-handling
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    logger.debug('Select all triggered in FileList', {
      component: 'FileList',
      data: {
        checked,
        totalItems: items.length
      }
    });
    
    if (checked) {
      const itemIds = items.map(item => item.id);
      selectAll(itemIds);
    } else {
      clearSelection();
    }
  }, [items, selectAll, clearSelection, logger]);
  
  // Refresh handler for manual updates
  const handleRefresh = useCallback(() => {
    setIsRefreshing(true);
    
    // Use CloudDriveContext refresh method if available
    if (forceRefreshFolderContents) {
      forceRefreshFolderContents();
    }
    
    // Also directly invalidate relevant queries
    if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
      queryClient.invalidateQueries({
        queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, currentFolder]
      });
    } else if (activeDriveId) {
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder', currentFolder, activeDriveId, false]
      });
    }
    
    // Force immediate refetch
    queryClient.refetchQueries({
      queryKey: ['cloud-drive', 'folder', currentFolder]
    }).then(() => {
      // Reset to initial count of visible items after refresh
      setVisibleCount(Math.min(INITIAL_RENDER_COUNT, items.length));
      setIsRefreshing(false);
    });
  }, [
    forceRefreshFolderContents, 
    queryClient, 
    encryptedDrive.isEncryptedMode, 
    encryptedDrive.encryptedDriveId, 
    currentFolder, 
    activeDriveId,
    items.length
  ]);

  // Empty state with loading skeleton
  if (items.length === 0) {
    if (isLoadingMore || isRefreshing) {
      return (
        <div className="p-4">
          <div className="animate-pulse flex items-center mb-6">
            <Skeleton className="h-7 w-7 rounded-full mr-2" />
            <Skeleton className="h-6 w-28" />
          </div>
          
          <div className="flex flex-col space-y-3">
            {[...Array(8)].map((_, i) => (
              <div key={i} className="flex items-center space-x-4">
                <Skeleton className="h-10 w-10 rounded-md" />
                <div className="space-y-2 flex-1">
                  <Skeleton className="h-4 w-1/3" />
                  <Skeleton className="h-3 w-1/4" />
                </div>
                <Skeleton className="h-4 w-16" />
                <Skeleton className="h-4 w-16" />
              </div>
            ))}
          </div>
        </div>
      );
    }
    
    return (
      <div className="flex flex-col items-center justify-center min-h-[200px] p-6 text-center">
        <div className="text-center text-gray-500 dark:text-gray-400 flex flex-col items-center">
          <Info className="h-10 w-10 mb-2 text-gray-400 dark:text-gray-500" />
          <p className="text-sm font-medium">No items to display</p>
          <p className="text-xs mt-1 max-w-md">This folder is empty or the items couldn't be loaded</p>
          <button 
            onClick={handleRefresh}
            className="mt-4 px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white text-sm flex items-center"
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            {t('drive.media.refresh') || 'Refresh'}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={parentRef}
      className={cn(
        "relative w-full no-scroll", // Added no-scroll marker 
        isDragging && "select-none",
        className
      )}
      style={{ overflow: 'visible', height: 'auto' }} // Explicit style to prevent scroll
    >
      {/* Header with modern checkbox and refresh */}
      {showHeader && (
        <div className={cn(
          "sticky top-0 z-10",
          "bg-white/95 dark:bg-gray-900/95",
          "backdrop-blur-sm border-b",
          "border-gray-100 dark:border-gray-800",
          "px-4 py-2"
        )}>
          <div className="flex items-center justify-between text-sm text-gray-500 dark:text-gray-400">
            <div className="flex items-center">
              <ItemCheckbox
                checked={items.length > 0 && items.every(item => selectedItems.includes(item.id))}
                isSelectMode={selectedItems.length > 0}
                onChange={(checked: boolean) => handleSelectAll(checked, {} as React.MouseEvent)}
                variant="modern"
                absolute={false}
                className="relative mr-2"
              />
              <span>
                {selectedItems.length > 0 ? `${selectedItems.length} selected` : 'Select all'}
              </span>
            </div>
            
            <div className="flex-1 grid grid-cols-[1fr,120px,120px] gap-4 mx-6">
              <span>Name</span>
              <span>Modified</span>
              <span>Size</span>
            </div>
            
            {/* Stats and refresh button */}
            <div className="flex items-center">
              <span className="text-xs mr-4">{totalItemCount !== undefined ? totalItemCount : items.length} {t('drive.media.items')}</span>
              
              <button
                onClick={handleRefresh}
                disabled={isRefreshing}
                className={cn(
                  "p-1.5 rounded-full",
                  "text-gray-500 hover:text-blue-500 hover:bg-blue-50 dark:hover:bg-gray-800",
                  "transition-colors",
                  isRefreshing && "animate-spin text-blue-500"
                )}
                title={t('drive.media.refresh') || 'Refresh'}
              >
                <RefreshCw className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Virtualized List with Optimized Rendering */}
      <div
        className="relative w-full"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`
        }}
      >
        <AnimatePresence mode="popLayout">
          {rowVirtualizer.getVirtualItems().map((virtualRow: any) => {
            const item = staggeredVisibleItems[virtualRow.index];
            if (!item) return null; // Safety check
            
            return (
              <motion.div
                key={item.id}
                layout={false} // Disable layout animations for better performance
                layoutId={item.id}
                className="absolute left-0 right-0"
                style={{
                  top: virtualRow.start,
                  height: virtualRow.size,
                  opacity: isDragging && !selectedItems.includes(item.id) ? 0.5 : 1
                }}
              >
                <FileItem
                  item={item}
                  items={items} // This is used for gallery navigation, can't be optimized further
                  view="list"
                  isSelected={selectedItems.includes(item.id)}
                  isSelectMode={isSelectMode}
                  onSelect={handleSelect}
                  onOpen={() => handleItemOpen(item)}
                  showCheckbox
                  animationDelay={(item as any)._decryptDelay}
                  className={cn(
                    "transition-transform duration-200",
                    isDragging && selectedItems.includes(item.id) && "scale-98"
                  )}
                />
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>

      {/* Virtual items observer - for internal pagination */}
      {items.length > visibleCount && (
        <div 
          ref={observerRef}
          className="w-full h-20 flex items-center justify-center"
        >
          <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
        </div>
      )}

      {/* Loading More Indicator - for API pagination */}
      {(hasNextPage || isLoadingMore) && (
        <div 
          ref={loadMoreRef}
          className={cn(
            "flex items-center justify-center",
            "py-4 mt-4",
            "text-gray-500 dark:text-gray-400"
          )}
        >
          {isLoadingMore && (
            <div className="flex items-center gap-2">
              <Loader2 className="h-5 w-5 animate-spin" />
              <span className="text-sm font-medium">Loading more...</span>
            </div>
          )}
        </div>
      )}
      
      {/* Render time debug info (only in dev) */}
      {process.env.NODE_ENV === 'development' && renderTime && (
        <div className="fixed bottom-2 right-2 bg-black/80 text-white text-xs p-1 rounded">
          Render: {renderTime.toFixed(2)}ms | Items: {visibleCount}/{items.length}
        </div>
      )}
    </div>
  );
};

export default FileList;