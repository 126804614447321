/**
 * @file src/layouts/ghost/components/create-core/steps/StepSuccess.tsx
 * @description Final success step with animations and achievement showcase
 * @version 1.0.0
 */

import React from 'react';
import { motion } from 'framer-motion';
import { 
  Ghost, 
  Shield, 
  Lock, 
  Sparkles,
  ArrowRight 
} from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface StepSuccessProps {
  formData: {
    name: string;
    encryptionTier: string;
    [key: string]: any;
  };
  onClose: () => void;
}

export const StepSuccess: React.FC<StepSuccessProps> = ({
  formData,
  onClose,
}) => {
  const { t } = useGhostTranslation();

  logger.debug(`Rendering StepSuccess with name: ${formData.name} for tier: ${formData.encryptionTier}`);

  // Unlocked achievements based on setup
  const achievements = [
    {
      icon: Ghost,
      title: t('create.success.achievements.core'),
      description: t('create.success.achievements.core_desc'),
      gradient: 'from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]'
    },
    {
      icon: Shield,
      title: t('create.success.achievements.shield'),
      description: t('create.success.achievements.shield_desc'),
      gradient: 'from-[hsl(var(--ghost-accent-secondary))] to-[hsl(var(--ghost-accent-tertiary))]'
    },
    {
      icon: Lock,
      title: t('create.success.achievements.escape'),
      description: t('create.success.achievements.escape_desc'),
      gradient: 'from-[hsl(var(--ghost-accent-tertiary))] to-[hsl(var(--ghost-accent))]'
    }
  ];


  // We add a useEffect to automatically close the dialog after a few seconds
  // which will trigger navigation to the new drive via the handleClose callback
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Auto-close after 3 seconds to proceed to the drive
    
    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div className="space-y-8">
      {/* Success Animation */}
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 20
        }}
        className="relative flex justify-center"
      >
        {/* Animated rings */}
        <motion.div
          className="absolute inset-0"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: [0, 1, 0], scale: [0.8, 1.2, 1.5] }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeOut"
          }}
        >
          <div className="absolute inset-0 rounded-full border-2 border-[hsl(var(--ghost-accent))] opacity-20" />
        </motion.div>
        
        {/* Ghost icon */}
        <motion.div
          animate={{
            y: [0, -10, 0],
            rotate: [0, 5, -5, 0]
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="relative"
        >
          <div className="absolute inset-0 blur-2xl opacity-50 bg-[hsl(var(--ghost-accent))]" />
          <Ghost className="relative w-20 h-20 text-[hsl(var(--ghost-accent))]" />
        </motion.div>

        {/* Floating particles */}
        {[...Array(6)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute"
            initial={{ 
              opacity: 0, 
              scale: 0,
              x: 0,
              y: 0
            }}
            animate={{ 
              opacity: [0, 1, 0],
              scale: [0, 1, 0],
              x: Math.cos(i * 60 * Math.PI / 180) * 50,
              y: Math.sin(i * 60 * Math.PI / 180) * 50
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: i * 0.3,
              ease: "easeOut"
            }}
          >
            <Sparkles className="w-4 h-4 text-[hsl(var(--ghost-accent))]" />
          </motion.div>
        ))}
      </motion.div>

      {/* Success Message */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="text-center space-y-2"
      >
        <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]">
          {t('create.success.title') as React.ReactNode}
        </h2>
        <p className="text-[hsl(var(--ghost-foreground-muted))]">
          {t('create.success.description', { name: formData.name }) as React.ReactNode}
        </p>
      </motion.div>

      {/* Achievements */}
      <div className="space-y-3">
        {achievements.map((achievement, index) => {
          const Icon = achievement.icon;
          
          return (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ 
                opacity: 1, 
                x: 0,
                transition: { delay: 0.7 + (index * 0.1) }
              }}
              className={cn(
                "p-4 rounded-xl",
                "bg-[hsl(var(--ghost-surface))]",
                "border border-[hsl(var(--ghost-accent))/0.2]",
                "relative overflow-hidden"
              )}
            >
              {/* Achievement glow */}
              <motion.div
                className="absolute inset-0"
                initial={false}
                animate={{
                  background: [
                    `linear-gradient(to right, ${achievement.gradient})`,
                    `linear-gradient(to left, ${achievement.gradient})`,
                    `linear-gradient(to right, ${achievement.gradient})`
                  ],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "linear"
                }}
                style={{ opacity: 0.1 }}
              />

              <div className="relative flex items-center space-x-4">
                <div className={cn(
                  "p-2 rounded-lg",
                  "bg-[hsl(var(--ghost-surface))]"
                )}>
                  <Icon className="w-5 h-5 text-[hsl(var(--ghost-accent))]" />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-[hsl(var(--ghost-foreground))]">
                    {achievement.title as React.ReactNode}
                  </h3>
                  <p className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
                    {achievement.description as React.ReactNode}
                  </p>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>

      {/* Action Button */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
        className="flex justify-center pt-4"
      >
        <motion.button
          onClick={onClose}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={cn(
            "px-8 py-3 rounded-xl",
            "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
            "text-white font-medium",
            "flex items-center space-x-2",
            "relative overflow-hidden"
          )}
        >
          {/* Button glow effect */}
          <motion.div
            className="absolute inset-0"
            initial={false}
            animate={{
              opacity: [0, 0.2, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "linear"
            }}
            style={{
              background: 'radial-gradient(circle at center, white 0%, transparent 100%)'
            }}
          />
          
          <span>{t('create.success.start_button') as React.ReactNode}</span>
          <ArrowRight className="w-4 h-4" />
        </motion.button>
      </motion.div>
    </div>
  );
};