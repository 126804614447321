/**
 * @file src/components/encrypted-drive/dialogs/UnlockDriveDialog.tsx
 * @description Enhanced unlock dialog with lazy loading for translation support
 * @version 2.1.0
 * @updated 2025-03-19
 */

import React, { lazy, Suspense } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import type { EncryptedDrive } from '@/types/encrypted-drive.types';

// Lazy load the dialog content
const UnlockDriveDialogContent = lazy(() => import('./UnlockDriveDialogContent'));

interface UnlockDriveDialogProps {
  drive: EncryptedDrive;
  onOpenChange: (open: boolean) => void;
  onUnlockSuccess?: (drive: EncryptedDrive) => void;
}

export const UnlockDriveDialog: React.FC<UnlockDriveDialogProps> = ({
  drive,
  onOpenChange,
  onUnlockSuccess
}) => {
  return (
    <Dialog open={true} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[450px] p-0 overflow-hidden">
        <Suspense fallback={null}>
          <UnlockDriveDialogContent 
            drive={drive}
            onOpenChange={onOpenChange}
            onUnlockSuccess={onUnlockSuccess}
          />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
};

export default UnlockDriveDialog;