/**
 * @file src/components/encrypted-drive/dialogs/LockAllConfirmDialog.tsx
 * @description Confirmation dialog for locking all encrypted drives with lazy loading
 * @version 1.1.0
 * @updated 2025-03-19
 */

import React, { lazy, Suspense } from 'react';
import {
  AlertDialog,
  AlertDialogContent
} from '@/components/ui/alert-dialog';

// Lazy load the dialog content to prevent translation issues
const LockAllConfirmDialogContent = lazy(() => import('./LockAllConfirmDialogContent'));

interface LockAllConfirmDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => Promise<void>;
  unlockedCount: number;
  isLocking: boolean;
}

export const LockAllConfirmDialog: React.FC<LockAllConfirmDialogProps> = ({
  open,
  onOpenChange,
  onConfirm,
  unlockedCount,
  isLocking
}) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      {open && (
        <AlertDialogContent>
          <Suspense fallback={null}>
            <LockAllConfirmDialogContent 
              onConfirm={onConfirm}
              unlockedCount={unlockedCount}
              isLocking={isLocking}
              onOpenChange={onOpenChange}
            />
          </Suspense>
        </AlertDialogContent>
      )}
    </AlertDialog>
  );
};

export default LockAllConfirmDialog;