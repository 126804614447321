/**
 * @file src/components/encrypted-drive/dialogs/steps/StepWelcome.tsx
 * @description Welcome step for encrypted drive creation
 * @version 1.0.0
 */

import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Key } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';

interface StepWelcomeProps {
  onNext: () => void;
}

export const StepWelcome: React.FC<StepWelcomeProps> = ({ onNext }) => {
  const { t } = useTranslation();

  const features = [
    {
      icon: <Shield className="h-5 w-5" />,
      title: t('encrypted_drive.features.zero_knowledge.title'),
      description: t('encrypted_drive.features.zero_knowledge.description')
    },
    {
      icon: <Lock className="h-5 w-5" />,
      title: t('encrypted_drive.features.military_grade.title'),
      description: t('encrypted_drive.features.military_grade.description')
    },
    {
      icon: <Key className="h-5 w-5" />,
      title: t('encrypted_drive.features.recovery.title'),
      description: t('encrypted_drive.features.recovery.description')
    }
  ];

  return (
    <div className="space-y-6">
      <DialogHeader>
        <DialogTitle className="text-2xl text-center">
          {t('encrypted_drive.welcome.title')}
        </DialogTitle>
      </DialogHeader>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-6"
      >
        {/* Features List */}
        <div className="grid gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ 
                opacity: 1, 
                x: 0,
                transition: { delay: index * 0.1 }
              }}
              className="flex items-start space-x-4"
            >
              <div className="rounded-full p-2 bg-blue-50 dark:bg-blue-900/20 text-blue-500 dark:text-blue-400">
                {feature.icon}
              </div>
              <div className="flex-1">
                <h4 className="text-sm font-medium text-gray-900 dark:text-gray-100">
                  {feature.title}
                </h4>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {feature.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Action Button */}
        <div className="flex justify-end pt-4">
          <Button
            onClick={onNext}
            className="min-w-[120px]"
            size="lg"
          >
            {t('common.get_started')}
          </Button>
        </div>
      </motion.div>
    </div>
  );
};