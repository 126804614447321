/**
 * @file src/routes/audit.routes.tsx
 * @description Audit feature routing configuration
 * @version 1.0.0
 * @created 2024-02-24
 */

import { type RouteObject } from "react-router-dom";
import { AuditProvider } from "@/providers/AuditProvider";
import AuditPage from "@/pages/audit";
import { ErrorBoundary } from "@/components/common/ErrorBoundary";

export const auditRoutes: RouteObject = {
  path: "audit",
  element: (
    <ErrorBoundary>
      <AuditProvider>
        <AuditPage />
      </AuditProvider>
    </ErrorBoundary>
  ),
};
