/**
 * @file src/components/ghost/common/background/effects/useParticlesEffect.ts
 * @description Enhanced floating particles effect
 * @version 1.0.0
 */

import { getAccentColor } from '../utils/colors';
import { CanvasDimensions, getResponsiveValue } from '../utils/canvasHelpers';
import { logger } from '@/utils/logger';

interface Particle {
  x: number;
  y: number;
  radius: number;
  speedY: number;
  opacity: number;
  pulse: number;
  connection: number;
}

export const useParticlesEffect = (
  ctx: CanvasRenderingContext2D,
  dimensions: CanvasDimensions
) => {
  // Initialize particles
  const particleCount = getResponsiveValue(30, 50);
  
  const particles: Particle[] = Array.from({ length: particleCount }, () => ({
    x: Math.random() * dimensions.width,
    y: Math.random() * dimensions.height,
    radius: Math.random() * 1.5 + 0.5,
    speedY: -(Math.random() * 0.5 + 0.2),
    opacity: Math.random() * 0.5 + 0.3,
    pulse: Math.random() * Math.PI,
    connection: Math.random() * 100 + 50
  }));

  logger.debug('Particles effect initialized', { 
    component: 'useParticlesEffect'
  });

  const render = () => {
    particles.forEach((particle, i) => {
      // Update position
      particle.y += particle.speedY;
      if (particle?.y < -10) {
        particle.y = dimensions.height + 10;
        particle.x = Math.random() * dimensions.width;
      }

      // Update pulse
      particle.pulse += 0.02;
      const pulseOpacity = (Math.sin(particle.pulse) + 1) / 2 * particle.opacity;

      // Draw particle glow
      const gradient = ctx.createRadialGradient(
        particle.x, particle.y, 0,
        particle.x, particle.y, particle.radius * 4
      );
      
      gradient.addColorStop(0, getAccentColor(pulseOpacity));
      gradient.addColorStop(1, getAccentColor(0));
      
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius * 4, 0, Math.PI * 2);
      ctx.fillStyle = gradient;
      ctx.fill();

      // Draw particle core
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
      ctx.fillStyle = getAccentColor(pulseOpacity * 1.5);
      ctx.fill();

      // Draw connections to nearby particles
      particles.slice(i + 1).forEach((other: any) => {
        const dx = other.x - particle.x;
        const dy = other.y - particle.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < particle.connection) {
          ctx.beginPath();
          ctx.moveTo(particle.x, particle.y);
          ctx.lineTo(other.x, other.y);
          ctx.strokeStyle = getAccentColor((1 - distance / particle.connection) * 0.15);
          ctx.stroke();
        }
      });
    });
  };

  return {
    render,
    cleanup: () => {
      // Nothing to clean up for particles
      logger.debug('Particles effect cleaned up');
    }
  };
};