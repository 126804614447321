/**
 * @file src/components/devices/DeviceListView.tsx
 * @description Enhanced list view for connected devices with filtering and search
 * @version 3.0.0
 * @updated 2025-02-28
 * @dependencies framer-motion, lucide-react, react-i18next
 */

import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Computer, Laptop, Smartphone, Server, Database, AlertCircle, InfoIcon } from 'lucide-react';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

// Import new components
import { DeviceSearchBar } from './DeviceSearchBar';
import { AddDeviceButton } from './AddDeviceButton';
import { DownloadDialog } from './dialogs/DownloadDialog';
import { DeviceFilterTabs, DeviceFilterType } from './DeviceFilterTabs';

interface DeviceListViewProps {
  onSelectDevice: (deviceId: string) => void;
  className?: string;
}

export const DeviceListView: React.FC<DeviceListViewProps> = ({ 
  onSelectDevice,
  className 
}) => {
  const { t } = useTranslation();
  const { devices, isLoading, error, refreshDevices } = useRootDrive();
  
  // Local state
  const [searchQuery, setSearchQuery] = useState('');
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState<DeviceFilterType>('all');

  // Get icon based on platform
  const getDeviceIcon = (platform?: string) => {
    switch(platform?.toLowerCase()) {
      case 'windows':
      case 'macos':
        return Laptop;
      case 'ios':
      case 'android':
        return Smartphone;
      case 'server':
      case 'linux':
        return Server;
      case 'nas':
      case 'qnap':
      case 'synology':
        return Database;
      default:
        return Computer;
    }
  };

  // Get device category based on platform and type
  const getDeviceCategory = (device: any): DeviceFilterType => {
    const platform = device.platform?.toLowerCase();
    const type = device.device_type?.toLowerCase();
    
    // Skip cloud services
    if (type === 'cloud') {
      return 'all'; // This will be filtered out later
    }
    
    if (platform === 'android' || platform === 'ios' || type === 'mobile') {
      return 'mobiles';
    }
    
    if (platform === 'server' || platform === 'linux' || type === 'server') {
      return 'servers';
    }
    
    if (platform?.includes('nas') || platform?.includes('qnap') || platform?.includes('synology')) {
      return 'nas';
    }
    
    // Default to computers for windows, macos, and other devices
    return 'computers';
  };

  // Filter devices based on search query and active filter
  // Filter devices based on search query and active filter
  const filteredDevices = useMemo(() => {
    if (!devices) return [];

    // Start with all devices except cloud services
    let filtered = devices.filter((device: any) => device.device_type !== 'cloud');
    
    // Apply search filter if query exists
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter((device: any) => 
        device.name.toLowerCase().includes(query) ||
        device.platform?.toLowerCase().includes(query) ||
        device.status.toLowerCase().includes(query)
      );
    }
    
    // Apply category filter if not "all"
    if (activeFilter !== 'all') {
      filtered = filtered.filter((device: any) => getDeviceCategory(device) === activeFilter);
    }
    
    return filtered;
  }, [devices, searchQuery, activeFilter]);

  // Handle search
  const handleSearch = (query: string) => {
    logger.debug('Device search', {
      component: 'DeviceListView',
      query,
      action: 'search'
    });
    setSearchQuery(query);
  };

  // Handle filter change
  const handleFilterChange = (filter: DeviceFilterType) => {
    setActiveFilter(filter);
  };

  // Handle add device
  const handleAddDevice = () => {
    logger.debug('Opening add device dialog', {
      component: 'DeviceListView',
      action: 'openAddDeviceDialog'
    });
    setIsDownloadDialogOpen(true);
  };

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <div className="animate-spin h-10 w-10 border-2 border-blue-500 border-t-transparent rounded-full mb-4"></div>
          <p className="text-gray-500 dark:text-gray-400">
            {t('devices.loading')}
          </p>
        </div>
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-6">
        <div className="rounded-full bg-red-100 dark:bg-red-900/20 p-3 mb-4">
          <AlertCircle className="h-6 w-6 text-red-600 dark:text-red-400" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
          {t('devices.error_title')}
        </h3>
        <p className="text-gray-500 dark:text-gray-400 text-center mb-4">
          {t('devices.error_description')}
        </p>
        <Button onClick={() => refreshDevices()}>
          {t('common.try_again')}
        </Button>
      </div>
    );
  }

  return (
    <div className={cn("flex flex-col h-full", className)}>
      {/* Header with search and add button */}
      <div className="flex flex-col gap-4 p-6 pb-2">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
            {t('devices.title')}
          </h2>
          
          <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-3">
            <DeviceSearchBar onSearch={handleSearch} initialQuery={searchQuery} />
            <AddDeviceButton onClick={handleAddDevice} className="whitespace-nowrap" />
          </div>
        </div>
        
        {/* Filter tabs */}
        <DeviceFilterTabs 
          activeFilter={activeFilter} 
          onFilterChange={handleFilterChange}
          className="mt-2" 
        />
      </div>

      {/* Show empty state if no devices match filters */}
      {(!filteredDevices || filteredDevices.length === 0) && (
        <div className="flex-1 flex flex-col items-center justify-center p-6">
          <div className="rounded-full bg-blue-100 dark:bg-blue-900/20 p-3 mb-4">
            <Computer className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
            {searchQuery || activeFilter !== 'all'
              ? t('devices.no_matching_devices_title')
              : t('devices.no_devices_title')}
          </h3>
          
          <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
            {searchQuery
              ? t('devices.no_matching_devices_description', { query: searchQuery })
              : activeFilter !== 'all'
                ? t('devices.no_matching_devices_filter', { filter: t(`devices.filters.${activeFilter}`) })
                : t('devices.no_devices_description')}
          </p>
          
          {(searchQuery || activeFilter !== 'all') && (
            <div className="flex gap-3 mt-4">
              {searchQuery && (
                <Button 
                  variant="outline" 
                  onClick={() => setSearchQuery('')}
                >
                  {t('devices.search.clear')}
                </Button>
              )}
              
              {activeFilter !== 'all' && (
                <Button 
                  variant="outline" 
                  onClick={() => setActiveFilter('all')}
                >
                  {t('devices.filters.clear')}
                </Button>
              )}
            </div>
          )}
          
          {!searchQuery && activeFilter === 'all' && (
            <Button 
              variant="default" 
              onClick={handleAddDevice} 
              className="mt-4"
            >
              {t('devices.actions.add_first_device')}
            </Button>
          )}
        </div>
      )}

      {/* Devices grid */}
      {filteredDevices.length > 0 && (
        <div className="p-6 pt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 overflow-auto">
          {filteredDevices.map((device) => {
            const DeviceIcon = getDeviceIcon(device.platform);
            
            // Format last seen date nicely if available
            const formattedLastSeen = device.last_seen 
              ? new Date(device.last_seen).toLocaleString() 
              : t('devices.status.never');
              
            return (
              <motion.div
                key={device.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className="rounded-lg border border-gray-200 dark:border-gray-800 overflow-hidden hover:shadow-md dark:hover:shadow-lg"
              >
                <button
                  onClick={() => {
                    logger.debug('Device selected', {
                      component: 'DeviceListView',
                      deviceId: device.id,
                      deviceName: device.name,
                      action: 'selectDevice'
                    });
                    onSelectDevice(device.id);
                  }}
                  className="w-full h-full text-left bg-white dark:bg-gray-900 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
                >
                  <div className="p-6">
                    <div className="flex items-start space-x-4">
                      <div className={cn(
                        "p-3 rounded-full",
                        device.status === 'online' 
                          ? "bg-green-100 dark:bg-green-900/20"
                          : "bg-gray-100 dark:bg-gray-800"
                      )}>
                        <DeviceIcon className={cn(
                          "h-6 w-6",
                          device.status === 'online'
                            ? "text-green-600 dark:text-green-400"
                            : "text-gray-500 dark:text-gray-400"
                        )} />
                      </div>

                      <div>
                        <h3 className="font-medium text-gray-900 dark:text-gray-100">
                          {device.name}
                        </h3>
                        <div className="mt-1 flex items-center">
                          <span className={cn(
                            "inline-block w-2 h-2 rounded-full mr-2",
                            device.status === 'online' 
                              ? "bg-green-500" 
                              : device.status === 'syncing' 
                                ? "bg-yellow-500" 
                                : "bg-gray-400"
                          )}></span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {t(`devices.status.${device.status}`)}
                          </span>
                          
                          {/* Add tooltip for status explanation */}
                          <TooltipProvider>
                            <Tooltip delayDuration={300}>
                              <TooltipTrigger asChild>
                                <Button variant="ghost" size="sm" className="h-6 w-6 p-0 ml-1">
                                  <InfoIcon className="h-3 w-3 text-gray-400" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>{t(`devices.status_explanation.${device.status}`)}</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                        
                        {/* Platform info */}
                        {device.platform && (
                          <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                            {device.platform} {device.version && `(${device.version})`}
                          </p>
                        )}
                        
                        {/* Last seen info */}
                        <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                          {t('devices.last_seen', { time: formattedLastSeen })}
                        </p>
                        
                        {/* Drives count if available */}
                        {device.drives && (
                          <p className="mt-2 text-xs font-medium text-blue-600 dark:text-blue-400">
                            {t('devices.drives_count', { count: device.drives.length })}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </button>
              </motion.div>
            );
          })}
        </div>
      )}

      {/* Download dialog */}
      <DownloadDialog
        isOpen={isDownloadDialogOpen}
        onClose={() => setIsDownloadDialogOpen(false)}
      />
    </div>
  );
};

export default DeviceListView;