/**
 * @file src/app/ghost/components/zero-knowledge/common/DemoModeView.tsx
 * @description Demo mode view showing simulated encrypted file - improved for mobile
 * @version 1.0.1
 * @created 2025-04-01
 * @updated 2025-04-03
 * @dependencies framer-motion, lucide-react
 */

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { File, Database, ExternalLink } from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { cn } from '@/utils/utils';

interface DemoModeViewProps {
  onDisableDemoMode: () => void;
}

export const DemoModeView: React.FC<DemoModeViewProps> = ({
  onDisableDemoMode
}) => {
  const { t } = useGhostTranslation();
  const [showDatabase, setShowDatabase] = useState(false);
  
  // Sample encrypted data with proper line breaks for mobile
  const encryptedData = `4d8a2b7f0e9c6d3a1f5e2b70c9d81f5a
e3b0c44298fc1c149afb298f1c2b6a7d
3d7e98c21a45b6f87d90e31f5a7c8b46
5e9f8a7b6c3d2e1f0a9b8c7d6e5f4a3b
7c8b9a0f1e2d3c4b5a6f7e8d9c0b1a2e`;

  const uuid = "8f9e0d1c-2b3a-4f5e-6d7c-8b9a0f1e2d3c";
  
  return (
    <div className="space-y-3">
      {/* Demo Mode Banner - Slimmer for mobile */}
      <div className="bg-amber-50 dark:bg-amber-900/20 border border-amber-200 dark:border-amber-700/30 rounded-lg p-2 text-amber-800 dark:text-amber-200 text-center text-xs">
        {t('zeroKnowledge.dialog.demoMode.banner', 'Demo Mode: This is a simulation to show how zero knowledge encryption works')}
      </div>
      
      {/* What You See Section - More compact */}
      <div className="space-y-1">
        <h3 className="text-xs font-medium text-[hsl(var(--ghost-foreground))]">
          {t('zeroKnowledge.dialog.demoMode.whatYouSee', 'What You See')}
        </h3>
        
        <div className="bg-[hsl(var(--ghost-surface))]/30 rounded-lg p-3 flex flex-col items-center justify-center">
          {/* Document preview - Smaller for mobile */}
          <div className="w-20 h-24 bg-[hsl(var(--ghost-bg))]/20 rounded-lg flex flex-col items-center justify-center mb-2">
            <File className="w-8 h-8 text-[hsl(var(--ghost-foreground-muted))]" />
          </div>
          <span className="text-xs text-[hsl(var(--ghost-foreground))]">
            expense_report.docx
          </span>
        </div>
      </div>
      
      {/* What's Actually Stored Section - Optimized for mobile */}
      <div className="space-y-1">
        <h3 className="text-xs font-medium text-[hsl(var(--ghost-foreground))]">
          {t('zeroKnowledge.dialog.demoMode.whatStored', 'What\'s Actually Stored')}
        </h3>
        
        <div className="bg-[hsl(var(--ghost-bg))] rounded-lg p-3 flex flex-col overflow-hidden">
          {/* Encrypted data - Text wrapped for mobile */}
          <div className="font-mono text-[10px] leading-tight text-[hsl(var(--ghost-accent))] overflow-x-auto whitespace-pre-wrap break-all">
            {encryptedData}
          </div>
          
          {/* UUID - Compact display for mobile */}
          <div className="mt-2 bg-[hsl(var(--ghost-bg))]/30 p-1.5 rounded text-[10px] font-mono text-[hsl(var(--ghost-accent))] break-all">
            <span className="opacity-70">UUID:</span> {uuid}
          </div>
        </div>
      </div>
      
      {/* S3 URL Demo Button - Centered and compact */}
      <div className="flex justify-center pt-1">
        <button
          className={cn(
            "px-3 py-1.5 rounded-lg text-xs",
            "bg-[hsl(var(--ghost-foreground-muted))]/10",
            "text-[hsl(var(--ghost-foreground))]",
            "border border-[hsl(var(--ghost-foreground-muted))]/20",
            "flex items-center justify-center",
            "transition-all duration-200"
          )}
        >
          <ExternalLink className="w-3 h-3 mr-1.5" />
          {t('zeroKnowledge.dialog.demoMode.viewS3Link', 'View Example S3 Link')}
        </button>
      </div>
      
      {/* Database View Toggle - Compact version */}
      <div className="text-center space-y-1 pt-1">
        <button
          className={cn(
            "px-3 py-1.5 rounded-lg text-xs",
            "bg-[hsl(var(--ghost-surface))]/30",
            "text-[hsl(var(--ghost-foreground))]",
            "border border-[hsl(var(--ghost-accent))]/10",
            "flex items-center justify-center mx-auto",
            "transition-all duration-200"
          )}
          onClick={() => setShowDatabase(!showDatabase)}
        >
          <Database className="w-3 h-3 mr-1.5" />
          {showDatabase 
            ? t('zeroKnowledge.dialog.demoMode.hideDatabase', 'Hide Database Records')
            : t('zeroKnowledge.dialog.demoMode.showDatabase', 'Show Database Records')}
        </button>
      </div>
      
      {/* Database Records - Responsive table for mobile */}
      {showDatabase && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className="overflow-hidden"
        >
          <div className="bg-[hsl(var(--ghost-bg))] rounded-lg p-2 font-mono text-[10px]">
            <div className="overflow-x-auto">
              <table className="w-full text-[hsl(var(--ghost-foreground-muted))]">
                <thead>
                  <tr className="text-[hsl(var(--ghost-accent))] border-b border-[hsl(var(--ghost-foreground-muted))]/10">
                    <th className="text-left p-1">Field</th>
                    <th className="text-left p-1">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-[hsl(var(--ghost-foreground-muted))]/10">
                    <td className="text-left p-1 whitespace-nowrap">id</td>
                    <td className="text-left p-1 break-all">{uuid}</td>
                  </tr>
                  <tr className="border-b border-[hsl(var(--ghost-foreground-muted))]/10">
                    <td className="text-left p-1 whitespace-nowrap">encrypted_name</td>
                    <td className="text-left p-1">[BINARY DATA]</td>
                  </tr>
                  <tr className="border-b border-[hsl(var(--ghost-foreground-muted))]/10">
                    <td className="text-left p-1 whitespace-nowrap">file_size</td>
                    <td className="text-left p-1">247680</td>
                  </tr>
                  <tr className="border-b border-[hsl(var(--ghost-foreground-muted))]/10">
                    <td className="text-left p-1 whitespace-nowrap">created_at</td>
                    <td className="text-left p-1">2025-04-01 14:23:01</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </motion.div>
      )}
      
      {/* Exit Demo Mode Button - Compact version */}
      <div className="flex justify-end pt-1">
        <button
          className={cn(
            "px-4 py-1.5 rounded-lg text-xs",
            "bg-blue-50 dark:bg-blue-900/20",
            "text-blue-700 dark:text-blue-300",
            "border border-blue-200 dark:border-blue-700/30",
            "transition-all duration-200"
          )}
          onClick={onDisableDemoMode}
        >
          {t('zeroKnowledge.dialog.demoMode.exitButton', 'Exit Demo Mode')}
        </button>
      </div>
    </div>
  );
};

export default DemoModeView;