/**
 * @file src/components/devices/dialogs/DownloadDialog.tsx
 * @description Dialog showing download options for device agents
 * @version 1.0.2
 * @created 2025-02-27
 * @dependencies lucide-react, react-i18next, framer-motion, qrcode.react
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { QRCodeSVG } from 'qrcode.react';
import { 
  Monitor, 
  LaptopIcon, 
  Server, 
  Smartphone, 
  Info, 
  Download
} from 'lucide-react';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription,
  DialogFooter
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

import { logger } from '@/utils/logger';

interface DownloadDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DownloadDialog: React.FC<DownloadDialogProps> = ({
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();

  const handleDownload = (platform: string) => {
    logger.debug('Download clicked', {
      component: 'DownloadDialog',
      platform,
      action: 'download'
    });
    // In a real implementation, this would trigger the download or redirect to download page
    // For now, we just log it
  };

  const platforms = [
    {
      id: 'windows',
      name: t('devices.platforms.windows'),
      icon: LaptopIcon,
      description: t('devices.platforms.windows_description'),
      version: '2.5.1',
      downloadUrl: '#windows-download'
    },
    {
      id: 'macos',
      name: t('devices.platforms.macos'),
      icon: LaptopIcon,
      description: t('devices.platforms.macos_description'),
      version: '2.5.1',
      downloadUrl: '#macos-download'
    },
    {
      id: 'linux',
      name: t('devices.platforms.linux'),
      icon: Server,
      description: t('devices.platforms.linux_description'),
      version: '2.5.0',
      downloadUrl: '#linux-download'
    }
  ];

  const mobilePlatforms = [
    {
      id: 'ios',
      name: t('devices.platforms.ios'),
      icon: Smartphone,
      description: t('devices.platforms.ios_description'),
      qrUrl: 'https://apps.apple.com/app/bigmind-cloud-storage',
      appStoreUrl: '#ios-app-store'
    },
    {
      id: 'android',
      name: t('devices.platforms.android'),
      icon: Smartphone,
      description: t('devices.platforms.android_description'),
      qrUrl: 'https://play.google.com/store/apps/details?id=com.bigmind.app',
      playStoreUrl: '#android-play-store'
    }
  ];

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>{t('devices.download.title')}</DialogTitle>
          <DialogDescription>
            {t('devices.download.description')}
          </DialogDescription>
        </DialogHeader>

        <Tabs defaultValue="desktop" className="mt-4">
          <TabsList className="grid grid-cols-2 mb-4">
            <TabsTrigger value="desktop">{t('devices.download.desktop')}</TabsTrigger>
            <TabsTrigger value="mobile">{t('devices.download.mobile')}</TabsTrigger>
          </TabsList>

          {/* Desktop Tab */}
          <TabsContent value="desktop" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {platforms.map((platform) => (
                <Card key={platform.id} className="overflow-hidden">
                  <CardContent className="p-0">
                    <div className="p-4">
                      <div className="flex items-center mb-4">
                        <div className="rounded-full bg-blue-100 dark:bg-blue-900/20 p-2 mr-3">
                          <platform.icon className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900 dark:text-gray-100">{platform.name}</h3>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            {t('devices.download.version', { version: platform.version })}
                          </p>
                        </div>
                      </div>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                        {platform.description}
                      </p>
                    </div>
                    <div className="border-t border-gray-100 dark:border-gray-800 p-3 bg-gray-50 dark:bg-gray-900 flex justify-end">
                      <Button
                        variant="default"
                        size="sm"
                        onClick={() => handleDownload(platform.id)}
                      >
                        <Download className="h-4 w-4 mr-2" />
                        {t('devices.download.download_button')}
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>

            <div className="rounded-lg border border-blue-100 dark:border-blue-900/30 bg-blue-50 dark:bg-blue-900/10 p-4 flex gap-3">
              <Info className="h-5 w-5 text-blue-600 dark:text-blue-400 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-800 dark:text-blue-300">
                <p className="font-medium mb-1">{t('devices.download.installation_note_title')}</p>
                <p>{t('devices.download.installation_note')}</p>
              </div>
            </div>
          </TabsContent>

          {/* Mobile Tab */}
          <TabsContent value="mobile" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {mobilePlatforms.map((platform) => (
                <Card key={platform.id} className="overflow-hidden">
                  <CardContent className="p-4">
                    <div className="flex items-center mb-4">
                      <div className="rounded-full bg-blue-100 dark:bg-blue-900/20 p-2 mr-3">
                        <platform.icon className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                      </div>
                      <h3 className="font-medium text-gray-900 dark:text-gray-100">{platform.name}</h3>
                    </div>
                    
                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                      {platform.description}
                    </p>
                    
                    {platform.qrUrl && (
                      <div className="flex flex-col items-center mb-4">
                        <div className="border border-gray-200 dark:border-gray-700 p-4 rounded-lg bg-white dark:bg-gray-800 mb-2">
                          <div className="w-32 h-32 mx-auto flex items-center justify-center">
                            <QRCodeSVG
                              value={platform.qrUrl}
                              size={128}
                              level="H"
                              includeMargin={true}
                              className="w-full h-auto"
                            />
                          </div>
                        </div>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          {t('devices.download.scan_qr')}
                        </p>
                      </div>
                    )}
                    
                    <Button 
                      variant="outline" 
                      className="w-full"
                      onClick={() => handleDownload(platform.id)}
                    >
                      {platform.id === 'ios' 
                        ? t('devices.download.app_store') 
                        : t('devices.download.play_store')}
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </div>
          </TabsContent>
        </Tabs>

        <DialogFooter className="mt-6">
          <Button variant="outline" onClick={onClose}>
            {t('common.close')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadDialog;