/**
 * @file src/app/ghost/components/common/GhostDialog.tsx
 * @description Theme-aware dialog wrapper for ghost interface with fixed borders
 * @version 1.0.2
 * @updated 2025-04-01
 */

import React from 'react';
import { motion } from 'framer-motion';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useGhostTheme } from '@/app/ghost/providers/GhostThemeProvider';
import { GhostGlass } from './GhostGlass';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface GhostDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
  blur?: boolean;
  showGlass?: boolean;
}

export const GhostDialog: React.FC<GhostDialogProps> = ({
  open,
  onOpenChange,
  children,
  className,
  blur = true,
  showGlass,
}) => {
  const { theme } = useGhostTheme();
  const isQuantum = theme === 'quantum';
  
  // Default showGlass to true only for quantum theme
  const shouldShowGlass = showGlass ?? isQuantum;

  // logger.debug('Rendering GhostDialog', {
  //   component: 'GhostDialog'
  // });

  const content = (
    <motion.div
      className={cn(
        "relative w-full",
        !shouldShowGlass && [
          theme === 'day' ? "bg-white" : "bg-[hsl(var(--ghost-surface))]",
          // Fixed: Removed border to eliminate double border effect
          "rounded-xl p-6",
          // Fixed: Simplified shadow for non-glass mode
          theme === 'day' ? "shadow-md" : "shadow-lg"
        ],
        className
      )}
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 30
      }}
    >
      {shouldShowGlass ? (
        <GhostGlass
          className="relative p-6 overflow-hidden"
          animate
          opacity="high"
          blur={blur && isQuantum ? "md" : undefined}
        >
          {children}
        </GhostGlass>
      ) : (
        children
      )}
    </motion.div>
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={cn(
        "p-0 border-none shadow-none",  // Fixed: Added shadow-none to prevent double borders
        // Fixed: Adjusted background styling to be more clean
        isQuantum 
          ? "bg-transparent" 
          : "bg-background/95 backdrop-blur-sm"
      )}>
        {content}
      </DialogContent>
    </Dialog>
  );
};