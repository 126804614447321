/**
 * @file src/contexts/AccountContext.tsx
 * @description Global account context provider
 * @version 1.0.0
 * @created 2024-01-27
 */

import React, { createContext, useContext, useMemo } from 'react';
import { useAccountInfo } from '@/hooks/useAccountInfo';
import type { AccountInfo } from '@/types/account.types';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

interface AccountContextType {
  accountInfo: AccountInfo | undefined;
  isLoading: boolean;
  error: Error | null;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<AccountInfo, Error>>;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const AccountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: accountInfo, isLoading, error, refetch } = useAccountInfo();

  const value = useMemo(() => ({
    accountInfo,
    isLoading,
    error,
    refetch,
  }), [accountInfo, isLoading, error, refetch]);

  return (
    <AccountContext.Provider value={value}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};