/**
 * @file src/services/cloud-drive.service.ts
 * @description Cloud drive API service with complete API integration
 * @version 1.2.0
 * @created 2024-12-23
 * @updated 2024-12-23
 * @dependencies @/types/cloud-drive.types, @/services/auth.service
 */

import { authService } from './auth.service';
// Import auth utility but rename to avoid conflict
import { getAuthHeaders as apiUtilsGetAuthHeaders } from '@/utils/api-utils';
import type { 
  DriveFile, 
  DriveFolder, 
  DriveItemsResponse,
  ShareSettings,
  StorageQuota,
  FilterConfig,
  SortConfig,
  ItemDetailsResponse 
} from '@/types/cloud-drive.types';

interface UploadResponse {
  id: string;
  name: string;
  size: number;
  type: string;
  parentId: string;
  url: string;
}

interface BatchItemsRequest {
  items: string[];
  targetFolderId: string;
  overwrite?: boolean;
}

interface BatchOperationResponse {
  success: boolean;
  processedItems: number;
  failedItems: number;
  errors?: Record<string, string>;
}

// Add imports at the top
import type {
  Device,
  Drive,
  DevicesResponse,
  DrivesResponse,
  SystemOverview,
  DeviceWithDrives
} from '@/types/root-drive.types';


import { logger } from '@/utils/logger';

const BASE_URL = '/api/v1/cloud-drive';

const CLOUD_DRIVE_ID_KEY = 'bigmind_cloud_drive_id';
const DEFAULT_CLOUD_DRIVE_ID = 'cloud-drive';

// Helper to check if string is a valid UUID
const isValidUUID = (uuid: string) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

// Helper to get auth headers
const getAuthHeaders = (requireContentType = true) => {
  try {
    const tokens = authService.getStoredToken();
    if (!tokens || !tokens.accessToken || !authService.isValidToken(tokens.accessToken)) {
      console.warn('Invalid or missing auth token');
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
    }

    return {
      'Accept': 'application/json',
      'Content-Type': requireContentType ? 'application/json' : undefined,
      'Authorization': tokens.accessToken
    };
  } catch (error) {
    console.error('Error getting auth headers:', error);
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }
};

// Helper to handle API errors
const handleApiError = async (response: Response, errorMessage: string) => {
  if (!response.ok) {
    const errorText = await response.text();
    console.error(`${errorMessage}:`, errorText);
    throw new Error(errorText || errorMessage);
  }
  return response.json();
};

export const cloudDriveService = {

  // List all devices
  listDevices: async (): Promise<DevicesResponse> => {
    try {
      logger.debug('Fetching devices list', {
        component: 'cloudDriveService',
        action: 'listDevices'
      });

      const response = await fetch(`${BASE_URL}/devices`, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch devices');
      }

      const data = await response.json();
      
      logger.debug('Devices fetch successful', {
        component: 'cloudDriveService',
        action: 'listDevices',
        data: {
          deviceCount: data.items?.length,
          devices: data.items?.map((d: any) => ({
            id: d.id,
            type: d.device_type,
            status: d.status
          }))
        }
      });
      
      return data;
    } catch (error) {
      logger.error('Error listing devices:', {
        component: 'cloudDriveService',
        error
      });
      throw error;
    }
  },

  // List drives for a device
  listDeviceDrives: async (deviceId: string): Promise<DrivesResponse> => {
    try {
      logger.debug('Fetching drives for device', {
        component: 'cloudDriveService',
        action: 'listDeviceDrives',
        data: { deviceId }
      });

      const response = await fetch(`${BASE_URL}/devices/${deviceId}/drives`, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch drives for device: ${deviceId}`);
      }

      const data = await response.json();
      
      logger.debug('Drives fetch successful', {
        component: 'cloudDriveService',
        action: 'listDeviceDrives',
        data: {
          deviceId,
          driveCount: data.items?.length,
          drives: data.items?.map((d: any) => ({
            id: d.id,
            type: d.type,
            name: d.name
          }))
        }
      });
      
      return data;
    } catch (error) {
      logger.error('Error listing device drives:', {
        component: 'cloudDriveService',
        error,
        data: { deviceId }
      });
      throw error;
    }
  },

  // Get system overview
  getSystemOverview: async (): Promise<Omit<SystemOverview, 'uploadStrategy'>> => {
  try {
      logger.debug('Starting system overview fetch', {
        component: 'cloudDriveService',
        action: 'getSystemOverview'
      });

      // First get all devices
      const { items: devices = [] } = await cloudDriveService.listDevices();
      
      // Find system device
      const systemDevice = devices.find((d: any) => 
        d.device_type === 'system' && d.config?.isRoot
      );

      if (!systemDevice) {
        logger.warn('No system device found', {
          component: 'cloudDriveService',
          action: 'getSystemOverview'
        });
      }

      // Get drives for each device
      const devicesWithDrives = await Promise.all(
        devices.map(async (device) => {
          try {
            const { items: drives = [] } = await cloudDriveService.listDeviceDrives(device.id);
            
            logger.debug('Drives fetched for device', {
              component: 'cloudDriveService',
              action: 'getSystemOverview',
              data: {
                deviceId: device.id,
                deviceType: device.device_type,
                driveCount: drives.length
              }
            });

            return { ...device, drives };
          } catch (error) {
            logger.error('Error fetching drives for device:', {
              component: 'cloudDriveService',
              error,
              data: { deviceId: device.id }
            });
            return { ...device, drives: [] };
          }
        })
      )

      // Find cloud drive in system device
      const systemDeviceWithDrives = devicesWithDrives.find((d: any) => 
        d.device_type === 'system' && d.config?.isRoot
      );
      
      let primaryDriveId: string | null = null;

      if (systemDeviceWithDrives) {
        const cloudDrive = systemDeviceWithDrives.drives.find((d: any) => 
          d.type === 'cloud_drive'
        );
        
        if (cloudDrive) {
          primaryDriveId = cloudDrive.id;
          logger.debug('Cloud drive found', {
            component: 'cloudDriveService',
            action: 'getSystemOverview',
            data: {
              driveId: primaryDriveId,
              deviceId: systemDeviceWithDrives.id
            }
          });

          localStorage.setItem('bigmind_cloud_drive_id', primaryDriveId);
        } else {
          logger.warn('No cloud drive found in system device', {
            component: 'cloudDriveService',
            action: 'getSystemOverview'
          });
        }
      }

      const overview = {
        devices: devicesWithDrives,
        totalDevices: devices.length,
        totalDrives: devicesWithDrives.reduce((acc: any, d: any) => acc + d.drives.length, 0),
        primaryDriveId
      };

      logger.debug('System overview complete', {
        component: 'cloudDriveService',
        data: {
          totalDevices: overview.totalDevices,
          totalDrives: overview.totalDrives,
          hasPrimaryDrive: !!overview.primaryDriveId
        }
      });

      return overview;

    } catch (error) {
      logger.error('Error getting system overview:', {
        component: 'cloudDriveService',
        error
      });
      throw error;
    }
  },

  // List folder contents
  listFolderContents: async (
    folderId: string | null = null,
    cursor?: string,
    filterConfig?: FilterConfig,
    sortConfig?: SortConfig
  ): Promise<DriveItemsResponse> => {
    try {
      logger.debug('Listing folder contents', {
        component: 'cloudDriveService',
        data: { folderId, cursor, filterConfig, sortConfig }
      });

      const params = new URLSearchParams();
      if (folderId) {
        params.append('folder_id', folderId);
      }
      if (cursor) {
        params.append('cursor', cursor);
      }
      if (filterConfig?.search) {
        params.append('search', filterConfig.search);
      }
      if (sortConfig) {
        params.append('sort_by', sortConfig.field);
        params.append('sort_order', sortConfig.order);
      }

      const response = await fetch(`${BASE_URL}/items?${params}`, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      const result = await handleApiError(response, 'Error listing folder contents');

      // Get the drive ID from response or use default for cloud drive
      const driveId = result?.driveId || localStorage.getItem('bigmind_cloud_drive_id') || DEFAULT_CLOUD_DRIVE_ID;


      // Store cloud drive ID when received
      if (driveId) {
        logger.debug('Setting drive ID', {
          component: 'cloudDriveService',
          action: 'listFolderContents',
          data: { driveId }
        });
        localStorage.setItem('bigmind_cloud_drive_id', driveId);
      }

      // Return the stored drive ID if none in response
      return {
        items: Array.isArray(result?.items) ? result.items : [],
        total: result?.total ?? 0,
        hasMore: !!result?.hasMore,
        nextCursor: result?.nextCursor || null,
        driveId // Always include a drive ID
      };
    } catch (error) {
      logger.error('Error listing folder contents:', {
        component: 'cloudDriveService',
        error,
        data: { folderId }
      });
      throw error;
    }
  },
  
  // Upload files with progress tracking
  uploadFiles: async (
    files: File[], 
    parentId: string | null = null,
    onProgress?: (progress: number) => void
  ): Promise<UploadResponse[]> => {
    try {
      const formData = new FormData();
      files.forEach((file: any) => formData.append('files', file));
      if (parentId) formData.append('parent_id', parentId);
  
      const xhr = new XMLHttpRequest();
      
      return new Promise((resolve, reject) => {
        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable && onProgress) {
            const progress = (event.loaded / event.total) * 100;
            onProgress(progress);
          }
        });
  
        xhr.addEventListener('load', () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(new Error(`Upload failed: ${xhr.statusText}`));
          }
        });
  
        xhr.addEventListener('error', () => {
          reject(new Error('Upload failed'));
        });
  
        xhr.open('POST', `${BASE_URL}/upload`);
        const headers = getAuthHeaders();
        Object.entries(headers).forEach(([key, value]) => {
          if (key !== 'Content-Type' && value !== undefined) { // Skip Content-Type for FormData
            xhr.setRequestHeader(key, value as string);
          }
        });
        xhr.withCredentials = true;
        xhr.send(formData);
      });
    } catch (error) {
      console.error('Error uploading files:', error);
      throw error;
    }
  },

  // Create new folder
  createFolder: async (name: string, driveId?: string | null, parentId: string | null = null): Promise<Omit<DriveFolder, 'labels'>> => {
    try {
      logger.debug('Creating folder - Start', {
        component: 'cloudDriveService',
        data: { 
          name, 
          driveId, 
          parentId,
          currentDriveType: localStorage.getItem('current_drive_type')
        }
      });

      // Check if this is an encrypted drive - look for explicit type in localStorage
      const isEncryptedDrive = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      
      // If this is an encrypted drive, use the encrypted folder service
      if (isEncryptedDrive) {
        // If no drive ID provided, try to get it from localStorage
        const effectiveEncryptedDriveId = driveId || localStorage.getItem('encrypted_drive_id');
        
        if (!effectiveEncryptedDriveId) {
          logger.error('No encrypted drive ID available', {
            component: 'cloudDriveService',
            data: { 
              providedDriveId: driveId,
              storedEncryptedId: localStorage.getItem('encrypted_drive_id')
            }
          });
          throw new Error('No active drive selected');
        }
        
        logger.debug('Using encrypted folder service', {
          component: 'cloudDriveService',
          data: { 
            effectiveEncryptedDriveId
          }
        });
        
        // Dynamically import to avoid circular dependencies
        const { encryptedFolderService } = await import('./encrypted-drive/encrypted-folder.service');
        return encryptedFolderService.createFolder(name, effectiveEncryptedDriveId, parentId);
      }

      // Continue with regular folder creation
      // Use FormData for the request
      const formData = new FormData();
      
      // Get drive ID with multiple fallbacks for standard drives
      const effectiveDriveId = driveId || 
        localStorage.getItem('bigmind_active_drive_id') ||
        localStorage.getItem('bigmind_cloud_drive_id');
          
      if (!effectiveDriveId) {
        logger.error('No standard drive ID available', {
          component: 'cloudDriveService',
          data: {
            providedDriveId: driveId,
            storedActiveId: localStorage.getItem('bigmind_active_drive_id'),
            storedCloudId: localStorage.getItem('bigmind_cloud_drive_id')
          }
        });
        throw new Error('No active drive selected');
      }

      formData.append('name', name);
      formData.append('drive_id', effectiveDriveId);
      if (parentId) {
        formData.append('parent_id', parentId);
      }

      // Important: Use null for content-type to let browser set it
      const headers = getAuthHeaders(false);
      delete headers['Content-Type']; // Remove content-type for FormData

      const response = await fetch(`${BASE_URL}/folders`, {
        method: 'POST',
        headers: headers as HeadersInit,
        body: formData,
        credentials: 'include'
      });

      // Add debug logging for the request
      logger.debug('Create folder request details', {
          component: 'cloudDriveService',
          data: {
              formData: Object.fromEntries(formData),
              headers: headers as HeadersInit,
              url: `${BASE_URL}/folders`
          }
      });

      if (!response.ok) {
          const errorData = await response.json();
          logger.error('Server error response:', {
              component: 'cloudDriveService',
              data: {
                  status: response.status,
                  statusText: response.statusText,
                  errorData
              }
          });
          throw new Error(JSON.stringify(errorData));
      }

      const result = await response.json();
      
      // Store drive ID from response if available
      if (result.driveId) {
          localStorage.setItem('bigmind_active_drive_id', result.driveId);
      }

      logger.debug('Folder created successfully', {
          component: 'cloudDriveService',
          data: { result }
      });

      return result;
  } catch (error) {
      logger.error('Error creating folder:', {
          component: 'cloudDriveService',
          error,
          data: { name, driveId, parentId }
      });
      throw error;
  }
},

  // Get folder details
  getFolderDetails: async (folderId: string): Promise<DriveFolder> => {
    try {
        logger.debug('Fetching folder details', {
          component: 'cloudDriveService',
          data: { folderId }
      });

      // Handle non-UUID folder IDs
      if (folderId && !isValidUUID(folderId)) {
          logger.warn('Invalid folder ID format, treating as root', {
              component: 'cloudDriveService',
              data: { folderId }
          });
          folderId = ''; // Treat as root folder
      }

      const response = await fetch(`${BASE_URL}/items?folder_id=${folderId || ''}&page_size=1`, {
          headers: getAuthHeaders() as HeadersInit,
          credentials: 'include'
      });

      const data = await handleApiError(response, 'Failed to get folder details');
      
      logger.debug('Received folder response', {
        component: 'cloudDriveService',
        data: {
          folderId,
          response: data
        }
      });

      // Validate response structure
      if (!data || !data.items || !Array.isArray(data.items) || data.items.length === 0) {
        logger.error('Invalid or empty folder response', {
          component: 'cloudDriveService',
          data
        });
        throw new Error('Folder not found or invalid response');
      }

      // Get parent folder details from the first item
      const parentDetails = data.items[0];
      
      // Transform the response to match DriveFolder type
      const folderDetails: DriveFolder = {
        id: folderId,
        name: parentDetails.name,
        type: 'folder',
        parentId: parentDetails.parentId,
        path: parentDetails.path || [],
        created: parentDetails.created,
        modified: parentDetails.modified,
        createdBy: parentDetails.createdBy,
        modifiedBy: parentDetails.modifiedBy,
        shared: parentDetails.shared || false,
        favorite: parentDetails.favorite || false,
        itemCount: parentDetails.itemCount || 0,
        totalSize: parentDetails.totalSize || 0,
        labels: []
      };

      logger.debug('Processed folder details', {
        component: 'cloudDriveService',
        data: folderDetails
      });

      return folderDetails;
    } catch (error) {
      logger.error('Error getting folder details:', error);
      throw error;
    }
  },

  // Rename item
  renameItem: async (itemId: string, newName: string): Promise<DriveFile | DriveFolder> => {
    try {
      const formData = new FormData();
      formData.append('name', newName);

      const response = await fetch(`${BASE_URL}/items/${itemId}/rename`, {
        method: 'PUT',
        headers: getAuthHeaders(false) as HeadersInit,
        body: formData,
        credentials: 'include'
      });

      return handleApiError(response, 'Failed to rename item');
    } catch (error) {
      console.error('Error renaming item:', error);
      throw error;
    }
  },

  // Move items
  moveItems: async (request: BatchItemsRequest): Promise<BatchOperationResponse> => {
    try {
      const response = await fetch(`${BASE_URL}/items/move`, {
        method: 'POST',
        headers: getAuthHeaders() as HeadersInit,
        body: JSON.stringify(request),
        credentials: 'include'
      });

      return handleApiError(response, 'Failed to move items');
    } catch (error) {
      console.error('Error moving items:', error);
      throw error;
    }
  },

  // Copy items
  copyItems: async (request: BatchItemsRequest): Promise<BatchOperationResponse> => {
    try {
      const response = await fetch(`${BASE_URL}/items/copy`, {
        method: 'POST',
        headers: getAuthHeaders() as HeadersInit,
        body: JSON.stringify(request),
        credentials: 'include'
      });

      return handleApiError(response, 'Failed to copy items');
    } catch (error) {
      console.error('Error copying items:', error);
      throw error;
    }
  },

  // Delete items
  deleteItems: async (itemIds: string[]): Promise<BatchOperationResponse> => {
    try {
      const response = await fetch(`${BASE_URL}/items`, {
        method: 'DELETE',
        headers: getAuthHeaders() as HeadersInit,
        body: JSON.stringify({ items: itemIds }),
        credentials: 'include'
      });

      return handleApiError(response, 'Failed to delete items');
    } catch (error) {
      console.error('Error deleting items:', error);
      throw error;
    }
  },

  // Get share settings
  getShareSettings: async (itemId: string): Promise<ShareSettings[]> => {
    try {
      const response = await fetch(`${BASE_URL}/items/${itemId}/shares`, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      return handleApiError(response, 'Failed to get share settings');
    } catch (error) {
      console.error('Error getting share settings:', error);
      throw error;
    }
  },

  // Update share settings
  updateShareSettings: async (
    itemId: string, 
    settings: ShareSettings
  ): Promise<ShareSettings> => {
    try {
      const response = await fetch(`${BASE_URL}/items/${itemId}/shares`, {
        method: 'PUT',
        headers: getAuthHeaders() as HeadersInit,
        body: JSON.stringify(settings),
        credentials: 'include'
      });

      return handleApiError(response, 'Failed to update share settings');
    } catch (error) {
      console.error('Error updating share settings:', error);
      throw error;
    }
  },

  // Get storage quota
  getStorageQuota: async (): Promise<StorageQuota> => {
    try {
      const response = await fetch(`${BASE_URL}/quota`, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      return handleApiError(response, 'Failed to get storage quota');
    } catch (error) {
      console.error('Error getting storage quota:', error);
      throw error;
    }
  },

  // Search items
  searchItems: async (query: string): Promise<DriveItemsResponse> => {
    try {
      const response = await fetch(`${BASE_URL}/search?q=${encodeURIComponent(query)}`, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      return handleApiError(response, 'Failed to search items');
    } catch (error) {
      console.error('Error searching items:', error);
      throw error;
    }
  },

  // Get item details - now with encryption support
  getItemDetails: async (itemId: string, encryptedDriveId?: string): Promise<ItemDetailsResponse> => {
    try {
      console.log('Fetching item details for:', itemId, { encryptedDriveId });
      
      // Determine if we need to use the encrypted drive API
      let url = `${BASE_URL}/items/${itemId}/details`;
      let headers = getAuthHeaders() as HeadersInit;
      
      // Try to get stored encryption context for this specific item view
      let storedContext = null;
      try {
        const contextStr = localStorage.getItem('encrypted_item_view_context');
        if (contextStr) {
          const parsed = JSON.parse(contextStr);
          if (parsed.itemId === itemId && parsed.isEncryptedMode && parsed.encryptedDriveId) {
            storedContext = parsed;
            console.log('Using stored encryption context for item details', storedContext);
          }
        }
      } catch (e) {
        console.warn('Error reading stored encryption context', e);
      }
      
      // If we have an encrypted drive ID (either passed in or from stored context), use the encrypted API endpoint
      const effectiveEncryptedDriveId = encryptedDriveId || (storedContext?.encryptedDriveId);
      
      if (effectiveEncryptedDriveId) {
        url = `${BASE_URL}/encrypted-drives/${effectiveEncryptedDriveId}/items/${itemId}/details`;
        console.log('Using encrypted API endpoint:', url);
        
        // Get encryption headers if needed
        try {
          const { encryptedDriveService } = await import('./encrypted-drive/encrypted-drive.service');
          const encryptionHeaders = await encryptedDriveService.getEncryptionHeaders(effectiveEncryptedDriveId);
          if (encryptionHeaders) {
            headers = { ...headers, ...encryptionHeaders };
            console.log('Applied encryption headers', Object.keys(encryptionHeaders));
          }
        } catch (e) {
          console.warn('Could not get encryption headers:', e);
        }
      }
      
      const response = await fetch(url, {
        headers,
        credentials: 'include'
      });

      if (!response.ok) {
        // Check if this is a 404 for an encrypted item
        if (response.status === 404 && effectiveEncryptedDriveId) {
          console.warn('Item not found in encrypted drive, attempting to use base item data as fallback');
          
          // This might be an item with a format like "encrypted:1e8dcfe57678e04082c73f87df79a64d"
          // Let's try to parse the ID and construct a minimal item details object
          try {
            // For encrypted items, we might have a prefix or different format
            // Check if the ID contains dashes (UUID format) or not
            const isUuid = itemId.includes('-');
            const cleanId = isUuid ? itemId : itemId.replace('encrypted:', '');
            
            // Get any previously stored data about this item that might have context
            try {
              // Check if we have any stored item data
              const storedItemData = localStorage.getItem(`encrypted_item_${cleanId}`);
              if (storedItemData) {
                const parsedItem = JSON.parse(storedItemData);
                console.log('Using stored item data as fallback:', parsedItem);
                return { data: parsedItem };
              }
            } catch (e) {
              console.warn('Error retrieving stored item data:', e);
            }
            
            // Construct a minimal response
            const fallbackResponse = {
              id: cleanId,
              name: `Encrypted Item ${cleanId.substring(0, 8)}`,
              type: isUuid ? 'file' : 'folder', // Guess based on ID format
              size: 0,
              created: new Date().toISOString(),
              modified: new Date().toISOString(),
              encrypted: true,
              favorite: false,
              isEncrypted: true,
              // Add minimal owner data
              owner: {
                id: 'current-user',
                name: 'Current User', 
                email: ''
              }
            };
            
            console.log('Returning fallback response for encrypted item:', fallbackResponse);
            return { data: fallbackResponse };
          } catch (fallbackError) {
            console.error('Error creating fallback response:', fallbackError);
            // Continue to throw the original error
          }
        }
        
        // If we got here, either it's not a 404 or fallback creation failed
        try {
          const errorData = await response.json();
          console.error('Server error:', errorData);
          throw new Error(errorData.detail || 'Failed to get item details');
        } catch (parseError) {
          // Handle case where error response isn't valid JSON
          console.error('Error parsing error response:', parseError);
          throw new Error(`Failed to get item details: ${response.status} ${response.statusText}`);
        }
      }

      const data = await response.json();
      console.log('Received item details:', data);
      
      // Ensure user fields are properly mapped (with safeguards)
      const mappedData = {
        ...data,
        owner: data.owner ? {
          id: data.owner.id || 'unknown',
          name: data.owner.name || 'Unknown',
          email: data.owner.email || '',
          avatar: data.owner.avatar
        } : { id: 'unknown', name: 'Unknown', email: '' },
        createdBy: data.createdBy ? {
          id: data.createdBy.id || 'unknown',
          name: data.createdBy.name || 'Unknown',
          email: data.createdBy.email || ''
        } : { id: 'unknown', name: 'Unknown', email: '' },
        modifiedBy: data.modifiedBy ? {
          id: data.modifiedBy.id || 'unknown',
          name: data.modifiedBy.name || 'Unknown',
          email: data.modifiedBy.email || ''
        } : { id: 'unknown', name: 'Unknown', email: '' }
      };

      return { data: mappedData };
    } catch (error) {
      console.error('Error getting item details:', error);
      throw error;
    }
  },

    // Download file
  downloadFile: async (itemId: string): Promise<Blob> => {
    try {
      logger.debug('Starting file download', {
        component: 'cloudDriveService',
        data: { itemId }
      });

      const response = await fetch(`${BASE_URL}/items/${itemId}/download`, {
        headers: getAuthHeaders() as HeadersInit,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('Download failed:', {
          component: 'cloudDriveService',
          data: {
            status: response.status,
            statusText: response.statusText,
            error: errorText
          }
        });
        throw new Error(errorText || 'Failed to download file');
      }

      return await response.blob();
    } catch (error) {
      logger.error('Error downloading file:', {
        component: 'cloudDriveService',
        error,
        data: { itemId }
      });
      throw error;
    }
  },



};