/**
 * @file src/layouts/ghost/components/create-core/steps/StepWelcome.tsx
 * @description Welcome step with enhanced visuals while maintaining core functionality
 * @version 2.0.0
 */

import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Ghost } from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { useGhostTheme } from '@/app/ghost/providers/GhostThemeProvider';
import { cn } from '@/utils/utils';

interface StepWelcomeProps {
  onNext: () => void;
}

export const StepWelcome: React.FC<StepWelcomeProps> = ({ onNext }) => {
  const { t } = useGhostTranslation();
  const { theme } = useGhostTheme();
  const isQuantum = theme === 'quantum';

  const features = [
    {
      icon: Shield,
      title: t('create.welcome.features.shield.title'),
      description: t('create.welcome.features.shield.description'),
      gradient: "from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]"
    },
    {
      icon: Lock,
      title: t('create.welcome.features.privacy.title'),
      description: t('create.welcome.features.privacy.description'),
      gradient: "from-[hsl(var(--ghost-accent-secondary))] to-[hsl(var(--ghost-accent-tertiary))]"
    },
    {
      icon: Ghost,
      title: t('create.welcome.features.emergency.title'),
      description: t('create.welcome.features.emergency.description'),
      gradient: "from-[hsl(var(--ghost-accent-tertiary))] to-[hsl(var(--ghost-accent))]"
    }
  ];


  return (
    <div className="space-y-8">
      {/* Header Section */}
      <div className="space-y-2 text-center relative">
        {/* Animated Ghost Icon */}
        <motion.div
          className="flex justify-center mb-6"
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <motion.div
            animate={{
              y: [0, -8, 0],
              rotate: [0, -5, 5, 0]
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="relative"
          >
            <div className="absolute inset-0 blur-2xl opacity-50 bg-[hsl(var(--ghost-accent))]" />
            <Ghost className="relative w-16 h-16 text-[hsl(var(--ghost-accent))]" />
            
            {/* Quantum theme particles */}
            {isQuantum && (
              <motion.div 
                className="absolute inset-0"
                animate={{
                  background: [
                    'radial-gradient(circle at 30% 50%, hsl(var(--ghost-accent)/0.5) 0%, transparent 50%)',
                    'radial-gradient(circle at 70% 50%, hsl(var(--ghost-accent)/0.5) 0%, transparent 50%)',
                    'radial-gradient(circle at 30% 50%, hsl(var(--ghost-accent)/0.5) 0%, transparent 50%)'
                  ],
                }}
                transition={{ duration: 4, repeat: Infinity }}
              />
            )}
          </motion.div>
        </motion.div>

        <h2 className={cn(
          "text-2xl sm:text-3xl font-bold",
          "bg-clip-text text-transparent",
          "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]"
        )}>
          {t('create.welcome.title') as React.ReactNode}
        </h2>
        
        <p className="text-[hsl(var(--ghost-foreground-muted))] text-sm sm:text-base">
          {t('create.welcome.subtitle') as React.ReactNode}
        </p>
      </div>

      {/* Features Grid */}
      <div className="grid gap-4">
        {features.map((feature, index) => (
          <motion.button
            key={`${index}`} 
            onClick={onNext}
            initial={{ opacity: 0, x: -20 }}
            animate={{ 
              opacity: 1, 
              x: 0,
              transition: { delay: index * 0.1 }
            }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={cn(
              "relative w-full p-4 rounded-xl overflow-hidden text-left",
              "bg-[hsl(var(--ghost-surface))]",
              "border border-[hsl(var(--ghost-accent))/0.2]",
              "group hover:bg-[hsl(var(--ghost-accent))/0.1]",
              "transition-colors duration-200"
            )}
          >
            {/* Gradient background on hover */}
            <motion.div
              className="absolute inset-0 opacity-0 group-hover:opacity-100"
              initial={false}
              animate={{
                background: [
                  `linear-gradient(to right, ${feature.gradient})`,
                  `linear-gradient(to left, ${feature.gradient})`,
                  `linear-gradient(to right, ${feature.gradient})`
                ],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "linear"
              }}
              style={{ opacity: 0.05 }}
            />

            <div className="relative flex items-start space-x-4">
              <div className={cn(
                "p-2 rounded-lg",
                "bg-[hsl(var(--ghost-surface))]",
                "group-hover:bg-[hsl(var(--ghost-accent))/0.1]",
                "transition-colors duration-200"
              )}>
                <feature.icon className="w-5 h-5 text-[hsl(var(--ghost-accent))]" />
              </div>

              <div>
                <h3 className="text-sm font-medium text-[hsl(var(--ghost-foreground))]">
                  {feature.title as React.ReactNode}
                </h3>
                <p className="mt-1 text-sm text-[hsl(var(--ghost-foreground-muted))]">
                  {feature.description as React.ReactNode}
                </p>
              </div>
            </div>
          </motion.button>
        ))}
      </div>

      {/* Start Button */}
      <motion.button
        onClick={onNext}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
        className={cn(
          "w-full px-8 py-3 rounded-xl",
          "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
          "text-white font-medium",
          "relative overflow-hidden"
        )}
      >
        {/* Button glow effect */}
        <motion.div
          className="absolute inset-0"
          animate={{
            opacity: [0, 0.2, 0],
            background: [
              'radial-gradient(circle at 20% 50%, white 0%, transparent 50%)',
              'radial-gradient(circle at 80% 50%, white 0%, transparent 50%)',
              'radial-gradient(circle at 20% 50%, white 0%, transparent 50%)'
            ],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: "linear"
          }}
        />
        
        <span className="relative">
          {t('create.welcome.start_button') as React.ReactNode}
        </span>
      </motion.button>
    </div>
  );
};

export default StepWelcome;