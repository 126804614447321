/**
 * @file src/components/encrypted-drive/status/DecryptionStatus.tsx
 * @description Enhanced decryption status component with better visual feedback and lazy loading
 * @version 1.2.0
 * @created 2025-02-25
 * @updated 2025-03-19
 */

import React, { lazy, Suspense, memo } from 'react';
import { useCloudDrive } from '@/context/cloud-drive';

// Props interface
interface DecryptionStatusProps {
  className?: string;
}

// Lazy-loaded content component to prevent translation issues
const DecryptionStatusContent = lazy(() => import('./DecryptionStatusContent'));

export const DecryptionStatus: React.FC<DecryptionStatusProps> = memo(({ className }) => {
  const { encryptedDrive } = useCloudDrive();
  
  // Don't render anything if not in encrypted mode
  if (!encryptedDrive.isEncryptedMode) return null;
  
  // Use Suspense to handle the lazy loading
  return (
    <Suspense fallback={null}>
      <DecryptionStatusContent className={className} />
    </Suspense>
  );
});

DecryptionStatus.displayName = 'DecryptionStatus';

export default DecryptionStatus;