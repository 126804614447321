/**
 * @file: components/media/MediaCard.tsx
 * @description: Card component for displaying individual media items
 * @version: 1.0.0
 */

import React, { useState } from 'react';
import { MediaItem, mediaService } from '../../services/media.service';
import { Card } from '@/components/ui/card';
import { CheckCircle, Image, Video, Star, Lock } from 'lucide-react';
import { format } from 'date-fns';

interface MediaCardProps {
  item: MediaItem;
  isSelected: boolean;
  onClick: (event: React.MouseEvent) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isHovered: boolean;
}

export const MediaCard: React.FC<MediaCardProps> = ({
  item,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isHovered
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  // Format the date for display
  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Unknown date';
    try {
      const date = new Date(dateString);
      return format(date, 'MMM d, yyyy');
    } catch (e) {
      return 'Invalid date';
    }
  };

  // Handle image load error
  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  // Handle image load success
  const handleLoad = () => {
    setIsLoading(false);
  };

  // Generate appropriate icon based on media type and encryption status
  const getTypeIcon = () => {
    if (item.is_encrypted) return <Lock className="h-5 w-5 text-blue-500" />;
    if (item.media_type === 'video') return <Video className="h-5 w-5 text-purple-500" />;
    return <Image className="h-5 w-5 text-indigo-500" />;
  };

  // Generate media URL
  const thumbnailUrl = mediaService.getMediaSrc(item, true);
  
  // Set aspect ratio based on dimensions if available
  const aspectRatio = item.dimensions
    ? `${item.dimensions.width} / ${item.dimensions.height}`
    : '1 / 1';

  return (
    <Card
      className={`overflow-hidden transition-all duration-200 cursor-pointer group ${
        isSelected 
          ? 'ring-2 ring-offset-2 ring-primary' 
          : isHovered 
            ? 'ring-1 ring-offset-1 ring-gray-300 dark:ring-gray-600' 
            : ''
      }`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="relative">
        {/* Preview container */}
        <div
          className="bg-gray-100 dark:bg-gray-800 relative overflow-hidden"
          style={{ paddingTop: '75%' }} // 4:3 aspect ratio container
        >
          {/* Loading skeleton */}
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-200 dark:bg-gray-700 animate-pulse">
              {getTypeIcon()}
            </div>
          )}
          
          {/* Error fallback */}
          {hasError && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100 dark:bg-gray-800">
              {getTypeIcon()}
              <span className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                {item.is_encrypted ? 'Encrypted media' : 'Unable to load preview'}
              </span>
            </div>
          )}
          
          {/* Actual image */}
          {!item.is_encrypted && (
            <img
              src={thumbnailUrl}
              alt={item.name}
              className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-200 ${
                isLoading ? 'opacity-0' : 'opacity-100'
              }`}
              onError={handleError}
              onLoad={handleLoad}
            />
          )}
          
          {/* Encrypted media placeholder */}
          {item.is_encrypted && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100 dark:bg-gray-800">
              <Lock className="h-8 w-8 text-blue-500 mb-2" />
              <span className="text-xs text-gray-500 dark:text-gray-400">
                Encrypted {item.media_type}
              </span>
            </div>
          )}
          
          {/* Video duration */}
          {item.media_type === 'video' && item.duration && !item.is_encrypted && (
            <div className="absolute bottom-2 right-2 bg-black bg-opacity-60 text-white text-xs px-1 py-0.5 rounded">
              {Math.floor(item.duration / 60)}:{Math.floor(item.duration % 60).toString().padStart(2, '0')}
            </div>
          )}
          
          {/* Selection indicator */}
          {isSelected && (
            <div className="absolute top-2 right-2 z-10">
              <CheckCircle className="h-6 w-6 text-primary fill-white" />
            </div>
          )}
          
          {/* Favorite indicator */}
          {item.is_favorite && (
            <div className="absolute top-2 left-2 z-10">
              <Star className="h-5 w-5 text-yellow-400 fill-yellow-400" />
            </div>
          )}
        </div>
        
        {/* Info bar */}
        <div className="p-2 text-xs bg-white dark:bg-gray-900">
          <div className="truncate font-medium" title={item.name}>
            {item.name}
          </div>
          <div className="flex justify-between text-gray-500 dark:text-gray-400 mt-1">
            <span>{formatDate(item.capture_date || item.created_at)}</span>
            <span>{(item.size / 1024 / 1024).toFixed(1)} MB</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MediaCard;