/**
 * @file src/context/cloud-drive/hooks/useDriveOperations.ts
 * @description Operation hooks for cloud drive with download support, rename and favorite toggling
 * @version 1.3.0
 */

import React from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/components/ui/toast';
import { cloudDriveService } from '@/services/cloud-drive.service';
import { encryptedDriveApi } from '@/services/encrypted-drive/encrypted-drive.service';
import { encryptionService } from '@/services/encrypted-drive/encryption-singleton';
import { useDriveState } from './useDriveState';
import { logger } from '@/utils/logger';
import type { DriveItem, DriveFolder, DriveItemUser, BatchItemsRequest, BatchOperationResponse } from '@/types/cloud-drive.types';

import { 
  renameEncryptedItem, 
  deleteEncryptedItems, 
  moveEncryptedItems 
} from '@/services/encrypted-drive/encrypted-drive-operations';

// Store renamed items in memory to ensure persistence
const renamedItems = new Map<string, string>();

export const useDriveOperations = () => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();
  const { 
    currentFolder,
    currentFolderContents,
    setCurrentFolderContents,
    isFolderDialogOpen,
    setIsFolderDialogOpen,
    clearSelection
  } = useDriveState();

  // Get activeDriveId from localStorage
  const activeDriveId = localStorage.getItem('bigmind_active_drive_id') || 
                        localStorage.getItem('bigmind_cloud_drive_id');

  // Create folder with optimistic updates
  const createFolder = useCallback(async (name: string, parentFolderId?: string | null) => {
    try {
      // Use provided parentFolderId or fallback to currentFolder
      const effectiveParentId = parentFolderId !== undefined ? parentFolderId : currentFolder;
      
      // Check if we're in encrypted mode based on localStorage
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      
      // Get the appropriate drive ID based on mode
      let driveId;
      
      if (isEncryptedMode) {
        driveId = localStorage.getItem('encrypted_drive_id');
        logger.debug('Using encrypted drive ID from localStorage', {
          component: 'useDriveOperations',
          data: { driveId }
        });
      } else {
        driveId = activeDriveId || 
          localStorage.getItem('bigmind_active_drive_id') || 
          localStorage.getItem('bigmind_cloud_drive_id');
        logger.debug('Using regular drive ID', {
          component: 'useDriveOperations',
          data: { driveId, activeDriveId }
        });
      }
      
      if (!driveId) {
        logger.error('No active drive selected', {
          component: 'useDriveOperations',
          data: {
            isEncryptedMode,
            encryptedDriveId: localStorage.getItem('encrypted_drive_id'),
            activeDriveId,
            storedActiveId: localStorage.getItem('bigmind_active_drive_id'),
            storedCloudId: localStorage.getItem('bigmind_cloud_drive_id')
          }
        });
        throw new Error('No active drive selected');
      }
      
      logger.debug('Creating folder with parameters', {
        component: 'useDriveOperations',
        data: {
          name,
          parentFolderId: effectiveParentId,
          driveId,
          isEncryptedMode,
          currentFolder
        }
      });
      
      // Create optimistic folder
      const optimisticFolder: DriveFolder = {
        id: `temp-${Date.now()}`,
        name,
        type: 'folder',
        parentId: effectiveParentId,
        path: [],
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
        createdBy: {
          id: 'me',
          name: 'me',
          email: 'me'
        } as DriveItemUser,
        modifiedBy: {
          id: 'me',
          name: 'me',
          email: 'me'
        } as DriveItemUser,
        shared: false,
        favorite: false,
        labels: [], // Add required labels array
        itemCount: 0,
        totalSize: 0
      };
      
      // Add optimistic update
      setCurrentFolderContents(prev => [...prev, optimisticFolder]);
      
      // Make API call with effective drive ID and parent folder ID
      const result = await cloudDriveService.createFolder(
        name,
        driveId,
        effectiveParentId
      );

      logger.debug('Folder created successfully', {
          component: 'useDriveOperations',
          data: {
              folderName: name,
              folderId: result.id,
              parentId: effectiveParentId,
              driveId: driveId,
              isEncryptedMode
          }
      });

      // Invalidate queries to get real data for both current folder and parent folder
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder', effectiveParentId]
      });
      
      // Also invalidate currentFolder if different from effectiveParentId
      if (currentFolder !== effectiveParentId) {
        queryClient.invalidateQueries({
          queryKey: ['cloud-drive', 'folder', currentFolder]
        });
      }
      
      showToast('Folder created successfully', 'success');
      setIsFolderDialogOpen(false);

      return result;
  } catch (error) {
      logger.error('Folder creation error:', {
          component: 'useDriveOperations',
          error,
          data: {
              name,
              parentId: currentFolder,
              driveId: activeDriveId,
              isEncryptedMode: localStorage.getItem('current_drive_type') === 'encrypted_drive'
          }
      });

      // Remove optimistic update on error
      logger.debug('Removing optimistic folder on error', {
          component: 'useDriveOperations',
          data: {
              currentContentsCount: currentFolderContents.length
          }
      });
      
      setCurrentFolderContents(prev => 
          prev.filter((item: DriveItem) => !item.id.startsWith('temp-'))
      );

      showToast('Failed to create folder', 'error');
      throw error;
  }
}, [
  currentFolder,
  activeDriveId,
  currentFolderContents,
  queryClient,
  showToast,
  setCurrentFolderContents,
  setIsFolderDialogOpen
]);


  // Delete items with encryption support
  const deleteItems = useCallback(async (itemIds: string[]) => {
    try {
      // Check if we're in encrypted mode based on localStorage
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Deleting items - start operation', {
        component: 'useDriveOperations',
        data: { 
          itemIds, 
          isEncryptedMode,
          encryptedDriveId,
          itemCount: itemIds.length
        }
      });

      // Apply optimistic UI update - remove items from the current folder view
      setCurrentFolderContents(prev => {
        const updatedContents = prev.filter(item => !itemIds.includes(item.id));
        
        logger.debug('Applied optimistic UI update for delete', {
          component: 'useDriveOperations',
          data: { 
            originalCount: prev.length,
            updatedCount: updatedContents.length,
            removedCount: prev.length - updatedContents.length
          }
        });
        
        return updatedContents;
      });

      let result;
      
      // Check if we're in encrypted mode
      if (isEncryptedMode && encryptedDriveId) {
        // For encrypted drives, use the encrypted API
        result = await deleteEncryptedItems(
          itemIds, 
          encryptedDriveId
        );
        
        // Try to clear the metadata cache for deleted items
        try {
          const { encryptedFilesService } = await import('@/services/encrypted-drive/encrypted-files.service');
          itemIds.forEach(itemId => {
            const cacheKey = `${encryptedDriveId}:${itemId}`;
            encryptedFilesService.clearSingleItemCache(cacheKey);
          });
          logger.debug('Cleared metadata cache for deleted items', {
            component: 'useDriveOperations',
            data: { itemCount: itemIds.length }
          });
        } catch (error) {
          logger.warn('Failed to clear metadata cache', {
            component: 'useDriveOperations',
            error,
            data: { itemCount: itemIds.length }
          });
        }
      } else {
        // For non-encrypted drives, use the standard service
        result = await cloudDriveService.deleteItems(itemIds);
      }

      // Force invalidate queries to refresh data
      queryClient.invalidateQueries({
        predicate: (query) => {
          // Target folder contents queries
          const key = query.queryKey.join('/');
          return key.includes('cloud-drive/folder');
        }
      });
      
      // Clear selection state
      clearSelection();

      // Handle the response based on cloud drive service response format
      const successCount = result.processedItems || (result.successful ? result.successful.length : 0);
      showToast(`${successCount} items deleted successfully`, 'success');
      
      const failedCount = result.failedItems || (result.failed ? result.failed.length : 0);
      if (failedCount > 0) {
        showToast(`${failedCount} items failed to delete`, 'error');
      }

      // Force a state update to ensure UI reflects the change
      setTimeout(() => {
        setCurrentFolderContents(prev => [...prev]);
      }, 100);

      logger.debug('Delete operation completed', {
        component: 'useDriveOperations',
        data: {
          successCount,
          failCount: failedCount
        }
      });
      
    } catch (error) {
      logger.error('Delete error:', {
        component: 'useDriveOperations',
        error,
        data: { 
          itemIds, 
          isEncryptedMode: localStorage.getItem('current_drive_type') === 'encrypted_drive' 
        }
      });
      
      // Revert optimistic update on error by forcing a refresh
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder', currentFolder]
      });
      
      showToast('Failed to delete items', 'error');
      throw error;
    }
  }, [currentFolder, queryClient, showToast, clearSelection, setCurrentFolderContents]);

  // Move items with encryption support
  const moveItems = useCallback(async (itemIds: string[], targetFolderId: string) => {
    try {
      // Check if we're in encrypted mode based on localStorage
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Moving items', {
        component: 'useDriveOperations',
        data: { 
          itemIds, 
          targetFolderId,
          isEncryptedMode,
          encryptedDriveId
        }
      });
  
      let response;
      
      // Check if we're in encrypted mode
      if (isEncryptedMode && encryptedDriveId) {
        // For encrypted drives, use the moveEncryptedItems function
        response = await moveEncryptedItems(
          itemIds,
          targetFolderId,
          encryptedDriveId
        );
      } else {
        // For non-encrypted drives, use the standard service
        response = await cloudDriveService.moveItems({
          items: itemIds,
          targetFolderId: targetFolderId,
          overwrite: false  // Use overwrite instead of skipExisting
        });
      }
  
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder']
      });
  
      // Use processedItems instead of successful
      const successCount = response.processedItems || 0;
      showToast(`${successCount} items moved successfully`, 'success');
      
      const failedCount = response.failedItems || 0;
      if (failedCount > 0) {
        showToast(`${failedCount} items failed to move`, 'error');
      }
  
      clearSelection();
  
    } catch (error) {
      logger.error('Move error:', {
        component: 'useDriveOperations',
        error,
        data: { 
          itemIds, 
          targetFolderId, 
          isEncryptedMode: localStorage.getItem('current_drive_type') === 'encrypted_drive' 
        }
      });
      showToast('Failed to move items', 'error');
      throw error;
    }
  }, [queryClient, showToast, clearSelection]);

// Copy items with encryption support
  const copyItems = useCallback(async (itemIds: string[], targetFolderId: string) => {
    try {
      // Check if we're in encrypted mode based on localStorage
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Copying items', {
        component: 'useDriveOperations',
        data: { 
          itemIds, 
          targetFolderId,
          isEncryptedMode,
          encryptedDriveId
        }
      });
  
      let response;
      
      // Check if we're in encrypted mode
      if (isEncryptedMode && encryptedDriveId) {
        // Import and use the copyEncryptedItems function
        const { copyEncryptedItems } = await import('@/services/encrypted-drive/encrypted-drive-copy-operations');
        
        // For encrypted drives, use the copyEncryptedItems function
        response = await copyEncryptedItems(
          itemIds,
          targetFolderId,
          encryptedDriveId
        );
      } else {
        // For non-encrypted drives, use the standard service
        response = await cloudDriveService.copyItems({
          items: itemIds,
          targetFolderId: targetFolderId,
          overwrite: false
        });
      }
  
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder']
      });
      
      // Use processedItems instead of successful
      const successCount = response.processedItems || 0;
      showToast(`${successCount} items copied successfully`, 'success');
      
      const failedCount = response.failedItems || 0;
      if (failedCount > 0) {
        showToast(`${failedCount} items failed to copy`, 'error');
      }
      
    } catch (error) {
      logger.error('Copy error:', {
        component: 'useDriveOperations',
        error,
        data: { 
          itemIds, 
          targetFolderId,
          isEncryptedMode: localStorage.getItem('current_drive_type') === 'encrypted_drive'
        }
      });
      showToast('Failed to copy items', 'error');
      throw error;
    }
  }, [queryClient, showToast]);

  // Rename item with encryption support - IMPROVED VERSION WITH PERSISTENCE AND FORCED REFRESH
  const renameItem = useCallback(async (itemId: string, newName: string) => {
    try {
      // Check if we're in encrypted mode based on localStorage
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Renaming item - start operation', {
        component: 'useDriveOperations',
        data: { 
          itemId, 
          newName,
          isEncryptedMode,
          encryptedDriveId
        }
      });
      
      // Store the renamed item in our map for persistence
      renamedItems.set(itemId, newName);
      
      // Update the UI immediately - IMPORTANT: This needs to happen before API call
      setCurrentFolderContents(prev => {
        const updatedContents = prev.map(item => 
          item.id === itemId 
            ? { ...item, name: newName } 
            : item
        );
        
        logger.debug('Updated current folder contents with new name', {
          component: 'useDriveOperations',
          data: { 
            itemId, 
            newName, 
            itemsUpdated: updatedContents.length 
          }
        });
        
        return updatedContents;
      });
  
      // Make the API call based on drive type
      if (isEncryptedMode && encryptedDriveId) {
        // Get the encryption keys
        const keys = encryptionService.getDecryptedKeys(encryptedDriveId);
        if (!keys) {
          logger.error('No encryption keys available for renaming', {
            component: 'useDriveOperations',
            data: { 
              itemId,
              encryptedDriveId
            }
          });
          throw new Error('Drive not unlocked');
        }
  
        // For encrypted drives, use the encrypted function
        await renameEncryptedItem(
          itemId, 
          newName, 
          encryptedDriveId, 
          keys.metadataKey
        );
        
        // Force clear the item from the metadata cache to ensure fresh data
        try {
          // Dynamically import to avoid circular dependencies
          const { encryptedFilesService } = await import('@/services/encrypted-drive/encrypted-files.service');
          // Clear specific item's cache
          const cacheKey = `${encryptedDriveId}:${itemId}`;
          encryptedFilesService.clearSingleItemCache(cacheKey);
          logger.debug('Cleared metadata cache for renamed item', {
            component: 'useDriveOperations',
            data: { itemId, cacheKey }
          });
        } catch (error) {
          logger.warn('Failed to clear metadata cache', {
            component: 'useDriveOperations',
            error,
            data: { itemId }
          });
        }
      } else {
        // For non-encrypted drives, use the standard service
        await cloudDriveService.renameItem(itemId, newName);
      }
      
      // Show success toast
      showToast('Item renamed successfully', 'success');
      
      // Force invalidate queries for the current folder to refresh data
      queryClient.invalidateQueries({
        predicate: (query) => {
          // Target both folder contents and specific item queries
          const key = query.queryKey.join('/');
          return key.includes('cloud-drive/folder') || key.includes(`/items/${itemId}`);
        }
      });
      
      logger.debug('Invalidated queries after rename', {
        component: 'useDriveOperations',
        data: { 
          itemId, 
          newName,
          queriesAffected: 'yes'
        }
      });
      
      // Force a state update to ensure UI reflects the change
      setTimeout(() => {
        setCurrentFolderContents(prev => [...prev]);
      }, 100);
      
    } catch (error) {
      logger.error('Error renaming item:', {
        component: 'useDriveOperations',
        error,
        data: { itemId, newName }
      });
      
      // Remove from renamed items map on error
      renamedItems.delete(itemId);
      
      // Revert UI update on error
      setCurrentFolderContents(prev => [...prev]);
      
      showToast('Failed to rename item', 'error');
      throw error;
    }
  }, [currentFolder, queryClient, showToast, setCurrentFolderContents, encryptionService]);


  // To ensure UI stays consistent, install a global interceptor that applies renamed items
  React.useEffect(() => {
    if (currentFolderContents.length > 0) {
      // Check if any items need to be updated from our renamedItems map
      if (renamedItems.size > 0) {
        const hasRenamedItems = currentFolderContents.some(item => 
          renamedItems.has(item.id) && item.name !== renamedItems.get(item.id)
        );
        
        if (hasRenamedItems) {
          logger.debug('Applying renamed items to folder contents', {
            component: 'useDriveOperations',
            data: {
              renamedCount: renamedItems.size,
              folderContentsCount: currentFolderContents.length
            }
          });
          
          setCurrentFolderContents(prev => 
            prev.map(item => 
              renamedItems.has(item.id) 
                ? { ...item, name: renamedItems.get(item.id)! } 
                : item
            )
          );
        }
      }
    }
  }, [currentFolderContents, setCurrentFolderContents]);

  // Toggle favorite with encryption support - enhanced version
  const toggleFavorite = useCallback(async (itemId: string, initialItem?: DriveItem) => {
    try {
      // Check if we're in encrypted mode
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Toggling favorite', {
        component: 'useDriveOperations',
        data: { 
          itemId, 
          isEncryptedMode,
          encryptedDriveId,
          hasInitialItem: !!initialItem
        }
      });

      // Find the item in current folder contents or use the provided initialItem
      let itemToUpdate = initialItem;
      if (!itemToUpdate) {
        itemToUpdate = currentFolderContents.find(item => item.id === itemId);
      }
      
      // If no item is found and no initialItem was provided, try to fetch the item details
      if (!itemToUpdate) {
        logger.debug('Item not found in current folder, attempting to proceed without current state', {
          component: 'useDriveOperations',
          data: { itemId }
        });
        
        // Assume we're toggling to favorite=true if we don't know the current state
        // The API will handle the actual toggle based on the current state in the database
        const currentFavoriteState = false; // Assume not favorited
        
        if (isEncryptedMode && encryptedDriveId) {
          // For encrypted mode, we need item details to update metadata, so we'll need to fetch the item
          try {
            // Import the file service to get item details
            const { encryptedFilesService } = await import('@/services/encrypted-drive/encrypted-files.service');
            const itemDetails = await encryptedFilesService.getFileDetails(itemId, encryptedDriveId);
            
            if (!itemDetails) {
              throw new Error('Could not fetch item details');
            }
            
            itemToUpdate = itemDetails;
            logger.debug('Successfully fetched item details', {
              component: 'useDriveOperations',
              data: { 
                itemId, 
                itemName: itemDetails.name
              }
            });
          } catch (fetchError) {
            logger.error('Failed to fetch item details', {
              component: 'useDriveOperations',
              error: fetchError,
              data: { itemId }
            });
            throw new Error('Item details required for encrypted favorite toggle');
          }
        } else {
          // For regular cloud drive, use a direct API call
          // Instead of using cloudDriveService.toggleFavorite which doesn't exist
          await fetch(`/api/v1/cloud-drive/items/${itemId}/favorite`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('auth_token') || localStorage.getItem('token')}`
            }
          });
          
          // Invalidate queries for both current folder and favorites
          queryClient.invalidateQueries({
            predicate: (query) => {
              const key = query.queryKey.join('/');
              return key.includes('cloud-drive/folder') || key.includes('favorites');
            }
          });
          
          showToast('Favorite status updated', 'success');
          return; // Exit early as we've handled the non-encrypted case
        }
      }
      
      // By this point, we should have itemToUpdate one way or another
      const currentFavoriteState = itemToUpdate?.favorite || false;
      
      // Apply optimistic UI update if the item is in current folder contents
      if (currentFolderContents.some(item => item.id === itemId)) {
        setCurrentFolderContents(prev => 
          prev.map(item => 
            item.id === itemId 
              ? { ...item, favorite: !currentFavoriteState } 
              : item
          )
        );
      }

      if (isEncryptedMode && encryptedDriveId) {
        // For encrypted items, update metadata
        // Get encryption keys
        const keys = encryptionService.getDecryptedKeys(encryptedDriveId);
        if (!keys) {
          logger.error('No encryption keys available for favorite toggle', {
            component: 'useDriveOperations',
            data: { 
              itemId,
              encryptedDriveId
            }
          });
          throw new Error('Drive not unlocked');
        }

        // Dynamically import to avoid circular dependencies
        const { toggleFavoriteEncryptedItem } = await import('@/services/encrypted-drive/encrypted-drive-operations');

        // For encrypted drive, use toggleFavoriteEncryptedItem
        await toggleFavoriteEncryptedItem(
          itemId,
          !currentFavoriteState,
          encryptedDriveId,
          keys.metadataKey
        );
        
        // Clear item's cache entry to ensure fresh data
        try {
          const { encryptedFilesService } = await import('@/services/encrypted-drive/encrypted-files.service');
          const cacheKey = `${encryptedDriveId}:${itemId}`;
          encryptedFilesService.clearSingleItemCache(cacheKey);
        } catch (error) {
          logger.warn('Failed to clear metadata cache', {
            component: 'useDriveOperations',
            error,
            data: { itemId }
          });
        }
      } else {
        // For regular cloud drive, use a direct API call instead of cloudDriveService.toggleFavorite
        await fetch(`/api/v1/cloud-drive/items/${itemId}/favorite`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('auth_token') || localStorage.getItem('token')}`
          }
        });
      }

      // Invalidate queries for both current folder and favorites
      queryClient.invalidateQueries({
        predicate: (query) => {
          const key = query.queryKey.join('/');
          return key.includes('cloud-drive/folder') || key.includes('favorites');
        }
      });

      showToast(currentFavoriteState 
        ? 'Removed from favorites' 
        : 'Added to favorites', 'success');
      
      // Force refresh to ensure UI is up to date
      if (currentFolderContents.some(item => item.id === itemId)) {
        setTimeout(() => {
          setCurrentFolderContents(prev => [...prev]);
        }, 100);
      }
      
    } catch (error) {
      logger.error('Favorite toggle error:', {
        component: 'useDriveOperations',
        error,
        data: { itemId }
      });
      
      // Revert optimistic update on error if item is in current folder
      if (currentFolderContents.some(item => item.id === itemId)) {
        setCurrentFolderContents(prev => [...prev]);
      }
      
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder', currentFolder]
      });
      
      showToast('Failed to update favorite status', 'error');
      throw error;
    }
  }, [currentFolder, currentFolderContents, queryClient, showToast, setCurrentFolderContents, encryptionService]);

  // Upload files
  const uploadFiles = useCallback(async (files: File[], targetFolderId?: string | null) => {
    try {
      logger.debug('Starting file upload', {
        component: 'useDriveOperations',
        data: { 
          fileCount: files.length, 
          targetFolderId,
          driveId: activeDriveId
        }
      });

      if (!activeDriveId) {
        throw new Error('No active drive selected');
      }

      await cloudDriveService.uploadFiles(files, targetFolderId || currentFolder);
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder', currentFolder]
      });
      showToast('Files uploaded successfully', 'success');
    } catch (error) {
      logger.error('Upload error:', {
        component: 'useDriveOperations',
        error,
        data: { fileCount: files.length, targetFolderId }
      });
      showToast('Failed to upload files', 'error');
      throw error;
    }
  }, [activeDriveId, currentFolder, queryClient, showToast]);

  // NEW: Download item with encryption support
  const downloadItem = useCallback(async (itemId: string, itemName?: string) => {
    try {
      // Check if we're in encrypted mode based on localStorage
      const isEncryptedMode = localStorage.getItem('current_drive_type') === 'encrypted_drive';
      const encryptedDriveId = localStorage.getItem('encrypted_drive_id');
      
      logger.debug('Downloading item', {
        component: 'useDriveOperations',
        data: { 
          itemId, 
          isEncryptedMode,
          encryptedDriveId,
          itemName
        }
      });

      // Show initial toast for better UX - use 'info' instead of 'loading'
      showToast('Starting download...', 'info');

      if (isEncryptedMode && encryptedDriveId) {
        // For encrypted items, use the encrypted file download service
        // Import dynamically to avoid circular dependencies
        const { downloadEncryptedFile } = await import('@/services/encrypted-drive/encrypted-file-download.service');
        
        // Get the encryption keys
        const keys = encryptionService.getDecryptedKeys(encryptedDriveId);
        if (!keys) {
          logger.error('No encryption keys available for download', {
            component: 'useDriveOperations',
            data: { 
              itemId,
              encryptedDriveId
            }
          });
          throw new Error('Drive not unlocked');
        }
        
        await downloadEncryptedFile(itemId, encryptedDriveId, keys.contentKey, itemName);
      } else {
        // For non-encrypted items, use the cloud drive service
        const blob = await cloudDriveService.downloadFile(itemId);
        
        // Create a download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = itemName || `file-${itemId}`;
        document.body.appendChild(a);
        a.click();
        
        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
      
      // Update toast to show success
      showToast('File downloaded successfully', 'success');
    } catch (error) {
      logger.error('Download error:', {
        component: 'useDriveOperations',
        error,
        data: { 
          itemId, 
          isEncryptedMode: localStorage.getItem('current_drive_type') === 'encrypted_drive' 
        }
      });
      
      showToast('Failed to download file', 'error');
      throw error;
    }
  }, [showToast, encryptionService]);

  return {
    createFolder,
    deleteItems,
    moveItems,
    copyItems,
    renameItem,
    toggleFavorite,
    uploadFiles,
    downloadItem
  };
};