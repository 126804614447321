/**
 * @file src/components/reports/ReportGrid.tsx
 * @description Grid layout for report cards with animations
 * @version 1.1.0
 * @updated 2024-01-29
 */

import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/utils/utils';
import { ReportCard } from './ReportCard';
import type { Report } from '@/types/reports.types';

interface ReportGridProps {
  reports: Report[];
  category: string;
  onViewReport: (report: Report) => void;
  className?: string;
}

export const ReportGrid: React.FC<ReportGridProps> = ({
  reports,
  category,
  onViewReport,
  className
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className={cn("space-y-8", className)}
    >
      {/* Category Title */}
      <div className="border-b border-gray-200 dark:border-gray-800 pb-4">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
          {category}
        </h2>
      </div>

      {/* Reports Grid */}
      <div className={cn(
        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
        "gap-8",
        "max-w-7xl mx-auto",
        "px-4 sm:px-6 lg:px-8"
      )}>
        {reports.map((report, index) => (
          <motion.div
            key={report.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              duration: 0.3,
              delay: index * 0.1
            }}
          >
            <ReportCard
              report={report}
              onView={onViewReport}
            />
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default ReportGrid;