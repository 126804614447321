/**
 * @file src/app/ghost/components/language/GhostLanguageDialog.tsx
 * @description Language selection dialog with flags and native language names
 * @version 1.0.1
 */

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { availableLanguages } from '@/i18n/ghost/config';
import CircleFlag from '@/app/ghost/components/language/CircleFlag';
import { GhostGradientText } from '@/app/ghost/components/common/GhostGradients';
import { useGhostTheme } from '@/app/ghost/providers/GhostThemeProvider';

interface GhostLanguageDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const GhostLanguageDialog: React.FC<GhostLanguageDialogProps> = ({
  isOpen,
  onClose
}) => {
  const { i18n, t } = useGhostTranslation();
  const { theme } = useGhostTheme();
  const [selectedLang, setSelectedLang] = useState<string>(i18n.language);
  
  if (!isOpen) return null;

  const handleLanguageChange = (langCode: string) => {
    setSelectedLang(langCode);
    logger.debug('Language change requested', {
      component: 'GhostLanguageDialog',
      from: i18n.language,
      to: langCode
    });
    i18n.changeLanguage(langCode);
    // We don't close the dialog immediately to give visual feedback
    setTimeout(onClose, 300);
  };

  return (
    <div 
      className={cn(
        "fixed inset-0 z-50",
        "flex items-center justify-center",
        "bg-black/40 backdrop-blur-sm",
        "p-4"
      )}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ type: "spring", damping: 30, stiffness: 300 }}
        className={cn(
          "relative w-full max-w-md p-6",
          "rounded-xl shadow-xl",
          "overflow-hidden",
          "border border-[hsl(var(--ghost-accent))/0.2]",
          // Proper theme-based background
          theme === 'day' 
            ? "bg-white" 
            : theme === 'night'
              ? "bg-[hsl(var(--ghost-surface))]"
              : "bg-[hsl(var(--ghost-bg))]/80 backdrop-blur-md" // Quantum theme
        )}
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div>
            <GhostGradientText as="h2" className="text-xl font-semibold">
              {t('settings.select_language', 'Select Language')}
            </GhostGradientText>
            <p className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
              {t('settings.choose_preferred_language', 'Choose your preferred language')}
            </p>
          </div>
          <button
            onClick={onClose}
            className={cn(
              "w-8 h-8 rounded-full",
              "flex items-center justify-center",
              "bg-[hsl(var(--ghost-accent))/0.1]",
              "hover:bg-[hsl(var(--ghost-accent))/0.2]",
              "transition-colors duration-200"
            )}
          >
            <X className="h-4 w-4 text-[hsl(var(--ghost-foreground))]" />
          </button>
        </div>

        {/* Languages Grid */}
        <div className="grid grid-cols-2 gap-3 max-h-[60vh] overflow-y-auto pr-1">
          {availableLanguages.map((language) => {
            const isActive = selectedLang === language.code;
            
            return (
              <button
                key={language.code}
                onClick={() => handleLanguageChange(language.code)}
                className={cn(
                  "flex items-center p-3",
                  "rounded-xl border",
                  "transition-all duration-200 ease-in-out",
                  isActive
                    ? "bg-[hsl(var(--ghost-accent))/0.15] border-[hsl(var(--ghost-accent))/0.3]"
                    : theme === 'day'
                      ? "bg-gray-50 border-gray-200 hover:bg-gray-100 hover:border-gray-300"
                      : "bg-[hsl(var(--ghost-surface))/0.6] border-[hsl(var(--ghost-foreground))/0.1] hover:bg-[hsl(var(--ghost-accent))/0.1] hover:border-[hsl(var(--ghost-accent))/0.2]"
                )}
              >
                <CircleFlag language={language.code} size="medium" className="mr-1" />
                <div className="ml-3 text-left">
                  <p className={cn(
                    "font-medium",
                    isActive ? "text-[hsl(var(--ghost-accent))]" : "text-[hsl(var(--ghost-foreground))]"
                  )}>
                    {language.nativeName}
                  </p>
                  <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                    {language.name}
                  </p>
                </div>
                {isActive && (
                  <div className="ml-auto w-2 h-2 rounded-full bg-[hsl(var(--ghost-accent))]" />
                )}
              </button>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default GhostLanguageDialog;