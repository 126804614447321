/**
 * @file src/components/cloud-drive/states/CloudDriveEmptyState.tsx
 * @description Enhanced empty state component with animations and multiple variants
 * @version 2.0.0
 */

import React from 'react';
import { motion } from 'framer-motion';
import { 
  Upload, 
  Search, 
  FolderOpen,
  Share2,
  Star,
  Trash2,
  AlertCircle,
  FileQuestion
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import { CloudDriveActions } from '../actions/CloudDriveActions';

type EmptyStateType = 
  | 'default' 
  | 'search' 
  | 'folder' 
  | 'shared' 
  | 'favorites'  // Add this  
  | 'starred' 
  | 'trash'
  | 'error'
  | 'no-access';

interface EmptyStateConfig {
  Icon: React.FC<{ className?: string }>;
  colorClass: string;
  bgClass: string;
  showAction?: boolean;
}

interface CloudDriveEmptyStateProps {
  type?: EmptyStateType;
  searchQuery?: string;
  customAction?: React.ReactNode;
  onRetry?: () => void;
  className?: string;
}

export const CloudDriveEmptyState = React.forwardRef<HTMLDivElement, CloudDriveEmptyStateProps>(
  ({ type = 'default', searchQuery, customAction, onRetry, className }, ref) => {
    const { t } = useTranslation();

    const stateConfig: Record<EmptyStateType, EmptyStateConfig> = {
      default: {
        Icon: Upload,
        colorClass: 'text-blue-500 dark:text-blue-400',
        bgClass: 'bg-blue-50 dark:bg-blue-900/20',
        showAction: true
      },
      search: {
        Icon: Search,
        colorClass: 'text-gray-500 dark:text-gray-400',
        bgClass: 'bg-gray-50 dark:bg-gray-800/50'
      },
      folder: {
        Icon: FolderOpen,
        colorClass: 'text-blue-500 dark:text-blue-400',
        bgClass: 'bg-blue-50 dark:bg-blue-900/20'
      },
      shared: {
        Icon: Share2,
        colorClass: 'text-green-500 dark:text-green-400',
        bgClass: 'bg-green-50 dark:bg-green-900/20'
      },
      favorites: {  // Add this configuration
        Icon: Star,
        colorClass: 'text-amber-500 dark:text-amber-400',
        bgClass: 'bg-amber-50 dark:bg-amber-900/20'
      },      
      starred: {
        Icon: Star,
        colorClass: 'text-amber-500 dark:text-amber-400',
        bgClass: 'bg-amber-50 dark:bg-amber-900/20'
      },
      trash: {
        Icon: Trash2,
        colorClass: 'text-red-500 dark:text-red-400',
        bgClass: 'bg-red-50 dark:bg-red-900/20'
      },
      error: {
        Icon: AlertCircle,
        colorClass: 'text-red-500 dark:text-red-400',
        bgClass: 'bg-red-50 dark:bg-red-900/20',
        showAction: true
      },
      'no-access': {
        Icon: FileQuestion,
        colorClass: 'text-gray-500 dark:text-gray-400',
        bgClass: 'bg-gray-50 dark:bg-gray-800/50'
      }
    };

    const config = stateConfig[type];
    const { Icon, colorClass, bgClass, showAction } = config;

    const getContent = () => {
      if (type === 'error') {
        return {
          title: t('common.error_loading'),
          description: t('cloud_drive.empty_state.error.description')
        };
      }

      if (type === 'search' && searchQuery) {
        return {
          title: t('cloud_drive.empty_state.search_title', { query: searchQuery }),
          description: t('cloud_drive.empty_state.search_description', { query: searchQuery })
        };
      }

      if (type === 'favorites') {
        return {
          title: t('cloud_drive.empty_state.favorites.title'),
          description: t('cloud_drive.empty_state.favorites.description')
        };
      }

      return {
        title: t(`cloud_drive.empty_state.${type}.title`),
        description: t(`cloud_drive.empty_state.${type}.description`)
      };
    };

    const { title, description } = getContent();

    const containerVariants = {
      hidden: { opacity: 0, scale: 0.95 },
      visible: { 
        opacity: 1, 
        scale: 1,
        transition: {
          duration: 0.3,
          staggerChildren: 0.1
        }
      }
    };

    const itemVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: { opacity: 1, y: 0 }
    };

    const handleUpload = () => {
      // Placeholder for upload functionality
    };

    const handleNewFolder = () => {
      // Placeholder for new folder functionality
    };

    return (
      <motion.div
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className={cn(
          "h-full flex flex-col items-center justify-center p-8",
          "select-none",
          className
        )}
      >
        <motion.div
          variants={itemVariants}
          className={cn(
            "w-24 h-24 rounded-full",
            "flex items-center justify-center",
            "mb-8 shadow-lg",
            bgClass
          )}
        >
          <motion.div
            animate={
              type === "default"
                ? {
                    y: [0, -5, 0],
                    transition: { duration: 2, repeat: Infinity },
                  }
                : undefined
            }
            className={colorClass}
          >
            <Icon className="h-12 w-12" />
          </motion.div>
        </motion.div>

        <motion.h3
          variants={itemVariants}
          className="text-2xl font-semibold text-gray-900 dark:text-gray-100 text-center mb-3"
        >
          {title}
        </motion.h3>

        <motion.p
          variants={itemVariants}
          className="text-sm text-gray-500 dark:text-gray-400 text-center max-w-sm mb-8"
        >
          {description}
        </motion.p>

        {(customAction || showAction) && (
          <motion.div
            variants={itemVariants}
            className="flex flex-col sm:flex-row gap-3"
          >
            {customAction ||
              (type === "error" ? (
                <Button
                  variant="default"
                  size="lg"
                  onClick={onRetry}
                  className="min-w-[200px]"
                >
                  {t("common.try_again")}
                </Button>
              ) : (
                <CloudDriveActions onUpload={handleUpload} onNewFolder={handleNewFolder} />
              )
            )}
          </motion.div>
        )}
      </motion.div>
    );
  }
);

CloudDriveEmptyState.displayName = "CloudDriveEmptyState";

export default CloudDriveEmptyState;