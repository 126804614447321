/**
 * @file src/providers/AccountProvider.tsx
 * @description Account information provider with React Query integration
 * @version 1.1.0
 */

import React from 'react';
import { useQuery, useQueryClient, QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { accountApi } from '@/services/account.service';
import { useAuth } from '@/context/AuthContext';
import type { AccountInfo } from '@/types/account.types';
import { logger } from '@/utils/logger';
import { isAuthPage } from '@/routes';

// Query keys
export const accountKeys = {
  all: ['account'] as const,
  info: () => [...accountKeys.all, 'info'] as const,
};

interface AuthContextType {
  isAuthenticated: boolean;
  token?: string;
}

interface AccountContextType {
  accountInfo: AccountInfo | undefined;
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<QueryObserverResult<AccountInfo, Error>>;  // Updated return type
}

const AccountContext = React.createContext<AccountContextType | undefined>(undefined);

export const AccountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, token } = useAuth() as AuthContextType;
  const queryClient = useQueryClient();
  
  // Check if we're on an auth page to prevent unnecessary API calls
  const onAuthPage = isAuthPage();

  const { 
    data: accountInfo, 
    isLoading, 
    error,
    refetch 
  } = useQuery<AccountInfo, Error>({
    queryKey: accountKeys.info(),
    queryFn: () => accountApi.getInfo(),
    enabled: isAuthenticated && !!token && !onAuthPage, // Don't run on auth pages
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 30, // 30 minutes
    retry: onAuthPage ? 0 : 2, // No retries on auth pages
  });
  // Use separate useEffect hooks for error and success handling
  React.useEffect(() => {
    if (error) {
      logger.error('Failed to fetch account info', {
        component: 'AccountProvider',
        error: error instanceof Error ? error : new Error('Unknown error occurred')
      });
    }
  }, [error]);

  React.useEffect(() => {
    if (accountInfo) {
      logger.debug('Account info fetched successfully', {
        component: 'AccountProvider',
        data: {
          hasData: !!accountInfo,
          dataStructure: {
            hasUser: !!accountInfo?.id,
            hasSubscription: !!accountInfo?.subscription,
            hasProfile: !!accountInfo?.profile
          }
        }
      });
    }
  }, [accountInfo]);

  // Clear cache on logout
  React.useEffect(() => {
    if (!isAuthenticated) {
      queryClient.removeQueries({
        queryKey: accountKeys.all
      });
    }
  }, [isAuthenticated, queryClient]);

  const value: AccountContextType = {
    accountInfo: accountInfo as AccountInfo | undefined,
    isLoading,
    error: error as Error | null,
    refetch: refetch as () => Promise<QueryObserverResult<AccountInfo, Error>>
  };

  return (
    <AccountContext.Provider value={value}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = (): AccountContextType => {
  const context = React.useContext(AccountContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};