/**
 * @file: components/media/MediaToolbar.tsx
 * @description: Toolbar component for media view filters and actions
 * @version: 1.0.0
 */

import React from 'react';
import { Button } from '@/components/ui/button';
import { 
  Image, 
  Video, 
  Star, 
  Trash2, 
  Download, 
  Share2, 
  RefreshCw,
  Filter,
  X
} from 'lucide-react';
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

interface MediaToolbarProps {
  totalItems: number;
  selectedCount: number;
  mediaTypes: string[];
  setMediaTypes: (types: string[]) => void;
  favoritesOnly: boolean;
  setFavoritesOnly: (value: boolean) => void;
  onClearSelection: () => void;
  onRefresh: () => void;
  hasActiveFilters: boolean;
  resetFilters: () => void;
}

export const MediaToolbar: React.FC<MediaToolbarProps> = ({
  totalItems,
  selectedCount,
  mediaTypes,
  setMediaTypes,
  favoritesOnly,
  setFavoritesOnly,
  onClearSelection,
  onRefresh,
  hasActiveFilters,
  resetFilters,
}) => {
  // Toggle media type filter
  const toggleMediaType = (type: string) => {
    if (mediaTypes.includes(type)) {
      // Don't remove if it would result in empty filter
      if (mediaTypes.length > 1) {
        setMediaTypes(mediaTypes.filter(t => t !== type));
      }
    } else {
      setMediaTypes([...mediaTypes, type]);
    }
  };

  return (
    <div className="border-b dark:border-gray-700 bg-white dark:bg-gray-900 p-2 flex flex-wrap items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        {/* Media filters */}
        <div className="flex items-center mr-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={mediaTypes.includes('image') ? "default" : "outline"}
                  onClick={() => toggleMediaType('image')}
                  aria-label="Filter images"
                  size="sm"
                >
                  <Image className="h-4 w-4 mr-1" />
                  Images
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Show/hide images</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={mediaTypes.includes('video') ? "default" : "outline"}
                  onClick={() => toggleMediaType('video')}
                  aria-label="Filter videos"
                  size="sm"
                  className="ml-1"
                >
                  <Video className="h-4 w-4 mr-1" />
                  Videos
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Show/hide videos</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant={favoritesOnly ? "default" : "outline"}
                  onClick={() => setFavoritesOnly(!favoritesOnly)}
                  aria-label="Show favorites only"
                  size="sm"
                  className="ml-1"
                >
                  <Star className="h-4 w-4 mr-1" />
                  Favorites
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Show favorites only</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {/* Clear filters button */}
        {hasActiveFilters && (
          <Button
            variant="ghost"
            size="sm"
            onClick={resetFilters}
            className="ml-1 text-muted-foreground"
          >
            <Filter className="h-4 w-4 mr-1" />
            <X className="h-3 w-3 mr-1" />
            Clear filters
          </Button>
        )}

        {/* Refresh button */}
        <Button
          variant="ghost"
          size="icon"
          onClick={onRefresh}
          className="ml-1"
        >
          <RefreshCw className="h-4 w-4" />
        </Button>
      </div>

      <div className="flex items-center">
        {/* Item count info */}
        <span className="text-sm text-muted-foreground mr-4">
          {selectedCount > 0 
            ? `${selectedCount} selected of ${totalItems} items` 
            : `${totalItems} items`}
        </span>

        {/* Selection actions */}
        {selectedCount > 0 && (
          <div className="flex items-center gap-1">
            <Button variant="outline" size="sm" onClick={onClearSelection}>
              <X className="h-4 w-4 mr-1" />
              Clear
            </Button>
            <Button variant="outline" size="sm">
              <Download className="h-4 w-4 mr-1" />
              Download
            </Button>
            <Button variant="outline" size="sm">
              <Share2 className="h-4 w-4 mr-1" />
              Share
            </Button>
            <Button variant="outline" size="sm" className="text-destructive hover:text-destructive">
              <Trash2 className="h-4 w-4 mr-1" />
              Delete
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaToolbar;