/**
 * @file src/app/ghost/components/zero-knowledge/tabs/CompareTab.tsx
 * @description Comparison of encryption approaches with other cloud providers
 * @version 1.0.2
 * @created 2025-04-01
 * @updated 2025-04-03
 * @dependencies framer-motion, lucide-react
 */

import React from 'react';
import { motion } from 'framer-motion';
import { HelpCircle } from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { cn } from '@/utils/utils';

// Status indicators with different colors
interface StatusChipProps {
  status: 'positive' | 'negative';
  children: React.ReactNode;
}

const StatusChip: React.FC<StatusChipProps> = ({ status, children }) => {
  return (
    <div className={cn(
      "rounded-full px-3 py-0.5 text-xs font-medium", // Reduced size
      status === 'positive' ? 
        "bg-green-50 text-green-700 dark:bg-green-900/20 dark:text-green-300" : 
        "bg-red-50 text-red-700 dark:bg-red-900/20 dark:text-red-300"
    )}>
      {children}
    </div>
  );
};

export const CompareTab: React.FC = () => {
  const { t } = useGhostTranslation();
  
  // Define comparison data - updated with new rows
  const comparisonData = [
    {
      feature: {
        title: t('zeroKnowledge.dialog.compare.encryption.title', 'Encryption'),
        description: t('zeroKnowledge.dialog.compare.encryption.description', 'Where does encryption happen?')
      },
      providers: {
        nygma: {
          status: 'positive',
          value: t('zeroKnowledge.dialog.compare.yourDevice', 'Your Device')
        },
        googleDrive: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.server', 'Server')
        },
        iCloud: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.server', 'Server')
        }
      }
    },
    {
      feature: {
        title: t('zeroKnowledge.dialog.compare.keys.title', 'Encryption Keys'),
        description: t('zeroKnowledge.dialog.compare.keys.description', 'Who has access to the keys?')
      },
      providers: {
        nygma: {
          status: 'positive',
          value: t('zeroKnowledge.dialog.compare.onlyYou', 'Only You')
        },
        googleDrive: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.google', 'Google')
        },
        iCloud: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.apple', 'Apple')
        }
      }
    },
    {
      feature: {
        title: t('zeroKnowledge.dialog.compare.metadata.title', 'Metadata'),
        description: t('zeroKnowledge.dialog.compare.metadata.description', 'Are filenames, dates encrypted?')
      },
      providers: {
        nygma: {
          status: 'positive',
          value: t('zeroKnowledge.dialog.compare.yes', 'Yes')
        },
        googleDrive: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.no', 'No')
        },
        iCloud: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.no', 'No')
        }
      }
    },
    // New row for file sizes
    {
      feature: {
        title: t('zeroKnowledge.dialog.compare.fileSize.title', 'File Sizes'),
        description: t('zeroKnowledge.dialog.compare.fileSize.description', 'Are file sizes encrypted?')
      },
      providers: {
        nygma: {
          status: 'positive',
          value: t('zeroKnowledge.dialog.compare.yes', 'Yes')
        },
        googleDrive: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.no', 'No')
        },
        iCloud: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.no', 'No')
        }
      }
    },
    // Search row with reversed colors for clarity on feature availability
    {
      feature: {
        title: t('zeroKnowledge.dialog.compare.search.title', 'Search'),
        description: t('zeroKnowledge.dialog.compare.search.description', 'Server-side search capability')
      },
      providers: {
        nygma: {
          status: 'negative', // Changed to negative as it's a missing feature
          value: t('zeroKnowledge.dialog.compare.no', 'No')
        },
        googleDrive: {
          status: 'positive', // Changed to positive as it's an available feature
          value: t('zeroKnowledge.dialog.compare.yes', 'Yes')
        },
        iCloud: {
          status: 'positive', // Changed to positive as it's an available feature
          value: t('zeroKnowledge.dialog.compare.yes', 'Yes')
        }
      }
    },
    {
      feature: {
        title: t('zeroKnowledge.dialog.compare.legalAccess.title', 'Legal Access'),
        description: t('zeroKnowledge.dialog.compare.legalAccess.description', 'Can provider access if required?')
      },
      providers: {
        nygma: {
          status: 'positive',
          value: t('zeroKnowledge.dialog.compare.impossible', 'Impossible')
        },
        googleDrive: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.yes', 'Yes')
        },
        iCloud: {
          status: 'negative',
          value: t('zeroKnowledge.dialog.compare.yes', 'Yes')
        }
      }
    }
  ];
  
  // The rest of the component remains the same...
  return (
    <div className="space-y-4">
      <h3 className="text-base font-medium text-[hsl(var(--ghost-foreground))]">
        {t('zeroKnowledge.dialog.compare.title', 'How Nygma Compares to Other Cloud Storage')}
      </h3>
      
      {/* Comparison Table */}
      <div className="overflow-hidden rounded-lg border border-[hsl(var(--ghost-foreground-muted))]/10">
        {/* Table Header */}
        <div className="bg-[hsl(var(--ghost-surface))]/30 p-2 grid grid-cols-4 gap-2 text-xs">
          <div className="text-[hsl(var(--ghost-foreground))] font-medium">
            {t('zeroKnowledge.dialog.compare.feature', 'Feature')}
          </div>
          <div className="text-[hsl(var(--ghost-foreground))] font-medium text-center">
            Nygma
          </div>
          <div className="text-[hsl(var(--ghost-foreground))] font-medium text-center">
            Google Drive
          </div>
          <div className="text-[hsl(var(--ghost-foreground))] font-medium text-center">
            iCloud
          </div>
        </div>
        
        {/* Table Body */}
        <div className="divide-y divide-[hsl(var(--ghost-foreground-muted))]/10">
          {comparisonData.map((row, index) => (
            <div 
              key={index}
              className={cn(
                "p-2 grid grid-cols-4 gap-2",
                index % 2 === 1 ? "bg-[hsl(var(--ghost-surface))]/10" : ""
              )}
            >
              {/* Feature */}
              <div className="flex flex-col">
                <div className="flex items-center">
                  <span className="text-xs text-[hsl(var(--ghost-foreground))] font-medium">
                    {row.feature.title}
                  </span>
                  <HelpCircle className="w-3 h-3 text-[hsl(var(--ghost-foreground-muted))] ml-1" />
                </div>
                <span className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                  {row.feature.description}
                </span>
              </div>
              
              {/* Nygma */}
              <div className="flex items-center justify-center">
                <StatusChip status={row.providers.nygma.status as 'positive' | 'negative'}>
                  {row.providers.nygma.value}
                </StatusChip>
              </div>
              
              {/* Google Drive */}
              <div className="flex items-center justify-center">
                <StatusChip status={row.providers.googleDrive.status as 'positive' | 'negative'}>
                  {row.providers.googleDrive.value}
                </StatusChip>
              </div>
              
              {/* iCloud */}
              <div className="flex items-center justify-center">
                <StatusChip status={row.providers.iCloud.status as 'positive' | 'negative'}>
                  {row.providers.iCloud.value}
                </StatusChip>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Get More Details Button */}
      <div className="flex justify-end">
        <button
          className={cn(
            "px-4 py-2 text-sm rounded-lg",
            "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
            "text-white",
            "transition-all duration-200"
          )}
        >
          {t('zeroKnowledge.dialog.compare.getDetailsButton', 'Get More Details')}
        </button>
      </div>
    </div>
  );
};

export default CompareTab;