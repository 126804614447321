/**
 * @file src/components/services/ServiceCard.tsx
 * @description Individual cloud service card component with connection status
 * @version 1.0.1
 * @created 2024-12-01
 * @dependencies framer-motion, lucide-react
 */

import React from 'react';
import { motion } from 'framer-motion';
import { 
  Cloud,
  Folder,
  Database,
  CheckCircle2,
  XCircle,
  Loader2,
  ExternalLink,
  HardDrive
} from 'lucide-react';
import { cn } from '@/utils/utils';
import type { CloudService } from '@/types/cloud-services';

interface ServiceCardProps {
  service: CloudService;
  onConnect: () => void;
  onDisconnect: () => void;
  isConnecting: boolean;
}

// Updated iconMap to use available icons
const iconMap: Record<string, typeof Cloud> = {
  'google_drive': HardDrive,
  'onedrive': Cloud,
  'dropbox': Database,
  'box': Folder,
  'aws_s3': Database,
};

export const ServiceCard: React.FC<ServiceCardProps> = ({
  service,
  onConnect,
  onDisconnect,
  isConnecting
}) => {
  const Icon = iconMap[service.type] || Cloud;
  
  const statusColors = {
    connected: 'bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400',
    disconnected: 'bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-400',
    connecting: 'bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400',
    error: 'bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400'
  };

  const StatusIcon = {
    connected: CheckCircle2,
    disconnected: ExternalLink,
    connecting: Loader2,
    error: XCircle
  }[service.status];

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className={cn(
        "relative p-6 rounded-xl",
        "bg-white dark:bg-gray-900",
        "border border-gray-200 dark:border-gray-800",
        "transition-colors duration-200",
        "group cursor-pointer"
      )}
      onClick={() => service.status === 'connected' ? onDisconnect() : onConnect()}
    >
      {/* Service Icon */}
      <div className={cn(
        "w-12 h-12 rounded-lg",
        "flex items-center justify-center",
        "bg-gray-50 dark:bg-gray-800",
        "text-gray-600 dark:text-gray-400",
        "mb-4"
      )}>
        <Icon className="w-6 h-6" />
      </div>

      {/* Service Info */}
      <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
        {service.name}
      </h3>
      <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
        {service.description}
      </p>

      {/* Connection Status */}
      <div className={cn(
        "inline-flex items-center space-x-2 px-3 py-1.5 rounded-full",
        statusColors[service.status]
      )}>
        <StatusIcon className={cn(
          "w-4 h-4",
          service.status === 'connecting' && "animate-spin"
        )} />
        <span className="text-sm font-medium capitalize">
          {service.status}
        </span>
      </div>

      {/* Storage Usage - Only show if connected */}
      {service.status === 'connected' && service.usedSpace && service.totalSpace && (
        <div className="mt-4">
          <div className="flex justify-between text-sm mb-2">
            <span className="text-gray-500 dark:text-gray-400">Storage Used</span>
            <span className="text-gray-700 dark:text-gray-300">
              {service.usedSpace.formatted} / {service.totalSpace.formatted}
            </span>
          </div>
          <div className="w-full h-2 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{ 
                width: `${(service.usedSpace.value / service.totalSpace.value) * 100}%` 
              }}
              transition={{ duration: 0.5 }}
              className="h-full bg-blue-500 dark:bg-blue-400 rounded-full"
            />
          </div>
        </div>
      )}

      {/* Last Sync Time - Only show if connected */}
      {service.status === 'connected' && service.lastSync && (
        <div className="mt-4 text-xs text-gray-500 dark:text-gray-400">
          Last synced: {new Date(service.lastSync).toLocaleString()}
        </div>
      )}

      {/* Action Button */}
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={cn(
          "mt-6 w-full py-2 px-4 rounded-lg",
          "text-sm font-medium",
          "transition-colors duration-200",
          service.status === 'connected'
            ? "bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-900/30"
            : "bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 hover:bg-blue-100 dark:hover:bg-blue-900/30"
        )}
        disabled={isConnecting}
      >
        {service.status === 'connected' ? 'Disconnect' : 'Connect'}
      </motion.button>
    </motion.div>
  );
};