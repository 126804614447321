/**
 * @file src/hooks/useAccountInfo.ts
 * @description Hook for fetching account information
 * @version 1.1.0
 */

import { useQuery } from '@tanstack/react-query';
import { accountApi } from '@/services/account.service';
import { logger } from '@/utils/logger';

export const useAccountInfo = () => {
  return useQuery({
    queryKey: ['accountInfo'],
    queryFn: async () => {
      try {
        logger.debug('Fetching account info', {
          component: 'useAccountInfo',
          data:{
            timestamp: new Date().toISOString()
          }
        });

        const data = await accountApi.getInfo();
        
        logger.debug('Account info fetched successfully', {
          component: 'useAccountInfo',
          data:{
            hasData: !!data,
            dataStructure: {
              id: data?.id,
              hasSubscription: !!data?.subscription,
              hasProfile: !!data?.profile,
              storageInfo: {
                used: data?.storage?.used,
                limit: data?.storage?.limit
              }
            }
          }
        });

        return data;
      } catch (error) {
        logger.error('Failed to fetch account info', {
          component: 'useAccountInfo',
          error
        });
        throw error;
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: 2,
    refetchOnWindowFocus: false
  });
};