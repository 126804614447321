/**
 * @file src/components/cloud-drive/selection/SelectionBox.tsx
 * @description Selection box component with intersection detection
 * @version 1.0.0
 * @created 2024-12-26
 */

import React, { useRef, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/utils/utils';
import type { DriveItem } from '@/types/cloud-drive.types';

interface SelectionBoxProps {
  containerRef: React.RefObject<HTMLElement>;
  items: DriveItem[];
  onSelect: (selectedIds: string[]) => void;
  disabled?: boolean;
  className?: string;
}

interface SelectionState {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  isSelecting: boolean;
}

export const SelectionBox: React.FC<SelectionBoxProps> = ({
  containerRef,
  items,
  onSelect,
  disabled = false,
  className
}) => {
  const selectionRef = useRef<SelectionState>({
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
    isSelecting: false
  });

  const boxRef = useRef<HTMLDivElement>(null);

  // Get coordinates relative to container
  const getRelativeCoordinates = useCallback((e: MouseEvent) => {
    if (!containerRef.current) return { x: 0, y: 0 };
    
    const rect = containerRef.current.getBoundingClientRect();
    const scrollTop = containerRef.current.scrollTop;
    const scrollLeft = containerRef.current.scrollLeft;

    return {
      x: e.clientX - rect.left + scrollLeft,
      y: e.clientY - rect.top + scrollTop
    };
  }, [containerRef]);

  // Check if element intersects with selection box
  const isIntersecting = useCallback((element: HTMLElement, selection: DOMRect) => {
    const rect = element.getBoundingClientRect();
    const containerRect = containerRef.current?.getBoundingClientRect();
    
    if (!containerRect) return false;

    // Adjust coordinates to be relative to container
    const elementRect = {
      left: rect.left - containerRect.left,
      right: rect.right - containerRect.left,
      top: rect.top - containerRect.top,
      bottom: rect.bottom - containerRect.top
    };

    return !(
      elementRect.left > selection.right ||
      elementRect.right < selection.left ||
      elementRect.top > selection.bottom ||
      elementRect.bottom < selection.top
    );
  }, [containerRef]);

  // Update selection box visuals
  const updateSelectionBox = useCallback(() => {
    if (!boxRef.current || !selectionRef.current.isSelecting) return;

    const { startX, startY, endX, endY } = selectionRef.current;
    const left = Math.min(startX, endX);
    const top = Math.min(startY, endY);
    const width = Math.abs(endX - startX);
    const height = Math.abs(endY - startY);

    boxRef.current.style.left = `${left}px`;
    boxRef.current.style.top = `${top}px`;
    boxRef.current.style.width = `${width}px`;
    boxRef.current.style.height = `${height}px`;

    // Check intersections and update selection
    const selectedIds = items
      .filter((item: any) => {
        const element = document.getElementById(`file-item-${item.id}`);
        return element && isIntersecting(element, boxRef.current!.getBoundingClientRect());
      })
      .map((item: any) => item.id);

    onSelect(selectedIds);
  }, [items, onSelect, isIntersecting]);

  useEffect(() => {
    if (disabled || !containerRef.current) return;

    const handleMouseDown = (e: MouseEvent) => {
      // Only start selection if clicking directly on the container
      if (e?.target !== containerRef.current) return;

      const { x, y } = getRelativeCoordinates(e);
      selectionRef.current = {
        startX: x,
        startY: y,
        endX: x,
        endY: y,
        isSelecting: true
      };

      if (!(e.ctrlKey || e.metaKey)) {
        onSelect([]); // Clear selection if not using modifier key
      }
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!selectionRef.current.isSelecting) return;

      const { x, y } = getRelativeCoordinates(e);
      selectionRef.current.endX = x;
      selectionRef.current.endY = y;
      updateSelectionBox();
    };

    const handleMouseUp = () => {
      selectionRef.current.isSelecting = false;
      if (boxRef.current) {
        boxRef.current.style.display = 'none';
      }
    };

    containerRef.current.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      containerRef.current?.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [containerRef, disabled, onSelect, getRelativeCoordinates, updateSelectionBox]);

  return (
    <AnimatePresence>
      {selectionRef.current.isSelecting && (
        <motion.div
          ref={boxRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn(
            "absolute pointer-events-none",
            "border-2 border-blue-500",
            "bg-blue-500/10",
            "z-50",
            className
          )}
        />
      )}
    </AnimatePresence>
  );
};

export default SelectionBox;