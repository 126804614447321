/**
 * @file src/components/encrypted-drive/dialogs/steps/StepRecoveryKey.tsx
 * @description Recovery key display and confirmation step
 * @version 1.2.0
 */

import React, { useState, useRef,useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Key, Copy, Download, AlertTriangle, Shield, Loader, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useToast } from '@/components/ui/toast';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import type { CreateDriveFormData } from '@/types/encrypted-drive.types';


interface StepRecoveryKeyProps {
  formData: CreateDriveFormData;
  onComplete: () => void;
  onBack: () => void;
}

export const StepRecoveryKey: React.FC<StepRecoveryKeyProps> = ({
  formData,
  onComplete,
  onBack,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { systemDevice } = useRootDrive();
  const recoveryKeyRef = useRef<HTMLDivElement>(null);

  const [isCreating] = useState(false);
  const [copied, setCopied] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  // Verify key data immediately
  useEffect(() => {
    logger.info('Recovery step mounted', {
      component: 'StepRecoveryKey',
      data: {
        hasKeyData: !!formData.keyData,
        hasRecoveryKey: !!formData.keyData?.recoveryData?.key,
        hasSystemDevice: !!systemDevice?.id
      }
    });
  }, []);

  // Verify we have the required key data
  if (!formData.keyData?.recoveryData?.key) {
    logger.error('Missing recovery key data', {
      component: 'StepRecoveryKey',
      hasKeyData: !!formData.keyData,
      hasRecoveryData: !!formData.keyData?.recoveryData
    });

    return (
      <div className="flex flex-col items-center justify-center p-8 space-y-4">
        <AlertTriangle className="w-8 h-8 text-red-500" />
        <p className="text-sm text-red-500">
          {t('encrypted_drive.errors.key_generation_failed')}
        </p>
        <Button onClick={onBack} variant="outline">
          {t('common.back')}
        </Button>
      </div>
    );
  }

  const recoveryKey = formData.keyData.recoveryData.key;

  // Format recovery key for display
  const formatRecoveryKey = (key: string) => {
    return key.match(/.{1,4}/g)?.join('-') || key;
  };

  const handleCopyKey = async () => {
    try {
      await navigator.clipboard.writeText(recoveryKey);
      setCopied(true);
      showToast(t('encrypted_drive.recovery.key_copied'), 'success');
      
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      logger.error('Error copying recovery key:', error);
      showToast(t('encrypted_drive.errors.copy_failed'), 'error');
    }
  };

  const handleDownloadKey = () => {
    try {
      const keyData = {
        driveName: formData.name,
        recoveryKey,
        generatedAt: new Date().toISOString(),
      };

      const blob = new Blob(
        [JSON.stringify(keyData, null, 2)],
        { type: 'application/json' }
      );
      
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${formData.name.toLowerCase()}-recovery-key.key`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      showToast(t('encrypted_drive.recovery.key_downloaded'), 'success');
    } catch (error) {
      logger.error('Error downloading recovery key:', error);
      showToast(t('encrypted_drive.errors.download_failed'), 'error');
    }
  };
  
  return (
    <div className="space-y-6">
      <DialogHeader>
        <DialogTitle>
          {t('encrypted_drive.recovery.title')}
        </DialogTitle>
      </DialogHeader>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-6"
      >
        {/* Recovery Key Display */}
        <div 
          ref={recoveryKeyRef}
          className={cn(
            "p-6 rounded-lg text-center",
            "bg-gray-50 dark:bg-gray-800/50",
            "border-2 border-dashed border-gray-200 dark:border-gray-700"
          )}
        >
          <div className="flex justify-center mb-4">
            <div className="rounded-full bg-blue-100 dark:bg-blue-900/50 p-3">
              <Key className="h-6 w-6 text-blue-500 dark:text-blue-400" />
            </div>
          </div>

          <div className="font-mono text-lg tracking-wider break-all">
            {formatRecoveryKey(recoveryKey)}
          </div>

          {/* Action Buttons */}
          <div className="flex justify-center space-x-3 mt-4">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={handleCopyKey}
              className="space-x-2"
            >
              {copied ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
              <span>{copied ? t('common.copied') : t('common.copy')}</span>
            </Button>

            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={handleDownloadKey}
              className="space-x-2"
            >
              <Download className="h-4 w-4" />
              <span>{t('encrypted_drive.recovery.download_key')}</span>
            </Button>
          </div>
        </div>

        {/* Warning Messages */}
        <div className="space-y-4">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="rounded-lg bg-yellow-50 dark:bg-yellow-900/20 p-4"
          >
            <div className="flex items-start space-x-3">
              <AlertTriangle className="h-5 w-5 text-yellow-500 dark:text-yellow-400 mt-0.5" />
              <div className="flex-1">
                <h4 className="text-sm font-medium text-yellow-800 dark:text-yellow-100">
                  {t('encrypted_drive.recovery.warning.title')}
                </h4>
                <p className="mt-1 text-sm text-yellow-700 dark:text-yellow-200">
                  {t('encrypted_drive.recovery.warning.description')}
                </p>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="rounded-lg bg-blue-50 dark:bg-blue-900/20 p-4"
          >
            <div className="flex items-start space-x-3">
              <Shield className="h-5 w-5 text-blue-500 dark:text-blue-400 mt-0.5" />
              <div className="flex-1">
                <h4 className="text-sm font-medium text-blue-900 dark:text-blue-100">
                  {t('encrypted_drive.password.security_note.title')}
                </h4>
                <p className="mt-1 text-sm text-blue-700 dark:text-blue-300">
                  {t('encrypted_drive.password.security_note.description')}
                </p>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Acknowledgment Checkbox */}
        <div className="flex items-start space-x-3">
          <input
            type="checkbox"
            id="acknowledge"
            checked={acknowledged}
            onChange={(e) => setAcknowledged(e.target.checked)}
            className="mt-1 h-4 w-4 rounded border-gray-300 text-blue-500 
                     focus:ring-blue-500 dark:border-gray-600 
                     dark:focus:ring-blue-400"
          />
          <label 
            htmlFor="acknowledge" 
            className="text-sm text-gray-600 dark:text-gray-300"
          >
            {t('encrypted_drive.recovery.acknowledgment')}
          </label>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between pt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            className="min-w-[100px]"
            disabled={isCreating}
          >
            {t('common.back')}
          </Button>
          
          <Button
            onClick={onComplete}
            disabled={!acknowledged || isCreating}
            className="min-w-[100px]"
          >
            {isCreating ? (
              <>
                <Loader className="w-4 h-4 mr-2 animate-spin" />
                {t('common.creating')}
              </>
            ) : (
              t('common.complete')
            )}
          </Button>
        </div>
      </motion.div>
    </div>
  );
};