/**
 * @file src/components/audit/filters/UserFilter.tsx
 * @description User filter select component for audit report with portal-based dropdown
 * @version 2.0.0
 * @created 2024-02-24
 * @updated 2025-02-26
 */

import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import type { User } from "@/types/audit.types";

interface UserFilterProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  users: User[];
  className?: string;
  disabled?: boolean;
  onLoadMore?: () => void;
  hasMore?: boolean;
  isLoading?: boolean;
}

export const UserFilter: React.FC<UserFilterProps> = ({
  value,
  onChange,
  users = [],
  className,
  disabled = false,
  onLoadMore,
  hasMore = false,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Find the selected user
  const selectedUser = users.find((user) => user.id === value);

  // Set up portal element
  useEffect(() => {
    const el = document.createElement("div");
    el.style.position = "absolute";
    el.style.zIndex = "9999";
    document.body.appendChild(el);
    setPortalElement(el);

    return () => {
      document.body.removeChild(el);
    };
  }, []);

  // Position the dropdown when button position changes or dropdown opens
  useEffect(() => {
    if (isOpen && buttonRef.current && portalElement) {
      const updatePosition = () => {
        const buttonRect = buttonRef.current?.getBoundingClientRect();

        if (buttonRect && portalElement) {
          portalElement.style.position = "absolute";
          portalElement.style.width = `${buttonRect.width}px`;
          portalElement.style.top = `${
            buttonRect.bottom + window.scrollY + 4
          }px`;
          portalElement.style.left = `${buttonRect.left + window.scrollX}px`;
        }
      };

      updatePosition();
      window.addEventListener("resize", updatePosition);
      window.addEventListener("scroll", updatePosition);

      return () => {
        window.removeEventListener("resize", updatePosition);
        window.removeEventListener("scroll", updatePosition);
      };
    }
  }, [isOpen, portalElement]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isOpen &&
        buttonRef.current &&
        dropdownRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  // Handle user selection
  const handleSelectUser = (userId: string) => {
    onChange(userId);
    setIsOpen(false);
  };

  // Clear selection
  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(undefined);
  };

  // Toggle dropdown
  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  // Handle scroll to load more users
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!onLoadMore || !hasMore || isLoading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop - clientHeight < 30) {
      onLoadMore();
    }
  };

  return (
    <div className="w-full max-w-xs relative">
      {/* Button */}
      <button
        ref={buttonRef}
        type="button"
        className={cn(
          "flex items-center justify-between",
          "w-full h-9 px-3",
          "bg-white dark:bg-gray-900",
          "border border-gray-200 dark:border-gray-800",
          "rounded-md text-left",
          disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
          className
        )}
        onClick={toggleDropdown}
        disabled={disabled}
      >
        <span className="block truncate flex-1 pr-2 text-sm">
          {selectedUser
            ? selectedUser.email
            : t("audit.filters.selectUser", "Select user")}
        </span>
        <div className="flex items-center space-x-1 flex-shrink-0">
          {selectedUser && (
            <button
              type="button"
              onClick={handleClear}
              className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 p-1"
            >
              <span className="sr-only">Clear selection</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          )}
          <ChevronDown className="h-4 w-4 text-gray-500 flex-shrink-0" />
        </div>
      </button>

      {/* Portal for dropdown to avoid CSS conflicts */}
      {isOpen &&
        portalElement &&
        ReactDOM.createPortal(
          <div
            ref={dropdownRef}
            className="border border-gray-200 dark:border-gray-800 rounded-md shadow-lg bg-white dark:bg-gray-900 overflow-hidden"
            style={{ width: "100%" }}
          >
            <div
              className="overflow-y-auto"
              style={{ maxHeight: "350px" }}
              onScroll={handleScroll}
            >
              {users.length > 0 ? (
                users.map((user) => (
                  <div
                    key={user.id}
                    className={cn(
                      "px-3 py-2 cursor-pointer border-b border-gray-100 dark:border-gray-800 last:border-0",
                      "hover:bg-gray-100 dark:hover:bg-gray-800",
                      value === user.id && "bg-blue-50 dark:bg-blue-900/20"
                    )}
                    onClick={() => handleSelectUser(user.id)}
                  >
                    <span className="block w-full text-sm text-gray-800 dark:text-gray-200 overflow-hidden text-ellipsis">
                      {user.email}
                    </span>
                  </div>
                ))
              ) : (
                <div className="px-3 py-4 text-center text-sm text-gray-500 dark:text-gray-400">
                  {t("audit.filters.noUsers", "No users available")}
                </div>
              )}
            </div>

            <div className="px-3 py-2 text-center text-xs text-gray-500 border-t border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-gray-800">
              {isLoading ? (
                <div className="flex justify-center items-center py-1">
                  <div className="w-4 h-4 border-2 border-gray-300 border-t-gray-600 rounded-full animate-spin"></div>
                  <span className="ml-2">
                    {t("common.loading", "Loading...")}
                  </span>
                </div>
              ) : (
                <>
                  <div>
                    {users.length}{" "}
                    {t("audit.filters.usersAvailable", "users available")}
                  </div>
                  {hasMore && (
                    <div className="mt-1 text-blue-500">
                      {t("common.scrollForMore", "Scroll for more")}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>,
          portalElement
        )}
    </div>
  );
};

export default UserFilter;
