/**
 * @file src/layouts/ghost/components/sidebar/components/CoresList.tsx
 * @description List of encrypted drive cores with simplified empty state and performance optimizations
 * @version 1.5.0
 */

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, Shield } from 'lucide-react';
import { motion } from 'framer-motion';
import { logger } from '@/utils/logger';
import { useEncryptedDrives } from '@/hooks/encrypted-drive/useEncryptedDrive';
import { encryptionService, DRIVE_STATUS_CHANGED_EVENT, dispatchDriveStatusChange } from '@/services/encrypted-drive/encryption-singleton';
import { useCloudDrive } from '@/context/cloud-drive';
import { GhostSkeleton } from '../../common/GhostSkeleton';
import { GhostErrorState } from '../../common/GhostErrorState';
import { GhostUnlockDriveDialog } from '../../dialogs/GhostUnlockDriveDialog';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { CoreListItem } from './CoreListItem';
import { cn } from '@/utils/utils';
import { EncryptionTier } from '@/types/encrypted-drive.types';
import type { EncryptedDrive } from '@/types/encrypted-drive.types';

interface ManageDrivesButtonProps {
  isCollapsed: boolean;
  onClick: () => void;
}

// Memoized ManageDrivesButton component
const ManageDrivesButton = React.memo<ManageDrivesButtonProps>(({ isCollapsed, onClick }) => {
  const { t } = useGhostTranslation();
  
  return (
    <div 
      className={cn(
        "w-full cursor-pointer group",
        "px-3 py-2 mt-2",
        "rounded-lg",
        "hover:bg-[hsl(var(--ghost-surface))]/50",
        "border-t border-[hsl(var(--ghost-accent))]/10",
        "transition-all duration-200"
      )}
      onClick={onClick}
    >
      <div className="flex items-center">
        <div className={cn(
          "flex items-center justify-center",
          "h-8 w-8 rounded-lg",
          "bg-[hsl(var(--ghost-surface))]/50",
          "group-hover:bg-[hsl(var(--ghost-accent))]/0.1",
          "transition-all duration-200"
        )}>
          <Settings className="h-4 w-4 text-[hsl(var(--ghost-foreground-muted))] group-hover:text-[hsl(var(--ghost-accent))]" />
        </div>
        
        {!isCollapsed && (
          <div className="ml-3">
            <span className="text-sm font-medium text-[hsl(var(--ghost-foreground-muted))] group-hover:text-[hsl(var(--ghost-accent))]">
              {String(t('drives.manage'))}
            </span>
          </div>
        )}
      </div>
    </div>
  );
});

ManageDrivesButton.displayName = 'ManageDrivesButton';

// Simple empty state component with dotted border
const SimpleEmptyState = React.memo<{isCollapsed: boolean}>(({ isCollapsed }) => {
  const { t } = useGhostTranslation();
  
  // If collapsed, show minimal state
  if (isCollapsed) {
    return (
      <div className="px-1.5 py-2">
        <div className={cn(
          "w-full rounded-lg py-3",
          "border border-dashed border-[hsl(var(--ghost-foreground-muted))]/15", // More subtle border
          "flex flex-col items-center justify-center"
        )}>
          <Shield className="h-5 w-5 mb-1 text-[hsl(var(--ghost-foreground-muted))]/60" />
        </div>
      </div>
    );
  }
  
  // Expanded view - simple with dotted border matching the screenshot
  return (
    <div className="px-1.5 py-2">
      <div className={cn(
        "w-full rounded-lg py-6",
        "border border-dashed border-[hsl(var(--ghost-foreground-muted))]/15", // More subtle border
        "flex flex-col items-center justify-center"
      )}>
        <Shield className="h-7 w-7 mb-3 text-[hsl(var(--ghost-foreground-muted))]/60" />
        <span className="text-sm text-[hsl(var(--ghost-foreground-muted))]">
          {t('drives.no_drives')}
        </span>
      </div>
    </div>
  );
});

SimpleEmptyState.displayName = 'SimpleEmptyState';

interface CoresListProps {
  isCollapsed: boolean;
}

export const CoresList = React.memo<CoresListProps>(({
  isCollapsed
}) => {
  const navigate = useNavigate();
  const [selectedCore, setSelectedCore] = useState<string | null>(null);
  const [_tooltipId, setTooltipId] = useState<string | null>(null);
  const [selectedDrive, setSelectedDrive] = useState<EncryptedDrive | null>(null);
  const [forceUpdate, setForceUpdate] = useState<number>(0); // State to force re-renders
  
  const {
    drives,
    isLoading,
    error,
  } = useEncryptedDrives();
  
  // Get the setEncryptedDrive function from CloudDriveContext
  const { setEncryptedDrive } = useCloudDrive();
  
  // Listen for drive status change events to update UI
  useEffect(() => {
    // Event handler for drive status changes
    const handleDriveStatusChange = (event: any) => {
      logger.debug('Drive status change event detected in CoresList', {
        component: 'CoresList',
        data: event.detail
      });
      
      // Force a re-render to ensure status indicators update correctly
      setForceUpdate(prev => prev + 1);
      
      // If this is a server call, also force a query invalidation using a timeout
      // in case the QueryClient needs to be refreshed
      setTimeout(() => {
        try {
          const queryClient = (window as any).__reactQueryClient;
          if (queryClient && typeof queryClient.invalidateQueries === 'function') {
            queryClient.invalidateQueries(['encrypted-drives', 'list']);
          }
        } catch (err) {
          // Ignore errors - this is just a best-effort refresh
        }
      }, 100);
    };
    
    // Register for drive status change events
    window.addEventListener(DRIVE_STATUS_CHANGED_EVENT, handleDriveStatusChange);
    
    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener(DRIVE_STATUS_CHANGED_EVENT, handleDriveStatusChange);
    };
  }, []);
  
  // Log only on initial render or important prop changes
  React.useEffect(() => {
    logger.debug('Rendering CoresList', {
      component: 'CoresList',
      driveCount: drives?.length,
      isLoading,
      error: error?.message,
      selectedCore,
      forceUpdate
    });
  }, [drives?.length, isLoading, error, selectedCore, forceUpdate]);

  const handleCreateClick = useCallback(() => {
    logger.debug('Create drive clicked');
  }, []);

  const handleDriveClick = useCallback((driveId: string) => {
    setSelectedCore(prev => driveId === prev ? null : driveId);
    
    // Find the drive from the list of drives
    const driveData = drives?.find((drive: any) => drive.id === driveId);
    
    if (!driveData) {
      logger.error('Drive not found in list', { driveId });
      navigate(`/ghost/drive/${driveId}`);
      return;
    }
    
    // Enhanced validation of drive unlock status with multiple checks
    // 1. Check if drive is marked as unlocked in server response
    const serverSaysUnlocked = driveData.status === 'unlocked';
    
    // 2. Check if the drive is unlocked in our local encryption service with enhanced validation
    const isLocallyUnlocked = encryptionService.isDriveUnlocked(driveId);
    
    // 3. Get detailed key information for better diagnostics
    const keys = encryptionService.getDecryptedKeys(driveId);
    const hasCompleteKeys = !!(keys && keys.contentKey && keys.metadataKey);
    
    // Determine if drive is truly unlocked based on all checks
    const isTrulyUnlocked = serverSaysUnlocked && isLocallyUnlocked && hasCompleteKeys;
    
    logger.debug('Sidebar drive clicked with enhanced validation', {
      component: 'CoresList',
      data: {
        driveId,
        driveName: driveData.name,
        serverStatus: driveData.status,
        isLocallyUnlocked,
        hasKeys: !!keys, 
        hasCompleteKeys,
        isTrulyUnlocked
      }
    });
    
    // If drive is truly unlocked with all checks passing, navigate directly
    if (isTrulyUnlocked) {
      logger.debug('Drive is confirmed unlocked with valid keys, navigating', {
        component: 'CoresList',
        data: {
          driveId,
          driveName: driveData.name,
          encryptionTier: driveData.encryption_tier
        }
      });
      
      // Set encrypted drive context - this is the key change
      setEncryptedDrive({
        isEncryptedMode: true,
        encryptedDriveId: driveId,
        encryptionTier: driveData.encryption_tier,
        driveName: driveData.name
      });
      
      // Navigate directly to drive
      navigate(`/ghost/drive/${driveId}`, {
        replace: true
      });
      return;
    }
    
    // We've reached this point, so the drive needs unlocking
    // There are several possible states causing this:
    // - Server says locked
    // - Server says unlocked but we don't have local keys
    // - Server says unlocked but local validation failed
    
    logger.debug('Drive needs unlocking', {
      component: 'CoresList',
      data: {
        driveId,
        driveName: driveData.name,
        serverStatus: driveData.status,
        statusMismatch: serverSaysUnlocked && !isLocallyUnlocked,
        missingKeys: serverSaysUnlocked && !hasCompleteKeys
      }
    });
    
    // If server says unlocked but we don't have keys, log this inconsistency
    if (serverSaysUnlocked && !isLocallyUnlocked) {
      logger.warn('Status inconsistency: Server says unlocked but local validation failed', {
        component: 'CoresList',
        data: { 
          driveId,
          driveName: driveData.name,
          hasAnyKeys: !!keys
        }
      });
    }
    
    // Show the unlock dialog directly
    setSelectedDrive(driveData as EncryptedDrive);
  }, [navigate, drives, setEncryptedDrive]);

  const handleManageDrivesClick = useCallback(() => {
    logger.debug('Manage drives clicked');
    navigate('/ghost/drives');
  }, [navigate]);

  const updateTooltip = useCallback((id: string, element: HTMLDivElement | null) => {
    setTooltipId(id);
  }, []);
  
  // Handle successful unlock (just like in the Drives page)
  const handleUnlockSuccess = useCallback((drive: EncryptedDrive) => {
    logger.debug('Drive unlock successful', {
      component: 'CoresList',
      action: 'handleUnlockSuccess',
      data: {
        driveId: drive.id,
        encryptionTier: drive.encryption_tier
      }
    });
  
    // Set encrypted drive context
    setEncryptedDrive({
      isEncryptedMode: true,
      encryptedDriveId: drive.id,
      encryptionTier: drive.encryption_tier,
      driveName: drive.name
    });
    
    // Force a refresh of the entire list to reflect the new unlock state
    setForceUpdate(prev => prev + 1);
    
    // Dispatch status change event to notify all components
    dispatchDriveStatusChange(drive.id, 'unlocked');
  
    // Navigate with a slight delay for success animation
    setTimeout(() => {
      navigate(`/ghost/drive/${drive.id}`, {
        replace: true
      });
    }, 1500);
  }, [navigate, setEncryptedDrive]);

  // Show loading state
  if (isLoading) {
    return <GhostSkeleton count={3} />;
  }

  // Show error state
  if (error) {
    return (
      <GhostErrorState
        error={error as Error}
        onRetry={() => {
          logger.debug('Retrying drive fetch');
        }}
      />
    );
  }

  // Show empty state - now using our simpler design with dotted border
  if (!drives?.length) {
    return (
      <div>
        <SimpleEmptyState isCollapsed={isCollapsed} />
        <ManageDrivesButton 
          isCollapsed={isCollapsed} 
          onClick={handleManageDrivesClick} 
        />
      </div>
    );
  }

  // Show drives list with Manage Drives button
  return (
    <>
      <div className="space-y-0.5 px-1.5">
        {/* Show up to 3 drives, sorted by last accessed time */}
        {drives
          .slice()
          .sort((a, b) => {
            // Sort by status first (unlocked drives first)
            if (a.status === 'unlocked' && b.status !== 'unlocked') return -1;
            if (a.status !== 'unlocked' && b.status === 'unlocked') return 1;
            
            // Then sort by last_accessed (if available)
            if (a.last_accessed && b.last_accessed) {
              return new Date(b.last_accessed).getTime() - new Date(a.last_accessed).getTime();
            }
            if (a.last_accessed) return -1;
            if (b.last_accessed) return 1;
            
            // Finally, fall back to creation date
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          })
          .slice(0, 3)
          .map((drive: any) => (
          <CoreListItem
            key={drive.id}
            drive={drive}
            isActive={drive.id === selectedCore}
            isCollapsed={isCollapsed}
            onClick={() => handleDriveClick(drive.id)}
            updateTooltip={updateTooltip}
          />
        ))}
        
        {/* Always show the Manage Drives button */}
        <ManageDrivesButton 
          isCollapsed={isCollapsed} 
          onClick={handleManageDrivesClick} 
        />
      </div>
      
      {/* Unlock Drive Dialog */}
      {selectedDrive && (
        <GhostUnlockDriveDialog
          drive={selectedDrive}
          onOpenChange={() => setSelectedDrive(null)}
          onUnlockSuccess={handleUnlockSuccess}
          onRecovery={() => {
            setSelectedDrive(null);
            // Navigate to recovery page
            navigate('/ghost/drives');
          }}
        />
      )}
    </>
  );
});

CoresList.displayName = 'CoresList';

export default CoresList;