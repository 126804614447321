/**
 * @file src/components/cloud-drive/items/components/ItemIcon.tsx
 * @description Icon component for file/folder items with encrypted indicator
 * @version 1.1.0
 */

import React from 'react';
import { Shield } from 'lucide-react';
import { cn } from '@/utils/utils';
import { getFileIcon, getFolderIcon } from '../utils/fileIcons';
import { useCloudDrive } from '@/context/cloud-drive';
import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';

interface ItemIconProps {
  item: DriveFile | DriveFolder;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const sizeClasses = {
  sm: "h-5 w-5",
  md: "h-8 w-8",
  lg: "h-16 w-16"
};

const badgeSizeClasses = {
  sm: "h-3 w-3 -top-1 -right-1",
  md: "h-4 w-4 -top-1.5 -right-1.5",
  lg: "h-5 w-5 -top-2 -right-2"
};

export const ItemIcon: React.FC<ItemIconProps> = ({
  item,
  size = 'md',
  className
}) => {
  const { encryptedDrive } = useCloudDrive();
  const { Icon, color } = item.type === 'folder'
    ? getFolderIcon()
    : getFileIcon((item as DriveFile).mimeType);

  return (
    <div className={cn(
      "flex items-center justify-center relative",
      item.type === 'folder' && "bg-gray-50/50 dark:bg-gray-900/50",
      className
    )}>
      <Icon className={cn(sizeClasses[size], color)} />
      
      {/* Encryption indicator */}
      {encryptedDrive?.isEncryptedMode && (
        <div className={cn(
          "absolute",
          badgeSizeClasses[size],
          "bg-blue-100 dark:bg-blue-900/70 rounded-full p-0.5",
          "flex items-center justify-center",
          "border border-blue-200 dark:border-blue-800"
        )}>
          <Shield className="text-blue-500 dark:text-blue-400 w-full h-full" />
        </div>
      )}
    </div>
  );
};