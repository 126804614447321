/**
 * @file src/components/upload/GlobalUploadQueue/UploadQueueItem.tsx
 * @description Individual upload item with enhanced encryption status display
 * @version 1.6.0
 * @updated 2025-03-18
 */

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, Cloud, X, AlertCircle, CheckCircle, Loader2, Pause, Play } from 'lucide-react';
import {FileKey, Video, Image, Film, Upload} from 'lucide-react';
import { useCloudDrive } from '@/context/cloud-drive';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { FileIcon } from '@/components/upload/shared/FileIcon';
import { useUploadQueue } from '@/hooks/upload/useUploadQueue';
import type { UploadFile } from '@/types/upload.types';
import { formatBytes } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useTranslation } from 'react-i18next';

// Define missing types
type EncryptionStatus = 'processing' | 'transcoding' | 'encrypting' | 'uploading' | 'completed';

// Extend UploadFile type with the missing properties
interface ExtendedUploadFile extends UploadFile {
  encryptionStatus?: EncryptionStatus;
  isEncrypted?: boolean;
  encryptionProgress?: number;
  statusMessage?: string;
  originalSize?: number;
  encryptedSize?: number;
  uploadSpeed?: number;
  isChunked?: boolean;
}

interface UploadQueueItemProps {
  item: ExtendedUploadFile;
  isLast?: boolean;
}

export const UploadQueueItem: React.FC<UploadQueueItemProps> = ({
  item,
  isLast = false
}) => {
  const { t } = useTranslation();
  const { removeFile, cancelUpload, pauseUpload, resumeUpload } = useUploadQueue();
  const [showSuccess, setShowSuccess] = React.useState(false);
  const { encryptedDrive } = useCloudDrive();
  
  // Track progress phases for delayed animation 
  const [progressPhase, setProgressPhase] = React.useState<EncryptionStatus | null>(null);
  
  // Add pulsing animation for long operations
  const [isPulsing, setIsPulsing] = React.useState(false);
  const pulseTimerRef = React.useRef<NodeJS.Timeout | null>(null);
  
  // Show success animation on completion
  React.useEffect(() => {
    if (item?.status === 'completed') {
      setShowSuccess(true);
      const timer = setTimeout(() => setShowSuccess(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [item.status]);
  
  // Monitor encryption status for long-running operations
  React.useEffect(() => {
    // Track current phase for UI animations
    if (item.encryptionStatus && progressPhase !== item.encryptionStatus) {
      setProgressPhase(item.encryptionStatus);
    }
    
    // Start pulsing animation for long processing/transcoding operations
    if (item?.encryptionStatus === 'processing' || item.encryptionStatus === 'transcoding') {
      // After 3 seconds, start pulsing animation if still in same phase
      if (!isPulsing && !pulseTimerRef.current) {
        pulseTimerRef.current = setTimeout(() => {
          setIsPulsing(true);
        }, 3000);
      }
    } else {
      // Clear timer and stop pulsing when phase changes
      if (pulseTimerRef.current) {
        clearTimeout(pulseTimerRef.current);
        pulseTimerRef.current = null;
      }
      setIsPulsing(false);
    }
    
    return () => {
      if (pulseTimerRef.current) {
        clearTimeout(pulseTimerRef.current);
        pulseTimerRef.current = null;
      }
    };
  }, [item.encryptionStatus, progressPhase, isPulsing]);

  // Get progress considering edge cases - with unified smooth scaling
  const getProgress = () => {
    try {
      if (item?.status === 'completed') return 100;
      if (item?.status === 'failed' || item.status === 'cancelled') return 0;
      
      // Create a unified progress scale that combines encryption and upload
      if (item.isEncrypted) {
        // If we're not uploading yet, encryption is still in progress (0-90%)
        if (item.encryptionStatus !== 'uploading') {
          // Scale encryption progress to 0-90% range
          if (item.encryptionProgress !== undefined) {
            return Math.min((item.encryptionProgress * 0.9), 90);
          }
          // Default values for different stages if encryptionProgress is missing
          if (item.encryptionStatus === 'processing') return 30;
          if (item.encryptionStatus === 'transcoding') return 50;
          if (item.encryptionStatus === 'encrypting') return 70;
          return 40; // Default for other stages
        }
        
        // If we're uploading, the encryption is done, so scale upload progress to 90-100% range
        if (item.bytesUploaded !== undefined && item.file.size > 0) {
          const uploadPercentage = (item.bytesUploaded / item.file.size) * 100;
          // Scale from 0-100% to 90-100%
          return 90 + (uploadPercentage * 0.1);
        }
        
        // If we don't have upload progress yet but we're in uploading stage
        return 90;
      }
      
      // For non-encrypted files, just use regular upload progress
      const progress = Math.min(
        ((item.bytesUploaded || 0) / item.file.size) * 100,
        99.9
      );
  
      return progress;
    } catch (error) {
      logger.error('Error calculating progress', {
        component: 'UploadQueueItem',
        action: 'getProgress',
        error
      });
      return 0;
    }
  };

  // Enhanced status text display with GenZ style and more detailed phases
  const getStatusText = () => {
    // First check for explicit status message provided by service
    if (item.statusMessage) {
      return item.statusMessage;
    }
    
    // Special handling for completed encrypted files
    if (item.status === 'completed' && item.isEncrypted) {
      return t('upload.status.secured', 'Secured in your vault!');
    }
    
    if (item.isEncrypted) {
      // Handle video files with enhanced status messages
      if (item.file.type.startsWith('video/')) {
        switch(item.encryptionStatus) {
          case 'processing':
            return t('upload.status.processing_video', 'Processing your video...');
          case 'transcoding':
            return t('upload.status.transcoding_video', 'Creating optimized version...');
          case 'encrypting':
            return t('upload.status.encrypting_video', 'Adding quantum encryption...');
          case 'uploading':
            return t('upload.status.uploading_video', 'Sending to your secure vault...');
          case 'completed':
            return t('upload.status.completed_video', 'Video secured!');
          default:
            return t(`upload.status.${item.status}`, item.status);
        }
      }
      
      // Handle image files with enhanced status messages
      if (item.file.type.startsWith('image/')) {
        switch(item.encryptionStatus) {
          case 'processing':
            return t('upload.status.processing_image', 'Creating secure previews...');
          case 'encrypting':
            return t('upload.status.encrypting_image', 'Applying encryption shield...');
          case 'uploading':
            return t('upload.status.uploading_image', 'Storing in your private gallery...');
          case 'completed':
            return t('upload.status.completed_image', 'Image secured!');
          default:
            return t(`upload.status.${item.status}`, item.status);
        }
      }
      
      // Generic encrypted file status messages
      switch(item.encryptionStatus) {
        case 'processing':
          return t('upload.status.processing', 'Processing file...');
        case 'transcoding':
          return t('upload.status.transcoding', 'Optimizing format...');
        case 'encrypting':
          return t('upload.status.encrypting', 'Encrypting your data...');
        case 'uploading':
          return t('upload.status.uploading_encrypted', 'Uploading to secure storage...');
        case 'completed':
          return t('upload.status.completed', 'Safe and sound!');
        default:
          return t(`upload.status.${item.status}`, item.status);
      }
    }

    return t(`upload.status.${item.status}`, item.status);
  };

  // Enhanced size info display with better handling of transcoded files
  const getSizeInfo = () => {
    // When in a processing or transcoding state, don't show byte counts
    // as these will be shown alongside the status text
    if (item.isEncrypted && 
        (item.encryptionStatus === 'processing' || 
         item.encryptionStatus === 'transcoding' || 
         item.encryptionStatus === 'encrypting')) {
      return '';
    }
    
    // Show both original and transcoded sizes when available
    if (item.isEncrypted && item.originalSize && item.encryptedSize) {
      const compressionRatio = Math.round((1 - (item.encryptedSize / item.originalSize)) * 100);
      
      // Only show compression info if there's significant difference
      if (Math.abs(compressionRatio) > 5) {
        return `${formatBytes(item.encryptedSize)} of ${formatBytes(item.originalSize)} (${
          compressionRatio > 0 ? '-' : '+'
        }${Math.abs(compressionRatio)}%)`;
      }
      
      // If sizes are about the same
      if (item.encryptionStatus === 'completed' || item?.status === 'completed') {
        return `${formatBytes(item.encryptedSize)}`;
      }
      
      // During upload - only show this for the uploading phase
      if (item.encryptionStatus === 'uploading') {
        return `${formatBytes(item.bytesUploaded || 0)} of ${formatBytes(item.encryptedSize)}`;
      }
      
      return formatBytes(item.encryptedSize);
    }
    
    // For regular uploads just show regular progress
    const uploaded = Math.min(item.bytesUploaded || 0, item.file.size);
    const total = item.file.size;
    return `${formatBytes(uploaded)} of ${formatBytes(total)}`;
  };

  // Format upload speed 
  const getSpeedInfo = () => {
    if (!item?.uploadSpeed || item.uploadSpeed <= 0) return null;
    return formatBytes(item.uploadSpeed) + '/s';
  };

  // Choose the appropriate icon for the current status
  const getStatusIcon = () => {
    // First priority: show correct icon for completed encrypted files
    if (item?.status === 'completed' && item.isEncrypted) {
      return <CheckCircle className="h-5 w-5 text-green-500" />;
    }
    
    if (item.isEncrypted) {
      // Processing phase icons
      if (item?.encryptionStatus === 'processing') {
        if (item.file.type.startsWith('video/')) {
          return (
            <motion.div
              animate={{ 
                rotate: 360,
                scale: isPulsing ? [1, 1.1, 1] : 1
              }}
              transition={{ 
                rotate: { duration: 2, repeat: Infinity, ease: "linear" },
                scale: isPulsing ? { duration: 1.5, repeat: Infinity, ease: "easeInOut" } : { duration: 0 }
              }}
              className="text-purple-500"
            >
              <Video className="h-5 w-5" />
            </motion.div>
          );
        } else if (item.file.type.startsWith('image/')) {
          return (
            <motion.div
              animate={{ 
                rotate: 360,
                scale: isPulsing ? [1, 1.1, 1] : 1
              }}
              transition={{ 
                rotate: { duration: 2, repeat: Infinity, ease: "linear" },
                scale: isPulsing ? { duration: 1.5, repeat: Infinity, ease: "easeInOut" } : { duration: 0 }
              }}
              className="text-pink-500"
            >
              <Image className="h-5 w-5" />
            </motion.div>
          );
        } else {
          return (
            <motion.div
              animate={{ 
                rotate: 360
              }}
              transition={{ 
                rotate: { duration: 2, repeat: Infinity, ease: "linear" }
              }}
              className="text-purple-500"
            >
              <Loader2 className="h-5 w-5" />
            </motion.div>
          );
        }
      }
      
      // Transcoding phase (specifically for video)
      if (item?.encryptionStatus === 'transcoding') {
        return (
          <motion.div
            animate={{ 
              rotate: [0, 0, 180, 180, 0],
              scale: isPulsing ? [1, 1.2, 1] : 1
            }}
            transition={{ 
              rotate: { duration: 3, repeat: Infinity, ease: "easeInOut" },
              scale: isPulsing ? { duration: 2, repeat: Infinity, ease: "easeInOut" } : { duration: 0 }
            }}
            className="text-indigo-500"
          >
            <Film className="h-5 w-5" />
          </motion.div>
        );
      }
      
      // Encryption phase
      if (item?.encryptionStatus === 'encrypting') {
        return (
          <motion.div
            animate={{ 
              rotate: 360,
              scale: [1, 1.1, 1]
            }}
            transition={{ 
              rotate: { duration: 2, repeat: Infinity, ease: "linear" },
              scale: { duration: 1, repeat: Infinity, ease: "easeInOut" }
            }}
            className="text-blue-500"
          >
            <FileKey className="h-5 w-5" />
          </motion.div>
        );
      }
      
      // Upload phase
      if (item?.encryptionStatus === 'uploading') {
        return (
          <motion.div
            animate={{ 
              y: [0, -3, 0],
              rotate: 0
            }}
            transition={{ 
              y: { duration: 1, repeat: Infinity, ease: "easeInOut" },
              rotate: { duration: 0 }
            }}
            className="text-blue-500"
          >
            <Upload className="h-5 w-5" />
          </motion.div>
        );
      }
    }
    
    // Default loading indicator for regular uploads
    if (item?.status === 'uploading') {
      return (
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
        >
          <Loader2 className="h-5 w-5 text-blue-500" />
        </motion.div>
      );
    }
    
    if (item?.status === 'failed') {
      return <AlertCircle className="h-5 w-5 text-red-500" />;
    }
    
    if (item?.status === 'paused') {
      return <Pause className="h-5 w-5 text-yellow-500" />;
    }
    
    if (item?.status === 'completed') {
      return <CheckCircle className="h-5 w-5 text-green-500" />;
    }
    
    return null;
  };

  // Get progress color based on encryption status
  const getProgressColor = () => {
    if (item.isEncrypted) {
      switch (item.encryptionStatus) {
        case 'processing':
          return 'bg-purple-100 dark:bg-purple-900/20';
        case 'transcoding':
          return 'bg-indigo-100 dark:bg-indigo-900/20';
        case 'encrypting':
          return 'bg-blue-100 dark:bg-blue-900/20';
        case 'uploading':
          return 'bg-cyan-100 dark:bg-cyan-900/20';
        default:
          return 'bg-blue-100 dark:bg-blue-900/20';
      }
    }
    return 'bg-gray-100 dark:bg-gray-800';
  };

  // Get progress gradient color based on encryption status
  const getProgressGradient = () => {
    if (item.isEncrypted) {
      switch (item.encryptionStatus) {
        case 'processing':
          return 'from-purple-500/20 to-purple-500/50';
        case 'transcoding':
          return 'from-indigo-500/20 to-indigo-500/50';
        case 'encrypting':
          return 'from-blue-500/20 to-blue-500/50';
        case 'uploading':
          return 'from-cyan-500/20 to-cyan-500/50';
        default:
          return 'from-blue-500/20 to-blue-500/50';
      }
    }
    return 'from-gray-500/20 to-gray-500/50';
  };

  // Get text color based on encryption status
  const getStatusTextColor = () => {
    if (item.isEncrypted) {
      switch (item.encryptionStatus) {
        case 'processing':
          return 'text-purple-500';
        case 'transcoding':
          return 'text-indigo-500';
        case 'encrypting':
          return 'text-blue-500';
        case 'uploading':
          return 'text-cyan-500';
        case 'completed':
          return 'text-green-500';
        default:
          return 'text-blue-500';
      }
    }
    
    if (item?.status === 'failed') return 'text-red-500';
    if (item?.status === 'completed') return 'text-green-500';
    if (item?.status === 'paused') return 'text-yellow-500';
    
    return '';
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.2 }}
      className={cn(
        "px-3 py-2",
        "border-b border-gray-100 dark:border-gray-800",
        "hover:bg-gray-50 dark:hover:bg-gray-800/50",
        item.isEncrypted && "bg-blue-50/30 dark:bg-blue-900/10", // Subtle background for encrypted files
        isLast && "border-b-0"
      )}
    >
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-3 min-w-0">
          <div className="relative hidden sm:block">
            <FileIcon fileName={item.file.name} size={24} />
            {/* Enhanced indicator badges */}
            {item.isEncrypted ? (
              <motion.div 
                className="absolute -right-1 -bottom-1"
                animate={{ scale: isPulsing ? [1, 1.2, 1] : 1 }}
                transition={{ 
                  scale: isPulsing ? { duration: 2, repeat: Infinity, ease: "easeInOut" } : { duration: 0 }
                }}
              >
                <Shield className="h-3 w-3 text-blue-500" />
              </motion.div>
            ) : item.strategy === 'direct_s3' && (
              <div className="absolute -right-1 -bottom-1">
                <Cloud className="h-3 w-3 text-blue-500" />
              </div>
            )}
          </div>
          
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium">
              {item.file.name.length > 35
                ? `${item.file.name.substring(0, 15)}...${item.file.name.substring(item.file.name.length - 15)}`
                : item.file.name}
            </p>
            <div className="flex flex-wrap items-center gap-x-2 gap-y-1 text-xs text-gray-500 dark:text-gray-400">
              {/* Combined size and status info display */}
              <div className="flex items-center">
                {/* For uploads in progress with encrypted files, status text already contains good messaging */}
                {item.isEncrypted && item.encryptionStatus ? (
                  <span className={cn(getStatusTextColor(), "whitespace-nowrap")}>
                    {getStatusText()}
                  </span>
                ) : (
                  <>
                    <span>{getSizeInfo()}</span>
                    
                    {/* Only show status for non-normal uploads */}
                    {(item.isEncrypted || item.status !== 'uploading') && (
                      <>
                        <span className="mx-1">•</span>
                        <span className={cn(getStatusTextColor(), "whitespace-nowrap")}>
                          {getStatusText()}
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              
              {/* Upload speed indicator removed for simplicity */}
            </div>
            
          </div>

          <AnimatePresence mode="wait">
            {showSuccess ? (
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.5, opacity: 0 }}
                className="text-green-500"
              >
                <CheckCircle className="h-5 w-5" />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {/* Get the appropriate icon based on current status */}
                {getStatusIcon()}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Controls */}
        <div className="flex items-center gap-1">
          {item.isChunked && (
            <>
              {item.status === "uploading" && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={() => pauseUpload(item.id)}
                  aria-label="Pause upload"
                >
                  <Pause className="h-4 w-4" />
                </Button>
              )}
              {item.status === "paused" && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={() => resumeUpload(item.id)}
                  aria-label="Resume upload"
                >
                  <Play className="h-4 w-4" />
                </Button>
              )}
            </>
          )}

          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => {
              if (item?.status === 'uploading') {
                cancelUpload(item.id);
              } else {
                removeFile(item.id);
              }
            }}
            aria-label={item?.status === 'uploading' ? "Cancel upload" : "Remove from list"}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>

      {/* Progress Bars with Enhancements */}
      {item.status === 'uploading' && (
        <div className="space-y-1">
          {/* Unified progress bar for all states */}
          <div className="relative">
            {/* Main progress bar - always shown with unified progress calculation */}
            <Progress 
              value={getProgress()} 
              className={cn("h-1", getProgressColor())}
            />
            
            {/* Animated overlay for processing/transcoding/encrypting phases */}
            {item.isEncrypted && (item.encryptionStatus === 'processing' || 
                                 item.encryptionStatus === 'transcoding' || 
                                 item.encryptionStatus === 'encrypting') && (
              <motion.div
                className={cn("absolute inset-0 bg-gradient-to-r", getProgressGradient())}
                animate={{
                  x: ['-100%', '100%']
                }}
                transition={{
                  duration: item.encryptionStatus === 'transcoding' ? 2 : 1.5,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
            )}
          </div>
          
          {/* Chunk Progress (for large files) */}
          {item.chunks && item.chunks.length > 0 && (
            <div className="flex gap-0.5 mt-1">
              {item.chunks.map((chunk, index) => (
                <motion.div
                  key={index}
                  className={cn(
                    "h-0.5 flex-1 rounded-full",
                    chunk.uploaded && "bg-green-500",
                    !chunk.uploaded && chunk.progress > 0 && "bg-blue-500",
                    !chunk.uploaded &&
                      chunk.progress === 0 &&
                      "bg-gray-200 dark:bg-gray-700"
                  )}
                  initial={{ opacity: 0.5 }}
                  animate={{
                    opacity: chunk.uploaded ? 1 : 0.5 + chunk.progress / 200,
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {/* Error Message */}
      {item.status === 'failed' && item.error && (
        <motion.p
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          className="text-xs text-red-500 mt-1"
        >
          {item.error}
        </motion.p>
      )}
    </motion.div>
  );
};