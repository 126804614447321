/**
 * @file src/components/cloud-drive/info/InfoPanelSkeleton.tsx
 * @description Loading skeleton for info panel
 * @version 1.1.0
 * @updated 2025-03-27
 */

import React from 'react';
import { cn } from '@/utils/utils';
import { useMediaQuery } from '@/hooks/useMediaQuery';


export const InfoPanelSkeleton: React.FC = () => {
  // Default to false if running on server or if hook fails
  let isMobile = false;
  
  try {
    // Only run the hook in browser environment
    if (typeof window !== 'undefined') {
      isMobile = useMediaQuery('(max-width: 768px)');
    }
  } catch (error) {
    console.warn('Error using useMediaQuery in InfoPanelSkeleton', error);
  }
  
  return (
    <div className={cn(
      "p-4 space-y-6 animate-pulse",
      isMobile && "px-4 py-5" // Adjusted padding on mobile
    )}>
      {/* Header */}
      <div className="space-y-2">
        <div className={cn(
          "h-6 bg-gray-200 dark:bg-gray-700 rounded w-3/4",
          isMobile && "h-5" // Smaller on mobile
        )}></div>
        <div className={cn(
          "h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2",
          isMobile && "w-2/3" // Wider on mobile
        )}></div>
      </div>

      {/* Quick Actions */}
      <div className={cn(
        "flex gap-2",
        isMobile && "grid grid-cols-2" // Grid layout on mobile
      )}>
        <div className={cn(
          "h-9 bg-gray-200 dark:bg-gray-700 rounded w-24",
          isMobile && "h-10 w-full" // Full width on mobile
        )}></div>
        <div className={cn(
          "h-9 bg-gray-200 dark:bg-gray-700 rounded w-24",
          isMobile && "h-10 w-full" // Full width on mobile
        )}></div>
      </div>

      {/* Details */}
      <div className="space-y-4">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className={cn(
            "flex items-center gap-2",
            isMobile && "gap-3" // More gap on mobile
          )}>
            <div className="h-4 w-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
            <div className={cn(
              "h-4 bg-gray-200 dark:bg-gray-700 rounded w-32",
              isMobile && "flex-1" // Flexible width on mobile
            )}></div>
            <div className={cn(
              "h-4 bg-gray-200 dark:bg-gray-700 rounded w-24",
              isMobile && "w-20" // Smaller on mobile
            )}></div>
          </div>
        ))}
      </div>

      {/* Tags */}
      <div className="space-y-2">
        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-20"></div>
        <div className="flex flex-wrap gap-2">
          {[1, 2].map((i) => (
            <div key={i} className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-16"></div>
          ))}
        </div>
      </div>
    </div>
  );
};