/**
 * @file src/context/SelectionContext.tsx
 * @description Enhanced selection context with multi-select and range support
 * @version 1.1.0
 */
import React, { createContext, useContext, useState, useCallback, useRef } from 'react';
import { logger } from '@/utils/logger';
import type { DriveItem, DriveFile } from "@/types/cloud-drive.types";

interface SelectionContextType {
  // Selection State
  selectedItems: string[];
  totalItemsCount: number;
  hasMoreItems: boolean;
  isAllSelected: boolean;
  isPaginatedSelection: boolean;
  lastSelectedItem: string | null;

    // Add these new properties
    isSelectMode: boolean;
    setIsSelectMode: (mode: boolean) => void;

  // Selection Actions
  selectItems: (ids: string[]) => void;
  toggleSelection: (id: string, event?: React.MouseEvent) => void;
  selectRange: (startId: string, endId: string, items: DriveItem[]) => void;
  selectAll: (currentPageIds: string[]) => void;
  clearSelection: () => void;
  
  // Batch Operations
  selectedItemsData: DriveItem[];
  setSelectedItemsData: (items: DriveItem[]) => void;
  
  // Selection Info
  getSelectionInfo: () => {
    count: number;
    hasFiles: boolean;
    hasFolders: boolean;
    totalSize: number;
  };
}

const SelectionContext = createContext<SelectionContextType | undefined>(undefined);

export const SelectionProvider: React.FC<{
  children: React.ReactNode;
  totalCount?: number;
}> = ({ children, totalCount = 0 }) => {
  // Selection state
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedItemsData, setSelectedItemsData] = useState<DriveItem[]>([]);
  const [isPaginatedSelection, setIsPaginatedSelection] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);
  
  const selectionModeRef = useRef<'single' | 'multi'>('single');

  // Add new selection mode state
  const [isSelectMode, setIsSelectMode] = useState(false);  

  // Toggle individual item selection with modifier key support
  // Update toggleSelection to handle selection mode
  const toggleSelection = useCallback((id: string, event?: React.MouseEvent) => {
    logger.debug('Toggle selection', {
      component: 'SelectionContext',
      data: { id, event }
    });

    if (!isSelectMode) {
      setIsSelectMode(true);
    }

    setSelectedItems(prev => {
      const isSelected = prev.includes(id);
      const newSelection = isSelected
        ? prev.filter((itemId: any) => itemId !== id)
        : [...prev, id];
        
      // If no items are selected and not in multi-select mode, exit select mode
      if (newSelection?.length === 0 && !event?.ctrlKey && !event?.shiftKey) {
        setIsSelectMode(false);
      }

      return newSelection;
    });

    setLastSelectedItem(id);
  }, [isSelectMode, setIsSelectMode]);

  // Select multiple items
  const selectItems = useCallback((ids: string[]) => {
    logger.debug('Setting selected items', {
      component: 'SelectionContext',
      data: { ids }
    });
    setSelectedItems(ids);
    if (ids?.length > 0) {
      setLastSelectedItem(ids[ids.length - 1]);
    }
  }, []);

  // Select range of items
  const selectRange = useCallback((startId: string, endId: string, items: DriveItem[]) => {
    logger.debug('Selecting range', {
      component: 'SelectionContext',
      data: { startId, endId }
    });

    const startIndex = items.findIndex(item => item.id === startId);
    const endIndex = items.findIndex(item => item.id === endId);

    if (startIndex === -1 || endIndex === -1) return;

    const start = Math.min(startIndex, endIndex);
    const end = Math.max(startIndex, endIndex);

    const rangeIds = items.slice(start, end + 1).map((item: any) => item.id);

    setSelectedItems(prev => {
      if (selectionModeRef?.current === 'multi') {
        return Array.from(new Set([...prev, ...rangeIds]));
      }
      return rangeIds;
    });
    setLastSelectedItem(endId);
  }, []);

  // Select all items
// Select all items
const selectAll = useCallback((currentPageIds: string[]) => {
    logger.debug('Select all triggered', {
      component: 'SelectionContext',
      data: { currentPageIds }
    });

    setIsSelectMode(true);

    // If all current page items are selected, clear selection
    const allCurrentSelected = currentPageIds.every((itemId: string) => selectedItems.includes(itemId));
    if (allCurrentSelected) {
      setSelectedItems([]);
      if (selectedItems?.length === currentPageIds.length) {
        setIsSelectMode(false);
      }
    } else {
      // Select all current page items
      const newSelection = Array.from(new Set([...selectedItems, ...currentPageIds]));
      setSelectedItems(newSelection);
    }

    setLastSelectedItem(currentPageIds[currentPageIds.length - 1]);
  }, [selectedItems, setIsSelectMode])

  // Clear selection
  // Update clearSelection to also clear selection mode
  const clearSelection = useCallback(() => {
    logger.debug('Clearing selection');
    setSelectedItems([]);
    setIsPaginatedSelection(false);
    setLastSelectedItem(null);
    setIsSelectMode(false); // Add this
    selectionModeRef.current = 'single';
  }, []);

  // Get selection information
  const getSelectionInfo = useCallback(() => {
    const info = {
      count: selectedItems.length,
      hasFiles: false,
      hasFolders: false,
      totalSize: 0
    };

    selectedItemsData.forEach((item: any) => {
      if (item?.type === 'file') {
        info.hasFiles = true;
        info.totalSize += (item as DriveFile).size ?? 0;
      } else {
        info.hasFolders = true;
      }
    });

    return info;
  }, [selectedItems, selectedItemsData]);

  const value: SelectionContextType = {
    // Existing values
    selectedItems,
    totalItemsCount: totalCount,
    hasMoreItems: totalCount > selectedItems.length,
    isAllSelected: selectedItems.length === totalCount,
    isPaginatedSelection,
    lastSelectedItem,
    
    // Add new values
    isSelectMode,
    setIsSelectMode,
    
    // Existing methods
    selectItems,
    toggleSelection,
    selectRange,
    selectAll,
    clearSelection,
    selectedItemsData,
    setSelectedItemsData,
    getSelectionInfo
  };

  return (
    <SelectionContext.Provider value={value}>
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelection = () => {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error('useSelection must be used within SelectionProvider');
  }
  return context;
};