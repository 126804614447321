/**
 * @file src/hooks/encrypted-drive/useEncryptedDrive.ts
 * @description Custom hook for encrypted drive operations
 * @version 1.1.0
 */

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/components/ui/toast';
import { encryptedDriveApi } from '@/services/encrypted-drive/encrypted-drive.service';
import { 
  encryptionService, 
  dispatchDriveStatusChange 
} from '@/services/encrypted-drive/encryption-singleton';
import { 
  CreateDriveRequest,
  EncryptedDrive,
  UnlockDriveRequest
} from '@/types/encrypted-drive.types';
import { logger } from '@/utils/logger';

const driveKeys = {
  all: ['encrypted-drives'] as const,
  lists: () => [...driveKeys.all, 'list'] as const,
  drive: (id: string) => [...driveKeys.all, 'drive', id] as const,
};

export const useEncryptedDrives = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  // Check if we're on an auth page to disable query
  const path = window.location.pathname;
  const onAuthPage = path.includes('/auth/') || path.includes('/ghost/auth/');

  // Get the token to check auth status
  const hasToken = !!localStorage.getItem('access_token');
  
  const { data: drives = [], isLoading, error } = useQuery({
    queryKey: driveKeys.lists(),
    queryFn: encryptedDriveApi.listDrives,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: !onAuthPage && hasToken, // Only run on non-auth pages when authenticated
    retry: onAuthPage ? 0 : 2, // Don't retry on auth pages
  });

  // Create drive mutation
  // Create drive mutation with option to disable automatic messages
  const createDrive = useMutation({
    mutationFn: encryptedDriveApi.createDrive,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: driveKeys.lists() });
      
      // Only show toast if not silenced
      if (!('silentSuccess' in variables) || !variables.silentSuccess) {
        showToast(
          "Your encrypted drive has been created successfully.",
          "success"
        );
      }
      
      return data;
    },
    onError: (error, variables) => {
      logger.error('Error creating drive:', error);
      
      // Only show toast if not silenced
      if (!('silentError' in variables) || !variables.silentError) {
        showToast(
          "Failed to create encrypted drive. Please try again.",
          "error"
        );
      }
    },
  });

  // Unlock drive mutation - supports both normal unlock and password-based unlock
  const unlockDrive = useMutation({
    mutationFn: (param: { driveId: string; password?: string; silent?: boolean }) => {
      // If password is provided, use the password-based unlock
      if (param.password) {
        return encryptedDriveApi.unlockDrive(param.driveId, param.password);
      }
      // Otherwise use regular unlock (relies on client-side key verification)
      return encryptedDriveApi.unlockDrive(param.driveId);
    },
    onSuccess: (_, variables) => {
      // Update the cache immediately
      queryClient.setQueryData<EncryptedDrive[]>(
        driveKeys.lists(),
        old => (old || []).map((drive: any) => 
          drive.id === variables.driveId 
            ? { ...drive, status: 'unlocked' }
            : drive
        )
      );

      // Also invalidate to get fresh data
      queryClient.invalidateQueries({ queryKey: driveKeys.drive(variables.driveId) });
      
      // Dispatch global status change event to sync UI components
      dispatchDriveStatusChange(variables.driveId, 'unlocked');
      
      // Only show toast if silent mode is not enabled
      if (!variables.silent) {
        showToast(
          "You can now access your encrypted files.",
          "success"
        );
      }
    },
    onError: (error) => {
      logger.error('Error unlocking drive:', error);
      showToast(
        "Failed to unlock drive. Please check your password.",
        "error"
      );
    },
  });

  const lockDrive = useMutation({
    mutationFn: (params: { driveId: string; force?: boolean }) =>
      encryptedDriveApi.lockDrive(params.driveId, params.force),
    onSuccess: (_, { driveId }) => {
      // Update the cache immediately
      queryClient.setQueryData<EncryptedDrive[]>(
        driveKeys.lists(),
        old => (old || []).map((drive: any) => 
          drive.id === driveId 
            ? { ...drive, status: 'locked' }
            : drive
        )
      );
      
      // Also invalidate to get fresh data
      queryClient.invalidateQueries({ queryKey: driveKeys.drive(driveId) });
      
      // Dispatch global status change event to sync UI components
      dispatchDriveStatusChange(driveId, 'locked');
      
      // Also clear the keys from encryption service to ensure consistent state
      encryptionService.clearDecryptedKeys(driveId);
      
      showToast(
        "Your drive has been locked successfully.",
        "success"
      );
    },
    onError: (error) => {
      logger.error('Error locking drive:', error);
      showToast(
        "Failed to lock drive. Please try again.",
        "error"
      );
    },
  });

  // Recovery mutation
  const recoverDrive = useMutation({
    mutationFn: async ({ driveId, recoveryKey }: { driveId: string; recoveryKey: string }) => {
      const drive = drives.find((d: any) => d.id === driveId);
      if (!drive) {
        logger.error('Drive not found for recovery', { driveId });
        throw new Error('Drive not found');
      }

      logger.debug('Starting drive recovery process', {
        data:{
          driveId,
          driveName: drive.name,
          recoveryEnabled: drive.recovery_enabled,
          hasRecoveryData: !!drive.recovery_data,
          hasRecoveryHash: !!drive.recovery_verification_hash
        }
      });

      const success = await encryptionService.recoverDrive(recoveryKey, drive);
      if (!success) {
        logger.error('Recovery failed for drive', { driveId });
        throw new Error('Recovery failed');
      }

      // If recovery successful, update drive status on server
      await encryptedDriveApi.unlockDrive(driveId);
      
      logger.debug('Drive recovery completed successfully', { data:{driveId} });
      return success;
    },
    onSuccess: (_, { driveId }) => {
      // Update cache and notify
      queryClient.invalidateQueries({ queryKey: driveKeys.drive(driveId) });
      queryClient.invalidateQueries({ queryKey: driveKeys.lists() });
      showToast(
        "Drive recovered successfully.",
        "success"
      );
    },
    onError: (error) => {
      logger.error('Drive recovery failed:', error);
      showToast(
        "Drive recovery failed. Please check your recovery key.",
        "error"
      );
    },
  });

  return {
    // Data
    drives,
    isLoading,
    error,

    // Operations
    createDrive: createDrive.mutateAsync,
    unlockDrive: unlockDrive.mutateAsync,
    lockDrive: lockDrive.mutateAsync,
    recoverDrive: recoverDrive.mutateAsync,

    // Loading states
    isCreating: createDrive.isPending || false,
    isUnlocking: unlockDrive.isPending || false,
    isLocking: lockDrive.isPending || false,
    isRecovering: recoverDrive.isPending || false
  };
};