/**
 * @file src/layouts/ghost/components/sidebar/components/AccountSection.tsx
 * @description Enhanced account section with billing and user data - improved logout handling
 * @version 2.4.0
 * @updated 2025-03-21
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {User, Bell, LogOut, Settings, CreditCard} from 'lucide-react';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { useAccount } from '@/context/AccountContext';
import { StorageSection } from './StorageSection';
import { GhostLanguageToggle } from '@/app/ghost/components/language/GhostLanguageToggle';
import { GhostThemeToggle } from '@/app/ghost/components/theme/GhostThemeToggle';
import { useAuth } from '@/context/AuthContext';
import { logoutAndClearCaches } from '@/utils/logout-utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';

interface AccountSectionProps {
  isCollapsed: boolean;
}

export const AccountSection: React.FC<AccountSectionProps> = ({
  isCollapsed
}) => {
  const { t } = useGhostTranslation();
  const navigate = useNavigate();
  const { accountInfo } = useAccount();
  const { logout } = useAuth();

  const displayName = accountInfo?.profile?.first_name || 
                     accountInfo?.profile?.company_name || 
                     accountInfo?.email?.split('@')[0] || 
                     'Unknown User';

  logger.debug('Rendering AccountSection', {
    component: 'AccountSection',
    data:{
      isCollapsed,
      displayName
    }
  });

  const handleLogout = async () => {
    try {
      logger.debug('Logout clicked from Nygma account section');
      // Force Ghost UI redirect
      await logoutAndClearCaches(logout, navigate, true);
    } catch (error) {
      logger.error('Error during logout from Nygma account section:', {
        component: 'AccountSection',
        error
      });
      // Fallback - try direct navigation to Ghost signin
      navigate('/ghost/auth/signin');
    }
  };

  const accountButton = (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <motion.button
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
          className={cn(
            "w-full px-3 py-2 rounded-lg",
            "bg-[hsl(var(--ghost-surface))]",
            "border border-[hsl(var(--ghost-accent))/0.1]",
            "flex items-center space-x-3",
            "group"
          )}
        >
          {/* Avatar */}
          <div
            className={cn(
              "relative h-8 w-8 rounded-lg",
              "bg-[hsl(var(--ghost-accent))/0.1]",
              "flex items-center justify-center",
              "border border-[hsl(var(--ghost-accent))/0.2]"
            )}
          >
            <User className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
            <div className="absolute -bottom-0.5 -right-0.5 w-2 h-2 bg-emerald-400 rounded-full" />
          </div>

          {!isCollapsed && (
            /* Info */
            <div className="flex-1 text-left">
              <div className="text-sm font-medium text-[hsl(var(--ghost-foreground))]">
                {displayName}
              </div>
              <div className="text-xs text-[hsl(var(--ghost-foreground-muted))]">
                {accountInfo?.subscription?.plan || t('account.free_plan') as React.ReactNode}
              </div>
            </div>
          )}
        </motion.button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[200px] bg-[hsl(var(--ghost-surface))] border-[hsl(var(--ghost-accent))/0.2] text-[hsl(var(--ghost-foreground))]"
        align={isCollapsed ? "center" : "end"}
      >
        <DropdownMenuItem
          onClick={() => navigate("/ghost/profile")}
          className="flex items-center gap-2 cursor-pointer hover:bg-[hsl(var(--ghost-accent))/0.1]"
        >
          <User className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
          <span>{t('account.profile') as React.ReactNode}</span>
        </DropdownMenuItem>

        <DropdownMenuItem
          onClick={() => navigate("/ghost/settings")}
          className="flex items-center gap-2 cursor-pointer hover:bg-[hsl(var(--ghost-accent))/0.1]"
        >
          <Settings className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
          <span>{t('account.settings') as React.ReactNode}</span>
        </DropdownMenuItem>

        <DropdownMenuItem
          onClick={() => navigate("/ghost/billing")}
          className="flex items-center gap-2 cursor-pointer hover:bg-[hsl(var(--ghost-accent))/0.1]"
        >
          <CreditCard className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
          <span>{t('account.billing') as React.ReactNode}</span>
        </DropdownMenuItem>
        

        <DropdownMenuItem
          onClick={() => navigate("/ghost/notifications")}
          className="flex items-center gap-2 cursor-pointer hover:bg-[hsl(var(--ghost-accent))/0.1]"
        >
          <Bell className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
          <span>{t('account.notifications') as React.ReactNode}</span>
        </DropdownMenuItem>

        <DropdownMenuSeparator className="bg-[hsl(var(--ghost-accent))/0.1]" />

        <DropdownMenuItem
          onClick={handleLogout}
          className="flex items-center gap-2 cursor-pointer hover:bg-[hsl(var(--ghost-accent))/0.1] text-red-400"
        >
          <LogOut className="h-4 w-4" />
          <span>{t('account.logout') as React.ReactNode}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );

  if (isCollapsed) {
    return (
      <div className="px-2 space-y-2">
        <StorageSection isCollapsed={true} />
        <div className="grid grid-cols-2 gap-2 rounded-lg bg-[hsl(var(--ghost-surface))] p-1 overflow-hidden">
          <GhostLanguageToggle className="w-full" />
          <GhostThemeToggle className="w-full" />
        </div>
        {accountButton}
      </div>
    );
  }

  return (
    <div className="px-2 space-y-2">
      <StorageSection isCollapsed={false} />
      <div className="grid grid-cols-2 gap-2 rounded-lg bg-[hsl(var(--ghost-surface))] p-1 overflow-hidden">
        <GhostLanguageToggle className="w-full" />
        <GhostThemeToggle className="w-full" />
      </div>
      {accountButton}
    </div>
  );
};

export default AccountSection;