/**
 * @file src/hooks/root-drive/useSystemQueries.ts
 */

import { useQuery } from '@tanstack/react-query';
import { cloudDriveService } from '@/services/cloud-drive.service';
import { uploadService } from '@/services/upload.service';
import { logger } from '@/utils/logger';
import type { SystemOverview, DrivesResponse } from '@/types/root-drive.types';
import { isAuthPage } from '@/routes';

export const systemKeys = {
  overview: () => ['system', 'overview'] as const,
  drives: (deviceId: string) => ['system', 'drives', deviceId] as const,
  strategy: () => ['system', 'upload-strategy'] as const,
};

export const useSystemQueries = () => {
  // Circuit breaker state to prevent infinite API calls
  const failedRequestsCount = localStorage.getItem('system_query_failures') || '0';
  const requestFailures = parseInt(failedRequestsCount, 10);
  
  // Check if we're on an auth page - never break the circuit on auth pages
  const onAuthPage = isAuthPage();
  const circuitBroken = !onAuthPage && requestFailures >= 3; // Break circuit after 3 consecutive failures
  
  logger.debug('System query circuit state', {
    component: 'useSystemQueries',
    data: {
      onAuthPage,
      requestFailures,
      circuitBroken
    }
  });
  
  // Check if user is authenticated to control query execution
  const isAuthenticated = localStorage.getItem('access_token') !== null;
  
  // Helper to track API failures
  const trackFailure = () => {
    const currentFailures = parseInt(localStorage.getItem('system_query_failures') || '0', 10);
    localStorage.setItem('system_query_failures', String(currentFailures + 1));
    
    logger.debug('System query failure tracked', { 
      component: 'useSystemQueries',
      failureCount: currentFailures + 1
    });
    
    // Reset failures after 60 seconds to prevent permanent lockout
    setTimeout(() => {
      localStorage.removeItem('system_query_failures');
    }, 60000);
  };
  
  // Only allow queries if authenticated AND circuit is not broken AND not on auth page
  // NOTE: We always enable system queries if the user is authenticated regardless of email verification
  const shouldEnableQueries = isAuthenticated && !circuitBroken && !onAuthPage;
  
  const system = useQuery<Omit<SystemOverview, 'uploadStrategy'>, Error>({
    queryKey: systemKeys.overview(),
    queryFn: async () => {
      try {
        logger.debug('Fetching system overview', {
          component: 'useSystemQueries',
          action: 'getSystemOverview',
        });
        const [overview, strategy] = await Promise.all([
          cloudDriveService.getSystemOverview(),
          uploadService.getUploadStrategy()
        ]);
        
        // Success - reset failure counter
        localStorage.removeItem('system_query_failures');
        
        return {
          ...overview,
          uploadStrategy: strategy
        };
      } catch (error) {
        trackFailure();
        throw error;
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: shouldEnableQueries, // Only run this query if user is authenticated and circuit isn't broken
    retry: 1, // Limit retries to avoid request storms
  });

  const strategyQuery = useQuery({
    queryKey: systemKeys.strategy(),
    queryFn: async () => {
      try {
        logger.debug('Fetching upload strategy via React Query', {
          component: 'useSystemQueries',
          action: 'strategyQuery'
        });
        const result = await uploadService.getUploadStrategy();
        
        // Success - reset failure counter
        localStorage.removeItem('system_query_failures');
        
        return result;
      } catch (error) {
        trackFailure();
        throw error;
      }
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: 1, // Limit retries
    enabled: shouldEnableQueries, // Only run if authenticated and circuit isn't broken
  });

  const getDeviceDrives = (deviceId: string) => useQuery<DrivesResponse>({
    queryKey: systemKeys.drives(deviceId),
    queryFn: () => cloudDriveService.listDeviceDrives(deviceId),
    enabled: !!deviceId && isAuthenticated, // Only run if deviceId is valid AND user is authenticated
  });

  return {
    system,
    strategyQuery,
    getDeviceDrives
  };
};