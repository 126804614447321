/**
 * @file src/utils/logger.ts
 * @description Enhanced logging utility with subscriber support
 * @version 1.2.0
 */

type LogLevel = 'debug' | 'info' | 'warn' | 'error';

interface LogOptions {
  component?: string;
  action?: string;
  data?: any;
  [key: string]: any; // Allow any additional properties
}

interface LogEntry {
  timestamp: string;
  level: LogLevel;
  message: string;
  component?: string;
  action?: string;
  data?: any;
}

type LogSubscriber = (entry: LogEntry) => void;

class Logger {
  private isDev = process.env.NODE_ENV === 'development';
  private subscribers = new Set<LogSubscriber>();
  
  // Add subscribe/unsubscribe methods
  subscribe(subscriber: LogSubscriber) {
    this.subscribers.add(subscriber);
    return {
      unsubscribe: () => this.subscribers.delete(subscriber)
    };
  }

  private notify(entry: LogEntry) {
    this.subscribers.forEach((subscriber: any) => subscriber(entry));
  }

  private formatMessage(level: LogLevel, message: string, options?: LogOptions): string {
    const timestamp = new Date().toISOString();
    const component = options?.component ? `[${options.component}]` : '';
    const action = options?.action ? `(${options.action})` : '';
    return `${timestamp} ${level.toUpperCase()} ${component}${action}: ${message}`;
  }

  private formatData(data: any): any {
    if (!data) return '';
    try {
      return JSON.stringify(data, null, 2);
    } catch (error) {
      return '[Circular or Non-Serializable Data]';
    }
  }

  debug(message: string, options?: LogOptions) {
    if (this.isDev) {
      const timestamp = new Date().toISOString();
      const formattedMessage = this.formatMessage('debug', message, options);
      console.debug(formattedMessage, this.formatData(options?.data));
      
      this.notify({
        timestamp,
        level: 'debug',
        message,
        ...options
      });
    }
  }

  info(message: string, options?: LogOptions) {
    const timestamp = new Date().toISOString();
    const formattedMessage = this.formatMessage('info', message, options);
    console.info(formattedMessage, this.formatData(options?.data));
    
    this.notify({
      timestamp,
      level: 'info',
      message,
      ...options
    });
  }

  warn(message: string, options?: LogOptions) {
    const timestamp = new Date().toISOString();
    const formattedMessage = this.formatMessage('warn', message, options);
    console.warn(formattedMessage, this.formatData(options?.data));
    
    this.notify({
      timestamp,
      level: 'warn',
      message,
      ...options
    });
  }

  error(message: string, error?: any, options?: LogOptions) {
    const timestamp = new Date().toISOString();
    const formattedMessage = this.formatMessage('error', message, options);
    console.error(formattedMessage, error, this.formatData(options?.data));
    
    this.notify({
      timestamp,
      level: 'error',
      message,
      ...options,
      data: { ...options?.data, error }
    });
  }

  // Add upload-specific logging methods
  uploadChunk(message: string, data?: any) {
    this.debug(message, {
      component: 'upload.chunk',
      data
    });
  }

  uploadProgress(message: string, data?: any) {
    this.debug(message, {
      component: 'upload.progress',
      data
    });
  }
}

export const logger = new Logger();