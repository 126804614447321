/**
 * @file src/services/audit.service.ts
 * @description Audit API service layer
 * @version 3.2.0
 * @created 2024-02-24
 * @updated 2025-03-04
 */

import type {
    AuditBaseFilters,
    AuditCursorFilters,
    AuditCursorResponse,
    AuditLog,
    User,
    Device
} from '@/types/audit.types';
import { logger } from '@/utils/logger';
import { formatDateForAPI } from '@/utils/date-utils';

const BASE_URL = '/api/v1/audit';

// Generate a request ID for deduplication
const generateRequestId = (url: string, params: Record<string, any>): string => {
    const sortedParams = Object.entries(params)
        .filter(([key, value]) => {
            // Exclude unique parameters from request ID to prevent caching issues
            return value !== undefined && value !== null &&
                !key.startsWith('_unique') && !key.startsWith('_timestamp') && !key.startsWith('_nocache');
        })
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    return `${url}?${sortedParams}`;
};

// Helper function to get auth headers
const getAuthHeaders = (): HeadersInit => {
    // Get token from localStorage or elsewhere in your app
    const token = localStorage.getItem('token');
    return token ? {
        'Authorization': `Bearer ${token}`
    } : {};
};

function createStrictDateParam(date: Date | null | undefined): string | undefined {
    if (!date) return undefined;

    // Instead of using ISO string, use a simpler date format
    // Many APIs have issues with full ISO strings including timezone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format: YYYY-MM-DDThh:mm:ss
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    logger.warn("Creating strict date parameter:", {
        original: date.toISOString(),
        formatted: formattedDate,
        parts: {
            year,
            month,
            day,
            hours,
            minutes,
            seconds
        }
    } as any);

    return formattedDate;
}

// Internal cache for exported data to ensure matching client-side data
let cachedViewData: AuditLog[] | null = null;

export const auditService = {
    // Method to cache current view data
    cacheViewData: (data: AuditLog[]): void => {
        logger.debug("🔍 Caching current view data for export:", {
            count: data.length,
            firstItem: data[0]?.id,
            lastItem: data[data.length - 1]?.id
        } as any);
        cachedViewData = [...data];
    },

    // Method to clear cached view data
    clearCachedViewData: (): void => {
        logger.debug("🔍 Clearing cached view data");
        cachedViewData = null;
    },

    getAuditLogs: async (filters: AuditCursorFilters & {
        _uniqueParam?: number; // Add a unique parameter to prevent caching
    }): Promise<AuditCursorResponse> => {
        try {
            const queryParams: Record<string, string> = {};

            // Handle pagination logic 
            if (filters.cursor) {
                // Add the cursor to query params
                queryParams.cursor = filters.cursor;
                logger.debug(`🔍 Using cursor: ${filters.cursor}`);
            } else if (filters.page) {
                // If page is explicitly provided, use it
                logger.debug(`🔍 Using page: ${filters.page}`);
                queryParams.page = String(filters.page);
            } else {
                logger.debug("🔍 No cursor or page in request (first page)");
                queryParams.page = '1'; // Default to page 1 for first request
            }

            // Add sorting parameters
            if (filters.sortColumn) {
                queryParams.sort_column = filters.sortColumn;
                logger.debug(`🔍 Sort column: ${filters.sortColumn}`);
            }

            if (filters.sortDirection) {
                queryParams.sort_direction = filters.sortDirection;
                logger.debug(`🔍 Sort direction: ${filters.sortDirection}`);
            }

            // Add limit parameter
            if (filters.limit) {
                queryParams.limit = filters.limit.toString();
                logger.debug(`🔍 Limit: ${filters.limit}`);
            }

            // Add filter parameters
            if (filters.userId) queryParams.user_id = filters.userId;
            if (filters.deviceId) queryParams.device_id = filters.deviceId;

            // Improved date handling - Ensure that date ranges are properly formatted
            if (filters.fromDate) {
                const dateStr = createStrictDateParam(filters.fromDate);
                if (dateStr) {
                    queryParams.from_date = dateStr;
                    logger.debug("🔍 From date filter:", {
                        dateObject: filters.fromDate,
                        formatted: dateStr,
                    } as any);
                }
            }

            if (filters.toDate) {
                const dateStr = createStrictDateParam(filters.toDate);
                if (dateStr) {
                    queryParams.to_date = dateStr;
                    logger.debug("🔍 To date filter:", {
                        dateObject: filters.toDate,
                        formatted: dateStr,
                    } as any);
                }
            }

            if (filters.action) {
                // Make sure we're passing the action value correctly
                queryParams.action = filters.action.toString();
                logger.debug(`🔍 Action filter: ${filters.action}`);
            }
            if (filters.search) queryParams.search = filters.search;

            // Add unique parameter to prevent browser caching
            queryParams._nocache = (filters._uniqueParam || Date.now()).toString();

            // Convert to URLSearchParams for actual request
            const searchParams = new URLSearchParams();
            Object.entries(queryParams).forEach(([key, value]) => {
                searchParams.append(key, value);
            });

            const requestUrl = `${BASE_URL}?${searchParams.toString()}`;

            // Request ID for logging
            const requestId = generateRequestId(BASE_URL, queryParams);

            logger.debug("🔍 API Request:", {
                url: requestUrl,
                requestId,
                params: queryParams,
                hasSort: Boolean(filters.sortColumn) && Boolean(filters.sortDirection)
            } as any);

            // Make a fresh request every time to avoid caching issues
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    ...getAuthHeaders()
                },
                credentials: 'include'
            });

            if (!response.ok) {
                const errorText = await response.text();
                logger.error("❌ API Error:", {
                    status: response.status,
                    statusText: response.statusText,
                    body: errorText
                });
                throw new Error(`Failed to fetch audit logs: ${response.statusText}`);
            }

            const data = await response.json() as AuditCursorResponse;

            // Fix any missing or invalid properties
            if (!Array.isArray(data.items)) {
                logger.error("❌ Malformed API response, missing items array:", data);
                data.items = [];
            }

            // Ensure we have "totalItems" from server's "total" field
            if (!data.totalItems && (data as any).total) {
                data.totalItems = (data as any).total;
            }

            // If API doesn't return nextCursor, set it to null
            if (!('nextCursor' in data)) {
                (data as any).nextCursor = null;
            }

            // Log important response data for debugging
            const firstItem = data.items[0];
            const lastItem = data.items[data.items.length - 1];

            logger.debug("🔍 API Response:", {
                itemsCount: data.items.length,
                nextCursor: data.nextCursor,
                totalItems: data.totalItems,
                firstItemId: firstItem?.id,
                lastItemId: lastItem?.id
            } as any);

            // If we have items, log more detailed first/last item information for debugging
            if (data.items.length > 0) {
                logger.debug("🔍 First/Last items timestamps:", {
                    first: firstItem?.timestamp,
                    last: lastItem?.timestamp
                } as any);
            }

            return data;
        } catch (error) {
            logger.error("❌ Error fetching audit logs:", error);
            throw error;
        }
    },

    getUsers: async (): Promise<User[]> => {
        try {
            logger.debug("🔍 Fetching users");

            const response = await fetch(`${BASE_URL}/users`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...getAuthHeaders()
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch users: ${response.statusText}`);
            }

            const data = await response.json() as User[];
            logger.debug(`🔍 Fetched users: ${data.length}`);
            return data;
        } catch (error) {
            logger.error("❌ Error fetching users:", error);
            throw error;
        }
    },

    getUserDevices: async (userId: string): Promise<Device[]> => {
        try {
            logger.debug(`🔍 Fetching devices for user ${userId}`);

            const response = await fetch(`${BASE_URL}/users/${userId}/devices`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    ...getAuthHeaders()
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch devices: ${response.statusText}`);
            }

            const data = await response.json() as Device[];
            logger.debug(`🔍 Fetched devices for user ${userId}: ${data.length}`);
            return data;
        } catch (error) {
            logger.error("❌ Error fetching devices:", error);
            throw error;
        }
    },

    // New method that uses cached data to ensure export matches display
    exportToCsv: async (filters: AuditBaseFilters, currentData?: AuditLog[]): Promise<Blob> => {
        try {
            // If we have currentData passed or cached view data, use client-side export
            if (currentData?.length || cachedViewData?.length) {
                const dataToExport = currentData || cachedViewData || [];

                logger.debug("🔍 Exporting using client-side data:", {
                    count: dataToExport.length,
                    source: currentData ? 'passed directly' : 'cached view data'
                } as any);

                // Generate CSV content directly from the client-side data
                return generateClientSideCsv(dataToExport);
            }

            // If no cached data is available, fall back to server-side export
            logger.debug("🔍 No cached data available, using server-side export", {
                filters
            } as any);

            // Traditional server-side export as fallback
            const queryParams = new URLSearchParams();
            if (filters.userId) queryParams.append('user_id', filters.userId);
            if (filters.deviceId) queryParams.append('device_id', filters.deviceId);

            // Improved date formatting for export
            if (filters.fromDate) {
                queryParams.append('from_date', formatDateForAPI(filters.fromDate) || '');
            }

            if (filters.toDate) {
                queryParams.append('to_date', formatDateForAPI(filters.toDate) || '');
            }

            if (filters.action) queryParams.append('action', filters.action);
            if (filters.search) queryParams.append('search', filters.search);
            if (filters.sortColumn) queryParams.append('sort_column', filters.sortColumn);
            if (filters.sortDirection) queryParams.append('sort_direction', filters.sortDirection);

            // Add nocache parameter
            queryParams.append('_nocache', Date.now().toString());

            logger.debug("🔍 Exporting to CSV via server:", {
                params: Object.fromEntries(queryParams.entries())
            } as any);

            const response = await fetch(`${BASE_URL}/export?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Accept': 'text/csv',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    ...getAuthHeaders()
                },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`Export failed: ${response.statusText}`);
            }

            const blob = await response.blob();
            logger.debug(`🔍 Exported CSV: ${blob.size} bytes via server`);
            return blob;
        } catch (error) {
            logger.error("❌ Error exporting to CSV:", error);
            throw error;
        }
    }
};

// Helper function to generate CSV directly from the client-side data
function generateClientSideCsv(data: AuditLog[]): Blob {
    logger.debug("🔍 Generating client-side CSV export", {
        recordCount: data.length
    } as any);

    // Define CSV header row
    const headers = ['Date & Time', 'Actor', 'Action', 'Target', 'Device ID', 'User ID'];

    // Create CSV content starting with header row
    let csvContent = headers.join(',') + '\n';

    // Process each data row
    data.forEach(item => {
        // Format the timestamp - ensure consistency with displayed data
        const timestamp = item.timestamp; // Use appropriate date formatting function if needed

        // Escape and quote fields to handle commas and quotes in the data
        const row = [
            `"${timestamp}"`,
            `"${escapeForCsv(item.actor)}"`,
            `"${escapeForCsv(item.action)}"`,
            `"${escapeForCsv(item.target)}"`,
            `"${escapeForCsv(item.device_id || '')}"`,
            `"${escapeForCsv(item.user_id || '')}"`
        ];

        csvContent += row.join(',') + '\n';
    });

    // Create blob with the proper MIME type
    return new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
}

// Helper function to escape special characters for CSV
function escapeForCsv(value: string | number | undefined | null): string {
    if (value === undefined || value === null) return '';
    return String(value).replace(/"/g, '""');
}