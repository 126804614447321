/**
 * @file src/components/cloud-drive/storage/StorageQuotaIndicator.tsx
 * @description Storage quota visualization with multiple display variants
 * @version 1.1.0
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { HardDrive, AlertCircle, ChevronRight } from 'lucide-react';
import { cn } from '@/utils/utils';
import { formatBytes } from '@/utils/utils';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '@/components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { useCloudDrive } from '@/hooks/cloud-drive/useCloudDrive';

type StorageQuotaVariant = 'full' | 'compact' | 'minimal';

interface StorageQuotaIndicatorProps {
  variant?: StorageQuotaVariant;
  className?: string;
  showDetails?: boolean;
}

export const StorageQuotaIndicator: React.FC<StorageQuotaIndicatorProps> = ({
  variant = 'full',
  className,
  showDetails = false
}) => {
  const { t } = useTranslation();
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);
  const { data: quota, isLoading, error } = useCloudDrive().getStorageQuota();

  // Calculate percentages
  const usedPercentage = quota ? (quota.used / quota.total) * 100 : 0;
  const isNearLimit = usedPercentage > 90;
  const isOverLimit = usedPercentage >= 100;

  // Colors based on usage
  const getBarColor = () => {
    if (isOverLimit) return 'bg-red-500';
    if (isNearLimit) return 'bg-amber-500';
    return 'bg-blue-500';
  };

  if (isLoading) {
    return (
      <Card className={cn("w-full animate-pulse", className)}>
        <CardContent className="p-4">
          <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded" />
        </CardContent>
      </Card>
    );
  }

  if (error || !quota) {
    return (
      <Card className={cn("w-full bg-red-50 dark:bg-red-900/20", className)}>
        <CardContent className="p-4">
          <div className="flex items-center space-x-2 text-red-600 dark:text-red-400">
            <AlertCircle className="h-4 w-4" />
            <span className="text-sm font-medium">
              {t('cloud_drive.storage.quota_error')}
            </span>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Minimal variant (icon only with tooltip)
  if (variant === 'minimal') {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <button 
            onClick={() => setIsDetailsOpen(true)}
            className={cn(
              "p-2 rounded-lg",
              "hover:bg-gray-100 dark:hover:bg-gray-800",
              "transition-colors duration-200",
              className
            )}
          >
            <HardDrive className={cn(
              "h-5 w-5",
              isOverLimit ? "text-red-500" : 
              isNearLimit ? "text-amber-500" : 
              "text-blue-500"
            )} />
          </button>
        </TooltipTrigger>
        <TooltipContent>
          <div className="text-sm">
            <p className="font-medium">{formatBytes(quota.used)} / {formatBytes(quota.total)}</p>
            <p className="text-xs text-gray-500">
              {formatBytes(quota.available)} {t('cloud_drive.storage.available')}
            </p>
          </div>
        </TooltipContent>
      </Tooltip>
    );
  }

  // Compact variant (progress bar with basic info)
  if (variant === 'compact') {
    return (
      <button
        onClick={() => setIsDetailsOpen(true)}
        className={cn(
          "group relative w-full",
          "hover:bg-gray-50 dark:hover:bg-gray-800",
          "rounded-lg p-2",
          "transition-colors duration-200",
          className
        )}
      >
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center space-x-2">
            <HardDrive className="h-4 w-4 text-gray-400" />
            <span className="text-sm font-medium">
              {usedPercentage.toFixed(1)}%
            </span>
          </div>
          <ChevronRight className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
        </div>
        <div className="relative h-1 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
          <motion.div
            className={cn("absolute top-0 left-0 h-full", getBarColor())}
            initial={{ width: 0 }}
            animate={{ width: `${Math.min(usedPercentage, 100)}%` }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          />
        </div>
      </button>
    );
  }

  // Full variant (complete card with details)
  return (
    <>
      <Card className={cn("w-full", className)}>
        <CardHeader className="pb-2">
          <CardTitle className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <HardDrive className="h-5 w-5" />
              <span>{t("cloud_drive.storage.quota")}</span>
            </div>
            {showDetails && (
              <button
                onClick={() => setIsDetailsOpen(true)}
                className="text-sm text-blue-500 hover:text-blue-600"
              >
                {t("cloud_drive.view_details")}
              </button>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent>
          {/* Progress Bar */}
          <div className="relative h-2 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
            <motion.div
              className={cn("absolute top-0 left-0 h-full", getBarColor())}
              initial={{ width: 0 }}
              animate={{ width: `${Math.min(usedPercentage, 100)}%` }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            />
          </div>

          {/* Usage Stats */}
          <div className="mt-4 flex items-center justify-between">
            <div className="text-sm">
              <span className="font-medium">{formatBytes(quota.used)}</span>
              <span className="text-gray-500 dark:text-gray-400 mx-1">
                {t("cloud_drive.of")}
              </span>
              <span className="font-medium">{formatBytes(quota.total)}</span>
            </div>
            <span className="text-sm font-medium">
              {usedPercentage.toFixed(1)}%
            </span>
          </div>

          {/* Warning Message */}
          <AnimatePresence>
            {(isNearLimit || isOverLimit) && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="mt-4"
              >
                <div
                  className={cn(
                    "text-sm p-2 rounded",
                    isOverLimit
                      ? "bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400"
                      : "bg-amber-50 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400"
                  )}
                >
                  {isOverLimit
                    ? t("cloud_drive.quota_exceeded")
                    : t("cloud_drive.quota_warning")}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </CardContent>
      </Card>

      {/* Details Sheet */}
      <Sheet
        isOpen={isDetailsOpen}
        onClose={() => setIsDetailsOpen(false)}
      >
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{t("cloud_drive.storage_details")}</SheetTitle>
          </SheetHeader>
          
          {(quota as any).breakdown && (
            <div className="mt-6 space-y-4">
              {Object.entries((quota as any).breakdown).map(([type, size]) => (
                <div key={type} className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {t(`cloud_drive.file_types.${type}`)}
                    </span>
                  </div>
                  <span className="text-sm font-medium">
                    {formatBytes(size as number)}
                  </span>
                </div>
              ))}
            </div>
          )}
        </SheetContent>
      </Sheet>
    </>
  );
};

export default StorageQuotaIndicator;