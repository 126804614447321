/**
 * @file src/services/compression.service.ts
 * @description File compression service with configurable compression strategies
 * @version 1.0.0
 */

import pako from 'pako';
import { logger } from '@/utils/logger';

interface CompressedFileData {
  data: File | Blob;
  originalSize: number;
  compressed: boolean;
  compressionRatio?: number;
}

// Compression thresholds by file type (in bytes)
export const COMPRESSIBLE_FILES = {
  // Text files - always compress
  'text/plain': 0,
  'text/csv': 0,
  'text/html': 0,
  'text/css': 0,
  'text/javascript': 0,
  'application/json': 50 * 1024,  // 50KB
  'application/xml': 50 * 1024,
  'application/x-yaml': 0,
  'application/x-yml': 0,
  
  // Documents
  'application/msword': 100 * 1024,  // 100KB
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 100 * 1024,
  'application/vnd.ms-excel': 100 * 1024,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 100 * 1024,
  'application/vnd.ms-powerpoint': 100 * 1024,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 100 * 1024,
  
  // Never compress these
  'image/': null,
  'video/': null,
  'audio/': null,
  'application/pdf': null,
  'application/zip': null,
  'application/x-zip-compressed': null,
  'application/gzip': null,
  'application/x-gzip': null,
  'application/x-rar-compressed': null,
  'application/vnd.rar': null,
  'application/x-7z-compressed': null
} as const;

export const compressionService = {
  /**
   * Check if file should be compressed based on type and size
   */
  shouldCompress(file: File): boolean {
    for (const [type, threshold] of Object.entries(COMPRESSIBLE_FILES)) {
      if (file.type.startsWith(type)) {
        // If threshold is null, don't compress
        if (threshold === null) {
          logger.debug('File type marked as non-compressible', {
            component: 'compressionService',
            data: { 
              fileName: file.name,
              type: file.type
            }
          });
          return false;
        }
        // If size is above threshold, compress
        const shouldCompress = file.size >= threshold;
        logger.debug('Compression check result', {
          component: 'compressionService',
          data: {
            fileName: file.name,
            type: file.type,
            size: file.size,
            threshold,
            shouldCompress
          }
        });
        return shouldCompress;
      }
    }
    return false;
  },

  /**
   * Compress file with progress tracking and type-specific handling
   */
  async compressFile(file: File): Promise<CompressedFileData> {
    try {
      if (!this.shouldCompress(file)) {
        logger.debug('Skipping compression for file', {
          component: 'compressionService',
          data: { 
            fileName: file.name,
            type: file.type,
            size: file.size
          }
        });
        return {
          data: file,
          originalSize: file.size,
          compressed: false
        };
      }

      logger.debug('Starting file compression', {
        component: 'compressionService',
        data: {
          fileName: file.name,
          type: file.type,
          originalSize: file.size
        }
      });

      let compressedData: Uint8Array;
      let compressedBlob: Blob;

      // Handle text files separately for better compression
      if (file.type.startsWith('text/') || 
          file.type === 'application/json' || 
          file.type === 'application/xml') {
        const text = await file.text();
        compressedData = pako.gzip(text);
        compressedBlob = new Blob([compressedData], { 
          type: `${file.type}+gzip` 
        });
      } else {
        // Handle other files
        const buffer = await file.arrayBuffer();
        compressedData = pako.gzip(new Uint8Array(buffer));
        compressedBlob = new Blob([compressedData], { 
          type: `${file.type}+gzip` 
        });
      }

      const compressionRatio = (compressedBlob.size / file.size) * 100;

      logger.debug('Compression complete', {
        component: 'compressionService',
        data: {
          fileName: file.name,
          originalSize: file.size,
          compressedSize: compressedBlob.size,
          compressionRatio: `${compressionRatio.toFixed(2)}%`
        }
      });

      return {
        data: compressedBlob,
        originalSize: file.size,
        compressed: true,
        compressionRatio
      };
    } catch (error) {
      logger.error('Compression failed:', {
        component: 'compressionService',
        error,
        data: { fileName: file.name }
      });

      // Return original file on error
      return {
        data: file,
        originalSize: file.size,
        compressed: false
      };
    }
  }
};

export default compressionService;