/**
 * @file src/components/audit/filters/DateRangeFilter.tsx
 * @description Date range filter component for audit reports
 * @version 1.3.0
 * @created 2024-02-24
 * @updated 2025-03-03
 */

import React from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { logger } from "@/utils/logger";

interface DateRangeFilterProps {
  fromDate: Date | null;
  toDate: Date | null;
  onFromDateChange: (date: Date | null) => void;
  onToDateChange: (date: Date | null) => void;
  disabled?: boolean;
}

export const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [fromOpen, setFromOpen] = React.useState(false);
  const [toOpen, setToOpen] = React.useState(false);

  // Handle the from date selection
  const handleFromDateSelect = (date: Date | undefined) => {
    if (date) {
      logger.warn("From date selected:", {
        date: date.toISOString(),
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      } as any);

      // Pass the date directly - transformation happens in parent component
      onFromDateChange(date);
    } else {
      onFromDateChange(null);
    }
    setFromOpen(false);
  };

  // Handle the to date selection
  const handleToDateSelect = (date: Date | undefined) => {
    if (date) {
      logger.debug("To date selected:", {
        date: date.toISOString(),
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      } as any);

      // Pass the date directly - transformation happens in parent component
      onToDateChange(date);
    } else {
      onToDateChange(null);
    }
    setToOpen(false);
  };

  return (
    <div className="flex gap-3 w-full px-2 py-1.5">
      <div className="flex-1">
        <Popover open={fromOpen} onOpenChange={setFromOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                "w-full justify-start text-left font-normal",
                !fromDate && "text-gray-500 dark:text-gray-400",
                "h-9 px-3"
              )}
              disabled={disabled}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {fromDate ? (
                format(fromDate, "PPP")
              ) : (
                <span>{t("audit.filters.fromDate", "From date")}</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={fromDate || undefined}
              onSelect={handleFromDateSelect}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>

      <div className="flex-1">
        <Popover open={toOpen} onOpenChange={setToOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                "w-full justify-start text-left font-normal",
                !toDate && "text-gray-500 dark:text-gray-400",
                "h-9 px-3"
              )}
              disabled={disabled}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {toDate ? (
                format(toDate, "PPP")
              ) : (
                <span>{t("audit.filters.toDate", "To date")}</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={toDate || undefined}
              onSelect={handleToDateSelect}
              initialFocus
              disabled={(date) => (fromDate ? date < fromDate : false)}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default DateRangeFilter;
