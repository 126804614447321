/**
 * @file src/layouts/ghost/components/create-core/steps/StepShieldType.tsx
 * @description Enhanced shield selection step with modern UI and animations
 * @version 3.0.0
 */

import React from 'react';
import { ShieldSelection } from './shield-selection';
import { MorphingProvider } from './shield-selection/context/MorphingContext';
import { logger } from '@/utils/logger';

interface StepShieldTypeProps {
  formData: {
    encryptionTier: string;
    [key: string]: any;
  };
  setFormData: (data: any) => void;
  onNext: () => void;
  onBack: () => void;
}

export const StepShieldType: React.FC<StepShieldTypeProps> = (props) => {
  logger.debug(`Rendering StepShieldType with encryption tier: ${props.formData.encryptionTier}`);

  return (
    <MorphingProvider>
      <ShieldSelection {...props} />
    </MorphingProvider>
  );
};

export default StepShieldType;