/**
 * @file src/layouts/ghost/components/sidebar/components/StorageSection.tsx
 * @description Enhanced storage section with plausible deniability for security
 * @version 1.5.0
 */

import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { Shield, Sparkles, Lock } from 'lucide-react';
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useAccount } from '@/context/AccountContext';
import { useEncryptedDrives } from '@/hooks/encrypted-drive/useEncryptedDrive';

interface StorageSectionProps {
  isCollapsed: boolean;
}

export const StorageSection: React.FC<StorageSectionProps> = ({
  isCollapsed
}) => {
  const { t } = useGhostTranslation();
  
  // Check if navigation context is available
  const navContext = useContext(UNSAFE_NavigationContext);
  const navigate = navContext ? useNavigate() : null;
  
  const { accountInfo } = useAccount();
  const { drives, isLoading: drivesLoading } = useEncryptedDrives();
  
  // Check if any drives are unlocked - for plausible deniability
  const hasUnlockedDrives = drives?.some((drive: any) => drive.status === 'unlocked') || false;
  
  // Only show real storage info when drives are unlocked
  const storage = {
    used: hasUnlockedDrives && accountInfo?.storage?.used 
      ? (accountInfo.storage.used / 1024 / 1024 / 1024).toFixed(1) 
      : '0.0',
    total: accountInfo?.storage?.limit 
      ? (accountInfo.storage.limit / 1024 / 1024 / 1024).toFixed(1) 
      : '0.0',
    // For security, show minimal percentage when locked
    percentage: hasUnlockedDrives && accountInfo?.storage?.limit 
      ? Math.min(100, Math.round((accountInfo.storage.used / accountInfo.storage.limit) * 100)) 
      : 0
  };
  
  // Only log in development
  if (process.env.NODE_ENV === 'development') {
    logger.debug('Storage section values', {
      component: 'StorageSection',
      hasUnlockedDrives,
      calculatedValues: storage
    });
  }

  const handleUpgradeClick = () => {
    if (process.env.NODE_ENV === 'development') {
      logger.debug('Upgrade security button clicked', {
        component: 'StorageSection'
      });
    }
    
    if (navigate) {
      navigate('/ghost/upgrade');
    } else {
      // Fallback for when navigate is not available
      if (process.env.NODE_ENV === 'development') {
        logger.warn('Navigation context not available, using window.location', {
          component: 'StorageSection'
        });
      }
      window.location.href = '/ghost/upgrade';
    }
  };

  if (isCollapsed) {
    return (
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleUpgradeClick}
        className={cn(
          "w-full h-8 rounded-lg",
          "bg-[hsl(var(--ghost-surface))]",
          "flex items-center justify-center",
          "group relative overflow-hidden"
        )}
      >
        {/* Add subtle glow effect even in collapsed state */}
        <motion.div 
          className="absolute inset-0 bg-[hsl(var(--ghost-accent))/0.05]"
          animate={{
            opacity: [0.05, 0.1, 0.05],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <Shield className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
      </motion.button>
    );
  }

  return (
    <div className="px-2">
      <div className={cn(
        "p-3 rounded-xl",
        "bg-[hsl(var(--ghost-surface))]",
        "border border-[hsl(var(--ghost-accent))/0.2]",
        "relative overflow-hidden"
      )}>
        {/* Animated background glow */}
        <div className="absolute inset-0 opacity-30">
          <div className="absolute inset-0 bg-gradient-to-br from-[hsl(var(--ghost-accent))/0.2] to-transparent" />
          <motion.div
            className="absolute inset-0 bg-[hsl(var(--ghost-accent))/0.1]"
            animate={{
              opacity: [0.1, 0.2, 0.1],
              scale: [1, 1.1, 1],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        </div>

        {/* Storage Info - Show differently based on unlock status */}
        <div className="relative">
          <div className="flex items-center justify-between mb-2">
            <div className="text-[10px] text-[hsl(var(--ghost-foreground-muted))] uppercase tracking-wider">
              {t('storage.title') as React.ReactNode}
            </div>
            
            {hasUnlockedDrives ? (
              <div className="text-xs font-medium flex items-center gap-1">
                <span>{storage.used}</span>
                <span className="opacity-50">/</span>
                <span>{storage.total} GB</span>
              </div>
            ) : (
              <div className="text-xs font-medium flex items-center gap-1 text-[hsl(var(--ghost-foreground-muted))]">
                <Lock className="h-3 w-3 mr-1" />
                <span>{t('storage.secured') as React.ReactNode}</span>
              </div>
            )}
          </div>

          {/* Progress Bar with enhanced visuals - different when locked/unlocked */}
          <div className="h-2 bg-[hsl(var(--ghost-surface))] rounded-full overflow-hidden mb-3 border border-[hsl(var(--ghost-accent))/0.1] relative">
            {hasUnlockedDrives ? (
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${storage.percentage}%` }}
                className="h-full relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]" />
                <motion.div
                  className="absolute inset-0 bg-[hsl(var(--ghost-accent-loud))]"
                  animate={{ opacity: [0.2, 0.4, 0.2] }}
                  transition={{ duration: 2, repeat: Infinity }}
                />
                
                {/* Add animated glow particles */}
                <motion.div
                  className="absolute top-0 right-0 h-full w-2 bg-white"
                  animate={{ 
                    opacity: [0, 0.6, 0],
                    x: [0, 5, 0]
                  }}
                  transition={{ 
                    duration: 1.5, 
                    repeat: Infinity,
                    repeatDelay: 1
                  }}
                />
              </motion.div>
            ) : (
              // Show locked state indicator
              <motion.div 
                className="absolute inset-0 flex items-center justify-center"
                animate={{ opacity: [0.3, 0.5, 0.3] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                <div className="w-full h-full flex items-center justify-center">
                  <motion.div 
                    className="h-[1px] bg-[hsl(var(--ghost-accent))/0.3]"
                    initial={{ width: '0%' }}
                    animate={{ width: '100%' }}
                    transition={{ 
                      duration: 2, 
                      repeat: Infinity,
                      repeatType: 'reverse',
                      ease: "easeInOut"
                    }}
                  />
                </div>
              </motion.div>
            )}
          </div>

          {/* Upgrade Button with improved styling */}
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleUpgradeClick}
            className={cn(
              "w-full py-1.5 px-3",
              "bg-[hsl(var(--ghost-accent))/0.1]",
              "border border-[hsl(var(--ghost-accent))/0.2]",
              "rounded-lg",
              "flex items-center justify-center gap-2",
              "group",
              "transition-all duration-200",
              "overflow-hidden relative"
            )}
          >
            <Sparkles className="h-3.5 w-3.5 text-[hsl(var(--ghost-accent))]" />
            <span className="text-xs font-medium text-[hsl(var(--ghost-accent))]">
              {t('storage.upgrade_security') as React.ReactNode}
            </span>
            
            {/* Enhanced button glow effect */}
            <motion.div
              className="absolute inset-0 rounded-lg bg-[hsl(var(--ghost-accent))/0.1]"
              animate={{
                opacity: [0, 0.2, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
            
            {/* Animated shimmer effect */}
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-transparent via-[hsl(var(--ghost-accent))/0.2] to-transparent"
              animate={{
                x: ['-100%', '100%']
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear",
                repeatDelay: 0.5
              }}
            />
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default StorageSection;