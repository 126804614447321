/**
 * @file src/pages/data/devices/DeviceDetailPage.tsx
 * @description Device detail page that loads device content
 * @version 1.0.0
 */

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DeviceDetailView } from '@/components/devices/DeviceDetailView';
import { ErrorBoundary } from '@/components/common/ErrorBoundary';

const DeviceDetailPage: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const navigate = useNavigate();
  
  if (!deviceId) {
    navigate('/data/devices');
    return null;
  }
  
  return (
    <ErrorBoundary>
      <DeviceDetailView 
        deviceId={deviceId}
        onBack={() => navigate('/data/devices')}
      />
    </ErrorBoundary>
  );
};

export default DeviceDetailPage;