/**
 * @file src/app/ghost/components/create-core/steps/shield-selection/components/ParanoidConfirmationDialog.tsx
 * @description Confirmation dialog for paranoid encryption tier selection with proper Ghost theming
 * @version 1.2.0
 * @updated 2025-04-01
 */

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AlertTriangle, Lock } from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface ParanoidConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ParanoidConfirmationDialog: React.FC<ParanoidConfirmationDialogProps> = ({
  isOpen,
  onClose,
  onConfirm
}) => {
  const { t } = useGhostTranslation();
  
  React.useEffect(() => {
    if (isOpen) {
      logger.debug('Paranoid confirmation dialog opened');
    }
  }, [isOpen]);

  const handleConfirm = () => {
    logger.debug('Paranoid encryption confirmed');
    onConfirm();
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center"
          onClick={onClose}
        >
          {/* Backdrop overlay */}
          <div className="absolute inset-0 bg-black/40 backdrop-blur-sm" />
          
          {/* Dialog content */}
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 300 }}
            className="relative w-full max-w-md m-4 bg-[hsl(var(--ghost-surface))] rounded-lg overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-5">
              {/* Header with icon */}
              <div className="flex items-center gap-3 mb-4">
                <div className="w-10 h-10 flex items-center justify-center">
                  <Lock className="w-6 h-6 text-[hsl(var(--ghost-accent))]" />
                </div>
                <h3 className="text-xl font-bold text-[hsl(var(--ghost-foreground))]">
                  {String(t('security.paranoid_confirm.title', 'Paranoid Encryption Selected'))}
                </h3>
              </div>

              {/* Warning message */}
              <div className="p-4 mb-4 rounded-md bg-[hsl(var(--ghost-accent))]/10 border border-[hsl(var(--ghost-accent))]/20">
                <div className="flex items-start gap-3">
                  <AlertTriangle className="w-5 h-5 text-[hsl(var(--ghost-accent))] flex-shrink-0 mt-0.5" />
                  <p className="text-sm text-[hsl(var(--ghost-foreground))]">
                    {String(t('security.paranoid_confirm.description', 'This encryption method is highly secure and virtually uncrackable, but it\'s slower than other options because it uses multiple layers of encryption.'))}
                  </p>
                </div>
              </div>

              {/* Performance impact notice */}
              <p className="mb-6 text-sm text-[hsl(var(--ghost-foreground-muted))]">
                {String(t('security.paranoid_confirm.performance_impact', 'You may experience slower upload and download speeds compared to other encryption types.'))}
              </p>

              {/* Confirmation question */}
              <p className="mb-6 text-sm font-medium text-[hsl(var(--ghost-foreground))]">
                {String(t('security.paranoid_confirm.question', 'Do you want to proceed with Paranoid Encryption?'))}
              </p>

              {/* Buttons */}
              <div className="flex flex-col space-y-3">
                {/* Cancel button - white/transparent style */}
                <button
                  onClick={onClose}
                  className={cn(
                    "w-full px-4 py-3 rounded-md",
                    "bg-white text-gray-800 dark:bg-[hsl(var(--ghost-surface))]",
                    "dark:text-[hsl(var(--ghost-foreground))]",
                    "border border-gray-200 dark:border-[hsl(var(--ghost-accent))]/10",
                    "hover:bg-gray-50 dark:hover:bg-[hsl(var(--ghost-surface))]/80",
                    "transition-colors duration-200",
                    "text-sm font-medium"
                  )}
                >
                  {String(t('security.paranoid_confirm.cancel', 'Cancel'))}
                </button>
                
                {/* Confirm button - gradient style */}
                <button
                  onClick={handleConfirm}
                  className={cn(
                    "w-full px-4 py-3 rounded-md",
                    "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
                    "text-white text-sm font-medium",
                    "hover:opacity-90 transition-opacity"
                  )}
                >
                  {String(t('security.paranoid_confirm.confirm', 'Proceed with Paranoid'))}
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ParanoidConfirmationDialog;