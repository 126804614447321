// src/layouts/ghost/components/create-core/steps/shield-selection/context/MorphingContext.tsx

/**
 * @file src/layouts/ghost/components/create-core/steps/shield-selection/context/MorphingContext.tsx
 * @description Context for managing text morphing animations
 * @version 1.0.0
 */

import React, { createContext, useContext, useState, useCallback } from 'react';
import { logger } from '@/utils/logger';

interface MorphingContextType {
  isFirstView: boolean;
  isMorphingComplete: boolean;
  activeMorphCount: number;
  trackMorphingText: () => void;
  handleMorphComplete: () => void;
  setFirstView: (value: boolean) => void;
}

const MorphingContext = createContext<MorphingContextType | undefined>(undefined);

export const MorphingProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isFirstView, setIsFirstView] = useState(true);
  const [isMorphingComplete, setIsMorphingComplete] = useState(false);
  const [activeMorphCount, setActiveMorphCount] = useState(0);

  const trackMorphingText = useCallback(() => {
    setActiveMorphCount(prev => prev + 1);
    logger.debug(`Tracking new morphing text: ${activeMorphCount + 1}`);
  }, [activeMorphCount]);

  const handleMorphComplete = useCallback(() => {
    setActiveMorphCount(prev => {
      const newCount = prev - 1;
      if (newCount === 0) {
        logger.debug('All morphing animations complete');
        setIsMorphingComplete(true);
      }
      return newCount;
    });
  }, []);

  return (
    <MorphingContext.Provider
      value={{
        isFirstView,
        isMorphingComplete,
        activeMorphCount,
        trackMorphingText,
        handleMorphComplete,
        setFirstView: setIsFirstView
      }}
    >
      {children}
    </MorphingContext.Provider>
  );
};

export const useMorphing = () => {
  const context = useContext(MorphingContext);
  if (context === undefined) {
    throw new Error('useMorphing must be used within a MorphingProvider');
  }
  return context;
};