/**
 * @file src/layouts/ghost/components/create-core/steps/shield-selection/index.tsx
 * @description Main shield selection container with improved spacing and layout
 * @version 3.1.0
 */

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Info, ArrowRight } from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { GhostGlass } from '@/app/ghost/components/common/GhostGlass';
import { ShieldCard } from './components/ShieldCard';
import { ShieldProgress } from './components/ShieldProgress';
import { ShieldDetails } from './components/ShieldDetails';
import { ParanoidConfirmationDialog } from './components/ParanoidConfirmationDialog';
import { SHIELDS } from './constants/shield-data';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { Shield as ShieldType, ShieldTier } from './types/shield-types';

interface ShieldSelectionProps {
  formData: {
    encryptionTier: string;
    [key: string]: any;
  };
  setFormData: (data: any) => void;
  onNext: () => void;
  onBack: () => void;
}

export const ShieldSelection: React.FC<ShieldSelectionProps> = ({
  formData,
  setFormData,
  onNext,
  onBack
}) => {
  const { t } = useGhostTranslation();
  const [selectedShield, setSelectedShield] = React.useState<string | null>(formData.encryptionTier || null);
  const [showDetails, setShowDetails] = React.useState<ShieldType | null>(null);
  const [showParanoidConfirm, setShowParanoidConfirm] = React.useState<boolean>(false);
  const [pendingParanoidSelection, setPendingParanoidSelection] = React.useState<boolean>(false);

  const handleSelect = (shieldId: string) => {
    logger.debug('Shield selected', { shieldId } as any);
    
    // If paranoid shield is selected, show confirmation dialog
    if (shieldId === 'paranoid') {
      setShowParanoidConfirm(true);
      setPendingParanoidSelection(true);
    } else {
      // For other shields, just set the selection directly
      setSelectedShield(shieldId);
      setFormData({ ...formData, encryptionTier: shieldId });
      setPendingParanoidSelection(false);
    }
  };
  
  // Handle paranoid confirmation
  const handleParanoidConfirm = () => {
    setSelectedShield('paranoid');
    setFormData({ ...formData, encryptionTier: 'paranoid' });
    setShowParanoidConfirm(false);
    setPendingParanoidSelection(false);
    
    logger.debug('Paranoid shield confirmed', {
      action: 'confirmParanoid',
      encryptionTier: 'paranoid'
    });
  };
  
  // Handle paranoid cancellation
  const handleParanoidCancel = () => {
    setShowParanoidConfirm(false);
    setPendingParanoidSelection(false);
    
    logger.debug('Paranoid shield canceled', {
      action: 'cancelParanoid'
    });
  };

  return (
    <div className="flex flex-col min-h-0 max-h-[650px]">
      {" "}
      {/* Added max-height */}
      {/* Fixed Header Section - More Compact */}
      <div className="px-5 pt-4 pb-2">
        {/* Reduced padding */}
        {/* Title - Removed subtitle */}
        <h2 className={cn(
          "text-xl font-bold text-center mb-4", // Added margin bottom
          "bg-clip-text text-transparent",
          "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]"
        )}>
          {t('create.shield.title') as React.ReactNode}
        </h2>

        {/* Info Banner - More Compact */}
        <GhostGlass
          className="p-3 mb-4" // Added margin bottom
          opacity="low"
          rounded="lg"
        >
          <div className="flex items-start space-x-2"> {/* Reduced spacing */}
            <Info className="w-4 h-4 text-[hsl(var(--ghost-accent))] flex-shrink-0" /> {/* Smaller icon */}
            <p className="text-xs text-[hsl(var(--ghost-foreground-muted)]"> {/* Smaller text */}
              {t('create.shield.info.description') as React.ReactNode}
            </p>
          </div>
        </GhostGlass>

        {/* Progress - More Compact */}
        <ShieldProgress
          shields={SHIELDS as ShieldType[]}
          selectedShield={selectedShield}
          className="mb-4" // Added margin bottom
        />
      </div>
      {/* Scrollable Shield Cards */}
      <div className="flex-1 overflow-y-auto px-5 pb-2 pt-2">
        {" "}
        {/* Added pt-2 */}
        <div className="space-y-2 min-h-full">
          {" "}
          {/* Reduced spacing between cards */}
          {SHIELDS.map((shield) => (
            <ShieldCard
              key={shield.id}
              shield={shield as ShieldType}
              isSelected={selectedShield === shield.id}
              onSelect={handleSelect}
              onShowDetails={() => setShowDetails(shield as ShieldType)}
            />
          ))}
        </div>
      </div>
      {/* Fixed Footer - More Compact */}
      <div className="p-4 bg-gradient-to-t from-[hsl(var(--ghost-bg))] to-transparent">
        {" "}
        {/* Reduced padding */}
        <div className="flex justify-between space-x-3">
          {" "}
          {/* Reduced spacing */}
          <motion.button
            onClick={onBack}
            className={cn(
              "px-5 py-2.5 rounded-lg", // Reduced padding
              "bg-[hsl(var(--ghost-surface))]",
              "border border-[hsl(var(--ghost-accent))]/10",
              "text-sm font-medium",
              "hover:bg-[hsl(var(--ghost-accent))]/5",
              "transition-colors duration-200",
              "flex-1"
            )}
          >
            {t('common.back') as React.ReactNode}
          </motion.button>
          {selectedShield && (
            <motion.button
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              onClick={onNext}
              className={cn(
                "px-5 py-2.5 rounded-lg", // Reduced padding
                "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
                "text-white text-sm font-medium",
                "flex items-center justify-center space-x-2",
                "flex-1"
              )}
            >
              <span>{t('common.continue') as React.ReactNode}</span>
              <ArrowRight className="w-4 h-4" />
            </motion.button>
          )}
        </div>
      </div>
      {/* Shield Details Modal remains the same */}
      <AnimatePresence>
        {showDetails && (
          <ShieldDetails
            shield={showDetails}
            onClose={() => setShowDetails(null)}
          />
        )}
      </AnimatePresence>
      
      {/* Paranoid Confirmation Dialog */}
      <ParanoidConfirmationDialog
        isOpen={showParanoidConfirm}
        onClose={handleParanoidCancel}
        onConfirm={handleParanoidConfirm}
      />
    </div>
  );
};

export default ShieldSelection;