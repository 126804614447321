/**
 * @file src/context/AuthContext.tsx
 * @description Authentication context provider with improved token persistence and refresh handling
 * @version 1.7.2
 */

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { authService } from "@/services/auth.service";
import type { User, AuthResponse } from "@/types/auth";
import { logger } from "@/utils/logger";
import { jwtDecode } from "jwt-decode";
import { logoutAndClearCaches } from "@/utils/logout-utils";

// Constants
const USER_KEY = "user";
const AUTH_ERROR_KEY = "auth_error_count";
const DEV_MODE_KEY = "dev_mode_active"; // New constant for dev mode
const MAX_ERROR_COUNT = 3;
const ERROR_RESET_TIME = 1000 * 60 * 5; // 5 minutes
const REFRESH_RETRY_DELAY = 1000; // 1 second
const MAX_REFRESH_RETRIES = 3;

// Types
interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  token: string | null;
  login: (response: AuthResponse) => void;
  logout: () => Promise<void>;
  refreshSession: () => Promise<void>;
  isDevMode: () => boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
  onLogout?: () => void;
}

interface JWTPayload {
  exp: number;
  iat: number;
  sub: string;
  type: string;
}

// Create context with a default value matching the interface
const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  token: null,
  login: () => {},
  logout: async () => {},
  refreshSession: async () => {},
  isDevMode: () => false,
});

let refreshPromise: Promise<AuthResponse> | null = null;

export const refreshTokenWrapper = async (): Promise<AuthResponse> => {
  if (refreshPromise) {
    return refreshPromise;
  }
  refreshPromise = authService.refreshToken();
  try {
    const result = await refreshPromise;
    return result;
  } finally {
    refreshPromise = null;
  }
};

// Separate component definition from export
function AuthProviderComponent({ children, onLogout }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [initializationComplete, setInitializationComplete] = useState(false);

  const isDevMode = useCallback((): boolean => {
    // Check URL parameter first
    const isTestMode = new URLSearchParams(window.location.search).get("test") === "true";
    
    // If test=true is in URL, store this in localStorage for persistence
    if (isTestMode && process.env.NODE_ENV === "development") {
      localStorage.setItem(DEV_MODE_KEY, "true");
      return true;
    }
    
    // Otherwise check if we've previously stored the dev mode flag
    return process.env.NODE_ENV === "development" && 
           localStorage.getItem(DEV_MODE_KEY) === "true";
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      logger.debug("Starting logout from AuthContext", { userId: user?.id } as any);
      
      // Basic logout function that just calls the auth service logout
      const basicLogout = async () => {
        try {
          await authService.logout();
          
          // Clear state in this component
          setUser(null);
          setToken(null);
          setIsAuthenticated(false);
          
          // Clear dev mode flag
          localStorage.removeItem(DEV_MODE_KEY);
          
          // Call the callback if provided
          if (onLogout) onLogout();
          
          logger.info("Basic logout completed");
        } catch (error) {
          logger.error("Basic logout error:", { error });
          throw error;
        }
      };
      
      // Use the comprehensive utility 
      // This will clear all caches and redirect to the sign-in page
      await logoutAndClearCaches(
        basicLogout,
        // This navigate function won't be used since logoutAndClearCaches does a hard redirect
        (to) => { window.location.href = typeof to === 'string' ? to : '/'; },
        // Detect if we're in the Ghost UI
        window.location.pathname.startsWith('/ghost')
      );
      
      // Clear client-side-only data in localStorage
      localStorage.removeItem('system_query_failures');
      localStorage.removeItem('auth_error_count');
      localStorage.removeItem('last_auth_error');
    } catch (error) {
      logger.error("Logout process error:", { error });
      
      // Fallback state clearing
      setUser(null);
      setToken(null);
      setIsAuthenticated(false);
    }
  }, [user?.id, onLogout]);

  const login = useCallback((response: AuthResponse) => {
    try {
      if (!response?.user?.id || !response?.access_token) {
        logger.error("Invalid auth response:", { response });
        throw new Error("Invalid authentication response");
      }
      
      // Validate and normalize user data
      if (response.user) {
        // Ensure is_email_verified is a boolean (default to false if not provided)
        if (response.user.is_email_verified === null || response.user.is_email_verified === undefined) {
          response.user.is_email_verified = false;
          logger.debug("Setting default is_email_verified value", {
            component: "AuthContext",
            userId: response.user.id,
          });
        }
      }

      logger.debug("Login process started", {
        userId: response.user.id,
        hasRefreshToken: !!response.refresh_token,
        isEmailVerified: response.user.is_email_verified
      } as any);
      
      // Prefetch system data on login to ensure it's available
      // We'll use a dynamic import to avoid circular dependencies
      try {
        import('@/hooks/root-drive/useSystemQueries').then(({ systemKeys }) => {
          const queryClient = window.__REACT_QUERY_GLOBAL_CLIENT__;
          if (queryClient) {
            logger.debug("Prefetching system data on login", {
              component: "AuthContext"
            });
            queryClient.prefetchQuery({ queryKey: systemKeys.overview() })
              .catch(err => logger.warn("Failed to prefetch system data on login", { error: err }));
          }
        });
      } catch (prefetchError) {
        logger.warn("Error setting up system data prefetch", { error: prefetchError });
      }

      // Make sure tokens don't have Bearer prefix before storage
      let accessToken = response.access_token;
      let refreshToken = response.refresh_token;
      
      // Clean tokens if they already have Bearer prefix
      if (accessToken.startsWith('Bearer ')) {
        accessToken = accessToken.substring(7).trim();
      }
      
      if (refreshToken && refreshToken.startsWith('Bearer ')) {
        refreshToken = refreshToken.substring(7).trim();
      }
      
      // Store clean tokens
      if (refreshToken) {
        authService.storeTokens(accessToken, refreshToken);
      } else {
        authService.storeTokens(accessToken);
      }
      
      // Also store tokens directly in localStorage for direct API access
      localStorage.setItem('access_token', accessToken);
      if (refreshToken) {
        localStorage.setItem('refresh_token', refreshToken);
      }

      // Then update user state
      localStorage.setItem(USER_KEY, JSON.stringify(response.user));
      
      // Reset any error state
      localStorage.removeItem('system_query_failures');
      localStorage.removeItem('auth_error_count');
      localStorage.removeItem('last_auth_error');

      const tokenWithBearer = response.access_token.startsWith("Bearer ")
        ? response.access_token
        : `Bearer ${response.access_token}`;

      setUser(response.user);
      setToken(tokenWithBearer);
      setIsAuthenticated(true);

      // Reset error count on successful login
      localStorage.removeItem(AUTH_ERROR_KEY);
    } catch (error) {
      logger.error("Login error:", { error });
      setUser(null);
      setIsAuthenticated(false);
      setToken(null);
      throw error;
    }
  }, []);

  const refreshSession = useCallback(async () => {
    const errorCount = Number(localStorage.getItem(AUTH_ERROR_KEY) || 0);
    const lastErrorTime = Number(localStorage.getItem("last_auth_error") || 0);
    const currentTime = Date.now();

    // Reset error count if enough time has passed
    if (currentTime - lastErrorTime > ERROR_RESET_TIME) {
      localStorage.removeItem(AUTH_ERROR_KEY);
      localStorage.removeItem("last_auth_error");
    } else if (errorCount >= MAX_ERROR_COUNT) {
      logger.warn("Too many refresh attempts, waiting for timeout");
      await handleLogout();
      return;
    }

    try {
      const { refreshToken } = authService.getStoredToken();
      if (!refreshToken) {
        logger.debug("No refresh token available");
        await handleLogout();
        return;
      }

      // Create a more detailed log of token state before refresh
      logger.debug("Preparing for token refresh", {
        component: "AuthContext",
        action: "refreshSession",
        data: {
          hasRefreshToken: !!refreshToken, 
          refreshTokenPrefix: refreshToken?.substring(0, 10) + "...",
          currentTime: new Date().toISOString(),
          timeSinceLastError: lastErrorTime ? (currentTime - lastErrorTime) / 1000 : "none",
          errorCount
        }
      });

      // Use the wrapper so only one refresh call is made at a time
      const response = await refreshTokenWrapper();
      if (response) {
        login(response);
        logger.debug("Session refreshed successfully");
        
        // Reset any error counters on successful refresh
        localStorage.removeItem(AUTH_ERROR_KEY);
        localStorage.removeItem("last_auth_error");
        return true;
      }
      return false;
    } catch (error) {
      // Log detailed information about the error
      logger.error("Session refresh failed:", { 
        error,
        component: "AuthContext",
        errorMessage: error instanceof Error ? error.message : "Unknown error",
        errorStack: error instanceof Error ? error.stack : undefined,
        refreshTokenAvailable: !!authService.getStoredToken().refreshToken
      });
      
      const newErrorCount = errorCount + 1;
      localStorage.setItem(AUTH_ERROR_KEY, newErrorCount.toString());
      localStorage.setItem("last_auth_error", currentTime.toString());
      
      // Don't immediately log out - give the system a chance to recover
      // This prevents a single refresh failure from disrupting the entire session
      if (newErrorCount >= 3) {
        logger.warn("Multiple token refresh failures, logging out", {
          component: "AuthContext",
          errorCount: newErrorCount
        });
        await handleLogout();
      }
      
      return false;
    }
  }, [handleLogout, login]);

  // Initialize auth state
  useEffect(() => {
    let mounted = true;

    const initAuth = async (retryCount = 0) => {
      try {
        if (!mounted) return;
        setIsLoading(true);
        
        // Check for auth_refresh parameter - indicates we need to force a clean initialization
        const authRefresh = new URLSearchParams(window.location.search).get("auth_refresh") === "true";
        
        // Check for auth page - never initialize authentication on auth pages
        const path = window.location.pathname;
        const isAuthPage = path.includes('/auth/') || path.includes('/ghost/auth/');
        
        logger.debug("Initializing auth state", { 
          retryCount, 
          authRefresh,
          isAuthPage
        } as any);
        
        // Skip initialization on auth pages to prevent unnecessary API calls
        if (isAuthPage) {
          logger.debug("Skipping auth initialization on auth page", {
            component: "AuthContext",
            path
          });
          
          if (mounted) {
            setIsLoading(false);
            setInitializationComplete(true);
          }
          return;
        }

        // Get tokens from both sources for reliability
        const { accessToken: serviceToken, refreshToken: serviceRefreshToken } = authService.getStoredToken();
        const directToken = localStorage.getItem('access_token');
        const directRefreshToken = localStorage.getItem('refresh_token');
        
        // Use whichever token is available
        const accessToken = directToken || serviceToken;
        const refreshToken = directRefreshToken || serviceRefreshToken;
        
        const storedUser = localStorage.getItem(USER_KEY);
        
        // If auth_refresh=true is present in URL, clear it from URL to prevent refresh loops
        // but keep the auth state
        if (authRefresh && mounted) {
          // Remove the parameter without reloading the page
          const newUrl = window.location.pathname + 
                        window.location.search.replace(/[?&]auth_refresh=true/, '') +
                        window.location.hash;
          window.history.replaceState({}, document.title, newUrl);
          
          logger.debug("Auth refresh parameter detected and removed", {
            component: "AuthContext"
          });
        }
        
        // Check for dev mode first - this should take precedence
        if (isDevMode()) {
          logger.debug("Dev mode authentication active");
          
          // Get stored test user or use default
          let devUser = null;
          
          try {
            // Try to get the stored user first
            if (storedUser) {
              devUser = JSON.parse(storedUser);
            } else {
              // Use default test user if nothing stored
              devUser = {
                id: "1",
                email: "test@example.com",
                name: "Test User",
              };
              // Store the default user for consistency
              localStorage.setItem(USER_KEY, JSON.stringify(devUser));
            }
          } catch (error) {
            logger.error("Error parsing stored user in dev mode:", { error });
            // Fallback to default test user
            devUser = {
              id: "1",
              email: "test@example.com",
              name: "Test User",
            };
            localStorage.setItem(USER_KEY, JSON.stringify(devUser));
          }
          
          if (mounted) {
            setUser(devUser);
            setToken("Bearer dev_token");
            setIsAuthenticated(true);
            setIsLoading(false);
            setInitializationComplete(true);
          }
          return; // Exit early since we're in dev mode
        }
        
        // Normal authentication flow
        if (accessToken && refreshToken && storedUser) {
          try {
            logger.debug("Found stored credentials");
            const parsedUser = JSON.parse(storedUser);

            if (mounted) {
              setUser(parsedUser);
              setToken(accessToken);
              setIsAuthenticated(true);
            }

            // Check token expiry
            try {
              const tokenData = jwtDecode<JWTPayload>(
                accessToken.replace("Bearer ", "")
              );
              const expiryTime = tokenData.exp * 1000;
              const timeUntilExpiry = expiryTime - Date.now();

              if (timeUntilExpiry > 3000 && timeUntilExpiry < 30000) {
                setTimeout(() => {
                  refreshSession().catch((err) =>
                    logger.error("Scheduled token refresh failed", { err })
                  );
                }, timeUntilExpiry - 3000);
              } else if (timeUntilExpiry <= 3000) {
                await refreshSession();
              }
            } catch (decodeError) {
              await refreshSession();
            }
          } catch (error) {
            logger.error("Error restoring auth state:", { error, retryCount });

            if (retryCount < MAX_REFRESH_RETRIES && mounted) {
              setTimeout(() => initAuth(retryCount + 1), REFRESH_RETRY_DELAY);
              return;
            }

            if (mounted) {
              await handleLogout();
            }
          }
        } 
      } catch (error) {
        logger.error("Auth initialization failed:", { error });
      } finally {
        if (mounted) {
          setIsLoading(false);
          setInitializationComplete(true);
        }
      }
    };

    initAuth();

    return () => {
      mounted = false;
    };
  }, [isDevMode, refreshSession, handleLogout]);

  useEffect(() => {
    if (!token || isDevMode()) return; // Skip token refresh for dev mode
    
    try {
      const tokenData = jwtDecode<JWTPayload>(token.replace("Bearer ", ""));
      const expiryTime = tokenData.exp * 1000; // convert exp (in seconds) to ms
      const timeUntilExpiry = expiryTime - Date.now();

      if (timeUntilExpiry > 3000) {
        const timeoutId = setTimeout(() => {
          refreshSession().catch((err) =>
            logger.error("Scheduled token refresh failed", { err })
          );
        }, timeUntilExpiry - 3000);
        return () => clearTimeout(timeoutId);
      } else {
        // If token is already near expiry, refresh immediately.
        refreshSession().catch((err) =>
          logger.error("Immediate token refresh failed", { err })
        );
      }
    } catch (error) {
      logger.error("Failed to schedule token refresh:", { error });
    }
  }, [token, refreshSession, isDevMode]);

  const value = {
    user,
    isAuthenticated,
    isLoading,
    token,
    login,
    logout: handleLogout,
    refreshSession,
    isDevMode,
  };

  if (!initializationComplete) {
    return null;
  }

  // Make auth context available globally for token refresh
  // This allows services to access auth functionality without circular imports
  window.__REACT_AUTH_CONTEXT__ = value;
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// Separate hook definition
function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

// Named exports
export { AuthProviderComponent as AuthProvider, useAuth };