/**
 * @file src/services/multipart-upload.service.ts
 * @description Handles chunked file uploads through server relay. This service is used 
 * when direct S3 upload is not available or when falling back from failed direct upload.
 * Files are uploaded in chunks through our server endpoints instead of directly to S3.
 * 
 * Strategy: Server Relay Multipart
 * Used when:
 * - Direct S3 upload is not available
 * - File size > 5MB but user doesn't have direct upload permission
 * - As fallback when direct upload fails
 * 
 * Flow:
 * 1. Initialize upload with server
 * 2. Upload chunks through server endpoints
 * 3. Server handles S3 upload
 * 4. Complete upload when all chunks are processed
 */
/**
 * @file src/services/multipart-upload.service.ts
 * @description Handles chunked file uploads through server relay.
 * @version 1.2.0
 * @created 2024-01-07
 * @updated 2024-02-06
 */

import { UPLOAD_CONFIG } from '@/config/upload.config';
import { logger } from '@/utils/logger';

import type {
  UploadProgress,
  UploadResponse,
  ChunkState
} from '@/types/upload.types';

// Extended UploadProgress interface with additional properties
interface ExtendedUploadProgress extends UploadProgress {
  totalChunks?: number;
}

export const multipartUploadService = {
  async initializeUpload(
    file: File,
    parentId: string | null,
    driveId: string
  ): Promise<{
    upload_id: string;
    chunk_size: number;
    total_chunks: number;
    storage_path: string;
    strategy: string;
  }> {
    try {
      logger.debug('Initializing multipart upload', {
        component: 'upload.multipart',
        action: 'initialize',
        data: {
          fileName: file.name,
          fileSize: file.size,
          mimeType: file.type,
          parentId,
          driveId
        }
      });

      const formData = new FormData();
      formData.append('filename', file.name);
      formData.append('file_size', file.size.toString());
      formData.append('content_type', file.type);
      formData.append('drive_id', driveId);
      if (parentId) formData.append('parent_id', parentId);

      const response = await fetch('/api/v1/cloud-drive/upload/initialize', {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('Upload initialization failed', {
          component: 'upload.multipart',
          action: 'initialize',
          data: {
            status: response.status,
            statusText: response.statusText,
            error: errorText
          }
        });
        throw new Error(`Failed to initialize upload: ${response.statusText}`);
      }

      const result = await response.json();

      logger.debug('Upload initialized successfully', {
        component: 'upload.multipart',
        action: 'initialize',
        data: {
          uploadId: result.upload_id,
          totalChunks: result.total_chunks,
          chunkSize: result.chunk_size,
          storagePath: result.storage_path
        }
      });

      return result;
    } catch (error) {
      logger.error('Upload initialization error', {
        component: 'upload.multipart',
        action: 'initialize',
        data: {
          fileName: file.name,
          error: error instanceof Error ? error.message : 'Unknown error'
        }
      });
      throw error;
    }
  },


  async uploadChunk(
    file: File,
    chunk: ChunkState,
    uploadId: string,
    driveId: string
  ): Promise<{ etag?: string, hash?: string }> {
    const formData = new FormData();
    const chunkBlob = file.slice(chunk.start, chunk.end);

    formData.append('chunk', chunkBlob);
    formData.append('chunk_index', chunk.index.toString());
    formData.append('drive_id', driveId);

    // Update to match our new API endpoint
    const response = await fetch(`/api/v1/cloud-drive/upload/chunk/${uploadId}`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Chunk upload failed: ${response.statusText}`);
    }

    return response.json();
  },


  async uploadChunks(
    file: File,
    uploadId: string,
    driveId: string,
    chunks: ChunkState[],
    onProgress?: (progress: ExtendedUploadProgress) => void
  ): Promise<ChunkState[]> {
    const startTime = Date.now();
    let totalUploaded = 0;

    logger.debug('Starting chunks upload', {
      component: 'upload.multipart',
      action: 'uploadChunks',
      data: {
        fileName: file.name,
        fileSize: file.size,
        uploadId,
        totalChunks: chunks.length,
        chunkSize: UPLOAD_CONFIG.chunkSize
      }
    });

    try {
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        let attempt = 0;
        const maxAttempts = 3;

        while (attempt < maxAttempts) {
          try {
            const chunkStart = i * UPLOAD_CONFIG.chunkSize;
            const chunkEnd = Math.min(chunkStart + UPLOAD_CONFIG.chunkSize, file.size);
            const chunkSize = chunkEnd - chunkStart;

            logger.debug('Uploading chunk', {
              component: 'upload.chunk',
              action: 'upload',
              data: {
                uploadId,
                chunkIndex: i + 1,
                totalChunks: chunks.length,
                chunkSize,
                attempt: attempt + 1,
                progress: Math.round((totalUploaded / file.size) * 100)
              }
            });

            const chunkBlob = file.slice(chunkStart, chunkEnd);
            const formData = new FormData();
            formData.append('chunk', chunkBlob);
            formData.append('chunk_index', (i + 1).toString());
            formData.append('drive_id', driveId);

            const response = await fetch(
              `/api/v1/cloud-drive/upload/chunk/${uploadId}`,
              {
                method: 'POST',
                body: formData,
                credentials: 'include'
              }
            );

            if (!response.ok) {
              throw new Error(`Chunk upload failed: ${response.statusText}`);
            }

            const result = await response.json();

            logger.debug('Chunk upload successful', {
              component: 'upload.chunk',
              action: 'complete',
              data: {
                chunkIndex: i + 1,
                etag: result.etag,
                size: chunkSize
              }
            });

            chunks[i].uploaded = true;
            chunks[i].etag = result.etag;
            chunks[i].partNumber = i + 1;
            totalUploaded += chunkSize;

            if (onProgress) {
              const progress: ExtendedUploadProgress = {
                loaded: totalUploaded,
                total: file.size,
                speed: totalUploaded / ((Date.now() - startTime) / 1000),
                currentChunk: i,
                totalChunks: chunks.length
              };

              logger.debug('Upload progress', {
                component: 'upload.progress',
                data: {
                  ...progress,
                  percentComplete: Math.round((progress.loaded / progress.total) * 100)
                }
              });

              onProgress(progress);
            }

            break;

          } catch (error) {
            attempt++;
            logger.error('Chunk upload attempt failed', {
              component: 'upload.chunk',
              action: 'error',
              data: {
                uploadId,
                chunkIndex: i + 1,
                attempt,
                maxAttempts,
                error: error instanceof Error ? error.message : 'Unknown error'
              }
            });

            if (attempt === maxAttempts) {
              throw error;
            }

            // Wait before retry with exponential backoff
            const delay = Math.min(1000 * Math.pow(2, attempt), 30000);
            await new Promise(resolve => setTimeout(resolve, delay));
          }
        }
      }

      logger.debug('All chunks uploaded successfully', {
        component: 'upload.multipart',
        action: 'complete',
        data: {
          uploadId,
          totalChunks: chunks.length,
          totalSize: totalUploaded,
          duration: Date.now() - startTime
        }
      });

      return chunks;

    } catch (error) {
      logger.error('Chunks upload failed', {
        component: 'upload.multipart',
        action: 'error',
        data: {
          uploadId,
          totalUploaded,
          error: error instanceof Error ? error.message : 'Unknown error'
        }
      });
      throw error;
    }
  },

  async completeUpload(
    uploadId: string,
    driveId: string,
    chunks: ChunkState[]
  ): Promise<UploadResponse> {
    try {
      logger.debug('Starting upload completion', {
        component: 'upload.multipart',
        action: 'complete',
        data: {
          uploadId,
          chunksCount: chunks.length,
          allChunksUploaded: chunks.every((chunk) => chunk.uploaded)
        }
      });

      // Log chunks state before completion
      logger.debug('Chunks state before completion', {
        component: 'upload.multipart',
        action: 'validate',
        data: {
          chunks: chunks.map((chunk: any) => ({
            partNumber: chunk.partNumber,
            uploaded: chunk.uploaded,
            etag: chunk.etag
          }))
        }
      });

      const completionPayload = {
        upload_id: uploadId,
        drive_id: driveId,
        MultipartUpload: {
          Parts: chunks
            .filter((c: any) => c.uploaded && c.etag)
            .sort((a, b) => (a.partNumber || 0) - (b.partNumber || 0))
            .map((chunk: any) => ({
              PartNumber: chunk.partNumber,
              ETag: chunk.etag?.replace(/^"|"$/g, '')
            }))
        }
      };

      logger.debug('Sending completion request', {
        component: 'upload.multipart',
        action: 'complete',
        data: {
          uploadId,
          payload: completionPayload
        }
      });

      const response = await fetch(
        `/api/v1/cloud-drive/upload/complete/${uploadId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          } as HeadersInit,
          body: JSON.stringify(completionPayload),
          credentials: 'include'
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('Completion request failed', {
          component: 'upload.multipart',
          action: 'complete',
          data: {
            status: response.status,
            statusText: response.statusText,
            error: errorText,
            payload: completionPayload
          }
        });
        throw new Error(`Failed to complete upload: ${response.statusText}`);
      }

      const result = await response.json();

      logger.debug('Upload completed successfully', {
        component: 'upload.multipart',
        action: 'complete',
        data: {
          uploadId,
          result
        }
      });

      return result;

    } catch (error) {
      logger.error('Error completing upload', {
        component: 'upload.multipart',
        action: 'error',
        data: {
          uploadId,
          error: error instanceof Error ? error.message : 'Unknown error'
        }
      });
      throw error;
    }
  },

  async abortUpload(uploadId: string, driveId: string): Promise<void> {
    try {
      // Update to match our new API endpoint
      const response = await fetch(`/api/v1/cloud-drive/upload/abort/${uploadId}`, {
        method: 'POST', // Changed from DELETE to match backend
        headers: {
          'Content-Type': 'application/json'
        } as HeadersInit,
        body: JSON.stringify({ drive_id: driveId }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`Failed to abort upload: ${response.statusText}`);
      }

      logger.debug('Upload aborted successfully', {
        component: 'multipartUploadService',
        data: { uploadId }
      });
    } catch (error) {
      logger.error('Error aborting upload', {
        component: 'multipartUploadService',
        error,
        data: { uploadId }
      });
    }
  }


};

// TODO: Future enhancement - implement parallel chunk uploads
// Add a note about potential implementation:
// - Add concurrency limit in config
// - Implement chunk queue with worker pool
// - Add proper progress aggregation
// - Consider memory usage for very large files