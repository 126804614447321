/**
 * @file src/components/data/DataSplitView/index.tsx
 * @description Main data split view component with category navigation
 * @version 1.0.0
 */

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { CategorySidebar } from './CategorySidebar';
import { MainContent } from './MainContent';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
// import { useCloudServices } from '@/hooks/useCloudServices';
// import { useCloudServices } from '@/hooks/useCloudServices';
import { useCloudDrive } from '@/context/cloud-drive';
import { logger } from '@/utils/logger';
import { 
  Cloud,
  Star,
  Share2,
  Users,
  Trash2,
  Download,
  Computer,
  Shield
} from 'lucide-react';
import type { Category, CategoryId, DataSplitViewProps, Device } from './types';
import { DeviceWithDrives } from '@/types/root-drive.types';



export const DataSplitView: React.FC<DataSplitViewProps> = ({
  initialCategory = 'cloudDrive',
  initialView = 'default',
  folderId = null,
  initialSelectedItem,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { encryptedDrive, setEncryptedDrive } = useCloudDrive();

  // Root drive context for devices
  const { 
    devices, 
    isLoading: isDevicesLoading, 
    error: devicesError 
  } = useRootDrive();

  // State
  const [selectedCategory, setSelectedCategory] = useState<CategoryId>(initialCategory as CategoryId);
  const [expandedDevice, setExpandedDevice] = useState<string | null>(null);
  const [selectedItemId, ] = useState<string | undefined>(initialSelectedItem);
  const [sidebarVisible, setSidebarVisible] = useState(!isMobile);

  // Get services
  // const { services } = useCloudServices();

  // Memoized backup devices
  const backupDevices = useMemo(() => {
    if (!devices) return [];
    return (devices as unknown as DeviceWithDrives[])
      .filter((device: DeviceWithDrives) => device.drives[0].type === 'backup')
      .slice(0, 3);
  }, [devices]);

  // Categories with dynamic data
  const categories = useMemo(() => {
    const cats: Category[] = [
      { 
        id: 'devices', 
        label: t('data_view.categories.devices'), 
        icon: Computer,
        devices: backupDevices,
        isLoading: isDevicesLoading,
        error: devicesError,
        showMoreCount: devices?.length ? Math.max(0, devices.length - 3) : 0
      },
      // Add services category back
      {
        id: 'services',
        label: t('data_view.categories.services'),
        icon: Share2 // You might want to use a different icon like Server or Cloud
      },      
      { 
        id: 'cloudDrive', 
        label: t('data_view.categories.cloud_drive'), 
        icon: Cloud
      },
      {
        id: 'encryptedDrives',
        label: t('data_view.categories.encrypted_drives'),
        icon: Shield
      },
      { 
        id: 'favorites', 
        label: t('data_view.categories.favorites'), 
        icon: Star 
      },
      { 
        id: 'shares', 
        label: t('data_view.categories.shares'), 
        icon: Share2 
      },
      { 
        id: 'sharedWithMe', 
        label: t('data_view.categories.shared_with_me'), 
        icon: Users 
      },
      { 
        id: 'trash', 
        label: t('data_view.categories.trash'), 
        icon: Trash2 
      },
      { 
        id: 'coldStorage', 
        label: t('data_view.categories.cold_storage'), 
        icon: Download 
      }
    ];

    return cats;
  }, [t, backupDevices, devices?.length, isDevicesLoading, devicesError]);

  // Handle category change
  const handleCategoryChange = (categoryId: CategoryId) => {
    logger.debug('Category changed', {
      component: 'DataSplitView',
      data: { 
        from: selectedCategory, 
        to: categoryId 
      }
    });

    setSelectedCategory(categoryId);
    setExpandedDevice(null);

    // Clear encrypted drive state if moving away from encrypted drives
    if (categoryId !== 'encryptedDrives' && encryptedDrive.isEncryptedMode) {
      setEncryptedDrive({
        isEncryptedMode: false,
        encryptedDriveId: undefined,
        encryptionTier: undefined
      });
    }

    // Map category IDs to routes
    const categoryRoutes: Record<CategoryId, string> = {
      favorites: '/cloud-drive/favorites',
      cloudDrive: '/cloud-drive',
      services: '/data/services',
      encryptedDrives: '/data/encrypted-drives',
      devices: '/data/devices',
      shares: '/data/shares',
      sharedWithMe: '/data/shared-with-me',
      trash: '/data/trash',
      coldStorage: '/data/cold-storage'
    };

    const route = categoryRoutes[categoryId];
    if (route) {
      navigate(route, { replace: true });
    }
  };

  // Effect to handle navigation from encrypted drive
  useEffect(() => {
    const state = location.state as { fromEncryptedDrive?: boolean };
    if (state?.fromEncryptedDrive) {
      setSelectedCategory('cloudDrive');
      logger.debug('Navigation from encrypted drive', {
        component: 'DataSplitView',
        data: { fromEncryptedDrive: true }
      });
    }
  }, [location.state]);

  // Effect to handle mobile sidebar
  useEffect(() => {
    setSidebarVisible(!isMobile);
  }, [isMobile]);

  return (
    <div className="flex h-full bg-gray-50 dark:bg-gray-900">
      {/* Category Sidebar */}
      {sidebarVisible && (
        <CategorySidebar
          activeCategory={selectedCategory}
          onCategoryChange={handleCategoryChange}
          selectedItemId={selectedItemId}
          onDeviceSelect={setExpandedDevice}
          categories={categories}
          className="w-64 flex-shrink-0"
        />
      )}

      {/* Main Content */}
      <MainContent
        selectedCategory={selectedCategory}
        expandedDevice={expandedDevice}
        folderId={folderId}
        initialView={initialView}
        className="flex-1"
      />
    </div>
  );
};

export default DataSplitView;