/**
 * @file src/components/ui/date-range-picker.tsx
 * @description Modern date range picker with animations
 * @version 1.0.0
 * @created 2024-12-23
 * @dependencies react-day-picker, date-fns
 */

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { DateRange, DayPicker } from "react-day-picker"
import { motion, AnimatePresence } from "framer-motion"
import { cn } from "@/utils/utils"
import { Button } from "./button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"

export type DateRangePickerProps = {
  /** The selected date range */
  value?: DateRange | undefined
  /** Callback when date range changes */
  onChange?: (date: DateRange | undefined) => void
  /** Optional className for the root element */
  className?: string
}

export function DateRangePicker({
  value,
  onChange,
  className,
}: DateRangePickerProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal",
              !value && "text-gray-500 dark:text-gray-400"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {value?.from ? (
              value.to ? (
                <>
                  {format(value.from, "LLL dd, y")} -{" "}
                  {format(value.to, "LLL dd, y")}
                </>
              ) : (
                format(value.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date range</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.15 }}
            >
              <DayPicker
                mode="range"
                defaultMonth={value?.from}
                selected={value}
                onSelect={onChange}
                numberOfMonths={2}
                disabled={{ before: new Date() }}
                styles={{
                  months: { display: "flex", gap: "1rem" },
                  caption: { position: "relative" },
                  caption_label: { fontWeight: "600", fontSize: "0.875rem" },
                  nav: { display: "flex", gap: "0.25rem" },
                  nav_button: {
                    width: "2rem",
                    height: "2rem",
                    borderRadius: "0.375rem",
                    color: "var(--gray-600)",
                    fontSize: "0.875rem",
                    transition: "background-color 150ms",
                    [":hover" as any]: {
                      backgroundColor: "var(--gray-100)",
                    },
                    [":disabled" as any]: {
                      opacity: "0.5",
                    },
                  },
                  table: { width: "100%", borderCollapse: "collapse" },
                  head_cell: {
                    width: "2.25rem",
                    height: "2.25rem",
                    fontSize: "0.75rem",
                    fontWeight: "600",
                    color: "var(--gray-500)",
                  },
                  cell: {
                    width: "2.25rem",
                    height: "2.25rem",
                    fontSize: "0.875rem",
                    padding: "0",
                  },
                  day: {
                    width: "2.25rem",
                    height: "2.25rem",
                    transition: "all 150ms",
                    borderRadius: "0.375rem",
                    cursor: "pointer",
                    [":hover" as any]: {
                      backgroundColor: "var(--gray-100)",
                    },
                    ['&[aria-selected="true"]' as any]: {
                      backgroundColor: "var(--blue-500)",
                      color: "white",
                    },
                  },
                }}
                className={cn(
                  "rounded-md border border-gray-200",
                  "bg-white p-4",
                  "dark:border-gray-800 dark:bg-gray-900"
                )}
                classNames={{
                  months:
                    "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                  month: "space-y-4",
                  caption: "flex justify-center pt-1 relative items-center",
                  caption_label: "text-sm font-medium",
                  nav: "space-x-1 flex items-center",
                  nav_button: cn(
                    "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
                  ),
                  nav_button_previous: "absolute left-1",
                  nav_button_next: "absolute right-1",
                  table: "w-full border-collapse space-y-1",
                  head_row: "flex",
                  head_cell:
                    "text-gray-500 rounded-md w-8 font-normal text-[0.8rem] dark:text-gray-400",
                  row: "flex w-full mt-2",
                  cell: cn(
                    "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-gray-100 dark:[&:has([aria-selected])]:bg-gray-800",
                    "[&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-range-start)]:rounded-l-md"
                  ),
                  day: cn(
                    "h-8 w-8 p-0 font-normal",
                    "aria-selected:opacity-100"
                  ),
                  day_range_start: "day-range-start",
                  day_range_end: "day-range-end",
                  day_selected:
                    "bg-gray-900 text-gray-50 hover:bg-gray-800 hover:text-gray-50 focus:bg-gray-900 focus:text-gray-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50 dark:hover:text-gray-900 dark:focus:bg-gray-50 dark:focus:text-gray-900",
                  day_today:
                    "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50",
                  day_outside: "text-gray-500 opacity-50 dark:text-gray-400",
                  day_disabled: "text-gray-500 opacity-50 dark:text-gray-400",
                  day_range_middle:
                    "aria-selected:bg-gray-100 aria-selected:text-gray-900 dark:aria-selected:bg-gray-800 dark:aria-selected:text-gray-50",
                  day_hidden: "invisible",
                }}
              />

              {/* Quick select presets */}
              <div className="border-t border-gray-200 dark:border-gray-800 p-4">
                <div className="flex justify-end space-x-2">
                  {/* Last 7 days */}
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      const to = new Date();
                      const from = new Date();
                      from.setDate(from.getDate() - 7);
                      onChange?.({ from, to });
                      setIsOpen(false);
                    }}
                  >
                    Last 7 days
                  </Button>
                  {/* Last 30 days */}
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      const to = new Date();
                      const from = new Date();
                      from.setDate(from.getDate() - 30);
                      onChange?.({ from, to });
                      setIsOpen(false);
                    }}
                  >
                    Last 30 days
                  </Button>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </PopoverContent>
      </Popover>
    </div>
  );
}
