/**
 * @file src/utils/logout-utils.ts
 * @description Unified logout utility with comprehensive cache clearing
 * @version 1.4.0
 * @created 2025-03-21
 * @updated 2025-03-21
 */

import { NavigateFunction } from 'react-router-dom';
import { mediaCacheService } from '@/services/encrypted-drive/media/media-cache.service';
import { encryptionService } from '@/services/encrypted-drive/encryption-singleton';
import { QueryClient } from '@tanstack/react-query';
import { logger } from '@/utils/logger';

// Get access to the global query client
// This is defined in QueryProvider.tsx
let queryClient: QueryClient | null = null;

export const registerQueryClient = (client: QueryClient): void => {
  queryClient = client;
};

/**
 * Comprehensive logout function that handles:
 * - Auth logout
 * - Cache clearing (media, query, encryption)
 * - Proper redirection based on UI context
 */
export const logoutAndClearCaches = async (
  logout: () => Promise<void>, 
  navigate: NavigateFunction,  // Still accept for API consistency
  forceGhostUI?: boolean
): Promise<void> => {
  // Prevent default event behavior if this was triggered by a click
  // This helps stop React Router from processing navigation
  
  // Determine current UI context with override option
  const isGhostUI = forceGhostUI === true ? true : window.location.pathname.startsWith('/ghost');
  
  // 1. Determine target sign-in path immediately
  const signInPath = isGhostUI ? '/ghost/auth/signin' : '/auth/signin';
  const baseUrl = window.location.origin;
  const fullUrl = `${baseUrl}${signInPath}`;
  
  try {
    logger.debug('Starting logout process', {
      component: 'logoutUtils',
      data: { 
        ui: isGhostUI ? 'Nygma' : 'BigMIND',
        path: window.location.pathname,
        forceGhostUI,
        targetUrl: fullUrl
      }
    });

    // 2. First, create a transient overlay to prevent any UI interaction 
    // and provide visual feedback during logout
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '9999';
    overlay.style.display = 'flex';
    overlay.style.alignItems = 'center';
    overlay.style.justifyContent = 'center';
    
    const spinner = document.createElement('div');
    spinner.style.width = '40px';
    spinner.style.height = '40px';
    spinner.style.border = '3px solid #f3f3f3';
    spinner.style.borderTop = '3px solid #3498db';
    spinner.style.borderRadius = '50%';
    spinner.style.animation = 'spin 1s linear infinite';
    
    const style = document.createElement('style');
    style.innerHTML = '@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }';
    
    document.head.appendChild(style);
    overlay.appendChild(spinner);
    document.body.appendChild(overlay);
    
    // 3. Clear caches synchronously - no awaits to avoid UI updates
    
    // Clear media cache
    try {
      mediaCacheService.clearAllCache();
      logger.debug('Media cache cleared', { component: 'logoutUtils' });
    } catch (error) {
      logger.error('Error clearing media cache during logout', { 
        component: 'logoutUtils', 
        error 
      });
    }
    
    // Clear encryption service keys
    try {
      encryptionService.clearAllKeys();
      logger.debug('Encryption keys cleared', { component: 'logoutUtils' });
    } catch (error) {
      logger.error('Error clearing encryption keys during logout', {
        component: 'logoutUtils',
        error
      });
    }
    
    // Clear React Query cache if available
    if (queryClient) {
      try {
        queryClient.clear();
        logger.debug('React Query cache cleared', { component: 'logoutUtils' });
      } catch (error) {
        logger.error('Error clearing React Query cache during logout', {
          component: 'logoutUtils',
          error
        });
      }
    }
    
    // 4. Fire up the logout function to clear auth state
    // and IMMEDIATELY set location to prevent any React renders
    logout().then(() => {
      logger.debug('Auth logout complete, redirecting', { 
        component: 'logoutUtils',
        data: { destination: fullUrl }
      });
    }).catch(error => {
      logger.error('Error in auth logout', { component: 'logoutUtils', error });
    }).finally(() => {
      // Force redirect regardless of logout promise result
      window.location.replace(fullUrl);
    });
    
    // 5. Set a timeout to ensure we redirect even if something hangs
    setTimeout(() => {
      logger.debug('Redirect timeout triggered', { component: 'logoutUtils' });
      window.location.replace(fullUrl);
    }, 1000);
    
  } catch (error) {
    logger.error('Error during logout process', {
      component: 'logoutUtils',
      error
    });
    
    // Redirect even on error
    window.location.replace(fullUrl);
  }
  
  // Return a promise that never resolves to prevent any further component execution
  return new Promise(() => {});
};