/**
 * @file src/components/ghost/common/background/utils/colors.ts
 * @description Color utilities for canvas effects
 * @version 1.0.0
 */

import { logger } from '@/utils/logger';

interface HSLColor {
  h: number;
  s: number;
  l: number;
}

export const getThemeColor = (variable: string): HSLColor => {
  try {
    // Create temporary element to compute styles
    const temp = document.createElement('div');
    temp.className = 'ghost-theme';
    document.body.appendChild(temp);
    
    // Get the HSL values from CSS variable
    const color = getComputedStyle(temp).getPropertyValue(variable).trim();
    document.body.removeChild(temp);

    // Parse HSL values
    const [h, s, l] = color.split(' ').map((v: any) => parseFloat(v));
    
    return { h, s, l };
  } catch (error) {
    logger.error('Failed to get theme color', { variable, error });
    // Fallback to a default color
    return { h: 285, s: 100, l: 65 };
  }
};

export const createHSLColor = (color: HSLColor, alpha: number = 1): string => {
  return `hsla(${color.h}, ${color.s}%, ${color.l}%, ${alpha})`;
};

// Cache theme colors for performance
let accentColor: HSLColor | null = null;
let secondaryColor: HSLColor | null = null;

export const getAccentColor = (alpha: number = 1): string => {
  if (!accentColor) {
    accentColor = getThemeColor('--ghost-accent');
  }
  return createHSLColor(accentColor, alpha);
};

export const getSecondaryColor = (alpha: number = 1): string => {
  if (!secondaryColor) {
    secondaryColor = getThemeColor('--ghost-accent-secondary');
  }
  return createHSLColor(secondaryColor, alpha);
};