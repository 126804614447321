/**
 * @file src/components/cloud-drive/browser/FileGrid.tsx
 * @description Virtualized grid view with dynamic rendering for large collections
 * @version 3.0.0
 * @updated 2025-03-26
 */

import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2, Info, RefreshCw } from 'lucide-react';
import { cn } from '@/utils/utils';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import { FileItem } from '../items/FileItem';
import { useNavigation } from '@/context/NavigationContext';
import { useSelection } from '@/context/SelectionContext';
import { useCloudDrive } from '@/context/cloud-drive';
import { Checkbox } from '@/components/ui/checkbox';
import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';
import { logger } from '@/utils/logger';
import { useToast } from '@/components/ui/toast';
import { useTranslation } from 'react-i18next';
import { useFilePreviewContext } from '@/providers/FilePreviewProvider';
import { CheckedState } from '@radix-ui/react-checkbox';
import { ItemCheckbox } from '../items/components/ItemCheckbox';
import { Skeleton } from '@/components/ui/skeleton';
import { useQueryClient } from '@tanstack/react-query';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { mediaCacheService } from '@/services/encrypted-drive/media/media-cache.service';

// Number of items to render initially
const INITIAL_RENDER_COUNT = 20;
// Number of items to add for each batch
const BATCH_SIZE = 15;

interface FileGridProps {
  items: (DriveFile | DriveFolder)[];
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  isDragging?: boolean;
  className?: string;
  totalItemCount?: number;
}

export const FileGrid: React.FC<FileGridProps> = ({
  items,
  hasNextPage,
  onLoadMore,
  isLoadingMore,
  isDragging,
  className,
  totalItemCount
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const parentRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const { activeDriveId } = useRootDrive();
  
  // Virtualization state
  const [visibleCount, setVisibleCount] = useState(INITIAL_RENDER_COUNT);
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  // Get navigation helpers with safety checks
  const navigationContext = useNavigation();
  const navigateToFolder = navigationContext?.navigateToFolder;
  const navigateToFile = navigationContext?.navigateToFile;
  
  const { selectedItems, isSelectMode, toggleSelection, selectAll, clearSelection } = useSelection();
  const { currentFolder, encryptedDrive, currentFolderContents, forceRefreshFolderContents } = useCloudDrive();
  
  // Get the preview context with error handling
  let previewFile: ((file: DriveFile, files?: DriveFile[]) => void) | undefined = undefined;
  try {
    const previewContext = useFilePreviewContext();
    previewFile = previewContext.previewFile;
  } catch (error) {
    // If FilePreviewContext is not available, log the error but don't crash
    logger.warn('FilePreviewContext not available - preview functionality disabled', {
      component: 'FileGrid',
      error
    });
    // previewFile will remain undefined
  }

  // Performance metrics
  const renderStartTime = useRef<number>(performance.now());
  const [renderTime, setRenderTime] = useState<number | null>(null);

  // Debug logging - reduced to only log essential info
  useEffect(() => {
    // Log only on initial mount and when the count changes significantly
    if (visibleCount === INITIAL_RENDER_COUNT || visibleCount % (BATCH_SIZE * 2) === 0) {
      logger.debug('FileGrid visible items update', {
        component: 'FileGrid',
        data: {
          visibleCount,
          totalItems: items.length,
          selectedCount: selectedItems.length,
          renderTimeMs: renderTime
        }
      });
    }
  }, [visibleCount, items.length, selectedItems.length, renderTime]);

  // Observer for progressive loading as user scrolls
  useEffect(() => {
    if (!observerRef.current || items.length <= visibleCount) return;
    
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isRefreshing) {
          // Start timing the render
          renderStartTime.current = performance.now();
          
          // Increase the number of visible items
          setVisibleCount(prev => Math.min(prev + BATCH_SIZE, items.length));
          
          // Measure render time after state update
          setTimeout(() => {
            const endTime = performance.now();
            setRenderTime(endTime - renderStartTime.current);
          }, 0);
        }
      },
      { rootMargin: '200px 0px' }
    );
    
    observer.observe(observerRef.current);
    
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [items.length, visibleCount, isRefreshing]);
  
  // Reset visible count when items change completely
  useEffect(() => {
    setVisibleCount(Math.min(INITIAL_RENDER_COUNT, items.length));
  }, [currentFolder]);

  // Get window size to determine thumbnail quality
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  
  // Update window size on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Always use medium thumbnails for grid view to ensure high quality images
  const thumbnailSize = useMemo(() => {
    // Grid view should always use medium thumbnails
    // List view will override this with 'small' in FileItem
    return 'medium';
  }, []);

  // Optimized staggered items with improved animation handling
  const staggeredVisibleItems = useMemo(() => {
    if (items.length === 0) return [];
    
    const visibleItems = items.slice(0, visibleCount);
    
    // Skip enhancements for non-encrypted mode
    if (!encryptedDrive?.isEncryptedMode) return visibleItems;
    
    // Import the media cache service to check for animated state
    // Use dynamic import to avoid circular dependencies
    try {
      // For encrypted mode, add animation delays and thumbnail size
      return visibleItems.map((item, index) => {
        // Check if this file has already been animated using the media cache service
        const hasBeenAnimated = encryptedDrive.encryptedDriveId 
          ? mediaCacheService.hasFileBeenAnimated(encryptedDrive.encryptedDriveId, item.id)
          : false;
        
        return {
          ...item,
          // Only animate first 10 items and only if not previously animated
          _decryptDelay: !hasBeenAnimated && index < 10 ? index * 80 : undefined,
          _thumbnailSize: thumbnailSize // Pass thumbnail size based on screen width
        };
      });
    } catch (error) {
      // Fallback if service is not available
      logger.warn('Error checking animation status', { 
        component: 'FileGrid',
        error 
      });
      
      // Fallback to simpler approach
      return visibleItems.map((item, index) => ({
        ...item,
        _decryptDelay: index < 10 ? index * 80 : undefined, // Only animate first 10 items with shorter delays
        _thumbnailSize: thumbnailSize
      }));
    }
  }, [items, visibleCount, encryptedDrive?.isEncryptedMode, encryptedDrive?.encryptedDriveId, thumbnailSize]);

  // Handle infinite scroll for API pagination
  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: onLoadMore,
    enabled: !!hasNextPage && !isLoadingMore && visibleCount >= items.length
  });

  // Handle select all with memory optimization
  const handleSelectAll = useCallback((checked: CheckedState, event?: React.MouseEvent) => {
    // Make sure any event is stopped from propagating to prevent double-handling
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    logger.debug('Select all triggered in FileGrid', {
      component: 'FileGrid',
      data: {
        checked,
        totalItems: items.length
      }
    });
    
    if (checked) {
      const itemIds = items.map(item => item.id);
      selectAll(itemIds);
    } else {
      clearSelection();
    }
  }, [items, selectAll, clearSelection, logger]);

  // Handle item selection with optimized lookup
  const handleSelect = useCallback((id: string, event?: React.MouseEvent) => {
    if (event?.shiftKey && selectedItems.length > 0) {
      const lastSelectedId = selectedItems[selectedItems.length - 1];
      const lastSelectedIndex = items.findIndex(item => item.id === lastSelectedId);
      const currentIndex = items.findIndex(item => item.id === id);
      
      const start = Math.min(lastSelectedIndex, currentIndex);
      const end = Math.max(lastSelectedIndex, currentIndex);
      
      const itemsToSelect = items.slice(start, end + 1).map(item => item.id);
      selectAll(itemsToSelect);
    } else {
      toggleSelection(id);
    }
  }, [items, selectedItems, toggleSelection, selectAll]);

  // Enhanced item open handling with memory optimization
  const handleItemOpen = useCallback((item: DriveFile | DriveFolder) => {
    try {
      if (item?.type === 'folder') {
        if (typeof navigateToFolder === 'function') {
          navigateToFolder(item.id, {
            isEncryptedMode: encryptedDrive.isEncryptedMode,
            encryptedDriveId: encryptedDrive.encryptedDriveId
          });
        } else {
          throw new Error('Navigation function not available');
        }
      } else {
        // For files, check if it's a media file
        const isMediaFile = item.type === 'file' && 
        ((item as DriveFile).mimeType?.startsWith('image/') || 
         (item as DriveFile).mimeType?.startsWith('video/') ||
         !!(item as any).media_info);
      
        if (isMediaFile) {
          // Get all media files for proper gallery navigation
          const mediaFiles = items.filter(i => 
            i.type === 'file' && 
            ((i as DriveFile).mimeType?.startsWith('image/') || 
            (i as DriveFile).mimeType?.startsWith('video/') ||
            !!(i as any).media_info)
          ) as DriveFile[];
          
          if (previewFile) {
            // Only preview if the function is available
            previewFile(item as DriveFile, mediaFiles);
          } else {
            // Fall back to file navigation if preview is not available
            logger.warn('File preview not available, falling back to file navigation', {
              component: 'FileGrid',
              fileId: item.id
            });
            
            if (typeof navigateToFile === 'function') {
              navigateToFile(item.id);
            } else {
              showToast(t('errors.navigation_failed'), 'error');
            }
          }
        }
        else if (typeof navigateToFile === 'function') {
          navigateToFile(item.id);
        } else {
          throw new Error('Navigation function not available');
        }
      }
    } catch (error) {
      logger.error('Error opening item', {
        component: 'FileItem',
        error,
        data: { itemId: item.id, itemType: item.type }
      });
      
      showToast(t('errors.navigation_failed'), 'error');
    }
  }, [navigateToFolder, navigateToFile, previewFile, encryptedDrive, items, t, showToast]);

  // Refresh handler for manual updates
  const handleRefresh = useCallback(() => {
    setIsRefreshing(true);
    
    // Use CloudDriveContext refresh method if available
    if (forceRefreshFolderContents) {
      forceRefreshFolderContents();
    }
    
    // Also directly invalidate relevant queries
    if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
      queryClient.invalidateQueries({
        queryKey: ['encrypted-drive', 'files', encryptedDrive.encryptedDriveId, currentFolder]
      });
    } else if (activeDriveId) {
      queryClient.invalidateQueries({
        queryKey: ['cloud-drive', 'folder', currentFolder, activeDriveId, false]
      });
    }
    
    // Force immediate refetch
    queryClient.refetchQueries({
      queryKey: ['cloud-drive', 'folder', currentFolder]
    }).then(() => {
      // Reset to initial count of visible items after refresh
      setVisibleCount(Math.min(INITIAL_RENDER_COUNT, items.length));
      setIsRefreshing(false);
    });
  }, [
    forceRefreshFolderContents, 
    queryClient, 
    encryptedDrive.isEncryptedMode, 
    encryptedDrive.encryptedDriveId, 
    currentFolder, 
    activeDriveId,
    items.length
  ]);

  // Empty state with loading skeleton
  if (items.length === 0) {
    if (isLoadingMore || isRefreshing) {
      return (
        <div className="p-4">
          <div className="animate-pulse flex items-center mb-6">
            <Skeleton className="h-7 w-7 rounded-full mr-2" />
            <Skeleton className="h-6 w-28" />
          </div>
          
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
            {[...Array(12)].map((_, i) => (
              <Skeleton key={i} className="aspect-square rounded-lg" />
            ))}
          </div>
        </div>
      );
    }
    
    return (
      <div className="flex flex-col items-center justify-center min-h-[200px] p-6 text-center">
        <div className="text-center text-gray-500 dark:text-gray-400 flex flex-col items-center">
          <Info className="h-10 w-10 mb-2 text-gray-400 dark:text-gray-500" />
          <p className="text-sm font-medium">No items to display</p>
          <p className="text-xs mt-1 max-w-md">This folder is empty or the items couldn't be loaded</p>
          <button 
            onClick={handleRefresh}
            className="mt-4 px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white text-sm flex items-center"
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            {t('drive.media.refresh') || 'Refresh'}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={parentRef}
      className={cn(
        "relative w-full",
        isDragging && "select-none",
        className
      )}
    >
      {/* Select All Header with modern checkbox */}
      <div className={cn(
        "sticky top-0 z-10",
        "bg-white/95 dark:bg-gray-900/95",
        "backdrop-blur-sm border-b",
        "border-gray-100 dark:border-gray-800",
        "px-4 py-2"
      )}>
        <div className="flex items-center justify-between text-sm text-gray-500 dark:text-gray-400">
          <div className="flex items-center">
            <ItemCheckbox
              checked={items.length > 0 && items.every(item => selectedItems.includes(item.id))}
              isSelectMode={selectedItems.length > 0}
              onChange={(checked: boolean) => handleSelectAll(checked, {} as React.MouseEvent)}
              variant="modern"
              absolute={false}
              className="relative mr-2"
            />
            <span>
              {selectedItems.length > 0 ? `${selectedItems.length} selected` : 'Select all'}
            </span>
          </div>
          
          {/* Stats and refresh button */}
          <div className="flex items-center space-x-4">
            <span className="text-xs">{totalItemCount !== undefined ? totalItemCount : items.length} {t('drive.media.items')}</span>
            
            <button
              onClick={handleRefresh}
              disabled={isRefreshing}
              className={cn(
                "p-1.5 rounded-full",
                "text-gray-500 hover:text-blue-500 hover:bg-blue-50 dark:hover:bg-gray-800",
                "transition-colors",
                isRefreshing && "animate-spin text-blue-500"
              )}
              title={t('drive.media.refresh') || 'Refresh'}
            >
              <RefreshCw className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>

      <div className="p-4 w-full">
        {/* Grid Container - With optimized rendering */}
        <div
          className={cn(
            "grid-container grid",
            "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6",
            "gap-4 auto-rows-[minmax(200px,auto)]"
          )}
        >
          {staggeredVisibleItems.map((item, index) => (
            <motion.div
              key={item.id}
              layout={false} // Disable layout animations for better performance with large collections
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ 
                duration: 0.15, 
                delay: index < 20 ? index * 0.03 : 0 // Fast animation delays, limited to first 20
              }}
              className={cn(
                "relative group file-grid-item",
                "rounded-lg overflow-hidden",
                "w-full h-full",
                isDragging && selectedItems.includes(item.id) && "opacity-50"
              )}
            >
              <FileItem
                item={item}
                items={items}
                view="grid"
                isSelected={selectedItems.includes(item.id)}
                isSelectMode={isSelectMode}
                onSelect={handleSelect}
                onOpen={handleItemOpen}
                animationDelay={(item as any)._decryptDelay}
              />
            </motion.div>
          ))}
          
          {/* Virtual items observer - for internal pagination */}
          {items.length > visibleCount && (
            <div 
              ref={observerRef}
              className="col-span-full h-20 flex items-center justify-center"
            >
              <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
            </div>
          )}
        </div>

        {/* Loading More Indicator - for API pagination */}
        {(hasNextPage || isLoadingMore) && (
          <div 
            ref={loadMoreRef}
            className={cn(
              "flex items-center justify-center",
              "py-4 mt-4",
              "text-gray-500 dark:text-gray-400"
            )}
          >
            {isLoadingMore && (
              <div className="flex items-center gap-2">
                <Loader2 className="h-5 w-5 animate-spin" />
                <span className="text-sm font-medium">Loading more...</span>
              </div>
            )}
          </div>
        )}
        
        {/* Render time debug info (only in dev) */}
        {process.env.NODE_ENV === 'development' && renderTime && (
          <div className="fixed bottom-2 right-2 bg-black/80 text-white text-xs p-1 rounded">
            Render: {renderTime.toFixed(2)}ms | Items: {visibleCount}/{items.length}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileGrid;