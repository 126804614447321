/**
 * @file src/components/upload/GlobalUploadQueue/UploadQueueFooter.tsx
 * @description Upload queue footer with enhanced drive handling
 * @version 1.2.1
 */

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Trash2, Upload } from 'lucide-react';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';

import { useTranslation } from 'react-i18next';
import { logger } from '@/utils/logger';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { useNavigation } from '@/context/NavigationContext';
import { useUploadContext } from '@/context/UploadContext';

interface UploadQueueFooterProps {
  fileInputRef: React.RefObject<HTMLInputElement>;
}

export const UploadQueueFooter: React.FC<UploadQueueFooterProps> = ({
  fileInputRef
}) => {
  // const { t } = useTranslation();
  const { queue, clearCompleted } = useUploadContext();
  const { activeDriveId, primaryDriveId } = useRootDrive();  // Use RootDrive
  const { currentFolderId } = useNavigation();  // Get current folder ID

  const handleFileClick = () => {
    const effectiveDriveId = activeDriveId || primaryDriveId;
    logger.debug('Add files button clicked', {
      component: 'UploadQueueFooter',
      action: 'addFiles',
      data: { 
        activeDriveId,
        primaryDriveId,
        effectiveDriveId,
        currentFolderId: currentFolderId || ''
      }
    });

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const completedCount = queue.filter((f: any) => 
    f.status === 'completed' || f.status === 'error'
  ).length;

  return (
    <div className={cn(
      "flex items-center justify-between",
      "px-4 h-16",
      "border-t border-gray-200 dark:border-gray-800",
      "bg-white dark:bg-gray-900",
      "z-20" // Ensure footer stays above overlay
    )}>
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="sm"
          onClick={handleFileClick}
          className="text-blue-500 hover:text-blue-600"
        >
          <Upload className="h-4 w-4 mr-2" />
          Add Files
        </Button>

        <AnimatePresence mode="wait">
          {completedCount > 0 && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.2 }}
            >
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  logger.debug('Clear completed clicked', {
                    component: 'UploadQueueFooter',
                    action: 'clearCompleted'
                  });
                  clearCompleted();
                }}
                className="text-gray-500 hover:text-gray-600"
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Clear Completed
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};