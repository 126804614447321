/**
 * @file src/components/layout/MainLayout.tsx
 * @description Main application layout with navigation
 * @version 1.4.0
 * @created 2024-12-05
 */

import React from 'react';
import { Outlet } from 'react-router-dom';
import { cn } from '@/utils/utils';
import Sidebar from './sidebar';
import { GlobalUploadQueue } from '@/components/upload/GlobalUploadQueue';
import { UploadDebugPanel } from '@/components/debug/UploadDebugPanel'; // Add this import

const MainLayout: React.FC = () => {
  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <Sidebar />
      <main className={cn(
        "flex-1",
        "overflow-x-hidden overflow-y-auto",
        "bg-gray-50 dark:bg-gray-900"
      )}>
        <Outlet />
      </main>
      <GlobalUploadQueue />
      {process.env.NODE_ENV === 'development' && <UploadDebugPanel />} {/* Add debug panel */}
    </div>
  );
};

export default MainLayout;