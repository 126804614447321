/**
 * @file src/layouts/ghost/components/sidebar/components/CoreListItem.tsx
 * @description Individual core item component for sidebar with performance improvements
 * @version 1.2.0
 */

import React, { useMemo, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Lock } from 'lucide-react';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { DRIVE_STATUS_CHANGED_EVENT } from '@/services/encrypted-drive/encryption-singleton';
import { 
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/app/ghost/components/common/GhostTooltip';
import type { EncryptedDrive } from '@/types/encrypted-drive.types';

interface CoreListItemProps {
  drive: EncryptedDrive;
  isActive: boolean;
  isCollapsed: boolean;
  onClick: () => void;
  updateTooltip?: (id: string, element: HTMLDivElement | null) => void;
}

export const CoreListItem = React.memo(React.forwardRef<HTMLDivElement, CoreListItemProps>(
  ({ drive, isActive, isCollapsed, onClick, updateTooltip }, ref) => {
    const { t } = useGhostTranslation();
    const [forceUpdate, setForceUpdate] = useState<number>(0); // State to force re-renders
    
    // Listen for drive status change events to update UI
    useEffect(() => {
      // Only add event listener if it's for this specific drive
      const handleDriveStatusChange = (event: any) => {
        if (event.detail.driveId === drive.id) {
          console.debug(`[CoreListItem] Status change event for drive ${drive.id}:`, event.detail);
          // Force a re-render to ensure status indicators update correctly
          setForceUpdate(prev => prev + 1);
        }
      };
      
      // Register for drive status change events
      window.addEventListener(DRIVE_STATUS_CHANGED_EVENT, handleDriveStatusChange);
      
      // Clean up the event listener when component unmounts
      return () => {
        window.removeEventListener(DRIVE_STATUS_CHANGED_EVENT, handleDriveStatusChange);
      };
    }, [drive.id]);

    // Memoize status color calculation with enhanced validation for actual unlock state
    const statusColor = useMemo(() => {
      // Get the encryptionService from window (it's exposed globally)
      const encryptionService = (window as any)?.encryptionService;
      
      // Check both the server status and the local encryption service status
      const isLocallyUnlocked = encryptionService?.isDriveUnlocked?.(drive.id) || false;
      
      // Get detailed information about keys for better diagnostics
      const keys = encryptionService?.getDecryptedKeys?.(drive.id);
      const hasCompleteKeys = !!(keys && keys.contentKey && keys.metadataKey);
      
      // Drive is truly unlocked if:
      // 1. Server says it's unlocked (drive.status === 'unlocked')
      // 2. AND the encryption service confirms it's unlocked (isLocallyUnlocked)
      // 3. AND we have complete keys for encryption/decryption
      const isTrulyUnlocked = drive.status === 'unlocked' && isLocallyUnlocked && hasCompleteKeys;
      
      // Log debugging info when there's an inconsistency
      if (drive.status === 'unlocked' && !isTrulyUnlocked) {
        // There's an inconsistency between server and client state
        console.debug(`[CoreListItem] Drive status inconsistency for ${drive.id}:`, {
          serverStatus: drive.status,
          locallyUnlocked: isLocallyUnlocked,
          hasCompleteKeys,
          hasKeys: !!keys,
          hasMetadataKey: keys?.metadataKey ? true : false,
          hasContentKey: keys?.contentKey ? true : false
        });
      }
      
      return isTrulyUnlocked 
        ? 'bg-[hsl(var(--ghost-accent))]' 
        : 'bg-[hsl(var(--ghost-foreground-muted))]';
    }, [drive.status, drive.id, forceUpdate]); // Include forceUpdate to recalculate when needed

    // Memoize encryption type label
    const encryptionLabel = useMemo(() => {
      // Use more specific translation path
      return t(`security.${drive.encryption_tier.toLowerCase()}`, 
               `${drive.encryption_tier} encryption`); // Fallback text
    }, [t, drive.encryption_tier]);

    // Log only on initial render or important prop changes
    React.useEffect(() => {
      logger.debug('Rendering CoreListItem', {
        driveId: drive.id,
        driveName: drive.name,
        status: drive.status
      });
    }, [drive.id, drive.status, drive.name]);

    const itemContent = useMemo(() => (
      <motion.div
        whileHover={{ x: 2 }}
        whileTap={{ scale: 0.98 }}
        className={cn(
          "w-full group cursor-pointer",
          "px-3 py-2.5",
          "rounded-lg",
          "transition-all duration-200 ease-in-out",
          "active:bg-[hsl(var(--ghost-surface))]/70",
          isActive 
            ? "bg-[hsl(var(--ghost-surface))]"
            : "hover:bg-[hsl(var(--ghost-surface))]/50",
          "touch-manipulation"
        )}
        onClick={onClick}
      >
        <div className="flex items-center min-w-0">
          <div className={cn(
            "relative flex-shrink-0",
            "flex items-center justify-center",
            "h-8 w-8",
            "rounded-lg",
            "bg-[hsl(var(--ghost-surface))]",
            "group-hover:bg-[hsl(var(--ghost-accent))]/0.1",
            "transition-all duration-200"
          )}>
            <Lock 
              className={cn(
                "h-4 w-4",
                isActive
                  ? "text-[hsl(var(--ghost-accent))]"
                  : "text-[hsl(var(--ghost-foreground-muted))]",
                "group-hover:text-[hsl(var(--ghost-accent))]",
                "transition-colors duration-200"
              )}
            />
            <div className={cn(
              "absolute top-1 right-1",
              "h-1.5 w-1.5 rounded-full",
              statusColor
            )} />
          </div>

          <AnimatePresence mode="wait">
            {!isCollapsed && (
              <motion.div
                initial={{ opacity: 0, x: -5 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -5 }}
                className="min-w-0 flex-1 ml-3"
              >
                <div className={cn(
                  "text-sm font-medium truncate leading-tight",
                  "break-words",
                  isActive 
                    ? "text-[hsl(var(--ghost-accent))]"
                    : "text-[hsl(var(--ghost-foreground))]",
                  "group-hover:text-[hsl(var(--ghost-accent))]",
                  "transition-colors duration-200"
                )}>
                  {drive.name}
                </div>
                <div className="text-[10px] text-[hsl(var(--ghost-foreground-muted)] truncate mt-0.5">
                  {encryptionLabel as React.ReactNode}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    ), [isCollapsed, isActive, onClick, drive.name, statusColor, encryptionLabel]);

    if (isCollapsed) {
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <div ref={ref} onMouseEnter={() => {
              if (updateTooltip && ref && 'current' in ref && ref.current) {
                updateTooltip(drive.id, ref.current);
              }
            }}>
              {itemContent}
            </div>
          </TooltipTrigger>
          <TooltipContent side="right" className="flex flex-col gap-1">
            <span className="font-medium">{drive.name}</span>
            <span className="text-xs opacity-75">
              {encryptionLabel as React.ReactNode}
            </span>
            <span className="text-xs opacity-75">
              {t(`status.${drive.status}`) as React.ReactNode}
            </span>
          </TooltipContent>
        </Tooltip>
      );
    }

    return (
      <div ref={ref} onMouseEnter={() => {
        if (updateTooltip && ref && 'current' in ref && ref.current) {
          updateTooltip(drive.id, ref.current);
        }
      }}>
        {itemContent}
      </div>
    );
  }
));

CoreListItem.displayName = "CoreListItem";

export default CoreListItem;