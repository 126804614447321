/**
 * @file src/components/cloud-drive/layout/toolbar/components/FilterPopover.tsx
 * @description Filter popover component with advanced filtering options
 * @version 1.0.0
 */

import React from "react";
import { useTranslation } from "react-i18next";
import { Filter } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/utils/utils";
import { logger } from "@/utils/logger";
import type { FilterPopoverProps } from "../types";
import type { DriveItemType } from "@/types/cloud-drive.types";

// Quick fix: cast SelectItem to any so that its children and other props are accepted.
const AnySelectItem = SelectItem as any;
const AnySelectTrigger = SelectTrigger as any;
const AnySelectContent = SelectContent as any;

// Define DateRange type if not exported
interface DateRange {
  from: Date | undefined;
  to: Date | undefined;
}

export const FilterPopover: React.FC<FilterPopoverProps> = ({
  filterConfig,
  onFilterChange,
  onClearFilters,
  activeFiltersCount,
}) => {
  const { t } = useTranslation();

  logger.debug("Rendering FilterPopover", {
    component: "FilterPopover",
    activeFilters: activeFiltersCount,
  } as any);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className={cn("relative", activeFiltersCount > 0 && "text-blue-500")}
        >
          <Filter className="h-4 w-4" />
          {activeFiltersCount > 0 && (
            <Badge
              variant="default"
              className="absolute -top-2 -right-2 h-4 w-4 p-0 flex items-center justify-center"
            >
              {activeFiltersCount}
            </Badge>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80" align="center">
        <div className="space-y-4">
          {/* File Types */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              {t("cloud_drive.filters.types")}
            </label>
            <Select
              value={filterConfig.types?.join(",") || ""}
              onValueChange={(value) =>
                onFilterChange({
                  types: value
                    ? (value.split(",") as DriveItemType[])
                    : undefined,
                })
              }
            >
              <AnySelectTrigger>
                <SelectValue
                  placeholder={t("cloud_drive.filters.select_types")}
                />
              </AnySelectTrigger>
              <AnySelectContent>
                <AnySelectItem value="file,folder">
                  {t("cloud_drive.filters.all")}
                </AnySelectItem>
                <AnySelectItem value="file">
                  {t("cloud_drive.filters.files_only")}
                </AnySelectItem>
                <AnySelectItem value="folder">
                  {t("cloud_drive.filters.folders_only")}
                </AnySelectItem>
              </AnySelectContent>
            </Select>
          </div>

          {/* Date Range */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              {t("cloud_drive.filters.date_range")}
            </label>
            <DateRangePicker
              value={filterConfig.dateRange as DateRange | undefined}
              onChange={(range) => onFilterChange({ dateRange: range as any })}
            />
          </div>

          {/* Additional Filters */}
          <div className="space-y-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="w-full">
                  {t("cloud_drive.filters.more_filters")}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuCheckboxItem
                  checked={filterConfig.shared}
                  onCheckedChange={(checked) =>
                    onFilterChange({ shared: checked })
                  }
                >
                  {t("cloud_drive.filters.shared")}
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={filterConfig.favorite}
                  onCheckedChange={(checked) =>
                    onFilterChange({ favorite: checked })
                  }
                >
                  {t("cloud_drive.filters.favorite")}
                </DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {/* Clear Filters */}
          {activeFiltersCount > 0 && (
            <Button
              variant="ghost"
              size="sm"
              onClick={onClearFilters}
              className="w-full"
            >
              {t("cloud_drive.filters.clear_all")}
            </Button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default FilterPopover;