/**
 * @file src/routes/index.tsx
 * @description Application routing configuration with optimized code splitting and auth route separation
 * @version 2.0.0
 * @created 2024-12-07
 * @updated 2025-04-01
 */

import { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useAuth } from "@/context/AuthContext";

// Layouts
import MainLayout from "@/components/layout/MainLayout";

// Route configurations - these are already optimized
import { accountRoutes } from "./account.routes";
import { aiRoutes } from "./ai.routes";
import { automationRoutes } from "./automation.routes";
import { cloudDriveRoutes } from "./cloud-drive.routes";
import { debugRoutes } from "./debug.routes";
import { ghostRoutes } from "./ghost.routes";
import { mediaRoutes } from "./media.routes";
import { reportsRoutes } from "./reports.routes";
const ResetPassword = lazy(() => import("@/pages/auth/ResetPassword"));

// Error boundaries
import {
  ErrorBoundary,
  RouteErrorBoundary,
} from "@/components/common/ErrorBoundary";

import { NavigationProvider } from '@/context/NavigationContext';
import { dataRoutes } from './data.routes';
import { encryptedDriveRoutes } from './encrypted-drive.routes';
import { auditRoutes } from "./audit.routes";

// Lazy load page components
const Dashboard = lazy(() => import("@/pages/Dashboard"));
const SignIn = lazy(() => import("@/pages/auth/SignIn"));
const SignUp = lazy(() => import("@/pages/auth/SignUp"));
const ForgotPassword = lazy(() => import("@/pages/auth/ForgotPassword"));
const SharedItemPage = lazy(() => import("@/pages/shared/SharedItemPage"));
const Users = lazy(() => import("@/pages/users"));
const Policies = lazy(() => import("@/pages/policies"));
const CreatePolicy = lazy(() => import("@/pages/policies/create"));
const PolicyView = lazy(() => import("@/pages/policies/view/[id]"));
const PolicyEdit = lazy(() => import("@/pages/policies/edit/[id]"));

// Loading component for Suspense fallbacks
const PageLoader = () => (
  <div className="flex items-center justify-center min-h-screen bg-background">
    <div className="flex flex-col items-center space-y-4">
      <div className="h-12 w-12 rounded-full border-4 border-primary/30 border-t-primary animate-spin"></div>
      <p className="text-muted-foreground">Loading...</p>
    </div>
  </div>
);

// Development placeholder with lazy loading
const UnderDevelopment = () => (
  <div className="flex flex-col items-center justify-center min-h-[60vh] text-gray-500 dark:text-gray-400">
    <h2 className="text-2xl font-semibold mb-4">Coming Soon</h2>
    <p>This section is under development</p>
  </div>
);

// Wrap MainLayout with NavigationProvider
const LayoutWithNavigation = () => {
  return (
    <NavigationProvider>
      <MainLayout />
    </NavigationProvider>
  );
};

// Auth Guard
// Auth Guard - Update this function
const AuthGuard = () => {
  const { isAuthenticated, isDevMode } = useAuth();
  const location = useLocation();
  
  // Check if we're in the Ghost UI based on the path
  const isGhostPath = location.pathname.startsWith('/ghost');
  
  // Exclude Ghost auth routes from protection
  const isGhostAuthPath = location.pathname.startsWith('/ghost/auth');
  
  // Only proceed with redirect if:
  // 1. Not authenticated
  // 2. Not in dev mode
  // 3. Not already on an auth path
  if (!isAuthenticated && !isDevMode() && !isGhostAuthPath) {
    // Redirect to appropriate sign-in page based on UI context
    const redirectTo = isGhostPath ? "/ghost/auth/signin" : "/auth/signin";
    
    // Ensure we're not already on the redirect path to avoid loops
    if (location.pathname !== redirectTo) {
      return <Navigate to={redirectTo} state={{ from: location }} replace />;
    }
  }

  return <Outlet />;
};

// Public Route Guard
const PublicRoute = () => {
  const { isAuthenticated, isDevMode } = useAuth();
  const location = useLocation();
  const from = (location.state as any)?.from?.pathname || "/";

  if (isAuthenticated || isDevMode()) {
    return <Navigate to={from} replace />;
  }

  return <Outlet />;
};

// Route configuration
const routes = [
  {
    path: "/",
    element: <AuthGuard />,
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        element: <LayoutWithNavigation />,
        errorElement: <RouteErrorBoundary />,
        children: [
          {
            index: true,
            element: (
              <ErrorBoundary>
                <Suspense fallback={<PageLoader />}>
                  <Dashboard />
                </Suspense>
              </ErrorBoundary>
            ),
            errorElement: <RouteErrorBoundary />,
          },
          ...(dataRoutes ? (Array.isArray(dataRoutes) ? dataRoutes : [dataRoutes]) : []),
          ...(aiRoutes ? (Array.isArray(aiRoutes) ? aiRoutes : [aiRoutes]) : []),
          ...(automationRoutes ? (Array.isArray(automationRoutes) ? automationRoutes : [automationRoutes]) : []),
          ...(cloudDriveRoutes ? (Array.isArray(cloudDriveRoutes) ? cloudDriveRoutes : [cloudDriveRoutes]) : []),
          ...(encryptedDriveRoutes ? [encryptedDriveRoutes] : []),
          ...(mediaRoutes ? (Array.isArray(mediaRoutes) ? mediaRoutes : [mediaRoutes]) : []),
          ...(debugRoutes ? (Array.isArray(debugRoutes) ? debugRoutes : [debugRoutes]) : []),
          ...(reportsRoutes ? (Array.isArray(reportsRoutes) ? reportsRoutes : [reportsRoutes]) : []),
          ...(auditRoutes ? [auditRoutes] : []),
          {
            path: "users",
            element: (
              <Suspense fallback={<PageLoader />}>
                <Users />
              </Suspense>
            ),
          },
          {
            path: "audit",
            element: <UnderDevelopment />,
          },
          {
            path: "upgrade",
            element: <UnderDevelopment />,
          },
          {
            path: "notifications",
            element: <UnderDevelopment />,
          },
          accountRoutes,
          {
            path: "policies",
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<PageLoader />}>
                    <Policies />
                  </Suspense>
                ),
              },
              {
                path: "create",
                element: (
                  <Suspense fallback={<PageLoader />}>
                    <CreatePolicy />
                  </Suspense>
                ),
              },
              {
                path: "view/:id",
                element: (
                  <Suspense fallback={<PageLoader />}>
                    <PolicyView />
                  </Suspense>
                ),
              },
              {
                path: "edit/:id",
                element: (
                  <Suspense fallback={<PageLoader />}>
                    <PolicyEdit />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      ghostRoutes,
    ],
  },

  // Auth Routes
  {
    path: "/auth",
    element: (
      <ErrorBoundary>
        <PublicRoute />
      </ErrorBoundary>
    ),
    children: [
      {
        path: "signin",
        element: (
          <Suspense fallback={<PageLoader />}>
            <SignIn />
          </Suspense>
        ),
      },
      {
        path: "signup",
        element: (
          <Suspense fallback={<PageLoader />}>
            <SignUp />
          </Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={<PageLoader />}>
            <ForgotPassword />
          </Suspense>
        ),
      },
      {
        path: "reset-password",
        element: (
          <Suspense fallback={<PageLoader />}>
            <ResetPassword />
          </Suspense>
        ),
      },
      { index: true, element: <Navigate to="/auth/signin" replace /> },
    ],
  },
  
  // Shared item route - public access, no authentication required
  {
    path: '/s/:shareKey',
    element: (
      <ErrorBoundary>
        <Suspense fallback={<PageLoader />}>
          <SharedItemPage />
        </Suspense>
      </ErrorBoundary>
    ),
  },

  // Compatibility routes for Stripe redirects
  {
    path: "/success",
    element: <Navigate to="/ghost/billing/success" replace />,
  },
  {
    path: "/cancel",
    element: <Navigate to="/ghost/billing/cancel" replace />,
  },
  
  // Catch all
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

/**
 * Helper function to check if the current URL path is an auth page
 */
export const isAuthPage = (): boolean => {
  const path = window.location.pathname;
  return path.includes('/auth/') || path.includes('/ghost/auth/');
};

// Create the browser router instance

export const appRouter = createBrowserRouter(routes);

// Export for compatibility with existing imports
export default appRouter;