/**
 * @file src/components/cloud-drive/views/FavoritesView.tsx
 * @description Enhanced favorites view with fixed UI issues
 * @version 1.3.0
 * @updated 2025-03-21
 */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Clock, RefreshCw } from 'lucide-react';
import { motion } from 'framer-motion';
import { useCloudDrive } from '@/context/cloud-drive';
import { encryptionService } from '@/services/encrypted-drive/encryption-singleton';
import { listEncryptedFavorites } from '@/services/encrypted-drive/encrypted-drive-operations';
import { logger } from '@/utils/logger';
import { cn } from '@/utils/utils';
import { useToast } from '@/components/ui/toast';

// Import UI components
import { Button } from '@/components/ui/button';
import { FileGrid } from '../browser/FileGrid';
import { FileList } from '../browser/FileList';
import { DecryptionStatus } from '@/components/encrypted-drive/status/DecryptionStatus';
import { CloudDriveEmptyState } from '../states/CloudDriveEmptyState';

// Import or define missing types
import type { DriveItem, DriveFile, DriveFolder, DriveItemUser } from '@/types/cloud-drive.types';

// Define missing type
interface EncryptedDriveItem {
  id: string;
  type: string;
  parentId: string;
  encrypted_metadata?: string;
  metadata_iv?: string;
  content_iv?: string;
  media_info?: any;
  favorited_at?: string;
}

export const FavoritesView: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { 
    encryptedDrive, 
    setCurrentFolderContents, 
    viewMode,
    updateDecryptionStatus,
    toggleFavorite
  } = useCloudDrive();
  
  // State for handling items and loading
  const [status, setStatus] = useState<'loading' | 'error' | 'empty' | 'loaded'>('loading');
  const [error, setError] = useState<string | null>(null);
  const [items, setItems] = useState<DriveItem[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  // Refs to prevent race conditions
  const isFetchingRef = useRef(false);
  const mountedRef = useRef(true);
  const retryCountRef = useRef(0);
  
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  
  const fetchFavorites = useCallback(async (cursor?: string) => {
    // Prevent duplicate fetches
    if (isFetchingRef.current) return;
    
    try {
      isFetchingRef.current = true;
      
      if (!cursor) {
        // Only set loading state on initial load or refresh, not when loading more
        if (!isRefreshing) {
          setStatus('loading');
        }
      }
      
      // Check if we have a drive ID
      if (!encryptedDrive.encryptedDriveId) {
        setError(t('cloud_drive.favorites.no_drive_selected'));
        setStatus('error');
        return;
      }
      
      logger.debug('Fetching encrypted favorites', {
        component: 'FavoritesView',
        data: { 
          driveId: encryptedDrive.encryptedDriveId,
          isEncryptedMode: encryptedDrive.isEncryptedMode,
          cursor 
        }
      });
      
      // Fix error: Expected 3 arguments, but got 2
      // Using 50 as a default page size (assuming the third parameter is pageSize)
      const result = await listEncryptedFavorites(
        encryptedDrive.encryptedDriveId,
        cursor,
        50
      );
      
      // Get encryption keys
      const keys = encryptionService.getDecryptedKeys(encryptedDrive.encryptedDriveId);
      if (!keys) {
        throw new Error(t('cloud_drive.errors.drive_locked'));
      }
      
      logger.debug('API returned favorites', {
        component: 'FavoritesView',
        data: { 
          itemCount: result.items.length,
          total: result.total,
          hasMore: result.hasMore
        }
      });
      
      // Important: Check for empty results early
      if (result.items.length === 0 && !cursor) {
        console.log('No favorites found - showing empty state');
        setStatus('empty');
        setItems([]);
        setCurrentFolderContents([]);
        setIsRefreshing(false);
        return;
      }
      
      // If we have items, process them
      if (result.items.length > 0) {
        // Start decryption
        updateDecryptionStatus({
          isDecrypting: true,
          progress: 0,
          completedItems: 0,
          totalItems: result.items.length
        });
        
        // Process each item
        const decryptedItems = await Promise.all(
          result.items.map(async (item: EncryptedDriveItem, index: number) => {
            try {
              // Fix error: Argument of type '{}' is not assignable to parameter of type 'number'
              // Providing the parameters in correct format based on the encryption service
              const decryptedMetadata = await encryptionService.decryptMetadata(
                item.encrypted_metadata || '',
                item.metadata_iv || '',
                keys.metadataKey
              );
              
              let metadata;
              if (typeof decryptedMetadata === 'string') {
                try {
                  metadata = JSON.parse(decryptedMetadata);
                } catch (parseError) {
                  logger.error('Error parsing decrypted metadata', {
                    component: 'FavoritesView',
                    error: parseError
                  });
                  throw new Error('Invalid metadata format');
                }
              } else {
                metadata = decryptedMetadata;
              }
              
              // Update decrypt progress
              updateDecryptionStatus({
                progress: ((index + 1) / result.items.length) * 100,
                completedItems: index + 1,
                totalItems: result.items.length
              });
              
              // Return formatted item with enhanced properties for thumbnail display
              const fileExt = (metadata.name || '').toLowerCase().split('.').pop() || '';
              const isImageByExt = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp'].includes(fileExt);
              const isVideoByExt = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv'].includes(fileExt);
              
              // Ensure we have proper media type detection
              let mediaType = metadata.mediaType;
              if (!mediaType) {
                if (metadata.contentType?.startsWith('image/') || isImageByExt) {
                  mediaType = 'image';
                } else if (metadata.contentType?.startsWith('video/') || isVideoByExt) {
                  mediaType = 'video';
                }
              }
              
              // Prepare media info if not already present
              let mediaInfo = item.media_info || metadata.media_info;
              
              // Create a placeholder media_info if needed for images/videos
              if (!mediaInfo && (isImageByExt || isVideoByExt)) {
                mediaInfo = {
                  type: isImageByExt ? 'image' : 'video',
                  thumbnails: {
                    small: {
                      width: 200,
                      height: 200
                    }
                  },
                  has_thumbnails: true
                };
              }
              
              return {
                id: item.id,
                name: metadata.name || '[Unnamed]',
                type: item.type,
                parentId: item.parentId,
                path: [],
                created: metadata.created || new Date().toISOString(),
                modified: metadata.modified || new Date().toISOString(),
                createdBy: {
                  id: 'me',
                  name: 'me',
                  email: 'me'
                } as DriveItemUser,
                modifiedBy: {
                  id: 'me',
                  name: 'me',
                  email: 'me'
                } as DriveItemUser,
                shared: metadata.shared || false,
                favorite: true,
                labels: [],
                favorited_at: item.favorited_at,
                size: metadata.size || 0,
                extension: fileExt || metadata.extension || '',
                contentType: metadata.contentType || (isImageByExt ? 'image/' + fileExt : isVideoByExt ? 'video/' + fileExt : ''),
                thumbnail: metadata.thumbnail,
                
                // Important: Additional fields needed for proper thumbnail display
                mimeType: metadata.mimeType || metadata.contentType || (isImageByExt ? 'image/' + fileExt : isVideoByExt ? 'video/' + fileExt : ''),
                mediaType: mediaType,
                hasThumbnail: true,  // Force to true for proper icon display
                hasMediaInfo: !!(mediaInfo),
                
                // Add enhanced media_info
                media_info: mediaInfo,
                mediaInfo: mediaInfo, // Add both formats to be safe
                
                // For encrypted content
                encrypted: true,
                metadata_iv: item.metadata_iv,
                content_iv: item.content_iv
              } as DriveItem;
            } catch (error) {
              logger.error('Error decrypting item metadata', {
                component: 'FavoritesView',
                error,
                data: { itemId: item.id }
              });
              
              // Return placeholder for failed items with corrected types
              return {
                id: item.id,
                name: t('cloud_drive.errors.decryption_failed'),
                type: item.type,
                parentId: item.parentId,
                path: [],
                created: new Date().toISOString(),
                modified: new Date().toISOString(),
                createdBy: {
                  id: 'me',
                  name: 'me',
                  email: 'me'
                } as DriveItemUser,
                modifiedBy: {
                  id: 'me',
                  name: 'me',
                  email: 'me'
                } as DriveItemUser,
                shared: false,
                favorite: true,
                labels: [],
                error: true
              } as unknown as DriveItem;
            }
          })
        );
        
        // Complete decryption status
        updateDecryptionStatus({
          isDecrypting: false,
          progress: 100,
          completedItems: result.items.length,
          totalItems: result.items.length
        });
        
        // Update state based on whether we're appending or replacing
        if (cursor) {
          setItems(prev => [...prev, ...decryptedItems]);
          // Fix for setCurrentFolderContents function
          setCurrentFolderContents([...items, ...decryptedItems]);
        } else {
          setItems(decryptedItems);
          setCurrentFolderContents(decryptedItems);
        }
        
        // Update pagination state
        setHasMore(result.hasMore);
        setNextCursor(result.hasMore ? result.nextCursor : null);
        setStatus('loaded');
      } else if (cursor) {
        // We got no items but were loading more - means we're at the end
        setHasMore(false);
        setNextCursor(null);
      }
      
      // Reset error retry count on success
      retryCountRef.current = 0;
    } catch (error) {
      if (!mountedRef.current) return;
      
      logger.error('Error fetching favorites:', {
        component: 'FavoritesView',
        error,
        data: { 
          driveId: encryptedDrive.encryptedDriveId,
          retryCount: retryCountRef.current
        }
      });
      
      retryCountRef.current++;
      setError(error instanceof Error ? error.message : String(error));
      setStatus('error');
      
      // Show toast only on initial error, not on load more errors
      if (!cursor) {
        showToast(t('cloud_drive.favorites.fetch_error'), 'error');
      }
      
      // Reset decryption status on error
      updateDecryptionStatus({
        isDecrypting: false,
        progress: 0,
        completedItems: 0,
        totalItems: 0
      });
    } finally {
      if (mountedRef.current) {
        setIsRefreshing(false);
      }
      
      // Reset the fetching flag to allow future fetches
      setTimeout(() => {
        if (mountedRef.current) {
          isFetchingRef.current = false;
        }
      }, 500);
    }
  }, [
    encryptedDrive.encryptedDriveId,
    encryptedDrive.isEncryptedMode,
    isRefreshing,
    t,
    updateDecryptionStatus,
    showToast,
    setCurrentFolderContents,
    items
  ]);
  
  // Fetch favorites when view mounts - with lazy loading
  useEffect(() => {
    console.log('FavoritesView mounted - lazy loading favorites');
    
    // Use a small delay for lazy loading to allow the UI to load first
    const timer = setTimeout(() => {
      if (mountedRef.current && !isFetchingRef.current) {
        fetchFavorites();
      }
    }, 500); // Small delay to reduce contention with other API calls
    
    return () => {
      clearTimeout(timer);
      mountedRef.current = false;
    };
  }, [fetchFavorites]);
  
  // Handle load more
  const handleLoadMore = useCallback(() => {
    if (hasMore && nextCursor && !isFetchingRef.current) {
      fetchFavorites(nextCursor);
    }
  }, [hasMore, nextCursor, fetchFavorites]);
  
  // Handle refresh
  const handleRefresh = useCallback(() => {
    if (!isFetchingRef.current) {
      setIsRefreshing(true);
      retryCountRef.current = 0;
      fetchFavorites();
    }
  }, [fetchFavorites]);
  
  // Loading state
  if (status === 'loading') {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-center">
          <Loader className="h-10 w-10 animate-spin text-primary mx-auto mb-4" />
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {t('cloud_drive.favorites.loading')}
          </div>
        </div>
      </div>
    );
  }
  
  // Error state - fixed props for CloudDriveEmptyState 
  if (status === 'error') {
    return (
      <CloudDriveEmptyState 
        type="error"
        customAction={
          <Button variant="outline" onClick={handleRefresh} disabled={isFetchingRef.current}>
            {isRefreshing ? (
              <Loader className="h-4 w-4 animate-spin mr-2" />
            ) : (
              <RefreshCw className="h-4 w-4 mr-2" />
            )}
            {t('common.try_again')}
          </Button>
        }
      />
    );
  }
  
  // Empty state - fixed type
  if (status === 'empty') {
    return (
      <CloudDriveEmptyState 
        type="favorites"
        customAction={
          <Button variant="outline" onClick={handleRefresh} disabled={isFetchingRef.current}>
            {isRefreshing ? (
              <Loader className="h-4 w-4 animate-spin mr-2" />
            ) : (
              <RefreshCw className="h-4 w-4 mr-2" />
            )}
            {t('common.refresh')}
          </Button>
        }
      />
    );
  }
  
  // Loaded state with content
  return (
    <div className={cn("h-full relative", className)}>
      {/* Decryption Status Indicator */}
      {encryptedDrive.isEncryptedMode && (
        <div className="absolute top-2 right-2 z-50">
          <DecryptionStatus />
        </div>
      )}
      
      {/* Main content - no extra title, uses the same components as normal browsing */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="h-full"
      >
        {viewMode === 'grid' ? (
          <FileGrid
            items={items as (DriveFile | DriveFolder)[]}
            hasNextPage={hasMore && items.length > 0}
            onLoadMore={handleLoadMore}
            isLoadingMore={isFetchingRef.current && nextCursor !== null}
            className="h-full"
          />
        ) : (
          <FileList
            items={items as (DriveFile | DriveFolder)[]}
            hasNextPage={hasMore && items.length > 0}
            onLoadMore={handleLoadMore}
            isLoadingMore={isFetchingRef.current && nextCursor !== null}
            className="h-full"
          />
        )}
      </motion.div>
    </div>
  );
};

export default FavoritesView;