/**
 * @file src/components/cloud-drive/preview/types/ImagePreview.tsx
 * @description Image preview component with zoom and pan support
 * @version 1.0.0
 * @created 2024-12-21
 */

import React, { useState } from 'react';
import {motion} from 'framer-motion';
import { ZoomIn, ZoomOut, RotateCw } from 'lucide-react';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import type { DriveFile } from '@/types/cloud-drive.types';

interface ImagePreviewProps {
  file: DriveFile;
  onLoad: () => void;
  onError: (error: Error) => void;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  file,
  onLoad,
  onError
}) => {
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleWheel = (e: React.WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
      const delta = e.deltaY * -0.01;
      setScale(prev => Math.min(Math.max(0.1, prev + delta), 5));
    }
  };

  const handleImageLoad = (_e: React.SyntheticEvent<HTMLImageElement>) => {
    // Reset transforms on new image
    setScale(1);
    setRotation(0);
    setPosition({ x: 0, y: 0 });
    onLoad();
  };

  const controls = [
    {
      icon: ZoomIn,
      action: () => setScale(prev => Math.min(prev + 0.25, 5)),
      label: 'Zoom in'
    },
    {
      icon: ZoomOut,
      action: () => setScale(prev => Math.max(prev - 0.25, 0.1)),
      label: 'Zoom out'
    },
    {
      icon: RotateCw,
      action: () => setRotation(prev => (prev + 90) % 360),
      label: 'Rotate'
    }
  ];

  return (
    <div className="w-full h-full relative" onWheel={handleWheel}>
      {/* Controls */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-10 flex items-center space-x-2">
        {controls.map(({ icon: Icon, action, label }) => (
          <Button
            key={label}
            variant="secondary"
            size="sm"
            onClick={action}
            className="bg-black/50 hover:bg-black/70 text-white"
          >
            <Icon className="h-4 w-4" />
            <span className="sr-only">{label}</span>
          </Button>
        ))}
      </div>

      {/* Image Container */}
      <motion.div
        className="w-full h-full flex items-center justify-center"
        drag
        dragMomentum={false}
        dragElastic={0.1}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
        style={{
          cursor: isDragging ? "grabbing" : "grab",
        }}
      >
        <motion.img
          src={(file as any).previewUrl} // Replace with actual preview URL from your file service
          alt={file.name}
          className={cn(
            "max-w-full max-h-full object-contain",
            "select-none pointer-events-none"
          )}
          style={{
            scale,
            rotate: rotation,
            x: position.x,
            y: position.y,
          }}
          onLoad={handleImageLoad}
          onError={() => onError(new Error("Failed to load image"))}
          draggable={false}
        />
      </motion.div>
    </div>
  );
};

/**
 * Adding New Image Features:
 * 1. Add new transform controls by extending the controls array
 * 2. Implement new gesture handlers in handleWheel or add new handlers
 * 3. Add new motion controls to the image component
 * 4. Consider adding image filters, cropping, or other editing features
 */