/**
 * @file src/hooks/useEncryptedFiles.ts
 * @description Hook to connect encrypted files service with context
 * @version 1.2.0
 * @created 2025-02-25
 * @updated 2025-03-21
 */

import { useEffect, useRef, useState, useCallback } from 'react';
import { useCloudDrive } from '@/context/cloud-drive';
import { encryptedFilesService } from '@/services/encrypted-drive/encrypted-files.service';
import { logger } from '@/utils/logger';

export const useEncryptedFiles = () => {
  const { updateDecryptionStatus, encryptedDrive, currentFolderContents } = useCloudDrive();
  const previousStatusRef = useRef<any>(null);
  const [decryptionComplete, setDecryptionComplete] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const folderContentsCountRef = useRef(currentFolderContents?.length || 0);
  const isFirstRender = useRef(true);
  
  // Store folder contents count in ref to stabilize dependencies
  useEffect(() => {
    folderContentsCountRef.current = currentFolderContents?.length || 0;
  }, [currentFolderContents]);
  
  // Memoize the status update function
  const handleStatusUpdate = useCallback(() => {
    const currentStatus = encryptedFilesService.decryptionStatus;
    
    // Only update state if status has actually changed
    if (JSON.stringify(previousStatusRef.current) !== JSON.stringify(currentStatus)) {
      logger.debug('Decryption status changed', {
        component: 'useEncryptedFiles',
        from: previousStatusRef.current,
        to: currentStatus
      });
      
      previousStatusRef.current = {...currentStatus};
      updateDecryptionStatus(currentStatus);
      
      // Check if decryption is complete
      if (currentStatus.isDecrypting === false && 
          currentStatus.completedItems > 0 && 
          currentStatus.completedItems === currentStatus.totalItems) {
        setDecryptionComplete(true);
        logger.debug('Decryption complete, all items processed', {
          component: 'useEncryptedFiles',
          itemCount: currentStatus.totalItems
        });
      }
    }
  }, [updateDecryptionStatus]);
  
  // Listen for decryption status changes
  useEffect(() => {
    // Skip setup on first render to prevent double initialization
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!encryptedDrive.isEncryptedMode) {
      // Clean up any existing interval when not in encrypted mode
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      return;
    }
    
    logger.debug('Setting up decryption status monitoring', {
      component: 'useEncryptedFiles',
      isEncryptedMode: encryptedDrive.isEncryptedMode,
      folderContentsCount: folderContentsCountRef.current
    });
    
    // Clean up any existing interval before creating a new one
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    
    // Longer interval to reduce rendering frequency
    intervalRef.current = setInterval(handleStatusUpdate, 500); 
    
    return () => {
      logger.debug('Cleaning up decryption status monitoring', {
        component: 'useEncryptedFiles'
      });
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      setDecryptionComplete(false);
    };
  }, [encryptedDrive.isEncryptedMode, handleStatusUpdate]);
  
  // Force a refresh of the UI when decryption is complete
  useEffect(() => {
    if (decryptionComplete && folderContentsCountRef.current > 0) {
      // Wait a short time to ensure all animations have completed
      const refreshTimer = setTimeout(() => {
        logger.debug('Refreshing UI after decryption completion', {
          component: 'useEncryptedFiles',
          itemCount: folderContentsCountRef.current
        });
        
        // This will trigger a re-render with the fully decrypted content
        updateDecryptionStatus({
          isDecrypting: false,
          progress: 100,
          completedItems: 0,
          totalItems: 0
        });
        
        setDecryptionComplete(false);
      }, 1500); // Wait for animations to complete
      
      return () => clearTimeout(refreshTimer);
    }
  }, [decryptionComplete, updateDecryptionStatus]);
  
  return {
    clearCache: encryptedFilesService.clearCache,
    isDecryptionComplete: decryptionComplete
  };
};