/**
 * @file: components/media/MediaViewSkeleton.tsx
 * @description: Skeleton loading component for media view
 * @version: 1.0.0
 */

import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';

interface MediaViewSkeletonProps {
  itemCount?: number;
  columns?: number;
}

export const MediaViewSkeleton: React.FC<MediaViewSkeletonProps> = ({
  itemCount = 12,
  columns = 4
}) => {
  // Create an array of skeleton items
  const items = Array.from({ length: itemCount }, (_, i) => i);

  return (
    <div className="grid gap-4" style={{ 
      gridTemplateColumns: `repeat(${columns}, 1fr)` 
    }}>
      {items.map((item) => (
        <div key={item} className="flex flex-col space-y-2">
          {/* Image skeleton */}
          <Skeleton className="w-full h-48 rounded-md" />
          
          {/* Title skeleton */}
          <Skeleton className="h-4 w-3/4" />
          
          {/* Info skeleton */}
          <div className="flex justify-between">
            <Skeleton className="h-3 w-1/3" />
            <Skeleton className="h-3 w-1/4" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MediaViewSkeleton;