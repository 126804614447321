/**
 * @file src/components/audit/filters/ActionFilter.tsx
 * @description Action filter component for audit reports
 * @version 1.2.0
 * @created 2024-02-24
 * @updated 2025-02-26
 */

import React from "react";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ActionType } from "@/types/audit.types"; // Make sure this is imported as a value not a type

interface ActionFilterProps {
  value: ActionType | undefined;
  onChange: (value: ActionType | undefined) => void;
  disabled?: boolean;
}

export const ActionFilter: React.FC<ActionFilterProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const handleChange = (newValue: string) => {
    console.log("🔍 Action filter selected:", newValue);
    if (newValue === "all") {
      onChange(undefined);
    } else {
      onChange(newValue as ActionType);
    }
  };

  return (
    <Select
      value={value || "all"} // Use "all" instead of empty string
      onValueChange={handleChange}
      disabled={disabled}
    >
      <SelectTrigger className="w-[210px]">
        <SelectValue
          placeholder={t("audit.filters.actionPlaceholder", "All actions")}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">
          {t("audit.filters.allActions", "All actions")}
        </SelectItem>
        {/* Convert Object.values to array to ensure we can map over it */}
        {Object.keys(ActionType).map((key) => (
          <SelectItem
            key={key}
            value={ActionType[key as keyof typeof ActionType]}
          >
            {ActionType[key as keyof typeof ActionType]}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default ActionFilter;
