/**
 * @file src/config/animation.config.ts
 * @description Global animation configuration settings
 * @version 1.0.0
 */

export const ANIMATION_CONFIG = {
    // Transitions
    transition: {
      default: {
        duration: 0.2,
        ease: 'easeInOut'
      },
      slow: {
        duration: 0.3,
        ease: 'easeInOut'
      },
      fast: {
        duration: 0.15,
        ease: 'easeInOut'
      }
    },
  
    // Common animations
    variants: {
      fadeIn: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
      },
      slideUp: {
        initial: { y: 20, opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: 20, opacity: 0 }
      },
      slideDown: {
        initial: { y: -20, opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: -20, opacity: 0 }
      },
      scale: {
        initial: { scale: 0.95, opacity: 0 },
        animate: { scale: 1, opacity: 1 },
        exit: { scale: 0.95, opacity: 0 }
      },
      minimize: {
        initial: { height: 400 },
        animate: { height: 64 },
        exit: { height: 0 }
      },
      maximize: {
        initial: { height: 64 },
        animate: { height: 400 },
        exit: { height: 64 }
      }
    },
  
    // Spring configurations
    spring: {
      gentle: {
        type: "spring",
        stiffness: 100,
        damping: 15
      },
      bounce: {
        type: "spring",
        stiffness: 200,
        damping: 10
      }
    }
  } as const;
  
  // Types
  export type AnimationVariant = keyof typeof ANIMATION_CONFIG.variants;
  export type TransitionType = keyof typeof ANIMATION_CONFIG.transition;
  export type SpringType = keyof typeof ANIMATION_CONFIG.spring;