/**
 * @file src/routes/reports.routes.tsx
 * @description Reports routing configuration
 * @version 1.0.0
 * @created 2024-01-27
 */

import React from 'react';
import { lazy } from "react";

import { ReportsProvider } from '@/providers/ReportsProvider';
import ReportsPage from '@/pages/reports';

// Lazy load report type pages
const SmartReportsPage = React.lazy(() => import('@/pages/reports/smart'));
const BillingReportsPage = React.lazy(() => import('@/pages/reports/billing'));
const AlertsReportPage = React.lazy(() => import('@/pages/reports/alerts'));
const ActivityPage = React.lazy(() => import('@/pages/reports/activity'));
const DeviceBuildsPage = React.lazy(() => import('@/pages/reports/device-builds'));
const BandwidthPage = React.lazy(() => import('@/pages/reports/bandwidth')); 
const ClientLocatorPage = lazy(() => import('@/pages/reports/client-locator'));
const FailedBackupsPage = lazy(() => import("@/pages/reports/failed-backups"));
const DeploymentErrorsPage = lazy(() => import("@/pages/reports/deployment"));

export const reportsRoutes = {
  path: 'reports',
  element: (
    <ReportsProvider>
      <React.Suspense fallback={<div>Loading...</div>}>
        <ReportsPage />
      </React.Suspense>
    </ReportsProvider>
  ),
  children: [
    {
      path: 'smart',
      element: <SmartReportsPage />
    },
    {
      path: 'billing',
      element: <BillingReportsPage />
    },
    {
      path: 'alerts',
      element: <AlertsReportPage />
    },
    {
      path: 'activity',
      element: <ActivityPage />
    },
    {
      path: 'device-builds',
      element: <DeviceBuildsPage />
    },
    {
      path: 'bandwidth',  // Add this route
      element: <BandwidthPage />
    },
    {
    path: 'client-locator',
    element: (
      <React.Suspense 
        fallback={
          <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6 flex items-center justify-center">
            <div className="text-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mb-2" />
              <div className="text-sm text-gray-500 dark:text-gray-400">
                Loading client locator...
              </div>
            </div>
          </div>
        }
      >
        <ClientLocatorPage />
      </React.Suspense>
    )
    },
    {
      path: 'failed-backups',
      element: <FailedBackupsPage />,
    },
    {
      path: 'deployment',
      element: <DeploymentErrorsPage />,
    },
  ]
};