/**
 * @file src/components/cloud-drive/layout/toolbar/components/ViewSwitcher.tsx
 * @description Enhanced view switcher with space-efficient design and favorites support
 * @version 1.2.0
 * @updated 2025-03-16
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutGrid, List, Image, Share2, Star } from 'lucide-react';
import { cn } from '@/utils/utils';
import { useCloudDrive } from '@/context/cloud-drive';
import { logger } from '@/utils/logger';
import type { ViewDisplayType } from '@/context/cloud-drive/types'; // Import from types instead of local definition

interface ViewSwitcherProps {
  className?: string;
}

export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({ className }) => {
  const { t } = useTranslation();
  const { viewMode, setViewMode, viewDisplay, setViewDisplay, encryptedDrive } = useCloudDrive();
  
  // Log the current state
  React.useEffect(() => {
    logger.debug('ViewSwitcher current state', {
      component: 'ViewSwitcher',
      data: {
        viewMode,
        viewDisplay,
        isEncryptedMode: encryptedDrive?.isEncryptedMode
      }
    });
  }, [viewMode, viewDisplay, encryptedDrive]);
  
  // Sync view modes when needed
  React.useEffect(() => {
    // We only need to synchronize when viewMode changes
    // and only for the list/grid modes - NEVER change media or shared back
    if (viewDisplay === 'list' || viewDisplay === 'grid') {
      if (viewMode !== viewDisplay) {
        console.log(`Syncing viewDisplay from ${viewDisplay} to match viewMode ${viewMode}`);
        setViewDisplay(viewMode);
      }
    }
  }, [viewMode, viewDisplay, setViewDisplay]);
  
  // Handle view change with logging and navigation
  const handleViewChange = (view: ViewDisplayType) => {
    // Add console log for immediate visibility in the browser
    console.log(`ViewSwitcher: Changing view from ${viewDisplay} to ${view}`);
    
    logger.debug('View change requested', {
      component: 'ViewSwitcher',
      previousView: viewDisplay,
      newView: view,
      isEncryptedMode: encryptedDrive?.isEncryptedMode
    });
    
    // First set the view display state
    setViewDisplay(view);
    
    // Update viewMode for list/grid views to ensure compatibility
    if (view === 'list' || view === 'grid') {
      setViewMode(view);
    } else {
      // IMPORTANT: Log when we're NOT updating viewMode
      console.log(`Not updating viewMode for non-list/grid view: ${view}`);
      
      // Temporarily modify media view to use the grid view instead of the dedicated media page
      // This will be reverted once the media index work is done
      if (view === 'media') {
        // Change view to grid for now
        console.log("Media view temporarily redirected to grid view");
        setViewDisplay('grid');
        setViewMode('grid');
        
        // Set a flag so we know we redirected from media view
        localStorage.setItem('media_view_redirected', 'true');
        
        // Force refresh to show all items
        try {
          // @ts-ignore - Access CloudDriveContext's exposed methods
          if (window._cloudDriveContext?.forceRefreshFolderContents) {
            logger.debug('Forcing folder refresh after media redirection', {
              component: 'ViewSwitcher'
            });
            setTimeout(() => {
              // @ts-ignore
              window._cloudDriveContext.forceRefreshFolderContents();
            }, 50); // Small delay to ensure state updates first
          }
        } catch (error) {
          logger.warn('Error refreshing folder contents', { 
            component: 'ViewSwitcher',
            error 
          });
        }
      }
    }
  };

  // Define view options
  const viewOptions = [
    {
      id: 'list',
      icon: List,
      label: t('drive.views.list'),
      alwaysShow: true
    },
    {
      id: 'grid',
      icon: LayoutGrid,
      label: t('drive.views.grid'),
      alwaysShow: true
    },
    {
      id: 'media',
      icon: Image,
      label: t('drive.views.media'),
      alwaysShow: false, // Only show for encrypted drive
    },
    {
      id: 'favorites',
      icon: Star,
      label: t('drive.views.favorites'),
      alwaysShow: true
    },
    {
      id: 'shared',
      icon: Share2,
      label: t('drive.views.shared'),
      alwaysShow: false, // Only show for encrypted drive
    }
  ];
  
  return (
    <div className={cn(
      "flex items-center justify-center space-x-1 bg-gray-100 dark:bg-gray-800 rounded-lg p-1",
      encryptedDrive?.isEncryptedMode && "bg-blue-50/30 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800/30",
      className
    )}>
      {viewOptions.map(option => {
        // Only show if always visible or we're in encrypted mode
        if (!option.alwaysShow && !encryptedDrive?.isEncryptedMode) {
          return null;
        }
        
        const isActive = viewDisplay === option.id;
        const Icon = option.icon;
        
        return (
          <button
            key={option.id}
            onClick={() => handleViewChange(option.id as ViewDisplayType)}
            className={cn(
              "flex items-center rounded transition-all duration-200",
              "px-3 py-1.5 text-sm",
              isActive 
                ? "bg-white dark:bg-gray-700 shadow-sm text-blue-600 dark:text-blue-400"
                : "text-gray-600 dark:text-gray-400 hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
            )}
          >
            <Icon className={cn("h-4 w-4", isActive ? "mr-2" : "mr-0")} />
            {/* Only show text for active item to save space */}
            {isActive && <span>{option.label}</span>}
          </button>
        );
      })}
    </div>
  );
};

export default ViewSwitcher;