/**
 * @file src/types/encrypted-drive.types.ts
 * @description Type definitions for encrypted drive operations
 * @version: 1.0.0
 */

import {KeyGenerationResult} from './encryption.types'


export enum EncryptionTier {
    STANDARD = 'standard',
    PERFORMANCE = 'performance',
    QUANTUM = 'quantum',
    PARANOID = 'paranoid'
}

export interface EncryptedKeys {
    content_key: string;
    metadata_key: string;
    iv: string;
}

export interface RecoveryKeys {
    content_key: string;
    metadata_key: string;
    iv: string;
}

export interface RecoveryData {
    key: string;
    verification_hash: string;
    encrypted_keys: RecoveryKeys;
}

export interface EncryptedDrive {
    id: string;
    name: string;
    encryption_tier: EncryptionTier;
    status: 'locked' | 'unlocked';
    created_at: string;
    last_accessed?: string;
    drive_id: string;
    device_id: string;
    key_salt: string;
    key_verification_hash: string;
    encrypted_keys: EncryptedKeys;
    recovery_enabled: boolean;
    recovery_verification_hash?: string;
    recovery_data?: RecoveryData;
}

export interface UnlockDriveRequest {
    driveId: string;
    // Remove password - zero knowledge
}

export interface CreateDriveRequest {
    name: string;
    device_id: string;  // Add this
    encryption_tier: EncryptionTier;
    encrypted_content_key: string;
    encrypted_metadata_key: string;
    encrypted_keys_iv: string;
    key_salt: string;
    key_verification_hash: string;
    recovery_verification_hash?: string;
    recovery_data?: {
        key: string;
        verification_hash: string;
        encrypted_keys: EncryptedKeys;
    };
}

// Add this near the top with other interfaces
export interface CreateDriveFormData {
    name: string;
    encryptionTier: EncryptionTier;
    password: string;
    confirmPassword: string;
    keyData?: KeyGenerationResult;  // From encryption.types.ts
    deviceId?: string;              // Track system device ID
}