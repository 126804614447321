/**
 * @file src/app/ghost/components/language/GhostLanguageToggle.tsx
 * @description Language toggle button that opens language dialog
 * @version 2.0.0
 */

import React, { useState } from 'react';
import { Globe } from 'lucide-react';
import { cn } from '@/utils/utils';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import GhostLanguageDialog from './GhostLanguageDialog';

interface GhostLanguageToggleProps {
  className?: string;
}

export const GhostLanguageToggle: React.FC<GhostLanguageToggleProps> = ({
  className
}) => {
  const { i18n } = useGhostTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openLanguageDialog = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <button
        onClick={openLanguageDialog}
        className={cn(
          "w-full h-10 rounded-lg",
          "flex items-center justify-center",
          "cursor-pointer",
          "transition-colors duration-150",
          "hover:bg-[hsl(var(--ghost-accent))/0.1]",
          "active:bg-[hsl(var(--ghost-accent))/0.2]",
          className
        )}
      >
        <Globe className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
      </button>

      <GhostLanguageDialog 
        isOpen={isDialogOpen} 
        onClose={() => setIsDialogOpen(false)} 
      />
    </>
  );
};

export default GhostLanguageToggle;