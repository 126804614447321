/**
 * @file src/layouts/ghost/components/common/GhostSkeleton.tsx
 * @description Skeleton loader component with ghost theme
 * @version 1.0.0 
 */

import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/utils/utils';

interface GhostSkeletonProps {
  className?: string;
  count?: number;
}

export const GhostSkeleton: React.FC<GhostSkeletonProps> = ({
  className,
  count = 3
}) => {
  return (
    <div className={cn("space-y-2", className)}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="flex items-center space-x-2 px-2 py-1.5"
        >
          {/* Icon skeleton */}
          <motion.div
            animate={{ opacity: [0.5, 0.7, 0.5] }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className={cn(
              "w-7 h-7 rounded-lg",
              "bg-[hsl(var(--ghost-surface))]"
            )}
          />

          {/* Content skeleton */}
          <div className="flex-1 space-y-2">
            <motion.div
              animate={{ opacity: [0.5, 0.7, 0.5] }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0.1
              }}
              className={cn(
                "h-2.5 w-24 rounded",
                "bg-[hsl(var(--ghost-surface))]"
              )}
            />
            <motion.div
              animate={{ opacity: [0.5, 0.7, 0.5] }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
                delay: 0.2
              }}
              className={cn(
                "h-2 w-16 rounded",
                "bg-[hsl(var(--ghost-surface))]"
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default GhostSkeleton;