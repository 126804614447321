/**
 * @file src/components/encrypted-drive/EmptyState.tsx
 * @description Enhanced empty state component for encrypted drives section with animation
 * @version 1.1.0
 */

import React from 'react';
import { Shield, Lock, Key } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';

interface EmptyStateProps {
    onCreateDrive: () => void; // Make this required since we need it
  }

  export const EmptyState: React.FC<EmptyStateProps> = ({ onCreateDrive }) => {
    const { t } = useTranslation();

  // Animation for the encryption visualization
  const lockAnimation = {
    locked: {
      y: [-2, 2],
      transition: {
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse" as const,
        ease: "easeInOut"
      }
    }
  };

  const encryptionLines = {
    animate: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const line = {
    initial: { opacity: 0, x: -20 },
    animate: {
      opacity: [0, 1, 0],
      x: [-20, 0, 20],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "linear"
      }
    }
  };

  return (
    <div className="h-full flex items-center justify-center p-8">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full text-center space-y-8"
      >
        {/* Animated Encryption Visualization */}
        <motion.div className="relative h-32 mx-auto">
          {/* Background Shield */}
          <motion.div 
            className="absolute inset-0 flex items-center justify-center opacity-10"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
          >
            <Shield className="w-32 h-32 text-blue-500 dark:text-blue-400" />
          </motion.div>

          {/* Animated Lock */}
          <motion.div 
            className="absolute inset-0 flex items-center justify-center"
            variants={lockAnimation}
            animate="locked"
          >
            <Lock className="w-12 h-12 text-blue-500 dark:text-blue-400" />
          </motion.div>

          {/* Encryption Lines */}
          <motion.div
            className="absolute inset-0"
            variants={encryptionLines}
            animate="animate"
          >
            {[...Array(3)].map((_, i) => (
              <motion.div
                key={i}
                className={cn(
                  "absolute top-1/2 left-1/2 h-0.5 w-8",
                  "bg-gradient-to-r from-blue-500 to-transparent",
                  "transform -translate-x-1/2 -translate-y-1/2"
                )}
                variants={line}
                style={{
                  rotate: i * 120,
                  transformOrigin: "center"
                }}
              />
            ))}
          </motion.div>
        </motion.div>

        {/* Content */}
        <div className="space-y-4">
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-2xl font-semibold text-gray-900 dark:text-gray-100"
          >
            {t('encrypted_drive.empty.title', 
              'Secure Your Data with Encrypted Drives')}
          </motion.h3>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-gray-500 dark:text-gray-400 max-w-sm mx-auto"
          >
            {t('encrypted_drive.empty.description',
              'Create an encrypted drive to store your sensitive files with military-grade encryption. Your data stays private and secure.')}
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="pt-4"
          >
            <Button
              onClick={onCreateDrive} // Use the passed handler
              size="lg"
              className="space-x-2"
            >
              <Key className="h-4 w-4" />
              <span>{t('encrypted_drive.create_new', 
                'Create Encrypted Drive')}</span>
            </Button>
          </motion.div>
        </div>

        {/* Features List */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="pt-8 grid grid-cols-1 gap-4 text-sm text-gray-500 dark:text-gray-400"
        >
          {[
            t('encrypted_drive.empty.feature_1', 'Military-grade AES-256 encryption'),
            t('encrypted_drive.empty.feature_2', 'Zero-knowledge privacy'),
            t('encrypted_drive.empty.feature_3', 'Secure file sharing')
          ].map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6 + (index * 0.1) }}
              className="flex items-center justify-center space-x-2"
            >
              <Shield className="h-4 w-4 text-blue-500 dark:text-blue-400" />
              <span>{feature}</span>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};