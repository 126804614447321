/**
 * @file src/components/cloud-drive/loading/LoadingStates.tsx
 * @description Collection of loading state components for cloud drive
 * @version 1.0.0
 * @created 2024-12-22
 */

import React from 'react';
import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { cn } from '@/utils/utils';

// Grid Loading Skeleton
const GridLoadingSkeleton: React.FC<{ count?: number }> = ({ 
  count = 12 
}) => (
  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
    {Array.from({ length: count }).map((_, i) => (
      <div 
        key={i}
        className={cn(
          "aspect-square rounded-lg",
          "bg-gray-100 dark:bg-gray-800",
          "animate-pulse"
        )}
      />
    ))}
  </div>
);

// List Loading Skeleton
const ListLoadingSkeleton: React.FC<{ count?: number }> = ({ 
  count = 10 
}) => (
  <div className="space-y-2 p-4">
    {Array.from({ length: count }).map((_, i) => (
      <div
        key={i}
        className="flex items-center space-x-4 h-16 rounded-lg animate-pulse"
      >
        <div className="w-10 h-10 rounded-lg bg-gray-100 dark:bg-gray-800" />
        <div className="flex-1 space-y-2">
          <div className="h-4 w-1/3 bg-gray-100 dark:bg-gray-800 rounded" />
          <div className="h-3 w-1/4 bg-gray-100 dark:bg-gray-800 rounded" />
        </div>
      </div>
    ))}
  </div>
);

// Centered Spinner
const CenteredSpinner: React.FC<{ text?: string }> = ({ text }) => (
  <div className="flex flex-col items-center justify-center h-full p-8">
    <Loader2 className="h-8 w-8 text-blue-500 animate-spin" />
    {text && (
      <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
        {text}
      </p>
    )}
  </div>
);

// Upload Progress
interface UploadProgressProps {
  progress: number;
  fileName: string;
  speed?: string;
  timeLeft?: string;
}

const UploadProgress: React.FC<UploadProgressProps> = ({
  progress,
  fileName,
  speed,
  timeLeft
}) => (
  <div className="space-y-2">
    <div className="flex items-center justify-between text-sm">
      <span className="font-medium truncate flex-1">{fileName}</span>
      <span className="ml-2">{Math.round(progress)}%</span>
    </div>
    <div className="relative h-2 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
      <motion.div
        className="absolute top-0 left-0 h-full bg-blue-500"
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{ duration: 0.2 }}
      />
    </div>
    {(speed || timeLeft) && (
      <div className="flex items-center justify-between text-xs text-gray-500 dark:text-gray-400">
        {speed && <span>{speed}/s</span>}
        {timeLeft && <span>{timeLeft} remaining</span>}
      </div>
    )}
  </div>
);

// Operation Progress
interface OperationProgressProps {
  operation: string;
  current: number;
  total: number;
  showCancel?: boolean;
  onCancel?: () => void;
}

const OperationProgress: React.FC<OperationProgressProps> = ({
  operation,
  current,
  total,
  showCancel,
  onCancel
}) => {
  const progress = (current / total) * 100;
  
  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-4">
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm font-medium">{operation}</span>
        {showCancel && (
          <button
            onClick={onCancel}
            className="text-sm text-red-500 hover:text-red-600"
          >
            Cancel
          </button>
        )}
      </div>
      <div className="relative h-2 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
        <motion.div
          className="absolute top-0 left-0 h-full bg-blue-500"
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.2 }}
        />
      </div>
      <div className="mt-2 text-xs text-gray-500 dark:text-gray-400">
        {current} of {total} items
      </div>
    </div>
  );
};

// Empty State
interface EmptyStateProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  action?: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  icon,
  title,
  description,
  action
}) => (
  <div className="flex flex-col items-center justify-center h-full p-8 text-center">
    <div className="w-16 h-16 rounded-full bg-gray-100 dark:bg-gray-800 flex items-center justify-center mb-4">
      {icon}
    </div>
    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
      {title}
    </h3>
    <p className="text-sm text-gray-500 dark:text-gray-400 max-w-sm mb-4">
      {description}
    </p>
    {action}
  </div>
);

// Error State
interface ErrorStateProps {
  title: string;
  message: string;
  action?: React.ReactNode;
}

const ErrorState: React.FC<ErrorStateProps> = ({
  title,
  message,
  action
}) => (
  <div className="flex flex-col items-center justify-center h-full p-8 text-center">
    <div className="w-16 h-16 rounded-full bg-red-50 dark:bg-red-900/20 flex items-center justify-center mb-4">
      <svg
        className="w-8 h-8 text-red-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
    </div>
    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
      {title}
    </h3>
    <p className="text-sm text-gray-500 dark:text-gray-400 max-w-sm mb-4">
      {message}
    </p>
    {action}
  </div>
);

export {
    GridLoadingSkeleton,
    ListLoadingSkeleton,
    CenteredSpinner,
    UploadProgress,
    OperationProgress,
    EmptyState,
    ErrorState,
    // Also export the types
    type UploadProgressProps,
    type OperationProgressProps,
    type EmptyStateProps,
    type ErrorStateProps
  };