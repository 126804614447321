/**
 * @file src/components/devices/AddDeviceButton.tsx
 * @description Button component for adding new devices
 * @version 1.0.1
 * @updated 2025-02-28
 * @dependencies lucide-react, react-i18next
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, Computer } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useMediaQuery } from '@/hooks/useMediaQuery';

interface AddDeviceButtonProps {
  onClick: () => void;
  className?: string;
}

export const AddDeviceButton: React.FC<AddDeviceButtonProps> = ({
  onClick,
  className
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 640px)');

  const handleClick = () => {
    logger.debug('Add device button clicked', {
      component: 'AddDeviceButton',
      action: 'click'
    });
    onClick();
  };

  return (
    <Button
      variant="default"
      size="sm"
      onClick={handleClick}
      className={cn(
        "bg-blue-500 hover:bg-blue-600 text-white",
        "whitespace-nowrap flex-shrink-0",
        "min-w-max h-10",
        className
      )}
    >
      <Computer className="h-4 w-4 mr-1.5" />
      {!isMobile && <Plus className="h-3 w-3 mr-1" />}
      {isMobile ? t('devices.actions.add_short') : t('devices.actions.add_device')}
    </Button>
  );
};

export default AddDeviceButton;