// src/components/encrypted-drive/media/AnimatedThumbnail.tsx

/**
 * @file src/components/encrypted-drive/media/AnimatedThumbnail.tsx
 * @description Enhanced thumbnail component with Gen Z reveal animations
 * @version 1.1.0
 * @created 2025-03-01
 * @updated 2025-03-03
 */

import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface AnimatedThumbnailProps {
  imageUrl: string | null;
  isLoading: boolean;
  className?: string;
}

export const AnimatedThumbnail: React.FC<AnimatedThumbnailProps> = ({
  imageUrl,
  isLoading,
  className
}) => {
  // Use useRef for animation state tracking to avoid re-renders that might cause loops
  const [revealStage, setRevealStage] = useState(0);
  
  // Track initialization with ref instead of state to avoid re-renders
  const animationInitialized = useRef(false);
  // Track component mounting count to prevent animation loops on remounts
  const mountCount = useRef(0);
  // Unique ID for each instance to help with debugging
  const instanceId = useRef(`at-${Math.random().toString(36).slice(2, 8)}`);
  
  // Initialization effect - runs once on mount
  useEffect(() => {
    // Increment mount count to track component lifecycle
    mountCount.current += 1;
    
    // Skip most logging to improve performance
    if (process.env.NODE_ENV === 'development' && Math.random() < 0.05) {
      logger.debug('AnimatedThumbnail mounted', {
        component: 'AnimatedThumbnail',
        instanceId: instanceId.current,
        data: {
          mountCount: mountCount.current,
          hasImageUrl: !!imageUrl,
          isLoading
        }
      });
    }
    
    // Immediately mark as initialized to prevent future animation attempts
    animationInitialized.current = true;
    
    // Cleanup function
    return () => {
      // Skip most logging to improve performance
      if (process.env.NODE_ENV === 'development' && Math.random() < 0.02) {
        logger.debug('AnimatedThumbnail unmounted', {
          component: 'AnimatedThumbnail',
          instanceId: instanceId.current,
          data: { mountCount: mountCount.current }
        });
      }
    };
  }, []);
  
  // Animation effect - separate from initialization but needs to complete reliably
  useEffect(() => {
    // Only start animation if:
    // 1. We have an image URL
    // 2. We're not in a loading state
    // 3. The animation hasn't started yet (stage 0)
    if (imageUrl && !isLoading && revealStage === 0) {
      // Limit logging to avoid performance issues
      if (process.env.NODE_ENV === 'development' && Math.random() < 0.05) {
        logger.debug('Starting thumbnail animation', {
          component: 'AnimatedThumbnail',
          instanceId: instanceId.current,
          data: { stage: 0 }
        });
      }
      
      // Create sequential timers for the animation stages
      const timer1 = setTimeout(() => {
        if (revealStage === 0) {
          setRevealStage(1);
          
          // Stage 2 timer - ensure it runs after stage 1
          const timer2 = setTimeout(() => {
            setRevealStage(2);
          }, 400); // Slightly faster progression
          
          // Return cleanup for the stage 2 timer
          return () => clearTimeout(timer2);
        }
      }, 100);
      
      // Return cleanup for the stage 1 timer
      return () => clearTimeout(timer1);
    }
  }, [imageUrl, isLoading, revealStage]);
  
  // Ensure animation completes - safety effect to prevent stuck animations
  useEffect(() => {
    // If we're in a partial animation state, ensure it completes
    if (revealStage === 1 && imageUrl) {
      const completeTimer = setTimeout(() => {
        setRevealStage(2);
      }, 400);
      
      return () => clearTimeout(completeTimer);
    }
  }, [revealStage, imageUrl]);
  
  return (
    <div 
      className={cn(
        "relative overflow-hidden w-full h-full",
        "bg-gray-100 dark:bg-gray-800",
        className
      )}
    >
      {/* Main image with staged reveal effects */}
      {imageUrl && (
        <motion.div
          initial={{ opacity: 0, scale: 1.05 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ 
            duration: 0.3,
            ease: "easeOut" 
          }}
          className="w-full h-full relative"
        >
          <motion.img
            src={imageUrl}
            alt="Thumbnail" 
            className={cn(
              "w-full h-full object-cover",
              "transition-all",
              revealStage === 0 && "blur-xl scale-105 brightness-75 duration-300",
              revealStage === 1 && "blur-md scale-102 brightness-90 duration-300",
              revealStage === 2 && "blur-none scale-100 brightness-100 duration-500"
            )}
          />
        </motion.div>
      )}
      
      {/* Digital reveal pattern overlay */}
      <AnimatePresence>
        {imageUrl && revealStage < 2 && (
          <motion.div 
            className="absolute inset-0 grid grid-cols-8 grid-rows-8 pointer-events-none"
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {Array.from({ length: 64 }).map((_, i) => (
              <motion.div
                key={i}
                className="border border-blue-400/20 backdrop-blur-sm bg-blue-500/10"
                initial={{ opacity: 0.7 }}
                animate={{ opacity: 0 }}
                transition={{ 
                  duration: 0.3, 
                  delay: 0.2 + Math.random() * 0.5 
                }}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      
      {/* Horizontal scan effect */}
      <AnimatePresence>
        {imageUrl && revealStage < 2 && (
          <motion.div
            className="absolute inset-0 pointer-events-none"
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <motion.div
              className="absolute inset-x-0 h-8 bg-gradient-to-r from-blue-500/10 via-blue-400/30 to-blue-500/10"
              initial={{ y: 0, opacity: 0.7 }}
              animate={{ y: '100%', opacity: 0 }}
              transition={{ 
                duration: 0.8,
                ease: "easeInOut"
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      
      {/* Decryption pattern effect */}
      <AnimatePresence>
        {imageUrl && revealStage < 2 && (
          <motion.div
            className="absolute inset-0 mix-blend-overlay pointer-events-none"
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="absolute inset-0 bg-grid-lines opacity-20" />
            <motion.div
              className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20"
              initial={{ opacity: 0.6 }}
              animate={{ opacity: 0 }}
              transition={{ duration: 0.7 }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AnimatedThumbnail;