/**
 * @file src/services/encrypted-drive/encrypted-drive-operations.ts
 * @description Enhanced API functions for encrypted drive operations with updated favorite endpoints
 * @version 1.1.0
 * @updated 2025-03-17
 */

import { authService } from '../auth.service';
import { encryptionService } from './encryption-singleton';
import { logger } from '@/utils/logger';

const BASE_URL = '/api/v1/encrypted-drive';

// Keep track of recent failed drive IDs to prevent hammering
const recentFailedRequests = new Map<string, number>();

// Simple function to get auth token
function getAuthHeaders(): Record<string, string> {
  const token = authService.getStoredToken();
  return token ? { 'Authorization': `Bearer ${token}` } : {};
}


// Rename an encrypted item
export async function renameEncryptedItem(
  itemId: string,
  newName: string,
  driveId: string,
  metadataKey: any
) {
  try {
    logger.debug('Starting encrypted rename operation', {
      component: 'encryptedDriveOperations',
      data: { itemId, driveId, newName }
    });

    // 1. Get the item details
    const response = await fetch(`${BASE_URL}/files/${itemId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        ...getAuthHeaders()
      } as HeadersInit,
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to get item details');
    }

    const fileDetails = await response.json();
    
    // 2. Decrypt the existing metadata with improved handling
    let decryptedMetadata = await encryptionService.decryptMetadata(
      fileDetails.encrypted_metadata,
      fileDetails.metadata_iv,
      metadataKey
    );

    // Fix for string metadata - parse it to an object if needed
    if (typeof decryptedMetadata === 'string') {
      try {
        decryptedMetadata = JSON.parse(decryptedMetadata);
        logger.debug('Parsed metadata from string', {
          component: 'encryptedDriveOperations',
          data: { metadataKeys: Object.keys(decryptedMetadata) }
        });
      } catch (parseError) {
        logger.error('Failed to parse decrypted metadata', {
          component: 'encryptedDriveOperations',
          error: parseError
        });
        throw new Error('Invalid metadata format');
      }
    }

    // 3. Update the name
    logger.debug('Updating metadata name', {
      component: 'encryptedDriveOperations',
      data: { oldName: decryptedMetadata.name, newName }
    });
    
    decryptedMetadata.name = newName;
    
    // 4. Re-encrypt the metadata
    const encryptedMetadataResult = await encryptionService.encryptMetadata(
      decryptedMetadata,
      metadataKey
    );

    // 5. Send the update
    const formData = new FormData();
    formData.append('encrypted_metadata', encryptedMetadataResult.encryptedData);
    formData.append('metadata_iv', encryptedMetadataResult.iv);
    formData.append('drive_id', driveId);

    // Get auth token but don't include Content-Type header for FormData
    const headers = getAuthHeaders();
    
    // Log the request details for debugging
    logger.debug('Sending rename request', {
      component: 'encryptedDriveOperations',
      data: { 
        url: `${BASE_URL}/files/rename-item/${itemId}`, 
        method: 'PUT',
        requestHeaders: headers,
        formDataKeys: [...formData.keys()]
      }
    });

    // Don't use getApiAuthHeaders() as it might set Content-Type
    // Let the browser set the correct multipart/form-data Content-Type
    const updateResponse = await fetch(`${BASE_URL}/files/rename-item/${itemId}`, {
      method: 'PUT',
      headers: headers, // Just use Authorization header
      body: formData,
      credentials: 'include'
    });

    if (!updateResponse.ok) {
      const errorText = await updateResponse.text();
      logger.error('Failed to rename item', {
        component: 'encryptedDriveOperations',
        data: {
          status: updateResponse.status,
          statusText: updateResponse.statusText,
          errorText
        }
      });
      throw new Error(`Failed to rename item: ${updateResponse.status} ${errorText}`);
    }

    return await updateResponse.json();
  } catch (error) {
    logger.error('Error renaming encrypted item:', {
      component: 'encryptedDriveOperations',
      error,
      data: { itemId, driveId }
    });
    throw error;
  }
}

// Delete encrypted items
export async function deleteEncryptedItems(
  itemIds: string[],
  driveId: string
) {
  try {
    logger.debug('Deleting encrypted items', {
      component: 'encryptedDriveOperations',
      data: { itemCount: itemIds.length, driveId }
    });

    const response = await fetch(`${BASE_URL}/files/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeaders()
      } as HeadersInit,
      body: JSON.stringify({
        items: itemIds,
        drive_id: driveId
      }),
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      logger.error('Failed to delete items', {
        component: 'encryptedDriveOperations',
        data: {
          status: response.status,
          statusText: response.statusText,
          errorText
        }
      });
      throw new Error(`Failed to delete items: ${response.status} ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    logger.error('Error deleting encrypted items:', {
      component: 'encryptedDriveOperations',
      error,
      data: { itemCount: itemIds.length, driveId }
    });
    throw error;
  }
}

// Move encrypted items
export async function moveEncryptedItems(
  itemIds: string[],
  targetFolderId: string,
  driveId: string
) {
  try {
    logger.debug('Moving encrypted items', {
      component: 'encryptedDriveOperations',
      data: { itemCount: itemIds.length, targetFolderId, driveId }
    });

    const response = await fetch(`${BASE_URL}/files/move`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeaders()
      } as HeadersInit,
      body: JSON.stringify({
        items: itemIds,
        target_folder_id: targetFolderId,
        drive_id: driveId
      }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to move items');
    }

    return await response.json();
  } catch (error) {
    logger.error('Error moving encrypted items:', {
      component: 'encryptedDriveOperations',
      error,
      data: { itemCount: itemIds.length, targetFolderId, driveId }
    });
    throw error;
  }
}


// Get favorite status for an item
export async function getEncryptedFavoriteStatus(
  itemId: string,
  driveId: string
) {
  try {
    logger.debug('Fetching encrypted item favorite status', {
      component: 'encryptedDriveOperations',
      data: { 
        itemId, 
        driveId
      }
    });

    const url = `${BASE_URL}/favorites/status/${itemId}?drive_id=${driveId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        ...getAuthHeaders()
      } as HeadersInit,
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      logger.error('Failed to get favorite status', {
        component: 'encryptedDriveOperations',
        data: {
          status: response.status,
          statusText: response.statusText,
          errorText
        }
      });
      throw new Error(`Failed to get favorite status: ${response.status} ${errorText}`);
    }

    const result = await response.json();
    
    logger.debug('Favorite status received', {
      component: 'encryptedDriveOperations',
      data: { 
        itemId,
        isFavorite: result.is_favorite
      }
    });

    return result;
  } catch (error) {
    logger.error('Error fetching favorite status:', {
      component: 'encryptedDriveOperations',
      error,
      data: { itemId, driveId }
    });
    throw error;
  }
}




// Toggle favorite with updated endpoint path
export async function toggleFavoriteEncryptedItem(
  itemId: string,
  isFavorite: boolean,
  driveId: string,
  metadataKey: any
) {
  try {
    logger.debug('Toggling encrypted item favorite status', {
      component: 'encryptedDriveOperations',
      data: { 
        itemId, 
        driveId, 
        isFavorite 
      }
    });

    // 1. Get the item details
    const response = await fetch(`${BASE_URL}/files/${itemId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        ...getAuthHeaders()
      } as HeadersInit,
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      logger.error('Failed to get item details for favorite toggle', {
        component: 'encryptedDriveOperations',
        data: {
          status: response.status,
          statusText: response.statusText,
          errorText
        }
      });
      throw new Error(`Failed to get item details: ${response.status} ${errorText}`);
    }

    const fileDetails = await response.json();
    
    // 2. Decrypt the existing metadata
    let decryptedMetadata = await encryptionService.decryptMetadata(
      fileDetails.encrypted_metadata,
      fileDetails.metadata_iv,
      metadataKey
    );

    // Fix for string metadata - parse it to an object if needed
    if (typeof decryptedMetadata === 'string') {
      try {
        decryptedMetadata = JSON.parse(decryptedMetadata);
      } catch (parseError) {
        logger.error('Failed to parse decrypted metadata', {
          component: 'encryptedDriveOperations',
          error: parseError
        });
        throw new Error('Invalid metadata format');
      }
    }

    // 3. Update the favorite property
    logger.debug('Updating metadata favorite', {
      component: 'encryptedDriveOperations',
      data: { 
        oldValue: decryptedMetadata.favorite,
        newValue: isFavorite
      }
    });
    
    decryptedMetadata.favorite = isFavorite;
    
    // 4. Re-encrypt the metadata
    const encryptedMetadataResult = await encryptionService.encryptMetadata(
      decryptedMetadata,
      metadataKey
    );

    // 5. Send the update to the new favorites endpoint
    const formData = new FormData();
    formData.append('encrypted_metadata', encryptedMetadataResult.encryptedData);
    formData.append('metadata_iv', encryptedMetadataResult.iv);
    formData.append('drive_id', driveId);
    formData.append('is_favorite', isFavorite.toString());

    // Get auth token but don't include Content-Type header for FormData
    const headers = getAuthHeaders();
    
    logger.debug('Sending favorite toggle request', {
      component: 'encryptedDriveOperations',
      data: { 
        url: `${BASE_URL}/favorites/toggle/${itemId}`,
        formDataKeys: [...formData.keys()]
      }
    });
    
    const updateResponse = await fetch(`${BASE_URL}/favorites/toggle/${itemId}`, {
      method: 'PUT',
      headers: headers,
      body: formData,
      credentials: 'include'
    });

    if (!updateResponse.ok) {
      const errorText = await updateResponse.text();
      logger.error('Failed to update favorite status', {
        component: 'encryptedDriveOperations',
        data: {
          status: updateResponse.status,
          statusText: updateResponse.statusText,
          errorText
        }
      });
      throw new Error(`Failed to update favorite status: ${updateResponse.status} ${errorText}`);
    }

    const result = await updateResponse.json();
    
    logger.debug('Favorite status updated successfully', {
      component: 'encryptedDriveOperations',
      data: { 
        itemId,
        isFavorite: result.is_favorite
      }
    });
    
    return result;
  } catch (error) {
    logger.error('Error updating encrypted item favorite status:', {
      component: 'encryptedDriveOperations',
      error,
      data: { itemId, driveId }
    });
    throw error;
  }
}

export async function listEncryptedFavorites(
  driveId: string,
  cursor?: string,
  pageSize: number = 50
) {
  try {
    // Check if we've recently failed for this drive ID to avoid hammering the server
    const lastFailureTime = recentFailedRequests.get(driveId);
    const currentTime = Date.now();
    
    if (lastFailureTime && currentTime - lastFailureTime < 5000) {
      logger.debug('Skipping favorites request due to recent failure', {
        component: 'encryptedDriveOperations',
        data: { 
          driveId,
          timeUntilRetry: Math.ceil((5000 - (currentTime - lastFailureTime)) / 1000)
        }
      });
      throw new Error('Too many requests. Please try again in a few seconds.');
    }
    
    // Validate drive ID format before making the request
    if (!driveId || !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(driveId)) {
      logger.error('Invalid drive ID format', {
        component: 'encryptedDriveOperations',
        data: { driveId }
      });
      throw new Error('Invalid drive ID format');
    }
    
    logger.debug('Fetching encrypted favorites', {
      component: 'encryptedDriveOperations',
      data: { driveId, pageSize }
    });

    let url = `${BASE_URL}/favorites/list?drive_id=${driveId}`;
    if (cursor) {
      // Validate cursor format
      if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(cursor)) {
        logger.warn('Invalid cursor format, skipping', {
          component: 'encryptedDriveOperations',
          data: { cursor }
        });
      } else {
        url += `&cursor=${cursor}`;
      }
    }
    if (pageSize) url += `&page_size=${pageSize}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        ...getAuthHeaders()
      } as HeadersInit,
      credentials: 'include'
    });

    if (!response.ok) {
      let errorText = '';
      try {
        const errorData = await response.json();
        errorText = errorData.detail || errorData.message || '';
      } catch {
        errorText = await response.text();
      }
      
      logger.error('Failed to fetch encrypted favorites', {
        component: 'encryptedDriveOperations',
        data: {
          status: response.status,
          statusText: response.statusText,
          errorText
        }
      });
      
      // Store the failure time for this drive ID
      recentFailedRequests.set(driveId, Date.now());
      
      // Set a timeout to clear the failure after the cooldown period
      setTimeout(() => {
        if (recentFailedRequests.get(driveId) === currentTime) {
          recentFailedRequests.delete(driveId);
        }
      }, 5000);
      
      throw new Error(`Failed to fetch favorites: ${response.status} ${errorText}`);
    }

    const result = await response.json();
    
    // Validate response structure
    if (!result || !Array.isArray(result.items)) {
      logger.error('Invalid response format', {
        component: 'encryptedDriveOperations',
        data: { 
          responseType: typeof result,
          hasItems: result && 'items' in result,
          isItemsArray: result && 'items' in result && Array.isArray(result.items)
        }
      });
      throw new Error('Invalid response format from server');
    }
    
    // Clear any stored failure time for this drive ID on success
    recentFailedRequests.delete(driveId);
    
    logger.debug('Encrypted favorites fetched successfully', {
      component: 'encryptedDriveOperations',
      data: { 
        itemCount: result.items?.length || 0,
        total: result.total,
        hasMore: result.hasMore
      }
    });

    return result;
  } catch (error) {
    logger.error('Error fetching encrypted favorites:', {
      component: 'encryptedDriveOperations',
      error,
      data: { driveId }
    });
    
    // Rethrow the error with improved error message
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error('Failed to fetch favorites');
    }
  }
}



