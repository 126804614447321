/**
 * @file src/components/upload/GlobalUploadQueue/UploadQueueList.tsx
 * @description List of upload items with proper scrolling support
 * @version 1.3.0
 */

import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { UploadQueueItem } from './UploadQueueItem';
import { useUploadQueue } from '@/hooks/upload/useUploadQueue';
import { logger } from '@/utils/logger';
import type { UploadFile } from '@/types/upload.types';

// Define the EncryptionStatus type
type EncryptionStatus = 'processing' | 'transcoding' | 'encrypting' | 'uploading' | 'completed';

// Extend UploadFile type with encryption properties
interface ExtendedUploadFile extends UploadFile {
  encryptionStatus?: EncryptionStatus;
  isEncrypted?: boolean;
  encryptionProgress?: number;
  statusMessage?: string;
  originalSize?: number;
  encryptedSize?: number;
  uploadSpeed?: number;
  isChunked?: boolean;
}

// Helper function to convert UploadFile to ExtendedUploadFile
const toExtendedUploadFile = (file: UploadFile): ExtendedUploadFile => {
  return {
    ...file,
    encryptionStatus: file.encryptionStatus as EncryptionStatus | undefined,
    // Only add properties that don't exist in UploadFile
    statusMessage: undefined,
    uploadSpeed: file.bytesUploaded ? (file.bytesUploaded / (file.endTime ? (file.endTime - (file.startTime || 0)) / 1000 : 1)) : 0,
    isChunked: file.totalChunks ? file.totalChunks > 1 : false
  };
};

export const UploadQueueList: React.FC = () => {
  const { queue } = useUploadQueue();

  React.useEffect(() => {
    logger.debug('Queue updated', {
      component: 'UploadQueueList',
      action: 'update',
      data: { queueLength: queue.length }
    });
  }, [queue.length]);

  if (!queue.length) return null;

  return (
    <div className="w-full">
      {queue.map((item, index) => {
        // Process the item to ensure it has the right typing
        const enhancedItem = toExtendedUploadFile(item);
        
        return (
          <UploadQueueItem
            key={item.id}
            item={enhancedItem}
            isLast={index === queue.length - 1}
          />
        );
      })}
    </div>
  );
};