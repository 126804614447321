/**
 * @file src/providers/UploadProvider.tsx
 * @description Upload provider implementation with enhanced drive handling and concurrent uploads
 * @version 2.2.0
 */

import React, { useReducer, useCallback, useRef } from 'react';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { useCloudDrive } from '@/context/cloud-drive';
import { UploadContext } from '@/context/UploadContext';
import { uploadOrchestrator } from '@/services/upload/core';
import { UPLOAD_CONFIG } from '@/config/upload.config';
import type { UploadFile, UploadStatus, UploadProgress, EncryptionProgress, UploadContextValue } from '@/types/upload.types';
import { logger } from '@/utils/logger';
import { useToast } from '@/components/ui/toast';
import { useQueryClient } from '@tanstack/react-query';
import { encryptedFilesService } from '@/services/encrypted-drive/encrypted-files.service';
import { authService } from '@/services/auth.service';
import { UPLOAD_HEARTBEAT_INTERVAL } from '@/config/constants';

// Try to use NavigationContext but don't require it
let navigationContext: any;
try {
  const { useNavigation } = require('@/context/NavigationContext');
  navigationContext = useNavigation;
} catch (error) {
  logger.debug('Navigation context not available for upload provider', {
    component: 'UploadProvider'
  });
}

function generateId(): string {
  return `upload-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
}

type UploadAction =
  | { type: 'ADD_FILES'; payload: UploadFile[] }
  | { type: 'REMOVE_FILE'; payload: string }
  | { type: 'UPDATE_FILE'; payload: { id: string; updates: Partial<UploadFile> } }
  | { type: 'CLEAR_COMPLETED' }
  | { type: 'SET_VISIBLE'; payload: boolean }
  | { type: 'SET_MINIMIZED'; payload: boolean }
  | { type: 'SET_ACCEPTING'; payload: boolean }
  | { type: 'SET_ACTIVE_UPLOAD'; payload: string | null }
  | { type: 'UPDATE_ENCRYPTION_PROGRESS'; payload: { id: string; progress: EncryptionProgress } }
  | { type: 'SET_ACTIVE_UPLOADS'; payload: string[] }
  | { type: 'ADD_ACTIVE_UPLOAD'; payload: string }
  | { type: 'REMOVE_ACTIVE_UPLOAD'; payload: string };

// Initialize state from localStorage if available for persistence across page changes
const getInitialState = () => {
  try {
    // Get persisted state from localStorage
    const persistedVisibleState = localStorage.getItem('upload_panel_visible') === 'true';
    const persistedMinimizedState = localStorage.getItem('upload_panel_minimized') === 'true';
    
    return {
      queue: [] as UploadFile[],
      isVisible: persistedVisibleState || false,
      isMinimized: persistedMinimizedState || false,
      isAcceptingFiles: true,
      activeUpload: null as string | null,
      activeUploads: [] as string[]  // Track multiple active uploads
    };
  } catch (error) {
    // Fallback to default state if localStorage access fails
    return {
      queue: [] as UploadFile[],
      isVisible: false,
      isMinimized: false,
      isAcceptingFiles: true,
      activeUpload: null as string | null,
      activeUploads: [] as string[]
    };
  }
};

const initialState = getInitialState();

function uploadReducer(state: typeof initialState, action: UploadAction): typeof initialState {
  switch (action.type) {
    case 'ADD_FILES':
      return {
        ...state,
        queue: [...state.queue, ...action.payload]
      };
    case 'REMOVE_FILE':
      return {
        ...state,
        queue: state.queue.filter((file: any) => file.id !== action.payload),
        activeUploads: state.activeUploads.filter(id => id !== action.payload)
      };
    case 'UPDATE_FILE':
      return {
        ...state,
        queue: state.queue.map((file: any) =>
          file.id === action.payload.id
            ? { ...file, ...action.payload.updates }
            : file
        )
      };
    case 'CLEAR_COMPLETED':
      return {
        ...state,
        queue: state.queue.filter((file: any) => !['completed', 'error'].includes(file.status))
      };
    case 'SET_VISIBLE':
      // Persist visibility state to localStorage
      localStorage.setItem('upload_panel_visible', action.payload.toString());
      return {
        ...state,
        isVisible: action.payload
      };
    case 'SET_MINIMIZED':
      // Persist minimized state to localStorage
      localStorage.setItem('upload_panel_minimized', action.payload.toString());
      return {
        ...state,
        isMinimized: action.payload
      };
    case 'SET_ACCEPTING':
      return {
        ...state,
        isAcceptingFiles: action.payload
      };
    case 'SET_ACTIVE_UPLOAD':
      return {
        ...state,
        activeUpload: action.payload
      };
    case 'SET_ACTIVE_UPLOADS':
      return {
        ...state,
        activeUploads: action.payload
      };
    case 'ADD_ACTIVE_UPLOAD':
      return {
        ...state,
        activeUploads: [...state.activeUploads, action.payload]
      };
    case 'REMOVE_ACTIVE_UPLOAD':
      return {
        ...state,
        activeUploads: state.activeUploads.filter(id => id !== action.payload)
      };
    case 'UPDATE_ENCRYPTION_PROGRESS':
      return {
        ...state,
        queue: state.queue.map((file: any) =>
          file.id === action.payload.id
            ? { 
                ...file, 
                encryptionProgress: action.payload.progress.progress,
                encryptionStatus: action.payload.progress.stage,
                originalSize: action.payload.progress.originalSize,
                encryptedSize: action.payload.progress.encryptedSize || file.encryptedSize,
                isEncrypted: true
              }
            : file
        )
      };
    default:
      return state;
  }
}

export const UploadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { showToast } = useToast();
  const { activeDriveId, primaryDriveId, systemDevice, uploadStrategy } = useRootDrive();
  const queryClient = useQueryClient();
  
  // Get encryptedDrive state safely, since CloudDriveProvider might not be available yet
  let encryptedDrive = { isEncryptedMode: false, encryptedDriveId: undefined };
  let clearEncryptedDriveState: () => void = () => {};
  
  // Subscribe to the global drive locking event
  const isGlobalDriveLockedRef = useRef<boolean>(false);
  const unlockedDrivesRef = useRef<string[]>([]);
  
  try {
    const cloudDrive = useCloudDrive();
    encryptedDrive = cloudDrive.encryptedDrive || { isEncryptedMode: false, encryptedDriveId: undefined, encryptionTier: undefined };
    clearEncryptedDriveState = cloudDrive.clearEncryptedDriveState;
    
    // Add unlocked drive IDs to keep track of them
    if (cloudDrive.encryptedDrive?.isEncryptedMode && cloudDrive.encryptedDrive?.encryptedDriveId) {
      const driveId = cloudDrive.encryptedDrive.encryptedDriveId;
      if (!unlockedDrivesRef.current.includes(driveId)) {
        unlockedDrivesRef.current.push(driveId);
      }
    }
  } catch (error) {
    // CloudDriveProvider might not be available yet, that's ok
    // We'll use default values
  }
  
  // Store the last active drive ID in localStorage for persistence
  // This is used when multiple drives are unlocked to know which to upload to
  const updateLastActiveDrive = useCallback((driveId: string) => {
    localStorage.setItem('last_active_drive', driveId);
    localStorage.setItem('last_active_drive_timestamp', Date.now().toString());
  }, []);

  const [state, dispatch] = useReducer(uploadReducer, initialState);
  
  // Upload queue management
  const uploadQueueRef = useRef<UploadFile[]>([]);
  const processingRef = useRef<boolean>(false);
  const heartbeatIntervalRef = useRef<NodeJS.Timeout | null>(null);
  
  // Session heartbeat to keep token alive during uploads
  const startSessionHeartbeat = useCallback(() => {
    // Clear any existing interval first
    if (heartbeatIntervalRef.current) {
      clearInterval(heartbeatIntervalRef.current);
    }
    
    // Create a new interval that refreshes the session every 10 minutes
    // This is less than the 30-minute token expiry to ensure we stay logged in
    heartbeatIntervalRef.current = setInterval(() => {
      if (localStorage.getItem('active_uploads') === 'true') {
        logger.debug('Sending session heartbeat during upload', {
          component: 'UploadProvider',
          action: 'sessionHeartbeat'
        });
        
        // Refresh the token to keep the session alive
        authService.refreshToken().catch(error => {
          logger.warn('Session heartbeat refresh failed, but uploads will continue', {
            component: 'UploadProvider',
            action: 'sessionHeartbeat',
            error
          });
        });
      } else {
        // If no active uploads, clear the interval
        if (heartbeatIntervalRef.current) {
          clearInterval(heartbeatIntervalRef.current);
          heartbeatIntervalRef.current = null;
        }
      }
    }, UPLOAD_HEARTBEAT_INTERVAL);
    
    return () => {
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
    };
  }, []);
  
  // Try to get navigation context if available
  let currentFolderId: string | null = null;
  try {
    if (navigationContext) {
      const { currentFolderId: folderId } = navigationContext();
      currentFolderId = folderId;
    }
  } catch (error) {
    logger.debug('Unable to get current folder ID', {
      component: 'UploadProvider'
    });
  }

  // Determine concurrent upload limit based on plan
  const getConcurrentLimit = useCallback(() => {
    // Use configuration values directly
    return UPLOAD_CONFIG.concurrentUploads?.paid || 3;
  }, []);

  const updateEncryptionProgress = useCallback((
    fileId: string, 
    progress: EncryptionProgress
  ) => {
    logger.debug('Encryption progress update', {
      component: 'UploadProvider',
      action: 'updateEncryptionProgress',
      data: {
        fileId,
        progress: progress.progress,
        stage: progress.stage,
        originalSize: progress.originalSize,
        encryptedSize: progress.encryptedSize
      }
    });

    dispatch({
      type: 'UPDATE_ENCRYPTION_PROGRESS',
      payload: { id: fileId, progress }
    });
  }, []);
  
  // Function to update active uploads flag in localStorage
  const updateActiveUploadsFlag = useCallback((hasActiveUploads: boolean) => {
    if (hasActiveUploads) {
      localStorage.setItem('active_uploads', 'true');
      // Start the session heartbeat when uploads become active
      startSessionHeartbeat();
    } else {
      localStorage.removeItem('active_uploads');
      // Stop the heartbeat when there are no more active uploads
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
        heartbeatIntervalRef.current = null;
      }
    }
    logger.debug('Active uploads flag updated', {
      component: 'UploadProvider',
      action: 'updateActiveUploadsFlag',
      data: { hasActiveUploads, heartbeatStarted: hasActiveUploads }
    });
  }, [startSessionHeartbeat]);

  // Process the upload queue
  const processQueue = useCallback(async () => {
    if (processingRef.current) {
      logger.debug('Upload queue processing already in progress, skipping', {
        component: 'UploadProvider', 
        action: 'processQueue'
      });
      return;
    }
    
    processingRef.current = true;
    logger.debug('Processing upload queue', {
      component: 'UploadProvider',
      action: 'processQueue',
      data: { 
        queueLength: uploadQueueRef.current.length,
        activeUploads: state.activeUploads.length,
        concurrentLimit: getConcurrentLimit()
      }
    });
    
    try {
      // Get all pending uploads
      const pendingUploads = uploadQueueRef.current.filter(
        file => file.status === 'queued' || file.status === 'preparing'
      );
      
      if (pendingUploads.length === 0) {
        logger.debug('No pending uploads to process', {
          component: 'UploadProvider',
          action: 'processQueue'
        });
        processingRef.current = false;
        return;
      }
      
      // Calculate how many new uploads we can start
      const concurrentLimit = getConcurrentLimit();
      const availableSlots = concurrentLimit - state.activeUploads.length;
      
      if (availableSlots <= 0) {
        logger.debug('No available upload slots', {
          component: 'UploadProvider',
          action: 'processQueue',
          data: {
            concurrentLimit,
            activeUploads: state.activeUploads.length
          }
        });
        processingRef.current = false;
        return;
      }
      
      // Start uploads up to the limit
      const uploadsToStart = pendingUploads.slice(0, availableSlots);
      logger.debug('Starting new uploads', {
        component: 'UploadProvider',
        action: 'processQueue',
        data: { 
          availableSlots,
          uploadsToStart: uploadsToStart.length,
          uploadIds: uploadsToStart.map(u => u.id),
          fileNames: uploadsToStart.map(u => u.file.name)
        }
      });
      
      for (const uploadFile of uploadsToStart) {
        // Enhanced logging for each file
        logger.debug('Processing upload file', {
          component: 'UploadProvider',
          action: 'processQueue',
          data: {
            fileId: uploadFile.id,
            fileName: uploadFile.file.name,
            fileSize: uploadFile.file.size,
            driveId: uploadFile.driveId,
            isEncrypted: uploadFile.isEncrypted,
            status: uploadFile.status
          }
        });
        
        // Remove from queue and mark as active
        uploadQueueRef.current = uploadQueueRef.current.filter(f => f.id !== uploadFile.id);
        dispatch({ type: 'ADD_ACTIVE_UPLOAD', payload: uploadFile.id });
        
        // Update file status
        dispatch({
          type: 'UPDATE_FILE',
          payload: {
            id: uploadFile.id,
            updates: { 
              status: 'uploading',
              message: uploadFile.isEncrypted ? 'Starting encryption process...' : 'Starting upload...'
            }
          }
        });
        
        // Start the upload with a try-catch to ensure queue processing continues
        try {
          startUpload(uploadFile);
        } catch (error) {
          logger.error('Error starting upload', {
            component: 'UploadProvider',
            action: 'processQueue',
            error,
            data: {
              fileId: uploadFile.id,
              fileName: uploadFile.file.name
            }
          });
          
          // Update status to error so we can see it in the UI
          dispatch({
            type: 'UPDATE_FILE',
            payload: {
              id: uploadFile.id,
              updates: { 
                status: 'error' as UploadStatus,
                error: error instanceof Error ? error.message : 'Unknown error starting upload'
              }
            }
          });
          
          // Remove from active uploads to free up a slot
          dispatch({ type: 'REMOVE_ACTIVE_UPLOAD', payload: uploadFile.id });
        }
      }
    } catch (error) {
      logger.error('Unexpected error in processQueue', {
        component: 'UploadProvider',
        action: 'processQueue',
        error
      });
    } finally {
      processingRef.current = false;
      
      // Check if there are still items to process
      if (uploadQueueRef.current.some(file => file.status === 'queued' || file.status === 'preparing')) {
        logger.debug('More items in queue after processing, scheduling next batch', {
          component: 'UploadProvider',
          action: 'processQueue'
        });
        setTimeout(() => processQueue(), 100);
      }
    }
  }, [state.activeUploads, getConcurrentLimit, updateActiveUploadsFlag]);
  
  // Handle a single file upload
  const startUpload = useCallback(async (uploadFile: UploadFile) => {
    let encryptionServiceModule;
    
    try {
      // Import encryptionService dynamically to avoid circular imports
      encryptionServiceModule = await import('@/services/encrypted-drive/encryption-singleton');
      const { encryptionService } = encryptionServiceModule;
      
      logger.debug('Starting upload for file', {
        component: 'UploadProvider',
        action: 'startUpload',
        data: {
          fileId: uploadFile.id,
          fileName: uploadFile.file.name,
          fileSize: uploadFile.file.size,
          fileType: uploadFile.file.type,
          isEncrypted: uploadFile.isEncrypted,
          encryptionTier: uploadFile.encryptionTier,
          driveId: uploadFile.driveId,
          encryptedDriveId: encryptedDrive.encryptedDriveId,
          parentId: uploadFile.parentId
        }
      });
      
      const encryptedOptions = uploadFile.isEncrypted ? {
        isEncrypted: true,
        encryptedDriveId: (encryptedDrive.encryptedDriveId || uploadFile.driveId || ""), // Add empty string fallback
        encryptionTier: encryptedDrive?.encryptionTier || 'standard'
      } : undefined;
      
      if (uploadFile.isEncrypted && (!encryptedOptions?.encryptedDriveId || !encryptedOptions?.encryptionTier)) {
        logger.error('Missing required encryption options', {
          component: 'UploadProvider',
          action: 'startUpload',
          data: {
            encryptedDriveId: encryptedOptions?.encryptedDriveId,
            encryptionTier: encryptedOptions?.encryptionTier
          }
        });
        
        // Update file status to show error
        dispatch({
          type: 'UPDATE_FILE',
          payload: {
            id: uploadFile.id,
            updates: {
              status: 'error' as UploadStatus,
              error: 'Missing encryption configuration'
            }
          }
        });
        
        // Remove from active uploads and process next in queue
        dispatch({ type: 'REMOVE_ACTIVE_UPLOAD', payload: uploadFile.id });
        setTimeout(() => processQueue(), 0);
        return;
      }
      
      // For encrypted uploads, verify keys are available right before upload
      if (uploadFile.isEncrypted && encryptedOptions) {
        const keys = encryptionService.getDecryptedKeys(encryptedOptions.encryptedDriveId);
        if (!keys || !keys.metadataKey || !keys.contentKey) {
          logger.error('Missing encryption keys at upload start', {
            component: 'UploadProvider',
            action: 'startUpload',
            data: {
              fileId: uploadFile.id,
              fileName: uploadFile.file.name,
              driveId: encryptedOptions.encryptedDriveId,
              hasKeys: !!keys,
              hasMetadataKey: keys?.metadataKey ? true : false,
              hasContentKey: keys?.contentKey ? true : false
            }
          });
          
          // Update file status to show error
          dispatch({
            type: 'UPDATE_FILE',
            payload: {
              id: uploadFile.id,
              updates: {
                status: 'error' as UploadStatus,
                error: 'Drive not unlocked. Please unlock the drive first.'
              }
            }
          });
          
          // Remove from active uploads and process next in queue
          dispatch({ type: 'REMOVE_ACTIVE_UPLOAD', payload: uploadFile.id });
          setTimeout(() => processQueue(), 0);
          return;
        }
        
        logger.debug('Encryption keys verified before upload start', {
          component: 'UploadProvider',
          action: 'startUpload',
          data: {
            fileId: uploadFile.id,
            fileName: uploadFile.file.name,
            driveId: encryptedOptions.encryptedDriveId
          }
        });
      }
      
      logger.debug('Calling uploadOrchestrator.startUpload', {
        component: 'UploadProvider',
        data: {
          fileName: uploadFile.file.name,
          parentId: uploadFile.parentId,
          encryptedOptions: encryptedOptions ? {
            encryptedDriveId: encryptedOptions.encryptedDriveId,
            encryptionTier: encryptedOptions.encryptionTier
          } : 'none'
        }
      });
      
      try {
        await uploadOrchestrator.startUpload(
          uploadFile.file,
          uploadFile.parentId || "",
          uploadFile.driveId || "",
          uploadStrategy?.default_mode === 'direct',
          (progress: UploadProgress) => {
            // Log initial progress update
            if (!uploadFile.bytesUploaded && progress) {
              logger.debug('First progress update received', {
                component: 'UploadProvider',
                data: {
                  fileId: uploadFile.id,
                  loaded: progress.loaded,
                  total: progress.total,
                  encryptionProgress: progress.encryptionProgress,
                  encryptionStatus: progress.encryptionStatus
                }
              });
            }
            
            // Handle progress updates
            if (progress?.encryptionProgress !== undefined) {
              // Remove duplicate message if present (workaround for UI bug)
              const message = progress.message && progress.message.includes("\n") ? 
                progress.message.split("\n")[0] : progress.message;
                
              dispatch({
                type: 'UPDATE_FILE',
                payload: {
                  id: uploadFile.id,
                  updates: {
                    status: 'uploading',
                    progress: progress.encryptionProgress,
                    encryptionProgress: progress.encryptionProgress,
                    encryptionStatus: progress.encryptionStatus,
                    originalSize: progress.originalSize,
                    encryptedSize: progress.encryptedSize,
                    bytesUploaded: progress.loaded,
                    message: message // Add message if present
                  }
                }
              });
            } else {
              const progressPercent = (progress.loaded / progress.total) * 100;
              dispatch({
                type: 'UPDATE_FILE',
                payload: {
                  id: uploadFile.id,
                  updates: {
                    status: 'uploading',
                    progress: progressPercent,
                    bytesUploaded: progress.loaded
                  }
                }
              });
            }
          },
          encryptedOptions
        );
        
        // Mark as completed
        dispatch({
          type: 'UPDATE_FILE',
          payload: {
            id: uploadFile.id,
            updates: { status: 'completed', progress: 100 }
          }
        });
        
        // Always refresh file listing after upload completion
        logger.debug('Refreshing folder contents after upload completion', {
          component: 'UploadProvider',
          data: {
            driveId: uploadFile.driveId,
            parentId: uploadFile.parentId || null,
            fileName: uploadFile.file.name,
            isEncrypted: uploadFile.isEncrypted
          }
        });
        
        // For encrypted uploads, perform special cache invalidation and update folder stats
        if (uploadFile.isEncrypted && uploadFile.driveId) {
          // 1. Invalidate the parent folder query to trigger a refetch
          queryClient.invalidateQueries({
            queryKey: ['encrypted-drive', 'files', uploadFile.driveId, uploadFile.parentId || null]
          });
          
          // 2. Also invalidate with the cloud-drive pattern for the UI components
          queryClient.invalidateQueries({
            queryKey: ['cloud-drive', 'folder', uploadFile.parentId || null]
          });
          
          // 3. If the file was uploaded to a folder, update folder statistics
          if (uploadFile.parentId) {
            try {
              // First import the encryptedDriveApi
              const { encryptedDriveApi } = await import('@/services/encrypted-drive/encrypted-drive.service');
              
              // Update folder statistics in background to ensure UI counts are updated
              encryptedDriveApi.updateFolderStats(uploadFile.driveId, uploadFile.parentId)
                .catch(error => {
                  // Log but don't block on this non-critical operation
                  logger.warn('Failed to update folder stats (non-critical)', {
                    component: 'UploadProvider',
                    error,
                    data: { 
                      driveId: uploadFile.driveId, 
                      folderId: uploadFile.parentId 
                    }
                  });
                });
              
              // Also clear metadata cache for this folder to ensure fresh data
              encryptedFilesService.clearSingleItemCache(uploadFile.driveId, uploadFile.parentId);
            } catch (statsError) {
              logger.warn('Error importing or running updateFolderStats (non-critical)', {
                component: 'UploadProvider',
                error: statsError
              });
            }
          }
        } else {
          // For regular uploads, invalidate cloud-drive folder queries
          queryClient.invalidateQueries({
            queryKey: ['cloud-drive', 'folder', uploadFile.parentId || null]
          });
        }
        
        // Aggressively force refetching by pattern
        queryClient.invalidateQueries({
          predicate: (query) => {
            const queryKey = query.queryKey.join('/');
            return queryKey.includes('folder') || 
                   queryKey.includes('files') || 
                   queryKey.includes('encrypted-drive');
          }
        });
        
        // Force immediate refetches for the current folder
        queryClient.refetchQueries({
          queryKey: ['cloud-drive', 'folder', uploadFile.parentId || null]
        });
        
        // Try to refresh folder contents, but don't rely on CloudDriveContext being available
        try {
          // This is a bit of a hack to access the CloudDriveContext method directly
          // In a production app, you might want to use a more structured approach
          // We check for window._cloudDriveContext but don't require it
          // @ts-ignore - Accessing window for direct context access
          if (typeof window !== 'undefined' && 
              typeof window !== 'undefined' && 
              (window as any)._cloudDriveContext && 
              typeof (window as any)._cloudDriveContext.forceRefreshFolderContents === 'function') {
            // Check if this is part of a multi-file upload
            const isMultiFileUpload = state.queue.filter(file => 
              file.status === 'uploading' || file.status === 'completed' || file.status === 'preparing'
            ).length > 1;
            
            // Safely access the context if it exists and pass the batch flag for multi-file uploads
            if (window._cloudDriveContext && typeof window._cloudDriveContext.forceRefreshFolderContents === 'function') {
              window._cloudDriveContext.forceRefreshFolderContents(isMultiFileUpload);
            }
          }
        } catch (e) {
          // Ignore errors - this is just an extra attempt
        }
        
        // Use multiple timed refreshes for more reliability with different network conditions
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: ['cloud-drive', 'folder', uploadFile.parentId || null]
          });
        }, 500);
        
        // Second refresh attempt with longer delay
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: ['cloud-drive', 'folder', uploadFile.parentId || null]
          });
          
          // Also try context method again
          try {
            // @ts-ignore - Accessing window for direct context access
            if (typeof window !== 'undefined' && 
                typeof window !== 'undefined' && 
                (window as any)._cloudDriveContext && 
                typeof (window as any)._cloudDriveContext.forceRefreshFolderContents === 'function') {
              // Check if this is part of a multi-file upload
              const isMultiFileUpload = state.queue.filter(file => 
                file.status === 'uploading' || file.status === 'completed' || file.status === 'preparing'
              ).length > 1;
              
              // Pass the batch flag for multi-file uploads
              (window as any)._cloudDriveContext.forceRefreshFolderContents(isMultiFileUpload);
            }
          } catch (e) {
            // Ignore errors
          }
        }, 2000);
        
        // For large batch uploads, we'll use a simpler approach with throttling
        // to prevent too many refresh requests overwhelming the system
        const isLargeBatchUpload = state.queue.filter(file => 
          file.status === 'uploading' || file.status === 'completed' || file.status === 'preparing'
        ).length > 4; // Consider 5+ files a large batch
        
        if (isLargeBatchUpload) {
          // Use a throttled single refresh that won't create multiple queued refreshes
          // We'll rely on the CloudDriveContext's built-in throttling mechanism
          setTimeout(() => {
            try {
              if (typeof window !== 'undefined' && 
                  (window as any)._cloudDriveContext && 
                  typeof (window as any)._cloudDriveContext.forceRefreshFolderContents === 'function') {
                // The batch flag will trigger the optimized refresh path with throttling
                (window as any)._cloudDriveContext.forceRefreshFolderContents(true);
              }
            } catch (e) {
              // Fallback to direct query invalidation if context method fails
              queryClient.invalidateQueries({
                queryKey: ['cloud-drive', 'folder', uploadFile.parentId || null]
              });
            }
          }, 3000);
        }
      } catch (error) {
        logger.error('Upload failed:', {
          component: 'UploadProvider',
          error,
          data: { 
            fileId: uploadFile.id,
            fileName: uploadFile.file.name,
            isEncrypted: uploadFile.isEncrypted
          }
        });
        
        dispatch({
          type: 'UPDATE_FILE',
          payload: {
            id: uploadFile.id,
            updates: {
              status: 'error' as UploadStatus,
              error: error instanceof Error ? error.message : 'Upload failed'
            }
          }
        });
        
        showToast(
          `Failed to upload ${uploadFile.file.name}: ${error instanceof Error ? error.message : 'Unknown error'}`,
          'error'
        );
      }
    } catch (outerError) {
      // Handle any errors from the dynamic import or other async operations
      logger.error('Fatal error in startUpload', {
        component: 'UploadProvider',
        action: 'startUpload',
        error: outerError,
        data: {
          fileId: uploadFile.id,
          fileName: uploadFile.file.name
        }
      });
      
      // Update file status to show error
      dispatch({
        type: 'UPDATE_FILE',
        payload: {
          id: uploadFile.id,
          updates: {
            status: 'error' as UploadStatus,
            error: outerError instanceof Error ? outerError.message : 'Unexpected error during upload'
          }
        }
      });
    } finally {
      // Remove from active uploads and process next in queue
      dispatch({ type: 'REMOVE_ACTIVE_UPLOAD', payload: uploadFile.id });
      
      // Update active uploads flag if we have no active uploads left
      setTimeout(() => {
        // Check if there will be no more active uploads after this one
        if (state.activeUploads.length <= 1) {
          updateActiveUploadsFlag(false);
        }
        
        logger.debug('Upload completed or failed, checking for more in queue', {
          component: 'UploadProvider',
          action: 'startUpload',
          data: {
            fileId: uploadFile.id,
            fileName: uploadFile.file.name,
            remainingActiveUploads: state.activeUploads.length - 1
          }
        });
        processQueue();
      }, 200);
    }
  }, [uploadStrategy, encryptedDrive, processQueue, showToast, state.activeUploads, updateActiveUploadsFlag]);

  const addFiles = useCallback(async (
    files: File[], 
    parentId: string | null = null,
    providedDriveId?: string
  ) => {
    // Variable for dynamic import result
    let encryptionServiceModule;
    
    // Determine best drive to use for upload
    let effectiveDriveId = providedDriveId;
    
    // If no drive ID provided, try to find the best drive to use
    if (!effectiveDriveId) {
      // 1. Try the current encrypted drive if in encrypted mode
      if (encryptedDrive.isEncryptedMode && encryptedDrive.encryptedDriveId) {
        effectiveDriveId = encryptedDrive.encryptedDriveId;
        logger.debug('Using current encrypted drive for upload', {
          component: 'UploadProvider',
          action: 'addFiles',
          data: { driveId: effectiveDriveId }
        });
        
        // Update last active drive
        updateLastActiveDrive(effectiveDriveId);
      } 
      // 2. Check for the last active drive from localStorage
      else {
        const lastActiveDrive = localStorage.getItem('last_active_drive');
        const lastActiveTimestamp = localStorage.getItem('last_active_drive_timestamp');
        
        if (lastActiveDrive && lastActiveTimestamp) {
          // Only use the last active drive if it was used in the last 30 minutes
          const timestamp = parseInt(lastActiveTimestamp, 10);
          const isRecent = Date.now() - timestamp < 30 * 60 * 1000; // 30 minutes
          
          if (isRecent) {
            effectiveDriveId = lastActiveDrive;
            logger.debug('Using last active drive for upload', {
              component: 'UploadProvider',
              action: 'addFiles',
              data: { 
                driveId: effectiveDriveId,
                lastActive: new Date(timestamp).toISOString()
              }
            });
          }
        }
      }
      
      // 3. Fallback to active drive or primary drive
      if (!effectiveDriveId) {
        effectiveDriveId = activeDriveId || primaryDriveId || '';
        if (effectiveDriveId) {
          logger.debug('Using fallback drive for upload', {
            component: 'UploadProvider',
            action: 'addFiles',
            data: { 
              driveId: effectiveDriveId,
              activeDriveId,
              primaryDriveId
            }
          });
          
          // Update last active drive with the fallback
          updateLastActiveDrive(effectiveDriveId);
        }
      }
    }
    
    // Check if we're in encrypted mode
    const isEncryptedMode = encryptedDrive.isEncryptedMode;
  
    if (!effectiveDriveId) {
      logger.error('Upload failed: No drive ID available', {
        component: 'UploadProvider',
        action: 'addFiles'
      });
      showToast('Please select a drive before uploading', 'error');
      return;
    }
  
    // Log upload info
    logger.debug('Adding files to upload queue', {
      component: 'UploadProvider',
      action: 'addFiles',
      data: {
        fileCount: files.length,
        isEncryptedMode,
        concurrentLimit: getConcurrentLimit(),
        encryptedDriveId: encryptedDrive.encryptedDriveId,
        encryptionTier: encryptedDrive.encryptionTier,
        parentId
      }
    });
    
    // Check if we have required encryption info before adding files
    if (isEncryptedMode && !encryptedDrive.encryptedDriveId) {
      logger.error('Upload failed: Missing encrypted drive ID', {
        component: 'UploadProvider',
        action: 'addFiles',
        data: {
          encryptedDriveData: encryptedDrive
        }
      });
      showToast('Cannot upload to encrypted drive: Missing drive information', 'error');
      return;
    }

    // For encrypted uploads, pre-verify that keys are available
    if (isEncryptedMode) {
      try {
        // Import dynamically to avoid circular dependencies
        encryptionServiceModule = await import('@/services/encrypted-drive/encryption-singleton');
        const { encryptionService } = encryptionServiceModule;
        
        const keys = encryptionService.getDecryptedKeys(encryptedDrive.encryptedDriveId!);
        if (!keys || !keys.metadataKey || !keys.contentKey) {
          logger.error('Upload failed: Missing encryption keys', {
            component: 'UploadProvider',
            action: 'addFiles',
            data: {
              driveId: encryptedDrive.encryptedDriveId,
              hasKeys: !!keys,
              hasMetadataKey: keys?.metadataKey ? true : false,
              hasContentKey: keys?.contentKey ? true : false
            }
          });
          showToast('Cannot upload to encrypted drive: Drive not unlocked. Please unlock the drive first.', 'error');
          return;
        }
        
        logger.debug('Encryption keys verified before upload', {
          component: 'UploadProvider',
          action: 'addFiles',
          data: {
            driveId: encryptedDrive.encryptedDriveId,
            keysAvailable: true
          }
        });
      } catch (error) {
        logger.error('Failed to verify encryption keys', {
          component: 'UploadProvider',
          action: 'addFiles',
          error
        });
        showToast('Cannot upload to encrypted drive: Drive might not be unlocked', 'error');
        return;
      }
    }
  
    // Create upload file objects with more explicit processing status
    const uploadFiles: UploadFile[] = files.map((file: any) => {
      // Log each file being processed to help with debugging
      logger.debug('Creating upload file object', {
        component: 'UploadProvider',
        action: 'createUploadFile',
        data: {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          isEncryptedMode,
          driveId: isEncryptedMode ? encryptedDrive.encryptedDriveId : effectiveDriveId
        }
      });
      
      return {
        id: generateId(),
        file,
        parentId: parentId || '', // Ensure parentId is never null/undefined
        driveId: isEncryptedMode ? 
          encryptedDrive.encryptedDriveId || effectiveDriveId : 
          effectiveDriveId,
        progress: 0,
        status: 'queued' as UploadStatus, // Start as queued, not pending
        createdAt: Date.now(),
        isEncrypted: isEncryptedMode,
        encryptionTier: encryptedDrive.encryptionTier,
        encryptionStatus: isEncryptedMode ? 'processing' : undefined, // Start with processing instead of encrypting
        message: 'Preparing for upload...'
      };
    });
  
    // Add to state and show upload UI
    dispatch({ type: 'ADD_FILES', payload: uploadFiles });
    dispatch({ type: 'SET_VISIBLE', payload: true });
    
    // Set active uploads flag to true
    updateActiveUploadsFlag(true);
    
    // Add to queue reference
    uploadQueueRef.current = [...uploadQueueRef.current, ...uploadFiles];
    
    // Start processing the queue
    processQueue();
    
    // Additional log to confirm files were added to queue
    logger.debug('Files added to upload queue, starting processing', {
      component: 'UploadProvider',
      action: 'addFiles',
      data: {
        fileCount: uploadFiles.length,
        queueLength: uploadQueueRef.current.length
      }
    });
  }, [
    activeDriveId, 
    primaryDriveId, 
    encryptedDrive, 
    showToast, 
    processQueue, 
    getConcurrentLimit,
    updateActiveUploadsFlag
  ]);
 
  const removeFile = useCallback((fileId: string) => {
    logger.debug('Removing file from queue', {
      component: 'UploadProvider',
      action: 'removeFile',
      data: { fileId }
    });
    dispatch({ type: 'REMOVE_FILE', payload: fileId });
    
    // Remove from queue reference
    uploadQueueRef.current = uploadQueueRef.current.filter(f => f.id !== fileId);
  }, []);

  const clearCompleted = useCallback(() => {
    logger.debug('Clearing completed uploads', {
      component: 'UploadProvider',
      action: 'clearCompleted'
    });
    dispatch({ type: 'CLEAR_COMPLETED' });
  }, []);

  const cancelUpload = useCallback(async (fileId: string) => {
    logger.debug('Cancelling upload', {
      component: 'UploadProvider',
      action: 'cancelUpload',
      data: { fileId }
    });

    try {
      const file = state.queue.find((f: any) => f.id === fileId);
      if (file && file.status === 'uploading') {
        await uploadOrchestrator.cancelUpload(fileId, file.driveId || '');
      }
      
      dispatch({
        type: 'UPDATE_FILE',
        payload: {
          id: fileId,
          updates: { status: 'cancelled' as UploadStatus }
        }
      });
      
      // Remove from active uploads
      dispatch({ type: 'REMOVE_ACTIVE_UPLOAD', payload: fileId });
      
      // Process next in queue
      setTimeout(() => processQueue(), 0);
    } catch (error) {
      logger.error('Error cancelling upload:', {
        component: 'UploadProvider',
        error,
        data: { fileId }
      });
    }
  }, [state.queue, processQueue]);

  // Add pause/resume functionality
  const pauseUpload = useCallback((fileId: string) => {
    logger.debug('Pausing upload', {
      component: 'UploadProvider',
      action: 'pauseUpload',
      data: { fileId }
    });
    
    dispatch({
      type: 'UPDATE_FILE',
      payload: {
        id: fileId,
        updates: { status: 'paused' as UploadStatus }
      }
    });
    
    // Remove from active uploads and process next
    dispatch({ type: 'REMOVE_ACTIVE_UPLOAD', payload: fileId });
    setTimeout(() => processQueue(), 0);
  }, [processQueue]);
  
  const resumeUpload = useCallback((fileId: string) => {
    logger.debug('Resuming upload', {
      component: 'UploadProvider',
      action: 'resumeUpload',
      data: { fileId }
    });
    
    // Get the file
    const file = state.queue.find((f: any) => f.id === fileId);
    if (!file) return;
    
    // Add to queue reference - Changed to use 'queued' status 
    uploadQueueRef.current.push({...file, status: 'queued' as UploadStatus});
    
    // Update status
    dispatch({
      type: 'UPDATE_FILE',
      payload: {
        id: fileId,
        updates: { status: 'queued' as UploadStatus }
      }
    });
    
    // Process queue
    processQueue();
  }, [state.queue, processQueue]);

  const value: UploadContextValue = {
    // State
    queue: state.queue,
    isVisible: state.isVisible,
    isMinimized: state.isMinimized,
    isAcceptingFiles: state.isAcceptingFiles,
    activeUpload: state.activeUpload,
  
    // Methods
    addFiles,
    removeFile,
    clearCompleted,
    cancelUpload,
    updateEncryptionProgress,
    pauseUpload,
    resumeUpload,
    // Internal state management methods
    setIsVisible: (visible: boolean) => dispatch({ type: 'SET_VISIBLE', payload: visible }),
    setIsMinimized: (minimized: boolean) => dispatch({ type: 'SET_MINIMIZED', payload: minimized }),
    setAcceptingFiles: (accepting: boolean) => dispatch({ type: 'SET_ACCEPTING', payload: accepting })
  };

  // Listen for GLOBAL drive lock events
  // This uses localStorage to detect when ALL drives have been locked
  React.useEffect(() => {
    const checkGlobalDriveLock = () => {
      try {
        const lockEventTimestamp = localStorage.getItem('drives_all_locked_timestamp');
        if (lockEventTimestamp) {
          // Check if this is a new lock event (within the last 5 seconds)
          const lockTime = parseInt(lockEventTimestamp, 10);
          const now = Date.now();
          const isRecentLock = now - lockTime < 5000; // 5 seconds
          
          if (isRecentLock && !isGlobalDriveLockedRef.current) {
            logger.warn('All drives have been locked globally', {
              component: 'UploadProvider',
              data: {
                timestamp: new Date(lockTime).toISOString()
              }
            });
            
            isGlobalDriveLockedRef.current = true;
            
            // Find all active uploads and mark them as failed
            const activeUploads = state.queue.filter(file => 
              ['queued', 'preparing', 'uploading'].includes(file.status)
            );
            
            if (activeUploads.length > 0) {
              logger.warn('Cancelling all active uploads due to global drive lock', {
                component: 'UploadProvider',
                data: {
                  activeUploadsCount: activeUploads.length
                }
              });
              
              // Cancel all active uploads
              activeUploads.forEach(upload => {
                dispatch({
                  type: 'UPDATE_FILE',
                  payload: {
                    id: upload.id,
                    updates: {
                      status: 'error',
                      error: 'All drives locked - upload canceled'
                    }
                  }
                });
              });
              
              showToast('All drives locked, uploads canceled', 'warning');
            }
            
            // Keep upload panel visible to show the failed uploads
            dispatch({ type: 'SET_ACCEPTING', payload: false });
          }
        } else {
          // Reset the flag if the lock event is cleared
          isGlobalDriveLockedRef.current = false;
        }
      } catch (error) {
        logger.error('Error checking global drive lock', {
          component: 'UploadProvider',
          error
        });
      }
    };
    
    // Check immediately on mount
    checkGlobalDriveLock();
    
    // Set up a periodic check every second
    const intervalId = setInterval(checkGlobalDriveLock, 1000);
    
    return () => {
      clearInterval(intervalId);
    };
  }, [state.queue, showToast]);

  // Listen for drive lock/unlock events and handle active uploads accordingly
  React.useEffect(() => {
    // Check if the encrypted drive state has changed from unlocked to locked
    if (encryptedDrive.encryptedDriveId && !encryptedDrive.isEncryptedMode) {
      // If there are active uploads for this drive, cancel them
      const driveUploads = state.queue.filter(file => 
        file.driveId === encryptedDrive.encryptedDriveId && 
        ['queued', 'preparing', 'uploading'].includes(file.status)
      );
      
      if (driveUploads.length > 0) {
        logger.warn('Drive locked while uploads in progress, cancelling uploads', {
          component: 'UploadProvider',
          data: {
            driveId: encryptedDrive.encryptedDriveId,
            affectedUploads: driveUploads.length
          }
        });
        
        // Mark all uploads for this drive as failed
        driveUploads.forEach(upload => {
          dispatch({
            type: 'UPDATE_FILE',
            payload: {
              id: upload.id,
              updates: { 
                status: 'error', 
                error: 'Drive locked during upload'
              }
            }
          });
        });
        
        showToast('Drive locked, uploads canceled', 'warning');
      }
      
      // Remove this drive from the unlocked drives list
      unlockedDrivesRef.current = unlockedDrivesRef.current.filter(id => id !== encryptedDrive.encryptedDriveId);
    }
    
    // Check if we have no active drive (all drives locked)
    const hasNoDrive = !activeDriveId && !primaryDriveId;
    if (hasNoDrive) {
      // If no drive available, disable accepting new uploads
      dispatch({ type: 'SET_ACCEPTING', payload: false });
      
      // Update UI to reflect no available drives
      // Keep the upload panel visible if there are items, but disable adding new files
      logger.debug('No active drives available, disabling uploads', {
        component: 'UploadProvider',
        data: {
          hasItems: state.queue.length > 0
        }
      });
      
      // Show toast if we have items
      if (state.queue.length > 0 && state.isVisible) {
        showToast('No drives available, new uploads disabled', 'warning');
      }
    } else {
      // Re-enable accepting uploads if a drive is available
      dispatch({ type: 'SET_ACCEPTING', payload: true });
    }
  }, [
    encryptedDrive.encryptedDriveId, 
    encryptedDrive.isEncryptedMode, 
    activeDriveId, 
    primaryDriveId,
    state.queue,
    state.isVisible,
    showToast
  ]);

  // Clean up heartbeat interval on component unmount
  React.useEffect(() => {
    return () => {
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
        heartbeatIntervalRef.current = null;
      }
    };
  }, []);

  return (
    <UploadContext.Provider value={value}>
      {children}
    </UploadContext.Provider>
  );
};