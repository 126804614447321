/**
 * @file src/utils/date-utils.ts
 * @description Date manipulation utilities with timezone awareness
 * @version 1.0.0
 * @created 2025-03-03
 */

import { format, startOfDay, endOfDay } from 'date-fns';
import { logger } from './logger';

/**
 * Ensures a date is set to the start of the day (00:00:00.000)
 */
export function createStartDate(date: Date | null | undefined): Date | null {
    if (!date) return null;

    // Create a new date set to midnight (00:00:00.000)
    const startDate = startOfDay(date);

    logger.debug("Creating start date:", {
        original: date.toISOString(),
        formatted: startDate.toISOString(),
        dateComponents: {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            day: startDate.getDate(),
            hours: startDate.getHours(),
            minutes: startDate.getMinutes(),
            seconds: startDate.getSeconds(),
            ms: startDate.getMilliseconds()
        }
    } as any);

    return startDate;
}

/**
 * Ensures a date is set to the end of the day (23:59:59.999)
 */
export function createEndDate(date: Date | null | undefined): Date | null {
    if (!date) return null;

    // Create a new date set to the end of the day (23:59:59.999)
    const endDate = endOfDay(date);

    logger.debug("Creating end date:", {
        original: date.toISOString(),
        formatted: endDate.toISOString(),
        dateComponents: {
            year: endDate.getFullYear(),
            month: endDate.getMonth() + 1,
            day: endDate.getDate(),
            hours: endDate.getHours(),
            minutes: endDate.getMinutes(),
            seconds: endDate.getSeconds(),
            ms: endDate.getMilliseconds()
        }
    } as any);

    return endDate;
}

/**
 * Explicitly formats a date for API request
 * Ensures the server receives a properly formatted string
 */
export function formatDateForAPI(date: Date | null | undefined): string | undefined {
    if (!date) return undefined;

    // Using toISOString for consistent serialization across all browsers
    const iso = date.toISOString();

    logger.debug("Formatting date for API:", {
        date: iso,
        timestamp: date.getTime(),
        dateObject: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            hours: date.getHours(),
            minutes: date.getMinutes(),
            seconds: date.getSeconds()
        }
    } as any);

    return iso;
}

/**
 * Creates a date range object with explicit start and end boundaries
 */
export function createDateRange(fromDate: Date | null | undefined, toDate: Date | null | undefined): {
    from: Date | null;
    to: Date | null;
} {
    // When creating a range, we want from=start of fromDate, to=end of toDate
    const from = fromDate ? createStartDate(fromDate) : null;
    const to = toDate ? createEndDate(toDate) : null;

    logger.debug("Created date range:", {
        input: {
            fromDate: fromDate?.toISOString(),
            toDate: toDate?.toISOString(),
        },
        output: {
            from: from?.toISOString(),
            to: to?.toISOString(),
        }
    } as any);

    return { from, to };
}

/**
 * Creates a simple date string in the format YYYY-MM-DD
 * This can be more reliable for some API endpoints
 */
export function createSimpleDateString(date: Date | null | undefined): string | undefined {
    if (!date) return undefined;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

/**
 * Formats a date for display
 */
export function formatDateForDisplay(date: Date | null | undefined): string {
    if (!date) return '';
    return format(date, 'PPP'); // e.g., "March 3rd, 2025"
}