/**
 * @file src/layouts/ghost/components/common/GhostErrorState.tsx
 * @description Error state component with fixed collapsible support
 * @version 1.2.0
 */

import React from "react";
import { motion } from "framer-motion";
import { AlertCircle, RefreshCw } from "lucide-react";
import { cn } from "@/utils/utils";
import { useGhostTranslation } from "@/hooks/ghost/useGhostTranslation";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/app/ghost/components/common/GhostTooltip";

interface GhostErrorStateProps {
  className?: string;
  onRetry?: () => void;
  error?: Error;
  isCollapsed?: boolean;
}

export const GhostErrorState: React.FC<GhostErrorStateProps> = ({
  className,
  onRetry,
  error,
  isCollapsed = false,
}) => {
  const { t } = useGhostTranslation();

  const collapsedContent = (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className={cn(
        "w-10 h-10", // Fixed size for consistency
        "flex items-center justify-center",
        "rounded-lg",
        "bg-[hsl(var(--ghost-surface))/0.5]",
        "border border-[hsl(var(--ghost-accent-tertiary))/0.2]",
        "transition-all duration-200",
        "hover:bg-[hsl(var(--ghost-surface))/0.8]",
        className
      )}
    >
      {onRetry ? (
        <RefreshCw className="w-5 h-5 text-[hsl(var(--ghost-accent-tertiary))]" />
      ) : (
        <AlertCircle className="w-5 h-5 text-[hsl(var(--ghost-accent-tertiary))]" />
      )}
    </motion.div>
  );

  const expandedContent = (
    <div
      className={cn(
        "flex flex-col items-center justify-center",
        "px-4 py-6",
        "text-center rounded-lg",
        "border border-[hsl(var(--ghost-accent-tertiary))/0.2]",
        "bg-[hsl(var(--ghost-surface))/0.5]",
        className
      )}
    >
      {/* Animated error icon */}
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="relative mb-4"
      >
        <div className="absolute inset-0 blur-lg opacity-50 bg-[hsl(var(--ghost-accent-tertiary))]" />
        <AlertCircle className="relative w-10 h-10 text-[hsl(var(--ghost-accent-tertiary))]" />
      </motion.div>

      <h3 className="text-sm font-medium mb-2 text-[hsl(var(--ghost-accent-tertiary))]">
        {t('error.title') as React.ReactNode}
      </h3>
      <p className="text-xs text-[hsl(var(--ghost-foreground-muted))] mb-4">
        {error?.message || t('error.description') as React.ReactNode}
      </p>

      {onRetry && (
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onRetry}
          className={cn(
            "flex items-center space-x-2",
            "px-3 py-1.5 rounded-lg",
            "bg-[hsl(var(--ghost-accent-tertiary))/0.1]",
            "border border-[hsl(var(--ghost-accent-tertiary))/0.2]",
            "text-[hsl(var(--ghost-accent-tertiary))]",
            "text-xs font-medium",
            "transition-colors duration-200",
            "hover:bg-[hsl(var(--ghost-accent-tertiary))/0.2]"
          )}
        >
          <RefreshCw className="w-3 h-3" />
          <span>{t('error.retry_button') as React.ReactNode}</span>
        </motion.button>
      )}
    </div>
  );

  if (isCollapsed) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <motion.button
            onClick={onRetry}
            whileTap={onRetry ? { scale: 0.95 } : undefined}
          >
            {collapsedContent}
          </motion.button>
        </TooltipTrigger>
        <TooltipContent side="right" className="flex flex-col gap-1">
          <p className="font-medium">{t('error.title') as React.ReactNode}</p>
          <p className="text-xs opacity-75">{error?.message || t('error.description') as React.ReactNode}</p>
          {onRetry && (
            <p className="text-xs text-[hsl(var(--ghost-accent-tertiary))]">
              {t('error.retry_tooltip') as React.ReactNode}
            </p>
          )}
        </TooltipContent>
      </Tooltip>
    );
  }

  return expandedContent;
};

export default GhostErrorState;
