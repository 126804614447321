/**
 * @file src/components/ghost/common/GhostGlass.tsx
 * @description Enhanced glassmorphism effect component using new theme system
 * @version 1.1.0
 */

import React from 'react';
import { motion, HTMLMotionProps } from 'framer-motion';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface GhostGlassProps extends React.HTMLAttributes<HTMLDivElement> {
  blur?: "none" | "sm" | "md" | "lg";
  opacity?: "low" | "medium" | "high";
  border?: boolean;
  animate?: boolean;
  rounded?: "none" | "sm" | "md" | "lg" | "xl" | "2xl" | "full";
}

export const GhostGlass: React.FC<GhostGlassProps> = ({
  blur = "md",
  opacity = "medium",
  border = true,
  animate = false,
  rounded = "lg",
  className,
  children,
  ...props
}) => {
  // Debug logging removed to reduce log spam

  const blurClasses = {
    none: "",
    sm: "backdrop-blur-sm",
    md: "backdrop-blur-md",
    lg: "backdrop-blur-lg",
  };

  const opacityClasses = {
    low: "bg-[hsl(var(--ghost-bg))]/5",
    medium: "bg-[hsl(var(--ghost-bg))]/10",
    high: "bg-[hsl(var(--ghost-bg))]/20",
  };

  const roundedClasses = {
    none: "rounded-none",
    sm: "rounded-sm",
    md: "rounded-md",
    lg: "rounded-lg",
    xl: "rounded-xl",
    "2xl": "rounded-2xl",
    full: "rounded-full",
  };

  if (animate) {
    return (
      <motion.div
        className={cn(
          // Base styles
          'relative overflow-hidden',
          blurClasses[blur],
          opacityClasses[opacity],
          roundedClasses[rounded],
          
          // Border using new theme variables
          border && 'border border-[hsl(var(--ghost-border))]/10',
          
          // Hover and animation states
          animate && 'transition-all duration-300 hover:bg-[hsl(var(--ghost-bg))]/[0.15]',
          
          // Custom classes
          className
        )}
        {...(props as HTMLMotionProps<"div">)}
      >
        {/* Content */}
        {children}
        
        {/* Gradient overlay using theme colors */}
        <div 
          className={cn(
            'absolute inset-0 pointer-events-none',
            'bg-gradient-to-b from-[hsl(var(--ghost-accent))]/5 to-transparent',
            roundedClasses[rounded]
          )} 
          aria-hidden="true"
        />
      </motion.div>
    );
  }

  return (
    <div
      className={cn(
        "relative overflow-hidden",
        blurClasses[blur],
        opacityClasses[opacity],
        roundedClasses[rounded],
        border && "border border-[hsl(var(--ghost-border))]/10",
        animate &&
          "transition-all duration-300 hover:bg-[hsl(var(--ghost-bg))]/[0.15]",
        className
      )}
      {...(props as any)} // Type assertion needed due to union type
    >
      {children}
      <div
        className={cn(
          "absolute inset-0 pointer-events-none",
          "bg-gradient-to-b from-[hsl(var(--ghost-accent))]/5 to-transparent",
          roundedClasses[rounded]
        )}
        aria-hidden="true"
      />
    </div>
  );
};

// Specialized variants
export const GhostGlassCard: React.FC<GhostGlassProps> = (props) => (
  <GhostGlass
    blur="lg"
    opacity="medium"
    rounded="xl"
    className={cn(
      'p-4',
      'hover:bg-[hsl(var(--ghost-bg))]/[0.15]',
      'transition-all duration-300',
      props.className
    )}
    {...props}
  />
);

export const GhostGlassButton: React.FC<GhostGlassProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  children,
  disabled,
  ...props
}) => (
  <button
    className={cn(
      'relative inline-flex items-center justify-center',
      'px-4 py-2 rounded-lg',
      'bg-[hsl(var(--ghost-bg))]/10',
      'backdrop-blur-md',
      'border border-[hsl(var(--ghost-accent))]/10',
      'text-[hsl(var(--ghost-foreground))]',
      'hover:bg-[hsl(var(--ghost-bg))]/[0.15]',
      'active:scale-[0.98]',
      'transition-all duration-300',
      'disabled:opacity-50 disabled:cursor-not-allowed',
      className
    )}
    disabled={disabled}
    {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
  >
    <span className="relative z-10">{children}</span>
    <div className="absolute inset-0 rounded-lg bg-gradient-to-b from-[hsl(var(--ghost-accent))]/5 to-transparent pointer-events-none" />
  </button>
);