/**
 * @file src/layouts/ghost/components/sidebar/components/QuickAccess.tsx
 * @description Quick access navigation with home, shared and cipher options
 * @version 2.0.0
 * xxxxx
 */

import React, { useState, useRef, RefObject } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { Home, Users, Key } from 'lucide-react';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { 
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/app/ghost/components/common/GhostTooltip';

interface QuickAccessProps {
  isCollapsed: boolean;
}

interface QuickAccessItem {
  id: string;
  icon: React.ElementType;
  label: string;
  path: string;
  badge?: number;
}

interface QuickAccessItemProps {
  item: QuickAccessItem;
  isActive: boolean;
  isCollapsed: boolean;
  onClick: () => void;
  updateTooltip?: (id: string, element: HTMLDivElement | null) => void;
}

const QuickAccessItem = React.forwardRef<HTMLDivElement, QuickAccessItemProps>(
  ({ item, isActive, isCollapsed, onClick, updateTooltip }, ref) => {
    const Icon = item.icon;
    const innerRef = useRef<HTMLDivElement | null>(null);
    const resolvedRef = (ref as RefObject<HTMLDivElement>) || innerRef;

    const itemContent = (
      <motion.div
        ref={resolvedRef}
        onMouseEnter={() => updateTooltip?.(item.label, resolvedRef.current)}
        whileHover={{ x: 2 }}
        className={cn(
          "w-full group cursor-pointer",
          "px-2 py-1.5 rounded-lg",
          "transition-all duration-200 ease-in-out",
          isActive
            ? "bg-[hsl(var(--ghost-surface))]"
            : "hover:bg-[hsl(var(--ghost-surface))]/50"
        )}
        onClick={onClick}
      >
        <div className="flex items-center">
          <div
            className={cn(
              "relative flex items-center justify-center",
              "h-7 w-7 rounded-lg",
              "transition-all duration-200"
            )}
          >
            <Icon
              className={cn(
                "h-4 w-4",
                isActive
                  ? "text-[hsl(var(--ghost-accent))]"
                  : "text-[hsl(var(--ghost-foreground-muted))]",
                "group-hover:text-[hsl(var(--ghost-accent))]",
                "transition-colors duration-200"
              )}
            />
            {item.badge && item.badge > 0 && (
              <div className="absolute -top-1 -right-1">
                <div
                  className={cn(
                    "min-w-[16px] h-4 px-1",
                    "rounded-full flex items-center justify-center",
                    "bg-[hsl(var(--ghost-accent))]",
                    "text-[10px] font-medium text-white"
                  )}
                >
                  {item.badge > 99 ? "99+" : item.badge}
                </div>
              </div>
            )}
          </div>

          <AnimatePresence>
            {!isCollapsed && (
              <motion.div
                initial={{ opacity: 0, x: -5 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -5 }}
                className="min-w-0 flex-1 ml-2"
              >
                <div
                  className={cn(
                    "text-sm font-medium truncate",
                    isActive
                      ? "text-[hsl(var(--ghost-accent))]"
                      : "text-[hsl(var(--ghost-foreground))]",
                    "group-hover:text-[hsl(var(--ghost-accent))]",
                    "transition-colors duration-200"
                  )}
                >
                  {item.label}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    );

    if (isCollapsed) {
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            {itemContent}
          </TooltipTrigger>
          <TooltipContent side="right">
            <div className="flex items-center space-x-2">
              <span>{item.label}</span>
              {item.badge && item.badge > 0 && (
                <span className="px-1.5 py-0.5 rounded-full text-[10px] bg-[hsl(var(--ghost-accent))]">
                  {item.badge}
                </span>
              )}
            </div>
          </TooltipContent>
        </Tooltip>
      );
    }

    return itemContent;
  }
);

QuickAccessItem.displayName = "QuickAccessItem";

export const QuickAccess: React.FC<QuickAccessProps> = ({
  isCollapsed
}) => {
  const { t } = useGhostTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [tooltipId, setTooltipId] = useState<string | null>(null);

  // Updated with Home, Shared with me, and Nygma Cipher
  const quickAccessItems: QuickAccessItem[] = [
    { 
      id: 'home',
      icon: Home, 
      label: String(t('navigation.items.home')), 
      path: '/ghost'
    },
    { 
      id: 'shared-with-me',
      icon: Users, 
      label: String(t('navigation.items.shared_with_me')), 
      path: '/ghost/shared-with-me',
      badge: 2
    },
    { 
      id: 'nygma-cipher',
      icon: Key, 
      label: String(t('navigation.items.nygma_cipher')), 
      path: '/ghost/quantum'
    }
  ];

  const updateTooltip = (id: string, _element: HTMLDivElement | null) => {
    setTooltipId(id);
  };

  // Check if path is active (exact match or starts with for nested routes)
  const isPathActive = (path: string) => {
    if (path === '/ghost' && location.pathname === '/ghost') {
      return true;
    }
    return path !== '/ghost' && location.pathname.startsWith(path);
  };
;
  // logger.debug(`Rendering quick access for current path: ${location.pathname} and tooltipId: ${tooltipId}`);

  return (
    <div className="space-y-0.5 px-1.5">
      {quickAccessItems.map((item: QuickAccessItem) => (
        <QuickAccessItem
          key={item.id}
          item={item}
          isActive={isPathActive(item.path)}
          isCollapsed={isCollapsed}
          onClick={() => navigate(item.path)}
          updateTooltip={updateTooltip}
        />
      ))}
    </div>
  );
};

export default QuickAccess;