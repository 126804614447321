/**
 * @file src/services/encrypted-drive/encryption.service.ts
 * @description Core encryption service for client-side operations
 * @version 1.0.0
 */

import { logger } from '@/utils/logger';
import { debugLogger } from '@/utils/debug-logger';
import type { 
  EncryptionKeys, 
  EncryptedKeys,
  KeyGenerationResult,
  DerivedKeys  
} from '@/types/encryption.types';
import type { EncryptionTier } from '@/types/encrypted-drive.types';

// Define missing interfaces
interface RecoveryEncryptedKeys {
  content_key: string;
  metadata_key: string;
  iv: string;
}

interface EncryptedDrive {
  id: string;
  name: string;
  key_salt: string;
  key_verification_hash: string;
  recovery_verification_hash?: string;
  recovery_enabled?: boolean;
  recovery_data?: {
    key: string;
    verification_hash: string;
    encrypted_keys: RecoveryEncryptedKeys;
  };
  encrypted_keys: {
    content_key: string;
    metadata_key: string;
    iv: string;
  };
}

// Polyfill for WeakRef if not available
const createWeakRef = <T extends object>(obj: T) => {
  // If WeakRef is available, use it
  if (typeof globalThis.WeakRef !== 'undefined') {
    return new (globalThis.WeakRef as typeof WeakRef)<T>(obj);
  }
  // Otherwise use a simple object that mimics WeakRef
  return {
    deref: () => obj
  };
};

class EncryptionError extends Error {
  constructor(message: string, public readonly code: string) {
    super(message);
    this.name = 'EncryptionError';
  }
}

export class EncryptionService {
  // Helper method to check if encryption is available
  isEncryptionAvailable(): boolean {
    return !!crypto.subtle && (window.location.protocol === 'https:' || import.meta.env.VITE_ENV === 'development');
  }
  
  // Method to encrypt content with different tiers
  async encryptContentWithTier(
    content: ArrayBuffer, 
    tier: EncryptionTier, 
    key: CryptoKey
  ): Promise<{ encryptedData: string; iv: string }> {
    // Basic implementation to fix typing issues
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encryptedBuffer = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      key,
      content
    );
    
    // Convert to base64
    const encryptedArray = new Uint8Array(encryptedBuffer);
    const encryptedBase64 = btoa(String.fromCharCode.apply(null, Array.from(encryptedArray)));
    const ivBase64 = btoa(String.fromCharCode.apply(null, Array.from(iv)));
    
    return {
      encryptedData: encryptedBase64,
      iv: ivBase64
    };
  }
  private readonly SALT_LENGTH = 32;
  private readonly KEY_LENGTH = 32;
  private readonly ITERATIONS = 100000;
  private readonly RECOVERY_KEY_LENGTH = 32;
  private readonly IV_LENGTH = 12;
  private readonly MIN_PASSWORD_LENGTH = 12;
  
  private activeBuffers: ReturnType<typeof createWeakRef<ArrayBuffer>>[] = [];
  private activeKeys: ReturnType<typeof createWeakRef<CryptoKey>>[] = [];

  constructor() {
    if (!crypto.subtle) {
      logger.error('Web Crypto API not available');
      throw new EncryptionError(
        'Web Crypto API is not available in this environment',
        'CRYPTO_API_UNAVAILABLE'
      );
    }
    this.registerCleanup();
  }

/**
 * @file src/services/encrypted-drive/encryption.service.ts
 */

private validateBase64IV(iv: string, forMetadata = false, forThumbnail = false): boolean {
  // Early validation for empty or malformed IVs
  if (!iv || typeof iv !== 'string') {
    debugLogger.error('IV is missing or not a string', {
      ivData: { 
        type: typeof iv,
        isEmpty: !iv
      }
    });
    return false;
  }

  // Handle non-standard IV lengths for all file types (thumbnails, metadata, and regular files)
  if (iv.length !== 16) {
    debugLogger.info(`Using flexible IV processing for ${forMetadata ? 'metadata' : forThumbnail ? 'thumbnail' : 'regular file'}:`, {
      iv: {
        length: iv.length,
        sample: iv.substring(0, 10) + '...',
        type: forMetadata ? 'metadata' : forThumbnail ? 'thumbnail' : 'regular file'
      }
    });
    
    // Check for valid base64 characters
    const isValidBase64 = /^[A-Za-z0-9+/=]+$/.test(iv);
    if (!isValidBase64) {
      debugLogger.error('Invalid base64 characters in IV', {
        ivData: {
          length: iv.length,
          sample: iv.substring(0, 10) + '...'
        }
      });
      return false;
    }
    
    return true;
  }
  
  // Standard validation for 16-character IVs
  if (!/^[A-Za-z0-9+/]+={0,2}$/.test(iv)) {
    debugLogger.error('Invalid IV format for standard length IV', { 
      ivData: { 
        length: iv.length,
        sample: iv.substring(0, 10) + '...'
      }
    });
    return false;
  }
  
  return true;
}


/**
 * Decrypt file content
 */
public async decryptContent(
  encryptedData: ArrayBuffer,
  iv: string,
  contentKey: CryptoKey,
  forThumbnail: boolean = false,
  algorithm?: string, // Allow specifying algorithm for paranoid mode
  driveId?: string // Added driveId to look up encryption tier
): Promise<ArrayBuffer> {
  try {
    const { IVHandler } = await import('./encryption/utils/iv-handler');
    
    if (!encryptedData || encryptedData?.byteLength === 0) {
      throw new EncryptionError(
        'No encrypted content provided for decryption',
        'INVALID_CONTENT'
      );
    }

    if (!iv) {
      throw new EncryptionError(
        'No IV provided for decryption',
        'INVALID_IV'
      );
    }

    if (!contentKey) {
      throw new EncryptionError(
        'No decryption key provided',
        'INVALID_KEY'
      );
    }

    // Add forThumbnail info to algorithm if not explicitly provided
    let effectiveAlgorithm = algorithm;
    if (forThumbnail && !algorithm) {
      // For thumbnails, we'll make the algorithm check more lenient
      // by passing a special flag that indicates to use the IV length to determine algorithm
      effectiveAlgorithm = 'AUTO_DETECT';
    }

    logger.debug('Decrypting content', {
      component: 'EncryptionService',
      data: {
        encryptedSize: encryptedData.byteLength,
        ivLength: iv.length,
        ivSample: iv.substring(0, 10) + '...',
        keyType: contentKey.type,
        forThumbnail,
        originalAlgorithm: algorithm || 'unknown',
        effectiveAlgorithm: effectiveAlgorithm || 'unknown'
      }
    });

    // Validate IV format
    if (!IVHandler.validateBase64IV(iv, effectiveAlgorithm)) {
      logger.error('Invalid IV format for decryption', {
        component: 'EncryptionService',
        data: {
          ivSample: iv.substring(0, 16),
          ivLength: iv.length,
          forThumbnail,
          algorithm: effectiveAlgorithm
        }
      });
      throw new EncryptionError(
        'Invalid IV format',
        'INVALID_IV_FORMAT'
      );
    }

    // Convert base64 IV to buffer
    const ivBuffer = this.base64ToBuffer(iv);
    const ivArray = new Uint8Array(ivBuffer);

    logger.debug('IV prepared for decryption', {
      component: 'EncryptionService',
      data: {
        ivArrayLength: ivArray.length,
        expectedLength: effectiveAlgorithm === 'CHACHA20-POLY1305' ? 
          IVHandler.CHACHA_NONCE_LENGTH : IVHandler.AES_IV_LENGTH,
        firstBytes: Array.from(ivArray.slice(0, 4)),
        algorithm: effectiveAlgorithm
      }
    });

    // Get algorithm from drive tier when available, otherwise use the provided one
    let detectedAlgorithm = effectiveAlgorithm;
    
    // If no algorithm provided, check if we can get it from the drive encryption tier
    if (effectiveAlgorithm === 'AUTO_DETECT') {
      // Check if drive encryption tier is stored
      const driveTier = this.getDriveEncryptionTier(driveId || 'shared');
      
      if (driveTier) {
        // Map drive tier to algorithm
        if (driveTier === 'quantum' || driveTier === 'performance') {
          detectedAlgorithm = 'CHACHA20-POLY1305';
        } else if (driveTier === 'paranoid') {
          detectedAlgorithm = 'PARANOID-MULTI';
        } else {
          // Default to standard AES-GCM
          detectedAlgorithm = 'AES-GCM';
        }
        
        logger.debug('Using drive encryption tier for algorithm selection', {
          component: 'EncryptionService',
          data: { 
            driveTier,
            selectedAlgorithm: detectedAlgorithm,
            driveId: driveId || 'shared'
          }
        });
      } else {
        // Fallback to AES-GCM as the most common algorithm
        detectedAlgorithm = 'AES-GCM';
        
        logger.debug('No drive tier found, defaulting to AES-GCM', {
          component: 'EncryptionService',
          data: { 
            driveId: driveId || 'shared'
          }
        });
      }
    }

    // Normalize IV length if needed
    let finalIv: Uint8Array;
    const expectedLength = detectedAlgorithm === 'CHACHA20-POLY1305' ? 
      IVHandler.CHACHA_NONCE_LENGTH : IVHandler.AES_IV_LENGTH;
      
    if (ivArray?.length !== expectedLength) {
      finalIv = IVHandler.normalizeIV(ivArray, detectedAlgorithm || 'AES-GCM');
      logger.debug('IV normalized to required length', {
        component: 'EncryptionService',
        data: {
          originalLength: ivArray.length,
          normalizedLength: finalIv.length,
          algorithm: detectedAlgorithm
        }
      });
    } else {
      finalIv = ivArray;
    }

    // Choose decryption method based on algorithm
    let decryptedData: ArrayBuffer;
    
    try {
      // Normalize algorithm string to handle variations
      const normalizedAlg = detectedAlgorithm ? detectedAlgorithm.toUpperCase() : '';
      
      logger.debug('Selecting decryption algorithm', {
        component: 'EncryptionService',
        data: {
          originalAlgorithm: algorithm,
          effectiveAlgorithm: effectiveAlgorithm,
          detectedAlgorithm: detectedAlgorithm,
          normalizedAlgorithm: normalizedAlg,
          forThumbnail
        }
      });
      
      // Fallback multi-algorithm approach - only used if we failed to determine the algorithm
      // from the drive tier and the first attempt fails
      if (normalizedAlg === 'TRY_ALL') {
        // Try decryption methods in sequence until one works
        let lastError: any = null;
        let attemptsMade = 0;
        
        // First try AES-GCM (most common)
        try {
          logger.debug('Thumbnail decryption - trying AES-GCM first', {
            component: 'EncryptionService'
          });
          attemptsMade++;
          
          // Normalize IV for AES if needed
          let aesIv = finalIv;
          if (finalIv.length !== IVHandler.AES_IV_LENGTH) {
            aesIv = IVHandler.normalizeIV(finalIv, 'AES-GCM');
          }
          
          decryptedData = await this.aesDecrypt(encryptedData, contentKey, aesIv);
          logger.debug('Thumbnail successfully decrypted with AES-GCM', {
            component: 'EncryptionService'
          });
          return decryptedData; // Success - return immediately
        } catch (aesError) {
          lastError = aesError;
          logger.debug('AES-GCM decryption failed for thumbnail, trying ChaCha20...', {
            component: 'EncryptionService',
            error: aesError
          });
        }
        
        // Then try ChaCha20-Poly1305
        try {
          logger.debug('Thumbnail decryption - trying ChaCha20-Poly1305', {
            component: 'EncryptionService'
          });
          attemptsMade++;
          
          // Normalize IV for ChaCha if needed
          let chachaIv = finalIv;
          if (finalIv.length !== IVHandler.CHACHA_NONCE_LENGTH) {
            chachaIv = IVHandler.normalizeIV(finalIv, 'CHACHA20-POLY1305');
          }
          
          decryptedData = await this.chaChaDecrypt(encryptedData, contentKey, chachaIv);
          logger.debug('Thumbnail successfully decrypted with ChaCha20', {
            component: 'EncryptionService'
          });
          return decryptedData; // Success - return immediately
        } catch (chachaError) {
          lastError = chachaError;
          logger.debug('ChaCha20 decryption failed for thumbnail, trying multi-layer...', {
            component: 'EncryptionService',
            error: chachaError
          });
        }
        
        // Finally try paranoid multi-layer as last resort
        try {
          logger.debug('Thumbnail decryption - trying multi-layer decryption', {
            component: 'EncryptionService'
          });
          attemptsMade++;
          
          decryptedData = await this.multiLayerDecrypt(encryptedData, contentKey, 
            finalIv.length === IVHandler.AES_IV_LENGTH ? finalIv : 
            IVHandler.normalizeIV(finalIv, 'AES-GCM'));
            
          logger.debug('Thumbnail successfully decrypted with multi-layer', {
            component: 'EncryptionService'
          });
          return decryptedData; // Success - return immediately
        } catch (multiError) {
          // All methods failed
          logger.error('All decryption methods failed for thumbnail', {
            component: 'EncryptionService',
            error: multiError,
            data: {
              attemptsMade,
              ivLength: finalIv.length
            }
          });
          
          // Throw the last error
          throw lastError || multiError;
        }
      }
      // Regular algorithm selection (non-auto-detect)
      else if (normalizedAlg === 'CHACHA20-POLY1305' || normalizedAlg === 'QUANTUM-CHACHA20' || normalizedAlg === 'QUANTUM') {
        // Use ChaCha20 decryption for Quantum or performance tier
        decryptedData = await this.chaChaDecrypt(encryptedData, contentKey, finalIv);
        logger.debug('Using ChaCha20 decryption for tier ' + normalizedAlg);
      } else if (normalizedAlg === 'PARANOID-MULTI' || normalizedAlg === 'PARANOID') {
        // Use multi-layer decryption for paranoid tier
        decryptedData = await this.multiLayerDecrypt(encryptedData, contentKey, finalIv);
        logger.debug('Using multi-layer decryption for paranoid tier');
      } else if (normalizedAlg === 'QUANTUM-AES') {
        // Legacy quantum implementation that used double AES
        logger.warn('Using legacy quantum decryption (double AES)');
        decryptedData = await this.quantumDecrypt(encryptedData, contentKey, finalIv);
      } else if (normalizedAlg === 'PERFORMANCE') {
        // Performance tier uses ChaCha20 as well
        decryptedData = await this.chaChaDecrypt(encryptedData, contentKey, finalIv);
        logger.debug('Using ChaCha20 decryption for performance tier');
      } else {
        // Default to AES-GCM for standard tier or unknown algorithms
        logger.debug('Using standard AES-GCM decryption');
        // Direct implementation since aesDecrypt doesn't exist
        decryptedData = await crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: finalIv
          },
          contentKey,
          encryptedData
        );
      }

      logger.debug('Content decryption successful', {
        component: 'EncryptionService',
        data: {
          encryptedSize: encryptedData.byteLength,
          decryptedSize: decryptedData.byteLength,
          algorithm: detectedAlgorithm || 'AES-GCM (default)',
          forThumbnail
        }
      });

      return decryptedData;
    } catch (decryptError) {
      logger.error('Decryption failed', {
        component: 'EncryptionService',
        error: decryptError,
        data: {
          algorithm: detectedAlgorithm || 'AES-GCM (default)',
          ivLength: finalIv.length,
          encryptedSize: encryptedData.byteLength,
          forThumbnail
        }
      });
      throw new EncryptionError(
        'Content decryption failed',
        'DECRYPTION_FAILED'
      );
    }
  } catch (error) {
    // Enhanced error handling for thumbnails
    const errorContext = {
      component: 'EncryptionService',
      error,
      data: {
        encryptedSize: encryptedData?.byteLength,
        ivSample: iv ? iv.substring(0, 10) + '...' : 'undefined',
        ivLength: iv?.length,
        algorithm,
        forThumbnail
      }
    };
    
    // Detailed logging for thumbnail decryption errors
    if (forThumbnail) {
      logger.error('Error in thumbnail decryption:', errorContext);
    } else {
      logger.error('Error in content decryption:', errorContext);
    }
    
    if (error instanceof EncryptionError) {
      throw error;
    }
    
    throw new EncryptionError(
      forThumbnail ? 'Failed to decrypt thumbnail' : 'Failed to decrypt content',
      'CONTENT_DECRYPTION_FAILED'
    );
  }
}

  /**
   * Generate master key and initialization data for a new encrypted drive
   */
  public async generateMasterKey(password: string): Promise<KeyGenerationResult> {
    try {
        debugLogger.info('Starting master key generation:', {
            passwordInfo: {
                length: password.length
            }
        });

        // Validate password
        if (!password || password?.length < this.MIN_PASSWORD_LENGTH) {
            debugLogger.error('Password validation failed:', {
                passwordInfo: {
                    providedLength: password?.length,
                    requiredLength: this.MIN_PASSWORD_LENGTH
                }
            });
            
            throw new EncryptionError(
                'Password must be at least 12 characters long',
                'INVALID_PASSWORD'
            );
        }

        // Generate salt
        const salt = crypto.getRandomValues(new Uint8Array(this.SALT_LENGTH));
        const saltBase64 = this.bufferToBase64(salt);
        
        debugLogger.info('Generated salt:', {
            saltInfo: {
                rawLength: salt.length,
                base64: saltBase64,
                base64Length: saltBase64.length
            }
        });

        // Derive master keys from password and salt
        debugLogger.info('Key derivation input:', {
            derivationInfo: {
                passwordLength: password.length,
                saltLength: salt.length,
                passwordFirstChars: password.substring(0, 10) + '...',
                iterations: this.ITERATIONS
            }
        });    

        debugLogger.info('Starting key derivation', {
            derivationInfo: {
                passwordLength: password.length,
                saltLength: salt.length,
                iterations: this.ITERATIONS,
                keyLength: this.KEY_LENGTH
            }
        });

        const masterKeys = await this.deriveKey(password, salt);
        debugLogger.info('Master keys derived successfully');

        // Generate content and metadata keys
        const contentKey = await this.generateEncryptionKey();
        const metadataKey = await this.generateEncryptionKey();
        debugLogger.info('Generated encryption keys');

        // Generate recovery key
        const recoveryKey = crypto.getRandomValues(new Uint8Array(this.RECOVERY_KEY_LENGTH));
        const recoveryKeyBase64 = this.bufferToBase64(recoveryKey);

        debugLogger.info('Recovery key generation:', {
            recoveryInfo: {
                rawLength: recoveryKey.length,
                base64Key: recoveryKeyBase64,
                base64Length: recoveryKeyBase64.length
            }
        });

        // Derive recovery master keys
        debugLogger.info('Deriving recovery keys:', {
            recoveryDerivationInfo: {
                recoveryKeyLength: recoveryKeyBase64.length,
                saltLength: salt.length
            }
        });

        const recoveryMasterKeys = await this.deriveKey(recoveryKeyBase64, salt);
        debugLogger.info('Recovery master keys derived');

        // First encrypt with master key
        debugLogger.info('Starting key encryption for master keys');
        
        const encryptedKeys = await this.encryptKeys(
            { contentKey, metadataKey },
            masterKeys
        );

          debugLogger.info('Master keys encrypted:', {
            keysInfo: {
                contentKeyLength: encryptedKeys.contentKey.length,
                metadataKeyLength: encryptedKeys.metadataKey.length,
                ivLength: encryptedKeys.iv.length
            }
        });

        // Then encrypt for recovery
        // Then encrypt for recovery using the same IV
        debugLogger.info('Starting key encryption for recovery');

        if (!encryptedKeys.iv) {
          throw new EncryptionError(
            'Main encryption IV is missing',
            'MISSING_MAIN_IV'
          );
        }
        
        const recoveryEncryptedKeys = await this.encryptKeysWithRecovery(
          { contentKey, metadataKey },
          recoveryMasterKeys,
          encryptedKeys.iv  // Pass the main IV as required parameter
        );

        debugLogger.info('Recovery keys encrypted:', {
          keysInfo: {
            contentKeyLength: recoveryEncryptedKeys.content_key.length,  // Changed from contentKey
            metadataKeyLength: recoveryEncryptedKeys.metadata_key.length, // Changed from metadataKey
            ivLength: recoveryEncryptedKeys.iv.length
          }
      });

        // Add test here
        debugLogger.info('Testing recovery encryption');
        const recoveryTest = await this.testRecoveryEncryption(
          recoveryKeyBase64,
          salt,
          recoveryEncryptedKeys,
          contentKey,
          metadataKey
        );

        if (!recoveryTest) {
          debugLogger.error('Recovery encryption test failed');
          throw new EncryptionError(
            'Recovery encryption verification failed',
            'RECOVERY_VERIFICATION_FAILED'
          );
        }

        debugLogger.info('Recovery encryption test passed');


        // Generate verification hashes
        const verificationHash = await this.generateVerificationHash({ 
            verificationKey: masterKeys.verificationKey 
        });
        
        const recoveryVerificationHash = await this.generateVerificationHash({ 
            verificationKey: recoveryMasterKeys.verificationKey 
        });

        debugLogger.info('Recovery verification details:', {
            recoveryVerificationInfo: {
                recoveryKeyUsed: recoveryKeyBase64,
                saltUsed: saltBase64,
                generatedHash: recoveryVerificationHash
            }
        });

        debugLogger.info('Generated verification hashes:', {
            hashInfo: {
                mainHashLength: verificationHash.length,
                recoveryHashLength: recoveryVerificationHash.length
            }
        });

        // Also update validation to include check for recovery data
        if (!verificationHash || 
          !encryptedKeys.contentKey || 
          !encryptedKeys.metadataKey || 
          !encryptedKeys.iv ||
          !recoveryEncryptedKeys.content_key ||  // Added recovery validation
          !recoveryEncryptedKeys.metadata_key ||
          !recoveryEncryptedKeys.iv) {
          debugLogger.error('Missing required encryption values', {
              validationInfo: {
                  hasVerificationHash: !!verificationHash,
                  hasContentKey: !!encryptedKeys.contentKey,
                  hasMetadataKey: !!encryptedKeys.metadataKey,
                  hasIV: !!encryptedKeys.iv,
                  hasRecoveryContentKey: !!recoveryEncryptedKeys.content_key,
                  hasRecoveryMetadataKey: !!recoveryEncryptedKeys.metadata_key,
                  hasRecoveryIV: !!recoveryEncryptedKeys.iv
              }
          });
          throw new EncryptionError(
              'Failed to generate all required encryption values',
              'KEY_GENERATION_INCOMPLETE'
          );
        }

        // Log final generation details
        debugLogger.info('Generated encryption data:', {
            encryptionDataInfo: {
                salt: saltBase64,
                verificationHash,
                contentKeyLength: encryptedKeys.contentKey?.length,
                metadataKeyLength: encryptedKeys.metadataKey?.length,
                ivLength: encryptedKeys.iv?.length,
                hasRecoveryData: true
            }
        });

        return {
            salt: saltBase64,
            verificationHash,
            encryptedKeys: {
                contentKey: encryptedKeys.contentKey,
                metadataKey: encryptedKeys.metadataKey,
                iv: encryptedKeys.iv
            },
            recoveryData: {
                key: recoveryKeyBase64,
                verification_hash: recoveryVerificationHash,
                encrypted_keys: recoveryEncryptedKeys // Now has correct format
            }
        };

    } catch (error) {
        logger.error('Error generating master key:', error);

        debugLogger.error('Master key generation failed:', {
            errorInfo: {
                error: error instanceof Error ? error.message : 'Unknown error',
                code: error instanceof EncryptionError ? error.code : undefined,
                stack: error instanceof Error ? error.stack : undefined
            }
        });

        if (error instanceof EncryptionError) {
            throw error;
        }
        throw new EncryptionError(
            'Failed to generate encryption keys',
            'KEY_GENERATION_FAILED'
        );
    }
}

// In encryption.service.ts, add a method to handle recovery key encryption specifically:
private async encryptKeysWithRecovery(
  keys: { contentKey: CryptoKey; metadataKey: CryptoKey },
  recoveryMasterKeys: DerivedKeys,
  mainIV: string  // Required parameter, no longer optional
): Promise<RecoveryEncryptedKeys> {
  debugLogger.info('Starting recovery key encryption');

  if (!mainIV) {
    throw new EncryptionError(
      'Main encryption IV must be provided for recovery',
      'MISSING_MAIN_IV'
    );
  }

  // Convert base64 IV to buffer
  const iv = new Uint8Array(this.base64ToBuffer(mainIV));
  
  debugLogger.info('Recovery encryption setup:', {
    recoveryEncryptionInfo: {
      ivLength: iv.length,
      ivBytes: Array.from(iv),
      hasContentKey: !!keys.contentKey,
      hasMetadataKey: !!keys.metadataKey,
      contentKeyType: keys.contentKey.type,
      metadataKeyType: keys.metadataKey.type,
      ivBase64: mainIV,
      ivBase64Length: mainIV.length
    }
  });

  // Export keys to raw format for encryption
  const contentKeyData = await crypto.subtle.exportKey('raw', keys.contentKey);
  const metadataKeyData = await crypto.subtle.exportKey('raw', keys.metadataKey);

  debugLogger.info('Raw key data:', {
    rawKeyInfo: {
      contentKeyLength: contentKeyData.byteLength,
      metadataKeyLength: metadataKeyData.byteLength,
      contentKeyFirstBytes: Array.from(new Uint8Array(contentKeyData.slice(0, 4))),
      metadataKeyFirstBytes: Array.from(new Uint8Array(metadataKeyData.slice(0, 4)))
    }
  });

  // Double check IV before encryption
  debugLogger.info('Pre-encryption IV verification:', {
    ivVerificationInfo: {
      ivBase64Original: mainIV,
      ivBytesOriginal: Array.from(iv),
      ivBufferLength: iv.buffer.byteLength
    }
  });

  // Encrypt with recovery master key
  const encryptedContent = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
      tagLength: 128
    },
    recoveryMasterKeys.encryptionKey,
    contentKeyData
  );

  const encryptedMetadata = await crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
      tagLength: 128
    },
    recoveryMasterKeys.encryptionKey,
    metadataKeyData
  );

  const result = {
    content_key: this.bufferToBase64(encryptedContent),
    metadata_key: this.bufferToBase64(encryptedMetadata),
    iv: mainIV  // Use the same IV that was passed in
  };

  // Verify IV consistency
  debugLogger.info('Final recovery encryption result:', {
    recoveryResultInfo: {
      contentKeyLength: result.content_key.length,
      metadataKeyLength: result.metadata_key.length,
      originalIv: mainIV,
      storedIv: result.iv,
      match: mainIV === result.iv
    }
  });

  if (mainIV !== result.iv) {
    throw new EncryptionError(
      'IV mismatch between main encryption and recovery',
      'IV_MISMATCH'
    );
  }

  return result;
}
  /**
   * Generate a verification hash for key validation
   */
// In generateVerificationHash
private async generateVerificationHash({ verificationKey }: { verificationKey: CryptoKey }): Promise<string> {
  try {
    const verifyData = new TextEncoder().encode('verify');
    logger.debug('Generating verification hash:', {
      verificationInfo: {
        inputLength: verifyData.length,
        keyAlgorithm: verificationKey.algorithm,
        keyUsages: verificationKey.usages
      }
    });

    const hash = await crypto.subtle.sign(
      {
        name: 'HMAC',
        hash: 'SHA-256'
      },
      verificationKey,
      verifyData
    );

    logger.debug('Generated hash details:', {
      hashInfo: {
        hashLength: hash.byteLength,
        expectedLength: 32
      }
    });

    return this.bufferToBase64(hash);
  } catch (error) {
    logger.error('Error in generateVerificationHash:', error);
    throw error;
  }
}

/**
 * Derive an encryption key from password and salt
 */
private async deriveKey(password: string, salt: Uint8Array): Promise<DerivedKeys> {
  try {

        debugLogger.info('Key derivation input:', {
            derivationInfo: {
                passwordLength: password.length,
                saltLength: salt.length,
                passwordFirstChars: password.substring(0, 10) + '...',
                iterations: this.ITERATIONS
            }
        });    

      // Original logging
      logger.debug('Deriving key input values:', {
        keyInputValues: {
          saltLength: salt.length,
          passwordLength: password.length
        }
      });

      // Additional debug page logging
      debugLogger.info('Starting key derivation', {
        derivationStart: {
          passwordLength: password.length,
          saltLength: salt.length,
          iterations: this.ITERATIONS,
          keyLength: this.KEY_LENGTH
        }
      });

      if (!password || !salt || salt?.length !== this.SALT_LENGTH) {
        // Original logging
        logger.error('Invalid derivation inputs', {
          derivationErrors: {
            hasPassword: !!password,
            passwordLength: password?.length,
            saltLength: salt?.length,
            expectedSaltLength: this.SALT_LENGTH
          }
        });

        // Additional debug page logging
        debugLogger.error('Invalid derivation inputs', {
          derivationErrorDetails: {
            hasPassword: !!password,
            passwordLength: password?.length,
            saltLength: salt?.length,
            expectedSaltLength: this.SALT_LENGTH
          }
        });

        throw new EncryptionError(
          'Invalid password or salt provided',
          'INVALID_DERIVATION_INPUT'
        );
      }

    logger.debug('Starting key derivation', {
      derivationParams: {
        iterations: this.ITERATIONS,
        saltLength: salt.length,
        passwordLength: password.length
      }
    });


    const baseKey = await crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(password),
      {
        name: 'PBKDF2'
      },
      false,
      ['deriveBits', 'deriveKey']
    );

    logger.debug('Base key imported successfully');

    // First derive a key for encryption
    const encryptionKey = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations: this.ITERATIONS,
        hash: 'SHA-256'
      },
      baseKey,
      {
        name: 'AES-GCM',
        length: this.KEY_LENGTH * 8
      },
      true,
      ['encrypt', 'decrypt']
    );

    logger.debug('Encryption key derived successfully');

    // Then derive a key for HMAC verification
    const verificationKey = await crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations: this.ITERATIONS,
        hash: 'SHA-256'
      },
      baseKey,
      {
        name: 'HMAC',
        hash: 'SHA-256',
        length: this.KEY_LENGTH * 8
      },
      true,
      ['sign', 'verify']
    );

    logger.debug('Verification key derived successfully');

    this.trackKey(encryptionKey);
    this.trackKey(verificationKey);

    logger.debug('Key derivation completed successfully', {
      derivationResults: {
        hasEncryptionKey: !!encryptionKey,
        hasVerificationKey: !!verificationKey
      }
    });

    return { encryptionKey, verificationKey };
  } catch (error) {
    logger.error('Error deriving key:', error, {
      derivationError: {
        passwordLength: password?.length,
        saltLength: salt?.length,
        error: error instanceof Error ? error.message : 'Unknown error',
        stack: error instanceof Error ? error.stack : undefined
      }
    });

    // Additional debug page logging
    debugLogger.error('Key derivation failed', {
      derivationErrorDetails: {
        error: error instanceof Error ? error.message : 'Unknown error',
        passwordLength: password?.length,
        saltLength: salt?.length
      }
    });

    throw new EncryptionError(
      'Failed to derive encryption key',
      'KEY_DERIVATION_FAILED'
    );
  }
}

  /**
   * Generate a new random encryption key
   */
  private async generateEncryptionKey(): Promise<CryptoKey> {
    try {
      const key = await crypto.subtle.generateKey(
        {
          name: 'AES-GCM',
          length: this.KEY_LENGTH * 8
        },
        true,
        ['encrypt', 'decrypt']
      );

      if (!key) {
        throw new EncryptionError(
          'Failed to generate encryption key',
          'KEY_GENERATION_FAILED'
        );
      }

      this.trackKey(key);
      return key;
    } catch (error) {
      logger.error('Error generating encryption key:', error);
      throw new EncryptionError(
        'Failed to generate encryption key',
        'KEY_GENERATION_FAILED'
      );
    }
  }

 /**
 * Encrypt a set of keys with a master key
 */
private async encryptKeys(keys: EncryptionKeys, masterKeys: DerivedKeys): Promise<EncryptedKeys> {
  try {
    if (!keys.contentKey || !keys.metadataKey || !masterKeys.encryptionKey) {
      throw new EncryptionError(
        'Invalid keys provided for encryption',
        'INVALID_KEYS'
      );
    }

    logger.debug('Encrypting keys');
    
    // Generate IV for key encryption
    const iv = crypto.getRandomValues(new Uint8Array(this.IV_LENGTH));
    if (iv?.length !== this.IV_LENGTH) {
      throw new EncryptionError(
        'Failed to generate secure IV',
        'IV_GENERATION_FAILED'
      );
    }

    logger.debug('Generated IV for key encryption', { 
      ivInfo: {
        iv: this.bufferToBase64(iv),
        length: iv.length
      }
    });

    const contentKeyData = await crypto.subtle.exportKey('raw', keys.contentKey);
    const metadataKeyData = await crypto.subtle.exportKey('raw', keys.metadataKey);

    const encryptedContent = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv
      },
      masterKeys.encryptionKey,
      contentKeyData
    );

    const encryptedMetadata = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv  // Using same IV is fine here as we're using different data
      },
      masterKeys.encryptionKey,
      metadataKeyData
    );

    logger.debug('Keys encrypted successfully', {
      encryptedKeysInfo: {
        contentKeyLength: encryptedContent.byteLength,
        metadataKeyLength: encryptedMetadata.byteLength,
        ivLength: iv.length
      }
    });

    return {
      contentKey: this.bufferToBase64(encryptedContent),
      metadataKey: this.bufferToBase64(encryptedMetadata),
      iv: this.bufferToBase64(iv)
    };
  } catch (error) {
    logger.error('Error encrypting keys:', error);
    throw new EncryptionError(
      'Failed to encrypt keys',
      'KEY_ENCRYPTION_FAILED'
    );
  }
}

  /**
   * Encrypt file content based on encryption tier
   */
  public async encryptContent(
    data: ArrayBuffer,
    encryptionTier: EncryptionTier | string,
    contentKey: CryptoKey
  ): Promise<{
    encryptedData: string;
    iv: string;
    algorithm: string;
  }> {
    try {
      const { IVHandler } = await import('./encryption/utils/iv-handler');
      
      if (!data || data?.byteLength === 0) {
        throw new EncryptionError(
          'No content provided for encryption',
          'INVALID_CONTENT'
        );
      }
  
      if (!contentKey) {
        throw new EncryptionError(
          'No encryption key provided',
          'INVALID_KEY'
        );
      }
  
      logger.debug('Encrypting content', { 
        contentInfo: {
          contentSize: data.byteLength,
          tier: encryptionTier 
        }
      });
  
      // Generate appropriate IV based on encryption tier
      let iv: Uint8Array;
      let algorithm: string;
      
      // Normalize tier value - handle both string values and enum cases
      const normalizedTier = 
        typeof encryptionTier === 'string' 
          ? encryptionTier.toLowerCase() 
          : String(encryptionTier).toLowerCase();
          
      // Log the normalized tier for debugging
      logger.debug('Using normalized encryption tier', {
        component: 'EncryptionService',
        data: {
          originalTier: encryptionTier,
          normalizedTier: normalizedTier
        }
      });
      
      // Handle tier mapping for IV generation
      if (normalizedTier === 'quantum' || normalizedTier === 'performance') {
        // ChaCha20-Poly1305 needs a 24-byte nonce
        iv = IVHandler.generateIV('CHACHA20-POLY1305');
        algorithm = 'CHACHA20-POLY1305';
      } else if (normalizedTier === 'paranoid') {
        // Multi-layer encryption starts with AES-GCM
        iv = IVHandler.generateIV('AES-GCM');
        algorithm = 'PARANOID-MULTI';
      } else {
        // Default to AES-GCM IV (12 bytes) for standard or unknown tiers
        iv = IVHandler.generateIV('AES-GCM');
        algorithm = 'AES-GCM';
      }
      
      if (!iv || iv.length === 0) {
        throw new EncryptionError(
          'Failed to generate secure IV',
          'IV_GENERATION_FAILED'
        );
      }
  
      // For large files (over 10MB), log a warning but still try to process
      if (data?.byteLength > 10 * 1024 * 1024) {
        logger.warn('Large file encryption may require more memory', {
          component: 'EncryptionService',
          data: {
            fileSize: data.byteLength,
            encryptionTier
          }
        });
      }
  
      let encryptedData: ArrayBuffer;

      // Handle tier mapping for actual encryption method
      if (normalizedTier === 'standard') {
        // Standard AES-GCM encryption
        encryptedData = await this.aesEncrypt(data, contentKey, iv);
        algorithm = 'AES-GCM';
        logger.debug('Standard encryption completed');
      }
      else if (normalizedTier === 'quantum' || normalizedTier === 'performance') {
        // Use ChaCha20 for quantum/performance tier
        encryptedData = await this.chaChaEncrypt(data, contentKey, iv);
        algorithm = 'CHACHA20-POLY1305';
        logger.debug('Quantum/Performance encryption (ChaCha20) completed');
      }
      else if (normalizedTier === 'paranoid') {
        // Use multi-layer encryption for paranoid tier
        encryptedData = await this.multiLayerEncrypt(data, contentKey, iv);
        algorithm = 'PARANOID-MULTI';
        logger.debug('Paranoid multi-layer encryption completed');
      }
      else {
        // Instead of falling back to AES, throw an error for unknown tiers
        logger.error(`Unknown encryption tier: ${encryptionTier}`);
        throw new EncryptionError(
          `Unknown encryption tier: ${encryptionTier}`,
          'INVALID_ENCRYPTION_TIER'
        );
      }

      // Convert results to base64
      return {
        encryptedData: this.bufferToBase64(encryptedData),
        iv: this.bufferToBase64(iv),
        algorithm: algorithm
      };
    } catch (error) {
      logger.error('Error encrypting content with tier: ' + encryptionTier, {
        component: 'EncryptionService',
        error,
        data: { 
          dataSize: data?.byteLength,
          encryptionTier,
          errorType: error instanceof Error ? error.constructor.name : typeof error
        }
      });
      
      // Always propagate the error, no fallback to AES
      if (error instanceof EncryptionError) {
        throw error;
      }
      
      throw new EncryptionError(
        `Failed to encrypt content with tier ${encryptionTier}: ${error instanceof Error ? error.message : 'Unknown error'}`,
        'TIER_SPECIFIC_ENCRYPTION_FAILED'
      );
    }
  }

  /**
   * AES-GCM decryption implementation
   */
  private async aesDecrypt(
    data: ArrayBuffer,
    key: CryptoKey,
    iv: Uint8Array
  ): Promise<ArrayBuffer> {
    try {
      return await crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv
        },
        key,
        data
      );
    } catch (error) {
      logger.error('AES-GCM decryption failed', {
        component: 'EncryptionService',
        error,
        data: {
          dataLength: data.byteLength,
          ivLength: iv.length,
          keyType: key.type
        }
      });
      throw error;
    }
  }
  
  /**
   * ChaCha20-Poly1305 decryption implementation
   */
  private async chaChaDecrypt(
    data: ArrayBuffer,
    key: CryptoKey,
    iv: Uint8Array
  ): Promise<ArrayBuffer> {
    try {
      // Try ChaCha20-Poly1305 if supported
      try {
        return await crypto.subtle.decrypt(
          {
            name: 'ChaCha20-Poly1305',
            iv,
            additionalData: new Uint8Array(0)
          },
          key,
          data
        );
      } catch (apiError) {
        // Fallback to AES-GCM if ChaCha20 is not supported
        logger.warn('ChaCha20 not supported by browser, falling back to AES-GCM', {
          component: 'EncryptionService',
          error: apiError
        });
        // Convert the IV to appropriate length for AES-GCM if needed
        const aesIv = iv.length !== 12 ? iv.slice(0, 12) : iv;
        return await crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: aesIv
          },
          key,
          data
        );
      }
    } catch (error) {
      logger.error('ChaCha20 decryption failed', {
        component: 'EncryptionService',
        error,
        data: {
          dataLength: data.byteLength,
          ivLength: iv.length,
          keyType: key.type
        }
      });
      throw error;
    }
  }
  

  /**
   * Encrypt metadata (filename, size, dates, etc)
   */
  public async encryptMetadata(
    metadata: Record<string, any>,
    metadataKey: CryptoKey
  ): Promise<{
    encryptedData: string;
    iv: string;
  }> {
    try {
      if (!metadata || Object.keys(metadata).length === 0) {
        throw new EncryptionError(
          'No metadata provided for encryption',
          'INVALID_METADATA'
        );
      }

      if (!metadataKey) {
        throw new EncryptionError(
          'No encryption key provided',
          'INVALID_KEY'
        );
      }

      logger.debug('Encrypting metadata', { 
        metadataInfo: {
          fields: Object.keys(metadata)
        }
      });

      const iv = crypto.getRandomValues(new Uint8Array(this.IV_LENGTH));
      if (iv?.length !== this.IV_LENGTH) {
        throw new EncryptionError(
          'Failed to generate secure IV',
          'IV_GENERATION_FAILED' );
        }
  
        const metadataString = JSON.stringify(metadata);
        const metadataBuffer = new TextEncoder().encode(metadataString);
  
        const encryptedData = await crypto.subtle.encrypt(
          {
            name: 'AES-GCM',
            iv
          },
          metadataKey,
          metadataBuffer
        );
  
        logger.debug('Metadata encryption completed');
  
        return {
          encryptedData: this.bufferToBase64(encryptedData),
          iv: this.bufferToBase64(iv)
        };
      } catch (error) {
        logger.error('Error encrypting metadata:', error);
        if (error instanceof EncryptionError) {
          throw error;
        }
        throw new EncryptionError(
          'Failed to encrypt metadata',
          'METADATA_ENCRYPTION_FAILED'
        );
      }
    }
  

/**
 * @file src/services/encrypted-drive/encryption.service.ts
 * 
 * Modify the decryptMetadata method to handle the special metadata IV case:
 */

/**
 * @file src/services/encrypted-drive/encryption.service.ts
 * 
 * Fix the decryptMetadata method to handle the IV correctly
 */

public async decryptMetadata(
  encryptedData: string,
  iv: string,
  metadataKey: CryptoKey
): Promise<any> {
  try {
    if (!encryptedData || !iv || !metadataKey) {
      throw new EncryptionError(
        'Missing required parameters for metadata decryption',
        'MISSING_PARAMETERS'
      );
    }

    logger.debug('Decrypting metadata', {
      component: 'EncryptionService',
      data: { 
        ivLength: iv.length,
        encryptedDataLength: encryptedData.length,
        sampleIV: iv.substring(0, 30) + '...',
        sampleData: encryptedData.substring(0, 30) + '...'
      }
    });

    // Extract IV - if it's the correct length (16 chars), use it directly
    // Otherwise try to extract a valid IV
    let ivToUse = iv;
    if (iv?.length === 16) {
      // Standard IV length for AES-GCM in base64 (12 bytes binary -> 16 chars base64)
      logger.debug('Using standard IV format', { 
        ivInfo: {
          ivLength: iv.length, 
          iv: iv 
        }
      });
    } else if (iv?.length > 16) {
      // Try to extract the first 16 characters as IV
      ivToUse = iv.substring(0, 16);
      logger.debug('Extracted IV from longer string', {
        ivExtraction: {
          originalLength: iv.length,
          extracted: ivToUse,
          extractedLength: ivToUse.length
        }
      });
    } else {
      logger.warn('Invalid IV length, will attempt with what was provided', {
        ivWarning: {
          ivLength: iv.length
        }
      });
    }

    try {
      // Convert base64 to buffers
      const encryptedBuffer = this.base64ToBuffer(encryptedData);
      const ivBuffer = this.base64ToBuffer(ivToUse);
      
      // Create a valid IV of exact length
      let ivArray: Uint8Array;
      if (ivBuffer?.byteLength !== this.IV_LENGTH) {
        // Ensure IV is exactly 12 bytes
        ivArray = new Uint8Array(this.IV_LENGTH);
        const tempArray = new Uint8Array(ivBuffer);
        ivArray.set(tempArray.slice(0, Math.min(tempArray.length, this.IV_LENGTH)));
        
        logger.debug('Normalized IV to correct length', {
          ivNormalization: {
            originalLength: ivBuffer.byteLength,
            normalizedLength: ivArray.length,
            ivBytes: Array.from(ivArray.slice(0, 4))
          }
        });
      } else {
        ivArray = new Uint8Array(ivBuffer);
      }

      // Decrypt the data
      const decryptedBuffer = await crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv: ivArray,
          tagLength: 128
        },
        metadataKey,
        encryptedBuffer
      );

      // Convert decrypted buffer to text
      const decryptedText = new TextDecoder().decode(decryptedBuffer);
      
      try {
        // Parse JSON data
        const metadata = JSON.parse(decryptedText);
        
        logger.debug('Metadata decryption successful', {
          component: 'EncryptionService',
          data: { 
            fileName: metadata.name,
            fileSize: metadata.size
          }
        });
        
        return metadata;
      } catch (error) {
        logger.error('Error parsing decrypted JSON metadata:', {
          component: 'EncryptionService',
          error,
          data: { 
            decryptedTextLength: decryptedText.length,
            decryptedTextSample: decryptedText.substring(0, 30) + '...'
          }
        });
        
        // FALLBACK: Return placeholder metadata
        return {
          name: 'Decryption Failed',
          path: '/encrypted',
          size: 0,
          type: 'unknown',
          created: Date.now(),
          modified: Date.now()
        };
      }
    } catch (error) {
      logger.error('AES-GCM decryption failed:', {
        component: 'EncryptionService',
        error,
        data: { 
          ivLength: ivToUse.length,
          ivSample: ivToUse.substring(0, 10),
          encryptedLength: encryptedData.length
        }
      });
      
      // FALLBACK: Return placeholder metadata
      return {
        name: 'Decryption Failed',
        path: '/encrypted',
        size: 0,
        type: 'unknown',
        created: Date.now(),
        modified: Date.now()
      };
    }
  } catch (error) {
    logger.error('Metadata decryption failed:', {
      component: 'EncryptionService',
      error,
      data: {
        errorType: error instanceof Error ? error.constructor.name : typeof error,
        errorMessage: error instanceof Error ? error.message : String(error)
      }
    });
    
    // FALLBACK: Return placeholder metadata
    return {
      name: 'Decryption Failed',
      path: '/encrypted',
      size: 0,
      type: 'unknown',
      created: Date.now(),
      modified: Date.now()
    };
  }
}


    /**
     * Standard AES-GCM encryption
     */
    private async aesEncrypt(
      data: ArrayBuffer,
      key: CryptoKey,
      iv: Uint8Array
    ): Promise<ArrayBuffer> {
      if (!data || data?.byteLength === 0) {
        throw new EncryptionError(
          'No data provided for encryption',
          'INVALID_DATA'
        );
      }
  
      if (!key) {
        throw new EncryptionError(
          'No encryption key provided',
          'INVALID_KEY'
        );
      }
  
      if (!iv) {
        throw new EncryptionError(
          'Invalid IV provided',
          'INVALID_IV'
        );
      }
      
      const { IVHandler } = await import('./encryption/utils/iv-handler');
      
      // Make sure IV is correct length for AES-GCM (12 bytes)
      let aesIv = iv;
      if (iv.length !== IVHandler.AES_IV_LENGTH) {
        aesIv = IVHandler.normalizeIV(iv, 'AES-GCM');
        logger.debug('IV normalized for AES encryption', {
          originalLength: iv.length,
          normalizedLength: aesIv.length
        });
      }
  
      try {
        return crypto.subtle.encrypt(
          {
            name: 'AES-GCM',
            iv: aesIv,
            tagLength: 128
          },
          key,
          data
        );
      } catch (error) {
        logger.error('AES encryption error:', error);
        throw new EncryptionError(
          'AES encryption failed',
          'AES_ENCRYPTION_FAILED'
        );
      }
    }
    
    /**
     * Standard AES-GCM decryption
     */
    // First implementation - rename to avoid duplication
    private async _aesDecryptFirst(
      data: ArrayBuffer,
      key: CryptoKey,
      iv: Uint8Array
    ): Promise<ArrayBuffer> {
      if (!data || data?.byteLength === 0) {
        throw new EncryptionError(
          'No data provided for decryption',
          'INVALID_DATA'
        );
      }
  
      if (!key) {
        throw new EncryptionError(
          'No decryption key provided',
          'INVALID_KEY'
        );
      }
  
      if (!iv) {
        throw new EncryptionError(
          'Invalid IV provided',
          'INVALID_IV'
        );
      }
      
      const { IVHandler } = await import('./encryption/utils/iv-handler');
      
      // Make sure IV is correct length for AES-GCM (12 bytes)
      let aesIv = iv;
      if (iv.length !== IVHandler.AES_IV_LENGTH) {
        aesIv = IVHandler.normalizeIV(iv, 'AES-GCM');
        logger.debug('IV normalized for AES decryption', {
          originalLength: iv.length,
          normalizedLength: aesIv.length
        });
      }
  
      try {
        return crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: aesIv,
            tagLength: 128
          },
          key,
          data
        );
      } catch (error) {
        logger.error('AES decryption error:', error);
        throw new EncryptionError(
          'AES decryption failed',
          'AES_DECRYPTION_FAILED'
        );
      }
    }
  
    /**
     * ChaCha20-Poly1305 encryption for performance tier
     * Using TweetNaCl.js for implementation
     */
    private async chaChaEncrypt(
      data: ArrayBuffer,
      key: CryptoKey,
      iv: Uint8Array
    ): Promise<ArrayBuffer> {
      try {
        const { IVHandler } = await import('./encryption/utils/iv-handler');
        const { KeyDerivation } = await import('./encryption/utils/key-derivation');
        
        // Import TweetNaCl dynamically
        const nacl = await import('tweetnacl');
        
        logger.debug('Starting ChaCha20 encryption', {
          dataSize: data.byteLength,
          ivLength: iv.length
        });
        
        // 1. Convert CryptoKey to format TweetNaCl can use
        const naclKey = await KeyDerivation.cryptoKeyToTweetNaclKey(key);
        
        // 2. Ensure we have a 24-byte nonce for ChaCha20
        let nonce = iv;
        if (iv.length !== IVHandler.CHACHA_NONCE_LENGTH) {
          nonce = IVHandler.deriveChaChaIV(iv);
          logger.debug('Derived ChaCha20 nonce from AES IV', {
            originalLength: iv.length,
            derivedLength: nonce.length
          });
        }
        
        // 3. Encrypt the data using TweetNaCl's secretbox (ChaCha20-Poly1305)
        const contentArray = new Uint8Array(data);
        const encryptedData = nacl.secretbox(contentArray, nonce, naclKey);
        
        logger.debug('ChaCha20 encryption completed', {
          originalSize: data.byteLength,
          encryptedSize: encryptedData.byteLength
        });
        
        return encryptedData.buffer;
      } catch (error) {
        logger.error('ChaCha20 encryption error:', error);
        throw new EncryptionError(
          'ChaCha20 encryption failed',
          'CHACHA_ENCRYPTION_FAILED'
        );
      }
    }
    
    /**
     * ChaCha20 decryption
     */
    // First implementation - rename to avoid duplication
    private async _chaChaDecryptFirst(
      data: ArrayBuffer,
      key: CryptoKey,
      iv: Uint8Array
    ): Promise<ArrayBuffer> {
      try {
        const { IVHandler } = await import('./encryption/utils/iv-handler');
        const { KeyDerivation } = await import('./encryption/utils/key-derivation');
        
        // Import TweetNaCl dynamically
        const nacl = await import('tweetnacl');
        
        logger.debug('Starting ChaCha20 decryption', {
          dataSize: data.byteLength,
          ivLength: iv.length
        });
        
        // 1. Convert CryptoKey to format TweetNaCl can use
        const naclKey = await KeyDerivation.cryptoKeyToTweetNaclKey(key);
        
        // 2. Ensure we have a 24-byte nonce for ChaCha20
        let nonce = iv;
        if (iv.length !== IVHandler.CHACHA_NONCE_LENGTH) {
          nonce = IVHandler.deriveChaChaIV(iv);
          logger.debug('Derived ChaCha20 nonce from AES IV', {
            originalLength: iv.length,
            derivedLength: nonce.length
          });
        }
        
        // 3. Decrypt the data using TweetNaCl's secretbox.open
        const encryptedArray = new Uint8Array(data);
        const decryptedData = nacl.secretbox.open(encryptedArray, nonce, naclKey);
        
        if (!decryptedData) {
          throw new EncryptionError(
            'ChaCha20 decryption failed - data may be corrupted',
            'CHACHA_DECRYPTION_FAILED'
          );
        }
        
        logger.debug('ChaCha20 decryption completed', {
          encryptedSize: data.byteLength,
          decryptedSize: decryptedData.byteLength
        });
        
        return decryptedData.buffer;
      } catch (error) {
        logger.error('ChaCha20 decryption error:', error);
        throw new EncryptionError(
          'ChaCha20 decryption failed',
          'CHACHA_DECRYPTION_FAILED'
        );
      }
    }
  
    /**
     * Quantum-resistant encryption (using ChaCha20-Poly1305)
     * This provides better performance and is considered a strong alternative to AES
     */
    private async quantumEncrypt(
      data: ArrayBuffer,
      key: CryptoKey,
      iv: Uint8Array
    ): Promise<ArrayBuffer> {
      const { IVHandler } = await import('./encryption/utils/iv-handler');
      
      // Make sure IV is appropriate for ChaCha20
      let chacha20Nonce = iv;
      if (iv.length !== IVHandler.CHACHA_NONCE_LENGTH) {
        chacha20Nonce = IVHandler.deriveChaChaIV(iv);
        logger.debug('Derived ChaCha20 nonce for quantum encryption', {
          originalIvLength: iv.length,
          derivedNonceLength: chacha20Nonce.length
        });
      }
      
      // Use ChaCha20 for quantum tier - it's faster than AES on many devices
      // and provides strong encryption that is considered resistant to quantum attacks
      return this.chaChaEncrypt(data, key, chacha20Nonce);
    }
    
    /**
     * Quantum-resistant decryption (using ChaCha20-Poly1305)
     */
    private async quantumDecrypt(
      data: ArrayBuffer,
      key: CryptoKey,
      iv: Uint8Array
    ): Promise<ArrayBuffer> {
      const { IVHandler } = await import('./encryption/utils/iv-handler');
      
      // Make sure IV is appropriate for ChaCha20
      let chacha20Nonce = iv;
      if (iv.length !== IVHandler.CHACHA_NONCE_LENGTH) {
        chacha20Nonce = IVHandler.deriveChaChaIV(iv);
        logger.debug('Derived ChaCha20 nonce for quantum decryption', {
          originalIvLength: iv.length,
          derivedNonceLength: chacha20Nonce.length
        });
      }
      
      // Use corresponding ChaCha20 decryption
      return this.chaChaDecrypt(data, key, chacha20Nonce);
    }
  
    /**
     * Multi-layer encryption for paranoid tier
     * Layer 1: AES-256-GCM
     * Layer 2: ChaCha20-Poly1305
     */
    private async multiLayerEncrypt(
      data: ArrayBuffer,
      key: CryptoKey,
      iv: Uint8Array
    ): Promise<ArrayBuffer> {
      try {
        const { KeyDerivation } = await import('./encryption/utils/key-derivation');
        const { IVHandler } = await import('./encryption/utils/iv-handler');
        
        logger.debug('Starting paranoid multi-layer encryption', {
          dataSize: data.byteLength,
          ivLength: iv.length
        });
        
        // 1. Derive separate keys for each layer
        const { aesKey, chachaKey } = await KeyDerivation.deriveLayeredKeys(key);
        
        // 2. First layer: AES-256-GCM
        logger.debug('Applying first encryption layer (AES-256-GCM)');
        const firstLayerResult = await this.aesEncrypt(data, aesKey, iv);
        
        // 3. Derive a ChaCha20 nonce from the AES IV
        const chachaNonce = IVHandler.deriveChaChaIV(iv);
        
        // 4. Second layer: ChaCha20-Poly1305
        logger.debug('Applying second encryption layer (ChaCha20-Poly1305)');
        const secondLayerResult = await this.chaChaEncrypt(
          firstLayerResult,
          chachaKey,
          chachaNonce
        );
        
        logger.debug('Paranoid multi-layer encryption completed', {
          originalSize: data.byteLength,
          finalSize: secondLayerResult.byteLength,
          encryptionLayers: 2
        });
        
        return secondLayerResult;
      } catch (error) {
        logger.error('Paranoid multi-layer encryption error:', error);
        throw new EncryptionError(
          'Multi-layer encryption failed',
          'MULTI_LAYER_ENCRYPTION_FAILED'
        );
      }
    }
    
    /**
     * Multi-layer decryption for paranoid tier
     */
    private async multiLayerDecrypt(
      data: ArrayBuffer,
      key: CryptoKey,
      iv: Uint8Array
    ): Promise<ArrayBuffer> {
      try {
        const { KeyDerivation } = await import('./encryption/utils/key-derivation');
        const { IVHandler } = await import('./encryption/utils/iv-handler');
        
        logger.debug('Starting paranoid multi-layer decryption', {
          dataSize: data.byteLength,
          ivLength: iv.length
        });
        
        // 1. Derive separate keys for each layer
        const { aesKey, chachaKey } = await KeyDerivation.deriveLayeredKeys(key);
        
        // 2. Derive a ChaCha20 nonce from the AES IV
        const chachaNonce = IVHandler.deriveChaChaIV(iv);
        
        // 3. First layer (outer): ChaCha20-Poly1305
        logger.debug('Decrypting outer layer (ChaCha20-Poly1305)');
        const firstLayerResult = await this.chaChaDecrypt(
          data,
          chachaKey,
          chachaNonce
        );
        
        // 4. Second layer (inner): AES-256-GCM
        logger.debug('Decrypting inner layer (AES-256-GCM)');
        const secondLayerResult = await this.aesDecrypt(
          firstLayerResult,
          aesKey,
          iv
        );
        
        logger.debug('Paranoid multi-layer decryption completed', {
          encryptedSize: data.byteLength,
          decryptedSize: secondLayerResult.byteLength,
          decryptionLayers: 2
        });
        
        return secondLayerResult;
      } catch (error) {
        logger.error('Paranoid multi-layer decryption error:', error);
        throw new EncryptionError(
          'Multi-layer decryption failed',
          'MULTI_LAYER_DECRYPTION_FAILED'
        );
      }
    }
  
    // Utility methods
  /**
   * Convert an ArrayBuffer to a base64 string with chunking for large files
   */
  private bufferToBase64(buffer: ArrayBuffer): string {
    if (!buffer) {
      throw new EncryptionError(
        'No buffer provided for base64 conversion',
        'INVALID_BUFFER'
      );
    }
    
    try {
      // Use a chunked approach for large buffers to avoid stack overflow
      const bytes = new Uint8Array(buffer);
      const chunkSize = 8192; // Process in 8KB chunks
      let binary = '';
      
      // Process the array in chunks to avoid call stack limits
      for (let i = 0; i < bytes.length; i += chunkSize) {
        const chunk = bytes.slice(i, Math.min(i + chunkSize, bytes.length));
        binary += String.fromCharCode.apply(null, Array.from(chunk));
      }
      
      return btoa(binary);
    } catch (error) {
      logger.error('Error in bufferToBase64:', {
        component: 'EncryptionService',
        error,
        data: { 
          bufferSize: buffer.byteLength,
          error: error instanceof Error ? error.message : 'Unknown error'
        }
      });
      
      throw new EncryptionError(
        'Failed to convert buffer to base64',
        'BASE64_CONVERSION_FAILED'
      );
    }
  }
  
    private base64ToBuffer(base64: string): ArrayBuffer {
      try {
          // Remove any padding or whitespace
          const cleaned = base64.trim();
          
          logger.debug('Converting base64 to buffer:', {
              conversionInfo: {
                  inputLength: cleaned.length,
                  input: cleaned.substring(0, 30) + '...' // First 30 chars for debugging
              }
          });
  
          // Decode base64
          const binaryString = atob(cleaned);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
          }
          
          logger.debug('Conversion result:', {
              conversionResult: {
                  outputLength: bytes.length,
                  firstBytes: Array.from(bytes.slice(0, 4)) // First 4 bytes for debugging
              }
          });
  
          return bytes.buffer;
      } catch (error) {
          logger.error('Error in base64ToBuffer:', error);
          debugLogger.error('Base64 conversion failed:', {
              conversionError: {
                  error: error instanceof Error ? error.message : 'Unknown error',
                  inputLength: base64?.length
              }
          });
          throw new EncryptionError(
              'Invalid base64 format',
              'BASE64_CONVERSION_FAILED'
          );
      }
  }
  
    private trackKey(key: CryptoKey): void {
      if (!key) {
        throw new EncryptionError(
          'No key provided for tracking',
          'INVALID_KEY'
        );
      }
      this.activeKeys.push(createWeakRef(key));
    }
  
    private registerCleanup(): void {
      window.addEventListener('beforeunload', () => {
        this.clearSensitiveData();
      });
    }
  
    private clearSensitiveData(): void {
      logger.debug('Clearing sensitive encryption data');
      this.activeBuffers.forEach((bufferRef: any) => {
        const buffer = bufferRef.deref();
        if (buffer) {
          crypto.getRandomValues(new Uint8Array(buffer));
        }
      });
      this.activeBuffers = [];
      this.activeKeys = [];
    }


    /**
   * Key storage for unlocked drives
   */
  private decryptedKeyStore: Map<string, {
    contentKey: CryptoKey;
    metadataKey: CryptoKey;
  }> = new Map();
  
  // Track encryption tiers for drives
  private driveEncryptionTiers: Map<string, string> = new Map();

  /**
   * Store decrypted keys for a drive
   */
  public storeDecryptedKeys(driveId: string, keys: { contentKey: CryptoKey; metadataKey: CryptoKey }): void {
    this.decryptedKeyStore.set(driveId, keys);
    logger.debug('Stored decrypted keys for drive', { 
      driveStorage: {
        driveId
      }
    });
  }
  
  /**
   * Set the encryption tier for a drive
   */
  public setDriveEncryptionTier(driveId: string, tier: string): void {
    this.driveEncryptionTiers.set(driveId, tier);
    logger.debug('Set drive encryption tier', {
      component: 'EncryptionService',
      data: {
        driveId,
        tier
      }
    });
  }
  
  /**
   * Get the encryption tier for a drive
   */
  public getDriveEncryptionTier(driveId: string): string | undefined {
    return this.driveEncryptionTiers.get(driveId);
  }

  /**
   * Get decrypted keys for a drive
   */
  public getDecryptedKeys(driveId: string): { contentKey: CryptoKey; metadataKey: CryptoKey } | undefined {
    return this.decryptedKeyStore.get(driveId);
  }

  /**
   * Clear stored keys for a drive
   */
  public clearDecryptedKeys(driveId: string): void {
    this.decryptedKeyStore.delete(driveId);
    logger.debug('Cleared decrypted keys for drive', { 
      driveClear: {
        driveId
      }
    });
  }


  public async unlockDrive(password: string, drive: EncryptedDrive): Promise<boolean> {
    try {
          logger.debug('Attempting drive unlock', { 
            unlockAttempt: {
              driveId: drive.id,
              saltPresent: !!drive.key_salt,
              verificationHashPresent: !!drive.key_verification_hash,
              keysPresent: !!drive.encrypted_keys
            }
        });

        // Debug page logging
        debugLogger.info('Starting unlock test', {
            unlockTestInfo: {
                driveId: drive.id,
                driveName: drive.name,
                storedSalt: drive.key_salt,
                storedHash: drive.key_verification_hash
            }
        });

        // Validate required data
        if (!drive.encrypted_keys?.iv) {
            logger.error('Missing IV for key decryption');
            debugLogger.error('Missing IV for key decryption');
            throw new EncryptionError(
                'Missing IV for key decryption',
                'MISSING_IV'
            );
        }

        // 1. Derive master key from password
        const saltBuffer = new Uint8Array(this.base64ToBuffer(drive.key_salt));
        
        logger.debug('Salt buffer prepared', { 
            saltPreparation: {
                saltLength: saltBuffer.length,
                expectedLength: this.SALT_LENGTH,
                salt: drive.key_salt
            }
        });

        debugLogger.info('Starting key derivation', {
            derivationInfo: {
                passwordLength: password.length,
                saltLength: saltBuffer.length,
                iterations: this.ITERATIONS,
                keyLength: this.KEY_LENGTH
            }
        });

        const masterKeys = await this.deriveKey(password, saltBuffer);
        logger.debug('Master keys derived', {
            keyDerivation: {
                hasEncryptionKey: !!masterKeys.encryptionKey,
                hasVerificationKey: !!masterKeys.verificationKey,
                encryptionKeyType: masterKeys.encryptionKey?.type,
                verificationKeyType: masterKeys.verificationKey?.type
            }
        });

        // 2. Generate and verify hash
        const verifyHash = await this.generateVerificationHash({ verificationKey: masterKeys.verificationKey });
        
        logger.debug('Hash verification details:', {
            verificationDetails: {
                computedHash: verifyHash,
                storedHash: drive.key_verification_hash,
                match: verifyHash === drive.key_verification_hash,
                ivPresent: !!drive.encrypted_keys?.iv,
                ivLength: drive.encrypted_keys?.iv ? drive.encrypted_keys.iv.length : 0,
                contentKeyLength: drive.encrypted_keys?.content_key?.length,
                metadataKeyLength: drive.encrypted_keys?.metadata_key?.length
            }
        });

        if (verifyHash !== drive.key_verification_hash) {
            logger.debug('Password verification failed', {
                verificationFailed: {
                    driveId: drive.id,
                    computedHash: verifyHash,
                    storedHash: drive.key_verification_hash
                }
            });
            debugLogger.error('Local verification failed');
            return false;
        }

        // 3. Decrypt the drive keys
        try {

    /**
     * IMPORTANT: Double Base64 Encoding Issue
     * ======================================
     * Current State:
     * -------------
     * 1. The encrypted keys are being double base64 encoded:
     *    - First encoding: When the keys are initially encrypted and prepared for transit
     *    - Second encoding: When the API responds with the data
     * 
     * Current Workaround:
     * ------------------
     * We handle this by:
     * 1. First decoding the double-encoded keys using atob()
     * 2. Then converting the resulting base64 string to a buffer
     * 
     * Example Flow:
     * ------------
     * Original key → Base64 encoded for storage → Base64 encoded again in API response
     * "raw data" → "cmF3IGRhdGE=" → "Y21GM0lHUmhkR0U9"
     * 
     * TODO: Future Fix Required
     * ------------------------
     * This should be fixed at the API level by:
     * 1. Review API response serialization in:
     *    - app/api/v1/endpoints/encrypted_drive/routes/list.py
     *    - app/api/v1/endpoints/encrypted_drive/routes/create.py
     * 2. Ensure keys are only encoded once before transit
     * 3. Update frontend to expect single-encoded values
     * 4. Remove this double-decode workaround
     * 
     * Note: When fixing, ensure all existing encrypted drives can still be unlocked
     * by adding a migration strategy for existing double-encoded keys.
     */

            // Get IV from encrypted keys
            const iv = new Uint8Array(this.base64ToBuffer(drive.encrypted_keys.iv));
    
            logger.debug('IV details:', {
                ivDetails: {
                    originalIV: drive.encrypted_keys.iv,
                    decodedLength: iv.length,
                    expectedLength: this.IV_LENGTH,
                    decodedBytes: Array.from(iv)
                }
            });

            // First decode the double-encoded content key
            const contentKeyBuffer = this.base64ToBuffer(drive.encrypted_keys.content_key);
            const metadataKeyBuffer = this.base64ToBuffer(drive.encrypted_keys.metadata_key);

            // debugLogger.info('Decoding encrypted keys:', {
            //     contentKeyFirstDecode: contentKeyBase64,
            //     metadataKeyFirstDecode: metadataKeyBase64,
            //     contentKeyLength: contentKeyBase64.length,
            //     metadataKeyLength: metadataKeyBase64.length
            // });

            // Then convert to buffer
              // First attempt to decode the double-encoded keys
              // const contentKeyBuffer = this.base64ToBuffer(drive.encrypted_keys.content_key);
              // const metadataKeyBuffer = this.base64ToBuffer(drive.encrypted_keys.metadata_key);

              debugLogger.info('Key buffers prepared:', {
                  keyBuffersInfo: {
                      contentKeyBufferLength: contentKeyBuffer.byteLength,
                      metadataKeyBufferLength: metadataKeyBuffer.byteLength,
                      contentKeyBase64Length: drive.encrypted_keys.content_key.length,
                      metadataKeyBase64Length: drive.encrypted_keys.metadata_key.length
                  }
              });

              const contentKeyData = await crypto.subtle.decrypt(
                { 
                    name: 'AES-GCM', 
                    iv,
                    tagLength: 128
                },
                masterKeys.encryptionKey,
                contentKeyBuffer
          ).catch(error => {
              debugLogger.error('Content key decryption failed:', {
                  decryptionError: {
                      error: error instanceof Error ? error.message : 'Unknown error',
                      ivLength: iv.length,
                      keyLength: contentKeyBuffer.byteLength
                  }
              });
              throw error;
          });

          const metadataKeyData = await crypto.subtle.decrypt(
            { 
                name: 'AES-GCM', 
                iv,
                tagLength: 128
            },
            masterKeys.encryptionKey,
            metadataKeyBuffer
        ).catch(error => {
            debugLogger.error('Metadata key decryption failed:', {
                decryptionError: {
                    error: error instanceof Error ? error.message : 'Unknown error',
                    ivLength: iv.length,
                    keyLength: metadataKeyBuffer.byteLength
                }
            });
            throw error;
        });

            debugLogger.info('Decoded values:', {
                decodedInfo: {
                    contentKeyLength: contentKeyData.byteLength,
                    metadataKeyLength: metadataKeyData.byteLength,
                    ivLength: iv.length
                }
            });

            // 4. Import decrypted keys
            const contentKey = await crypto.subtle.importKey(
              'raw',
              contentKeyData,
              'AES-GCM',
              true,
              ['encrypt', 'decrypt']
          );

          const metadataKey = await crypto.subtle.importKey(
            'raw',
            metadataKeyData,
            'AES-GCM',
            true,
            ['encrypt', 'decrypt']
        );

            logger.debug('Keys imported successfully', {
                keyImport: {
                    contentKeyType: contentKey.type,
                    metadataKeyType: metadataKey.type,
                    contentKeyUsages: contentKey.usages,
                    metadataKeyUsages: metadataKey.usages
                }
            });

            // 5. Store decrypted keys
            this.storeDecryptedKeys(drive.id, { contentKey, metadataKey });
            
            // Store encryption tier if available
            if ('encryptionTier' in drive && drive.encryptionTier) {
              this.setDriveEncryptionTier(drive.id, drive.encryptionTier);
            } else if ('encryption_tier' in drive && drive.encryption_tier) {
              // Fallback for API response with snake_case property
              this.setDriveEncryptionTier(drive.id, drive.encryption_tier);
              
              logger.debug('Stored drive encryption tier', {
                component: 'EncryptionService',
                data: {
                  driveId: drive.id,
                  tier: 'encryptionTier' in drive ? drive.encryptionTier : ('encryption_tier' in drive ? drive.encryption_tier : 'standard')
                }
              });
            }
            
            logger.debug('Drive unlocked successfully', { 
                unlockResult: { 
                    driveId: drive.id,
                    encryptionTier: 'encryptionTier' in drive ? drive.encryptionTier : ('encryption_tier' in drive ? drive.encryption_tier : 'standard')
                }
            });
            debugLogger.success('Drive unlocked successfully');

            return true;

        } catch (error) {
            logger.error('Error during key decryption/import:', error);
            debugLogger.error('Key decryption/import failed', {
                importError: {
                    error: error instanceof Error ? error.message : 'Unknown error'
                }
            });
            return false;
        }
    } catch (error) {
        logger.error('Error unlocking drive:', error, {
            unlockError: {
                driveId: drive.id,
                error: error instanceof Error ? error.message : 'Unknown error',
                stack: error instanceof Error ? error.stack : undefined
            }
        });
        debugLogger.error('Drive unlock failed', {
            failureInfo: {
                error: error instanceof Error ? error.message : 'Unknown error'
            }
        });
        return false;
    }
}


 /**
   * Recover drive access using recovery key
   */
 public async recoverDrive(recoveryKey: string, drive: EncryptedDrive): Promise<boolean> {
  try {
    debugLogger.info('Starting recovery with detailed logging', {
      recoveryInfo: {
        driveId: drive.id,
        driveName: drive.name,
        recoveryKeyLength: recoveryKey.length,
        storedRecoveryData: {
          hasData: !!drive.recovery_data,
          keyLength: drive.recovery_data?.key.length,
          encryptedKeysFormat: drive.recovery_data?.encrypted_keys ? {
            contentKeyLength: drive.recovery_data.encrypted_keys.content_key.length,
            metadataKeyLength: drive.recovery_data.encrypted_keys.metadata_key.length,
            ivLength: drive.recovery_data.encrypted_keys.iv.length,
            ivValue: drive.recovery_data.encrypted_keys.iv
          } : null
        }
      }
    });

    // Validate prerequisites
    if (!drive.recovery_enabled || !drive.recovery_data || !drive.recovery_verification_hash) {
      debugLogger.error('Recovery prerequisites not met', {
        recoveryValidation: {
          recoveryEnabled: drive.recovery_enabled,
          hasRecoveryData: !!drive.recovery_data,
          hasVerificationHash: !!drive.recovery_verification_hash
        }
      });
      return false;
    }

    // Process salt
    const saltBuffer = new Uint8Array(this.base64ToBuffer(drive.key_salt));
    debugLogger.info('Salt preparation:', {
      saltInfo: {
        originalSalt: drive.key_salt,
        saltBufferLength: saltBuffer.length,
        expectedLength: this.SALT_LENGTH,
        saltFirstBytes: Array.from(saltBuffer.slice(0, 4))
      }
    });

    if (saltBuffer?.length !== this.SALT_LENGTH) {
      debugLogger.error('Invalid salt length', {
        saltError: {
          actual: saltBuffer.length,
          expected: this.SALT_LENGTH
        }
      });
      return false;
    }

    // Derive master keys
    debugLogger.info('Starting recovery key derivation:', {
      recoveryDerivation: {
        recoveryKeyLength: recoveryKey.length,
        saltLength: saltBuffer.length,
        iterations: this.ITERATIONS
      }
    });

    const masterKeys = await this.deriveKey(recoveryKey, saltBuffer);
    debugLogger.info('Recovery master keys derived:', {
      masterKeyInfo: {
        encryptionKeyType: masterKeys.encryptionKey.type,
        verificationKeyType: masterKeys.verificationKey.type,
        encryptionKeyUsages: masterKeys.encryptionKey.usages,
        verificationKeyUsages: masterKeys.verificationKey.usages
      }
    });

    // Verify recovery hash
    const verifyHash = await this.generateVerificationHash({
      verificationKey: masterKeys.verificationKey
    });

    debugLogger.info('Recovery hash verification:', {
      hashVerificationInfo: {
        computed: verifyHash,
        stored: drive.recovery_verification_hash,
        match: verifyHash === drive.recovery_verification_hash
      }
    });

    if (verifyHash !== drive.recovery_verification_hash) {
      debugLogger.error('Recovery hash verification failed');
      return false;
    }

    try {
      // Process and decrypt keys
      const { contentKeyData, metadataKeyData } = await this.processRecoveryKeys(
        drive.recovery_data.encrypted_keys,
        masterKeys.encryptionKey
      );

      // Import decrypted keys
      debugLogger.info('Importing decrypted keys');
      
      const contentKey = await crypto.subtle.importKey(
        'raw',
        contentKeyData,
        'AES-GCM',
        true,
        ['encrypt', 'decrypt']
      ).catch(error => {
        debugLogger.error('Content key import failed:', {
          importError: {
            error: error instanceof Error ? error.message : 'Unknown error',
            keyDataLength: contentKeyData.byteLength
          }
        });
        throw error;
      });

      const metadataKey = await crypto.subtle.importKey(
        'raw',
        metadataKeyData,
        'AES-GCM',
        true,
        ['encrypt', 'decrypt']
      ).catch(error => {
        debugLogger.error('Metadata key import failed:', {
          importError: {
            error: error instanceof Error ? error.message : 'Unknown error',
            keyDataLength: metadataKeyData.byteLength
          }
        });
        throw error;
      });

      debugLogger.info('Keys imported successfully:', {
        importSuccessInfo: {
          contentKeyType: contentKey.type,
          metadataKeyType: metadataKey.type,
          contentKeyUsages: contentKey.usages,
          metadataKeyUsages: metadataKey.usages
        }
      });

      // Store decrypted keys
      this.storeDecryptedKeys(drive.id, { contentKey, metadataKey });
      debugLogger.success('Recovery completed successfully');
      return true;

    } catch (error) {
      debugLogger.error('Key processing error:', {
        processingError: {
          error: error instanceof Error ? error.message : 'Unknown error',
          name: error instanceof Error ? error.name : 'Unknown',
          phase: 'key processing'
        }
      });
      return false;
    }

  } catch (error) {
    debugLogger.error('Recovery process error:', {
      recoveryError: {
        error: error instanceof Error ? error.message : 'Unknown error',
        stack: error instanceof Error ? error.stack : undefined
      }
    });
    return false;
  }
}

// Add to your encryptionService.ts or create a new service

/**
 * Unwrap shared encryption keys using a decryption key from URL
 */
public async unwrapSharedKeys(
  encryptedContentKey: string,
  encryptedMetadataKey: string,
  decryptionKeyBase64: string
): Promise<{ contentKey: CryptoKey, metadataKey: CryptoKey }> {
  try {
    // Convert base64 decryption key to a CryptoKey
    const keyData = this.base64ToBuffer(decryptionKeyBase64);
    const unwrapKey = await crypto.subtle.importKey(
      'raw',
      keyData,
      {
        name: 'AES-GCM',
        length: 256
      },
      false,
      ['unwrapKey']
    );
    
    // Extract IV from encrypted keys (assuming IV is prepended)
    const contentKeyData = this.base64ToBuffer(encryptedContentKey);
    const contentIv = new Uint8Array(contentKeyData.slice(0, 12));
    const wrappedContentKey = contentKeyData.slice(12);
    
    const metadataKeyData = this.base64ToBuffer(encryptedMetadataKey);
    const metadataIv = new Uint8Array(metadataKeyData.slice(0, 12));
    const wrappedMetadataKey = metadataKeyData.slice(12);
    
    // Unwrap the content key
    const contentKey = await crypto.subtle.unwrapKey(
      'raw',
      wrappedContentKey,
      unwrapKey,
      {
        name: 'AES-GCM',
        iv: contentIv
      },
      {
        name: 'AES-GCM',
        length: 256
      },
      true,
      ['encrypt', 'decrypt']
    );
    
    // Unwrap the metadata key
    const metadataKey = await crypto.subtle.unwrapKey(
      'raw',
      wrappedMetadataKey,
      unwrapKey,
      {
        name: 'AES-GCM',
        iv: metadataIv
      },
      {
        name: 'AES-GCM',
        length: 256
      },
      true,
      ['encrypt', 'decrypt']
    );
    
    return { contentKey, metadataKey };
  } catch (error) {
    logger.error('Error unwrapping shared keys:', error);
    throw new Error('Failed to decrypt shared content keys');
  }
}


private async processRecoveryKeys(
  recoveryEncryptedKeys: RecoveryEncryptedKeys,
  masterKey: CryptoKey
): Promise<{ contentKeyData: ArrayBuffer; metadataKeyData: ArrayBuffer }> {
  debugLogger.info('Processing recovery keys:', {
    recoveryKeysInfo: {
      contentKeyLength: recoveryEncryptedKeys.content_key.length,
      metadataKeyLength: recoveryEncryptedKeys.metadata_key.length,
      ivLength: recoveryEncryptedKeys.iv.length,
      ivValue: recoveryEncryptedKeys.iv
    }
  });

  if (!this.validateBase64IV(recoveryEncryptedKeys.iv)) {
    debugLogger.error('Invalid IV format detected:', {
      ivFormatError: {
        iv: recoveryEncryptedKeys.iv,
        length: recoveryEncryptedKeys.iv.length
      }
    });
    throw new EncryptionError('Invalid IV format', 'INVALID_IV_FORMAT');
  }

  try {
    // Convert base64 IV to buffer directly
    const ivBuffer = this.base64ToBuffer(recoveryEncryptedKeys.iv);
    const iv = new Uint8Array(ivBuffer);

    debugLogger.info('IV preparation:', {
      ivPreparationInfo: {
        originalIv: recoveryEncryptedKeys.iv,
        decodedLength: iv.length,
        decodedBytes: Array.from(iv),
        expectedLength: this.IV_LENGTH
      }
    });

    if (iv?.length !== this.IV_LENGTH) {
      throw new EncryptionError(
        `Invalid IV length: ${iv.length}`,
        'INVALID_IV_LENGTH'
      );
    }

    // Process content key
    let contentKeyBuffer: ArrayBuffer;
    try {
      contentKeyBuffer = this.base64ToBuffer(recoveryEncryptedKeys.content_key);
      debugLogger.info('Content key buffer prepared:', {
        contentKeyInfo: {
          bufferLength: contentKeyBuffer.byteLength,
          firstBytes: Array.from(new Uint8Array(contentKeyBuffer.slice(0, 4)))
        }
      });
    } catch (error) {
      debugLogger.error('Content key buffer preparation failed:', {
        bufferError: {
          error: error instanceof Error ? error.message : 'Unknown error',
          inputLength: recoveryEncryptedKeys.content_key.length
        }
      });
      throw new EncryptionError('Invalid content key format', 'INVALID_CONTENT_KEY');
    }

    // Process metadata key
    let metadataKeyBuffer: ArrayBuffer;
    try {
      metadataKeyBuffer = this.base64ToBuffer(recoveryEncryptedKeys.metadata_key);
      debugLogger.info('Metadata key buffer prepared:', {
        metadataKeyInfo: {
          bufferLength: metadataKeyBuffer.byteLength,
          firstBytes: Array.from(new Uint8Array(metadataKeyBuffer.slice(0, 4)))
        }
      });
    } catch (error) {
      debugLogger.error('Metadata key buffer preparation failed:', {
        bufferError: {
          error: error instanceof Error ? error.message : 'Unknown error',
          inputLength: recoveryEncryptedKeys.metadata_key.length
        }
      });
      throw new EncryptionError('Invalid metadata key format', 'INVALID_METADATA_KEY');
    }

    // Decrypt content key
    let contentKeyData: ArrayBuffer;
    try {
      contentKeyData = await crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv,
          tagLength: 128
        },
        masterKey,
        contentKeyBuffer
      );
      debugLogger.info('Content key decrypted:', {
        contentKeyResult: {
          decryptedLength: contentKeyData.byteLength
        }
      });
    } catch (error) {
      debugLogger.error('Content key decryption failed:', {
        decryptionError: {
          error: error instanceof Error ? error.message : 'Unknown error',
          ivLength: iv.length,
          keyLength: contentKeyBuffer.byteLength,
          masterKeyAlg: masterKey.algorithm,
          masterKeyUsages: masterKey.usages
        }
      });
      throw new EncryptionError('Content key decryption failed', 'CONTENT_KEY_DECRYPTION_FAILED');
    }

    // Decrypt metadata key
    let metadataKeyData: ArrayBuffer;
    try {
      metadataKeyData = await crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv,
          tagLength: 128
        },
        masterKey,
        metadataKeyBuffer
      );
      debugLogger.info('Metadata key decrypted:', {
        metadataKeyResult: {
          decryptedLength: metadataKeyData.byteLength
        }
      });
    } catch (error) {
      debugLogger.error('Metadata key decryption failed:', {
        decryptionError: {
          error: error instanceof Error ? error.message : 'Unknown error',
          ivLength: iv.length,
          keyLength: metadataKeyBuffer.byteLength
        }
      });
      throw new EncryptionError('Metadata key decryption failed', 'METADATA_KEY_DECRYPTION_FAILED');
    }

    debugLogger.info('Keys decrypted successfully:', {
      decryptionSuccess: {
        contentKeyLength: contentKeyData.byteLength,
        metadataKeyLength: metadataKeyData.byteLength,
        expectedLength: this.KEY_LENGTH
      }
    });

    return { contentKeyData, metadataKeyData };
  } catch (error) {
    debugLogger.error('Key processing failed:', {
      processingError: {
        error: error instanceof Error ? error.message : 'Unknown error',
        name: error instanceof Error ? error.name : 'Unknown',
        code: error instanceof EncryptionError ? error.code : 'UNKNOWN_ERROR',
        stack: error instanceof Error ? error.stack : undefined
      }
    });
    throw error;
  }
}

private async testRecoveryEncryption(
  recoveryKey: string,
  salt: Uint8Array,
  encryptedKeys: RecoveryEncryptedKeys,
  originalContentKey: CryptoKey,
  originalMetadataKey: CryptoKey
): Promise<boolean> {
  try {
    // Derive test master keys
    const testMasterKeys = await this.deriveKey(recoveryKey, salt);
    
    // Decrypt and test
    const { contentKeyData, metadataKeyData } = await this.processRecoveryKeys(
      encryptedKeys,
      testMasterKeys.encryptionKey
    );

    // Compare with original key data
    const originalContentData = await crypto.subtle.exportKey('raw', originalContentKey);
    const originalMetadataData = await crypto.subtle.exportKey('raw', originalMetadataKey);

    // Compare byte by byte
    const contentMatches = this.compareArrayBuffers(contentKeyData, originalContentData);
    const metadataMatches = this.compareArrayBuffers(metadataKeyData, originalMetadataData);

    debugLogger.info('Recovery encryption test:', {
      testResults: {
        contentMatches,
        metadataMatches,
        originalContentLength: originalContentData.byteLength,
        decryptedContentLength: contentKeyData.byteLength
      }
    });

    return contentMatches && metadataMatches;
  } catch (error) {
    debugLogger.error('Recovery encryption test failed:', {
      testError: {
        error: error instanceof Error ? error.message : 'Unknown error'
      }
    });
    return false;
  }
}

private compareArrayBuffers(a: ArrayBuffer | undefined, b: ArrayBuffer | undefined): boolean {
  if (!a || !b) return false;
  if (a?.byteLength !== b.byteLength) {
    debugLogger.info('Buffer length mismatch:', {
      lengthInfo: {
        aLength: a.byteLength,
        bLength: b.byteLength
      }
    });
    return false;
  }
  const a8 = new Uint8Array(a);
  const b8 = new Uint8Array(b);
  
  debugLogger.info('Comparing buffers:', {
    bufferComparisonInfo: {
      aBytes: Array.from(a8.slice(0, 10)), // Show only first 10 bytes
      bBytes: Array.from(b8.slice(0, 10))  // Show only first 10 bytes
    }
  });
  
  return a8.every((val, i) => val === b8[i]);
}

/**
 * Standard AES-GCM decryption
 */
// Second implementation - rename to avoid duplication
private async _aesDecryptSecond(
  data: ArrayBuffer,
  key: CryptoKey,
  iv: Uint8Array
): Promise<ArrayBuffer> {
  if (!data || data?.byteLength === 0) {
    throw new EncryptionError(
      'No data provided for decryption',
      'INVALID_DATA'
    );
  }

  if (!key) {
    throw new EncryptionError(
      'No decryption key provided',
      'INVALID_KEY'
    );
  }

  if (!iv) {
    throw new EncryptionError(
      'Invalid IV provided',
      'INVALID_IV'
    );
  }
  
  const { IVHandler } = await import('./encryption/utils/iv-handler');
  
  // Make sure IV is correct length for AES-GCM (12 bytes)
  let aesIv = iv;
  if (iv.length !== IVHandler.AES_IV_LENGTH) {
    aesIv = IVHandler.normalizeIV(iv, 'AES-GCM');
    logger.debug('IV normalized for AES decryption', {
      originalLength: iv.length,
      normalizedLength: aesIv.length
    });
  }

  try {
    return crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: aesIv,
        tagLength: 128
      },
      key,
      data
    );
  } catch (error) {
    logger.error('AES decryption error:', error);
    throw new EncryptionError(
      'AES decryption failed',
      'AES_DECRYPTION_FAILED'
    );
  }
}

/**
 * ChaCha20-Poly1305 decryption for quantum/performance tier
 */
// Second implementation - rename to avoid duplication
private async _chaChaDecryptSecond(
  data: ArrayBuffer,
  key: CryptoKey,
  iv: Uint8Array
): Promise<ArrayBuffer> {
  try {
    const nacl = await import('tweetnacl');
    
    // Convert CryptoKey to format TweetNaCl can use
    const exportedKey = await crypto.subtle.exportKey('raw', key);
    const naclKey = new Uint8Array(exportedKey).slice(0, 32);
    
    logger.debug('ChaCha20 key preparation', {
      component: 'EncryptionService',
      data: {
        exportedKeyLength: exportedKey.byteLength,
        naclKeyLength: naclKey.length,
        expectedLength: 32
      }
    });
    
    if (naclKey.length !== 32) {
      throw new Error('Invalid key length for ChaCha20');
    }
    
    // Ensure IV is 24 bytes (ChaCha20 nonce length)
    const { IVHandler } = await import('./encryption/utils/iv-handler');
    
    let nonce: Uint8Array;
    if (iv.length === IVHandler.CHACHA_NONCE_LENGTH) {
      nonce = iv;
    } else {
      logger.debug('IV length mismatch, attempting conversion', {
        ivLength: iv.length,
        expectedLength: IVHandler.CHACHA_NONCE_LENGTH,
        ivFirstBytes: Array.from(iv.slice(0, 4))
      });
      
      // Create a full 24-byte nonce by padding or extending
      nonce = new Uint8Array(IVHandler.CHACHA_NONCE_LENGTH);
      
      // Copy available bytes
      nonce.set(iv.slice(0, Math.min(iv.length, IVHandler.CHACHA_NONCE_LENGTH)));
      
      // If original IV is shorter than needed, derive the rest
      if (iv.length < IVHandler.CHACHA_NONCE_LENGTH) {
        // Fill remaining bytes with derived values
        for (let i = iv.length; i < IVHandler.CHACHA_NONCE_LENGTH; i++) {
          if (i - iv.length < iv.length) {
            // XOR with original bytes when possible
            nonce[i] = iv[i - iv.length] ^ 0xFF;
          } else {
            // Otherwise use a simple pattern
            nonce[i] = i % 256;
          }
        }
      }
      
      logger.debug('Normalized IV for ChaCha20', {
        originalLength: iv.length,
        newLength: nonce.length,
        firstBytesOrig: Array.from(iv.slice(0, 4)),
        firstBytesNew: Array.from(nonce.slice(0, 4))
      });
    }
    
    // Decrypt using ChaCha20-Poly1305
    const encryptedArray = new Uint8Array(data);
    
    // Log detailed information about the decryption attempt
    logger.debug('Attempting ChaCha20 decryption', {
      component: 'EncryptionService',
      data: {
        dataSize: encryptedArray.length,
        nonceSize: nonce.length,
        keySize: naclKey.length,
        firstDataBytes: Array.from(encryptedArray.slice(0, 4)),
        firstNonceBytes: Array.from(nonce.slice(0, 4))
      }
    });
    
    // The secretbox protocol requires that the first 16 bytes (the authentication tag)
    // are included with the ciphertext.
    // If we're getting a null result, we might need to try different approaches.
    
    let decryptedData = nacl.secretbox.open(encryptedArray, nonce, naclKey);
    
    // If standard decryption fails, try alternative approaches
    if (!decryptedData) {
      logger.warn('Standard ChaCha20 decryption failed, trying fallback approach', {
        component: 'EncryptionService',
        data: {
          dataLength: encryptedArray.length,
          nonceLength: nonce.length
        }
      });
      
      // Try to fall back to AES-GCM decryption as the data might have been 
      // encrypted with the wrong algorithm in the server response
      try {
        logger.debug('Attempting fallback to AES-GCM decryption');
        const aesNonce = iv.slice(0, 12); // Use first 12 bytes for AES
        return await this.aesDecrypt(data, key, aesNonce);
      } catch (aesError) {
        logger.error('Fallback AES-GCM decryption also failed', {
          component: 'EncryptionService',
          error: aesError
        });
        throw new EncryptionError(
          'ChaCha20 decryption failed - data may be corrupted',
          'CHACHA_DECRYPTION_FAILED'
        );
      }
    }
    
    logger.debug('ChaCha20 decryption successful', {
      component: 'EncryptionService',
      data: {
        inputSize: encryptedArray.length,
        outputSize: decryptedData.length,
        firstOutputBytes: Array.from(decryptedData.slice(0, 4))
      }
    });
    
    return decryptedData.buffer;
  } catch (error) {
    logger.error('ChaCha20 decryption error:', error);
    throw new EncryptionError(
      'ChaCha20 decryption failed',
      'CHACHA_DECRYPTION_FAILED'
    );
  }
}

/**
 * Multi-layer decryption for paranoid tier (alt version)
 * Decrypts content that has been encrypted with both AES-256-GCM and ChaCha20-Poly1305
 */
private async multiLayerDecryptAlt(
  data: ArrayBuffer,
  key: CryptoKey,
  iv: Uint8Array
): Promise<ArrayBuffer> {
  try {
    logger.debug('Starting multi-layer decryption for paranoid tier', {
      component: 'EncryptionService',
      data: {
        encryptedSize: data.byteLength,
        ivLength: iv.length
      }
    });

    // Check if the IV is a combined format with a pipe character
    const ivString = this.bufferToBase64(iv);
    const ivParts = ivString.split('|');
    
    let aesIv: Uint8Array;
    let chachaIv: Uint8Array;
    
    if (ivParts.length === 2) {
      // If we have a compound IV (from the standard-key-management implementation)
      logger.debug('Found compound IV for multi-layer decryption', {
        aesIvLength: ivParts[0].length,
        chachaIvLength: ivParts[1].length
      });
      
      aesIv = new Uint8Array(this.base64ToBuffer(ivParts[0]));
      chachaIv = new Uint8Array(this.base64ToBuffer(ivParts[1]));
    } else {
      // Regular format, use the first 12 bytes for AES
      const { IVHandler } = await import('./encryption/utils/iv-handler');
      logger.debug('Using standard IV format for multi-layer decryption');
      
      // Generate ChaCha nonce from AES IV
      aesIv = iv.slice(0, IVHandler.AES_IV_LENGTH);
      chachaIv = IVHandler.deriveChaChaIV(aesIv);
    }

    try {
      // First, decrypt the outer layer using AES-GCM
      const firstLayerDecrypted = await this.aesDecrypt(data, key, aesIv);
      
      logger.debug('First layer (AES-GCM) decryption completed', {
        component: 'EncryptionService',
        data: {
          firstLayerSize: firstLayerDecrypted.byteLength,
          firstBytes: Array.from(new Uint8Array(firstLayerDecrypted).slice(0, 8))
        }
      });
      
      // Check if we have image data already (often the case for thumbnails)
      const dataHeader = new Uint8Array(firstLayerDecrypted).slice(0, 4);
      const isJpeg = dataHeader[0] === 0xFF && dataHeader[1] === 0xD8;
      const isPng = dataHeader[0] === 0x89 && dataHeader[1] === 0x50 && dataHeader[2] === 0x4E && dataHeader[3] === 0x47;
      
      if (isJpeg || isPng) {
        logger.debug('First layer already contains valid image data, skipping second layer', {
          component: 'EncryptionService',
          data: {
            isJpeg,
            isPng,
            firstBytes: Array.from(dataHeader)
          }
        });
        return firstLayerDecrypted;
      }
      
      // Derive a separate key for the second layer
      const { KeyDerivation } = await import('./encryption/utils/key-derivation');
      const { aesKey, chachaKey } = await KeyDerivation.deriveLayeredKeys(key);
      
      try {
        // Second layer: Decrypt using ChaCha20-Poly1305
        const secondLayerDecrypted = await this.chaChaDecrypt(
          firstLayerDecrypted, 
          chachaKey,
          chachaIv
        );
        
        logger.debug('Multi-layer decryption completed successfully', {
          component: 'EncryptionService',
          data: {
            originalSize: data.byteLength,
            finalSize: secondLayerDecrypted.byteLength
          }
        });
        
        return secondLayerDecrypted;
      } catch (innerError) {
        // If ChaCha20 decryption fails, the first layer might be the final result
        // This can happen if the thumbnail was only encrypted with AES during upload
        logger.warn('Second layer (ChaCha20) decryption failed, using first layer result', {
          component: 'EncryptionService',
          error: innerError
        });
        
        return firstLayerDecrypted;
      }
    } catch (outerError) {
      // If AES decryption fails, try direct ChaCha20 decryption
      // This covers the case where the layers might be in reverse order
      logger.warn('First layer (AES) decryption failed, trying direct ChaCha20', {
        component: 'EncryptionService',
        error: outerError
      });
      
      try {
        // Derive a ChaCha20 key
        const { KeyDerivation } = await import('./encryption/utils/key-derivation');
        const { aesKey, chachaKey } = await KeyDerivation.deriveLayeredKeys(key);
        
        // Attempt ChaCha20 decryption directly
        return await this.chaChaDecrypt(data, chachaKey, chachaIv);
      } catch (chachaError) {
        // Try one more approach: use the original key with ChaCha
        try {
          return await this.chaChaDecrypt(data, key, chachaIv);
        } catch (finalError) {
          // All approaches failed, throw the original error with more context
          logger.error('All decryption approaches failed for paranoid tier', {
            component: 'EncryptionService',
            error: outerError
          });
          throw outerError;
        }
      }
    }
  } catch (error) {
    logger.error('Multi-layer decryption failed', {
      component: 'EncryptionService',
      error
    });
    
    throw new EncryptionError(
      'Multi-layer decryption failed: ' + (error instanceof Error ? error.message : 'Unknown error'),
      'MULTILAYER_DECRYPTION_FAILED'
    );
  }
}
}