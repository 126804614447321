/**
 * @file: automation.routes.tsx
 * @path: src/routes/automation.routes.tsx
 * @description: Routing configuration for automation features with lazy loading
 * @version: 1.1.0
 * @created: 2024-12-10
 * @updated: 2025-02-25
 * @dependencies: react-router-dom, react
 */

import { Suspense, lazy } from 'react';

// Lazy load automation components
const StorageAutomationPage = lazy(() => import('@/pages/automation/storage'));
const AutomationPage = lazy(() => import('@/pages/automation'));

// Loading component for automation pages
const AutomationLoader = () => (
  <div className="flex items-center justify-center min-h-[60vh]">
    <div className="flex flex-col items-center space-y-2">
      <div className="h-8 w-8 rounded-full border-2 border-primary/30 border-t-primary animate-spin"></div>
      <p className="text-sm text-muted-foreground">Loading automation features...</p>
    </div>
  </div>
);

export const automationRoutes = {
  path: 'automation',
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<AutomationLoader />}>
          <AutomationPage />
        </Suspense>
      )
    },
    {
      path: 'storage',
      element: (
        <Suspense fallback={<AutomationLoader />}>
          <StorageAutomationPage />
        </Suspense>
      )
    }
  ]
};