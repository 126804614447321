/**
 * @file src/layouts/ghost/components/create-core/steps/StepRecovery.tsx
 * @description Recovery key generation with Ghost theme
 * @version 1.2.0
 */

import React, { useState } from 'react';
import {motion} from 'framer-motion';
import { 
  Copy, 
  Check, 
  Download, 
  Shield, 
  Ghost,
  AlertTriangle,
  Loader
} from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import type { KeyGenerationResult } from '@/types/encryption.types';

interface StepRecoveryProps {
  formData: {
    name: string;
    recoveryKey?: string;
    [key: string]: any;
  };
  keyData: KeyGenerationResult | null;
  onNext: () => void;
  onBack: () => void;
  onComplete: () => void; // Add this prop
  isCreating?: boolean;   // Add this prop
}

export const StepRecovery: React.FC<StepRecoveryProps> = ({
  formData,
  keyData,
  onNext,
  onBack,
  onComplete,
  isCreating = false
}) => {
  const { t } = useGhostTranslation();
  const [copied, setCopied] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  // Format recovery key for display
  const formatRecoveryKey = (key: string) => {
    return key.match(/.{1,4}/g)?.join('-') || key;
  };

  const handleCopyKey = async () => {
    try {
      await navigator.clipboard.writeText(formData.recoveryKey || '');
      setCopied(true);
      logger.debug('Recovery key copied');
      
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (error) {
      logger.error('Error copying recovery key:', error);
    }
  };

  const handleDownloadKey = () => {
    try {
      const keyData = {
        protocolId: formData.recoveryKey,
        driveName: formData.name,
        timestamp: new Date().toISOString(),
        type: 'GHOST_ESCAPE_PROTOCOL'
      };

      const blob = new Blob(
        [JSON.stringify(keyData, null, 2)], 
        { type: 'application/json' }
      );
      
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `ghost-protocol-${formData.name.toLowerCase()}.key`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      logger.debug('Recovery key file downloaded');
    } catch (error) {
      logger.error('Error downloading recovery key:', error);
    }
  };

  // Early return if no recovery key
  if (!formData.recoveryKey || !keyData) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[300px] space-y-4">
        <Ghost className="w-12 h-12 text-[hsl(var(--ghost-accent))] animate-pulse" />
        <p className="text-[hsl(var(--ghost-foreground-muted))]">
          {t('create.recovery.loading') as React.ReactNode}
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Recovery Key Display */}
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2 }}
        className={cn(
          "p-6 rounded-xl",
          "relative overflow-hidden",
          "bg-[hsl(var(--ghost-surface))]",
          "border border-[hsl(var(--ghost-accent))/0.2]"
        )}
      >
        {/* Animated background */}
        <motion.div
          className="absolute inset-0"
          initial={false}
          animate={{
            background: [
              "radial-gradient(circle at 0% 0%, hsl(var(--ghost-accent)/0.1) 0%, transparent 50%)",
              "radial-gradient(circle at 100% 100%, hsl(var(--ghost-accent)/0.1) 0%, transparent 50%)",
              "radial-gradient(circle at 0% 0%, hsl(var(--ghost-accent)/0.1) 0%, transparent 50%)",
            ],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear",
          }}
        />

        {/* Icon */}
        <div className="flex justify-center mb-4">
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: 360 }}
            transition={{
              duration: 20,
              repeat: Infinity,
              ease: "linear",
            }}
            className="relative"
          >
            <div className="absolute inset-0 blur-lg opacity-50 bg-[hsl(var(--ghost-accent))]" />
            <Ghost className="relative h-12 w-12 text-[hsl(var(--ghost-accent))]" />
          </motion.div>
        </div>

        {/* Recovery Key Display */}
        <div className="relative">
          <div className="font-mono text-lg tracking-wider break-all text-center text-[hsl(var(--ghost-foreground))]">
            {formatRecoveryKey(formData.recoveryKey)}
          </div>

          {/* Action Buttons */}
          <div className="flex justify-center space-x-3 mt-4">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleCopyKey}
              className={cn(
                "px-4 py-2 rounded-lg",
                "bg-[hsl(var(--ghost-accent))/0.1]",
                "border border-[hsl(var(--ghost-accent))/0.2]",
                "text-sm text-[hsl(var(--ghost-accent))]",
                "flex items-center space-x-2",
                "transition-colors duration-200",
                "hover:bg-[hsl(var(--ghost-accent))/0.2]"
              )}
            >
              {copied ? (
                <Check className="w-4 h-4" />
              ) : (
                <Copy className="w-4 h-4" />
              )}
              <span>{copied ? t('common.copied') as React.ReactNode : t('common.copy') as React.ReactNode}</span>
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleDownloadKey}
              className={cn(
                "px-4 py-2 rounded-lg",
                "bg-[hsl(var(--ghost-accent))/0.1]",
                "border border-[hsl(var(--ghost-accent))/0.2]",
                "text-sm text-[hsl(var(--ghost-accent))]",
                "flex items-center space-x-2",
                "transition-colors duration-200",
                "hover:bg-[hsl(var(--ghost-accent))/0.2]"
              )}
            >
              <Download className="w-4 h-4" />
              <span>{t('create.recovery.download') as React.ReactNode}</span>
            </motion.button>
          </div>
        </div>
      </motion.div>

      {/* Warning Messages */}
      <div className="space-y-4">
        {/* Primary Warning */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
          className={cn(
            "p-4 rounded-xl",
            "bg-[hsl(var(--ghost-accent-tertiary))/0.1]",
            "border border-[hsl(var(--ghost-accent-tertiary))/0.2]"
          )}
        >
          <div className="flex items-start space-x-3">
            <AlertTriangle className="w-5 h-5 text-[hsl(var(--ghost-accent-tertiary))]" />
            <div>
              <h4 className="text-sm font-medium text-[hsl(var(--ghost-accent-tertiary))]">
                {t('create.recovery.warning.title') as React.ReactNode}
              </h4>
              <p className="mt-1 text-sm text-[hsl(var(--ghost-foreground-muted))]">
                {t('create.recovery.warning.description') as React.ReactNode}
              </p>
            </div>
          </div>
        </motion.div>

        {/* Security Notice */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
          className={cn(
            "p-4 rounded-xl",
            "bg-[hsl(var(--ghost-accent))/0.1]",
            "border border-[hsl(var(--ghost-accent))/0.2]"
          )}
        >
          <div className="flex items-start space-x-3">
            <Shield className="w-5 h-5 text-[hsl(var(--ghost-accent))]" />
            <div>
              <h4 className="text-sm font-medium text-[hsl(var(--ghost-accent))]">
                {t('create.recovery.security.title') as React.ReactNode}
              </h4>
              <p className="mt-1 text-sm text-[hsl(var(--ghost-foreground-muted))]">
                {t('create.recovery.security.description') as React.ReactNode}
              </p>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Acknowledgment */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="flex items-start space-x-3"
      >
        <input
          type="checkbox"
          id="acknowledge"
          checked={acknowledged}
          onChange={(e) => setAcknowledged(e.target.checked)}
          className={cn(
            "mt-1 h-4 w-4 rounded",
            "border-[hsl(var(--ghost-accent))/0.2]",
            "text-[hsl(var(--ghost-accent))]",
            "focus:ring-[hsl(var(--ghost-accent))]",
            "bg-[hsl(var(--ghost-surface))]"
          )}
        />
        <label
          htmlFor="acknowledge"
          className="text-sm text-[hsl(var(--ghost-foreground-muted))]"
        >
          {t('create.recovery.acknowledgment') as React.ReactNode}
        </label>
      </motion.div>

      {/* Action Buttons */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
        className="flex justify-between pt-4"
      >
        <motion.button
          type="button"
          onClick={onBack}
          disabled={isCreating}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className={cn(
            "px-6 py-2 rounded-lg",
            "bg-[hsl(var(--ghost-surface))]",
            "border border-[hsl(var(--ghost-accent))/0.2]",
            "text-[hsl(var(--ghost-foreground))]",
            "text-sm font-medium",
            "transition-colors duration-200",
            "hover:bg-[hsl(var(--ghost-accent))/0.1]",
            isCreating && "opacity-50 cursor-not-allowed"
          )}
        >
          {t('common.back') as React.ReactNode}
        </motion.button>

        <motion.button
          onClick={onComplete} // Changed from onNext to onComplete
          disabled={!acknowledged || isCreating}
          whileHover={acknowledged && !isCreating ? { scale: 1.02 } : {}}
          whileTap={acknowledged && !isCreating ? { scale: 0.98 } : {}}
          className={cn(
            "px-6 py-2 rounded-lg",
            "text-sm font-medium",
            "transition-all duration-200",
            "flex items-center space-x-2", // Added for loader
            acknowledged && !isCreating ? (
              "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))] text-white"
            ) : (
              "bg-[hsl(var(--ghost-surface))] text-[hsl(var(--ghost-foreground-muted))]"
            ),
            isCreating && "opacity-90 cursor-wait" // Added for loading state
          )}
        >
          {isCreating ? (
            <>
              <Loader className="w-4 h-4 animate-spin" />
              <span>{t('common.creating') as React.ReactNode}</span>
            </>
          ) : (
            <span>{t('create.recovery.create_protocol') as React.ReactNode}</span>
          )}
        </motion.button>
      </motion.div>
    </div>
  );
};

export default StepRecovery;