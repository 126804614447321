/**
 * @file src/services/encrypted-drive/encrypted-files.service.ts
 * @description Handles encrypted file operations and metadata decryption with improved error handling
 * @version 2.2.0
 * @updated 2025-03-05
 */

import { encryptionService } from './encryption-singleton';
import { encryptedDriveApi } from './encrypted-drive.service';

import { logger } from '@/utils/logger';
import { debugLogger } from '@/utils/debug-logger';
import type { DriveItemsResponse, DriveItem, DriveItemType, DriveItemUser } from '@/types/cloud-drive.types';

interface EncryptedListOptions {
  folderId: string | null;
  driveId: string;
  cursor?: string;
  pageSize?: number;
}

export interface DecryptedMetadata {
  name: string;
  path: string;
  size: number;
  type: string;
  created: number;
  modified: number;
}

// Add a decryption status type
export interface DecryptionStatus {
  isDecrypting: boolean;
  progress: number;
  completedItems: number;
  totalItems: number;
}

// Add initial status
const initialDecryptionStatus: DecryptionStatus = {
  isDecrypting: false,
  progress: 0,
  completedItems: 0,
  totalItems: 0
};

// Add a metadata cache
const metadataCache = new Map<string, DecryptedMetadata>();

// Extend DriveItem for encrypted properties
interface EncryptedDriveItem extends DriveItem {
  encrypted_metadata?: string;
  metadata_iv?: string;
  media_info?: any;
  content_iv?: string;
  _isPlaceholder?: boolean;
  size?: number;
}

class DriveNotUnlockedException extends Error {
  constructor(driveId: string) {
    super(`Drive must be unlocked before listing contents (${driveId})`);
    this.name = 'DriveNotUnlockedException';
  }
}

export const encryptedFilesService = {
  // Track decryption status
  decryptionStatus: { ...initialDecryptionStatus },
  
  // Update the decryption status
  updateDecryptionStatus(updates: Partial<DecryptionStatus>) {
    this.decryptionStatus = {
      ...this.decryptionStatus,
      ...updates
    };
    
    // This status could be observed by the UI later
    debugLogger.info('Decryption status updated', {
      component: 'encryptedFilesService',
      data: this.decryptionStatus
    });
  },
  
  /**
   * Get cached metadata for a specific item
   */
  getCachedMetadata(driveId: string, itemId: string): DecryptedMetadata | null {
    const cacheKey = `${driveId}:${itemId}`;
    return metadataCache.has(cacheKey) ? metadataCache.get(cacheKey) as DecryptedMetadata : null;
  },

/**
 * Check if metadata was successfully decrypted
 */
isValidDecryptedMetadata(metadata: any): boolean {
  // First, basic validation
  const isValid = (
    metadata &&
    typeof metadata === 'object' &&
    'name' in metadata &&
    typeof metadata.name === 'string' &&
    metadata.name.length > 0
  );
  
  // If basic validation fails, log detailed info about the metadata
  if (!isValid) {
    debugLogger.error('Invalid metadata detected', {
      component: 'encryptedFilesService',
      data: {
        metadataExists: !!metadata,
        metadataType: typeof metadata,
        metadataIsString: typeof metadata === 'string',
        nameExists: metadata && 'name' in metadata,
        nameType: metadata && 'name' in metadata ? typeof metadata.name : 'undefined',
        nameLength: metadata && 'name' in metadata ? metadata.name.length : 0,
        metadataKeys: metadata && typeof metadata === 'object' ? Object.keys(metadata) : []
      }
    });
  }
  
  return isValid;
},

/**
 * Create placeholder item for failed decryption
 */
createPlaceholderItem(itemId: string, type: 'file' | 'folder', folderId: string | null, mediaInfo?: any): EncryptedDriveItem {
  // Create a specific placeholder name based on item type
  const placeholderName = type === 'folder' ? '[Encrypted Folder]' : '[Encrypted File]';
  
  logger.warn(`Creating placeholder for ${type}`, {
    component: 'encryptedFilesService',
    data: { itemId, type, placeholderName }
  });
  
  return {
    id: itemId,
    name: placeholderName,
    type: type,
    parentId: folderId,
    path: [],
    created: new Date().toISOString(),
    modified: new Date().toISOString(),
    createdBy: 'me' as unknown as DriveItemUser,
    modifiedBy: 'me' as unknown as DriveItemUser,
    shared: false,
    favorite: false,
    media_info: mediaInfo, // Preserve media_info if available
    _isPlaceholder: true // Mark as placeholder for UI handling
  } as unknown as EncryptedDriveItem;
},

/**
 * List encrypted folder contents with decryption
 */
async listContents(options: EncryptedListOptions): Promise<DriveItemsResponse> {
  const { driveId, folderId, cursor, pageSize = 50 } = options;

  try {
    debugLogger.info('Listing encrypted contents', {
      component: 'encryptedFilesService',
      data: { driveId, folderId, cursor, pageSize }
    });

    // Check all cached drives
    try {
      const allCachedDriveIds = encryptionService.getAllCachedDriveIds();
      debugLogger.info('Available cached drive keys', {
        component: 'encryptedFilesService',
        data: { 
          cachedDriveIds: allCachedDriveIds,
          requestedDriveId: driveId,
          match: allCachedDriveIds.includes(driveId)
        }
      });
    } catch (error) {
      debugLogger.error('Error checking cached drives', {
        component: 'encryptedFilesService',
        data: { error }
      });
    }

    // 1. Check if drive is unlocked with multiple checks
    const isDriveUnlocked = encryptionService.isDriveUnlocked(driveId);
    
    // Advanced check - get the keys directly
    const keys = encryptionService.getDecryptedKeys(driveId);
    
    debugLogger.info('Drive unlock status check', {
      component: 'encryptedFilesService',
      data: {
        driveId,
        isDriveUnlocked,
        hasKeys: !!keys
      }
    });

    if (!keys) {
      logger.error('No encryption keys available', {
        component: 'encryptedFilesService',
        data: { driveId }
      });
      
      // Provide more context in the error
      const error = new DriveNotUnlockedException(driveId);
      debugLogger.error('Drive keys not available', {
        component: 'encryptedFilesService',
        error: error.message,
        suggestion: 'User should return to encrypted drive list and unlock again'
      });
      
      throw error;
    }

    // 2. Get encrypted contents from API
    const response = await encryptedDriveApi.listFolderContents({
      driveId,
      folderId,
      cursor,
      pageSize
    });

    // Start decryption process
    this.updateDecryptionStatus({
      isDecrypting: true,
      totalItems: response.items.length,
      completedItems: 0,
      progress: 0
    });

    // 3. Decrypt items metadata
    const decryptedItems = await Promise.all(
      response.items.map(async (item, index) => {
        try {
          // Add specific folder detection
          const isFolder = item.type === 'folder';
          const itemAsEncrypted = item as EncryptedDriveItem;
          
          debugLogger.info('Processing item for decryption', {
            component: 'encryptedFilesService',
            data: { 
              itemId: item.id, 
              itemType: item.type,
              isFolder,
              hasEncryptedMetadata: !!itemAsEncrypted.encrypted_metadata,
              hasMetadataIV: !!itemAsEncrypted.metadata_iv,
              metadataLength: itemAsEncrypted.encrypted_metadata?.length,
              ivLength: itemAsEncrypted.metadata_iv?.length
            }
          });

          // Check cache first for better performance
          const cacheKey = `${driveId}:${item.id}`;
          if (metadataCache.has(cacheKey)) {
            const cachedMetadata = metadataCache.get(cacheKey) as DecryptedMetadata;
            
            // For folders, add specific logging
            if (isFolder) {
              debugLogger.info('Using cached folder metadata', {
                component: 'encryptedFilesService',
                data: {
                  folderId: item.id,
                  folderName: cachedMetadata.name,
                  path: cachedMetadata.path
                }
              });
            }
            
            // Update progress
            this.updateDecryptionStatus({
              completedItems: this.decryptionStatus.completedItems + 1,
              progress: ((this.decryptionStatus.completedItems + 1) / this.decryptionStatus.totalItems) * 100
            });
            
            // Return item with cached metadata
            // Make sure to preserve folder_info for folders
            return {
              id: item.id,
              name: cachedMetadata.name,
              type: item.type,
              parentId: folderId,
              path: cachedMetadata.path.split('/').filter(Boolean),
              size: cachedMetadata.size,
              created: new Date(cachedMetadata.created).toISOString(),
              modified: new Date(cachedMetadata.modified).toISOString(),
              createdBy: 'me' as unknown as DriveItemUser,
              modifiedBy: 'me' as unknown as DriveItemUser,
              shared: false,
              favorite: false,
              media_info: itemAsEncrypted.media_info,  // Preserve media_info
              folder_info: isFolder ? itemAsEncrypted.folder_info : undefined, // Preserve folder_info
              // For backward compatibility with existing UI code
              itemCount: isFolder && itemAsEncrypted.folder_info ? 
                itemAsEncrypted.folder_info.total_items : undefined
            } as unknown as EncryptedDriveItem;
          }

          // Decrypt metadata with enhanced logging for folders
          let decryptedMetadata = await encryptionService.decryptMetadata(
            itemAsEncrypted.encrypted_metadata!,
            itemAsEncrypted.metadata_iv!,
            keys.metadataKey
          ) as DecryptedMetadata;
          
          // Fix for issue #1: If decryptedMetadata is a string (stringified JSON), parse it
          if (typeof decryptedMetadata === 'string') {
            try {
              decryptedMetadata = JSON.parse(decryptedMetadata);
            } catch (parseError) {
              logger.error('Error parsing decrypted metadata JSON:', {
                component: 'encryptedFilesService',
                error: parseError,
                data: { itemId: item.id, metadataValue: decryptedMetadata }
              });
            }
          }

          // Add specific logging for folder decryption results
          if (isFolder) {
            debugLogger.info('Folder decryption result:', {
              component: 'encryptedFilesService',
              data: {
                folderId: item.id,
                successful: !!decryptedMetadata,
                decryptedName: decryptedMetadata?.name || 'undefined',
                metadataType: typeof decryptedMetadata,
                metadataStructure: decryptedMetadata ? Object.keys(decryptedMetadata) : 'none',
                isValidMetadata: this.isValidDecryptedMetadata(decryptedMetadata)
              }
            });
          }

          // Validate decrypted metadata
          if (!this.isValidDecryptedMetadata(decryptedMetadata)) {
            logger.error('Invalid decrypted metadata format:', {
              component: 'encryptedFilesService',
              data: { 
                itemId: item.id,
                itemType: item.type,
                metadata: decryptedMetadata 
              }
            });
            throw new Error('Invalid metadata format');
          }

          // Store in cache
          metadataCache.set(cacheKey, decryptedMetadata);

          debugLogger.info('Decryption successful:', {
            component: 'encryptedFilesService',
            data: {
              itemId: item.id,
              itemType: item.type,
              itemName: decryptedMetadata.name,
              size: decryptedMetadata.size,
              created: new Date(decryptedMetadata.created).toISOString()
            }
          });

          // Update progress
          this.updateDecryptionStatus({
            completedItems: this.decryptionStatus.completedItems + 1,
            progress: ((this.decryptionStatus.completedItems + 1) / this.decryptionStatus.totalItems) * 100
          });

          // Convert to DriveItem format
          return {
            id: item.id,
            name: decryptedMetadata.name,
            type: item.type,
            parentId: folderId,
            path: decryptedMetadata.path.split('/').filter(Boolean),
            size: decryptedMetadata.size || 0,
            created: new Date(decryptedMetadata.created || Date.now()).toISOString(),
            modified: new Date(decryptedMetadata.modified || Date.now()).toISOString(),
            createdBy: 'me' as unknown as DriveItemUser,
            modifiedBy: 'me' as unknown as DriveItemUser,
            shared: false,
            favorite: false,
            media_info: itemAsEncrypted.media_info,  // Preserve media_info
            folder_info: isFolder ? itemAsEncrypted.folder_info : undefined, // Preserve folder_info
            // For backward compatibility with existing UI code
            itemCount: isFolder && itemAsEncrypted.folder_info ? 
              itemAsEncrypted.folder_info.total_items : undefined
          } as unknown as EncryptedDriveItem;

        } catch (error) {
          // Enhanced error logging for folders
          const isFolder = item.type === 'folder';
          logger.error(`Error decrypting ${isFolder ? 'folder' : 'file'} metadata:`, {
            component: 'encryptedFilesService',
            error,
            data: { 
              itemId: item.id,
              itemType: item.type,
              isFolder
            }
          });

          // Update progress even for failed items
          this.updateDecryptionStatus({
            completedItems: this.decryptionStatus.completedItems + 1,
            progress: ((this.decryptionStatus.completedItems + 1) / this.decryptionStatus.totalItems) * 100
          });

          // Return placeholder for failed items with improved clarity
          const placeholderName = isFolder ? '[Encrypted Folder]' : '[Encrypted File]';
          const itemAsEncrypted = item as EncryptedDriveItem;
          
          logger.warn(`Creating placeholder for ${item.type}`, {
            component: 'encryptedFilesService',
            data: { itemId: item.id, type: item.type, placeholderName }
          });
          
          return {
            id: item.id,
            name: placeholderName,
            type: item.type,
            parentId: folderId,
            path: [],
            size: 0,
            created: new Date().toISOString(),
            modified: new Date().toISOString(),
            createdBy: 'me' as unknown as DriveItemUser,
            modifiedBy: 'me' as unknown as DriveItemUser,
            shared: false,
            favorite: false,
            media_info: itemAsEncrypted.media_info, // Preserve media_info if available
            folder_info: isFolder ? itemAsEncrypted.folder_info : undefined, // Preserve folder_info
            // For backward compatibility with existing UI code
            itemCount: isFolder && itemAsEncrypted.folder_info ? 
              itemAsEncrypted.folder_info.total_items : undefined,
            _isPlaceholder: true // Mark as placeholder for UI handling
          } as unknown as EncryptedDriveItem;
        }
      })
    );

    // Reset decryption status
    this.updateDecryptionStatus({
      isDecrypting: false,
      completedItems: 0,
      progress: 0,
      totalItems: 0
    });

    debugLogger.info('Folder contents decrypted', {
      component: 'encryptedFilesService',
      data: {
        totalItems: decryptedItems.length,
        folderCount: decryptedItems.filter((i: any) => i.type === 'folder').length,
        fileCount: decryptedItems.filter((i: any) => i.type === 'file').length,
        placeholderCount: decryptedItems.filter((i: any) => (i as any)._isPlaceholder).length
      }
    });

    // Use folder stats if available in the response
    const folderStats = (response as any).folder_stats || {};
    
    // Map response to consistent format with added folder stats
    return {
      items: decryptedItems,
      total: (response as any).item_count || response.total || decryptedItems.length,
      totalItems: (response as any).total_items || response.totalItems || decryptedItems.length,
      hasMore: (response as any).has_more || response.hasMore || false,
      nextCursor: (response as any).next_cursor || response.nextCursor,
      driveId: driveId // Ensure driveId is included
    };

  } catch (error) {
    if (error instanceof DriveNotUnlockedException) {
      // Special handling for unlock errors
      logger.error('Drive not unlocked error:', {
        component: 'encryptedFilesService',
        data: { 
          driveId,
          error: error.message
        }
      });
      
      // Return empty response instead of throwing
      return {
        items: [],
        total: 0,
        totalItems: 0,
        hasMore: false,
        nextCursor: undefined,
        driveId: driveId // Ensure driveId is included
      };
    }
    
    logger.error('Error listing encrypted contents:', {
      component: 'encryptedFilesService',
      error,
      data: { driveId, folderId }
    });
    
    // Return empty response on error instead of throwing
    return {
      items: [],
      total: 0,
      totalItems: 0,
      hasMore: false,
      nextCursor: undefined,
      driveId: driveId // Ensure driveId is included
    };
  }
},

  /**
   * Get single file details with decryption
   */
  async getFileDetails(fileId: string, driveId: string): Promise<DriveItem> {
    try {
      debugLogger.info('Getting encrypted file details', {
        component: 'encryptedFilesService',
        data: { fileId, driveId }
      });

      // 1. Check encryption keys
      const keys = encryptionService.getDecryptedKeys(driveId);
      if (!keys) {
        throw new DriveNotUnlockedException(driveId);
      }

      // 2. Get encrypted file details
      const encryptedDetails = await encryptedDriveApi.getFileDetails(fileId);
      const encryptedDetailsWithType = encryptedDetails as EncryptedDriveItem;

      // Check cache first
      const cacheKey = `${driveId}:${fileId}`;
      let decryptedMetadata: DecryptedMetadata;
      
      if (metadataCache.has(cacheKey)) {
        decryptedMetadata = metadataCache.get(cacheKey) as DecryptedMetadata;
        
        logger.debug('Using cached metadata for file details', {
          component: 'encryptedFilesService',
          data: { fileId, fileName: decryptedMetadata.name }
        });
      } else {
        try {
          // 3. Decrypt metadata
          decryptedMetadata = await encryptionService.decryptMetadata(
            encryptedDetailsWithType.encrypted_metadata!,
            encryptedDetailsWithType.metadata_iv!,
            keys.metadataKey
          ) as DecryptedMetadata;
          
          // Validate decrypted metadata
          if (!this.isValidDecryptedMetadata(decryptedMetadata)) {
            throw new Error('Invalid decrypted metadata format');
          }
          
          // Store in cache
          metadataCache.set(cacheKey, decryptedMetadata);
          
          logger.debug('Decrypted and cached new metadata', {
            component: 'encryptedFilesService',
            data: { fileId, fileName: decryptedMetadata.name }
          });
        } catch (error) {
          logger.error('Failed to decrypt file metadata', {
            component: 'encryptedFilesService',
            error,
            data: { fileId, driveId }
          });
          
          // Create placeholder metadata
          decryptedMetadata = {
            name: '[Encrypted]',
            path: '/',
            size: 0,
            type: 'file',
            created: Date.now(),
            modified: Date.now()
          };
        }
      }

      debugLogger.info('File details decrypted', {
        component: 'encryptedFilesService',
        data: {
          fileId,
          fileName: decryptedMetadata.name
        }
      });

      // 4. Convert to DriveItem format
      return {
        id: fileId,
        name: decryptedMetadata.name,
        type: encryptedDetails.type as DriveItemType,
        parentId: encryptedDetails.parent_id,
        path: decryptedMetadata.path.split('/').filter(Boolean),
        size: decryptedMetadata.size,
        created: new Date(decryptedMetadata.created).toISOString(),
        modified: new Date(decryptedMetadata.modified).toISOString(),
        createdBy: 'me' as unknown as DriveItemUser,
        modifiedBy: 'me' as unknown as DriveItemUser,
        shared: false,
        favorite: false,
        media_info: encryptedDetailsWithType.media_info, // Include media_info if available
        content_iv: encryptedDetailsWithType.content_iv // Include content_iv for decryption
      } as unknown as EncryptedDriveItem;

    } catch (error) {
      logger.error('Error getting encrypted file details:', {
        component: 'encryptedFilesService',
        error,
        data: { fileId, driveId }
      });
      
      if (error instanceof DriveNotUnlockedException) {
        // Return a blank placeholder rather than throwing
        return {
          id: fileId,
          name: '[Drive Locked]',
          type: 'file',
          parentId: null,
          path: [],
          size: 0,
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
          createdBy: 'me' as unknown as DriveItemUser,
          modifiedBy: 'me' as unknown as DriveItemUser,
          shared: false,
          favorite: false,
          labels: []
        };
      }
      
      // Return placeholder on error instead of throwing
      return {
        id: fileId,
        name: '[Error]',
        type: 'file',
        parentId: null,
        path: [],
        size: 0,
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
        createdBy: 'me' as unknown as DriveItemUser,
        modifiedBy: 'me' as unknown as DriveItemUser,
        shared: false,
        favorite: false,
        labels: []
      };
    }
  },
  
  /**
   * Clear the metadata cache for a specific drive
   */
  clearCache(driveId?: string): void {
    if (driveId) {
      // Clear only items for this drive
      const keysToDelete: string[] = [];
      metadataCache.forEach((_, key) => {
        if (key.startsWith(`${driveId}:`)) {
          keysToDelete.push(key);
        }
      });
      
      keysToDelete.forEach((key: any) => metadataCache.delete(key));
      
      debugLogger.info('Metadata cache cleared for drive', {
        component: 'encryptedFilesService',
        data: { 
          driveId,
          clearedCount: keysToDelete.length
        }
      });
    } else {
      // Clear entire cache
      metadataCache.clear();
      debugLogger.info('Entire metadata cache cleared', {
        component: 'encryptedFilesService'
      });
    }
  },


  /**
   * Clear a single item from the metadata cache
   * @param cacheKey - Either a combined key or a drive ID
   * @param itemId - Optional item ID to combine with drive ID
   */
  clearSingleItemCache(cacheKey: string, itemId?: string): void {
    // If itemId is provided, construct the full cache key
    const fullCacheKey = itemId ? `${cacheKey}:${itemId}` : cacheKey;
    
    if (metadataCache.has(fullCacheKey)) {
      metadataCache.delete(fullCacheKey);
      debugLogger.info('Cleared single item from metadata cache', {
        component: 'encryptedFilesService',
        data: { 
          cacheKey: fullCacheKey,
          driveId: itemId ? cacheKey : undefined,
          itemId
        }
      });
    } else {
      debugLogger.info('Item not found in metadata cache', {
        component: 'encryptedFilesService',
        data: { 
          cacheKey: fullCacheKey,
          driveId: itemId ? cacheKey : undefined,
          itemId
        }
      });
    }
  },

  /**
   * List media files with optimized performance for large collections (100K+ files)
   * 
   * This specialized method handles:
   * 1. Efficient caching with 30-second TTL
   * 2. Date-based filtering and sorting
   * 3. Cursor-based pagination
   * 4. Smart client-side filtering when needed
   * 
   * @param options The media listing options
   * @returns A promise with the media files response
   */
  listMediaFiles: async (options: { 
    driveId: string, 
    pageSize?: number, 
    cursor?: string, 
    mediaTypes?: string[],
    dateRange?: { start: string, end: string },
    forceRefresh?: boolean
  }): Promise<DriveItemsResponse> => {
    try {
      const { 
        driveId, 
        pageSize = 100, 
        cursor, 
        mediaTypes = ['image', 'video'],
        dateRange,
        forceRefresh = false
      } = options;
      
      logger.debug('Requesting media files', { 
        component: 'encryptedFilesService.listMediaFiles',
        driveId, 
        mediaTypes,
        hasDateRange: !!dateRange,
        cursor,
        pageSize,
        forceRefresh
      });
      
      // Check if we have a cached response that's recent enough
      const cacheKey = `media_files_cache_${driveId}`;
      const cachedData = localStorage.getItem(cacheKey);
      const now = Date.now();
      const mediaViewRefreshTimestamp = localStorage.getItem('media_view_refresh_timestamp');
      
      // If we have cached data and it's less than 30 seconds old, and no refresh was requested
      if (cachedData && !mediaViewRefreshTimestamp && !forceRefresh) {
        try {
          const parsed = JSON.parse(cachedData);
          const cacheAge = now - parsed.timestamp;
          
          // Use cache if it's fresh (less than 30 seconds old)
          if (cacheAge < 30000) {
            logger.debug('Using cached media files response', {
              component: 'encryptedFilesService.listMediaFiles',
              cacheAge: `${cacheAge}ms`,
              itemCount: parsed.data.items.length,
              pageSize
            });
            return parsed.data;
          }
        } catch (e) {
          // Ignore parse errors and continue with API call
          logger.warn('Error parsing cached media data', {
            component: 'encryptedFilesService.listMediaFiles',
            error: e
          });
        }
      }
      
      // Clear the refresh timestamp if it exists
      if (mediaViewRefreshTimestamp) {
        localStorage.removeItem('media_view_refresh_timestamp');
      }
      
      // In the future, use a specialized API endpoint once it's implemented
      // Currently, we'll optimize the existing API usage with smarter filtering
      
      try {
        const { encryptedDriveApi } = await import('./encrypted-drive.service');
        
        logger.debug('Fetching media files from API', {
          component: 'encryptedFilesService.listMediaFiles',
          driveId,
          pageSize
        });
        
        // For now, we use the listFolderContents with null folderId to get all files
        // In a future API update, this would be replaced with a dedicated media endpoint
        const response = await encryptedDriveApi.listFolderContents({ 
          driveId, 
          folderId: null, // Special case for media files - we want all media regardless of folder
          cursor,
          pageSize: pageSize * 2 // Request more items to account for filtering
        });
        
        // Post-process the response to filter only media files
        if (response && response.items && response.items.length > 0) {
          const startTime = performance.now();
          
          // Filter for media files only using optimized logic
          const mediaFiles = response.items.filter((item: any) => {
            if (item?.type !== 'file') return false;
            
            // Check if the item has media_info or mediaInfo
            const hasMediaInfo = !!(item.media_info || item.mediaInfo);
            if (hasMediaInfo) return true;
            
            // Fallback to checking file extension
            const fileExt = item.name?.toLowerCase().split('.').pop() || '';
            const imageExts = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'heic', 'heif', 'bmp', 'svg'];
            const videoExts = ['mp4', 'mov', 'avi', 'mkv', 'webm', 'flv', 'm4v', 'wmv', '3gp'];
            
            // Check if the file extension matches our media types
            if (mediaTypes.includes('image') && imageExts.includes(fileExt)) return true;
            if (mediaTypes.includes('video') && videoExts.includes(fileExt)) return true;
            
            return false;
          });
          
          // Date filtering if needed
          let filteredMediaFiles = mediaFiles;
          if (dateRange) {
            const startDate = new Date(dateRange.start).getTime();
            const endDate = new Date(dateRange.end).getTime();
            
            filteredMediaFiles = mediaFiles.filter((item: any) => {
              try {
                // Parse the modified date
                const itemDate = new Date(item.modified).getTime();
                return itemDate >= startDate && itemDate <= endDate;
              } catch (e) {
                return true; // Include if date parsing fails
              }
            });
          }
          
          // Sort by date (newest first) if not already sorted
          filteredMediaFiles.sort((a: any, b: any) => {
            try {
              return new Date(b.modified).getTime() - new Date(a.modified).getTime();
            } catch (e) {
              return 0;
            }
          });
          
          // Limit to requested page size
          const limitedItems = filteredMediaFiles.slice(0, pageSize);
          
          // Update the response with filtered items
          const endTime = performance.now();
          logger.debug('Media files filtered and sorted', {
            component: 'encryptedFilesService.listMediaFiles',
            totalItems: response.items.length,
            mediaItems: mediaFiles.length,
            filteredItems: filteredMediaFiles.length,
            returnedItems: limitedItems.length,
            processingTime: `${(endTime - startTime).toFixed(2)}ms`
          });
          
          // Create modified response with our filtered data
          const modifiedResponse = {
            ...response,
            items: limitedItems,
            total: filteredMediaFiles.length,
            hasMore: filteredMediaFiles.length > pageSize,
            // Use the original API's cursor format instead of our custom one
            // This ensures compatibility with the backend's pagination expectations
            nextCursor: response.nextCursor || (limitedItems.length > 0 && filteredMediaFiles.length > pageSize ? 
              limitedItems[limitedItems.length - 1].id : undefined)
          };
          
          // Cache the response
          try {
            localStorage.setItem(cacheKey, JSON.stringify({
              timestamp: now,
              data: modifiedResponse
            }));
            
            logger.debug('Cached media files response', {
              component: 'encryptedFilesService.listMediaFiles',
              timestamp: now,
              itemCount: modifiedResponse.items.length
            });
          } catch (e) {
            // Ignore storage errors
            logger.warn('Failed to cache media files response', {
              component: 'encryptedFilesService.listMediaFiles',
              error: e
            });
          }
          
          return modifiedResponse;
        }
        
        return response;
      } catch (error) {
        logger.error('Error calling media files endpoint', {
          component: 'encryptedFilesService.listMediaFiles',
          error
        });
        throw error;
      }
    } catch (error) {
      logger.error('Error in listMediaFiles:', {
        component: 'encryptedFilesService.listMediaFiles',
        error
      });
      throw error;
    }
  }

};

export default encryptedFilesService;