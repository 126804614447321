/**
 * @file: hooks/media/useMediaGallery.ts
 * @description: React hook for media gallery operations
 * @version: 1.0.0
 */

import { useState, useEffect, useCallback, useMemo } from 'react';
import { mediaService, MediaItem, MediaListParams, MediaStats } from '../../services/media.service';
import { useCloudDrive } from '../../context/cloud-drive/CloudDriveContext';

export interface MediaGalleryOptions {
  driveId?: string;
  pageSize?: number;
  initialMediaTypes?: string[];
  autoLoad?: boolean;
}

export interface MediaDateRange {
  start: string;
  end: string;
}

export interface MediaGalleryState {
  items: MediaItem[];
  isLoading: boolean;
  hasMore: boolean;
  nextCursor: string | null;
  totalItems: number;
  error: string | null;
  stats: MediaStats | null;
  mediaTypes: string[];
  dateRange: MediaDateRange | null;
  favoritesOnly: boolean;
}

export function useMediaGallery({
  driveId: initialDriveId,
  pageSize = 50,
  initialMediaTypes = ['image', 'video'],
  autoLoad = true
}: MediaGalleryOptions = {}) {
  const { currentDrive } = useCloudDrive();
  const driveId = initialDriveId || (currentDrive?.id || '');

  const [state, setState] = useState<MediaGalleryState>({
    items: [],
    isLoading: false,
    hasMore: false,
    nextCursor: null,
    totalItems: 0,
    error: null,
    stats: null,
    mediaTypes: initialMediaTypes,
    dateRange: null,
    favoritesOnly: false
  });

  // Load media stats
  const loadStats = useCallback(async () => {
    if (!driveId) return;
    
    try {
      const stats = await mediaService.getMediaStats(driveId);
      setState(prev => ({ ...prev, stats }));
    } catch (error) {
      console.error('Failed to load media stats:', error);
      setState(prev => ({ ...prev, error: 'Failed to load media statistics' }));
    }
  }, [driveId]);

  // Load media items
  const loadItems = useCallback(async (reset: boolean = false) => {
    if (!driveId) return;
    
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      
      const params: MediaListParams = {
        drive_id: driveId,
        media_types: state.mediaTypes,
        cursor: reset ? null : state.nextCursor,
        page_size: pageSize,
        date_range: state.dateRange,
        favorites_only: state.favoritesOnly
      };
      
      const response = await mediaService.listMediaFiles(params);
      
      setState(prev => ({
        ...prev,
        items: reset ? response.items : [...prev.items, ...response.items],
        hasMore: response.has_more,
        nextCursor: response.next_cursor,
        totalItems: response.total,
        isLoading: false
      }));
    } catch (error) {
      console.error('Failed to load media items:', error);
      setState(prev => ({ 
        ...prev, 
        isLoading: false, 
        error: 'Failed to load media items' 
      }));
    }
  }, [driveId, pageSize, state.mediaTypes, state.nextCursor, state.dateRange, state.favoritesOnly]);

  // Set media types filter
  const setMediaTypes = useCallback((types: string[]) => {
    setState(prev => ({ ...prev, mediaTypes: types }));
  }, []);

  // Set date range filter
  const setDateRange = useCallback((range: MediaDateRange | null) => {
    setState(prev => ({ ...prev, dateRange: range }));
  }, []);

  // Set favorites only filter
  const setFavoritesOnly = useCallback((favoritesOnly: boolean) => {
    setState(prev => ({ ...prev, favoritesOnly }));
  }, []);

  // Reset all filters
  const resetFilters = useCallback(() => {
    setState(prev => ({
      ...prev,
      mediaTypes: initialMediaTypes,
      dateRange: null,
      favoritesOnly: false
    }));
  }, [initialMediaTypes]);

  // Load initial data
  useEffect(() => {
    if (autoLoad && driveId) {
      loadStats();
      loadItems(true);
    }
  }, [autoLoad, driveId, loadStats, loadItems]);

  // Handle filter changes - only reload when filters actually change
  const [prevFilters, setPrevFilters] = useState({
    driveId,
    mediaTypes: state.mediaTypes,
    dateRange: state.dateRange,
    favoritesOnly: state.favoritesOnly
  });
  
  useEffect(() => {
    // Create current filter state
    const currentFilters = {
      driveId,
      mediaTypes: state.mediaTypes,
      dateRange: state.dateRange,
      favoritesOnly: state.favoritesOnly
    };
    
    // Check if any filter has changed - only reload if actually changed
    const hasChanged = 
      prevFilters.driveId !== currentFilters.driveId ||
      prevFilters.favoritesOnly !== currentFilters.favoritesOnly ||
      JSON.stringify(prevFilters.mediaTypes) !== JSON.stringify(currentFilters.mediaTypes) ||
      JSON.stringify(prevFilters.dateRange) !== JSON.stringify(currentFilters.dateRange);
    
    if (hasChanged && driveId) {
      console.log('Filters changed, reloading items');
      loadItems(true);
      setPrevFilters(currentFilters);
    }
  }, [driveId, state.mediaTypes, state.dateRange, state.favoritesOnly, loadItems, prevFilters]);

  // Check if there are any active filters
  const hasActiveFilters = useMemo(() => {
    return (
      state.mediaTypes.length !== initialMediaTypes.length ||
      state.mediaTypes.some(type => !initialMediaTypes.includes(type)) ||
      state.dateRange !== null ||
      state.favoritesOnly
    );
  }, [initialMediaTypes, state.mediaTypes, state.dateRange, state.favoritesOnly]);

  return {
    ...state,
    refresh: () => loadItems(true),
    loadMore: () => loadItems(false),
    setMediaTypes,
    setDateRange,
    setFavoritesOnly,
    resetFilters,
    hasActiveFilters
  };
}

export default useMediaGallery;