/**
 * @file src/layouts/ghost/components/create-core/steps/shield-selection/components/ShieldCard.tsx
 */

import React from 'react';
import { motion } from 'framer-motion';
import { Info, ArrowUpRight } from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { GhostGlass } from '@/app/ghost/components/common/GhostGlass';
import { cn } from '@/utils/utils';
import type { Shield } from '../types/shield-types';

interface ShieldCardProps {
  shield: Shield;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onShowDetails: (shield: Shield) => void;
  className?: string;
}

export const ShieldCard: React.FC<ShieldCardProps> = ({
  shield,
  isSelected,
  onSelect,
  onShowDetails,
  className
}) => {
  const { t } = useGhostTranslation();
  const Icon = shield.icon;

  return (
    <GhostGlass
      onClick={() => onSelect(shield.id)}
      className={cn(
        "relative overflow-hidden cursor-pointer group",
        "transition-all duration-300",
        isSelected && [
          "ring-1 ring-[hsl(var(--ghost-accent))]",
          "bg-[hsl(var(--ghost-accent))]/5"
        ],
        className
      )}
      opacity={isSelected ? "medium" : "low"}
      rounded="lg"
    >
      <div className="p-3.5"> {/* Reduced padding */}
        <div className="flex items-start space-x-3">
          {/* Icon */}
          <div className={cn(
            "relative w-8 h-8 rounded-lg", // Smaller icon container
            "flex items-center justify-center",
            "bg-[hsl(var(--ghost-accent))]/10",
            "group-hover:bg-[hsl(var(--ghost-accent))]/15",
            "transition-colors duration-300"
          )}>
            <Icon className="w-4 h-4 text-[hsl(var(--ghost-accent))]" /> {/* Smaller icon */}
          </div>

          {/* Content */}
          <div className="flex-1 min-w-0">
            {/* Title and Upgrade */}
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-sm font-medium text-[hsl(var(--ghost-foreground))]"> {/* Smaller text */}
                  {String(t(shield.nameKey))}
                </h3>
                <p className="text-xs text-[hsl(var(--ghost-foreground-muted))]"> {/* Smaller text */}
                  {String(t(shield.descriptionKey))}
                </p>
              </div>

              <div className="flex items-center space-x-2 ml-2">
                {shield.requiresUpgrade && (
                  <motion.button
                    onClick={(e) => {
                      e.stopPropagation();
                      // Handle upgrade
                    }}
                    className={cn(
                      "flex items-center space-x-1 px-2 py-0.5", // Reduced padding
                      "rounded-full text-xs font-medium",
                      "bg-[hsl(var(--ghost-accent))]/10",
                      "text-[hsl(var(--ghost-accent))]",
                      "hover:bg-[hsl(var(--ghost-accent))]/20",
                      "transition-colors duration-200"
                    )}
                  >
                    <span>Upgrade</span>
                    <ArrowUpRight className="w-3 h-3" />
                  </motion.button>
                )}

                {/* Info Button */}
                <motion.button
                  onClick={(e) => {
                    e.stopPropagation();
                    onShowDetails(shield);
                  }}
                  className={cn(
                    "p-1 rounded-full", // Smaller padding
                    "hover:bg-[hsl(var(--ghost-accent))]/10",
                    "transition-colors duration-200"
                  )}
                >
                  <Info className="w-3.5 h-3.5 text-[hsl(var(--ghost-foreground-muted))]" /> {/* Smaller icon */}
                </motion.button>
              </div>
            </div>

            {/* Features */}
            <div className="mt-2 space-y-1.5"> {/* Reduced spacing */}
              {shield.features.simple.map((feature) => (
                <div 
                  key={feature.id}
                  className="flex items-center space-x-2"
                >
                  <div className={cn(
                    "w-1.5 h-1.5 rounded-full", // Smaller dots
                    "bg-[hsl(var(--ghost-accent))]",
                    "opacity-75"
                  )} />
                  <span className="text-xs text-[hsl(var(--ghost-foreground-muted))]"> {/* Smaller text */}
                    {String(t(feature.titleKey))}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </GhostGlass>
  );
};

export default ShieldCard;