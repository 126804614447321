/**
 * @file src/components/ui/button.tsx
 * @description Enhanced button component with consistent styling
 * @version 2.0.0
 */

import * as React from "react";
import { cn } from "@/utils/utils";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link';
  size?: 'default' | 'sm' | 'lg' | 'icon';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    // Update the base styles to use rounded-xl instead of rounded-lg
    const baseStyles = "inline-flex items-center justify-center rounded-xl text-sm font-medium transition-all duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50";

    // Enhanced variants with better shadows and transitions
    const variants = {
      default: "bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-700 shadow-sm hover:shadow-md",
      destructive: "bg-red-500 text-white hover:bg-red-600 active:bg-red-700 shadow-sm hover:shadow-md",
      outline: "border border-gray-200 bg-white/80 hover:bg-gray-50 hover:border-gray-300 shadow-sm hover:shadow-md dark:border-gray-800 dark:bg-gray-950/80 dark:hover:bg-gray-800 dark:hover:border-gray-700",
      secondary: "bg-gray-100 text-gray-900 hover:bg-gray-200 active:bg-gray-300 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-700 shadow-sm hover:shadow-md",
      ghost: "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50",
      link: "text-gray-900 underline-offset-4 hover:underline dark:text-gray-50",
    };

    // Updated sizes with better padding
    const sizes = {
      default: "h-10 px-5 py-2",
      sm: "h-9 rounded-xl px-4 text-xs",
      lg: "h-11 rounded-xl px-8",
      icon: "h-10 w-10",
    };

    return (
      <button
        className={cn(
          baseStyles,
          variants[variant],
          sizes[size],
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

// Button Group
interface ButtonGroupProps extends React.HTMLAttributes<HTMLDivElement> {}

// Update ButtonGroup corners to match
const ButtonGroup = React.forwardRef<HTMLDivElement, ButtonGroupProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "inline-flex items-center justify-center rounded-xl border border-gray-200 bg-white shadow-sm dark:border-gray-800 dark:bg-gray-950",
          className
        )}
        {...props}
      />
    );
  }
);

ButtonGroup.displayName = "ButtonGroup";

interface ButtonGroupItemProps extends ButtonProps {}

const ButtonGroupItem = React.forwardRef<HTMLButtonElement, ButtonGroupItemProps>(
  ({ className, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="ghost"
        className={cn(
          "rounded-none first:rounded-l-lg last:rounded-r-lg",
          "border-r last:border-r-0 border-gray-200 dark:border-gray-800",
          "hover:bg-gray-50 dark:hover:bg-gray-800",
          "data-[state=on]:bg-gray-100 dark:data-[state=on]:bg-gray-800",
          className
        )}
        {...props}
      />
    );
  }
);

ButtonGroupItem.displayName = "ButtonGroupItem";

export { Button, ButtonGroup, ButtonGroupItem };