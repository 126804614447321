/**
 * @file src/components/encrypted-drive/EncryptedDriveSection.tsx
 * @description Enhanced encrypted drive section with better UI and actions
 * @version 1.1.0
 */

import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { 
  Shield, Lock, Unlock, Plus, ShieldOff, 
  Search, AlertCircle 
} from 'lucide-react';
import { useEncryptedDrives } from '@/hooks/encrypted-drive/useEncryptedDrive';
import { useToast } from '@/components/ui/toast';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import { CircularProgress } from '@/components/ui/circular-progress';
import { CreateDriveDialog } from './dialogs/CreateDriveDialog';
import { UnlockDriveDialog } from './dialogs/UnlockDriveDialog';
import { LockAllConfirmDialog } from './dialogs/LockAllConfirmDialog';
import { EmptyState } from './EmptyState';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { ANIMATION_CONFIG } from '@/config/animation.config';
import type { EncryptedDrive } from '@/types/encrypted-drive.types';
import { useCloudDrive } from '@/context/cloud-drive';
import { encryptionService } from '@/services/encrypted-drive/encryption-singleton';
import { EncryptionTier } from '@/types/encrypted-drive.types';


export const EncryptedDriveSection: React.FC = () => {
  const { t } = useTranslation();
  const { drives, isLoading, lockDrive } = useEncryptedDrives();
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setEncryptedDrive } = useCloudDrive();

  // Local state
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [selectedDrive, setSelectedDrive] = useState<EncryptedDrive | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showLockAllConfirm, setShowLockAllConfirm] = useState(false);
  const [isLockingAll, setIsLockingAll] = useState(false);

  // Derived state
  const filteredDrives = useMemo(() => 
    drives.filter((drive: any) => 
      drive.name.toLowerCase().includes(searchQuery.toLowerCase())
    ),
    [drives, searchQuery]
  );

  const unlockedDrivesCount = useMemo(() => 
    drives.filter((drive: any) => drive.status === 'unlocked').length,
    [drives]
  );

  // Enhanced handleDriveClick
  const handleDriveClick = useCallback((drive: EncryptedDrive) => {
    logger.debug('Drive clicked', { 
      component: 'EncryptedDriveSection',
      data: { 
        driveId: drive.id,
        status: drive.status,
        name: drive.name 
      }
    });

    // Get latest state
    const cachedDrives = queryClient.getQueryData<EncryptedDrive[]>(
      ['encrypted-drives', 'list']
    ) || [];
    const cachedDrive = cachedDrives.find((d: any) => d.id === drive.id);
    
    // Use cached status or fallback to drive status
    const currentStatus = cachedDrive?.status || drive.status;
    
    // Check if the drive is already unlocked in our encryption service
    const isDriveUnlocked = encryptionService.isDriveUnlocked(drive.id);
    const hasStoredKeys = !!encryptionService.getDecryptedKeys(drive.id);

    logger.debug('Checking drive state', {
      component: 'EncryptedDriveSection',
      data: {
        driveId: drive.id,
        currentStatus,
        isDriveUnlocked,
        hasStoredKeys,
        cachedStatus: cachedDrive?.status,
        originalStatus: drive.status
      }
    });

    // If drive is unlocked AND we have keys, navigate directly
    if (currentStatus === 'unlocked' && isDriveUnlocked && hasStoredKeys) {
      logger.debug('Drive is unlocked with valid keys, navigating', {
        component: 'EncryptedDriveSection',
        data: {
          driveId: drive.id,
          encryptionTier: drive.encryption_tier
        }
      });

      // Set encrypted drive context
      setEncryptedDrive({
        isEncryptedMode: true,
        encryptedDriveId: drive.id,
        encryptionTier: drive.encryption_tier as EncryptionTier,
        driveName: drive.name
      });

      // Navigate to cloud drive view
      navigate(`/encrypted-drives/${drive.id}`, {
        replace: true
      });
      return;
    }

    // Otherwise show unlock dialog - even if UI says unlocked but keys are missing
    logger.debug('Opening unlock dialog', { 
      component: 'EncryptedDriveSection',
      data: {
        driveId: drive.id,
        currentStatus,
        isDriveUnlocked,
        hasStoredKeys,
        reason: !hasStoredKeys ? 'missing_keys' : 'locked_status'
      }
    });
    setSelectedDrive(drive);
  }, [navigate, setEncryptedDrive, queryClient]);

  // Also update UnlockDriveDialog success handler to navigate
  const handleUnlockSuccess = useCallback((drive: EncryptedDrive) => {
    logger.debug('Drive unlock successful', {
      component: 'EncryptedDriveSection',
      action: 'handleUnlockSuccess',
      data: {
        driveId: drive.id,
        encryptionTier: drive.encryption_tier
      }
    });
  
    // Set encrypted drive context
    setEncryptedDrive({
      isEncryptedMode: true,
      encryptedDriveId: drive.id,
      encryptionTier: drive.encryption_tier as EncryptionTier
    });
  
    // Navigate with a slight delay for success animation
    setTimeout(() => {
      navigate(`/encrypted-drives/${drive.id}`, {
        replace: true
      });
    }, 1500);
  }, [navigate, setEncryptedDrive]);

  // Handle lock all drives
// Handle lock all drives
// Improved handleLockAll function for BigMIND UI
// Improved handleLockAll function for sequential locking
const handleLockAll = async () => {
  try {
    setIsLockingAll(true);
    const unlockedDrives = drives.filter((d: any) => d.status === 'unlocked');
    
    logger.debug('Starting lock all process', {
      component: 'EncryptedDriveSection',
      action: 'handleLockAll',
      unlockedCount: unlockedDrives.length
    });

    // Lock each drive sequentially to better isolate failures
    let successCount = 0;
    const successfullyLockedDriveIds = new Set<string>();
    const errors: Record<string, string> = {};

    for (const drive of unlockedDrives) {
      try {
        logger.debug('Locking drive in sequence', { 
          driveId: drive.id,
          driveName: drive.name
        });
        
        await lockDrive({ driveId: drive.id, force: true });
        successfullyLockedDriveIds.add(drive.id);
        successCount++;
        
        // Update UI immediately for better user experience
        queryClient.setQueryData<EncryptedDrive[]>(
          ['encrypted-drives', 'list'],
          old => (old || []).map((d: any) => 
            d.id === drive.id ? { ...d, status: 'locked' } : d
          )
        );
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        errors[drive.id] = errorMessage;
        
        logger.error('Failed to lock drive:', {
          component: 'EncryptedDriveSection',
          action: 'handleLockAll',
          driveId: drive.id,
          error: errorMessage
        });
      }
    }

    // Show appropriate toast
    if (successCount === unlockedDrives.length) {
      showToast(
        t('encrypted_drive.actions.lock_success'),
        'success'
      );
    } else if (successCount > 0) {
      showToast(
        t('encrypted_drive.actions.lock_partial', {
          successful: successCount,
          total: unlockedDrives.length
        }),
        'warning'
      );
    } else {
      showToast(
        t('encrypted_drive.actions.lock_failed'),
        'error'
      );
    }
  } catch (error) {
    logger.error('Error during lock all:', error);
    showToast(
      t('encrypted_drive.actions.lock_failed'),
      'error'
    );
  } finally {
    setIsLockingAll(false);
    setShowLockAllConfirm(false);
  }
};

  // Render drive item
  const renderDriveItem = useCallback((drive: EncryptedDrive) => (
    <motion.div
      key={drive.id}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={ANIMATION_CONFIG.variants.slideUp}
      transition={ANIMATION_CONFIG.transition.default}
    >
      <button
        onClick={() => handleDriveClick(drive)}
        className={cn(
          "w-full p-4 rounded-lg",
          "flex items-center justify-between",
          "group hover:bg-gray-50 dark:hover:bg-gray-800/50",
          "transition-all duration-200",
          "border border-gray-100 dark:border-gray-800",
          drive.status === 'unlocked' && "bg-blue-50/50 dark:bg-blue-900/20"
        )}
      >
        <div className="flex items-center space-x-4">
          <div className={cn(
            "p-2 rounded-lg transition-colors duration-200",
            drive.status === 'unlocked'
              ? "bg-blue-100 dark:bg-blue-900/50"
              : "bg-gray-100 dark:bg-gray-800"
          )}>
            <Shield 
              className={cn(
                "h-5 w-5",
                drive.status === 'unlocked' 
                  ? "text-blue-500 dark:text-blue-400"
                  : "text-gray-400 dark:text-gray-500"
              )} 
            />
          </div>
          <div className="text-left">
            <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
              {drive.name}
            </p>
            <div className="flex items-center space-x-2 mt-1">
              <Badge
                variant={drive.status === 'unlocked' ? 'default' : 'secondary'}
                className="text-xs"
              >
                {t(`encrypted_drive.status.${drive.status}`)}
              </Badge>
              <span className="text-xs text-gray-500 dark:text-gray-400">
                {t('encrypted_drive.encryption_type', { 
                  type: drive.encryption_tier 
                })}
              </span>
            </div>
          </div>
        </div>

        {drive.status === 'locked' ? (
          <Lock 
            className="h-4 w-4 text-gray-400 dark:text-gray-500 
                    group-hover:text-blue-500 dark:group-hover:text-blue-400
                    transition-colors duration-200" 
          />
        ) : (
          <Unlock 
            className="h-4 w-4 text-blue-500 dark:text-blue-400" 
          />
        )}
      </button>
    </motion.div>
  ), [t, handleDriveClick]);

  return (
    <div className="p-6 space-y-6">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
          {t('encrypted_drive.title')}
        </h2>

        <div className="flex items-center space-x-3">
          <Button
            onClick={() => setShowCreateDialog(true)}
            className="space-x-2"
            size="lg"
          >
            <Plus className="h-4 w-4" />
            <span>{t('encrypted_drive.create_new')}</span>
          </Button>

          <Button
            variant="destructive"
            onClick={() => setShowLockAllConfirm(true)}
            className="space-x-2"
            size="lg"
            disabled={unlockedDrivesCount === 0 || isLockingAll}
          >
            <ShieldOff className="h-4 w-4" />
            <span>{t('encrypted_drive.actions.instant_lock')}</span>
          </Button>
        </div>
      </div>

      {/* Search Bar */}
      {drives.length > 0 && (
        <div className="relative max-w-md">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('encrypted_drive.search_placeholder')}
            className="pl-9"
          />
        </div>
      )}

      {/* Warning Banner */}
      {unlockedDrivesCount > 0 && (
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={ANIMATION_CONFIG.variants.fadeIn}
          className="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-100 dark:border-yellow-800 rounded-lg p-4"
        >
          <div className="flex items-center space-x-3">
            <AlertCircle className="h-5 w-5 text-yellow-600 dark:text-yellow-400" />
            <p className="text-sm text-yellow-800 dark:text-yellow-200">
              {t('encrypted_drive.unlocked_drives_warning', {
                count: unlockedDrivesCount
              })}
            </p>
          </div>
        </motion.div>
      )}

      {/* Drives List */}
      <div className="space-y-3">
        <AnimatePresence mode="popLayout">
          {isLoading ? (
            <div className="flex items-center justify-center py-12">
              <CircularProgress 
                percentage={100} 
                size={40} 
                strokeWidth={3}
              >
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {t('common.loading')}
                </span>
              </CircularProgress>
            </div>
          ) : drives.length === 0 ? (
            <EmptyState onCreateDrive={() => setShowCreateDialog(true)} />
          ) : (
            filteredDrives.map((drive: any) => renderDriveItem(drive))
          )}
        </AnimatePresence>
      </div>

      {/* Dialogs */}
      <CreateDriveDialog 
        open={showCreateDialog}
        onOpenChange={setShowCreateDialog}
      />
      
      {/* Update UnlockDriveDialog to handle success */}
      {selectedDrive && (
        <UnlockDriveDialog
            drive={selectedDrive}
            onOpenChange={() => setSelectedDrive(null)}
            onUnlockSuccess={handleUnlockSuccess}  // Fix: Pass the handler directly
        />
        )}

      <LockAllConfirmDialog
        open={showLockAllConfirm}
        onOpenChange={setShowLockAllConfirm}
        onConfirm={handleLockAll}
        unlockedCount={unlockedDrivesCount}
        isLocking={isLockingAll}
      />
    </div>
  );
};