/**
 * @file src/hooks/root-drive/useRootDrive.ts
 * @description Hook for accessing RootDriveContext
 * @version 1.1.0
 */

import { useContext } from 'react';
import { useRootDrive as useRootDriveFromContext } from '@/context/root-drive/RootDriveContext';
import { EncryptedDriveState } from '@/context/cloud-drive/types';

// Enhanced wrapper for accessing Root Drive Context with fallback
export const useRootDrive = () => {
  try {
    // First try to get the context from the provider
    return useRootDriveFromContext();
  } catch (error) {
    // If provider is not available, return a dummy implementation
    // This prevents errors during initial rendering before context is ready
    const encryptedDrive: EncryptedDriveState = {
      isEncryptedMode: false,
      encryptionTier: undefined,
      encryptedDriveId: undefined
    };
    
    return {
      // Dummy implementation with sensible defaults
      devices: [],
      activeDriveId: null,
      primaryDriveId: null,
      systemDevice: null,
      isLoading: false,
      error: null,
      uploadStrategy: null,
      totalItemsCount: 0,
      
      getDeviceById: () => undefined,
      getDrivesByDevice: () => [],
      getDefaultDriveId: () => null,
      
      setActiveDriveId: () => {},
      refreshDevices: async () => {},
      refreshDeviceDrives: async () => {},
      refreshUploadStrategy: async () => {},
      
      // This is the important part that's used by components
      encryptedDrive
    };
  }
};

export default useRootDrive;