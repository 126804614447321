/**
 * @file src/components/encrypted-drive/dialogs/steps/StepPassword.tsx
 * @description Password creation step with strength meter
 * @version 1.0.0
 */

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Eye, EyeOff, Check, X, Shield, Loader } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { createEncryptedDriveService } from '@/services/encrypted-drive/createDrive.service';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import type { CreateDriveFormData } from '@/types/encrypted-drive.types';

interface StepPasswordProps {
  formData: CreateDriveFormData;
  setFormData: (data: CreateDriveFormData) => void;
  onNext: () => void;
  onBack: () => void;
}

export const StepPassword: React.FC<StepPasswordProps> = ({
  formData,
  setFormData,
  onNext,
  onBack,
}) => {
  const { t } = useTranslation();
  const { systemDevice } = useRootDrive();

  // State
  const [isGenerating, setIsGenerating] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  // Log component mount
  useEffect(() => {
    logger.info('Password step mounted', {
      component: 'StepPassword',
      data: {
        hasName: !!formData.name,
        hasEncryptionTier: !!formData.encryptionTier,
        hasSystemDevice: !!systemDevice?.id
      }
    });
  }, [formData.name, formData.encryptionTier, systemDevice?.id]);

  const [requirements, setRequirements] = useState([
    {
      text: t("encrypted_drive.password.requirements.length"),
      regex: /.{6,}/,
      met: false,
    },
    {
      text: t("encrypted_drive.password.requirements.uppercase"),
      regex: /[A-Z]/,
      met: false,
    },
    {
      text: t("encrypted_drive.password.requirements.lowercase"),
      regex: /[a-z]/,
      met: false,
    },
    {
      text: t("encrypted_drive.password.requirements.number"),
      regex: /[0-9]/,
      met: false,
    },
    {
      text: t("encrypted_drive.password.requirements.special"),
      regex: /[!@#$%^&*(),.?":{}|<>]/,
      met: false,
    },
  ]);

  // Update requirements when password changes
  useEffect(() => {
    if (formData.password) {
      const updatedRequirements = requirements.map((req: any) => ({
        ...req,
        met: req.regex.test(formData.password)
      }));
      setRequirements(updatedRequirements);

      // Clear error when password changes
      if (error) setError('');
    } else {
      // Reset requirements when password is empty
      setRequirements(requirements.map(req => ({ ...req, met: false })));
    }
  }, [formData.password, requirements, error]);
  const getStrengthPercentage = () => {
    const metCount = requirements.filter((r: any) => r.met).length;
    return (metCount / requirements.length) * 100;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!systemDevice?.id) {
      logger.error('System device not found', {
        component: 'StepPassword',
        action: 'handleSubmit'
      });
      setError(t('encrypted_drive.errors.no_system_device'));
      return;
    }

    if (!requirements.every((r: any) => r.met)) {
      setError(t('encrypted_drive.password.errors.requirements_not_met'));
      return;
    }

    if (formData?.password !== formData.confirmPassword) {
      setError(t('encrypted_drive.password.errors.passwords_dont_match'));
      return;
    }

    try {
      setIsGenerating(true);
      logger.info('Starting key generation', {
        component: 'StepPassword',
        action: 'generateKeys',
        data: {
          name: formData.name,
          deviceId: systemDevice.id,
          requirements: requirements.filter((r: any) => r.met).length
        }
      });

      const result = await createEncryptedDriveService({
        name: formData.name,
        password: formData.password,
        deviceId: systemDevice.id,
        encryptionTier: formData.encryptionTier
      });

      if (!result.success || !result.keyData) {
        throw new Error(result.error || 'Failed to generate encryption keys');
      }

      logger.info('Key generation successful', {
        component: 'StepPassword',
        action: 'generateKeys',
        data: {
          hasRecoveryKey: !!result.keyData.recoveryData?.key,
          hasVerificationHash: !!result.keyData.verificationHash
        }
      });

      setFormData({
        ...formData,
        keyData: result.keyData
      });

      setIsGenerating(false);
      onNext();

    } catch (error) {
      logger.error('Key generation failed:', error, {
        component: 'StepPassword',
        action: 'generateKeys'
      });
      
      setError(t('encrypted_drive.password.errors.generation_failed'));
      setIsGenerating(false);
    }
  };


  // Show loading state during key generation
  if (isGenerating) {
    return (
      <div className="flex flex-col items-center justify-center p-8 space-y-4">
        <Loader className="w-8 h-8 animate-spin text-blue-500" />
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {t('encrypted_drive.status.generating_key')}
        </p>
      </div>
    );
  }

  const strengthPercentage = getStrengthPercentage();
  const strengthColor = strengthPercentage < 40 ? 'red' : 
                       strengthPercentage < 70 ? 'yellow' : 
                       'green';

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <DialogHeader>
        <DialogTitle>
          {t('encrypted_drive.password.title')}
        </DialogTitle>
      </DialogHeader>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-6"
      >
        {/* Password Input */}
        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            {t('encrypted_drive.password.password_label')}
          </label>
          
          <div className="relative">
            <Input
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
                setError('');
              }}
              className={cn(
                "pr-10",
                error && "border-red-500 dark:border-red-500"
              )}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            >
              {showPassword ? (
                <EyeOff className="h-4 w-4" />
              ) : (
                <Eye className="h-4 w-4" />
              )}
            </button>
          </div>

          {/* Strength Meter */}
          <div className="space-y-2">
            <div className="h-2 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
              <motion.div
                className={cn(
                  "h-full rounded-full",
                  strengthColor === 'red' && "bg-red-500",
                  strengthColor === 'yellow' && "bg-yellow-500",
                  strengthColor === 'green' && "bg-green-500"
                )}
                initial={{ width: 0 }}
                animate={{ width: `${strengthPercentage}%` }}
                transition={{ duration: 0.3 }}
              />
            </div>
            
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {t('encrypted_drive.password.strength')}:{' '}
              <span className={cn(
                strengthColor === 'red' && "text-red-500",
                strengthColor === 'yellow' && "text-yellow-500",
                strengthColor === 'green' && "text-green-500"
              )}>
                {strengthPercentage < 40 ? t('encrypted_drive.password.strength_weak') :
                 strengthPercentage < 70 ? t('encrypted_drive.password.strength_medium') :
                 t('encrypted_drive.password.strength_strong')}
              </span>
            </div>
          </div>

          {/* Requirements List */}
          <div className="space-y-2">
            {requirements.map((req, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ 
                  opacity: 1, 
                  x: 0,
                  transition: { delay: index * 0.1 }
                }}
                className="flex items-center space-x-2"
              >
                {req.met ? (
                  <Check className="h-4 w-4 text-green-500" />
                ) : (
                  <X className="h-4 w-4 text-gray-300 dark:text-gray-600" />
                )}
                <span className={cn(
                  "text-sm",
                  req.met ? "text-green-500" : "text-gray-500 dark:text-gray-400"
                )}>
                  {req.text}
                </span>
              </motion.div>
            ))}
          </div>

          {/* Confirm Password */}
          <div className="pt-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t('encrypted_drive.password.confirm_label')}
            </label>
            
            <div className="relative mt-1">
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={(e) => {
                  setFormData({ ...formData, confirmPassword: e.target.value });
                  setError('');
                }}
                className={cn(
                  "pr-10",
                  error && "border-red-500 dark:border-red-500"
                )}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
              >
                {showConfirmPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
              </button>
            </div>
          </div>

          {error && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-sm text-red-500 dark:text-red-400"
            >
              {error}
            </motion.p>
          )}
        </div>

        {/* Security Note */}
        <div className="rounded-lg bg-blue-50 dark:bg-blue-900/20 p-4">
            <div className="flex items-start space-x-3">
                <Shield className="h-5 w-5 text-blue-500 dark:text-blue-400 mt-0.5" />
                <div className="flex-1">
                <h4 className="text-sm font-medium text-blue-900 dark:text-blue-100">
                    {t('encrypted_drive.password.security_note.title')}
                </h4>
                <p className="mt-1 text-sm text-blue-700 dark:text-blue-300">
                    {t('encrypted_drive.password.security_note.description')}
                </p>
                </div>
            </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between pt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            className="min-w-[100px]"
          >
            {t('common.back')}
          </Button>
          
          <Button
            type="submit"
            className="min-w-[100px]"
          >
            {t('common.continue')}
          </Button>
        </div>
      </motion.div>
    </form>
  );
};
