/**
 * @file src/components/ui/collapsible.tsx
 * @description Collapsible UI component
 * @version 1.0.0
 * @created 2024-02-24
 */

import * as React from "react";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import { cn } from "@/lib/utils";

/**
 * Re-export Radix Collapsible components for convenience
 */
const Collapsible = CollapsiblePrimitive.Root;
const CollapsibleTrigger = CollapsiblePrimitive.Trigger;

/**
 * Collapsible Content Implementation
 */
const CollapsibleContentImpl = CollapsiblePrimitive.Content;

/**
 * 1) Define a ref type based on the underlying component
 * 2) Define a props type extending CollapsiblePrimitive.Content props
 */
type CollapsibleContentRef = React.ElementRef<typeof CollapsibleContentImpl>;
export type CollapsibleContentProps = React.ComponentPropsWithoutRef<
  typeof CollapsibleContentImpl
> & {
  className?: string;
};

const CollapsibleContent = React.forwardRef<
  CollapsibleContentRef,
  CollapsibleContentProps
>(({ className, children, ...props }, ref) => {
  return (
    <CollapsibleContentImpl
      ref={ref}
      className={cn(
        "overflow-hidden transition-all duration-200",
        "data-[state=closed]:animate-collapsible-up",
        "data-[state=open]:animate-collapsible-down",
        className
      )}
      {...props}
    >
      {children}
    </CollapsibleContentImpl>
  );
});

CollapsibleContent.displayName = "CollapsibleContent";

export { Collapsible, CollapsibleTrigger, CollapsibleContent };
