/**
 * @file src/services/encrypted-sharing.service.ts
 * @description Service for encrypted drive share management with key wrapping and fragment preservation
 * @version 2.0.1
 * @updated 2025-04-06
 */


import { encryptionService } from './encrypted-drive/encryption-singleton';
import { authService } from './auth.service';
import { logger } from '@/utils/logger';
import type { DriveItem } from '@/types/cloud-drive.types';
import type { EncryptionTier } from '@/types/encrypted-drive.types';
import { ContentType } from './shared-item.service';


// Helper function to format UUID in a way that can be properly converted
function formatUuid(uuidStr: string): string {
    // Remove dashes to avoid any potential format issues
    return uuidStr.replace(/-/g, '');
  }

interface EncryptedShareSettings {
  shareType: 'private' | 'public' | 'password' | 'domain';
  permission: 'view' | 'download' | 'edit' | 'manage';
  expirationDate?: string; // ISO date string
  password?: string;
  accessLimit?: number;
  allowedDomains?: string[];
}

interface EncryptedShareResponse {
  id: string;
  itemId: string;
  shareKey: string;
  shareType: string;
  permission: string;
  expiresAt?: string;
  createdAt: string;
  lastAccessedAt?: string;
  currentAccessCount: number;
  maxAccessCount?: number;
  isActive: boolean;
  requiresPassword: boolean;
  shareUrl: string;
}

// Fix the API endpoint to match the backend route
const BASE_URL = '/api/v1/shares';

// Storage keys for sharing-related data
const FRAGMENT_STORAGE_KEY = 'encrypted_share_fragment';
const REDIRECT_URL_KEY = 'auth_redirect_url';

export const encryptedSharingService = {
  /**
   * Preserve and retrieve encryption fragments during auth flow
   */
  preserveUrlFragment: (fragment: string): void => {
    try {
      // Store the fragment for later retrieval
      localStorage.setItem(FRAGMENT_STORAGE_KEY, fragment);
      
      logger.debug('Preserved URL fragment for auth flow', {
        component: 'encryptedSharingService',
        data: { fragmentLength: fragment.length }
      });
    } catch (error) {
      logger.error('Error preserving URL fragment', {
        component: 'encryptedSharingService',
        error
      });
    }
  },
  
  /**
   * Retrieve preserved URL fragment
   */
  getPreservedFragment: (): string | null => {
    try {
      return localStorage.getItem(FRAGMENT_STORAGE_KEY);
    } catch (error) {
      logger.error('Error retrieving URL fragment', {
        component: 'encryptedSharingService',
        error
      });
      return null;
    }
  },
  
  /**
   * Clear preserved fragment
   */
  clearPreservedFragment: (): void => {
    try {
      localStorage.removeItem(FRAGMENT_STORAGE_KEY);
    } catch (error) {
      logger.error('Error clearing URL fragment', {
        component: 'encryptedSharingService',
        error
      });
    }
  },
  
  /**
   * Store redirect URL with fragment for post-auth navigation
   */
  storeRedirectUrl: (url: string): void => {
    try {
      localStorage.setItem(REDIRECT_URL_KEY, url);
      
      logger.debug('Stored redirect URL for auth flow', {
        component: 'encryptedSharingService',
        data: { url }
      });
    } catch (error) {
      logger.error('Error storing redirect URL', {
        component: 'encryptedSharingService',
        error
      });
    }
  },
  
  /**
   * Get stored redirect URL
   */
  getRedirectUrl: (): string | null => {
    try {
      return localStorage.getItem(REDIRECT_URL_KEY);
    } catch (error) {
      logger.error('Error retrieving redirect URL', {
        component: 'encryptedSharingService',
        error
      });
      return null;
    }
  },
  
  /**
   * Clear stored redirect URL
   */
  clearRedirectUrl: (): void => {
    try {
      localStorage.removeItem(REDIRECT_URL_KEY);
    } catch (error) {
      logger.error('Error clearing redirect URL', {
        component: 'encryptedSharingService',
        error
      });
    }
  },
  /**
   * Create a share for an encrypted file with key wrapping
   */
  createEncryptedShare: async (
    file: DriveItem,
    driveId: string,
    settings: EncryptedShareSettings
  ): Promise<EncryptedShareResponse> => {
    try {
      logger.debug('Creating encrypted share', {
        component: 'encryptedSharingService',
        data: { 
          itemId: file.id,
          itemType: file.type,
          driveId,
          shareType: settings.shareType
        }
      });

      // Get encryption keys for the drive
      const keys = encryptionService.getDecryptedKeys(driveId);
      if (!keys) {
        throw new Error('Drive is not unlocked - cannot share encrypted content');
      }

      // Generate a random share key for this share
      const shareKey = await generateShareKey();
      
      // Wrap the content and metadata keys with the share key
      const wrappedContentKey = await wrapKeyForSharing(keys.contentKey, shareKey);
      const wrappedMetadataKey = await wrapKeyForSharing(keys.metadataKey, shareKey);
      
      // Generate verification hash to later verify correct key
      const verificationHash = await generateVerificationHash(shareKey);
      
      // Format UUIDs to avoid endianness issues
      const formattedItemId = formatUuid(file.id);
      const formattedDriveId = formatUuid(driveId);
      
      // Enhanced logging with formatted UUIDs
      logger.debug('Encrypted share request details:', {
        component: 'encryptedSharingService',
        data: { 
          originalItemId: file.id,
          originalDriveId: driveId,
          formattedItemId,
          formattedDriveId
        }
      });
      
      // Create the share on the server with formatted UUIDs
      const { accessToken } = authService.getStoredToken();
      if (!accessToken) {
        logger.error('No authentication token available', {
          component: 'encryptedSharingService'
        });
        throw new Error('Not authenticated');
      }
      
      const response = await fetch(`${BASE_URL}/encrypted`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': accessToken
        } as HeadersInit,
        body: JSON.stringify({
          item_id: formattedItemId,
          drive_id: formattedDriveId,
          encrypted_content_key: arrayBufferToBase64(wrappedContentKey),
          encrypted_metadata_key: arrayBufferToBase64(wrappedMetadataKey),
          verification_hash: verificationHash,
          encryption_tier: file.encryption_tier || 'standard',
          share_type: settings.shareType,
          permission: settings.permission,
          expires_at: settings.expirationDate,
          password_hash: settings.password ? await generatePasswordHash(settings.password) : null,
          max_access_count: settings.accessLimit || null,
          allowed_domains: settings.allowedDomains
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        logger.error('Encrypted share creation error:', errorText);
        throw new Error(errorText);
      }

      const data = await response.json();
      
      // Format the share URL with key in fragment
      const shareKeyBase64 = arrayBufferToBase64(await exportKey(shareKey));
      const shareUrl = `${window.location.origin}/s/${data.share_key}#${encodeURIComponent(shareKeyBase64)}`;
      
      logger.debug('Encrypted share created successfully', {
        component: 'encryptedSharingService',
        data: { 
          shareId: data.id,
          shareKey: data.share_key,
          encryptionKey: shareKeyBase64.substring(0, 10) + '...', // Show just the beginning for security
          urlLength: shareUrl.length,
          fullUrl: shareUrl
        }
      });
      
      return {
        ...data,
        shareUrl
      };
    } catch (error) {
      logger.error('Error creating encrypted share:', error);
      throw error;
    }
  }

};

/**
 * Generate a strong random key for sharing
 */
async function generateShareKey(): Promise<CryptoKey> {
  try {
    return await crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256
      },
      true, // extractable
      ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
    );
  } catch (error) {
    logger.error('Error generating share key:', error);
    throw error;
  }
}

/**
 * Wrap a key for secure sharing
 */
async function wrapKeyForSharing(key: CryptoKey, wrapKey: CryptoKey): Promise<ArrayBuffer> {
  try {
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const wrappedKey = await crypto.subtle.wrapKey(
      'raw',
      key,
      wrapKey,
      {
        name: 'AES-GCM',
        iv
      }
    );
    
    // Prepend IV to wrapped key
    const result = new Uint8Array(iv.length + wrappedKey.byteLength);
    result.set(iv, 0);
    result.set(new Uint8Array(wrappedKey), iv.length);
    
    return result.buffer;
  } catch (error) {
    logger.error('Error wrapping key:', error);
    throw error;
  }
}

/**
 * Generate a verification hash for the share key
 */
async function generateVerificationHash(key: CryptoKey): Promise<string> {
  try {
    // Create a simple verification payload
    const verifyData = new TextEncoder().encode('bigmind-share-verification');
    
    // Convert key to signing key
    const keyData = await crypto.subtle.exportKey('raw', key);
    const signingKey = await crypto.subtle.importKey(
      'raw',
      keyData,
      {
        name: 'HMAC',
        hash: 'SHA-256'
      },
      false,
      ['sign']
    );
    
    // Sign the verification data
    const signature = await crypto.subtle.sign(
      'HMAC',
      signingKey,
      verifyData
    );
    
    return arrayBufferToBase64(signature);
  } catch (error) {
    logger.error('Error generating verification hash:', error);
    throw error;
  }
}

/**
 * Generate a password hash for securing shares
 */
async function generatePasswordHash(password: string): Promise<string> {
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(password);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    return arrayBufferToBase64(hashBuffer);
  } catch (error) {
    logger.error('Error generating password hash:', error);
    throw error;
  }
}

/**
 * Export a key to raw format
 */
async function exportKey(key: CryptoKey): Promise<ArrayBuffer> {
  try {
    return await crypto.subtle.exportKey('raw', key);
  } catch (error) {
    logger.error('Error exporting key:', error);
    throw error;
  }
}

/**
 * Convert ArrayBuffer to Base64 string
 */
function arrayBufferToBase64(buffer: ArrayBuffer): string {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((acc: any, byte: any) => acc + String.fromCharCode(byte), '');
  return btoa(binary);
}