/**
 * @file src/pages/cloud-drive/shared.tsx
 * @description Shared files view with integrated components
 * @version 1.1.0
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloudDriveLayout } from '@/components/cloud-drive/layout/CloudDriveLayout';
import { FileBrowserView } from '@/components/cloud-drive/browser/FileBrowserView';
import { FilePreviewSheet } from '@/components/cloud-drive/preview/FilePreviewSheet';

import { CloudDriveErrorBoundary } from '@/components/cloud-drive/error/ErrorBoundary';

import { logger } from '@/utils/logger';

const SharedWithMePage: React.FC = () => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = React.useState<string | null>(null);

  logger.debug('Rendering SharedWithMePage', {
    component: 'SharedWithMePage',
    data:{selectedFile}
  });

  return (
    <CloudDriveLayout>
      <CloudDriveErrorBoundary>
        <div className="flex flex-col h-full">
          {/* Header - Just title and description */}
          <div className="border-b border-gray-100 dark:border-gray-800 p-6">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
              {t('cloud_drive.shared_with_me')}
            </h1>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              {t('cloud_drive.shared_with_me_description')}
            </p>
          </div>

          {/* Content - Using MainToolbar for search/filters */}
          <div className="flex-1 min-h-0">
            <FileBrowserView
              folderId={selectedFile}
              viewType="shared"
              // Using a ref forwarding pattern rather than directly passing onFileSelect
              ref={null}
            />
            {/* Add a custom handler for file selection */}
            <div id="file-selection-handler" className="hidden" data-callback="setSelectedFile" />
          </div>

          {/* File Preview */}
          {selectedFile && (
            <FilePreviewSheet
              fileId={selectedFile}
              isOpen={!!selectedFile}
              onClose={() => setSelectedFile(null)}
            />
          )}
        </div>
      </CloudDriveErrorBoundary>
    </CloudDriveLayout>
  );
};

export default SharedWithMePage;