/**
 * @file src/utils/formatters.ts
 * @description Utility functions for formatting dates, numbers, and other data
 * @version 1.0.0
 * @created 2024-01-30
 */

import { format, isValid, parseISO } from 'date-fns';

/**
 * Format a date string or Date object to a consistent format
 * @param date - Date string or Date object
 * @param formatStr - Optional format string (defaults to 'MMM dd, yyyy')
 * @returns Formatted date string or '-' if invalid
 */
export const formatDate = (date: string | Date | null | undefined, formatStr: string = 'MMM dd, yyyy'): string => {
    if (!date) return '-';

    try {
        const dateObj = typeof date === 'string' ? parseISO(date) : date;
        
        if (!isValid(dateObj)) {
            console.warn('Invalid date provided to formatDate:', date);
            return '-';
        }

        return format(dateObj, formatStr);
    } catch (error) {
        console.error('Error formatting date:', error);
        return '-';
    }
};

/**
 * Format a number to include proper thousand separators
 * @param value - Number to format
 * @param minimumFractionDigits - Minimum fraction digits
 * @param maximumFractionDigits - Maximum fraction digits
 * @returns Formatted number string
 */
export const formatNumber = (
    value: number,
    minimumFractionDigits: number = 0,
    maximumFractionDigits: number = 2
): string => {
    try {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits,
            maximumFractionDigits
        }).format(value);
    } catch (error) {
        console.error('Error formatting number:', error);
        return String(value);
    }
};

/**
 * Format bytes to human readable size
 * @param bytes - Number of bytes
 * @param decimals - Number of decimal places
 * @returns Formatted size string
 */
export const formatBytes = (bytes: number, decimals: number = 2): string => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Format a duration in milliseconds to a human readable string
 * @param ms - Duration in milliseconds
 * @returns Formatted duration string
 */
export const formatDuration = (ms: number): string => {
    if (ms < 0) return '0s';
    
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days}d`;
    if (hours > 0) return `${hours}h`;
    if (minutes > 0) return `${minutes}m`;
    return `${seconds}s`;
};

/**
 * Format file size in bytes to a human readable string
 * @param bytes - File size in bytes
 * @returns Formatted file size string
 */
export const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 B';
    
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    
    return `${(bytes / Math.pow(1024, i)).toFixed(2).replace(/\.0+$/, '')} ${units[i]}`;
};