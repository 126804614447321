/**
 * @file src/components/common/ErrorBoundary.tsx
 * @description Enhanced error boundary component with detailed error information
 * @version 1.1.0
 */

import React from 'react';
import { AlertCircle, RotateCcw } from 'lucide-react';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

// Shared Error UI Component
const ErrorDisplay: React.FC<{
  error: Error | null;
  errorInfo?: React.ErrorInfo | null;
  onRefresh?: () => void;
}> = ({ error, errorInfo, onRefresh }) => (
  <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900 p-4">
    <div className="max-w-2xl w-full bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 space-y-6">
      {/* Error Icon */}
      <div className="flex justify-center">
        <div className="rounded-full bg-red-100 dark:bg-red-900/20 p-3">
          <AlertCircle className="w-8 h-8 text-red-600 dark:text-red-400" />
        </div>
      </div>
      
      {/* Error Title */}
      <div className="text-center space-y-2">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
          Oops! Something went wrong
        </h2>
        <p className="text-gray-600 dark:text-gray-300">
          We're sorry, but there was an error processing your request.
        </p>
      </div>

      {/* Error Details (in development only) */}
      {process.env.NODE_ENV === 'development' && (
        <div className="space-y-4">
          <div className="p-4 bg-gray-50 dark:bg-gray-900 rounded-lg overflow-auto">
            <p className="text-sm font-medium text-red-600 dark:text-red-400">
              {error?.toString()}
            </p>
            {errorInfo && (
              <pre className="mt-2 text-xs text-gray-600 dark:text-gray-400 whitespace-pre-wrap">
                {errorInfo.componentStack}
              </pre>
            )}
          </div>
        </div>
      )}

      {/* Action Buttons */}
      <div className="flex justify-center space-x-4">
        <button
          onClick={onRefresh}
          className="inline-flex items-center justify-center px-4 py-2 bg-blue-500 hover:bg-blue-600 
                   text-white font-medium rounded-lg transition-colors duration-200 space-x-2"
        >
          <RotateCcw className="w-4 h-4" />
          <span>Refresh Page</span>
        </button>
      </div>
    </div>
  </div>
);

// Main Error Boundary (Class Component)
export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    this.setState({
      error,
      errorInfo
    });
  }

  private handleRefresh = () => {
    window.location.reload();
  };

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorDisplay 
          error={this.state.error}
          errorInfo={this.state.errorInfo}
          onRefresh={this.handleRefresh}
        />
      );
    }

    return this.props.children;
  }
}

// Separate Route Error Component (Function Component)
import { useRouteError } from 'react-router-dom';

export const RouteErrorBoundary: React.FC = () => {
  const error = useRouteError();
  
  return (
    <ErrorDisplay 
      error={error instanceof Error ? error : new Error('Unknown error')}
      onRefresh={() => window.location.reload()}
    />
  );
};