/**
 * @file src/pages/debug/encryption/index.tsx
 * @description Debug page for testing encryption flows and data validation
 * @version 1.1.0
 * @created 2024-01-24
 */

import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/toast';

import { debugLogger } from '@/utils/debug-logger';

import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { EncryptionService } from '@/services/encrypted-drive/encryption.service';
import { encryptedDriveApi } from '@/services/encrypted-drive/encrypted-drive.service';
import type { EncryptionTier, EncryptedDrive } from '@/types/encrypted-drive.types';
import LogViewer from '@/components/debug/LogViewer';
import { RefreshCw, Copy, Key } from 'lucide-react';
import { CryptoTester } from '@/components/debug/CryptoTester';

const encryptionService = new EncryptionService();

const EncryptionDebug: React.FC = () => {
  const { showToast } = useToast();
  const { systemDevice } = useRootDrive();

  // State management
  const [driveName, setDriveName] = useState('');
  const [password, setPassword] = useState('');
  const [unlockPassword, setUnlockPassword] = useState('');
  const [recoveryInput, setRecoveryInput] = useState('');
  const [selectedDrive, setSelectedDrive] = useState<string>('');
  const [availableDrives, setAvailableDrives] = useState<EncryptedDrive[]>([]);
  const [recoveryKey, setRecoveryKey] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Load drives on mount
  useEffect(() => {
    handleListDrives();
  }, []);

  const handleListDrives = async () => {
    try {
      setIsLoading(true);
      debugLogger.info('Fetching encrypted drives from server');
      const drives = await encryptedDriveApi.listDrives();
      
      setAvailableDrives(drives);
      
      debugLogger.success('Drives retrieved successfully', {
        count: drives.length,
        drives: drives.map((d: any) => ({
          id: d.id,
          name: d.name,
          status: d.status,
          created_at: d.created_at
        }))
      });
    } catch (error) {
      debugLogger.error('Failed to fetch drives', { error });
      showToast('Failed to fetch drives', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateDrive = async () => {
    try {
      // Check for system device
      if (!systemDevice) {
        showToast('System device not found', 'error');
        return;
      }

      debugLogger.info('Starting drive creation test', {
        name: driveName,
        passwordLength: password.length,
        deviceId: systemDevice.id
      });

      // Generate encryption keys
      const keyData = await encryptionService.generateMasterKey(password);
      
      debugLogger.info('Generated encryption data:', {
        salt: keyData.salt,
        verificationHash: keyData.verificationHash,
        contentKeyLength: keyData.encryptedKeys.contentKey.length,
        metadataKeyLength: keyData.encryptedKeys.metadataKey.length,
        ivLength: keyData.encryptedKeys.iv.length,
        hasRecoveryData: !!keyData.recoveryData
      });

      // Create drive using API
      const response = await encryptedDriveApi.createDrive({
        name: driveName,
        device_id: systemDevice.id,
        encryption_tier: 'standard' as EncryptionTier,
        key_salt: keyData.salt,
        key_verification_hash: keyData.verificationHash,
        encrypted_content_key: keyData.encryptedKeys.contentKey,
        encrypted_metadata_key: keyData.encryptedKeys.metadataKey,
        encrypted_keys_iv: keyData.encryptedKeys.iv,
        recovery_verification_hash: keyData.recoveryData?.verification_hash,
        recovery_data: keyData.recoveryData && {
          key: keyData.recoveryData.key,
          verification_hash: keyData.recoveryData.verification_hash,
          encrypted_keys: {
            content_key: keyData.recoveryData.encrypted_keys.content_key,
            metadata_key: keyData.recoveryData.encrypted_keys.metadata_key,
            iv: keyData.encryptedKeys.iv
          }
        }
      });

      // Store recovery key if available
      if (keyData.recoveryData?.key) {
        setRecoveryKey(keyData.recoveryData.key);
        debugLogger.info('Recovery key saved for testing');
      }

      debugLogger.success('Drive created successfully', {
        driveId: response.id,
        name: response.name,
        status: response.status
      });

      showToast('Drive created successfully', 'success');
      setDriveName('');
      setPassword('');

      // Refresh drives list
      handleListDrives();

    } catch (error) {
      debugLogger.error('Drive creation failed', { 
        error: error instanceof Error ? {
          message: error.message,
          name: error.name,
          response: error instanceof Error && 'response' in error 
            ? (error as any).response?.data || (error as any).response?.statusText 
            : undefined
        } : error
      });
      showToast('Failed to create drive', 'error');
    }
  };

  const handleUnlockTest = async () => {
    try {
      if (!selectedDrive) {
        showToast('Please select a drive to unlock', 'error');
        return;
      }

      const drive = availableDrives.find((d: any) => d.id === selectedDrive);
      if (!drive) {
        debugLogger.error('Drive not found', { selectedDrive });
        return;
      }

      debugLogger.info('Starting unlock test', {
        driveId: selectedDrive, 
        driveName: drive.name,
        storedSalt: drive.key_salt,
        storedHash: drive.key_verification_hash
      });

      // Verify locally first
      const unlocked = await encryptionService.unlockDrive(unlockPassword, drive);
      
      debugLogger.info('Local verification result:', { success: unlocked });

      if (!unlocked) {
        debugLogger.error('Local verification failed');
        showToast('Local verification failed', 'error');
        return;
      }

      // Try server unlock
      debugLogger.info('Attempting server unlock');
      await encryptedDriveApi.unlockDrive(selectedDrive);

      debugLogger.success('Drive unlocked successfully');
      showToast('Drive unlocked successfully', 'success');
      setUnlockPassword('');
      
      // Refresh drives to update status
      handleListDrives();
      
    } catch (error) {
      debugLogger.error('Unlock test failed', { error });
      showToast('Failed to unlock drive', 'error');
    }
  };

  const handleRecoveryTest = async () => {
    try {
      if (!selectedDrive) {
        showToast('Please select a drive', 'error');
        return;
      }

      const drive = availableDrives.find((d: any) => d.id === selectedDrive);
      if (!drive) {
        debugLogger.error('Drive not found', { selectedDrive });
        return;
      }

      debugLogger.info('Starting recovery test', {
        driveId: selectedDrive,
        driveName: drive.name,
        recoveryKeyLength: recoveryInput.length
      });

      const recovered = await encryptionService.recoverDrive(recoveryInput, drive);
      
      debugLogger.info('Recovery test result:', { success: recovered });

      if (recovered) {
        showToast('Recovery successful', 'success');
        // Update drive status if needed
        handleListDrives();
      } else {
        showToast('Recovery verification failed', 'error');
      }

    } catch (error) {
      debugLogger.error('Recovery test failed', { error });
      showToast('Recovery test failed', 'error');
    }
  };

  const recoveryInstructions = `
  1. Create a new drive using Create Drive Test
  2. Note the Recovery Key displayed below the creation
  3. Try unlocking the drive with password
  4. Lock the drive (if needed)
  5. Use Recovery Test with the saved Recovery Key
  6. Verify the drive unlocks successfully
  
  Expected Results:
  - Drive creation should show recovery key
  - Regular unlock should work with password
  - Recovery should work with recovery key
  - Both methods should correctly decrypt drive keys
  `;  

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">Encryption Debug</h1>
        <Button 
          onClick={handleListDrives}
          className="flex items-center gap-2"
          disabled={isLoading}
        >
          <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
          Refresh Drives
        </Button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="space-y-6">
          {/* Create Drive Test */}
          <Card>
            <CardHeader>
              <CardTitle>Create Drive Test</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <Input
                placeholder="Drive Name"
                value={driveName}
                onChange={(e) => setDriveName(e.target.value)}
              />
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button 
                onClick={handleCreateDrive}
                disabled={!driveName || !password || !systemDevice}
                className="w-full"
              >
                Test Create Drive
              </Button>
            </CardContent>
          </Card>

          {/* Recovery Key Display */}
          {recoveryKey && (
            <Card>
              <CardHeader>
                <CardTitle>Recovery Key (Latest Created Drive)</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg overflow-x-auto">
                  <code className="text-sm break-all">{recoveryKey}</code>
                </div>
                <Button 
                  onClick={() => {
                    navigator.clipboard.writeText(recoveryKey);
                    showToast('Recovery key copied to clipboard', 'success');
                  }}
                  className="flex items-center gap-2"
                >
                  <Copy className="w-4 h-4" />
                  Copy Recovery Key
                </Button>
              </CardContent>
            </Card>
          )}

          {/* Unlock Test */}
          <Card>
            <CardHeader>
              <CardTitle>Unlock Test</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <select
                className="w-full p-2 border rounded-md dark:bg-gray-900 dark:border-gray-800"
                value={selectedDrive}
                onChange={(e) => setSelectedDrive(e.target.value)}
              >
                <option value="">Select a drive</option>
                {availableDrives.map((drive: any) => (
                  <option key={drive.id} value={drive.id}>
                    {drive.name} ({drive.status})
                  </option>
                ))}
              </select>
              
              <Input
                type="password"
                placeholder="Unlock Password"
                value={unlockPassword}
                onChange={(e) => setUnlockPassword(e.target.value)}
              />
              
              <Button 
                onClick={handleUnlockTest}
                disabled={!selectedDrive || !unlockPassword}
                className="w-full"
              >
                Test Unlock
              </Button>
            </CardContent>
          </Card>

          {/* Recovery Test */}
          <Card>
            <CardHeader>
              <CardTitle>Recovery Test
              <Button
        variant="ghost"
        size="sm"
        onClick={() => {
          showToast('Instructions copied to clipboard', 'success');
          navigator.clipboard.writeText(recoveryInstructions);
        }}
      >
        <Copy className="w-4 h-4" />
      </Button>
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <select
                className="w-full p-2 border rounded-md dark:bg-gray-900 dark:border-gray-800"
                value={selectedDrive}
                onChange={(e) => setSelectedDrive(e.target.value)}
              >
                <option value="">Select a drive</option>
                {availableDrives.map((drive: any) => (
                  <option key={drive.id} value={drive.id}>
                    {drive.name} ({drive.status})
                  </option>
                ))}
              </select>
              
              <Input
                placeholder="Recovery Key"
                value={recoveryInput}
                onChange={(e) => setRecoveryInput(e.target.value)}
              />
              
              <Button 
                onClick={handleRecoveryTest}
                disabled={!selectedDrive || !recoveryInput}
                className="flex items-center gap-2 w-full"
              >
                <Key className="w-4 h-4" />
                Test Recovery
              </Button>
              <div className="mt-4 text-sm text-gray-600 dark:text-gray-400">
                <h4 className="font-medium mb-2">Test Procedure:</h4>
                <ol className="list-decimal pl-4 space-y-1">
                  <li>Create new drive using Create Drive Test</li>
                  <li>Copy displayed Recovery Key</li>
                  <li>Test normal unlock with password</li>
                  <li>Use Recovery Test with saved key</li>
                </ol>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Log Viewer and Crypto Tester */}
        <div className="lg:row-span-2 space-y-6">
          <LogViewer />
          <CryptoTester />
        </div>
      </div>
    </div>
  );
};

export default EncryptionDebug;