/**
 * @file src/stores/uiStore.ts
 * @description Persistent UI state management using Zustand
 * @version 1.0.0
 * @created 2024-11-24
 */

import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface UIState {
  // Sidebar
  isSidebarCollapsed: boolean
  setSidebarCollapsed: (collapsed: boolean) => void
  
  // Theme
  theme: 'dark' | 'light' | 'system'
  setTheme: (theme: 'dark' | 'light' | 'system') => void
  
  // Generic state management
  preferences: Record<string, any>
  setPreference: (key: string, value: any) => void
  getPreference: (key: string, defaultValue?: any) => any
}

export const useUIStore = create<UIState>()(
  persist(
    (set, get) => ({
      // Sidebar state
      isSidebarCollapsed: true,
      setSidebarCollapsed: (collapsed) => set({ isSidebarCollapsed: collapsed }),
      
      // Theme state
      theme: 'system',
      setTheme: (theme) => set({ theme }),
      
      // Generic preferences
      preferences: {},
      setPreference: (key, value) => 
        set((state) => ({
          preferences: {
            ...state.preferences,
            [key]: value,
          },
        })),
      getPreference: (key, defaultValue) => {
        const state = get()
        return state.preferences[key] ?? defaultValue
      },
    }),
    {
      name: 'bigmind-ui-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        isSidebarCollapsed: state.isSidebarCollapsed,
        theme: state.theme,
        preferences: state.preferences,
      }),
    }
  )
)