/**
 * @file src/components/cloud-drive/layout/toolbar/components/ActionButtons.tsx
 * @description Action buttons component for main operations
 * @version 1.0.0
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, FolderPlus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import type { ActionButtonsProps } from '../types';

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  onUpload,
  onNewFolder,
  disabled = false,
  className
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleUploadClick = () => {
    logger.debug('Upload button clicked', {
      component: 'ActionButtons'
    });
    onUpload();
  };

  const handleNewFolderClick = () => {
    logger.debug('New folder button clicked', {
      component: 'ActionButtons'
    });
    onNewFolder();
  };

  return (
    <div className={cn("flex items-center gap-2", className)}>
      <Button
        variant="default"
        size="sm"
        onClick={handleUploadClick}
        disabled={disabled}
        className="bg-blue-500 hover:bg-blue-600 text-white"
      >
        <Upload className="h-4 w-4 mr-2" />
        {!isMobile && t('cloud_drive.actions.upload')}
      </Button>

      <Button
        variant="outline"
        size="sm"
        onClick={handleNewFolderClick}
        disabled={disabled}
      >
        <FolderPlus className="h-4 w-4 mr-2" />
        {!isMobile && t('cloud_drive.actions.new_folder')}
      </Button>
    </div>
  );
};

export default ActionButtons;