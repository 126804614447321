/**
 * @file src/pages/audit/index.tsx
 * @description Audit reporting page component
 * @version 3.5.0
 * @created 2024-02-24
 * @updated 2025-03-03
 */

import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

// Hooks & Services
import { useAudit } from "@/providers/AuditProvider";
import { useAuditReport } from "@/hooks/audit/useAuditReport";
import { auditService } from "@/services/audit.service";
import { useToast } from "@/components/ui/toast";
import { logger } from "@/utils/logger";

// Components
import { AuditContainer } from "@/components/audit/AuditContainer";
import { Button } from "@/components/ui/button";
import { ActionType } from "@/types/audit.types";
import { createEndDate, createStartDate } from "@/utils/date-utils";

const AuditPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { filters, setFilters, setSorting } = useAudit();

  // Reset counter to force refetch when sorting changes
  const [resetCounter, setResetCounter] = React.useState(0);

  // Track the current date range for debugging
  const [currentDateRange, setCurrentDateRange] = React.useState({
    fromDate: filters.fromDate,
    toDate: filters.toDate,
  });

  // Fetch audit data using custom hook
  const { auditLogs, users, devices, isLoading, isError } = useAuditReport({
    ...filters,
    resetCounter,
  });

  // Handle user filter change
  const handleUserChange = React.useCallback(
    (userId: string | undefined) => {
      logger.debug("User selection changed:", { userId } as any);
      setFilters({ userId, deviceId: undefined });
    },
    [setFilters]
  );

  // Handle device filter change
  const handleDeviceChange = React.useCallback(
    (deviceId: string | undefined) => {
      logger.debug("Device selection changed:", { deviceId } as any);
      setFilters({ deviceId });
    },
    [setFilters]
  );

  // Use a ref to store the latest resetCounter value for the callback
  // This prevents the callback from recreating on each counter change
  const resetCounterRef = React.useRef(resetCounter);
  React.useEffect(() => {
    resetCounterRef.current = resetCounter;
  }, [resetCounter]);

  // Handle search and filters with enhanced date handling
  const handleSearch = React.useCallback(
    (searchFilters: {
      search?: string;
      action?: ActionType;
      fromDate?: Date | null;
      toDate?: Date | null;
    }) => {
      // Process date filters with explicit boundary assignment
      // This is critical for proper date range filtering
      const fromDate = searchFilters.fromDate
        ? createStartDate(searchFilters.fromDate)
        : null;
      const toDate = searchFilters.toDate
        ? createEndDate(searchFilters.toDate)
        : null;

      // Store the current range for debugging purposes
      setCurrentDateRange({
        fromDate,
        toDate,
      });

      logger.debug("Search filters with processed dates:", {
        search: searchFilters.search,
        action: searchFilters.action,
        originalDates: {
          fromDate: searchFilters.fromDate?.toISOString(),
          toDate: searchFilters.toDate?.toISOString(),
        },
        processedDates: {
          fromDate: fromDate?.toISOString(),
          toDate: toDate?.toISOString(),
        },
      } as any);

      // Apply filters to context
      setFilters({
        search: searchFilters.search,
        action: searchFilters.action,
        fromDate,
        toDate,
      });

      // Increment the reset counter to force a data refresh
      // Use setTimeout to ensure state updates have completed
      setTimeout(() => {
        setResetCounter((prev) => prev + 1);
      }, 50);
    },
    [setFilters]
  );

  // Handle sort change with proper reset
  const handleSortChange = React.useCallback(
    (column: string, direction: "asc" | "desc") => {
      logger.debug("Sort changed:", { column, direction } as any);

      // First update context
      setSorting(column, direction);

      // Increment reset counter to force complete refetch
      setTimeout(() => {
        setResetCounter((prev) => prev + 1);
      }, 0);
    },
    [setSorting]
  );

  // Handle reset
  const handleReset = React.useCallback(() => {
    logger.debug("Resetting filters" as any);

    // Clear date range for debugging purposes
    setCurrentDateRange({
      fromDate: null,
      toDate: null,
    });

    // Reset filters
    setFilters({
      userId: undefined,
      deviceId: undefined,
      fromDate: null,
      toDate: null,
      action: undefined,
      search: undefined,
      // Keep sorting as is
      sortColumn: filters.sortColumn,
      sortDirection: filters.sortDirection,
    });

    // Trigger reset
    setTimeout(() => {
      setResetCounter((prev) => prev + 1);
    }, 0);
  }, [setFilters, filters.sortColumn, filters.sortDirection]);

  // Handle export
  const handleExport = React.useCallback(async () => {
    try {
      logger.debug("Starting audit log export", { filters } as any);

      const exportFilters = {
        ...filters,
      };

      const blob = await auditService.exportToCsv(exportFilters);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const fileName = `audit-log-${new Date().toISOString()}.csv`;

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      // Using string format for toast
      showToast(t("audit.export.downloadStarted"), "success");

      logger.debug("Export completed successfully" as any);
    } catch (error) {
      logger.error("Export failed:", error as any);
      showToast(t("audit.export.failed"), "error");
    }
  }, [filters, showToast, t]);

  // Handle load more
  const handleLoadMore = React.useCallback(() => {
    if (auditLogs.hasNextPage && !auditLogs.isFetchingNextPage) {
      logger.debug("Loading more audit logs" as any);
      auditLogs.fetchNextPage();
    }
  }, [auditLogs]);

  // Avoid excessive logging for each render
  const debugLogRef = React.useRef({
    dataLength: 0,
    totalCount: 0,
  });

  // Log for debugging - with throttling to prevent excessive logs
  React.useEffect(() => {
    const newDataLength = auditLogs.data?.length || 0;
    const newTotalCount = auditLogs.totalCount || 0;

    // Only log if something meaningful has changed
    if (
      debugLogRef.current.dataLength !== newDataLength ||
      debugLogRef.current.totalCount !== newTotalCount
    ) {
      debugLogRef.current = {
        dataLength: newDataLength,
        totalCount: newTotalCount,
      };

      logger.debug("Audit page data state", {
        dataLength: newDataLength,
        usersLoaded: users.data?.length || 0,
        devicesLoaded: devices.data?.length || 0,
        hasNextPage: auditLogs.hasNextPage,
        totalCount: newTotalCount,
        sortColumn: filters.sortColumn,
        sortDirection: filters.sortDirection,
        dateFilters: {
          fromDate: filters.fromDate?.toISOString(),
          toDate: filters.toDate?.toISOString(),
        },
        currentDateRange: {
          fromDate: currentDateRange.fromDate?.toISOString(),
          toDate: currentDateRange.toDate?.toISOString(),
        },
      } as any);
    }
  }, [
    auditLogs.data,
    auditLogs.hasNextPage,
    auditLogs.totalCount,
    users.data,
    devices.data,
    filters.sortColumn,
    filters.sortDirection,
    filters.fromDate,
    filters.toDate,
    currentDateRange,
    isLoading,
  ]);

  // Error handling
  React.useEffect(() => {
    if (isError) {
      logger.error("Error loading audit data" as any);
      showToast(t("audit.error.loadFailed"), "error");
    }
  }, [isError, showToast, t]);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6 space-y-6">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Button
          variant="ghost"
          size="sm"
          className="mb-4"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          {t("common.back")}
        </Button>

        <AuditContainer
          data={auditLogs.data || []}
          users={users.data || []}
          devices={devices.data || []}
          selectedUser={filters.userId}
          selectedDevice={filters.deviceId}
          isLoading={isLoading}
          isLoadingMore={auditLogs.isFetchingNextPage}
          hasNextPage={!!auditLogs.hasNextPage}
          isLoadingDevices={devices.isLoading}
          onUserChange={handleUserChange}
          onDeviceChange={handleDeviceChange}
          onLoadMore={handleLoadMore}
          onExport={handleExport}
          onReset={handleReset}
          onSearch={handleSearch}
          onSortChange={handleSortChange}
          totalCount={auditLogs.totalCount}
          currentSortColumn={filters.sortColumn}
          currentSortDirection={filters.sortDirection}
          className="min-h-[500px]"
        />
      </motion.div>
    </div>
  );
};

export default AuditPage;
