/**
 * @file src/components/ghost/common/background/GhostGridBackground.tsx
 * @description Enhanced grid background with multiple subtle effects
 * @version 2.0.0
 */

import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { setupCanvas, clearCanvas } from './utils/canvasHelpers';
import {
  useParticlesEffect,
  useQuantumEffect,
  useDataStreamEffect,
  useScanLinesEffect
} from './effects';

interface GhostGridBackgroundProps {
  className?: string;
}

export const GhostGridBackground: React.FC<GhostGridBackgroundProps> = ({
  className
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  logger.debug('Rendering GhostGridBackground', { 
    component: 'GhostGridBackground'
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const result = setupCanvas(canvas);
    if (!result) return;
    
    const { ctx, dimensions, cleanup } = result;

    // Initialize effects
    const particlesEffect = useParticlesEffect(ctx, dimensions);
    const quantumEffect = useQuantumEffect(ctx, dimensions);
    const dataStreamEffect = useDataStreamEffect(ctx, dimensions);
    const scanLinesEffect = useScanLinesEffect(ctx, dimensions);

    // Animation frame
    let animationFrame: number;

    // Main animation loop
    const animate = () => {
      clearCanvas(ctx, dimensions);

      // Layer the effects
      particlesEffect.render();
      quantumEffect.render();
      dataStreamEffect.render();
      scanLinesEffect.render();

      animationFrame = requestAnimationFrame(animate);
    };

    animate();

    // Cleanup
    return () => {
      cancelAnimationFrame(animationFrame);
      cleanup();
      particlesEffect.cleanup();
      quantumEffect.cleanup();
      dataStreamEffect.cleanup();
      scanLinesEffect.cleanup();
    };
  }, []);

  return (
    <div className={cn(
      "ghost-theme fixed inset-0 pointer-events-none",
      className
    )}>
      {/* Base grid */}
      <div className="ghost-grid absolute inset-0 opacity-30" />
      
      {/* Effects canvas */}
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full"
      />
      
      {/* Gradient overlay */}
      <motion.div
        className="absolute inset-0 bg-gradient-radial from-transparent via-transparent to-[hsl(var(--ghost-bg))]"
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.2, 0.4, 0.2] }}
        transition={{
          duration: 10,
          repeat: Infinity,
          ease: "linear"
        }}
      />
    </div>
  );
};

export default GhostGridBackground;