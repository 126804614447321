// src/hooks/favorites/useFavorites.ts

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { favoritesApi } from '@/services/favorites.service';
import type {
  FavoritesListParams,
  FavoritesListResponse,
  BatchFavoriteRequest,    // Add this import
  BatchFavoriteResponse,   // Add this import
} from '@/types/favorites.types';
import { logger } from '@/utils/logger';

// Query keys
export const favoriteKeys = {
  all: ['favorites'] as const,
  lists: () => [...favoriteKeys.all, 'list'] as const,
  list: (params: FavoritesListParams) => [...favoriteKeys.lists(), params] as const,
  stats: () => [...favoriteKeys.all, 'stats'] as const,
};

// Hook for fetching favorites list
export const useFavorites = (params: FavoritesListParams) => {
  return useQuery<FavoritesListResponse>({
    queryKey: ['favorites', params],
    queryFn: async () => {
      try {
        return await favoritesApi.listFavorites(params);
      } catch (error) {
        logger.error('Error fetching favorites:', error);
        throw error;
      }
    },
    placeholderData: (prevData) => prevData,
  });
};

// Hook for favorites statistics
export const useFavoriteStats = () => {
  return useQuery({
    queryKey: favoriteKeys.stats(),
    queryFn: () => favoritesApi.getFavoriteStats(),
    staleTime: 1000 * 60, // 1 minute
  });
};

// Hook for toggling favorite status
export const useToggleFavorite = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: favoritesApi.toggleFavorite,
    onSuccess: (data) => {
      logger.debug('Favorite toggled successfully', {
        component: 'useToggleFavorite',
        data,
      });
      queryClient.invalidateQueries({ queryKey: favoriteKeys.all });
    },
  });
};

// Hook for batch favorite operations
export const useBatchFavorites = () => {
  const queryClient = useQueryClient();

  return useMutation<BatchFavoriteResponse, Error, BatchFavoriteRequest>({
    mutationFn: favoritesApi.batchToggleFavorites,
    onSuccess: (data) => {
      logger.debug('Batch favorite operation completed', {
        component: 'useBatchFavorites',
        successful: data.successful.length,
        failed: data.failed.length,
      } as any);
      queryClient.invalidateQueries({ queryKey: favoriteKeys.all });
    },
  });
};