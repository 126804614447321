/**
 * @file src/components/ghost/common/background/effects/useQuantumEffect.ts
 * @description Quantum entanglement visualization effect
 * @version 1.0.0
 */

import { getAccentColor, getSecondaryColor } from '../utils/colors';
import { CanvasDimensions, getResponsiveValue } from '../utils/canvasHelpers';
import { logger } from '@/utils/logger';

interface QuantumParticle {
  x: number;
  y: number;
  partnerX: number;
  partnerY: number;
  angle: number;
  speed: number;
  radius: number;
  opacity: number;
}

export const useQuantumEffect = (
  ctx: CanvasRenderingContext2D,
  dimensions: CanvasDimensions
) => {
  const particleCount = getResponsiveValue(3, 5);
  
  const particles: QuantumParticle[] = Array.from({ length: particleCount }, () => ({
    x: Math.random() * dimensions.width,
    y: Math.random() * dimensions.height,
    partnerX: 0,
    partnerY: 0,
    angle: Math.random() * Math.PI * 2,
    speed: Math.random() * 0.5 + 0.2,
    radius: Math.random() * 2 + 1,
    opacity: Math.random() * 0.3 + 0.1
  }));

  logger.debug('Quantum effect initialized', { 
    component: 'useQuantumEffect'
  });
  const render = () => {
    const time = Date.now() * 0.001;

    particles.forEach((particle: any) => {
      // Update quantum pair positions
      particle.angle += particle.speed * 0.02;
      const distance = Math.sin(time * 0.5) * 50 + 50;

      particle.partnerX = particle.x + Math.cos(particle.angle) * distance;
      particle.partnerY = particle.y + Math.sin(particle.angle) * distance;

      // Draw quantum connection
      ctx.beginPath();
      ctx.moveTo(particle.x, particle.y);
      ctx.lineTo(particle.partnerX, particle.partnerY);
      ctx.setLineDash([2, 4]);
      ctx.strokeStyle = getAccentColor(particle.opacity * 0.5);
      ctx.stroke();
      ctx.setLineDash([]);

      // Draw particles
      [
        [particle.x, particle.y, getAccentColor] as [number, number, (opacity: number) => string],
        [particle.partnerX, particle.partnerY, getSecondaryColor] as [number, number, (opacity: number) => string]
      ].forEach(([x, y, colorFn]) => {
        const radius = particle.radius + Math.sin(time * 2) * 0.5;

        // Glow effect
        const gradient = ctx.createRadialGradient(
          x, y, 0,
          x, y, radius * 4
        );

        gradient.addColorStop(0, colorFn(particle.opacity));
        gradient.addColorStop(1, colorFn(0));

        ctx.beginPath();
        ctx.arc(x, y, radius * 4, 0, Math.PI * 2);
        ctx.fillStyle = gradient;
        ctx.fill();

        // Core
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, Math.PI * 2);
        ctx.fillStyle = colorFn(particle.opacity * 1.5);
        ctx.fill();
      });
    });
  };

  return {
    render,
    cleanup: () => {
      logger.debug('Quantum effect cleaned up');
    }
  };
};