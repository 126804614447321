/**
 * @file src/hooks/cloud-drive/useFilePreview.ts
 * @description Enhanced hook for managing file preview state with full screen viewer
 * @version 1.1.0
 * @created 2025-03-05
 * @updated 2025-03-05
 */

import {useState, useCallback, useRef} from 'react';
import { logger } from '@/utils/logger';
import type { DriveFile } from '@/types/cloud-drive.types';

export interface PreviewState {
  isOpen: boolean;
  currentFile: DriveFile | null;
  currentIndex: number;
  files: DriveFile[];
}

// Initial state
const initialPreviewState: PreviewState = {
  isOpen: false,
  currentFile: null,
  currentIndex: -1,
  files: []
};

export function useFilePreview() {
  // Preview state
  const [previewState, setPreviewState] = useState<PreviewState>(initialPreviewState);
  
  // Function to show preview - with debouncing and protection against repeated invocation
  const previewFileCallCount = useRef(0);
  const lastFileId = useRef<string | null>(null);
  const COOLDOWN_PERIOD = 1000; // 1 second cooldown between previews of the same file
  
  const previewFile = useCallback((file: DriveFile, files: DriveFile[] = []) => {
    if (!file) {
      logger.warn('Attempted to preview undefined file', {
        component: 'useFilePreview'
      });
      return;
    }
    
    // Prevent the same file from being previewed multiple times in quick succession
    if (file.id === lastFileId.current) {
      const now = Date.now();
      previewFileCallCount.current += 1;
      
      if (previewFileCallCount.current > 2) {
        logger.warn('Blocking rapid repeated preview of the same file', {
          component: 'useFilePreview',
          data: {
            fileId: file.id,
            callCount: previewFileCallCount.current
          }
        });
        return;
      }
      
      // Reset the counter after cooldown period
      setTimeout(() => {
        if (lastFileId.current === file.id) {
          previewFileCallCount.current = 0;
        }
      }, COOLDOWN_PERIOD);
    } else {
      // Different file, reset tracking
      lastFileId.current = file.id;
      previewFileCallCount.current = 1;
    }
    
    logger.debug('Opening file preview', {
      component: 'useFilePreview',
      data: {
        fileId: file.id,
        fileName: file.name,
        totalFiles: files.length || 1,
        callCount: previewFileCallCount.current
      }
    });
    
    // Add a timestamp and source to help with debugging
    const enhancedFile = {
      ...file,
      previewTimestamp: Date.now(),
      source: 'preview_call' // Help identify where this file came from
    };
    
    // Use provided files list or create single-item list
    const previewFiles = files.length > 0 ? files : [enhancedFile];
    const fileIndex = files.length > 0 ? files.findIndex(f => f.id === file.id) : 0;
    
    // Only set state if we aren't already showing this file
    if (previewState.currentFile?.id !== file.id || !previewState.isOpen) {
      setPreviewState({
        isOpen: true,
        currentFile: enhancedFile,
        currentIndex: fileIndex >= 0 ? fileIndex : 0,
        files: previewFiles
      });
    }
  }, [previewState.currentFile, previewState.isOpen]);
  
  // Close preview - enhanced to avoid infinite loops
  const closePreview = useCallback(() => {
    logger.debug('Closing file preview', {
      component: 'useFilePreview',
      data: {
        hasFile: !!previewState.currentFile,
        fileId: previewState.currentFile?.id
      }
    });
    
    // Close immediately but clear all data to avoid future infinite loops
    setPreviewState(initialPreviewState);
    
    // Also clear any cached query data for this file
    if (previewState.currentFile?.id && window.queryClient) {
      try {
        window.queryClient.removeQueries({ 
          queryKey: ['item-details', previewState.currentFile.id]
        });
        
        window.queryClient.removeQueries({
          queryKey: ['file-preview', previewState.currentFile.id]
        });
        
        logger.debug('Cleared related queries on preview close', {
          component: 'useFilePreview',
          data: { fileId: previewState.currentFile.id }
        });
      } catch (error) {
        logger.warn('Error clearing queries', {
          component: 'useFilePreview',
          error
        });
      }
    }
  }, [previewState.currentFile]);
  
  // Navigate to next preview file
  const nextPreview = useCallback(() => {
    setPreviewState(prev => {
      if (!prev.isOpen || prev.files?.length <= 1) return prev;
      
      const nextIndex = (prev.currentIndex + 1) % prev.files.length;
      const nextFile = prev.files[nextIndex];
      
      logger.debug('Navigating to next preview file', {
        component: 'useFilePreview',
        data: {
          currentIndex: prev.currentIndex,
          nextIndex,
          nextFileId: nextFile.id
        }
      });
      
      return {
        ...prev,
        currentIndex: nextIndex,
        currentFile: nextFile
      };
    });
  }, []);
  
  // Navigate to previous preview file
  const previousPreview = useCallback(() => {
    setPreviewState(prev => {
      if (!prev.isOpen || prev.files?.length <= 1) return prev;
      
      const prevIndex = (prev.currentIndex - 1 + prev.files.length) % prev.files.length;
      const prevFile = prev.files[prevIndex];
      
      logger.debug('Navigating to previous preview file', {
        component: 'useFilePreview',
        data: {
          currentIndex: prev.currentIndex,
          prevIndex,
          prevFileId: prevFile.id
        }
      });
      
      return {
        ...prev,
        currentIndex: prevIndex,
        currentFile: prevFile
      };
    });
  }, []);
  
  // Custom onOpenChange handler with improved logging and state management
  const handleOpenChange = useCallback((open: boolean) => {
    logger.debug('Sheet open state change', {
      component: 'useFilePreview',
      data: {
        open,
        currentState: previewState.isOpen,
        fileId: previewState.currentFile?.id
      }
    });
    
    if (!open) {
      // When closing, reset everything to avoid infinite loop
      closePreview();
      
      // Prevent rapid reopen
      const timeoutId = setTimeout(() => {
        logger.debug('Preview fully closed', {
          component: 'useFilePreview'
        });
      }, 300);
      
      // Return cleanup function
      return () => clearTimeout(timeoutId);
    } else {
      setPreviewState(prev => ({ ...prev, isOpen: true }));
    }
  }, [closePreview, previewState.isOpen, previewState.currentFile]);
  
  return {
    previewState,
    previewFile,
    closePreview,
    nextPreview,
    previousPreview,
    handleOpenChange
  };
}

export default useFilePreview;