/**
 * @file src/pages/cloud-drive/file/index.tsx
 * @description File detail page with integrated components
 * @version 1.0.0
 * @created 2024-12-22
 */

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CloudDriveErrorBoundary } from '@/components/cloud-drive/error/ErrorBoundary';
import { FilePreviewSheet } from '@/components/cloud-drive/preview/FilePreviewSheet';

const FileDetailPage: React.FC = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const navigate = useNavigate();

  if (!fileId) {
    return null;
  }

  return (
    <CloudDriveErrorBoundary>
      <FilePreviewSheet
        fileId={fileId}
        isOpen={true}
        onClose={() => navigate(-1)}
      />
    </CloudDriveErrorBoundary>
  );
};

export default FileDetailPage;