/**
 * @file src/components/cloud-drive/browser/SharedItems.tsx
 * @description Enhanced shared items view with real data loading, error handling, and animations
 * @version 2.1.0
 */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Share2, 
  Clock, 
  File, 
  Folder,
  Link,
  Copy,
  Check,
  ExternalLink,
  AlertCircle,
  Lock,
  Loader2,
  X
} from 'lucide-react';
import { cn } from '@/utils/utils';
import { format, formatDistanceToNow } from 'date-fns';
import { useCloudDrive } from '@/context/cloud-drive';
import { sharingService } from '@/services/sharing.service';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { useToast } from '@/components/ui/toast';
import { 
  Tabs, 
  TabsContent, 
  TabsList, 
  TabsTrigger 
} from '@/components/ui/tabs';
import { logger } from '@/utils/logger';

interface SharedItemsProps {
  folderId: string | null;
  className?: string;
}

export const SharedItems: React.FC<SharedItemsProps> = ({
  folderId,
  className
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { encryptedDrive } = useCloudDrive();
  
  const [myShares, setMyShares] = useState<any[]>([]);
  const [sharedWithMe, setSharedWithMe] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>('by-me');
  
  // Fetch shares on component mount and when encrypted mode changes
  useEffect(() => {
    fetchShares();
  }, [encryptedDrive.isEncryptedMode]);
  
  // Function to fetch shares with proper error handling
// In SharedItems.tsx, update the fetchShares function:

/**
 * @file src/components/cloud-drive/browser/SharedItems.tsx
 * @description Enhanced shared items view with proper error handling
 * @version 2.1.1
 */

// ... other imports remain the same

// Update the fetchShares function:
const fetchShares = async () => {
  try {
    setIsLoading(true);
    setError(null);
    
    logger.debug('Fetching shares', {
      component: 'SharedItems',
      isEncryptedMode: encryptedDrive.isEncryptedMode
    });
    
    // Use real sharing service
    const response = await sharingService.listShares();
    
    // Safely handle the response
    if (response && Array.isArray(response.items)) {
      logger.debug('Shares fetched successfully', {
        component: 'SharedItems',
        shareCount: response.items.length
      });
      
      // Filter shares based on owner field - with null checks
      const byMe = response.items.filter((share: any) => share && share.is_owner === true);
      const withMe = response.items.filter((share: any) => share && share.is_owner === false);
      
      setMyShares(byMe);
      setSharedWithMe(withMe);
    } else {
      // Handle unexpected response format
      logger.error('Invalid response format from sharing service', {
        component: 'SharedItems',
        responseType: typeof response,
        hasItems: response && 'items' in response,
        itemsType: response && 'items' in response ? typeof response.items : 'N/A'
      });
      
      setError(t('cloud_drive.shared.invalid_response'));
      setMyShares([]);
      setSharedWithMe([]);
    }
  } catch (err) {
    logger.error('Error fetching shares:', {
      component: 'SharedItems',
      error: err
    });
    
    setError(t('cloud_drive.shared.fetch_error'));
    showToast(t('cloud_drive.shared.fetch_error'), 'error');
    setMyShares([]);
    setSharedWithMe([]);
  } finally {
    setIsLoading(false);
  }
};
  
  // Copy share URL to clipboard
  const copyShareUrl = async (shareId: string, shareUrl: string) => {
    try {
      // Check if shareUrl is valid, if not generate it from share_key
      let urlToCopy = shareUrl;
      if (!urlToCopy || urlToCopy === 'Link') {
        // Find the share by ID
        const share = [...myShares, ...sharedWithMe].find(s => s.id === shareId);
        if (share && share.share_key) {
          urlToCopy = `${window.location.origin}/s/${share.share_key}`;
          // Add encryption key in hash if encrypted
          if (share.is_encrypted && share.encryption_key) {
            urlToCopy += `#${encodeURIComponent(share.encryption_key)}`;
          }
        } else {
          throw new Error('No valid share key found');
        }
      }
      
      logger.debug('Copying share URL to clipboard', {
        component: 'SharedItems',
        shareId,
        urlToCopy
      });
      
      await navigator.clipboard.writeText(urlToCopy);
      setCopiedId(shareId);
      
      setTimeout(() => setCopiedId(null), 2000);
    } catch (error) {
      logger.error('Error copying to clipboard:', {
        component: 'SharedItems',
        error
      });
      
      showToast(t('cloud_drive.shared.copy_error'), 'error');
    }
  };
  
  // Handle opening shared link
  const openShareLink = (shareUrl: string) => {
    window.open(shareUrl, '_blank');
    
    logger.debug('Opening share URL in new tab', {
      component: 'SharedItems',
      url: shareUrl
    });
  };
  
  // Handle share deletion
  const deleteShare = async (shareId: string) => {
    try {
      setDeletingId(shareId);
      
      logger.debug('Deleting share', {
        component: 'SharedItems',
        shareId
      });
      
      await sharingService.deleteShare(shareId);
      
      // Update local state to remove the deleted share
      setMyShares(prev => prev.filter((share: any) => share.id !== shareId));
      
      showToast(t('cloud_drive.shared.delete_success'), 'success');
      
      logger.debug('Share deleted successfully', {
        component: 'SharedItems',
        shareId
      });
    } catch (error) {
      logger.error('Error deleting share:', {
        component: 'SharedItems',
        shareId,
        error
      });
      
      showToast(t('cloud_drive.shared.delete_error'), 'error');
    } finally {
      setDeletingId(null);
    }
  };
  
  // Format share information for display
  const formatShareDetails = (share: any) => {
    // Calculate expiration remaining time
    let expirationDisplay = null;
    
    if (share.expires_at) {
      const expiresAt = new Date(share.expires_at);
      const now = new Date();
      
      if (expiresAt < now) {
        expirationDisplay = (
          <span className="text-red-500 dark:text-red-400">
            {t('cloud_drive.shared.expired')}
          </span>
        );
      } else {
        expirationDisplay = (
          <span>
            {t('cloud_drive.shared.expires')} {formatDistanceToNow(expiresAt, { addSuffix: true })}
          </span>
        );
      }
    }
    
    // Extract file extension if it exists
    let fileExtension = '';
    if (share.item_name) {
      const match = share.item_name.match(/\.([a-zA-Z0-9]+)$/);
      if (match) {
        fileExtension = match[0];
      }
    }
    
    // If the item is encrypted, find a descriptive type based on extension
    let itemType = '';
    if (share.is_encrypted && fileExtension) {
      const imageExts = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.svg', '.bmp'];
      const videoExts = ['.mp4', '.mov', '.avi', '.mkv', '.webm'];
      const docExts = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt'];
      
      if (imageExts.some(ext => fileExtension.toLowerCase() === ext)) {
        itemType = t('cloud_drive.shared.encrypted_image');
      } else if (videoExts.some(ext => fileExtension.toLowerCase() === ext)) {
        itemType = t('cloud_drive.shared.encrypted_video');
      } else if (docExts.some(ext => fileExtension.toLowerCase() === ext)) {
        itemType = t('cloud_drive.shared.encrypted_document');
      }
    }
    
    return {
      expirationDisplay,
      isExpired: share.expires_at ? new Date(share.expires_at) < new Date() : false,
      permissionDisplay: t(`cloud_drive.shared.permission_${share.permission}`),
      accessDisplay: share.max_access_count 
        ? `${share.current_access_count}/${share.max_access_count}` 
        : share.current_access_count.toString(),
      fileExtension,
      itemType: itemType || t('cloud_drive.shared.encrypted_file')
    };
  };
  
  // Loading state
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-96 p-8">
        <motion.div 
          className="p-4 rounded-full bg-gray-100 dark:bg-gray-800 mb-4"
          animate={{ rotate: 360 }}
          transition={{ 
            duration: 2,
            ease: "linear",
            repeat: Infinity
          }}
        >
          <Loader2 className="h-8 w-8 text-gray-400 dark:text-gray-500" />
        </motion.div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
          {t('cloud_drive.shared.loading')}
        </h3>
      </div>
    );
  }
  
  // Error state
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-96 p-8">
        <div className="p-4 rounded-full bg-red-100 dark:bg-red-900/20 mb-4">
          <AlertCircle className="h-8 w-8 text-red-500 dark:text-red-400" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
          {t('cloud_drive.shared.error')}
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md text-center mb-4">
          {error}
        </p>
        <Button 
          onClick={fetchShares}
          className="flex items-center gap-2"
        >
          <span className="mr-1">{t('cloud_drive.shared.retry')}</span>
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ 
              duration: 2, 
              ease: "linear", 
              repeat: Infinity,
              repeatType: "loop" 
            }}
          >
            <Loader2 className="h-4 w-4" />
          </motion.div>
        </Button>
      </div>
    );
  }
  
  // Empty state (no shares at all)
  if (myShares.length === 0 && sharedWithMe.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-96 p-8">
        <div className="p-4 rounded-full bg-gray-100 dark:bg-gray-800 mb-4">
          <Share2 className="h-8 w-8 text-gray-400 dark:text-gray-500" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
          {t('cloud_drive.shared.empty')}
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md text-center">
          {t('cloud_drive.shared.empty_description')}
        </p>
      </div>
    );
  }
  
  return (
    <div className={cn("p-4", className)}>
      <Tabs defaultValue="by-me" className="w-full" onValueChange={setActiveTab}>
        <TabsList className="mb-4">
          <TabsTrigger value="by-me" className="flex items-center gap-2">
            <Share2 className="h-4 w-4" />
            {t('cloud_drive.shared.by_me')}
            <Badge variant="secondary" className="ml-1">{myShares.length}</Badge>
          </TabsTrigger>
          <TabsTrigger value="with-me" className="flex items-center gap-2">
            <File className="h-4 w-4" />
            {t('cloud_drive.shared.with_me')}
            <Badge variant="secondary" className="ml-1">{sharedWithMe.length}</Badge>
          </TabsTrigger>
        </TabsList>
        
        <TabsContent value="by-me" className="min-h-[200px]">
          {myShares.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-64 p-4">
              <div className="p-4 rounded-full bg-gray-100 dark:bg-gray-800 mb-4">
                <Share2 className="h-6 w-6 text-gray-400 dark:text-gray-500" />
              </div>
              <p className="text-gray-500 dark:text-gray-400 text-center">
                {t('cloud_drive.shared.by_me_empty')}
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AnimatePresence mode="popLayout">
                {myShares.map((share, index) => {
                  const { 
                    expirationDisplay, 
                    isExpired, 
                    permissionDisplay, 
                    accessDisplay 
                  } = formatShareDetails(share);
                  
                  return (
                    <motion.div
                      key={share.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2, delay: index * 0.05 }}
                      layout
                    >
                      <Card className={cn(
                        "overflow-hidden",
                        isExpired && "opacity-70"
                      )}>
                        <div className="flex p-4 border-b dark:border-gray-800">
                          <div className={cn(
                            "w-10 h-10 rounded-lg flex items-center justify-center mr-3",
                            "bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400"
                          )}>
                            {share.is_encrypted ? (
                              <Lock className="h-5 w-5" />
                            ) : share.item_type === 'folder' ? (
                              <Folder className="h-5 w-5" />
                            ) : (
                              <File className="h-5 w-5" />
                            )}
                          </div>
                          <div className="flex-1 min-w-0">
                            <h3 className="font-medium truncate">
                              {share.item_name && share.item_name.startsWith('encrypted:') 
                                ? (share.is_encrypted 
                                  ? (share.original_name || formatShareDetails(share).itemType)  // Use item type based on extension
                                  : share.item_name.substring(10)) // Remove "encrypted:" prefix if not encrypted
                                : share.item_name}
                            </h3>
                            <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
                              <Clock className="h-3 w-3 mr-1" />
                              {formatDistanceToNow(new Date(share.created_at), { addSuffix: true })}
                              
                              {expirationDisplay && (
                                <span className="ml-2">• {expirationDisplay}</span>
                              )}
                              
                              {share.is_encrypted && (
                                <span className="ml-2 flex items-center">
                                  • <Lock className="h-3 w-3 mx-1 text-blue-500" />
                                  {t('cloud_drive.shared.encrypted')}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        
                        <div className="p-4 flex flex-col gap-2">
                          <div className="flex items-center justify-between text-sm">
                            <div className="flex items-center">
                              <Link className="h-4 w-4 mr-1.5 text-gray-500" />
                              <span className="font-medium truncate max-w-[200px] overflow-hidden inline-block">
                                {share.share_url ? share.share_url : `${window.location.origin}/s/${share.share_key}`}
                              </span>
                            </div>
                            <div className="flex items-center gap-2">
                              <Button
                                variant="ghost"
                                size="sm"
                                className="h-8 px-2"
                                onClick={() => copyShareUrl(share.id, share.share_url)}
                                disabled={isExpired}
                              >
                                <AnimatePresence mode="wait">
                                  {copiedId === share.id ? (
                                    <motion.div
                                      key="check"
                                      initial={{ scale: 0.8, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      exit={{ scale: 0.8, opacity: 0 }}
                                      transition={{ duration: 0.15 }}
                                    >
                                      <Check className="h-4 w-4 text-green-500" />
                                    </motion.div>
                                  ) : (
                                    <motion.div
                                      key="copy"
                                      initial={{ scale: 0.8, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      exit={{ scale: 0.8, opacity: 0 }}
                                      transition={{ duration: 0.15 }}
                                    >
                                      <Copy className="h-4 w-4" />
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </Button>
                              <Button
                                variant="ghost"
                                size="sm"
                                className="h-8 px-2"
                                onClick={() => openShareLink(share.share_url)}
                                disabled={isExpired}
                              >
                                <ExternalLink className="h-4 w-4" />
                              </Button>
                            </div>
                          </div>
                          
                          <div className="grid grid-cols-2 gap-2 mt-1">
                            <div className="text-xs">
                              <span className="text-gray-500 dark:text-gray-400">
                                {t('cloud_drive.shared.permission')}:
                              </span>
                              <span className="ml-1 font-medium">
                                {permissionDisplay}
                              </span>
                            </div>
                            
                            <div className="text-xs">
                              <span className="text-gray-500 dark:text-gray-400">
                                {t('cloud_drive.shared.access_count')}:
                              </span>
                              <span className="ml-1 font-medium">
                                {accessDisplay}
                              </span>
                            </div>
                          </div>
                          
                          <Button
                            variant="ghost"
                            size="sm"
                            className={cn(
                              "mt-2 w-full border border-gray-200 dark:border-gray-800",
                              "text-red-500 dark:text-red-400 hover:text-red-600 dark:hover:text-red-300",
                              "hover:bg-red-50 dark:hover:bg-red-900/20"
                            )}
                            onClick={() => deleteShare(share.id)}
                            disabled={deletingId === share.id}
                          >
                            {deletingId === share.id ? (
                              <>
                                <motion.div
                                  animate={{ rotate: 360 }}
                                  transition={{ 
                                    duration: 1,
                                    ease: "linear",
                                    repeat: Infinity,
                                  }}
                                  className="mr-2"
                                >
                                  <Loader2 className="h-4 w-4" />
                                </motion.div>
                                {t('cloud_drive.shared.deleting')}
                              </>
                            ) : (
                              <>
                                <X className="h-4 w-4 mr-2" />
                                {t('cloud_drive.shared.delete')}
                              </>
                            )}
                          </Button>
                        </div>
                      </Card>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
          )}
        </TabsContent>
        
        <TabsContent value="with-me" className="min-h-[200px]">
          {sharedWithMe.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-64 p-4">
              <div className="p-4 rounded-full bg-gray-100 dark:bg-gray-800 mb-4">
                <File className="h-6 w-6 text-gray-400 dark:text-gray-500" />
              </div>
              <p className="text-gray-500 dark:text-gray-400 text-center">
                {t('cloud_drive.shared.with_me_empty')}
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AnimatePresence mode="popLayout">
                {sharedWithMe.map((share, index) => {
                  const { 
                    expirationDisplay, 
                    isExpired, 
                    permissionDisplay 
                  } = formatShareDetails(share);
                  
                  return (
                    <motion.div
                      key={share.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2, delay: index * 0.05 }}
                    >
                      <Card className={cn(
                        "overflow-hidden",
                        isExpired && "opacity-70"
                      )}>
                        <div className="flex p-4 border-b dark:border-gray-800">
                          <div className={cn(
                            "w-10 h-10 rounded-lg flex items-center justify-center mr-3",
                            "bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400"
                          )}>
                            {share.is_encrypted ? (
                              <Lock className="h-5 w-5" />
                            ) : share.item_type === 'folder' ? (
                              <Folder className="h-5 w-5" />
                            ) : (
                              <File className="h-5 w-5" />
                            )}
                          </div>
                          <div className="flex-1 min-w-0">
                            <h3 className="font-medium truncate">{share.item_name}</h3>
                            <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
                              <span className="mr-1">{t('cloud_drive.shared.shared_by')}</span>
                              <span className="font-medium">{share.created_by_name || 'User'}</span>
                              
                              {expirationDisplay && (
                                <span className="ml-2">• {expirationDisplay}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        
                        <div className="p-4">
                          <div className="flex items-center justify-between text-sm mb-4">
                            <div className="text-xs">
                              <span className="text-gray-500 dark:text-gray-400">
                                {t('cloud_drive.shared.permission')}:
                              </span>
                              <span className="ml-1 font-medium">
                                {permissionDisplay}
                              </span>
                            </div>
                            
                            {share.is_encrypted && (
                              <Badge variant="outline" className="bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300 border-blue-200 dark:border-blue-800">
                                <Lock className="h-3 w-3 mr-1" />
                                {t('cloud_drive.shared.encrypted')}
                              </Badge>
                            )}
                          </div>
                          
                          <Button
                            className="w-full"
                            disabled={isExpired}
                            onClick={() => openShareLink(share.share_url)}
                          >
                            <ExternalLink className="h-4 w-4 mr-2" />
                            {t('cloud_drive.shared.access_shared')}
                          </Button>
                        </div>
                      </Card>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SharedItems;