/**
 * @file src/components/services/ServicesListView.tsx
 * @description List view for connected cloud services
 * @version 1.1.0
 * @updated 2025-02-28
 * @dependencies framer-motion, lucide-react, react-i18next
 */

import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { Cloud, HardDrive, AlertCircle, Folder, Database } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useCloudServices } from '@/hooks/useCloudServices';

// Import components
import { ServiceSearchBar } from './ServiceSearchBar';
import { ConnectServiceButton } from './ConnectServiceButton';
import { ServiceCard } from './ServiceCard';
import { ServicesAvailableView } from './ServicesAvailableView';

// Dialog imports
import { ServiceAuthDialog } from './ServiceAuthDialog';
import { ServiceDetails } from './ServiceDetails';

interface ServicesListViewProps {
  className?: string;
}

export const ServicesListView: React.FC<ServicesListViewProps> = ({ 
  className 
}) => {
  const { t } = useTranslation();
  const { 
    services, 
    isLoading, 
    error, 
    connectService, 
    disconnectService,
    connectingService
  } = useCloudServices();
  
  // Local state
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>(null);
  const [authDialogService, setAuthDialogService] = useState<any | null>(null);
  const [showAvailableServices, setShowAvailableServices] = useState(false);

  // Get service icon based on type
  const getServiceIcon = (type?: string) => {
    switch(type?.toLowerCase()) {
      case 'google_drive':
      case 'onedrive':
      case 'dropbox':
        return HardDrive;
      case 'box':
        return Folder;
      case 'aws_s3':
        return Database;
      default:
        return Cloud;
    }
  };

  // Filter services based on search query
  const filteredServices = useMemo(() => {
    if (!services) return [];

    if (!searchQuery.trim()) {
      return services;
    }

    const query = searchQuery.toLowerCase();
    return services.filter((service: any) => 
      service.name.toLowerCase().includes(query) ||
      service.type.toLowerCase().includes(query) ||
      service.description?.toLowerCase().includes(query)
    );
  }, [services, searchQuery]);

  // Get connected services
  const connectedServices = useMemo(() => {
    return filteredServices.filter((service: any) => service.status === 'connected');
  }, [filteredServices]);

  // Handle search
  const handleSearch = (query: string) => {
    logger.debug('Service search', {
      component: 'ServicesListView',
      query,
      action: 'search'
    });
    setSearchQuery(query);
  };

  // Handle connect service click
  const handleConnectService = () => {
    logger.debug('Showing available services view', {
      component: 'ServicesListView',
      action: 'showAvailableServices'
    });
    
    setShowAvailableServices(true);
  };

  // Handle service card click
  const handleServiceClick = (service: any) => {
    logger.debug('Service clicked', {
      component: 'ServicesListView',
      serviceId: service.id,
      serviceName: service.name,
      action: 'selectService'
    });
    
    if (service?.status === 'connected') {
      setSelectedServiceId(service.id);
    } else {
      setAuthDialogService(service);
    }
  };

  // If showing available services view
  if (showAvailableServices) {
    return (
      <ServicesAvailableView
        onBack={() => setShowAvailableServices(false)}
        className={className}
      />
    );
  }

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <div className="animate-spin h-10 w-10 border-2 border-blue-500 border-t-transparent rounded-full mb-4"></div>
          <p className="text-gray-500 dark:text-gray-400">
            {t('services.loading')}
          </p>
        </div>
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-6">
        <div className="rounded-full bg-red-100 dark:bg-red-900/20 p-3 mb-4">
          <AlertCircle className="h-6 w-6 text-red-600 dark:text-red-400" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
          {t('services.error_title')}
        </h3>
        <p className="text-gray-500 dark:text-gray-400 text-center mb-4">
          {t('services.error_description')}
        </p>
        <Button onClick={() => window.location.reload()}>
          {t('common.try_again')}
        </Button>
      </div>
    );
  }

  return (
    <div className={cn("flex flex-col h-full", className)}>
      {/* Header with search and connect button */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-6 gap-4">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
          {t('services.title')}
        </h2>
        
        <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-3">
          <ServiceSearchBar onSearch={handleSearch} initialQuery={searchQuery} />
          <ConnectServiceButton onClick={handleConnectService} />
        </div>
      </div>

      {/* Show empty state if no services connected */}
      {(!connectedServices || connectedServices.length === 0) && (
        <div className="flex-1 flex flex-col items-center justify-center p-6">
          <div className="rounded-full bg-blue-100 dark:bg-blue-900/20 p-3 mb-4">
            <Cloud className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
            {searchQuery
              ? t('services.no_matching_services_title')
              : t('services.no_connected_services_title')}
          </h3>
          
          <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
            {searchQuery
              ? t('services.no_matching_services_description', { query: searchQuery })
              : t('services.no_connected_services_description')}
          </p>
          
          {!searchQuery && (
            <ConnectServiceButton 
              variant="large"
              onClick={handleConnectService} 
              className="mt-6"
            />
          )}
          
        </div>
      )}

      {/* Services grid */}
      {connectedServices.length > 0 && (
        <div className="p-6 pt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 overflow-auto">
          {connectedServices.map((service) => (
            <ServiceCard
              key={service.id}
              service={service}
              onConnect={() => handleServiceClick(service)}
              onDisconnect={() => disconnectService(service.id)}
              isConnecting={connectingService === service.type}
            />
          ))}
        </div>
      )}

      {/* Service details panel */}
      <AnimatePresence>
        {selectedServiceId && (
          <motion.div
            initial={{ opacity: 0, x: 300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 300 }}
            className="fixed inset-y-0 right-0 w-96 bg-white dark:bg-gray-900 border-l border-gray-200 dark:border-gray-800 z-20"
          >
            <ServiceDetails
              serviceId={selectedServiceId}
              onClose={() => setSelectedServiceId(null)}
            />
          </motion.div>
        )}
      </AnimatePresence>
      
      {/* Auth dialog */}
      {authDialogService && (
        <ServiceAuthDialog
          service={authDialogService}
          isOpen={!!authDialogService}
          onClose={() => setAuthDialogService(null)}
          onSuccess={() => {
            setAuthDialogService(null);
            // Refresh services list if needed
          }}
        />
      )}
    </div>
  );
};

export default ServicesListView;