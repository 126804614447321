/**
 * @file src/providers/ReportsProvider.tsx
 * @description Reports context provider with filtering and state management
 * @version 1.0.0
 * @created 2024-01-27
 * @dependencies react, date-fns
 */

import React, { createContext, useContext, useReducer, useCallback } from 'react';
import {subDays} from 'date-fns';
import { logger } from '@/utils/logger';
import type {
  Report,
  ReportFilter,
  ReportSettings,
  ReportContextState,
  DateRange
} from '@/types/reports.types';

// Action Types
type ReportAction =
  | { type: 'SET_FILTERS'; payload: ReportFilter }
  | { type: 'SET_DATE_RANGE'; payload: DateRange }
  | { type: 'SET_SELECTED_REPORT'; payload?: Report }
  | { type: 'UPDATE_SETTINGS'; payload: Partial<ReportSettings> }
  | { type: 'RESET_FILTERS' };

// Context Interface
interface ReportContextValue extends ReportContextState {
  setFilters: (filters: ReportFilter) => void;
  setDateRange: (dateRange: DateRange) => void;
  selectReport: (report?: Report) => void;
  updateSettings: (settings: Partial<ReportSettings>) => void;
  resetFilters: () => void;
}

// Default Settings
const defaultSettings: ReportSettings = {
  autoRefresh: false,
  refreshInterval: 5 * 60 * 1000, // 5 minutes
  defaultDateRange: {
    start: subDays(new Date(), 7),
    end: new Date()
  }
};

// Initial State
const initialState: ReportContextState = {
  filters: {},
  settings: defaultSettings,
  selectedReport: undefined
};

// Create Context
const ReportContext = createContext<ReportContextValue | undefined>(undefined);

// Reducer
function reportReducer(state: ReportContextState, action: ReportAction): ReportContextState {
  logger.debug('Report action', { data:{action: action.type} });

  switch (action.type) {
    case 'SET_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };

    case 'SET_DATE_RANGE':
      return {
        ...state,
        filters: {
          ...state.filters,
          dateRange: action.payload
        }
      };

    case 'SET_SELECTED_REPORT':
      return {
        ...state,
        selectedReport: action.payload
      };

    case 'UPDATE_SETTINGS':
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload
        }
      };

    case 'RESET_FILTERS':
      return {
        ...state,
        filters: {
          dateRange: state.settings.defaultDateRange
        }
      };

    default:
      return state;
  }
}

// Provider Component
export function ReportsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reportReducer, initialState);

  // Actions
  const setFilters = useCallback((filters: ReportFilter) => {
    dispatch({ type: 'SET_FILTERS', payload: filters });
  }, []);

  const setDateRange = useCallback((dateRange: DateRange) => {
    dispatch({ type: 'SET_DATE_RANGE', payload: dateRange });
  }, []);

  const selectReport = useCallback((report?: Report) => {
    dispatch({ type: 'SET_SELECTED_REPORT', payload: report });
  }, []);

  const updateSettings = useCallback((settings: Partial<ReportSettings>) => {
    dispatch({ type: 'UPDATE_SETTINGS', payload: settings });
  }, []);

  const resetFilters = useCallback(() => {
    dispatch({ type: 'RESET_FILTERS' });
  }, []);

  // Context value
  const value = {
    ...state,
    setFilters,
    setDateRange,
    selectReport,
    updateSettings,
    resetFilters
  };

  return (
    <ReportContext.Provider value={value}>
      {children}
    </ReportContext.Provider>
  );
}

// Hook
export function useReports() {
  const context = useContext(ReportContext);
  if (context === undefined) {
    throw new Error('useReports must be used within a ReportsProvider');
  }
  return context;
}

// Export Provider
export default ReportsProvider;