/**
 * @file src/pages/reports/index.tsx
 * @description Main reports page with filters and report categories
 * @version 1.1.0
 * @updated 2024-01-29
 */

import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { logger } from '@/utils/logger';
import { useReports } from '@/providers/ReportsProvider';
import { ReportGrid } from '@/components/reports/ReportGrid';
import type { Report, ReportType, ReportCategory } from '@/types/reports.types';

const ReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    selectReport
  } = useReports();

  // Check if we're on the main reports page or a sub-route
  const isMainPage = location.pathname === '/reports';

  // Sample reports data - will be replaced with API call
  const reports = {
    advanced: [
      {
        id: "smart-reports",
        type: "smart" as ReportType,
        category: "advanced" as ReportCategory,
        title: t("reports.types.smart.title"),
        description: t("reports.types.smart.description"),
        path: "/reports/smart",
      },
      {
        id: "billing",
        type: "billing" as ReportType,
        category: "advanced" as ReportCategory,
        title: t("reports.types.billing.title"),
        description: t("reports.types.billing.description"),
        path: "/reports/billing",
      },
      {
        id: "alerts",
        type: "alerts" as ReportType,
        category: "advanced" as ReportCategory,
        title: t("reports.types.alerts.title"),
        description: t("reports.types.alerts.description"),
        path: "/reports/alerts",
      },
    ],
    others: [
      {
        id: "bandwidth",
        type: "bandwidth" as ReportType,
        category: "others" as ReportCategory,
        title: t("reports.types.bandwidth.title"),
        description: t("reports.types.bandwidth.description"),
        path: "/reports/bandwidth",
      },
      {
        id: "deviceBuilds",
        type: "deviceBuilds" as ReportType,
        category: "others" as ReportCategory,
        title: t("reports.types.deviceBuilds.title"),
        description: t("reports.types.deviceBuilds.description"),
        path: "/reports/device-builds",
      },
      {
        id: "deployment",
        type: "deployment" as ReportType,
        category: "others" as ReportCategory,
        title: t("reports.types.deployment.title"),
        description: t("reports.types.deployment.description"),
        path: "/reports/deployment",
      },
      {
        id: 'activity',
        type: 'activity' as ReportType,
        category: 'advanced' as ReportCategory,
        title: t('reports.types.activity.title'),
        description: t('reports.types.activity.description'),
        path: '/reports/activity'
      },
      {
        id: 'client-locator',
        type: 'client' as ReportType,
        category: 'others' as ReportCategory,
        title: t('reports.types.client.title'),
        description: t('reports.types.client.description'),
        path: '/reports/client-locator'  // Update this path to match the route
      },
      {
        id: "failed-backups",
        type: "failed" as ReportType,
        category: "advanced" as ReportCategory,
        title: t("reports.types.failedBackups.title"),
        description: t("reports.types.failedBackups.description"),
        path: "/reports/failed-backups",
      },
    ]
  };

  const handleViewReport = (report: Report) => {
    logger.debug('Viewing report', { data:{report} });
    selectReport(report);
    
    if (report.path) {
      logger.debug('Navigating to report path', { data:{path: report.path} });
      navigate(report.path);
    } else {
      logger.warn('No path defined for report', { data:{report} });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6 space-y-6">
      {isMainPage ? (
        <>
          {/* Header */}
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4"
          >
            <div>
              <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                {t('reports.title')}
              </h1>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                {t('reports.description')}
              </p>
            </div>
          </motion.div>


          {/* Report Categories */}
          <div className="space-y-8">
            {/* Advanced Reports */}
            <ReportGrid
              reports={reports.advanced as Report[]}
              category={t('reports.categories.advanced')}
              onViewReport={handleViewReport}
            />

            {/* Other Reports */}
            <ReportGrid
              reports={reports.others as Report[]}
              category={t('reports.categories.others')}
              onViewReport={handleViewReport}
            />
          </div>
        </>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default ReportsPage;