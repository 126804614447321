/**
 * @file src/services/auth.service.ts
 * @description Authentication service with comprehensive token management
 * @version 1.6.0
 */

import { SignInCredentials, SignUpCredentials, AuthResponse } from '@/types/auth';
import { logger } from '@/utils/logger';

const BASE_URL = '/api/v1/auth';
const PASSWORD_URL = '/api/v1/password';
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const USER_KEY = 'user';
const DEVICE_ID_KEY = 'device_id';

export const authService = {
  isValidToken: (token: string | null | { accessToken: string | null; refreshToken: string | null }): boolean => {
    if (!token) return false;
    try {
      // Handle both string and object formats
      if (typeof token === 'object' && token.accessToken) {
        return token.accessToken.toLowerCase().startsWith("bearer ");
      } else if (typeof token === "string") {
        return token.toLowerCase().startsWith("bearer ");
      }
      return false;
    } catch (error) {
      logger.error("Token validation error:", { error });
      return false;
    }
  },

  signIn: async (credentials: SignInCredentials): Promise<AuthResponse> => {
    try {
      const requestBody = {
        email: credentials.email,
        password: credentials.password,
        device_info: credentials.device_info || undefined,
        args: [],
        kwargs: {}
      };

      logger.debug('Sign in attempt:', {
        component: 'authService',
        email: credentials.email,
        hasDevice: !!credentials.device_info
      });

      const response = await fetch(`${BASE_URL}/login/access-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        } as HeadersInit,
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        let errorMessage = 'Failed to sign in';
        try {
          const errorData = await response.json();
          errorMessage = errorData.detail || errorMessage;
          
          // For 401 Unauthorized, make the error message more user-friendly
          if (response.status === 401) {
            if (errorMessage.includes("Incorrect email or password")) {
              errorMessage = "Incorrect email or password. Please try again.";
            }
          }
        } catch (parseError) {
          // If we can't parse the JSON response, use the status text
          errorMessage = response.statusText || errorMessage;
          
          // Handle specific HTTP status codes when JSON parsing fails
          if (response.status === 401) {
            errorMessage = "Invalid credentials. Please check your email and password.";
          } else if (response.status === 500) {
            errorMessage = "Server error. Please try again later.";
          }
        }
        
        logger.error('Login response error:', {
          component: 'authService',
          status: response.status,
          statusText: response.statusText,
          error: errorMessage
        });
        
        throw new Error(errorMessage);
      }

      // Parse the response data
      const data = await response.json();

      // Ensure user object has required fields with defaults if missing
      if (data.user) {
        // Ensure is_email_verified is a boolean (default to false if not provided)
        if (data.user.is_email_verified === null || data.user.is_email_verified === undefined) {
          data.user.is_email_verified = false;
        }
      }

      // Store tokens and user data
      authService.storeTokens(data.access_token, data.refresh_token);
      localStorage.setItem(USER_KEY, JSON.stringify(data.user));
      
      logger.debug('Sign in successful:', {
        component: 'authService',
        hasUser: !!data.user,
        hasAccessToken: !!data.access_token,
        hasRefreshToken: !!data.refresh_token
      });
      
      return data;
    } catch (error) {
      logger.error('Sign in error:', { error });
      throw error;
    }
  },

  signUp: async (credentials: SignUpCredentials): Promise<AuthResponse> => {
    try {
      const signupData = {
        email: credentials.email,
        password: credentials.password,
        name: credentials.name || credentials.email.split('@')[0],
        username: credentials.username || credentials.email.split('@')[0],
        device_info: credentials.device_info || undefined,
        turnstile_token: credentials.turnstile_token || undefined,
        invitation_code: credentials.invitation_code || undefined
      };

      const response = await fetch(`${BASE_URL}/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(signupData)
      });

      if (!response.ok) {
        let errorMessage = 'Failed to create account';
        try {
          const errorData = await response.json();
          errorMessage = errorData.detail || errorMessage;
          logger.error('Signup API error response:', errorData);
        } catch (parseError) {
          // If JSON parsing fails, use status text or specific errors based on status code
          logger.error('Failed to parse error response:', { 
            status: response.status, 
            statusText: response.statusText 
          });
          
          if (response.status === 400) {
            errorMessage = "Invalid signup data. Please check your input and try again.";
          } else if (response.status === 500) {
            errorMessage = "Server error during account creation. Please try again later.";
          } else {
            errorMessage = response.statusText || errorMessage;
          }
        }
        throw new Error(errorMessage);
      }

      const data = await response.json();
      
      // Store tokens immediately after signup, just like signIn does
      if (data.access_token) {
        try {
          // Clear any existing tokens first to avoid conflicts
          authService.clearTokens();
          
          // Small delay to ensure proper cleanup
          await new Promise(resolve => setTimeout(resolve, 100));
          
          // Double-check that token clearing was successful
          if (localStorage.getItem(ACCESS_TOKEN_KEY) || localStorage.getItem(REFRESH_TOKEN_KEY)) {
            logger.debug('Retrying token cleanup after signup', {
              component: 'authService'
            });
            authService.clearTokens();
            await new Promise(resolve => setTimeout(resolve, 100));
          }
          
          // Important -- directly set tokens in localStorage first
          // This needs to happen BEFORE calling the service method
          try {
            localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token.replace(/^bearer\s+/i, '').trim());
            if (data.refresh_token) {
              localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
            }
            if (data.user) {
              localStorage.setItem(USER_KEY, JSON.stringify(data.user));
            }
            
            // Reset any error counters
            localStorage.removeItem('auth_error_count');
            localStorage.removeItem('last_auth_error');
            localStorage.removeItem('system_query_failures');
            
            // Verify tokens were actually set
            const tokenSet = localStorage.getItem(ACCESS_TOKEN_KEY);
            const userSet = localStorage.getItem(USER_KEY);
            
            logger.debug('Direct token storage after signup', {
              component: 'authService',
              tokenAvailable: !!tokenSet,
              userAvailable: !!userSet,
              tokenLength: tokenSet?.length || 0
            });
          } catch (directStorageError) {
            logger.error('Error during direct token storage', {
              component: 'authService',
              error: directStorageError
            });
          }
          
          // Then also use the service method as a backup
          try {
            authService.storeTokens(data.access_token, data.refresh_token);
          } catch (serviceStorageError) {
            logger.error('Error using service method for token storage', {
              component: 'authService',
              error: serviceStorageError
            });
            // Continue anyway since we already stored directly in localStorage
          }
          
          logger.debug('Tokens stored after signup', {
            component: 'authService',
            hasRefreshToken: !!data.refresh_token,
            userId: data.user?.id,
            tokenInLocalStorage: !!localStorage.getItem(ACCESS_TOKEN_KEY),
            tokenLength: localStorage.getItem(ACCESS_TOKEN_KEY)?.length || 0
          });
          
          // Force a short delay to ensure tokens are fully saved before any redirects
          await new Promise(resolve => setTimeout(resolve, 300));
        } catch (storageError) {
          logger.error('Error storing tokens after signup', { 
            component: 'authService',
            error: storageError
          });
          throw new Error('Failed to complete signup process: ' + storageError);
        }
      }
      
      return data;
    } catch (error) {
      logger.error('Sign up error:', { error });
      throw error;
    }
  },

  resetPassword: async (email: string, uiType: string = 'ghost'): Promise<void> => {
    try {
      logger.debug('Requesting password reset', {
        component: 'authService',
        email,
        uiType
      });
      
      const response = await fetch(`${PASSWORD_URL}/request-reset`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, ui_type: uiType })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to request password reset');
      }
    } catch (error) {
      logger.error('Password reset request error:', { error });
      throw error;
    }
  },

  confirmPasswordReset: async (token: string, newPassword: string): Promise<void> => {
    try {
      const response = await fetch(`${PASSWORD_URL}/confirm-reset`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          new_password: newPassword  // Convert from camelCase to snake_case for API
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to reset password');
      }
    } catch (error) {
      logger.error('Password reset confirmation error:', { error });
      throw error;
    }
  },

  clearTokens: (): void => {
    try {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
    } catch (error) {
      logger.error('Error clearing tokens:', { error });
    }
  },

  refreshToken: async (): Promise<AuthResponse> => {
    // Implement refresh token lock to prevent multiple simultaneous refreshes
    const REFRESH_LOCK_KEY = 'refresh_lock';
    try {
      // Check if refresh is already in progress
      if (localStorage.getItem(REFRESH_LOCK_KEY)) {
        const lockTime = Number(localStorage.getItem(REFRESH_LOCK_KEY));
        const currentTime = Date.now();
        
        // If lock is older than 10 seconds, it might be stale
        if (currentTime - lockTime > 10000) {
          logger.warn('Found stale refresh lock, clearing it', {
            component: 'authService',
            lockAge: (currentTime - lockTime) / 1000
          });
          localStorage.removeItem(REFRESH_LOCK_KEY);
        } else {
          throw new Error('Refresh already in progress');
        }
      }

      // Set refresh lock with current timestamp
      localStorage.setItem(REFRESH_LOCK_KEY, Date.now().toString());

      // Get current tokens - try multiple sources to be robust
      const { refreshToken } = authService.getStoredToken();
      const directRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      
      // Use whichever refresh token is available
      const tokenToUse = directRefreshToken || (refreshToken?.replace(/^bearer\s+/i, '') || null);
      
      if (!tokenToUse) {
        logger.error('No refresh token available for refresh');
        throw new Error('No refresh token available');
      }

      logger.debug('Attempting token refresh', {
        component: 'authService',
        hasRefreshToken: !!tokenToUse,
        tokenLength: tokenToUse.length,
        refreshTokenPrefix: tokenToUse.substring(0, 10) + '...',
        timestamp: new Date().toISOString()
      });

      const device_info = localStorage.getItem(DEVICE_ID_KEY);
      const response = await fetch(`${BASE_URL}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          refresh_token: tokenToUse,
          device_info: device_info || undefined
        })
      });

      // Get response text even for errors to help with debugging
      const responseText = await response.text();
      
      if (!response.ok) {
        let errorData;
        try {
          // Try to parse error as JSON
          errorData = JSON.parse(responseText);
        } catch {
          // If not JSON, use text as is
          errorData = { message: responseText };
        }
        
        logger.error('Token refresh API call failed', {
          component: 'authService',
          status: response.status,
          statusText: response.statusText,
          errorData
        });
        
        // Only clear tokens for 401 Unauthorized (invalid token)
        // For other errors (like network issues), keep tokens to allow retries
        if (response.status === 401) {
          logger.warn('Clearing tokens due to 401 Unauthorized response', {
            component: 'authService'
          });
          authService.clearTokens();
        }
        
        throw new Error(`Token refresh failed: ${response.status} ${response.statusText}`);
      }

      let data;
      try {
        // Parse the successful response
        data = JSON.parse(responseText);
      } catch (parseError) {
        logger.error('Failed to parse refresh token response', {
          component: 'authService',
          error: parseError,
          responseText: responseText.substring(0, 100) // Log the start of the response
        });
        throw new Error('Invalid response from refresh token endpoint');
      }
      
      if (data.access_token && data.refresh_token) {
        // Store tokens BEFORE clearing the lock to prevent race conditions
        authService.storeTokens(data.access_token, data.refresh_token);
        
        logger.debug('Token refresh succeeded', {
          component: 'authService',
          hasNewAccessToken: !!data.access_token,
          hasNewRefreshToken: !!data.refresh_token,
          accessTokenPrefix: data.access_token.substring(0, 10) + '...',
          timestamp: new Date().toISOString()
        });
      } else {
        logger.warn('Token refresh response missing tokens', {
          component: 'authService',
          hasAccessToken: !!data.access_token,
          hasRefreshToken: !!data.refresh_token,
          responseKeys: Object.keys(data)
        });
      }

      return data;
    } catch (error) {
      logger.error('Token refresh failed:', { 
        error,
        message: error instanceof Error ? error.message : 'Unknown error',
        stack: error instanceof Error ? error.stack : undefined
      });
      
      // Don't automatically clear tokens on network errors
      // Only 401 responses should clear tokens (handled above)
      if (error instanceof Error && 
          (error.message.includes('401') || error.message.includes('Unauthorized'))) {
        authService.clearTokens();
      }
      
      throw error;
    } finally {
      // Clear refresh lock
      localStorage.removeItem(REFRESH_LOCK_KEY);
    }
  },

  storeTokens: (accessToken?: string | null, refreshToken?: string | null): void => {
    try {
      if (!accessToken) {
        throw new Error('Invalid access token provided');
      }

      // Clean the access token by removing any existing "Bearer " prefix
      // This ensures consistent token storage format
      const cleanAccessToken = accessToken.replace(/^bearer\s+/i, '').trim();
      
      // Store both tokens atomically to prevent race conditions
      const storage = {
        [ACCESS_TOKEN_KEY]: cleanAccessToken,
        ...(refreshToken && { [REFRESH_TOKEN_KEY]: refreshToken }),
      };

      // Store all tokens in a single batch
      Object.entries(storage).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
      
      logger.debug('Tokens stored successfully', {
        component: 'authService',
        hasAccessToken: true,
        hasRefreshToken: !!refreshToken
      });
    } catch (error) {
      logger.error('Error storing tokens:', { error });
      // Clear any partially stored tokens
      authService.clearTokens();
      throw error;
    }
  },

  getStoredToken: () => {
    try {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      
      // Debug token retrieval in detail
      logger.debug('Token retrieval details', {
        component: 'authService',
        hasRawAccessToken: !!accessToken,
        rawTokenLength: accessToken?.length || 0,
        rawTokenPrefix: accessToken?.substring(0, 10),
        hasRefreshToken: !!refreshToken,
      });

      // Check if the token already has a Bearer prefix
      const tokenWithProperPrefix = accessToken 
        ? (accessToken.startsWith('Bearer ') ? accessToken : `Bearer ${accessToken}`)
        : null;

      return {
        accessToken: tokenWithProperPrefix,
        refreshToken: refreshToken || null
      };
    } catch (error) {
      logger.error('Error getting stored tokens:', { error });
      return { accessToken: null, refreshToken: null };
    }
  },

  logout: async (): Promise<void> => {
    try {
      const { accessToken } = authService.getStoredToken();
      if (accessToken) {
        try {
          await fetch(`${BASE_URL}/logout`, {
            method: 'POST',
            headers: {
              'Authorization': accessToken,
              'Content-Type': 'application/json'
            }
          });
        } catch (error) {
          logger.warn('Logout API call failed:', { error } as any);
        }
      }

      // Basic auth keys to remove
      const keysToRemove = [ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_KEY, DEVICE_ID_KEY];
      
      // Additional application state keys to clear
      const appKeysToRemove = [
        'bigmind_active_drive_id',   // Drive ID from RootDriveContext
        'dev_mode_active',           // Dev mode flag
        'auth_error_count',          // Auth error counter
        'last_auth_error',           // Last auth error timestamp
        'upload_resume_states',      // Upload states
        'encrypted_drive_states'     // Encrypted drive states
      ];
      
      // Combine all keys
      const allKeysToRemove = [...keysToRemove, ...appKeysToRemove];
      
      // Clear all storage items
      allKeysToRemove.forEach(key => {
        try {
          localStorage.removeItem(key);
        } catch (e) {
          logger.error(`Error removing ${key} from localStorage:`, { error: e });
        }
      });
      
      // Clear all application query caches
      try {
        // This will be handled by the QueryProvider's reset after logout
        logger.debug('Application storage cleared during logout');
      } catch (e) {
        logger.error('Error clearing application caches:', { error: e });
      }
    } catch (error) {
      logger.error('Logout process error:', { error });
      throw error;
    }
  }
};