/**
 * @file src/components/services/ConnectServiceButton.tsx
 * @description Button component for connecting a new service
 * @version 1.0.1
 * @updated 2025-02-28
 * @dependencies lucide-react, react-i18next
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, Cloud } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useMediaQuery } from '@/hooks/useMediaQuery';

interface ConnectServiceButtonProps {
  onClick: () => void;
  variant?: 'default' | 'large';
  className?: string;
}

export const ConnectServiceButton: React.FC<ConnectServiceButtonProps> = ({
  onClick,
  variant = 'default',
  className
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 640px)');

  const handleClick = () => {
    logger.debug('Connect service button clicked', {
      component: 'ConnectServiceButton',
      action: 'click'
    });
    onClick();
  };

  if (variant === 'large') {
    return (
      <Button
        variant="default"
        size="lg"
        onClick={handleClick}
        className={cn(
          "bg-blue-500 hover:bg-blue-600 text-white",
          "py-6 px-8 rounded-xl text-base",
          className
        )}
      >
        <Cloud className="h-5 w-5 mr-2" />
        {t('services.actions.connect_first_service')}
      </Button>
    );
  }

  return (
    <Button
      variant="default"
      size="sm"
      onClick={handleClick}
      className={cn(
        "bg-blue-500 hover:bg-blue-600 text-white",
        "whitespace-nowrap flex-shrink-0",
        "min-w-max h-10",
        className
      )}
    >
      <Cloud className="h-4 w-4 mr-1.5" />
      {!isMobile && <Plus className="h-3 w-3 mr-1" />}
      {isMobile ? t('services.actions.connect_short') : t('services.actions.connect_service')}
    </Button>
  );
};

export default ConnectServiceButton;