/**
 * @file src/layouts/ghost/components/GhostHeader.tsx
 * @description Enhanced header component with improved Nygma logo display and spacing
 * @version 2.1.0
 * @updated 2025-03-29
 */

import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { Shield, Search, Bell, Menu, X, ChevronLeft } from 'lucide-react';
import { GhostGlass } from '@/app/ghost/components/common/GhostGlass';
import { GhostGradientText } from '@/app/ghost/components/common/GhostGradients';
import { cn } from '@/utils/utils';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { logger } from '@/utils/logger';

interface GhostHeaderProps {
  onMenuClick?: () => void;
  isMobileMenuOpen?: boolean;
  className?: string;
}

export const GhostHeader: React.FC<GhostHeaderProps> = ({
  onMenuClick,
  isMobileMenuOpen,
  className
}) => {
  const { t } = useGhostTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we're in a sub-route to show back button
  const isSubRoute = location.pathname !== '/ghost';

  logger.debug('Rendering GhostHeader', {
    component: 'GhostHeader',
    isMobileMenuOpen,
    pathname: location.pathname,
    isSubRoute
  });

  return (
    <GhostGlass
      className={cn(
        "w-full h-16 px-4 sm:px-6",
        "border-b border-[hsl(var(--ghost-accent))/0.1]",
        "flex items-center justify-between",
        className
      )}
      opacity="low"
      animate
    >
      {/* Logo Area with conditional back button */}
      <div className="flex items-center space-x-3">
        {isSubRoute ? (
          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={() => navigate(-1)}
            className="h-8 w-8 rounded-lg flex items-center justify-center bg-[hsl(var(--ghost-surface))]"
          >
            <ChevronLeft className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
          </motion.button>
        ) : (
          <motion.div
            animate={{ rotate: [0, 360] }}
            transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
            className="relative"
          >
            <div className="absolute inset-0 blur-sm opacity-50 bg-[hsl(var(--ghost-accent))]" />
            <Shield className="relative h-8 w-8 text-[hsl(var(--ghost-accent))]" />
          </motion.div>
        )}
        <GhostGradientText className="text-lg font-bold hidden sm:block">
          {"Nygma.ai"}
        </GhostGradientText>
      </div>

      {/* Title for mobile - responsive based on route */}
      <div className="md:hidden">
        <GhostGradientText className="text-base font-medium">
          {isSubRoute 
            ? String(t(`routes.${location.pathname.split('/')[2] || 'unknown'}`))
            : 'Nygma.ai'}
        </GhostGradientText>
      </div>

      {/* Search Bar - Hidden on Mobile */}
      <div className="hidden md:flex flex-1 max-w-md mx-4">
        <GhostGlass
          className="w-full flex items-center space-x-2 px-4 py-2"
          opacity="low"
        >
          <Search className="h-4 w-4 text-[hsl(var(--ghost-accent))]" />
          <input 
            type="text"
            placeholder={String(t('search.placeholder'))}
            className={cn(
              "bg-transparent border-none outline-none w-full",
              "text-sm text-[hsl(var(--ghost-foreground))]",
              "placeholder:text-[hsl(var(--ghost-foreground-muted))]"
            )}
          />
        </GhostGlass>
      </div>

      {/* Actions */}
      <div className="flex items-center space-x-2 sm:space-x-4">
        {/* Earn Space Button - Hidden on Mobile */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/ghost/upgrade')}
          className={cn(
            "hidden md:flex items-center space-x-2",
            "px-4 py-1.5 rounded-lg",
            "bg-[hsl(var(--ghost-accent))]",
            "text-white text-sm font-medium",
            "transition-all duration-200"
          )}
        >
          <span>{String(t('actions.earn_space'))}</span>
        </motion.button>

        {/* Notifications */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/ghost/notifications')}
          className={cn(
            "relative w-10 h-10 rounded-lg",
            "flex items-center justify-center",
            "bg-[hsl(var(--ghost-surface))]",
            "border border-[hsl(var(--ghost-accent))/0.1]",
            "transition-all duration-200"
          )}
        >
          <Bell className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
          {/* Notification Badge with Animation */}
          <motion.span 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className={cn(
              "absolute -top-1 -right-1",
              "min-w-[18px] h-[18px]",
              "flex items-center justify-center",
              "rounded-full text-[10px] font-medium",
              "bg-[hsl(var(--ghost-accent))]",
              "text-white"
            )}
          >
            2
          </motion.span>
        </motion.button>

        {/* Mobile Menu Button */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onMenuClick}
          className={cn(
            "md:hidden w-10 h-10 rounded-lg",
            "flex items-center justify-center",
            "bg-[hsl(var(--ghost-surface))]",
            "border border-[hsl(var(--ghost-accent))/0.1]",
            "transition-all duration-200"
          )}
        >
          {isMobileMenuOpen ? (
            <X className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
          ) : (
            <Menu className="h-5 w-5 text-[hsl(var(--ghost-accent))]" />
          )}
        </motion.button>
      </div>
    </GhostGlass>
  );
};

export default GhostHeader;