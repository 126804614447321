/**
 * @file src/components/cloud-drive/layout/toolbar/components/ViewControls.tsx
 * @description View controls component with sort and display options
 * @version 1.0.0
 */

import React from 'react';
// import { useTranslation } from 'react-i18next';
import { LayoutGrid, List as ListIcon, Info, SidebarClose } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { StorageQuotaIndicator } from '../../../storage/StorageQuotaIndicator';
import { SortDropdown } from './SortDropdown';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import type { ViewControlsProps } from '../types';

export const ViewControls: React.FC<ViewControlsProps> = ({
  sortConfig,
  onSortChange,
  viewMode,
  onViewModeChange,
  isInfoPanelOpen,
  onToggleInfoPanel,
  viewType = 'default',
  className
}) => {
  // const { t } = useTranslation();

  const handleViewModeToggle = () => {
    logger.debug('View mode toggled', {
      component: 'ViewControls',
      currentMode: viewMode,
      newMode: viewMode === 'grid' ? 'list' : 'grid'
    } as any);
    onViewModeChange(viewMode === 'grid' ? 'list' : 'grid');
  };

  return (
    <div className={cn("flex items-center space-x-2", className)}>
      <StorageQuotaIndicator 
        variant="minimal" 
        className="hidden md:flex"
      />

      <SortDropdown 
        sortConfig={sortConfig}
        onSortChange={onSortChange}
        viewType={viewType}
      />

      <Button
        variant="ghost"
        size="icon"
        onClick={handleViewModeToggle}
        className="hidden sm:flex"
      >
        {viewMode === 'grid' ? (
          <ListIcon className="h-4 w-4" />
        ) : (
          <LayoutGrid className="h-4 w-4" />
        )}
      </Button>

      <Button
        variant="ghost"
        size="icon"
        onClick={() => {
          logger.debug('Info panel toggled', {
            component: 'ViewControls',
            currentState: isInfoPanelOpen
          } as any);
          onToggleInfoPanel();
        }}
        className={cn(
          "hidden md:flex",
          isInfoPanelOpen && "bg-gray-100 dark:bg-gray-800"
        )}
      >
        {isInfoPanelOpen ? (
          <SidebarClose className="h-4 w-4" />
        ) : (
          <Info className="h-4 w-4" />
        )}
      </Button>
    </div>
  );
};

export default ViewControls;