/**
 * @file src/components/cloud-drive/layout/CloudDriveLayout.tsx
 * @description Final layout component for cloud drive with fixed scroll issues
 * @version 2.7.0
 * @updated 2025-03-18
 */

import React, { useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/utils/utils';
import { MainToolbar } from './toolbar/MainToolbar';
import { DragProvider } from '../dnd/DragContext';
import { SelectionProvider } from '@/context/SelectionContext';
import { CreateFolderDialog } from '../dialogs/CreateFolderDialog';
import { InfoPanel } from '../info/InfoPanel';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { useCloudDrive } from '@/context/cloud-drive';
import { GlobalUploadQueue } from '@/components/upload/GlobalUploadQueue';
import { logger } from '@/utils/logger';
import { BreadcrumbNavigation } from '../navigation/BreadcrumbNavigation';
import { FavoritesToolbar } from '@/components/favorites/FavoritesToolbar';
import { useEncryptedFiles } from '@/hooks/useEncryptedFiles';
import { useNavigation } from '@/context/NavigationContext';

interface CloudDriveLayoutProps {
  children: React.ReactNode;
  className?: string;
  viewType?: 'default' | 'folder' | 'shared' | 'favorites' | 'trash';
}

export const CloudDriveLayout: React.FC<CloudDriveLayoutProps> = ({ 
  children,
  className,
  viewType = 'default'
}) => {
  const { 
    isFolderDialogOpen,
    closeFolderDialog,
    currentFolder,
    selectedItems,
    isInfoPanelOpen,
    currentFolderContents,
    encryptedDrive
  } = useCloudDrive();
  
  // Use the NavigationContext to get the current folder ID - this is more reliable
  const { currentFolderId } = useNavigation();
  
  // Initialize the encryption connection
  useEncryptedFiles();

  // Debug logging
  useEffect(() => {
    logger.debug('CloudDriveLayout mounted', {
      component: 'CloudDriveLayout',
      data: {
        currentFolder,
        contentsLength: currentFolderContents?.length,
        selectedItems,
        isInfoPanelOpen,
        isEncryptedMode: encryptedDrive?.isEncryptedMode,
        viewType
      }
    });
  }, [currentFolder, currentFolderContents, selectedItems, isInfoPanelOpen, encryptedDrive, viewType]);

  // Selected item computation
  const selectedItem = useMemo(() => {
    if (selectedItems?.length !== 1 || !currentFolderContents) {
      return undefined;
    }
    
    return currentFolderContents.find((item: any) => item.id === selectedItems[0]);
  }, [selectedItems, currentFolderContents]);

  // Debug logs
  logger.debug('CloudDriveLayout render', {
    component: 'CloudDriveLayout',
    data: {
      selectedItems,
      selectedItemId: selectedItem?.id,
      folderContentsLoaded: !!currentFolderContents,
      isInfoPanelOpen
    }
  });

  return (
    <SelectionProvider>
      <DragProvider>
        <div className={cn(
          "flex flex-col h-full w-full",
          "bg-gray-50 dark:bg-gray-900",
          "overflow-hidden", // Prevent any overflow at this level
          className
        )}>
          {/* Fixed Header Area - Will not scroll */}
          <div className="flex-none">
            {/* Use viewType to conditionally render toolbar */}
            {viewType === 'favorites' ? (
              <FavoritesToolbar className="flex-none" />
            ) : (
              <MainToolbar className="flex-none" />
            )}
            
            {/* Breadcrumb Navigation - Only shown in list and grid views */}
            {useCloudDrive().viewDisplay === 'grid' || useCloudDrive().viewDisplay === 'list' ? (
              <BreadcrumbNavigation 
                className={cn(
                  "bg-white dark:bg-gray-900",
                  "border-b border-gray-100 dark:border-gray-800"
                )}
              />
            ) : null}
          </div>
          
          {/* Content Area - Single Scrollable Container */}
          <div className="flex-1 relative overflow-hidden">
            {/* Main Content with single scroll container */}
            <motion.div 
              className={cn(
                "absolute top-0 bottom-0 left-0",  
                "transition-all duration-200 ease-in-out",
                "ghost-drive-primary-scroll" // Add the marker class for primary scroll container
              )}
              style={{
                right: isInfoPanelOpen ? '320px' : 0,
                overflowY: 'auto',  // This is the ONLY scrollable container
                overflowX: 'hidden',
                width: isInfoPanelOpen ? 'calc(100% - 320px)' : '100%',
                height: '100%', // Full height of the container
                WebkitOverflowScrolling: 'touch' // For smoother scrolling on iOS
              }}
            >
              {/* Children components - no overflow or height constraints */}
              <div className="min-h-full">
                {children}
              </div>
            </motion.div>

            {/* Info Panel */}
            {isInfoPanelOpen && (
              <SheetContent
                side="right"
                showCloseButton={false}
                className={cn("z-30", "bg-white dark:bg-gray-900", "p-0")}
                style={{
                  top: 'auto', // Remove fixed top position
                  width: '320px',
                  height: 'calc(100% - 104px)', // Account for toolbar and breadcrumb height
                  bottom: 0,
                  willChange: "transform",
                }}
              >
                <InfoPanel
                  {...({
                    item: selectedItem,
                    onAddTag: (tag: string) => {
                      logger.debug("Adding tag", {
                        component: "CloudDriveLayout",
                        tag,
                      } as any);
                    },
                    onRemoveTag: (tag: string) => {
                      logger.debug("Removing tag", {
                        component: "CloudDriveLayout",
                        tag,
                      } as any);
                    },
                  } as any)}
                />
              </SheetContent>
            )}
          </div>

          {/* Dialogs */}
          {/* Log current folder value to help debug folder creation issue */}
          {(() => {
            if (isFolderDialogOpen) {
              logger.debug('Opening CreateFolderDialog with parentFolderId', {
                component: 'CloudDriveLayout',
                data: {
                  currentFolder,
                  isFolderDialogOpen
                }
              });
            }
            return null;
          })()}
          <CreateFolderDialog
            isOpen={isFolderDialogOpen}
            onClose={closeFolderDialog}
            parentFolderId={currentFolderId || currentFolder}
          />

          {/* Global Upload Queue */}
          <GlobalUploadQueue />
        </div>
      </DragProvider>
    </SelectionProvider>
  );
};

export default CloudDriveLayout;