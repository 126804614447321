/**
 * @file src/components/cloud-drive/error/ErrorBoundary.tsx
 * @description Error boundary for cloud drive components
 * @version 1.0.0
 * @created 2024-12-22
 */

import React from 'react';
import { ErrorState } from '../loading/LoadingStates';
import { Button } from '@/components/ui/button';
import { logger } from '@/utils/logger';

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class CloudDriveErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error('Cloud Drive error boundary caught error:', {
      component: 'CloudDriveErrorBoundary',
      error,
      componentStack: errorInfo.componentStack
    });
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback || (
        <ErrorState
          title="Something went wrong"
          message={this.state.error?.message || 'An unexpected error occurred'}
          action={
            <Button
              onClick={() => this.setState({ hasError: false, error: null })}
            >
              Try Again
            </Button>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default CloudDriveErrorBoundary;