/**
 * @file src/providers/FilePreviewProvider.tsx
 * @description Provides file preview capabilities including encrypted media
 * @version 1.0.0
 * @created 2025-03-05
 */

import React, { createContext, useContext, ReactNode, useEffect, useState, useRef, useCallback } from 'react';
import { logger } from '@/utils/logger';
import { useFilePreview, PreviewState } from '@/hooks/cloud-drive/useFilePreview';
import { EncryptedImageViewer } from '@/components/encrypted-drive/media/EncryptedImageViewer';
import type { DriveFile } from '@/types/cloud-drive.types';

// Define the preview context interface
interface FilePreviewContextType {
  previewState: PreviewState;
  previewFile: (file: DriveFile, files?: DriveFile[]) => void;
  closePreview: () => void;
  nextPreview: () => void;
  previousPreview: () => void;
}

// Create the context
const FilePreviewContext = createContext<FilePreviewContextType | undefined>(undefined);

// Provider component
export const FilePreviewProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {
    previewState,
    previewFile,
    closePreview,
    nextPreview,
    previousPreview,
    handleOpenChange
  } = useFilePreview();
  

  useEffect(() => {
    logger.debug('FilePreviewProvider state update', {
      component: 'FilePreviewProvider',
      data: {
        isOpen: previewState.isOpen,
        hasFile: !!previewState.currentFile,
        fileIndex: previewState.currentIndex,
        totalFiles: previewState.files.length
      }
    });
  }, [previewState]);

  // Context value
  const value: FilePreviewContextType = {
    previewState,
    previewFile,
    closePreview,
    nextPreview,
    previousPreview
  };
  
  // Track errors to prevent infinite loops
  const [viewerError, setViewerError] = useState<boolean>(false);
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  // Reset error after a delay
  useEffect(() => {
    if (viewerError) {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      
      errorTimeoutRef.current = setTimeout(() => {
        setViewerError(false);
      }, 5000);
    }
    
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, [viewerError]);
  
  // Handle viewer errors
  const handleViewerError = useCallback(() => {
    logger.error('Error in EncryptedImageViewer - disabling temporarily', {
      component: 'FilePreviewProvider'
    });
    
    setViewerError(true);
    closePreview();
  }, [closePreview]);
  
  return (
    <FilePreviewContext.Provider value={value}>
      {children}
      
      {/* Only render the file preview component if we have a file, it's open, and there's no error */}
      {previewState.currentFile && previewState.isOpen && !viewerError ? (
        <EncryptedImageViewer
            file={previewState.currentFile}
            open={previewState.isOpen}  // this will get converted to isOpen in the component
            onOpenChange={handleOpenChange}
            onClose={closePreview}
            onNext={previewState.files.length > 1 ? nextPreview : undefined}
            onPrevious={previewState.files.length > 1 ? previousPreview : undefined}
            hasNext={previewState.files.length > 1}
            hasPrevious={previewState.files.length > 1}
            onError={() => handleViewerError()}
        />
        ) : null}
    </FilePreviewContext.Provider>
  );
};

// Hook for using the preview context
export const useFilePreviewContext = () => {
  const context = useContext(FilePreviewContext);
  if (!context) {
    throw new Error('useFilePreviewContext must be used within a FilePreviewProvider');
  }
  return context;
};

export default FilePreviewProvider;