/**
 * @file slider.tsx
 * @path src/components/ui/slider.tsx
 * @description Slider UI component with theme support
 * @version 1.1.0
 * @date 2024-12-23
 */

import * as React from "react"
import { cn } from "@/utils/utils"

interface SliderProps extends React.HTMLAttributes<HTMLDivElement> {
  value: number[]
  max?: number
  min?: number
  step?: number
  onValueChange?: (value: number[]) => void
  onValueCommit?: () => void
  disabled?: boolean
}

const Slider = React.forwardRef<HTMLDivElement, SliderProps>(
  ({ 
    className,
    value = [0],
    min = 0,
    max = 100,
    step = 1,
    onValueChange,
    onValueCommit,
    disabled = false,
    ...props 
  }, ref) => {
    const [isDragging, setIsDragging] = React.useState(false)
    const sliderRef = React.useRef<HTMLDivElement>(null)

    const percentage = React.useMemo(() => {
      const val = value[0]
      return ((val - min) / (max - min)) * 100
    }, [value, min, max])

    const updatePosition = React.useCallback((clientX: number) => {
      if (!sliderRef.current || disabled) return

      const rect = sliderRef.current.getBoundingClientRect()
      const x = clientX - rect.left
      const width = rect.width
      
      let percentage = (x / width) * 100
      percentage = Math.min(Math.max(percentage, 0), 100)
      
      const newValue = min + ((max - min) * percentage) / 100
      const steppedValue = Math.round(newValue / step) * step
      
      onValueChange?.([steppedValue])
    }, [disabled, min, max, step, onValueChange])

    const handleMouseDown = React.useCallback((e: React.MouseEvent) => {
      if (disabled) return
      setIsDragging(true)
      updatePosition(e.clientX)
    }, [disabled, updatePosition])
    
    const handleMouseMove = React.useCallback((e: MouseEvent) => {
      if (!isDragging || disabled) return
      updatePosition(e.clientX)
    }, [isDragging, disabled, updatePosition])
    
    const handleMouseUp = React.useCallback(() => {
      setIsDragging(false)
      if (onValueCommit) {
        onValueCommit()
      }
    }, [onValueCommit])
    
    const handleTouchStart = React.useCallback((e: React.TouchEvent) => {
      if (disabled || !e.touches[0]) return
      setIsDragging(true)
      updatePosition(e.touches[0].clientX)
    }, [disabled, updatePosition])
    
    const handleTouchMove = React.useCallback((e: TouchEvent) => {
      if (!isDragging || disabled || !e.touches[0]) return
      e.preventDefault()
      updatePosition(e.touches[0].clientX)
    }, [isDragging, disabled, updatePosition])
    
    const handleTouchEnd = React.useCallback(() => {
      setIsDragging(false)
      if (onValueCommit) {
        onValueCommit()
      }
    }, [onValueCommit])

    React.useEffect(() => {
      if (isDragging) {
        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('mouseup', handleMouseUp)
        window.addEventListener('touchmove', handleTouchMove, { passive: false })
        window.addEventListener('touchend', handleTouchEnd)
      }
      
      return () => {
        window.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('mouseup', handleMouseUp)
        window.removeEventListener('touchmove', handleTouchMove)
        window.removeEventListener('touchend', handleTouchEnd)
      }
    }, [isDragging, handleMouseMove, handleMouseUp, handleTouchMove, handleTouchEnd])

    return (
      <div
        ref={ref}
        className={cn(
          "relative flex items-center select-none touch-none w-full",
          disabled && "opacity-50 cursor-not-allowed",
          className
        )}
        {...props}
      >
        <div
          ref={sliderRef}
          className={cn(
            "relative w-full h-2 sm:h-2 rounded-full",
            "bg-gray-100 dark:bg-gray-800",
            !disabled && "cursor-pointer"
          )}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          style={{ touchAction: 'none' }}
        >
          {/* Track Fill */}
          <div
            className="absolute h-full rounded-full bg-blue-500 dark:bg-blue-400"
            style={{ width: `${percentage}%` }}
          />
          
          {/* Thumb - Increased size for mobile touch targets */}
          <div
            className={cn(
              "absolute w-5 h-5 sm:w-4 sm:h-4 rounded-full",
              "bg-white dark:bg-gray-100",
              "border-2 border-blue-500 dark:border-blue-400",
              "transform -translate-x-1/2 -translate-y-1/4",
              "focus:outline-none focus:ring-2 focus:ring-blue-400",
              "drop-shadow-md", /* Add shadow for better visibility */
              !disabled && "cursor-grab active:cursor-grabbing"
            )}
            style={{ left: `${percentage}%` }}
            role="slider"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value[0]}
            tabIndex={disabled ? -1 : 0}
          />
        </div>
      </div>
    )
  }
)

Slider.displayName = "Slider"

export { Slider }
export type { SliderProps }