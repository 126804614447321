/**
 * @file src/components/ghost/common/background/utils/canvasHelpers.ts
 * @description Canvas setup and utility functions
 * @version 1.0.0
 */

import { logger } from '@/utils/logger';

export interface CanvasDimensions {
  width: number;
  height: number;
  scale: number;
}

export const setupCanvas = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d', { alpha: true });
  if (!ctx) {
    logger.error('Failed to get canvas context');
    return null;
  }

  // Set up resize observer for canvas scaling
  let dimensions: CanvasDimensions = { width: 0, height: 0, scale: 1 };

  const updateCanvasSize = () => {
    const rect = canvas.getBoundingClientRect();
    const scale = window.devicePixelRatio;
    
    canvas.width = rect.width * scale;
    canvas.height = rect.height * scale;
    
    ctx.scale(scale, scale);
    
    dimensions = {
      width: rect.width,
      height: rect.height,
      scale
    };

    logger.debug('Canvas dimensions updated', { 
      component: 'canvasHelpers'
    });
  };

  const resizeObserver = new ResizeObserver(() => {
    updateCanvasSize();
  });

  resizeObserver.observe(canvas);
  updateCanvasSize();

  return {
    ctx,
    dimensions,
    cleanup: () => resizeObserver.disconnect()
  };
};

export const clearCanvas = (
  ctx: CanvasRenderingContext2D, 
  dimensions: CanvasDimensions
) => {
  ctx.clearRect(0, 0, dimensions.width, dimensions.height);
};

export const getResponsiveValue = (
  mobileValue: number,
  desktopValue: number
): number => {
  return window.innerWidth < 768 ? mobileValue : desktopValue;
};