/**
 * @file src/services/upload/core/uploadOrchestrator.ts
 * @description Core upload orchestration and coordination
 * @version 1.0.0
 */

import { logger } from '@/utils/logger';
import { UPLOAD_CONFIG } from '@/config/upload.config';
import { uploadService } from '../../upload.service';
import { multipartUploadService } from '../../multipart-upload.service';
import { secureS3UploadService } from '../../s3-upload';

import { resumeManager } from './resumeManager';
import { UploadStrategy } from '@/types/upload.types';
import type {
  UploadFile,
  UploadProgress,
  UploadResponse,
  UploadStatus,
  ChunkState
} from '@/types/upload.types';
import type { S3UploadState } from '@/types/s3-upload.types';

class EncryptedUploadError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'EncryptedUploadError';
  }
}




export class UploadOrchestrator {
  private activeUploads: Map<string, UploadFile> = new Map();

  constructor() {
    logger.debug('Initializing UploadOrchestrator');
  }


  /**
   * Start a new upload or resume an existing one
   */
  async startUpload(
    file: File,
    parentId: string | null,
    driveId: string,
    directUploadEnabled: boolean = false,
    onProgress?: (progress: UploadProgress) => void,
    encryptedOptions?: {
      isEncrypted: boolean;
      encryptedDriveId: string;
      encryptionTier: string;
    }
  ): Promise<UploadResponse> {
    // Create a wrap progress function to add debugging
    const debugProgress = (progress: UploadProgress) => {
      // Log first progress update
      if (progress && !progressReported) {
        progressReported = true;
        logger.debug('First progress update in orchestrator', {
          component: 'UploadOrchestrator',
          data: {
            fileName: file.name,
            progress: progress.encryptionProgress || (progress.loaded / progress.total * 100),
            status: progress.encryptionStatus || 'uploading',
            message: progress.message
          }
        });
      }
      
      // Pass to original callback
      if (onProgress) {
        onProgress(progress);
      }
    };
    
    let progressReported = false;
    
    try {
      // Enhanced logging with encryption info
      logger.debug('Starting upload process', {
        component: 'UploadOrchestrator',
        action: 'startUpload',
        data: {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          driveId,
          mode: 'relay',
          isEncrypted: !!encryptedOptions?.isEncrypted,
          encryptedDriveId: encryptedOptions?.encryptedDriveId,
          encryptionTier: encryptedOptions?.encryptionTier
        }
      });

      // If encrypted options are provided, validate and process accordingly
      if (encryptedOptions?.isEncrypted) {
        logger.debug('Validating encrypted upload options', {
          component: 'UploadOrchestrator',
          data: {
            fileName: file.name,
            encryptedOptions: {
              encryptedDriveId: encryptedOptions.encryptedDriveId,
              encryptionTier: encryptedOptions.encryptionTier
            }
          }
        });
        
        // Validate required encryption parameters
        if (!encryptedOptions.encryptedDriveId) {
          const error = new EncryptedUploadError('Missing encrypted drive ID for encrypted upload');
          logger.error('Encrypted upload validation failed', {
            component: 'UploadOrchestrator',
            error,
            data: {
              fileName: file.name,
              encryptedOptions
            }
          });
          throw error;
        }

        if (!encryptedOptions.encryptionTier) {
          const error = new EncryptedUploadError('Missing encryption tier for encrypted upload');
          logger.error('Encrypted upload validation failed', {
            component: 'UploadOrchestrator',
            error,
            data: {
              fileName: file.name,
              encryptedOptions
            }
          });
          throw error;
        }

        logger.debug('Using encrypted upload', {
          component: 'UploadOrchestrator',
          data: {
            fileName: file.name,
            encryptedDriveId: encryptedOptions.encryptedDriveId,
            encryptionTier: encryptedOptions.encryptionTier
          }
        });

        try {
          // For large files (>5MB), use encrypted multipart upload
          if (file?.size > UPLOAD_CONFIG.chunkSize) {
            logger.debug('Using encrypted multipart upload for large file', {
              component: 'UploadOrchestrator',
              data: {
                fileName: file.name,
                fileSize: file.size,
                chunkSize: UPLOAD_CONFIG.chunkSize,
                encryptedDriveId: encryptedOptions.encryptedDriveId
              }
            });

            // Dynamically import the encrypted multipart service
            const { encryptedMultipartService } = await import('../../encrypted-drive/encrypted-multipart.service');

            // Check media type to use appropriate service
            const fileType = file.type.toLowerCase();
            const fileName = file.name.toLowerCase();
            const isImage = fileType.includes('image/') ||
              ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].some((ext: any) => fileName.endsWith(`.${ext}`));
            const isVideo = fileType.includes('video/') ||
              ['mp4', 'mov', 'avi', 'mkv', 'webm'].some((ext: any) => fileName.endsWith(`.${ext}`));

            // For media files, use the media upload service
            if (isImage || isVideo) {
              logger.debug('Using encrypted media upload service for media file', {
                component: 'UploadOrchestrator',
                data: {
                  fileName: file.name,
                  fileType: file.type,
                  isImage,
                  isVideo
                }
              });

              // Import media upload service
              const { default: encryptedMediaUploadService } = await import('../../encrypted-drive/encrypted-media-upload.service');

              // Process media using appropriate service
              const { mediaProcessingService } = await import('../../encrypted-drive/media/media-processing.service');
              
              logger.debug('Starting media processing in orchestrator', {
                component: 'UploadOrchestrator',
                data: {
                  fileName: file.name,
                  fileType: file.type,
                  fileSize: file.size
                }
              });
              
              // Process media
              try {
                const mediaResult = await mediaProcessingService.processMedia(file);
                
                // No media result means it's not a media file
                if (!mediaResult) {
                  logger.debug('File is not a supported media type, using standard upload', {
                    component: 'UploadOrchestrator',
                    data: {
                      fileName: file.name,
                      fileType: file.type
                    }
                  });
                  
                  return await encryptedMultipartService.uploadFile(
                    file,
                    {
                      driveId: encryptedOptions.encryptedDriveId,
                      parentId,
                      encryptionTier: encryptedOptions.encryptionTier as any,
                      onProgress
                    }
                  );
                }
                
                // If we have a media result, proceed with media upload
                logger.debug('Media processing successful, using media upload service', {
                  component: 'UploadOrchestrator',
                  data: {
                    fileName: file.name,
                    mediaType: mediaResult.type,
                    hasThumbnails: Object.keys(mediaResult.thumbnails).length > 0
                  }
                });
                
                return await encryptedMediaUploadService.uploadMediaFile(
                  file,
                  {
                    driveId: encryptedOptions.encryptedDriveId,
                    parentId,
                    encryptionTier: encryptedOptions.encryptionTier as any,
                    onProgress,
                    mediaInfo: mediaResult.mediaInfo,
                    // Pass the already processed media result to avoid duplicate processing
                    processedMedia: mediaResult
                  }
                );
              } catch (error) {
                // Log detailed error information
                logger.error('Media processing failed with critical error', {
                  component: 'UploadOrchestrator',
                  error,
                  data: { 
                    fileName: file.name,
                    fileType: file.type,
                    fileSize: file.size
                  }
                });
                
                // Return a clear error for the UI
                throw new Error(`Media processing failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
              }
            }

            // For non-media files, use regular encrypted multipart
            return await encryptedMultipartService.uploadFile(
              file,
              {
                driveId: encryptedOptions.encryptedDriveId,
                parentId,
                encryptionTier: encryptedOptions.encryptionTier as any,
                onProgress
              }
            );
          }

          // For smaller files, use regular encrypted upload
          return await uploadService.uploadFile(
            file,
            parentId,
            driveId,
            onProgress,
            encryptedOptions
          );
        } catch (error) {
          // Log error but don't fallback for encrypted uploads
          logger.error('Encrypted upload failed - security prevented fallback', {
            component: 'UploadOrchestrator',
            error,
            data: {
              fileName: file.name,
              encryptedDriveId: encryptedOptions.encryptedDriveId
            }
          });
          throw error; // Re-throw the error without fallback
        }
      }

      // For non-encrypted files, determine size-based strategy
      if (file?.size > UPLOAD_CONFIG.chunkSize) {
        logger.debug('Using multipart upload', {
          component: 'UploadOrchestrator',
          data: {
            fileName: file.name,
            size: file.size,
            chunkSize: UPLOAD_CONFIG.chunkSize
          }
        });
        return this.handleMultipartUpload(file, parentId, driveId, onProgress);
      }

      logger.debug('Using simple upload', {
        component: 'UploadOrchestrator',
        data: {
          fileName: file.name,
          size: file.size
        }
      });
      return this.handleSimpleUpload(file, parentId, driveId, onProgress);

    } catch (error) {
      // Check if this is an encrypted upload error
      if (error instanceof EncryptedUploadError ||
        (encryptedOptions?.isEncrypted && error instanceof Error)) {
        // For encrypted uploads, never fall back to simple upload
        logger.error('Encrypted upload failed with no fallback permitted', {
          component: 'UploadOrchestrator',
          error,
          data: {
            fileName: file.name,
            driveId,
            encryptedDriveId: encryptedOptions?.encryptedDriveId
          }
        });
        throw error;
      }

      // Only for regular uploads, try simple upload as fallback
      if (!encryptedOptions?.isEncrypted && error instanceof Error) {
        logger.debug('Attempting fallback to simple upload', {
          component: 'UploadOrchestrator',
          data: { fileName: file.name }
        });
        return this.handleSimpleUpload(file, parentId, driveId, onProgress);
      }

      // Otherwise re-throw the error
      logger.error('Upload process failed:', {
        component: 'UploadOrchestrator',
        error,
        data: {
          fileName: file.name,
          driveId
        }
      });

      // Try fallback if S3 upload fails
      if (error === 'S3_UPLOAD_FAILED') {
        logger.debug('Attempting fallback to server upload', {
          component: 'UploadOrchestrator'
        });
        return this.handleSimpleUpload(file, parentId, driveId, onProgress);
      }

      throw error;
    }
  }

  /**
   * Execute selected upload strategy
   */
  private async executeStrategy(
    strategy: UploadStrategy,
    file: File,
    parentId: string | null,
    driveId: string,
    onProgress?: (progress: UploadProgress) => void
  ): Promise<UploadResponse> {
    // Always use relay until S3 is implemented
    if (file?.size > UPLOAD_CONFIG.chunkSize) {
      return this.handleMultipartUpload(file, parentId, driveId, onProgress);
    }
    return this.handleSimpleUpload(file, parentId, driveId, onProgress);
  }



  /**
     * Handle S3 direct upload
     */
  // private async handleS3Upload(
  //     file: File,
  //     parentId: string | null,
  //     driveId: string,
  //     resumeState: S3UploadState | null,
  //     onProgress?: (progress: UploadProgress) => void
  //   ): Promise<UploadResponse> {
  //     try {
  //     // Initialize or resume S3 upload
  //     const uploadState = resumeState || 
  //       await secureS3UploadService.initializeUpload(file, driveId, parentId);

  //     // If direct upload not available, fallback to server relay
  //     if (uploadState?.error === 'DIRECT_UPLOAD_NOT_AVAILABLE') {
  //         logger.debug('Direct upload not available, falling back to server relay', {
  //           component: 'UploadOrchestrator',
  //           action: 'handleS3Upload',
  //           data: { fileName: file.name }
  //         });
  //         return this.handleSimpleUpload(file, parentId, driveId, onProgress);
  //       }

  //       // Track active upload
  //       const uploadFile: UploadFile = {
  //         id: uploadState.uploadId,
  //         file,
  //         parentId,
  //         driveId,
  //         status: 'uploading',
  //         progress: 0,
  //         bytesUploaded: uploadState.bytesUploaded,
  //         attempt: 0,
  //         strategy: 'direct_s3',
  //         startTime: Date.now(),
  //         resumable: true
  //       };

  //       this.activeUploads.set(uploadFile.id, uploadFile);
  //       resumeManager.saveResumeState(this.activeUploads);

  //       // Start or resume chunk upload
  //       await secureS3UploadService.uploadChunks(file, uploadState, (progress) => {
  //         if (onProgress) {
  //           onProgress(progress);
  //         }
  //         uploadFile.progress = (progress.loaded / progress.total) * 100;
  //         uploadFile.bytesUploaded = progress.loaded;
  //         resumeManager.saveResumeState(this.activeUploads);
  //       });

  //       // Upload completed successfully
  //       uploadFile.status = 'completed';
  //       uploadFile.progress = 100;
  //       uploadFile.endTime = Date.now();
  //       resumeManager.clearResumeState(uploadFile.id);

  //       return {
  //         id: uploadFile.id,
  //         name: file.name,
  //         size: file.size,
  //         path: uploadState.fileId,
  //         mimeType: file.type,
  //         strategy: 'direct_s3'
  //       };

  //     } catch (error) {
  //       logger.error('S3 upload failed:', {
  //         component: 'UploadOrchestrator',
  //         error,
  //         data: { fileName: file.name }
  //       });
  //       throw error;
  //     }
  //   }

  /**
   * Handle multipart upload through server
   */
  private async handleMultipartUpload(
    file: File,
    parentId: string | null,
    driveId: string,
    onProgress?: (progress: UploadProgress) => void
  ): Promise<UploadResponse> {
    try {
      logger.debug('Starting multipart upload', {
        component: 'UploadOrchestrator',
        action: 'handleMultipartUpload',
        data: {
          fileName: file.name,
          size: file.size,
          driveId
        }
      });

      // Initialize multipart upload
      const initResponse = await multipartUploadService.initializeUpload(
        file,
        parentId,
        driveId
      );

      if (!initResponse.upload_id) {
        throw new Error('Failed to get upload ID from initialization');
      }

      // Track upload state
      const uploadFile = {
        id: initResponse.upload_id,
        file,
        parentId,
        driveId,
        status: 'uploading',
        progress: 0,
        bytesUploaded: 0,
        startTime: Date.now(),
        strategy: UploadStrategy.MULTIPART_RELAY,
        retryCount: 0,
        isChunked: true,
        chunks: Array.from({ length: initResponse.total_chunks }, (_, i) => ({
          index: i,
          start: i * initResponse.chunk_size,
          end: Math.min((i + 1) * initResponse.chunk_size, file.size),
          uploaded: false,
          progress: 0
        }))
      } as UploadFile;

      this.activeUploads.set(uploadFile.id, uploadFile);
      resumeManager.saveResumeState(this.activeUploads);

      // Create the chunks array with the correct type
      const chunks: ChunkState[] = Array.from(
        { length: initResponse.total_chunks },
        (_, i) => ({
          index: i,
          start: i * initResponse.chunk_size,
          end: Math.min((i + 1) * initResponse.chunk_size, file.size),
          status: 'pending',          // default status
          retryCount: 0,              // initial retry count
          uploadId: initResponse.upload_id,  // or an empty string if not available
          uploaded: false,
          progress: 0
        })
      );

      // Upload chunks with progress tracking
      const uploadedChunks = await multipartUploadService.uploadChunks(
        file,
        initResponse.upload_id,
        driveId,
        chunks,
        (progress) => {
          if (onProgress) {
            onProgress(progress);
          }
          uploadFile.progress = (progress.loaded / progress.total) * 100;
          uploadFile.bytesUploaded = progress.loaded;
          // Update chunks uploaded count if currentChunk is available
          if (progress?.currentChunk !== undefined && uploadFile.chunks) {
            // Mark the chunk as uploaded
            if (uploadFile.chunks[progress?.currentChunk]) {
              uploadFile.chunks[progress.currentChunk].uploaded = true;
            }
          }
          resumeManager.saveResumeState(this.activeUploads);
        }
      );

      // Complete the upload
      const response = await multipartUploadService.completeUpload(
        initResponse.upload_id,
        driveId,
        uploadedChunks
      );

      // Update final state
      uploadFile.status = 'completed';
      uploadFile.progress = 100;
      uploadFile.endTime = Date.now();
      resumeManager.clearResumeState(uploadFile.id);

      return response;

    } catch (error) {
      logger.error('Multipart upload failed:', {
        component: 'UploadOrchestrator',
        error,
        data: { fileName: file.name }
      });
      throw error;
    }
  }


  /**
   * Handle simple upload through server
   */
  private async handleSimpleUpload(
    file: File,
    parentId: string | null,
    driveId: string,
    onProgress?: (progress: UploadProgress) => void
  ): Promise<UploadResponse> {
    try {
      logger.debug('Starting simple upload', {
        component: 'UploadOrchestrator',
        action: 'handleSimpleUpload',
        data: { fileName: file.name }
      });

      const uploadFile = {
        id: crypto.randomUUID(),
        file,
        parentId,
        driveId,
        status: 'uploading',
        progress: 0,
        bytesUploaded: 0,
        startTime: Date.now(),
        strategy: UploadStrategy.SERVER_RELAY,
        retryCount: 0
      } as UploadFile;

      this.activeUploads.set(uploadFile.id, uploadFile);

      const response = await uploadService.uploadFile(
        file,
        parentId,
        driveId,
        (progress) => {
          if (onProgress) {
            onProgress(progress);
          }
          uploadFile.progress = (progress.loaded / progress.total) * 100;
          uploadFile.bytesUploaded = progress.loaded;
        }
      );

      uploadFile.status = 'completed';
      uploadFile.progress = 100;
      uploadFile.endTime = Date.now();

      return response;
    } catch (error) {
      logger.error('Simple upload failed:', {
        component: 'UploadOrchestrator',
        error,
        data: { fileName: file.name }
      });
      throw error;
    }
  }

  /**
   * Cancel an active upload
   */
  async cancelUpload(uploadId: string, driveId: string): Promise<void> {
    try {
      logger.debug('Cancelling upload', {
        component: 'UploadOrchestrator',
        action: 'cancelUpload',
        data: { uploadId }
      });

      const upload = this.activeUploads.get(uploadId);
      if (!upload) {
        logger.warn('Upload not found for cancellation', {
          component: 'UploadOrchestrator',
          data: { uploadId }
        });
        return;
      }

      switch (upload.strategy) {
        case 'direct_s3':
          // S3 upload cancellation handled by service
          break;
        case 'multipart_relay':
          await multipartUploadService.abortUpload(uploadId, driveId);
          break;
      }

      upload.status = 'cancelled';
      resumeManager.clearResumeState(uploadId);
      this.activeUploads.delete(uploadId);

    } catch (error) {
      logger.error('Error cancelling upload:', {
        component: 'UploadOrchestrator',
        error,
        data: { uploadId }
      });
      throw error;
    }
  }


}

// Export singleton instance
export const uploadOrchestrator = new UploadOrchestrator();