/**
 * @file src/components/audit/filters/DeviceFilter.tsx
 * @description Custom device filter dropdown with centered menu and improved information display
 * @version 2.5.0
 * @created 2024-02-24
 * @updated 2025-02-26
 */

import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Search, ChevronDown, X } from "lucide-react";
import { cn } from "@/lib/utils";

// Updated device type based on the actual data structure
interface Device {
  id: string;
  device_name: string;
  device_state: string;
  type: string;
  sub_type?: string;
  city?: string;
  country?: string;
  [key: string]: any; // For other properties
}

interface DeviceFilterProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  devices: Device[];
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  selectedUser?: string;
}

export const DeviceFilter: React.FC<DeviceFilterProps> = ({
  value,
  onChange,
  devices = [],
  className,
  disabled = false,
  isLoading = false,
  selectedUser,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Find selected device
  const selectedDevice = devices.find((device) => device.id === value);

  // Filtered devices based on search
  const filteredDevices = React.useMemo(() => {
    if (!searchQuery) return devices;

    const query = searchQuery.toLowerCase();
    return devices.filter((device) => {
      const deviceName = (device.device_name || "").toLowerCase();
      const deviceType = (device.type || "").toLowerCase();
      const subType = (device.sub_type || "").toLowerCase();
      const location = [device.city, device.country]
        .filter(Boolean)
        .join(" ")
        .toLowerCase();

      return (
        deviceName.includes(query) ||
        deviceType.includes(query) ||
        subType.includes(query) ||
        location.includes(query)
      );
    });
  }, [devices, searchQuery]);

  // Handle selection
  const handleSelectDevice = (deviceId: string) => {
    console.log("Device selected:", deviceId);
    onChange(deviceId);
    setIsOpen(false);
    setSearchQuery("");
  };

  // Clear selection
  const handleClearSelection = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(undefined);
  };

  // Toggle dropdown
  const toggleDropdown = () => {
    if (!disabled && !isLoading && selectedUser) {
      setIsOpen(!isOpen);
      if (!isOpen) {
        setSearchQuery("");
      }
    }
  };

  // Get placeholder text
  const getPlaceholderText = () => {
    if (isLoading)
      return t("audit.filters.loadingDevices", "Loading devices...");
    if (!selectedUser)
      return t("audit.filters.selectUserFirst", "Select user first");
    return t("audit.filters.selectDevice", "Select device");
  };

  // Format device status
  const formatStatus = (status: string) => {
    return status === "ONLINE" ? "Online" : "Offline";
  };

  // Get device type and subtype formatted
  const getDeviceTypeDisplay = (device: Device) => {
    const type = device.type || "";
    const subType = device.sub_type ? `(${device.sub_type})` : "";
    return `${type} ${subType}`.trim();
  };

  // Get location formatted
  const getLocationDisplay = (device: Device) => {
    return [device.city, device.country].filter(Boolean).join(", ");
  };

  // Position the dropdown in the center
  useEffect(() => {
    if (isOpen && dropdownRef.current && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const buttonCenter = buttonRect.left + buttonRect.width / 2;
      const dropdownWidth = 400; // Width of dropdown

      // Calculate left position to center the dropdown
      const leftPosition = buttonCenter - dropdownWidth / 2;

      // Adjust if too far left or right
      const windowWidth = window.innerWidth;
      const minLeft = 10; // Minimum distance from left edge
      const maxLeft = windowWidth - dropdownWidth - 10; // Maximum left position

      const adjustedLeft = Math.max(minLeft, Math.min(maxLeft, leftPosition));

      dropdownRef.current.style.left = `${adjustedLeft}px`;
      dropdownRef.current.style.position = "fixed";
      dropdownRef.current.style.top = `${
        buttonRect.bottom + window.scrollY + 5
      }px`;
    }
  }, [isOpen]);

  // Close when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Calculate item height for a more comfortable display
  const itemHeight = 85; // Approximate height of each item
  const listHeight = itemHeight * 2.5; // Show 2.5 items at once

  return (
    <div className="w-full max-w-xs">
      {/* Dropdown button */}
      <button
        ref={buttonRef}
        type="button"
        className={cn(
          "flex items-center justify-between",
          "w-full h-9 px-3",
          "bg-white dark:bg-gray-900",
          "border border-gray-200 dark:border-gray-800",
          "rounded-md text-left",
          (!selectedUser || disabled || isLoading) &&
            "opacity-50 cursor-not-allowed",
          className
        )}
        onClick={toggleDropdown}
        disabled={!selectedUser || disabled || isLoading}
      >
        <span className="block truncate flex-1 pr-2 text-sm">
          {selectedDevice ? (
            selectedDevice.device_name || "Unnamed Device"
          ) : (
            <span className="text-gray-500">{getPlaceholderText()}</span>
          )}
        </span>
        <div className="flex items-center space-x-1">
          {selectedDevice && (
            <button
              type="button"
              onClick={handleClearSelection}
              className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 p-1"
              aria-label="Clear selection"
            >
              <X size={14} />
            </button>
          )}
          <ChevronDown className="h-4 w-4 text-gray-500 flex-shrink-0" />
        </div>
      </button>

      {/* Dropdown menu - positioned with fixed positioning */}
      {isOpen && (
        <div
          ref={dropdownRef}
          className="z-50 rounded-md shadow-lg bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-800"
          style={{
            width: "400px",
            maxWidth: "90vw",
          }}
        >
          {/* Search input */}
          <div className="px-3 py-2 border-b border-gray-100 dark:border-gray-800">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500 dark:text-gray-400" />
              <input
                type="text"
                placeholder={t(
                  "audit.filters.searchDevices",
                  "Search devices..."
                )}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={cn(
                  "w-full py-2 pl-8 pr-3",
                  "text-sm text-gray-900 dark:text-gray-100",
                  "bg-gray-50 dark:bg-gray-800",
                  "border border-gray-200 dark:border-gray-700",
                  "rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                )}
                autoFocus
              />
            </div>
          </div>

          {/* Device list */}
          <div
            className="overflow-y-auto"
            style={{
              height: `${listHeight}px`,
            }}
          >
            {filteredDevices.map((device) => (
              <div
                key={device.id}
                className={cn(
                  "px-4 py-3 cursor-pointer",
                  "border-b border-gray-100 dark:border-gray-800 last:border-0",
                  "hover:bg-gray-100 dark:hover:bg-gray-800",
                  value === device.id && "bg-blue-50 dark:bg-blue-900/20"
                )}
                onClick={() => handleSelectDevice(device.id)}
                style={{
                  minHeight: `${itemHeight - 15}px`,
                }}
              >
                <div className="flex flex-col">
                  {/* Device full name (target) - more prominent and fully displayed */}
                  <div className="font-medium text-sm mb-1">
                    {device.device_name || "Unnamed Device"}
                  </div>

                  {/* Device status and type */}
                  <div className="flex items-center mt-2">
                    <div className="flex items-center mr-3">
                      <span
                        className={cn(
                          "inline-block w-2.5 h-2.5 rounded-full mr-1.5",
                          device.device_state === "ONLINE"
                            ? "bg-green-500"
                            : "bg-gray-400"
                        )}
                      />
                      <span className="text-xs font-medium">
                        {formatStatus(device.device_state)}
                      </span>
                    </div>

                    <div className="text-xs text-gray-600 dark:text-gray-300">
                      {getDeviceTypeDisplay(device)}
                    </div>
                  </div>

                  {/* Location */}
                  <div className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                    {getLocationDisplay(device) || "Unknown location"}
                  </div>
                </div>
              </div>
            ))}

            {filteredDevices.length === 0 && (
              <div className="px-3 py-6 text-center text-sm text-gray-500 dark:text-gray-400">
                {searchQuery
                  ? t("audit.filters.noDevicesFound", "No devices found")
                  : t("audit.filters.noDevices", "No devices available")}
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="px-3 py-2 border-t border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-gray-800 text-xs text-center text-gray-500 dark:text-gray-400">
            {filteredDevices.length}{" "}
            {t("audit.filters.devicesFound", "devices found")}
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceFilter;
