/**
 * @file src/services/language.service.ts
 * @description Language service for handling language changes
 * @version 1.0.0
 * @created 2024-12-05
 */

import i18n from '../i18n/config';

export interface Language {
  code: string;
  name: string;
  nativeName: string;
}

export const languages: Language[] = [
  { code: 'en', name: 'English', nativeName: 'English' },
  { code: 'ja', name: 'Japanese', nativeName: '日本語' },
  { code: 'ko', name: 'Korean', nativeName: '한국어' },
  { code: 'it', name: 'Italian', nativeName: 'Italiano' }
];

export const languageService = {
  getCurrentLanguage: (): Language => {
    const code = i18n.language || 'en';
    return languages.find((lang: any) => lang.code === code) || languages[0];
  },

  setLanguage: async (code: string) => {
    try {
      await i18n.changeLanguage(code);
      localStorage.setItem('bigmind_language', code);
      return true;
    } catch (error) {
      console.error('Error changing language:', error);
      return false;
    }
  },

  getLanguages: () => languages
};