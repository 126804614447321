/**
 * @file src/components/ghost/common/GhostGradients.tsx
 * @description Enhanced gradient components using new theme system
 * @version 1.1.0
 */

import React from 'react';
import { motion, HTMLMotionProps } from 'framer-motion';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';

interface GhostGradientProps extends Omit<HTMLMotionProps<"div">, "animate"> {
  variant?: "primary" | "secondary" | "accent" | "purple";
  intensity?: "low" | "medium" | "high";
  animate?: boolean;
  hover?: boolean;
}

export const GhostGradient: React.FC<GhostGradientProps> = ({
  variant = "primary",
  intensity = "medium",
  animate = false,
  hover = false,
  className,
  ...props
}) => {
  // logger.debug('Rendering GhostGradient', {
  //   variant,
  //   intensity,
  //   animate,
  //   hover
  // });

  const baseGradients = {
    primary:
      "from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-tertiary))]",
    secondary:
      "from-[hsl(var(--ghost-accent-secondary))] to-[hsl(var(--ghost-accent))]",
    accent:
      "from-[hsl(var(--ghost-accent-tertiary))] to-[hsl(var(--ghost-accent-secondary))]",
    purple:
      "from-[hsl(var(--ghost-accent-secondary))] via-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-tertiary))]",
  };

  const intensityClasses = {
    low: "opacity-10",
    medium: "opacity-20",
    high: "opacity-30",
  };

  if (animate) {
    const animationProps = {
      animate: {
        opacity: [parseInt(intensityClasses[intensity].split('-')[1]) / 100, 
                  parseInt(intensityClasses[intensity].split('-')[1]) / 100 + 0.1],
        scale: [1, 1.05],
      },
      transition: {
        duration: 3,
        repeat: Infinity,
        repeatType: "reverse" as const,
        ease: "easeInOut"
      }
    };

    return (
      <motion.div
        className={cn(
          'absolute inset-0',
          'bg-gradient-to-r',
          baseGradients[variant],
          intensityClasses[intensity],
          hover && 'transition-opacity duration-300 group-hover:opacity-40',
          className
        )}
        {...animationProps}
        {...(props as HTMLMotionProps<"div">)}
      />
    );
  }

  return (
    <div
      className={cn(
        "absolute inset-0",
        "bg-gradient-to-r",
        baseGradients[variant],
        intensityClasses[intensity],
        hover && "transition-opacity duration-300 group-hover:opacity-40",
        className
      )}
      {...(props as React.HTMLAttributes<HTMLDivElement>)}
    />
  );
};

interface GhostGlowProps extends Omit<HTMLMotionProps<"div">, "animate"> {
  variant?: "primary" | "secondary" | "accent" | "purple";
  size?: "sm" | "md" | "lg";
  position?: "top" | "bottom" | "left" | "right" | "center";
  intensity?: "low" | "medium" | "high";
}
export const GhostGlow: React.FC<GhostGlowProps> = ({
  variant = 'primary',
  size = 'md',
  position = 'center',
  intensity = 'medium',
  className,
  ...props
}) => {
  // logger.debug('Rendering GhostGlow', {
  //   variant,
  //   size,
  //   position,
  //   intensity
  // });

  const variantClasses = {
    primary: 'bg-[hsl(var(--ghost-accent))]',
    secondary: 'bg-[hsl(var(--ghost-accent-secondary))]',
    accent: 'bg-[hsl(var(--ghost-accent-tertiary))]',
    purple: 'bg-[hsl(var(--ghost-accent-secondary))]'
  };

  const sizeClasses = {
    sm: 'w-32 h-32',
    md: 'w-48 h-48',
    lg: 'w-64 h-64'
  };

  const positionClasses = {
    top: '-top-24',
    bottom: '-bottom-24',
    left: '-left-24',
    right: '-right-24',
    center: 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
  };

  const intensityClasses = {
    low: 'opacity-10',
    medium: 'opacity-20',
    high: 'opacity-30'
  };

  return (
    <motion.div
      className={cn(
        'absolute rounded-full blur-3xl',
        variantClasses[variant],
        sizeClasses[size],
        positionClasses[position],
        intensityClasses[intensity],
        className
      )}
      animate={{
        scale: [1, 1.2, 1],
        opacity: [
          parseInt(intensityClasses[intensity].split('-')[1]) / 100,
          parseInt(intensityClasses[intensity].split('-')[1]) / 100 * 1.5,
          parseInt(intensityClasses[intensity].split('-')[1]) / 100
        ],
      }}
      transition={{
        duration: 5,
        repeat: Infinity,
        repeatType: "reverse" as const,
        ease: "easeInOut"
      }}
      {...(props as HTMLMotionProps<"div">)}
    />
  );
};

export const GhostGradientText: React.FC<{
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'accent' | 'purple';
  animate?: boolean;
  className?: string;
}> = ({
  children,
  variant = 'primary',
  animate = false,
  className
}) => {
  // logger.debug('Rendering GhostGradientText', {
  //   variant,
  //   animate
  // });

  const gradientClasses = {
    primary: 'from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-tertiary))]',
    secondary: 'from-[hsl(var(--ghost-accent-secondary))] to-[hsl(var(--ghost-accent))]',
    accent: 'from-[hsl(var(--ghost-accent-tertiary))] to-[hsl(var(--ghost-accent-secondary))]',
    purple: 'from-[hsl(var(--ghost-accent-secondary))] via-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-tertiary))]'
  };

  if (animate) {
    const animateProps = {
      animate: {
        backgroundPosition: ['0% center', '100% center'],
      },
      transition: {
        duration: 3,
        repeat: Infinity,
        repeatType: 'reverse' as const,
        ease: 'linear',
      },
      style: {
        backgroundSize: '200% auto',
      },
    };

    return (
      <motion.span
        className={cn(
          'bg-gradient-to-r',
          gradientClasses[variant],
          'bg-clip-text text-transparent',
          animate && 'inline-block',
          className
        )}
        {...animateProps}
      >
        {children}
      </motion.span>
    );
  }

  return (
    <span
      className={cn(
        'bg-gradient-to-r',
        gradientClasses[variant],
        'bg-clip-text text-transparent',
        animate && 'inline-block',
        className
      )}
    >
      {children}
    </span>
  );
};

// Export a composition helper
export const GhostGradientBox: React.FC<{
  children: React.ReactNode;
  className?: string;
  glowVariant?: GhostGlowProps['variant'];
  gradientVariant?: GhostGradientProps['variant'];
}> = ({
  children,
  className,
  glowVariant = 'primary',
  gradientVariant = 'primary'
}) => {
  return (
    <div className={cn('relative group overflow-hidden rounded-2xl', className)}>
      <GhostGlow
        variant={glowVariant}
        position="center"
        size="lg"
        intensity="low"
      />
      <GhostGradient
        variant={gradientVariant}
        intensity="low"
        hover
      />
      {children}
    </div>
  );
};