/**
 * @file src/layouts/ghost/components/GhostBottomNav.tsx
 * @description Enhanced mobile bottom navigation for Ghost interface with corrected security hub links
 * @version 2.2.0
 * @updated 2025-03-30
 */

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Home, Lock, ShieldCheck, Users } from 'lucide-react';
import { GhostGlass } from '@/app/ghost/components/common/GhostGlass';
import { cn } from '@/utils/utils';
import { logger } from '@/utils/logger';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';

export const GhostBottomNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useGhostTranslation();

  // Updated navigation items with correct path for Security Hub
  const navItems = [
    { 
      icon: Home, 
      label: t('navigation.items.home') as React.ReactNode, 
      path: '/ghost',
    },
    { 
      icon: Lock, 
      label: t('navigation.items.cores') as React.ReactNode, 
      path: '/ghost/drives',
      badge: 3
    },
    { 
      icon: ShieldCheck, 
      label: t('navigation.items.security') as React.ReactNode, 
      path: '/ghost/quantum', // Fixed: Now links to /ghost/quantum to match desktop sidebar
    },
    { 
      icon: Users, 
      label: t('navigation.items.shared') as React.ReactNode, 
      path: '/ghost/shared-with-me',
    }
  ];

  logger.debug('Rendering GhostBottomNav', {
    component: 'GhostBottomNav',
    currentPath: location.pathname
  });

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <motion.div 
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      className="fixed bottom-0 left-0 right-0 z-40"
    >
      <GhostGlass
        className="w-full border-t border-[hsl(var(--ghost-accent))/0.1]"
        opacity="low"
        animate
      >
        <nav className="h-16 px-2 grid grid-cols-4">
          {navItems.map(({ icon: Icon, label, path, badge }) => {
            // Determine if path is active - improved matching for nested routes
            const isActive = path === '/ghost' 
              ? location.pathname === '/ghost' 
              : location.pathname.startsWith(path);

            return (
              <motion.button
                key={path}
                onClick={() => handleNavigation(path)}
                whileTap={{ scale: 0.95 }}
                className={cn(
                  "relative flex flex-col items-center justify-center",
                  "gap-1",
                  "transition-all duration-200"
                )}
              >
                <div className={cn(
                  "w-6 h-6 flex items-center justify-center relative",
                  isActive 
                    ? "text-[hsl(var(--ghost-accent))]" 
                    : "text-[hsl(var(--ghost-foreground-muted))]"
                )}>
                  <Icon className="w-5 h-5" />
                  {badge && (
                    <span className={cn(
                      "absolute -top-1.5 -right-1.5",
                      "min-w-[16px] h-4 px-1",
                      "rounded-full flex items-center justify-center",
                      "bg-[hsl(var(--ghost-accent))]",
                      "text-[10px] font-medium text-white"
                    )}>
                      {badge > 99 ? '99+' : badge}
                    </span>
                  )}
                </div>

                <span className={cn(
                  "text-xs font-medium",
                  isActive 
                    ? "text-[hsl(var(--ghost-accent))]" 
                    : "text-[hsl(var(--ghost-foreground-muted))]"
                )}>
                  {label}
                </span>

                {isActive && (
                  <motion.div
                    layoutId="activeTab"
                    className="absolute -top-px left-1/2 w-12 h-0.5"
                    style={{
                      background: `linear-gradient(to right, 
                        hsl(var(--ghost-accent)), 
                        hsl(var(--ghost-accent-secondary))
                      )`
                    }}
                    initial={{ x: "-50%" }}
                    animate={{ x: "-50%" }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  />
                )}
              </motion.button>
            );
          })}
        </nav>
      </GhostGlass>
    </motion.div>
  );
};

export default GhostBottomNav;