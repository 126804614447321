/**
 * @file src/hooks/ghost/useGhostTranslation.ts
 * @description Ghost translation hook using namespace fallback with proper variable interpolation
 * @version 3.1.0
 */

import { useTranslation } from 'react-i18next';
import { useCallback, useRef, useEffect } from 'react';
import i18n from '@/i18n/config';
import { logger } from '@/utils/logger';

// Track global initialization
let isGhostI18nInitialized = false;

export const useGhostTranslation = () => {
  const initRef = useRef(false);
  
  // Use the 'ghost' namespace with fallback to 'default'
  const { t: baseT, i18n: i18nInstance } = useTranslation('ghost', {
    returnObjects: true     // Allow accessing object properties in translations
  });

  // Log initialization only once per component instance
  useEffect(() => {
    if (!initRef.current) {
      if (!isGhostI18nInitialized) {
        logger.debug('Ghost translation system initialized (using namespace fallback)');
        isGhostI18nInitialized = true;
      }
      initRef.current = true;
    }
  }, []);

  // Wrap the translation function to handle errors when accessing objects
  // Fixed to properly forward interpolation variables and improve type safety
  const t = useCallback((key: string, options?: any): string => {
    try {
      // If options is a string, treat it as fallback
      if (typeof options === 'string') {
        const result = baseT(key, { returnObjects: true, defaultValue: options });
        return typeof result === 'string' ? result : options;
      }
      
      // Otherwise, forward all options including interpolation variables
      const result = baseT(key, {
        ...options,
        returnObjects: true
      });
      
      // Ensure we return a string, even if the translation is an object
      return typeof result === 'string' ? result : 
             (typeof options === 'string' ? options : key);
    } catch (error) {
      logger.warn(`Translation error for key: ${key}`, { error });
      return typeof options === 'string' ? options : key;
    }
  }, [baseT]);

  return { t, i18n: i18nInstance };
};