/**
 * @file src/components/cloud-drive/info/InfoPanel.tsx
 * @description Enhanced info panel with better UI, proper positioning, encryption support and error handling
 * @version 4.2.0
 * @updated 2025-03-27
 * @notes Now designed to work inside InfoPanelContainer instead of Sheet component
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { 
  Tag as TagIcon,
  Clock,
  HardDrive,
  File as FileIcon,
  Shield,
  Download,
  Share2,
  AlertCircle,
  Star,
  Info,
  Calendar,
  Users,
  LucideIcon
} from 'lucide-react';

import { cn } from '@/utils/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

// Import the InfoPanelSkeleton component from its dedicated file
import { InfoPanelSkeleton } from './InfoPanelSkeleton';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useItemDetails } from '@/hooks/cloud-drive/useItemDetails';
import { useCloudDrive } from '@/context/cloud-drive'; 
import { useDriveOperations } from '@/context/cloud-drive/hooks/useDriveOperations';
import { logger } from '@/utils/logger';
import type { DriveItem } from '@/types/cloud-drive.types';

interface InfoPanelProps {
  className?: string;
}

/**
 * Empty state shown when no item is selected
 */
export const InfoPanelEmptyState: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <div className="flex flex-col items-center justify-center h-full p-6 text-center">
      <div className="w-12 h-12 rounded-full bg-gray-100 dark:bg-gray-800 flex items-center justify-center mb-4">
        <Info className="h-6 w-6 text-gray-400" />
      </div>
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
        {t('cloud_drive.info.no_selection')}
      </h3>
      <p className="text-sm text-gray-500 dark:text-gray-400 max-w-[200px]">
        {t('cloud_drive.info.select_to_view')}
      </p>
    </div>
  );
};

/**
 * Error state component
 */
interface ErrorStateProps {
  error: Error;
  onRetry: () => void;
}

export const ErrorState: React.FC<ErrorStateProps> = ({ error, onRetry }) => {
  const { t } = useTranslation();
  
  // Check if this is a "Not Found" error for an encrypted item
  const isEncryptedNotFoundError = 
    error.message === 'Not Found' || 
    error.message.includes('404');
  
  return (
    <div className="flex flex-col items-center justify-center h-full p-6 text-center">
      <div className={cn(
        "w-12 h-12 rounded-full flex items-center justify-center mb-4",
        isEncryptedNotFoundError 
          ? "bg-blue-100 dark:bg-blue-900/20" 
          : "bg-red-100 dark:bg-red-900/20"
      )}>
        <AlertCircle className={cn(
          "h-6 w-6", 
          isEncryptedNotFoundError 
            ? "text-blue-500 dark:text-blue-400" 
            : "text-red-500 dark:text-red-400"
        )} />
      </div>
      <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
        {isEncryptedNotFoundError 
          ? t('encrypted_drive.encrypted_access_error') || "Encrypted Item Access Error"
          : t('cloud_drive.info.error')}
      </h3>
      <p className="text-sm text-gray-500 dark:text-gray-400 max-w-[250px] mb-4">
        {isEncryptedNotFoundError 
          ? t('encrypted_drive.item_needs_decryption') || "The item details need to be decrypted. Try reloading or viewing the item in its parent folder."
          : (error.message || t('cloud_drive.info.error_details'))}
      </p>
      <div className="flex gap-2">
        <Button variant="outline" size="sm" onClick={onRetry}>
          {t('common.retry')}
        </Button>
        {isEncryptedNotFoundError && (
          <Button 
            variant="default" 
            size="sm" 
            onClick={() => {
              try {
                // Clear specific localStorage items that might be causing problems
                localStorage.removeItem('encrypted_item_view_context');
                // Then retry
                onRetry();
              } catch (e) {
                console.warn('Error during clear and retry', e);
                onRetry();
              }
            }}
          >
            {t('encrypted_drive.reset_encryption') || "Reset Encryption"}
          </Button>
        )}
      </div>
    </div>
  );
};

/**
 * Detail Item component for consistency
 */
interface DetailItemProps {
  icon: LucideIcon;
  label: string;
  value: React.ReactNode;
  action?: React.ReactNode;
}

const DetailItem: React.FC<DetailItemProps> = ({ icon: Icon, label, value, action }) => {
  return (
    <div className="flex items-start justify-between py-2">
      <div className="flex items-start gap-2">
        <Icon className="h-4 w-4 text-gray-500 dark:text-gray-400 mt-0.5" />
        <div>
          <p className="text-xs text-gray-500 dark:text-gray-400">{label}</p>
          <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
            {value || '-'}
          </p>
        </div>
      </div>
      {action}
    </div>
  );
};

/**
 * Main InfoPanel component
 */
export const InfoPanel: React.FC<InfoPanelProps> = ({ className }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('details');
  
  // Check if we're on mobile with error handling
  let isMobile = false;
  try {
    // Only use the hook in browser environment
    if (typeof window !== 'undefined') {
      isMobile = useMediaQuery('(max-width: 768px)');
    }
  } catch (error) {
    console.warn('Error using useMediaQuery in InfoPanel', error);
  }
  
  // Get context and hooks
  const { 
    selectedItemId, 
    selectedItem, 
    encryptedDrive
  } = useCloudDrive();
  
  const { 
    downloadItem, 
    toggleFavorite
  } = useDriveOperations();
  
  // Check if we have an explicit encryption context stored for this item view
  const [localEncryptionContext, setLocalEncryptionContext] = useState<{
    isEncryptedMode: boolean;
    encryptedDriveId?: string;
    itemId: string;
  } | null>(null);
  
  // On mount, check localStorage for the encryption context
  useEffect(() => {
    try {
      const storedContext = localStorage.getItem('encrypted_item_view_context');
      if (storedContext) {
        const parsedContext = JSON.parse(storedContext);
        // Only use it if it's for the current item
        if (parsedContext.itemId === selectedItemId) {
          setLocalEncryptionContext(parsedContext);
          logger.debug('Retrieved stored encryption context for item', {
            component: 'InfoPanel',
            data: parsedContext
          });
        }
      }
    } catch (e) {
      logger.warn('Error retrieving stored encryption context', { 
        component: 'InfoPanel',
        error: e 
      });
    }
  }, [selectedItemId]);

  // Fetch detailed item info with explicit encrypted context
  // Use the local encryption context if available, otherwise use the context from the hook
  const contextToUse = localEncryptionContext 
    ? {
        encryptedMode: localEncryptionContext.isEncryptedMode,
        encryptedDriveId: localEncryptionContext.encryptedDriveId
      }
    : {
        encryptedMode: encryptedDrive?.isEncryptedMode || false,
        encryptedDriveId: encryptedDrive?.encryptedDriveId
      };
        
  const { 
    data: details,
    isLoading,
    isError,
    error,
    refetch 
  } = useItemDetails(selectedItemId, {
    enabled: !!selectedItemId,
    // Pass explicit context to ensure consistency
    context: contextToUse
  });

  // Debug logging
  useEffect(() => {
    logger.debug('InfoPanel render state', {
      component: 'InfoPanel',
      data: {
        selectedItemId,
        hasItem: !!selectedItem,
        detailsLoaded: !!details,
        isLoading,
        isError: !!error,
        activeTab,
        isEncryptedMode: encryptedDrive?.isEncryptedMode
      }
    });
  }, [selectedItemId, selectedItem, details, isLoading, error, activeTab, encryptedDrive]);

  // Action handlers
  const handleDownload = () => {
    if (!selectedItem) return;
    downloadItem(selectedItem.id, selectedItem.name);
  };
  
  const handleToggleFavorite = useCallback(async () => {
    if (!selectedItem) return;
    
    logger.debug('Toggling favorite in InfoPanel', {
      component: 'InfoPanel',
      data: { 
        itemId: selectedItem.id, 
        itemName: selectedItem.name,
        currentFavoriteState: selectedItem.favorite,
        isEncryptedMode: encryptedDrive?.isEncryptedMode
      }
    });
    
    try {
      await toggleFavorite(selectedItem.id, selectedItem);
      
      // Force state update in UI since context updates may not be reflected immediately
      if (window.queryClient) {
        // Invalidate related queries to ensure UI updates properly
        window.queryClient.invalidateQueries({ 
          queryKey: ['item-details', selectedItem.id]
        });
        
        // Force refetch item details
        setTimeout(() => refetch(), 100);
      }
      
    } catch (error) {
      logger.error('Error toggling favorite in InfoPanel', {
        component: 'InfoPanel',
        error,
        data: { itemId: selectedItem.id }
      });
    }
  }, [selectedItem, toggleFavorite, encryptedDrive?.isEncryptedMode, refetch]);
  
  // Log more details when selected item changes
  useEffect(() => {
    if (selectedItem) {
      logger.debug('Selected item available in InfoPanel', {
        component: 'InfoPanel',
        data: { 
          itemId: selectedItem.id,
          itemName: selectedItem.name,
          detailsLoading: isLoading,
          detailsError: isError,
          encryptedMode: encryptedDrive?.isEncryptedMode,
          encryptedDriveId: encryptedDrive?.encryptedDriveId
        }
      });
    }
  }, [selectedItem, isLoading, isError, encryptedDrive]);

  // If no item selected, show empty state
  if (!selectedItem || !selectedItemId) {
    logger.debug('No item selected in InfoPanel', {
      component: 'InfoPanel',
      data: { selectedItemId }
    });
    return <InfoPanelEmptyState />;
  }

  // If error occurred, show error state
  if (isError) {
    logger.debug('Error loading item details in InfoPanel', {
      component: 'InfoPanel',
      data: { 
        itemId: selectedItemId,
        error: error instanceof Error ? error.message : 'Unknown error',
        isEncryptedMode: encryptedDrive?.isEncryptedMode,
        encryptedDriveId: encryptedDrive?.encryptedDriveId,
        contextToUse
      }
    });
    
    // Attempt to automatically recover by checking localStorage again
    useEffect(() => {
      if (isError) {
        try {
          const storedContext = localStorage.getItem('encrypted_item_view_context');
          if (storedContext) {
            const parsedContext = JSON.parse(storedContext);
            if (parsedContext.itemId === selectedItemId && 
                (parsedContext.isEncryptedMode !== contextToUse.encryptedMode ||
                 parsedContext.encryptedDriveId !== contextToUse.encryptedDriveId)) {
              
              logger.debug('Found different context in localStorage, retrying fetch', {
                component: 'InfoPanel',
                data: {
                  storedContext: parsedContext,
                  currentContext: contextToUse
                }
              });
              
              // Context mismatch detected - retry with updated context
              setTimeout(() => refetch(), 300);
            }
          }
        } catch (e) {
          logger.warn('Error during auto-recovery attempt', {
            component: 'InfoPanel',
            error: e
          });
        }
      }
    }, [isError, selectedItemId]);
    
    return (
      <ErrorState 
        error={error instanceof Error ? error : new Error('An unknown error occurred')} 
        onRetry={() => {
          logger.debug('Manually retrying item details fetch', {
            component: 'InfoPanel',
            data: { 
              itemId: selectedItemId,
              encryptedMode: contextToUse.encryptedMode,
              encryptedDriveId: contextToUse.encryptedDriveId
            }
          });
          
          // Clear any cached query data for this item
          // This helps refresh stale data that might be causing issues
          window.queryClient?.removeQueries({ 
            queryKey: ['item-details', selectedItemId]
          });
          
          // Then refetch with fresh state
          refetch();
        }}
      />
    );
  }

  // If loading, show skeleton
  if (isLoading) {
    return <InfoPanelSkeleton />;
  }

  // Format dates consistently
  const formatDate = (dateString?: string) => {
    if (!dateString) return '-';
    try {
      return format(new Date(dateString), 'PPp');
    } catch (e) {
      return dateString;
    }
  };

  // Helper to format size
  const formatSize = (bytes?: number) => {
    if (bytes === undefined || bytes === null) return '-';
    
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let size = bytes;
    let unitIndex = 0;
    
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
    
    return `${size.toFixed(1)} ${units[unitIndex]}`;
  };

  return (
    <div className={cn(
      "p-6", 
      isMobile && "px-4 py-5", // Less padding on mobile
      className
    )}>
      {/* Header with file/folder info */}
      <div className={cn(
        "mb-4",
        isMobile && "mb-3"
      )}>
        <h3 className={cn(
          "text-lg font-medium text-gray-900 dark:text-gray-100 mb-1 truncate",
          isMobile ? "max-w-full text-base" : "max-w-[240px]"
        )}>
          {selectedItem.name}
        </h3>
        {/* Fix p tag containing div (Badge) issue by using div instead of p */}
        <div className="text-sm text-gray-500 dark:text-gray-400 flex items-center">
          {selectedItem.type === 'folder' ? (
            <>
              <HardDrive className="h-3.5 w-3.5 mr-1.5" />
              <span>{t('cloud_drive.info.type.folder')}</span>
            </>
          ) : (
            <>
              <FileIcon className="h-3.5 w-3.5 mr-1.5" />
              <span>{t('cloud_drive.info.type.file')}</span>
            </>
          )}
          
          {/* Show encryption indicator for encrypted files */}
          {encryptedDrive?.isEncryptedMode && (
            <Badge variant="outline" className="ml-2 px-1.5 py-0 border-blue-200 dark:border-blue-900">
              <Shield className="h-3 w-3 text-blue-500 dark:text-blue-400 mr-1" />
              <span className="text-xs text-blue-600 dark:text-blue-400">
                {t('encrypted_drive.encrypted')}
              </span>
            </Badge>
          )}
        </div>
      </div>
      
      {/* Quick Actions - responsive for mobile */}
      <div className={cn(
        "flex flex-wrap gap-2 mb-6",
        isMobile && "grid grid-cols-2 gap-2 mb-4" // Grid for mobile
      )}>
        {selectedItem.type === 'file' && (
          <Button 
            variant="outline" 
            size={isMobile ? "default" : "sm"}
            onClick={handleDownload} 
            className={cn(
              "flex items-center justify-center gap-1.5",
              isMobile && "w-full" // Full width on mobile
            )}
          >
            <Download className="h-3.5 w-3.5" />
            <span className={isMobile ? "text-sm" : ""}>
              {t('cloud_drive.actions.download')}
            </span>
          </Button>
        )}
        
        <Button 
          variant={selectedItem.favorite ? "default" : "outline"} 
          size={isMobile ? "default" : "sm"}
          onClick={handleToggleFavorite} 
          className={cn(
            "flex items-center justify-center gap-1.5",
            selectedItem.favorite && "bg-amber-500 hover:bg-amber-600 text-white border-amber-500",
            isMobile && "w-full" // Full width on mobile
          )}
        >
          <Star className="h-3.5 w-3.5" />
          <span className={isMobile ? "text-sm" : ""}>
            {selectedItem.favorite 
              ? t('cloud_drive.actions.remove_favorite') 
              : t('cloud_drive.actions.add_favorite')}
          </span>
        </Button>
      </div>
      
      <Separator className={cn("mb-6", isMobile && "mb-4")} />
      
      {/* Tabs for different sections */}
      <Tabs defaultValue="details" value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="mb-4 grid grid-cols-2 h-9 w-full">
          <TabsTrigger value="details" className="text-sm">
            {t('cloud_drive.info.tabs.details')}
          </TabsTrigger>
          <TabsTrigger value="activity" className="text-sm">
            {t('cloud_drive.info.tabs.activity')}
          </TabsTrigger>
        </TabsList>
        
        {/* Details Tab */}
        <TabsContent value="details" className="space-y-6">
          {/* Basic Details */}
          <Card>
            <CardHeader className="pb-3">
              <CardTitle className="text-sm font-medium">
                {t('cloud_drive.info.basic_details')}
              </CardTitle>
            </CardHeader>
            <CardContent className="text-sm space-y-0.5">
              {/* File Type (for files only) */}
              {selectedItem.type === 'file' && (
                <DetailItem 
                  icon={FileIcon}
                  label={t('cloud_drive.info.file_type')}
                  value={(selectedItem as any).mimeType || (selectedItem as any).extension || '-'}
                />
              )}
              
              {/* Size */}
              {selectedItem.type === 'file' ? (
                <DetailItem 
                  icon={HardDrive}
                  label={t('cloud_drive.info.size')}
                  value={formatSize((selectedItem as any).size)}
                />
              ) : (
                <DetailItem 
                  icon={HardDrive}
                  label={t('cloud_drive.info.items')}
                  value={`${(selectedItem as any).itemCount || 0} ${t('cloud_drive.info.item_count')}`}
                />
              )}
              
              {/* Creation Date */}
              <DetailItem 
                icon={Calendar}
                label={t('cloud_drive.info.created')}
                value={formatDate(selectedItem.created)}
              />
              
              {/* Modified Date */}
              <DetailItem 
                icon={Clock}
                label={t('cloud_drive.info.modified')}
                value={formatDate(selectedItem.modified)}
              />
              
              {/* Owner Info - if available */}
              {details?.owner && (
                <DetailItem 
                  icon={Users}
                  label={t('cloud_drive.info.owner')}
                  value={
                    <div className="flex items-center">
                      <Avatar className="h-5 w-5 mr-2">
                        {details.owner.avatar && (
                          <AvatarImage src={details.owner.avatar} alt={details.owner.name} />
                        )}
                        <AvatarFallback className="text-xs">
                          {details.owner.name ? details.owner.name.charAt(0).toUpperCase() : 'U'}
                        </AvatarFallback>
                      </Avatar>
                      <span>{details.owner.name || details.owner.email || '-'}</span>
                    </div>
                  }
                />
              )}
              
              {/* Sharing Status - Use optional chaining to handle undefined shareCount */}
              {details && 'shareCount' in details && (
                <DetailItem 
                  icon={Share2}
                  label={t('cloud_drive.info.sharing')}
                  value={
                    details.shareCount && typeof details.shareCount === 'number' && details.shareCount > 0
                      ? t('cloud_drive.info.shared_with_count', { count: details.shareCount as number })
                      : t('cloud_drive.info.not_shared')
                  }
                  action={
                    <Button variant="ghost" size="sm" className="h-6 px-2">
                      <Share2 className="h-3.5 w-3.5 mr-1" />
                      <span className="text-xs">{t('cloud_drive.actions.share')}</span>
                    </Button>
                  }
                />
              )}
            </CardContent>
          </Card>
          
          {/* Tags Section - If Available */}
          {details?.tags && details.tags.length > 0 && (
            <Card>
              <CardHeader className="pb-3">
                <CardTitle className="text-sm font-medium flex items-center">
                  <TagIcon className="h-4 w-4 mr-2" />
                  {t('cloud_drive.info.tags')}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-2">
                  {details.tags.map(tag => (
                    <Badge key={tag} variant="outline">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </CardContent>
            </Card>
          )}
          
          {/* Advanced Details - If Available */}
          {selectedItem.type === 'file' && details?.versions && (
            <Card>
              <CardHeader className="pb-3">
                <CardTitle className="text-sm font-medium">
                  {t('cloud_drive.info.versions')}
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-2 pt-0">
                {details.versions.slice(0, 3).map((version) => (
                  <div 
                    key={version.id}
                    className="flex items-center justify-between text-sm p-2 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800"
                  >
                    <div>
                      <p className="font-medium">v{version.number}</p>
                      <p className="text-xs text-gray-500">
                        {formatDate(version.modified)}
                      </p>
                    </div>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button variant="ghost" size="icon" className="h-8 w-8">
                          <Download className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {t('cloud_drive.actions.download_version')}
                      </TooltipContent>
                    </Tooltip>
                  </div>
                ))}
              </CardContent>
            </Card>
          )}
        </TabsContent>
        
        {/* Activity Tab */}
        <TabsContent value="activity" className="space-y-6">
          <Card>
            <CardHeader className="pb-3">
              <CardTitle className="text-sm font-medium">
                {t('cloud_drive.info.recent_activity')}
              </CardTitle>
            </CardHeader>
            
            <CardContent className="space-y-4 pt-0">
              {details?.activity && details.activity.length > 0 ? (
                details.activity.map((event, index) => (
                  <div key={event.id || index} className="flex gap-3 pb-3">
                    <Avatar className="h-8 w-8">
                      {event.user?.avatar && (
                        <AvatarImage src={event.user.avatar} alt={event.user.name} />
                      )}
                      <AvatarFallback className="text-xs">
                        {event.user?.name ? event.user.name.charAt(0).toUpperCase() : 'U'}
                      </AvatarFallback>
                    </Avatar>
                    
                    <div className="space-y-1">
                      <p className="text-sm">
                        <span className="font-medium">{event.user?.name}</span>{' '}
                        <span className="text-gray-500 dark:text-gray-400">
                          {event.type}
                        </span>
                      </p>
                      
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {formatDate(event.timestamp)}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="py-6 text-center">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {t('cloud_drive.info.no_activity')}
                  </p>
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default InfoPanel;