/**
 * @file src/app/ghost/components/zero-knowledge/tabs/HowItWorksTab.tsx
 * @description Tab explaining how zero knowledge encryption works with enhanced animations
 * @version 1.0.2
 * @created 2025-04-01
 * @updated 2025-04-03
 * @dependencies framer-motion, lucide-react
 */

import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Server, XCircle } from 'lucide-react';
import { useGhostTranslation } from '@/hooks/ghost/useGhostTranslation';
import { GhostGradient } from '@/app/ghost/components/common/GhostGradients';
import { cn } from '@/utils/utils';

export const HowItWorksTab: React.FC = () => {
  const { t } = useGhostTranslation();
  
  return (
    <div className="space-y-4">
      {/* Step 1: Encryption on Device */}
      <div className="relative overflow-hidden rounded-xl p-3 border border-[hsl(var(--ghost-accent))]/10 bg-[hsl(var(--ghost-bg))]/10">
        <GhostGradient variant="primary" intensity="low" />
        
        <h3 className="text-base font-bold mb-1 text-[hsl(var(--ghost-foreground))]">
          {t('zeroKnowledge.dialog.howItWorks.step1.title', '1. Encryption Happens On Your Device')}
        </h3>
        
        <p className="text-sm text-[hsl(var(--ghost-foreground))] mb-3">
          {t('zeroKnowledge.dialog.howItWorks.step1.description', 
            'Before any data leaves your device, Nygma encrypts it using keys that only exist on your device.')}
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {/* Device Illustration with Animation */}
          <div className="bg-[hsl(var(--ghost-surface))]/30 rounded-xl p-2 flex justify-center items-center">
            {/* Device frame */}
            <div className="relative w-36 h-28 rounded-lg bg-[hsl(var(--ghost-surface))]/60 border border-[hsl(var(--ghost-foreground-muted))]/20 flex items-center justify-center">
              {/* Screen */}
              <div className="w-28 h-20 rounded bg-[hsl(var(--ghost-surface))]/80 flex items-center justify-center">
                {/* Animated Content */}
                <div className="relative flex items-center space-x-2">
                  {/* Original file */}
                  <motion.div
                    className="w-8 h-10 rounded bg-blue-400 flex items-center justify-center"
                    animate={{
                      x: [-5, -14, -14],
                      opacity: [1, 1, 0],
                      scale: [1, 1, 0.8]
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  >
                    <div className="w-5 flex flex-col space-y-0.5">
                      <div className="h-0.5 bg-white rounded-full"></div>
                      <div className="h-0.5 bg-white rounded-full"></div>
                      <div className="h-0.5 bg-white rounded-full"></div>
                    </div>
                  </motion.div>
                  
                  {/* Lock icon */}
                  <motion.div
                    className="absolute"
                    animate={{
                      x: [12, 0, -12],
                      opacity: [0, 1, 0],
                      scale: [0.8, 1.2, 0.8]
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  >
                    <Lock className="w-4 h-4 text-[hsl(var(--ghost-accent))]" />
                  </motion.div>
                  
                  {/* Encrypted file */}
                  <motion.div
                    className="w-8 h-10 rounded bg-[hsl(var(--ghost-accent))] flex items-center justify-center"
                    animate={{
                      x: [14, 14, -5],
                      opacity: [0, 1, 1],
                      scale: [0.8, 1, 1]
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  >
                    <svg viewBox="0 0 24 24" className="w-5 h-5 text-white">
                      <path 
                        d="M3 3l3 5 4-3 3 4 5-3 3 4" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="1.5"
                        strokeLinecap="round" 
                      />
                    </svg>
                  </motion.div>
                </div>
              </div>
              
              {/* Home button */}
              <div className="absolute bottom-2 w-3 h-3 rounded-full border border-[hsl(var(--ghost-foreground-muted))]/20" />
            </div>
          </div>
          
          {/* Key Information with Animation */}
          <div className="bg-[hsl(var(--ghost-accent))]/10 rounded-xl p-3 flex flex-col justify-center items-center">
            <motion.div
              animate={{
                y: [0, -2, 0],
                filter: [
                  'drop-shadow(0 0 0px hsla(var(--ghost-accent), 0.3))',
                  'drop-shadow(0 0 3px hsla(var(--ghost-accent), 0.6))',
                  'drop-shadow(0 0 0px hsla(var(--ghost-accent), 0.3))'
                ]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <Lock className="w-8 h-8 text-[hsl(var(--ghost-accent))] mb-1" />
            </motion.div>
            
            <h4 className="font-medium text-center mb-1 text-sm">
              {t('zeroKnowledge.dialog.howItWorks.step1.keyTitle', 'Your Key')}
            </h4>
            
            <motion.div
              className="text-xs text-center text-[hsl(var(--ghost-foreground-muted))]"
              animate={{
                opacity: [0.8, 1, 0.8]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <code className="bg-[hsl(var(--ghost-surface))]/40 px-1 py-0.5 rounded text-xs">b7e45d0a72...</code>
              <span className="block mt-1">
                {t('zeroKnowledge.dialog.howItWorks.step1.keyDescription', 'never leaves your device')}
              </span>
            </motion.div>
          </div>
        </div>
      </div>
      
      {/* Step 2: Storage on Server */}
      <div className="relative overflow-hidden rounded-xl p-3 border border-[hsl(var(--ghost-accent))]/10 bg-[hsl(var(--ghost-bg))]/10">
        <GhostGradient variant="secondary" intensity="low" />
        
        <h3 className="text-base font-bold mb-1 text-[hsl(var(--ghost-foreground))]">
          {t('zeroKnowledge.dialog.howItWorks.step2.title', '2. We Only Store Encrypted Data')}
        </h3>
        
        <p className="text-sm text-[hsl(var(--ghost-foreground))] mb-3">
          {t('zeroKnowledge.dialog.howItWorks.step2.description', 
            'Our servers only receive and store already-encrypted data. We never see your original files.')}
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {/* Server Animation */}
          <div className="bg-[hsl(var(--ghost-surface))]/30 rounded-xl p-2 flex justify-center items-center">
            {/* Server representation */}
            <div className="w-36 h-28 rounded-lg bg-white/10 border border-[hsl(var(--ghost-foreground-muted))]/10 relative flex flex-col p-2 space-y-1">
              {/* Server rows with blinking lights */}
              {[1, 2, 3, 4].map(i => (
                <div key={i} className="h-5 flex items-center bg-[hsl(var(--ghost-bg))]/20 rounded px-1">
                  <motion.div 
                    className={cn(
                      "w-1 h-1 rounded-full mr-1",
                      i % 2 === 0 ? "bg-green-400" : "bg-[hsl(var(--ghost-accent))]"
                    )}
                    animate={{
                      opacity: i % 3 === 0 ? [0.5, 1, 0.5] : [1, 0.5, 1]
                    }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      delay: i * 0.2
                    }}
                  />
                  <div className="flex-1 h-0.5 bg-[hsl(var(--ghost-foreground-muted))]/10 rounded-full" />
                </div>
              ))}
              
              {/* Lock overlay with pulse animation */}
              <motion.div 
                className="absolute inset-0 flex items-center justify-center bg-[hsl(var(--ghost-bg))]/10"
                animate={{
                  opacity: [0.4, 0.6, 0.4]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <Lock className="w-8 h-8 text-[hsl(var(--ghost-accent))]" />
              </motion.div>
            </div>
          </div>
          
          {/* X-Ray Vision Blocked with Animation */}
          <div className="flex flex-col justify-center items-center">
            <motion.div 
              className="relative"
              animate={{
                scale: [1, 1.03, 1],
                filter: [
                  'drop-shadow(0 0 0px rgba(220, 38, 38, 0.3))',
                  'drop-shadow(0 0 4px rgba(220, 38, 38, 0.6))',
                  'drop-shadow(0 0 0px rgba(220, 38, 38, 0.3))'
                ]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <XCircle className="w-12 h-12 text-red-500 mb-1" />
              <motion.div
                className="absolute inset-0 flex items-center"
                animate={{
                  rotate: [-45, -45],
                  opacity: [0.8, 1, 0.8]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <div className="w-full h-0.5 bg-red-500 rounded-full" />
              </motion.div>
            </motion.div>
            
            <h4 className="font-medium text-center mb-1 text-sm">
              {t('zeroKnowledge.dialog.howItWorks.step2.serverTitle', 'We Cannot Access Your Data')}
            </h4>
            
            <p className="text-xs text-center text-[hsl(var(--ghost-foreground-muted))]">
              {t('zeroKnowledge.dialog.howItWorks.step2.serverDescription', 
                'Even with legal requests, we simply cannot decrypt your files')}
            </p>
          </div>
        </div>
      </div>
      
      {/* Buttons with Animation */}
      <div className="flex justify-end space-x-2 mt-2">
        <motion.button
          className={cn(
            "px-3 py-1.5 text-sm rounded-lg",
            "bg-[hsl(var(--ghost-surface))]/40",
            "text-[hsl(var(--ghost-foreground))]",
            "border border-[hsl(var(--ghost-accent))]/10",
            "transition-all duration-200"
          )}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          {t('zeroKnowledge.dialog.howItWorks.learnMoreButton', 'Learn More')}
        </motion.button>
        
        <motion.button
          className={cn(
            "px-3 py-1.5 text-sm rounded-lg",
            "bg-gradient-to-r from-[hsl(var(--ghost-accent))] to-[hsl(var(--ghost-accent-secondary))]",
            "text-white",
            "transition-all duration-200"
          )}
          whileHover={{ 
            scale: 1.02,
            boxShadow: '0 0 8px hsla(var(--ghost-accent), 0.4)'
          }}
          whileTap={{ scale: 0.98 }}
        >
          <motion.div 
            className="flex items-center"
            animate={{ x: [0, 2, 0] }}
            transition={{ 
              duration: 1.5, 
              repeat: Infinity,
              ease: "easeInOut" 
            }}
          >
            <span>{t('zeroKnowledge.dialog.howItWorks.testButton', 'Test It Yourself')}</span>
            <span className="ml-1">→</span>
          </motion.div>
        </motion.button>
      </div>
    </div>
  );
};

export default HowItWorksTab;