/**
 * @file src/app/ghost/providers/GhostThemeProvider.tsx
 * @description Enhanced Ghost theme provider with theme switching support
 * @version 2.0.0
 */

import React, { createContext, useContext, useEffect, useState } from 'react';
import { logger } from '@/utils/logger';

// Import theme styles
import '../styles';

export type GhostTheme = 'day' | 'night' | 'quantum';

interface GhostThemeContextType {
  theme: GhostTheme;
  setTheme: (theme: GhostTheme) => void;
  isLoaded: boolean;
}

const GhostThemeContext = createContext<GhostThemeContextType | undefined>(undefined);

const STORAGE_KEY = 'ghost-theme-preference';
const DEFAULT_THEME: GhostTheme = 'quantum';

export const useGhostTheme = () => {
  const context = useContext(GhostThemeContext);
  if (!context) {
    throw new Error('useGhostTheme must be used within GhostThemeProvider');
  }
  return context;
};

interface GhostThemeProviderProps {
  children: React.ReactNode;
}

export function GhostThemeProvider({ children }: GhostThemeProviderProps) {
  const [theme, setThemeState] = useState<GhostTheme>(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY) as GhostTheme | null;
      return stored || DEFAULT_THEME;
    } catch (error) {
      logger.error('Error reading theme preference', { error });
      return DEFAULT_THEME;
    }
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    
    logger.debug('Ghost theme initializing', {
      component: 'GhostThemeProvider',
      data:{
        initialTheme: theme
      }
    });

    // Remove all theme classes first
    root.classList.remove('ghost-theme-day', 'ghost-theme-night', 'ghost-theme-quantum');
    
    // Add the new theme class
    root.classList.add(`ghost-theme-${theme}`);
    
    try {
      // Store the preference
      localStorage.setItem(STORAGE_KEY, theme);
    } catch (error) {
      logger.error('Error storing theme preference', { error });
    }
    
    setIsLoaded(true);

    logger.debug('Ghost theme applied', {
      component: 'GhostThemeProvider',
      data:{
        appliedTheme: theme,
        classList: root.classList.toString()
      }
    });
  }, [theme]);

  const setTheme = (newTheme: GhostTheme) => {
    logger.debug('Changing Ghost theme', {
      component: 'GhostThemeProvider',
      data:{
        from: theme,
        to: newTheme
      }
    });
    setThemeState(newTheme);
  };

  // Don't render until theme is loaded to prevent flash
  if (!isLoaded) {
    return null;
  }

  return (
    <GhostThemeContext.Provider value={{ theme, setTheme, isLoaded }}>
      <div className={`ghost-theme-${theme}`} data-ghost-theme={theme}>
        {children}
      </div>
    </GhostThemeContext.Provider>
  );
}

export default GhostThemeProvider;