/**
 * @file src/utils/api-helpers.ts
 * @description Helper functions for API calls
 * @version 1.0.0
 */

// Note: Adjust the import path as needed for your project
import { authService } from '../services/auth.service';

/**
 * Gets authentication headers for API requests
 * Properly typed to avoid TS2769 errors
 */
export const getAuthHeaders = (): HeadersInit => {
  const token = authService.getStoredToken();
  
  // Create base headers
  const headers: Record<string, string> = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  
  // Add Authorization header if token exists
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  
  return headers;
};
