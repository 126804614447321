/**
 * @file src/services/encrypted-drive/createDrive.service.ts
 * @description Centralized encrypted drive creation service
 * @version 1.0.0
 */

import { logger } from '@/utils/logger';
import { encryptedDriveApi } from './encrypted-drive.service';
import { EncryptionService } from './encryption.service';
import type { EncryptionTier } from '@/types/encrypted-drive.types';
import type { KeyGenerationResult } from '@/types/encryption.types';

interface CreateDriveParams {
  name: string;
  password: string;
  deviceId: string;
  encryptionTier: EncryptionTier;
}

interface CreateDriveResult {
  success: boolean;
  driveId?: string;
  recoveryKey?: string;
  error?: string;
  keyData?: KeyGenerationResult;
}

const encryptionService = new EncryptionService();

export const createEncryptedDriveService = async (
  params: CreateDriveParams
): Promise<CreateDriveResult> => {
  try {
    logger.debug('Starting encrypted drive creation', {
      data:{
        name: params.name,
        deviceId: params.deviceId,
        encryptionTier: params.encryptionTier
      }
    });

    // Generate encryption keys first
    const keyData = await encryptionService.generateMasterKey(params.password);
    
    if (!keyData.recoveryData?.key) {
      throw new Error('Failed to generate recovery key');
    }

    logger.debug('Generated encryption data', {
      data:{
        hasRecoveryKey: !!keyData.recoveryData?.key,
        hasVerificationHash: !!keyData.verificationHash,
        hasSalt: !!keyData.salt
      }
    });

    // Return recovery key for immediate display without creating drive yet
    return {
      success: true,
      recoveryKey: keyData.recoveryData.key,
      keyData
    };

  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Unknown error';
    logger.error('Key generation failed', { error });
    return {
      success: false,
      error: errorMessage
    };
  }
};

export const completeDriveCreation = async (
    params: CreateDriveParams,
    keyData: KeyGenerationResult
  ): Promise<CreateDriveResult> => {
    try {
      // Add detailed logging of the request payload
      logger.debug('Creating drive with payload:', {
        component: 'completeDriveCreation',
        data: {
          params: {
            name: params.name,
            device_id: params.deviceId, // Log the incoming ID
            encryptionTier: params.encryptionTier
          },
          keyData: {
            hasEncryptedKeys: !!keyData.encryptedKeys,
            hasRecoveryData: !!keyData.recoveryData,
            hasVerificationHash: !!keyData.verificationHash,
            hasSalt: !!keyData.salt
          }
        }
      });
  
      const requestPayload = {
        name: params.name,
        device_id: params.deviceId,  // Make sure this is being set
        encryption_tier: params.encryptionTier,
        encrypted_content_key: keyData.encryptedKeys.contentKey,
        encrypted_metadata_key: keyData.encryptedKeys.metadataKey,
        encrypted_keys_iv: keyData.encryptedKeys.iv,
        key_salt: keyData.salt,
        key_verification_hash: keyData.verificationHash,
        recovery_verification_hash: keyData.recoveryData?.verification_hash,
        recovery_data: keyData.recoveryData && {
          key: keyData.recoveryData.key,
          verification_hash: keyData.recoveryData.verification_hash,
          encrypted_keys: {
            content_key: keyData.recoveryData.encrypted_keys.content_key,
            metadata_key: keyData.recoveryData.encrypted_keys.metadata_key,
            iv: keyData.encryptedKeys.iv
          }
        }
      };
  
      // Log the final request payload
    // Add more detailed logging
    logger.debug('Request payload prepared:', {
        component: 'completeDriveCreation',
        action: 'prepare',
        data: {
          hasDeviceId: !!requestPayload.device_id,
          deviceId: requestPayload.device_id,
          recoveryDataPresent: !!requestPayload.recovery_data
        }
      });
  
      const response = await encryptedDriveApi.createDrive(requestPayload);
  
      logger.debug('Drive created successfully', {
        data:{
          driveId: response.id,
          name: response.name,
          status: response.status
        }
      });
  
      return {
        success: true,
        driveId: response.id,
        recoveryKey: keyData.recoveryData?.key
      };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      logger.error('Drive creation failed', { 
        error,
        requestParams: {
          name: params.name,
          deviceId: params.deviceId,
          encryptionTier: params.encryptionTier
        }
      });
      return {
        success: false,
        error: errorMessage
      };
    }
  };


