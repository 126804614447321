/**
 * @file: components/media/MediaGrid.tsx
 * @description: Virtualized grid for displaying media items
 * @version: 1.0.0
 */

import React, { useCallback, useRef, useState } from 'react';
import { MediaItem } from '../../services/media.service';
import MediaCard from './MediaCard';
import { useVirtualizer } from '@tanstack/react-virtual';

interface MediaGridProps {
  items: MediaItem[];
  selectedItems: MediaItem[];
  onSelect: (item: MediaItem, append: boolean) => void;
  isLoading: boolean;
  hasMore: boolean;
  columns?: number;
}

export const MediaGrid: React.FC<MediaGridProps> = ({
  items,
  selectedItems,
  onSelect,
  isLoading,
  hasMore,
  columns = 4
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [hoverItem, setHoverItem] = useState<string | null>(null);

  // Calculate columns based on screen width
  const getColumns = useCallback(() => {
    if (!parentRef.current) return columns;
    
    const width = parentRef.current.offsetWidth;
    if (width < 640) return 2;
    if (width < 1024) return 3;
    if (width < 1280) return 4;
    return 5;
  }, [columns]);

  const columnCount = getColumns();
  const itemCount = Math.ceil(items.length / columnCount);

  // Create virtualizer for rows
  const rowVirtualizer = useVirtualizer({
    count: itemCount,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 250,
    overscan: 5,
  });

  // Item click handler (single click)
  const handleItemClick = useCallback(
    (item: MediaItem, event: React.MouseEvent) => {
      onSelect(item, event.ctrlKey || event.metaKey || event.shiftKey);
    },
    [onSelect]
  );

  return (
    <div 
      ref={parentRef} 
      className="h-full overflow-auto"
    >
      <div
        className="relative w-full"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const rowStartIndex = virtualRow.index * columnCount;
          
          return (
            <div
              key={virtualRow.key}
              className="absolute w-full grid gap-4"
              style={{
                top: 0,
                left: 0,
                transform: `translateY(${virtualRow.start}px)`,
                gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
              }}
            >
              {Array.from({ length: columnCount }).map((_, colIndex) => {
                const itemIndex = rowStartIndex + colIndex;
                const item = items[itemIndex];
                
                if (!item) return <div key={`empty-${colIndex}`} className="h-60"></div>;
                
                const isSelected = selectedItems.some((i) => i.id === item.id);
                const isHovered = hoverItem === item.id;
                
                return (
                  <MediaCard
                    key={item.id}
                    item={item}
                    isSelected={isSelected}
                    onClick={(e) => handleItemClick(item, e)}
                    onMouseEnter={() => setHoverItem(item.id)}
                    onMouseLeave={() => setHoverItem(null)}
                    isHovered={isHovered}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      
      {/* Loading indicator */}
      {isLoading && (
        <div className="py-4 text-center text-gray-500 dark:text-gray-400">
          Loading more items...
        </div>
      )}
      
      {/* End of list indicator */}
      {!isLoading && !hasMore && items.length > 0 && (
        <div className="py-4 text-center text-gray-500 dark:text-gray-400">
          End of gallery
        </div>
      )}
    </div>
  );
};

export default MediaGrid;