/**
 * @file src/components/cloud-drive/dnd/DragContext.tsx
 * @description Enhanced drag and drop context with preview and multi-drag support
 * @version 2.0.0
 */

import React, { createContext, useContext, useState, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { motion, AnimatePresence } from 'framer-motion';
import { File, Folder, Files } from 'lucide-react';
import { cn } from '@/utils/utils';
import type { DriveFile, DriveFolder } from '@/types/cloud-drive.types';

// Drag types
export const ItemTypes = {
  FILE: 'file',
  FOLDER: 'folder'
};

// Context type
interface DragContextValue {
  draggedItems: string[];
  isDragging: boolean;
  dropTarget: string | null;
  dragType: string | null;
  startDrag: (items: string[], type: string) => void;
  endDrag: () => void;
  setDropTarget: (id: string | null) => void;
}

const DragContext = createContext<DragContextValue | undefined>(undefined);

// Enhanced drag preview
const DragPreview: React.FC<{ count: number; type: string }> = ({ count, type }) => {
  const isMultiple = count > 1;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      className={cn(
        "fixed left-0 top-0 pointer-events-none",
        "bg-white dark:bg-gray-900",
        "border border-gray-200 dark:border-gray-700",
        "shadow-lg rounded-lg p-3",
        "flex items-center space-x-3"
      )}
    >
      {/* Icon */}
      <div className={cn(
        "flex items-center justify-center",
        "w-10 h-10 rounded-lg",
        "bg-gray-50 dark:bg-gray-800"
      )}>
        {isMultiple ? (
          <Files className="h-5 w-5 text-blue-500" />
        ) : (
          type === ItemTypes.FOLDER ? (
            <Folder className="h-5 w-5 text-blue-500" />
          ) : (
            <File className="h-5 w-5 text-blue-500" />
          )
        )}
      </div>

      {/* Info */}
      <div className="flex flex-col">
        <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
          {isMultiple ? `${count} items` : 'Moving item'}
        </span>
        <span className="text-xs text-gray-500 dark:text-gray-400">
          Drop to move here
        </span>
      </div>

      {/* Counter badge for multiple items */}
      {isMultiple && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className={cn(
            "absolute -top-2 -right-2",
            "w-6 h-6 rounded-full",
            "bg-blue-500 text-white",
            "flex items-center justify-center",
            "text-xs font-medium"
          )}
        >
          {count}
        </motion.div>
      )}
    </motion.div>
  );
};

// Provider component
export const DragProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [draggedItems, setDraggedItems] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dropTarget, setDropTarget] = useState<string | null>(null);
  const [dragType, setDragType] = useState<string | null>(null);

  const startDrag = useCallback((items: string[], type: string) => {
    setDraggedItems(items);
    setDragType(type);
    setIsDragging(true);
  }, []);

  const endDrag = useCallback(() => {
    setDraggedItems([]);
    setDragType(null);
    setIsDragging(false);
    setDropTarget(null);
  }, []);

  return (
    <DragContext.Provider
      value={{
        draggedItems,
        isDragging,
        dropTarget,
        dragType,
        startDrag,
        endDrag,
        setDropTarget
      }}
    >
      {children}
      <AnimatePresence>
        {isDragging && (
          <DragPreview 
            count={draggedItems.length} 
            type={dragType || ItemTypes.FILE} 
          />
        )}
      </AnimatePresence>
    </DragContext.Provider>
  );
};

// Custom hooks for items
export const useDraggableItem = (
  item: DriveFile | DriveFolder,
  isSelected: boolean
) => {
  const { draggedItems, startDrag, endDrag } = useDragDrop();

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: item.type === 'folder' ? ItemTypes.FOLDER : ItemTypes.FILE,
    item: () => {
      const items = isSelected ? draggedItems : [item.id];
      startDrag(items, item.type);
      return { ids: items };
    },
    end: endDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [item, isSelected, draggedItems]);

  return { dragRef, isDragging, draggedItems };
};

export const useDroppableFolder = (
  folder: DriveFolder,
  onMove: (itemIds: string[], targetId: string) => void
) => {
  const { setDropTarget } = useDragDrop();

  const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
    accept: [ItemTypes.FILE, ItemTypes.FOLDER],
    drop: (item: { ids: string[] }) => {
      onMove(item.ids, folder.id);
    },
    hover: () => setDropTarget(folder.id),
    canDrop: (item: { ids: string[] }) => !item.ids.includes(folder.id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }), [folder, onMove]);

  return { dropRef, isOver, canDrop };
};

// Export context hook
export const useDragDrop = () => {
  const context = useContext(DragContext);
  if (!context) {
    throw new Error('useDragDrop must be used within a DragProvider');
  }
  return context;
};

// Indicator component for drop targets
export const DropIndicator: React.FC<{ isVisible: boolean }> = ({ isVisible }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className={cn(
            "absolute inset-0",
            "border-2 border-blue-500 border-dashed",
            "rounded-lg",
            "bg-blue-50/20 dark:bg-blue-900/20",
            "pointer-events-none"
          )}
        />
      )}
    </AnimatePresence>
  );
};