/**
* @file src/config/upload.config.ts
* @description Enhanced upload configuration settings with chunk support
* @version 1.2.0
*/

export const UPLOAD_CONFIG = {
  // Queue Settings
  maxQueueSize: 10,
  // Concurrent upload limits
  concurrentUploads: {
    free: 2,
    paid: 3  // All paid plans get 3 concurrent uploads
  },
  maxFileSize: 1024 * 1024 * 1000, // 1GB
  minDirectUploadSize:10,

  // Chunk Settings
  chunkSize: 1024 * 1024 * 5, // 5MB chunks
  minChunkSize: 1024 * 1024 * 1, // 1MB minimum chunk size
  maxChunks: 10000, // Maximum number of chunks per file
  chunkRetryAttempts: 3,
  chunkRetryDelay: 1000, // Base delay in ms (exponential backoff)

  // API Endpoints - Updated to match new structure
  endpoints: {
    simple: '/api/v1/cloud-drive/upload/simple',
    strategy: '/api/v1/cloud-drive/upload/strategy',
    multipart: {
      initialize: '/api/v1/cloud-drive/upload/initialize',
      chunk: '/api/v1/cloud-drive/upload/chunk', // Base URL - uploadId will be appended
      complete: '/api/v1/cloud-drive/upload/complete',
      abort: '/api/v1/cloud-drive/upload/abort', // uploadId will be appended
      status: '/api/v1/cloud-drive/upload/status' // uploadId will be appended
    }
  },
 
  // Resume Settings
  resumeExpiry: 24 * 60 * 60 * 1000, // 24 hours
  resumeMaxAttempts: 5,
  retryAttempts: 3,
  retryDelay: 1000, // Base delay in ms (exponential backoff)
  
  // Network Settings
  concurrentChunks: 3, // Number of concurrent chunk uploads
  requestTimeout: 30000, // 30 seconds
  bandwidthThrottle: 0, // 0 for no limit, bytes per second
  
  // Compression Settings
  compression: {
    // Size thresholds in bytes
    minSize: 1024,  // 1KB minimum size to consider compression
    maxSize: 100 * 1024 * 1024,  // 100MB maximum size for compression
    
    // Compression options
    level: 6,  // GZIP compression level (1-9)
    chunkSize: 512 * 1024,  // 512KB chunks for streaming compression
    
    // Memory usage limits
    maxMemoryUsage: 512 * 1024 * 1024,  // 512MB max memory for compression
    
    // Performance settings
    concurrentCompressions: 2,  // Max concurrent compressions
    compressionTimeout: 30000,  // 30 seconds timeout
  },

  // File type settings
  compressibleTypes: [
    // Text
    'text/plain', 'text/csv', 'text/html', 'text/css', 'text/javascript',
    // Documents
    'application/json', 'application/xml',
    'application/msword', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ],

  // File Settings
  acceptedFiles: [
    // Images
    'image/*',
    // Documents
    '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx',
    '.txt', '.rtf', '.csv',
    // Archives
    '.zip', '.rar', '.7z',
    // Media
    '.mp3', '.mp4', '.avi', '.mov',
    // Others
    '.json', '.xml'
  ].join(','),

  // Status Check Settings
  statusCheckInterval: 5000, // 5 seconds
  maxStatusChecks: 100, // Maximum number of status checks for a file
 
  // Error Handling
  retryableErrors: [
    'NETWORK_ERROR',
    'TIMEOUT',
    'SERVER_ERROR',
    'CHUNK_UPLOAD_FAILED'
  ],
 
  // Storage Settings
  localStorageKeys: {
    chunkStates: 'bigmind_chunk_states',
    uploadQueue: 'bigmind_upload_queue',
    uploadStrategy: 'bigmind_upload_strategy'
  },
 
  // Progress Update Settings
  progressUpdateInterval: 100, // ms between progress updates
  speedCalculationWindow: 5000, // Window for calculating upload speed (ms)
} as const;

// Validation helper functions
export const uploadUtils = {
  isValidFileType: (file: File): boolean => {
    const acceptedTypes = UPLOAD_CONFIG.acceptedFiles.split(',');
    return acceptedTypes.some((type: any) => {
      if (type.startsWith('.')) {
        return file.name.toLowerCase().endsWith(type);
      }
      if (type.endsWith('/*')) {
        const baseType = type.slice(0, -2);
        return file.type.startsWith(baseType);
      }
      return file.type === type;
    });
  },
 
  getChunkCount: (fileSize: number): number => {
    return Math.ceil(fileSize / UPLOAD_CONFIG.chunkSize);
  },
 
  shouldUseChunks: (file: File): boolean => {
    return file.size > UPLOAD_CONFIG.chunkSize;
  },
 
  getResumeKey: (file: File): string => {
    return `${file.name}-${file.size}-${file.lastModified}`;
  }
};

// Export types
export type UploadConfigType = typeof UPLOAD_CONFIG;
export type UploadErrorType = keyof typeof UPLOAD_ERRORS;

// Error constants
export const UPLOAD_ERRORS = {
  FILE_TOO_LARGE: 'FILE_TOO_LARGE',
  QUEUE_FULL: 'QUEUE_FULL',
  INVALID_FILE_TYPE: 'INVALID_FILE_TYPE',
  UPLOAD_FAILED: 'UPLOAD_FAILED',
  CHUNK_FAILED: 'CHUNK_FAILED',
  QUOTA_EXCEEDED: 'QUOTA_EXCEEDED',
  NETWORK_ERROR: 'NETWORK_ERROR',
  TIMEOUT: 'TIMEOUT',
  SERVER_ERROR: 'SERVER_ERROR',
  INVALID_STATE: 'INVALID_STATE'
} as const;