/**
 * @file src/components/cloud-drive/preview/FilePreviewSheet.tsx
 * @description File preview sheet with integrated loading states
 * @version 1.0.0
 * @created 2024-12-22
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Sheet, 
  SheetContent, 
  SheetHeader, 
} from '@/components/ui/sheet';
import { FilePreview } from './FilePreview';
import { Button } from '@/components/ui/button';
import { CenteredSpinner } from '../loading/LoadingStates';
import { useCloudDrive } from '@/hooks/cloud-drive/useCloudDrive';
import type { DriveFile } from '@/types/cloud-drive.types';


interface FilePreviewSheetProps {
  fileId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const FilePreviewSheet: React.FC<FilePreviewSheetProps> = ({
  fileId,
  isOpen,
  onClose
}) => {
  const { t } = useTranslation();
  const { getFileDetails } = useCloudDrive();
  const { data: file, isLoading, error } = getFileDetails(fileId);

  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <SheetContent
        className="w-full md:max-w-3xl lg:max-w-5xl"
        showCloseButton
      >
        <SheetHeader>
          {file && (
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">{(file as unknown as DriveFile).name}</h3>
            </div>
          )}
        </SheetHeader>

        {isLoading && (
          <CenteredSpinner text={t("cloud_drive.loading_preview")} />
        )}

        {error && (
          <div className="flex flex-col items-center justify-center h-full p-8">
            <p className="text-sm text-red-500">
              {t("cloud_drive.error_loading_preview")}
            </p>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => getFileDetails(fileId).refetch()}
              className="mt-4"
            >
              {t("common.try_again")}
            </Button>
          </div>
        )}

        {file && (
          <div className="flex-1 overflow-hidden">
            {/*
              Cast file to unknown then to DriveFile, and cast FilePreview to any.
            */}
            {(FilePreview as any)({
              file: file as unknown as DriveFile,
              onShare: () => {
                /* Implement share */
              },
              onDownload: () => {
                /* Implement download */
              },
            })}
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};