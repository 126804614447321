/**
 * @file src/components/upload/GlobalUploadQueue/UploadQueueHeader.tsx
 * @description Upload queue header with encrypted drive support and concurrent upload display
 * @version 1.4.0
 */

import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload, X, Minimize2, Maximize2, Shield } from 'lucide-react';
import { cn } from '@/utils/utils';
import { Button } from '@/components/ui/button';
import { useUploadQueue } from '@/hooks/upload/useUploadQueue';
import { logger } from '@/utils/logger';
import { useToast } from '@/components/ui/toast';
import { useRootDrive } from '@/context/root-drive/RootDriveContext';
import { useCloudDrive } from '@/context/cloud-drive';
import { useTranslation } from 'react-i18next';

interface UploadQueueHeaderProps {
  fileInputRef: React.RefObject<HTMLInputElement>;
  concurrentLimit?: number;
  isPaidUser?: boolean;
}

export const UploadQueueHeader: React.FC<UploadQueueHeaderProps> = ({
  fileInputRef,
  concurrentLimit = 1,
  isPaidUser = true
}) => {
  const { t } = useTranslation(); // Uncommented this line
  const { showToast } = useToast();
  const { activeDriveId, primaryDriveId } = useRootDrive();
  const { encryptedDrive } = useCloudDrive();

  const lastCompletedCountRef = React.useRef<number>(0);  

  const { 
    queue, 
    isMinimized,
    isVisible,
    hideQueue,
    showQueue,
    minimizeQueue,
    activeUpload
  } = useUploadQueue();

  // Calculate active uploads from the queue
  const activeUploads = queue.filter(file => file.status === 'uploading');

  // Calculate stats
  const totalFiles = queue.length;
  const pendingFiles = queue.filter((f: any) => f.status === 'pending').length;
  const completedFiles = queue.filter((f: any) => f.status === 'completed').length;
  const activeCount = activeUploads.length || 0;
  const hasActiveUpload = !!activeUpload || activeCount > 0;

  // Updated to reflect encrypted status in quick upload
  const handleQuickUpload = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    const effectiveDriveId = activeDriveId || primaryDriveId;
    logger.debug('Quick upload clicked', {
      component: 'UploadQueueHeader',
      action: 'quickUpload',
      data: { 
        activeDriveId,
        primaryDriveId,
        effectiveDriveId,
        isEncryptedMode: encryptedDrive.isEncryptedMode,
        concurrentLimit,
        activeCount,
        isAcceptingFiles: useUploadQueue().isAcceptingFiles
      }
    });
    
    // Check if we have any drive available
    if (!effectiveDriveId) {
      showToast('Please unlock a drive for uploads', 'error');
      return;
    }
    
    // Check if uploads are disabled
    if (!useUploadQueue().isAcceptingFiles) {
      showToast('Uploads disabled - no unlocked drives available', 'error');
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleMinimizeToggle = () => {
    logger.debug('Toggle minimize state', {
      component: 'UploadQueueHeader',
      action: isMinimized ? 'maximize' : 'minimize'
    });

    if (isMinimized) {
      showQueue();
    } else {
      minimizeQueue();
    }
  };

  // Updated to show encryption status in toast
  useEffect(() => {
    if (completedFiles > lastCompletedCountRef.current && (isMinimized || !isVisible)) {
      const newCompletions = completedFiles - lastCompletedCountRef.current;
      showToast(
        t(encryptedDrive.isEncryptedMode 
          ? 'upload.encrypted_files_complete' 
          : 'upload.files_complete', 
          { count: newCompletions }
        ), 
        "success"
      );
    }
    lastCompletedCountRef.current = completedFiles;
  }, [completedFiles, isMinimized, isVisible, showToast, encryptedDrive.isEncryptedMode, t]);

  return (
    <div className={cn(
      "flex items-center justify-between",
      "px-3 sm:px-4 h-16",
      "border-b border-gray-200 dark:border-gray-800",
      "bg-white dark:bg-gray-900",
      encryptedDrive.isEncryptedMode && "bg-blue-50/50 dark:bg-blue-950/30",
      "z-20"
    )}>
      <div className="flex items-center gap-2 sm:gap-3">
        <div className="relative">
          {encryptedDrive.isEncryptedMode ? (
            <Shield className="h-5 w-5 text-blue-500" />
          ) : (
            <Upload className="h-5 w-5 text-blue-500" />
          )}
          <AnimatePresence>
            {hasActiveUpload && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{ duration: 0.2 }}
                className="absolute -right-1 -top-1 w-2 h-2 bg-blue-500 rounded-full"
              />
            )}
          </AnimatePresence>
        </div>
        
        <div className="flex flex-col">
          <h3 className="text-sm font-medium flex items-center flex-wrap gap-1 sm:gap-2">
            {encryptedDrive.isEncryptedMode ? (
              <>
                {t('upload.encrypted_upload')}
                <span className="text-xs text-blue-500 bg-blue-100 dark:bg-blue-900/50 px-2 py-0.5 rounded truncate max-w-[120px] sm:max-w-[150px]">
                  {encryptedDrive.driveName || t('encrypted_drive.unnamed_drive')}
                </span>
              </>
            ) : (
              t('upload.queue_title')
            )}
          </h3>
          <div className="text-xs text-gray-500 flex items-center gap-1 flex-wrap">
            <span>
              {completedFiles}/{totalFiles} files complete
            </span>
            {/* Show concurrent upload limit for paid users */}
            {isPaidUser && !isMinimized && (
              <span className="bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 px-1 rounded text-[10px]">
                {activeCount}/{concurrentLimit} active
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-1">
        {isMinimized && (
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8 text-blue-500 hover:text-blue-600"
            onClick={handleQuickUpload}
            aria-label="Quick upload"
          >
            <Upload className="h-4 w-4" />
          </Button>
        )}
        
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8"
          onClick={handleMinimizeToggle}
          aria-label={isMinimized ? 'Maximize' : 'Minimize'}
        >
          <motion.div
            animate={{ rotate: isMinimized ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            {isMinimized ? (
              <Maximize2 className="h-4 w-4" />
            ) : (
              <Minimize2 className="h-4 w-4" />
            )}
          </motion.div>
        </Button>
        
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8"
          onClick={hideQueue}
          aria-label="Close upload queue"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};